import React from 'react';
import { InterestSitesMaintenanceContent } from './InterestSitesMaintenanceContent'
import { InterestSitesProvider } from './context/interestSiteMaintenanceContext';

export const InterestSitesMaintenancePage: React.FC = () => {
    return (
        <InterestSitesProvider>
            <InterestSitesMaintenanceContent />
        </InterestSitesProvider>
    );
};
