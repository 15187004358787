import React, { useState, useEffect, FunctionComponent } from "react";
import { FormList, Props, ContractData, Doc } from "../../utilities/interfaces";
import { useForm, SubmitHandler } from "react-hook-form";
import OfflineModal from "../../commons/plain/modals/offline";
import DownloadingModal from "../../commons/plain/modals/downloading";
import { handleClick, handleDownload } from "../../utilities/functions";
import fichaContratacionService from "../../../../services/ficha-contratacion-service";
import { scrollToTop } from "../../../../services/utiles";

interface Props1 extends Props {
    prevStep(): void;
    contractData: ContractData;
    setContractData: React.Dispatch<React.SetStateAction<ContractData>>;
    postValues: any;
    setPostValues: React.Dispatch<React.SetStateAction<any>>;
    company: string;
}

const EigthStep: FunctionComponent<Props1> = ({ options, nextStep, prevStep, contractData, setContractData, postValues, setPostValues, company }) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
    } = useForm<ContractData>({
        defaultValues: {
            ...contractData,
        }
    });

    const [rulesFile, setRulesFile] = useState<Doc | null>(null);
    const [downloadedRulesFile, setdownloadedRulesFile] = useState(false);
    const [postStatus, setPostStatus] = useState<any>(false);
    const [checked, setChecked] = useState(contractData.cn_contra === "1");
    const [disableButton, setDisableButton] = useState(false);
    const rules = watch("cn_contra");

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        if (rules === "" || rules === "0") {
            setChecked(false);
        } else if (rules === "1") {
            setChecked(true);
        }
    }, [rules]);

    useEffect(() => {
        setValue("cn_contra", checked ? "1" : "0");
    }, [checked]);

    useEffect(() => {
        if (postStatus) {
            document.getElementById("closeSendingInfo")?.click();
        }
        const timer = setTimeout(() => {
            setDisableButton(false);
            if (postStatus.status === "0") {
                nextStep();
            }
        }, 519);
        return () => clearTimeout(timer);
    }, [postStatus]);

    useEffect(() => {
        if (rulesFile !== null) {
            handleDownload(rulesFile, setdownloadedRulesFile, "closeDownloadModal");
        }
    }, [rulesFile]);

    const formSubmitHandler: SubmitHandler<ContractData> = (data: ContractData) => {
        if (navigator.onLine) {
            if (downloadedRulesFile) {
                setDisableButton(true);
                let newData = { ...data };
                setContractData(newData);
                document.getElementById("sendingInfo")?.click();
                fichaContratacionService.postMethods.setFicha(postValues, newData, setPostValues, false, setPostStatus, setDisableButton);
            }
        } else {
            document.getElementById("offline")?.click();
        }
    };

    return (
        <>
            <form
                className="needs-validation"
                onSubmit={handleSubmit(formSubmitHandler)}
            >
                <div id={"rules"} className="box mb-4">
                    <h2 className="h4 tit-section" children={"Firma Electrónica"} />
                    <div className="mb-3">
                        <a 
                            href="/ficha-ingreso"
                            onClick={handleClick({ setDocument: setRulesFile, company: company, docType: "5", idBtn: "download" })}
                            className="text-decoration-none"
                        >
                            <span className="text-uppercase fw-bolder">{"Descargar"}</span>
                            <i className="fa fa-file-pdf-o color-primary ms-2 fa-lg" aria-hidden="true" />
                        </a>
                        {downloadedRulesFile ? (
                            <>
                                <i className="fa fa-check color-primary ms-2 fa-lg" aria-hidden="true" />
                            </>
                        ) : null}
                        {!downloadedRulesFile ? (
                            <div className="invalid-feedback d-block mt-2">
                                {"Debe descargar el documento"}
                                <i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" />
                            </div>
                        ) : null}
                        <input type="hidden" {...register("cn_contra", {
                            required: "Debe aceptar",
                            pattern: {
                                value: /[^0]/,
                                message: "Debe aceptar haber leído el documento",
                            }
                            
                        })}/>
                    </div>
                    <p className="lh-base">{"El trabajador que suscribe manifiesta conocer y haber recibido un anexo de Autoservicio\
                    de la Empresa Contratante el cual además ha sido leído y declara\
                    que se compromete a dar estricto cumplimiento a sus disposiciones y normas contenidas en dicho\
                    Anexo. "}</p>
                    <div>
                        <input
                            className="form-check-input border-primary me-2 h5"
                            type="checkbox"
                            id="invalidCheck"
                            onClick={() => setChecked((oldChecked) => !oldChecked)}
                            checked={checked}
                        />
                        <label className="form-check-label color-primary-strong mt-1" htmlFor="invalidCheck">
                            {"He leído y acepto el documento"}
                        </label>
                        {errors["cn_contra"] && (
                            <div className="invalid-feedback d-block">
                                {"Debe aceptar haber leído el documento"}
                            </div>
                        )}
                    </div>                
                </div>
                <div className="d-grid d-md-flex justify-content-md-between gap-2">
                    <button type="button" className="btn btn-primary" onClick={(e) => { e.preventDefault(); prevStep() }}>
                        <i className="fa fa-chevron-left fa-fw fa-xs" aria-hidden="true" />
                        {"Anterior"}
                    </button>
                    <button type="submit" className="btn btn-degradado" disabled={disableButton}>
                        {"Siguiente"}
                        <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                    </button>
                </div>
            </form>
            <DownloadingModal idBtn="download" target="downloadModal" closeTarget="closeDownloadModal"/>
            <OfflineModal />
        </>
    );
};

export default EigthStep;