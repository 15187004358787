import React, { useEffect, useState } from "react";
import AprobacionEsquema from "./aprobacionList";
import EsquemaHibridoService from "../../services/esquemaHibrido/esquema-hibrido-service";
import styles from "../../resources/css/style-COL.module.css";

export default function EsquemaHibridoAprobacion() {
    const [pendientes, setPendientes] = useState([])
    const [tipos, setTipos] = useState([])
    const [loadingInfo, setLoadingInfo] = useState(true)
    const [errorInfo, setErrorInfo] = useState(false)

    const getInfo = async () => {
        try {
            let response = await EsquemaHibridoService.getPendientes();
            if (
                response !== null &&
                response !== undefined &&
                (response?.statusCode === 200 || response?.status === 200)
            ) {
                setTipos(response?.data.tipoEsquema)
                setPendientes(response?.data.pendientes.sort((a, b) => {
                    if (!a.idSolicitud) return 1; // Mueve las entradas sin fecha al final.
                    if (!b.idSolicitud) return -1; // Mueve las entradas sin fecha al final.

                    return b.idSolicitud - a.idSolicitud
                }));
                setLoadingInfo(false);
            } else {
                setErrorInfo(true)
                setLoadingInfo(false);
            }

        } catch (error) {
            setLoadingInfo(false);
        }
    };

    useEffect(() => {
        getInfo()
    }, [])

    const resetValues = () => {
        getInfo()
    }

    return (
        <div>
            <nav className={`${styles["breadcrumbs"]} ${styles["d-none"]} ${styles["d-lg-block"]}`} aria-label="breadcrumb">
                <ol className={styles["breadcrumb"]}>
                    <li className={styles["breadcrumb-item"]}><a href="#"><i className="fa fa-home" aria-hidden="true"></i> Inicio</a></li>
                    <li className={styles["breadcrumb-item"]}><a href="#">Esquema de Trabajo</a></li>
                    <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">Aprobación de Esquema</li>
                </ol>
            </nav>
            <section className={styles["aprobacion-esquema-hibrido"]}>
                <AprobacionEsquema
                    pendientes={pendientes}
                    resetValues={resetValues}
                    loadingPendientes={loadingInfo}
                    errorPendientes={errorInfo}
                    tipos={tipos}
                />
            </section>
        </div>
    )
}