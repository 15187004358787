import React from 'react';
import { Link, useLocation } from "react-router-dom";

const DetallesSolicitud = () => {
    const { state } = useLocation()
    return (
        <div className="container card-box">
        <div className="button-container">
          <button className="button cancel go-back-btn"><Link to='../solicitudes-vacaciones-arg'><span className="volver-tag">Volver</span></Link></button>
        </div>
        <div className="approver">
          <div className="title">
            {/* <div className="row"> */}
            {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6"> */}
            APROBADOR:  <span className='approver-name'>{state.aprovedName}</span>
          </div>
          {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6"> */}
          <div className="title">
            FECHA SOLICITUD:  <span className='approver-name'>{state.requestDate}</span>
          </div>
          {/* </div> */}
        </div>
  
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
            <div className="fields">
              <label className="label">Fecha inicio</label>
              <input className="form-control" disabled={true} value={state.beginDate} readOnly />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
            <div className="fields">
              <label className="label">Fecha final</label>
              <input className="form-control" disabled={true} value={state.returnDate} readOnly />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
            <div className="fields">
              <label className="label">Fecha anticipo</label>
              <input disabled={true} value={state.advanceDate} readOnly />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="fields">
              <label className="label">Comentario colaborador</label>
              <textarea className="form-control" disabled={true} value={state.applicantComments} readOnly />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="fields">
              <label className="label">Comentario aprobador</label>
              <textarea className="form-control"disabled={true} value={state.aprovedComments} />
            </div>
          </div>
        </div>
      </div>
    )
    
}

export default DetallesSolicitud
