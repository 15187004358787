import React, { useState, useEffect, Children } from "react";
import ModalComponent from "./modal.js";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { formatDate } from "../../services/utiles";

const Section = ({ bancos, direccion, section, hiddenTd, setData }) => {
    const [modalValues, setModalValues] = useState(false);
    //cantidad de filas y columnas de la tabla
    const rowcant = section.rows ? section.rows.length : 0;
    // const columncant = section.headers.filter((header) => header.visible).length + 1;
    if (section.sectionid === "DIRECCIONES") {
        //delete section.rows
        for (let i = 0; i < section.forms.length; i++) {
            if (section.forms[i].formid === "PAIS_DIRECCION") {
                section.forms[i]["values"] = [
                    {
                        label: "Chile",
                        value: "CHL",
                        visible: true,
                    },
                ];
            } else if (section.forms[i].formid === "REGION_DIRECCION") {
                if (direccion && typeof direccion !== "string") {
                    section.forms[i]["values"] = direccion.response.regiones.map((direccion) => {
                        return {
                            label: direccion.name.replaceAll("&n", "ñ").replaceAll("&N", "Ñ"),
                            value: direccion.state,
                            visible: true,
                        };
                    });
                } else {
                    section.forms[i]["values"] = [
                        {
                            label: "",
                            value: "",
                            visible: "",
                        },
                    ];
                }
            } else if (section.forms[i].formid === "PROVINCIA_DIRECCION") {
                section.forms[i].type = "text";
            } else if (section.forms[i].formid === "COMUNA_DIRECCION") {
                let comunas = [];
                if (direccion) {
                    for (let i = 0; i < direccion.response.regiones.length; i++) {
                        for (let j = 0; j < direccion.response.regiones[i].provincias.length; j++) {
                            for (let k = 0; k < direccion.response.regiones[i].provincias[j].comunas.length; k++) {
                                comunas.push(direccion.response.regiones[i].provincias[j].comunas[k]);
                            }
                        }
                    }
                    comunas = comunas.sort((a, b) => {
                        if (a.nombre < b.nombre) return -1;
                        if (a.nombre > b.nombre) return 1;
                        return 0;
                    });
                    section.forms[i]["values"] = comunas.map((comuna) => {
                        return {
                            label: comuna.nombre.replaceAll("&n", "ñ").replaceAll("&N", "Ñ"),
                            value: comuna.city,
                            visible: true,
                        };
                    });
                } else {
                    section.forms[i]["values"] = [
                        {
                            label: "",
                            value: "",
                            visible: "",
                        },
                    ];
                }
            }
        }
    }
    if (section.sectionid === "DATOS_BANCARIOS") {
        let values = [];
        if (bancos !== null && bancos.message !== "API rate limit exceeded") {
            for (let i = 0; i < bancos.length; i++) {
                values.push({
                    label: bancos[i].descr_bank,
                    value: bancos[i].Codigo,
                    visible: true,
                });
            }
            section.forms[1]["values"] = values;
        } else {
            section.forms[1]["values"] = [{}];
        }
    }
    useEffect(() => {
        if (modalValues) {
            setTimeout(() => {
                document.getElementById("modal" + section.sectionid + "btn").click();
            }, 200);
        }
    }, [modalValues]);

    const openModal = (e, action, rowid) => {
        e.preventDefault();
        let values = {
            action: action,
            rowid: rowid,
        };
        setModalValues(values);
    };

    return (
        <>
            <div className="box mb-4">
                <div className="tit-group d-flex justify-content-between">
                    <h2 className="h4 tit-section">{section.title}</h2>
                    {section.editable && (section.maxlength === 0 || section.maxlength > rowcant) ? (
                        <>
                            <Link to="#" onClick={(e) => openModal(e, "add", null)} className="mt-2" data-bs-toggle="modal" data-bs-target="#modal" data-backdrop="static">
                                <i className="fa fa-plus-square-o fa-lg" aria-hidden="true"></i>
                            </Link>
                        </>
                    ) : null}
                </div>
                {
                    <>
                        {section.style === "table" && (
                            <>
                                <table className="table table-even table-section table-borderless align-middle text-uppercase d-none d-md-table">
                                    <thead>
                                        <tr>
                                            {Children.toArray(section.headers.map((head) => head.visible && <th className={`text-left`} key={head.formid} >{head.value}</th>))}
                                            <th className={`text-center`}>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {section.rows &&
                                            Children.toArray(section.rows.map((row) => (
                                                <tr key={section.sectionid + row.row}>
                                                    <>
                                                        {Children.toArray(row.items.map((item, index) => section.headers[index].visible && <td className={`text-left`} key={item.formid}>{hiddenTd ? "*****" : item.type === "dropdown" ? item.description : item.type === "checkbox" && (item.value === "Y" || item.value === "N") ? <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={item.value === "Y" ? true : false} disabled /> : RegExp(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/).test(item.value) ? formatDate(item.value) : item.value}</td>))}
                                                        <td className={`text-center`} key={"btns" + String(Math.random())}>
                                                            {row.editable ? (
                                                                <>
                                                                    <Link to="#" onClick={(e) => openModal(e, "edit", row.row)} className="mt-2" data-bs-toggle="modal" data-bs-target="#modal" data-backdrop="static">
                                                                        <i className="fa fa-pencil fa-fw me-2" aria-hidden="true"></i>
                                                                    </Link>
                                                                </>
                                                            ) : null}
                                                            {row.removable ? (
                                                                <>
                                                                    <Link to="#" onClick={(e) => openModal(e, "del", row.row)} className="mt-2" data-bs-toggle="modal" data-bs-target="#modal" data-backdrop="static">
                                                                        <i className="fa fa-trash-o fa-fw" aria-hidden="true"></i>
                                                                    </Link>
                                                                </>
                                                            ) : null}
                                                        </td>
                                                    </>
                                                </tr>
                                            )))}
                                    </tbody>
                                </table>
                                <div className="data-list-section d-block d-md-none">
                                    {section.rows &&
                                        Children.toArray(section.rows.map((row) => (
                                            <ul className="data-list-section--item list-unstyled mb-0">
                                                {Children.toArray(row.items.map(
                                                    (item, index) =>
                                                        section.headers[index].visible && (
                                                            <li key={item.formid + "movli"}>
                                                                <span key={section.headers[index].formid + "headmov" + index} className="text-uppercase d-block mb-1">
                                                                    <strong>{section.headers[index].value}</strong>
                                                                </span>
                                                                <span id={item.formid} key={item.formid + "mov" + index}>{hiddenTd ? "*****" : item.type === "dropdown" ? item.description : item.type === "checkbox" && (item.value === "Y" || item.value === "N") ? <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={item.value === "Y" ? true : false} disabled /> : RegExp(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/).test(item.value) ? formatDate(item.value) : item.value}</span>
                                                            </li>
                                                        )
                                                ))}
                                                <li key={"btns" + String(Math.random())}>
                                                    <span className="text-uppercase d-block mb-1">
                                                        <strong>Acciones</strong>
                                                    </span>
                                                    {row.editable ? (
                                                        <>
                                                            <Link to="#" onClick={(e) => openModal(e, "edit", row.row)} className="mt-2" data-bs-toggle="modal" data-bs-target="#modal" data-backdrop="static">
                                                                <i className="fa fa-pencil fa-fw me-2" aria-hidden="true"></i>
                                                            </Link>
                                                        </>
                                                    ) : null}
                                                    {row.removable ? (
                                                        <>
                                                            <Link to="#" onClick={(e) => openModal(e, "del", row.row)} className="mt-2" data-bs-toggle="modal" data-bs-target="#modal" data-backdrop="static">
                                                                <i className="fa fa-trash-o fa-fw" aria-hidden="true"></i>
                                                            </Link>
                                                        </>
                                                    ) : null}
                                                </li>
                                            </ul>
                                        )))}
                                </div>
                                <ModalComponent direccion={direccion} section={section} modalVal={modalValues} setModalVal={setModalValues} setData={setData} />
                                <button hidden id={"modal" + section.sectionid + "btn"} data-bs-toggle="modal" data-bs-target={"#modal" + section.sectionid} data-backdrop="static" />
                            </>
                        )}
                        {section.style === "grid" && (
                            <div className="data-list-box">
                                <ul className="list-column-2 text-uppercase list-unstyled">
                                    {section.rows &&
                                        Children.toArray(section.rows.map((row) =>
                                            Children.toArray(row.items.map((item) => (
                                                <li key={item.formid}>
                                                    <span className="data-list-box__title">{item.label}</span>
                                                    <span id={item.formid} visibility={item.visible ? item.visible.toString() : null} className="data-list-box__text">{hiddenTd ? "*****" : item.type === "dropdown" ? item.description : item.type === "checkbox" && (item.value === "Y" || item.value === "N") ? <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={item.value === "Y" ? true : false} disabled /> : RegExp(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/).test(item.value) ? formatDate(item.value) : item.value}</span>
                                                </li>
                                            )))
                                        ))}
                                </ul>
                            </div>
                        )}
                    </>
                }
            </div>
        </>
    );
};

Section.propTypes = {
    section: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    setData: PropTypes.func.isRequired,
    direccion: PropTypes.string.isRequired,
    hiddenTd: PropTypes.bool.isRequired,
    bancos: PropTypes.array.isRequired,
};

export default Section;