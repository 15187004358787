import { Children, FC } from "react";
import { SelectLinkProps } from "../../../../../../utilities/interfaces";

const SelectLinks:FC<SelectLinkProps> = ({arrayLinks, setArrayLinks}) => {
  
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        setArrayLinks((prev) => {
            const updTemp = [...prev];
            updTemp[index] = { ...updTemp[index], id: index, url: event.target.value };
            return updTemp;
        });
    };
  
    const handleAddInput = () => {
        setArrayLinks((prev) => [...prev, {  url: '' }]);
    };

    const handleDeleteLink = (id: number | undefined) => {
        setArrayLinks(prevLinks => prevLinks.filter(link => link.id !== id));
    }
   
    return(
        <>
            <div className="border-bottom border-2 py-4" id="divVideos">
                <div className="tit-group d-flex justify-content-between col-md-8">
                    <h2 className="h4 tit-section">Videos</h2>
                    <a onClick={handleAddInput} className="mt-2 btnLink"><i className="fa fa-plus-square-o fa-lg fa-fw" title="Añadir video" aria-hidden="true"></i></a>
                </div>
                {Children.toArray(arrayLinks.map((element, index)=>{
                    if (element) {
                            return (
                                <div key={index} className="row w-auto align-items-center my-2">
                                    <div className="col-md-2">
                                        <label className="form-label mb-2 mb-md-0">URL</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input className="form-control" type="text" value={element.url} placeholder="Ingresar url de video embebido" onChange={(e)=>{
                                            handleInputChange(e, index)
                                        }}/>
                                        
                                    </div>
                                    <div className="col-md-4">
                                        <a id={"trash"} className="btnLink" onClick={(e) => { handleDeleteLink(element.id)}}>
                                                <i className="fa fa-trash-o fa-fw fa-lg ms-4" title="Eliminar Archivo Adjunto" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            )
                        }
                    }))}
            </div>        
        </>
    )
}

export default SelectLinks