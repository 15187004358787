import React, { Children, FC, useState } from "react";
import { MyModel as MyModelType, MyModelProps } from "../../utilities/interfaces";
import * as A from "fp-ts/Array";
import * as S from "fp-ts/string";
import { pipe } from "fp-ts/lib/function";

const MyModel : FC<MyModelProps> = ({ data }) => {
    const years = pipe(
        data.myModel,
        A.map((mm) => mm.asignationYear),
        A.uniq(S.Eq),
        A.sort(S.Ord),
        A.reverse
    );
    const [modelsByYear, setModelsByYear] = useState<MyModelType[]>(pipe(
        data.myModel,
        A.filter((mm) => mm.asignationYear === years[0])
    ));

    return (
        data.valid === "1" ?
            <div className="box mb-4">
                <div className="tit-group d-flex justify-content-between">
                    <h2 className="h4 tit-section">Mi Modelo</h2>
                    <div>
                        <div className="input-group">
                            <select
                                className="form-select"
                                onChange={(e) => pipe(
                                    data.myModel,
                                    A.filter((mm) => mm.asignationYear === e.target.value),
                                    setModelsByYear
                                )}
                            >
                                <option disabled value="">Seleccione</option>
                                {Children.toArray(years.map((m, i) => {
                                    return (
                                        <option value={m} selected={i === 0}>{m}</option>
                                    )
                                }))}
                            </select>
                        </div>
                    </div>
                </div>
                <table className="table table-even-acordeon table-section table-th-border table-borderless align-middle d-none d-md-table">
                    <thead>
                        <tr>
                            <th>Tipo de Modelo</th>
                            <th>Detalle</th>
                            <th>Área</th>
                            <th className="text-center">Carta</th>
                            <th>Detalle KPI</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Children.toArray(modelsByYear.map((m, i) => {
                            return (
                                <>
                                    <tr>
                                        <td>{m.modelDescr}</td>
                                        <td>
                                            {Children.toArray(m.asignationIndicators.sort((a, b)=> parseInt(b.indicatorWeight) - parseInt(a.indicatorWeight)).map((indicator, idx, indArr) => {
                                                return (
                                                    <>
                                                        <span className="color-primary">{indicator.indicatorCode}</span>
                                                        <span className="color-orange">{" = "}</span>
                                                        <span className="color-normal">{`${indicator.indicatorWeight}%`}</span>
                                                        {idx < indArr.length - 1 && <span className="color-orange">{" + "}</span>}
                                                    </>
                                                )
                                            }))}
                                        </td>
                                        <td>{m.allDependencies ? "Aplica a toda su área" : ""}</td>
                                        <td className="text-center">
                                            <input type="checkbox" className="form-check-input me-2 h5" checked={m.bondSent} readOnly disabled={!m.bondSent}/>
                                        </td>
                                        <td className="text-center">
                                            {m.asignationIndicators.some((a) => a.kpi.length !== 0) &&
                                                <a className={`btn btn-link btn-sm collapsed`} href="#" data-bs-toggle="collapse" data-bs-target={`#showInfo${i}`} aria-expanded="false" aria-controls={`showInfo${i}`}>
                                                    Ver más
                                                </a>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-0" colSpan={5}>
                                            <div className="accordion accordion-flush">
                                                <div className="accordion-item">
                                                    <div id={`showInfo${i}`} className="accordion-collapse collapse" aria-labelledby={`showInfo${i}`}>
                                                        <div className="data-list-box p-3 pt-0">
                                                            {m.asignationIndicators.some((a) => a.kpi.length !== 0) && Children.toArray((m.asignationIndicators.find((a) => a.kpi.length !== 0))?.kpi.map((k) => {
                                                                return (
                                                                    <ul className="list-column-2 list-unstyled m-0">
                                                                        <li className="d-block">
                                                                            <span className="data-list-box__title d-block mb-1">Nombre KPI</span>
                                                                            <span className="data-list-box__text">{k.kpiName}</span>
                                                                        </li>
                                                                        <li className="d-block">
                                                                            <span className="data-list-box__title d-block mb-1">Peso</span>
                                                                            <span className="data-list-box__text">{`${k.kpiWeight}%`}</span>
                                                                        </li>
                                                                    </ul>
                                                                )
                                                            }))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            )
                        }))}
                    </tbody>
                </table>
                <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
                    <div className="accordion-item">
                        {Children.toArray(modelsByYear.map((m, i) => {
                            return (
                                <>
                                    <h2 className="accordion-header">
                                        <button className={`accordion-button${i !== 0 ? " collapsed": ""}`} type="button" data-bs-toggle="collapse" data-bs-target={`#accordion${i}`} aria-expanded={`${i === 0}`} aria-controls={`accordion${i}`}>
                                            <strong>Tipo de Modelo</strong>{` ${m.modelDescr}`}
                                        </button>
                                    </h2>
                                    <div id={`accordion${i}`} className={`accordion-collapse collapse${i === 0 ? " show": ""}`} aria-labelledby={`accordion${i}`}>
                                        <div className="accordion-body p-0">
                                            <div className="data-list-box">
                                                <ul className="data-list-section--item list-unstyled">
                                                    <li>
                                                        <span className="data-list-box__title">Detalle</span>
                                                        <span className="data-list-box__text">
                                                        {Children.toArray(m.asignationIndicators.sort((a, b)=> parseInt(b.indicatorWeight) - parseInt(a.indicatorWeight)).map((indicator, idx, indArr) => {
                                                            return (
                                                                    <>
                                                                        <span className="color-primary">{indicator.indicatorCode}</span>
                                                                        <span className="color-orange">{" = "}</span>
                                                                        <span className="color-normal">{`${indicator.indicatorWeight}%`}</span>
                                                                        {idx < indArr.length - 1 && <span className="color-orange">{" + "}</span>}
                                                                    </>
                                                                )
                                                            }))}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title">Área</span>
                                                        <span className="data-list-box__text">{m.allDependencies ? "Aplica a toda su área" : ""}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title">Carta</span>
                                                        <span className="data-list-box__text"><input type="checkbox" className="form-check-input me-2 h5" checked={m.bondSent} readOnly disabled={!m.bondSent}/></span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title">Detalle KPI</span>
                                                        <span className="data-list-box__text">
                                                            {m.asignationIndicators.some((a) => a.kpi.length !== 0) &&
                                                                <a className={`btn btn-link btn-sm collapsed`} href="#" data-bs-toggle="collapse" data-bs-target={`#showInfoMobile${i}`} aria-expanded="false" aria-controls={`showInfoMobile${i}`}>
                                                                    Ver más
                                                                </a>
                                                            }
                                                        </span>
                                                    </li>
                                                </ul>
                                                {m.asignationIndicators.some((a) => a.kpi.length !== 0) &&
                                                    <div className="accordion accordion-flush">
                                                        <div className="accordion-item">
                                                            <div id={`showInfoMobile${i}`} className="accordion-collapse collapse" aria-labelledby={`showInfo${i}`}>
                                                                <div className="data-list-box p-3 pt-0">
                                                                    {Children.toArray((m.asignationIndicators.find((a) => a.kpi.length !== 0))?.kpi.map((k) => {
                                                                        return (
                                                                            <ul className="list-column-2 list-unstyled m-0">
                                                                                <li className="d-block">
                                                                                    <span className="data-list-box__title d-block mb-1">Nombre KPI</span>
                                                                                    <span className="data-list-box__text">{k.kpiName}</span>
                                                                                </li>
                                                                                <li className="d-block">
                                                                                    <span className="data-list-box__title d-block mb-1">Peso</span>
                                                                                    <span className="data-list-box__text">{`${k.kpiWeight}%`}</span>
                                                                                </li>
                                                                            </ul>
                                                                        )
                                                                    }))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }))}
                    </div>
                </div>
            </div>
        :
        <div className="box mb-4">
            {data.status}
        </div>
    );
};

export default MyModel;