import React, { useEffect, useState } from 'react';
import { BsExclamationCircle } from "react-icons/bs";
import DetalleOtrosEstudios from './detalle-otros-estudios';
import ModificarOtrosEstudios from './modificar-otros-estudios';
import AgregarOtrosEstudios from './agregar-otros-estudios';
import OtrosEstudiosServices from '../../services/otros estudios/otros-estudios-service';
import { Link } from 'react-router-dom';
import styles from "../../resources/css/style-COL.module.css";


function OtrosEstudios() {
    const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembe"]
    const [subjectAreas, setSubjectAreas] = useState([])
    const [, setLoadingSubjectAreas] = useState(false)
    const [countries, setCountries] = useState([])
    const [infoStudy, setInfoStudy] = useState({})
    const [infoStudies, setInfoStudies] = useState([])
    const [loadingStudies, setLoadingStudies] = useState(false)
    const [, setNoDataStudies] = useState(false)
    const [id, setId] = useState(null)
    const [idEdit, setIdEdit] = useState(null)
    const [displayDetail, setDisplayDetail] = useState(false)
    const [displayEdit, setDisplayEdit] = useState(false)
    const [displayAdd, setDisplayAdd] = useState(false)
    const seeStudy = (e, id) => {
        e.preventDefault()
        setId(id)
        setDisplayDetail(true)
        setDisplayEdit(false)
        setDisplayAdd(false)
    }

    const addStudy = () => {
        setDisplayDetail(false)
        setDisplayEdit(false)
        setDisplayAdd(true)
    }

    const changeDisplay = (d, idx, infoStudy) => {
        setInfoStudy(infoStudy)
        setIdEdit(idx)
        setDisplayEdit(d)
        setDisplayAdd(false)
        setDisplayDetail(false)
    }

    const reloadAcademicStudies = () => {
        setDisplayEdit(false)
        setDisplayAdd(false)
        setDisplayDetail(false)
        setLoadingStudies(true)
        OtrosEstudiosServices.GetOtherStudies(setInfoStudies, setLoadingStudies, setNoDataStudies)
    }

    useEffect(() => {
        setLoadingStudies(true)
        OtrosEstudiosServices.GetOtherStudies(setInfoStudies, setLoadingStudies, setNoDataStudies)
        OtrosEstudiosServices.GetCountries(setCountries)
        OtrosEstudiosServices.GetSubjectAreas(setSubjectAreas,setLoadingSubjectAreas)
    }, [])
    return (
        <>
            <nav className={`${styles["breadcrumbs"]} ${styles["d-none"]} ${styles["d-lg-block"]}`} aria-label="breadcrumb">
                <ol className={styles["breadcrumb"]}>
                    <li className={styles["breadcrumb-item"]}>
                        <Link to="/inicio">
                            <i className="fa fa-home" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className={styles["breadcrumb-item"]}>Actualización de Información</li>
                    <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">Otros Estudios</li>
                    {displayEdit && <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">Modificar Estudios</li>}
                    {displayAdd && <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">Agregar Estudios</li>}
                </ol>
            </nav>
           <section className={styles["otros-estudios"] + " " + "cencosud-col"}>
            <h1>Otros Estudios</h1>
            {!loadingStudies 
            ?
            <>
                <div className={`${styles["box"]} ${styles["mb-4"]}`}>
                    <div className={`${styles["tit-group"]} ${styles["d-flex"]} ${styles["justify-content-between"]}`}>
                        <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Detalle</h2>
                        <Link to="#" onClick={addStudy} className={`${styles["h5"]} ${styles["mt-2"]} ${styles["fl-normal"]}`}><span className={`${styles["d-none"]} ${styles["d-sm-inline-block"]}`}>Agregar estudio</span><i className={`${styles["fa"]} ${styles["fa-plus-square-o"]} ${styles["fa-lg"]} ${styles["ms-2"]} `} aria-hidden="true"></i></Link>
                    </div>

                    <p>Datos Obligatorios <span className={styles["color-red"]}>*</span></p>
                    {infoStudies.length !== 0 ? <>
                    <div className={styles["table-responsive"]}>
                        <table className={`${styles["table"]} ${styles["table-even"]} ${styles["table-section"]} ${styles["table-borderless"]} ${styles["d-none"]} ${styles["d-md-table"]}`}>
                            <thead>
                                <tr>
                                    <th>Título Académico</th>
                                    <th>Lugar de Estudios</th>
                                    <th>Fecha</th>
                                    <th>Ver</th>
                                </tr>
                            </thead>
                            <tbody>
                                {infoStudies && infoStudies.map( i => {
                                    return (<tr key={i.id}>
                                    <td>{i.program_name}</td>
                                    <td>{i.institution}</td>
                                    <td>{i.study_end_date !== "" ? months[i.study_end_date.split("-")[1]-1] + " " + new Date(i.study_end_date).getFullYear().toString() : "En curso"}</td>
                                    <td>
                                        <Link to="#" onClick={e => seeStudy(e,i.id)}><i className={`${styles["fa"]} ${styles["fa-eye"]} ${styles["fa-fw"]} ${styles["fa-lg"]}`} aria-hidden="true"></i></Link>
                                    </td>
                                </tr>)})}
                            </tbody>
                        </table>
                    </div>
                    <div className={`accordion accordion-flush accordion-light accordion-table ${styles["d-md-none"]}`}>
                        {infoStudies && infoStudies.map( (i, idx) => {
                                    return (<div key={i.id} className="accordion-item">
                            <h2 className="accordion-header" id="accordion">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-item-${idx}`} aria-expanded="false" aria-controls={`accordion-item-${idx}`}>
                                    <strong>Título Académico</strong> {i.program_name}
                                </button>
                            </h2>
                            <div id={`accordion-item-${idx}`} className="accordion-collapse collapse" aria-labelledby="accordion">
                                <div className={`accordion-body ${styles["p-0"]}`}>
                                    <div className={`${styles["data-list-box"]} ${styles["data-list-box-even"]}`}>
                                        <ul className={`${styles["data-list-section--item"]} ${styles["list-unstyled"]}`}>
                                            <li>
                                                <span className={styles["data-list-box__title"]}>Lugar de Estudios</span>
                                                <span className={styles["data-list-box__text"]}>{i.institution}</span>
                                            </li>
                                            <li>
                                                <span className={styles["data-list-box__title"]}>Fecha</span>
                                                <span className={styles["data-list-box__text"]}>{i.study_end_date !== "" ? months[i.study_end_date.split("-")[1]-1] + " " + new Date(i.study_end_date).getFullYear().toString() : "En curso"}</span>
                                            </li>
                                            <li>
                                                <span className={styles["data-list-box__title"]}>Ver</span>
                                                <span className={styles["data-list-box__text"]}>
                                                    <Link to="#" onClick={e => seeStudy(e,i.id)}><i className={`${styles["fa"]} ${styles["fa-eye"]} ${styles["fa-fw"]} ${styles["fa-lg"]}`} aria-hidden="true"></i></Link>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>)})}
                    </div></> : <div className={`${styles["alert"]} ${styles["alert-info"]} ${styles["alert-dismissible"]} ${styles["fade"]} ${styles["show"]} ${styles["text-center"]}`} role="alert">
                        <BsExclamationCircle />  No se cargaron estudios
                    </div>}
                </div>
                {displayDetail && <DetalleOtrosEstudios display={displayDetail} subjectAreas={subjectAreas} countries={countries} changeDisplay={changeDisplay} id={id} reloadAcademicStudies={reloadAcademicStudies}/>}
                {displayEdit && <ModificarOtrosEstudios display={displayEdit} subjectAreas={subjectAreas} countries={countries} id={idEdit} infoStudy={infoStudy} reloadAcademicStudies={reloadAcademicStudies}/>}
                {displayAdd && <AgregarOtrosEstudios display={displayAdd} subjectAreas={subjectAreas} changeDisplay={changeDisplay} countries={countries} reloadAcademicStudies={reloadAcademicStudies} />}
            </>   
            
            :  <div data-testid="loading" className={styles["full-spinner"]}>
                 <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
                </div>}
                </section>
        </>
    )
}

export default OtrosEstudios;