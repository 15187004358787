const baseEndpoint = process.env.REACT_APP_BACKEND_URL_GLOBAL
const endPoint2 = process.env.REACT_APP_BACKEND_URL

const urls = {
  imgSrc: `${baseEndpoint}/uploads`,
  search: `${baseEndpoint}/api/articles/v2/search/`,
  categoryListV2: `${baseEndpoint}/api/categories/v2/`,
  categoryList: `${baseEndpoint}/api/categories/`,
  articlesList: `${baseEndpoint}/api/articles/v2/`,
  subCategories: `${baseEndpoint}/api/sub-categories/`,
  feedback: `${baseEndpoint}/api/feedbacks/v2`,
  userInfo: `${endPoint2}/first-login`,
  userHistory: `${baseEndpoint}/api/user-histories/v2/`
}

const querys = {
  articlesByCategory: '?populate[0]=articles',
  categoryIcons: '?populate=icon',
  subCategoriesAndArticlesByCategoryID: '?populate[0]=sub_categories&populate[sub_categories][populate][0]=articles',
  subCategoriesByCategoryID: '?populate=sub_categories',
  subCategoryArticlesAndCategory: '?populate[0]=category&populate[1]=articles',
  articleRelationsInfo: '&populate[0]=sub_category&populate[1]=sub_category.category',
  articlesFilterDatep1: '?populate[0]=sub_category&populate[1]=sub_category.category&filters[enabled][$eq]=true&filters[$or][0][expiration_date][$gte]=',
  articlesFilterDatep2: '&filters[$or][1][expiration_date][$null]=true',
  articlesSearchTop5: '?suggestion=true',
  subCategoryFilter1: '[filters][$or][0][expiration_date][$gte]=',
  subCategoryFilter2: '&populate[articles][filters][$or][1][expiration_date][$null]=true&&populate[articles][filters][enabled][$eq]=true'
}

export default { urls, querys }
