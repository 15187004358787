import cencologo from "../../resources/images/cencosud-logo.svg";
import PropTypes from "prop-types";
import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../context";
import { decrypt } from "../../services/utiles";
import defaultImg from "../../resources/images/default_profile_foto.png";
import Notifications from "../../notifications-arg/notifications";

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        const updateSize = () => {
            setSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
};

const Header = (props) => {
    const { active, setActive, shrink, setShrink, firstLoginData, foto, boss, step, appState, isPublicUrl } = useContext(Context);
    const [expDate, setExpDate] = useState(null);
    const diffDays = parseInt(sessionStorage.getItem("expDate"));
    const ficha = JSON.parse(sessionStorage.getItem("ficha"));
    const [width,] = useWindowSize();
    const showAdminBtn = appState && appState.map((item) => item.codigo).includes("PADM");
    const urlBase = window.location.pathname.split("/");
    let navigate = useNavigate();

    useEffect(() => {
        if (shrink) {
            document.body.classList.add("shrink");
        } else {
            document.body.classList.remove("shrink");
        }
    }, [shrink]);

    useEffect(() => {
        if (diffDays !== null && diffDays !== "") {
            setExpDate(34 - diffDays);
        }
    }, [diffDays]);

    let inputSearch = document.getElementById("inputSearch");
    let inputSearch2 = document.getElementById("inputSearch2");
    let box_search = document.getElementById("box-search");

    const redireccionar = (ruta) => {
        navigate("/" + ruta);
        box_search.style.display = "none";
        inputSearch.value = "";
        inputSearch2.value = "";
    };

    const Buscador = () => {
        return (
            <>
                <ul className="list-group" style={{ padding: "inherit" }}>
                    <li type="button" onClick={() => redireccionar("inicio")} className="list-group-item list-group-item-action">
                        <a>
                            {" "}
                            Inicio <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>
                    <li type="button" onClick={() => redireccionar("noticias")} className="list-group-item list-group-item-action">
                        <a>
                            {" "}
                            Noticias <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>
                    <li type="button" onClick={() => redireccionar("datoslaborales")} className="list-group-item list-group-item-action">
                        <a>
                            {" "}
                            Datos Laborales <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>
                    <li type="button" onClick={() => redireccionar("datospersonales")} className="list-group-item list-group-item-action">
                        <a>
                            {" "}
                            Datos Personales <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>
                    <li type="button" onClick={() => redireccionar("liquidacion")} className="list-group-item list-group-item-action">
                        <a>
                            {" "}
                            Liquidaciones <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>
                    <li type="button" onClick={() => redireccionar("remuneracion")} className="list-group-item list-group-item-action">
                        <a>
                            {" "}
                            Anexos Y Remuneracion <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>
                    <li type="button" onClick={() => redireccionar("ausencias")} className="list-group-item list-group-item-action">
                        <a>
                            {" "}
                            Ausencias <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>

                    <li type="button" onClick={() => redireccionar("solicitar-vacaciones")} className="list-group-item list-group-item-action">
                        <a>
                            {" "}
                            Vacaciones / Solicitar Vacaciones <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>
                    <li type="button" onClick={() => redireccionar("reemplazo-vacaciones")} className="list-group-item list-group-item-action">
                        <a>
                            {" "}
                            Vacaciones / Mi Remplazo <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>
                    <li type="button" onClick={() => redireccionar("ver-cartola")} className="list-group-item list-group-item-action">
                        <a>
                            {" "}
                            Vacaciones / Cartola De Vacaciones <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>
                    <li type="button" onClick={() => redireccionar("ver-detalle-vacaciones")} className="list-group-item list-group-item-action">
                        <a>
                            {" "}
                            Vacaciones / Resumen Dias Tomados Papeleta <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>

                    <li type="button" onClick={() => redireccionar("miequipo")} className="list-group-item list-group-item-action">
                        <a>
                            {" "}
                            Geo Victorias <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>
                    <li type="button" onClick={() => redireccionar("evaluaciondesempeno")} className="list-group-item list-group-item-action">
                        <a>
                            {" "}
                            Ev. De Desempeño <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>
                    <li type="button" onClick={() => redireccionar("mis-documentos")} className="list-group-item list-group-item-action">
                        <a>
                            {" "}
                            Mis Documentos <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>
                    <li type="button" onClick={() => redireccionar("miequipo")} className="list-group-item list-group-item-action">
                        <a>
                            {" "}
                            Mi Equipo <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>

                    <li type="button" onClick={() => redireccionar("mis-solicitudes")} className="list-group-item list-group-item-action">
                        <a>
                            {" "}
                            Mis Solicitudes <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>
                    <li type="button" onClick={() => redireccionar("mis-solicitudes/DEF")} className="list-group-item list-group-item-action">
                        <a>
                            {" "}
                            Mis Solicitudes / Bono Por Defuncion <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>
                    <li type="button" onClick={() => redireccionar("mis-solicitudes/MAT")} className="list-group-item list-group-item-action">
                        <a>
                            {" "}
                            Mis Solicitudes / Bono Por Matrimonio <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>
                    <li type="button" onClick={() => redireccionar("mis-solicitudes/NAC")} className="list-group-item list-group-item-action">
                        <a>
                            {" "}
                            Mis Solicitudes / Bono Por Nacimiento <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>
                    <li type="button" onClick={() => redireccionar("mis-solicitudes/anticipos")} className="list-group-item list-group-item-action">
                        <a>
                            {" "}
                            Mis Solicitudes / Anticipo <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>

                    <li type="button" onClick={() => redireccionar("mis-beneficios/all")} className="list-group-item list-group-item-action">
                        <a>
                            Mis Beneficios <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>
                    <li type="button" className="list-group-item list-group-item-action">
                        <a>
                            {" "}
                            Codigo De Etica <i className="fa fa-search" style={{ float: "right" }}></i>
                        </a>
                    </li>

                    {/*  Modulos Admin */}
                    {decrypt(boss) == "Y" ? (
                        <>
                            <li type="button" onClick={() => redireccionar("vacacion")} className="list-group-item list-group-item-action">
                                <a>
                                    {" "}
                                    Vacaciones <i className="fa fa-search" style={{ float: "right" }}></i>
                                </a>
                            </li>
                            <li type="button" onClick={() => redireccionar("admin/aprobar-vacaciones")} className="list-group-item list-group-item-action">
                                <a>
                                    {" "}
                                    Vacaciones / Aprobar Vacaciones <i className="fa fa-search" style={{ float: "right" }}></i>
                                </a>
                            </li>
                        </>
                    ) : (
                        <li type="button" onClick={() => redireccionar("vacaciones")} className="list-group-item list-group-item-action">
                            <a>
                                {" "}
                                Vacaciones <i className="fa fa-search" style={{ float: "right" }}></i>
                            </a>
                        </li>
                    )}
                </ul>

                {/*        <li><a href="#"><i className="fa fa-comments-o fa-fw"></i>Geo Victoria</a></li>

                <li><a href="#"><i className="fa fa-comments-o fa-fw"></i>Evaluacion De Desempeño</a></li>

                
                <li><a href="#"><i className="fa fa-usd fa-fw"></i>Liquidaciones</a></li>
                <li><a href="#"><i className="fa fa-comments-o fa-fw"></i>Evaluacion De Desempeño</a></li> */}
            </>
        );
    };

    document.onclick = ocultarFiltro;

    function buscador_interno() {
        let filter = inputSearch.value.toUpperCase();
        let li = box_search.getElementsByTagName("li");

        //Recorriendo elementos a filtrar mediante los "li"
        for (let i = 0; i < li.length; i++) {
            let a = li[i].getElementsByTagName("a")[0];
            let textValue = a.textContent || a.innerText;
            if (textValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
                box_search.style.display = "block";
                if (inputSearch.value === "") {
                    box_search.style.display = "none";
                }
            } else {
                li[i].style.display = "none";
            }
        }
    }

    function buscador_interno2() {
        let filter = inputSearch2.value.toUpperCase();
        let li = box_search.getElementsByTagName("li");

        //Recorriendo elementos a filtrar mediante los "li"
        for (let i = 0; i < li.length; i++) {
            let a = li[i].getElementsByTagName("a")[0];
            let textValue = a.textContent || a.innerText;

            if (textValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
                box_search.style.display = "block";
                if (inputSearch2.value === "") {
                    box_search.style.display = "none";
                }
            } else {
                li[i].style.display = "none";
            }
        }
    }
    function ocultarFiltro(e) {
        if (e == null) {
            // Si hac click un elemento, lo leemos
        } else {
            // Si ha hecho click sobre un destino, lo leemos
            if (e.target.id != "inputSearch") {
                if (box_search != null) {
                    box_search.style.display = "none";
                }
                if (document.getElementById("inputSearch") != null) {
                    document.getElementById("inputSearch").value = "";
                }
                if (document.getElementById("inputSearch2") != null) {
                    document.getElementById("inputSearch2").value = "";
                }
            }
        }
    }

    useEffect(() => {
        if (window.location.pathname !== "/encuestas" && !(urlBase[1] && urlBase[1].includes("-dt")) && appState && window.location.pathname !== "/ficha-ingreso" && firstLoginData?.NEWHIRING !== "Y") {
            document.getElementById("inputSearch").value = "";
            document.getElementById("inputSearch2").value = "";

            if (box_search) {
                box_search.style.display = "none";
            }
            if (width <= 991) {
                if (width !== 0) {
                    if (width <= 768) {
                        box_search.style.right = "5%";
                    } else {
                        box_search.style.right = "40px";
                    }
                    box_search.style.width = "90%";
                }
            } else {
                box_search.style.right = "auto";
                box_search.style.width = "50%";
            }
        }
    }, [width]);

    const closeSubItems = () =>{

        const navFolder = document.getElementsByClassName("nav-folder-cont");
        for (let i = 0; i < navFolder.length; i++) {
            let listClass =  navFolder[i].classList;
            if(listClass.contains('show')){
                navFolder[i].className = "collapse nav-folder-cont"
            }
        }
    }

    return (window.location.pathname === "/ficha-ingreso" ?
        <header className="header p-3 border-bottom">
            <div className="container">
                <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                    {step !== 0 ? (
                        <a className="btn btn-primary d-inline-block d-lg-none me-3 navbar-btn" id="sidebarCollapse" role="button" href="#" onClick={(e) => { e.preventDefault(); () => setActive(!active) }}>
                            <i className="fa fa-bars fa-lg" aria-hidden="true" />
                        </a>
                    ) : null}
                    <a className="logo-cencosud me-3" href="#" onClick={(e) => { e.preventDefault(); navigate("/ficha-ingreso") }}><img src={cencologo} width="90" alt="Ir al inicio" /></a>
                    <div className="menu-h-1 d-flex col-lg-auto me-auto mb-2 mb-md-0">
                        {step !== 0 ? (
                            <a className="btn btn-primary d-none d-lg-inline-block me-3" href="#" onClick={(e) => { e.preventDefault(); () => shrink(!shrink) }}>
                                <span className="shrink-btn">
                                    <i className="fa fa-bars fa-lg" aria-hidden="true" />
                                </span>
                            </a>
                        ) : null}
                        <div className="input-group d-none d-lg-block">
                            {ficha !== null ? (
                                <>
                                    <div className="text-white text-uppercase">{ficha.sheet.firstStep.personalData.firstname + " " + ficha.sheet.firstStep.personalData.lastname + " " + ficha.sheet.firstStep.personalData.secondlastname}</div>
                                    <div className="text-white text-uppercase">{ficha.descr_jobcode}</div>
                                </>
                            ) : null}
                        </div>
                    </div>
                    <div className="menu-h-2 d-flex text-end align-items-center">
                        <a
                            className="btn btn-sign-out"
                            href="/"
                            onClick={(e) => {
                                e.preventDefault();
                                props.signOut();
                            }}
                        >
                            <i className="fa fa-sign-out fa-lg" aria-hidden="true" />
                        </a>
                    </div>
                    <div className="input-group d-block d-lg-none mt-3 text-center">
                        {ficha !== null ? (
                            <>
                                <div className="text-white text-uppercase">{ficha.sheet.firstStep.personalData.firstname + " " + ficha.sheet.firstStep.personalData.lastname + " " + ficha.sheet.firstStep.personalData.secondlastname}</div>
                                <div className="text-white text-uppercase">{ficha.descr_jobcode}</div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        </header>
        : urlBase[1] && urlBase[1].includes("-dt") ?
            <header className="header p-3 border-bottom" id="header">
                <div className="container">
                    <div className="d-flex flex-wrap align-items-center justify-content-between justify-content-lg-start">
                        <a className="btn btn-primary d-inline-block d-lg-none me-3 navbar-btn" id="sidebarCollapse" role="button" data-bs-target="#sidebar" onClick={() => setActive(!active)}>
                            <i className="fa fa-bars fa-lg" aria-hidden="true"></i>
                        </a>
                        <Link to="/carpeta-digital-dt" className="logo-cencosud me-3" ><img src={cencologo} width="90" alt="Ir a carpeta digital"></img></Link>
                        <div className="menu-h-1 d-flex col-lg-auto me-0 me-auto mb-2 mb-md-0">
                            <a className="btn btn-primary d-none d-lg-inline-block me-3" onClick={() => setShrink(!shrink)}>
                                <span className="shrink-btn">
                                    <i className="fa fa-bars fa-lg" aria-hidden="true"></i>
                                </span>
                            </a>
                        </div>
                        <div className="menu-h-2 d-flex text-end align-items-center">
                            <a className="btn btn-sign-out" onClick={(e) => {
                                e.preventDefault();
                                props.signOut();
                                // logout()
                            }}><i className="fa fa-sign-out fa-lg" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </header>
            :
            isPublicUrl ?
                <header className="header p-3 border-bottom" id="header">
                    <div className="container">
                        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                            {/* <!-- logo cencosud --> */}
                            <Link className="logo-cencosud me-3" to="">
                                <img src={cencologo} width="90" alt="Ir al inicio" />
                            </Link>

                        </div>
                    </div>
                </header>
                :
                <header className="header p-3 border-bottom" id="header">
                    <div className="container">
                        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                            {/* <!-- boton para mobile, despliega menú --> */}
                            <a className="btn btn-primary d-inline-block d-lg-none me-3 navbar-btn" id="sidebarCollapse" role="button" data-bs-target="#sidebar" onClick={() => setActive(!active)}>
                                <i className="fa fa-bars fa-lg" aria-hidden="true"></i>
                            </a>
                            {/* <!-- logo cencosud --> */}
                            <Link className="logo-cencosud me-3" to="/inicio">
                                <img src={cencologo} width="90" alt="Ir al inicio" />
                            </Link>
                            <div className="menu-h-1 d-flex col-lg-auto me-auto mb-2 mb-md-0">
                                {/* <!-- boton para desktop, esconde texto --> */}
                                <a className="btn btn-primary d-none d-lg-inline-block me-3" onClick={() => {
                                    setShrink(!shrink);
                                    closeSubItems();
                                }}>
                                    <span className="shrink-btn">
                                        <i className="fa fa-bars fa-lg" aria-hidden="true"></i>
                                    </span>
                                </a>
                                {/* <!-- Buscador desktop --> */}
                                <div className="notification collapse mb-3" id="box-search" style={{ zIndex: "4", width: "50%", padding: "inherit", fontSize: "17px" }}>
                                    <div className="notification-group">
                                        <Buscador></Buscador>
                                    </div>
                                </div>
                                <div className="input-group d-none d-lg-flex">
                                    <div className="form-outline">
                                        <input type="search" onChange={() => { buscador_interno(); }} id="inputSearch" className="form-control globalsearcher" placeholder="Buscar..." aria-label="Buscar"></input>
                                    </div>
                                    <span className="btn btn-primary">
                                        <i className="fa fa-search"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="menu-h-2 d-flex text-end align-items-center">
                                {/* <!-- Clave--> */}

                                {expDate !== undefined && expDate !== null && expDate <= 7 &&
                                    <small className="color-white me-3 me-lg-4 d-none d-xl-inline-block">
                                        <i className="fa fa-exclamation-triangle fa-lg me-1" aria-hidden="true"></i>
                                        {expDate === 0 && "Su clave caduca hoy"}
                                        {expDate === 1 && `Su clave caduca mañana`}
                                        {expDate > 1 && `Su clave caduca en ${expDate} días`}
                                    </small>
                                }
                                {/* <!-- Notificaciones--> */}
                                <Notifications />
                                {
                                    showAdminBtn &&
                                    <Link className="btn me-3 me-lg-4" to="/admin/panel-administracion">
                                        <i className="fa fa-cog fa-lg" aria-hidden="true"></i>
                                    </Link>
                                }
                                {/* <!-- Perfil --> */}
                                <div className="profile me-2 me-lg-4">
                                    <a className="text-decoration-none">
                                        {foto.length > 0? (
                                            <>
                                                {foto[0].photo === "iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAsxJREFUeF7t0jENAAAMw7CVP+nByOMSqBR5ZwqEBRZ+u1bgAIQgLQBgmt85gAykBQBM8zsHkIG0AIBpfucAMpAWADDN7xxABtICAKb5nQPIQFoAwDS/cwAZSAsAmOZ3DiADaQEA0/zOAWQgLQBgmt85gAykBQBM8zsHkIG0AIBpfucAMpAWADDN7xxABtICAKb5nQPIQFoAwDS/cwAZSAsAmOZ3DiADaQEA0/zOAWQgLQBgmt85gAykBQBM8zsHkIG0AIBpfucAMpAWADDN7xxABtICAKb5nQPIQFoAwDS/cwAZSAsAmOZ3DiADaQEA0/zOAWQgLQBgmt85gAykBQBM8zsHkIG0AIBpfucAMpAWADDN7xxABtICAKb5nQPIQFoAwDS/cwAZSAsAmOZ3DiADaQEA0/zOAWQgLQBgmt85gAykBQBM8zsHkIG0AIBpfucAMpAWADDN7xxABtICAKb5nQPIQFoAwDS/cwAZSAsAmOZ3DiADaQEA0/zOAWQgLQBgmt85gAykBQBM8zsHkIG0AIBpfucAMpAWADDN7xxABtICAKb5nQPIQFoAwDS/cwAZSAsAmOZ3DiADaQEA0/zOAWQgLQBgmt85gAykBQBM8zsHkIG0AIBpfucAMpAWADDN7xxABtICAKb5nQPIQFoAwDS/cwAZSAsAmOZ3DiADaQEA0/zOAWQgLQBgmt85gAykBQBM8zsHkIG0AIBpfucAMpAWADDN7xxABtICAKb5nQPIQFoAwDS/cwAZSAsAmOZ3DiADaQEA0/zOAWQgLQBgmt85gAykBQBM8zsHkIG0AIBpfucAMpAWADDN7xxABtICAKb5nQPIQFoAwDS/cwAZSAsAmOZ3DiADaQEA0/zOAWQgLQBgmt85gAykBQBM8zsHkIG0AIBpfucAMpAWADDN7xxABtICAKb5nQPIQFoAwDS/cwAZSAsAmOZ3/pJhAKGjqUGRAAAAAElFTkSuQmCC" || foto[0].photo === null || foto[0].photo === "" || foto[0].photo === undefined? (
                                                    <>
                                                        <img src={defaultImg} className="rounded-circle" width="40" height="40" />
                                                    </>
                                                ) : (
                                                    <>
                                                        <img src={`data:image/png;base64, ${foto[0].photo}`} className="rounded-circle" width="40" height="40" />
                                                    </>
                                                )}           
                                            </>
                                        ) : null}
                                        {firstLoginData !== null ? (
                                            <span className="profile-name text-uppercase ms-2 d-none d-lg-inline">
                                                <strong>{firstLoginData.NAME}</strong>
                                            </span>
                                        ) : null}
                                    </a>
                                </div>
                                {/* <!-- Botón salir --> */}
                                <a
                                    className="btn btn-sign-out"
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        await props.signOut();
                                    }}
                                >
                                    <i className="fa fa-sign-out fa-lg" aria-hidden="true"></i>
                                </a>
                            </div>
                            {/* <!-- Buscador mobile --> */}
                            <div className="notification collapse mb-3" id="box-search" style={{ zIndex: "4", width: "50%", padding: "inherit", fontSize: "17px" }}>
                                <div className="notification-group">
                                    <Buscador></Buscador>
                                </div>
                            </div>
                            <div className="input-group d-flex d-lg-none mt-3">
                                <div className="form-outline col-10">
                                    <input type="search" onChange={() => buscador_interno2()} id="inputSearch2" className="form-control globalsearcher" placeholder="Buscar..." aria-label="Buscar" />
                                </div>
                                <button type="button" className="btn btn-primary col-2">
                                    <i className="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </header>
    );
};

Header.propTypes = {
    step: PropTypes.number,
    boss: PropTypes.bool,
    firstLoginData: PropTypes.object,
    showAdminBtn: PropTypes.bool,
    signOut: PropTypes.func,
    appState: PropTypes.object,
};

export default Header;
