/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { HiOutlineArrowNarrowDown, HiOutlineArrowNarrowUp } from "react-icons/hi";
import moment from 'moment';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap';
import RequestPlateService from '../../services/request-plate/request-plate-service'
import styles from "../../resources/css/style.module.css"
import PaginatedContainer from '../commonComponents/PaginatedContainer.';

// eslint-disable-next-line react/prop-types
const RequestPlateTable = ({ info = [], onSelectAll, onSelectRow, selectedRows, getRequestList,statusSelected ,typeSelected,countrySelected }) => {
  const [state, setState] = useState(false);
  const [idDropdown, setIdDropdown] = useState(null)
  // const [disabled, setDisabled] = useState(true)
  const [order, setOrder] = useState("DES")
  const [orderField, setOrderField] = useState();
  const isPendiente = (ausencia) => {
    return ausencia && ausencia.status === 'P';
  }

  const getStatusClassName = (status) => {
    return (status === 'A' || status === 'PRH' || status === 'ARH') ? "Aprobada" : status === 'P' ? "Pendiente" : "Rechazada";
  };

  const [sortedData, setSortedData] = useState(info);

  useEffect(() => {
    setSortedData(info);
    setOrderField(null);
   // setOrder("DES");
  }, [info]);

  useEffect(() => {
    sortByDate("beginDate","ASC");
  }
    , [statusSelected,typeSelected,countrySelected]);


    const sortByDate = (field,order) => {
    let data = [];
    if (order === "ASC") {
      data = info.sort((a, b) => moment(b[field]).unix() - moment(a[field]).unix())
      setOrder("DES")
    } else {
      data = info.sort((a, b) => moment(a[field]).unix() - moment(b[field]).unix())
      setOrder("ASC")
    }

    setSortedData(data);
    setOrderField(field);
  }

  const sortByField = (field) => {
    let data = [];
    if (order === "DES") {
      data = info.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0))
      setOrder("ASC")
    } else {
      data = info.sort((a, b) => (b[field] > a[field]) ? 1 : ((a[field] > b[field]) ? -1 : 0));
      setOrder("DES")
    }

    setSortedData(data);
    setOrderField(field);
  }

  const castType = (type) => {
    const types = {
      licenses: 'Licencia',
      vacations: 'Vacaciones',
      freeTime: 'Mi Tiempo'
    }

    return types[type];
  }

  const dropdown = (e, i) => {
    e.preventDefault();
    setIdDropdown(i);
    if (state) {
      setState(false);
    } else {
      setState(true);
    }
  };

  const ArrowSort = ({ orderType, field, isDate }) => {
    const arrowColorClass = field === orderField ? 'color-orange' : 'color-normal';
    if (orderType === "DES") {
      return (
        <HiOutlineArrowNarrowDown className={`${arrowColorClass}   ${styles["icon_arrow"]}`} onClick={() => isDate ? sortByDate(field,order) : sortByField(field)} />
      )
    }

    if (orderType === "ASC") {
      return (
        <HiOutlineArrowNarrowUp className={`${arrowColorClass} ${styles["icon_arrow"]}`} onClick={() => isDate ? sortByDate(field,order) : sortByField(field)} />
      )
    }
  }

  const getDescriptionStatus = (status) => {

    switch (status) {
      case 'A':
        return "Aprobada";
        break;
      case 'PRH':
        return "Pendiente RH";
        break;
      case 'ARH':
        return "Aprobado RH";
        break;
      case 'NRH':
        return "No Aprobado RH";
        break;
      case 'NAJ':
        return "Rechazada";
        break;
      case 'P':
        return "Pendiente";
        break;
      case 'R':
        return "Rechazada";
        break;
    }
  }

  const DetailRequest = ({ request, index }) => {
    const { type, id, region, hasCertificate, status } = request;
    const [openFile, setOpenFile] = useState({ active: false, data: null, type: null, title: null });
    const [showConfirmAnular, setShowConfirmAnular] = useState(false);
    const [loadingAnularAction, setLoadingAnularAction] = useState(false);
    
    const anularVacaciones = async () => {
      try {
        setLoadingAnularAction(true);
        const { data } = await RequestPlateService.anularVacaciones({ emplid: request.emplid, approved_by: "", num_req: request.num_req })
        const { valid, status } = data.response;
        Swal.fire({
          allowOutsideClick: false,
          position: 'center',
          title: `<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> ${valid === "1" ? "Acción realizada" : "Error"} </div>`,
          html: `<div style="text-align: center; font-size: 15px"> ${status} </div>`,
          confirmButtonColor: '#0169b5',
        }).then(() => {
          getRequestList();
        })
      } catch (err) {
        console.log(err);
        Swal.fire({
          allowOutsideClick: false,
          position: 'center',
          title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Error </div>',
          html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo. </div>`,
          confirmButtonColor: '#0169b5',
        })
      } finally {
        setLoadingAnularAction(false);
        setShowConfirmAnular(false);
      }
    }

    const rejectRequest = async () => {
      try {
        setLoadingAnularAction(true);
        const { data } = await RequestPlateService.rejectRequest([request])
        const message = data.message;
        Swal.fire({
          allowOutsideClick: false,
          position: 'center',
          title: `<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px">Acción realizada</div>`,
          html: `<div style="text-align: center; font-size: 15px"> ${message} </div>`,
          confirmButtonColor: '#0169b5',
        }).then(() => {
          getRequestList();
        })
      } catch (err) {
        Swal.fire({
          allowOutsideClick: false,
          position: 'center',
          title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Error </div>',
          html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo. </div>`,
          confirmButtonColor: '#0169b5',
        })
      } finally {
        setLoadingAnularAction(false);
        setShowConfirmAnular(false);
      }
    }

    const getLicenceCertificate = async () => {
      try {
        const { data } = await RequestPlateService.getLicenceCertificate(id,region);
        if (data) {
          let byteChar = atob(data.certificateFile);
          let byteArray = new Uint8Array(new ArrayBuffer(byteChar.length));
          for (let i = 0; i < byteChar.length; i++) {
            byteArray[i] = byteChar.charCodeAt(i);
          }
          let blob = new Blob([byteArray], { type: data.certificateFileType });
          const url = window.URL.createObjectURL(blob);
          setOpenFile({ active: true, data: url, type: data.certificateFileType, title: data.certificateFileName });
        }
      } catch (err) {
        console.log(err);
      }
    }

    if (type === 'licenses' && ( region === 'AR' || region==='UY' )&& hasCertificate) {
      return (
        <>
          <div className={`dropdown ${styles['offset-ml-5px']}`}>
            <Tippy content="Ver archivo"><div className="certificado-align" onClick={() => getLicenceCertificate()}><i className="color-orange fa fa-eye fa-fw fa-lg"></i></div></Tippy>
          </div>
          <Modal show={openFile.active} data={openFile.data} size="lg">
            <Modal.Header closeButton onClick={() => { setOpenFile({ active: false, data: null, type: null, title: null }) }}>
              <h2 className="title-modal m-3 text-center">Certificado</h2>
            </Modal.Header>
            <div className="file-container-if">
              {openFile.type === 'application/pdf' ? (
                <object data={openFile.data} type="application/pdf" style={{ width: "100%", height: "800px" }}>
                  <p style={{ marginBottom: "0", textAlign: 'center', padding: '10px' }}>
                    El navegador no puede previsualizar el pdf. <br />
                    <a href={openFile.data} download={openFile.title}>
                      Descargar archivo
                    </a>
                  </p>
                </object>) : <img className="img-fluid" src={openFile.data} style={{ width: "100%", maxHeight: "800px" }}></img>}

            </div>
          </Modal>
        </>
      )
    }

    if (type === 'vacations') {
      return (
        <>
          <UncontrolledDropdown
            className={`${styles["avatar-container"]} ${styles["align-items-sm-start"]}`}
          >
            <DropdownToggle
              className={`${styles["toggle-margin"]} check-one-toggle m-0 py-0`}
              size="sm"
              split
              color="transparent"
            >
              <BsThreeDotsVertical size={20} />
            </DropdownToggle>
            <DropdownMenu
              className={styles["avatar-action"]}
            >
              {region === 'AR' && (
                <DropdownItem
                  key={1}
                  // onClick={() => handleAvatarActions(option.value)}
                  id={1}
                  size="sm"
                >
                  <Link to='detalle-aprobacion' state={request}>Ver más detalles</Link>

                </DropdownItem>
              )}
              {region === 'CL' && status === 'A' && (
                <DropdownItem
                  key={2}
                  onClick={() => setShowConfirmAnular(true)}
                  id={2}
                  size="sm"
                >
                  <AiOutlineCloseCircle className="color-orange" /> Anular Solicitud
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
          <Modal show={showConfirmAnular} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton onClick={() => { setShowConfirmAnular(false) }}>
              <h2 className="title-modal m-3 text-center">Anular solicitud</h2>
            </Modal.Header>
            <Modal.Body>
              <h2 className="h5">
                ¿Estás seguro que deseas anular la solicitud?
              </h2>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary px-5"
                data-bs-dismiss="modal"
                onClick={() => setShowConfirmAnular(false)}
              >
                No{" "}
                <i
                  className="fa fa-chevron-right fa-fw fa-xs"
                  aria-hidden="true"
                ></i>
              </button>
              <button
                type="button"
                className="btn btn-degradado px-5"
                disabled={loadingAnularAction}
                onClick={(event) =>
                  anularVacaciones()
                }
                data-bs-dismiss="modal"
              >
                Sí{" "}
                {loadingAnularAction && <div className="spinner-border spinner-border-sm"></div>}
                <i
                  className="fa fa-chevron-right fa-fw fa-xs"
                  aria-hidden="true"
                ></i>
              </button>
            </Modal.Footer>
          </Modal>
        </>
      )
    }

    if (type === 'freeTime') {
      return (
        <>
          <UncontrolledDropdown
            className={`${styles["avatar-container"]} ${styles["align-items-sm-start"]}`}
          >
            <DropdownToggle
              className={`${styles["toggle-margin"]} check-one-toggle m-0 py-0`}
              size="sm"
              split
              color="transparent"
            >
              <BsThreeDotsVertical />
            </DropdownToggle>
            <DropdownMenu
              className={styles["avatar-action"]}
            >
              {region === 'CL' && status === 'A' && (
                <DropdownItem
                  key={2}
                  onClick={() => setShowConfirmAnular(true)}
                  id={2}
                  size="sm"
                >
                  <AiOutlineCloseCircle className="color-orange" /> Anular Solicitud
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
          <Modal show={showConfirmAnular} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton onClick={() => { setShowConfirmAnular(false) }}>
              <h2 className="title-modal m-3 text-center">Anular solicitud</h2>
            </Modal.Header>
            <Modal.Body>
              <h2 className="h5">
                ¿Estás seguro que deseas anular la solicitud?
              </h2>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary px-5"
                data-bs-dismiss="modal"
                onClick={() => setShowConfirmAnular(false)}
              >
                No{" "}
                <i
                  className="fa fa-chevron-right fa-fw fa-xs"
                  aria-hidden="true"
                ></i>
              </button>
              <button
                type="button"
                className="btn btn-degradado px-5"
                disabled={loadingAnularAction}
                onClick={(event) =>
                  rejectRequest()
                }
                data-bs-dismiss="modal"
              >
                Sí{" "}
                {loadingAnularAction && <div className="spinner-border spinner-border-sm"></div>}
                <i
                  className="fa fa-chevron-right fa-fw fa-xs"
                  aria-hidden="true"
                ></i>
              </button>
            </Modal.Footer>
          </Modal>
        </>
      )
    }

    return null;
  }

  return (
    <>
      <div className="container card-box table-header-fixed table-aprobacion-vacaciones mb-3">
        <h2 className="form-title tit aproove-tit">Detalle</h2>
        <div className="responsive-table">
          <table className={`${styles.table} ${styles['request-table']}`}>
            <thead className="table-header-border">
              <tr>
                <th>
                  <input className={`${styles['form-check-input']} me-2 h5 ${styles['request-table-selectall']}`} type="checkbox" onChange={onSelectAll}></input>
                </th>
                <th className="cap">Nombre</th>
                <th className="cap">Pais <ArrowSort orderType={order} field="region" /></th>
                <th className="cap">Tipo  <ArrowSort orderType={order} field="type" /></th>
                <th className="cap">Fecha Inicio <ArrowSort orderType={order} field="beginDate" isDate /></th>
                <th className="cap">Fecha Final <ArrowSort orderType={order} field="returnDate" isDate /></th>
                <th className="cap">Saldo dias <ArrowSort orderType={order} field="saldo" /></th>
                <th className="cap">Estado <ArrowSort orderType={order} field="status" /></th>
                <th className="cap">Comentario</th>
                <th> </th>
              </tr>
            </thead>
            <PaginatedContainer itemsPerPage={8}> 
              {sortedData && sortedData.map((request, i) => {
                return (
                  <tr key={request.id+i}>
                    <td>
                      <input className={`${styles['form-check-input']} me-2 h5 ${styles['request-table-selectone']}`} type="checkbox" id={request.id} onChange={onSelectRow}
                        value={request.id}
                        disabled={!isPendiente(request)}
                        checked={selectedRows.includes(request.id.toString())} />
                    </td>
                    <td>{request.userName}</td>
                    <td>{request.region}</td>
                    <td>{castType(request.type)}</td>
                    <td>{moment(request.beginDate).format('DD-MM-YYYY')}</td>
                    <td>{moment(request.returnDate).format('DD-MM-YYYY')}</td>
                    <td>{request.saldo}</td>
                    <td>
                      <div className={styles[getStatusClassName(request.status)]}>{getDescriptionStatus(request.status)}</div>
                    </td>
                    <td>{request.comments}</td>
                    <td>
                      {<DetailRequest request={request} index={i} />}
                    </td>
                  </tr>)
              })
              }

              </PaginatedContainer> 
          </table>
          {info.length === 0 && <span className="d-flex justify-content-center">No se encontraron elementos en la bandeja de entrada</span>}
        </div>
        {/*responsive-table */}
      </div>
      <div className="box mb-4  d-md-none">
        {info.length > 0 ?
          <div>
            <h2 className="h4 tit-section">Detalle</h2>
            <Accordion className="accordion accordion-flush accordion-light accordion-table" defaultActiveKey="0">
            <PaginatedContainer itemsPerPage={6}> 
              {sortedData.map((request, i) => {
                return (
                  <Accordion.Item key={i} eventKey={request.id}>
                    <Accordion.Header className="accordion-header title-emp" id="accordion">
                      <strong>{request.userName}</strong></Accordion.Header>
                    <Accordion.Body className="accordion-body p-0">
                      <div className="data-list-box data-list-box-even">
                        <ul className="data-list-section--item list-unstyled">
                          <li>
                            <input className="form-check-input me-2 h5" type="checkbox" id={request.id} onChange={onSelectRow}
                              value={request.id}
                              disabled={!isPendiente(request)}
                              checked={selectedRows.includes(request.id.toString())}>
                            </input>
                          </li>
                          <li>
                            <span className="data-list-box__title">Fecha Inicio</span>
                            <span className="data-list-box__text">{moment(request.beginDate).format("DD-MM-YYYY")}</span>
                          </li>
                          <li>
                            <span className="data-list-box__title">Fecha Final</span>
                            <span className="data-list-box__text">{moment(request.returnDate).format("DD-MM-YYYY")}</span>
                          </li>
                          <li>
                            <span className="data-list-box__title">Día/s</span>
                            <span className="data-list-box__text">{request.durationDays}</span>
                          </li>
                          <li>
                            <span className="data-list-box__title">Comentarios</span>
                            <span className="data-list-box__text text-truncate">{request.comments}</span>
                          </li>
                          <li>
                            <span className="data-list-box__title">Estado</span>
                            <span className="data-list-box__text text-uppercase fw-bold">
                              <span className={`boton-estado ${(request.status === 'A' || request.status === 'PRH' || request.status === 'NRH') ? "Aprobada" : request.status === 'P' ? "Pendiente" : "Rechazada"}`}>{getDescriptionStatus(request.status)}</span>
                            </span>
                          </li>
                          <li>
                          { <DetailRequest request={request} index={i} />}
                          </li>
                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )
              })}
            </PaginatedContainer> 
            </Accordion></div> : null}
      </div>
    </>
  )
}

export default RequestPlateTable;
