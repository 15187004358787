import Auth from "@aws-amplify/auth";
import axios, { AxiosRequestConfig, AxiosInstance } from "axios";
import { JobRequisitionResponse } from "../interfaces/applyInterface";
import { RegionalInfoResponse } from "../interfaces/regionalInfoInterface";

const BASE_URL =
    process.env.REACT_APP_BACKEND_URL_GLOBAL || "http://localhost:4000";

const Axios = async (): Promise<AxiosInstance> => {
    const auth = await Auth.currentSession();
    const AccessToken = auth.getAccessToken().getJwtToken();

    const axiosConfiguration: AxiosRequestConfig = {
        baseURL: BASE_URL,
    };

    const TalentAPI = axios.create(axiosConfiguration);

    TalentAPI.interceptors.request.use((config) => {
        config.headers!.AccessToken = AccessToken;
        config.headers!["Content-Type"] = "application/json";

        return config;
    });

    return TalentAPI;
};

export const getRequisitions = async (
    setLoading: (isLoading: boolean) => void,
    setError?: any
): Promise<JobRequisitionResponse | undefined> => {
    try {
        setLoading(true);
        const talentAPI = await Axios();

        const { data } = await talentAPI.get<JobRequisitionResponse>(
            "api/cencotalent"
        );

        return data;
    } catch (error) {
        console.log(error);
        setLoading(false);
        setError(true);
    }
};

export const getInfoRegional = async (
    setLoading: (isLoading: boolean) => void,
    setErrorInfo?: any
) => {
    try {
        setLoading(true);
        const talentAPI = await Axios();

        const { data } = await talentAPI.get<RegionalInfoResponse>(
            "api/cencotalent/info-by-country"
        );

        return data;
    } catch (error) {
        console.log(error);
        setLoading(false);
        setErrorInfo(true);
    }
};

export const normalizeStrings = (input: string): string => {
    return input
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
};
