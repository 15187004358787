import React, { FC, useRef, useState } from "react";
import { FaXmark } from "react-icons/fa6";
import { BiLink } from "react-icons/bi";

import { Button } from "./Button";
import { ShareLink } from "./ShareLink";
import AlertInfo from "./AlertInfo";

type ShareIn = "Facebook" | "Whatsapp" | "Telegram" | "X" | "Linkedin";

interface props {
    activeState: boolean;
    modalTitle?: string;
    urlToShare: string;
    shareIn: ShareIn[];
    shareDescription?: string;
    emailTitle?: string;
    emailBody?: string;
    infoAlert?: string;
    toggleState: (state: boolean) => void;
}

export const Share: FC<props> = ({
    activeState,
    toggleState,
    urlToShare,
    shareIn,
    modalTitle = "Compartir",
    infoAlert
}) => {
    const divPopup = useRef<HTMLDivElement>(null);
    const isActive = activeState ? "share-show" : "";
    const inputUrl = useRef<HTMLInputElement>(null);
    const url = encodeURIComponent(urlToShare);
    const [copyStatus, setCopyStatus] = useState<boolean>(false);

    const handleCopy = () => {
        const input = inputUrl.current;
        if (!input) return;
        input.select();
        navigator.clipboard.writeText(urlToShare);
        setCopyStatus(true);
        setTimeout(() => {
            setCopyStatus(false);
            window.getSelection()?.removeAllRanges();
        }, 1700);
    };

    return (
        <div ref={divPopup} className={`share-popup ${isActive}`}>
            <header className="share-header">
                <h2 className="h4 tit-section">{modalTitle}</h2>
                <span></span>
                <div className="share-close" onClick={() => toggleState(false)}>
                    <FaXmark />
                </div>
            </header>
            <div className="share-content">
                {infoAlert ? (
                    <AlertInfo text={infoAlert} color="alert-primary" heightIcon="60" widthIcon="60"/>
                ): null}
                <p>Compartir vía: </p>
                <ul className="share-icons">
                    {shareIn.map((item) => (
                        <ShareLink key={item} url={url} socialName={item} />
                    ))}
                </ul>
                <p>O copiar enlace:</p>
                <div className="share-field">
                    <BiLink className="share-url-icon mr-1" width={25} />
                    <input
                        className="share-input"
                        ref={inputUrl}
                        type="text"
                        readOnly
                        value={urlToShare}
                    />
                    <Button onClick={handleCopy} styleType="secondary">
                        {copyStatus ? "Copiado" : "Copiar"}
                    </Button>
                </div>
            </div>
        </div>
    );
};
