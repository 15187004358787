import React from "react";
import { JobCard } from "../ui";
import { Pagination, ScreenSizeViewer } from "../../../commonComponents";
import { useCencoTalent } from "../../hooks/useCencoTalent";
import { useCencoUI } from "../../hooks/useCencoUI";

export const ResultsApply = () => {
    const {
        //Variables
        activeApplys,
        elementsPerPage,

        //Funciones
        calculateNumberOfElements,
        handleSetActiveRequisition,
        startPaginateArray,
    } = useCencoTalent();

    const { paginationPage, setPaginationPage } = useCencoUI();

    return (
        <>
            <h2 className="h4 tit-section">Resultados</h2>
            {activeApplys && activeApplys.length > 0 && (
                <p className="h5 mb-4">
                    Mostrando {calculateNumberOfElements()} de{" "}
                    {activeApplys?.length ?? 1} resultados
                </p>
            )}

            <div className="d-flex justify-content-center">
                <div className="row">
                    {activeApplys && activeApplys.length > 0 ? (
                        startPaginateArray(paginationPage, 4, activeApplys).map(
                            (item, index) => {
                                const paddingClass =
                                    index % 2 === 0
                                        ? "pe-xl-2 ps-xl-0"
                                        : "ps-xl-2 pe-xl-0";
                                return (
                                    <div
                                        key={item.id}
                                        className={`col-12 col-md-12 col-xl-6 mb-4 d-flex px-0 ${paddingClass}`}
                                    >
                                        <JobCard
                                            applyInfo={item}
                                            onDetailsClick={() =>
                                                handleSetActiveRequisition(item)
                                            }
                                            className="w-100"
                                        />
                                    </div>
                                );
                            }
                        )
                    ) : (
                        <p className="h5 mb-4">
                            Lo sentimos, no encontramos resultados.
                        </p>
                    )}
                </div>
            </div>
            {activeApplys && activeApplys.length > 1 && (
                <Pagination
                    page={paginationPage}
                    setPage={setPaginationPage}
                    totalItems={activeApplys?.length ?? 0}
                    elementsPerPage={elementsPerPage}
                    className="mt-5"
                />
            )}
        </>
    );
};
