import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import DataServices from "../../services/data-test.service";
import { BsExclamationCircle } from "react-icons/bs";
import Swal from 'sweetalert2';
import "../../resources/css/style-ARG.css";
import ProfileImage from '../FotoPerfil/profile-image';


const DatosPersonalesARG = () => {

    //variables de estado
    const [data, setData] = useState({});
    const [loadingUser, setLoadingUser] = useState(true);
    const [sentAddress, setSentAddress] = useState(false);
    const [sent, setSent] = useState(false)
    const [ddjj, setDdjj] = useState(null)
    const [noData, setNoData] = useState(false);
    const [flag1, setFlag1] = useState(false);
    const [flag2, setFlag2] = useState(false);
    const [adressString, setAdressString] = useState(null);
    const [addressFocus, setAddressFocus] = useState(false);
    const [saveClicked, setSaveClicked] = useState(false);
    const [FormContactValues, setFormContactValues] = useState({ personalEmail: '', personalPhone: '', whatsappPhone: '' });
    const [FormAddressValues, setFormAddressValues] = useState(
        {
            status: "",
            fromDate: "",
            country: "",
            street: "",
            doorNumber: "",
            floor: "",
            aparment: "",
            building: "",
            betweenStreets: "",
            stateCode: "",
            cityCode: "",
            postalCode: ""
        }
    );
    const [contactErrors, setContactErrors] = useState({});
    const [addressErrors, setAddressErrors] = useState({});
    const [counties, setCounties] = useState([]);
    const [zipCodes, setZipCodes] = useState([]);
    const [states, setStates] = useState([]);
    const [addressToSet, setAddressToSet] = useState({});
    const [loadingZip, setLoadingZip] = useState(false);
    const [loadingCounties, setLoadingCounties] = useState(false);
    const [loadingStates, setLoadingStates] = useState(false);
    const [fetched, setFetched] = useState(false);
 
    
    useEffect(() => {
        if (!fetched) {
            getUser();
        }

    }, [fetched])

    useEffect(() => {
        if (Object.keys(contactErrors).length === 0 && saveClicked) {
            console.log("peticion en proceso");
            DataServices.sendPhoneUpdate(FormContactValues, setFlag1, setFlag2, setSent);
        }
    }, [contactErrors, saveClicked])

    useEffect(async () => {
        if (fetched) {
            await DataServices.getLocalidad(addressToSet, setAdressString, setLoadingUser)
            LoadStates();
            LoadCounties();
            LoadZipCodes();
        }
    }, [fetched])

  
 

    const getUser = async () => {
        await DataServices.getUserData(setFetched, setAddressToSet, setData, setFormContactValues, setFormAddressValues, setNoData, setLoadingUser);
    }


    const HandleChange = (e) => {
        setSaveClicked(false);
        setContactErrors({});
        const { name, value } = e.target;
        setFormContactValues({ ...FormContactValues, [name]: value });
    }


    const HandleAddressChange = (e) => {
        setSaveClicked(false);
        setAddressErrors({});
        const { name, value } = e.target;
        if (name === 'stateCode') {
            addressToSet.stateCode = value;
            data.currentAddress.cityCode = null;
            data.currentAddress.postalCode = null;
            FormAddressValues.cityCode = null;
            FormAddressValues.postalCode = null;
            setZipCodes([]);
            setCounties([]);
            LoadCounties();
        }
        if (name === 'cityCode') {
            addressToSet.cityCode = value
            data.currentAddress.postalCode = null;
            FormAddressValues.postalCode = null;
            setZipCodes([]);
            LoadZipCodes();
        }
        setFormAddressValues({ ...FormAddressValues, [name]: value });
    }



    const validateContact = (val) => {
        let errors = {};
        const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!val.personalEmail) { errors.personalEmail = '*Campo obligatorio' }
        if (val.personalEmail && val.personalEmail !== '' && !regEx.test(val.personalEmail)) { errors.personalEmail = '*Dirección de correo invalida. Ingrese una dirección de correo por favor' }

        return errors
    }
    const validateAddress = (val) => {
        let errors = {};
        if (!val.street) { errors.street = '*Campo obligatorio' }
        if (!val.doorNumber) { errors.doorNumber = '*Campo obligatorio' }
        if (!val.cityCode) { errors.cityCode = '*Seleccione una localidad.' }
        if (!val.postalCode) { errors.postalCode = '*Seleccione un código postal.' }
        if (!val.stateCode) { errors.stateCode = '*Seleccione una provincia.' }

        return errors
    }
    /* 
        const AlertTimeOut = () => {
            setTimeout(() => {
                setFlag1(false);
                setFlag2(false);
                setErrorFlag(false)
            }, 3000);
        } */


    const ValidateContactUndefined = (form) => {
        if (form.email === undefined) {
            form.email = ""
        } else if (form.tel_cel === undefined) {
            form.tel_cel = ""
        } else if (form.tel_casa === undefined) {
            form.tel_casa = ""
        } else {
            return;
        }
    }

    const ValidateAddressUndefined = (form) => {

        if (form.street === undefined) {
            form.street = ""
        } else if (form.doorNumber === undefined) {
            form.doorNumber = ""
        } else if (form.floor === undefined) {
            form.floor = ""
        } else if (form.apartment === undefined) {
            form.apartment = ""
        } else if (form.cityCode === undefined) {
            form.cityCode = ""
        } else if (form.stateCode === undefined) {
            form.stateCode = ""
        } else if (form.postalCode === undefined) {
            form.postalCode = ""
        } else {
            return
        }
    }

    // eslint-disable-next-line no-unused-vars
    const validateDDJJ = e => {
        if (ddjj === null) {
            setDdjj(true)
        } else {
            setDdjj(!ddjj);
        }
    }


    const AddressSubmit = () => {
        ValidateAddressUndefined(FormAddressValues);
        const errors = validateAddress(FormAddressValues);
        const es = setAddressErrors(errors);
        if (!es && Object.keys(errors).length === 0) {
            if (ddjj) {
                console.log("peticion en proceso");
                DataServices.sendAddressUpdate(FormAddressValues, setFlag1, setFlag2, setSentAddress);
            }
            if (ddjj === null) {
                setDdjj(false);
            }

        }

    }

    const ContactSubmit = () => {
        ValidateContactUndefined(FormContactValues);
        setContactErrors(validateContact(FormContactValues))
        setSaveClicked(true);
    }


    const LoadingUserScreen = () => {

        return <div data-testid="loading" className="full-spinner">
            <div className="spinner-border text-primary"></div>
        </div>

    }

    const LoadStates = async () => {
        setLoadingStates(true);
        await DataServices.getStates(setStates, setLoadingStates);
    }

    const LoadZipCodes = async () => {
        setLoadingZip(true);
        await DataServices.getCountyById(addressToSet, setZipCodes, setLoadingZip);
    }

    const LoadCounties = async () => {
        setLoadingCounties(true);
        await DataServices.getCounties(setCounties, addressToSet, setLoadingCounties);
    }

    const SuccessAlert = () => {

        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: green; font-size: 16px">Realizado</div>',
            html: '<div style="text-align: center; font-size: 15px">Datos actualizados correctamente</div>',
            confirmButtonColor: '#0169b5',
        }).then((result) => {
            if (result.isConfirmed)

                setFlag1(false);
            setSaveClicked(false);
            setDdjj(null)
            setAddressFocus(false);
            setFetched(false);
        })

    }

    const ErrorAlert = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px">Error!</div>',
            html: `<div style="text-align: center; font-size: 15px">Error actualizando datos. Intentelo nuevamente.</div>`,
            confirmButtonColor: '#0169b5',
        }).then((result) => {
            if (result.isConfirmed) {
                setFlag2(false);
                setSaveClicked(false);
                setSent(false);
                setSentAddress(false);
            }
        })
    }


    const DataDisplay = () => {
        if (!noData) {
            return <div className="row">
                <div className="col-lg-3 col-md-3 p-0 m-0">
                    <div className={!addressFocus ? `card-box summary-card data-summary mb-3` : `edit-card summary-card data-summary mb-3 data-summary-addr`}>
                     <div className="mb-2 mt-2">
                     <ProfileImage ></ProfileImage>
                     </div>
                     
                        <div className='detail-user'>
                            <div className="title">{data?.firstName}  {data?.lastName}</div>
                            <div className="subtitle fw-bold">{data?.positionDescription}</div>
                        </div>
                        <div className="datas-group-container">
                            <div className="datas-group">
                                <div className="data-group">
                                    <div className="days"><span className='ic-circle ic-circle-primary'><i className="fa fa-envelope fa-fw"></i></span></div><div className="data-text text-truncate">{data?.businessEmail}</div>
                                </div>
                                <div className="data-group">
                                    <div className="days"><span className='ic-circle ic-circle-primary'><i className="fa fa-map-marker fa-fw"></i></span></div> <div className="data-text text-truncate">{data?.locationDescription}</div>
                                </div>
                                <div className="data-group">
                                    <div className="days"><span className='ic-circle ic-circle-primary'><i className="fa fa-hashtag fa-fw"></i></span></div><div className="data-text text-truncate">{data?.alternateEmployeeID}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-9 p-0">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className={!addressFocus ? `custom-card card-box data-card` : "custom-card card-box data-card data-card-addr"}>
                            {!addressFocus ?
                                <div className="form">

                                    <div className="section">
                                        <div className="header-card">
                                            <div data-testid="test"><h4 className="form-title tit">Personal</h4></div>
                                            <div className="line"></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-6 col-md-4">
                                                <div className="fields">
                                                    <div className="form-label" data-testid="fetched-data">Nombre y Apellido</div>
                                                    <input className="form-control" name="name" type="text" value={data.firstName + " " + data.lastName} disabled></input>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-6 col-md-4">
                                                <div className="fields">
                                                    <div className="form-label">Fecha de nacimiento</div>
                                                    <input className="form-control" name="birthDayDate" type="text" value={data.birthDayDate} disabled />
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-6 col-md-4">
                                                <div className="fields">
                                                    <div className="form-label">DNI</div>
                                                    <input className="form-control" name="nationalId" type="number" value={data.nationalId} disabled />
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-6 col-md-4">
                                                <div className="fields">
                                                    <div className="form-label">Estado civil</div>
                                                    <input name="maritalStatus" className="form-control" required value={data.maritalStatus} disabled></input>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-8">
                                                <div className="fields">
                                                    <div className="form-label">Banco / CBU</div>
                                                    <input name="bankData" className="form-control" type="text" value={data.bankName + " / " + data.cbuArgentina} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div className="header-card">
                                            {/* <BiEdit data-testid="phones-modal-button" className="edit-button" type="button" onClick={() => { setOpenModal(true) }} ></BiEdit> */}
                                            <div data-testid="test"><h4 className="form-title tit">Contacto</h4></div>
                                            <div className="line"></div>
                                        </div>
                                        <div className="row">
                                            <div className="space col-xs-12 col-sm-12 col-md-6">
                                                <div className="fields">

                                                    <div className="form-label">Email laboral</div>
                                                    <div className="input-group">
                                                        <input name="businessEmail" className="form-control" type="text" disabled value={data.businessEmail} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="space col-xs-12 col-sm-12 col-md-6">
                                                <div className="fields">
                                                    <div className="form-label">Email personal</div>
                                                    <div className="input-group">
                                                        <button className="input-group-text input-group-text-left">
                                                            <i className="fa fa-pencil color-orange" aria-hidden="true"></i>
                                                        </button>
                                                        <input name="personalEmail" onChange={HandleChange} type="text" className={addressErrors.personalEmail ? "form-control error-fc" : "form-control no-left-border"} defaultValue={data.personalEmail} />
                                                        <small className="form-error-message w-100">{contactErrors.personalEmail ? contactErrors.personalEmail : null}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-6 col-md-4">
                                                <div className="fields">
                                                    <div className="form-label">Teléfono laboral</div>
                                                    <div className="input-group">
                                                        <input name="businessPhone" placeholder="Ej: +5491199999999" type="text" className="form-control" value={data.businessPhone} disabled />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-6 col-md-4">
                                                <div className="fields">
                                                    <div className="form-label">Teléfono personal</div>
                                                    <div className="input-group">
                                                        <button className="input-group-text input-group-text-left">
                                                            <i className="fa fa-pencil color-orange" aria-hidden="true"></i>
                                                        </button>
                                                        <input name="personalPhone" onChange={HandleChange} placeholder="Ej: +5491199999999" type="text" className={addressErrors.personalPhone ? "form-control error-fc" : "form-control no-left-border"} defaultValue={data.personalPhone} />
                                                        <small className="form-error-message">{contactErrors.personalPhone ? contactErrors.personalPhone : null}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-6 col-md-4">
                                                <div className="fields">
                                                    <div className="form-label">Otro</div>
                                                    <div className="input-group">
                                                        <button className="input-group-text input-group-text-left">
                                                            <i className="fa fa-pencil color-orange" aria-hidden="true"></i>
                                                        </button>
                                                        <input name="whatsappPhone" onChange={HandleChange} placeholder="Ej: +5491199999999" type="text" className={addressErrors.whatsappPhone ? "form-control error-fc" : "form-control no-left-border"} required defaultValue={data.whatsappPhone} />
                                                        <small className="form-error-message">{contactErrors.whatsappPhone ? contactErrors.whatsappPhone : null}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div className="col-12">
                                                    <div className="fields">
                                                        <div className="form-label">Dirección</div>
                                                        <div className="input-group">
                                                            <button className="input-group-text input-group-text-left">
                                                                <i className="fa fa-pencil color-orange" aria-hidden="true"></i>
                                                            </button>
                                                            <input onFocus={() => setAddressFocus(true)} name="direccion" type="text" className="form-control no-left-border" defaultValue={adressString} required></input>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div> :

                                <div className="row">
                                    <div className="header-card">
                                        <div data-testid="test"><h4 className="form-title tit">Dirección</h4></div>
                                        <div className="line"></div>
                                    </div>
                                    <form>
                                        <div className="row">
                                            <div className="col-12 col-md-4">

                                                <div className="fields  address-fields">
                                                    <div className="form-label">Calle</div>
                                                    <input name="street" type="text" className={addressErrors.street ? "form-control error-fc" : "form-control"} onChange={HandleAddressChange} defaultValue={data.currentAddress?.street} required></input>
                                                    <small className="form-error-message">{addressErrors.street ? addressErrors.street : null}</small>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="fields  address-fields">
                                                    <div className="form-label">Número</div>
                                                    <input name="doorNumber" type="text" className={addressErrors.doorNumber ? "form-control error-fc" : "form-control"} onChange={HandleAddressChange} defaultValue={data.currentAddress?.doorNumber} required />
                                                    <small className="form-error-message">{addressErrors.doorNumber ? addressErrors.doorNumber : null}</small>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="fields address-fields">
                                                    <div className="form-label">Piso</div>
                                                    <input name="floor" type="text" className="form-control" onChange={HandleAddressChange} defaultValue={data.currentAddress?.floor}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-4">
                                                <div className="fields  address-fields">
                                                    <div className="form-label">Departamento</div>
                                                    <input name="apartment" type="text" className="form-control" onChange={HandleAddressChange} defaultValue={data.currentAddress?.apartment}></input>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="fields  address-fields">
                                                    <div className="form-label">Entre calles</div>
                                                    <input name="betweenStreets" type="text" className="form-control" onChange={HandleAddressChange} defaultValue={data.currentAddress?.betweenStreets}></input>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="fields  address-fields">
                                                    <div className="form-label">Provincia</div>
                                                    {!loadingStates ? <select name="stateCode" className="form-select" required onChange={HandleAddressChange} defaultValue={data.currentAddress?.stateCode}>
                                                        {states.map((p, i) =>
                                                            <option key={i} value={p.state}>{p.stateDescription}</option>)}
                                                    </select> : <div className="spinner-container"> <div role="status" className="spinner-border spinner-border-sm consult-spinner"></div></div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-4">
                                                <div className="fields  address-fields">
                                                    <div className="form-label">Localidad</div>
                                                    {!loadingCounties ? <select name="cityCode" className="form-select" onChange={HandleAddressChange} defaultValue={data.currentAddress?.cityCode || ''} required>
                                                        <option value={''} disabled></option>
                                                        {counties.map((c, i) =>
                                                            <option key={i} value={c.county}>{c.countyDescription}</option>)}
                                                    </select> : <div className="spinner-container">  <div role="status" className="spinner-border spinner-border-sm consult-spinner"></div></div>}
                                                    <small className="form-error-message">{addressErrors.cityCode ? addressErrors.cityCode : null}</small>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="fields address-fields">
                                                    <div className="form-label">Código Postal</div>
                                                    {!loadingZip ? <select name="postalCode" type="text" className="form-select" onChange={HandleAddressChange} defaultValue={data.currentAddress?.postalCode || ''} required>
                                                        <option value={''} disabled></option>
                                                        {zipCodes.map((z, i) =>
                                                            <option key={i} value={z.zipCode}>{z.zipCode}</option>)}
                                                    </select> : <div className="spinner-container"> <div role="status" className="spinner-border spinner-border-sm consult-spinner"></div> </div>}
                                                    <small className="form-error-message">{addressErrors.postalCode ? addressErrors.postalCode : null}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={(ddjj === null && Object.keys(addressErrors).length === 0 || ddjj) ? "check-ddjj adress-fields" : "check-ddjj-e adress-fields"}>
                                            <input className={(ddjj === null && Object.keys(addressErrors).length === 0 || ddjj) ? "check form-check-input" : "check form-check-input error-c"} type="checkbox" name="ddjj" onChange={validateDDJJ} value={ddjj} checked={ddjj}></input>
                                            <label className="form-check-label" htmlFor="invalidCheck">
                                                Estoy en conocimiento que la presente información reviste el carácter de DDJJ de Domicilio.
                                            </label>
                                        </div>
                                    </form>
                                </div>
                            }


                        </div>
                    </div>
                    <div>
                        {flag1 ? SuccessAlert() : null}
                        {flag2 ? ErrorAlert : null}
                    </div>


                    <div>
                        {flag1 ? SuccessAlert() : null}
                        {flag2 ? ErrorAlert() : null}
                    </div>

                </div>
            </div>
        } else {
            return <div className="alert alert-info alert-dismissible fade show text-center" role="alert">
                <BsExclamationCircle />  No se encontraron datos asociados
            </div>
        }

    }


    return (<>
        <nav className="breadcrumbs" aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to="/inicio">
                        <i style={{ pointerEvents: "none" }} className="fa fa-home color-orange" aria-hidden="true"></i> Inicio
                    </Link>
                </li>
                <li className="breadcrumb-item active prueba" aria-current="page">
                    Datos Personales
                </li>
            </ol>
        </nav>
        <section className="noticias mb-3">
            <div className="header-page-container">
                <h1>Datos Personales</h1>
                <div className="buttons">
                    <button title="Cancelar" type="reset" className="button reject" onClick={() => !addressFocus ? getUser() : setAddressFocus(false)}>Cancelar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                    <button title="Guardar Cambios" type="submit" className="button save modal-save" onClick={() => addressFocus ? AddressSubmit() : ContactSubmit()} disabled={sent || sentAddress}>Guardar {sent || sentAddress ? <div role="status" className="spinner-border spinner-border-sm"></div> : <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>}</button>
                </div>
            </div>

            <div className="col-12 position-relative mb-0">
                {/* <a className="btn btn-degradado btn-absolute" href="#">Ver más <i className="fa fa-chevron-right" aria-hidden="true"></i></a> */}
            </div>
        </section>
        <div className="row p-0 data-container">
            {loadingUser ? LoadingUserScreen() : DataDisplay()}
        </div>

    </>
    )



}



export default DatosPersonalesARG;


