import React, { useState, useEffect, useRef, Children, useLayoutEffect } from 'react';
import { Text, Image, StyleSheet, View, Page, Document, PDFDownloadLink } from "@react-pdf/renderer";
import { Chart as ChartJS, registerables } from "chart.js";
import { Bar, Chart } from "react-chartjs-2";
import { groupBy, formatDate, dotPerc, dotNumber } from '../../../services/utiles';

ChartJS.register(...registerables);
const regresionLineal = (cambioRentaData) => {
    const x = cambioRentaData.map((item, index) => index); //array con valores de x
    const y = cambioRentaData.map((item) => parseInt(item.salario_base)); //aray con valores de y
    const largo = x.length; //tamaño del array
    const sumX = x.reduce((a, b) => a + b, 0); //suma de todos los valores de x
    const sumY = y.reduce((a, b) => a + b, 0); //suma de todos los valores de y
    const xy = y.map((item, index) => item * index); //multiplica cada valor de y por x
    const sumXY = xy.reduce((a, b) => a + b, 0); //suma de todos los valores de xy
    const x2 = x.map((item) => item * item); //array de x elevado al cuadrado
    const sumX2 = x2.reduce((a, b) => a + b, 0); //suma de todos los valores de x elevado al cuadrado
    const m = (largo * sumXY - sumX * sumY) / (largo * sumX2 - sumX * sumX); //calcula la pendiente
    const b = sumY / largo - m * (sumX / largo); //calcula el valor de la ordenada al origen
    const listaRegresion = [];
    for (let i = 0; i < largo; i++) {
        listaRegresion.push(m * i + b); //calcula la regresion lineal
    }
    return listaRegresion;
};

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        const updateSize = () => {
            setSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
};

const Remuneraciones = ({
    data,
    loadingData
}) => {
    const [dataGraficoCambiosRenta, setDataGraficoCambiosRenta] = useState(false);
    const [hiddenTd, setHiddenTd] = useState(true);
    const [cambioRentaporAno, setCambioRentaporAno] = useState(false);
    const [width, height] = useWindowSize();
    const graficoRemuneracionesBae64 = document.getElementById("graficoRemuneraciones2") !== null ? document.getElementById("graficoRemuneraciones2").toDataURL() : "";

    useEffect(() => {
        if (!loadingData) {
            const cambioRentaData = Array.isArray(data.CN_RENTA_LIST) ? data.CN_RENTA_LIST.slice(0, 10).reverse() : data.CN_RENTA_LIST;
            if (cambioRentaData) {
                let dataGraph = {
                    datasets: [
                        {
                            type: "line",
                            label: "Sal Base",
                            yAxisID: "yaxis1",
                            data: Array.isArray(cambioRentaData) ? cambioRentaData.map((item) => item.salario_base) : [cambioRentaData.salario_base],
                            borderColor: "#5071be",
                            backgroundColor: "#5071be",
                            datalabels: {
                                align: "top",
                                anchor: "end",
                                color: "grey",
                            },
                        },
                        {
                            type: "bar",
                            label: "% Cambio",
                            yAxisID: "yaxis2",
                            data: Array.isArray(cambioRentaData) ? cambioRentaData.map((item) => item.perc_cambio) : [cambioRentaData.perc_cambio],
                            borderColor: "#d49449",
                            backgroundColor: "#d49449",
                            datalabels: {
                                align: "top",
                                anchor: "end",
                                color: "transparent",
                            },
                        },
                        {
                            type: "line",
                            label: "lineal (Sal Base)",
                            yAxisID: "yaxis3",
                            data: Array.isArray(cambioRentaData) ? regresionLineal(cambioRentaData) : regresionLineal([cambioRentaData]),
                            borderColor: "#5071be",
                            backgroundColor: "transparent",
                            borderDash: [10, 5],
                            datalabels: {
                                align: "top",
                                anchor: "end",
                                color: "transparent",
                            },
                        },
                    ],
                    labels: Array.isArray(cambioRentaData)
                        ? cambioRentaData.map((item) => {
                            return `${item.motivo} ${item.anio}`;
                        })
                        : [cambioRentaData.motivo + " " + cambioRentaData.anio],
                };
                let options = {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                            position: "bottom",
                        },
                    },
                    layout: {
                        padding: {
                            top: 20,
                        },
                    },
                    scales: {
                        yaxis1: {
                            beginAtZero: true,
                            type: "linear",
                            position: "left",
                        },
                        yaxis2: {
                            beginAtZero: true,
                            type: "linear",
                            position: "right",
                            grid: {
                                drawOnChartArea: false,
                            },
                        },
                        yaxis3: {
                            type: "linear",
                            position: "left",
                            display: false,
                            grid: {
                                drawOnChartArea: false,
                            },
                        },
                    },
                }
                setDataGraficoCambiosRenta({ data: dataGraph, options: options });
                setCambioRentaporAno(groupBy(Array.isArray(data.CN_RENTA_LIST) ? data.CN_RENTA_LIST : [data.CN_RENTA_LIST], "anio"));
            }
        }
    }, [loadingData]);

    const FichaRemuneracionPDF = ({ colaborador, lastRemData }) => {

        if (!Array.isArray(colaborador.CN_REM_LIST)) {
            colaborador.CN_REM_LIST = [colaborador.CN_REM_LIST];
        }

        const styles = StyleSheet.create({
            page: {
                backgroundColor: '#fff',
                padding: '20px',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
            },
            card: {
                padding: "20px",
                borderRadius: "10px",
                width: "48%",
                border: "1px solid #eeeeee",
                margin: " 0px 5px 20px 5px",
                height: "auto",
            },
            head: {
                display: "flex",
                marginBottom: "20px",
                paddingLeft: "10px",
            },
            body: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "20px"
            },
            h1: {
                fontSize: "16px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: "10px",
                color: "#0169b5"
            },
            table: {
                width: "100%",
                borderCollapse: "collapse",
                borderSpacing: "0",
                marginBottom: "20px"
            },
            tableHead: {
                backgroundColor: "#eeeeee",
                borderBottom: "1px solid #eeeeee",
                borderTop: "1px solid #eeeeee",
                borderLeft: "1px solid #eeeeee",
                borderRight: "1px solid #eeeeee",
                padding: "5px",
                fontSize: "8px",
            },
            tableBody: {
                borderBottom: "1px solid #eeeeee",
                borderLeft: "1px solid #eeeeee",
                borderRight: "1px solid #eeeeee",
                fontSize: "8px",
                textAlign: "center",
                color: "#777777",
            },
            tableRow: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                padding: "5px 5px 5px 5px",
                border: "1px solid #eeeeee",
            },
            labelHead: {
                textAlign: "center",
                color: "#777777",
                fontWeight: "900",
            },
            labelBody: {
                textAlign: "center",
                color: "#777777",
                fontWeight: "normal",
            },
            li: {
                display: "flex",
                flexDirection: "row",
                alignItems: 'flex-start',
                padding: "10px",
                borderBottom: "1px solid #eeeeee",
                position: "relative",
                justifyContent: "flex-start"
            },
            liTitle: {
                width: "50%",
            },
            liContent: {
                width: "50%",
            },
            titleLabel: {
                fontSize: "8px",
                fontWeight: "bold",
                color: "#777777"
            },
            contentLabel: {
                fontSize: "8px",
                fontWeight: "normal",
                color: "#777777"
            },
            foto: {
                width: "100px",
                height: "100px",
            },
            checkimg: {
                width: "20px",
                height: "20px",
            },
        });

        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    {/* Datos Remuneraciones */}
                    <View style={{ ...styles.card, width: "100%" }}>
                        <View style={styles.head}>
                            <Text style={styles.h1}>Datos de Remuneraciones</Text>
                        </View>
                        <View style={styles.body}>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>AÑO</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_REM_LIST[0].anio}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>MES</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_REM_LIST[0].mes}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>DIAS TRABAJADOS</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_REM_LIST[0].dias_trabajados}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>BASE HORARIA</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_REM_LIST[0].base_horaria}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>ÚLTIMO AJUSTE RENTA</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{formatDate(colaborador.CN_REM_LIST[0].ult_ajuste_renta)}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>ÚLTIMO AJUSTE RENTA %</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_REM_LIST[0].ult_ajuste_renta_p}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>TIPO DE JORNADA</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_REM_LIST[0].tipo_jornada}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>TOTAL HABERES</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_REM_LIST[0].total_haberes}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    {/* Ultima Remuneracion */}
                    <View style={{ ...styles.card, width: "100%" }}>
                        <View style={styles.head}>
                            <Text style={styles.h1}>Última Remuneración</Text>
                        </View>
                        <View style={styles.body}>
                            <Text style={{ ...styles.labelHead, margin: "5px 5px 5px 5px", alignSelf: "flex-end", fontSize: "10px" }}>
                                {lastRemData && (lastRemData.mes === "01" ? "Enero, " :
                                    lastRemData.mes === "02" ? "Febrero, " :
                                        lastRemData.mes === "03" ? "Marzo, " :
                                            lastRemData.mes === "04" ? "Abril, " :
                                                lastRemData.mes === "05" ? "Mayo, " :
                                                    lastRemData.mes === "06" ? "Junio, " :
                                                        lastRemData.mes === "07" ? "Julio, " :
                                                            lastRemData.mes === "08" ? "Agosto, " :
                                                                lastRemData.mes === "09" ? "Septiembre, " :
                                                                    lastRemData.mes === "10" ? "Octubre, " :
                                                                        lastRemData.mes === "11" ? "Noviembre, " :
                                                                            lastRemData.mes === "12" ? "Diciembre, " :
                                                                                "")}{lastRemData && lastRemData.anio}
                            </Text>
                            <View style={styles.table}>
                                <View style={styles.tableHead}>
                                    <View style={styles.tableRow}>
                                        <Text style={{ ...styles.labelHead, width: "15%" }}>HABER</Text>
                                        <Text style={{ ...styles.labelHead, width: "15%" }}>VALOR HABER</Text>
                                    </View>
                                </View>
                                <View style={styles.tableBody}>
                                    {
                                        colaborador.CN_REM_LIST[0].CN_HABER_LIST !== undefined ?
                                            Array.isArray(colaborador.CN_REM_LIST[0].CN_HABER_LIST) ?
                                                colaborador.CN_REM_LIST[0].CN_HABER_LIST.map((item, index) => {
                                                    return (
                                                        <View style={styles.tableRow} key={"dt" + index}>
                                                            <Text style={{ ...styles.labelContent, width: "15%" }}>{item.haber === "" ? "-" : item.haber}</Text>
                                                            <Text style={{ ...styles.labelContent, width: "15%" }}>{item.valor === "" ? "-" : item.valor}</Text>
                                                        </View>
                                                    )
                                                })
                                                :
                                                <View style={styles.tableRow} key={"dt0"}>
                                                    <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_REM_LIST[0].CN_HABER_LIST.haber === "" ? "-" : colaborador.CN_REM_LIST[0].CN_HABER_LIST.haber}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_REM_LIST[0].CN_HABER_LIST.valor === "" ? "-" : colaborador.CN_REM_LIST[0].CN_HABER_LIST.valor}</Text>
                                                </View>
                                            :
                                            <View style={styles.tableRow}>
                                                <Text style={styles.labelContent}>Sin Registros</Text>
                                            </View>
                                    }
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
                <Page size="A4" style={styles.page}>
                    {/* Grafico */}
                    <View style={{ ...styles.card, width: "100%" }}>
                        <View style={styles.head}>
                            <Text style={styles.h1}>Gráfico Cambios de Renta</Text>
                        </View>
                        <View style={styles.body}>
                            <Image source={graficoRemuneracionesBae64} style={{ width: "100%" }}></Image>
                        </View>
                    </View>
                    {/* Cambios de Renta */}
                    <View style={{ ...styles.card, width: "100%" }}>
                        <View style={styles.head}>
                            <Text style={styles.h1}>Cambios de Renta</Text>
                        </View>
                        <View style={styles.body}>
                            <View style={styles.table}>
                                <View style={styles.tableHead}>
                                    <View style={styles.tableRow}>
                                        <Text style={{ ...styles.labelHead, width: "15%" }}>AÑO</Text>
                                        <Text style={{ ...styles.labelHead, width: "15%" }}>RUT</Text>
                                        <Text style={{ ...styles.labelHead, width: "15%" }}>NOMBRE</Text>
                                        <Text style={{ ...styles.labelHead, width: "15%" }}>FECHA CAMBIO</Text>
                                        <Text style={{ ...styles.labelHead, width: "15%" }}>MOTIVO</Text>
                                        <Text style={{ ...styles.labelHead, width: "15%" }}>SAL. BASE</Text>
                                        <Text style={{ ...styles.labelHead, width: "15%" }}>% CAMBIO</Text>
                                    </View>
                                </View>
                                <View style={styles.tableBody}>
                                    {
                                        colaborador.CN_RENTA_LIST !== undefined ?
                                            Array.isArray(colaborador.CN_RENTA_LIST) ?
                                                colaborador.CN_RENTA_LIST.map((item, index) => {
                                                    return (
                                                        <View style={styles.tableRow} key={"dt" + index}>
                                                            <Text style={{ ...styles.labelContent, width: "15%" }}>{item.anio === "" ? "-" : item.anio}</Text>
                                                            <Text style={{ ...styles.labelContent, width: "15%" }}>{item.rut === "" ? "-" : item.rut}</Text>
                                                            <Text style={{ ...styles.labelContent, width: "15%" }}>{item.nombre === "" ? "-" : item.nombre}</Text>
                                                            <Text style={{ ...styles.labelContent, width: "15%" }}>{item.fecha_cambio === "" ? "-" : formatDate(item.fecha_cambio)}</Text>
                                                            <Text style={{ ...styles.labelContent, width: "15%" }}>{item.motivo_descr === "" ? "-" : item.motivo_descr}</Text>
                                                            <Text style={{ ...styles.labelContent, width: "15%" }}>{item.salario_base === "" ? "-" : item.salario_base}</Text>
                                                            <Text style={{ ...styles.labelContent, width: "15%" }}>{item.perc_cambio === "" ? "-" : item.perc_cambio}</Text>
                                                        </View>
                                                    )
                                                })
                                                :
                                                <View style={styles.tableRow} key={"dt0"}>
                                                    <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_RENTA_LIST.anio === "" ? "-" : colaborador.CN_RENTA_LIST.anio}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_RENTA_LIST.rut === "" ? "-" : colaborador.CN_RENTA_LIST.rut}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_RENTA_LIST.nombre === "" ? "-" : colaborador.CN_RENTA_LIST.nombre}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_RENTA_LIST.fecha_cambio === "" ? "-" : formatDate(colaborador.CN_RENTA_LIST.fecha_cambio)}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_RENTA_LIST.motivo_descr === "" ? "-" : colaborador.CN_RENTA_LIST.motivo_descr}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_RENTA_LIST.salario_base === "" ? "-" : colaborador.CN_RENTA_LIST.salario_base}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_RENTA_LIST.perc_cambio === "" ? "-" : colaborador.CN_RENTA_LIST.perc_cambio}</Text>
                                                </View>
                                            :
                                            <View style={styles.tableRow}>
                                                <Text style={styles.labelContent}>Sin Registros</Text>
                                            </View>
                                    }
                                </View>
                            </View>
                        </View>
                    </View>

                </Page>
            </Document>

        )
    }

    const FichaRemuneracion = ({ colaborador, pdf, dataGraficoCambiosRenta, cambioRentaporAno, hiddenTd }) => {
        const remData = Array.isArray(colaborador.CN_REM_LIST) ? colaborador.CN_REM_LIST : [colaborador.CN_REM_LIST];
        const [lastRemData, setLastRemData] = useState(Array.isArray(colaborador.CN_REM_LIST) ? colaborador.CN_REM_LIST[0] : colaborador.CN_REM_LIST);
        const graficoRemuneracionesRef = useRef(null);
        const [anioValue, setAnioValue] = useState(Array.isArray(colaborador.CN_REM_LIST) ? colaborador.CN_REM_LIST[0].anio : colaborador.CN_REM_LIST.anio);



        return (

            <>


                <PDFDownloadLink
                    className="text-decoration-none d-inline-block mb-3 mt-4 color-orange"
                    fileName="evaluacion_desempenio"
                    document={<FichaRemuneracionPDF colaborador={colaborador} lastRemData={lastRemData} />}
                >
                    Descargar
                </PDFDownloadLink>

                <div id="fichaRemuneracion">
                    <div className="form-check form-switch d-inline-block ms-4">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            defaultChecked={!hiddenTd}
                            checked={!hiddenTd}
                            id="datos-ocultos"
                            onChange={() => {
                                setHiddenTd(!hiddenTd);
                            }}
                        />
                        <label className="form-check-label text-uppercase" htmlFor="datos-ocultos">
                            Datos visibles
                        </label>
                    </div>
                    <div className="row row-cols-1 g-4 mb-4">
                        <div className="col-12 col-xl-12 d-flex">
                            <div className="box align-items-stretch w-100">
                                <h2 className="h4 tit-section">Datos de Remuneraciones</h2>
                                <div className="data-list-box data-list-box-even text-uppercase">
                                    {Array.isArray(remData) ?
                                        Children.toArray(remData.map((item) => {
                                            return (
                                                <ul className="list-unstyled m-0" >
                                                    <li>
                                                        <span className="data-list-box__title col-12 col-sm-5">AÑO</span>
                                                        <span className="data-list-box__text">{hiddenTd ? "****" : item.anio}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title col-12 col-sm-5">MES</span>
                                                        <span className="data-list-box__text">{hiddenTd ? "****" : item.mes}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title col-12 col-sm-5">DIAS TRABAJADOS</span>
                                                        <span className="data-list-box__text">{hiddenTd ? "****" : item.dias_trabajados}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title col-12 col-sm-5">BASE HORARIA</span>
                                                        <span className="data-list-box__text">{hiddenTd ? "****" : item.base_horaria}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title col-12 col-sm-5">ÚLTIMO AJUSTE RENTA</span>
                                                        <span className="data-list-box__text">{hiddenTd ? "****" : formatDate(item.ult_ajuste_renta)}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title col-12 col-sm-5">ÚLTIMO AJUSTE RENTA %</span>
                                                        <span className="data-list-box__text">{hiddenTd ? "****" : dotPerc(item.ult_ajuste_renta_p)}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title col-12 col-sm-5">TIPO DE JORNADA</span>
                                                        <span className="data-list-box__text">{hiddenTd ? "****" : item.tipo_jornada}</span>
                                                    </li>
                                                </ul>
                                            );
                                        }))
                                        :
                                        <ul className="list-unstyled m-0">
                                            <li>
                                                <span className="data-list-box__title col-12 col-sm-5">AÑO</span>
                                                <span className="data-list-box__text">{hiddenTd ? "****" : remData.anio}</span>
                                            </li>
                                            <li>
                                                <span className="data-list-box__title col-12 col-sm-5">MES</span>
                                                <span className="data-list-box__text">{hiddenTd ? "****" : remData.mes}</span>
                                            </li>
                                            <li>
                                                <span className="data-list-box__title col-12 col-sm-5">DIAS TRABAJADOS</span>
                                                <span className="data-list-box__text">{hiddenTd ? "****" : remData.dias_trabajados}</span>
                                            </li>
                                            <li>
                                                <span className="data-list-box__title col-12 col-sm-5">BASE HORARIA</span>
                                                <span className="data-list-box__text">{hiddenTd ? "****" : remData.base_horaria}</span>
                                            </li>
                                            <li>
                                                <span className="data-list-box__title col-12 col-sm-5">ÚLTIMO AJUSTE RENTA</span>
                                                <span className="data-list-box__text">{hiddenTd ? "****" : formatDate(remData.ult_ajuste_renta)}</span>
                                            </li>
                                            <li>
                                                <span className="data-list-box__title col-12 col-sm-5">ÚLTIMO AJUSTE RENTA %</span>
                                                <span className="data-list-box__text">{hiddenTd ? "****" : dotPerc(remData.ult_ajuste_renta_p)}</span>
                                            </li>
                                            <li>
                                                <span className="data-list-box__title col-12 col-sm-5">TIPO DE JORNADA</span>
                                                <span className="data-list-box__text">{hiddenTd ? "****" : remData.tipo_jornada}</span>
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row-cols-1 g-4 mb-4">
                        <div className="col-12 col-xl-6 d-flex">
                            <div className="box align-items-stretch w-100">
                                <h2 className="h4 tit-section">Última Remuneración</h2>
                                <div className="row g-3 align-items-center justify-content-end mb-4">
                                    <div className="col-auto">
                                        <label className="form-label m-0">
                                            <strong>Buscar por:</strong>
                                        </label>
                                    </div>
                                    <div className="col-auto">
                                        <select
                                            id="anioremdata"
                                            className="form-select"
                                            defaultValue={lastRemData.anio}
                                            onChange={(e) => {
                                                setAnioValue(e.target.value)
                                                if (e.target.value !== "") {
                                                    if (Array.isArray(remData)) {
                                                        const filtred = remData.filter((item) => item.anio === e.target.value);
                                                        setLastRemData(filtred);
                                                    } else {
                                                        setLastRemData(colaborador.CN_REM_LIST);
                                                    }
                                                } else {
                                                    setLastRemData(colaborador.CN_REM_LIST);
                                                }
                                            }}
                                        >
                                            <option value="">Año</option>
                                            {Array.isArray(remData) ? (
                                                Children.toArray(remData.map((remuneracion) => {
                                                    return <option value={remuneracion.anio} >{remuneracion.anio}</option>;
                                                }))
                                            ) : remData.anio === "" ? null : (
                                                <option value={remData.anio}>{remData.anio}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-auto">
                                        <select
                                            id="mesremdata"
                                            disabled={anioValue === ""}
                                            className="form-select"
                                            defaultValue={lastRemData.mes}
                                            onChange={(e) => {
                                                const anioSelect = document.getElementById("anioremdata").value;
                                                if (e.target.value !== "") {
                                                    if (Array.isArray(remData)) {
                                                        let filtred = remData.filter((item) => item.anio === anioSelect);
                                                        filtred = filtred.filter((item) => item.mes === e.target.value);
                                                        setLastRemData(filtred);
                                                    } else {
                                                        setLastRemData(colaborador.CN_REM_LIST);
                                                    }
                                                } else {
                                                    setLastRemData(colaborador.CN_REM_LIST);
                                                }
                                            }}
                                        >
                                            <option value="" >Mes</option>
                                            {Array.isArray(remData) ? (
                                                Children.toArray(remData.map((remuneracion) => {
                                                    return <option value={remuneracion.mes} >{remuneracion.mes}</option>;
                                                }))
                                            ) : remData.mes === "" ? null : (
                                                <option value={remData.mes}>{remData.mes}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>

                                <table className="table table-even table-section table-borderless text-uppercase d-md-table">
                                    <thead>
                                        <tr>
                                            <th className="col-2">Haber</th>
                                            <th className="col-4">Valor Haber</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(remData) ? (
                                            Children.toArray(remData.map((rem) => {
                                                {
                                                    return Array.isArray(rem.CN_HABER_LIST) ?
                                                        Children.toArray(rem.CN_HABER_LIST.map((item) => {
                                                            return (
                                                                <tr>
                                                                    <td>{hiddenTd ? "*****" : item.haber === "" ? "-" : item.haber}</td>
                                                                    <td>{hiddenTd ? "*****" : item.valor === "" ? "-" : "$ " + dotNumber(item.valor)}</td>
                                                                </tr>
                                                            );
                                                        })) :
                                                        <tr>
                                                            <td>{hiddenTd ? "*****" : rem.CN_HABER_LIST.haber === "" ? "-" : rem.CN_HABER_LIST.haber}</td>
                                                            <td>{hiddenTd ? "*****" : rem.CN_HABER_LIST.valor === "" ? "-" : "$ " + dotNumber(rem.CN_HABER_LIST.valor)}</td>
                                                        </tr>

                                                }
                                            }))
                                        ) : (
                                            <tr>
                                                <td colSpan="3">No hay datos</td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td>Suma total de Haberes</td>
                                            <td>{hiddenTd ? "*****" : '$ ' + dotNumber(remData[0].total_haberes)}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6 d-flex">
                            <div className="box align-items-stretch w-100">
                                <h2 className="h4 tit-section">Gráfico Cambios de Renta</h2>
                                <div className="table-responsive">
                                    {dataGraficoCambiosRenta ? (
                                        hiddenTd ? "****" :
                                            <Chart
                                                id="graficoRemuneraciones"
                                                ref={graficoRemuneracionesRef}
                                                data={dataGraficoCambiosRenta.data}
                                                type="line"
                                                width={645}
                                                height={null}
                                                //plugins={[ChartDataLabels]}
                                                options={dataGraficoCambiosRenta.options}
                                            />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <h2 className="h4 tit-section">Cambios de Renta</h2>
                        <table className="table table-even table-section table-borderless text-uppercase d-none d-md-table">
                            <thead>
                                <tr>
                                    <th>Año</th>
                                    <th>Fecha cambio</th>
                                    <th>Motivo</th>
                                    <th>Sal. Base</th>
                                    <th>% Cambio</th>
                                </tr>
                            </thead>
                            <tbody>
                                {colaborador.CN_RENTA_LIST !== undefined ?
                                    Array.isArray(colaborador.CN_RENTA_LIST) ? (
                                        Children.toArray(colaborador.CN_RENTA_LIST.map((item) => {
                                            return (
                                                <tr>
                                                    <td>{hiddenTd ? "****" : item.anio === "" ? "-" : item.anio}</td>
                                                    <td>{hiddenTd ? "****" : item.fecha_cambio === "" ? "-" : formatDate(item.fecha_cambio)}</td>
                                                    <td>{hiddenTd ? "****" : item.motivo_descr === "" ? "-" : item.motivo_descr}</td>
                                                    <td>{hiddenTd ? "****" : item.salario_base === "" ? "-" : dotNumber(item.salario_base)}</td>
                                                    <td>{hiddenTd ? "****" : item.perc_cambio === "" ? "-" : dotPerc(item.perc_cambio)}</td>
                                                </tr>
                                            );
                                        }))
                                    ) : (
                                        <tr>
                                            <td>{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.anio === "" ? "-" : colaborador.CN_RENTA_LIST.anio}</td>
                                            <td>{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.fecha_cambio === "" ? "-" : formatDate(colaborador.CN_RENTA_LIST.fecha_cambio)}</td>
                                            <td>{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.motivo_descr === "" ? "-" : colaborador.CN_RENTA_LIST.motivo_descr}</td>
                                            <td>{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.salario_base === "" ? "-" : dotNumber(colaborador.CN_RENTA_LIST.salario_base)}</td>
                                            <td>{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.perc_cambio === "" ? "-" : dotPerc(colaborador.CN_RENTA_LIST.perc_cambio)}</td>
                                        </tr>

                                    )
                                    :
                                    <tr>
                                        <td colSpan="7">No hay datos</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
                            {colaborador.CN_RENTA_LIST !== undefined ? (
                                Array.isArray(colaborador.CN_RENTA_LIST) ? (
                                    Children.toArray(Object.keys(cambioRentaporAno).reverse().map((year, yearIndex) => {
                                        return (
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id={"accordion-" + yearIndex}>
                                                    <button className={`accordion-button ${yearIndex === 0 ? " " : "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target={"#accordion-item-cambios-renta-" + yearIndex} aria-expanded={yearIndex === 0} aria-controls={"accordion-item-cambios-renta-" + yearIndex}>
                                                        <strong className="text-uppercase">Año</strong> {year}
                                                    </button>
                                                </h2>
                                                {
                                                    Children.toArray(cambioRentaporAno[year].map((item) => {
                                                        return (
                                                            <div id={"accordion-item-cambios-renta-" + yearIndex} className={`accordion-collapse collapse ${yearIndex === 0 ? "show" : " "}`} aria-labelledby={"accordion-" + yearIndex}>
                                                                <div className="accordion-body p-0">
                                                                    <div className="data-list-box data-list-box text-uppercase">
                                                                        <ul className="data-list-section--item list-unstyled">
                                                                            <li>
                                                                                <span className="data-list-box__title">Año</span>
                                                                                <span className="data-list-box__text">{hiddenTd ? "****" : item.anio === "" ? "-" : item.anio}</span>
                                                                            </li>
                                                                            <li>
                                                                                <span className="data-list-box__title">Fecha cambio</span>
                                                                                <span className="data-list-box__text">{hiddenTd ? "****" : item.fecha_cambio === "" ? "-" : formatDate(item.fecha_cambio)}</span>
                                                                            </li>
                                                                            <li>
                                                                                <span className="data-list-box__title">Motivo</span>
                                                                                <span className="data-list-box__text">{hiddenTd ? "****" : item.motivo_descr === "" ? "-" : item.motivo_descr}</span>
                                                                            </li>
                                                                            <li>
                                                                                <span className="data-list-box__title">Sal. Base</span>
                                                                                <span className="data-list-box__text">{hiddenTd ? "****" : item.salario_base === "" ? "-" : dotNumber(item.salario_base)}</span>
                                                                            </li>
                                                                            <li>
                                                                                <span className="data-list-box__title">% Cambio</span>
                                                                                <span className="data-list-box__text">{hiddenTd ? "****" : item.perc_cambio === "" ? "-" : dotPerc(item.perc_cambio)}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )

                                                    }))
                                                }
                                            </div>
                                        );
                                    }))
                                ) : (
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id={"accordion-" + colaborador.CN_RENTA_LIST.anio}>
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#accordion-item-cambios-renta-" + colaborador.CN_RENTA_LIST.anio} aria-expanded="true" aria-controls={"accordion-item-cambios-renta-" + colaborador.CN_RENTA_LIST.anio}>
                                                <strong className="text-uppercase">Año</strong> {colaborador.CN_RENTA_LIST.anio}
                                            </button>
                                        </h2>
                                        <div id={"accordion-item-cambios-renta-" + colaborador.CN_RENTA_LIST.anio} className="accordion-collapse collapse show" aria-labelledby={"accordion-" + colaborador.CN_RENTA_LIST.anio}>
                                            <div className="accordion-body p-0">
                                                <div className="data-list-box data-list-box text-uppercase">
                                                    <ul className="data-list-section--item list-unstyled">
                                                        <li>
                                                            <span className="data-list-box__title">Año</span>
                                                            <span className="data-list-box__text">{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.anio === "" ? "-" : colaborador.CN_RENTA_LIST.anio}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Fecha cambio</span>
                                                            <span className="data-list-box__text">{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.fecha_cambio === "" ? "-" : formatDate(colaborador.CN_RENTA_LIST.fecha_cambio)}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Motivo</span>
                                                            <span className="data-list-box__text">{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.motivo_descr === "" ? "-" : colaborador.CN_RENTA_LIST.motivo_descr}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Sal. Base</span>
                                                            <span className="data-list-box__text">{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.salario_base === "" ? "-" : dotNumber(colaborador.CN_RENTA_LIST.salario_base)}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">% Cambio</span>
                                                            <span className="data-list-box__text">{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.perc_cambio === "" ? "-" : dotPerc(colaborador.CN_RENTA_LIST.perc_cambio)}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <p>{"Sin Registros"}</p>
                            )}
                        </div>
                    </div>
                </div>

            </>);
    };

    return (
        <>

            {!loadingData ? <FichaRemuneracion hiddenTd={hiddenTd} setHiddenTd={setHiddenTd} colaborador={data} pdf={false} dataGraficoCambiosRenta={dataGraficoCambiosRenta} cambioRentaporAno={cambioRentaporAno} setCambioRentaporAno={setCambioRentaporAno} />
                : null
            }
            <div style={{ "visibility": "hidden", "position": "absolute", "top": "-9999px", "maxWidth": `${width - 50}px` }}>
                {dataGraficoCambiosRenta ? (
                    <Chart
                        id="graficoRemuneraciones2"
                        data={dataGraficoCambiosRenta.data}
                        type="line"
                        width={645}
                        height={null}
                        //plugins={[ChartDataLabels]}
                        options={dataGraficoCambiosRenta.options}
                    />
                ) : null}
            </div>
        </>
    )
};

export default Remuneraciones;