
import React from "react";
import textstyle from '../../../commons/styles/textStyles.module.css'
import rootstyle from '../../../commons/styles/rootStyle.module.css'
import articleStyles from '../styles/articleStyle.module.css'
import { checkBrowser } from "../../../utilities/helpers/browserChecker"
import ImgViewer from '../components/imgViewer/imgViewer'
import VideoModal from '../components/videoModal/videoModal'
import ReactDOM from "react-dom";
import { Button } from '@mui/material'
interface tagClassName {
  [key: string]: string,
}[]

const classNames: tagClassName = {
  'h1': articleStyles.article_title,
  'h2': articleStyles.article_subtitles,
  'h3': textstyle.page_subtitle,
  'h6': textstyle.content_subtitle,
  'ul': textstyle.custom_list,
  'li': textstyle.custom_li,
  'img': 'article_img',
  'iframe': 'article_video',
  'img-mobile': 'article_img_mobile',
  'img-desktop': 'article_img_desktop',
  'p': articleStyles.article_p
}

export const parseHtmlContent = (content: string, pageTitle: string): HTMLElement => {
  const htmlObject = document.createElement('div');
  htmlObject.innerHTML = content;
  addClassNames(htmlObject)
  extractVideotag(htmlObject)
  const title = createHtmlTitle(pageTitle)
  htmlObject.insertBefore(title, htmlObject.firstChild);
  return htmlObject
}

const addClassNames = (htmlObject: HTMLDivElement) => {
  const tags = Object.keys(classNames)
  tags.map((tag: string) => {
    const tagsList = htmlObject.getElementsByTagName(tag)
    if (tagsList.length === 0) return
    Array.from(tagsList).forEach((htmlElement, index: number) => {
      if (htmlElement.tagName === 'LI') {
        checkForLiStyle(htmlElement as HTMLLIElement)
        return
      }
      if (htmlElement.tagName === 'IMG') {
        createIMGViewer(htmlElement as HTMLImageElement, index)
      }
      htmlElement.classList.add(classNames[tag])
    });
  })
}

const checkForLiStyle = (htmlObject: HTMLLIElement) => {
  const browserName = checkBrowser()
  if (browserName === 'safari') {
    return
  } else {
    htmlObject.classList.add(classNames['li'])
  }
}

const extractVideotag = (htmlObject: HTMLDivElement) => {
  const childrens = htmlObject.children
  Array.from(childrens).map(element => {
    const content = element.innerHTML
    if (element.nodeName === 'P' && content.includes('iframe')) {
      const src = content.split(' ').find(text => text.includes('src'))
      const url = src?.split('"')[1]
      if (!url) return
      const htmlIframe = document.createElement('iframe');
      htmlIframe.setAttribute('src', url)
      htmlIframe.setAttribute('allowfullscreen', 'true')
      htmlIframe.classList.add('article_video')
      element.innerHTML = ''
      element.appendChild(htmlIframe)
    }
  })
}

const createHtmlTitle = (title: string) => {
  const htmlObject = document.createElement('h1');
  htmlObject.innerHTML = title;
  htmlObject.classList.add(classNames['h1'])
  return htmlObject
}

const createIMGViewer = (img: HTMLImageElement, index: number) => {
  const src = img.src.split('%20')
  let className = ''
  if(src[1] !== '') {
    className = classNames['img-' + src[1]]
  }
  const el = React.createElement(ImgViewer, 
    {
      imgProp: src[0],
      imgIndex: index,
      imgClass: className,
      imgStyle: img.style.cssText
    }
  )
  const parent = img.parentNode
  if(parent !== null ) {
    const div = document.createElement('div');
    div.classList.add('article_img')
    ReactDOM.render(el, div)
    parent.appendChild(div)
    parent.removeChild(img)
  }

}
