import React, {FC, Children, ReactNode} from "react";
import { ColumnProps, RowProps, TableProps, TitleDescriptionProps, TableComponentProps } from "./Helpers/interfaces/table";
const Table : FC<TableComponentProps> = ({ id, view, data : dataToDisplay }: TableComponentProps) => {
    
    return (
        dataToDisplay.length > 0 ?
            dataToDisplay.length === 1 ?
            <>
                <div id={id} className="d-none d-lg-block w-100">
                    <div className="box-scroll-sm">
                        <table className="table table-borderless align-middle">
                            <thead>
                                <tr>{Children.toArray(dataToDisplay[0].columns.map((column: ColumnProps) => <th>{column.title}</th>))}</tr>
                            </thead>
                            <tbody>
                                {dataToDisplay[0].rows.length > 0 && Children.toArray(dataToDisplay[0].rows.map((row: RowProps, rowIndex: number) => (
                                    <tr key={rowIndex}>
                                        {Children.toArray(dataToDisplay[0].columns.map((column: ColumnProps, index: number) => {
                                            const cellData = row[index];
                                            if (column.type === 'text' && typeof cellData === 'string') {
                                                return <td>{cellData}</td>;
                                            }
                                            else if (column.type === 'image' && typeof cellData === 'string' && cellData.indexOf('data:image') > -1) {
                                                return <td><img src={cellData} alt="" className="rounded-circle me-2" width="40" height="40"/></td>;
                                            }
                                            else if (column.type === 'boolean' && typeof cellData === 'boolean') {
                                                return <td><input type="checkbox" checked={cellData} disabled/></td>;
                                            }
                                            else if (column.type === 'title-description') {
                                                const values = cellData as TitleDescriptionProps;
                                                return  <td>
                                                    <strong className="d-block">{values.title}</strong>
                                                    <small className="d-block">{values.description}</small>
                                                </td>
                                            }
                                            return <td>{cellData as ReactNode}</td>;
                                        }))}
                                    </tr>
                                )))}
                                {dataToDisplay[0].rows.length === 0 && <tr><td>No hay registros disponibles</td></tr>}
                            </tbody>
                        </table>                 
                    </div>
                </div>
                <div className="d-block d-lg-none w-100">
                    <div className="box-scroll">
                        <div className="data-list-item d-flex">
                            <ul className="data-list-item--text list-unstyled ms-3 mb-0">
                                {dataToDisplay[0].rows.length > 0 && Children.toArray(dataToDisplay[0].rows.map((_: RowProps, rowIndex: number) => 
                                    <div className="data-list-item d-flex" key={rowIndex}>
                                        <ul className="data-list-item--text list-unstyled ms-3 mb-0">
                                            {Children.toArray(dataToDisplay[0].columns.map((column: ColumnProps, colIndex: number) => {
                                                const cellData = dataToDisplay[0].rows[rowIndex][colIndex];
                                                if (column.type === 'text' && typeof cellData === 'string') {
                                                    return <li>
                                                        <small className="text-uppercase d-block">{column.title}</small>
                                                        <p>{cellData}</p>
                                                    </li>;
                                                }
                                                else if (column.type === 'image' && typeof cellData === 'string' && cellData.indexOf('data:image') > -1) {
                                                    return <li>
                                                        <small className="text-uppercase d-block">{column.title}</small>
                                                        <p><img src={cellData} alt="" className="rounded-circle me-2" width="40" height="40"/></p>
                                                    </li>;
                                                }
                                                else if (column.type === 'boolean' && typeof cellData === 'boolean') {
                                                    return <li>
                                                        <small className="text-uppercase d-block">{column.title}</small>
                                                        <p><input type="checkbox" checked={cellData} disabled/></p>
                                                    </li>;
                                                }
                                                else if (column.type === 'title-description') {
                                                    const values = cellData as TitleDescriptionProps;
                                                    return  <li>
                                                        <small className="text-uppercase d-block">{column.title}</small>
                                                        <p>
                                                            <strong className="text-uppercase d-block">{values.title}</strong>
                                                            <small className="d-block">{values.description}</small>
                                                        </p>
                                                    </li>
                                                }
                                                return <li><small className="text-uppercase d-block">{column.title}</small><p>{cellData as ReactNode}</p></li>;
                                            }))}
                                        </ul>
                                    </div>
                                ))}
                                {dataToDisplay[0].rows.length === 0 && <li>No hay registros disponibles</li>}
                            </ul>
                        </div>
                    </div>
                </div>
            </>
            :
            <>
                <div className={`${view === 'table-responsive' ? 'd-none d-lg-none' : 'd-none d-lg-block'} w-100 mb-4 mb-xl-0`}>
                    <div className="box-scroll">
                        <ul className="nav nav-tabs" id="tab-ac" role="tablist">
                            {
                                Children.toArray(dataToDisplay.map((item : TableProps,  index: number) => {
                                    return (
                                        <li className="nav-item" role="presentation" key={item.id}>
                                            <button 
                                                className={`nav-link ${index === 0 ? 'active' : ''}`} 
                                                id={item.id+'-tab'} 
                                                data-bs-toggle="tab" 
                                                data-bs-target={`#${id+'-'+item.id}`} 
                                                type="button" 
                                                role="tab" aria-controls={id+'-'+item.id} aria-selected="true">
                                                    {item.title}
                                            </button>
                                        </li>
                                    );
                                }))
                            }
                        </ul>
                        <div className="tab-content" id="tab-ac-Content">
                            {Children.toArray(dataToDisplay.map((table : TableProps, index: number) => {
                                return <div className={`tab-pane fade ${index === 0 ? 'active show' : ''}`} id={id + '-' + table.id} role="tabpanel" aria-labelledby={id + '-' + table.id + "-tab"}>
                                    {table.rows.length > 0 ? (
                                        <table className="table table-borderless align-middle">
                                            <thead>
                                                <tr>{Children.toArray(table.columns.map((column: ColumnProps) => <th>{column.title}</th>))}</tr>
                                            </thead>
                                            <tbody>
                                                {Children.toArray(table.rows.map((row: RowProps) => (
                                                <tr>
                                                    {Children.toArray(table.columns.map((column: ColumnProps, indexColumn: number) => {
                                                        const cellData = row[indexColumn];
                                                        if (column.type === 'text' && typeof cellData === 'string') {
                                                            return <td>{cellData}</td>;
                                                        }
                                                        else if (column.type === 'image' && typeof cellData === 'string' && cellData.indexOf('data:image') > -1) {
                                                            return <td><img src={cellData} alt="" className="rounded-circle me-2" width="40" height="40"/></td>;
                                                        }
                                                        else if (column.type === 'boolean' && typeof cellData === 'boolean') {
                                                            return <td><input type="checkbox" checked={cellData} disabled/></td>;
                                                        }
                                                        else if (column.type === 'title-description') {
                                                            const values = cellData as TitleDescriptionProps;
                                                            return  <td>
                                                                <strong className="d-block">{values.title}</strong>
                                                                <small className="d-block">{values.description}</small>
                                                            </td>
                                                        }
                                                        return <td>{cellData as ReactNode}</td>;
                                                    }))}
                                                </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <p className="mt-2">No hay registros disponibles</p>
                                    )}
                                </div>
                            }))}
                        </div>     
                    </div>
                </div>
                <div className={`${view === 'table-responsive' ? 'd-block d-lg-block' : 'd-block d-lg-none'} w-100 mb-4 mb-xl-0`}>
                    <div className="accordion accordion-flush" id="accordion-ad">
                        {Children.toArray(dataToDisplay.map((table: TableProps, index: number) => {
                            return (
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id={id + '-' + "accordion-ad-heading-" + index}>
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#" + id + '-' + "accordion-item-" + index} aria-expanded={index === 0 ? "true" : "false"} aria-controls={id + '-' + "accordion-item-" + index}>
                                            {table.title}
                                        </button>
                                    </h2>
                                    <div id={id + '-' + "accordion-item-" + index} className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`} aria-labelledby={id + '-' + "accordion-ad-heading-" + index}>
                                        <div className="accordion-body">
                                            <div className="box-scroll">
                                                {Children.toArray(table.rows.map((row: RowProps, rowIndex: number) => {
                                                    return (
                                                        <div className="data-list-item d-flex">
                                                            <ul className="data-list-item--text list-unstyled ms-3 mb-0">
                                                                {table.columns.map((column: ColumnProps, colIndex: number) => {
                                                                    const cellData = row[colIndex];
                                                                    if (column.type === 'text' && typeof cellData === 'string') {
                                                                        return <li>
                                                                            <small className="text-uppercase d-block">{column.title}</small>
                                                                            <p>{cellData}</p>
                                                                        </li>;
                                                                    }
                                                                    
                                                                    else if (column.type === 'image' && typeof cellData === 'string' && cellData.indexOf('data:image') > -1) {
                                                                        return <li>
                                                                            <small className="text-uppercase d-block">{column.title}</small>
                                                                            <p><img src={cellData} alt="" className="rounded-circle me-2" width="40" height="40"/></p>
                                                                        </li>;
                                                                    }
                                                                    else if (column.type === 'boolean' && typeof cellData === 'boolean') {
                                                                        return <li>
                                                                            <small className="text-uppercase d-block">{column.title}</small>
                                                                            <p><input type="checkbox" checked={cellData} disabled/></p>
                                                                        </li>;
                                                                    }
                                                                    else if (column.type === 'title-description' ) {
                                                                        const values = cellData as TitleDescriptionProps;
                                                                        return  <li>
                                                                            <small className="text-uppercase d-block">{column.title}</small>
                                                                            <p>
                                                                                <strong className="text-uppercase d-block">{values.title}</strong>
                                                                                <small className="d-block">{values.description}</small>
                                                                            </p>
                                                                        </li>
                                                                    }
                                                                    return <li>
                                                                        <small className="text-uppercase d-block">{column.title}</small>
                                                                        <p>{cellData as ReactNode}</p>
                                                                    </li>;
                                                                })}
                                                            </ul>
                                                        </div>
                                                    );
                                                }))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }))}
                    </div>
                </div>
            </>
            : <p className="mt-2">No hay información para mostrar</p>
    );
}

export default Table;