import Auth from "@aws-amplify/auth";
import { urls, urlsAws } from "../resources/foo/api-endpoints";

const handleCache = async (key, data, setData) => {
    const cache = await caches.open("api-cache");
    cache.put(key, new Response(JSON.stringify(data)));
    if (setData) setData(data);
};
const fetchData = async (url, method, body) => {
    const auth = await Auth.currentSession();
    const requestOptions = {
        method,
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: auth.getIdToken().getJwtToken(),
            AccessToken: auth.getAccessToken().getJwtToken(),
        },
        body: body ? JSON.stringify(body) : null,
    };
    return await fetch(url, requestOptions).then((response) => response.json()).catch((error) => { console.log(error); return false; });
};

const noticiasService = {
    listaBannerDestacado: async (setData, setStatus, banner) => {
        const cacheResponse = await caches.match(urls.getNoticiasDestacadas + "?banner=" + banner)
            .then((response) => response.json())
            .then((data) => {
                if (data && data.length > 0 && data[0].id) {
                    return data;
                }
                return false;
            })
            .catch(() => false);
        if (cacheResponse) {
            setData(cacheResponse);
            setStatus(true);
        } else {
            const data = await fetchData(urls.getNoticiasDestacadas + "?banner=" + banner, "POST");
            if (data && data.response && data.response.valid === 1 && data.response.noticias.length > 0) {
                let newArray;
                if (banner) {
                    newArray = data.response.noticias.map(noticia => {
                        noticia.banner_image_small = noticia.banner_image_small.split("base64,")[1];
                        return noticia;
                    });
                } else {
                    newArray = data.response.noticias.map(noticia => {
                        noticia.banner_image = noticia.banner_image.split("base64,")[1];
                        return noticia;
                    });
                }

                await handleCache(urls.getNoticiasDestacadas + "?banner=" + banner, newArray, setData);
                setStatus(true);
            } else {
                setData(1);
            }
        }
    },
    getInfoNoticias: async (setDataListadoNoticias, setSiguientePagina, siguientePagina, dataNoticias, setEnd) => {
        const data = await fetchData(urls.getInfoNoticias, "POST", { pagina: siguientePagina });
        if (data && data.response && data.response.valid === 1) {
            const { response: { noticias, sigPagina } } = data;
            if (noticias && noticias.length > 0) {
                const noticiasMapped = noticias.map((element) => ({
                    id: element.id,
                    titulo: element.title,
                    vig_desde: element.start_date,
                    vig_hasta: element.end_date,
                    destacada: element.is_featured,
                    imagen: element.banner_image_small.split(";base64")[1],
                    pagina: sigPagina
                }));
                const dataNoticiasToSet = dataNoticias ? [...dataNoticias, ...noticiasMapped] : noticiasMapped;
                setSiguientePagina(sigPagina);
                if(dataNoticiasToSet.length > 0) handleCache(`${urls.getInfoNoticias}`, dataNoticiasToSet, setDataListadoNoticias);
            } else {
                setDataListadoNoticias(dataNoticias);
                setEnd(true);
            }
        } else {
            setDataListadoNoticias(dataNoticias);
            setEnd(true);
        }
    },
    getNoticiasFull: async (id, setDataNoticiaFull) => {
        const data = await fetchData(urls.getListadoNoticiaUniqueLocal, "POST", { id });
        if (data && data.response && data.response.valid === 1) {
            const noticias = data.response.noticias;
            const dataLocal = {
                id: noticias.id,
                titulo: noticias.title,
                vig_desde: noticias.start_date,
                vig_hasta: noticias.end_date,
                imagen: noticias.banner_image_small.split(";base64,")[1],
                imagen_grande: noticias.banner_image.split(";base64,")[1],
                contenido: noticias.content,
                like: data.response.like,
                archivo: noticias.file !== null && noticias.file !== "null" ? noticias.file.split(";base64,")[1] : "",
                status: noticias.status,
                total_like: data.response.total_like,
                visible_title: noticias.visible_title,
            };
            handleCache(urls.getListadoNoticiaUniqueLocal + "?id=" + id, dataLocal, setDataNoticiaFull);

        }
    },
    getComentarios: async (id, setComentarios, setEstado) => {
        setEstado(true);
        const data = await fetchData(urls.getComentarios, "POST", { id });
        if (data && data.response && data.response.valid === 1) {
            setComentarios(data.response.comentarios);
            setEstado(false);
        } else {
            setEstado(false);
            setComentarios(false);
        }
    },
    addLikeNoticia: async (id) => {
        const data = await fetchData(urls.addLikeNoticia, "POST", { id });
        if (data && data.response && data.response.valid === 1) {
            caches.match(urls.getListadoNoticiaUniqueLocal + "?id=" + id).then(function (response) {
                if (response) {
                    response.json().then(function (data) {
                        data.like = 1;
                        data.total_like = data.total_like + 1;
                        caches.delete(urls.getListadoNoticiaUniqueLocal + "?id=" + id);
                        handleCache(urls.getListadoNoticiaUniqueLocal + "?id=" + id, data);
                    });
                }
            });
        }
    },
    substractLikeNoticia: async (id) => {
        const data = await fetchData(urls.substractLikeNoticia, "POST", { id });
        if (data && data.response && data.response.valid === 1) {
            caches.match(urls.getListadoNoticiaUniqueLocal + "?id=" + id).then(function (response) {
                if (response) {
                    response.json().then(function (data) {
                        data.like = 0;
                        data.total_like = data.total_like - 1;
                        caches.delete(urls.getListadoNoticiaUniqueLocal + "?id=" + id);
                        handleCache(urls.getListadoNoticiaUniqueLocal + "?id=" + id, data);
                    });
                }
            });
        }
    },
    sendComentario: async (id, comentario, setEstadoEnviarComentario, setEstadoGuardado, setComentarios) => {
        const data = await fetchData(urls.sendComentario, "POST", { comentario, id });
        if (data && data.response && data.response.valid === 1) {
            setEstadoEnviarComentario("enviado");
            setEstadoGuardado(true);
            setComentarios(data.response.comentarios);
            document.getElementById("comentario-btn").click();
        } else {
            setEstadoGuardado(false);
            setEstadoEnviarComentario("error");
            document.getElementById("comentario-btn").click();
        }
    },
    eliminarComentario: async (id, setestadoEliminado, setComentarios) => {
        const data = await fetchData(urls.eliminarComentario, "POST", { id });
        if (data && data.response && data.response.valid === 1) {
            setestadoEliminado(true);
            setComentarios(data.response.comentarios);
            document.getElementById("comentario-btn").click();
        } else {
            setestadoEliminado(false);
            document.getElementById("comentario-btn").click();
        }
    }
};

export default noticiasService;
