import { useTranslation } from "react-i18next";
import { ColumnsConfig } from "../../../../components/table";
import { useProfileSummaryProvider } from "../../context/EmployeeProfileSummaryContext";
import { LayoutCard } from "../employeeProfileSummary/LayoutCard";
import { useEffect } from "react";

export const VacationMenu = () => {
  const { summaryEmployee, countryCode } = useProfileSummaryProvider();
  const { t, i18n  } = useTranslation();

  useEffect(() => {
    if (countryCode) {
      i18n.changeLanguage(countryCode);
    }
  }, [countryCode, i18n]);

  let columnsConfigVacations: ColumnsConfig[] = [
    {
      accessor: "period",
      header: t("myteam.vacation.data.table.period"),
      className: "text-center",
    },
    {
      accessor: "previousPeriodCarryoverDays",
      header: t("myteam.vacation.data.table.previousPeriodCarryoverDays"),
      className: "text-center",
    },
    {
      accessor: "normalDays",
      header: t("myteam.vacation.data.table.normalDays"),
      className: "text-center",
    },
    {
      accessor: "progressiveDays",
      header: t("myteam.vacation.data.table.progressiveDays"),
      className: "text-center",
      showColumn: countryCode === "CL",
    },
    {
      accessor: "daysTaken",
      header: t("myteam.vacation.data.table.daysTaken"),
      className: "text-center",
    },
    {
      accessor: "adjustment",
      header: t("myteam.vacation.data.table.adjustment"),
      className: "text-center",
    },
    {
      accessor: "balance",
      header: t("myteam.vacation.data.table.balance"),
      className: "text-center",
    },
  ];

  if (summaryEmployee.isLoading) return <></>;

  return (
    <div className="col-12 col-xl-12 d-flex">
      <div className="box w-100">
            <LayoutCard 
            title={t("myteam.vacation.data.table.title")}
            columnsConfig={columnsConfigVacations} 
            data={ summaryEmployee.data.vacations }/>
      </div>
    </div>
  );
};
