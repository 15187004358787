import { ChangeEvent, Children, FC, useEffect } from "react";
import { Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import { ContentNewsProps } from "../../../../../utilities/interfaces";
import SelectLinks from "./selectLinks/SelectLinks";
import DynamicSelect from "../../../../../commons/DynamicSelect";
import { CalculateTimeByDate } from "../../../../../../../services/utiles";

const ContentNews: FC<ContentNewsProps> = ({
    textDescr,
    textCond,
    history,
    selectModule,
    arrayLinks,
    isEditBenef,
    paramFilter,
    errorUrl,
    errors,
    control,
    setSelectModule,
    setArrayLinks,
    setTextDescr,
    setTextCond,
    register,
    setValue,
    trigger
}) => {

    const modulos = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            ["link", "image"],
            ["clean"],
        ],
    };

    const modulosCond = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            ["link"],
            ["clean"],
        ],
    };

    const formatos = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
    ];

    const handleChangeDescription = (text: string, field: any) => {
        if (text === '<p><br></p>' || text === '<p> </p>' || text.trim() === "") {
            setTextDescr('');
            setValue('description', "")
        } else {
            setTextDescr(text);
            setValue('description', text)
            field.onChange(text);
        }
        trigger('description');
    };

    const handleChangeCondition = (text: string, field: any) => {
        if (text === '<p><br></p>' || text === '<p> </p>' || text.trim() === "") {
            setTextCond('');
            setValue('condition', "")
        } else {
            setTextCond(text);
            setValue('condition', text)
            field.onChange(text);
        }
        trigger('condition');
    };

    return (
        <>
            <div className="box mb-4">
                <div className="py-4 mb-5">
                    <h2 className="h4 tit-section">Descripción *
                        <button type="button" className="btn btn-benefit" data-bs-toggle="tooltip" data-bs-placement="right" title={"Si agrega una imagen, esta no debe superar los 100 KB."}>
                            <i className="fa fa-info-circle fa-fw color-primary fa-lg" aria-hidden="true"></i>
                        </button>
                    </h2>
                    <div className="row w-auto">
                        <div className="col-8 col-md-8">
                            <Controller
                                name="description"
                                control={control}
                                rules={{
                                    required: 'Este campo es requerido',
                                    validate: value => value.trim() !== '' || 'Ingresar subtítulo / bajada'
                                }}
                                defaultValue=""
                                render={({ field }) => (
                                    <ReactQuill
                                        {...field}
                                        theme="snow"
                                        value={textDescr}
                                        onChange={(content) => {
                                            handleChangeDescription(content, field);
                                        }}
                                        modules={modulos}
                                        formats={formatos}
                                        style={{ height: '250px', marginBottom: '5%' }}
                                    />
                                )}
                            />
                            {errors["description"] !== undefined && <div style={{ display: "block" }} className="invalid-feedback">{errors.description.message}</div>}
                        </div>

                        <div className="col-4 col-md-4">
                            {isEditBenef ?
                                <>
                                    <h3 className="h5 py-2 border-top border-bottom border-light fw-bold">Historial</h3>
                                    <div className="box-scroll">
                                        <ul className="history list-unstyled mt-4">
                                            {history && history.length > 0 ? Children.toArray(history.map((hist) => {

                                                if (hist.full_name !== '') {
                                                    return (
                                                        <>
                                                            <li><span>Actualizado</span> Por {hist.full_name}, {CalculateTimeByDate(hist.created_at)}</li>
                                                        </>
                                                    )
                                                } else {
                                                    return (
                                                        <li><span>Sin cambios.</span></li>
                                                    )
                                                }
                                            })) : <li><span>Sin cambios.</span></li>}
                                        </ul>
                                    </div>

                                </>
                                :
                                <>
                                </>}
                        </div>
                    </div>
                </div>

                <div className="py-4 mb-5">
                    <h2 className="h4 tit-section">Condiciones</h2>
                    <div className="row w-auto">
                        <div className="col-12 col-md-8">
                            <Controller
                                name="condition"
                                control={control}
                                rules={{ required: false }}
                                defaultValue=""
                                render={({ field }) => (
                                    <ReactQuill
                                        {...field}
                                        theme="snow"
                                        value={textCond}
                                        onChange={(content) => {
                                            handleChangeCondition(content, field);
                                        }}
                                        modules={modulosCond}
                                        formats={formatos}
                                        style={{ height: '250px', marginBottom: '5%' }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>

                <SelectLinks arrayLinks={arrayLinks} setArrayLinks={setArrayLinks} />
                {errorUrl !== '' && <div style={{ display: "block" }} className="invalid-feedback">{errorUrl}</div>}

                <div className="row justify-content-end mt-3">
                    <div className="col-auto">
                        <div className="form-check">
                            <input className="form-check-input" type="radio" value="published" id="flexRadioDefault1"
                                {
                                ...register('status', {
                                    required: "Seleccione una opción",
                                })
                                } />
                            <label className="form-check-label">
                                Publicado
                            </label>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-check">
                            <input className="form-check-input" type="radio" value="draft" id="flexRadioDefault2"
                                {
                                ...register('status', {
                                    required: "Seleccione una opción",
                                })
                                } />
                            <label className="form-check-label">
                                Borrador
                            </label>
                        </div>
                        {errors["status"] !== undefined && <div style={{ display: "block" }} className="invalid-feedback">{errors.status.message}</div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContentNews;