import React, { useState, useEffect } from 'react';
import MiEquipoService from '../../../services/mi-equipo/mi-equipo-service';

const usePosition = (employee, country) => {
    const [position, setPosition] = useState();
    const [statusPosition, setStatusPosition] = useState();
    const [loadingPosition, setLoadingPosition] = useState(true)

    const getPuesto = async () => {
        const data = await MiEquipoService.getDatosPuesto(employee, country);
        setPosition(data.data[0]);
        setStatusPosition(data.status);
        setLoadingPosition(false)

    }
    useEffect(() => {
        getPuesto();
    }, []);

    return { position, statusPosition, loadingPosition };
};

export default usePosition;