import React, { useState, useEffect } from 'react';
import MiEquipoService from '../../../services/mi-equipo/mi-equipo-service';

const useDiasTomados = (employee, country) => {
    const [diasTomados, setDiasTomados] = useState();
    const [statusDiasTomados, setStatusDiasTomados] = useState();
    const [loadingDiasTomados, setLoadingDiasTomados] = useState(true);
    const getDiasTomados = async () => {
        const data = await MiEquipoService.getDiasTomados(employee, country);
        setDiasTomados(data.data);
        setStatusDiasTomados(data.status);
        setLoadingDiasTomados(false);
    }

    useEffect(() => {
        getDiasTomados();

    }, []);
    return { diasTomados, statusDiasTomados, loadingDiasTomados };
};

export default useDiasTomados;