import Tippy from "@tippyjs/react";
import React, { useState } from "react";
import moment from "moment/moment.js";
import { ButtonsRegistroViaje } from "./ButtonsRegistroViaje";
import { FieldDateCmp } from "../commonComponents/FieldDateCmp";
import { DropdowCmp } from "../commonComponents/DropdowCmp";
import { CommentsCmp } from "../commonComponents/CommentsCmp";



const countrys = [
  { value: "ARG", label: "Argentina" },
  { value: "BRA", label: "Brasil" },
  { value: "CHL", label: "Chile" },
  { value: "COL", label: "Colombia" },
  { value: "PER", label: "Perú" },
  { value: "USA", label: "EEUU" },
  { value: "OTH", label: "Otros" },
];

export const SolicitudRegistroViaje = ({
  RegistroViaje,
  setRegistroViaje,
  RegistroViajeData,
  statusRequest,
  setstatusRequest,
}) => {
  const { beginDt, endDt, country, comments } = RegistroViaje;

  return (
    <div className="tit-group d-block justify-content-between row">
      <div className="d-block d-sm-flex justify-content-between">
        <h3>
          Viajes de trabajo
          <div className="title-underlined"></div>
        </h3>
        <ButtonsRegistroViaje
          setRegistroViaje={setRegistroViaje}
          RegistroViaje={RegistroViajeData}
          statusRequest={statusRequest}
          setstatusRequest={setstatusRequest}
        />
      </div>
      <form className="mt-4 justify-content-center align-items-center">
        <div className="row">
          <FieldDateCmp
            label="Fecha de Inicio"
            callback={(e) =>
              setRegistroViaje({
                ...RegistroViaje,
                beginDt: e,
              })
            }
            valueState={beginDt}
            Usetippy={false}
          />

          <FieldDateCmp
            label="Fecha de Finalizacion"
            callback={(e) =>
              setRegistroViaje({
                ...RegistroViaje,
                endDt: e,
              })
            }
            valueState={endDt}
            name="endDt"
          />

          <DropdowCmp
            className="col-12 col-md-4 mb-3"
            label="Destino"
            callback={(e) => setRegistroViaje({ ...RegistroViaje, country: e })}
            stateValue={country}
            valueList={countrys}
            tippy={true}
            textTippy=" Seleccione el país de destino"
          />
        </div>
        

        <CommentsCmp
          label="Justificación de Viaje"
          stateValue={comments}
          callback={(e) =>
            setRegistroViaje({
              ...RegistroViaje,
              comments: e,
            })
          }
        />
      </form>
    </div>
  );
};
