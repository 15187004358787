import { Auth } from "aws-amplify";
import axios from "axios";

const url = process.env.REACT_APP_BACKEND_URL_GLOBAL;

// Backend localhost
const backend_localhost_url_base = "http://localhost:5000";
// const getEhicalCode_URL =
//   backend_localhost_url_base + "/api/ethicalcode/ethicalcode";
// const sign_URL = backend_localhost_url_base + "/api/ethicalcode/sign";
// const downloadPdf_URL =
//   backend_localhost_url_base + "/api/ethicalcode/downloadpdf";
// const downloadPdfByPdf_URL =
//   backend_localhost_url_base + "/api/ethicalcode/downloadpdf";
// const getPdfs_URL = backend_localhost_url_base + "/api/ethicalcode/pdfs";
// const uploadPdf_URL = backend_localhost_url_base + "/api/ethicalcode/uploadpdf";

// Backend beanstalk
// const backend_beanstalk_url_base = "http://autoservicio-bff-col.us-east-1.elasticbeanstalk.com";
// const getEhicalCode_URL = backend_beanstalk_url_base + "/api/ethicalcode/ethicalcode";
// const sign_URL = backend_beanstalk_url_base + "/api/ethicalcode/sign";
// const downloadPdf_URL = backend_beanstalk_url_base + "/api/ethicalcode/downloadpdf";
// const downloadPdfByPdf_URL = backend_beanstalk_url_base + "/api/ethicalcode/downloadpdf";
// const getPdfs_URL = backend_beanstalk_url_base + "/api/ethicalcode/pdfs";
// const uploadPdf_URL = backend_beanstalk_url_base + "/api/ethicalcode/uploadpdf";

// Gateway
const backend_gateway_url_base = url;
const getEhicalCode_URL =
  backend_gateway_url_base + "/api/ethicalcode/ethicalcode";
const sign_URL = backend_gateway_url_base + "/api/ethicalcode/sign";
const downloadPdf_URL =
  backend_gateway_url_base + "/api/ethicalcode/downloadpdf";
const downloadPdfByPdf_URL =
  backend_gateway_url_base + "/api/ethicalcode/downloadpdf";
const getPdfs_URL = backend_gateway_url_base + "/api/ethicalcode/pdfs";
const uploadPdf_URL = backend_gateway_url_base + "/api/ethicalcode/uploadpdf";

const getHeader = () => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(async (auth) => {
        const header = {
          "Content-Type": "application/json",
          accesstoken: auth.getAccessToken().getJwtToken(),
        };
        resolve(header);
      })
      .catch(function (error) {
        return reject(error);
      });
  });
};

const EthicalCodeServices = {
  getEhicalCode: async () => {
    try {
      const header = await getHeader();

      const response = await axios.get(getEhicalCode_URL, {
        headers: header,
      });

      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },
  sign: async () => {
    try {
      const formData = new FormData();
      const header = await getHeader();

      const data = await axios({
        method: "post",
        url: sign_URL,
        data: formData,
        headers: {
          "Content-Type": `multipart/form-data;`,
          accesstoken: header.accesstoken,
        },
      });

      return data;
    } catch (error) {
      return error?.response?.data;
    }
  },
  downloadPdf: async (pais) => {
    try {
      const header = await getHeader();

      const url = `${downloadPdf_URL}${pais}`;

      const response = await fetch(url, {
        headers: header,
      }).then((res) => res.arrayBuffer());

      return response;
    } catch (error) {
      error?.response?.data;
    }
  },
  downloadPdfById: async (id, pais) => {
    try {
      const header = await getHeader();

      const url = `${downloadPdfByPdf_URL}${pais}/${id}`;

      const response = await fetch(url, {
        headers: header,
      }).then((res) => res.arrayBuffer());

      return response;
    } catch (error) {
      error?.response?.data;
    }
  },
  getPdfs: async () => {
    try {
      const header = await getHeader();

      const { data } = await axios.get(getPdfs_URL, {
        headers: header,
      });

      return data;
    } catch (error) {
      error?.response?.data;
    }
  },
  uploadPdf: async (dto, file, pais) => {
    try {
      const formData = new FormData();
      const header = await getHeader();

      formData.append("description", dto.description);
      formData.append("file", file);

      const data = await axios({
        method: "post",
        url: `${uploadPdf_URL}${pais}`,
        data: formData,
        headers: {
          "Content-Type": `multipart/form-data;`,
          accesstoken: header.accesstoken,
        },
      });

      return data;
    } catch (error) {
      return error?.response?.data;
    }
  },
};

export default EthicalCodeServices;
