import Auth from "@aws-amplify/auth";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import * as t from "io-ts";
import { urlsAws } from "../../../resources/foo/api-endpoints";
import { HttpRequestError, HttpContentTypeError, HttpResponseStatusError, HttpClientEnv } from "../../../services/apiServices/interfaces";
import { getJson } from "../../../services/apiServices/httpClient";
import { DonationPost, fetchGetDonateListCodec, fetchPostDonateListCodec, ResponseGetDonate, ResponsePostDonate } from "./interfaces";

export const GetDonations = async (type: string) => {
    const params = "?type=" + type;
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: "GET",
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        ResponseGetDonate
    > = getJson(urlsAws.donations, params, requestOptions, fetchGetDonateListCodec.decode)
    return response;
};

export const PostDonations = async (body: DonationPost) => {
    const params: string = ""
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
        body: JSON.stringify(body)
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        ResponsePostDonate
    > = getJson(urlsAws.donations, params, requestOptions, fetchPostDonateListCodec.decode)
    return response;
};