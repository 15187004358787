import React, { useState, useEffect } from "react";
import axios from "axios";
import Auth from "@aws-amplify/auth";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import styles from "../../resources/css/style-COL.module.css";

const BloquearCredito = () => {
  const [data, setData] = useState(null);
  const [loader, setLoader] = useState(true);

  const bloquearAprobacion = async () => {
    const auth = await Auth.currentSession();
    const head = {
      "Content-Type": "application/json",
      AccessToken: auth.getAccessToken().getJwtToken(),
    };

    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/creditapproval/disapprovedcredit`,
        { headers: head }
      )
      .then((response) => {
       
        if (response.status == 200) {
          Swal.fire({
            allowOutsideClick: false,
            position: "center",
            title:
              '<div style="text-align: center; color: green; font-size: 16px"> Informacion </div>',
            html: `<div style="text-align: center; font-size: 15px"> ${ response.data.message || response.data}</div>`,
            confirmButtonColor: "#11427C",
          });
        }
      })
      .catch((error) => {
        console.log(error)
        Swal.fire({
          allowOutsideClick: false,
          position: "center",
          title:
            '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
          html: `<div style="text-align: center; font-size: 15px"> ${error}</div>`,
          confirmButtonColor: "#11427C",
        });
      });
  };

  useEffect(async () => {
    setLoader(true)
    try {
      const auth = await Auth.currentSession();
      const head = {
        "Content-Type": "application/json",
        AccessToken: auth.getAccessToken().getJwtToken(),
      };
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/creditapproval`
         ,
          { headers: head }
        )
        .then((response) => {
          
          if (response.data.length > 0) setData(response.data);
        })
        .catch((error)=>console.log(error))
        .finally(()=> setLoader(false))
    } catch (error) {
      setData(null);
      console.log("Error UseEffect:", error);
    }
  }, []);

  return (
    <>
      <nav className={styles["breadcrumbs"]} aria-label="breadcrumb">
        <ol className={styles["breadcrumb"]}>
          <li className={styles["breadcrumb-item"]}>
            <Link to="/inicio">
              <i className="fa fa-home" aria-hidden="true"></i> Inicio
            </Link>
          </li>
          <li className={styles["breadcrumb-item"]}>
            Opciones de cr&eacute;dito
          </li>
          <li className={`${styles["breadcrumb-item"]} ${styles["active"]} ${styles["prueba"]}`} aria-current="page">
            Bloquear credito
          </li>
        </ol>
      </nav>

      <section className={styles["aprobar-credito"]}>
          <h1>Bloqueo de Cr&eacute;dito</h1>
          {loader ? (
                      <div data-testid="loading" className={styles["full-spinner"]}>
                        <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>{" "}
                      </div>
                    ) : (
          <form>
            <div className={styles["box"]}>
              <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Bloqueo de Cupo de Cr&eacute;dito</h2>
              <h3 className={`${styles["h5"]} ${styles["fw-bold"]} ${styles["color-normal"]} ${styles["mb-3"]}`}>Datos del usuario</h3>

              <div className={styles["table-responsive"]}>
                <table className={`${styles["table"]} ${styles["table-even"]} ${styles["table-section"]} ${styles["table-borderless"]} ${styles["table-vertical-middle"]} ${styles["d-none"]} ${styles["d-md-table"]}`}>
                  <thead>
                    <tr>
                      <th>Cédula</th>
                      <th>Nombre</th>
                      <th>Cupo Asignado</th>
                      <th>Estado Actual</th>
                      <th>Saldo Disponible</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data != null &&
                      data.map((e, key) => {
                        return (
                          <tr key={key}>
                            <td>{e.cedula}</td>
                            <td>{e.nombre}</td>
                            <td>$ {e.cupo}</td>
                            <td>{e.nombreEstado}</td>
                            <td>${e.saldo}</td>
                          </tr>
                        );
                      })}
                    {data == null && <span>Sin datos</span>}
                  </tbody>
                </table>
              </div>
              <div className={`${styles["accordion"]} ${styles["accordion-flush"]} ${styles["accordion-light"]} ${styles["accordion-table"]} ${styles["d-md-none"]}`}>
                {data != null &&
                  data.map((e, key) => {
                    return (
                      
                      <div className={styles["accordion-item"]}>
                  <h2 className={styles["accordion-header"]} id="accordion">
                    <button
                      className={styles["accordion-button"]}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-item-1"
                      aria-expanded="true"
                      aria-controls="accordion-item-1"
                      key={key}
                    >
                      <strong>Cédula</strong> {e.cedula}
                    </button>
                  </h2>
                  <div
                    id="accordion-item-1"
                    className={`${styles["accordion-collapse"]} ${styles["collapsed"]} ${styles["show"]}`}
                    aria-labelledby="accordion"
                  >
                    <div className={`${styles["accordion-body"]} ${styles["p-0"]}`}>
                      <div className={`${styles["data-list-box"]} ${styles["data-list-box-even"]}`}>
                        <ul className={`${styles["data-list-section--item"]} ${styles["list-unstyled"]}`}>
                          <li>
                            <span className={styles["data-list-box__title"]}>Nombre</span>
                            <span className={styles["data-list-box__text"]}>
                            {e.nombre}
                            </span>
                          </li>
                          <li>
                            <span className={styles["data-list-box__title"]}>
                              Cupo Asignado
                            </span>
                            <span className={styles["data-list-box__text"]}>$ {e.cupo}</span>
                          </li>
                          <li>
                            <span className={styles["data-list-box__title"]}>
                              Estado Actual
                            </span>
                            <span className={styles["data-list-box__text"]}>
                            {e.nombreEstado}
                            </span>
                          </li>
                          <li>
                            <span className={styles["data-list-box__title"]}>
                              Saldo Disponible
                            </span>
                            <span className={styles["data-list-box__text"]}>${e.saldo}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                     
                      
                    );
                  })}
                {data == null && <span>Sin datos</span>}

                
              </div>
            </div>

            <div className={styles["my-5"]}>
              <p>
                (<span className={styles["color-red"]}>***</span>) Unicamente Bloquee el Cupo si
          lo tiene Activo.
              </p>
              <p>
                (<span className={styles["color-red"]}>***</span>) Esta opcion permite bloquear
          en Cajas, el uso de cupo Aprobado.
              </p>
              <p>
                (<span className={styles["color-red"]}>***</span>)  Recuerde que si usted acepta
          bloquear su cupo, no podra hacer uso de este beneficio hasta el
          siguiente corte de liquidacion(Cierre de Nomina).
              </p>
            </div>

            <div className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mt-4"]}`}>
              <div className={`${styles["col-12"]} ${styles["col-md-auto"]}`}>
                <button
                  onClick={bloquearAprobacion}
                  type="button"
                  className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["w-100"]}`}
                >
                  Bloquear{" "}
                  <i
                    className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
            </div>
          </form>
                    )}
        </section>
    </>
  );
};
export default BloquearCredito;
