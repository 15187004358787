import React, { useEffect, useState } from 'react';
import { Text, Image, StyleSheet, View, Page, Document, PDFDownloadLink } from "@react-pdf/renderer";
import defaultColaboradorImage from "../../../resources/images/default-colaborador-image.png";
import { formatDate } from "../../../services/utiles";

const DatosPuesto = ({
    colaborador,
    foto,
    statusPosition,
    loadingPosition
}) => {
    const DatosPuestoPDF = () => {
        const styles = StyleSheet.create({
            page: {
                backgroundColor: '#fff',
                padding: '20px',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
            },
            card: {
                padding: "20px",
                borderRadius: "10px",
                width: "48%",
                border: "1px solid #eeeeee",
                margin: " 0px 5px 20px 5px",
                height: "auto",
            },
            head: {
                display: "flex",
                marginBottom: "20px",
                paddingLeft: "10px",
            },
            body: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "20px"
            },
            h1: {
                fontSize: "16px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: "10px",
                color: "#0169b5"
            },
            table: {
                width: "100%",
                borderCollapse: "collapse",
                borderSpacing: "0",
                marginBottom: "20px"
            },
            tableHead: {
                backgroundColor: "#eeeeee",
                borderBottom: "1px solid #eeeeee",
                borderTop: "1px solid #eeeeee",
                borderLeft: "1px solid #eeeeee",
                borderRight: "1px solid #eeeeee",
                padding: "5px",
                fontSize: "8px",
            },
            tableBody: {
                borderBottom: "1px solid #eeeeee",
                borderLeft: "1px solid #eeeeee",
                borderRight: "1px solid #eeeeee",
                fontSize: "8px",
                textAlign: "center",
                color: "#777777",
            },
            tableRow: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                padding: "5px 5px 5px 5px",
                border: "1px solid #eeeeee",
            },
            labelHead: {
                textAlign: "center",
                color: "#777777",
                fontWeight: "900",
            },
            labelBody: {
                textAlign: "center",
                color: "#777777",
                fontWeight: "normal",
            },
            li: {
                display: "flex",
                flexDirection: "row",
                alignItems: 'flex-start',
                padding: "10px",
                borderBottom: "1px solid #eeeeee",
                position: "relative",
                justifyContent: "flex-start"
            },
            liTitle: {
                width: "50%",
            },
            liContent: {
                width: "50%",
            },
            titleLabel: {
                fontSize: "8px",
                fontWeight: "bold",
                color: "#777777"
            },
            contentLabel: {
                fontSize: "8px",
                fontWeight: "normal",
                color: "#777777"
            },
            foto: {
                width: "100px",
                height: "100px",
            },
            checkimg: {
                width: "20px",
                height: "20px",
            },
        });

        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={{ ...styles.card, width: "48%" }}>
                        <View style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
                            <Image source={colaborador.CN_PERS_LIST.foto || defaultColaboradorImage} style={styles.foto} />
                        </View>
                        <View style={styles.body}>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>NOMBRE</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_PERS_LIST.nombre}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>Codigo Impositivo</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_PERS_LIST.taxId}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>FECHA DE NACIMIENTO</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{formatDate(colaborador.CN_PERS_LIST.fecha_nacimiento)}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>EDAD</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_PERS_LIST.edad}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>PCD</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_PERS_LIST.pcd}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>NACIONALIDAD</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_PERS_LIST.nacionalidad}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>GRUPO DE RIESGO</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_PERS_LIST.grupo_riesgo}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>FUERO</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_PERS_LIST.fuero}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.card}>
                        <View style={styles.head}>
                            <Text style={styles.h1}>Datos de Puesto / Compensación</Text>
                        </View>
                        <View style={styles.body}>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>UBICACION</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.ubicacion}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>CARGO</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.cargo}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>E-MAIL</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.email}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>FECHA DE INGRESO</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{formatDate(colaborador.CN_JOB_LIST.fecha_ingreso)}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>AÑOS DE ANTIGÜEDAD</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.antiguedad}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>DÍAS DE AUSENCIA</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.dias_ausencia}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>SALDO VACACIONES</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.saldo_vacaciones}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>JEFE DIRECTO</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.jefe_directo}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>GGS</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.ggs}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>BONOS</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.bonos}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>MES DE PAGO</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.mes_pago}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>CR</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.cr}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>CONVENIO COLECTIVO</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.conv_colectivo}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>SINDICATO</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.sindicato}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        )
    }

    return (
        <>
            {loadingPosition ? null : statusPosition == 200 ? (<>
                {/*  <PDFDownloadLink
                    className="text-decoration-none d-inline-block mb-3 mt-4 color-orange"
                    fileName="datos_puesto"
                    document={<DatosPuestoPDF foto={foto} />}
                >
                    <i className="fa fa-file-pdf-o me-1" aria-hidden="true"></i>
                    Descargar
                </PDFDownloadLink>
 */}

                {/* {colaborador ? ( */}
                <div className="col-12 col-xl-7 d-flex">
                    <div className="box align-items-stretch w-100">
                        <h2 className="h4 tit-section">Datos de Puesto / Compensación</h2>
                        <div className="data-list-box data-list-box-even text-uppercase">
                            <ul className="list-unstyled m-0">
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">Ubicación</span>
                                    <span className="data-list-box__text"> {colaborador.CN_JOB_LIST.ubicacion}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">Cargo</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.cargo}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">E-mail</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.email}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">Fecha de ingreso</span>
                                    <span className="data-list-box__text">{formatDate(colaborador.CN_JOB_LIST.fecha_ingreso)}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">Años de Antigüedad</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.antiguedad}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">Días de ausencia</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.dias_ausencia}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">Saldo vacaciones</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.saldo_vacaciones}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">Jefe directo</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.jefe_directo}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">GGS</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.ggs}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">Bonos</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.bonos}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">Mes de pago</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.mes_pago}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">CR</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.cr}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">Convenio colectivo</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.conv_colectivo}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">Sindicato</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.sindicato}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>) : (
                <div>
                    <strong>Sin Registros</strong>
                </div>
            )}
        </>
    );
};

export default DatosPuesto;