import React, { Children, FC, useEffect, useState } from "react";
import * as A from "fp-ts/Array";
import { OptionProps } from "../../utilities/interfaces";
import { validaRut } from "../../../../services/utiles";
import * as RD from "@devexperts/remote-data-ts";
import { pipe } from "fp-ts/lib/function";
import getExcel from "../excel/generateExcel";
import { Link } from "react-router-dom";

const options = [
  { value: "", label: "Seleccione" },
  { value: "directo", label: "Mi Equipo Directo" },
  { value: "equipo", label: "Equipo de" },
  // { value: "rut", label: "Rut" },
];

const yearOptions = A.makeBy(4, (i: number) => {
  return {
    label: `${new Date().getFullYear() - i}`,
    value: `${new Date().getFullYear() - i}`,
  };
});

const Option: FC<OptionProps> = ({
  selectedOption,
  setSelectedOption,
  setselectedOptionEquipo,
  selectedOptionEquipo,
  setselectedOptionYear,
  selectedOptionYear,
  setRut,
  directReport,
  rut,
  setRequest,
  rows,
  emplid,
  header,
  title,
  showYear,
  setDirectReport,
  rutValid,
  setRutValid,
  setDependencyArray,
  info
  
}) => {
  const [rutInput, setRutInput] = useState(rut);
  let actualYear = new Date().getFullYear().toString();


  // useEffect(() => {
  //   if (rutInput !== "" && rutInput.charAt(rutInput.length-1)!=='-') {
  //     setRutValid(validaRut(rutInput));
  //   }
  // }, [rutInput]);

  useEffect(() => {
    if (selectedOption === "directo") {
      setselectedOptionEquipo("");
    }
  }, [selectedOption]);

  useEffect(() => {
    
    if (selectedOption === "equipo" && selectedOptionEquipo !== "") {
      const request =
        "?target=" +
        selectedOptionEquipo +
        "&reports=true&year=" +
        selectedOptionYear;
      setRequest(request);
      setRut("");
    } 
    //Funcion rut desactivada
    // else if (selectedOption === "rut" && rutValid && rutInput!=='') {
    //   const request =
    //     "?target=" +
    //     rutInput +
    //     "&reports=" +
    //     false +
    //     "&year=" +
    //     selectedOptionYear;
    //   setRequest(request);
    //   setRut(rutInput);
    // }
    if (selectedOption === "directo" && selectedOptionYear !== actualYear) {
      const request =
        "?target=" +
        emplid +
        "&reports=true&year=" +
        selectedOptionYear;
      setRequest(request);
      setRut("");
      setselectedOptionEquipo("");
    } else if (selectedOption === "directo") {
      let filter = directReport?.filter((dir)=>!dir.disabled);
      filter !== undefined && setDirectReport(filter)
      setRut("");
      setselectedOptionEquipo("");
      setRequest("");
      setRutValid(false);
      //setRutInput("");
      setDependencyArray([]);
    }
  }, [selectedOption, selectedOptionYear, selectedOptionEquipo, rutValid]);

  const botonExcel = () => {
    let dataE = getExcel(header, rows);
    dataE.then((data) => {
      const downloadLink = document.createElement("a");
      downloadLink.href = data.response.file;
      downloadLink.download = `dashboard_resumen_nomina.xlsx`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  };

  return (
    <>
      <div className="tit-group d-md-flex justify-content-between mb-4 mb-md-0">
      <div className="d-flex align-items-center"> {/* Contenedor flex */}
        <h2 className="h4 tit-section">{title}</h2>
        {info && <i
        className="fa fa-info-circle fa-fw fa-lg color-primary mb-3 ms-2"
        aria-hidden="true"
        title={info}
        data-placement="top"
        ></i>}
      </div>
        <div className="d-flex flex-wrap justify-content-end">
          <div className="col-5 col-md-auto mb-3 me-2">
            <div className="input-group">
              <select
                className="form-select"
                value={selectedOption}
                onChange={(e) => {
                  setSelectedOption(e.target.value);
                }}
              >
                {Children.toArray(
                  options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))
                )}
              </select>
            </div>
          </div>
          {selectedOption === "equipo" && (
            <div className="col-5 col-md-auto mb-3 me-2">
              <div className="input-group">
                <select
                  className="form-select"
                  value={selectedOptionEquipo}
                  onChange={(e) => {
                    setselectedOptionEquipo(e.target.value);
                  }}
                >
                  <option disabled value="">
                    Seleccione
                  </option>
                  {Children.toArray(
                    directReport?.map((report) => (
                      <option key={report.emplid} value={report.emplid} disabled={report.disabled}>
                        {report.fullName}
                      </option>
                    ))
                  )}
                </select>
              </div>
            </div>
          )}
          {/* {selectedOption === "rut" && (
            <div className="col-4 col-md-auto mb-0 me-2">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ingrese Rut con guión"
                  maxLength={10}
                  aria-label="Buscar por Rut"
                  value={rutInput}
                  onChange={(e) => {
                    setRutInput(e.target.value);
                  }}
                />
              </div>
              {rutInput !== "" && !rutValid && <small>Ingrese rut válido.</small>}
            </div>
          )} */}
          <div className="col-5 col-md-auto mb-3 me-2">
            <div className="input-group">
              <select
                className="form-select"
                disabled={!showYear}
                value={selectedOptionYear}
                onChange={(e) => {
                  setselectedOptionYear(e.target.value);
                }}
              >
                <option disabled value="">
                  Seleccione
                </option>
                {!showYear ? (
                  <>
                  <option value={actualYear}>
                      {actualYear}
                    </option>
                  </>
                ): 
                  <>
                    { Children.toArray(
                      yearOptions.map((option) => (
                        <option key={option.label} value={option.value}>
                          {option.label}
                        </option>
                      ))
                    )}
                  </>
                }
                
              </select>
            </div>
          </div>

          <div className="col-5 col-md-auto mt-3 me-2">
              <div className="input-group">
                <Link
                  title="Descargar Resumen"
                  to="#"
                  className="d-flex"
                  onClick={(e) => {
                    e.preventDefault();
                    botonExcel();
                  }}
                >
                  {"Descargar XLS"}
                  <i
                    className="fa fa-file-excel-o fa-lg mx-1 "
                    aria-hidden="true"
                  />
                </Link>
              </div>
            </div>
        </div>
      </div>
    </>
  );
};
export default Option;
