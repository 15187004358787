import React, { useEffect, useState } from "react";
import { useHttpClient } from "..";
import BalanceCard from "../../commons/balanceCard";
import Breadcrumb from "../../commons/breadcrumb";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import { NonEmptyArray, range } from "fp-ts/NonEmptyArray";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import * as RD from "@devexperts/remote-data-ts";
import { RemoteData } from "@devexperts/remote-data-ts";
import { HttpError, Voucher, Options, Transaction } from "../../utilities/interfaces";
import { getVoucher } from "../../utilities/apiServices/apiRequest";
import Search from "../../commons/search";
import PaginatorTable from "../../../elements/paginator-table";
import Searching from "../../commons/searching";
import { dotNumber, rjust } from "../../../../services/utiles";

const VoucherPage = () => {
    const httpClientEnv = useHttpClient();
    const [remoteData, setRemoteData] = useState<RemoteData<HttpError, Voucher>>(RD.initial);
    const [balance, setBalance] = useState<string>("");
    const [voucherOptions, setVoucherOptions] = useState<Options | null>(null);
    const [yearOptions, setYearOptions] = useState<Options | null>(null);
    const [monthOptions, setMonthOptions] = useState<Options | null>(null);
    const [items, setItems] = useState<{ title: string, rows: string[] | NonEmptyArray<number> }[] | null>(null);
    const [headers, setHeaders] = useState<string[] | null>(null);
    const [resetOffset, setResetOffset] = useState(false);
    const [voucher, setVoucher] = useState("MERC");
    const [year, setYear] = useState(new Date().getFullYear().toString());
    const [month, setMonth] = useState(rjust((new Date().getMonth() + 1).toString(), 2, "0"));
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        const transfer = async () => {
            setRemoteData(RD.pending);
            RTE.run(await getVoucher(`?type=MERC&year=${new Date().getFullYear()}&month=${rjust((new Date().getMonth() + 1).toString(), 2, "0")}`), httpClientEnv)
                .then(E.fold(e => { return setRemoteData(RD.failure(e)) }, a => { return setRemoteData(RD.success(a)) }))
        };
        transfer();
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            remoteData._tag === "RemoteSuccess" && document.getElementById("closeSendingInfo")?.click();
        }, 519);
        if (remoteData._tag === "RemoteSuccess") {
            setBalance(remoteData.value.response.saldos.monto);
            setVoucherOptions(remoteData.value.response.voucherOptions);
            setYearOptions(remoteData.value.response.yearOptions);
            setMonthOptions(remoteData.value.response.monthOptions);
            pipe(
                [
                    {
                        title: "N°",
                        rows: range(1, remoteData.value.response.transacciones.length),
                    },
                    {
                        title: "Fecha Compra",
                        rows: A.map((transaction: Transaction) => transaction.fecha.split(" ")[0].replace(/-/g, "/"))(remoteData.value.response.transacciones),
                    },
                    {
                        title: "Tipo Vale",
                        rows: A.map((transaction: Transaction) => transaction.descr)(remoteData.value.response.transacciones),
                    },
                    {
                        title: "ID Transacción",
                        rows: A.map((transaction: Transaction) => transaction.tx)(remoteData.value.response.transacciones),
                    },
                    {
                        title: "Monto",
                        rows: A.map((transaction: Transaction) => `$${dotNumber(transaction.monto)}`)(remoteData.value.response.transacciones),
                    },
                ],
                setItems
            );
            setHeaders(A.map((transaction: Transaction) => transaction.descr)(remoteData.value.response.transacciones));
        }
        if (remoteData._tag === "RemotePending" || remoteData._tag === "RemoteInitial") {
            document.getElementById("sendingInfo")?.click();
        }
        if (remoteData._tag === "RemoteFailure") {
            document.getElementById("closeSendingInfo")?.click();
        }
        return () => clearTimeout(timer);
    }, [remoteData]);

    useEffect(() => {
        if (voucher !== "" && year !== "" && month !== "") {
            setDisabled(false);
        }
    }, [voucher, year, month]);

    return (
        <>
            <Breadcrumb />
            <section className="vale-de-mercadería">
                <BalanceCard balance={balance} />
                {voucherOptions && yearOptions && monthOptions ?
                    <Search
                        voucherOptions={voucherOptions}
                        yearOptions={yearOptions}
                        monthOptions={monthOptions}
                        voucher={voucher}
                        setVoucher={setVoucher}
                        year={year}
                        setYear={setYear}
                        month={month}
                        setMonth={setMonth}
                        disabled={disabled}
                        setDisabled={setDisabled}
                        setRemoteData={setRemoteData}
                    />
                : null}
                {items && headers && remoteData._tag === "RemoteSuccess" && remoteData.value.response.transacciones[0].tipo !== "" ?
                    <div className="box mb-4">
                        <h2 className="h4 tit-section">{"Transacciones"}</h2>
                        <div className="table-responsive">
                            <PaginatorTable
                                items={items}
                                itemsPerPage={5}
                                header={headers}
                                constHeader={"Fecha Compra"}
                                resetOffset={resetOffset}
                                setResetOffset={setResetOffset}
                                hiddenMediaColumn={[0, 1]}
                            />
                        </div>
                    </div>
                :
                    <div className="box mb-4">
                        <h2 className="h4 tit-section">{"Transacciones"}</h2>
                        <div className="table-responsive">
                            {remoteData._tag === "RemotePending" ? "Cargando..." : "No se ha encontrado información"}
                        </div>
                    </div>}
            </section>
            <Searching />
        </>
  )
}

export default VoucherPage;
