import React from "react";

export const BotonsSaveCancel = ({
  saveHandler,
  cancelHandler,
}) => {
  return (
    <div className="d-block d-sm-flex justify-content-end">
      <div className="license-btn-container">
        <div className="license-btn">
          <button
            type="reset"
            className="button cancel"
            onClick={() => cancelHandler()}
          >
            Cancelar
            <i
              className="fa fa-chevron-right fa-fw fa-xs"
              aria-hidden="true"
            ></i>
          </button>
          <button
            type="submit"
            className="button save"
            onClick={() => saveHandler()}
          >
            Guardar
            <i
              className="fa fa-chevron-right fa-fw fa-xs"
              aria-hidden="true"
            ></i>
          </button>
        </div>
      </div>
    </div>
  );
};
