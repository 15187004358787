import { useState, useEffect } from 'react';

const useResponsiveBreakpoint = () => {
  // Determina el breakpoint inicial basado en el ancho de la ventana
  const getBreakpoint = (width :number) => {
    if(width < 576) return 'xs';
    else if(width >= 576 && width < 768) return 'sm';
    else if(width >= 768 && width < 992) return 'md';
    else if(width >= 992 && width < 1200) return 'lg';
    else if(width >= 1200 && width <= 1440) return 'xl';
    else return 'xxl';
  };

  const [breakpoint, setBreakpoint] = useState(getBreakpoint(window.innerWidth));

  useEffect(() => {
    const handleResize = () => {
      const currentBreakpoint = getBreakpoint(window.innerWidth);
      if (currentBreakpoint !== breakpoint) {
        setBreakpoint(currentBreakpoint);
      }
    };

    window.addEventListener('resize', handleResize);

    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpoint]);

  return breakpoint;
};

export default useResponsiveBreakpoint;
