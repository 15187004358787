import React, { useEffect, useState } from 'react';
import menuService from '../../../services/permissions/access-service';
import useBuildHierarchy from './useBuildHierarchy';
import { Auth } from "aws-amplify";

const usePagesByCountry = () => {
    const [pagesCountry, setPagesCountry] = useState([]);
    const [isLoadingCountry, setIsLoadingCountry] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState();

    useEffect(() => {
        Auth.currentUserInfo().then(user => {
            if (user.attributes['custom:cd_pais']) {
                const countryCode = user.attributes['custom:cd_pais'];
                setSelectedCountry(countryCode);
            }
        }).catch(err => console.log(err));
    }, []);

    const fetchPagesByCountry = async () => {
        if (!selectedCountry) {
        console.error('selectedCountry is undefined or empty');
        return;
    }

        try {
            setIsLoadingCountry(true);
            const { data } = await menuService.getRolesByCountry({ country: selectedCountry });
            const hierarchicalPages = useBuildHierarchy(data);
            setPagesCountry(hierarchicalPages);
        } catch (error) {
            console.error('Error fetching pagesCountry by country:', error);
        } finally {
            setIsLoadingCountry(false);
        }
    };

    useEffect(() => {
        if (selectedCountry) {
            fetchPagesByCountry();
        }
    }, [selectedCountry]);

    return { isLoadingCountry, pagesCountry, fetchPagesByCountry, selectedCountry, setSelectedCountry };
};

export default usePagesByCountry;
