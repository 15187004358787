import React, { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../../context';
import { WidgetBanner, WidgetCarouselIcon, WidgetSlider, WidgetTable } from '../../commonComponents/Widget';
import { getBannerBenefits, getBannerNews, getNextBirthdays, getShortcuts, getVacationsAndLicenses } from '../services/homeServices';
import newsletterInicio from "../../../resources/images/banner-suscripcion-inicio.png";
import Box from '../../commonComponents/Box';

const HomeAR: FC = () => {
    const { setShowNewsletter } = useContext<any>(Context);
    const navigate = useNavigate();

    return (
        <>
            <WidgetCarouselIcon
                id="accesos-directos"
                width="3/3"
                box={false}
                data={() => getShortcuts()}
            />

            <WidgetBanner
                id="noticias-destacadas"
                width="2/3"
                box={false}
                data={() => getBannerNews()}
            />

            <WidgetTable
                id="proximos-cumpleanos"
                title="Próximos Cumpleaños"
                data={() => getNextBirthdays()}
                width="1/3"
                box={true}
            />

            <WidgetSlider
                id="beneficios-destacados"
                data={() => getBannerBenefits()}
                width="3/3"
                box={false}
            />

            <Box width="3/3" box={false}>
                <div className="pointer w-100" onClick={(e) => {
                    e.preventDefault();
                    setShowNewsletter(true);
                    navigate('/suscripciones');
                }}>
                    <img src={newsletterInicio} className="img-fluid p-0 w-100 rounded-4" alt="logo" />
                </div>
            </Box>

            <WidgetTable
                id="vacations-and-licenses"
                title="Mi Equipo"
                data={() => getVacationsAndLicenses()}
                width="2/3"
                box={true}
            />

        </>
    );
}

export default HomeAR;