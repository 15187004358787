import { ProfileSummaryProvider } from "../../context/EmployeeProfileSummaryContext";
import { EmployeeProfileContent } from "./EmployeeProfileContent";

export const EmployeeProfileSummary = () => {
  return (
    <ProfileSummaryProvider>
      <EmployeeProfileContent/>
    </ProfileSummaryProvider>
  );
};

