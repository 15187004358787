import React, { useState } from "react";
import styles from "../../resources/css/style-COL.module.css";
import EsquemaHibridoService from "../../services/esquemaHibrido/esquema-hibrido-service";
import { BsExclamationCircle } from "react-icons/bs";
import errorMessage from "../SharedComponents/error-message";
import successMessage from "../SharedComponents/success-message";

export default function RegistrosEmpleado({ registros, loadingRegistros, errorRegistros, resetValues, tipos }) {

    const [loadingDesadherirse, setLoadingDesadherirse] = useState(false)
    const estado = (t, s) => {
        if (t != "P") {
            switch (s) {
                case 'A':
                    return "Aprobado";
                case 'P':
                    return "Pendiente";
                case 'R':
                    return "No aprobado";
                case 'V':
                    return "Desadherido";
            }
        } else {
            return "-"
        }
    }

    const desadherirse = async (id) => {
        let response = await EsquemaHibridoService.desadherirse(id);

        if (response === null) {
            errorMessage(response?.message, resetValues);
        } else {
            const estado = response?.statusCode || response?.status;
            switch (estado) {
                case 200:
                    setLoadingDesadherirse(false);
                    successMessage(resetValues);
                    break;

                case 400:
                    errorMessage(resetValues, response?.message)
                    setLoadingDesadherirse(false);
                    break;

                case 404:
                    errorMessage(resetValues, response?.message)
                    setLoadingDesadherirse(false);
                    break;

                case 500:
                    errorMessage(resetValues, response?.message)
                    setLoadingDesadherirse(false);
                    break;

                default:
                    errorMessage(resetValues, response?.message)
                    setLoadingDesadherirse(false);
                    break;
            }
        }
    };

    return (
        <div className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}>
            <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Solicitudes Pendientes</h2>
            {/* <!-- Escritorio (Tabla) --> */}
            {!loadingRegistros ? (!errorRegistros ? (registros.length !== 0 ? <>
                <div className={styles["table-responsive"]}>
                    <table className={`${styles["table"]} ${styles["table-even"]} ${styles["table-section"]} ${styles["table-borderless"]} ${styles["d-none"]} ${styles["d-md-table"]}`}>
                        <thead>
                            <tr>
                                <th className={styles["text-nowrap"]}>Fecha <a href="#"><i className="fa fa-long-arrow-down fa-fw" aria-hidden="true"></i></a></th>
                                <th className={styles["text-nowrap"]}>Tipo de Esquema</th>
                                <th className={styles["text-nowrap"]}>Estado</th>
                                <th className={`${styles["text-nowrap"]} ${styles["text-center"]}`}>Comentario</th>
                                <th className={`${styles["text-nowrap"]} ${styles["text-center"]}`}>Desadherirse</th>
                            </tr>
                        </thead>
                        <tbody>
                            {registros?.map((r, id) => {
                                return (<tr key={id}>
                                    <td>{r?.fecha}</td>
                                    <td>{tipos?.filter(e => e.key == r?.esquema)[0] == undefined ? "No definido" : tipos?.filter(e => e.key == r?.esquema)[0].value}</td>
                                    <td><strong className={`${styles["color-primary"]} ${styles["text-uppercase"]}`}>{estado(r?.esquema, r?.estado)}</strong></td>
                                    <td className={styles["text-center"]}>{r?.estado == 'A' || r?.estado == 'R' ? <a href="#" data-bs-toggle="modal" data-bs-target={`#comentario-${id}`}><i className={`${styles["fa"]} ${styles["fa-eye"]} ${styles["fa-fw"]} ${styles["fa-lg"]}`} aria-hidden="true"></i></a> : null}</td>
                                    <td className={styles["text-center"]}>{r?.estado == 'A' && r?.esquema != "P" ? <a href="#" data-bs-toggle="modal" data-bs-target={`#seguro-${id}`}>{loadingDesadherirse ? <div className="spinner-border spinner-border-sm"></div> : <i className={`${styles["fa"]} ${styles["fa-trash-o"]} ${styles["fa-fw"]} ${styles["fa-lg"]}`} aria-hidden="true"></i>}</a> : null}</td>
                                    {/* <!-- Modal Comentario --> */}
                                    <div className="modal fade" id={`comentario-${id}`} tabIndex="-1" aria-labelledby={`comentario-${id}`} aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content">
                                                <div className="modal-header border-0 pb-0">
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body py-4">
                                                    <h2 className="h4 mb-4">Comentario</h2>
                                                    <p>{r?.comentario != "" ? r?.comentario : "No se ingresó comentario."}</p>
                                                </div>
                                                <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                                                    <button type="submit" className="btn btn-degradado col-12 col-md-auto my-auto px-5" data-bs-dismiss="modal">Cerrar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- fin modals --> */}

                                    {/* <!-- Modal Está seguro --> */}
                                    <div className="modal fade" id={`seguro-${id}`} tabIndex="-1" aria-labelledby={`seguro-${id}`} aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content">
                                                <div className="modal-header border-0 pb-0">
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body text-center py-4">
                                                    <i className="fa fa-info-circle color-orange fa-3x" aria-hidden="true"></i>
                                                    <h2 className="h5">¿Estás seguro que quieres desadherirte?</h2>
                                                </div>
                                                <div className="modal-footer border-0 pt-0 pb-5 justify-content-center gap-2">
                                                    <button type="button" className="btn btn-degradado order-md-last col-12 col-md-auto my-auto px-5" data-bs-dismiss="modal" data-bs-toggle="modal" onClick={() => desadherirse(r?.idSolicitud)}>Desadherirte <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                                    <button type="button" className="btn btn-primary col-12 col-md-auto my-auto px-5" data-bs-dismiss="modal">Cancelar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- fin modals --> */}
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
                {/* <!-- Móvil (Acordeón) --> */}
                <div className={`accordion accordion-flush accordion-light accordion-table ${styles["d-md-none"]}`}>
                    {/* <!-- Acordeón --> */}
                    {registros?.map((r, id) => {
                        return (<div key={id} className="accordion-item">
                            <h2 className="accordion-header" id="accordion">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-item-${id}`} aria-expanded="false" aria-controls={`accordion-item-${id}`}>
                                    <strong>Fecha</strong> {r?.fecha}
                                </button>
                            </h2>
                            <div id={`accordion-item-${id}`} className="accordion-collapse collapse" aria-labelledby="accordion">
                                <div className="accordion-body p-0">
                                    {/* <!-- cuerpo item --> */}
                                    <div className="data-list-box data-list-box-even">
                                        <ul className="data-list-section--item list-unstyled">
                                            <li>
                                                <span className="data-list-box__title">Tipo de Esquema</span>
                                                <span className="data-list-box__text">{tipos?.filter(e => e.key == r?.esquema)[0] == undefined ? "No definido" : tipos?.filter(e => e.key == r?.esquema)[0].value}</span>
                                            </li>
                                            <li>
                                                <span className="data-list-box__title">Estado</span>
                                                <span className="data-list-box__text"><strong className="color-primary text-uppercase">{estado(r?.esquema, r?.estado)}</strong></span>
                                            </li>
                                            <li>
                                                <span className="data-list-box__title">Comentario</span>
                                                <span className="data-list-box__text">{r?.estado == 'A' || r?.estado == 'R' ? <a href="#" data-bs-toggle="modal" data-bs-target={`#comentario-movil-${id}`}><i className="fa fa-eye fa-fw fa-lg" aria-hidden="true"></i></a> : null}</span>
                                            </li>
                                            <li>
                                                <span className="data-list-box__title">Deshaderirse</span>
                                                <span className="data-list-box__text">{r?.estado == 'A' && r?.esquema != "P" ? <a href="#" data-bs-toggle="modal" data-bs-target={`#seguro-movil-${id}`}><i className="fa fa-trash-o fa-fw fa-lg" aria-hidden="true"></i></a> : null}</span>
                                            </li>
                                        </ul>
                                        {/* <!-- Modal Comentario --> */}
                                        <div className="modal fade" id={`comentario-movil-${id}`} tabIndex="-1" aria-labelledby={`#comentario-movil-${id}`} aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header border-0 pb-0">
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body py-4">
                                                        <h2 className="h4 mb-4">Comentario</h2>
                                                        <p>{r?.comentario != null ? r?.comentario : "No se ingresó comentario."}</p>
                                                    </div>
                                                    <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                                                        <button type="submit" className="btn btn-degradado col-12 col-md-auto my-auto px-5" data-bs-dismiss="modal">Cerrar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- fin modals --> */}

                                        {/* <!-- Modal Está seguro --> */}
                                        <div className="modal fade" id={`seguro-movil-${id}`} tabIndex="-1" aria-labelledby={`#seguro-movil-${id}`} aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header border-0 pb-0">
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body text-center py-4">
                                                        <i className="fa fa-info-circle color-orange fa-3x" aria-hidden="true"></i>
                                                        <h2 className="h5">¿Estás seguro que quieres desadherirte?</h2>
                                                    </div>
                                                    <div className="modal-footer border-0 pt-0 pb-5 justify-content-center gap-2">
                                                        <button type="button" className="btn btn-degradado order-md-last col-12 col-md-auto my-auto px-5" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#success" onClick={() => desadherirse(r?.idSolicitud)}>Desadherirte <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                                        <button type="button" className="btn btn-primary col-12 col-md-auto my-auto px-5" data-bs-dismiss="modal">Cancelar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- fin modals --> */}
                                    </div>
                                    {/* <!-- fin cuerpo item --> */}
                                </div>
                            </div>
                        </div>)
                    })}
                    {/* <!-- fin items acordeón --> */}
                </div>
                {/* <!-- fin Móvil (Acordeón) --> */}
            </> : <div className={`${styles["alert"]} ${styles["alert-info"]} ${styles["alert-dismissible"]} ${styles["fade"]} ${styles["show"]} ${styles["text-center"]}`} role="alert">
                <BsExclamationCircle />  No se encontraron solicitudes.
            </div>) : <div className={`${styles["alert"]} ${styles["alert-info"]} ${styles["alert-dismissible"]} ${styles["fade"]} ${styles["show"]} ${styles["text-center"]}`} role="alert">
                <BsExclamationCircle />  No se encontraron datos asociados
            </div>) : <div data-testid="loading" className="full-spinner">
                <div className="spinner-border text-primary"></div>
            </div>}
        </div>)
}