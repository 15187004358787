import React, { Children, FunctionComponent } from "react";
import { FormList, Options } from "../../utilities/interfaces";
import InputForm from "../inputForm";
import { FieldErrorsImpl, UseFormRegister, } from "react-hook-form";
import CertificateModal from "../plain/modals/certificate";
import AccountModal from "../plain/modals/account";

interface Props {
    formList: Array<FormList>;
    options: Map<string, Array<Options>>
    register: UseFormRegister<any>;
    errors: Partial<FieldErrorsImpl>;
    accountTerms?: boolean;
    contractDate?: string;
}

const UnorderedList: FunctionComponent<Props> = ({ formList, options, register, errors, accountTerms, contractDate }) => {
    return (
        <>
            <div className="data-list-box data-list-box-even data-list-box-align-middle data-list-box-form">
                <ul className="list-column-1 list-unstyled">
                    {Children.toArray(formList.map((element) => {
                        if (!element.hidden) {
                            return (
                                <li>
                                    {element.title.length >= 25 ?
                                        <span className="data-list-box__title">
                                            {element.title.substring(0, 20)}
                                            <br />
                                            {element.title.substring(20, element.title.length)}{element.required && "*"}
                                        </span>
                                    :
                                        <span className="data-list-box__title">{element.title}{element.required && "*"}</span>}
                                    <span className="data-list-box__text">
                                        {element.classNameType === "form-control" ?
                                            element.inputNumber !== undefined && element.inputNumber > 1 ?
                                                <div className="row g-3">
                                                    <div className="col-3">
                                                        <InputForm element={element} register={register} errors={errors} />
                                                    </div>
                                                    <div className="col-9">
                                                        <InputForm element={element?.nextInput as FormList} key={element?.nextInput?.value as string} register={register} errors={errors} />
                                                    </div>
                                                </div>
                                            :
                                                element.requireModal !== undefined && element.requireModal ?
                                                <div className="row">
                                                    <div className="col-9">
                                                        <InputForm element={element} register={register} errors={errors} contractDate={contractDate} />
                                                    </div>
                                                    <CertificateModal />
                                                </div>
                                                :
                                                <InputForm element={element} register={register} errors={errors} contractDate={contractDate} />
                                        : element.classNameType === "form-select" ?
                                            <>
                                                <select
                                                    id={element.id}
                                                    className={element.classNameType}
                                                    disabled={element.readOnly}
                                                    { ...register(element.id, { required: element.required ? element.onInvalidText : undefined }) }
                                                >
                                                    <option disabled={true} value={""}>Seleccione una Opción</option>
                                                    {options.get(element.optionSelect)?.map((o) => (
                                                        <option disabled={o.value === ""} key={o.key} value={o.value}>
                                                            {o.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors[element.id] &&
                                                    <div className="invalid-feedback d-block">
                                                        {element.onInvalidText}
                                                    </div>}
                                            </>
                                        : null}
                                    </span>
                                </li>
                            )
                        } else {
                            return (
                                <input type="hidden" id={element.id} { ...register(element.id, { required: element.required ? element.onInvalidText : undefined })} />
                            )
                        }
                    }))}
                </ul>
            </div>
            {accountTerms && 
                <div className="box-footer">
                    <div className="mb-3">
                        <input type="checkbox" className="btn-check" id="readed" { ...register("readed", { required: true }) } />
                        <label className="color-primary" htmlFor="readed" data-bs-toggle="modal" data-bs-target="#termsModal"><i className="fa fa-file-text-o color-primary me-2 fa-lg" aria-hidden="true"></i>{" Ver Términos y Condiciones"}</label>
                        {errors["readed"] &&
                            <div className="invalid-feedback d-block">
                                {"Recuerda leer los términos y condiciones"}
                            </div>}
                    </div>
                    <AccountModal />
                    <div>
                        <input className="form-check-input border-primary me-2 h5" type="checkbox" value="" id="accepted" { ...register("accepted", { required: true }) } />
                        <label className="form-check-label color-primary-strong mt-1" htmlFor="accepted">{"Autorización"}</label>
                        {errors["accepted"] &&
                            <div className="invalid-feedback d-block">
                                {"Debe autorizar a la empresa para el depósito en su cuenta"}
                            </div>}
                    </div>
                </div>}
        </>
        
    )
};

export default UnorderedList;