import React, { useState, useEffect } from "react";
import { urlsAws } from "../../resources/foo/api-endpoints";
import documentoService from "../../services/documento-service";
import listService from "../../services/list-service";

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const emailRegexString = '^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$';

const ModalFirstLogin = ({ firstLoginData }) => {
    const [loginData, setloginData] = useState(firstLoginData);
    const [email, setEmail] = useState("");
    const [descargaAnexo, setDescargaAnexo] = useState(false);
    const [autorizacionAnexo, setAutorizacionAnexo] = useState(false);
    const [modalState, setModalState] = useState(false);
    const [documentList, setDocumentList] = useState(null);
    const [downloading, setDownloading] = useState(false);
    const descargo = true;
    const [_, setStateFirma] = useState(null);

    useEffect(() => {
        if (firstLoginData === undefined || firstLoginData === null) {
            caches.match(urlsAws.firstLogin).then(data => {
                if (data !== null && data !== undefined && data.body !== null) {
                    return data.json();
                } else {
                    throw new Error("mal");
                }
            }).then(data => {
                if (data !== null && data !== undefined) {
                    setloginData(data)
                }
            }).catch(error => console.log(error));
        } 
    }, [firstLoginData]);

    useEffect(() => {
        if (loginData && loginData.FIRSTLOGIN == "N" && !modalState) {
            openModal();
        }
    }, [loginData]);

    useEffect(() => {
        if (documentList !== null) {
            setDescargaAnexo(true);
            let doc = Buffer.from(documentList.archivo, "base64");
            let link = document.createElement("a");
            let blob = new Blob([doc], { type: "application/pdf" });
            link.href = window.URL.createObjectURL(blob);
            link.download = documentList.descripcion;
            link.dispatchEvent(new MouseEvent("click"));
            setDownloading(false);
        }
    }, [documentList]);

    const openModal = () => {
        setModalState(true);
        document.body.classList.add("modal-open");
        document.body.style.overflow = "hidden";
        document.body.style.paddingRight = "15px";
        let newDiv = document.createElement("div");
        newDiv.id = "modal-overlay";
        newDiv.className = "modal-backdrop fade show";
        document.body.appendChild(newDiv);
    };

    const closeModal = () => {
        setModalState(false);
        document.body.removeChild(document.getElementById("modal-overlay"));
        document.body.classList.remove("modal-open");
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "0";
    };

    const handleDownload = (e) => {
        e.preventDefault();
        if (!descargaAnexo) {
            setDownloading(true);
            documentoService.getDocumentType(setDocumentList, loginData.COMPANY, "5");
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        listService.setAnexoContrato(email, setStateFirma);

        closeModal();
    };
    return (
        <>
            {modalState && !downloading ?
                <div className="modal fade show" id="first-login" style={{ display: "block" }} tabIndex="-1" aria-labelledby="first-login" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <form id="formLiquidacion" onSubmit={(e) => handleSubmit(e)}>
                                <div className="modal-header border-0 pb-0">
                                    <h2 className="h5">Firma Anexo Autoservicio</h2>
                                </div>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <label className="form-label mb-2">
                                            <strong>Debe descargar y autorizar el anexo de autoservicio e ingresar su correo personal:</strong>
                                        </label>
                                        <input type="email" required id="email" name="correo" pattern={emailRegexString} className="form-control" placeholder="mailcontacto@cencosud.com" onInput={(e) => e.target.setCustomValidity("")} onInvalid={(e) => e.target.setCustomValidity("Formato invalido")} value={email} onChange={(e) => setEmail(e.target.value)}/>
                                    </div>
                                    <div className="mb-3">
                                        <a name="descargaAnexo" href="/datoslaborales"  onClick={(e) => handleDownload(e)} value={descargaAnexo.toString()} className="text-decoration-none">
                                            <span className="text-uppercase fw-bolder">{"Descargar Anexo"}</span>
                                            <i className="fa fa-file-pdf-o color-primary ms-2 fa-lg" aria-hidden="true" />
                                        </a>
                                        {descargaAnexo ? (
                                            <>
                                                <i className="fa fa-check color-primary ms-2 fa-lg" aria-hidden="true" />
                                            </>
                                        ) : null}
                                        {!descargo ? (
                                            <div className="invalid-feedback d-block mt-2" id="noAcepto">
                                                {"Debe descargar el documento "}
                                                <i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" />
                                            </div>
                                        ) : null}
                                    </div>
                                    <div>
                                        <input className="form-check-input border-primary me-2 h5" type="checkbox" id="invalidCheck" name="autorizacionAnexo" onChange={() => setAutorizacionAnexo(!autorizacionAnexo)} value={autorizacionAnexo.toString()} checked={autorizacionAnexo} required onInput={(e) => e.target.setCustomValidity("")} onInvalid={(e) => e.target.setCustomValidity("Debe aceptar que ha leído el documento")} />
                                        <label className="form-check-label color-primary-strong mt-1" htmlFor="invalidCheck">
                                            {"He leído y acepto el documento"}
                                        </label>
                                    </div>
                                </div>
                                <div className="modal-footer border-0 pt-0">
                                    <button disabled={!autorizacionAnexo || !email.match(emailRegex) || !descargaAnexo} type="submit" className="btn btn-degradado col-12 col-md-auto" data-bs-target="#success">Confirmar{" "}<i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            : null}
            {downloading ? (
                <div className="col">
                    <div className="modal fade show" id="descargando" tabIndex="-1" aria-labelledby="fail" aria-modal="true" role="dialog" style={{ display: "block" }}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body text-center py-4">
                                    <i className="fa fa-download color-orange fa-3x mb-2" data-bs-dismiss="modal" aria-label="Close" aria-hidden="true"></i>
                                    <h2 className="h5">Descargando...</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    )
};

export default ModalFirstLogin;
