import ausenciasService from "../services/ausencias-service";
import { useState, useEffect, Children } from "react";
import React from "react";
import { formatDate } from "../services/utiles";
import { Link } from "react-router-dom";
import PaginatorTable from "./elements/paginator-table";
import { transpose } from "../services/utiles";

const delay = (ms = 4000) => new Promise((r) => setTimeout(r, ms));

const Ausencias = () => {
    const [data, setData] = useState(null);
    const emplid = window.localStorage.getItem("emplid");
    const [descripciondata, setDescripciondata] = useState(null);
    const [estadoCarga, setEstadoCarga] = useState(null);
    const [items, setItems] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [resetOffset, setResetOffset] = useState(false);

    const newCall = async () => {
        setEstadoCarga(false);
        await delay();
        ausenciasService.getausencias(emplid, setData, setEstadoCarga, data);
    };

    useEffect(() => {
        setEstadoCarga(false);
        ausenciasService.getausencias(emplid, setData, setEstadoCarga, data);
    }, []);

    useEffect(() => {
        if (data === 1 || data === "1") {
            newCall();
        }
    }, [data]);

// cod_ausencia: "1"
// descr_ausencia: "LICENCIA NORMAL"
// descr_cod: "ENFERMEDAD COMUN"
// dias_duracion: "1"
// empl_rcd: "0"
// emplid: "20438852-0"
// fecha_inicio: "2022-08-28"
// fecha_retorno: "2022-08-28"
// motivo: "ASDASD"
// tipo_ausencia: "LIN"

    useEffect(() => {
        if (data !== null && data !== undefined && data !== 1 && data !== "1" && data !== 2) {
            let titles = ["Descripción", "Detalle", "Fecha inicio", "Fecha término", "Días"];
            let newHeaders = [];
            let rows = transpose(data.map((row, index, arr) => {
                newHeaders.push(arr[arr.length - index - 1].descr_ausencia.substring(0, 1).toUpperCase() + arr[arr.length - index - 1].descr_ausencia.substring(1, arr[arr.length - index - 1].descr_ausencia.length).toLowerCase())
                return [
                    arr[arr.length - index - 1].descr_ausencia.substring(0, 1).toUpperCase() + arr[arr.length - index - 1].descr_ausencia.substring(1, arr[arr.length - index - 1].descr_ausencia.length).toLowerCase(),
                    arr[arr.length - index - 1].descr_cod.substring(0, 1).toUpperCase() + arr[arr.length - index - 1].descr_cod.substring(1, arr[arr.length - index - 1].descr_cod.length).toLowerCase(),
                    formatDate(arr[arr.length - index - 1].fecha_inicio),
                    formatDate(arr[arr.length - index - 1].fecha_retorno),
                    arr[arr.length - index - 1].dias_duracion.substring(0, 1).toUpperCase() + arr[arr.length - index - 1].dias_duracion.substring(1, arr[arr.length - index - 1].dias_duracion.length).toLowerCase()
                ];
            }));
            setHeaders(newHeaders);
            let newList = titles.map((title, index) => {
                return {
                    title: title,
                    rows: rows[index],
                };
            });
            setItems(newList);
            const dupped = data.reduce((accumulator, currentValue) => {
                const exists = accumulator.find((ausencia) => ausencia.descr_ausencia === currentValue.descr_ausencia);
                if (exists) {
                    return accumulator.map((ausencia) => {
                        if (ausencia.descr_ausencia === currentValue.descr_ausencia) {
                            return {
                                ...ausencia,
                                dias_duracion: Number(ausencia.dias_duracion) + Number(currentValue.dias_duracion),
                            };
                        }
                        return ausencia;
                    });
                }
                return [...accumulator, currentValue];
            }, []);
            setDescripciondata(dupped);
        }
    }, [data]);

    return (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item">Ausencias y Vacaciones</li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Ausencias
                    </li>
                </ol>
            </nav>
            <section className="ausencias">
                <h1>Ausencias</h1>
                {data !== undefined && data !== null && data !== 1 && data !== "1" && data !== 2 ? 
                    <>
                        {/*<!-- Inicio Días de ausencia -->*/}
                        <div className="box mb-4">
                            <h2 className="h4 tit-section">Detalle de Ausencia</h2>
                            <PaginatorTable itemsPerPage={5} items={items} resetOffset={resetOffset} setResetOffset={setResetOffset} header={headers} constHeader={"Descripción"}/>
                            </div>
                        {/*<!-- Inicio totales -->*/}
                        <div className="box mb-4">
                            <h2 className="h4 tit-section">Totales</h2>
                            {/*<!-- Escritorio -->*/}
                            <table className="table table-even table-section table-borderless align-middle text-uppercase d-none d-md-table">
                                <thead>
                                    <tr>
                                        <th className="col-9">Descripción</th>
                                        <th className="col-3">Días totales</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {descripciondata !== null && descripciondata !== undefined && descripciondata !== []
                                        ? Children.toArray(
                                            descripciondata.map((value, index) => {
                                                return (
                                                    <tr key={"tr-1" + index + "escritorio-totales"}>
                                                        <>
                                                            <td key={"td-1" + index + "escritorio-totales"}>{value.descr_ausencia}</td>
                                                            <td key={"td-2" + index + "escritorio-totales"}>{value.dias_duracion}</td>
                                                            {/* suma de todos los dias_duración con el mismo ausencia */}
                                                        </>
                                                    </tr>
                                                );
                                            })
                                        )
                                        : null}
                                </tbody>
                            </table>
                            {/*<!-- inicio acordeon para movil totales -->*/}

                            <div className="data-list-box data-list-box-sa text-uppercase d-md-none">
                                {descripciondata !== null && descripciondata !== undefined && descripciondata !== []
                                    ? Children.toArray(
                                        descripciondata.map((value, index) => {
                                            return (
                                                <>
                                                    <ul className="list-column-1 list-unstyled">
                                                        <li key={"li-1" + index + "movil-totales"}>
                                                            <span key={"span-1" + index + "movil-totales"} className="data-list-box__title">
                                                                Descripción
                                                            </span>
                                                            <span key={"span-2" + index + "movil-totales"} className="data-list-box__text">
                                                                {" "}
                                                                {value.descr_ausencia}
                                                            </span>
                                                        </li>
                                                        <li key={"li-2" + index + "movil-totales"}>
                                                            <span key={"span-3" + index + "movil-totales"} className="data-list-box__title">
                                                                Días totales
                                                            </span>
                                                            <span key={"span-4" + index + "movil-totales"} className="data-list-box__text">
                                                                {value.dias_duracion}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </>
                                            );
                                        })
                                    )
                                    : null}
                            </div>
                        </div>
                    </>
                : data !== 1 && data !== "1" && !estadoCarga ?
                    <div className="text-center">
                        <span className="spinner-border spinner-border-xl" />
                    </div>
                : data === 2 && estadoCarga ?
                    <div className="text-center">
                        <p>No hay datos</p>
                    </div>
                : 
                    <div className="text-center">
                        <span className="spinner-border spinner-border-xl" />
                    </div>}
            </section>
        </>
    );
};

export default Ausencias;
