import React, { FC, useEffect, useState } from "react";
import BreadCrumbs from "../../commons/breadCrumbs";
import { AnnualBond, GetMyModelResponse, GetTeamAsignationResponse, HttpError } from "../../utilities/interfaces";
import { RemoteData } from "@devexperts/remote-data-ts";
import * as RD from "@devexperts/remote-data-ts";
import { getRemoteData, getRemoteMyModel, getRemoteTeamModel } from "../../utilities/helpers/setData";
import Carousel from "../../commons/carousel";
import AssignModel from "../assignModel";
import MyModel from "../myModel";
import TeamModel from "../teamModel";

const AB: FC<{ boss: string }> = ({ boss }) => {
    const [carouselSelected, setCarouselSelected] = useState(0);
    const [remoteData, setRemoteData] = useState<RemoteData<HttpError, AnnualBond>>(RD.initial);
    const [remoteMyModel, setRemoteMyModel] = useState<RemoteData<HttpError, GetMyModelResponse>>(RD.initial);
    const [remoteTeamAsignation, setRemoteTeamAsignation] = useState<RemoteData<HttpError, GetTeamAsignationResponse>>(RD.initial);
    const [views, setViews] = useState<string[]>([]);

    useEffect(() => {
        ((views.length === 3 || views.length === 0) && carouselSelected === 0) &&
            getRemoteData({setRemoteData});
    }, [carouselSelected]);

    useEffect(() => {
        (
            (views.length === 3 && carouselSelected === 1) ||
            (views.length === 2 && carouselSelected === 0) ||
            (views.length === 1 && carouselSelected === 0)
        ) && getRemoteMyModel({ setRemoteData: setRemoteMyModel });
    }, [carouselSelected, views]);

    useEffect(() => {
        (
            (views.length === 3 && carouselSelected === 2) ||
            (views.length === 2 && carouselSelected === 1)
        ) && getRemoteTeamModel({ setRemoteData: setRemoteTeamAsignation });
    }, [carouselSelected, views]);

    useEffect(() => {
        remoteData._tag === "RemoteSuccess" ?
            remoteData.value.response.valid === "1" && boss === "Y" ?
                setViews(["Asignar Modelo", "Mi Modelo", "Modelos de mi Equipo"])
            :
                remoteData.value.response.valid === "0" && boss === "Y" ?
                    setViews(["Mi Modelo", "Modelos de mi Equipo"])
                :
                    remoteData.value.response.valid === "0" && boss !== "Y" ?
                        setViews(["Mi Modelo"])
                    : null
        : null
        remoteData._tag === "RemoteFailure" ?
            boss === "Y" ?
                setViews(["Asignar Modelo", "Mi Modelo", "Modelos de mi Equipo"])
            :
                setViews(["Mi Modelo"])
        : null
    }, [remoteData]);

    return (
        <>
            <BreadCrumbs
                crumbs={[{ name: "Inicio", url: "/inicio" }]}
                actual={views.length > 0 ? views[carouselSelected] : ""}
            />
            <h1>Bono Anual</h1>
            <section className="AnnualBond" id="AnnualBond">
                {views.length ? <Carousel setCarouselSelected={setCarouselSelected} carouselSelected={carouselSelected} views={views} /> : null}
                <>
                    {views.length === 0 &&
                        <div className={"text-center"}>
                            <span className="spinner-border spinner-border-xl" />
                        </div>}
                    {views.length === 3 && carouselSelected === 0 &&
                        <>
                            {remoteData._tag === "RemoteSuccess" ?
                                <AssignModel data={remoteData.value.response} setCarouselSelected={setCarouselSelected} />
                            :
                                <div className={remoteData._tag === "RemoteFailure" ? "box mb-4" : "text-center"}>
                                    {remoteData._tag === "RemoteFailure" ? 
                                        <>Error al cargar la información<br/>Intentar más tarde.</>
                                    :
                                        <span className="spinner-border spinner-border-xl" />
                                    }
                                </div>}
                        </>
                    }
                    {(
                        (views.length === 3 && carouselSelected === 1) ||
                        (views.length === 2 && carouselSelected === 0) ||
                        (views.length === 1 && carouselSelected === 0)
                    ) &&
                        <>
                            {remoteMyModel._tag === "RemoteSuccess" ?
                                <MyModel data={remoteMyModel.value.response} />
                            :
                                <div className={remoteMyModel._tag === "RemoteFailure" ? "box mb-4" : "text-center"}>
                                    {remoteMyModel._tag === "RemoteFailure" ? 
                                        <>Error al cargar la información<br/>Intentar más tarde.</>
                                    :
                                        <span className="spinner-border spinner-border-xl" />
                                    }
                                </div>}
                        </>
                    }
                    {(
                        (views.length === 3 && carouselSelected === 2) ||
                        (views.length === 2 && carouselSelected === 1) ||
                        (views.length === 1 && carouselSelected === 1)
                    ) &&
                        <>
                            {remoteTeamAsignation._tag === "RemoteSuccess" ?
                                <TeamModel data={remoteTeamAsignation.value.response} />
                            :
                                <div className={remoteTeamAsignation._tag === "RemoteFailure" ? "box mb-4" : "text-center"}>
                                    {remoteTeamAsignation._tag === "RemoteFailure" ? 
                                        <>Error al cargar la información<br/>Intentar más tarde.</>
                                    :
                                        <span className="spinner-border spinner-border-xl" />
                                    }
                                </div>}
                        </>
                    }
                </>
            </section>
        </>
    )
};
export default AB;