import React, { useContext } from "react";
import { httpClientEnv } from "../../../services/apiServices/httpClient";
import BenefitManager from "./BenefitManager/benefitManager";

export const HttpClientContext = React.createContext(httpClientEnv);
export const useHttpClient = () => useContext(HttpClientContext);

export const Benefit = () => {
    return (
        <HttpClientContext.Provider value={httpClientEnv}>
            <BenefitManager/>
        </HttpClientContext.Provider>
    )
};