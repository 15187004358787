import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import Highlighter from '../../../../commons/highlighter'
import CollapseList from '../../../../commons/collapseList/collapseList'
import { RelatedArticles as RelatedArticlesType } from '../../../../utilities/interfaces'
import { ArticlesProps } from '../../utilities/interfaces'
import { ArticleContent } from '../../../../utilities/constantsTexts'
import textstyle from '../../../../commons/styles/textStyles.module.css'
import articleStyles from '../../styles/articleStyle.module.css'

const RelatedArticles = ({ articles }: ArticlesProps) => {
  const [articlesList, setArticlesList] = useState<RelatedArticlesType[]>([])

  useEffect(() => {
    articles && setArticlesList(articles)
  }, [articles]) 


  return (
    <Grid item xs={12} className='subcategory'>
      <h2 className={textstyle.page_title_2}>{ArticleContent.relatedArticlesTitle}</h2>
      <Highlighter width={35} />
      {articlesList && 
      <Grid item className={articleStyles.related_articles_wrapper}>
        <CollapseList
          isRelatedArticle={true}
          relatedArticles={articlesList}
          maxItems={6}
        /> 
      </Grid>
      }
    </Grid>
  )
}

export default RelatedArticles