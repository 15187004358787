import React, { useState } from 'react';
import { useLocation, Link, useNavigate } from "react-router-dom";
import RequestPlateService from '../../services/request-plate/request-plate-service'
import Swal from 'sweetalert2';

const DetailRequestPlate = () => {
    const { state } = useLocation();

    const [loadingApproveAction, setLoadingApproveAction] = useState(false);
    const [loadingRejectAction, setLoadingRejectAction] = useState(false);
    const [inputComment, setInputComment] = useState("");

    const navigate = useNavigate()
    const onChange = (e) => {
        e.preventDefault()
        setInputComment(e.target.value)
    }

    const handleStatusError = () => {
        Swal.fire({
          allowOutsideClick: false,
          position: 'center',
          title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
          html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo.</div>`,
          confirmButtonColor: '#0169b5',
        }).then((result) => {
          if (result.isConfirmed) {
            console.log("error")
          }
        })
      }
      // Mensaje de éxito al enviar un post
      const handleStatusSuccess = () => {
        Swal.fire({
          allowOutsideClick: false,
          position: 'center',
          title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
          html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
          confirmButtonColor: '#0169b5',
        })
      }

    const onRejectSubmit = async () => {
        setLoadingRejectAction(true)
        try {
            const request = {
                ...state,
                id: state.id.toString(),
                comments: inputComment
            }
            await RequestPlateService.rejectRequest([request]);
            handleStatusSuccess();
        } catch (err) {
            handleStatusError();
        } finally {
            setLoadingRejectAction(false);
        }
    }

    // Aprobar peticion de vacaciones
    const onApproveSubmit = async () => {
        setLoadingApproveAction(true)
        try {
            const request = {
                ...state,
                id: state.id.toString(),
                comments: inputComment
            }
            await RequestPlateService.approveRequest([request]);
            handleStatusSuccess();
        } catch (err) {
            handleStatusError();
        } finally {
            setLoadingApproveAction(false);
        }
    }

    return (
        <>
            <div className="container edit-card">
                <div className="approver">
                    <div className="title">
                        {/* <div className="row"> */}
                        {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6"> */}
                        EMPLEADO:  <span className='approver-name fw-bold link-primary'>{state.userName}</span>
                    </div>
                    {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6"> */}
                    <div className="title">
                        FECHA SOLICITUD:  <span className='approver-name'>{state.applicationRequestDate}</span>
                    </div>
                    {/* </div> */}
                </div>

                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                        <div className="fields">
                            <label className="label">Fecha inicio</label>
                            <input className="form-control" value={state.beginDate} readOnly />
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                        <div className="fields">
                            <label className="label">Fecha final</label>
                            <input className="form-control" value={state.returnDate} readOnly />
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                        <div className="fields">
                            <label className="label">Fecha anticipo</label>
                            <input className="form-control" value={state.advanceDate} readOnly />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className="fields">
                            <label className="label">Comentario colaborador</label>
                            <textarea className="form-control" value={state.applicantComments} readOnly />
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className="fields">
                            <label className="label">Comentario aprobador</label>
                            <textarea className="form-control" onChange={onChange} value={state.status !== 'P' ? state.aprovedComments : null} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="detail-btn-container">
                <div className='button-history-container'>
                    <Link to='../'>Volver <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></Link>
                </div>
                <div className="actions-btn-container">
                    <button disabled={state.status === "A" || state.status === "R" || loadingRejectAction} className="button history" onClick={onRejectSubmit}>
                        Rechazar {loadingRejectAction ? <div className="spinner-border spinner-border-sm"></div> : <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>}
                    </button>
                    <button disabled={state.status === "A" || state.status === "R" || loadingApproveAction} className="button save" onClick={onApproveSubmit}>
                        Aprobar {loadingApproveAction ? <div className="spinner-border spinner-border-sm"></div> : <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>}
                    </button>
                </div>
            </div>
        </>
    );
};

export default DetailRequestPlate;