import React from 'react';

const buildHierarchy = (data) => {
    const parentChildMap = {};

    data.forEach(item => {
        if (!parentChildMap[item.parent]) {
            parentChildMap[item.parent] = [];
        }
        parentChildMap[item.parent].push(item);
    });

    const buildTree = (parentId = 0) => {
        return (parentChildMap[parentId] || []).map(item => ({
            ...item,
            children: buildTree(item.contentreferenceid)
        }));
    };

    return buildTree();
};

export default buildHierarchy;


