import { Auth } from "aws-amplify";
import axios from "axios";


const FormacionAcademicaServices = {
    GetAcademicStudies: async (setInfoStudies, setLoadingStudies, setNoDataStudies) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/emp/academicstudies`, { headers: head })
                setInfoStudies(data)
                setLoadingStudies(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingStudies(false);
                setNoDataStudies(true);
            })
        } catch (error) {
            console.error(error);
        }

    },
    GetAcademicStudy: async (id, setInfoStudy, setLoadingStudy, setNoDataStudy) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/emp/academicstudy/${id}`, { headers: head })
                setInfoStudy({ ...data, institution: data.institution === "" ? "999" : data.institution })
                setLoadingStudy(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingStudy(false);
                setNoDataStudy(true);
            })
        } catch (error) {
            console.error(error);
        }

    },
    DeleteAcademicStudy: async (id, setDeleteStudy, setLoadingDeleteStudy, setNoDataDelete) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                await axios.delete(`${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/emp/academicstudy/${id}`, { headers: head })
                setDeleteStudy(true)
                setLoadingDeleteStudy(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingDeleteStudy(false);
                setNoDataDelete(true);
            })
        } catch (error) {
            console.error(error);
        }

    },
    AddAcademicStudy: async (body, setAddStudyInfo, setLoadingAddStudy, setNoDataAdd, setErrorMessageAdd) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                await axios.post(`${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/emp/academicstudy`, body, { headers: head })
                setAddStudyInfo(true)
                setLoadingAddStudy(false);
            }).catch(function (error) {
                setErrorMessageAdd(error.response.data.message);
                setLoadingAddStudy(false);
                setNoDataAdd(true);
            })
        } catch (error) {
            console.error(error);
        }

    },
    EditAcademicStudy: async (body, setEditStudyInfo, setLoadingEditStudy, setNoDataEdit, setErrorMessageEdit) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                await axios.put(`${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/emp/academicstudy`, body, { headers: head })
                setEditStudyInfo(true)
                setLoadingEditStudy(false);
            }).catch(function (error) {
                setErrorMessageEdit(error.response.data.message);
                setLoadingEditStudy(false);
                setNoDataEdit(true);
            })
        } catch (error) {
            console.error(error);
        }

    },
    GetProfessions: async (setProfessions) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/rrhhbase/professions`, { headers: head })
                setProfessions(data)
                // setLoadingProfessions(false);
            }).catch(function (error) {
                console.log(error);
                // setLoadingProfessions(false);
                // setNoDataProfessions(true);
            })
        } catch (error) {
            console.error(error);
        }

    },
    GetAcademicInstitutions: async (setAcademicInstitutions) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/rrhhbase/academicinstitutions`, { headers: head })
                setAcademicInstitutions(data)
                // setLoadingAcademicInstitutions(false);
            }).catch(function (error) {
                console.log(error);
                // setLoadingAcademicInstitutions(false);
                // setNoDataAcademicInstitutions(true);
            })
        } catch (error) {
            console.error(error);
        }

    },
    GetCountries: async (setCountries) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/location/countries`, { headers: head })

                setCountries(data)
                // setLoadingCountries(false);
            }).catch(function (error) {
                console.log(error);
                // setLoadingCountries(false);
                // setNoDataCountries(true);
            })
        } catch (error) {
            console.error(error);
        }
    },
    GetStates: async (country, setStates, setLoadingStates) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/location/countries/${country}/states`, { headers: head })
                setStates(data)
                setLoadingStates(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingStates(false);
                // setNoDataStates(true);
            })
        } catch (error) {
            console.error(error);
        }
    },
    GetCounties: async (country, state, setCounties, setLoadingCounties) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/location/countries/${country}/states/${state}/counties`, { headers: head })
                setCounties(data)
                setLoadingCounties(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingCounties(false);
                // setNoDataCounties(true);
            })
        } catch (error) {
            console.error(error);
        }
    },
}

export default FormacionAcademicaServices;
