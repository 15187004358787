import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Card, CardContent, Button, Grid } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CardProps from '../../../home/components/card/utilities/interfaces'
import { paths } from '../../../../utilities/paths'
import allCategoryStyles from '../../styles/allCategories.module.css'
import cardStyle from '../../../home/components/card/styles/cardStyle.module.css'

const AllCategoryCard = ({ CardInfo }: CardProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToCategory = (id: number) => {
    navigate(paths[2].path + id, { state: { from: location.pathname } });
  };

  return (
    <Card variant="outlined"
      className={`${allCategoryStyles.all_categories_card} ${cardStyle.category_card}`}
      onClick={() => navigateToCategory(CardInfo.id)}>
      <CardContent className={allCategoryStyles.all_categories_card_content_wrapper}>
        <Grid item>
          <img src={CardInfo.cardIcon} className={allCategoryStyles.all_categories_card_icon} />
        </Grid>
        <div className={allCategoryStyles.all_categories_card_text_wrapper}>
          <h4>{CardInfo.cardTitle}</h4>
          <p>{CardInfo.cardSubtitle}</p>
        </div>
        <Grid item className={allCategoryStyles.all_categories_card_button_wrapper}>
          <Button className={allCategoryStyles.all_categories_card_button}>
            <ArrowForwardIosIcon />
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default AllCategoryCard;