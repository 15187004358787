import React, { Children, useEffect, useState } from "react";
import { AddKpiTableProps, id, kpi } from "../../../../utilities/interfaces";
import { contramap, Eq } from "fp-ts/Eq";
import * as A from "fp-ts/Array";
import * as S from "fp-ts/string";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { range } from "fp-ts/lib/NonEmptyArray";

const getComplementRange = <A,>(array: Array<A>) => array.length + 1 <= 5 ? range(array.length + 1, 5) : range(array.length + 1, array.length + 1);

const eqKpi: Eq<id> = S.Eq;
const eqKpiById: Eq<kpi> = pipe(
    eqKpi,
    contramap((kpi) => kpi.id)
);
const commuteUnion = (kpi1: kpi[]) => (kpi2: kpi[]): kpi[] => A.union(eqKpiById)(kpi2)(kpi1);
    
const KpiModal: React.FC<AddKpiTableProps> = ({ kpiList, setKpiList, contactEmail, reportEmplid, reportName, setKpiLists }) => {
    const [kpiListToDisplay, setKpiListToDisplay] = useState<kpi[]>(range(1, 5).map((k) => {
        return { id: `${k}|${reportEmplid}`, name: "", weight: 0, applySame: false }
    }));
    const [accWeight, setAccWeight] = useState(0);
    const [applySameList, setApplySameList] = useState<boolean[]>(pipe(
        kpiListToDisplay,
        A.map((x) => x.applySame)
    ));

    useEffect(() => {
        kpiList.length && pipe(
            kpiListToDisplay,
            A.filter((k) => k.name !== "" && k.applySame),
            getComplementRange,
            A.map((k) => { return { id: `${k}|${reportEmplid}`, name: "", weight: 0, applySame: false } }),
            commuteUnion(kpiList),
            setKpiListToDisplay,
        );
        pipe(
            kpiListToDisplay,
            A.filter((k) => k.name !== ""),
            getComplementRange,
            A.map((k) => { return { id: `${k}|${reportEmplid}`, name: "", weight: 0, applySame: false } }),
            commuteUnion(kpiList),
            A.map((x) => x.applySame),
            setApplySameList,
        );
    }, [kpiList]);

    useEffect(() => {
        setAccWeight(kpiListToDisplay.reduce((a, b) => a + b.weight, 0));
    }, [kpiListToDisplay]);

    return (
        <>
            <div className="modal fade" id="kpiModal" tabIndex={-1} aria-labelledby="kpiModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <form role="form">
                            <div className="modal-header border-0 pb-0">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <h2 className="h5 mb-4">{"FRG para " + reportName}</h2>
                                {Children.toArray(kpiListToDisplay.map((kpi, index) => {
                                    return (
                                        <div className="mb-3 border-bottom">
                                            <div className="row">
                                                <div className="col-12 col-md-9">
                                                    <div className="mb-3">
                                                        <label className="form-label mb-2"><strong>{`Escribir KPI ${kpi.id.split("|")[0]} (200 caracteres máx.)`}</strong></label>
                                                        <input
                                                            id={`kpiName${index}`}
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={200}
                                                            value={kpi.name}
                                                            readOnly={(index > 0 && kpiListToDisplay[index - 1].name === "" && kpiListToDisplay[index - 1].weight === 0) ||
                                                                (accWeight === 100 && index > 0 && kpiListToDisplay[index - 1].name === "" && kpiListToDisplay[index - 1].weight === 0)}
                                                            onChange={(e) => {
                                                                setKpiListToDisplay((oldKpiList) => {
                                                                    return oldKpiList.map((k, i) => i === index ? { ...k, name: e.target?.value } : { ...k })
                                                                });
                                                            }}
                                                            onBlur={(e) => {
                                                                e.target.value !== "" &&
                                                                setKpiListToDisplay((oldKpiList) => {
                                                                    return oldKpiList.map((k, i) => i === index ? { ...k, weight: 20 } : { ...k })
                                                                });
                                                                // imperative block :(
                                                                if (e.target.value === "") {
                                                                    setKpiListToDisplay((oldKpiList) => {
                                                                        return oldKpiList.map((k, i) => i === index ? { ...k, weight: 0 } : { ...k })
                                                                    });
                                                                    (document.getElementById(`kpiInput${index}`) as HTMLInputElement).value = "";
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-3">
                                                    <div className="mb-3">
                                                        <label className="form-label mb-2"><strong>{"Peso (%)*"}</strong></label>
                                                        <input
                                                            id={`kpiInput${index}`}
                                                            type="number"
                                                            className="form-control"
                                                            maxLength={3}
                                                            min={20}
                                                            max={100}
                                                            onBlur={(e) => {(document.getElementById(`kpiName${index}`) as HTMLInputElement)?.value !== "" &&
                                                                    (parseInt(e.target.value) < 20 || e.target.value === "" || parseInt(e.target.value) > 100) &&
                                                                    setKpiListToDisplay((oldKpiList) => {
                                                                        return oldKpiList.map((k, i) => i === index ? { ...k, weight: 20 } : { ...k })
                                                                    });
                                                                }
                                                            }
                                                            value={kpi.name !== "" || kpi.weight !== 0 ? kpi.weight.toString() : ""}
                                                            readOnly={(document.getElementById(`kpiName${index}`) as HTMLInputElement)?.value === "" ||
                                                                (index > 0 && kpiListToDisplay[index - 1].name === "" && kpiListToDisplay[index - 1].weight < 20) ||
                                                                (accWeight === 100 && index > 0 && kpiListToDisplay[index - 1].name === "" && kpiListToDisplay[index - 1].weight < 20)}
                                                            onChange={(e) => {
                                                                setKpiListToDisplay((oldKpiList) => {
                                                                    return oldKpiList.map((k, i) => i === index ? { ...k, weight: e.target?.value !== "" ? parseInt(e.target?.value) : 0 } : { ...k })
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5">
                                                    <div className="mb-3">
                                                        <div className="form-check mt-3">
                                                            <input
                                                                className="form-check-input me-2 h5"
                                                                type="checkbox"
                                                                id={`applySameKpi${index}`}
                                                                checked={applySameList[index] !== undefined ? applySameList[index] : false}
                                                                disabled={(index > 0 && kpiListToDisplay[index - 1].name === "" && kpiListToDisplay[index - 1].weight < 20) ||
                                                                    (accWeight === 100 && index > 0 && kpiListToDisplay[index - 1].name === "" && kpiListToDisplay[index - 1].weight < 20)}
                                                                onChange={() => {
                                                                    pipe(
                                                                        applySameList,
                                                                        A.updateAt(index, !applySameList[index]),
                                                                        O.getOrElse(() => applySameList),
                                                                        setApplySameList,
                                                                    )
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor={`applySameKpi${index}`}>
                                                                Aplicar mismo KPI a todos mis reportes
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }))}
                                <div className="d-grid d-md-flex justify-content-md-center gap-2 flex-nowrap mt-5">
                                    <button
                                        type="button"
                                        className="btn btn-degradado col-12 col-md-auto px-5 order-md-last ms-md-2"
                                        data-bs-dismiss="modal"
                                        disabled={accWeight !== 100 || kpiListToDisplay.filter((k) => k.name !== "").length > 5}
                                        onClick={() => {
                                            const applySamefiltered = pipe(
                                                kpiListToDisplay,
                                                A.zip(applySameList),
                                                A.filterMap((x) => x[1] ? O.some(x[0]) : O.none),
                                                A.filter((k) => k.name !== ""),
                                            );
                                            pipe(
                                                kpiListToDisplay,
                                                A.zip(applySameList),
                                                A.map((k) => { return { ...k[0], applySame: k[1] } }),
                                                A.filter((k) => k.name !== ""),
                                                setKpiList,
                                            );
                                            applySamefiltered.length && setKpiLists && 
                                                setKpiLists((oldLists) => oldLists.map((list) => {
                                                    return pipe(
                                                        list,
                                                        commuteUnion(applySamefiltered),
                                                    )
                                                }));
                                        }}
                                    >
                                        {"Agregar "}
                                        <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                                    </button>
                                    <button type="button" className="btn btn-primary col-12 col-md-auto px-5" data-bs-dismiss="modal">{"Cancelar "}<i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" /></button>
                                </div>
                            </div>
                            {(accWeight > 100 || kpiListToDisplay.filter((k) => k.name !== "").length > 5) && 
                                <div className="d-grid d-md-flex justify-content-md-center gap-2 flex-nowrap invalid-feedback d-block">
                                    {accWeight > 100 ? "La suma de los pesos no puede superar el 100%." : ""}
                                    {kpiListToDisplay.filter((k) => k.name !== "").length > 5 ? " No puede ingresar más de 5 KPI's, por favor, dejar en 0% el que quiera borrar." : ""}
                                </div>}
                            <div className="modal-footer border-0 pt-0 justify-content-center pb-5">
                                <div className="border-top mt-5 pt-3 text-center w-100">
                                    <p className="m-0">Puedes ingresar hasta 5 KPI's. Si necesitas incorporar otro de manera excepcional, favor comunícate con{Children.toArray(contactEmail?.split(", ").map((mail, index, arr) => {
                                        return (
                                            <><a href={`mailto:${mail}`}>{mail}</a>{index < arr.length -1 ? "," : ""}</>
                                        )
                                    }))}.</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
};

export default KpiModal;