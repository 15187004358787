import React, { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { toBase64 } from '../../services/utiles';
import OtrosEstudiosServices from "../../services/otros estudios/otros-estudios-service";
import styles from "../../resources/css/style-COL.module.css";

function ModificarOtrosEstudios({ id, subjectAreas, countries, reloadAcademicStudies }) {
    const [submit, setSubmit] = useState(false)
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue
    } = useForm();
    const [infoStudy, setInfoStudy] = useState([])
    const [loadingStudy, setLoadingStudy] = useState({})
    const [, setNoDataStudy] = useState([])
    const [deleteStudy, setDeleteStudy] = useState(false)
    const [loadingDeleteStudy, setLoadingDeleteStudy] = useState(false)
    const [noDataDelete, setNoDataDelete] = useState(false)
    const [errorMessageEdit, setErrorMessageEdit] = useState("")
    const [editStudyInfo, setEditStudyInfo] = useState(false)
    const [loadingEditStudy, setLoadingEditStudy] = useState(false)
    const [noDataEdit, setNoDataEdit] = useState(false)
    const [states, setStates] = useState([])
    const [loadingStates, setLoadingStates] = useState(false)
    const [counties, setCounties] = useState([])
    const [loadingCounties, setLoadingCounties] = useState(false)
    const [errorProgramName, setErrorProgramName] = useState(null)
    const [errorInstitution, setErrorInstitution] = useState(null)
    const [errorCity, setErrorCity] = useState(null)
    const [errorFechas, setErrorFechas] = useState(null)
    const [errorAttachment, setErrorAttachment] = useState(null)

    const onSubmit = async (data) => {
        setErrorProgramName(null)
        setErrorInstitution(null)
        setErrorCity(null)
        setErrorFechas("")
        setErrorAttachment(null)
        if(data.attachment && data.attachment[0].name.length > 30){
            setErrorAttachment("El nombre del archivo supera el máximo de caracteres.")
        }
        if (data.program_name.length > 50) {
            setErrorProgramName("*Supera el máximo de caracteres");
            return;
        }
        if (data.institution.length > 50) {
            setErrorInstitution("*Supera el máximo de caracteres");
            return;
        }
        if (data.city.length > 30) {
            setErrorCity("*Supera el máximo de caracteres");
            return;
        }
        if (data.study_start_date > data.study_end_date) {
            setErrorFechas("*La fecha final no puede ser menor a la fecha inicial.")
            return;
        } else {
            if (data.study_end_date) {
                if (data.study_start_date > data.study_end_date) {
                    setErrorFechas("*La fecha final no puede ser menor a la fecha inicial.")
                    return;
                }
            }
        }

        const submit = {
            id: infoStudy.id.toString(),
            city: data.country === "COL" ? "" : data.city,
            country: data.country,
            county: data.country === "COL" ? data.county : "",
            institution: data.institution,
            state: data.country === "COL" ? data.state : "",
            study_start_date: data.study_start_date,
            study_end_date: data.study_end_date ? data.study_end_date : "",
            study_status: data.study_status,
            attachment: data.attachment ? await toBase64(data.attachment[0]) : "",
            attachment_name: data.attachment ? data.attachment[0].name : "",
            subject_area: data.subject_area,
            program_name: data.program_name
        }
        setLoadingEditStudy(true)
        OtrosEstudiosServices.EditOtherStudy(submit, setEditStudyInfo, setLoadingEditStudy, setNoDataEdit, setErrorMessageEdit)
    };

    useEffect(() => {
        if (id !== null) {
            OtrosEstudiosServices.GetOtherStudy(id, setInfoStudy, setLoadingStudy, setNoDataStudy)
        }
    }, [id])

    useEffect(() => {
        if (infoStudy.country === "COL") {
            setLoadingStates(true)
            OtrosEstudiosServices.GetStates(infoStudy.country, setStates, setLoadingStates)
            OtrosEstudiosServices.GetCounties(infoStudy.country, infoStudy.state, setCounties, setLoadingCounties)
        }
    }, [infoStudy.country])

    useEffect(() => {
        if (infoStudy.country === "COL") {
            setLoadingCounties(true)
            OtrosEstudiosServices.GetCounties(infoStudy.country, infoStudy.state, setCounties, setLoadingCounties)
        }
    }, [infoStudy.state])

    useEffect(() => {
        if (watch("country") === "COL") {
            setLoadingStates(true)
            OtrosEstudiosServices.GetStates(watch("country"), setStates, setLoadingStates)
        }
    }, [watch("country")])

    useEffect(() => {
        if (watch("country") === "COL") {
            setLoadingCounties(true)
            OtrosEstudiosServices.GetCounties(watch("country"), watch("state"), setCounties, setLoadingCounties)
        }
    }, [watch("state")])

    useEffect(() => {
        setErrorAttachment(null)
    },[watch("attachment")])

    const deleteOneStudy = () => {
        Swal
            .fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: red; font-size: 16px"> Eliminar </div>',
                html: '<div style="text-align: center; font-size: 15px"> ¿Desea continuar la operación? </div>',
                showCancelButton: true,
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                confirmButtonColor: "red",
                cancelButtonColor: "#0169b5"
            }).then((result) => {
                if (result.isConfirmed) {
                    setLoadingDeleteStudy(true)
                    OtrosEstudiosServices.DeleteOtherStudy(infoStudy.id, setDeleteStudy, setLoadingDeleteStudy, setNoDataDelete)
                }
            })
    }

    // Mensaje de error al enviar un post
    const handleStatusErrorEdit = (errorMessage) => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
            html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. ${errorMessage}</div>`,
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadAcademicStudies()
        })
        setLoadingEditStudy(false)
        setNoDataEdit(false)
    }

    // Mensaje de éxito al enviar un post
    const handleStatusSuccessEdit = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
            html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadAcademicStudies()
        })
        setLoadingEditStudy(false)
        setNoDataEdit(false)
    }

    // Mensaje de error al enviar un post
    const handleStatusErrorDelete = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
            html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo.</div>`,
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadAcademicStudies()
        })
        setLoadingDeleteStudy(false)
        setNoDataDelete(false)
    }

    // Mensaje de éxito al enviar un post
    const handleStatusSuccessDelete = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
            html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadAcademicStudies()
        })
        setLoadingDeleteStudy(false)
        setNoDataDelete(false)
    }

    useEffect(() => {
        // noDataDelete ? handleStatusErrorDelete() : null
        deleteStudy ? handleStatusSuccessDelete() : null
    }, [loadingDeleteStudy])

    useEffect(() => {
        // noDataEdit ? handleStatusErrorEdit() : null
        editStudyInfo ? handleStatusSuccessEdit() : null
    }, [loadingEditStudy])

    return (
        <>
            {!loadingStudy ?
                <>
                    <div className={`${styles["box"]} ${styles["mb-4"]}`}>
                        <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Modificar Estudios</h2>
                        <form>
                            {/* <!-- Fila 1 --> */}
                            <div className={styles["row"]}>
                                <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                                    <label className={styles["form-label"]}>Nombre del Programa <span className={styles["color-red"]}>*</span></label>
                                    <input type="text" className={styles["form-control"]} maxLength={50} {...register("program_name", { required: "* Campo obligatorio" })} defaultValue={infoStudy.program_name} />
                                    <small className="form-error-message">{errors.program_name?.message}</small>
                                    <small className="form-error-message">{errorProgramName}</small>
                                </div>
                                <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                                    <label className={styles["form-label"]}>Institución <span className={styles["color-red"]}>*</span></label>
                                    <input type="text" className={styles["form-control"]} maxLength={50} {...register("institution", { required: "* Campo obligatorio" })} defaultValue={infoStudy.institution} />
                                    <small className="form-error-message">{errors.institution?.message}</small>
                                    <small className="form-error-message">{errorInstitution}</small>
                                </div>
                            </div>
                            {/* <!-- Fila 2 --> */}
                            <div className={styles["row"]}>
                                <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                                    <label className={styles["form-label"]}>Área Temática <span className={styles["color-red"]}>*</span></label>
                                    <div className={styles["input-group"]}>
                                        <select className={styles["select-col"]} {...register("subject_area", { required: "* Campo obligatorio" })} >
                                            <option value="" selected>Seleccione</option>
                                            {subjectAreas.map(s => {
                                                return (
                                                    <option key={s.id} value={s.id} selected={infoStudy.subject_area === s.id}>{s.description}</option>
                                                )
                                            })}
                                        </select>
                                        <small className={styles["form-error-message"]}>{errors.subject_area?.message}</small>
                                    </div>
                                </div>
                                <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                                    <label className={styles["form-label"]}>Adjuntar documentación (Archivos menores a 5Mb)</label>
                                    <input
                                        className={styles["form-control"]}
                                        type="file"
                                        id="file"
                                        onChange={e => {
                                            if (
                                                e.target.files[0]?.type === "image/jpeg" ||
                                                e.target.files[0]?.type === "image/jpg" ||
                                                e.target.files[0]?.type === "application/pdf" ||
                                                e.target.files[0]?.type === "image/png"
                                            ) {
                                                if (e.target.files[0].size <= 5000000) {
                                                    setValue("attachment", e.target?.files, { shouldValidate: true, shouldDirty: true })
                                                } else {
                                                    Swal.fire({
                                                        allowOutsideClick: false,
                                                        position: 'center',
                                                        title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                                                        html: '<div style="text-align: center; font-size: 15px">El tamaño del Certificado debe ser menor a 5Mb</div>',
                                                        confirmButtonColor: '#11427C',
                                                    }
                                                    )
                                                }
                                            } else {
                                                Swal.fire({
                                                    allowOutsideClick: false,
                                                    position: 'center',
                                                    title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                                                    html: '<div style="text-align: center; font-size: 15px">Debe ingresar uno de los tipos de archivos permitidos</div>',
                                                    confirmButtonColor: '#11427C',
                                                })
                                            }
                                        }}
                                        formcontrolname="file"
                                        name="file"
                                        accept=".pdf, .jpeg, .jpg, .png"
                                    />
                                <small className="form-error-message">{errorAttachment}</small>
                                </div>
                            </div>
                            {/* <!-- Fila 3 --> */}
                            <div className={styles["row"]}>
                                <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                                    <label className={styles["form-label"]}>País <span className={styles["color-red"]}>*</span></label>
                                    <div className={styles["input-group"]}>
                                        <select className={styles["select-col"]} {...register("country", { required: "* Campo obligatorio" })}>
                                            <option value="" selected>Seleccione</option>
                                            {
                                                countries.map((c) => {
                                                    return (
                                                        <option key={c.country} value={c.country} selected={infoStudy.country === c.country}>{c.descr}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <small className={styles["form-error-message"]}>{errors.country?.message}</small>
                                    </div>
                                </div>
                                <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]} ${watch("country") === "COL" ? styles["d-none"] : null}`}>
                                    <label className={styles["form-label"]}>Ciudad <span className={styles["color-red"]}>*</span></label>
                                    <input type="text" className={styles["form-control"]} maxLength={30} {...register("city", { required: watch("country") !== "COL" ? "* Campo obligatorio" : false, maxLength: 30 })} defaultValue={infoStudy.city} />
                                    <small className="form-error-message">{errors.city?.message}</small>
                                    <small className="form-error-message">{errorCity}</small>
                                </div>
                            </div>
                            {/* <!-- Fila 4 --> */}
                            <div className={styles["row"]}>
                                <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]} ${watch("country") !== "COL" ? styles["d-none"] : null}`}>
                                    <label className={styles["form-label"]}>Departamento <span className={styles["color-red"]}>*</span></label>
                                    <div className={styles["input-group"]}>
                                        {!loadingStates ? <select className={styles["select-col"]} {...register("state", { required: watch("country") === "COL" ? "* Campo obligatorio" : false, maxLength: 3 })}>
                                            <option value="" selected>Seleccione</option>
                                            {
                                                states.map(s => {
                                                    return (
                                                        <option key={s.state} value={s.state} selected={infoStudy.state === s.state}>{s.descrshort}</option>
                                                    )
                                                })
                                            }
                                        </select> : <div><div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                                        <small className={styles["form-error-message"]}>{errors.state?.message}</small>
                                    </div>
                                </div>
                                <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]} ${watch("country") !== "COL" ? styles["d-none"] : null}`}>
                                    <label className={styles["form-label"]}>Ciudad <span className={styles["color-red"]}>*</span></label>
                                    <div className={styles["input-group"]}>
                                        {!loadingCounties ? <select className={styles["select-col"]} {...register("county", { required: watch("country") === "COL" ? "* Campo obligatorio" : false, maxLength: 3 })}>
                                            {
                                                counties.map((c) => {
                                                    return (
                                                        <option key={c.county} value={c.county} selected={infoStudy.county === c.county}>{c.descr}</option>
                                                    )
                                                })
                                            }
                                        </select> : <div><div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                                        <small className={styles["form-error-message"]}>{errors.county?.message}</small>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Fila 5 --> */}
                            <div className={styles["row"]}>
                                <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                                    <label className={styles["form-label"]}>Estado <span className={styles["color-red"]}>*</span></label>
                                    <div className={styles["row"]}>
                                        <div className={`${styles["col-12"]} ${styles["col-md-4"]}`}>
                                            <div className={styles["form-check"]}>
                                                <input className={styles["form-check-input"]} type="radio" name="study_status" id="estado1" value="T"
                                                    {...register("study_status", { required: "* Campo obligatorio", maxLength: 1 })} defaultChecked={infoStudy.study_status === "T"} />
                                                <label className={styles["form-check-label"]} htmlFor="estado1">Titulado</label>
                                            </div>
                                        </div>
                                        <div className={`${styles["col-12"]} ${styles["col-md-4"]}`}>
                                            <div className={styles["form-check"]}>
                                                <input className={styles["form-check-input"]} type="radio" name="study_status" id="estado2" value="P"
                                                    {...register("study_status", { required: "* Campo obligatorio", maxLength: 1 })} defaultChecked={infoStudy.study_status === "P"} />
                                                <label className={styles["form-check-label"]} htmlFor="estado2">En curso</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* <!-- Fila 6 --> */}
                            <div className={styles["row"]}>
                                <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                                    <label className={styles["form-label"]}>Fecha de Inicio <span className={styles["color-red"]}>*</span></label>
                                    <input type="date" className={styles["form-control"]} {...register("study_start_date", { required: "* Campo obligatorio", maxLength: 50 })} defaultValue={infoStudy.study_start_date} />
                                    <small className={styles["form-error-message"]}>{errors.study_start_date?.message}</small>
                                </div>
                                <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                                    <label className={styles["form-label"]}>Fecha de Finalización {watch("study_status") === 'T' ? <span className={styles["color-red"]}>*</span> : null}</label>
                                    <input type="date" className={styles["form-control"]} {...register("study_end_date", { required: watch("study_status") === 'T' ? "* Campo obligatorio" : false, maxLength: 50 })} defaultValue={infoStudy.study_end_date} />
                                    <small className={styles["form-error-message"]}>{errors.study_end_date?.message}</small>{" "}
                                    <small className="form-error-message">{errorFechas}</small>
                                </div>
                            </div>
                            {/* <!-- fin filas --> */}
                        </form>

                        <div className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mt-4"]}`}>
                            <div className={`${styles["col-12"]} ${styles["col-md-auto"]}`}>
                                <button type="button" className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`} onClick={() => deleteOneStudy()} disabled={loadingDeleteStudy}>Eliminar {loadingDeleteStudy ? <div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div> : <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i>}</button>
                            </div>
                        </div>

                    </div>
                    {/* <!-- Fin Modificar --> */}

                    <div className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mt-4"]}`}>
                        <div className={`${styles["col-12"]} ${styles["col-md-auto"]}`}>
                            <button type="button" className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["w-100"]}`} onClick={handleSubmit(onSubmit)} disabled={loadingEditStudy}>Guardar {loadingEditStudy ? <div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div> : <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i>}</button>
                        </div>
                    </div>
                </> :
                <div data-testid="loading" className={styles["full-spinner"]}>
                    <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
                </div>
            }
            {noDataEdit ? handleStatusErrorEdit(errorMessageEdit) : null}
            {noDataDelete ? handleStatusErrorDelete() : null}
        </>
    );
}

export default ModificarOtrosEstudios;