import React, { useEffect, useState } from 'react'
import DatosPersonalesServices from '../../services/datos personales col/datos-personales-services'
import fotoDefault from "../../resources/images/default-colaborador-image.png"
import styles from "../../resources/css/style-COL.module.css";
import DatosLaboralesService from "../../services/datos laborales/datos-laborales-service";

export default function DetalleCargo() {
    const [employeeInfo, setEmployeeInfo] = useState({})
    const [loadingEmployeeInfo, setLoadingEmployeeInfo] = useState(true)
    const [noEmployeeData, setNoEmployeeData] = useState(false)
    const [avatar, setAvatar] = useState(null)
    const [loadingAvatar, setLoadingAvatar] = useState(false)
    
    const [loadInfo, setLoadInfo] = useState(true);
const loadAvatar = async () => {
        try{
            const response = await DatosLaboralesService.getAvatarRegional();
            setAvatar(response.data);
            setLoadingAvatar(false);
        } catch (e){
            setAvatar(null);
            setLoadingAvatar(false);
        }
    }
    useEffect(() => {
        loadAvatar()
        DatosPersonalesServices.getDetalleCargo(setEmployeeInfo, setLoadingEmployeeInfo, setNoEmployeeData,setLoadInfo)
    }, [])
    return (
        <>
        { loadingEmployeeInfo ? (
            <div className={`${styles["col-12"]} ${styles["col-xl-4"]} ${styles["d-flex"]} ${styles["mb-4"]} ${styles["mb-xl-0"]}`}>
            <div className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}>
              <div className={styles["alert"]} role="alert">
                <i
                  className={`${styles["fa"]} ${styles["fa-spinner"]} ${styles["fa-spin"]} ${styles["color-orange"]} ${styles["fa-fw"]}`}
                  aria-hidden="true"
                ></i>
                <span className={styles["ms-2"]}>Cargando...</span>
              </div>
            </div>
          </div>
        ) : (
            <div className={`${styles["col-12"]} ${styles["col-xl-4"]} ${styles["d-flex"]} ${styles["mb-4"]} ${styles["mb-xl-0"]}`}>
            {/* <!-- Nombre y cargo --> */}
            {!noEmployeeData ?
               <div className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}>
                    {!loadingAvatar ? <img src={avatar === null ? fotoDefault : avatar} alt="" className={`${styles["rounded-circle"]} ${styles["mb-3"]}`} width="120" height="120" /> :
                        <div data-testid="loading" className={styles["full-spinner"]}>
                            <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
                        </div>
                    }
                     {!loadingEmployeeInfo ?  <>
                    <h2 className={`${styles["h3"]} ${styles["mb-2"]}`}>{employeeInfo.firstname + " " + employeeInfo.lastname}</h2>
                    <p className={`${styles["h5"]} ${styles["fw-bold"]}`}>{employeeInfo.position_description}</p>

                    <div className={`${styles["data-list-box"]} ${styles["data-list-box-align-middle"]} ${styles["mt-3"]}`}>
                        <ul className={`${styles["list-unstyled"]} ${styles["m-0"]}`}>
                            <li className={"text-break"}>
                                <span className={`${styles["ic-circle"]} ${styles["ic-circle-primary"]}`}><i className={`${styles["fa"]} ${styles["fa-envelope"]} ${styles["fa-fw"]}`}></i></span>
                                {employeeInfo.business_email?employeeInfo.business_email:(<></>)}
                            </li>
                            <li>
                                <span className={`${styles["ic-circle"]} ${styles["ic-circle-primary"]}`}><i className={`${styles["fa"]} ${styles["fa-map-marker"]} ${styles["fa-fw"]}`}></i></span>
                                {employeeInfo.location_description}
                            </li>
                            <li>
                                <span className={`${styles["ic-circle"]} ${styles["ic-circle-primary"]}`}><i className={`${styles["fa"]} ${styles["fa-hashtag"]} ${styles["fa-fw"]}`}></i></span>
                                {employeeInfo.organization_position}
                            </li>
                        </ul>
                    </div> </>:
                    <div data-testid="loading" className={styles["full-spinner"]}>
                        <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
                    </div>}

                </div>
                : <div className={`${styles["alert"]} ${styles["alert-info"]} ${styles["alert-dismissible"]} ${styles["fade"]} ${styles["show"]} ${styles["text-center"]}`} role="alert">
                    No se encontraron datos asociados
            </div>}
        </div>
        )} 
        </>
    )
}