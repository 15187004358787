import React, { FC, ReactNode, useReducer } from "react";
import { uiReducer } from "./uiReducer";
import { PageTypes } from "../../interfaces/applyInterface";
import { UIContext } from "./UIContext";

export interface IUIContext {
    actualPage: PageTypes;
    hasError: boolean;
    isLoading: boolean;
    isModalVideoOpen: boolean;
    paginationPage: number;
}

interface Props {
    children: ReactNode;
}

const INITIAL_STATE: IUIContext = {
    actualPage: "SEARCH_APPLY",
    hasError: false,
    isLoading: false,
    isModalVideoOpen: false,
    paginationPage: 1,
};

export const UIProvider: FC<Props> = ({ children }) => {
    const [state, dispatch] = useReducer(uiReducer, INITIAL_STATE);

    const setIsLoading = (isLoading: boolean) => {
        dispatch({ type: "UI - Set Is Loading", payload: isLoading });
    };

    const setPage = (page: PageTypes) => {
        dispatch({ type: "UI - Set Actual Page", payload: page });
    };

    const setIsModalVideoOpen = (isModalVideoOpen: boolean) => {
        dispatch({
            type: "UI - Set Is Modal Video Open",
            payload: isModalVideoOpen,
        });
    };

    const setPaginationPage = (paginationPage: number) => {
        dispatch({ type: "UI - Set Pagination Page", payload: paginationPage });
    };

    const setHasError = (hasError: boolean) => {
        dispatch({
            type: "UI - Set Has Error",
            payload: hasError,
        });
    };

    return (
        <UIContext.Provider
            value={{
                //Variables
                ...state,

                //Funciones
                setHasError,
                setIsLoading,
                setIsModalVideoOpen,
                setPage,
                setPaginationPage,
            }}
        >
            {children}
        </UIContext.Provider>
    );
};
