import React, { useState } from 'react';
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Label,
	Input
} from 'reactstrap';
import Swal from 'sweetalert2';

import menuService from '../../services/permissions/access-service';

const ModalCreateRol = ({
	pageSelected,
	isOpen,
	toggle,
	getPages
}) => {
	const [rol, setRol] = useState('');

	const handleAssignRolToPage = async () => {
		try {
			const payload = {
				contentreferenceitemid: pageSelected,
				rol: rol
			};
			await menuService.assingRolToPage(payload);
			Swal.fire({
				allowOutsideClick: false,
				position: 'center',
				title: `<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px">Accion realizada</div>`,
				html: `<div style="text-align: center; font-size: 15px">Rol asignado correctamente</div>`,
				confirmButtonColor: '#0169b5',
			}).then(() => {
				getPages();
			})
		} catch(err) {
			console.log(err.response);
			Swal.fire({
				allowOutsideClick: false,
				position: 'center',
				title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
				html: `<div style="text-align: center; font-size: 15px">${err?.response?.data?.message}</div>`,
				confirmButtonColor: '#0169b5',
			})
		} finally {
			toggle();
		}
	}

	return (
		<Modal isOpen={isOpen} toggle={toggle}>
			<ModalHeader toggle={toggle}>
				Asociar rol
			</ModalHeader>
			<ModalBody>
				<Label>Nombre</Label>
				<Input
					type="text"
					value={rol}
					onChange={(evt) => setRol(evt.target.value)}
				/>
			</ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={handleAssignRolToPage}>
					Guardar
				</Button>{' '}
				<Button color="secondary" onClick={toggle}>
					Cancelar
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ModalCreateRol;