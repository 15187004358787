import React, { Children, isValidElement, useState } from "react";
import { TabProps, TabsProps } from "./tab.types";
import { TabPanel } from "./tabPanel";

export const Tabs = (props: TabsProps) => {
  const { children } = props;
  const [value, setValue] = useState(0);

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      {/* TAB HEADER */}
      <div role="tablist" className="nav nav-tabs text-uppercase">
        {Children.map(children, (child, index) => {
          if (isValidElement<TabProps>(child)) {
            return React.cloneElement(child, {
              key: index,
              onClick: () => handleChange(index),
              selected: value === index,
            });
          }
          return null;
        })}
      </div>

      {/* TAB CONTENT */}
      {Children.map(children, (child, index) => {
        if (isValidElement(child)) {
          return (
            <TabPanel value={value} index={index} key={index}>
              {child.props.children}
            </TabPanel>
          );
        }
        return null;
      })}
    </>
  );
};
