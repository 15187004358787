import React, { useState, useEffect, useMemo, Children, useContext } from "react";
import moduloDtService from "../services/modulo-dt-service";
import remuneracionService from "../services/remuneracion-service";
import { decrypt, encrypt } from "../services/utiles";
import Modales from "./elements/modal-dt";
import { Base64 } from "js-base64";
import { Link } from "react-router-dom";
import { Context } from '../context';
import { DTGobNorm } from "./commonComponents/DTGobNorm";

const AnexoYRemuneracionDt = () => {
    const { setAuditoria, firstLoginData } = useContext(Context);
    const [datosTrabajador, setDatosTrabajador] = useState(false);
    const [dataDt, setDataDt] = useState([]);
    const [validData, setValidData] = useState(false);
    const [msj, setMsj] = useState(false);
    const [rut, setRut] = useState(false);
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    const today = new Date(Date.now());
    let year = today.getFullYear();
    const [bloquear, setBloquear] = useState(false);
    const ModalBasico = Modales.ModalBasico;
    const [descripcion, setDescripcion] = useState("");
    const [data2, setData2] = useState(null);
    const [data2Xls, setData2Xls] = useState(null);
    const [modalOff, setModalOff] = useState(false);
    const [cargando, setCargando] = useState(false);

    const monthOption = [
        {
            value: "ene",
            int: "01",
            label: "Enero",
        },
        {
            value: "feb",
            int: 2,
            label: "Febrero",
        },
        {
            value: "mar",
            int: 3,
            label: "Marzo",
        },
        {
            value: "abr",
            int: 4,
            label: "Abril",
        },
        {
            value: "may",
            int: 5,
            label: "Mayo",
        },
        {
            value: "jun",
            int: 6,
            label: "Junio",
        },
        {
            value: "jul",
            int: 7,
            label: "Julio",
        },
        {
            value: "ago",
            int: 8,
            label: "Agosto",
        },
        {
            value: "sep",
            int: 9,
            label: "Septiembre",
        },
        {
            value: "oct",
            int: 10,
            label: "Octubre",
        },
        {
            value: "nov",
            int: 11,
            label: "Noviembre",
        },
        {
            value: "dic",
            int: 12,
            label: "Diciembre",
        },
    ];

    monthOption.unshift({ value: "", int: 0, label: "Seleccione mes" });
    const yearOption = useMemo(() => [], [year]);
    yearCharge();
    function yearCharge() {
        if (yearOption.length === 0) {
            yearOption.push({ value: "", label: "Selecione año", int: 0 });
            for (let y = year; y >= 2020; y--) {
                yearOption.push({
                    value: String(y),
                    label: String(y),
                    int: y,
                });
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDataDt([])
        setValidData(false);
        setRut(false);
        setSelectedYear("");
        setSelectedMonth("");
        setBloquear(true)
        const ruts = document.getElementById("rut").value;
        moduloDtService.validateRut(ruts, firstLoginData.USERDT, setDatosTrabajador, setMsj, setRut);
        setAuditoria({ user: encrypt(firstLoginData.USERDT), descripcion: encrypt("valida permisos para consultar el usuario anexo y remuneracion"), rut: encrypt(ruts) })

    };

    useEffect(() => {
        if (!(msj == true && datosTrabajador != false && rut != false)) {
            if (msj == 2 && datosTrabajador == 2 && rut == 0) {
                document.getElementById("openModalPermisoDenegado").click();
                setDatosTrabajador(false);
                setDatosTrabajador(false);
                setMsj(false);
                setRut(false);
                setDataDt([]);
            }
        }
    }, [rut]);

    async function handleSubmitBuscar(e) {
        e.preventDefault();
        const ruts = document.getElementById("rut").value;
        if (selectedYear === "" || selectedMonth === "") {
            document.getElementById("openModalFail").click();
        } else {
            setBloquear(true);
            remuneracionService.getremuneracion(ruts, selectedMonth, selectedYear, setDataDt, firstLoginData.USERDT);
            setAuditoria({ user: encrypt(firstLoginData.USERDT), descripcion: encrypt("Buscar anexo mes: " + selectedMonth + " Año: " + selectedYear), rut: encrypt(ruts) })
        }
    }

    useEffect(() => {
        setBloquear(false);
    }, [selectedMonth, selectedYear]);

    useEffect(() => {
        if (dataDt.length == 1) {
            if (decrypt(dataDt[0].emplid) == "") {
                setValidData(false);
                document.getElementById("openModalNoAnexos").click();
            } else {
                setValidData(true);
            }
        } else if (dataDt === "") {
            setValidData(false);
            document.getElementById("openModalNoAnexos").click();
            setDataDt(false);
        }
    }, [dataDt]);

    const handleXls = (e, descripcion, concepto) => {
        e.preventDefault();
        if (dataDt !== undefined && dataDt.length !== 0) {
            setDescripcion(descripcion);
            remuneracionService.getremuneracion2(rut, selectedMonth, selectedYear, setData2Xls, "", "XLS", dataDt, concepto, "", setModalOff, setCargando, firstLoginData.USERDT);
            document.getElementById("openModalDescargando").click();
        }
    };

    const handlePdf = (e, descripcion, concepto) => {
        e.preventDefault();
        if (dataDt !== undefined && dataDt.length !== 0) {
            setDescripcion(descripcion);
            remuneracionService.getremuneracion2(rut, selectedMonth, selectedYear, setData2, "", "PDF", dataDt, concepto, "", setModalOff, setCargando, firstLoginData.USERDT);
            document.getElementById("openModalDescargando").click();
        }
    };

    function base64ToArrayBuffer(base64) {
        base64 = decrypt(base64);
        if (base64.charAt(0) === "0") {
            base64 = base64.substring(1).replaceAll("\n", "").replaceAll(" ", "");
        }
        let binaryString = Base64.atob(base64);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        setTimeout(function () {
            document.getElementById("closeModalBasicoA").click();
        }, 500);
        document.getElementById("closeModalBasicoA").click();
        setData2Xls(null);
        setData2(null);
        return bytes;
    }

    function saveByteArrayPDF(reportName, byte) {
        let blob = new Blob([byte], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        let fileName = reportName;
        link.download = fileName;
        link.dispatchEvent(new MouseEvent("click"));
        setCargando(false);
    }

    function saveByteArrayExcel(reportName, byte) {
        let blob = new Blob([byte], { type: "application/vnd.ms-excel" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        let fileName = reportName;
        link.download = fileName;
        link.dispatchEvent(new MouseEvent("click"));
        setCargando(false);
    }

    useEffect(() => {
        if (data2 != undefined && data2 != null) {
            let ascii = base64ToArrayBuffer(data2.email_att);
            saveByteArrayPDF(descripcion.replaceAll(" ", "_") + "-" + selectedMonth + "-" + selectedYear, ascii);
        }
    }, [data2]);

    useEffect(() => {
        if (data2Xls != undefined && data2Xls != null) {
            let ascii = base64ToArrayBuffer(data2Xls.email_att);
            saveByteArrayExcel(descripcion.replaceAll(" ", "_") + "-" + selectedMonth + "-" + selectedYear, ascii);
        }
    }, [data2Xls]);

    useEffect(() => {
        if (modalOff == "offline") {
            setTimeout(function () {
                document.getElementById("closeModalBasicoA").click();
                document.getElementById("openModalSinConexion").click();
            }, 1000);
            setModalOff("online");
        }
    }, [modalOff]);

    const disabledBtn = (e) => {
        e.preventDefault();
        let input = e.target.value;
        let btn = document.getElementById("btnBuscar");
        if (input == "") {
            btn.disabled = true;
        } else {
            btn.disabled = false;
        }
    }

    return (
        <>
            <ModalBasico
                idBtn="openModalFail"
                idModal="modalFail"
                target="modalFail"
                msj=" Seleccione año y mes"
                p=""
                modalCierre="closeModalBasico"
                icon="fa fa-exclamation-triangle color-orange fa-3x"
            ></ModalBasico>
            <ModalBasico
                idBtn="openModalDescargando"
                idModal="modalDescargando"
                target="modalDescargando"
                msj="Descargando..."
                p=""
                modalCierre="closeModalBasicoA"
                icon="fa fa-exclamation-triangle color-orange fa-3x"
            ></ModalBasico>
            <ModalBasico
                idBtn="openModalNoAnexos"
                idModal="modalNoAnexos"
                target="modalNoAnexos"
                msj="No existen Anexos para el período consultado"
                p=""
                modalCierre="closeModalBasico"
                icon="fa fa-exclamation-triangle color-orange fa-3x"
            ></ModalBasico>
            <ModalBasico
                idBtn="openModalSinConexion"
                idModal="modalNoConexion"
                target="modalNoConexion"
                msj="No tienes conexión"
                p="No puedes ejecutar esta acción si no estás conectado."
                modalCierre="closeModalBasico"
                icon="fa fa-exclamation-triangle color-orange fa-3x"
            ></ModalBasico>

            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active" aria-current="page"><Link href="/liquidaciones-dt">Anexo de Rremuneraciones</Link></li>
                </ol>
            </nav>

            <section className="anexo-de-remuneraciones">
                <h1>Anexo de Remuneraciones</h1>
                <div className="box mb-4">
                    <h2 className="h4 tit-section">Anexos</h2>
                    <h3 className="h5 my-4">Iniciar Búsqueda</h3>
                    <form onSubmit={async (e) => { handleSubmit(e); }} role="form" className="needs-validation" noValidate="noValidate">
                        <div className="row mb-2">
                            <div className="col-xl-6">
                                <div className="mb-3 row">
                                    <label htmlFor="rut" className="col-md-2 col-form-label "><strong>RUT:</strong></label>
                                    <div className="col-sm-6 mb-3">
                                        <input type="text" onChange={(e) => disabledBtn(e)} className="form-control" id="rut" required="required"></input>
                                        <div className="invalid-feedback">
                                            * Ingresar rut sin puntos y con guión.
                                        </div>
                                        <p className="mt-3">
                                            Indicar RUT sin puntos y con guión, del colaborador que desea buscar.
                                        </p>
                                    </div>
                                    <div className="col d-grid d-md-block">
                                        {/*  Agregar disabled a button para desactivar botón */}
                                        <button type="submit" id='btnBuscar' disabled className="btn btn-degradado" >Buscar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </div>
                            {/*  inicio nombre */}
                            <div className="col-xl-6">
                                <div className="mb-3 row">
                                    <label
                                        htmlFor="name"
                                        className="col-md-2 col-xl-3 col-form-label"
                                    >
                                        <strong>Nombre:</strong>
                                    </label>
                                    <div className="col-md-10 col-xl-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            defaultValue={datosTrabajador.name}
                                            disabled
                                            readOnly
                                        ></input>
                                    </div>
                                </div>
                            </div>
                            {/*  inicio modal */}
                            <a
                                hidden
                                id="openModalPermisoDenegado"
                                data-bs-toggle="modal"
                                data-bs-target="#permiso-rut"
                            ></a>
                            <div
                                className="modal fade"
                                id="permiso-rut"
                                tabIndex="-1"
                                aria-labelledby="permiso-rut"
                                aria-hidden="true"
                            >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header border-0 pb-0">
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            ></button>
                                        </div>
                                        <div className="modal-body text-center py-4">
                                            <h2 className="h5">
                                                No tiene permisos para consultar ese RUT
                                            </h2>
                                        </div>
                                        <div className="modal-footer border-0 pt-0 justify-content-center pb-5">
                                            <button
                                                type="button"
                                                className="btn btn-degradado"
                                                data-bs-dismiss="modal"
                                            >
                                                Aceptar{" "}
                                                <i
                                                    className="fa fa-chevron-right fa-fw fa-xs"
                                                    aria-hidden="true"
                                                ></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*  fin modal */}
                        </div>
                        {rut != false && rut != 0 && yearOption.length > 0 && monthOption.length > 0 ? (
                            <>
                                <hr className="mb-5"></hr>
                                <div className="row">
                                    <div className="col-12 col-xl-6">
                                        <div className="row">
                                            <div className="col-12 col-lg-6 mb-4">
                                                <select className="form-select" id="year" value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
                                                    {yearOption.map((o) => (
                                                        <option key={o.value} value={o.value}>
                                                            {o.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-12 col-lg-6 mb-4">
                                                <select className="form-select" id="month" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
                                                    {monthOption.map((o) => (
                                                        <option key={o.value} value={o.int}>
                                                            {o.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col d-grid d-md-block">
                                        {/*  Agregar disabled a button para desactivar botón */}
                                        <button
                                            type="button"
                                            disabled={bloquear}
                                            className="btn btn-degradado"
                                            onClick={(e) => {
                                                handleSubmitBuscar(e);
                                            }}>Buscar{" "}
                                            <i
                                                className="fa fa-chevron-right fa-fw fa-xs"
                                                aria-hidden="true"
                                            ></i>
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </form>
                </div>
                {rut != false && rut != 0 ? (
                    <>
                        <div className="box mb-4">
                            <h2 className="h4 tit-section">Detalle Anexo Remuneraciones</h2>
                            {validData ? (
                                <>
                                    <table className="table table-even table-section table-borderless align-middle d-none d-md-table">
                                        <thead>
                                            <tr>
                                                <th className="col-3">Concepto</th>
                                                <th className="col-7">Descripción</th>
                                                <th className="col-2 text-center">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataDt.length > 0
                                                ? Children.toArray(dataDt.map((remuneracion, i) => {
                                                    return (
                                                        <>
                                                            <tr key={remuneracion + i}>
                                                                <td>{decrypt(remuneracion.concepto)}</td>
                                                                <td>{decrypt(remuneracion.descr)}</td>

                                                                {decrypt(remuneracion.publisher) !== "0" ? (
                                                                    <td className="text-center">
                                                                        <a href="#"><i onClick={(e) => handleXls(e, decrypt(remuneracion.descr), decrypt(remuneracion.concepto))} className="fa fa-file-excel-o fa-fw me-2" aria-hidden="true"></i></a>
                                                                        <a href="#"><i onClick={(e) => handlePdf(e, decrypt(remuneracion.descr), decrypt(remuneracion.concepto))} className="fa fa-file-pdf-o fa-fw me-2" aria-hidden="true"></i></a>
                                                                    </td>
                                                                ) : (
                                                                    <td className="text-center">
                                                                        <a href="#"><i onClick={(e) => handlePdf(e, decrypt(remuneracion.descr), decrypt(remuneracion.concepto))} className="fa fa-file-pdf-o fa-fw me-2" aria-hidden="true"></i></a>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        </>
                                                    );
                                                }))
                                                : null}
                                        </tbody>
                                    </table>
                                </>
                            ) : "no hay registro para mostrar"
                            }
                            {validData ? (
                                <>
                                    {dataDt.length > 0 ?
                                        <div className="accordion accordion-flush d-block d-md-none accordion-light accordion-table">
                                            {Children.toArray(dataDt.map((anexo, i) => {
                                                return <>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="accordion-ad-headingOne">
                                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#accordion-item-" + i} aria-expanded="true" aria-controls={"accordion-item-" + i}>
                                                                <strong>Concepto</strong> {decrypt(anexo.concepto)}
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id={"accordion-item-" + i} className="accordion-collapse collapse show" aria-labelledby="accordion-ad-headingOne">
                                                        <div className="accordion-body p-0">
                                                            {/*  cuerpo item */}
                                                            <div className="data-list-box data-list-box-even">
                                                                <ul className="list-column-1 list-unstyled">
                                                                    <li>
                                                                        <span className="data-list-box__title">
                                                                            Descripción
                                                                        </span>
                                                                        <span className="data-list-box__text">
                                                                            {decrypt(anexo.descr)}
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="data-list-box__title">
                                                                            Acciones
                                                                        </span>
                                                                        <span className="data-list-box__text">
                                                                            {decrypt(anexo.publisher) !== "0" ? (
                                                                                <>
                                                                                    <a href="#"><i onClick={(e) => handleXls(e, decrypt(anexo.descr))} className="fa fa-file-excel-o fa-fw me-2" aria-hidden="true"></i></a>
                                                                                    <a href="#"><i onClick={(e) => handlePdf(e, decrypt(anexo.descr))} className="fa fa-file-pdf-o fa-fw me-2" aria-hidden="true"></i></a>
                                                                                </>
                                                                            ) : (
                                                                                <a href="#"><i onClick={(e) => handlePdf(e, decrypt(anexo.descr))} className="fa fa-file-pdf-o fa-fw me-2" aria-hidden="true"></i></a>
                                                                            )}
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            {/*  fin cuerpo item */}
                                                        </div>
                                                    </div>
                                                </>
                                            }))}
                                        </div>
                                        : null}
                                </>
                            ) : null}
                            {/*  fin acordeon */}
                        </div>
                    </>
                ) : null}
                <DTGobNorm />
            </section>

            <a
                id="abrirModalcertificados"
                data-bs-toggle="modal"
                data-bs-target="#fail-certificados"
            ></a>
            <div
                className="modal fade"
                id="fail-certificados"
                tabIndex="-1"
                aria-labelledby="sinConexionModal"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body text-center py-4">
                            <i
                                className="fa fa-exclamation-triangle color-orange fa-3x"
                                aria-hidden="true"
                            ></i>
                            <h2 className="h5">No tienes conexión </h2>
                            <p>No puedes ejecutar esta acción.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AnexoYRemuneracionDt;
