import React, { FC, useContext, createContext, Dispatch, SetStateAction } from "react";
import { NameCollaborator } from "../../warningLetter/utilities/interfaces";
import { httpClientEnv } from "../utilities/apiServices/httpClient";
import Ficha from "./Ficha";
 
export const HttpClientContext = createContext(httpClientEnv);
export const useHttpClient = () => useContext(HttpClientContext);
  
interface Props {
    setFichaSIC: Dispatch<SetStateAction<boolean>>;
    emplidColab: string;
    collaborators: NameCollaborator[];
    setCollaboratorEmplid: React.Dispatch<React.SetStateAction<string>>;
    fromEquipo: boolean;
    setFromEquipo: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FichaSIC: FC<Props> = ({setFichaSIC, emplidColab, collaborators, setCollaboratorEmplid,fromEquipo, setFromEquipo}) => {
    return (
        <Ficha setFichaSIC={setFichaSIC} emplidColab={emplidColab} collaborators={collaborators} setCollaboratorEmplid={setCollaboratorEmplid} fromEquipo={fromEquipo} setFromEquipo={setFromEquipo}/>
    )
};
