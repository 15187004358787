import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { urlsAws } from "../resources/foo/api-endpoints";
import datosLaboralesService from "../services/datos-laborales-service";
import { decrypt, dotPerc } from "../services/utiles";
import vacacionesService from "../services/vacaciones-service";
import PaginatorTable from "./elements/paginator-table";
import CarrouselVacaciones from "./elements/vacaciones-carrousel";

const Vacaciones = () => {
  const [diasDisponibles, setDiasDisponibles] = useState(null);
  const [misDias, setMisDias] = useState(0);
  const [verCartola, setVerCartola] = useState(null);
  //const [emplid, setEmplid] = useState('16545111-2');
  const [existDataModal, setExistDataModal] = useState(false);
  const textmodal = "Cargando información..";
  const [cargando, setCargando] = useState(true);
  const [tieneNet, setTieneNet] = useState(true);
  const [existDataModalComu, setExistDataModalComu] = useState(false);
  const [noExisteData, setnoExisteData] = useState(false);
  const [dataCartola, setDataCartola] = useState(null);
  const [emplid, setEmplid] = useState(localStorage.getItem("emplid"));
  const rowPeriodo = [];
  const rowDiasArrastre = [];
  const rowDiasNormales = [];
  const rowDiasProgresivos = [];
  const rowDiasTake = [];
  const rowAjusteVacaciones = [];
  const rowBalance = [];
  const [itemPaginador, setItemPaginador] = useState(null);
  const [resetOffset, setResetOffset] = useState(false);
  const [encabezados, setEncabezados] = useState(null);
  const handleClose = () => {
    // setRespuestaSolicitudValid(false);
    setExistDataModal(false);
    setTieneNet(true);
    setExistDataModalComu(false);
    setnoExisteData(false);
  };

  let navigate = useNavigate();

  const app_id = "221052ea-b3d1-4f8d-bf37-e37647414596";

  // //PAGINADOR
  const [totalValores, setTotalValores] = useState(0);
  const [valorInicial, setValorInicial] = useState(1);
  const valoresPagina = useState(4);

  //FIN PAGINADOR
  function formato(texto) {
    return texto.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1");
  }

  useEffect(() => {
    if (verCartola) {
      if (verCartola === 2) {
        setExistDataModalComu(true);
        setCargando(false);
      } else if (verCartola === 3) {
        setnoExisteData(true);
        setCargando(false);
      } else {
        setCargando(false);

        verCartola.detalle_vacacion.map((e) => {
          rowPeriodo.push(formato(decrypt(e.periodo)));
          rowDiasArrastre.push(dotPerc(decrypt(e.dias_arrastre)));
          rowDiasNormales.push(dotPerc(decrypt(e.dias_normales)));
          rowDiasProgresivos.push(dotPerc(decrypt(e.dias_progresivos)));
          rowDiasTake.push(dotPerc(decrypt(e.dys_take)));
          rowAjusteVacaciones.push(dotPerc(decrypt(e.ajuste_vac)));
          rowBalance.push(decrypt(e.balance));
        });

        if (
          rowPeriodo.length > 0 ||
          rowDiasArrastre.length > 0 ||
          rowDiasNormales.length > 0 ||
          rowDiasProgresivos.length > 0 ||
          rowDiasTake.length > 0 ||
          rowAjusteVacaciones.length > 0 ||
          rowBalance.length > 0
        ) {
          setEncabezados(rowPeriodo);
          setItemPaginador([
            {
              title: "Período",
              rows: rowPeriodo,
            },
            {
              title: "Días arrastre período anterior",
              rows: rowDiasArrastre,
            },
            {
              title: "Días devengados normales",
              rows: rowDiasNormales,
            },
            {
              title: "Días devengados progresivos",
              rows: rowDiasProgresivos,
            },
            {
              title: "Días tomados",
              rows: rowDiasTake,
            },
            {
              title: "Ajuste",
              rows: rowAjusteVacaciones,
            },
            {
              title: "Saldo",
              rows: rowBalance,
            },
          ]);
        }

        if (valoresPagina) {
          if (verCartola.detalle_vacacion.length > 1) {
            setTotalValores(
              Math.round(verCartola.detalle_vacacion.length / valoresPagina)
            );
          } else {
            setTotalValores(
              Math.round([verCartola.detalle_vacacion].length / valoresPagina)
            );
          }
        }
        var final = valorInicial * valoresPagina;
        var inicio = final - valoresPagina;
        var listadoMostrar;
        if (verCartola.detalle_vacacion.length > 1) {
          listadoMostrar = verCartola.detalle_vacacion.slice(inicio, final);
        } else {
          listadoMostrar = [verCartola.detalle_vacacion].slice(inicio, final);
        }

        setDataCartola(listadoMostrar);

        //console.log(listadoMostrar)
      }
    }
  }, [verCartola, valorInicial]);

  useEffect(() => {
    if (itemPaginador) {
      undefined;
    }
  }, [itemPaginador]);
  useEffect(() => {
    if (emplid !== null) {
      vacacionesService.getDetalleDiasDisponibles(setDiasDisponibles, emplid);
      vacacionesService.getCartola(setVerCartola, emplid);
    }
  }, [emplid]);

  useEffect(() => {
    if (diasDisponibles) {
      if (decrypt(diasDisponibles.balance) !== undefined) {
        setMisDias(decrypt(diasDisponibles.balance));
      }
    }
  }, [diasDisponibles]);

  useEffect(() => {
    //console.log('mis dias: ',misDias)
  }, [misDias]);

  return (
    <>
      {/*<!-- inicio breadcrumbs -->*/}
      <nav className="breadcrumbs" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/inicio">
              <i className="fa fa-home" aria-hidden="true"></i> Inicio
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/vacacion">Vacaciones</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Ver Cartola
          </li>
        </ol>
      </nav>
      {/*<!-- fin breadcrumbs -->*/}

      {/*<!-- inicio Vacaciones -->*/}
      <section className="vacaciones">
        <div className="tit-group d-block d-xxl-flex justify-content-between">
          <h1>Vacaciones</h1>
          <div className="saldo-vacaciones rounded-3 bg-light p-3 color-primary mb-4">
            <div className="row align-items-center">
              <label
                htmlFor="inputPassword"
                className="col-6 col-sm-4 col-md-3 col-form-label"
              >
                Saldo Vacaciones:
              </label>
              <div
                className="col-3 col-md-2 col-xl-1"
                style={{ width: "100px" }}
              >
                <input
                  type="text"
                  className="form-control text-center bg-white"
                  value={misDias}
                  disabled
                />
              </div>
              <small className="col-12 col-md-6 col-lg-7 col-xl-15 mt-4 mt-md-0">
                * Su saldo de vacaciones actual incluye los días futuros
                aprobados
              </small>
            </div>
          </div>
        </div>

        <CarrouselVacaciones tituloSelect="Ver cartola"></CarrouselVacaciones>

        {/*<!-- Cartola de Vacaciones -->*/}
        <div className="box mb-4">
          <h2 className="h4 tit-section">Cartola de Vacaciones</h2>
          {/*<!-- Escritorio (Tabla) -->*/}
          <div className="table-responsive">
            {itemPaginador && encabezados ? (
              <PaginatorTable
                itemsPerPage={5}
                items={itemPaginador}
                header={encabezados}
                constHeader={"Año"}
                setResetOffset={setResetOffset}
                resetOffset={resetOffset}
              ></PaginatorTable>
            ) : null}
          </div>
        </div>
      </section>
      {existDataModal ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => handleClose(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-download color-orange fa-3x mb-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">Ver cartola</h2>
                    <p>No se ha encontrado información.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {existDataModalComu ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => handleClose(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-download color-orange fa-3x mb-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">Ver cartola</h2>
                    <p>
                      Se ha producido un error de comunicación, vuelva a
                      intentarlo más tarde.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {noExisteData ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => handleClose(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-download color-orange fa-3x mb-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">Ver cartola</h2>
                    <p>No se ha encontrado información.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {cargando ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0"></div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-download color-orange fa-3x mb-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">{textmodal}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {!tieneNet ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => handleClose(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-exclamation-triangle color-orange fa-3x"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">No tienes conexión</h2>
                    <p>No puedes ejecutar esta acción si no tienes conexión</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Vacaciones;
