import { useAxiosFetch } from "../../../hooks";
import type {
  Flag,
  CompensationsApi,
  CompensationsResponse,
  UseFetchTableDataReturnType,
  UseCompensationsFetchProps,
  CompensationsMapper,
} from "./hooksTeamDashboard.types";
import defaultProfilImage from "../../../resources/images/default-colaborador-image.png";
import { countryDictionary } from "../../../utils";
import { useEffect } from "react";
import { useTeamDashboardProvider } from "../context/TeamDashboardContext";
import { subMenuListData } from "../context/carouselList.data";

export const useCompensationsFetch = (
  props: UseCompensationsFetchProps
): UseFetchTableDataReturnType<CompensationsResponse> => {
  const { idSubLeader, selectedYear } = props;
  const { getSelectedMenu } = useTeamDashboardProvider();
  const [getCompensations, dataCompensations] = useAxiosFetch<
    CompensationsApi[]
  >({
    method: "GET",
    url: "/api/my-teams/compensations",
    params: {
      year: selectedYear,
    },
  });

  const [getSubCompensations, dataSubCompensations] = useAxiosFetch<CompensationsApi[]>({
    method: "GET",
    url: "/api/my-teams/compensations/subteam",
    params: {

      subleader: idSubLeader,
      year: selectedYear,
    },
  });

  useEffect(() => {
    if (getSelectedMenu?.name === subMenuListData[3].name) {
      getCompensations();
    }
  }, [getSelectedMenu]);


  useEffect(() => {
    idSubLeader && getSubCompensations();
  }, [idSubLeader]);


  const compensationsMapper = (
    response: typeof dataCompensations | typeof dataSubCompensations
  ): CompensationsMapper[] => {
    const { data } = response;
    return (
      data?.map((employee) => ({
        nro_document: employee.nro_document || "",
        photo: employee.photo || defaultProfilImage,
        name: employee.name || "",
        last_name: employee.last_name || "",
        country: countryDictionary(employee.country) || "-",
        salary: employee.sueldo_base || "-",
        position: employee.posicionamiento || "-",
        cr: employee.cr || "-",
        monthly_rent: employee.renta_bruta_mensual || "-",
        current_date: employee.fecha_posicion_actual || "-",
        hasSubordinates: employee.hasSubordinates || false,
      })) || []
    );
  };

  const removeDuplicateFlag = (flags: Flag[]) => {
    return flags.reduce((acc, flag) => {
      if (!acc.find((a) => a.id === flag.id)) {
        acc.push(flag);
      }

      return acc;
    }, [] as Flag[]);
  };

  const getAllFlags = () => {
    const mapFlag = (team: CompensationsApi) => ({
      id: countryDictionary(team.country),
      name: team.country,
    });

    if (idSubLeader) {
      return dataSubCompensations.data?.map(mapFlag);
    }

    return dataCompensations.data?.map(mapFlag);
  };

  return {
    key: subMenuListData[3].name,
    response: {
      dataTable: compensationsMapper(dataCompensations),
      subDatable: compensationsMapper(dataSubCompensations),
      isLoading: dataCompensations.isLoading || dataSubCompensations.isLoading,
      isError: dataCompensations.isError || dataSubCompensations.isError,
      getAllFlags: removeDuplicateFlag(getAllFlags() || []),
    },
  };
};