import React, { FC } from "react";
import { modalPreview, typeLinks } from "../../../../../utilities/interfaces";
import Slider from "../../../../../../commonComponents/Slider";

const transformDataForSlider = (data: typeLinks[]) => {
    return data.map((video, i) => (
        <div key={i} className="col-12 col-md-4">
            <div className="ratio ratio-16x9 mb-4">
                <iframe className="embed-responsive-item" src={video.url}></iframe>
            </div>
        </div>
    ));
};

const ModalPreview: FC<modalPreview> = ({watchedValues, imgCard, arrayLinks}) => {
    const renderHTML = (rawHTML: any) => (
        <div
          className="render-html-content"
          dangerouslySetInnerHTML={{ __html: rawHTML }}
        />
      );

    let filterLinks = arrayLinks.filter((link) => link.url && link.url.includes("https://"));

    return(
        <>
            <div className="modal fade" id="preview" aria-labelledby="preview" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered custom-modal-width">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h2 className="h5">Vista previa</h2>
                            <div className="card card-box-mobile card-image-end p-0 mb-3">
                                <div className="row align-items-center g-0">
                                    <div className="col-md-6">
                                        <div className="card-body p-5">
                                            <div className="color-gradient-1 fw-bold fs-1 mb-4">
                                                <div>{watchedValues?.title}</div>
                                            </div>
                                            <p className="fs-4 fw-bold m-0">{watchedValues?.subtitle?.substring(0,25)+'...'}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <img className="img-fluid img-banner-detail" src={imgCard ? imgCard : require('../../../../../../../resources/images/default_img.png')} width="792" alt=""/>
                                    </div>
                                </div>
                            </div>
                        <div className="mb-4">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <p className="fs-5 color-primary"></p>
                                    {watchedValues?.file && watchedValues?.file.length > 0 && <p className="fs-5 color-primary"><strong>Descargar:</strong> <a className="text-decoration-none fw-bold btnLink">Archivo adjunto <i className="fa fa-download fa-fw color-primary" aria-hidden="true"></i></a></p>}
                                </div>
                                <div className="col-12 col-md-6 text-md-end">
                                    {watchedValues?.is_request && <div className="d-grid d-md-flex justify-content-md-end gap-2 flex-nowrap">
                                        <a className="btn btn-degradado" href="">Solicitar<i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></a>
                                    </div>}
                                    <div className="mt-3 text-center text-md-end btn-benefit">
                                        <button className="btn color-primary-strong"><i id="rojo" className="fa fa-heart fa-fw fa-lg" aria-hidden="true"></i> 0 Me gusta</button>
                                        <button className="btn color-primary-strong"><i className="fa fa-paper-plane-o fa-fw color-primary" aria-hidden="true"></i> Compartir</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box mb-4">
                            <h2 className="h4 tit-section">Descripción</h2>
                            <div className="condition-content">{renderHTML(watchedValues?.description)}</div>
                        </div>
                        {watchedValues?.condition && 
                            <div className="box mb-4">
                                <h2 className="h4 tit-section">Condiciones</h2>
                                <div className="condition-content">{renderHTML(watchedValues?.condition)}</div>
                            </div>
                        }
                        {filterLinks.length > 0 && 
                            <div className="box mb-5">
                                <h2 className="h4 tit-section">Videos</h2>
                                <Slider 
                                    id="videos-beneficios" 
                                    data={transformDataForSlider(filterLinks)}
                                />
                            </div>
                        }
                        </div>
                        <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                            <button type="submit" className="btn btn-degradado col-12 col-md-auto my-auto px-5" data-bs-dismiss="modal">Cerrar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModalPreview;