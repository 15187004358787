import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { HomeContent } from '../../../../utilities/constantsTexts'
import CollapseList from '../../../../commons/collapseList/collapseList'
import Highlighter from '../../../../commons/highlighter'
import { APIArticles, Article } from '../../../../utilities/interfaces'
import { HttpClient } from '../../../../utilities/apiServices/httpClient'
import { ApiRequest } from '../../../../utilities/apiServices/apiRequest'
import { errorsMessages } from '../../../../utilities/apiServices/apiErrors'
import textstyle from '../../../../commons/styles/textStyles.module.css'
import Loading from '../../../../commons/dataStatus/loading/loading'
import { UpdateLocalStorage } from '../../../../utilities/helpers/updateStorage'

const httpClient = new HttpClient()
const apiService = new ApiRequest(httpClient)

const TopArticles = () => {
  const [articlesToShow, setArticles] = useState<Article[]>([])
  const [statusMessage, setStatusMessage] = useState('Cargando información...')
  const [isLoading, setIsLoading] = useState(false)
  const [validData, setValidData] = useState(false)
  const maxNumberOfArticles = 10
  const update = new UpdateLocalStorage()

  useEffect(() =>{
    setIsLoading(true)
    fetchArticlesList()
      .then((articles: APIArticles) => {
        if(!isValidData(articles)) return
        receiveArticles(articles)
      })
      .catch((err) => {
        emitError(err)
      }).finally(() => {
        setIsLoading(false)
      })
    }, []);

    const emitError = (err: number) => {
      const newError = {
        errorStatus: err,
        errorFire: 'getArticles',
        errorComponent: 'TopArticles'
      }
      update.updateSlot(update.keyConstants[7], JSON.stringify(newError))
    }

    const receiveArticles = (articles: APIArticles) => {
      const isValidData = articles.data && articles.data.length !== 0
      if(isValidData) {
        if(articles.data.length > 10) {
          setArticles(articles.data.splice(0, 10))
        } else {
          setArticles(articles.data)
        }
      }
    }

  const isValidData = (articles: APIArticles): boolean => {
    if (!articles.data || articles.data.length === 0) {
      setStatusMessage('No hay información disponible')
      emitError(500)
      return false
    } else {
      setValidData(true)
      return true
    }
  }

  const fetchArticlesList = async (): Promise<APIArticles> => {
    const data = await apiService.getArticles()
    return data
  }

  return (
    <Grid item xs={12} className={textstyle.top_articles_wrapper}>
        <h2 className={textstyle.page_title_2}>{HomeContent.sectionTitle}</h2>
        <Highlighter width={35}/>
        {isLoading && <Loading normalMessage={true}/>}
        {validData && !isLoading &&
          <CollapseList
            articlelist={articlesToShow.slice(0, maxNumberOfArticles)}
            maxItems={maxNumberOfArticles}
            isTopArticles={true}
          />
        }
        {!validData && !isLoading &&  <p>{statusMessage}</p> }
    </Grid>
  );
}

export default TopArticles;
