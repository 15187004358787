import React, { useEffect, useState, FC } from 'react'
import { useForm } from 'react-hook-form'
import { validaRut } from "../../../../services/utiles";
import service from '../../services';
import { IModalDetalle, IFormData } from '../../interfaces';


const ModalDetalle:FC<IModalDetalle> = ({ conflictsOptions, modalValues, setModalValues, conflicts, btnDisabled, setBtnDisabled, refLoadOpen, refLoadClose }) => {
    
    const [displayFields, setDisplayFields] = useState({
        nombre: true,
        rut: true,
        relacion: true,
        comentario: true,
    });

    const met = modalValues.metodo
    const index = modalValues.index
    const modv = met !== "add" && modalValues ? modalValues.data : {
        nombre: "",
        rut: "",
        relacion: "",
        tipoconflicto: "",
        comentario: "",
    };
    
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        getValues,
        setError,
        watch
    } = useForm({
        mode: "onSubmit",
        defaultValues: { ...modv },
    })

    useEffect(() => {

        if (modalValues.metodo === "upd" && modalValues.data.tipoconflicto === "006") {
            setDisplayFields({ 
                nombre: false, 
                rut: false, 
                relacion: false, 
                comentario: true 
            });
        }
        if (modalValues.metodo !== "") {
            setValue("nombre", modalValues.data.nombre)
            setValue("rut", modalValues.data.rut)
            setValue("relacion", modalValues.data.relacion)
            setValue("tipoconflicto", modalValues.data.tipoconflicto)
            setValue("comentario", modalValues.data.comentario)
        }
        else {
            setValue("nombre", "")
            setValue("rut", "")
            setValue("relacion", "")
            setValue("tipoconflicto", "")
            setValue("comentario", "")
        }
    }, [modalValues])

    const onSubmit = async (data:IFormData) => {
        if(validaRut(data.rut) === false && data.tipoconflicto !== "006") {
            setError("rut", {
                type: "manual",
                message: "El rut ingresado no es válido"
            });
            return;
        }
        setBtnDisabled(true);
        document.getElementById("btn-close")?.click();
        refLoadOpen.current?.click();
        let request : any = {};
        if (met === 'del') {
            request["existsConflict"] = true
            request["conflictId"] = conflicts[index]["conflictId"]
            request["action"] = "delete"
            request["conflictedRut"] = data["rut"]
            request["conflictTypeCd"] = data["tipoconflicto"]
        }
        if (met === 'add') {
            request["existsConflict"] = true
            request["action"] = "create"
            request["conflictTypeCd"] = data["tipoconflicto"]
            request["relationshipCd"] = data["relacion"]
            request["conflictedRut"] = data["rut"]
            request["conflictedName"] = data["nombre"]
            request["observation"] = data["comentario"]
        }
        if(met === 'upd') {
            request["existsConflict"] = true,
            request["conflictId"] = conflicts[index]["conflictId"]
            request["action"] = "update"
            request["conflictTypeCd"] = data["tipoconflicto"]
            request["relationshipCd"] = data["relacion"]
            request["conflictedRut"] = data["rut"]
            request["conflictedName"] = data["nombre"]
            request["observation"] = data["comentario"]
        }
        await service.setConflicto(request);
        refLoadClose.current?.click();
        document.getElementById("modal-anexo")?.click();
    }
    const isNotEmpty = (fieldValue:string) => fieldValue !== "" && fieldValue !== undefined;
    

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const tipo = e.target.value;
        const tipoconflicto = getValues("tipoconflicto");
        const comentario = getValues("comentario");
        const nombre = getValues("nombre");
        const rut = getValues("rut");
        const relacion = getValues("relacion");
        
        if (e.target.name === "tipoconflicto"){
            if (tipo === "005") {
                setDisplayFields({ 
                    nombre: false, 
                    rut: false, 
                    relacion: false, 
                    comentario: false 
                });
            } else if (tipo === "006") {
                setValue("nombre", "")
                setValue("rut", "")
                setValue("relacion", "")
                setValue("tipoconflicto", "006")
                setDisplayFields({ 
                    nombre: false, 
                    rut: false, 
                    relacion: false, 
                    comentario: true 
                });
            } else {
                setDisplayFields({ 
                    nombre: true, 
                    rut: true, 
                    relacion: true, 
                    comentario: true 
                });
            }
        }
        if (tipoconflicto === "005" ||
            (tipoconflicto === "006" && isNotEmpty(comentario)) ||
            (isNotEmpty(nombre) && isNotEmpty(rut) && isNotEmpty(relacion) && 
            isNotEmpty(tipoconflicto) && isNotEmpty(comentario))) {
            setBtnDisabled(false);
            return; 
        }
        else {
            setBtnDisabled(true);
        }
    }


    const close = () => {
        setTimeout(() => {
            setModalValues({
                metodo: "",
                index: 0,
                data: {
                    nombre: "",
                    rut: "",
                    relacion: "",
                    tipoconflicto: "",
                    comentario: "",
                }
            });
        }, 200);
        setBtnDisabled(true);
        setDisplayFields({ 
            nombre: true, 
            rut: true, 
            relacion: true, 
            comentario: true 
        });
        reset();
    }

    return (
        <div className="modal fade" id="detalle" tabIndex={-1} aria-labelledby="detalle" aria-modal="true" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <form className="needs-validation" noValidate={true} onSubmit={handleSubmit(onSubmit)}>
                        <div className="modal-header border-0 pb-0">
                            <button id="btn-close" type="button" className="btn-close" data-bs-dismiss="modal"
                                onClick={() => {
                                    close()
                                }} aria-label="Close"></button>
                        </div>

                        {met === "del" &&
                            <div className="modal-body">
                                <div className="text-center">
                                    <i className="fa fa-times-circle-o color-orange fa-3x mb-2" aria-hidden="true"></i>
                                    <p className="h5">
                                        <strong>¿Desea borrar este registro?</strong>
                                    </p>
                                </div>
                            </div>}
                        <div className="modal-body" style={{ display: met === "del" ? "none" : "block" }}>
                            <h2 className="h5 text-uppercase mb-4">Conflicto de interés</h2>

                            <div className="mb-3" id="tipoconflictofield">
                                <label className="form-label mb-2"><strong>* Tipo de conflicto</strong></label>
                                <select hidden={met === "del"} className="form-select" 
                                    defaultValue={modv.tipoconflicto}
                                    disabled={met === "upd"}
                                    {...register("tipoconflicto", {
                                        required: (met !== "del"),
                                        onChange: (e) => onChange(e),
                                    })}>
                                    <option value="">Seleccione una opción</option>
                                    {conflictsOptions && conflictsOptions.conflictos.map(
                                        (item) => <option value={item.valor}>{item.descr}</option>
                                    )}
                                </select>
                                {errors["tipoconflicto"] !== undefined && <div style={{ display: "block" }} className="invalid-feedback">
                                    * Debe seleccionar un campo
                                </div>}

                            </div>

                            <div className="mb-3" id="relacionfield" style={{ display: !displayFields.relacion ? 'none' : 'block' }}>
                                <label className="form-label mb-2"><strong>* Relación</strong></label>
                                <select hidden={met === "del"} className="form-select" defaultValue={modv.relacion} {
                                    ...register("relacion", {
                                        required: watch("tipoconflicto") === "005" || watch("tipoconflicto") === "006" ? false : (met !== "del"),
                                        maxLength: 100,
                                        minLength: 1,
                                        onChange: (e) => onChange(e),
                                    })}>
                                    <option value="">Seleccione una opción</option>
                                    {conflictsOptions && conflictsOptions.relaciones.map(
                                        (item) => {
                                            return (
                                                <option value={item.valor}>{item.descr.toUpperCase()}</option>
                                            )
                                        })}
                                </select>
                                {errors["relacion"] !== undefined && <div style={{ display: "block" }} className="invalid-feedback" >
                                    * Debe seleccionar un campo
                                </div>}

                            </div>

                            <div className="mb-3" id="rutfield" style={{ display: !displayFields.rut ? 'none' : 'block' }}>
                                <label className="form-label mb-2"><strong>* Rut</strong></label>
                                <input type="text" 
                                    className="form-control" 
                                    placeholder="11111111-1" 
                                    id="validar-nombre"
                                    maxLength={10}
                                    minLength={9}
                                    defaultValue={modv.rut}
                                    readOnly={met === "upd"}
                                    {...register("rut", {
                                        required: watch("tipoconflicto") === "005" || watch("tipoconflicto") === "006" ? false : (met === "del") ? false : true,
                                        onChange: (e) => onChange(e),
                                    })}
                                />
                                {errors["rut"] !== undefined && <div style={{ display: "block" }} className="invalid-feedback">
                                    {errors.rut.message}
                                </div>
                                }

                            </div>

                            <div className="mb-3" id="nombrefield" style={{ display: !displayFields.nombre ? 'none' : 'block' }}>
                                <label className="form-label mb-2"><strong>* Nombre</strong></label>
                                <input hidden={met === "del"} type="text" className="form-control" placeholder="Ingresar nombre" id="validar-nombre" defaultValue={modv.nombre}
                                    {...register("nombre", {
                                        required: watch("tipoconflicto") === "005" || watch("tipoconflicto") === "006" ? false : (met !== "del"),
                                        maxLength: 100,
                                        pattern: {
                                            value: /^[a-zA-Z\s]*$/,
                                            message: "El formato del nombre es incorrecto"
                                        },
                                        onChange: (e) => onChange(e),
                                    })} />
                                {errors["nombre"] !== undefined && <div style={{ display: "block" }} className="invalid-feedback">* Debe ingresar un nombre</div>}
                            </div>

                            <div className="mb-3" id="comentariofield">
                                <label className="form-label"><strong>* Observaciones</strong></label>
                                <textarea hidden={met === "del"} className="form-control" placeholder="Comentario..." defaultValue={modv.comentario}
                                    {...register("comentario", {
                                        required: watch("tipoconflicto") === "005" ? false : (met !== "del"),
                                        maxLength: {
                                            value: 2000,
                                            message: "El comentario no puede superar los 2000 caracteres"
                                        },
                                        onChange: (e) => onChange(e),
                                    })}></textarea>
                                {errors["comentario"] !== undefined && <div style={{ display: "block" }} className="invalid-feedback">{errors.comentario.message}</div>}

                            </div>
                            <small>(*) Campo obligatorio</small>
                        </div>
                        <div className="modal-footer border-0 pt-0">
                            <button type="button" className="btn btn-primary col-12 col-md-auto" data-bs-dismiss="modal" onClick={() => { close() }}>Cancelar</button>
                            <button type="submit" disabled={met === "del" ? false : btnDisabled} className="btn btn-degradado col-12 col-md-auto">{met === "del" ? "Borrar" : "Guardar"} <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ModalDetalle