import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { toBase64 } from '../../services/utiles';
import OtrosEstudiosServices from "../../services/otros estudios/otros-estudios-service";
import styles from "../../resources/css/style-COL.module.css";

function AgregarOtrosEstudios({ subjectAreas, countries, reloadAcademicStudies, changeDisplay }) {
    const [states, setStates] = useState([])
    const [loadingStates, setLoadingStates] = useState(false)
    const [counties, setCounties] = useState([])
    const [loadingCounties, setLoadingCounties] = useState(false)
    const [submit, setSubmit] = useState(false)
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue
    } = useForm();
    const [addStudyInfo, setAddStudyInfo] = useState(false)
    const [loadingAddStudy, setLoadingAddStudy] = useState(false)
    const [noDataAdd, setNoDataAdd] = useState(false)
    const [errorMessageAdd, setErrorMessageAdd] = useState("")
    const [errorProgramName, setErrorProgramName] = useState(null)
    const [errorInstitution, setErrorInstitution] = useState(null)
    const [errorCity, setErrorCity] = useState(null)
    const [errorFechas, setErrorFechas] = useState(null)
    const [errorAttachment, setErrorAttachment] = useState(null)

    const onSubmit = async (data) => {
        setErrorProgramName(null)
        setErrorInstitution(null)
        setErrorCity(null)
        setErrorFechas(null)
        setErrorAttachment(null)
        if(data.attachment && data.attachment[0].name.length > 30){
            setErrorAttachment("El nombre del archivo supera el máximo de caracteres.")
            return
        }
        if(data.program_name.length > 50){
            setErrorProgramName("*Supera el máximo de caracteres");
            return;
        }
        if(data.institution.length > 50){
            setErrorInstitution("*Supera el máximo de caracteres");
            return;
        }
        if(data.city.length > 30){
            setErrorCity("*Supera el máximo de caracteres");
            return;
        }
        if(data.study_status == 'T' && data.study_end_date != undefined){
            if (data.study_start_date > data.study_end_date) {
                setErrorFechas("*La fecha final no puede ser menor a la fecha inicial.")
                return;
                }
        }else{
            if(data.study_end_date){
                if (data.study_start_date > data.study_end_date) {
                setErrorFechas("*La fecha final no puede ser menor a la fecha inicial.")
                return;
                }
        }}
        const submit = {
            city: data.country === "COL" ? "" : data.city,
            country: data.country,
            county: data.country === "COL" ? data.county : "",
            institution: data.institution,
            state: data.country === "COL" ? data.state : "",
            study_start_date: data.study_start_date,
            study_end_date: data.study_end_date ? data.study_end_date : "",
            study_status: data.study_status,
            attachment: data.attachment ? await toBase64(data.attachment[0]) : "",
            attachment_name: data.attachment ? data.attachment[0].name : "",
            subject_area: data.subject_area,
            program_name: data.program_name
        }
        setLoadingAddStudy(true)
        OtrosEstudiosServices.AddOtherStudy(submit, setAddStudyInfo, setLoadingAddStudy, setNoDataAdd, setErrorMessageAdd)
    };

    useEffect(() => {
        if (watch("country") === "COL") {
            setLoadingStates(true)
            OtrosEstudiosServices.GetStates(watch("country"), setStates, setLoadingStates)
        }
    }, [watch("country")])

    useEffect(() => {
        if (watch("country") === "COL") {
            setLoadingCounties(true)
            OtrosEstudiosServices.GetCounties(watch("country"), watch("state"), setCounties, setLoadingCounties)
        }
    }, [watch("state")])

    // Mensaje de error al enviar un post
    const handleStatusError = (errorMessage) => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
            html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. ${errorMessage}</div>`,
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadAcademicStudies()

        })
        setLoadingAddStudy(false)
        setNoDataAdd(false)
    }

    // Mensaje de éxito al enviar un post
    const handleStatusSuccess = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
            html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadAcademicStudies()

        })
        setLoadingAddStudy(false)
        setNoDataAdd(false)
    }

    useEffect(() => {
        // noDataAdd ? handleStatusError() : null
        addStudyInfo ? handleStatusSuccess() : null
    }, [loadingAddStudy])

    useEffect(() => {
        setErrorAttachment(null)
    },[watch("attachment")])
    return (
        <>
            {/* <!-- Inicio Agregar Estudios --> */}
            <div className={`${styles["box"]} ${styles["mb-4"]}`}>
                <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Agregar Estudios</h2>
                <form>
                    {/* <!-- Fila 1 --> */}
                    <div className={styles["row"]}>
                        <div  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Nombre del Programa <span className={styles["color-red"]}>*</span></label>
                            <input type="text" className={styles["form-control"]} maxLength={50} {...register("program_name", { required: "* Campo obligatorio" })} />
                            <small className="form-error-message">{errors.program_name?.message}</small>
                            <small className="form-error-message">{errorProgramName}</small>
                        </div>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Institución <span className={styles["color-red"]}>*</span></label>
                            <input type="text" className={styles["form-control"]} maxLength={50} {...register("institution", { required: "* Campo obligatorio" })} />
                            <small className="form-error-message">{errors.institution?.message}</small>
                            <small className="form-error-message">{errorInstitution}</small>
                        </div>
                    </div>
                    {/* <!-- Fila 2 --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Área Temática <span className={styles["color-red"]}>*</span></label>
                            <div className={styles["input-group"]}>
                                <select className={styles["select-col"]} {...register("subject_area", { required: "* Campo obligatorio" })} >
                                    <option value="" selected>Seleccione</option>
                                    {subjectAreas.map(s => {
                                        return (
                                            <option key={s.id} value={s.id}>{s.description}</option>
                                        )
                                    })}
                                </select>
                                <small className={styles["form-error-message"]}>{errors.subject_area?.message}</small>
                            </div>
                        </div>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Adjuntar documentación (Archivos menores a 5Mb)</label>
                            <input
                                className={styles["form-control"]}
                                type="file"
                                id="file"
                                onChange={e => {
                                    if (
                                        e.target.files[0]?.type === "image/jpeg" ||
                                        e.target.files[0]?.type === "image/jpg" ||
                                        e.target.files[0]?.type === "application/pdf" ||
                                        e.target.files[0]?.type === "image/png"
                                    ) {
                                        if (e.target.files[0].size <= 5000000) {
                                            setValue("attachment", e.target?.files, { shouldValidate: true, shouldDirty: true })
                                        } else {
                                            Swal.fire({
                                                allowOutsideClick: false,
                                                position: 'center',
                                                title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                                                html: '<div style="text-align: center; font-size: 15px">El tamaño del Certificado debe ser menor a 5Mb</div>',
                                                confirmButtonColor: '#11427C',
                                            }
                                            )
                                        }
                                    } else {
                                        Swal.fire({
                                            allowOutsideClick: false,
                                            position: 'center',
                                            title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                                            html: '<div style="text-align: center; font-size: 15px">Debe ingresar uno de los tipos de archivos permitidos</div>',
                                            confirmButtonColor: '#11427C',
                                        })
                                    }
                                }}
                                formcontrolname="file"
                                name="file"
                                accept=".pdf, .jpeg, .jpg, .png"
                            />
                        <small className={styles["form-error-message"]}>{errorAttachment}</small>
                        </div>
                    </div>
                    {/* <!-- Fila 3 --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>País <span className={styles["color-red"]}>*</span></label>
                            <div className={styles["input-group"]}>
                                <select className={styles["select-col"]} {...register("country", { required: "* Campo obligatorio" })}>
                                    <option value="" selected>Seleccione</option>
                                    {
                                        countries.map((c) => {
                                            return (
                                                <option key={c.country} value={c.country}>{c.descr}</option>
                                            )
                                        })
                                    }
                                </select>
                                <small className={styles["form-error-message"]}>{errors.country?.message}</small>
                            </div>
                        </div>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]} ${watch("country") === "COL" ? styles["d-none"] : null}`}>
                            <label className={styles["form-label"]}>Ciudad <span className={styles["color-red"]}>*</span></label>
                            <input type="text" className={styles["form-control"]} maxLength={30} {...register("city", { required: watch("country") !== "COL" ? "* Campo obligatorio" : false, maxLength: 30 })} />
                            <small className="form-error-message">{errors.city?.message}</small>
                            <small className="form-error-message">{errorCity}</small>
                        </div>
                    </div>
                    {/* <!-- Fila 4 --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]} ${watch("country") !== "COL" ? styles["d-none"] : null}`}>
                            <label className={styles["form-label"]}>Departamento <span className={styles["color-red"]}>*</span></label>
                            <div className={styles["input-group"]}>
                                {!loadingStates ? <select className={styles["select-col"]} {...register("state", { required: watch("country") === "COL" ? "* Campo obligatorio" : false, maxLength: 3 })}>
                                    <option value="" selected>Seleccione</option>
                                    {
                                        states.map(s => {
                                            return (
                                                <option key={s.state} value={s.state}>{s.descrshort}</option>
                                            )
                                        })
                                    }
                                </select> : <div><div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                                <small className={styles["form-error-message"]}>{errors.state?.message}</small>
                            </div>
                        </div>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]} ${watch("country") !== "COL" ? styles["d-none"] : null}`}>
                            <label className={styles["form-label"]}>Ciudad <span className={styles["color-red"]}>*</span></label>
                            <div className={styles["input-group"]}>
                                {!loadingCounties ? <select className={styles["select-col"]} {...register("county", { required: watch("country") === "COL" ? "* Campo obligatorio" : false, maxLength: 3 })}>
                                    <option value="" selected>Seleccione</option>
                                    {
                                        counties.map((c) => {
                                            return (
                                                <option key={c.county} value={c.county}>{c.descr}</option>
                                            )
                                        })
                                    }
                                </select> : <div><div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                                <small className={styles["form-error-message"]}>{errors.county?.message}</small>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Fila 5 --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Estado <span className={styles["color-red"]}>*</span></label>
                            <div className={styles["row"]}>
                                <div className={`${styles["col-12"]} ${styles["col-md-4"]}`}>
                                    <div className={styles["form-check"]}>
                                        <input className={styles["form-check-input"]} type="radio" name="estado" id="estado1" value="T"
                                            {...register("study_status", { required: "* Campo obligatorio", maxLength: 1 })}  />
                                        <label className={styles["form-check-label"]} htmlFor="estado1">Titulado</label>
                                    </div>
                                </div>
                                <div className={`${styles["col-12"]} ${styles["col-md-4"]}`}>
                                    <div className={styles["form-check"]}>
                                        <input className={styles["form-check-input"]} type="radio" name="estado" id="estado2" value="P"
                                            {...register("study_status", { required: "* Campo obligatorio", maxLength: 1 })} defaultChecked />
                                        <label className={styles["form-check-label"]} htmlFor="estado2">En curso</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Fila 6 --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Fecha de Inicio <span className={styles["color-red"]}>*</span></label>
                            <input type="date" className={styles["form-control"]} {...register("study_start_date", { required: "* Campo obligatorio", maxLength: 50 })} />
                            <small className="form-error-message">{errors.study_start_date?.message}</small>
                        </div>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Fecha de Finalización {watch("study_status") === 'T' ? <span className={styles["color-red"]}>*</span> : null}</label>
                            <input type="date" className={styles["form-control"]} {...register("study_end_date", { required: watch("study_status") === 'T' ? "* Campo obligatorio" : false, maxLength: 50 })} />
                            <small className="form-error-message">{errors.study_end_date?.message}</small>{" "}
                            <small className="form-error-message">{errorFechas}</small>
                        </div>
                    </div>
                    {/* <!-- fin filas --> */}
                </form>

                <div className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mt-4"]}`}>
                    <div className={`${styles["col-6"]} ${styles["col-md-auto"]}`}>
                        <button type="button" className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`} onClick={() => changeDisplay(false, null, null)}>Cancelar <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i></button>
                    </div>
                </div>

            </div>
            {/* <!-- Fin Agregar Estudios --> */}

            <div className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mt-4"]}`}>
                <div className={`${styles["col-12"]} ${styles["col-md-auto"]}`}>
                    <button type="button" className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["w-100"]}`} onClick={handleSubmit(onSubmit)} disabled={loadingAddStudy}>Guardar {loadingAddStudy ? <div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div> : <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i>}</button>
                </div>
            </div>
            {noDataAdd ? handleStatusError(errorMessageAdd) : null}
        </>
    );
}

export default AgregarOtrosEstudios;