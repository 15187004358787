import React from 'react';
import styles from './replacement-assignment.module.css';
import PropTypes from 'prop-types';

const ReplacementComponent = ({ errors, register, team, replacement, setReplacement, hideSendButton }) => {

    return (
        <>
            <div className={styles['replacement-container']}>
                <div className={'row'}>
                    <div className={`${styles['block']}`}>
                        <div className={styles.label}>Mi Equipo: </div>
                        <div className={'col-4'}>
                            <select
                                className={'form-select mb-2'}
                                {...register('person', { required: 'Seleccione un reemplazo...' })}
                                defaultValue={''}
                                onChange={(e) => {
                                    setReplacement({
                                        ...replacement,
                                        person: e.target.value,
                                    });
                                }}
                            >
                                <option value="" disabled>
                                    Seleccione un reemplazo...
                                </option>
                                {team.map((teamMember) => (
                                    <option key={teamMember.identity} value={teamMember.name}>
                                        {teamMember.name}
                                    </option>
                                ))}
                            </select>
                            {errors.person && <span>{errors.person.message}</span>}
                        </div>
                    </div>
                </div>
                {!hideSendButton && <div className={`${styles['pr-0']}row`}>
                    <div className={`${styles['block']}`}>
                        <div className={styles['dates-container']}>
                            <div className={'col-5'}>
                                <div className={styles.label}>Fecha Inicio:</div>
                                <input
                                    type="date"
                                    {...register('init', { required: 'Campo requerido' })}
                                    defaultValue={replacement.init || ''}
                                    className={`form-control ${styles['date-input']}`}
                                    max={replacement.end ? replacement.end : undefined}
                                    onChange={(e) => {
                                        setReplacement({
                                            ...replacement,
                                            init: e.target.value,
                                        });
                                    }}
                                />
                                {errors.init && <span>{errors.init.message}</span>}
                            </div>
                            <div className={'col-5'}>
                                <div className={styles.label}>Fecha Finalización:</div>
                                <input
                                    type="date"
                                    {...register('end', { required: 'Campo requerido' })}
                                    defaultValue={replacement.end || ''}
                                    className={`form-control ${styles['date-input']}`}
                                    min={replacement.init ? replacement.init : undefined}
                                    onChange={(e) => {
                                        setReplacement({
                                            ...replacement,
                                            end: e.target.value,
                                        });
                                    }}
                                />
                                {errors.end && <span>{errors.end.message}</span>}
                            </div>
                        </div>
                    </div>
                </div>
            }
            </div>
        </>
    );
};

ReplacementComponent.propTypes = {
    register: PropTypes.func.isRequired,
    team: PropTypes.array.isRequired,
    replacement: PropTypes.shape({
        person: PropTypes.string.isRequired,
        init: PropTypes.string,
        end: PropTypes.string,
    }).isRequired,
    setReplacement: PropTypes.func.isRequired,
    replacementSetted: PropTypes.bool.isRequired,
};

export default ReplacementComponent;
