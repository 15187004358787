import React, { FC, ReactElement } from "react";

type cardType = "primary" | "secondary" | "outlined";

interface Props {
    children: ReactElement | ReactElement[];
    type?: cardType;
    className?: string;
    style?: React.CSSProperties;
}

const classCardType = {
    primary: "box h-100",
    secondary: "p-4 border border-primary rounded-4 w-100",
    outlined: "border",
};

export const Card: FC<Props> = ({
    children,
    type = "primary",
    className,
    style,
}) => {
    return (
        <div className={`${className}`} style={style}>
            <div className={`${classCardType[type]} `}>
                {children}
            </div>
        </div>
    );
};
