import React, { Children, useEffect, useState } from "react";
import { directReport, kpi, PreviewModalProps } from "../../../../utilities/interfaces";
import * as A from "fp-ts/Array";
import * as N from "fp-ts/number";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { range } from "fp-ts/lib/ReadonlyNonEmptyArray";

const sortByKpiLength = A.sortBy([N.Ord]);

const PreviewModal: React.FC<PreviewModalProps> = ({ reportsToDisplay, kpiLists, setSuccess }) => {
    const [zippedReports, setZippedReports] = useState<[directReport, kpi[]][]>([]);
    const [maxKpiLength, setMaxKpiLength] = useState<number>(0);

    useEffect(() => {
        reportsToDisplay.length && kpiLists.length && pipe(reportsToDisplay, A.zip(kpiLists), setZippedReports);
    }, [reportsToDisplay, kpiLists]);

    useEffect(() => {
        zippedReports.length && pipe(
            zippedReports,
            A.map((r) => r[1].filter((k) => k.name !== "").length),
            sortByKpiLength,
            A.last,
            O.getOrElse(() => 0),
            setMaxKpiLength,
        );
    }, [zippedReports]);

    return (
        <>
            {zippedReports.length && maxKpiLength > 0 ?
                <div className="modal fade" id="previewKpisModal" tabIndex={-1} aria-labelledby="previewKpisModal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content">
                            <form role="form">
                                <div className="modal-header border-0 pb-0">
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                </div>
                                <div className="modal-body">
                                    <h2 className="h5 mb-4">Resumen del Registro de mis Reportes</h2>
                                    <div className="box-scroll box-scroll d-none d-lg-block">
                                        <div className="table-responsive">
                                            <table className="table table-section table-borderless table-th-border align-middle table-even">
                                                <thead className="text-nowrap">
                                                    <tr>
                                                        <th>Nombre</th>
                                                        <th>Cargo</th>
                                                        {Children.toArray(range(1, maxKpiLength).map((k) => {
                                                            return (
                                                                <>
                                                                    <th>{`KPI ${k}`}</th>
                                                                    <th>Peso</th>
                                                                </>
                                                            )
                                                        }))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Children.toArray(zippedReports.map((r) => {
                                                        return (
                                                            <tr>
                                                                <td>{r[0].name}</td>
                                                                <td>{r[0].jobCodeDescr}</td>
                                                                {Children.toArray(r[1].filter((k) => k.name !== "").map((k) => {
                                                                    return k.weight !== 0 ? (
                                                                        <>
                                                                            <td>{k.name}</td>
                                                                            <td>{`${k.weight}%`}</td>
                                                                        </>
                                                                    ) : <></>
                                                                }))}
                                                                {r[1].filter((k) => k.name !== "").length < maxKpiLength && Children.toArray(range(r[1].filter((k) => k.name !== "").length, maxKpiLength - 1).map((r) => {
                                                                    return (
                                                                        <>
                                                                            <td></td>
                                                                            <td></td>
                                                                        </>
                                                                    )
                                                                }))}
                                                            </tr>
                                                        )
                                                    }))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="accordion accordion-flush accordion-light accordion-table d-lg-none">
                                        {Children.toArray(zippedReports.map((r, i) => {
                                            return (
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="accordion">
                                                        <button className={`accordion-button${i !== 0 ? " collapsed" : ""}`} type="button" data-bs-toggle="collapse" data-bs-target={`#previewItem${i}`} aria-expanded={`${i === 0}`} aria-controls={`previewItem${i}`}>
                                                            <strong>Nombre</strong>{` ${r[0].name}`}
                                                        </button>
                                                    </h2>
                                                    <div id={`previewItem${i}`} className={`accordion-collapse collapse${i === 0 ? "show" : ""}`} aria-labelledby="accordion">
                                                        <div className="accordion-body p-0">
                                                            <div className="data-list-box">
                                                                <ul className="data-list-section--item list-unstyled">
                                                                    <li className="d-block">
                                                                        <span className="data-list-box__title d-block col-12">Cargo</span>
                                                                        <span className="data-list-box__text d-block col-12">{r[0].jobCodeDescr}</span>
                                                                    </li>
                                                                    {Children.toArray(r[1].map((k, j) => {
                                                                        return k.weight !== 0 ? (
                                                                            <>
                                                                                <li className="d-block">
                                                                                    <span className="data-list-box__title d-block col-12">{`Indicador ${j + 1}`}</span>
                                                                                    <span className="data-list-box__text d-block col-12">{k.name}</span>
                                                                                </li>
                                                                                <li className="d-block">
                                                                                    <span className="data-list-box__title d-block col-12">Peso</span>
                                                                                    <span className="data-list-box__text d-block col-12">{`${k.weight}%`}</span>
                                                                                </li>
                                                                            </>
                                                                        ) : <></>
                                                                    }))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }))}
                                    </div>
                                </div>
                                <div className="modal-footer border-0 pt-0 justify-content-center pb-5 mt-3">
                                    <button type="button" className="btn btn-degradado col-12 col-md-auto px-5 order-md-last" data-bs-dismiss="modal" onClick={() => setSuccess({ valid: 1, message: "Asignación agregada" })}>{"Aceptar "}<i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" /></button>
                                    <button type="button" className="btn btn-link col-12 col-md-auto px-5" data-bs-dismiss="modal">{"Volver "}<i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" /></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            : <></>}
        </>
    )
};

export default PreviewModal;