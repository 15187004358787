import React from "react";

const Searching = () => {
  return (
    <>
        <button hidden id="sendingInfo" data-bs-target="#sendingInfoModal" data-bs-toggle="modal" data-bs-backdrop="static" data-bs-keyboard="false"  />
        <div className="modal fade" id="sendingInfoModal" tabIndex={-1} aria-labelledby="sending-info" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                        <button hidden id="closeSendingInfo" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center py-4">
                        <span className="spinner-border spinner-border-sm" />
                        <p> Cargando información... </p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Searching;