import React from "react";

const SendingInformation = () => {
  return (
    <>
        <button hidden id="sendingInfo" data-bs-target="#sendingInfoModal" data-bs-toggle="modal" data-bs-backdrop="static" data-bs-keyboard="false"  />
        <div className="modal fade" id="sendingInfoModal" tabIndex={-1} aria-labelledby="sending-info" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                        <button hidden id="closeSendingInfo" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center py-4">
                        <div className="modal-body text-center py-4">
                            <i className="fa fa-exclamation-triangle color-orange fa-3x" aria-hidden="true"></i>
                            <h2 className="h5">{"Cargando información"}</h2>
                            <p>{"Este proceso puede tardar unos segundos"}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default SendingInformation;