import React from 'react';

const Politicas = () => <>
    <h1>
        CONSENTIMIENTO DE RESGUARDO DE DATOS PERSONALES, PRIVACIDAD Y CONFIDENCIALIDAD DE LA INFORMACIÓN, GENERACIÓN DE FIRMA, GESTIÓN Y ENVÍO DE DOCUMENTACIÓN LABORAL ELECTRÓNICA
    </h1>
    <p>
        La Empresa ha puesto a disposición de usted, un Portal o Plataforma de autoservicio https://autoserviciorrhh.cencosud.cl, el que es de acceso a través de cualquier dispositivo electrónico conectado a internet, usando la clave personal del trabajador. Su funcionamiento comprende los siete días de la semana, las 24 horas del día, salvo mantenciones programadas.
    </p>
    <p>
        Para resguardar la confidencialidad de la información, la empresa le proporcionará una clave de seguridad de carácter personal, única e intransferible, siendo su responsabilidad no dar a conocer a terceros, usuarios ni contraseñas. Además, se pone a su disposición un portal de autogestión (https://reset.cencosud.com), para recuperar esta información en caso de ser necesario.
    </p>
    <p>
        En este Portal https://autoserviciorrhh.cencosud.cl podrá visualizar, y, si usted así lo requiere, enviar a su correo electrónico personal o al correo del responsable de Recursos Humanos (RRHH), información y documentación laboral personal relevante. En efecto, en dicho Portal podrá encontrar los siguientes documentos: contrato de trabajo, anexos al contrato de trabajo, liquidación de remuneraciones (actual como las liquidaciones de remuneración históricas), anexos de remuneración variable y certificados de antigüedad, entre otros. Además, podrá visualizar información personal laboral como saldos de días feriados (vacaciones), historial deausencias y evaluaciones de desempeño, entre otros. En dicho Portal también se encuentran alojados el Reglamento Interno de Orden, Higiene y Seguridad, Código de Ética, y otros Reglamentos y Políticas de la empresa relacionados con la gestión de personas.
    </p>
    <p>
        Considerando lo anterior, usted está conforme que podrá recibir, visualizar y firmar a través del mismo Portal, todo tipo de información y documentación laboral, entre las cuales se contemplan los siguientes, sin que esta nómina sea considerada como excluyente: contrato de trabajo, anexos al contrato de trabajo, liquidaciones de remuneraciones, Reglamentos Internos, Código de Ética. Lo anterior, en ningún caso será impedimento para que pueda solicitar los mismos documentos antes señalados en la oficina de "RR.HH" correspondiente a su ubicación.Asimismo, el Trabajador acepta el uso de sus datos personales y biométricos, para el control de acceso a distintas dependencias del empleador, incluso de aquellos datos personales o biométricos no indicados en el artículo 10 del Código del Trabajo. Se contempla expresamente el uso de los datos de reconocimiento facial, huella dactilar y teléfono personal.
    </p>
    <p>
        Es importante que usted tenga presente que los datos que ingresa a la Plataforma deben ser correctos y corresponder a la realidad, esto con el objeto de que el Portal pueda serle de utilidad y se facilite la gestión de documentos laborales para usted y la empresa.
    </p>
    <p>
        En ese sentido, usted mantendrá actualizada su información de contacto en el Portal de autoservicio, tales como teléfono celular, correo electrónico personal, dirección particular y contactos de emergencia.
    </p>
    <p>
        Por último, la empresa, al gestionar la Plataforma o Portal de Autoservicio sólo podrá usar, procesar, disponer y tratar la Información contenida en ella, para los propósitos informados precedentemente, respetando la Empresa plenamente los derechos que le concede la Ley N° 19.628.-, sobre Protección de la Vida Privada, y la Constitución Política de la República, con lo que usted manifiesta estar de acuerdo. Sus datos personales, para los efectos informados, sólo se mantendrán en la Plataforma mientras sea necesario para el facilitamiento de la gestión documental mencionada en este documento.
</p>
</>;

export default Politicas;