import React, { useState, useEffect, useRef, Children, useLayoutEffect } from 'react';
import { Text, Image, StyleSheet, View, Page, Document, PDFDownloadLink } from "@react-pdf/renderer";
import { dotNumber } from '../../../services/utiles';


const UltimaRemuneracion = ({
    colaborador,
    loadingColaborador,
    hiddenTd
}) => {
    const [remData, setRemData] = useState(null);
    const [lastRemData, setLastRemData] = useState({});
    const [anioValue, setAnioValue] = useState(null);

    useEffect(() => {

        if (!loadingColaborador) {
            setRemData(Array.isArray(colaborador.CN_REM_LIST) ? colaborador.CN_REM_LIST : [colaborador.CN_REM_LIST]);
            setLastRemData(Array.isArray(colaborador.CN_REM_LIST) ? colaborador.CN_REM_LIST[0] : colaborador.CN_REM_LIST);
            setAnioValue(Array.isArray(colaborador.CN_REM_LIST) ? colaborador.CN_REM_LIST[0].anio : colaborador.CN_REM_LIST.anio);
        };

    }, [loadingColaborador]);




    const FichaUltimaRemuneracionPDF = ({ colaborador, lastRemData }) => {

        if (!Array.isArray(colaborador.CN_REM_LIST)) {
            colaborador.CN_REM_LIST = [colaborador.CN_REM_LIST];
        }

        const styles = StyleSheet.create({
            page: {
                backgroundColor: '#fff',
                padding: '20px',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
            },
            card: {
                padding: "20px",
                borderRadius: "10px",
                width: "48%",
                border: "1px solid #eeeeee",
                margin: " 0px 5px 20px 5px",
                height: "auto",
            },
            head: {
                display: "flex",
                marginBottom: "20px",
                paddingLeft: "10px",
            },
            body: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "20px"
            },
            h1: {
                fontSize: "16px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: "10px",
                color: "#0169b5"
            },
            table: {
                width: "100%",
                borderCollapse: "collapse",
                borderSpacing: "0",
                marginBottom: "20px"
            },
            tableHead: {
                backgroundColor: "#eeeeee",
                borderBottom: "1px solid #eeeeee",
                borderTop: "1px solid #eeeeee",
                borderLeft: "1px solid #eeeeee",
                borderRight: "1px solid #eeeeee",
                padding: "5px",
                fontSize: "8px",
            },
            tableBody: {
                borderBottom: "1px solid #eeeeee",
                borderLeft: "1px solid #eeeeee",
                borderRight: "1px solid #eeeeee",
                fontSize: "8px",
                textAlign: "center",
                color: "#777777",
            },
            tableRow: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                padding: "5px 5px 5px 5px",
                border: "1px solid #eeeeee",
            },
            labelHead: {
                textAlign: "center",
                color: "#777777",
                fontWeight: "900",
            },
            labelBody: {
                textAlign: "center",
                color: "#777777",
                fontWeight: "normal",
            },
            li: {
                display: "flex",
                flexDirection: "row",
                alignItems: 'flex-start',
                padding: "10px",
                borderBottom: "1px solid #eeeeee",
                position: "relative",
                justifyContent: "flex-start"
            },
            liTitle: {
                width: "50%",
            },
            liContent: {
                width: "50%",
            },
            titleLabel: {
                fontSize: "8px",
                fontWeight: "bold",
                color: "#777777"
            },
            contentLabel: {
                fontSize: "8px",
                fontWeight: "normal",
                color: "#777777"
            },
            foto: {
                width: "100px",
                height: "100px",
            },
            checkimg: {
                width: "20px",
                height: "20px",
            },
        });

        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    {/* Ultima Remuneracion */}
                    <View style={{ ...styles.card, width: "100%" }}>
                        <View style={styles.head}>
                            <Text style={styles.h1}>Última Remuneración</Text>
                        </View>
                        <View style={styles.body}>
                            <Text style={{ ...styles.labelHead, margin: "5px 5px 5px 5px", alignSelf: "flex-end", fontSize: "10px" }}>
                                {lastRemData && (lastRemData.mes === "01" ? "Enero, " :
                                    lastRemData.mes === "02" ? "Febrero, " :
                                        lastRemData.mes === "03" ? "Marzo, " :
                                            lastRemData.mes === "04" ? "Abril, " :
                                                lastRemData.mes === "05" ? "Mayo, " :
                                                    lastRemData.mes === "06" ? "Junio, " :
                                                        lastRemData.mes === "07" ? "Julio, " :
                                                            lastRemData.mes === "08" ? "Agosto, " :
                                                                lastRemData.mes === "09" ? "Septiembre, " :
                                                                    lastRemData.mes === "10" ? "Octubre, " :
                                                                        lastRemData.mes === "11" ? "Noviembre, " :
                                                                            lastRemData.mes === "12" ? "Diciembre, " :
                                                                                "")}{lastRemData && lastRemData.anio}
                            </Text>
                            <View style={styles.table}>
                                <View style={styles.tableHead}>
                                    <View style={styles.tableRow}>
                                        <Text style={{ ...styles.labelHead, width: "15%" }}>HABER</Text>
                                        <Text style={{ ...styles.labelHead, width: "15%" }}>VALOR HABER</Text>
                                    </View>
                                </View>
                                <View style={styles.tableBody}>
                                    {
                                        colaborador.CN_REM_LIST[0].CN_HABER_LIST !== undefined ?
                                            Array.isArray(colaborador.CN_REM_LIST[0].CN_HABER_LIST) ?
                                                colaborador.CN_REM_LIST[0].CN_HABER_LIST.map((item, index) => {
                                                    return (
                                                        <View style={styles.tableRow} key={"dt" + index}>
                                                            <Text style={{ ...styles.labelContent, width: "15%" }}>{item.haber === "" ? "-" : item.haber}</Text>
                                                            <Text style={{ ...styles.labelContent, width: "15%" }}>{item.valor === "" ? "-" : item.valor}</Text>
                                                        </View>
                                                    )
                                                })
                                                :
                                                <View style={styles.tableRow} key={"dt0"}>
                                                    <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_REM_LIST[0].CN_HABER_LIST.haber === "" ? "-" : colaborador.CN_REM_LIST[0].CN_HABER_LIST.haber}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_REM_LIST[0].CN_HABER_LIST.valor === "" ? "-" : colaborador.CN_REM_LIST[0].CN_HABER_LIST.valor}</Text>
                                                </View>
                                            :
                                            <View style={styles.tableRow}>
                                                <Text style={styles.labelContent}>Sin Registros</Text>
                                            </View>
                                    }
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>

            </Document>

        )
    }





    return (

        <>

            {(!loadingColaborador && remData != null) ?
                <>

                    {/* <PDFDownloadLink
                        className="text-decoration-none d-inline-block mb-3 mt-4 color-orange"
                        fileName="Ultima Remuneracion"
                        document={<FichaUltimaRemuneracionPDF colaborador={colaborador} lastRemData={lastRemData} />}
                    >
                        <i className="fa fa-file-pdf-o me-1" aria-hidden="true"></i>
                        Descargar
                    </PDFDownloadLink> */}
                    <div className='col-12 col-xl-6 d-flex'>
                        <div className="box align-items-stretch w-100">
                            <h2 className="h4 tit-section">Última Remuneración</h2>
                            <div className="row g-3 align-items-center justify-content-end mb-4">
                                <div className="col-auto">
                                    <label className="form-label m-0">
                                        <strong>Buscar por:</strong>
                                    </label>
                                </div>
                                <div className="col-auto">
                                    <select
                                        id="anioremdata"
                                        className="form-select"
                                        defaultValue={lastRemData.anio}
                                        onChange={(e) => {
                                            setAnioValue(e.target.value)
                                            if (e.target.value !== "") {
                                                if (Array.isArray(remData)) {
                                                    const filtred = remData.filter((item) => item.anio === e.target.value);
                                                    setLastRemData(filtred);
                                                } else {
                                                    setLastRemData(colaborador.CN_REM_LIST);
                                                }
                                            } else {
                                                setLastRemData(colaborador.CN_REM_LIST);
                                            }
                                        }}
                                    >
                                        <option value="">Año</option>
                                        {Array.isArray(remData) ? (
                                            Children.toArray(remData.map((remuneracion) => {
                                                return <option value={remuneracion.anio} >{remuneracion.anio}</option>;
                                            }))
                                        ) : remData.anio === "" ? null : (
                                            <option value={remData.anio}>{remData.anio}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="col-auto">
                                    <select
                                        id="mesremdata"
                                        disabled={anioValue === ""}
                                        className="form-select"
                                        defaultValue={lastRemData.mes}
                                        onChange={(e) => {
                                            const anioSelect = document.getElementById("anioremdata").value;
                                            if (e.target.value !== "") {
                                                if (Array.isArray(remData)) {
                                                    let filtred = remData.filter((item) => item.anio === anioSelect);
                                                    filtred = filtred.filter((item) => item.mes === e.target.value);
                                                    setLastRemData(filtred);
                                                } else {
                                                    setLastRemData(colaborador.CN_REM_LIST);
                                                }
                                            } else {
                                                setLastRemData(colaborador.CN_REM_LIST);
                                            }
                                        }}
                                    >
                                        <option value="" >Mes</option>
                                        {Array.isArray(remData) ? (
                                            Children.toArray(remData.map((remuneracion) => {
                                                return <option value={remuneracion.mes} >{remuneracion.mes}</option>;
                                            }))
                                        ) : remData.mes === "" ? null : (
                                            <option value={remData.mes}>{remData.mes}</option>
                                        )}
                                    </select>
                                </div>
                            </div>

                            <table className="table table-even table-section table-borderless text-uppercase d-md-table">
                                <thead>
                                    <tr>
                                        <th className="col-2">Haber</th>
                                        <th className="col-4">Valor Haber</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(remData) ? (
                                        Children.toArray(remData.map((rem) => {
                                            {
                                                return Array.isArray(rem.CN_HABER_LIST) ?
                                                    Children.toArray(rem.CN_HABER_LIST.map((item) => {
                                                        return (
                                                            <tr>
                                                                <td>{hiddenTd ? "*****" : item.haber === "" ? "-" : item.haber}</td>
                                                                <td>{hiddenTd ? "*****" : item.valor === "" ? "-" : "$ " + dotNumber(item.valor)}</td>
                                                            </tr>
                                                        );
                                                    })) :
                                                    <tr>
                                                        <td>{hiddenTd ? "*****" : rem.CN_HABER_LIST.haber === "" ? "-" : rem.CN_HABER_LIST.haber}</td>
                                                        <td>{hiddenTd ? "*****" : rem.CN_HABER_LIST.valor === "" ? "-" : "$ " + dotNumber(rem.CN_HABER_LIST.valor)}</td>
                                                    </tr>

                                            }
                                        }))
                                    ) : (
                                        <tr>
                                            <td colSpan="3">No hay datos</td>
                                        </tr>
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>Suma total de Haberes</td>
                                        <td>{hiddenTd ? "*****" : '$ ' + dotNumber(remData[0].total_haberes)}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </> : null
            }</>);
};

export default UltimaRemuneracion;