import FirmaDocumentosService from "../services/firma-documentos-service";
import React, { useState, useEffect, Children } from "react";
import { formatDate } from "../services/utiles";
import PropTypes from 'prop-types';
import { DTGobNorm } from "./commonComponents/DTGobNorm";

const FirmaDocumentos = (props) => {
    const [data, setData] = useState(false);
    const [responseFirmar, setResponseFirmar] = useState(false);
    const [responseRechazar, setResponseRechazar] = useState(false);
    const [cargar, setCargar] = useState(false);
    const [estadoCarga, setEstadoCarga] = useState("cargando...");
    const [datoSelecionado, setDatoSelecionado] = useState(data ? data[0] : false);
    const [btn, setBtn] = useState(true);
    const [btnSelected, setBtnSelected] = useState();
    const [documento, setdocumento] = useState([]);
    const [estadoDocumento, setEstadoDocumento] = useState(false);
    const [typeDoc, setTypeDoc] = useState([]);
    const [indexDoc, setIndexDoc] = useState(-1);
    const emplid = localStorage.getItem("emplid");

    useEffect(() => {
        document.getElementById("textFirmando").innerHTML = "Actualizando Documentos...";
        props.count("Cargando...")
        FirmaDocumentosService.getFirmaDocumentos(emplid, setData, setEstadoCarga);
    }, [emplid, cargar]);

    const setFirmar = (value, requesId, item) => {
        document.getElementById("textFirmando").innerHTML = "Firmando...";
        if (navigator.onLine) {
            if (value) {
                document.getElementById("closeModalFirmarDocumento").click();
                FirmaDocumentosService.setFirmar(emplid, setResponseFirmar, requesId, setCargar, cargar, item);
            } else {
                document.getElementById("closeModalRechazarDocumento").click();
                FirmaDocumentosService.setRechazar(emplid, setResponseRechazar, requesId, setCargar, cargar, item);
            }
            document.getElementById("firmandoBtn").click();
        } else {
            if (value) {
                document.getElementById("closeModalFirmarDocumento").click();
            } else {
                document.getElementById("closeModalRechazarDocumento").click();
            }
            document.getElementById("sinConexionFirma").click();
        }
    };

    const desencriptarArchivo = (value, name) => {
        name = name.replaceAll(" ", "_").replaceAll(".", "_");
        value = Buffer.from(value, "base64");
        let link = document.createElement("a");
        let blob = new Blob([value], { type: "application/pdf" });
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.dispatchEvent(new MouseEvent("click"));
        document.getElementById("modalFirmandoFirmaDocumento").click();
        if (typeDoc[0] == "R") {
            if (typeDoc[1] == "Y") {
                document.getElementById("button" + indexDoc + "R").disabled = false;
            }
            document.getElementById("button" + indexDoc + "A").disabled = false;
        } else {
            setBtn(false);
        }
    };

    const buscarDocumento = (request_id, emplid, name, i, tipo, aceptaR) => {
        FirmaDocumentosService.documento(request_id, emplid, setdocumento, setEstadoDocumento, name);
        setIndexDoc(i);
        setTypeDoc([tipo, aceptaR])
        document.getElementById("textFirmando").innerHTML = "Descargando...";
        document.getElementById("modalFirmandoFirmaDocumento").click();
    }

    useEffect(() => {
        if (documento.length > 0 && !estadoDocumento) {
            desencriptarArchivo(documento[0], documento[1]);
        } else if(estadoDocumento) {
            setTimeout(() => {
                document.getElementById("firmandoBtn").click();
                document.getElementById("textFirmando").innerHTML = "Error al descargar documento";
                document.getElementById("modalFirmandoFirmaDocumento").click();
            }, 1000);
            setdocumento([])
            setEstadoDocumento(false);
        }
        
    }, [documento, estadoDocumento])

    useEffect(() => {
        if (data != false && data.length > 0) {
            setBtnSelected("document0")
            setBtn(true)
            document.getElementById("document0").click();
            props.count(data.length);
            if (responseFirmar == true || responseRechazar == true) {
                setTimeout(() => {
                    document.getElementById("modalFirmandoFirmaDocumento").click();
                    document.getElementById("abrirModalSuccesFirma").click();
                }, 1000);
                setResponseFirmar(false);
                setResponseRechazar(false);
            } else if (responseFirmar === 1 || responseRechazar === 1) {
                setTimeout(() => {
                    document.getElementById("textFirmando").innerHTML = "Error al firmar";
                    document.getElementById("modalFirmandoFirmaDocumento").click();
                    document.getElementById("firmandoBtn").click();
                }, 1000);
                setResponseFirmar(false);
                setResponseRechazar(false);
            }
        } else {
            if (data == false && estadoCarga == "No hay documentos para firmar") {
                if (responseFirmar == true || responseRechazar == true) {
                    setTimeout(() => {
                        document.getElementById("modalFirmandoFirmaDocumento").click();
                        document.getElementById("abrirModalSuccesFirma").click();
                    }, 1000);
                    setResponseFirmar(false);
                    setResponseRechazar(false);
                }
                else if (responseFirmar === 1 || responseRechazar === 1) {
                    setTimeout(() => {
                        document.getElementById("textFirmando").innerHTML = "Error al firmar";
                        document.getElementById("modalFirmandoFirmaDocumento").click();
                        document.getElementById("firmandoBtn").click();
                    }, 1000);
                    setResponseFirmar(false);
                    setResponseRechazar(false);
                }
                props.count(0)
            }
        }
    }, [data, estadoCarga])

    const BloquearBoton = (doc, i, e) => {
        e.preventDefault();
        setDatoSelecionado(doc)
        setBtnSelected("document" + i);
        setBtn(true)
    }

    return (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
            </nav>
            <div className="firma-de-documentos">
                <div className="box mb-4">
                    <h2 className="h4 tit-section">Detalle Documentos</h2>
                    <div className="row d-none d-md-flex">
                        <>
                            <div className="col-12 col-xl-7 d-flex">
                                <div className="border border-primary rounded-4 p-4 align-items-stretch w-100">
                                    <div className="box-scroll box-scroll">
                                        {data ?
                                            Children.toArray(data.map((documento, i) => {
                                                return <>
                                                    <a type="button" onClick={(e) => BloquearBoton(documento, i, e)} id={"document" + i} className={btnSelected == ("document" + i) ? "text-decoration-none link-list d-block p-3  sectionDocumentSelected" : "text-decoration-none link-list d-block p-3  sectionDocument"}>
                                                        <div className="row">
                                                            <div className="col-11">
                                                                <div className="data-list-box ">
                                                                    <ul className="list-unstyled">
                                                                        <li>
                                                                            <span className="data-list-box__title col-6 text-uppercase">Tipo</span>
                                                                            <span className="data-list-box__text">{documento.descrdoc}</span>
                                                                        </li>
                                                                        <li >
                                                                            <span className="data-list-box__title col-6 text-uppercase">Fecha</span>
                                                                            <span className="data-list-box__text">{formatDate(documento.begin_dt)}</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="col-1 d-flex align-items-center">
                                                                <i className="fa fa-chevron-right color-primary fa-lg" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </>
                                            }))
                                            : (<div>
                                                <span>
                                                    <label>{estadoCarga}</label>
                                                </span>
                                            </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-5 d-flex mt-4 mt-xl-0">
                                <div className="border border-primary rounded-4 p-4 w-100">
                                    {data ?
                                        datoSelecionado != false ?
                                            <>
                                                <div className="data-list-box ">
                                                    <ul className="data-list-section--item list-unstyled">
                                                        <li>
                                                            <span className="data-list-box__title col-6 text-uppercase">Tipo</span>
                                                            <span className="data-list-box__text">{datoSelecionado.descrdoc}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title col-6 text-uppercase">Descargar</span>
                                                            <span className="data-list-box__text">
                                                                <a type="button"><i className="fa fa-file-pdf-o fa-fw me-2" onClick={() => { 
                                                                        document.getElementById("buttonDownloadingDocument")?.click();
                                                                        setIndexDoc(-1); 
                                                                        setTypeDoc([]);  
                                                                        buscarDocumento(datoSelecionado.request_id, emplid, datoSelecionado.descrdoc);
                                                                    }} 
                                                                    aria-hidden="true"></i></a>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="row mt-5">
                                                    <div className="d-grid col-6">
                                                        <button type="button" disabled={btn} data-bs-toggle="modal" data-bs-target="#aprobar" className="btn btn-degradado" >Firmar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                                    </div>
                                                    {datoSelecionado.aceptaRechazo == "Y" ?
                                                        <div className="d-grid col-6">
                                                            <button type="button" disabled={btn} data-bs-toggle="modal" data-bs-target="#rechazar" className="btn btn-primary" >Rechazar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                                        </div>
                                                        : null}
                                                </div>
                                            </>
                                            : null
                                        : <div>
                                            <span>
                                                <label>{estadoCarga}</label>
                                            </span>
                                        </div>}
                                </div>
                            </div>
                        </>
                    </div>

                    <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
                        {data ?
                            Children.toArray(data.map((documento, i) => {
                                return <>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="accordion">
                                            <button className={i === 0 ? "accordion-button" : "accordion-button collapsed"} type="button" onClick={() => setBtn(true)} data-bs-toggle="collapse" data-bs-target={"#accordion-item-solicitudes-1" + i} aria-expanded="true" aria-controls={"accordion-item-solicitudes-1" + i}>
                                                <strong className="text-uppercase">Tipo</strong>{documento.descrdoc}
                                            </button>
                                        </h2>
                                        <div id={"accordion-item-solicitudes-1" + i} className={i === 0 ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="accordion">
                                            <div className="accordion-body p-0">
                                                <div className="data-list-box data-list-box-even text-uppercase">
                                                    <ul className="data-list-section--item list-unstyled">
                                                        <li>
                                                            <span className="data-list-box__title">Fecha inicial</span>
                                                            <span className="data-list-box__text">{formatDate(documento.begin_dt)}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Acciones</span>
                                                            <span className="data-list-box__text">
                                                                <a onClick={ (e) => e.preventDefault() } type="button"><i className="fa fa-file-pdf-o fa-fw me-2" onClick={() => { 
                                                                    document.getElementById("buttonDownloadingDocument")?.click();
                                                                    setIndexDoc(-1); 
                                                                    setTypeDoc([]);   
                                                                    buscarDocumento(documento.request_id, emplid, documento.descrdoc, i, "R", documento.aceptaRechazo);
                                                                    }} aria-hidden="true"></i></a>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    <div className="row my-4">
                                                        <div className="d-grid gap-3" onClick={() => setDatoSelecionado(documento)}>
                                                            <button type="button" disabled id={"button" + i + "A"} onClick={() => setDatoSelecionado(documento)} data-bs-toggle="modal" data-bs-target="#aprobar" className="btn btn-degradado" >Firmar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                                            {documento.aceptaRechazo == "Y" ?

                                                                <button type="button" disabled id={"button" + i + "R"} onClick={() => setDatoSelecionado(documento)} data-bs-toggle="modal" data-bs-target="#rechazar" className="btn btn-primary" >Rechazar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                                                : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }))
                            : (<div>
                                <span>
                                    <label>{estadoCarga}</label>
                                </span>
                            </div>
                            )}
                    </div>
                </div>

                <div className="d-flex mb-5">
                    <i className="fa fa-exclamation-triangle fa-lg me-3 mt-2 color-red" aria-hidden="true"></i>
                    <div>
                        <h2 className="h6 text-uppercase color-normal">
                            <strong>Aceptación términos y condiciones</strong>
                        </h2>
                        <p>
                            Estoy de acuerdo de que al momento de firmar electrónicamente el
                            documento a través de la plataforma de Autoservicio,
                            <br className="d-none d-xxl-inline-block"></br> leí previamente el
                            documento disponible y acepto sus términos y condiciones.
                        </p>
                    </div>
                </div>

                <DTGobNorm />
            </div>

            <a hidden id="abrirModalSuccesFirma" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#successFirma"></a>
            <div className="modal fade" id="successFirma" tabIndex="-1" aria-labelledby="successFirma" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center py-4">
                            <i className="fa fa-check-circle-o color-orange fa-3x mb-2" aria-hidden="true"></i>
                            <h2 className="h5" id="textModal1">
                                Firma enviada correctamente
                            </h2>
                            <a id="cerrarModal" data-bs-dismiss="modal" data-bs-target="#successFirma"></a>
                            <button type="button" className="btn btn-degradado mt-4" data-bs-dismiss="modal">
                                Aceptar{" "}
                                <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <a hidden id="sinConexionFirma" data-bs-toggle="modal" data-bs-target="#sinConexionModalFirma"></a>
            <div className="modal fade" id="sinConexionModalFirma" tabIndex="-1" aria-labelledby="sinConexionModalFirma" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center py-4">
                            <i className="fa fa-exclamation-triangle color-orange fa-3x" aria-hidden="true"></i>
                            <h2 className="h5">No tienes conexión</h2>
                            <p>
                                No puedes ejecutar esta acción si no estás conectado.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <a hidden id="firmandoBtn" data-bs-toggle="modal" data-bs-target="#firmandoModalFirmaDocumento"></a>
            <div className="modal fade" id="firmandoModalFirmaDocumento" tabIndex="-1" aria-labelledby="firmandoModalFirmaDocumento" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button type="button" id="modalFirmandoFirmaDocumento" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center py-4">
                            <i className="fa fa-exclamation-triangle color-orange fa-3x" aria-hidden="true"></i>
                            <h2 id="textFirmando" className="h5">Firmando...</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="aprobar" tabIndex="-1" aria-labelledby="aprobar" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button id="closeModalFirmarDocumento" type="button" className="btn-close " data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center py-4">
                            <h2 className="h5">¿Estás seguro que deseas firmar <br></br> el documento?</h2>
                        </div>
                        <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                            <button type="button" className="btn btn-primary px-5" data-bs-dismiss="modal">No</button>
                            <button type="button" onClick={() => { setFirmar(true, datoSelecionado.request_id, datoSelecionado); }} className="btn btn-degradado px-5 " >Sí <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="rechazar" tabIndex="-1" aria-labelledby="rechazar" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button type="button" id="closeModalRechazarDocumento" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center py-4">
                            <h2 className="h5">¿Estás seguro que deseas <br></br> rechazar firmar el documento?</h2>
                        </div>
                        <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                            <button type="button" className="btn btn-primary px-5" data-bs-dismiss="modal">No</button>
                            <button type="button" onClick={() => { setFirmar(false, datoSelecionado.request_id, datoSelecionado); }} className="btn btn-degradado px-5 ">Sí <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <>
                <button hidden id={"buttonDownloadingDocument"} data-bs-target={"#downloadingDocumentModal"} data-bs-toggle="modal" data-bs-backdrop="static" data-bs-keyboard="false"  />
                <div className="modal fade" id={"downloadingDocumentModal"} tabIndex={-1} aria-labelledby={"downloadingDocumentModal"} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header border-0 pb-0">
                                <button type="button" hidden id={"closeButtonDownloadingDocument"} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-center py-4">
                                <i className="fa fa-exclamation-triangle color-orange fa-3x" aria-hidden="true"></i>
                                <h2 className="h5">{"Descargando..."}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    );
};
FirmaDocumentos.propTypes = {
    count: PropTypes.any,
};
export default FirmaDocumentos;
