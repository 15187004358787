import {
  ChangeEvent,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  DashboardProviderProps,
  DashboardTableContextType,
  DataTableToExport,
  ProfileSummarySelected,
} from "./teamdashboard.types";
import { useTeamDashboardProvider } from "./TeamDashboardContext";
import {
  CompensationsMapper,
  CompensationsResponse,
  MyTeamsResponse,
  PerformanceEvaluationResponse,
  TalentReviewResponse,
  TeamApi,
  UseFetchTableDataReturnType,
  WorkEnviromentResponse,
  useMyTeamsFetch,
} from "../hooks";
import { managmentPayRoll } from "./carouselList.data";
import { useDownloadExcel } from "../../../hooks";
import { useWorkEnvironmentFetch } from "../hooks/useWorkEnvironmentFetch";
import { useTalentReviewFetch } from "../hooks/useTalentReview";
import { usePerformanceEvaluationFetch } from "../hooks/usePerformanceEvaluation";
import { useCompensationsFetch } from "../hooks/useCompensationsFetch";
import { TypeWithKey, countryDictionary, currentYear } from "../../../utils";

export const DashboardTableContext = createContext<
  DashboardTableContextType | undefined
>(undefined);

export const DashboardTableContextProvider = (
  props: DashboardProviderProps
) => {
  const { children } = props;

  const [teamSelect, setTeamSelect] = useState("0");
  const [flagSelect, setFlagSelect] = useState<string>();
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  const [showLeaders, setShowLeaders] = useState(false);
  const [tableFetchInfo, setTableFetchInfo] = useState<
    UseFetchTableDataReturnType<
      | MyTeamsResponse
      | CompensationsResponse
      | PerformanceEvaluationResponse
      | TalentReviewResponse
      | WorkEnviromentResponse
    >[]
  >([]);
  const { getSelectedMenu } = useTeamDashboardProvider();
  const [idSubLeader, setIdSubLeader] = useState<string>();
  const { downloadExcel } = useDownloadExcel();
  const [dniList, setdniList] = useState<string>();
  const [profileSummarySelected, setProfileSummarySelected] =
    useState<ProfileSummarySelected>({
      idEmpoyee: undefined,
      countryCode: undefined,
      photo: "",
    });

  const [dataTableToExport, setDataTableToExport] =
    useState<DataTableToExport<any> | null>(null);

  const myTeamFetch = useMyTeamsFetch({
    idSubLeader,
    selectedYear,
  });

  const compensationsFetch = useCompensationsFetch({
    idSubLeader,
    selectedYear,
  });

  const performanceEvaluationFetch = usePerformanceEvaluationFetch({
    idSubLeader,
    selectedYear,
    dniList,
  });

  const talentReviewFetch = useTalentReviewFetch({
    selectedYear,
  });

  const workEnvironmentFetch = useWorkEnvironmentFetch({
    selectedYear,
  });

  useEffect(() => {
    const dataList = idSubLeader
      ? performanceEvaluationFetch.response.subDatable
      : performanceEvaluationFetch.response.dataTable;
    if (dataList.length > 0) {
      const newDniList = JSON.stringify(
        dataList.map((data) => data.nro_document)
      );
      if (newDniList !== JSON.stringify(dniList)) {
        const array = JSON.parse(newDniList);
        const numberArray = array.map(Number);
        const result = JSON.stringify(numberArray);
        setdniList(result);
      }
    }
  }, [idSubLeader, performanceEvaluationFetch.response]);

  useEffect(() => {
    setTableFetchInfo([
      myTeamFetch,
      compensationsFetch,
      performanceEvaluationFetch,
      talentReviewFetch,
      workEnvironmentFetch,
    ]);
  }, [
    myTeamFetch.response.isLoading,
    compensationsFetch.response.isLoading,
    performanceEvaluationFetch.response.isLoading,
    talentReviewFetch.response.isLoading,
    workEnvironmentFetch.response.isLoading,
  ]);

  useEffect(() => {
    resetSubData();
  }, [idSubLeader]);

  const teamSelectOptions = useMemo(
    () => [
      {
        id: "0",
        label: "Mi Equipo directo",
        fn: () => {
          setShowLeaders(false);
          setIdSubLeader(undefined);
        },
      },
      {
        id: "1",
        label: "Equipo de",
        fn: () => {
          setShowLeaders(true);
        },
      },
    ],
    []
  );

  const teamFindOption = (id: string) => {
    const findOption = teamSelectOptions.find((option) => option.id === id);
    findOption ? findOption.fn() : setShowLeaders(false);
  };

  const onChangeTeamSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    teamFindOption(value);
    setTeamSelect(value);
  };

  const onChangeFlagSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFlagSelect(value);
  };

  const titleSection = useMemo(
    () => getSelectedMenu?.label || managmentPayRoll.label,
    [getSelectedMenu]
  );

  const handleYearChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(e.target.value);
  };

  const handleDownloadFile = () => {
    if (dataTableToExport) {
      const { header, rows, filename } = dataTableToExport;
      downloadExcel(header, rows, filename);
    }
  };

  const sortingDataTable = (
    key: string,
    isSubData: boolean,
    list:
      | MyTeamsResponse
      | CompensationsResponse
      | PerformanceEvaluationResponse
      | TalentReviewResponse
      | WorkEnviromentResponse
  ) => {
    const newData = tableFetchInfo.map((table) => {
      if (table.key === key) {
        return {
          ...table,
          response: {
            ...table.response,
            dataTable: !isSubData ? list : table.response.dataTable,
            subDatable: isSubData ? list : table.response.dataTable,
          },
        };
      }

      return table;
    });

    setTableFetchInfo(newData);
  };

  const filterByFlagDataTable = (key: string) => {
    const filterFn = (data: TeamApi | CompensationsMapper) => {
      if (flagSelect && flagSelect !== "0") {
        return data.country === countryDictionary(flagSelect);
      }

      return data;
    };

    const dataByFilter: TypeWithKey<any> = {
      managmentPayRoll: {
        dataFilter: myTeamFetch.response.dataTable.filter(filterFn),
        subDataFilter: myTeamFetch.response.subDatable.filter(filterFn),
      },
      workEnvironment: {},
      talentReview: {},
      performanceEvaluation: {},
      compensations: {
        dataFilter: compensationsFetch.response.dataTable.filter(filterFn),
        subDataFilter: compensationsFetch.response.subDatable.filter(filterFn),
      },
    };

    const newData = tableFetchInfo.map((table) => {
      if (table.key === key) {
        return {
          ...table,
          response: {
            ...table.response,
            dataTable: dataByFilter[key].dataFilter,
            subDatable: dataByFilter[key].subDataFilter,
          },
        };
      }

      return table;
    });

    setTableFetchInfo(newData);
  };

  const resetSubData = () => {
    if (!idSubLeader) {
      setTableFetchInfo(
        tableFetchInfo.map((table) => {
          if (managmentPayRoll.name) {
            return {
              ...table,
              response: {
                ...table.response,
                subDatable: [],
                getAllFlags: myTeamFetch.response.getAllFlags,
              },
            };
          }

          return table;
        })
      );
    }
  };

  // Data exported context
  const contextValue = {
    titleSection,
    teamSelectOptions,
    onChangeTeamSelect,
    teamSelect,
    showLeaders,
    setIdSubLeader,
    idSubLeader,
    handleYearChange,
    onChangeFlagSelect,
    flagSelect,
    handleDownloadFile,
    setDataTableToExport,
    selectedYear,
    tableFetchInfo,
    sortingDataTable,
    filterByFlagDataTable,
    performanceChart:
      performanceEvaluationFetch.response.getPerformanceChartData || [],
    profileSummarySelected,
    setProfileSummarySelected,
  };

  return (
    <DashboardTableContext.Provider value={contextValue}>
      {children}
    </DashboardTableContext.Provider>
  );
};

export const useTableProvider = (): DashboardTableContextType => {
  const dashboardTableContext = useContext(DashboardTableContext);

  if (!dashboardTableContext) return {} as DashboardTableContextType;

  return dashboardTableContext;
};
