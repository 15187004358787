import React, { useEffect, useState, useContext } from 'react';
import moduloDtService from '../services/modulo-dt-service';
import ausenciasService from '../services/ausencias-service';
import Modales from './elements/modal-dt';
import PaginatorTable from "./elements/paginator-table";
import { encrypt } from '../services/utiles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Context } from '../context';
import { DTGobNorm } from './commonComponents/DTGobNorm';

const Ausencias_Dt = () => {
    const { setAuditoria, firstLoginData } = useContext(Context);
    const [datosTrabajador, setDatosTrabajador] = useState(false);
    const [data, setData] = useState([]);
    const [msj, setMsj] = useState(false);
    const [rut, setRut] = useState(false);
    const [ausenciasUnica, setAusenciaUnica] = useState([]);
    const [ausenciasTotales, setAusenciasTotales] = useState([]);
    const ModalBasico = Modales.ModalBasico;
    const ModalNoPermiso = Modales.ModalNoPermiso;
    const rowDescripción = []
    const rowDetalle = []
    const rowFechaInicio = []
    const rowFechaFinal = []
    const rowDias = []
    const [itemPaginador, setItemPaginador] = useState(null)
    const [resetOffset, setResetOffset] = useState(false)
    const [encabezados, setEncabezados] = useState(null)

    const rowDesc = []
    const rowDiasTotales = []
    const [itemPaginador2, setItemPaginador2] = useState(null)
    const [resetOffset2, setResetOffset2] = useState(false)
    const [encabezados2, setEncabezados2] = useState(null)

    useEffect(() => {
        if (ausenciasTotales.length > 0) {
            if (data != undefined && data !== 2 && data != false) {
                ausenciasTotales.map(ausencia => {
                    rowDesc.push(ausencia.tipoAusencia);
                    rowDiasTotales.push(ausencia.dias);
                });

                if (rowDesc.length > 0 || rowDiasTotales.length > 0) {
                    setEncabezados2(rowDesc)
                    setItemPaginador2(
                        [
                            {
                                title: "DEscripcion",
                                rows: rowDesc
                            },
                            {
                                title: "Dias Totales",
                                rows: rowDiasTotales
                            }
                        ]
                    )
                }
            }

        }
    }, [ausenciasTotales])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const ruts = document.getElementById("rut").value;
        setDatosTrabajador(false);
        setMsj(false);
        setRut(false);
        setData([]);
        moduloDtService.validateRut(ruts, firstLoginData.USERDT, setDatosTrabajador, setMsj, setRut);
        setAuditoria({ user: encrypt(firstLoginData.USERDT), descripcion: encrypt("valida permisos para consultar el usuario ausencias"), rut: encrypt(ruts) })

    }

    useEffect(() => {
        if (msj == true && datosTrabajador != false && rut != false && rut !== 0) {
            document.getElementById("openModalCarga").click(setData);
            setData([]);
            ausenciasService.getausencias(document.getElementById("rut").value, setData, null, null, firstLoginData.USERDT);
        } else if (msj == 2 && datosTrabajador == 2 && rut == 0) {
            document.getElementById("openModalPermiso").click();
            setDatosTrabajador(false);
            setMsj(false);
            setRut(false);
            setData([]);
        }
    }, [rut]);

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    useEffect(() => {
        if (data === false || data === 2) {
            setTimeout(function () {
                document.getElementById("closeModalCargando").click();
                document.getElementById("abrirModalDetalle").click();
            }, 500);
        } else if (data.length > 0) {
            const ruts = document.getElementById("rut").value;
            if (data != undefined && data !== 2 && data != false) {
                data.map(ausencia => {
                    rowDescripción.push(ausencia.descr_ausencia);
                    rowDetalle.push(ausencia.descr_cod);
                    rowFechaInicio.push(ausencia.fecha_inicio);
                    rowFechaFinal.push(ausencia.fecha_retorno);
                    rowDias.push(ausencia.dias_duracion);
                });

                if (rowDescripción.length > 0 || rowDetalle.length > 0 || rowFechaInicio.length > 0 || rowFechaFinal.length > 0 || rowDias.length > 0) {
                    setEncabezados(rowDescripción)
                    setItemPaginador(
                        [
                            {
                                title: "Descripcion",
                                rows: rowDescripción,
                            },
                            {
                                title: "Detalle",
                                rows: rowDetalle,
                            },
                            {
                                title: "Fecha inicio",
                                rows: rowFechaInicio,
                            },
                            {
                                title: "Fecha final",
                                rows: rowFechaFinal,
                            },
                            {
                                title: "Días",
                                rows: rowDias,
                            }
                        ]
                    )
                }
            }
            setAusenciaUnica(data.map(ausencia => ausencia.descr_ausencia).filter(onlyUnique));
            setAuditoria({ user: encrypt(firstLoginData.USERDT), descripcion: encrypt("Consulta ausencias del usuario"), rut: encrypt(ruts) })

            setTimeout(function () {
                document.getElementById("closeModalCargando").click();
            }, 500);
        }
    }, [data]);

    useEffect(() => {
        if (ausenciasUnica.length > 0) {
            const arrayTotales = [];
            let contador = 0;
            ausenciasUnica.map((tipoAusencia) => {
                data.filter(dato => dato.descr_ausencia == tipoAusencia).map(dataFiltered => {
                    contador += Number(dataFiltered.dias_duracion);
                })
                arrayTotales.push({ tipoAusencia, "dias": contador })
                contador = 0;
            })
            setAusenciasTotales(arrayTotales);
        }
    }, [ausenciasUnica])

    const disabledBtn = (e) => {
        e.preventDefault();
        let input = e.target.value;
        let btn = document.getElementById("btnBuscar");
        if (input == "") {
            btn.disabled = true;
        } else {
            btn.disabled = false;
        }
    }

    return (
        <>
            <ModalNoPermiso idBtnOpen="openModalPermiso"></ModalNoPermiso>
            <ModalBasico idBtn="openModalCarga" target="modalCargando" idModal="modalCargando" msj="Cargando..." p="" modalCierre="closeModalCargando" icon="fa fa-exclamation-triangle color-orange fa-3x" ></ModalBasico>

            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active"><Link to="/ausencias-dt">Ausencias</Link></li>
                </ol>
            </nav>
            <section className="ausencias">
                <h1>Ausencias</h1>
                <div className="box mb-4">
                    <h2 className="h4 tit-section">Detalle Ausencias</h2>
                    <h3 className="h5 my-4">Iniciar Búsqueda</h3>
                    <form onSubmit={async (e) => { handleSubmit(e); }} role="form" className="needs-validation" noValidate="noValidate">
                        <div className="row mb-2">
                            <div className="col-xl-6">
                                <div className="mb-3 row">
                                    <label htmlFor="rut" className="col-md-2 col-form-label "><strong>RUT:</strong></label>
                                    <div className="col-sm-6 mb-3">
                                        <input type="text" onChange={(e) => disabledBtn(e)} className="form-control" id="rut" required="required"></input>
                                        <div className="invalid-feedback">
                                            * Ingresar rut sin puntos y con guión.
                                        </div>
                                        <p className="mt-3">
                                            Indicar RUT sin puntos y con guión, del colaborador que desea buscar.
                                        </p>
                                    </div>
                                    <div className="col d-grid d-md-block">
                                        <button type="submit" id='btnBuscar' disabled className="btn btn-degradado" >Buscar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="mb-3 row">
                                    <label htmlFor="name" className="col-md-2 col-xl-3 col-form-label "><strong>NOMBRE:</strong></label>
                                    <div className="col-md-10 col-xl-9">
                                        <input type="text" className="form-control" id="name" defaultValue={datosTrabajador.name} disabled readOnly></input>
                                    </div>
                                </div>
                            </div>
                            <a hidden id="abrirModalDetalle" data-bs-toggle="modal" data-bs-target="#no-ausencia"></a>
                            <div className="modal fade" id="no-ausencia" tabIndex="-1" aria-labelledby="no-ausencia" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header border-0 pb-0">
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body text-center py-4">
                                            <i className="fa fa-times-circle-o color-orange fa-3x mb-2" aria-hidden="true"></i>
                                            <h2 className="h5">No existe detalle de Ausencias para el RUT consultado</h2>
                                        </div>
                                        <div className="modal-footer border-0 pt-0 justify-content-center pb-5">
                                            <button type="button" className="btn btn-degradado" data-bs-dismiss="modal">Aceptar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                {rut != false && rut != 0 ? (
                    <>
                        <div className="box mb-4">
                            <h2 className="h4 tit-section">Días de Ausencia</h2>
                            {data != false && data !== 1 && Array.isArray(data) && data.length > 0 ?
                                <PaginatorTable itemsPerPage={5} items={itemPaginador} header={encabezados} constHeader={'Descripcion'} setResetOffset={setResetOffset} resetOffset={resetOffset}></PaginatorTable>
                                : null
                            }
                        </div>
                        <div className="box mb-4">
                            <h2 className="h4 tit-section">Totales</h2>
                            {data.length > 0 && ausenciasTotales.length > 0 ?
                                <PaginatorTable itemsPerPage={5} items={itemPaginador2} header={encabezados2} constHeader={'Descripcion'} setResetOffset={setResetOffset2} resetOffset={resetOffset2}></PaginatorTable>
                                : "No hay registros que mostrar"}
                        </div>
                    </>
                ) : null}
                <DTGobNorm />
            </section>
        </>
    )
}

Ausencias_Dt.propTypes = {
    userDt: PropTypes.any,
    auditoria: PropTypes.any,
};
export default Ausencias_Dt;