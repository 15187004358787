import React, { useEffect, useRef, useState } from 'react'
import styles from '../../styles/errorStyle.module.css'
import { Grid } from '@mui/material'
import {ErrorContent} from '../../../../utilities/constantsTexts'
import ErrorButtons from '../actions/errorButtons'

const ServerError = () => {
  const notFoundImg : string = require('../../../../assets/errors/error500.svg').default;

  return (
    <Grid container className={styles.error_container}>
      <Grid item className={styles.error_img_container}>
        <img src={notFoundImg} className={styles.error_img}/>
      </Grid>
      <Grid item>
        <h1 className={styles.error_title}>{ErrorContent.title500_line1}</h1>
        <h1 className={styles.error_title}>{ErrorContent.title500_line2}</h1>
        <h3 className={styles.error_subtitle}>{ErrorContent.subtitle500}</h3>
      </Grid>
      <ErrorButtons/>
    </Grid>
  );
}

export default ServerError;