import { TrendingUp } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CertificadoIngresosRetencionesServices from "../../services/certificado-ingresos-retenciones/service";
import successMessage from "../SharedComponents/success-message";
import errorMessage from "../SharedComponents/error-message";
import Swal from "sweetalert2";
import confirmMessage from "../SharedComponents/confirm-message";
import ColorPicker from "../SharedComponents/color-picker";
import styles from "../../resources/css/style-COL.module.css";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-degradado ms-md-2",
    cancelButton: "btn btn-primary",
  },
  buttonsStyling: false,
});

const Form = ({
  isAdding,
  isEditing,
  setIsAdding,
  setIsEditing,
  colors,
  fonts,
  getCertificates,
  idCertificate,
  isLoadingCertificate,
  certificate,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [file, setFile] = useState(null);
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);

  const [color, setColor] = useState({
    r: "255",
    g: "255",
    b: "255",
    a: "1",
    hex: "#ffffff",
    wasModified: false,
  });

  const [maxSizeExceeded, setMaxSizeExceeded] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const cancel = () => {
    setIsAdding(false);
    setIsEditing(false);
  };

  const save = async (data) => {
    console.log("DATA", data);
    console.log("FILE", file);
    console.log("COLOR", color);

    setIsFirstTime(false);

    if (file === null) {
      return;
    }

    if (file.size / 1024 > 10240) {
      setMaxSizeExceeded(true);
      return;
    } else {
      setMaxSizeExceeded(false);
    }

    if (color?.hex === null) {
      return;
    }

    swalWithBootstrapButtons
      .fire({
        title: "Confirmación",
        text: "¿Desea continuar la operación?",
        // icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
        showCloseButton: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          setIsSaving(true);

          const createDto = {
            name: data.name,
            description: data.description,
            year: data.year,
            enable: data.enable,
            default_text_size: data.default_text_size,
            default_text_color: data.default_text_color,
            default_text_font: data.default_text_font,
            id: idCertificate,
            show_box_border: data.show_box_border,
            box_background_color: color?.wasModified
              ? color?.hex
              : convertHexToRgb(certificate?.box_background_color)?.hex,
          };

          // Call to service
          let response = null;

          if (idCertificate === null) {
            response =
              await CertificadoIngresosRetencionesServices.createCertificate(
                createDto,
                file
              );
          } else {
            response =
              await CertificadoIngresosRetencionesServices.modifyCertificate(
                createDto,
                file
              );
          }

          if (response === null) {
            errorMessage(null, "");
          } else {
            const status = response?.statusCode || response?.status;
            switch (status) {
              case 200:
                setIsAdding(false);
                setIsEditing(false);
                setIsSaving(false);
                successMessage(getCertificates);
                break;

              case 400:
                errorMessage(null, response?.message);
                setIsSaving(false);
                break;

              case 500:
                errorMessage(null, response?.message);
                setIsSaving(false);
                break;

              default:
                break;
            }
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      if (e.target.files.length < 1) {
        return;
      }
      const file = e.target.files[0];
      switch (file.type) {
        case "application/pdf":
          break;
        default:
          e.target.value = null;
          break;
      }

      setFile(e.target.files[0]);
      setIsFileLoaded(true);
    } else {
      setIsFileLoaded(false);
    }
  };

  const deleteObj = () => {
    confirmMessage(deleteConfirm);
  };

  const deleteConfirm = async () => {
    setIsDeleting(true);

    // Call to service
    const response =
      await CertificadoIngresosRetencionesServices.deleteCertificate(
        idCertificate
      );

    if (response === null) {
      errorMessage(null, "");
    } else {
      const status = response?.statusCode || response?.status;
      switch (status) {
        case 200:
          setIsAdding(false);
          setIsEditing(false);
          setIsDeleting(false);
          successMessage(getCertificates);
          break;

        case 400:
          errorMessage(null, response?.message);
          setIsDeleting(false);
          break;

        case 500:
          errorMessage(null, response?.message);
          setIsDeleting(false);
          break;

        default:
          break;
      }
    }
  };

  const convertHexToRgb = (hex) => {
    const returnRgb = {
      r: "255",
      g: "255",
      b: "255",
      a: "1",
      hex: "#ffffff",
    };

    if (hex != null && hex != undefined && typeof hex === "string") {
      const cleanHex = hex.replace("#", "");
      try {
        returnRgb.r = parseInt(cleanHex.slice(0, 2), 16);
        returnRgb.g = parseInt(cleanHex.slice(2, 4), 16);
        returnRgb.b = parseInt(cleanHex.slice(4, 6), 16);
      } catch (error) {
        console.log("Conversion error: hex to rgb");
      }
    }

    return returnRgb;
  };

  useEffect(() => {
    reset();
  }, [certificate]);

  return (
    <>
      {isLoadingCertificate ? (
        <div data-testid="loading" className={styles["full-spinner"]}>
          <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
        </div>
      ) : null}

      {!isLoadingCertificate ? (
        <div className={styles["box"] + " " + styles["mb-4"]}>
          <h2 className={styles["h4"] + " " + styles["tit-section"]}>
            {isAdding ? "Agregar Certificado" : null}
            {isEditing ? "Editar Certificado" : null}
          </h2>

          <form>
            {/* <!-- Fila 1 --> */}
            <div className={styles["row"]}>
              <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                <label className={styles["form-label"]}>
                  Nombre <span className={styles["color-red"]}>*</span>
                </label>
                <input
                  type="text"
                  className={styles["form-control"]}
                  {...register("name", {
                    required: "* Campo obligatorio",
                    maxLength: 255,
                  })}
                  defaultValue={certificate?.name}
                />
                <small className={styles["form-error-message"]}>
                  {errors?.name?.message}
                </small>
              </div>
              <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                <label className={styles["form-label"]}>
                  Descripción <span className={styles["color-red"]}></span>
                </label>
                <input
                  type="text"
                  className={styles["form-control"]}
                  {...register("description", {
                    maxLength: 500,
                  })}
                  defaultValue={certificate?.description}
                />
                <small className={styles["form-error-message"]}>
                  {errors?.description?.message}
                </small>
              </div>
            </div>
            {/* <!-- Fila 2 --> */}
            <div className={styles["row"]}>
              <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                <label className={styles["form-label"]}>
                  Año <span className={styles["color-red"]}>*</span>
                </label>
                <input
                  type="number"
                  className={styles["form-control"]}
                  {...register("year", {
                    required: "* Campo obligatorio",
                    validate: {
                      graterOrEqualThan: (value) => parseFloat(value) >= 1950,
                      lessOrEqualTha: (value) => parseFloat(value) <= 2150,
                    },
                  })}
                  defaultValue={certificate?.year}
                  onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  min={0}
                />
                <small className={styles["form-error-message"]}>
                  {errors?.year?.message}
                  {errors?.year &&
                    errors?.year?.type === "graterOrEqualThan" &&
                    "El valor debe ser mayor o igual a 1950"}
                  {errors?.year &&
                    errors?.year?.type === "lessOrEqualTha" &&
                    "El valor debe ser menor o igual a 2150"}
                </small>
              </div>
              <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                <label className={styles["form-label"]}>
                  Habilitado <span className={styles["color-red"]}>*</span>
                </label>
                <div className={styles["row"]}>
                  <div className={`${styles["col-12"]} ${styles["col-md-4"]}`}>
                    <div className={styles["form-check"]}>
                      <input
                        className={styles["form-check-input"]}
                        type="radio"
                        name="enable"
                        id="enable1"
                        value="true"
                        {...register("enable", {
                          required: "* Campo obligatorio",
                        })}
                        // defaultChecked={certificate?.enable === true}
                        defaultChecked={
                          certificate?.enable === null ||
                          certificate?.enable === undefined
                            ? true
                            : certificate?.enable === true
                        }
                      />
                      <label
                        className={styles["form-check-label"]}
                        htmlFor="enable1"
                      >
                        Sí
                      </label>
                    </div>
                  </div>
                  <div className={`${styles["col-12"]} ${styles["col-md-4"]}`}>
                    <div className={styles["form-check"]}>
                      <input
                        className={styles["form-check-input"]}
                        type="radio"
                        name="enable"
                        id="enable2"
                        value="false"
                        {...register("enable", {
                          required: "* Campo obligatorio",
                        })}
                        // defaultChecked={certificate?.enable === false}
                        defaultChecked={
                          certificate?.enable === null ||
                          certificate?.enable === undefined
                            ? false
                            : certificate?.enable === false
                        }
                      />
                      <label
                        className={styles["form-check-label"]}
                        htmlFor="enable2"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Fila 3 --> */}
            <div className={styles["row"]}>
              <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                <label className={styles["form-label"]}>
                  Tamaño de letra por defecto{" "}
                  <span className={styles["color-red"]}>*</span>
                </label>
                <input
                  type="number"
                  className={styles["form-control"]}
                  {...register("default_text_size", {
                    required: "* Campo obligatorio",
                    validate: {
                      graterOrEqualThan: (value) => parseFloat(value) >= 4,
                      lessOrEqualTha: (value) => parseFloat(value) <= 30,
                    },
                  })}
                  defaultValue={certificate?.default_text_size}
                  onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  min={0}
                />
                <small className={styles["form-error-message"]}>
                  {errors?.default_text_size?.message}
                  {errors?.default_text_size &&
                    errors?.default_text_size?.type === "graterOrEqualThan" &&
                    "El valor debe ser mayor o igual a 4"}
                  {errors?.default_text_size &&
                    errors?.default_text_size?.type === "lessOrEqualTha" &&
                    "El valor debe ser menor o igual a 30"}
                </small>
              </div>
              <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                <label className={styles["form-label"]}>
                  Color de letra por defecto{" "}
                  <span className={styles["color-red"]}>*</span>
                </label>
                <div className={styles["input-group"]}>
                  <select
                    className={styles["form-select"]}
                    {...register("default_text_color", {
                      required: "* Campo obligatorio",
                    })}
                  >
                    {isAdding ? (
                      <option value="" selected>
                        Seleccione
                      </option>
                    ) : null}

                    {colors.map((list) => {
                      return (
                        <option
                          key={list.id}
                          value={list.id}
                          selected={certificate?.default_text_color === list.id}
                        >
                          {list.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <small className={styles["form-error-message"]}>
                  {errors?.default_text_color?.message}
                </small>
              </div>
            </div>
            {/* <!-- Fila 4 --> */}
            <div className={styles["row"]}>
              <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                <label className={styles["form-label"]}>
                  Fuente por defecto <span className={styles["color-red"]}>*</span>
                </label>
                <div className={styles["input-group"]}>
                  <select
                    className={styles["form-select"]}
                    {...register("default_text_font", {
                      required: "* Campo obligatorio",
                    })}
                  >
                    {isAdding ? (
                      <option value="" selected>
                        Seleccione
                      </option>
                    ) : null}

                    {fonts.map((list) => {
                      return (
                        <option
                          key={list.id}
                          value={list.id}
                          selected={certificate?.default_text_font === list.id}
                        >
                          {list.description}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <small className={styles["form-error-message"]}>
                  {errors?.default_text_font?.message}
                </small>
              </div>
              <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                <label className={styles["form-label"]}>
                  PDF (Archivos menores a 8Mb) <span className={styles["color-red"]}>*</span>
                </label>
                <div className={styles["input-group"]}>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    // hidden
                    id="file"
                    formcontrolname="file"
                    name="file"
                    accept=".pdf"
                    className={styles["form-control"]}
                    aria-label="Adjuntar certificado"
                  />
                  {/* <a>
                    <i
                      className="fa fa-paperclip fa-fw"
                      aria-hidden="true"
                      style={{ color: "orange", cursor: "pointer" }}
                      htmlFor="file"
                    ></i>
                  </a> */}
                  {/* <label
                    htmlFor="file"
                    className="button-doc"
                    style={{ cursor: "pointer" }}
                  >
                    Subir
                  </label> */}
                  {/* <small className="label" style={{ marginLeft: "5px" }}>
                    {file?.name === null
                      ? "Ningún archivo seleccionado"
                      : file?.name}
                  </small> */}
                </div>
                <small className={styles["form-error-message"]}>
                  {!isFileLoaded && !isFirstTime
                    ? "* Campo obligatorio.  "
                    : null}
                  {maxSizeExceeded
                    ? "* El archivo no puede superar los 10MB"
                    : null}
                </small>
              </div>
            </div>
            {/* <!-- Fila 5 --> */}
            <div className={styles["row"]}>
              <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                <label className={styles["form-label"]}>
                  Color fondo de cajas <span className={styles["color-red"]}>*</span>
                </label>
                <div className={styles["input-group"]}>
                  <ColorPicker
                    color={
                      color?.wasModified === true
                        ? color
                        : convertHexToRgb(certificate?.box_background_color)
                    }
                    setColor={setColor}
                  />
                  <small className={styles["label"]} style={{ marginLeft: "5px" }}>
                    {/* {color?.hex === null ? "" : color?.hex} */}
                  </small>
                </div>
                <small className={styles["form-error-message"]}></small>
              </div>
              <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                <label className={styles["form-label"]}>
                  Mostrar borde de cajas <span className={styles["color-red"]}>*</span>
                </label>
                <div className={styles["row"]}>
                  <div className={`${styles["col-12"]} ${styles["col-md-4"]}`}>
                    <div className={styles["form-check"]}>
                      <input
                        className={styles["form-check-input"]}
                        type="radio"
                        name="show_box_border"
                        id="show_box_border1"
                        value="true"
                        {...register("show_box_border", {
                          required: "* Campo obligatorio",
                        })}
                        // defaultChecked={certificate?.show_box_border === true}
                        defaultChecked={
                          certificate?.show_box_border === null ||
                          certificate?.show_box_border === undefined
                            ? false
                            : certificate?.show_box_border === true
                        }
                      />
                      <label
                        className={styles["form-check-label"]}
                        htmlFor="show_box_border1"
                      >
                        Sí
                      </label>
                    </div>
                  </div>
                  <div className={`${styles["col-12"]} ${styles["col-md-4"]}`}>
                    <div className={styles["form-check"]}>
                      <input
                        className={styles["form-check-input"]}
                        type="radio"
                        name="show_box_border"
                        id="show_box_border2"
                        value="false"
                        {...register("show_box_border", {
                          required: "* Campo obligatorio",
                        })}
                        // defaultChecked={certificate?.show_box_border === false}
                        defaultChecked={
                          certificate?.show_box_border === null ||
                          certificate?.show_box_border === undefined
                            ? true
                            : certificate?.show_box_border === false
                        }
                      />
                      <label
                        className={styles["form-check-label"]}
                        htmlFor="show_box_border2"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div
            className={
              styles["d-grid"] +
              " " +
              styles["d-md-flex"] +
              " " +
              (isEditing === true
                ? styles["justify-content-md-between"]
                : styles["justify-content-md-end"]) +
              " " +
              styles["gap-2"] +
              " " +
              styles["flex-nowrap"] +
              " " +
              styles["mt-4"]
            }
          >
            <div className={`${styles["d-grid"]} ${styles["d-md-flex"]} ${styles["gap-2"]}`}>
              <button
                type="button"
                className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["order-md-last"]} ${styles["ms-md-2"]}`}
                onClick={handleSubmit(save)}
                disabled={isSaving || isDeleting}
              >
                Guardar{" "}
                {isSaving ? (
                  <div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div>
                ) : (
                  <i
                  className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                    aria-hidden="true"
                  ></i>
                )}
              </button>
              <button
                type="button"
                className={`${styles["btn"]} ${styles["btn-primary"]}`}
                onClick={cancel}
                disabled={isSaving || isDeleting}
              >
                Cancelar{" "}
                <i
                  className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                  aria-hidden="true"
                ></i>
              </button>
            </div>
            {isEditing ? (
              <button
                type="button"
                className={`${styles["btn"]} ${styles["btn-secondary"]} ${styles["order-md-first"]}`}
                onClick={deleteObj}
                disabled={isAdding || isSaving || isDeleting}
              >
                Eliminar{" "}
                {isDeleting ? (
                  <div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div>
                ) : (
                  <i
                  className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                    aria-hidden="true"
                  ></i>
                )}
              </button>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Form;
