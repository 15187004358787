import React, { useEffect, useState } from "react";
import { RemoteData } from "@devexperts/remote-data-ts";
import * as RD from "@devexperts/remote-data-ts";
import { HttpError } from "../../../../services/apiServices/interfaces";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import { httpClientEnv } from "../../../../services/apiServices/httpClient";
import { getBenefitByID, getBenefitCard, getItemsCache, getRemotePhotosBenefits, existsPhotoIds, likeBenefit, handleCacheBenefits, getFileByID } from "../../utilities/apiServices/apiRequest";
import { Link, useNavigate, useParams } from "react-router-dom";
import { downloadBase64, removeFileExt, scrollToTop, useCarousel } from "../../../../services/utiles";
import { benefitsCards, GetBenefitByIDProps, PostBenefit, GetBenefitCard, GetPhotoBenefit, PhotoBenefitProps, GetFileBenefit, typeLinks } from "../../utilities/interfaces";
import { SpinerFullScreen } from "../../../commonComponents/SpinerFullScreen";
import { urlsAws } from "../../../../resources/foo/api-endpoints";
import { Share } from "../../../commonComponents";
import Slider from "../../../commonComponents/Slider";

const transformDataForSlider = (data: typeLinks[]) => {
    return data.map((video, i) => (
        <div key={i} className="col-12 col-md-4">
            <div className="ratio ratio-16x9 mb-4">
                <iframe className="embed-responsive-item" src={video.url}></iframe>
            </div>
        </div>
    ));
};

const BenefitDetail = () => {
    const idParam = useParams();
    const [remoteDataBenefitByID, setRemoteDataBenefitByID] = useState<RemoteData<HttpError, GetBenefitByIDProps>>(RD.initial);
    const [totalLike, setTotallike] = useState(0);
    const [disabledLike, setDisabledLike] = useState(true);
    const [ownLike, setOwnLike] = useState(false);
    const [dataLike, setDataLike] = useState<RemoteData<HttpError, PostBenefit>>(RD.initial);
    const [relatedBenefits, setRelatedBenefits] = useState<RemoteData<HttpError, GetBenefitCard>>(RD.initial);
    const [relatedBenefitsCopy, setRelatedBenefitsCopy] = useState<RemoteData<HttpError, GetBenefitCard>>(RD.initial);
    const [imgCard, setImgCard] = useState('');
    const [remoteDataPhotoRelated, setRemoteDataPhotoRelated] = useState<RemoteData<HttpError, GetPhotoBenefit>>(RD.initial);
    const [remoteDataPhoto, setRemoteDataPhoto] = useState<RemoteData<HttpError, GetPhotoBenefit>>(RD.initial);
    const [remoteDataFile, setRemoteDataFile] = useState<RemoteData<HttpError, GetFileBenefit>>(RD.initial);
    const [isDataRelated, setIsDataRelated] = useState(false);
    const [shareActive, setShareActive] = useState<boolean>(false);
    const location = window.location.href;
    let navigate = useNavigate();
    
    const GetBenefitByID = async (idBenef: number) => {
        setRemoteDataBenefitByID(RD.pending)
        const dataBenefitByID = await getItemsCache(urlsAws.crudBenefit + "?id=" + idBenef);
        if (dataBenefitByID) {
            setRemoteDataBenefitByID(dataBenefitByID);
            setOwnLike(dataBenefitByID.value.response.benefit.own_like === 1 ? true : false)
                setTotallike(dataBenefitByID.value.response.benefit.total_likes)
        } else {
            RTE.run(await getBenefitByID(idBenef, true), httpClientEnv)
            .then(E.fold(e => { return setRemoteDataBenefitByID(RD.failure(e)) }, a => {
                let data = RD.success(a)
                if (data._tag === 'RemoteSuccess') {
                    setOwnLike(data.value.response.benefit.own_like === 1 ? true : false)
                        setTotallike(data.value.response.benefit.total_likes)
                    handleCacheBenefits(urlsAws.crudBenefit + "?id=" + idBenef, data)
                    return setRemoteDataBenefitByID(RD.success(a));
                } else {
                    return setRemoteDataBenefitByID(RD.failure({ tag: 'httpRequestError', error: 'length GetBenefitByID 0' }));
                } 
            }))
        }
    };

    const GetFileByID = async (idBenef: number) => {
        RTE.run(await getFileByID(idBenef), httpClientEnv)
            .then(E.fold(e => { return setRemoteDataFile(RD.failure(e)) }, a => {
                let data = RD.success(a)
                if (data._tag === 'RemoteSuccess') {
                    downloadBase64(removeFileExt(data.value.response.name_file), data.value.response.file);
                    return setRemoteDataFile(RD.success(a));
                } else {
                    return setRemoteDataFile(RD.failure({ tag: 'httpRequestError', error: 'length GetBenefitByID 0' }));
                } 
            }))
        
    };

    const setDataRelated = async (temp: RemoteData<HttpError, GetBenefitCard>) => {
        let arrayIdsNotCache: number[] = [];
        const dataBenefit = await Promise.all(
            temp._tag === 'RemoteSuccess' ? temp.value.response.benefits.map(async (benefit) => {
                const response = await caches.match(urlsAws.getPhotoBenefit + "?id=" + benefit.id);
                if (response) {
                    const data = await response.json();
                    if (data.id === benefit.id) {
                        return { ...benefit, img_card: data.img_card };
                    } else {
                        return {...benefit}
                    }
                } else {
                    arrayIdsNotCache.push(benefit.id)
                    return benefit;
                }
            }) : []
        );
        if (arrayIdsNotCache.length > 0) {
            GetPhotoBenefits(arrayIdsNotCache);
          } else if (temp._tag === 'RemoteSuccess') {
            let newBenefit = dataBenefit.filter(
              benef => benef.id !== Number(idParam.id),
            );
            temp.value.response.benefits = newBenefit;
            setRelatedBenefitsCopy(temp);
            setIsDataRelated(false);
          }
    };

    const GetPhotoBenefits = (arrayIds: number[]) => {
        if(arrayIds.length > 0){
            setIsDataRelated(true);
            existsPhotoIds(arrayIds)
                .then((emplidPhoto) => {
                    if (emplidPhoto.length > 0) {
                        getRemotePhotosBenefits(emplidPhoto, setRemoteDataPhotoRelated)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const GetBenefitRelated = async (categoryId: number) => {
        const benefitsRelated = await getItemsCache(urlsAws.getBenefitCard + "?category=" + categoryId);
        if(benefitsRelated){
            setDataRelated(benefitsRelated);  
        } else {
            RTE.run(await getBenefitCard(categoryId), httpClientEnv)
            .then(E.fold(e => { return setRelatedBenefits(RD.failure(e)) }, a => {
                let data = RD.success(a)
                if (data._tag === 'RemoteSuccess') {
                    setDataRelated(data)                  
                    handleCacheBenefits(urlsAws.getBenefitCard + "?category=" + categoryId, data)
                    return setRelatedBenefits(RD.success(a));
                } else {
                    return setRelatedBenefits(RD.failure({ tag: 'httpRequestError', error: 'error GetBenefitRelated' }));
                } 
            }))
        }
    };

    const PostLikeBenefit = async (idBenef: number, like : boolean) => {
        setDataLike(RD.pending)
        const responseBenef = await caches.match(urlsAws.crudBenefit + "?id=" + idBenef);
        RTE.run(await likeBenefit({id: idBenef}, like), httpClientEnv)
        .then(E.fold(e => { return setDataLike(RD.failure(e)) }, async a => {
            let data = RD.success(a)
            if (data._tag === 'RemoteSuccess' && data.value.valid) {
                setDisabledLike(true);
                if (responseBenef) {
                    const dataBenef = await responseBenef.json();
                    const updatedItem: GetBenefitByIDProps  = { 
                        ...dataBenef.value.response.benefit, 
                        total_likes: like ? dataBenef.value.response.benefit.total_likes + 1 : dataBenef.value.response.benefit.total_likes - 1,
                        own_like: like ? 1 : 0
                    };
                    dataBenef.value.response.benefit = updatedItem
                    caches.open("api-cache").then((cache) => {
                        cache.put(urlsAws.crudBenefit + "?id=" + idBenef , new Response(JSON.stringify(dataBenef)))
                    })  
                }
                return setDataLike(RD.success(a));
            } else {
                setDisabledLike(false);
                return setDataLike(RD.failure({ tag: 'httpRequestError', error: 'error PostLikeBenefit' }));
            } 
        }))
    };

    const renderHTML = (rawHTML: any) => (
        <div
          className="render-html-content"
          dangerouslySetInnerHTML={{ __html: rawHTML }}
        />
      );

    const handleLike = async (e: any) => {
        e.preventDefault();
        e.target.disabled = true;
        setDisabledLike(false);
        if (ownLike) {
            PostLikeBenefit(Number(idParam.id), false)
            setTotallike(totalLike - 1);
        } else {
            PostLikeBenefit(Number(idParam.id), true);
            setTotallike(totalLike + 1);
        }
        setOwnLike(!ownLike);
    };

    const getImgCard = async (idBenef: number) => {
        const responseBenef = await caches.match(urlsAws.getPhotoBenefit + "?id=" + idBenef);
        if(responseBenef){
            const data: PhotoBenefitProps = await responseBenef.json();
            setImgCard(data.img_card)
        } else {
            getRemotePhotosBenefits([idBenef], setRemoteDataPhoto, setImgCard)
        }
    }

    useEffect(()=> {
        scrollToTop();
        Number(idParam.id) > 0 && GetBenefitByID(Number(idParam.id))
    },[idParam.id])

    useEffect(()=>{
        if(remoteDataBenefitByID._tag === 'RemoteSuccess'){
            GetBenefitRelated(remoteDataBenefitByID.value.response.benefit.category_id);
            getImgCard(remoteDataBenefitByID.value.response.benefit.id)
        }
    },[remoteDataBenefitByID])

    useEffect(() => {
        if (
          remoteDataPhotoRelated._tag === 'RemoteSuccess' &&
          isDataRelated &&
          relatedBenefits._tag === 'RemoteSuccess'
        ) {
          setTimeout(() => {
            setDataRelated(relatedBenefits);
          }, 1500);
        }
      }, [remoteDataPhotoRelated, isDataRelated, relatedBenefits]);
     
    useCarousel(10000,'carousel-beneficios-mobile', relatedBenefits._tag === 'RemoteSuccess');
    
    return(
        <>
            <section className="beneficios">
            {remoteDataBenefitByID._tag === 'RemoteSuccess' && (
                <>
                    <nav id="nav" className="breadcrumbs" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/inicio"><i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true"></i> Inicio</Link></li>
                            <li className="breadcrumb-item"><Link type="button" to="/mis-beneficios"><i style={{ pointerEvents: "none" }} aria-hidden="true"></i> Mis Beneficios</Link></li>
                            {remoteDataBenefitByID && remoteDataBenefitByID.value.response.benefit.title && <li className="breadcrumb-item active" aria-current="page">{remoteDataBenefitByID.value.response.benefit.title}</li>}
                        </ol>
                    </nav>
                </>
            )}
                
            <h1>Mis Beneficios</h1>
            {remoteDataBenefitByID._tag === 'RemoteSuccess' ? (
                <>
                    <div className="card card-box-mobile card-image-end p-0 mb-3">
                        <div className="row align-items-center g-0">
                            <div className="col-md-6">
                                <div className="card-body p-4">
                                    <div className="color-gradient-1 fw-bold fs-1 mb-4">
                                        <div>{remoteDataBenefitByID.value.response.benefit.title}</div>
                                    </div>
                                    <p className="fs-4 fw-bold m-0">{remoteDataBenefitByID.value.response.benefit.subtitle}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img className="img-fluid img-banner-detail" src={imgCard ? imgCard : require('../../../../resources/images/default_img.png')} width="792" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="row w-auto">
                            <div className="col-12 col-md-6">
                                <p className="fs-5 color-primary"></p>
                                {remoteDataBenefitByID.value.response.isFile && <p className="fs-5 color-primary"><strong>Descargar:</strong> <a className="fw-bold btnLink" onClick={(e) => { GetFileByID(Number(idParam.id)) }}>Archivo adjunto <i className="fa fa-download fa-fw color-primary" aria-hidden="true"></i></a></p>}
                            </div>
                            <div className="col-12 col-md-6 text-md-end">
                                {remoteDataBenefitByID.value.response.benefit.module && <div className="d-grid d-md-flex justify-content-md-end gap-2 flex-nowrap">
                                    <a className="btn btn-degradado" target="_blank" href={remoteDataBenefitByID.value.response.benefit.module.component.url}>Solicitar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></a>
                                </div>}
                                <div className="mt-3 text-center text-md-end btn-benefit">
                                    <button className="btn color-primary-strong"><i id="rojo" onClick={(e) => { disabledLike && handleLike(e); }} style={ownLike ? {color: 'red'} : { color: "#d2d2d2" }} className="fa fa-heart fa-fw fa-lg" aria-hidden="true"></i>  {totalLike} Me gusta</button>
                                    <button className="btn color-primary-strong" onClick={(e) => setShareActive(!shareActive)}><i className="fa fa-paper-plane-o fa-fw color-primary" aria-hidden="true"></i> Compartir</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {remoteDataBenefitByID.value.response.benefit.description && 
                        <div className="box mb-4">
                            <h2 className="h4 tit-section">Descripción</h2>
                            <div className="html-content">{renderHTML(remoteDataBenefitByID.value.response.benefit.description)}</div>
                        </div>
                    }
                    {remoteDataBenefitByID.value.response.benefit.condition && 
                        <div className="box mb-4" style={{ whiteSpace: 'pre-line'}}>
                           <h2 className="h4 tit-section">Condiciones</h2>
                            <div className="html-content">{renderHTML(remoteDataBenefitByID.value.response.benefit.condition)}</div>
                        </div>
                    }

                    {remoteDataBenefitByID.value.response.benefit.videos.length > 0 && 
                        <div className="box mb-5">
                            <h2 className="h4 tit-section">Videos</h2>
                            <Slider 
                                id="videos-beneficios" 
                                data={transformDataForSlider(remoteDataBenefitByID.value.response.benefit.videos)} 
                            />
                        </div>
                    }
                    {relatedBenefitsCopy._tag === 'RemoteSuccess' && relatedBenefitsCopy.value.response.benefits.length > 0 ? <div className="mb-4">
                        <h2 className="h4 tit-section">Beneficios Relacionados</h2>
                        <div id="carousel-beneficios" className="carousel carousel-dark slide carousel-control-inner mb-3 d-none d-md-block" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                {relatedBenefitsCopy.value.response.benefits.reduce((chunks: benefitsCards[][], beneficio, i) => {
                                        const index = Math.floor(i / 4);
                                        if (!chunks[index]) {
                                        chunks[index] = [];
                                        }
                                        chunks[index].push(beneficio);
                                        return chunks;
                                        }, []).map((banner, index) => {
                                        if (banner) {
                                            return <button key={index} type="button" data-bs-target="#carousel-beneficios" data-bs-slide-to={index} aria-label={"Slide " + index} className={index === 0 ? "active" : ""} aria-current="true"></button>
                                        }
                                    })
                                }
                            </div>
                            <div className="carousel-inner">
                                {relatedBenefitsCopy.value.response.benefits.reduce((chunks: benefitsCards[][], beneficio, i) => {
                                    const index = Math.floor(i / 4);
                                    if (!chunks[index]) {
                                    chunks[index] = [];
                                    }
                                    chunks[index].push(beneficio);
                                    return chunks;
                                    }, []).map((benefit, index )=>(
                                        <div className={`carousel-item p-2 ${index === 0 ? 'active' : ''}`} data-bs-interval="10000">
                                            <div className="row row-cols-4">
                                                {benefit.map((benef)=>{
                                                    if(benef.id && benef.id !== Number(idParam.id)){
                                                        return(
                                                            <div className="col d-flex mx-auto mb-4">
                                                                <article className="d-flex flex-column box-card w-100">
                                                                    <div className="box-card__img">
                                                                    <div className="card-icon card-icon-absolute">
                                                                        <span className="material-symbols-outlined fs-2">
                                                                            <i className={`fa ${benef.category.icon} fa-fw`} aria-hidden="true"></i>
                                                                        </span>
                                                                    </div>
                                                                    <img className="img-fluid img-cards" src={benef.img_card ? benef.img_card : require('../../../../resources/images/default_img.png')}  alt=""/>
                                                                </div>
                                                                <div className="box-card__body d-flex flex-column flex-grow-1">
                                                                    <h3>
                                                                        <a className="color-dark" href={"mis-beneficios/"+benef.id}>{benef.title}</a>
                                                                    </h3>
                                                                    <p>{benef.subtitle.length > 40 ? benef.subtitle.substring(0,40) + '...' : benef.subtitle}</p>                                                                        
                                                                    <div className="text-end mt-auto">
                                                                        <a className="btn btn-degradado" onClick={()=>{
                                                                            navigate("/mis-beneficios/"+benef.id);
                                                                        }}>Ver más<i className="fa fa-chevron-right" aria-hidden="true"></i></a>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        </div>
                                ))}
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carousel-beneficios" data-bs-slide="prev">
                                <i className="fa fa-chevron-left color-dark" aria-hidden="true"></i>
                                <span className="visually-hidden">Anterior</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carousel-beneficios" data-bs-slide="next">
                                <i className="fa fa-chevron-right color-dark" aria-hidden="true"></i>
                                <span className="visually-hidden">Siguiente</span>
                            </button>
                        </div>
                        <div id="carousel-beneficios-mobile" className="carousel carousel-dark slide carousel-control-inner d-md-none pointer-event" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                {relatedBenefitsCopy.value.response.benefits.map((banner, index) => {
                                    if (banner.id) {
                                        return <button key={index} type="button" data-bs-target="#carousel-beneficios-mobile" data-bs-slide-to={index} aria-label={"Slide " + index} className={index === 0 ? "active" : ""} aria-current="true"></button>
                                    }
                                    })
                                }
                            </div>
                            <div className="carousel-inner">
                                {relatedBenefitsCopy.value.response.benefits.map((benefit, i )=>{
                                    if(benefit.id && benefit.id !== Number(idParam.id)){
                                        return(
                                            <>
                                                <div className={`carousel-item p-2 mb-4 ${i === 0 ? 'active' : ''}`} data-bs-interval="10000">
                                                    <div className="row row-cols-1 row-cols-md-2 row-cols-xl-2 row-cols-xxl-4 g-4 mb-2 w-auto">
                                                        <div className="col d-flex">
                                                            <article className="d-flex flex-column box-card w-100">
                                                                <div className="box-card__img">
                                                                    <div className="card-icon card-icon-absolute">
                                                                        <span className="material-symbols-outlined fs-2">
                                                                            <i className={`fa ${benefit.category.icon} fa-fw`} aria-hidden="true"></i>
                                                                        </span>
                                                                    </div>
                                                                    <img className="img-fluid img-cards" src={benefit.img_card ? benefit.img_card : require('../../../../resources/images/default_img.png')} alt=""/>
                                                                </div>
                                                                <div className="box-card__body d-flex flex-column flex-grow-1">
                                                                    <h3><a className="color-dark" href="#">{benefit.title}</a></h3>
                                                                    <p>{benefit.subtitle.length > 40 ? benefit.subtitle.substring(0,40) + '...' : benefit.subtitle}</p>                                                                        
                                                                    <div className="text-end mt-auto">
                                                                        <a className="btn btn-degradado" onClick={()=>{
                                                                            navigate("/mis-beneficios/"+benefit.id);
                                                                        }}>Ver más <i className="fa fa-chevron-right" aria-hidden="true"></i></a>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div> 
                    : relatedBenefitsCopy._tag === 'RemotePending' || relatedBenefitsCopy._tag === 'RemoteInitial' &&  
                        <>
                            <div className="text-center">
                                <p>Cargando beneficios relacionados...</p>
                                <SpinerFullScreen/>
                            </div>
                        </>
                    }
                </>
            ): remoteDataBenefitByID._tag === 'RemotePending' ?
                <>
                    <div className="text-center">
                        <p>Cargando beneficio...</p>
                        <SpinerFullScreen/>
                    </div>
                </>
            : remoteDataBenefitByID._tag === 'RemoteFailure' && 
                <>
                    <div className="text-center">
                        <p>Beneficio no disponible.</p>
                    </div>
                </>
            }
               
                <div className="d-grid d-md-flex justify-content-md-between gap-2 flex-nowrap">
                    <Link to="/mis-beneficios" onClick={()=>{
                        setRemoteDataBenefitByID(RD.initial)
                    }}>
                        <button type="button" className="btn btn-link order-md-first"> {'Volver'}</button>
                    </Link>
                </div>
            </section>

            <Share
                modalTitle="Compartir Beneficio"
                urlToShare={location}
                activeState={shareActive}
                toggleState={setShareActive}
                shareIn={[
                    "Facebook",
                    "Whatsapp",
                    "X",
                    "Telegram",
                    "Linkedin",
                ]}
                emailTitle="Compartir Oferta"
                emailBody=""
            />
        </>
    )
}
export default BenefitDetail;