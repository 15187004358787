import { HttpClient } from './httpClient'
import apiInfo from './apiEndpoints'

import {
  Article,
  APICategories,
  APIArticles,
  APICategory,
  FeedbackPost
} from "../interfaces"
export class ApiRequest {
  constructor(public http: HttpClient) { }

  async getCategories(): Promise<APICategories> {
    const response = await this.http.get(apiInfo.urls.categoryListV2 + apiInfo.querys.categoryIcons)
    return response
  }

  async getCategoryByID(id: number): Promise<APICategory> {
    const response = await this.http.get(apiInfo.urls.categoryListV2 + id)
    return response
  }

  async getArticles(): Promise<APIArticles> {
    const url = apiInfo.urls.articlesList
    const response = await this.http.get(url)
    return response
  }

  async getArticleByID(id: number): Promise<Article> {
    const response = await this.http.get(apiInfo.urls.articlesList + id)
    return response?.data
  }

  async sendUserHistory(id: number): Promise<any> {
    const savedData = this.http.getLocalUserData()
    if(savedData === '') return ''
    const savedUserData = JSON.parse(savedData)
    const completeData = {
      ...savedUserData, article_id: id
    }
    const sendUserData = await this.http.postUserHistory(apiInfo.urls.userHistory, completeData)
    console.log(sendUserData)
    const successText = 'History recorded successfully'
    const body = await sendUserData.text()
    if (body === successText) {
      return 'success'
    } else {
      return 'failed'
    }
  }

  async getSearchResults(searchText: string): Promise<APIArticles> {
    const response = await this.http.get(apiInfo.urls.search + searchText)
    return response
  }
  async postFeedback(feedback: FeedbackPost): Promise<any> {
    const response = await this.http.postFeedback(apiInfo.urls.feedback, feedback)
    return response
  }
}