import React, { Children, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { urlsAws } from "../../resources/foo/api-endpoints";
import datosLaboralesService from "../../services/datos-laborales-service";
import PropTypes from 'prop-types';
import { useCarousel } from "../../services/utiles";

const CarrouselTiempo = (props) => {
  CarrouselTiempo.propTypes = {
    tituloSelect: PropTypes.any,
  };
  const [loginData, setLoginData] = useState(null);
  const employeeID = localStorage.getItem("employeeId");
  const [rutas, setRutas] = useState(null);
  useEffect(() => {
    caches.match(urlsAws.firstLogin).then((response) => {
      if (response && response.body !== null) {
        response.json().then((data) => {
          setLoginData(data);
        });
      } else {
        datosLaboralesService.firstLogin(employeeID);
      }
    });
  }, []);

  useEffect(() => {
    if (loginData) {
      if (loginData.BOSS === "Y" || loginData.REPLACEMENT !== "") {
        setRutas([
          {
            titulo: "Canje cenco flexible",
            ruta: "/tiempo-beneficios",
          },
          {
            titulo: "Canjear",
            ruta: "/tiempo-canjear",
          },
          {
            titulo: "Historial de Canjes",
            ruta: "/tiempo-historial",
          },
          {
            titulo: "Canjes de mi equipo",
            ruta: "/tiempo-equipo",
          },
        ]);
      } else {
        setRutas([
          {
            titulo: "Canje cenco flexible",
            ruta: "/tiempo-beneficios",
          },
          {
            titulo: "Canjear",
            ruta: "/tiempo-canjear",
          },
          {
            titulo: "Historial de Canjes",
            ruta: "/tiempo-historial",
          },
        ]);
      }
    }
  }, [loginData]);

  useCarousel(0,'carousel-mi-tiempo-mobile', rutas);

  return (
    <>
      <div className="row mt-5 mb-4 d-none d-lg-flex">
        {rutas ? (
          Children.toArray(
            rutas.map((e) => {
              if (props.tituloSelect === e.titulo) {
                return (
                  <div className="col mb-4">
                    <Link
                      className="btn btn-xl text-uppercase btn-degradado-2"
                      to={e.ruta}
                    >
                      <span className="align-self-center">{e.titulo}</span>
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </Link>
                  </div>
                );
              } else {
                return (
                  <div className="col mb-4">
                    <Link className="btn btn-xl text-uppercase" to={e.ruta}>
                      <span className="align-self-center">{e.titulo}</span>
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </Link>
                  </div>
                );
              }
            })
          )
        ) : (
          <></>
        )}
      </div>

      <div
        id="carousel-mi-tiempo-mobile"
        className="carousel carousel-dark slide carousel-control-inner mt-4 mb-4 d-lg-none"
        data-bs-ride="carousel"
        data-bs-interval="false"
      >
        <div className="carousel-indicators">
          {rutas ? (
            Children.toArray(
              rutas.map((e, index) => {
                if (props.tituloSelect === e.titulo) {
                  return (
                    <button
                    type="button"
                    data-bs-target="#carousel-mi-tiempo-mobile"
                    data-bs-slide-to={"" + index}
                    className="active"
                    aria-current="true"
                    aria-label={"Slide " + index}
                  ></button>
                    
                  );
                } else {
                  return (
                    <button
                    type="button"
                    data-bs-target="#carousel-mi-tiempo-mobile"
                    data-bs-slide-to={"" + index}
                    aria-label={"Slide " + index}
                  ></button>
                  );
                }
              })
            )
          ) : (
            <></>
          )}
        </div>
        <div className="carousel-inner">
          {rutas ? (
            Children.toArray(
              rutas.map((e) => {
                if (props.tituloSelect === e.titulo) {
                  return (
                    <div
                      className="carousel-item p-2 active">
                      <Link
                        className="btn btn-xl text-uppercase btn-degradado-2"
                        to={e.ruta}
                      >
                        <span className="align-self-center">{e.titulo}</span>
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className="carousel-item p-2 ">
                      <Link className="btn btn-xl text-uppercase " to={e.ruta}>
                        <span className="align-self-center">{e.titulo}</span>
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </div>
                  );
                }
              })
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default CarrouselTiempo;
