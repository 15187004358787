import React, { useEffect, useRef, useState } from 'react'
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import { MobileSettings } from 'lightgallery/lg-settings';

interface Props {
  imgProp: string,
  imgIndex: number,
  imgClass?: string,
  imgStyle?: string
}

const ImgViewer = ({imgProp, imgClass, imgIndex, imgStyle}: Props) => {
  const gallery = useRef<HTMLSpanElement>(null)

  const onInit = () => {
    const zoomButton = document.getElementsByClassName('lg-zoom-in')[imgIndex]
    const imgWrapp = document.getElementsByClassName('lg-img-wrap')[0]
    const img = document.getElementsByClassName('lg-image')[0]
    zoomButton.addEventListener('click', () => {
      setTimeout(() => {
        if (!imgWrapp.hasAttribute('style')) {
          if(img.classList.contains('zoom-in')) {
            zoomButton.classList.remove('lg-zoom-out')
            zoomButton.classList.add('lg-zoom-in')
            img.classList.remove('zoom-in')
          } else {
            zoomButton.classList.remove('lg-zoom-in')
            zoomButton.classList.add('lg-zoom-out')
            img?.classList.add('zoom-in')
          }
        }
      }, 100)
    })
  }

  useEffect(() => {
    if(imgStyle && gallery.current) {
      const img = gallery.current.getElementsByTagName('img')[0]
      img.setAttribute('style', imgStyle)
    }
  }, [imgStyle])


  return (
    <span ref={gallery}>
      <LightGallery
        onAfterOpen={onInit}
        speed={500}
        plugins={[lgThumbnail, lgZoom]}
        zoom={true}
        closable={true}
        mobileSettings={{showCloseIcon: true, download: true} as MobileSettings}
      >
        <img src={imgProp} className={imgClass}/>
      </LightGallery>
    </span>
  )
}

export default ImgViewer