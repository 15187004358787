import { RemoteData } from "@devexperts/remote-data-ts";
import { Children, FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import * as RD from "@devexperts/remote-data-ts";
import { HttpError } from "../../../../../../services/apiServices/interfaces";
import { bodyPostCategoryBenefit, CategoryProps, ModalAsignCatProps, PostBenefit, SearchProps } from "../../../../utilities/interfaces";
import SearchBar from "./searchBar/searchBar";
import { crudCategoryBenefit } from "../../../../utilities/apiServices/apiRequest";
import { httpClientEnv } from "../../../../../../services/apiServices/httpClient";

const ModalAsignCategory:FC<ModalAsignCatProps> = ({
    editProps, 
    setReload, 
    setDataModal,  
    isEditCategory, 
    countryFilters, 
    setEditProps, 
    defaultPropsEdit, 
    refLoadClose, 
    refStatus,
    pais,
    refCloseStatus
}) => {
    const { register, handleSubmit, getValues, control, setError, setValue, formState: {errors, isDirty, isValid}, reset } = useForm<CategoryProps>({
        mode: "onSubmit" 
    });

    const [remotePostCatBenefit, setRemotePostCatBenefit] = useState<RemoteData<HttpError, PostBenefit>>(RD.initial);
    const [searchResults, setSearchResults] = useState<SearchProps[]>([]);
    const [wordEntered, setWordEntered] = useState<string>("");
    const [disabledSubmit, setDisabledSubmit] = useState(false);

    let iconArray = [
        {
            label: "salud",
            value: "fa-heartbeat"
        },
        {
            label: "hospital",
            value: "fa-hospital-o"
        },
        {
            label: "medication",
            value: "fa-plus-square"
        },
        {
            label: "family",
            value: "fa-users"
        }, 
        {
            label: "check",
            value: "fa-check-square-o"
        },
        {
            label: "educacion",
            value: "fa-graduation-cap"
        }
    ]

    const onSubmit = () => {
        if (!wordEntered.startsWith('fa-') || wordEntered === 'fa-') {
            setError("icon", {
                type: 'required',
                message: 'Por favor, ingrese un ícono válido.'
            });
        } else {
            delete errors['icon'];
            PostCategoryBenefit();
        }
    }

    const PostCategoryBenefit = async () => {
        let modal = document.getElementById('asignacion');
        modal && modal.click();
        setRemotePostCatBenefit(RD.pending)
        let body: bodyPostCategoryBenefit = {
            country_id: getValues('country_id'),
            icon: wordEntered.trim(),
            title: getValues('title')
        }

        if (isEditCategory) {
            body.id = editProps.id
        }

        let type = isEditCategory ? 'PUT' : 'POST';
        RTE.run(await crudCategoryBenefit(body, type), httpClientEnv)
        .then(E.fold(e => { return setRemotePostCatBenefit(RD.failure(e)) }, a => {
            let data = RD.success(a)
            if (data._tag === 'RemoteSuccess' && data.value.valid) {
                refLoadClose && refLoadClose.current && refLoadClose.current.click();
                refStatus && refStatus.current && refStatus.current.click();
                setDataModal({
                    msg:'Categoría ingresada correctamente.',
                    icon: 'fa-check-circle-o',
                    callBack: () => {
                        setReload(true);
                        reset();
                        refCloseStatus && refCloseStatus.current && refCloseStatus.current.click();
                    }
                })                
                return setRemotePostCatBenefit(RD.success(a))
            } else {
                return setRemotePostCatBenefit(RD.failure({ tag: 'httpRequestError', error: 'Valid PostCategoryBenefit' }))
            } 
        }))
    };

    useEffect(()=>{
        if (isEditCategory) {
            setValue('country_id',editProps.country_id)
            setValue('title',editProps.title)
            setWordEntered(editProps.icon)
            setValue('icon', editProps.icon)
        } else {
            setValue('country_id',pais)
            setWordEntered("");
        }
    },[editProps, isEditCategory])

    useEffect(()=> {
        if (!isDirty || !isValid) {
            setDisabledSubmit(true);
        } else {
            setDisabledSubmit(false);
        }
    },[isDirty, isValid])

    useEffect(()=>{
        if (remotePostCatBenefit._tag === 'RemoteFailure') {
            refLoadClose && refLoadClose.current && refLoadClose.current.click();
            refStatus && refStatus.current && refStatus.current.click();
            setDataModal({
                msg:'Ha ocurrido un error al guardar información Por favor, intente más tarde.',
                icon: 'fa-exclamation-triangle',
                callBack: () => {
                    refCloseStatus && refCloseStatus.current && refCloseStatus.current.click();
                }
            })  
        }
    },[remotePostCatBenefit])

    return(
    <>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal fade" id="asignacion" aria-labelledby="asignacion" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header border-0 pb-0">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{
                                    setEditProps(defaultPropsEdit);
                                    reset();
                                    setWordEntered('');
                                    setSearchResults([]);
                                }}></button>
                            </div>
                            <div className="modal-body py-4 pb-0">
                                <h2 className="h5">{isEditCategory ? 'Editar': 'Nueva'} categoría</h2>
                                <div className="mb-3">
                                    <label className="form-label">País *</label>
                                    <div className="input-group">
                                    <Controller
                                        name="country_id"
                                        control={control}
                                        defaultValue=""
                                        rules={{required: 'Seleccione un país.'}}
                                        render={({ field }) => (
                                        <>
                                            {countryFilters.length > 0 ? (
                                                <select className="form-select" {...field}>
                                                    <option value="" disabled> Seleccione </option>
                                                    {Children.toArray(countryFilters.map((country) => (
                                                        <option key={country.id} value={country.id} selected={pais === country.id}>
                                                            {country.label}
                                                        </option>
                                                    )))}
                                                </select>
                                            ) : (
                                                <select className="form-select" disabled>
                                                    <option value="" disabled selected> Seleccione </option>
                                                </select>
                                            )}
                                        </>
                                        )}
                                    />
                                    {errors["country_id"] !== undefined && <div style={{ display: "block" }} className="invalid-feedback">{errors.country_id.message}</div>}
                                    </div>
                                </div>
                                
                                <div className="mb-3">
                                    <label className="form-label">Título *</label>
                                    <input className="form-control" type="text" placeholder="Ingresa Nombre"
                                    {
                                        ...register('title',{
                                            required: 'Ingresar título.',
                                            maxLength: {
                                                value: 20,
                                                message: "Límite máximo de 20 caracteres"
                                            }
                                        })
                                    }/>
                                    {errors["title"] !== undefined && <div style={{ display: "block" }} className="invalid-feedback mb-2">{errors.title.message}</div>}
                                </div>
                                
                                <div className="mb-4">
                                    <label className="form-label">Ícono *</label>
                                    <div className="input-group input-group-white">
                                        <SearchBar 
                                            register={register} 
                                            setSearchResults={setSearchResults}
                                            wordEntered={wordEntered}
                                            setWordEntered={setWordEntered}
                                            iconArray={iconArray}
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                                {errors["icon"] !== undefined && <div style={{ display: "block" }} className="invalid-feedback">{errors.icon.message}</div>}

                                <div className="mb-3">
                                <div className="d-flex flex-wrap justify-content-center align-items-center">
                                        {searchResults.length > 0 ? 
                                        Children.toArray(searchResults.map((result) => {
                                            return (
                                                <>
                                                    <button type="button" className="btn btn-shadow me-2 btn-benefit mt-1" onClick={()=>{ setWordEntered(result.value); delete errors["icon"]; setValue('icon', result.value)}}>
                                                          <span className={`material-symbols-outlined fa ${result.value} fa-fw fa-lg`} >
                                                          </span>
                                                    </button>
                                                </>
                                            )
                                        }))
                                        : 
                                            <>
                                                {wordEntered === '' ? iconArray.map((icon, i ) => {
                                                    if(i < 6){
                                                        return (
                                                            <button type="button" className="btn btn-shadow me-2 btn-benefit mt-1" onClick={()=>{ setWordEntered(icon.value); delete errors["icon"]; setValue('icon', icon.value)}}>
                                                                <span className={`material-symbols-outlined fa ${icon.value} fa-fw fa-lg`} >
                                                                </span>
                                                            </button>
                                                        )
                                                    }
                                                }): wordEntered !== '' && searchResults.length === 0 && 
                                                <>
                                                    <div className="text-center">
                                                        <p>Vista previa</p>
                                                        <button type="button" className="btn btn-shadow me-2 btn-benefit" onClick={()=>{ setWordEntered(wordEntered); delete errors["icon"]; setValue('icon', wordEntered)}}>
                                                            <span className={`material-symbols-outlined fa ${wordEntered} fa-fw fa-lg`} >
                                                            </span>
                                                        </button>
                                                    </div>
                                                </>
                                                }
                                            </>
                                        }
                                        </div> 
                                </div>

                                <div className="text-center">
                                    <a className="a d-block d-md-inline-block text-center my-2 mx-5 "  href="https://fontawesome.com/v4/icons/" target="_blank">Ver más íconos <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></a>
                                </div>
                            </div>
                            
                            <div className="modal-footer border-0 pt-0 pb-5 pt-4 justify-content-center gap-2">
                                <button type="submit" disabled={Object.keys(errors).length > 0 || disabledSubmit} className="btn btn-degradado order-md-last col-12 col-md-auto my-auto px-5">Guardar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                <button type="button" className="btn btn-primary col-12 col-md-auto my-auto px-5" data-bs-dismiss="modal" onClick={()=>{
                                    setEditProps(defaultPropsEdit)
                                    setWordEntered('');
                                    setSearchResults([]);
                                    reset();
                                }}>Cancelar</button>
                            </div>
                        </div>
                    </div>
            </div>
        </form>
    </>
)

}

export default ModalAsignCategory;