import React from "react";

const CertificateModal = () => {
    return (
        <>
            <div className="col-auto">
                <a
                    href="/ficha-ingreso"
                    data-bs-toggle="modal"
                    data-bs-target="#certificado-afiliacion"
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                >
                    <i className="fa fa-question-circle-o fa-fw mt-2 fa-lg color-primary" aria-hidden="true" />
                </a>
            </div>
            <div className="modal fade" id="certificado-afiliacion" tabIndex={-1} aria-labelledby="certificado-afiliacion" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h2 className="h5 mb-4">{"Certificado de Afiliación"}</h2>
                            <p>
                                {"AFP S.A., certifica que el Señor(a) JUAN JOSE PEREZ PEREZ, RUT 11.111.111-2, "}
                                <strong>{"ingresó al Sistema Previsional regido por el \
                                    decreto de ley N° 3.500 y sus modificaciones, \
                                    con fecha "}</strong>
                                <strong style={{ color: "red" }}>{"01/05/2005"}</strong>
                                {", incorporándose a esta Administradora de Fondos \
                                de pensiones a contar del día 01/11/2019, y que a \
                                la fecha mantiene su condición de afiliado a ella."}
                            </p>
                            <p className="text-justify">{"Este certificado ha sido otorgado a petición del \
                                interesado(a), para los fines que estie conveniente \
                                y sin ulterior responsabilidad para AFP. S.A."}</p>
                        </div>
                        <div className="modal-footer border-0 pt-0 pb-4 justify-content-center">
                            <button type="button" className="btn btn-degradado col col-md-auto" data-bs-dismiss="modal">
                                {"Aceptar "}
                                <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default CertificateModal;