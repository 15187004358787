import Swal from "sweetalert2";

export const fileModal = (modalView,file,setShowFile) => {
    if (file.FileType == 'application/pdf') {
        Swal.fire({
            title: file.FileName,
            allowOutsideClick: false,
            width: 500,
            html: `
                <object data="${modalView}" type="application/pdf" style="text-align: left; width: 100%; height: 600px;">
                    <p style="margin-bottom: 0; text-align: center; padding: 10px">
                        El navegador no puede previsualizar el pdf. <br/>
                        <a href="${modalView}" download="${file.FileName}">
                            Descargar archivo
                        </a>
                    </p>
                </object>
                `,
            confirmButtonColor: '#0169b5',
        }).then((result) => {
            if (result.isConfirmed) {
                setShowFile(false);
            }
        })
    } else {
        Swal.fire({
            width: '40%',
            allowOutsideClick: false,
            heightAuto: false,
            imageWidth: '90%',
            imageUrl: `${modalView}`,
            confirmButtonColor: '#0169b5'
        }).then((result) => {
            if (result.isConfirmed) {
                setShowFile(false);
            }
        })
    }
}
