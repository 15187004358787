import React, { useState } from "react";
import { SwatchesPicker, SketchPicker } from "react-color";

const ColorPicker = ({ color, setColor }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    setColor({
      r: color?.rgb?.r,
      g: color?.rgb?.g,
      b: color?.rgb?.b,
      a: color?.rgb?.a,
      hex: color?.hex,
      wasModified: true,
    });
  };

  return (
    <div>
      <div
        style={{
          padding: "5px",
          background: "#B3B3B3",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <div
          style={{
            width: "36px",
            height: "14px",
            borderRadius: "2px",
            background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
          }}
        ></div>
      </div>
      {displayColorPicker ? (
        <div style={{ position: "absolute", zIndex: "2" }}>
          <div
            style={{
              position: "fixed",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px",
            }}
            onClick={handleClose}
          />
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
