import axios from 'axios';
import Auth from '@aws-amplify/auth';

// eslint-disable-next-line no-undef
const baseUrl = process.env.REACT_APP_BACKEND_URL_GLOBAL;
// const baseUrl = "http://localhost:5000";

const getHeader = () => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(async (auth) => {
        const header = {
          "Content-Type": "application/json",
          accesstoken: auth.getAccessToken().getJwtToken(),
        };
        resolve(header);
      })
      .catch(function (error) {
        return reject(error);
      });
  });
};


const OrganigramaBusquedaService = {

    getSearch: async (name, country) => {
        try {
          const header = await getHeader(); 
          const data = await axios.get(`${baseUrl}/api/organigrama/search?q=${name}&country=${country}`, {
            headers: header,
          });
          return data;
        } catch (error) {
          error?.response?.data;
        }
      }
    
}

export default OrganigramaBusquedaService;