import { useState, useEffect, useLayoutEffect } from "react";
import { Base64 } from "js-base64";
import React from "react";
// import home from "../resources/images/noticia-home.jpg";
import { formatDate } from "../../services/utiles";
import { Link, useLocation, useParams } from "react-router-dom";
// import useOnlineState from "./elements/online-hook";
import { useForm } from "react-hook-form";
// import SinConexion from "./elements/modal-sin-conexion";
// import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, FacebookMessengerShareButton, FacebookMessengerIcon } from "react-share";
import newsService from '../../services/news/news-services';

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        const updateSize = () => {
            setSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
};

const NewsDetail = () => {
    const [data, setData] = useState(null);
    const [width,] = useWindowSize();
    const params = useParams();

    useEffect(() => {
        newsService.getNew(setData, params.id);
    }, []);


    const base64ToArrayBuffer = (archivo) => {
        let byteChar = atob(archivo);
        let byteArray = new Uint8Array(new ArrayBuffer(byteChar.length));
        for (let i = 0; i < byteChar.length; i++) {
            byteArray[i] = byteChar.charCodeAt(i);
        }
        return byteArray;
    };

    const saveByteArrayPDF = (reportName, byte) => {
        let blob = new Blob([byte], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        let fileName = reportName;
        link.download = fileName;
        link.dispatchEvent(new MouseEvent("click"));

    };

    const handleSub = (e, descripcion, base64) => {
        e.preventDefault();
        var ascii = base64ToArrayBuffer(base64.replaceAll("data:application/pdf;base64,", ""));
        saveByteArrayPDF(descripcion.replaceAll(" ", "_"), ascii);
    };



    return (
        <>
            {data ? (
                <div className="noticias">
                    <span className="h1 d-block">Noticias</span>
                    <div className="col-12 position-relative mb-5">

                        {width < 768 ? (
                            <img
                                src={URL.createObjectURL(new Blob([base64ToArrayBuffer(data.banner_img_url.split(";base64,")[1])]))}
                                className="d-block w-100 rounded-4"
                            />
                        ) : (
                            <img
                                src={URL.createObjectURL(new Blob([base64ToArrayBuffer(data.feature_img_url.split(";base64,")[1])]))}
                                className="d-block w-100 rounded-4"
                            />
                        )}
                    </div>
                    <div className="box p-4">
                        <h2>{data.title}</h2>
                        <article dangerouslySetInnerHTML={{ __html: data.content }} />

                        {Object.keys(data).includes("file") ? (
                            data.file !== null && data.file !== "" ? (
                                <a
                                    onClick={(e) => {
                                        handleSub(e, data.title, data.file);
                                    }}
                                    className="text-decoration-none"

                                >
                                    <span style={{ cursor: 'pointer' }} className="text-uppercase fw-bolder">{"Para mas información click aquí "}</span>
                                    <i className="fa fa-file-pdf-o color-primary ms-2 fa-lg" aria-hidden="true" />
                                </a>
                            ) : null
                        ) : null}
                        <ul className="list-share list-unstyled color-primary text-uppercase">
                            <li className="me-4 mb-2 mb-md-0">{"Fecha: " + formatDate(data.date_from)}</li>

                        </ul>

                    </div>
                    <div className="mt-4 d-grid d-md-inline-block">
                        <Link
                            className="btn btn-degradado"
                            to="/noticias"
                        >
                            <i className="fa fa-chevron-left" aria-hidden="true"></i> Volver
                        </Link>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default NewsDetail;
