import React, { FC, useEffect } from "react";
import { SectionProps, Cell } from "../utilities/interfaces";

const Section: FC<SectionProps> = ({ title, content, type,assets,assets_total,discounts,discounts_total,net_total }) => {
  
    return (
        <>
            <div className="box mb-4">
                <h2 className="h4 tit-section">{title}</h2>
                {
                    type === "list" &&
                    <div className="data-list-box">
                        <ul className="list-column-2 list-unstyled">
                            {
                                content && content.map((item : Cell, index) => {
                                    return <li>
                                        <span className="data-list-box__title col-12 col-md-6 ms-2">{item.title}:</span>
                                        <span className="data-list-box__text col-2 ms-2">{item.value}</span>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                }
                {
                    type === "table" &&
                    <>
                        <table className="table table-even table-section table-borderless align-middle d-none d-md-table">
                            <thead>
                                <tr>{content && content.map((item) => <th>{item.title}</th>)}</tr>
                            </thead>
                            <tbody>
                                <tr>
                                {content && content.map((item) => {
                                    return(
                                        <>
                                        <td>{item.value}</td> 
                                        </>
                                    )
                                })}
                                </tr>
                            </tbody>
                        </table>
                        <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
                            {
                                content && content.map((item, index) => <div className="accordion-item">
                                <h2 className="accordion-header" id="accordion">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#accordion_"+index} aria-expanded="true" aria-controls={"accordion_"+index}>
                                        <strong>{item.title}</strong>
                                    </button>
                                </h2>
                                <div id={"accordion_"+index} className="accordion-collapse collapse show" aria-labelledby="accordion">
                                    <div className="accordion-body p-0">
                                        <div className="data-list-box">
                                            <ul className="data-list-section--item list-unstyled">
                                            <li>
                                                <span className="data-list-box__text col-2 ms-2">{item.value}</span>
                                            </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                )
                            }
                        </div>
                    </>
                }

                {
                    type === "salary" &&
                    <>
                        <div className="row">
                            <div className="data-list-box" style={{width:'50%'}}>
                                <ul className="list-row-2 list-unstyled">
                                    {assets && assets.map((item : Cell, index) => {
                                            if(!item.hidden){
                                                return (
                                                    <li>
                                                        <span className="data-list-box__title col-12 col-md-6 ms-2">{item.title}:</span>
                                                        <span className="data-list-box__text col-2 ms-2">{item.value}</span>
                                                    </li>
                                                )
                                            }
                                        })
                                    }
                                
                                    
                                </ul>
                            </div>
                            <div className="data-list-box" style={{width:'50%'}}>
                                <ul className="list-row-2 list-unstyled">
                                    {discounts && discounts.map((item : Cell, index) => {
                                            if(!item.hidden){
                                                return (
                                                    <li>
                                                        <span className="data-list-box__title col-12 col-md-6 ms-2">{item.title}:</span>
                                                        <span className="data-list-box__text col-2 ms-2">{item.value}</span>
                                                    </li>
                                                )
                                            }
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="data-list-box" style={{width:'50%'}}>
                                {assets_total &&
                                    <ul className="list-row-2 list-unstyled">
                                        <li className="btn-primary">
                                            <div className="ms-2">
                                                <span className="color-white col-6 col-md-6" >{assets_total.title}: </span>
                                                <span className="color-white col-6" >{' '+assets_total.value}</span>
                                        </div>
                                        </li> 
                                    </ul>
                                }
                            </div>
                            <div className="data-list-box" style={{width:'50%'}}>
                                <ul className="list-row-2 list-unstyled">
                                    {discounts_total &&  
                                        <li className="btn-primary">
                                            <div className="ms-2">
                                                <span className="color-white col-6 col-md-6" >{discounts_total.title}: </span>
                                                <span className="color-white col-6">{discounts_total.value}</span>
                                            </div>
                                        </li>
                                    }
                                    {net_total &&
                                        <li className="btn-primary">
                                        <div className="ms-2">
                                            <span className="color-white col-6 col-md-6" >{net_total.title}: </span>
                                            <span className="color-white col-6" >{' '+net_total.value}</span>
                                        </div>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
};

export default Section;