import React, { useEffect, useState } from "react";
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { Modal } from 'reactstrap';
import FirstLoginService from "../../services/firstlogin-service";

const ModalTermsAndConditions = (props) => {
    const { openModal, closeModal } = props;
    const [conditionsHtml, setConditionsHtml] = useState();

    const getRawHTML = async () => {
        const response = await FirstLoginService.getTerminosYCondiciones();
        setConditionsHtml(response.termsconditions);
    }

    useEffect(() => {
        getRawHTML();
    }, []);

    return (
        <Modal modalClassName='modal-properties' style={{ padding: 24 }} size="lg" centered isOpen={openModal}>
            <div className="modal-container">
                <div className="modal-data-title tit">
                    Terminos y Condiciones
                    <AiOutlineCloseCircle title="Cerrar" type="button" className="dismiss-data-modal" onClick={() => { closeModal() }}></AiOutlineCloseCircle>
                </div>
                <div
                    className="modal-data-body paddingModal"
                    dangerouslySetInnerHTML={{ __html: conditionsHtml }}
                />
                <div className="text-center mb-sm-4">
                    <button className="btn btn-primary" onClick={() => closeModal()}>OK</button>
                </div>
            </div>
        </Modal>
    );
};

export default ModalTermsAndConditions;