import React, { useEffect, useRef, useState } from 'react'
import styles from '../styles/errorStyle.module.css'
import { ErrorProps } from '../utils/interfaces'
import { errorsMessages, errorTypes } from '../../../utilities/apiServices/apiErrors'
import NotFoundError from './variants/notFound'
import ForbidenError from './variants/forbiden'
import ServerError from './variants/serverError'
import TimeoutError from './variants/timeout'

const ErrorScreen = (props: ErrorProps) => {
  const [errorType, setErrorType] = useState('')

  useEffect(() => {
    if(props.errorStatus) {
      const errorCause = errorsMessages[props.errorStatus as keyof typeof errorsMessages]
      setErrorType(errorCause)
    }
  }, [props.errorStatus])

  { switch(errorType) {
    case errorTypes[0]: return (<p>0</p>)
    case errorTypes[1]: return (<NotFoundError />)
    case errorTypes[2]: return (<ForbidenError />)
    case errorTypes[3]: return (<ServerError />)
    case errorTypes[4]: return (<TimeoutError />)
    default: return (<ServerError />)
  }}

}

export default ErrorScreen;