import Auth from "@aws-amplify/auth";
import { decrypt } from "./utiles";
import { urls, urlsAws } from "../resources/foo/api-endpoints";
const decryptAll = (dataResumenEmpl, setData, isArray, msj, msjf) => {
    let element,
        subElement,
        newObj = dataResumenEmpl;
    for (element in newObj) {
        if (typeof newObj[element] === "object") {
            if (Array.isArray(newObj[element])) {
                for (let i = 0; i < newObj[element].length; i++) {
                    for (subElement in newObj[element][i]) {
                        if (typeof newObj[element][i][subElement] === "string" && newObj[element][i][subElement] !== "") {
                            newObj[element][i][subElement] = decrypt(newObj[element][i][subElement]);
                        }
                    }
                }
            } else {
                for (subElement in newObj[element]) {
                    if (typeof newObj[element][subElement] === "string" && newObj[element][subElement] !== "") {
                        newObj[element][subElement] = decrypt(newObj[element][subElement]);
                    }
                }
            }
        } else if (typeof newObj[element] === "string") {
        }
    }
    if (!isArray) {
        setData(newObj);
    } else {
        if (newObj != undefined && newObj.CN_PERS_LIST && newObj.CN_PERS_LIST.nombre) {
            setData({ name: newObj.CN_PERS_LIST.nombre });
            msj(true);
        } else {
            setData(false);
            if (msjf === 2) {
                msj("2");
            } else {
                msj(2);
            }
        }
    }
};

const miEquipoService = {
    getMiEquipo: async (emplid, setData, inactives = false) => {
        const queryParams = inactives ? '?inactive=true' : '?inactive=false';
        const cacheResponse = await caches.match(urlsAws.getMiEquipo + "?emplid=" + emplid + queryParams)
        .then((response) => response.json())
        .then((data) => {
            if (data && data.response.CN_JEFESUB_LIST) {
                return data;
            }
            return false;
        })
        .catch(() => false);
    if (cacheResponse) {
        setData(cacheResponse.response.CN_JEFESUB_LIST);
    } else {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.getMiEquipo + queryParams , requestOptions)
                .then((response) => response.json())
                .then((data) => data)
                .then((data) => {
                    if (data.response.CN_JEFESUB_LIST === undefined) data = false;
                    if (data) {
                        caches.open("api-cache").then((cache) => {
                            if (Array.isArray(data.response.CN_JEFESUB_LIST)) {
                                data.response.CN_JEFESUB_LIST = data.response.CN_JEFESUB_LIST.filter((item) => item.EMPLID !== "");
                                cache.put(urlsAws.getMiEquipo + "?emplid=" + emplid + queryParams, new Response(JSON.stringify(data)));
                                setData(data.response.CN_JEFESUB_LIST);
                            } else {
                                if (data.response.CN_JEFESUB_LIST !== undefined) {
                                    data.response.CN_JEFESUB_LIST = [data.response.CN_JEFESUB_LIST];
                                    data.response.CN_JEFESUB_LIST = data.response.CN_JEFESUB_LIST.filter((item) => item.EMPLID !== "");
                                    setData(data.response.CN_JEFESUB_LIST);
                                    cache.put(urlsAws.getMiEquipo + "?emplid=" + emplid + queryParams, new Response(JSON.stringify(data)));
                                } else {
                                    setData(false);
                                }
                            }
                        });
                        window.sessionStorage.setItem("miEquipo", JSON.stringify(data.response.CN_JEFESUB_LIST));
                    } else {
                        setData("error");
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    caches.match(urlsAws.getMiEquipo + "?emplid=" + emplid).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                if (Array.isArray(data)) setData(data);
                                else setData(data.response.CN_JEFESUB_LIST);
                            });
                        }else{
                            setData("error");
                        }
                    });
                });
        }).catch((err) => console.log(err));
    }
        
    },
    getColaborador: (emplid, setData, msj, msjf) => {
        Auth.currentSession().then((auth) => {
            let isArray = Array.isArray(emplid);
            if (isArray) {
                emplid = emplid[0];
            }
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            const name = localStorage.getItem("name");
            const emplidd = localStorage.getItem("emplid");
            if (name == null || !isArray || emplid !== emplidd) {
                fetch(urlsAws.getColaborador + "?rut=" + emplid, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.message === "API rate limit exceeded") data = false;
                        if (data) {
                            if (data.response !== undefined) {
                                caches.open("api-cache").then((cache) => {
                                    if (data.response.CN_EVALDES_LIST !== undefined && !Array.isArray(data.response.CN_EVALDES_LIST)) {
                                        data.response.CN_EVALDES_LIST = [data.response.CN_EVALDES_LIST];
                                    }
                                    if (data.response.CN_TR_LIST !== undefined && !Array.isArray(data.response.CN_TR_LIST)) {
                                        data.response.CN_TR_LIST = [data.response.CN_TR_LIST];
                                    }
                                    if (data.response.CN_HISTPOS_LIST !== undefined && !Array.isArray(data.response.CN_HISTPOS_LIST)) {
                                        data.response.CN_HISTPOS_LIST = [data.response.CN_HISTPOS_LIST];
                                    }
                                    if (data.response.CN_HISTUBI_LIST !== undefined && !Array.isArray(data.response.CN_HISTUBI_LIST)) {
                                        data.response.CN_HISTUBI_LIST = [data.response.CN_HISTUBI_LIST];
                                    }
                                    if (data.response.CN_CURNORM_LIST !== undefined && !Array.isArray(data.response.CN_CURNORM_LIST)) {
                                        data.response.CN_CURNORM_LIST = [data.response.CN_CURNORM_LIST];
                                    }
                                    if (data.response.CN_CLIMA_LIST !== undefined && !Array.isArray(data.response.CN_CLIMA_LIST)) {
                                        data.response.CN_CLIMA_LIST = [data.response.CN_CLIMA_LIST];
                                    }
                                    if (data.response.CN_RENTA_LIST !== undefined && !Array.isArray(data.response.CN_RENTA_LIST)) {
                                        data.response.CN_RENTA_LIST = [data.response.CN_RENTA_LIST];
                                    }
                                    if (data.response.CN_REM_LIST !== undefined && !Array.isArray(data.response.CN_REM_LIST)) {
                                        data.response.CN_REM_LIST = [data.response.CN_REM_LIST];
                                        data.response.CN_REM_LIST.forEach((value) => {
                                            if (!Array.isArray(value.CN_HABER_LIST)) {
                                                value.CN_HABER_LIST = [value.CN_HABER_LIST];
                                            }
                                        });
                                        data.response.CN_REM_LIST.forEach((rem) => {
                                            rem.CN_HABER_LIST.forEach((item) => {
                                                if (item.decrypted === undefined || item.decrypted !== "ok") {
                                                    item.haber = decrypt(item.haber);
                                                    item.valor = decrypt(item.valor);
                                                    item.decrypted = "ok";
                                                }
                                            });
                                        });
                                    } else {
                                        data.response.CN_REM_LIST = [];
                                    }
                                    if (data.response.CN_WARNING_LIST !== undefined && !Array.isArray(data.response.CN_WARNING_LIST)) {
                                        data.response.CN_WARNING_LIST = [data.response.CN_WARNING_LIST];
                                    }
                                    cache.put(urlsAws.getColaborador + "?rut=" + emplid, new Response(JSON.stringify(data)));
                                    if (isArray) {
                                        decryptAll(data.response, setData, isArray, msj, msjf);
                                    } else {
                                        decryptAll(data.response, setData, isArray);
                                    }
                                });
                            } else {
                                throw new Error("undefined response");
                            }
                        } else {
                            setData("error");
                            if (isArray) {
                                msj(2);
                            }
                        }
                    })
                    .catch((error) => {
                        caches.match(urlsAws.getColaborador + "?rut=" + emplid).then((response) => {
                            if (response) {
                                response.json().then((data) => {
                                    decryptAll(data.response, setData);
                                });
                            } else {
                                setData(false);
                                if (isArray) {
                                    msj(2);
                                }
                            }
                        });
                        console.log("error", error);
                    });
            } else {
                setData({ "name": decrypt(name), "emplid": emplidd })
                msj(true);
            }
        }).catch((err) => console.log(err));
    },
    getCartola: (setData, emplid, setEstadoCarga) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.getCartola + "?rut=" + emplid, requestOptions)
                .then((response) => response.text())
                .then((result) => result.replaceAll(",}", "}"))
                .then((result) => result.replaceAll("(,", "}"))
                .then((result) => JSON.parse(result))
                .then((data) => {
                    if (data.message === "API rate limit exceeded") data = false;
                    if (data) {
                        if (data.response.detalle_vacacion !== undefined) {
                            if (!Array.isArray(data.response.detalle_vacacion)) {
                                data.response.detalle_vacacion = [data.response.detalle_vacacion];
                            }
                            data.response.detalle_vacacion.forEach((item) => {
                                if (item.decrypted === undefined || item.decrypted !== "ok") {
                                    item.periodo = item.periodo !== undefined ? decrypt(item.periodo).slice(0, 4) : "";
                                    item.nombre = decrypt(item.nombre);
                                    item.dias_arrastre = decrypt(item.dias_arrastre);
                                    item.dias_normales = decrypt(item.dias_normales);
                                    item.dias_progresivos = decrypt(item.dias_progresivos);
                                    item.dys_take = decrypt(item.dys_take);
                                    item.ajuste_vac = decrypt(item.ajuste_vac);
                                    item.balance = decrypt(item.balance);
                                    item.decrypted = "ok";
                                }
                            });
                            caches.open("api-cache").then((cache) => {
                                cache.put(urlsAws.getCartola + "?rut=" + emplid, new Response(JSON.stringify(data.response)));
                                setData(data.response);
                            });
                        } else {
                            setEstadoCarga(true);
                            setData(false);
                        }
                    } else {
                        setData("error");
                    }
                })
                .catch((error) => {
                    caches.match(urlsAws.getCartola + "?rut=" + emplid).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                setData(data);
                            });
                        } else {
                            setEstadoCarga(true);
                            setData(false);
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },
    getDiasTomados: (setData, emplid, setEstadoCarga) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.getDetalleVacaciones + "?rut=" + emplid + "&rcd=0", requestOptions)
                .then((response) => response.text())
                .then((result) => result.replaceAll(",}", "}"))
                .then((result) => JSON.parse(result))
                .then((data) => {
                    if (data.message === "API rate limit exceeded") data = false;
                    if (data) {
                        if (data.response.vacaciones !== undefined) {
                            if (!Array.isArray(data.response.vacaciones)) {
                                data.response.vacaciones = [data.response.vacaciones];
                            }
                            data.response.vacaciones.forEach((item) => {
                                if (item.decrypted === undefined || item.decrypted !== "ok") {
                                    item.year = decrypt(item.year);
                                    item.fecha_inicio = decrypt(item.fecha_inicio);
                                    item.fecha_retorno = decrypt(item.fecha_retorno);
                                    item.duracion_dias = decrypt(item.duracion_dias);
                                    item.approved_flag = decrypt(item.approved_flag);
                                    item.emplid = decrypt(item.emplid);
                                    item.decrypted = "ok";
                                }
                            });
                            const vacacionesInvertidas = data.response.vacaciones;
                            /*const vacacionesInvertidas = data.response.vacaciones.map((item,index) => {
                                        const largo = data.response.vacaciones.length-1;
                                        return {
                                            year: data.response.vacaciones[largo-index].year,
                                            fecha_inicio: data.response.vacaciones[largo-index].fecha_inicio,
                                            fecha_retorno: data.response.vacaciones[largo-index].fecha_retorno,
                                            duracion_dias: data.response.vacaciones[largo-index].duracion_dias,
                                            approved_flag: data.response.vacaciones[largo-index].approved_flag,
                                            emplid: data.response.vacaciones[largo-index].emplid,
                                            decrypted: "ok",
                                        };
                                    });*/
                            setData(vacacionesInvertidas);
                        } else {
                            setEstadoCarga(true);
                            setData(false);
                        }
                    } else {
                        setData("error");
                    }
                })
                .catch((error) => {
                    setEstadoCarga(true);
                    setData(false);
                });
        }).catch((err) => console.log(err));
    },
    getPhotos: (setData, emplidArray) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: JSON.stringify({ request: { emplids: emplidArray } }),
            };
            fetch(urlsAws.getPhotosEquipo , requestOptions)
                .then((response) => response.json())
                .then((data) => data)
                .then((data) => {
                    if (data.message === "API rate limit exceeded" || Object.keys(data.response).length === 0) data = false;
                    if (data) {
                        if (Array.isArray(data.response.photos)) {
                            caches.open("api-cache").then((cache) => {
                                data.response.photos.forEach((photo) => {
                                    cache.put(urlsAws.getPhotosEquipo + photo.emplid, new Response(JSON.stringify(photo)));
                                });
                            });
                            setData(data.response.photos);
                        } else {
                            caches.open("api-cache").then((cache) => {
                                cache.put(urlsAws.getPhotosEquipo + data.response.photos.emplid, new Response(JSON.stringify(data.response.photos)));
                            });
                            setData([data.response.photos]);
                        }
                    } else {
                        setData("error");
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    let cachedEmplid = [];
                    emplidArray.forEach((emplid) => {
                        caches.match(urlsAws.getPhotosEquipo + emplid).then((response) => {
                            if (response) {
                                response.json().then((data) => {
                                    if (data) {
                                        console.log(data);
                                        cachedEmplid.push(data);  
                                    }
                                });
                            }
                        });
                    });   
                    if (cachedEmplid.length > 0) {
                        setData(cachedEmplid);
                    } else {
                        setData("error");
                    }
                });
        }).catch((err) => console.log(err));
    },
    getFirmaDocumentos: (emplid, setData, setEstadoCarga) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.getFirmaDocumentos + "?emplid=" + emplid, requestOptions)
                .then((response) => response.json())
                .then((data) => data)
                .then((data) => {
                    if (data.message === "API rate limit exceeded" || Object.keys(data.response).length === 0) data = false;
                    if (data) {
                        caches.open("api-cache").then((cache) => {
                            cache.put(urlsAws.getFirmaDocumentos + "?emplid=" + emplid, new Response(JSON.stringify(data)));
                        });
                        setData(data.request.CN_HOMOFFSOL_RP);
                    } else {
                        setEstadoCarga(true);
                        setData(false);
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    caches.match(urlsAws.getFirmaDocumentos + "?emplid=" + emplid).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                setData(data);
                            });
                        } else {
                            setEstadoCarga(true);
                            setData(false);
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },
    getPDF: (num, datos, setFichaPDF) => {
        Auth.currentSession().then((auth) => {
            let body = {
                request: {
                    EMPLID: datos.emplid,
                    TIPO: num,
                    PHOTO: datos.photo,
                    COLABORADOR: datos.colaborador,
                },
            };
            if (num === 2) {
                let docs = [];
                if (Array.isArray(datos.documentos)) {
                    datos.documentos.forEach((documento) => {
                        docs.push({ end_dt: documento.end_dt, descrdoc: documento.descrdoc });
                    });
                }
                body.request.DOCUMENTOS = docs;
            } else if (num === 3) {
                if (datos.vacaciones.detalle_vacacion !== undefined && datos.diasTomados !== undefined) {
                    let det = datos.vacaciones.detalle_vacacion;
                    let dias = datos.diasTomados;
                    body.request.VACACIONES = det;
                    body.request.DIASTOMADOS = dias;
                }
            }
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: JSON.stringify(body),
            };
            fetch(urls.getPDF , requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.message !== undefined) data = false;
                    else {
                        caches.open("api-cache").then((cache) => {
                            cache.put(urls.getPDF + "_" + datos.emplid, new Response(JSON.stringify(data)));
                            setFichaPDF(data);
                        });
                    }
                    setFichaPDF("error");
                })
                .catch((error) => {
                    console.log("error", error);
                    caches.match(urls.getPDF + "_" + datos.emplid).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                setFichaPDF(data);
                            });
                        } else {
                            setFichaPDF(false);
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },
};
export default miEquipoService;
