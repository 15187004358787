import React, { useEffect, useState } from 'react';
import FormacionAcademicaServices from '../../services/formacion academica/formacion-academica-service';
import { BsExclamationCircle } from "react-icons/bs";
import AgregarEstudio from './agregar-estudio';
import DetalleEstudio from './detalle-estudio';
import ModificarEstudio from './modificar-estudio';
import styles from "../../resources/css/style-COL.module.css";
import { Link } from 'react-router-dom';
"../../resources/css/style-COL.css";

function FormacionAcademica() {
    const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
    const [professions, setProfessions] = useState([])
    const [academicInstitutions, setAcademicInstitutions] = useState([])
    const [countries, setCountries] = useState([])
    const [infoStudy, setInfoStudy] = useState([])
    const [infoStudies, setInfoStudies] = useState([])
    const [loadingStudies, setLoadingStudies] = useState(false)
    const [, setNoDataStudies] = useState(false)
    const [id, setId] = useState(null)
    const [idEdit, setIdEdit] = useState(null)
    const [displayDetail, setDisplayDetail] = useState(false)
    const [displayEdit, setDisplayEdit] = useState(false)
    const [displayAdd, setDisplayAdd] = useState(false)

    const seeStudy = (e, id) => {
        e.preventDefault()
        setId(id)
        setDisplayDetail(true)
        setDisplayEdit(false)
        setDisplayAdd(false)
    }

    const addStudy = () => {
        setDisplayDetail(false)
        setDisplayEdit(false)
        setDisplayAdd(true)
    }

    const changeDisplay = (d, idx, infoStudy) => {
        setInfoStudy(infoStudy)
        setIdEdit(idx)
        setDisplayEdit(d)
        setDisplayAdd(false)
        setDisplayDetail(false)
    }

    const reloadAcademicStudies = () => {
        setDisplayEdit(false)
        setDisplayAdd(false)
        setDisplayDetail(false)
        setLoadingStudies(true)
        FormacionAcademicaServices.GetAcademicStudies(setInfoStudies, setLoadingStudies, setNoDataStudies)
    }

    useEffect(() => {
        setLoadingStudies(true)
        FormacionAcademicaServices.GetAcademicStudies(setInfoStudies, setLoadingStudies, setNoDataStudies)
        FormacionAcademicaServices.GetAcademicInstitutions(setAcademicInstitutions)
        FormacionAcademicaServices.GetProfessions(setProfessions)
        FormacionAcademicaServices.GetCountries(setCountries)
    }, [])
    return (
        <>
            {/* <!-- inicio breadcrumbs -->   */}
            <nav className={`${styles["breadcrumbs"]} ${styles["d-none"]} ${styles["d-lg-block"]}`} aria-label="breadcrumb">
                <ol className={styles["breadcrumb"]}>
                    <li className={styles["breadcrumb-item"]}>
                        <Link to="/inicio">
                            <i className="fa fa-home" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className={styles["breadcrumb-item"]}>Actualización de Información</li>
                    <li className={styles["breadcrumb-item"]}>Formación Académica</li>
                    {displayEdit && <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">Modificar Estudios</li>}
                    {displayAdd && <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">Agregar Estudios</li>}
                </ol>
            </nav>
            {/* <!-- fin breadcrumbs --> */}

            <section className={styles["formacion-academica"] + " " + "cencosud-col"}>
                <h1>Formación Académica</h1>

                {!loadingStudies 
            ?
            <>
                <div className={`${styles["box"]} ${styles["mb-4"]}`}>
                    <div className={`${styles["tit-group"]} ${styles["d-flex"]} ${styles["justify-content-between"]}`}>
                        <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Detalle</h2>
                        <Link to="#" onClick={addStudy}className={`${styles["h5"]} ${styles["mt-2"]} ${styles["fl-normal"]}`}><span className={`${styles["d-none"]} ${styles["d-sm-inline-block"]}`}>Agregar estudio</span><i className={`${styles["fa"]} ${styles["fa-plus-square-o"]} ${styles["fa-lg"]} ${styles["ms-2"]}`} aria-hidden="true"></i></Link>
                    </div>

                    <p>Datos Obligatorios <span className={styles["color-red"]}>*</span></p>

                    {/* <!-- Escritorio (Tabla) --> */}
                    {infoStudies.length !== 0 ? <div className={styles["table-responsive"]}>
                        <table className={`${styles["table"]} ${styles["table-even"]} ${styles["table-section"]} ${styles["table-borderless"]} ${styles["d-none"]} ${styles["d-md-table"]}`}>
                            <thead>
                                <tr>
                                    <th>Títulos Académicos</th>
                                    <th>Lugar de Estudios</th>
                                    <th>Fecha</th>
                                    <th>Ver</th>
                                </tr>
                            </thead>
                            <tbody>
                                {infoStudies && infoStudies.map((el) => {
                                    return (
                                        <tr key={el.id}>
                                            <td>{el.degree}</td>
                                            <td>{el.institution ? academicInstitutions?.filter(a => el.institution === a.id)[0]?.description : el.other_institution}</td>
                                            <td>{el.study_end_date ? months[el.study_end_date.split("-")[1] - 1] + " " + new Date(el.study_end_date).getFullYear().toString() : "En curso"}</td>
                                            <td><Link to="#" onClick={(e) => seeStudy(e, el.id)}><i className={`${styles["fa"]} ${styles["fa-eye"]} ${styles["fa-fw"]} ${styles["fa-lg"]}`} aria-hidden="true"></i></Link></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        {/* <!-- Móvil (Acordeón) --> */}
                        <div className={`accordion accordion-flush accordion-light accordion-table ${styles["d-md-none"]}`}>
                            {/* <!-- 1er acordeón --> */}
                            {infoStudies && infoStudies.map((el, id) => {
                                return (<div key={el.id} className="accordion-item">
                                    <h2 className="accordion-header" id="accordion">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-item-${id}`} aria-expanded="true" aria-controls={`accordion-item-${id}`}>
                                            <strong>Títulos Académicos</strong> {el.degree}
                                        </button>
                                    </h2>
                                    <div id={`accordion-item-${id}`} className="accordion-collapse collapse" aria-labelledby="accordion">
                                        <div className={`accordion-body ${styles["p-0"]}`}>
                                            {/* <!-- cuerpo item --> */}
                                            <div className={`${styles["data-list-box"]} ${styles["data-list-box-even"]}`}>
                                                <ul className={`${styles["data-list-section--item"]} ${styles["list-unstyled"]}`}>
                                                    <li>
                                                        <span className={styles["data-list-box__title"]}>Lugar de Estudios</span>
                                                        <span className={styles["data-list-box__text"]}>{el.institution ? academicInstitutions?.filter(a => el.institution === a.id)[0]?.description : el.other_institution}</span>
                                                    </li>
                                                    <li>
                                                        <span className={styles["data-list-box__title"]}>Fecha</span>
                                                        <span className={styles["data-list-box__text"]}>{el.study_end_date ? months[el.study_end_date.split("-")[1] - 1] + " " + new Date(el.study_end_date).getFullYear().toString() : "En curso"}</span>
                                                    </li>
                                                    <li>
                                                        <span className={styles["data-list-box__title"]}>Ver</span>
                                                        <span className={styles["data-list-box__text"]}>
                                                            <Link to="#" onClick={(e) => seeStudy(e, el.id)}><i className={`${styles["fa"]} ${styles["fa-eye"]} ${styles["fa-fw"]} ${styles["fa-lg"]}`} aria-hidden="true"></i></Link>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* <!-- fin cuerpo item --> */}
                                        </div>
                                    </div>
                                </div>)
                            })}
                            {/* <!-- fin items acordeón --> */}
                        </div>
                        {/* <!-- fin Móvil (Acordeón) --> */}
                    </div> : <div className={`${styles["alert"]} ${styles["alert-info"]} ${styles["alert-dismissible"]} ${styles["fade"]} ${styles["show"]} ${styles["text-center"]}`} role="alert">
                        <BsExclamationCircle />  No se cargaron estudios
                    </div>}
                </div>
                {/* <!-- Fin Detalle --> */}
                    {displayDetail && <DetalleEstudio professions={professions} academicInstitutions={academicInstitutions} countries={countries} changeDisplay={changeDisplay} id={id} reloadAcademicStudies={reloadAcademicStudies} />}
                    {displayEdit && <ModificarEstudio professions={professions} academicInstitutions={academicInstitutions} countries={countries} id={idEdit} infoStudy={infoStudy} reloadAcademicStudies={reloadAcademicStudies} />}
                    {displayAdd && <AgregarEstudio professions={professions} academicInstitutions={academicInstitutions} countries={countries} changeDisplay={changeDisplay} reloadAcademicStudies={reloadAcademicStudies} />}
            </>
            : <div data-testid="loading" className={styles["full-spinner"]}>
                <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
            </div>
            }
            </section> 
        </>
    )
}

export default FormacionAcademica;