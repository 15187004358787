import React from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import HistoricoEmpleado from "./HistoricoEmpleado";
import SolicitudesEmpleados from "./SolicitudesEmpleado";
import styles from '../../resources/css/style-COL.module.css';
import SolicitudesJefe from "./SolicitudesJefe";
import HistoricoJefe from "./HistoricoJefe";
const VacacionesCol = () => {
  return (
    <>
      <div className={`${styles["container"]} ${styles["p-0"]}`}>
        <Routes>
          <Route exact path="/" element={<SolicitudesEmpleados />} />
          <Route exact path='historicoempleado-col' element={<HistoricoEmpleado />} />
          <Route exact path="solicitudesjefe-col" element={<SolicitudesJefe/>}/>
          <Route exact path="historicojefe-col" element={<HistoricoJefe/>}/>
        </Routes>
      </div>
    </>
  );
};

export default VacacionesCol;

