import { Auth } from "aws-amplify";
import axios from "axios";

const url = process.env.REACT_APP_BACKEND_URL_GLOBAL;

const getHeader = () => {
    return new Promise((resolve, reject) => {
        Auth.currentSession()
            .then(async (auth) => {
                const header = {
                    "Content-Type": "application/json",
                    accesstoken: auth.getAccessToken().getJwtToken(),
                };
                resolve(header);
            })
            .catch(function (error) {
                return reject(error);
            });
    });
};

const VacacionesServices = {
    generateDuration: (fechaInicio, fechaFin, setDuration, setLoadingDuration, setNoData, setErrorMessageDuration) => {
        Auth.currentSession().then(async auth => {
            const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
            axios.get(`${url}/api/vacaciones?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`
                , { headers: head }).then((response) => {
                    setDuration(response.data.DURACION)
                    setLoadingDuration(false);
                })
                .catch((error) => {
                    console.log(error.response.data, "errorr")
                    setErrorMessageDuration(error.response.data)
                    setLoadingDuration(false);
                    setNoData(true);
                })
        }).catch(function (error) {
            console.log(error);

        })
    },
    getEmployee: async (setEmployeeInfo, setLoadingEmployeeInfo, setNoEmployeeData) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(url + '/api/emp', { headers: head })
                setEmployeeInfo(data)
                setLoadingEmployeeInfo(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingEmployeeInfo(false);
                setNoEmployeeData(true);
            })
        } catch (error) {
            console.error(error);
        }
    },
    getAvatar: async (setAvatar, setLoadingAvatar) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(`${url}/api/emp/avatar`
                    , { headers: head })
                setAvatar(data.avatar)
                setLoadingAvatar(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingAvatar(false);
                setNoAvatar(true);
            })
        } catch (error) {
            console.error(error);
        }
    },
    getDiasTotales: async (setDiasTotales, setLoadingDiasTotales, setNoDiasTotales) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(`${url}/api/vacaciones/diasTotales`
                    , { headers: head })
                setDiasTotales(data.CN_COL_DIAS_VAC_GET)
                setLoadingDiasTotales(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingDiasTotales(false);
                setNoDiasTotales(true);
            })
        } catch (error) {
            console.error(error);
        }
    },
    getTotalVacaciones: async (setDiasTotalVacaciones, setLoadingDiasTotalVacaciones, setNoDiasTotalVacaciones) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(`${url}/api/vacaciones/totalVacaciones`
                    , { headers: head })
                setDiasTotalVacaciones(data)
                setLoadingDiasTotalVacaciones(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingDiasTotalVacaciones(false);
                setNoDiasTotalVacaciones(true);
            })
        } catch (error) {
            console.error(error);
        }
    },
    postSolicitud: (body, setPostSolicitud, setLoadingPostSolicitud, setNoPostSolicitud, setErrorPostSolicitud) => {
        Auth.currentSession().then(auth => {
            const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
            axios.post(`${url}/api/vacaciones`, body
                , { headers: head })
                .then(() => {
                    setPostSolicitud(true)
                    setLoadingPostSolicitud(false);
                }).catch((error) => {
                    setLoadingPostSolicitud(false);
                    setNoPostSolicitud(true);
                    setErrorPostSolicitud(error.response.data)
                })
        }).catch(function (error) {
            console.log(error)
        })
    },
    getHistoricoEmpleado: async (fechaInicio, fechaFin, estado) => {
        try {
            const header = await getHeader();

            const response = await axios.get(`${url}/api/vacaciones/historicoempleado?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&estado=${estado}`, {
                headers: header,
            });
            return response;
        } catch (error) {
            return error?.response?.data;
        }
    },
    getEstados: async () => {
        try {
            const header = await getHeader();

            const response = await axios.get(`${url}/api/vacaciones/getestados`, {
                headers: header,
            });
            return response;
        } catch (error) {
            return error?.response?.data;
        }
    },

}

export default VacacionesServices;
