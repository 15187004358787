import { createContext } from "react";
import { PageTypes } from "../../interfaces/applyInterface";

interface Props {
    actualPage: PageTypes;
    hasError: boolean;
    isLoading: boolean;
    isModalVideoOpen: boolean;
    paginationPage: number;

    setHasError: (hasError: boolean) => void;
    setIsLoading: (isLoading: boolean) => void;
    setIsModalVideoOpen: (isLoading: boolean) => void;
    setPage: (page: PageTypes) => void;
    setPaginationPage: (paginationPage: number) => void;
}

export const UIContext = createContext({} as Props);
