// AreaModal.tsx
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import OrganigramaService from '../../services/organigrama/organigrama';
// import styles from './organigrama-ss.module.css';
import { ReactComponent as IconUser } from '../../resources/images/organigrama/ico_user.svg';
import { ReactComponent as IconArg } from '../../resources/images/ico_ar.svg';
import { ReactComponent as IconBr } from '../../resources/images/ico_br.svg';
import { ReactComponent as IconCl } from '../../resources/images/ico_cl.svg';
import { ReactComponent as IconCol } from '../../resources/images/ico_col.svg';
import { ReactComponent as IconPe } from '../../resources/images/ico_pe.svg';
import { ReactComponent as IconUy } from '../../resources/images/flag_uruguay.svg';
import { ReactComponent as IconUsa } from '../../resources/images/flag_usa.svg';
import { ReactComponent as IconExcel } from '../../resources/images/organigrama/ico_excel.svg';
import { ReactComponent as IconPDF } from '../../resources/images/organigrama/ico_pdf.svg';
import OrganigramaPDF from './pdf-organigrama'
import { PDFDownloadLink } from '@react-pdf/renderer';

interface AreaModalProps {
    dni: number;
    onHide: () => void;
    datePerson: any;
    hierarchyTree: any;
}

const AreaModal: React.FC<AreaModalProps> = ({ dni, onHide, datePerson, hierarchyTree }) => {

    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [csv, setCsv] = useState<any>([]);


    useEffect(() => {
        const getArea = async (dni: number) => {
            setLoading(true);
            try {
                const response = await OrganigramaService.getArea(dni);
                setData(response);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        getArea(dni);
    }, [dni]);

    const getCsv = async (dni: number) => {
        setLoading(true);
        try {
            const response = await OrganigramaService.getCsv(dni);
            if (response && response.data) {
                const blob = new Blob([response.data], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `nominamc_${new Date().toISOString()}.csv`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                console.error("La respuesta del servicio es nula o no tiene datos");
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleClick = (dni: number) => {
        getCsv(dni)
    }

    return (
        <Modal centered backdrop="static" keyboard={false} show onHide={onHide}>
            <Modal.Header closeButton>
                <h5 className="text-uppercase fw-bold m-0">
                    {datePerson.area ? datePerson.area : 'Sin Información'}
                </h5>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div className="text-center">
                        <div className="spinner-border text-primary m-4"></div>
                    </div>
                ) : (
                    data && (
                        <>
                            <div className=''>
                                <p className="text-uppercase text-primary fw-bold m-0">
                                    Total colaboradores
                                </p>
                                <div className='d-flex align-items-center'>
                                    <div className="m-0 input-number display-6">
                                        {data?.all ? data?.all : "0"}
                                    </div>
                                    <div className='mx-1 title-input'>
                                        <IconUser />
                                    </div>
                                </div>
                            </div>
                            <div className='mt-2'>
                                <h6 className="text-uppercase fw-bold">
                                    Colaboradores País
                                    <div className="bd-links-span-all mt-1 mb-3 mx-4 border-top"></div>
                                </h6>
                                <div className="row">
                                    <div className="col">
                                        <p className='text-uppercase text-primary fw-bold m-0'>Argentina</p>
                                        <p className='display-6 d-flex align-items-baseline'>
                                            {data?.total_argentina ? data?.total_argentina : 0} <IconArg className="ms-2" />
                                        </p>
                                    </div>
                                    <div className="col">
                                        <p className='text-uppercase text-primary fw-bold m-0'>Brasil</p>
                                        <p className='display-6 d-flex align-items-baseline'>
                                            {data?.total_brasil ? data?.total_brasil : 0} <IconBr className="ms-2" />
                                        </p>
                                    </div>
                                    <div className="col">
                                        <p className='text-uppercase text-primary fw-bold m-0'>Chile</p>
                                        <p className='display-6 d-flex align-items-baseline'>
                                            {data?.total_chile ? data?.total_chile : 0} <IconCl className="ms-2" />
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <p className='text-uppercase text-primary fw-bold m-0'>Colombia</p>
                                        <p className='display-6 d-flex align-items-baseline'>
                                            {data?.total_colombia ? data?.total_colombia : 0} <IconCol className="ms-2" />
                                        </p>
                                    </div>
                                    <div className="col">
                                        <p className='text-uppercase text-primary fw-bold m-0'>Perú</p>
                                        <p className='display-6 d-flex align-items-baseline'>
                                            {data?.total_peru ? data?.total_peru : 0} <IconPe className="ms-2" />
                                        </p>
                                    </div>
                                    <div className="col">
                                        <p className='text-uppercase text-primary fw-bold m-0'>Uruguay</p>
                                        <p className='display-6 d-flex align-items-baseline'>
                                            {data?.total_uruguay ? data?.total_uruguay : 0} <IconUy className="ms-2" />
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <p className='text-uppercase text-primary fw-bold m-0'>Estados Unidos</p>
                                        <p className='display-6 d-flex align-items-baseline'>
                                            {data?.total_eeuu ? data?.total_eeuu : 0} <IconUsa className="ms-2" />
                                        </p>
                                    </div>
                                </div>

                            </div>
                            <div className='mb-3'>
                                <h6 className="text-uppercase fw-bold">Descargar Información</h6>
                                <div className="bd-links-span-all mt-3 mx-4 border-top"></div>
                                <div className="d-inline-block me-3" onClick={() => handleClick(dni)}>
                                    <IconExcel style={{ cursor: "pointer" }} />
                                </div>
                                <PDFDownloadLink
                                    className="text-decoration-none d-inline-block mt-3"
                                    fileName="OrganigramaPDF"
                                    document={<OrganigramaPDF hierarchyTree={hierarchyTree} />}
                                >
                                    <IconPDF />
                                </PDFDownloadLink>
                            </div>
                        </>
                    )
                )}
            </Modal.Body>
        </Modal>
    );
};

export default AreaModal;
