import React, { useEffect, useState, useContext } from 'react';
import moduloDtService from '../../services/modulo-dt-service';
import { decrypt, encrypt } from '../../services/utiles';
import vacacionesService from '../../services/vacaciones-service';
import Modales from '../elements/modal-dt';
import ReactPaginate from "react-paginate";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Context } from '../../context';
import { DTGobNorm } from '../commonComponents/DTGobNorm';

const Vacaciones_Dt = () => {
    const { setAuditoria, firstLoginData } = useContext(Context);
    const ModalBasico = Modales.ModalBasico;
    const ModalNoPermiso = Modales.ModalNoPermiso;
    const [datosTrabajador, setDatosTrabajador] = useState(false);
    const [data, setData] = useState(false);
    const [msj, setMsj] = useState(false);
    const [rut, setRut] = useState(false);
    const [pdf, setPdf] = useState('');
    const [años, setAños] = useState([]);
    const [resolucion, setResolucion] = useState(document.body.clientWidth);
    const handleSubmit = async (e) => {
        e.preventDefault();
        const ruts = document.getElementById("rut").value;
        setDatosTrabajador(false);
        setMsj(false);
        setRut(false);
        setData(false);
        setAños([]);
        moduloDtService.validateRut(ruts, firstLoginData.USERDT, setDatosTrabajador, setMsj, setRut);
        setAuditoria({ user: encrypt(firstLoginData.USERDT), descripcion: encrypt("valida permisos para consultar el usuario vacaciones"), rut: encrypt(ruts) })
    }

    useEffect(() => {
        const ruts = document.getElementById("rut").value;
        if (msj == true && datosTrabajador != false && rut != false) {
            document.getElementById("openModalCarga").click();
            setData(false);
            setAños([]);
            vacacionesService.getDetalleVacaciones(setData, ruts, firstLoginData.USERDT);
        } else if (msj == 2 && datosTrabajador == 2 && rut == 0) {
            document.getElementById("openModalPermiso").click();
            setDatosTrabajador(false);
            setMsj(false);
            setRut(false);
            setData(2);
            setAños([]);
        }
    }, [rut]);

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    useEffect(() => {
        setTimeout(function () {
            document.getElementById("closeModalCargando").click();
        }, 500);
        if (data !== false && data != "") {
            const ruts = document.getElementById("rut").value;
            setAuditoria({ user: encrypt(firstLoginData.USERDT), descripcion: encrypt("Consulta las vacaciones del usuario"), rut: encrypt(ruts) })
            if (data.vacaciones != undefined) {
                setAños(data.vacaciones.map(vacacion => decrypt(vacacion.year)).filter(onlyUnique));
            }
        }
    }, [data]);

    const downloadPDF = (fechaInicio) => {
        const ruts = document.getElementById("rut").value;
        document.getElementById("openModalDescargando").click();
        vacacionesService.papeletaVacaciones(setPdf, fechaInicio, ruts, firstLoginData.USERDT);
        setAuditoria({ user: encrypt(firstLoginData.USERDT), descripcion: encrypt("Descarga documento de vacaciones Fecha inicio: " + fechaInicio), rut: encrypt(ruts) })
    }

    const desencriptarArchivo = (value, name) => {
        name = name.replaceAll(' ', '').replaceAll('.', '')
        value = Buffer.from(value, 'base64')
        let link = document.createElement("a");
        let blob = new Blob([value], { type: "application/pdf" });
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.dispatchEvent(new MouseEvent("click"));
        setTimeout(function () {
            document.getElementById("closeModalBasicoA").click();
        }, 500);
    }

    useEffect(() => {
        if (pdf == undefined || pdf == 2) {
            setTimeout(function () {
                document.getElementById("closeModalBasicoA").click();
                document.getElementById("openModalNoDocumento").click();
            }, 500);
            setPdf('');
        } else if (pdf != "") {
            desencriptarArchivo(pdf.archivo, 'documento_vacaciones');
            setPdf('');
        }
    }, [pdf]);

    const disabledBtn = (e) => {
        e.preventDefault();
        let input = e.target.value;
        let btn = document.getElementById("btnBuscar");
        if (input == "") {
            btn.disabled = true;
        } else {
            btn.disabled = false;
        }
    }

    window.addEventListener("resize", function () {
        setResolucion(document.body.clientWidth);
    })

    function PaginatedItems({ itemsPerPage }) {
        const [currentItems, setCurrentItems] = useState(null);
        const [currentItemsAños, setCurrentItemsAños] = useState(null);
        const [pageCount, setPageCount] = useState(0);
        const [pageCountAños, setPageCountAños] = useState(0);
        const [itemOffset, setItemOffset] = useState(0);
        const [itemOffsetAños, setItemOffsetAños] = useState(0);

        useEffect(() => {
            const endOffset = itemOffset + itemsPerPage;
            setCurrentItems(data.vacaciones.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(data.vacaciones.length / itemsPerPage));

        }, [itemOffset, itemsPerPage]);

        useEffect(() => {
            const endOffsetAños = itemOffsetAños + itemsPerPage;
            setCurrentItemsAños(años.slice(itemOffsetAños, endOffsetAños));
            setPageCountAños(Math.ceil(años.length / itemsPerPage));

        }, [itemOffsetAños, itemsPerPage]);

        const handlePageClick = (event) => {
            const newOffset = (event.selected * itemsPerPage) % data.vacaciones.length;
            setItemOffset(newOffset);
        };

        const handlePageClickAño = (event) => {
            const newOffsetAño = (event.selected * itemsPerPage) % años.length;
            setItemOffsetAños(newOffsetAño);
        };

        return (
            <>
                <table className="table table-even table-section table-borderless align-middle text-uppercase d-none d-md-table">
                    {currentItems !== false && currentItems != 2 ?
                        <>
                            <thead>
                                <tr>
                                    <th>Año</th>
                                    <th>Fecha inicio</th>
                                    <th>Fecha final</th>
                                    <th>Días</th>
                                    <th className="text-center">Aprobado</th>
                                    <th className="text-center">Descargar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems != undefined ?
                                    currentItems.map(vacacion => {
                                        return <>
                                            <tr>
                                                <td>{decrypt(vacacion.year)}</td>
                                                <td>{decrypt(vacacion.fecha_inicio)}</td>
                                                <td>{decrypt(vacacion.fecha_retorno)}</td>
                                                <td>{decrypt(vacacion.duracion_dias)}</td>
                                                <td className="text-center">{decrypt(vacacion.approved_flag) === 'N' ? <i className="fa fa-times-circle-o fa-fw me-2 color-primary" aria-hidden="true"></i> : decrypt(vacacion.approved_flag) === 'Y' ? <i className="fa fa-check-circle-o fa-fw me-2 color-primary" aria-hidden="true"></i> : "Pendiente"}</td>
                                                <td className="text-center">
                                                    <a onClick={() => downloadPDF(decrypt(vacacion.fecha_inicio))} ><i className="fa fa-file-pdf-o fa-fw me-2" aria-hidden="true"></i></a>
                                                </td>
                                            </tr>
                                        </>
                                    })
                                    : null}
                            </tbody>
                        </>
                        : "No hay documentos para mostrar"
                    }
                </table>
                {currentItemsAños != undefined && data != undefined && data.vacaciones != undefined && data.vacaciones.length > 0 && currentItems !== 2 ?
                    currentItemsAños.map((año, i) => {
                        return <>
                            <div className="accordion accordion-flush d-block d-md-none accordion-light accordion-table">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id={"accordion-vacaciones-" + i}>
                                        <button className={i == 0 ? "accordion-button" : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={"#accordion-item-dias-tomados-" + i} aria-expanded="true" aria-controls={"accordion-item-dias-tomados-" + i}>
                                            <strong className="text-uppercase">Año {año} </strong>
                                        </button>
                                    </h2>
                                    <div id={"accordion-item-dias-tomados-" + i} className={i == 0 ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="accordion-vacaciones-1">
                                        <div className="accordion-body p-0">
                                            <div className="data-list-box text-uppercase"></div>
                                            <div className="data-list-section">
                                                {data.vacaciones.filter(vaca => decrypt(vaca.year) == año).map((vacaFiltered) => {
                                                    return (
                                                        <ul className="data-list-section--item list-unstyled mb-0">

                                                            <li>
                                                                <span className="text-uppercase d-block mb-1"><strong>Fecha inicio</strong></span>
                                                                <span>{decrypt(vacaFiltered.fecha_inicio)}</span>
                                                            </li>
                                                            <li>
                                                                <span className="text-uppercase d-block mb-1"><strong>Fecha final</strong></span>
                                                                <span>{decrypt(vacaFiltered.fecha_retorno)}</span>
                                                            </li>
                                                            <li>
                                                                <span className="text-uppercase d-block mb-1"><strong>Días</strong></span>
                                                                <span>{decrypt(vacaFiltered.duracion_dias)}</span>
                                                            </li>
                                                            <li>
                                                                <span className="text-uppercase d-block mb-1"><strong>Aprobado</strong></span>
                                                                <span>{decrypt(vacaFiltered.approved_flag) === 'N' ? <i className="fa fa-times-circle-o fa-fw me-2 color-primary" aria-hidden="true"></i> : decrypt(vacaFiltered.approved_flag) === 'Y' ? <i className="fa fa-check-circle-o fa-fw me-2 color-primary" aria-hidden="true"></i> : "Pendiente"}</span>
                                                            </li>
                                                            <li>
                                                                <span className="text-uppercase d-block mb-1"><strong>Descargar</strong></span>
                                                                <span><a onClick={() => downloadPDF(decrypt(vacaFiltered.fecha_inicio))} ><i className="fa fa-file-pdf-o fa-fw me-2" aria-hidden="true"></i></a></span>
                                                            </li>
                                                        </ul>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    })
                    : null
                }

                {resolucion >= 751 ?
                    <>
                        <ReactPaginate
                            id={"paginationnav"}
                            style={{ pointerEvents: "all" }}
                            className="pagination justify-content-center align-items-center"
                            breakLabel=" ... " nextLabel=" > " onPageChange={handlePageClick}
                            pageRangeDisplayed={0} marginPagesDisplayed={1} pageCount={pageCount}
                            previousLabel=" < " renderOnZeroPageCount={null} previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"} breakLinkClassName={"page-link"}
                            pageLinkClassName={"page-link"} pageClassName={"page-item"}
                            breakClassName={"page-item"} previousClassName={"page-item"}
                            nextClassName={"page-item"} activeClassName={"page-item active"}
                            activeLinkClassName={"page-link active"} />
                    </>
                    :
                    <>
                        <ReactPaginate
                            id={"paginationnav"}
                            style={{ pointerEvents: "all" }}
                            className="pagination justify-content-center align-items-center"
                            breakLabel=" ... " nextLabel=" > " onPageChange={handlePageClickAño}
                            pageRangeDisplayed={0} marginPagesDisplayed={1} pageCount={pageCountAños}
                            previousLabel=" < " renderOnZeroPageCount={null} previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"} breakLinkClassName={"page-link"}
                            pageLinkClassName={"page-link"} pageClassName={"page-item"}
                            breakClassName={"page-item"} previousClassName={"page-item"}
                            nextClassName={"page-item"} activeClassName={"page-item active"}
                            activeLinkClassName={"page-link active"} />
                    </>
                }
            </>
        );
    }

    return (
        <>
            <ModalBasico idBtn="openModalNoDocumento" target="modalNoHayDocumentos" idModal="modalNoHayDocumentos" msj="No hay documentos" p="No se encontraron documento" modalCierre="closeModalBasico" icon="fa fa-exclamation-triangle color-orange fa-3x" ></ModalBasico>
            <ModalNoPermiso idBtnOpen="openModalPermiso"></ModalNoPermiso>
            <ModalBasico idBtn="openModalCarga" target="modalCargando" idModal="modalCargando" msj="Cargando..." p="" modalCierre="closeModalCargando" icon="fa fa-exclamation-triangle color-orange fa-3x" ></ModalBasico>
            <ModalBasico idBtn="openModalDescargando" idModal="modalDescargando" target="modalDescargando" msj="Descargando..." p="" modalCierre="closeModalBasicoA" icon="fa fa-exclamation-triangle color-orange fa-3x"></ModalBasico>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active" aria-current="page"><Link href="/vacaciones-dt">Vacaciones</Link></li>
                </ol>
            </nav>
            <section className="ausencias">
                <h1>Vacaciones</h1>
                <div className="box mb-4">
                    <h2 className="h4 tit-section">Detalle de Vacaciones</h2>
                    <h3 className="h5 my-4">Iniciar Búsqueda</h3>
                    <form onSubmit={async (e) => { handleSubmit(e); }} role="form" className="needs-validation" noValidate="noValidate">
                        <div className="row mb-2">
                            <div className="col-xl-6">
                                <div className="mb-3 row">
                                    <label htmlFor="rut" className="col-md-2 col-form-label "><strong>RUT:</strong></label>
                                    <div className="col-sm-6 mb-3">
                                        <input type="text" onChange={(e) => disabledBtn(e)} className="form-control" id="rut" required="required"></input>
                                        <div className="invalid-feedback">
                                            * Ingresar rut sin puntos y con guión.
                                        </div>
                                        <p className="mt-3">
                                            Indicar RUT sin puntos y con guión, del colaborador que desea buscar.
                                        </p>
                                    </div>
                                    <div className="col d-grid d-md-block">
                                        <button type="submit" id='btnBuscar' disabled className="btn btn-degradado" >Buscar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="mb-3 row">
                                    <label htmlFor="name" className="col-md-2 col-xl-3 col-form-label "><strong>NOMBRE:</strong></label>
                                    <div className="col-md-10 col-xl-9">
                                        <input type="text" className="form-control" id="name" defaultValue={datosTrabajador.name} disabled readOnly></input>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade" id="no-vacaciones" tabIndex="-1" aria-labelledby="no-vacaciones" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header border-0 pb-0">
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body text-center py-4">
                                            <i className="fa fa-times-circle-o color-orange fa-3x mb-2" aria-hidden="true"></i>
                                            <h2 className="h5">No existe detalle de Vacaciones para el RUT consultado</h2>
                                        </div>
                                        <div className="modal-footer border-0 pt-0 justify-content-center pb-5">
                                            <button type="button" className="btn btn-degradado" data-bs-dismiss="modal">Aceptar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {
                    rut ?
                        <div className="box mb-4">
                            <h2 className="h4 tit-section">Días Tomados</h2>
                            {data != undefined && data.vacaciones != undefined && data.vacaciones.length > 0 ?
                                <PaginatedItems itemsPerPage={4} />
                                : null}
                        </div>
                        : null}
                <DTGobNorm />
            </section>
        </>
    )
}

Vacaciones_Dt.propTypes = {
    userDt: PropTypes.any,
    auditoria: PropTypes.any,
    itemsPerPage: PropTypes.any,

};

export default Vacaciones_Dt;