import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
	faPlus,
	faMinus
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Card,
	CardTitle,
	CardBody,
	Row,
	Col,
	ListGroup,
	ListGroupItem,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter
} from 'reactstrap';

import usePagesByRoles from './hooks/usePagesByRoles';
import ModalCreateRol from './modal-create-rol';

import styles from '../../resources/css/style.module.css'
import ModalConfirmDeleteRol from './modal-confirm-delete-rol';
import { reverseCountryDictionary } from '../../utils';
import usePagesByCountry from './hooks/useRolesByCountry';

const AsociarRolesPaginas = ({

}) => {
	const { isLoading, pages, countries, getPages } = usePagesByRoles();
	const [pageSelected, setPageSelected] = useState();
	const [rolToDelete, setRolToDelete] = useState();
	const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
	const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
	const { isLoadingCountry, pagesCountry, setSelectedCountry, selectedCountry } = usePagesByCountry();

	const toggleModalCreate = () => setIsModalCreateOpen((prev) => !prev);
	const toggleModalDelete = () => setIsModalDeleteOpen((prev) => !prev);

	const roles = useMemo(() => {
		if (!pageSelected) return [];
		const findRoles = (pagesList) => {
			for (const page of pagesList) {
				if (page.contentreferenceid === pageSelected) {
					return page.roles || [];
				}
				if (page.children && page.children.length > 0) {
					const rolesFromChildren = findRoles(page.children);
					if (rolesFromChildren.length > 0) {
						return rolesFromChildren;
					}
				}
			}
			return [];
		};
		return findRoles(pages);
	}, [pages, pageSelected]);

	const handleCountryChange = (event) => {
		const selectedCountry = event.target.value;
		setSelectedCountry(selectedCountry);
		setPageSelected(null);
	};

	const renderPageListItems = (pages) => {
		const sortedPages = [...pages].sort((a, b) => a.order - b.order);
		return sortedPages.map(p => (
			<React.Fragment key={p.contentreferenceid}>
				<ListGroupItem
					style={p.children && p.children.length > 0 ? {} : { border: 'none' }}
					onClick={() => setPageSelected(p.contentreferenceid)}
					className={`${styles['page-card-item']} ${pageSelected === p.contentreferenceid ? styles['page-card-selected'] : ''}`}
				>
					{p.children && p.children.length > 0 ? (
						<i className="fa fa-folder-open-o color-orange me-2" aria-hidden="true"></i>
					) : (
						<i className="fa fa-file color-orange me-2" aria-hidden="true"></i>
					)}
					{p.name} {p.component && (
						<span className='fw-semibold'>({p.component})</span>
					)}
				</ListGroupItem>
				{p.children && p.children.length > 0 && (
					<ListGroup className="ms-3">
						{renderPageListItems(p.children)}
					</ListGroup>
				)}
			</React.Fragment>
		));
	};

	return (
		<>
			<nav className="breadcrumbs" aria-label="breadcrumb">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to="/inicio">
							<i style={{ pointerEvents: "none" }} className="fa fa-home color-orange" aria-hidden="true"></i> Inicio
						</Link>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						Seguridad por Roles
					</li>
				</ol>
			</nav>
			<Row>
				<Col sm="6">
					<Card className={styles['page-card-container']}>
						<CardTitle tag="h5">Pantallas</CardTitle>
						<div className='mb-2'>
							<form>
								<select className='form-select' id="selectCountry" onChange={handleCountryChange} value={selectedCountry}>
									{countries.map((country, index) => (
										<option key={index} value={country}>{reverseCountryDictionary(country)}</option>
									))}
								</select>
							</form>
						</div>
						<ListGroup flush>
							{isLoadingCountry ? (
								<div className="text-center m-4">
									<div className="spinner-border text-primary"></div>
								</div>
							) : (
								renderPageListItems(pagesCountry)
							)}
						</ListGroup>
					</Card>
				</Col>
				<Col sm="6">
					<Card className={styles['page-card-container']}>
						<CardTitle tag="h5">
							Roles asignados
							{pageSelected && (
								<button type="button" className={styles['add-role-btn']} onClick={() => setIsModalCreateOpen(true)}>
									<FontAwesomeIcon icon={faPlus} />
								</button>
							)}
						</CardTitle>
						<ListGroup flush>
							{roles?.map((r, i) => (
								<ListGroupItem key={i}>
									<Row>
										<Col sm="11">
											{r}
										</Col>
										<Col sm="1">
											<button
												type="button"
												className={styles['delete-role-btn']}
												onClick={() => {
													setRolToDelete(r);
													setIsModalDeleteOpen(true);
												}}
											>
												<FontAwesomeIcon icon={faMinus} />
											</button>
										</Col>
									</Row>
								</ListGroupItem>
							))}
						</ListGroup>
					</Card>
				</Col>
			</Row>
			{isLoading && <div className="spinner-border text-primary"></div>}
			<ModalCreateRol
				pageSelected={pageSelected}
				isOpen={isModalCreateOpen}
				toggle={toggleModalCreate}
				getPages={getPages}
			/>
			<ModalConfirmDeleteRol
				getPages={getPages}
				isOpen={isModalDeleteOpen}
				toggle={toggleModalDelete}
				pageSelected={pageSelected}
				rolSelected={rolToDelete}
			/>
		</>
	);
};

export default AsociarRolesPaginas;