import { Auth } from "aws-amplify";
import axios from "axios";

const url = process.env.REACT_APP_BACKEND_URL_GLOBAL;

const ExperienciaLaboralServices = {
    GetWorkExperiences: async (setWorkExperiences, setLoadingWorkExperiences, setNoDataWorkExperiences) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/emp/workexperiences`, { headers: head })
                setWorkExperiences(data)
                setLoadingWorkExperiences(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingWorkExperiences(false);
                setNoDataWorkExperiences(true);
            })
        } catch (error) {
            console.log(error)
        }
    }, 
    GetWorkExperience: async (id, setWorkExperience, setLoadingWorkExperience, setNoDataWorkExperience) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/emp/workexperiences/${id}`, { headers: head })
                setWorkExperience(data)
                setLoadingWorkExperience(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingWorkExperience(false);
                setNoDataWorkExperience(true);
            })
        } catch (error) {
            console.log(error)
        }
    },
    DeleteWorkExperience: async (id, setDeleteWorkExperience, setLoadingDeleteWorkExperience, setNoDataDelete, setInfoDataDeleteError) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                await axios.delete(`${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/emp/workexperiences/${id}`, { headers: head })
                setDeleteWorkExperience(true)
                setLoadingDeleteWorkExperience(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingDeleteWorkExperience(false);
                setNoDataDelete(true);
                setInfoDataDeleteError(error.response.data.message)
            })
        } catch (error) {
            console.error(error);
        }
    },
    AddWorkExperience: async (body, setAddWorkExperienceInfo, setLoadingAddWorkExperience, setNoDataAdd, setInfoDataAddError) => {
        try {
            Auth.currentSession().then(auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                axios.post(`${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/emp/workexperiences`, body, { headers: head }).then(() => {
                    setAddWorkExperienceInfo(true)
                    setLoadingAddWorkExperience(false);
                })
            }).catch(function (error) {
                console.log(error);
                setLoadingAddWorkExperience(false);
                setNoDataAdd(true);
                setInfoDataAddError(error.response.data.message)
            })
        } catch (error) {
            console.error(error);
        }
    },
    EditWorkExperience: async (body, setEditWorkExperience, setLoadingEditWorkExperience, setNoDataEdit, setInfoDataEditError) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                await axios.put(`${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/emp/workexperiences`, body, { headers: head })
                setEditWorkExperience(true)
                setLoadingEditWorkExperience(false);
            }).catch(function (error) {
                console.log(error.response)
                setLoadingEditWorkExperience(false);
                setNoDataEdit(true);
                setInfoDataEditError(error.response.data.message)
            })
        } catch (error) {
            console.error(error);
        }
    },
    GetCountries: async (setCountries) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/location/countries`, { headers: head })
                setCountries(data)
                // setLoadingCountries(false);
            }).catch(function (error) {
                console.log(error);
                // setLoadingCountries(false);
                // setNoDataCountries(true);
            })
        } catch (error) {
            console.error(error);
        }
    },
    GetStates: async (country,setStates,setLoadingStates) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/location/countries/${country}/states`, { headers: head })
                setStates(data)
                setLoadingStates(false); 
            }).catch(function (error) {
                console.log(error);
                setLoadingStates(false);
                // setNoDataStates(true);
            })
        } catch (error) {
            console.error(error);
        }
    },
    GetCounties: async (country, state ,setCounties,setLoadingCounties) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/location/countries/${country}/states/${state}/counties`, { headers: head })
                setCounties(data)
                setLoadingCounties(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingCounties(false);
                // setNoDataCounties(true);
            })
        } catch (error) {
            console.error(error);
        }
    },
    GetIndustries: async (setIndustries, setLoadingIndustries) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(url + `/api/rrhhbase/industries`, { headers: head })
                setIndustries(data)
                setLoadingIndustries(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingIndustries(false);
            })
        } catch (error) {
            console.error(error)
        }
    },
    GetSectors: async (setSectors, setLoadingSectors) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(url + `/api/rrhhbase/sectors`, { headers: head })
                setSectors(data)
                setLoadingSectors(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingSectors(false);
            })
        } catch (error) {
            console.error(error)
        }
    },
    GetWorkAreas: async (setWorkAreas, setLoadingWorkAreas) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(url + `/api/rrhhbase/workareas`, { headers: head })
                setWorkAreas(data)
                setLoadingWorkAreas(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingWorkAreas(false);
            })
        } catch (error) {
            console.error(error)
        }
    },
    GetReasonsRetirement: async (setReasonsRetirement, setLoadingReasonsRetirement) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(url + `/api/rrhhbase/reasonsretirement`, { headers: head })
                setReasonsRetirement(data)
                setLoadingReasonsRetirement(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingReasonsRetirement(false);
            })
        } catch (error) {
            console.error(error)
        }
    }
}

export default ExperienciaLaboralServices;