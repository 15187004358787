import React, { FC, useState, useRef, useEffect, ChangeEvent } from "react";
import Styles from "./styles/style";
import html2canvas from "html2canvas";
import services from "../../../../../../services/ficha-contratacion-service";
const PreviewBtn: FC<{ encuesta: any, getValues: any, token:string }> = ({ encuesta, getValues, token }) => {
    const [show, setShow] = useState(false);
    const [sendWelcome, setSendWelcome] = useState(true);
    const [checkbox, setCheckbox] = useState(true);
    const name = sessionStorage.getItem("name") || "";
    const position = sessionStorage.getItem("position") || "";
    const department = sessionStorage.getItem("department") || "";
    const profilePicture = sessionStorage.getItem("photo") || "";
    const imageRef = useRef<any>(null);
    const closeRef = useRef<any>(null);

    useEffect(() => {
        if(sendWelcome) sessionStorage.setItem("sendWelcome", "true");
        else sessionStorage.removeItem("sendWelcome");
    }, [sendWelcome]);


    const getRespuestasSeleccionadas = (opciones: string[], index: number) => {
        let result = opciones.filter((_, i) => opciones.includes(getValues(`pregunta${index + 1}${i}`)))
        if (opciones.includes("otros") && getValues(`pregunta${index + 1}${opciones.length - 1}otros`)){
            result.push(getValues(`pregunta${index + 1}${opciones.length - 1}otros`));
        }
        return result.join(", ");
    };

    const submitCallback = async () => {
        const screenshotElement = async () => {
            const prevStyle = imageRef.current.style.width;
            imageRef.current.style.width = "900px";
            // Renderizar el elemento en un canvas
            const canvas = await html2canvas(imageRef.current);
            // Convertir el canvas en una imagen
            imageRef.current.style.width = prevStyle;
            const dataURL = canvas.toDataURL();
            // Obtener la imagen en forma de texto base64
            const base64 = dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
            if (base64 && sendWelcome) {
                services.postMethods.welcomeNotification({ request: { image: base64 } }, token);
            }
            closeRef.current.click();
            document.getElementById("btn-submit-encuesta")?.click();
        }
        screenshotElement();
    };

    const handleChange = (e : ChangeEvent<HTMLInputElement>) => setCheckbox(e.target.checked)

    return <>
        {encuesta && encuesta.preguntas && encuesta.valid === '1' ?
            <>
            <div className={`modal fade ${show ? 'show' : ''}`} id="vista-previa" tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true" aria-labelledby="vista-previa">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content rounded">
                        <div className="modal-header border-0 p-0">
                            <button ref={closeRef} id="closeprevbtn" type="button" onClick={() => {setShow(false)}} className="btn-close rounded border-light px-2 py-1 me-2 mt-2" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body py-4">
                            <h2 className="h3 text-center">Vista previa</h2>
                            <div className="d-flex flex-row justify-content-between my-3 w-100">
                                <button type="button" onClick={() => { setShow(false) }} className="ms-5 btn btn-primary" data-bs-dismiss="modal">
                                    Volver
                                </button>
                                <button type="button" onClick={() => { submitCallback(); }} className="btn btn-degradado mx-5">
                                    Confirmar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div ref={imageRef} id="ficha-resumen-container" >
                                <Styles/>
                                <div id="ficha-resumen" className="ficha-resumen">
                                    <div className={`fondo px-5 py-2 m-0 rounded`}>
                                        <div className="fondo-circle"></div>
                                        <div className="fondo-pill rounded-pill"></div>
                                        <div className="fondo-pill-circle"></div>
                                    </div>
                                    <div className="mb-5">
                                        <div className="ficha-resumen-titles">
                                            <p className="px-md-2 m-0 ms-1 fw-bold bienvenido">BIENVENID@ A</p>
                                            <p className="m-0 fw-bold cenco">CENCOSUD</p>
                                        </div>
                                        <div className="ficha-resumen-subtitle">
                                            <i className="px-2 fs-3 fa fa-heart fa-2x color-white" aria-hidden="true"></i>
                                            <i className="px-2 fs-3 fa fa-comment fa-2x color-white" aria-hidden="true"></i>
                                            <i className="px-2 fs-3 fa fa-send fa-2x color-white" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div className="box pb-1 shadow-lg">
                                        <div className="row mb-4 align-items-center">
                                            <div className="col-lg-4">
                                                <img className="rounded-circle img-fluid mb-3 mb-lg-0" src={profilePicture} alt="" width="160" height="160" />
                                            </div>
                                            <div className="col-lg-8">
                                                <h3 className="fs-5 fw-bold color-normal mb-0">{name}</h3>
                                                <p className="fs-6 mb-0">{position}</p>
                                                <p>{department}</p>
                                            </div>
                                        </div>
                                        <p className="fs-5 fw-bold"><span className="color-magenta">#</span>Top<span className="color-magenta">Favoritos</span></p>
                                        <div className="row" >
                                            {
                                                encuesta.preguntas.map((pregunta: any, index: number) => {
                                                    const opciones = JSON.parse(JSON.stringify(pregunta.opcion));
                                                    if (pregunta.otros === "Y" && !opciones.includes("otros")) opciones.push("otros");
                                                    const stringfinal = getRespuestasSeleccionadas(opciones, index);
                                                    if (!stringfinal) return null;
                                                    return <div className="col-lg-6 d-flex" key={index}>
                                                        <div className="bg-light-2 p-3 mb-4 rounded rounded-3 w-100">
                                                            <div className="d-flex align-items-center mb-3">
                                                                <i className={`fa ${pregunta.icono} fa-lg me-2 color-magenta`} aria-hidden="true"></i>
                                                                <span className="fw-bold">{pregunta.etiqueta}</span>
                                                            </div>
                                                            <p>{stringfinal}</p>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="mt-5 d-lg-flex justify-content-center text-center">
                                        <div className="fs-4 fw-bold mx-3">
                                            <span className="color-blue-light">#</span><span className="color-charlotte">Talento</span><span className="color-blue-light">Cencosud</span>
                                        </div>
                                        <div className="fs-4 fw-bold mx-3">
                                            <span className="color-blue-light">#</span><span className="color-charlotte">Pasión</span><span className="color-blue-light">PorElServicio</span>
                                        </div>
                                    </div>
                                    <div className="bar-footer mb-5">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                            <div className="d-flex flex-row justify-content-between my-5 w-100">
                                <button type="button" onClick={() => { setShow(false) }} className="ms-5 btn btn-primary" data-bs-dismiss="modal">
                                    Volver
                                </button>
                                <button type="button" onClick={() => { submitCallback(); }} className="btn btn-degradado mx-5">
                                    Confirmar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-4 mt-2 border-top">
                <div className="form-check mb-2 ms-1 mb-3">
                    <input className="form-check-input h5" type="checkbox" defaultChecked={true}
                        onChange={(e) => { setSendWelcome(!sendWelcome); handleChange }}
                    />
                    <label className="form-check-label fw-normal color-primary ms-2" htmlFor="consentimiento">¿Deseas compartir esta informacion con tus compañeros de equipo? Verás una vista previa del correo de bienvenida que enviaremos a tus compañeros.</label>
                </div>
            </div>
            <button type="button" id="prevbtn" onClick={() => {setShow(true)}} className="btn btn-degradado btn-floating btn-lg" data-bs-toggle="modal" data-bs-target="#vista-previa" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">Enviar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
            </>
            :
            null
        }
    </>
};

export default PreviewBtn;
