import { Auth } from "aws-amplify";
import axios from "axios";
import moment from "moment";

/* const url = "http://localhost:5000"; */

 const url = process.env.REACT_APP_BACKEND_URL_GLOBAL;

export const postRegistroViaje = async (
  RegistroViaje,
  statusRequest,
  setstatusRequest
) => {

  const { beginDt, endDt, country,comments } = RegistroViaje;

  const data={
    beginDt: moment(beginDt,'YYYY-MM-DD').format('DD-MM-YYYY'), 
    endDt: moment(endDt,'YYYY-MM-DD').format('DD-MM-YYYY'),
    country,
    comments
  }
  try {
    Auth.currentSession().then(async (auth) => {
      const headers = {
        "Content-Type": "application/json",
        AccessToken: auth.getAccessToken().getJwtToken(),
      };
      const apiUrl = `${url}/api/Employee/RegistroViaje`;

      axios
        .post(apiUrl, data, { headers: headers })
        .then((res) => {
          setstatusRequest({
            loading: false,
            error: false,
            success: true,
            message: res.data.description,
          });
        })
        .catch(function (error) {
          //TODO VALIDAR MENSAJE DE ERROR
          
            setstatusRequest({ loading: false, error: true, success: false, message: error.response.data}) 
        });
    });
  } catch (error) {
    setstatusRequest({ loading: false, error: true, success: false, message: "Error al envio de datos intentelo mas tarde porfavor"}) ; 
    
  }
};
export const getRegistroViaje = async (setRegistroViaje,setloading) => {
  try {
    Auth.currentSession().then(async (auth) => {
      const headers = {
        "Content-Type": "application/json",
        AccessToken: auth.getAccessToken().getJwtToken(),
      };
      const apiUrl = `${url}/api/Employee/RegistroViaje`;
      axios
        .get(apiUrl, { headers: headers })
        .then((res) => {
          setRegistroViaje(res.data);
           setloading(false); 
        })
        .catch(function (error) {
          console.log(error);
          setloading(false); 
        });
    });
  } catch (error) {
    console.log(error);
    setloading(false); 
  }
};

export const deleteRegistroViaje = async (RegistroViaje, setstatusRequest,statusRequest) => {
  try {
    Auth.currentSession().then(async (auth) => {
      const headers = {
        "Content-Type": "application/json",
        AccessToken: auth.getAccessToken().getJwtToken(),
      };
      const apiUrl = `${url}/api/Employee/RegistroViaje`;
      axios
        .delete(apiUrl, { headers: headers, data: RegistroViaje })
        .then((res) => {
          setstatusRequest({
            ...statusRequest,
            loadingDelete: false,
            successDelete: true,
            message: res.data.description,

          });
        })
        .catch(function (error) {
          console.log(error);
          setstatusRequest({
            ...statusRequest,
            loadingDelete: false,
            errorDelete: true,
            message: error.response.data,
          });
        })
    });
  } catch (error) {
    console.log(error);
  }
};
