import React, { useEffect, useState } from 'react';
import { BsExclamationCircle } from "react-icons/bs";
import { Link } from 'react-router-dom';
import ExperienciaLaboralServices from '../../services/experiencia laboral/experiencia-laboral-service';
import AgregarExperienciaLaboral from './agregar-experiencia-laboral';
import DetalleExperienciaLaboral from './detalle-experiencia-laboral';
import ModificarExperienciaLaboral from './modificar-experiencia-laboral';
import styles from "../../resources/css/style-COL.module.css";
"../../resources/css/style-COL.css"

function ExperienciaLaboral() {
    const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
    const [workExperiences, setWorkExperiences] = useState([])
    const [loadingWorkExperiences, setLoadingWorkExperiences] = useState(false)
    const [noDataWorkExperiences, setNoDataWorkExperiences] = useState(false)
    const [infoWorkExperience, setInfoWorkExperience] = useState({})
    const [countries, setCountries] = useState([])
    const [id, setId] = useState(null)
    const [idEdit, setIdEdit] = useState(null)
    const [displayDetail, setDisplayDetail] = useState(false)
    const [displayEdit, setDisplayEdit] = useState(false)
    const [displayAdd, setDisplayAdd] = useState(false)
    const seeExperience = (e, id) => {
        e.preventDefault()
        setId(id)
        setDisplayDetail(true)
        setDisplayEdit(false)
        setDisplayAdd(false)
    }

    const addExperience = () => {
        setDisplayDetail(false)
        setDisplayEdit(false)
        setDisplayAdd(true)
    }

    const changeDisplay = (d, idx, infoWorkExperience) => {
        setInfoWorkExperience(infoWorkExperience)
        setIdEdit(idx)
        setDisplayEdit(d)
        setDisplayAdd(false)
        setDisplayDetail(false)
    }

    const reloadWorkExperiences = () => {
        setDisplayEdit(false)
        setDisplayAdd(false)
        setDisplayDetail(false)
        setLoadingWorkExperiences(true)
        ExperienciaLaboralServices.GetWorkExperiences(setWorkExperiences, setLoadingWorkExperiences, setNoDataWorkExperiences)
    }

    useEffect(() => {
        setLoadingWorkExperiences(true)
        ExperienciaLaboralServices.GetWorkExperiences(setWorkExperiences, setLoadingWorkExperiences, setNoDataWorkExperiences)
        ExperienciaLaboralServices.GetCountries(setCountries)
    }, [])


    return (<div>
        {/* <!-- inicio breadcrumbs -->   */}
        <nav className={`${styles["breadcrumbs"]} ${styles["d-none"]} ${styles["d-lg-block"]}`} aria-label="breadcrumb">
            <ol className={styles["breadcrumb"]}>
                <li className={styles["breadcrumb-item"]}>
                    <Link to="/inicio">
                        <i className="fa fa-home" aria-hidden="true"></i> Inicio
                    </Link>
                </li>
                <li className={styles["breadcrumb-item"]}>Actualización de Información</li>
                <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">Experiencia Laboral</li>
                {displayEdit && <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">Modificar Experiencia</li>}
                {displayAdd && <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">Agregar Experiencia</li>}
            </ol>
        </nav>
        {/* <!-- fin breadcrumbs --> */}

        {/* <!-- inicio Experiencia Laboral --> */}
            <h1>Experiencia Laboral</h1>
            {loadingWorkExperiences ? <div data-testid="loading" className={styles["full-spinner"]}>
                        <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
                    </div> : noDataWorkExperiences ? <div className={`${styles["alert"]} ${styles["alert-info"]} ${styles["alert-dismissible"]} ${styles["fade"]} ${styles["show"]} ${styles["text-center"]}`} role="alert">
                        <BsExclamationCircle />  No se encontraron datos asociados
                    </div> : <section className={styles["experiencia-laboral"] + " " + "cencosud-col"}>

            <div className={`${styles["box"]} ${styles["mb-4"]}`}>
                <div className={`${styles["tit-group"]} ${styles["d-flex"]} ${styles["justify-content-between"]}`}>
                    <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Detalle</h2>
                    <Link to="#" onClick={addExperience} className={`${styles["h5"]} ${styles["mt-2"]} ${styles["fl-normal"]}`}><span className={`${styles["d-none"]} ${styles["d-sm-inline-block"]}`}>Agregar Experiencia Laboral</span><i className={`${styles["fa"]} ${styles["fa-plus-square-o"]} ${styles["fa-lg"]} ${styles["ms-2"]}`} aria-hidden="true"></i></Link>
                </div>

                <p>Datos Obligatorios <span className={styles["color-red"]}>*</span></p>
                {workExperiences.length !== 0 ? <>
                {/* <!-- Escritorio (Tabla) --> */}
                <div className={styles["table-responsive"]}>
                    <table className={`${styles["table"]} ${styles["table-even"]} ${styles["table-section"]} ${styles["table-borderless"]} ${styles["d-none"]} ${styles["d-md-table"]}`}>
                        <thead>
                            <tr>
                                <th>Experiencia Laboral</th>
                                <th>Lugar de Trabajo</th>
                                <th>Fecha</th>
                                <th>Ver</th>
                            </tr>
                        </thead>
                        <tbody>
                            {workExperiences.map((l) => {
                                return (<tr key={l.id}>
                                    <td>{l.position}</td>
                                    <td>{l.company}</td>
                                    <td>{months[l.end_date.split("-")[1] - 1] + " " + new Date(l.end_date).getFullYear().toString()}</td>
                                    <td>
                                        <Link to="#" onClick={e => seeExperience(e, l.id)}><i className={`${styles["fa"]} ${styles["fa-eye"]} ${styles["fa-fw"]} ${styles["fa-lg"]}`} aria-hidden="true"></i></Link>
                                    </td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
                {/* <!-- Móvil (Acordeón) --> */}
                <div className={`accordion accordion-flush accordion-light accordion-table ${styles["d-md-none"]}`}>
                    {/* <!-- 1er acordeón --> */}
                    {workExperiences.map((l, idx) => {
                        return (<div key={l.id} className="accordion-item">
                            <h2 className="accordion-header" id="accordion">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-item-${idx}`} aria-expanded="false" aria-controls={`accordion-item-${idx}`}>
                                    <strong>Experiencia Laboral</strong> {l.position}
                                </button>
                            </h2>
                            <div id={`accordion-item-${idx}`} className="accordion-collapse collapse" aria-labelledby="accordion">
                                <div className={`accordion-body ${styles["p-0"]}`}>
                                    {/* <!-- cuerpo item --> */}
                                    <div className={`${styles["data-list-box"]} ${styles["data-list-box-even"]}`}>
                                        <ul className={`${styles["data-list-section--item"]} ${styles["list-unstyled"]}`}>
                                            <li>
                                                <span className={styles["data-list-box__title"]}>Lugar de Trabajo</span>
                                                <span className={styles["data-list-box__text"]}>{l.company}</span>
                                            </li>
                                            <li>
                                                <span className={styles["data-list-box__title"]}>Fecha</span>
                                                <span className={styles["data-list-box__text"]}>{months[l.end_date.split("-")[1] - 1] + " " + new Date(l.end_date).getFullYear().toString()}</span>
                                            </li>
                                            <li>
                                                <span className={styles["data-list-box__title"]}>Ver</span>
                                                <span className={styles["data-list-box__text"]}>
                                                    <Link to="#" onClick={e => seeExperience(e, l.id)}><i className={`${styles["fa"]} ${styles["fa-eye"]} ${styles["fa-fw"]} ${styles["fa-lg"]}`} aria-hidden="true"></i></Link>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <!-- fin cuerpo item --> */}
                                </div>
                            </div>
                        </div>)
                    })}
                    {/* <!-- fin items acordeón --> */}
                </div></> : <div className={`${styles["alert"]} ${styles["alert-info"]} ${styles["alert-dismissible"]} ${styles["fade"]} ${styles["show"]} ${styles["text-center"]}`} role="alert">
                        <BsExclamationCircle />  No se cargaron experiencias laborales
                    </div>}
                {/* <!-- fin Móvil (Acordeón) --> */}
            </div>
            {/* <!-- Fin Detalle --> */}

            {displayDetail && <DetalleExperienciaLaboral countries={countries} changeDisplay={changeDisplay} id={id} reloadWorkExperiences={reloadWorkExperiences} />}
            {displayAdd && <AgregarExperienciaLaboral countries={countries} reloadWorkExperiences={reloadWorkExperiences} changeDisplay={changeDisplay}/>}
            {displayEdit && <ModificarExperienciaLaboral countries={countries} id={idEdit} infoWorkExperience={infoWorkExperience} reloadWorkExperiences={reloadWorkExperiences} />}
        </section>}
    </div>)
}

export default ExperienciaLaboral;