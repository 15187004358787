import PropTypes from 'prop-types';

const CompletedHiringModal = ({signOut}) => {
    return (
        <>
            <button hidden id="openCompletedHiring" data-bs-target="#completedHiring" data-bs-toggle="modal" data-bs-backdrop="static" data-bs-keyboard="false" />
            <div className="modal fade" id="completedHiring" tabIndex={-1} aria-labelledby="completedHiring" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button onClick={async () => await signOut()} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center py-4">
                            <h2 className="h2 fw-normal tit-section tit-section-center">Gracias</h2>
                            <div className="fs-5">
                                <p>Tu ficha ya fue recibida por el equipo de Recursos Humanos, quien te notificará cuando la información esté cargada en sistema y sobre el envío de la documentación asociada a tu contratación.</p>
                                <p>Te mantendremos al tanto si existiese algún inconveniente con este proceso.</p>
                                <p>¡Mantente atento a tu correo para no perderte los siguientes pasos sobre tu proceso de contratación!</p>
                                <p>¡Gracias por tu tiempo e interés!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

CompletedHiringModal.propTypes = {
    signOut: PropTypes.func,
};

export default CompletedHiringModal;