import React from 'react';
import { Link } from 'react-router-dom';
import Card from './Card';
import { Course, useCourses } from './context/CoursesContext';

export const CoursesContent: React.FC = () => {
  const { courses } = useCourses();

  const categorizedCourses = courses.reduce((acc, course) => {
    if (!acc[course.category]) {
      acc[course.category] = {
        title: course.category,
        courses: [],
      };
    }
    acc[course.category].courses.push(course);
    return acc;
  }, {} as Record<string, { title: string; courses: Course[] }>);

  return (
    <>
      <nav className="breadcrumbs" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/inicio">
              <i
                style={{ pointerEvents: 'none' }}
                className="fa fa-home"
                aria-hidden="true"
              ></i>{' '}
              Inicio
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Cursos
          </li>
        </ol>
      </nav>
      <section>
        <h1>Cursos</h1>
        {Object.entries(categorizedCourses).map(([category, data]) => (
          <div key={category} className="mb-5">
            <h2 className="h4 tit-section">{data.title}</h2>
            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-2 row-cols-xxl-4 g-4">
              {data.courses.map((course, index) => (
                <Card
                  key={index}
                  imageSrc={course.image}
                  title={course.title}
                  link={course.url}
                />
              ))}
            </div>
          </div>
        ))}
      </section>

    </>
  );
};
