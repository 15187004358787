import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DetailRequestPlate from './detail-request-plate';
import RequestPlate from './request-plate';

const BandejaEntrada = () => {
  return (
   <>
      <div className='container p-0'>
        <div className='row'>
          <Routes>
            <Route path="" element={<RequestPlate />} />
          </Routes>
          <Routes>
            <Route path="detalle-aprobacion" element={<DetailRequestPlate />} />
          </Routes>
        </div>
      </div>
    </>
  )
}

export default BandejaEntrada
