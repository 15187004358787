import React, { FC } from 'react';

const Styles: FC = () => {
    return <style>
        {`
            .rounded{
                border-radius: 10px;
            }
            .ficha-resumen{
                color: #ffffff;
                padding: 10px;
                border-radius: 5px;
                margin-bottom: 10px;
                position: relative;
                background-color: #ffffff;
                width: 100%;
                z-index: 1;
            }
            .ficha-resumen .fondo{
                top: 0;
                left: 0;
                position: absolute;
                background: rgb(124,62,252);
                background: linear-gradient(90deg, rgba(124,62,252,1) 0%, rgba(251,61,140,1) 100%);
                height: 60%;
                width: 100%;
                z-index: -1;
                overflow: hidden;
            }
            .ficha-resumen .box {
                background-color: #ffffff;
                color: #7c7c7c
            }
            .fondo-circle{
                position: absolute;
                top: -85px;
                right: -84px;
                background-color: transparent;
                border: 35px solid #07d2cc;
                border-radius: 50%;
                width: 200px;
                height: 200px;
            }
            .fondo-pill-circle{
                position: absolute;
                top: 13px;
                right: 12px;
                background-color: #7c3efc;
                border-radius: 50%;
                z-index: 2;
                width: 14px;
                height: 14px;
            }
            .fondo-pill{
                position: absolute;
                top: 10px;
                right: 7px;
                padding: 10px 20px;
                background-color: #ffffff;
            }

            .ficha-resumen-titles {
                font-size: 1.2rem;
                font-weight: 700;
            }
            .ficha-resumen-titles .bienvenido{
                line-height: 1;
                font-size: 2.6ch !important;
            }
            .ficha-resumen-titles .cenco{
                color: transparent;
                border-style: solid;
                -webkit-text-stroke-width: 2px;
                -webkit-text-stroke-color: white;
                background-clip: text;
                line-height: 1;
                font-size: 5ch !important;
            }

            @media (min-width: 992px) {
                .ficha-resumen-titles .bienvenido {
                font-size: 3.5ch !important;
                }
                .ficha-resumen-titles .cenco {
                font-size: 8ch !important;
                }
                .fondo-circle{
                top: -110px;
                right: -110px;
                width: 290px;
                height: 290px;
                border: 45px solid #07d2cc;
                }
                .fondo-pill{
                top: 18px;
                right: 15px;
                padding: 14px 30px;
                }
                .fondo-pill-circle{
                top: 21px;
                right: 21px;
                width: 22px;
                height: 22px;
                }
            }

            .color-magenta {
                color: #ed0f79 !important;
            }

            .color-charlotte {
                color: #07d2cc !important;
            }

            .color-blue-light {
                color: #1580ee !important;
            }

            .color-white {
                color: #ffffff !important;
            }
        `}
    </style>
}

export default Styles;
