import { useEffect, useState } from "react";
import { useAxiosFetch } from "../../../hooks";
import { InterestSitesMaintenance } from '../context/interestSiteMaintenanceContext';

export interface FetchParams {
    country?: string;
    siteId?: number | null;
    updatedData?: InterestSitesMaintenance | undefined;
    newData?: InterestSitesMaintenance | undefined;
    siteIdToDelete?: number | null;
}

const useFetchInterestSitesMaintenance = ({ country, siteId, updatedData, newData, siteIdToDelete }: FetchParams) => {
    const [data, setData] = useState<InterestSitesMaintenance[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
    const [isLoadingPost, setIsLoadingPost] = useState<boolean>(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);

    const [fetchInterestSiteAll] = useAxiosFetch<InterestSitesMaintenance[]>({
        url: `/api/interest_sites/`,
        method: 'GET',
    });

    const [fetchInterestSiteByCountry] = useAxiosFetch<InterestSitesMaintenance[]>({
        url: `/api/interest_sites/country/${country}`,
        method: 'GET',
    });

    const [updateInterestSite] = useAxiosFetch<InterestSitesMaintenance>({
        url: `/api/interest_sites/item/${siteId}`,
        method: 'PUT',
        data: updatedData,
    });

    const [postInterestSite] = useAxiosFetch<InterestSitesMaintenance>({
        url: `/api/interest_sites/create`,
        method: 'POST',
        data: newData,
    });

    const [deleteInterestSite] = useAxiosFetch({
        url: `/api/interest_sites/delete/${siteIdToDelete}`,
        method: 'DELETE',
    });

    //Get
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = country ? await fetchInterestSiteByCountry() : await fetchInterestSiteAll();
                setData(response);
                setIsError(false);
            } catch (error) {
                setIsError(true);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [country, updatedData, isLoadingUpdate, isLoadingPost])

    //PUT
    useEffect(() => {
        if (updatedData !== undefined && siteId) {
            const updateData = async () => {
                setIsLoadingUpdate(true);
                try {
                    const response =  await updateInterestSite();
                    setData((prevData) =>
                    prevData.map((item) =>
                        item.id === siteId ? response : item
                    )
                )
                    setIsError(false);
                } catch (error) {
                    setIsError(true);
                } finally {
                    setIsLoadingUpdate(false);
                }
            };

            updateData();
        }
    }, [updatedData, siteId]);

    //Post
    useEffect(() => {
        if (newData !== undefined) {
            const postData = async () => {
                setIsLoadingPost(true);
                try {
                    await postInterestSite();
                    setIsError(false);
                } catch (error) {
                    setIsError(true);
                } finally {
                    setIsLoadingPost(false);
                }
            };

            postData();
        }
    }, [newData]);

    // DELETE
    useEffect(() => {
        if (siteIdToDelete) {
            const deleteData = async () => {
                setIsLoadingDelete(true);
                try {
                    await deleteInterestSite();
                    setData((prevData) => prevData.filter((item) => item.id !== siteIdToDelete));
                    setIsError(false);
                } catch (error) {
                    setIsError(true);
                } finally {
                    setIsLoadingDelete(false);
                }
            };

            deleteData();
        }
    }, [siteIdToDelete]);

    return { data, isLoading, isError};
};

export default useFetchInterestSitesMaintenance;
