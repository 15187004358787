import { useProfileSummaryProvider } from "../../context/EmployeeProfileSummaryContext";

export const WorkCompensationCard = () => {
  const { summaryEmployee } = useProfileSummaryProvider();

  return (
    <div className="col-12 col-xl-7 d-flex">
      <div className="box align-items-stretch w-100">
        <h2 className="h4 tit-section">Datos de Puesto / Compensación</h2>
        <div className="data-list-box data-list-box-even text-uppercase">
          <ul className="list-unstyled m-0">
            {summaryEmployee.data.jobData.map(
              (data) =>
                !data.hiddeAttribute && (
                  <li key={data.id}>
                    <span className="data-list-box__title col-12 col-sm-5">
                      {data.keyLabel}
                    </span>
                    <span className="data-list-box__text">{data.KeyValue}</span>
                  </li>
                )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};
