import React, { Children } from 'react';
import { Text, Image, StyleSheet, View, Page, Document, PDFDownloadLink } from "@react-pdf/renderer";

const EvaluacionDesempenio = ({
    data,
    loadingData
}) => {
    const EvaluacionDesempenioPDF = () => {
        const styles = StyleSheet.create({
            page: {
                backgroundColor: '#fff',
                padding: '20px',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
            },
            card: {
                padding: "20px",
                borderRadius: "10px",
                width: "48%",
                border: "1px solid #eeeeee",
                margin: " 0px 5px 20px 5px",
                height: "auto",
            },
            head: {
                display: "flex",
                marginBottom: "20px",
                paddingLeft: "10px",
            },
            body: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "20px"
            },
            h1: {
                fontSize: "16px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: "10px",
                color: "#0169b5"
            },
            table: {
                width: "100%",
                borderCollapse: "collapse",
                borderSpacing: "0",
                marginBottom: "20px"
            },
            tableHead: {
                backgroundColor: "#eeeeee",
                borderBottom: "1px solid #eeeeee",
                borderTop: "1px solid #eeeeee",
                borderLeft: "1px solid #eeeeee",
                borderRight: "1px solid #eeeeee",
                padding: "5px",
                fontSize: "8px",
            },
            tableBody: {
                borderBottom: "1px solid #eeeeee",
                borderLeft: "1px solid #eeeeee",
                borderRight: "1px solid #eeeeee",
                fontSize: "8px",
                textAlign: "center",
                color: "#777777",
            },
            tableRow: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                padding: "5px 5px 5px 5px",
                border: "1px solid #eeeeee",
            },
            labelHead: {
                textAlign: "center",
                color: "#777777",
                fontWeight: "900",
            },
            labelBody: {
                textAlign: "center",
                color: "#777777",
                fontWeight: "normal",
            },
            li: {
                display: "flex",
                flexDirection: "row",
                alignItems: 'flex-start',
                padding: "10px",
                borderBottom: "1px solid #eeeeee",
                position: "relative",
                justifyContent: "flex-start"
            },
            liTitle: {
                width: "50%",
            },
            liContent: {
                width: "50%",
            },
            titleLabel: {
                fontSize: "8px",
                fontWeight: "bold",
                color: "#777777"
            },
            contentLabel: {
                fontSize: "8px",
                fontWeight: "normal",
                color: "#777777"
            },
            foto: {
                width: "100px",
                height: "100px",
            },
            checkimg: {
                width: "20px",
                height: "20px",
            },
        });

        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={{ ...styles.card, width: "100%" }}>
                        <View style={styles.head}>
                            <Text style={styles.h1}>Evaluación de Desempeño</Text>
                        </View>
                        <View style={styles.body}>
                            <View style={styles.table}>
                                <View style={styles.tableHead}>
                                    <View style={styles.tableRow}>
                                        <Text style={{ ...styles.labelHead, width: "33%" }}>AÑO</Text>
                                        <Text style={{ ...styles.labelHead, width: "33%" }}>NOTA CALIBRADA</Text>
                                        <Text style={{ ...styles.labelHead, width: "33%" }}>EVALUADOR</Text>
                                    </View>
                                </View>
                                <View style={styles.tableBody}>
                                    {
                                        data.CN_EVALDES_LIST !== undefined ?
                                            Array.isArray(data.CN_EVALDES_LIST) ?
                                                data.CN_EVALDES_LIST.map((item) => {
                                                    return (
                                                        <View style={styles.tableRow} >
                                                            <Text style={{ ...styles.labelContent, width: "33%" }}>{item.anio_evalua === "" ? "-" : item.anio_evalua}</Text>
                                                            <Text style={{ ...styles.labelContent, width: "33%" }}>{item.nota_calibrada === "" ? "-" : item.nota_calibrada}</Text>
                                                            <Text style={{ ...styles.labelContent, width: "33%" }}>{item.evaluador === "" ? "-" : item.evaluador}</Text>
                                                        </View>
                                                    )
                                                })
                                                :

                                                <View style={styles.tableRow} >
                                                    <Text style={{ ...styles.labelContent, width: "33%" }}>{data.CN_EVALDES_LIST.anio_evalua === "" ? "-" : data.CN_EVALDES_LIST.anio_evalua}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "33%" }}>{data.CN_EVALDES_LIST.nota_calibrada === "" ? "-" : data.CN_EVALDES_LIST.nota_calibrada}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "33%" }}>{data.CN_EVALDES_LIST.evaluador === "" ? "-" : data.CN_EVALDES_LIST.evaluador}</Text>
                                                </View>


                                            :
                                            <View style={styles.tableRow}>
                                                <Text style={styles.labelContent}>Sin Registros</Text>
                                            </View>
                                    }
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{ ...styles.card, width: "100%" }}>
                        <View style={styles.head}>
                            <Text style={styles.h1}>TR</Text>
                        </View>
                        <View style={styles.body}>
                            <View style={styles.table}>
                                <View style={styles.tableHead}>
                                    <View style={styles.tableRow}>
                                        <Text style={{ ...styles.labelHead, width: "33%" }}>AÑO</Text>
                                        <Text style={{ ...styles.labelHead, width: "33%" }}>NOTA</Text>
                                        <Text style={{ ...styles.labelHead, width: "33%" }}>DESCRIPCIÓN</Text>
                                    </View>
                                </View>
                                <View style={styles.tableBody}>
                                    {
                                        data.tr !== undefined ?
                                            data.tr.map((item) => {
                                                return (
                                                    <View style={styles.tableRow} >
                                                        <Text style={{ ...styles.labelContent, width: "33%" }}>{item.anio === "" ? "-" : item.anio}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "33%" }}>{item.nota === "" ? "-" : item.nota}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "33%" }}>{item.descripcion === "" ? "-" : item.descripcion}</Text>
                                                    </View>
                                                )
                                            })
                                            :
                                            <View style={styles.tableRow}>
                                                <Text style={styles.labelContent}>Sin Registros</Text>
                                            </View>
                                    }
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        );
    }

    return (

        <>

            {!loadingData ? (<>


                {/*    <PDFDownloadLink
                    className="text-decoration-none d-inline-block mb-3 mt-4 color-orange"
                    fileName="evaluacion_desempenio"
                    document={<EvaluacionDesempenioPDF />}
                >
                    <i className="fa fa-file-pdf-o me-1" aria-hidden="true"></i>
                    Descargar
                </PDFDownloadLink> */}


                <div className="col-12 col-xl-12 d-flex">
                    <div className="box align-items-stretch w-100">
                        <ul className="nav nav-tabs text-uppercase" role="tablist">
                            <li className="nav-item w-50" role="presentation">
                                <button className="nav-link w-100 active" id="detalle-evaluaciones-tab" data-bs-toggle="tab" data-bs-target="#detalle-evaluaciones" type="button" role="tab" aria-controls="detalle-evaluaciones" aria-selected="true">
                                    Detalle evaluaciones
                                </button>
                            </li>
                            <li className="nav-item w-50" role="presentation">
                                <button className="nav-link w-100" id="tr-tab" data-bs-toggle="tab" data-bs-target="#tr" type="button" role="tab" aria-controls="tr" aria-selected="false">
                                    TR
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane fade show pt-4 active" id="detalle-evaluaciones" role="tabpanel" aria-labelledby="detalle-evaluaciones-tab">
                                <h2 className="h4 tit-section">Detalle </h2>
                                <table className="table table-even table-section table-borderless text-uppercase d-none d-md-table">
                                    <thead>
                                        <tr>
                                            <th className="col-2">Año</th>
                                            <th className="col-2">Nota Calibrada</th>
                                            <th className="col-2">Evaluador</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.CN_EVALDES_LIST !== undefined ? (
                                            Array.isArray(data.CN_EVALDES_LIST) ? (
                                                Children.toArray(data.CN_EVALDES_LIST.map((item) => {
                                                    return (
                                                        <tr >
                                                            <td>{item.anio_evalua === "" ? "-" : item.anio_evalua}</td>
                                                            <td>{item.nota_calibrada === "" ? "-" : item.nota_calibrada}</td>
                                                            <td>{item.evaluador === "" ? "-" : item.evaluador}</td>
                                                        </tr>
                                                    );
                                                }))
                                            ) : (
                                                <tr>
                                                    <td>{data.CN_EVALDES_LIST.anio_evalua === "" ? "-" : data.CN_EVALDES_LIST.anio_evalua}</td>
                                                    <td>{data.CN_EVALDES_LIST.nota_calibrada === "" ? "-" : data.CN_EVALDES_LIST.nota_calibrada}</td>
                                                    <td>{data.CN_EVALDES_LIST.evaluador === "" ? "-" : data.CN_EVALDES_LIST.evaluador}</td>
                                                </tr>
                                            )
                                        ) : (
                                            <tr>
                                                <td colSpan="3">No hay datos</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
                                    {data?.CN_EVALDES_LIST !== undefined ? (
                                        Array.isArray(data.CN_EVALDES_LIST) ? (
                                            Children.toArray(data.CN_EVALDES_LIST.map((item, index) => {
                                                return (
                                                    <div className="accordion-item" >
                                                        <h2 className="accordion-header" id={"accordion" + index}>
                                                            <button className={`accordion-button ${index === 0 ? " " : "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target={"#accordion-item-evaluaciones-" + index} aria-expanded={index === 0 ? "true" : "false"} aria-controls={"accordion-item-evaluaciones-" + index}>
                                                                <strong className="text-uppercase">Evaluación año {item.anio_evalua}</strong>
                                                            </button>
                                                        </h2>
                                                        <div id={"accordion-item-evaluaciones-" + index} className={`accordion-collapse collapse ${index === 0 ? "show" : " "}`} aria-labelledby={"accordion-" + index}>
                                                            <div className="accordion-body p-0">
                                                                <div className="data-list-box data-list-box-even text-uppercase">
                                                                    <ul className="list-column-1 list-unstyled">
                                                                        <li>
                                                                            <span className="data-list-box__title">Nota calibrada</span>
                                                                            <span className="data-list-box__text">{item.nota_calibrada === "" ? "-" : item.nota_calibrada}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="data-list-box__title">Evaluador</span>
                                                                            <span className="data-list-box__text">{item.evaluador === "" ? "-" : item.evaluador}</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }))
                                        ) : (
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="accordion-one">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-item-evaluaciones-1" aria-expanded="true" aria-controls="accordion-item-evaluaciones-1">
                                                        <strong className="text-uppercase">Evaluación año {data.CN_EVALDES_LIST.anio_evalua}</strong>
                                                    </button>
                                                </h2>
                                                <div id="accordion-item-evaluaciones-1" className="accordion-collapse collapse show" aria-labelledby="accordion-one">
                                                    <div className="accordion-body p-0">
                                                        <div className="data-list-box data-list-box-even text-uppercase">
                                                            <ul className="list-column-1 list-unstyled">
                                                                <li>
                                                                    <span className="data-list-box__title">Nota calibrada</span>
                                                                    <span className="data-list-box__text">{data.CN_EVALDES_LIST.nota_calibrada === "" ? "-" : data.CN_EVALDES_LIST.nota_calibrada}</span>
                                                                </li>
                                                                <li>
                                                                    <span className="data-list-box__title">Evaluador</span>
                                                                    <span className="data-list-box__text">{data.CN_EVALDES_LIST.CN_EVALDES_LISTuador === "" ? "-" : data.CN_EVALDES_LIST.evaluador}</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    ) : (
                                        <li>
                                            <span className="data-list-box__title">No hay datos</span>
                                        </li>
                                    )}
                                </div>
                            </div>
                            <div className="tab-pane pt-4 fade" id="tr" role="tabpanel" aria-labelledby="tr-tab">
                                <h2 className="h4 tit-section">TR</h2>
                                <table className="table table-even table-section table-borderless text-uppercase d-none d-md-table">
                                    <thead>
                                        <tr>
                                            <th className="col-2">Año</th>
                                            <th className="col-2">Nota</th>
                                            <th className="col-2">Descripción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.tr !== undefined ? (
                                            Array.isArray(data.tr) ? (
                                                Children.toArray(data.tr.map((item, index) => {
                                                    const indice = (data.tr.length - 1) - index;
                                                    return (
                                                        <tr >
                                                            <td>{data.tr[indice].anio === "" ? "-" : data.tr[indice].anio}</td>
                                                            <td>{data.tr[indice].nota === "" ? "-" : data.tr[indice].nota}</td>
                                                            <td>{data.tr[indice].descripcion === "" ? "-" : data.tr[indice].descripcion}</td>
                                                        </tr>
                                                    );
                                                }))
                                            ) : (
                                                <tr>
                                                    <td>{data.tr.anio === "" ? "-" : data.tr.anio}</td>
                                                    <td>{data.tr.nota === "" ? "-" : data.tr.nota}</td>
                                                    <td>{data.tr.descripcion === "" ? "-" : data.tr.descripcion}</td>
                                                </tr>
                                            )
                                        ) : (
                                            <tr>
                                                <td colSpan="3">No hay datos</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
                                    {data?.tr !== undefined ? (
                                        Array.isArray(data.tr) ? (
                                            Children.toArray(data.tr.map((item, index) => {
                                                const indice = (data.tr.length - 1) - index;
                                                return (
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id={"accordion" + index}>
                                                            <button className={`accordion-button ${index === 0 ? " " : "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target={"#accordion-item-tr-" + index} aria-expanded={index === 0 ? "true" : "false"} aria-controls={"accordion-item-tr-" + index}>
                                                                <strong className="text-uppercase">Evaluación año {data.tr[indice].anio}</strong>
                                                            </button>
                                                        </h2>
                                                        <div id={"accordion-item-tr-" + index} className={`accordion-collapse collapse ${index === 0 ? "show" : " "}`} aria-labelledby={"accordion-" + index}>
                                                            <div className="accordion-body p-0">
                                                                <div className="data-list-box data-list-box-even text-uppercase">
                                                                    <ul className="list-column-1 list-unstyled">
                                                                        <li>
                                                                            <span className="data-list-box__title">Nota calibrada</span>
                                                                            <span className="data-list-box__text">{data.tr[indice].nota === "" ? "-" : data.tr[indice].nota}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="data-list-box__title">Desc</span>
                                                                            <span className="data-list-box__text">{data.tr[indice].descripcion === "" ? "-" : data.tr[indice].descripcion}</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }))
                                        ) : (
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="accordion-one">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-item-evaluaciones-1" aria-expanded="true" aria-controls="accordion-item-evaluaciones-1">
                                                        <strong className="text-uppercase">Evaluación año {data.tr.anio}</strong>
                                                    </button>
                                                </h2>
                                                <div id="accordion-item-evaluaciones-1" className="accordion-collapse collapse show" aria-labelledby="accordion-ad-one">
                                                    <div className="accordion-body p-0">
                                                        <div className="data-list-box data-list-box-even text-uppercase">
                                                            <ul className="list-column-1 list-unstyled">
                                                                <li>
                                                                    <span className="data-list-box__title">Nota calibrada</span>
                                                                    <span className="data-list-box__text">{data.tr.nota === "" ? "-" : data.tr.nota}</span>
                                                                </li>
                                                                <li>
                                                                    <span className="data-list-box__title">Descripción</span>
                                                                    <span className="data-list-box__text">{data.tr.descripcion === "" ? "-" : data.tr.descripcion}</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    ) : (
                                        <li>
                                            <span className="data-list-box__title">No hay datos</span>
                                        </li>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>) : null}
        </>
    );
};

export default EvaluacionDesempenio;