
import { urlsAws } from "../../../resources/foo/api-endpoints";
import { fetchData } from "../helpers/utilities";

const getBannerBenefits = async () => {
    const url = urlsAws.getBannerBenefitsHome;
    return await fetchData({ url });
};

const getBannerNews = async () => {
    const url = urlsAws.getBannerNewsHome;
    return await fetchData({ url, saveCache: false });
};

const getTask = async () => {
    const url = urlsAws.task + "?status=pending";
    const data = await fetchData({ url, saveCache: false });
    return data.tasks;
};

const getNextBirthdays = async () => {
    const url = urlsAws.getNextBirthdays;
    return await fetchData({ url, saveCache: false });
};

const getVacationsAndLicenses = async () => {
    const url = urlsAws.getVacationsAndLicenses;
    return await fetchData({ url, saveCache: false });
};

const getVacations = async () => {
    const url = urlsAws.getVacations;
    return await fetchData({ url, saveCache: false });
};


const getShortcuts = async () => {
    const url = urlsAws.getShortcuts;
    return await fetchData({ url, saveCache: false });
};


export { getBannerBenefits, getTask, getNextBirthdays, getVacationsAndLicenses, getShortcuts, getVacations, getBannerNews };
