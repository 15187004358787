import React, { useEffect, useState } from "react";
import styles from "../../resources/css/style-COL.module.css";

const ListFields = ({
  fields,
  isAddingField,
  isEditingField,
  setIsConfiguring,
  setIdField,
  setIdCertificate,
  getField,
  setField,
  setIsAddingField,
  setIsEditingField,
  certificateName,
  colors,
  fonts,
}) => {
  const addField = () => {
    setIsAddingField(true);
    setIsEditingField(false);
    setIdField(null);
    setField({
      id: null,
      pdffillerid: null,
      code: "",
      name: "",
      page_number: "",
      position_x: "",
      position_y: "",
      text_size: "",
      text_color: null,
      text_font: null,
      box_background_color: "",
      box_width: "",
      box_length: "",
      box_text_align: null,
    });
  };

  const editField = (e, id) => {
    e.preventDefault();
    setIsEditingField(true);
    setIsAddingField(false);
    setIdField(id);
    getField(id);
  };

  const backToCertificate = () => {
    setIsConfiguring(false);
  };

  const getColorName = (id) => {
    let name = "";
    try {
      if (Array.isArray(colors)) {
        name = colors?.find((c) => c.id.toString() === id.toString())?.name;
      }
      return name;
    } catch (error) {
      return name;
    }
  };

  const getFontDescription = (id) => {
    let description = "";
    try {
      if (Array.isArray(fonts)) {
        description = fonts?.find(
          (c) => c.id.toString() === id.toString()
        )?.description;
      }
      return description;
    } catch (error) {
      return description;
    }
  };

  return (
    <>
      <div className={styles["box"] + " " + styles["mb-4"]}>
        <div
          className={
            styles["tit-group"] +
            " " +
            styles["d-flex"] +
            " " +
            styles["justify-content-between"]
          }
        >
          <h2 className={styles["h4"] + " " + styles["tit-section"]}>
            Configurar Campos
          </h2>
          <a
            onClick={addField}
            className={
              styles["h5"] + " " + styles["mt-2"] + " " + styles["fl-normal"]
            }
          >
            <span
              className={styles["d-none"] + " " + styles["d-sm-inline-block"]}
            >
              Agregar
            </span>
            <i
              className={
                styles["fa"] +
                " " +
                styles["fa-plus-square-o"] +
                " " +
                styles["fa-lg"] +
                " " +
                styles["ms-2"]
              }
              aria-hidden="true"
              style={{ cursor: "pointer" }}
            ></i>
          </a>
        </div>

        <div>
          <h6 style={{ display: "inline-block" }}>Certificado:</h6>
          <p style={{ display: "inline-block" }}>
            <strong>{`\u00A0${certificateName}`}</strong>
          </p>
        </div>

        {/* <!-- Escritorio (Tabla) --> */}
        <div className={styles["table-responsive"]}>
          <table
            className={
              styles.table +
              " " +
              styles["table-even"] +
              " " +
              styles["table-section"] +
              " " +
              styles["table-borderless td-none"] +
              " " +
              styles["d-none"] +
              " " +
              styles["d-md-table"]
            }
          >
            {!Array.isArray(fields) ||
            fields?.length === 0 ||
            fields === null ||
            fields === undefined ? (
              <caption>No se encontraron datos asociados.</caption>
            ) : null}

            <thead>
              <tr>
                <th>Id</th>
                <th>Nombre</th>
                <th>Pág.</th>
                <th>Pos. X</th>
                <th>Pos. Y</th>
                <th>Tamaño</th>
                <th>Color</th>
                <th>Fuente</th>
                <th className={styles["text-center"]}>Editar</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(fields)
                ? fields.map((i) => {
                    return (
                      <tr key={i.id}>
                        <td>{i.code}</td>
                        <td>{i.name}</td>
                        <td>{i.page_number}</td>
                        <td>{i.position_x}</td>
                        <td>{i.position_y}</td>
                        <td>{i.text_size}</td>
                        <td>{getColorName(i.text_color)}</td>
                        <td>{getFontDescription(i.text_font)}</td>
                        <td className={styles["text-center"]}>
                          <a onClick={(e) => editField(e, i.id)}>
                            <i
                              className={`${styles["fa"]} ${styles["fa-pencil"]} ${styles["fa-fw"]} ${styles["fa-lg"]}`}
                              aria-hidden="true"
                              style={{ color: "orange", cursor: "pointer" }}
                            ></i>
                          </a>
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>

        {/* <!-- Móvil (Acordeón) --> */}
        <div className={`${styles["accordion"]} ${styles["accordion-flush"]} ${styles["accordion-light"]} ${styles["accordion-table"]} ${styles["d-md-none"]}`}>
          {/* <!-- Acordeón - ítems --> */}
          {Array.isArray(fields)
            ? fields.map((i, index) => {
                return (
                  <div className={styles["accordion-item"]}>
                    <h2 className={styles["accordion-header"]} id={"accordion" + i?.id}>
                      {index === 0 ? (
                        <button
                        className={styles["accordion-button"]}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={"#accordion-item-1" + i?.id}
                          aria-expanded="true"
                          aria-controls={"accordion-item-1" + i?.id}
                        >
                          <strong>ID</strong> {" " + i?.code}
                        </button>
                      ) : (
                        <button
                        className={`${styles["accordion-button"]} ${styles["collapsed"]}`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={"#accordion-item-1" + i?.id}
                          aria-expanded="false"
                          aria-controls={"accordion-item-1" + i?.id}
                        >
                          <strong>ID</strong> {" " + i?.code}
                        </button>
                      )}
                    </h2>
                    <div
                      id={"accordion-item-1" + i?.id}
                      className={`${styles["accordion-collapse"]} ${styles["collapsed"]} ${styles["show"]}`}
                      aria-labelledby="accordion"
                    >
                      <div className={`${styles["accordion-body"]} ${styles["p-0"]}`}>
                        {/* <!-- cuerpo item --> */}
                        <div className={`${styles["data-list-box"]} ${styles["data-list-box-even"]}`}>
                          <ul className={`${styles["data-list-section--item"]} ${styles["list-unstyled"]}`}>
                            <li>
                              <span className={styles["data-list-box__title"]}>Nombre</span>
                              <span className={styles["data-list-box__text"]}>{i?.name}</span>
                            </li>
                            <li>
                              <span className={styles["data-list-box__title"]}>Página</span>
                              <span className={styles["data-list-box__text"]}>
                                {i?.page_number}
                              </span>
                            </li>
                            <li>
                              <span className={styles["data-list-box__title"]}>
                                Posición X
                              </span>
                              <span className={styles["data-list-box__text"]}>
                                {i?.position_x}
                              </span>
                            </li>
                            <li>
                              <span className={styles["data-list-box__title"]}>
                                Posición Y
                              </span>
                              <span className={styles["data-list-box__text"]}>
                                {i?.position_y}
                              </span>
                            </li>
                            <li>
                              <span className={styles["data-list-box__title"]}>Editar</span>
                              <span className={styles["data-list-box__text"]}>
                                <a onClick={(e) => editField(e, i.id)}>
                                  <i
                                    className={
                                      styles["fa"] +
                                      " " +
                                      styles["fa-pencil"] +
                                      " " +
                                      styles["fa-fw"] +
                                      " " +
                                      styles["fa-lg"]
                                    }
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span>
                            </li>
                          </ul>
                        </div>
                        {/* <!-- fin cuerpo item --> */}
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
          {/* <!-- fin items acordeón --> */}
        </div>
      </div>

      {!isEditingField && !isAddingField ? (
        <div className={`${styles["row"]} ${styles["justify-content-start"]} ${styles["mt-4"]}`}>
          <div className={`${styles["col-12"]} ${styles["col-md-auto"]}`}>
            <button
              type="button"
              className={`${styles["btn"]} ${styles["btn-link"]}`}
              onClick={backToCertificate}
            >
              Volver
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ListFields;
