import DatosPersonalesARG from "./datos personales/datos-personales-ARG";
import VacacionesArg from "./solicitud vacaciones/vacaciones-arg";
import ListadoAprobacionVacaciones from "./aprobacion vacaciones/listado-aprobacion-vacaciones";
import Giftcard from "./giftcard/giftcard";
import Goals from "./goals/goals";
import Discounts from "./discounts/discounts";
import Licencias from "./solicitud licencias/licencias-arg";
import LicenciasUy from "./solicitud licencias-UY/licencias-uy";
import AprobacionLicencias from "./aprobacion licencias/aprobacion-licencias";
import Recibos from "../components/Recibos ARG/recibos";
import Public from "./public/public";
import Register from "./firstLogin/register";
import Activacion from "./firstLogin/activate";
import ResendMail from "./firstLogin/resendMail";
import DatosLaboralesCOL from "./datos laborales/datos-laborales";
import FormacionAcademica from "./formacion academica/formacion-academica";
import Home from "./home-Arg/Home-ARG";
import Idiomas from "./idiomas/idiomas";
import OtrosEstudios from "./otros estudios/otros-estudios";
import ExperienciaLaboral from "./experiencia laboral/experiencia-laboral";
import AbsenceAction from "./absence-mail-arg/absence-action";
import LicenseAction from "./license-mail-ARG/license-action-mail";
import ExtractoCredito from "./ExtractoCredito/ExtractoCredito";
import AprobacionCredito from "./AprobacionCredito/aprobacioncredito-COL";
import BloquearCredito from "./BloquearCredito/bloquearcredito";
import DesprendiblePago from "./DesprendiblePago/DesprendiblePago";
import CertificadoIngresosRetencionesAdmin from "./CertificadoIngresosRetencionesAdmin";
import CertificadoIngresosRetencionesEmpleado from "./CertificadoIngresosRetencionesEmpleado";
import CodigoEticaEmpleado from "./CodigoEticaEmpleado";
import CodigoEticaAdmin from "./CodigoEticaAdmin";
import ConflictoInteres from "./ConflictoInteres";
import DatosPersonalesCOL from "./datos personales col/datos-personales";
import DatosPersonalesPE from "./datosPersonalesPe/datos-personales";
import ContactosEmergenciaYFamiliares from "./contactos emergencia y familiares";
import OldNews from './News/news';
import OldNewsManager from './NewsManager/news-manager';
import ArbolModulos from "./panel-administracion-regional/ArbolModulos";
import AsociarRolesPaginas from "./PaginasPorRoles/asociar-roles-paginas";
import CalendarioGestionAsistencia from "./calendar/gestion-asistencia";
import CalendarioAprobacionAsistencia from './calendar/aprobacion-asistencia';
import CalendarHistory from "./calendar/calendario-historico";
import ApprovalsHistory from "./calendar/aprobacion-historico/history-approvals";
import MisHorarios from "./calendar/mis-horarios";
import AprobacionHorarios from "./calendar/aprobacion-horarios";

import Inicio from "./Inicio/pages/Home";
import Noticias from "./noticias";
import NoticiasDetalle from "./noticias-detalle";
import CrearNoticias from "./crear-noticias";
import DatosLaborales from "./datos-laborales";
import DatosPersonalesCHL from "./datos-personales";
import Liquidacion from "./liquidacion";
import Remuneracion from "./remuneracion";
import Ausencias from "./ausencias";
import AprobarVacaciones from "./Vacations/aprobar-vacaciones";
import SolicitarVacaciones from "./Vacations/solicitar-vacaciones";
import VerDetalle from "./Vacations/ver-detalle-vacaciones";
import ReemplazoVacaciones from "./Vacations/reemplazo-vacaciones";
import VacacionesTareas from "./Vacations/vacaciones-tareas";
import PerformanceEvaluation from "./evaluacion-empleados";
import MisDocumentos from "./mis-documentos";
import Solicitudes from "./mis-solicitudes";
import Anticipo from "./anticipo";
import Bonos from "./bono";
import Compliance from "./Compliance/pages/Compliance";
import ComplianceTaskEmployee from "./Compliance/pages/TaskEmployee";
import ComplianceTaskManager from "./Compliance/pages/TaskManager";
import ReportingChannel from "./reportingChannel/pages";
import TiempoBeneficios from "./FreeTime/tiempo-beneficios";
import TiempoCanjear from "./FreeTime/tiempo-canjear";
import TiempoHistorial from "./FreeTime/tiempo-historial";
import TiempoEquipo from "./FreeTime/tiempo-equipo";
import TiempoTareas from "./FreeTime/tiempo-tareas";
import MiEquipo from "./miEquipo/mi-equipo";
import { Dashboard } from "./Dashboard/pages";
import GeoVictoria from "./geo-victoria";
import VerCartola from "./ver-cartola";
import IndexNoticias from "./index-noticias";
import ModificarNoticias from "./modificar-noticias";
import HelpCenter from "./helpCenter/pages";
import HelpCenterRegional from "./helpCenter-Regional/pages";
import { PersonalVoucher } from "./personalVoucher/pages";
import { AnnualBond } from "./AnnualBond/pages";
import PanelAdministracion from "./panel-administracion";
import PanelAdministracionRoles from "./panel-administracion-roles";
import CarpetaDigitalDt from "./carpeta-digital-dt";
import LiquidacionDt from "./liquidacion-dt";
import AnexoYRemuneracionDt from "./anexo-y-remuneracion-dt";
import VacacionesDt from "./Vacations/vacaciones-dt";
import AusenciasDt from "./ausencias-dt";
import Encuestas from "./encuestas";
import Politicas from "./politicas";
import FichaIngreso from "./fichaIngreso/pages";
import BandejaEntrada from './BandejaEntrada';
import VacacionesCol from "./VacacionesCOL";
import MiEquipoRegional from "./mi equipo/MiEquipo";
import { Benefit } from "./Beneficios/pages";
import MyBenefit from "./Beneficios/pages/Benefits/benefits";
import BenefitDetail from "./Beneficios/pages/Benefits/benefitsDetail";

import CencoTalent from "./CencoTalent/pages";
import VacacionesJefeIndex from "./VacacionesCOL/jefe-index";
import AprobarVacacinesPublic from "./VacacionesCOL/aprobar-vacaciones-public";
import CertificadosCol from "./certificados col";
import { VerifyDocument } from "./verifyDocument/pages";

import DatosPersonalesUY from './Datos-personales-UY/datos-personales-UY'
import RecibosUY from "./Recibos-UY/receipts-uy";

import CertificadosARG from "./certificados-arg/certificates";
import RegistroViaje from "./registroViajes-UY/RegistroViaje";
import EsquemaHibridoAprobacion from "./EsquemaHibrido/index-aprobacion";
import EsquemaHibridoSolicitud from "./EsquemaHibrido/index-solicitud";
import AprobacionRegional from "./Aprobaciones-regional/aprobacion-public";
import { MaintenancePage } from "./Maintenance/MaintenancePage";
import { MaintenanceCmp } from "./elements/maintenance-screen/MaintenanceCmp";
import VacacionesUy from "./solicitudVacaciones-UY/vacaciones-uy";
import RenunciaVoluntaria from "./renuncia-voluntaria-col";
import Seguros from "./Seguros/seguros";
import ReplacementAssignment from "./asignacion-reemplazo/replacement-assignment";
import ReplacementsManagement from "./reemplazos-manager/replacements";

import { SubscriptionSetting } from "./subscriptionSetting";

import Organigrama from "./organigrama/organigrama";
import BusquedaOrganigrama from "./Organigrama-busqueda/busqueda"
import LicenciasHistorico from "./solicitud licencias-UY/licencias-historico";
// import BusquedaOrganigrama from "./Organigrama-busqueda/busqueda"
import { TeamDashboardPage } from "../pages/teamDashboard";
import MyNewsList from "./News/pages/News/newsList";
import { News } from "./News/pages";
import NewsDetail from "./News/pages/News/newsDetail";
import { CoursesPage } from "../pages/courses";
import { InterestSitesPage } from "../pages/interestSites";
import { InterestSitesMaintenancePage } from "../pages/interestSitesMaintenance";

const components = {
  "Ausencias-AR": Ausencias,
  "DatosPersonales-AR": DatosPersonalesARG,
  "DatosPersonales-UY": DatosPersonalesUY,
  "RegistroViaje-UY": RegistroViaje,
  "Vacaciones-AR": VacacionesArg,
  "Certificados-AR": CertificadosARG,
  "Giftcard-AR": Giftcard,
  "Seguros-AR": Seguros,
  "Goals-AR": Goals,
  "Discounts-AR": Discounts,
  "ListadoAprobacionVacaciones-AR": ListadoAprobacionVacaciones,
  "ListadoAprobacionVacaciones-CL": ListadoAprobacionVacaciones,
  "ListadoAprobacionVacaciones-CO": ListadoAprobacionVacaciones,
  "Licencias-AR": Licencias,
  "Licencias-UY": LicenciasUy,
  "LicenciasHistorico-UY": LicenciasHistorico,
  "AprobacionLicencias-AR": AprobacionLicencias,
  "AprobacionLicencias-CL": AprobacionLicencias,
  "AprobacionLicencias-CO": AprobacionLicencias,
  "Recibos-AR": Recibos,
  "Recibos-UY": RecibosUY,
  "Public": Public,
  "Register": Register,
  "Activacion": Activacion,
  "ResendMail": ResendMail,
  "Home-AR": Inicio,
  "Home-UY": Inicio,
  "Home-CL": Home,
  "Home-CO": Inicio,
  "Home-PE": Inicio,
  "DatosLaborales-CO": DatosLaboralesCOL,
  "FormacionAcademica-CO": FormacionAcademica,
  "FormacionAcademica-PER": FormacionAcademica,
  "Idiomas-CO": Idiomas,
  "OtrosEstudios-CO": OtrosEstudios,
  "ExperienciaLaboral-CO": ExperienciaLaboral,
  "ExperienciaLaboral-PER": ExperienciaLaboral,
  "AbsenceAction": AbsenceAction,
  "LicenseAction": LicenseAction,
  "ExtractoCredito-CO": ExtractoCredito,
  "AprobacionCredito-CO": AprobacionCredito,
  "BloquearCredito-CO": BloquearCredito,
  "DesprendiblePago-CO": DesprendiblePago,
  "CertificadoIngresosRetencionesAdmin-CO": CertificadoIngresosRetencionesAdmin,
  "CertificadoIngresosRetencionesEmpleado-CO": CertificadoIngresosRetencionesEmpleado,
  "CodigoEticaEmpleado-CO": CodigoEticaEmpleado,
  "CodigoEticaAdmin-CO": CodigoEticaAdmin,
  "ConflictoInteres-CO": ConflictoInteres,
  "DatosPersonales-CO": DatosPersonalesCOL,
  "DatosPersonales-PER": DatosPersonalesCOL,
  "ContactosEmergenciaYFamiliares-CO": ContactosEmergenciaYFamiliares,
  "ContactosEmergenciaYFamiliares-PER": ContactosEmergenciaYFamiliares,
  "Inicio": Inicio,
  "Noticias": Noticias,
  "NoticiasDetalle": NoticiasDetalle,
  "DatosLaborales": DatosLaborales,
  "DatosPersonales": DatosPersonalesCHL,
  "Liquidacion": Liquidacion,
  "Remuneracion": Remuneracion,
  "Ausencias": Ausencias,
  "AprobarVacaciones": AprobarVacaciones, //si viene eqi el menu tiene que acceder a este
  "SolicitarVacaciones": SolicitarVacaciones, //si no viene eqi el menu tiene que acceder a este
  "VerDetalle": VerDetalle,
  "ReemplazoVacaciones": ReemplazoVacaciones,
  "VacacionesTareas": VacacionesTareas,
  "VerCartola": VerCartola,
  "GeoVictoria": GeoVictoria,
  "PerformanceEvaluation": PerformanceEvaluation,
  "MisDocumentos": MisDocumentos,
  "MiEquipo": MiEquipo,
  "Dashboard": TeamDashboardPage,
  "Benefit": Benefit,
  "Solicitudes": Solicitudes,
  "Bonos": Bonos,
  "Anticipo": Anticipo,
  "Compliance": Compliance,
  "ComplianceTaskEmployee": ComplianceTaskEmployee,
  "ComplianceTaskManager": ComplianceTaskManager,
  "MisBeneficios": MyBenefit,
  "MisBeneficiosB": BenefitDetail,
  "ReportingChannel": ReportingChannel,
  "TiempoBeneficios": TiempoBeneficios,
  "TiempoCanjear": TiempoCanjear,
  "TiempoHistorial": TiempoHistorial,
  "TiempoEquipo": TiempoEquipo,
  "TiempoTareas": TiempoTareas,
  "HelpCenter": HelpCenter,
  "HelpCenterRegional": HelpCenterRegional,
  "PersonalVoucher": PersonalVoucher,
  "AnnualBond": AnnualBond,
  "IndexNoticias": IndexNoticias,
  "ModificarNoticias": ModificarNoticias,
  "CrearNoticias": CrearNoticias,
  "PanelAdministracion": PanelAdministracion,
  "PanelAdministracionRoles": PanelAdministracionRoles,
  "Encuestas": Encuestas,
  "Politicas": Politicas,
  "CarpetaDigitalDt": CarpetaDigitalDt,
  "LiquidacionDt": LiquidacionDt,
  "AnexoYRemuneracionDt": AnexoYRemuneracionDt,
  "VacacionesDt": VacacionesDt,
  "AusenciasDt": AusenciasDt,
  "FichaIngreso": FichaIngreso,
  "BandejaEntrada": BandejaEntrada,
  "Ausencias-PER": Ausencias,
  "Vacaciones-PER": VacacionesArg,
  "Giftcard-PER": Giftcard,
  "Goals-PER": Goals,
  "Discounts-PER": Discounts,
  "ListadoAprobacionVacaciones-PER": ListadoAprobacionVacaciones,
  "Licencias-PER": Licencias,
  "AprobacionLicencias-PER": AprobacionLicencias,
  "Recibos-PER": Recibos,
  "Home-PER": Home,

  "VacacionesCol-CO": VacacionesCol,
  "VacacionesJefeIndex-CO": VacacionesJefeIndex,
  "AprobarVacacinesPublic-CO": AprobarVacacinesPublic,
  "CertificadosCol-CO": CertificadosCol,
  "Noticias-AR": OldNews,
  "CanalDeComunicacion-AR": OldNewsManager,
  "VerifyDocument": VerifyDocument,
  "ArbolModulos": ArbolModulos,
  "AsociarRolesPaginas": AsociarRolesPaginas,
  "GestionAsistencia-AR": CalendarioGestionAsistencia,
  "AprobacionAsistencia-AR": CalendarioAprobacionAsistencia,
  "CalendarHistory": CalendarHistory,
  "MisHorarios": MisHorarios,
  "AprobacionHorarios": AprobacionHorarios,
  "ApprovalsHistory": ApprovalsHistory,
  "ListaMiEquipo": MiEquipoRegional,
  "EsquemaHibridoAprobacion-CO": EsquemaHibridoAprobacion,
  "EsquemaHibridoSolicitud-CO": EsquemaHibridoSolicitud,
  "AprobacionRegional": AprobacionRegional,
  "CodigoEticaEmpleado-UY": CodigoEticaEmpleado,
  "CodigoEticaAdmin-UY": CodigoEticaAdmin,
  "ConflictoInteres-UY": ConflictoInteres,
  "CencoTalentos": CencoTalent,
  "Vacaciones-UY": VacacionesUy,
  "Organigrama": Organigrama,
  "RenunciaVoluntaria-CO": RenunciaVoluntaria,
  "MaintancePage": MaintenancePage,
  "MaintanceComponent": MaintenanceCmp,
  "ReplacementAssignment": ReplacementAssignment,
  "ReplacementsManagement": ReplacementsManagement,
  "Dashboards-AR": TeamDashboardPage,
  "Dashboards-CO": TeamDashboardPage,
  "Subscription": SubscriptionSetting,
  "BusquedaOrganigrama": BusquedaOrganigrama,
  "MisNoticias": MyNewsList,
  "MisNoticiasB": NewsDetail,
  "News": News,
  "CursosNormativos": CoursesPage,
  "LinksInteres": InterestSitesPage,
  "MaintenanceLinksInteres": InterestSitesMaintenancePage,
  "CodigoEticaEmpleado-PE": CodigoEticaEmpleado,
  "CodigoEticaAdmin-PE": CodigoEticaAdmin,
  "ConflictoInteres-PE": ConflictoInteres,
  "DatosPersonales-PE": DatosPersonalesPE,
  "DatosLaborales-PE": DatosLaboralesCOL,
};

export default components;
