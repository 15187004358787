import Auth from "@aws-amplify/auth";
import { urls, urlsAws } from "../../resources/foo/api-endpoints";
import { NewsLetterPost } from "./types";

const fetchData = async (url: string, method: string, body: NewsLetterPost) => {
  const auth = await Auth.currentSession();
  const requestOptions = {
    method,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: auth.getIdToken().getJwtToken(),
      AccessToken: auth.getAccessToken().getJwtToken(),
    },
    body: body ? JSON.stringify(body) : null,
  };
  return await fetch(url, requestOptions)
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
      return false;
    });
};
const services = {
  getNewsLetter: async (jwt = "") => {
    const params = jwt ? "?token=" + jwt : "";
    const data = await fetchData(urls.newsLetter + params, "GET", false);
    if (data && data.response) return data.response;
    else return false;
  },
  postNewsLetter: async (body: NewsLetterPost, jwt = "") => {
    const params = jwt ? "?token=" + jwt : "";
    const data = await fetchData(urls.newsLetter + params, "POST", body);
    if (data && data.response) return data.response;
    else return false;
  },

  async getRegionalNewsLetter() {
    try {
      const response = await fetch(urls.regionalNewsLetter);
      const data = await response.json();
      return data.response;
    } catch (err) {
      return false;
    }
  },
};

export default services;
