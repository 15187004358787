import { useContext, useState } from "react";
import { JobInfo } from "../interfaces/applyInterface";
import {
    paginationHelper,
    cacheHelpers,
    normalizeStrings,
    getRequisitions,
} from "../helpers";
import { ApplyContext } from "../context";
import { useCencoUI } from "./useCencoUI";

export const useCencoTalent = () => {
    const elementsPerPage = 4;
    const { startPaginateArray } = paginationHelper();
    const { getApplyListFromSessionStorage, saveApplyListInSessionStorage } =
        cacheHelpers();
    const {
        paginationPage,
        setHasError,
        setIsLoading,
        setPage,
        setPaginationPage,
    } = useCencoUI();

    const {
        //Variables y Constantes
        activeJob,
        applyList,
        filterArea,
        filterFlag,
        filterSearchInput,
        filterUbication,
        filterworkDay,
        searchFilters,
        searchFiltersV2,

        //Funciones y Metodos
        setActiveAllAplys,
        setActiveRequisition,
        setApplyList,
        setSearchFilters,
        setSearchFiltersV2,
        setToggleActiveApply,
        setFilterArea,
        setFilterFlag,
        setFilterSearchInput,
        setFilterUbication,
        setFilterworkDay,
    } = useContext(ApplyContext);

    const activeApplys = applyList?.filter((item) => item.active) ?? [];

    /**************INIT SEARCH APPLY****************/

    const startInitiCencoTalent = async (): Promise<void> => {
        const cacheApplyList = getApplyListFromSessionStorage();

        if (Object.keys(cacheApplyList).length > 0) {
            const { searchFilters, searchFiltersV2, jobRequisitions } =
                cacheApplyList;

            setSearchFilters(searchFilters);
            setSearchFiltersV2(searchFiltersV2);
            setApplyList(jobRequisitions);
        } else {
            const response = await getRequisitions(setIsLoading, setHasError);
            if (!response) {
                setHasError(true);
                return;
            }
            const { searchFilters, searchFiltersV2, jobRequisitions } =
                response;

            if (Object.keys(jobRequisitions).length > 0)
                saveApplyListInSessionStorage(response);
            setSearchFilters(searchFilters);
            setSearchFiltersV2(searchFiltersV2);
            setApplyList(jobRequisitions);
        }
        setIsLoading(false);
    };

    /**************END SEARCH APPLY****************/

    /**************SEARCH APPLY RESULTS****************/
    const calculateNumberOfElements = (): number => {
        const totalElements = activeApplys?.length ?? 0;

        const totalPages = Math.ceil(totalElements / elementsPerPage);

        let elementsOnPage;
        if (paginationPage === totalPages) {
            elementsOnPage = totalElements - elementsPerPage * (totalPages - 1);
        } else {
            elementsOnPage = Math.min(
                elementsPerPage,
                totalElements - elementsPerPage * (paginationPage - 1)
            );
        }

        return elementsOnPage;
    };
    /**************SEARCH APPLY RESULTS****************/

    const handleSetActiveRequisition = (jobRequisition: JobInfo): void => {
        setIsLoading(true);
        setActiveRequisition(jobRequisition);
        resetForn();
        setPage("APPLY_DETAILS");
        setIsLoading(false);
    };

    /**************INIT SEARCH CARD****************/

    const searchApply = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setPaginationPage(1);

        const normalizedSearchInput =
            normalizeStrings(filterSearchInput).toLowerCase();
        const applyListFiltred =
            applyList?.filter((item) => {
                const normalizedTitle = normalizeStrings(
                    item.title
                ).toLowerCase();
                const normalizedDescription = normalizeStrings(
                    item.description
                ).toLowerCase();

                const includesSearchInput = normalizedSearchInput
                    ? normalizedTitle.includes(normalizedSearchInput) ||
                      normalizedDescription.includes(normalizedSearchInput)
                    : true;

                const hasBusinessArea = filterArea
                    ? item.area === filterArea
                    : true;
                const hasWorkDay = filterworkDay
                    ? item.workingHours === filterworkDay
                    : true;
                const hasUbication = filterUbication
                    ? item.ubication === filterUbication
                    : true;
                const hasFlag = filterFlag ? item.flag === filterFlag : true;

                return (
                    includesSearchInput &&
                    hasBusinessArea &&
                    hasWorkDay &&
                    hasUbication &&
                    hasFlag
                );
            }) ?? [];

        const idsApplyListFiltred = applyListFiltred.map((item) => item.id);

        setToggleActiveApply(idsApplyListFiltred);
    };

    const resetForn = () => {
        setFilterSearchInput("");
        setFilterArea("");
        setFilterFlag("");
        setFilterworkDay("");
        setFilterUbication("");

        setActiveAllAplys();
        setPaginationPage(1);
    };

    /**************END SEARCH CARD****************/

    return {
        // Variables y Constantes
        activeApplys,
        activeJob,
        applyList,
        elementsPerPage,
        filterArea,
        filterFlag,
        filterSearchInput,
        filterUbication,
        filterworkDay,
        searchFilters,
        searchFiltersV2,

        //Funciones y Metodos
        calculateNumberOfElements,
        handleSetActiveRequisition,
        resetForn,
        searchApply,
        setActiveRequisition,
        setFilterArea,
        setFilterFlag,
        setFilterSearchInput,
        setFilterUbication,
        setFilterworkDay,
        startInitiCencoTalent,
        startPaginateArray,
    };
};
