import { useMemo } from "react";
import { ChartBar } from "../../../../components/chart";
import { PerformanceChartProps } from "./performanceEvaluation.types";
import { Line } from "react-chartjs-2";
import { chartColors, lastFourYearsReverse } from "../../../../utils";
import { useTableProvider } from "../../context/DashboardTableContext";

export const PerformanceChart = (props: PerformanceChartProps) => {
  const { chartInfo, isLoading } = props;
  const { performanceChart }=useTableProvider()
  const xAxisLabelsBar = ["AA", "AB", "AC", "BA", "BB", "BC", "CA", "CB", "CC"];

  const xAxisLabelsLine = [
    "Bajo lo esperado",
    "En lo esperado",
    "Sobre lo esperado",
  ];

  
  const assessmentToRange: Record<string, string> = {
    AA: "Sobre lo esperado",
    AB: "Sobre lo esperado",
    BA: "Sobre lo esperado",
    BB: "En lo esperado",
    BC: "Bajo lo esperado",
    CB: "Bajo lo esperado",
    CC: "Bajo lo esperado",
  };

  const calculatePercentages = (): number[] => {
    const counts: Record<string, number> = {
      "Bajo lo esperado": 0,
      "En lo esperado": 0,
      "Sobre lo esperado": 0,
    };

    chartInfo?.forEach((item) => {
      const range = assessmentToRange[item.assessment];
      if (range) {
        counts[range]++;
      }
    });

    const total = chartInfo?.length || 0;

    const percentages = xAxisLabelsLine.map((label) => {
      return total > 0 ? (counts[label] / total) * 100 : 0;
    });

    return percentages;
  };

  const data = {
    labels: xAxisLabelsLine,
    tension: 0.3,
    datasets: [
      {
        label: "Distribución Real",
        data: calculatePercentages(),
        backgroundColor: chartColors[0],
        borderColor: chartColors[0],
        tension: 0.3,
      },
      {
        label: "Distribución Esperada",
        data: calculatePercentages(),
        backgroundColor: chartColors[1],
        borderColor: chartColors[1],
        tension: 0.3,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
          stepSize: 1,
        },
      },
    },
  };

  const dataBar = useMemo(() => {
    const counts = xAxisLabelsBar.map(() => 0);

    if (!chartInfo) return counts;

    chartInfo.forEach((item) => {
      const index = xAxisLabelsBar.indexOf(item.assessment);
      if (index !== -1) {
        counts[index]++;
      }
    });

    return counts;
  }, [chartInfo]);

  const assessmentHistoryData = {
    labels: lastFourYearsReverse,
    datasets: 
    performanceChart?.map((data, index) => ({
      ...data,
      backgroundColor: chartColors[index],
        borderColor: chartColors[index],
        tension: 0.3,
    })) || []
  };

  if (isLoading)
    return (
      <div className="text-center">
        <span className="spinner-border spinner-border-sm" />
        <p> Cargando información... </p>
      </div>
    );

  return (
    <>
      <div className="box mb-4">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h4 className="text-primary fw-bold header-card tit">
              Evaluación de Desempeño
            </h4>
            <div className="line"></div>
          </div>
        </div>

        <div className="table-responsive">
          <ChartBar xAxisLabels={xAxisLabelsBar} values={dataBar} />
        </div>
      </div>

      <div className="box mb-4">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h4 className="text-primary fw-bold header-card tit">
              Distribucion de Evaluación de Desempeño
            </h4>
            <div className="line"></div>
          </div>
        </div>
        <div className="table-responsive">
          <Line data={data} options={options} height={90} />
        </div>
      </div>

      <div className="box mb-4">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h4 className="text-primary fw-bold header-card tit">
              Evolución de Evaluación de Desempeño
            </h4>
            <div className="line"></div>
          </div>
        </div>

        <div className="table-responsive">
          <Line data={assessmentHistoryData} options={options} height={90} />
        </div>
      </div>
    </>
  );
};
