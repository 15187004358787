import React, { useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import DataServices from "../../services/data-test.service";
import { BsExclamationCircle } from 'react-icons/bs';
import { Barcode } from './barcode';

import LogoJumbo from '../../resources/images/logo-jumbo.png';
import LogoDisco from '../../resources/images/logo-disco.png';
import LogoVea from '../../resources/images/logo-vea.png';
import LogoEasy from '../../resources/images/Logo Easy.png';


const Discounts = (props) => {

    const [info, setInfo] = useState({});
    const [superPurchases, setSuperPurchases] = useState([]);
    const [easyPurchases, setEasyPurchases] = useState([]);
    const [comerceSuper, setComerceSuper] = useState('super');
    const [sortOrder, setSortOrder] = useState('ASC');
    const [_superDates, _setSuperList] = useState([]);
    const [loadingDiscounts, setLoadingDiscounts] = useState(false);
    const [noSuperPurchases, setNoSuperPurchases] = useState(false);
    const [noEasyPurchases, setNoEasyPurchases] = useState(false);
    const [noDiscountInfo, setNoDiscountInfo] = useState(false);
    const [passedValue, setPassedValue] = useState(null);
    const isFirstRender = useRef(true);

    useEffect(() => {

        if (isFirstRender.current) {
            //console.log(loadingDiscounts)
            fetchData();
            const setBarcode = () => {

            }
            isFirstRender.current = false;
            return;
        }

    }, [loadingDiscounts])

    const fetchData = () => {
        DataServices.getDescuentos(setLoadingDiscounts, setInfo, setNoDiscountInfo, setSuperPurchases, setEasyPurchases, setNoSuperPurchases, setNoEasyPurchases, setPassedValue)
    }
    const noPurchasesInfo = () => {
        return <tr>
            <td className="alert-col" colSpan={6}><i>*Sin movimientos recientes</i></td>
        </tr>;
    }
    const showLoadingScreen = () => {
        return <div className="full-spinner discount-spinner">
            <div className="spinner-border text-primary"></div>
        </div>
    }



    const displaySuperTable = () => {
        return superPurchases.map((purchase) => {
            return (
                <tr key={purchase.id}>
                    <td className="tdata">${purchase.discount}</td>
                    <td className="tdata">{formatDate(purchase.purchaseDate)}</td>
                    <td className="tdata">${purchase.amount}</td>
                    <td className="tdata">{purchase.terminal}</td>
                    <td className="tdata">{purchase.transaction}</td>
                </tr>
            )
        })
    }

    const displayEasyTable = () => {
        return easyPurchases.map((purchase) => {
            return (
                <tr key={purchase.id}>
                    <td className="tdata">${purchase.discount}</td>
                    <td className="tdata">{formatDate(purchase.purchaseDate)}</td>
                    <td className="tdata">${purchase.amount}</td>
                    <td className="tdata">{purchase.terminal}</td>
                    <td className="tdata">{purchase.transaction}</td>
                </tr>
            )
        })
    }

    const sort = () => {
        if (comerceSuper === 'super') {
            toggleSortOrder();
            if (sortOrder === 'ASC') {
                setSuperPurchases([...superPurchases].sort((a, b) => (a.purchaseDate > b.purchaseDate) ? 1 : ((b.purchaseDate > a.purchaseDate) ? -1 : 0)))
            } else if (sortOrder === 'DESC') {
                setSuperPurchases([...superPurchases].sort((a, b) => (a.purchaseDate < b.purchaseDate) ? 1 : ((b.purchaseDate < a.purchaseDate) ? -1 : 0)))

            } else {
                return
            }

        } else if (comerceSuper === 'easy') {
            toggleSortOrder();
            if (sortOrder === 'ASC') {
                setEasyPurchases([...easyPurchases].sort((a, b) => (a.purchaseDate > b.purchaseDate) ? 1 : ((b.purchaseDate > a.purchaseDate) ? -1 : 0)))
            } else if (sortOrder === 'DESC') {
                setEasyPurchases([...easyPurchases].sort((a, b) => (a.purchaseDate < b.purchaseDate) ? 1 : ((b.purchaseDate < a.purchaseDate) ? -1 : 0)))
            } else {
                return
            }
        } else {
            return
        }
    }

    const toggleSortOrder = () => {
        if (sortOrder === 'ASC') {
            setSortOrder('DESC');
        } else if (sortOrder === 'DESC') {
            setSortOrder('ASC');
        } else {
            return
        }
    }
    const getComerce = e => {
        setComerceSuper(e.target.value)
    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    const showDiscount = () => {
        if (!noDiscountInfo) {
            return <div className="discount">
                <div className="row space-between">
                    <div className="col-sm-12 col-md-12 col-lg-3 box">
                        <h2 className="h4 tit-section m-0">Código de barras</h2>
                        <div className="discount-card test-card">
                            <div className="barcode">
                                <Barcode data={info?.barCode} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-8 box mt-sm-4 mt-lg-0">
                        <div className="discount">
                        <h2 className="h4 tit-section m-0">Montos Disponibles</h2>
                            <div className="row">
                                <div className="col-lg-7 col-sm-12 first-card">
                                    <div className="discount-card discount-card-border">
                                        <div className="discount-header">Supermercados</div>
                                        <div className="discount-content color-orange">${info.super?.available}</div>
                                        <div className="img">
                                            <img src={LogoJumbo} alt="" />
                                            <img src={LogoDisco} alt="" />
                                            <img src={LogoVea} alt="" />
                                        </div>
                                        <div className="discount-limit discount-limit footer">Límite: ${info.super?.limit}</div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <div className="discount-card discount-card-easy">
                                        <div className="discount-header">Easy</div>
                                        <div className="discount-content color-orange">${info.easy?.available}</div>
                                        <div className="img">
                                            <img src={LogoEasy} alt="" />
                                        </div>
                                        <div className="discount-limit discount-limit footer">Límite: ${info.easy?.limit}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="discount-container box">
                        <div className="menu mb-4 row">
                            <h2 className="h4 tit-section m-0 col-sm-4 col-lg-7">Movimientos</h2>
                            <div className="filters me-0 movements-comerce col-sm-8 col-lg-4">
                                <div className="form-check form-check-inline movements-comerce-option">
                                    <input className="form-check-input" onChange={getComerce} checked={comerceSuper == "super" ? true : false} type="radio" value="super" />
                                    <label className="label-check form-check-label">Supermercados</label>
                                </div>
                                <div className="form-check form-check-inline me-0 movements-comerce-option">
                                    <input className="form-check-input" onChange={getComerce} checked={comerceSuper == "easy" ? true : false} type="radio" value="easy" />

                                    <label className="label-check form-check-label">Easy</label>

                                </div>
                            </div>
                        </div>
                        <div className="table-card table-header-fixed">
                            <div className="table-responsive">
                                <table className="table table-even table-section table-borderless">
                                    <thead className="table-header-border">
                                        <tr>
                                            <th className="cap">Descuento</th>
                                            <th className="cap arrow">
                                                Fecha 
                                                <a className="pointer" onClick={() => { sort() }}> 
                                                    {sortOrder == 'ASC' ? <i className='fa fa-solid fa-long-arrow-up color-orange' /> : sortOrder == 'DESC' ? <i className="fa fa-solid fa-long-arrow-down color-orange" /> : ''}
                                                </a>
                                            </th>
                                            <th className="cap">Importe</th>
                                            <th className="cap">N°Caja</th>
                                            <th className="cap">N°Ticket</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {comerceSuper === 'super' && !noSuperPurchases ? displaySuperTable() : comerceSuper === 'easy' && !noEasyPurchases ? displayEasyTable() : noPurchasesInfo()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        } else {
            return <div className="alert alert-info alert-dismissible fade show text-center" role="alert">
                <BsExclamationCircle />  No se encontraron datos asociados
            </div>
        }
    }

    return (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home color-orange" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Descuentos en linea de cajas
                    </li>
                </ol>
            </nav>
            <section className="noticias mb-3">
                <h1>Descuentos en linea de cajas</h1>
                <div className="col-12 position-relative mb-0">
                    {/* <a className="btn btn-degradado btn-absolute" href="#">Ver más <i className="fa fa-chevron-right" aria-hidden="true"></i></a> */}
                </div>
            </section>
            <div className="container">
                {!loadingDiscounts ? showLoadingScreen() : showDiscount()}
            </div>
        </>
    )
}

export default Discounts;