import React, { Children, FC } from "react";
import { Link } from "react-router-dom";
import { useCarousel } from "../../../../services/utiles";
import { CarouselProps } from "../../utilities/interfaces";

const Carousel: FC<CarouselProps> = ({ setCarouselSelected, carouselSelected, views }) => {

    useCarousel(0,'carousel-annual-bond-mobile', views);

    return (
        <>
            {/* CARoUSEL ESCRITORIO */}
            <div id="carousel-ficha-resumen" className="d-none d-md-block">
                <div className="row">
                    {
                        Children.toArray(views.map((view, index, self) => {
                            return (
                                <div className={`col${self.length === 1 ? "-5" : ""} mb-4`} key={index}>
                                    <Link
                                        onClick={() => { setCarouselSelected(index) }}
                                        className={`btn btn-xl text-uppercase ${carouselSelected === index ? "btn-degradado-2" : ""}`}
                                        to="#"
                                    >
                                        <span className="align-self-center">{view}</span>
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </Link>
                                </div>
                            )
                        }))
                    }
                </div>
            </div>
            {/* CARoUSEL MOBILE */}
            <div id="carousel-annual-bond-mobile" className="carousel carousel-dark slide carousel-control-inner d-md-none" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    {
                        Children.toArray(views.map((_, index) => {
                            return (
                                <button
                                    onClick={() => { setCarouselSelected(index) }}
                                    type="button"
                                    data-bs-target="#carousel-annual-bond-mobile"
                                    data-bs-slide-to={index}
                                    className={`${carouselSelected === index ? "active" : ""}`}
                                    aria-current={carouselSelected === index}
                                    aria-label={`Slide ${index + 1}`}
                                    key={index}
                                ></button>
                            )
                        }))
                    }
                </div>
                <div className="carousel-inner">
                    {
                        Children.toArray(views.map((view, index) => {
                            return (
                                <div className={`carousel-item p-2 ${carouselSelected === index ? 'active' : ''}`} data-bs-interval="10000" key={index}>
                                    <div className="row">
                                        <div className="col mb-4">
                                            <Link
                                                onClick={() => {
                                                    setCarouselSelected(index);
                                                }}
                                                className={`btn btn-xl text-uppercase ${carouselSelected === index ? "btn-degradado-2" : ""}`}
                                                to="#"
                                            >
                                                <span className="align-self-center">{view}</span>
                                                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        }))
                    }
                </div>
            </div>
        </>
    );
};

export default Carousel;