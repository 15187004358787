import React from 'react';
import { CoursesContent } from './CoursesContent';
import { CoursesProvider } from './context/CoursesContext';

export const CoursesPage: React.FC = () => {
    return (
        <CoursesProvider>
            <CoursesContent />
        </CoursesProvider>
    );
};
