import * as t from "io-ts";
import React from "react";

export const nameCollaboratorC = t.type({
    EMPLID: t.string,
    NOMBRE: t.string,
    CARGO: t.string,
    POSICION: t.string,
    UBICACION: t.string,
    EMPRESA: t.string
});

export type NameCollaborator = t.TypeOf<typeof nameCollaboratorC>

export const emplidCollaboratorC = t.type({
    valid: t.boolean,
    status: t.string,
    fullName: t.string,
    postition: t.string,
    location: t.string,
    company: t.string,
    emplid: t.string,
});

export type EmplidCollaborator = t.TypeOf<typeof emplidCollaboratorC>

export const collaboratorCodec = t.intersection([
    t.partial({
        byName: nameCollaboratorC,
    }),
    t.partial({
        byEmplid: emplidCollaboratorC,
    })
]);

export type Collaborator = t.TypeOf<typeof collaboratorCodec>

export const warningHistoryCodec = t.type({
    id: t.string,
    tipo: t.string,
    fecha: t.string,
    estado: t.string,
    comentario_jef: t.string,
    comentario_rrhh: t.string,
    comentario_rel_lab: t.string,
    descargar: t.string
});

export type WarningHistrory = t.TypeOf<typeof warningHistoryCodec>

export interface WarningLettersProps {
    collaborators: Collaborator[];
    setWarningLetter: React.Dispatch<React.SetStateAction<boolean>>;
}

export const faultC = t.type({
    code: t.string,
    descr: t.string
});

export type Fault = t.TypeOf<typeof faultC>

export const getWarningLetterResponseCodec = t.type({
    response: t.intersection([
        emplidCollaboratorC,
        t.type({
            reasons: t.array(faultC)
        })
    ])
});

export type GetWarningLetterResponse = t.TypeOf<typeof getWarningLetterResponseCodec>

// Get Photos
export const getPhotosResponseC = t.type({
    response: t.type({
        photos: t.type({
            emplid: t.string,
            photo: t.string,
            status: t.string,
            valid: t.string,
        })
    })
});

export type GetPhotosResponse = t.TypeOf<typeof getPhotosResponseC>

export const getPhotosRequestC = t.type({
    request: t.type(({
        emplids: t.array(t.string)
    }))
});

export type GetPhotosRequest = t.TypeOf<typeof getPhotosRequestC>


export type InputFile = {
    file: File | null;
    index: number;
}

export const getWarningLetterFileRequestCodec = t.type({
    request: t.type({
        emplid: t.string,
        warningLetterId: t.string,
    })
});

export type GetWarningLetterFileRequest = t.TypeOf<typeof getWarningLetterFileRequestCodec>

export const evidenceWarningLetter = t.type({
    title: t.string,
    extension: t.string,
    file: t.union([t.string, t.null])
});

export type EvidenceWarningLetter = t.TypeOf<typeof evidenceWarningLetter>

export const postWarningLetter = t.type({
    request: t.type({
        emplid: t.string,
        reasonCode: t.string,
        comments: t.string,
        evidence: t.array(evidenceWarningLetter)
    })
});



export type PostWarningLetterRequest = t.TypeOf<typeof postWarningLetter>

export const postWarningLetterResponseCodec = t.type({
    response: t.type({
        valid: t.boolean,
        status: t.string,
    })
});

export type PostWarningLetterResponse = t.TypeOf<typeof postWarningLetterResponseCodec>

export const getWarningLetterFileResponseCodec = t.type({
    response: t.type({
        valid: t.boolean,
        status: t.string,
        file: t.string,
    })
});
export type GetWarningLetterFileResponse = t.TypeOf<typeof getWarningLetterFileResponseCodec>
