import React, { FC, useContext, createContext } from "react";
import { httpClientEnv } from "../.././../../services/apiServices/httpClient";
import { NameCollaborator } from "../utilities/interfaces";
import Warning from "./warning";

export const HttpClientContext = createContext(httpClientEnv);
export const useHttpClient = () => useContext(HttpClientContext);
  
interface WarningLetterProps {
    collaborators: NameCollaborator[];
    setWarningLetter: React.Dispatch<React.SetStateAction<boolean>>;
    collaboratorEmplid: string;
    fromEquipo: boolean;
    setFromEquipo: React.Dispatch<React.SetStateAction<boolean>>;
    setCollaboratorEmplid: React.Dispatch<React.SetStateAction<string>>;
}

export const WarningLetter: FC<WarningLetterProps> = ({ collaborators, setCollaboratorEmplid, setWarningLetter, collaboratorEmplid,fromEquipo, setFromEquipo }) => {
    return (
        <Warning collaborators={collaborators} setWarningLetter={setWarningLetter} collaboratorEmplid={collaboratorEmplid} fromEquipo={fromEquipo} setFromEquipo={setFromEquipo} setCollaboratorEmplid={setCollaboratorEmplid}/>
    )
};
