import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  getMaintanceServicesAll,
  getMaintenceFeatures,
  putMaintanceServices,
  putMaintanceServicesFeature,
} from "../../services/maintanceServives/maintanceService";
import { HeaderPages } from "../commonComponents/HeaderPages";
import { CheckBoxSample } from "../commonComponents/CheckBoxSample";
import { SpinerFullScreen } from "../commonComponents/SpinerFullScreen";
import { BotonsSaveCancel } from "../commonComponents/BotonsSave";
import menuService from "../../services/permissions/access-service";
import { MaintanceFunction } from "./MaintanceFunction";
import { MaintenancePaises } from "./MaintenancePaises";
import { MessageDinamic } from "../commonComponents/MessageDinamic";
import { Link } from "react-router-dom";
import { AgregarContenido } from "./AgregarContenido"
import { ModificarContenido } from "./ModificarContenido";
import styles from "../../resources/css/style-COL.module.css";
"../../resources/css/style-COL.css";

export const MaintenancePage = () => {
  const [loadingData, setloadingData] = useState(false);
  const [data, setdata] = useState([]);
  const [dataFeature, setdataFeature] = useState([]);
  const [features, setFeatures] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [statusRequest, setStatusRequest] = useState({ sending: false, error: false, success: false, initi: false });
  const [statusRequestFeature, setStatusRequestFeature] = useState({ sending: false, error: false, success: false });
  const [displayAdd, setDisplayAdd] = useState(false)
  const [displayUpdate, setDisplayUpdate] = useState(false)
  const [itemId, setItemId] = useState(null)

  useEffect(async () => {
    await getMaintanceServicesAll(setloadingData, setdata);
    await getMaintenceFeatures(setloadingData, setFeatures);
  }, []);

  useEffect(() => {
    /*   if (data.length > 0) {
        setDisabled(true);
      } */
  }, [data]);

  useEffect(() => {
    console.log("statusRequest", statusRequest);
    if (statusRequest.success || statusRequestFeature.success && !statusRequest.sending && !statusRequestFeature.sending) {
      MessageDinamic("success", "Exito", "Se guardo la informacion correctamente")
      getMaintanceServicesAll(setloadingData, setdata);
      getMaintenceFeatures(setloadingData, setFeatures);


    } else
      if (statusRequest.error || statusRequestFeature.error && !statusRequest.sending && !statusRequestFeature.sending) {
        MessageDinamic("Error", "Error", "No se pudo guardar la informacion")
        getMaintanceServicesAll(setloadingData, setdata);
        getMaintenceFeatures(setloadingData, setFeatures);

      }

  }, [statusRequest]);

  const cancelHandler = () => {
    setloadingData(true);
    getMaintanceServicesAll(setloadingData, setdata);
    setDisabled(false);

    setDisplayAdd(false);
    setDisplayUpdate(false)
  }

  const saveHandler = () => {
    setStatusRequest(false);
    putMaintanceServices(data, setStatusRequest)
    putMaintanceServicesFeature(dataFeature, setStatusRequestFeature)

    setDisplayAdd(false);
    setDisplayUpdate(false)
  }

  const addContent = () => {
    setDisplayAdd(true)
  }

  const updateContent = (featureId) =>{
    setDisplayUpdate(true)
    setItemId(featureId)
  }

  return (
    <>
      <HeaderPages title="Mantenimiento" />

      {loadingData || statusRequestFeature.sending ? (
        <SpinerFullScreen />
      ) : (
        <>
          <div className="card-box  align-items-center mb-5  text-md-start">
            <MaintenancePaises data={data} setdata={setdata} />
            <div className={`${styles["tit-group"]} ${styles["d-flex"]} ${styles["justify-content-end"]}`}>
              <Link to="#" onClick={addContent} className={`${styles["h5"]} ${styles["mt-2"]} ${styles["fl-normal"]}`}><span className={`${styles["d-none"]} ${styles["d-sm-inline-block"]}`}>Agregar contenido</span><i className={`${styles["fa"]} ${styles["fa-plus-square-o"]} ${styles["fa-lg"]} ${styles["ms-2"]}`} aria-hidden="true"></i></Link>
            </div>
            {displayAdd && <AgregarContenido onCancel={cancelHandler} onSave={saveHandler} />}
            {displayUpdate && <ModificarContenido onCancel={cancelHandler} onSave={saveHandler} itemId={itemId} features={features} />}
            {!displayAdd && !displayUpdate && <div>
              <MaintanceFunction features={features} dataFeature={dataFeature} setdataFeature={setdataFeature} updateContent={updateContent} />
              <BotonsSaveCancel cancelHandler={cancelHandler} saveHandler={saveHandler} />
            </div>}
          </div>
        </>
      )}
    </>
  );
};
