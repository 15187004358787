import { Children, FC } from "react";
import { Controller } from "react-hook-form";
import { InputForm } from "../../interfaces/interfaces";

const SelectInput:FC<InputForm> = ({formState, input, control, handleFunction, getValues}) => {

    return(
        <>
        <span className="data-list-box__text ms-2">
            <Controller
                name={input.key}
                control={control}
                defaultValue={input.defaultValue || ''}
                render={({ field }) => (
                <>
                    <select
                        {...field}
                        className="form-select"
                        value={getValues(input.key)}
                        disabled={input.readOnly}
                        onChange={(e) => {
                            handleFunction ? handleFunction(input.key, e.target.value) : null
                            field.onChange(e);
                        }}
                    >
                    <option value="">Seleccione</option>
                    {Children.toArray(input.options.map(opt => (
                        <option key={opt.value} value={opt.value} selected={opt.value === input.defaultValue}>
                        {opt.label}
                        </option>
                    )))}
                    </select>
                    {formState.errors[input.key] && formState.errors[input.key]?.message ? (
                        <div className="invalid-feedback d-block">
                            {formState.errors[input.key]?.message}
                        </div>
                    ) : null}                                            
                    </>
                )}
            />
        </span>
        </>
    )
}

export default SelectInput;