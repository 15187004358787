import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import React, { Children, useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link, useNavigate } from "react-router-dom";
import baseDatosService from "../services/bd-service";

export default function CrearNoticias() {
  const [message, setMessage] = useState("");
  let navigate = useNavigate();
  const [status, setStatus] = useState(true);
  const [, setModalShow] = useState(true);
  const [, setFechaTerminoError] = useState(false);
  const [respuestaSolicitud, setRespuestaSolicitud] = useState(false);
  const [titutoloNoticia, setTituloNoticia] = useState("");
  const [itemGroup, setItemGroup] = useState(0);
  const [arregloGroup, setArregloGroup] = useState([]);
  const [cargando, setCargando] = useState(false);
  const [grupos, setGrupos] = useState(null);
  const [validacionFecha, setValidacionFecha] = useState(true);
  const [valorTexto, setValorTexto] = useState("");
  const personName = [];
  const modulos = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formatos = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleClose = () => {
    setRespuestaSolicitud(false);
  };

  const [, setPicture3] = useState(null);
  const [, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [, setPicture2] = useState(null);
  const [imgData2, setImgData2] = useState(null);

  const deletePicture3 = () => {
    setPdf(null);
    document.getElementById("selectImage3").value = "";
  };
  const onChangePicture2 = (e) => {
    if (e.target.files[0]) {
      setPicture2(e.target.files[0]);
      const reader2 = new FileReader();
      reader2.addEventListener("load", () => {
        setImgData2(reader2.result);
      });
      reader2.readAsDataURL(e.target.files[0]);
    }
  };
  const onChangePicture3 = (e) => {
    if (e.target.files[0]) {
      setPicture3(e.target.files[0]);
      const reader3 = new FileReader();
      reader3.addEventListener("load", () => {
        setPdf(reader3.result);
      });
      reader3.readAsDataURL(e.target.files[0]);
    }
  };

  const deletePicture = () => {
    setImgData(null);
    document.getElementById("selectImage1").value = "";
  };
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const deletePicture2 = () => {
    document.getElementById("selectImage2").value = "";
    setImgData2(null);
  };

  useEffect(() => {
    baseDatosService.getAllgrupos(setGrupos);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    for (var i = 0; i < arregloGroup.length; i++) {
      if (arregloGroup[i] !== null && arregloGroup[i] !== "") {
        if (
          document.getElementById("inputState" + arregloGroup[i]).value === ""
        ) {
          personName.push(
            document.getElementById("inputState2" + arregloGroup[i]).value
          );
        } else {
          personName.push(
            document.getElementById("inputState" + arregloGroup[i]).value
          );
        }
      }
    }

    var titulo = document.getElementById("titulo").value;
    var fechaInicio = document.getElementById("fechaInicio").value;
    var fechaTermino = document.getElementById("fechaTermino").value;
    var destacada = document.getElementById("destacada").checked;

    if (
      titulo !== "" &&
      fechaInicio !== "" &&
      fechaTermino !== "" &&
      imgData !== "" &&
      imgData2 != "" &&
      validacionFecha
    ) {
      var noticiaDestacada = false;
      if (destacada) {
        noticiaDestacada = true;
      }
      var radio1 = document.getElementById("flexRadioDefault1").checked;
      var visible_title = document.getElementById("visible_title")?.checked;
      //Estado noticia 0 = Borrador
      //Estado noticia 1 = Publicado
      var estadoNoticia = 'draft'
      if (radio1) {
        estadoNoticia = 'published'
      }

      if (personName.length > 0) {
        if (personName[0] === "") {
          setStatus(false);
          setMessage("Debes seleccionar al menos un grupo");
        } else {
          setStatus(true);
          setMessage("");
          setTituloNoticia(titulo);
          setCargando(true);
          baseDatosService.newNoticia(
            titulo,
            fechaInicio,
            fechaTermino,
            imgData2,
            imgData,
            valorTexto,
            noticiaDestacada,
            pdf,
            estadoNoticia,
            personName,
            setRespuestaSolicitud,
            visible_title,
          );
        }

      } else {
        setStatus(false);
        setMessage("Debes seleccionar al menos un grupo");
      }
    }
  };

  function setFechaActual() {
    function join(t, a, s) {
      function format(m) {
        let f = new Intl.DateTimeFormat("en", m);
        return f.format(t);
      }
      return a.map(format).join(s);
    }

    let a = [{ year: "numeric" }, { month: "numeric" }, { day: "numeric" }];
    let s = join(new Date(), a, "-");
    let castFecha = s.split("-");
    if (castFecha[1] < 10) {
      castFecha[1] = "0" + castFecha[1];
    }
    if (castFecha[2] < 10) {
      castFecha[2] = "0" + castFecha[2];
    }
    var fechaNew = castFecha[0] + "-" + castFecha[1] + "-" + castFecha[2];
    return fechaNew;
  }
  const fechaInicioSelect = () => {
    var fechaInicio = document.getElementById("fechaInicio");
    var fechaTermino = document.getElementById("fechaTermino");
    fechaInicio.min = setFechaActual();
    fechaTermino.min = setFechaActual();
    if (Date.parse(fechaTermino.value) < Date.parse(fechaInicio.value)) {
      setStatus(false);
      setMessage("La fecha de término no debe ser menor a la fecha de inicio.");
      setModalShow(true);
      setFechaTerminoError(true);
      setValidacionFecha(false);
    } else {
      setStatus(true);
      setMessage("");
      setModalShow(false);
      setFechaTerminoError(false);
      setValidacionFecha(true);
    }
  };

  const fechaTerminoSelect = () => {
    var fechaTermino = document.getElementById("fechaTermino");
    var fechaInicio = document.getElementById("fechaInicio");
    fechaInicio.min = setFechaActual();
    fechaTermino.min = setFechaActual();
    if (Date.parse(fechaTermino.value) < Date.parse(fechaInicio.value)) {
      setStatus(false);
      setMessage("La fecha de término no debe ser menor a la fecha de inicio.");
      setModalShow(true);
      setFechaTerminoError(true);
      setValidacionFecha(false);
    } else {
      setStatus(true);
      setMessage("");
      setModalShow(false);
      setFechaTerminoError(false);
      setValidacionFecha(true);
    }
  };
  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  const agregarGrupo = () => {
    setItemGroup(itemGroup + 1);
    setArregloGroup([...arregloGroup, itemGroup]);
  };

  const eliminarGrupo = (e, indice) => {
    e.preventDefault();
    let arreglo = arregloGroup;

    let arreglo2 = arreglo.map((element, index) => {
      if (index === indice) {
        element = null;
      }
      return element;
    });

    setArregloGroup(arreglo2);
  };

  return (
    <>
      <nav className="breadcrumbs" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={"/inicio"}>
              <i className="fa fa-home" aria-hidden="true"></i> Inicio
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Crear Noticias
          </li>
        </ol>
      </nav>
      <form className="needs-validation" onSubmit={(e) => handleSubmit(e)}>
        <section className="crear-nueva-noticia">
          <h1>Crear Nueva Noticia</h1>

          <div className="row">
            <div className="col-12 col-xxl-8 d-flex">
              {/*<!--Datos de Noticia */}
              <div className="box mb-4 align-items-stretch w-100">
                <h2 className="h4 tit-section">Datos de Noticia</h2>
                <div className="data-list-box data-list-box-align-middle data-list-box-form">
                  <ul className="list-column-1 list-unstyled">
                    <li className="d-flex flex-nowrap">
                      <span className="data-list-box__title">Título</span>
                      <span className="data-list-box__text">
                        <input
                          id="titulo"
                          onChange={(e) => setTituloNoticia(e.target.value)}
                          className="form-control min-w-50"
                          type="text"
                          placeholder="Ingrese título"
                        />
                      </span>
                      <span className="data-list-box__checkbox d-flex flex-column align-items-center ms-2">
                        <input
                          className="form-check-input m-0"
                          type="checkbox"
                          id="visible_title"
                          defaultChecked={true}
                        />
                        <label className="form-check-label m-0" htmlFor="visible">Visible</label>
                      </span>
                    </li>
                    <li>
                      <span className="data-list-box__title">Tipo de Noticia</span>
                      <span className="data-list-box__text">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="destacada"
                          />
                          <label className="form-check-label" htmlFor="destacada">
                            Destacada
                          </label>
                        </div>
                      </span>
                    </li>
                    <li className="border-0">
                      <span className="data-list-box__title">Vigencia</span>
                      <span className="data-list-box__text d-flex align-items-center">
                        Desde{" "}
                        <input
                          id="fechaInicio"
                          label="Vigente desde"
                          required
                          onClick={(event) => fechaInicioSelect(event)}
                          onChange={(event) => fechaInicioSelect(event)}
                          type="date"
                          className="form-control ms-3"
                          placeholder="dd/mm/aaaa"
                        />
                        <i
                          className="fa fa-calendar fa-fw fa-lg color-primary ms-3"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </li>
                    <li>
                      <span className="data-list-box__title"></span>
                      <span className="data-list-box__text d-flex align-items-center">
                        Hasta{" "}
                        <input
                          id="fechaTermino"
                          label="Vigente Hasta"
                          required
                          onClick={(event) => fechaTerminoSelect(event)}
                          onChange={(event) => fechaTerminoSelect(event)}
                          type="date"
                          style={{ width: "100%" }}
                          className="form-control ms-3"
                          placeholder="dd/mm/aaaa"
                        />
                        <i
                          className="fa fa-calendar fa-fw fa-lg color-primary ms-3"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="tit-group d-flex justify-content-between align-items-center mt-4">
                  <h3 className="h5 fw-bold m-0">
                    Seleccione los grupos que podrán ver esta noticia
                  </h3>
                  <a onClick={(e) => agregarGrupo(e)} className="mt-2">
                    <i
                      className="fa fa-plus-square-o fa-lg fa-fw"
                      aria-hidden="true"
                    ></i>
                  </a>
                </div>

                <div className="mt-3 p-3 bg-light-2 rounded-4">
                  {/*<!--Escritorio */}
                  <table className="table table-section table-borderless align-middle d-none d-md-table text-uppercase">
                    <thead>
                      <tr>
                        <th className="col-6">Grupo</th>
                        <th className="col-3 text-center">Acciones</th>
                      </tr>
                    </thead>

                    <tbody>
                      {Children.toArray(
                        arregloGroup.map((element, index) => {
                          if (element !== undefined && element !== null) {
                            return (
                              <tr key={index}>
                                <td>
                                  <select className="form-select"
                                    id={"inputState" + arregloGroup[index]}
                                  >
                                    <option selected disabled value="">
                                      Seleccione Grupo
                                    </option>
                                    {grupos ? (
                                      grupos.map((name, index) => (
                                        <option
                                          key={"array1" + index}
                                          value={parseInt(name.id)}
                                        >
                                          {name.title}
                                        </option>
                                      ))
                                    ) : (
                                      <></>
                                    )}
                                  </select>
                                </td>
                                <td className="text-center">
                                  <a
                                    id={"trash" + index}
                                    href="#"
                                    onClick={(e) => eliminarGrupo(e, index)}
                                  >
                                    <i
                                      className="fa fa-trash-o fa-fw me-2"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </td>
                              </tr>
                            );
                          }
                        })
                      )}
                    </tbody>
                  </table>
                  {/*<!--Mobile */}
                  <div className="data-list-box data-list-box-align-middle data-list-box-form d-md-none">
                    {Children.toArray(
                      arregloGroup.map((element, index) => {
                        if (element !== undefined && element !== null) {
                          return (
                            <ul className="list-column-1 list-unstyled" key={index}>
                              <li>
                                <span className="data-list-box__title">Grupo</span>
                                <span className="data-list-box__text">
                                  <select
                                    id={"inputState2" + arregloGroup[index]}
                                    className="form-select"
                                  >
                                    <option selected disabled value="">
                                      Seleccione Grupo
                                    </option>
                                    {grupos ? (
                                      grupos.map((name, index) => (
                                        <option
                                          key={"array2" + index}
                                          value={parseInt(name.id)}
                                        >
                                          {name.title}
                                        </option>
                                      ))
                                    ) : (
                                      <></>
                                    )}
                                  </select>
                                </span>
                              </li>
                              <li>
                                <span className="data-list-box__title">Grupos</span>
                                <span className="data-list-box__text">
                                  <a
                                    id={"trash" + index}
                                    href="#"
                                    onClick={(e) => eliminarGrupo(e, index)}
                                  >
                                    <i
                                      className="fa fa-trash-o fa-fw me-2"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </span>
                              </li>
                            </ul>
                          );
                        }
                      })
                    )}
                  </div>
                </div>
              </div>
              {/*<!--Fin Datos de Noticia */}
            </div>

            <div className="col-12 col-xxl-4 d-flex">
              {/*<!--Adjuntar Archivos */}
              <div className="box mb-4 align-items-stretch w-100">
                <h2 className="h4 tit-section">Adjuntar Archivos</h2>
                <ul className="upload-files list-unstyled">
                  <li>
                    <span
                      style={{ width: "100%" }}
                      className="data-list-box__text"
                    >
                      <label htmlFor="selectImage1">
                        Seleccione una imagen tamaño banner
                      </label>

                      <input
                        accept="image/*"
                        required
                        style={{ width: "100%" }}
                        id="selectImage1"
                        className="form-control"
                        type="file"
                        onChange={(e) => onChangePicture(e)}
                      ></input>

                      {imgData !== null ? (
                        <>
                          <br></br>
                          <Button
                            variant="outlined"
                            onClick={deletePicture}
                            startIcon={<DeleteIcon />}
                          >
                            Borrar imagen tamaño banner
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                      <p>Se recomienda una dimensión de 1000 x 200 píxeles. </p>

                      {imgData !== null ? (
                        <div className="d-flex justify-content-center align-items-end">
                          <picture>
                            <source
                              media="(max-width: 767px)"
                              srcSet={imgData != undefined ? imgData : null}
                            />
                            <source
                              media="(min-width: 768px)"
                              srcSet={imgData != undefined ? imgData : null}
                            />
                            <img
                              style={{
                                width: "1000px",
                                height: "200px",
                                objectFit: "cover",
                              }}
                              src={
                                imgData != undefined && imgData != undefined
                                  ? imgData
                                  : null
                              }
                              className="d-block w-100 rounded-4"
                              alt=""
                            />
                          </picture>
                        </div>
                      ) : (
                        <></>
                      )}
                    </span>
                  </li>
                  <li>
                    <span
                      style={{ width: "100%" }}
                      className="data-list-box__text"
                    >
                      <label htmlFor="selectImage1">
                        Seleccione una imagen tamaño responsive
                      </label>

                      <input
                        accept="image/*"
                        style={{ width: "100%" }}
                        id="selectImage2"
                        required
                        className="form-control"
                        type="file"
                        onChange={(e) => onChangePicture2(e)}
                      ></input>

                      {imgData2 !== null ? (
                        <>
                          <br></br>
                          <Button
                            variant="outlined"
                            onClick={deletePicture2}
                            startIcon={<DeleteIcon />}
                          >
                            Borrar imagen tamaño responsive
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                      <p>Se recomienda una dimensión de 500 x 200 píxeles. </p>

                      {imgData2 !== null ? (
                        <div className="d-flex justify-content-center align-items-end">
                          <picture>
                            <source
                              media="(max-width: 767px)"
                              srcSet={imgData2 != undefined ? imgData2 : null}
                            />
                            <source
                              media="(min-width: 768px)"
                              srcSet={imgData2 != undefined ? imgData2 : null}
                            />
                            <img
                              style={{
                                width: "1000px",
                                height: "200px",
                                objectFit: "cover",
                              }}
                              src={
                                imgData2 != undefined && imgData2 != undefined
                                  ? imgData2
                                  : null
                              }
                              className="d-block w-100 rounded-4"
                              alt=""
                            />
                          </picture>
                        </div>
                      ) : (
                        <></>
                      )}
                    </span>
                  </li>
                  <li>
                    <span
                      style={{ width: "100%" }}
                      className="data-list-box__text"
                    >
                      <label htmlFor="selectImage3">Seleccione un archivo</label>

                      <input
                        accept=".pdf"
                        style={{ width: "100%" }}
                        id="selectImage3"
                        className="form-control"
                        type="file"
                        onChange={(e) => onChangePicture3(e)}
                      ></input>
                      {pdf !== null ? (
                        <>
                          <br></br>
                          <Button
                            variant="outlined"
                            onClick={deletePicture3}
                            startIcon={<DeleteIcon />}
                          >
                            Borrar archivo
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                    </span>
                  </li>
                </ul>
              </div>
              {/*<!--Fin Adjuntar Archivos */}
            </div>
          </div>

          {/*<!--Contenidos */}
          <div className="box mb-4">
            <h2 className="h4 tit-section">Contenido</h2>
            <div className="row">
              <div className="col-12 col-md-12">
                {/*<!--Editor de texto */}
                <span style={{ width: "100%" }} className="data-list-box__text">
                  <ReactQuill
                    theme="snow"
                    modules={modulos}
                    value={valorTexto}
                    onChange={setValorTexto}
                    formats={formatos}
                  ></ReactQuill>
                </span>

                <div className="row justify-content-end mt-3">
                  <div className="col-auto">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        defaultChecked={true}
                      />
                      <label className="form-check-label" htmlFor="flexRadioDefault1">
                        Publicado
                      </label>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                      />
                      <label className="form-check-label" htmlFor="flexRadioDefault2">
                        Borrador
                      </label>
                    </div>
                  </div>
                </div>
                {/*<!--Fin Editor de texto */}
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <a
              className="btn btn-link text-uppercase"
              data-bs-toggle="modal"
              data-bs-target="#back"
            >
              Volver
            </a>
            <div>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#previsualizar"
              >
                Previsualizar{" "}
                <i
                  className="fa fa-chevron-right fa-fw fa-xs"
                  aria-hidden="true"
                ></i>
              </button>
              <button
                type="submit"
                disabled={valorTexto !== "" ? false : true}
                className="btn btn-degradado ms-3"
              >
                Guardar{" "}
                <i
                  className="fa fa-chevron-right fa-fw fa-xs"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </div>

          <div
            className="modal fade"
            id="previsualizar"
            tabIndex="-1"
            aria-labelledby="previsualizar"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header border-0 pb-0">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <h2 className="h5 text-uppercase">Vista previa</h2>
                  <img
                    className="rounded rounded-4 mb-3 img-fluid"
                    src="assets/images/noticia-home.jpg"
                    alt=""
                  />
                  <div className="d-flex justify-content-left align-items-end">
                    <img
                      style={{
                        width: "1000px",
                        height: "200px",
                        objectFit: "cover",
                      }}
                      className="playerProfilePic_home_tile"
                      src={imgData}
                    />
                  </div>
                  <h3 className="d-flex justify-content-left align-items-end">
                    {titutoloNoticia}
                  </h3>
                  {renderHTML(valorTexto)}
                </div>
                <div className="modal-footer border-0 pt-0">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Aceptar{" "}
                    <i
                      className="fa fa-chevron-right fa-fw fa-xs"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="publicar-noticia"
            tabIndex="-1"
            aria-labelledby="publicar-noticia"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header border-0 pb-0">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body text-center py-4">
                  <h2 className="h5">
                    ¿Estás seguro que deseas <br /> publicar la noticia?
                  </h2>
                </div>
                <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                  <button
                    type="button"
                    className="btn btn-primary px-5"
                    data-bs-dismiss="modal"
                  >
                    No{" "}
                    <i
                      className="fa fa-chevron-right fa-fw fa-xs"
                      aria-hidden="true"
                    ></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-degradado px-5"
                    data-bs-dismiss="modal"
                    data-bs-toggle="modal"
                    data-bs-target="#success"
                  >
                    Sí{" "}
                    <i
                      className="fa fa-chevron-right fa-fw fa-xs"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="back"
            tabIndex="-1"
            aria-labelledby="back"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header border-0 pb-0">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body text-center py-4">
                  <h2 className="h5">
                    ¿Estás seguro que deseas volver al listado? <br /> Se
                    perderán los datos ingresados.
                  </h2>
                </div>
                <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                  <button
                    type="button"
                    className="btn btn-primary px-5"
                    data-bs-dismiss="modal"
                  >
                    No{" "}
                    <i
                      className="fa fa-chevron-right fa-fw fa-xs"
                      aria-hidden="true"
                    ></i>
                  </button>
                  <button
                    onClick={() => navigate("/canal-de-comunicacion")}
                    type="button"
                    className="btn btn-degradado px-5"
                    data-bs-dismiss="modal"
                  >
                    Sí{" "}
                    <i
                      className="fa fa-chevron-right fa-fw fa-xs"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="success"
            tabIndex="-1"
            aria-labelledby="success"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header border-0 pb-0">
                  <button
                    onClick={() => navigate("/canal-de-comunicacion")}
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body text-center py-4 pt-0 pb-1">
                  <i
                    className="fa fa-check-circle-o color-orange fa-3x mb-2"
                    aria-hidden="true"
                  ></i>
                  <h2 className="h5">
                    Su noticia ha sido guardada
                    <br /> correctamente.
                  </h2>
                </div>
                <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                  <button
                    onClick={() => navigate("/canal-de-comunicacion")}
                    type="button"
                    className="btn btn-degradado px-5"
                    data-bs-dismiss="modal"
                  >
                    Aceptar{" "}
                    <i
                      className="fa fa-chevron-right fa-fw fa-xs"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/*<!--fin Contenidos */}
        </section>
      </form>
      {cargando ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0"></div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-download color-orange fa-3x mb-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">Enviando solicitud..</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {respuestaSolicitud &&
        <div className="modal fade show" id="foto-credencial" tabIndex={"-1"} aria-labelledby="enviar-por-correo" aria-modal="true" role="dialog" style={{ display: "block", textAlign: "left" }} >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0 pb-0">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => handleClose(e)}
                ></button>
              </div>
              <div className="modal-body text-center py-4 pt-0 pb-1">
                <i
                  className={`fa fa-${respuestaSolicitud.valid === 1 ? 'check' : 'times'}-circle-o color-orange fa-3x mb-2`}
                  aria-hidden="true"
                ></i>
                <h2 className="h5">{respuestaSolicitud.status}</h2>
              </div>
              <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                <button
                  onClick={() => navigate("/canal-de-comunicacion")}
                  type="button"
                  className="btn btn-degradado px-5"
                  data-bs-dismiss="modal"
                >
                  Aceptar{" "}
                  <i
                    className="fa fa-chevron-right fa-fw fa-xs"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      }
      {!status && <div>
        <div
            style={{
              width: "100%",
              textAlign: "center",
              padding: "5px",
              background: "pink",
              border: "0",
              borderRadius: "5px",
              marginBottom: "10px",
            }}
        >
          <p style={{ color: "red", textAlign: "center", width: "100%" }} className="forgot-password">{message}</p>
        </div>
      </div>}
    </>
  );
}
