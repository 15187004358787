import { FeedbackPost } from '../interfaces'
import { Auth } from "@aws-amplify/auth";
export class HttpClient {

  async get(url: string): Promise<any> {
    const autorization = await Auth.currentSession().then((authResponse) => authResponse.getIdToken().getJwtToken())
    const accesstoken = await Auth.currentSession().then((authResponse) => authResponse.getAccessToken().getJwtToken());
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': autorization,
        'AccessToken': accesstoken
      })
    })
    if (response) {
      if (response.ok) {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const content = isJson ? response.json() : null;
        return Promise.resolve(content)
      } else {
        return Promise.reject(response.status)
      }
    } else {
      return Promise.reject('Invalid response')
    }
  }

  async postFeedback(url: string, body: FeedbackPost): Promise<any> {
    const autorization = await Auth.currentSession().then((authResponse) => authResponse.getIdToken().getJwtToken())
    const accesstoken = await Auth.currentSession().then((authResponse) => authResponse.getAccessToken().getJwtToken());
    const response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': autorization,
        'AccessToken': accesstoken
      }),
      body: JSON.stringify(body)
    })
    if (response) {
      return Promise.resolve(response)
    } else {
      return Promise.reject('Invalid response')
    }
  }

  async postUserInfo(jwtId: string, url: string, emplid: string, token: string): Promise<any> {
    const body = JSON.stringify({ request: { emplid: emplid } });
    const response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        "Content-Type": 'application/json; charset=utf-8',
        Authorization: jwtId,
        AccessToken: token
      }),
      body: body
    })
    return Promise.resolve(response)
  }

  async postUserHistory(url: string, userData: string): Promise<any> {
    const autorization = await Auth.currentSession().then((authResponse) => authResponse.getIdToken().getJwtToken())
    const accesstoken = await Auth.currentSession().then((authResponse) => authResponse.getAccessToken().getJwtToken());
    const body = JSON.stringify({ data: userData });
    const response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': autorization,
        'AccessToken': accesstoken
      }),
      body: body
    })
    if (response.ok) {
      return Promise.resolve(response)
    }
  }

  getLocalUserData(): string {
    const value = localStorage.getItem('userInfoHC')
    if (value !== null) { return value } else { return '' }
  }
}