import React, { useEffect, useState, useRef, KeyboardEvent } from 'react'
import Stack from '@mui/material/Stack'
import Autocomplete from '@mui/material/Autocomplete'
import { APIArticles, Article } from '../../utilities/interfaces'
import { HttpClient } from '../../utilities/apiServices/httpClient'
import { ApiRequest } from '../../utilities/apiServices/apiRequest'
import { useLocation, useNavigate } from 'react-router-dom'
import { paths } from '../../utilities/paths'
import { customFilter, filterCommonWords, restoreOptions } from './utils/filterFunctions'
import SearchOptions from './components/options/searchOptions'
import SearchInput from './components/textInput/searchInput'
import FailedSearch from './components/fail/failedSearch'
import { LoadingContent } from '../../utilities/constantsTexts'
import './styles/searchStyle.css'
import { errorsMessages } from '../../utilities/apiServices/apiErrors'
import Loading from '../dataStatus/loading/loading'
import { UpdateLocalStorage } from '../../utilities/helpers/updateStorage'

const httpClient = new HttpClient()
const apiService = new ApiRequest(httpClient)

type Props = {
  onSearchEnter: (searchText: string) => void;
};

const Searcher = ({ onSearchEnter }: Props) => {
  const [open, setOpen] = useState(false);
  const [articlesToShow, setArticles] = useState<readonly Article[]>([])
  const [currentArticlesOptions, setCurrentArticlesOptions] = useState<Article[]>([])
  const [loading, setLoading] = useState(false)
  const [userInput, setUserInput] = useState('')
  const autoComplete = useRef<HTMLElement>(null)
  const navigate = useNavigate()
  const location = useLocation()
  const [statusMessage, setStatusMessage] = useState(LoadingContent.loading)
  const [validData, setValidData] = useState(false)
  const update = new UpdateLocalStorage()

  useEffect(() => {
    if (!open) {
      setArticles([]);
      setLoading(false)
    } else {
      setLoading(true)
      fetchArticlesList()
        .then((articles: APIArticles) => {
          if (isValidData(articles)) {
            setArticles(articles.data)
            setCurrentArticlesOptions(articles.data)
            targetCustomPopper()
          }
        })
        .catch((err) => {
          emitError(err)
        }).finally(() => {
          setLoading(false)
        })
    }
  }, [open]);

  const emitError = (err: number) => {
    const newError = {
      errorStatus: err,
      errorFire: 'fetchArticlesList',
      errorComponent: 'Searcher'
    }
    update.updateSlot(update.keyConstants[7], JSON.stringify(newError))
  }

  const isValidData = (articles: APIArticles): boolean => {
    if (!articles.data || articles.data.length === 0) {
      setStatusMessage(LoadingContent.notValid)
      setValidData(false)
      emitError(500)
      return false
    } else {
      setValidData(true)
      return true
    }
  }

  const fetchArticlesList = async (): Promise<APIArticles> => {
    const data = await apiService.getArticles()
    return data
  }

  const getLabels = (option: Article): string => {
    return option.attributes.title
  }

  const handleChange = (event: React.SyntheticEvent<Element, Event>, value: Article | null, reason: string) => {
    // on user select option
    if (reason === 'selectOption') {
      navigate(paths[3].path + value?.attributes.slug + '/' + value?.id, { state: { from: location.pathname } })
    }
  }

  const handleInputChange = (event: React.SyntheticEvent<Element, Event>, value: string) => {
    // on user types change
    setUserInput(value)
    handleLabelDisplay(value)
  };

  const handleLabelDisplay = (inputValue: string) => {
    if (autoComplete.current) {
      const label = autoComplete.current.getElementsByTagName('label')[0]
      const display = inputValue !== '' ? 'none' : 'block'
      label.style.display = display
      const searcher = autoComplete.current.getElementsByTagName('input')
      searcher[0].removeAttribute('type')
    }
  }

  const handleArrow = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const inputRoot = event.target as HTMLInputElement
    const targetActive = inputRoot.getAttribute('aria-activedescendant')
    const targetIndex = targetActive?.split('-')[3]
    let num = 0
    if(targetIndex === undefined) {
      num = 0
    } else {
      num = Number(targetIndex) + 1
    }
    const options = document.getElementsByClassName('MuiAutocomplete-option')
    inputRoot.addEventListener('keydown', (event2) => {
      const keyBoard = event2 as unknown
      const keyBoardEv = keyBoard as KeyboardEvent 
      if (keyBoardEv.key === 'Enter' && options[num]?.classList.contains('Mui-focused')) {
        const currentOptions = filterArticles(inputRoot.value)
        const realTarget = currentOptions[num]
        navigate(paths[3].path + realTarget.attributes.slug + '/' + realTarget.id, { state: { from: location.pathname } })
        return
      }
    })
    return
  }

  const filterArticles = ((inputValue: string) => {
    if (inputValue === '') {
      return articlesToShow.slice(0, 5)
    } else {
      const results = customFilter(currentArticlesOptions, inputValue)
      return results.slice(0, 5)
    }
  })

  const handleEnterEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      handleArrow(event)
      return
    }
    if (event.key !== 'Enter') return
    (event as any).defaultMuiPrevented = true;
    const filterCommonWordsInput = filterCommonWords(userInput)
    if (filterCommonWordsInput !== '') {
      onSearchEnter(filterCommonWordsInput)
    } else {
      onSearchEnter(userInput)
    }
    setOpen(false)
  }

  const clearInput = () => {
    setUserInput('')
    handleLabelDisplay('')
  }

  const targetCustomPopper = () => {
    const popper = document.getElementsByClassName('MuiAutocomplete-popper')[0]
    if (popper) {
      popper.setAttribute('origin', 'helpCenter-searcher')
    }
  }

  const filterOptions = ((options: Article[], { inputValue }: { inputValue: string }): Article[] => {
    if (inputValue === '') {
      restoreOptions(options)
      return options.slice(0, 5)
    } else {
      const results = customFilter(options, inputValue)
      return results.slice(0, 5)
    }
  })

  return (
    <Stack spacing={2} className='helpCenter-searcher'>
      <Autocomplete
        disableClearable
        ref={autoComplete}
        id='hc-searcher'
        fullWidth={true}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.attributes.title === value.attributes.title}
        getOptionLabel={option => getLabels(option)}
        filterOptions={filterOptions}
        options={articlesToShow}
        loading={loading}
        loadingText={<Loading infoMessage={true} smallSpinner={true}/>}
        noOptionsText={validData ? (<FailedSearch options={articlesToShow} />) : (<p>{statusMessage}</p>)}
        onChange={handleChange}
        onInputChange={handleInputChange}
        inputValue={userInput}
        renderInput={(params) => (
          <SearchInput
            params={params}
            loading={loading}
            open={open}
            onClearInput={() => clearInput()}
          />
        )}
        renderOption={(props, option, { inputValue }) => {
          return (
            <SearchOptions
              liProps={props}
              searchText={inputValue}
              option={option}
              key={option.id}
            />
          )
        }}
        onKeyDown={(event) => {
          handleEnterEvent(event)
        }}
      />
    </Stack>
  );
}

export default Searcher;
