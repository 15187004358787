import { ProfileSummaryProvider } from "../../context/EmployeeProfileSummaryContext";
import { EmployeeDetailCard } from "../employeeProfileSummary/EmployeeDetailCard";
import { GraphicVacation } from "./GraphicVacation";
import { VacationMenu } from "./VacationMenu";
import { DaysTaken } from "./DaysTaken";

export const VacationData = () => {
    return (
        <ProfileSummaryProvider>
            <div className="row row-cols-1 g-4 mb-4">
                <EmployeeDetailCard />
                <DaysTaken />
                <GraphicVacation/>
                <VacationMenu />
            </div>
        </ProfileSummaryProvider>
    );
};
