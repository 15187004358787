import React, { FC, useEffect, useState } from "react";
import { getWarningLetterFile } from "../../utilities/apiServices/apiRequet";
import { WarningHistrory } from "../../utilities/interfaces";
import * as RD from "@devexperts/remote-data-ts";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import { httpClientEnv } from "../../../../../services/apiServices/httpClient";
interface WarningHistoryProps {
    emplid: string;
    warningHistory: WarningHistrory[];
}

interface DocProps{
    file:string,
    nameFile:string
}

const handleDownload = (documento:DocProps[]) => {
    const fileName = `${documento[0].nameFile}.pdf`
    let archivo = Buffer.from(documento[0].file, "base64");
    let link = document.createElement("a");
    let blob = new Blob([archivo], { type: "application/pdf" });
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.dispatchEvent(new MouseEvent("click"));
};

const WarningHistory: FC<WarningHistoryProps> = ({ emplid, warningHistory }) => {

    const [documento,setDocumento] = useState<DocProps[]>([]);
    const getDocument = async(collaboratorEmplid:string,idLetter:string) =>{
        const resp = await RTE.run(await getWarningLetterFile({request:{
            emplid:collaboratorEmplid,
            warningLetterId:idLetter
        }}), httpClientEnv)
                    .then(E.fold(e => { return RD.failure(e) }, a => { return RD.success(a) }));
        
        if(resp._tag==='RemoteSuccess'){
            let nameFile = `firma_carta_${idLetter}_${collaboratorEmplid}`
            setDocumento([{file:resp.value.response.file,nameFile:nameFile}])
        }
    }

   useEffect(()=>{
    if(documento.length>0){
        handleDownload(documento)
    }
   },[documento])

    return (
        <div className="box mb-4">
            <h2 className="h4 tit-section">Historial de Amonestaciones</h2>
            <table className="table table-even-acordeon table-section table-th-border table-borderless align-middle d-none d-md-table">
                <thead>
                    <tr>
                        <th>Tipo de Amonestación</th>
                        <th>Fecha Solicitud</th>
                        <th>Estado</th>
                        <th className="text-center text-nowrap">Detalle</th>
                    </tr>
                </thead>
                <tbody>
                    {warningHistory.map((wrn, idx) => {
                        if (wrn.id !=='' && wrn.tipo !=='') {
                            return (
                                <>
                                    <tr>
                                        <td>{wrn.tipo}</td>
                                        <td>{wrn.fecha}</td>
                                        <td><strong className="color-primary">{wrn.estado}</strong></td>
                                        <td className="text-center">
                                            <a className="btn btn-link btn-sm collapsed" href="#" data-bs-toggle="collapse" data-bs-target={`#ver-mas-${idx}`} aria-expanded="false" aria-controls={`ver-mas-${idx}`}>Ver más</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-0" colSpan={5}>
                                            <div className="accordion accordion-flush">
                                                <div className="accordion-item">
                                                    <div id={`ver-mas-${idx}`} className="accordion-collapse collapse" aria-labelledby={`ver-mas-${idx}`}>
                                                        <div className="row p-2 mt-3">
                                                            {wrn.comentario_jef !== '' && <div className="col-md-3">
                                                                <p><strong>Comentario Jefatura</strong></p>
                                                                <p>{wrn.comentario_jef}</p>
                                                            </div>}
                                                            {wrn.comentario_rrhh !== '' && <div className="col-md-3">
                                                                <p><strong>Comentario RR.HH.</strong></p>
                                                                <p>{wrn.comentario_rrhh}</p>
                                                            </div>}
                                                            {wrn.comentario_rel_lab !== '' && <div className="col-md-3">
                                                                <p><strong>Comentario RR.LL.</strong></p>
                                                                <p>{wrn.comentario_rel_lab}</p>
                                                            </div>}
                                                            {wrn.descargar==='TRUE' && <div className="col-md-3">
                                                                <p><strong>Descargar Carta</strong></p>
                                                                <a className="d-block mt-2" onClick={(e) => { 
                                                                    e.preventDefault();
                                                                    getDocument(emplid,wrn.id) }}
                                                                    href="#">
                                                                    <i className="fa fa-file-pdf-o fa-lg" aria-hidden="true" />
                                                                </a>
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </>  
                            )
                        } else {
                            return(
                                <>
                                    <tr>
                                        <p><strong>No se ha encontrado información.</strong></p>
                                    </tr>
                                </>
                            )
                        }
                    })}
                </tbody>
            </table>
            <div className="accordion accordion-flush d-block d-md-none accordion-light accordion-table">
                {warningHistory.map((wrn, idx) => {
                    if (wrn.tipo !=='' && wrn.id !=='') {
                        return (
                            <div className="accordion-item">
                            <h2 className="accordion-header" id={`acordeon-${idx}`}>
                                <button className={idx === 0 ? "accordion-button" : "accordion-button collapsed"}  type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-item-${idx}`} aria-expanded="true" aria-controls={`accordion-item-${idx}`}>
                                    <strong>Tipo de Amonestación</strong>{` ${wrn.tipo}`}
                                </button>
                            </h2>
                                <div id={`accordion-item-${idx}`} className={`accordion-collapse collapse${idx === 0  ? " show" : "accordion-collapse collapse "}`} aria-labelledby={`acordeon-${idx}`}>
                                    <div className="accordion-body p-0">
                                        <div className="data-list-box data-list-box-even">
                                            <ul className="list-column-1 list-unstyled">
                                                <li>
                                                    <span className="data-list-box__title">Fecha Solicitud</span>
                                                    <span className="data-list-box__text">{wrn.fecha}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Estado</span>
                                                    <span className="data-list-box__text"><strong className="color-primary">{wrn.estado}</strong></span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Detalle</span>
                                                    <span className="data-list-box__text">
                                                        <a className="mb-2 collapsed mx-0" href="#" data-bs-toggle="collapse" data-bs-target={`#ver-mas-${idx}`} aria-expanded="false" aria-controls={`ver-mas-${idx}`}>Ver más</a>
                                                    </span>
                                                </li>
                                            </ul>
                                            <div className="accordion accordion-flush">
                                                <div className="accordion-item">
                                                    <div id={`ver-mas-${idx}`} className="accordion-collapse collapse" aria-labelledby={`ver-mas-${idx}`}>
                                                        <div className="data-list-box bg-light">
                                                            <ul className="list-column-2 list-unstyled m-0">
                                                                {wrn.comentario_jef !== '' && <li className="d-block">
                                                                    <span className="data-list-box__title d-block mb-1">Comentario Jefatura</span>
                                                                    <span className="data-list-box__text">{wrn.comentario_jef}</span>
                                                                </li>}
                                                                {wrn.comentario_rrhh !== '' && <li className="d-block">
                                                                    <span className="data-list-box__title d-block mb-1">Comentario RR.HH.</span>
                                                                    <span className="data-list-box__text">{wrn.comentario_rrhh}</span>
                                                                </li>}
                                                                {wrn.comentario_rel_lab !== '' && <li className="d-block">
                                                                    <span className="data-list-box__title d-block mb-1">Comentario RR.LL.</span>
                                                                    <span className="data-list-box__text">{wrn.comentario_rel_lab}</span>
                                                                </li>}
                                                                {wrn.descargar==='TRUE' && <li className="d-block">
                                                                    <span className="data-list-box__title d-block mb-1">Descargar Carta</span>
                                                                    <span className="data-list-box__text">
                                                                        <a className="d-block mt-2" href="#"
                                                                        onClick={(e) => { 
                                                                            e.preventDefault(); 
                                                                            getDocument(emplid,wrn.id) }}>
                                                                            <i className="fa fa-file-pdf-o fa-lg" aria-hidden="true" />
                                                                </a>
                                                                    </span>
                                                                </li>}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    } else {
                        return(
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`acordeon-${idx}`}>
                                    <button className={idx === 0 ? "accordion-button" : "accordion-button collapsed"}  type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-item-${idx}`} aria-expanded="true" aria-controls={`accordion-item-${idx}`}>
                                        <strong>Tipo de Amonestación</strong>{` ${wrn.tipo}`}
                                    </button>
                                </h2>
                                <div id={`accordion-item-${idx}`} className={`accordion-collapse collapse${idx === 0  ? " show" : "accordion-collapse collapse "}`} aria-labelledby={`acordeon-${idx}`}>
                                    <div className="accordion-body p-0">
                                        <div className="data-list-box data-list-box-even">
                                            <ul className="list-column-1 list-unstyled">
                                                <li>
                                                    <span className="data-list-box__title">Fecha Solicitud</span>
                                                    <span className="data-list-box__text">No se ha encontrado información.</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
        </div>
    )
};

export default WarningHistory;