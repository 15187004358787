import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { BsExclamationCircle } from 'react-icons/bs';
import { FaEye, FaTrash } from "react-icons/fa";
import Swal from 'sweetalert2';
import moment from 'moment';
import { useNavigate } from 'react-router';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import SolicitudLicenciasService from '../../services/solicitud-licencias-uy/licencias-uy-service';
import { MessageDinamic } from '../commonComponents';
import { validateForm } from './helpers/valadateForm';
import { handleCertificateError } from './helpers/handleCertificateError';
import { fileModal } from './helpers/fileModal';
import { handleError } from './helpers/handleError';
import { handleFileChange } from './helpers/handleFileChange';
import { handleSuccess } from './helpers/handleSuccess';

const CardLicencias = () => {
    const fileRef = useRef(null);
    const navigate = useNavigate();
    const [showFile, setShowFile] = useState(false);
    const [license, setLicense] = useState({
        id: '',
        licenceCode: '',
        userId: '',
        approver: '',
        beginDate: null,
        returnDate: null,
        status: '',
        comments: '',
        certificateFile: '',
        certificateFileName: '',
        certificateFileType: '',
        certificateFileSize: null
    });
    const [certificateSent, setCertificateSent] = useState(null);
    const [agreedTerms, setAgreedTerms] = useState(true);
    const [data, setData] = useState({});
    const [noData, setNoData] = useState(false);
    const [file, setFile] = useState({
        File: null,
        FileName: '',
        FileType: ''
    });
    const [manager, setManager] = useState({});
    const [licenseOption, setLicenseOption] = useState([]);
    const [loadedFile, setLoadedFile] = useState(false);
    const [modalView, setModalView] = useState(null);
    const [loadingSave, setLoadingSave] = useState(false)
    const [availableDays, setAvailableDays] = useState({})
    const [terms, setTerms] = useState({});
    const [fetched, setFetched] = useState(false);
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(true);
    const [reset, setReset] = useState(false);
    const [error, setError] = useState(false);
    const [licenseSent, setLicenseSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [certificateError, setCertificateError] = useState(false);
    const [responseId, setResponseId] = useState(null);

    useEffect(() => {
        if (reset) {
            setLicense({ ...license, beginDate: null, returnDate: null, comments: '' })
            setReset(false);
        }
    }, [reset])

    useEffect(() => {
        getLicenceData();
        termsBP();
        if (fetched) {
            console.log("fetched");
        }
    }, [fetched])


    useEffect(() => {
        if (code) {
            setAvailableDays(licenseOption.find(x => x.ABSENCE_TYPE === code));
        }
    }, [code, licenseOption]);

    useEffect(() => {
        const handleUpload = async () => {
            if (file.File && licenseSent) {
                await uploadCertificate(responseId);
            }
        };

        handleUpload();
    }, [file, licenseSent, responseId])

    const getLicenceData = async () => {
        await SolicitudLicenciasService.getLicensesInfo(setFetched, setLicenseOption, setData, setLoading, setNoData, setManager);
    }
  
    const uploadCertificate = (id) => {
        const fd = new FormData();
        fd.append("idSolicitud", id)
        fd.append("fileName", file.FileName);
        fd.append("file", file.File);
        // for (var key of fd.entries()) {
        //     console.log(key[0] + ', ' + key[1])
        // }
        SolicitudLicenciasService.uploadCertificate(setLoadingSave, fd, setCertificateSent, setError, setErrorMessage, setCertificateError);
    }
    
    const termsBP = async () => {
        await SolicitudLicenciasService.getTermsBP(setTerms);
    }
    const modalTerms = () => {
        return Swal.fire({
            title: 'BP',
            html: `<div style="text-align: left; height: 300px;"> ${terms?.bpsConditions} </div>`,
            confirmButtonColor: '#0169b5',
        })
    }
    const handleSelect = (e) => {
        setCode(e.target.value);
    }
    
    const sendLicence = () => {
       
            if (validateForm(license, agreedTerms,availableDays )) {
                let info = {
                    approverEmail: manager.MANAGER_EMAIL,
                    approver: manager.MANAGER_ID,
                    approverName: manager.MANAGER_NAME,
                    licenseCode: availableDays.ABSENCE_TYPE,
                    licenseDescription: availableDays.ABSENCE_TYPE_DESCR,
                    beginDate: license.beginDate,
                    returnDate: license.returnDate,
                    commentsDate: moment(new Date()).format('YYYY-MM-DD'),
                    comments: license.comments,
                    fileType: "",
                    fileName: ""
                };
                // console.log(info)
                SolicitudLicenciasService.createLicence(setLoadingSave, info, setError, setErrorMessage, setLicenseSent, setResponseId);
            }
        
    }

    return (
        <>
            <section className="licencias p-0">

                {!loading ? !noData ?
                    <div className="card-box align-items-stretch w-100 mb-4">
                        <div className="license-btn-container">
                            <div className='button-history-container'>
                                <button className="button history"><Link to='historico'>Ver Historico <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i> </Link> </button>
                            </div>
                            <div className="license-btn">
                                <button type="reset" className="button cancel" onClick={() => {  setCode("009"); setReset(!reset) }}>Cancelar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                <button type="submit" className="button save" onClick={sendLicence}>Guardar {loadingSave ? <div className="spinner-border spinner-border-sm"></div> : <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>}</button>
                            </div>
                        </div>
                        <div className="tit-group d-flex justify-content-between">
                            <h3>Solicitud de Licencia <div className='title-underlined'></div></h3>
                            {code ? <div><span className="ic-circle ic-circle-primary">{availableDays?.BALANCE}</span> {availableDays?.BALANCE == 1 ? 'Día' : 'Días'} {availableDays?.BALANCE == 1 ? 'Disponible' : 'Disponibles'}</div> : null}
                        </div>

                        <div className="title-card-container">
                            <h3 className="h5 fw-bold">Aprobador de Licencias: {manager?.MANAGER_NAME}</h3>
                            {/*onClick={() => modalTerms()}*/}
                            <p>Si tu aprobador no es el correcto contactá a tu BP. <a className="terms-anchor" >Consultá acá</a> en caso de duda sobre tu BP.</p>
                        </div>

                        <form className="mt-4">

                            <div className="row">
                                <div className="col-12 col-md-4 mb-3">
                                    <label className="form-label fw-bold">Tipo de Licencia
                                        <Tippy content="Ingrese el tipo correspondiente a su solicitud de Licencia">
                                            <i className="fa fa-question fa-fw fa-lg color-orange" aria-hidden="true"></i>
                                        </Tippy>
                                    </label>
                                    <select className="form-select" value={code} onChange={handleSelect} required type='select' defaultValue={""}>
                                        <option disabled value="">Escoger...</option>
                                        {licenseOption.map((licenseOption, i) => {
                                            return <option key={i} value={licenseOption?.ABSENCE_TYPE}>{licenseOption?.ABSENCE_TYPE_DESCR}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="col-12 col-md-4 mb-3">
                                    <label className="form-label fw-bold">Fecha Inicio
                                        <Tippy content="La fecha de inicio debe ser un día hábil.">
                                            <i className="fa fa-question fa-fw fa-lg color-orange" aria-hidden="true"></i>
                                        </Tippy>
                                    </label>
                                    <div className="input-group">
                                        <input className="form-control license-picker" value={license?.beginDate ? license.beginDate : "mm/dd/yyy"} onChange={e => { setLicense({ ...license, beginDate: e.target.value }) }} required name='beginDate' type="date"></input>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 mb-3">
                                    <label className="form-label fw-bold">Fecha Final
                                        <Tippy content="Para establecer la fecha fin considerá que los días deben ser corridos e incluir los fines de semana.">
                                            <i className="fa fa-question fa-fw fa-lg color-orange" aria-hidden="true"></i>
                                        </Tippy>
                                    </label>
                                    <div className="input-group">
                                        <input className="form-control license-picker" onChange={e => setLicense({ ...license, returnDate: e.target.value })} value={license?.returnDate ? license?.returnDate : "mm/dd/yyyy"} required name='returnDate' type='date'></input>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <label className="form-label fw-bold">Observaciones</label>
                                    <div className="input-group">
                                        <textarea type="text" className="form-control" rows="2" name='comments' value={license?.comments} onChange={e => setLicense({ ...license, comments: e.target.value })}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 mb-3">
                                    <label className="form-label fw-bold">Cargar Certificado
                                        <Tippy content="Puede cargar un certificado en los formatos .jpg, .jpeg, .png, .pdf">
                                            <i className="fa fa-question fa-fw fa-lg color-orange" aria-hidden="true"></i>
                                        </Tippy>
                                    </label>
                                    {loadedFile ? <div className="label-wrapper"><label className='file-name-label'>/{file?.FileName}</label> <FaEye className="certify-icons" onClick={() => setShowFile(true)} ></FaEye> <FaTrash onClick={() => { setLoadedFile(false); setModalView(null); setFile({ File: null, FileName: '', FileType: '' }); }} className="certify-icons"></FaTrash></div>
                                        : <input type="file" className="form-control" aria-label="Adjuntar certificado" ref={fileRef} onChange={(e)=>handleFileChange(e,setFile,setLoadedFile,setModalView)} name="fileLoader" accept=".jpg, .jpeg, .png, .pdf"></input>}
                                    <div>
                                        <label className="file-name" htmlFor="file"><i></i></label>
                                    </div>
                                </div>
                            </div>
                           {/*  <div className="my-3">
                                <h4 className="h5 fw-bold">Términos y Condiciones</h4>
                                <p>Las licencias cuentan con sus respectivos topes:</p>
                                <ul className="list-orange">
                                    <li><strong>Donación de sangre:</strong> 1 día</li>
                                    <li><strong>Examen Terciario/Universitario:</strong> 20 días anuales, con un máximo de 4 días por examen.</li>
                                    <li><strong>Mudanza:</strong> 2 días</li>
                                </ul>
                                <p>Estoy en conocimiento del saldo de días disponibles a la solicitud de la licencia</p>
                            </div>
                            <div className='accept'>
                                <input type="checkbox" className='check form-check-input' name="approvedFlag" value={agreedTerms} checked={agreedTerms} onChange={() => setAgreedTerms(!agreedTerms)} required></input>
                                <label className='form-check-label-licenses' htmlFor="invalidCheck">
                                    Acepto términos y condiciones
                                </label>
                            </div> */}
                        </form>

                        {!showFile ? null : fileModal(modalView,file,setShowFile)}
                        {!licenseSent ? null : handleSuccess(certificateSent,licenseSent,file,setLicenseSent,navigate)}
                        {!error ? null : handleError(errorMessage,setErrorMessage,setError)}
                        {certificateError ? handleCertificateError(licenseSent,certificateSent,setLoadingSave,navigate,setCertificateError) : null}

                    </div>

                    : <div className="alert alert-info alert-dismissible fade show text-center" role="alert">
                        <BsExclamationCircle />  No se encontraron datos asociados
                    </div> : <div className="full-spinner"><div className="spinner-border text-primary"></div></div>
                }

            </section>

        </>

    )
}

export default CardLicencias