const ModalNotUserInfo = ({signOut}) => {
    
    return <> 
    <div className="modal fade show" id="modalNotUserInfo" tabIndex={-1} aria-labelledby="modalNotUserInfo" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-body text-center py-4">
                    <i
                        className="fa fa-exclamation-triangle color-orange fa-3x"
                        aria-hidden="true"
                    ></i>
                    <h2 className="h5">No tienes acceso a autoservicio</h2>
                    <p>
                        No existe información de tu usuario en el sistema.
                    </p>
                </div>
                <div className="modal-footer border-0 pt-0">
                    <button
                        type="button"
                        className="btn btn-degradado"
                        data-bs-dismiss="modal"
                        onClick={(e)=>{e.preventDefault;signOut()}}
                    >Aceptar</button>
                </div>
            </div>
        </div>
    </div>
    <button hidden type="button" id="btnModalNotUserInfo" data-bs-toggle="modal" data-bs-target="#modalNotUserInfo" data-bs-backdrop="static" data-bs-keyboard="false" />
</>
};

export default ModalNotUserInfo;