import Swal from "sweetalert2";

const confirmMessage = (callBack) => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-degradado",
      cancelButton: "btn btn-primary",
    },
    buttonsStyling: false,
  });

  swalWithBootstrapButtons
    .fire({
      title: "Confirmación",
      text: "¿Desea continuar la operación?",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
      showCloseButton: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        if (typeof callBack === "function") {
          callBack();
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
};

export default confirmMessage;
