import React from "react";
import fichaContratacionService from "../../../services/ficha-contratacion-service";
import { Conflicts, Doc } from "./interfaces";

interface HandleDownload {
    setDocument: React.Dispatch<React.SetStateAction<Doc | null>>;
    company: string;
    docType: string;
    idBtn: string;
}

const handleClick = ({ setDocument, company, docType, idBtn }: HandleDownload) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    document.getElementById(idBtn)?.click();
    fichaContratacionService.getMethods.getDocumentList(setDocument, company, docType);
};

const handleDownload = (doc: Doc, setDownloaded: React.Dispatch<React.SetStateAction<boolean>>, target: string) => {
    const fileName = doc.descripcion;
    let archivo = Buffer.from(doc.archivo, "base64");
    let link = document.createElement("a");
    let blob = new Blob([archivo], { type: "application/pdf" });
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.dispatchEvent(new MouseEvent("click"));
    setDownloaded(true);
    document.getElementById(target)?.click();
};

const handleConflicts = (conflict : Conflicts, setConflictModal: React.Dispatch<React.SetStateAction<Conflicts | null>>) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setConflictModal({ ...conflict });
};

const handleRemoveConflicts = (conflict : Conflicts, setConflictsList: React.Dispatch<React.SetStateAction<Array<Conflicts> | null>>) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setConflictsList((prevConflictsList) => prevConflictsList !== null ? prevConflictsList.filter((c) => c.index !== conflict.index) : null);
};

const trasnformFile = (file: string) => {
    return file.includes(";base64,") ? `${file.split(";base64,")[1]}|${file.split(";base64,")[0].replace(/data:.*\//g, "").toUpperCase()}` : file;
};

export {
    handleClick,
    handleDownload,
    handleConflicts,
    handleRemoveConflicts,
    trasnformFile,
}