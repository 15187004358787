import { useSubscriptionContext } from "./context";
import type { SubscriptionItemProps } from "./utils";

export const SubscriptionItem = (props: SubscriptionItemProps) => {
  const { subscription } = props;

  const { handleChange } = useSubscriptionContext();
  
  return (
    <div className="form-check mb-2 d-flex align-items-center">
      <input
        type="checkbox"
        id={subscription.value}
        defaultChecked={subscription.selected}
        className="form-check-input"
        checked={subscription.selected}
        onChange={() => handleChange(subscription.value)}
      />
      <label
        className="form-check-label fw-normal ms-4"
        htmlFor={subscription.value}
      >
        {subscription.title}
      </label>
    </div>
  );
};
