import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BsExclamationCircle } from 'react-icons/bs';
import ReplacementAssignmentTable from './replacement-assignment-table';
import ReplacementsService from '../../services/asignacion-reemplazo/replacements-service';
import ReplacementExportableComponent from './replacement-exportable';
import styles from './replacement-assignment.module.css';


const ReplacementAssignment = () => {
    const [loadingTable, setLoadingTable] = useState(false);
    const [userReplacements, setUserReplacements] = useState([]);
    const [dataNotFetched, setDataNotFetched] = useState(false);
    const [reload, setReload] = useState(false);

    useEffect(async () => {
        setTableValues();
    }, [])

    useEffect(() => {
        if (reload) {
            setTableValues();
            setReload(false);
        }
    }, [reload])

    const setTableValues = async () => {
        try {
            await ReplacementsService.getUserReplacements(setLoadingTable, setUserReplacements, setDataNotFetched);

        } catch (error) {
            console.error('Error while setting values:', error);
        }
    }

    const handleClick = () =>{
        setTableValues()
    }

    return (
        <React.Fragment>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home color-orange" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Asignación Back Up/Reemplazo
                    </li>
                </ol>
            </nav>
            <section className="noticias mb-3">
                <h1>Asignación Back Up/Reemplazo</h1>
                <div className="col-12 position-relative mb-0">
                    {/* <a className="btn btn-degradado btn-absolute" href="#">Ver más <i className="fa fa-chevron-right" aria-hidden="true"></i></a> */}
                </div>
            </section>

            {dataNotFetched ? ( // Render the alert if dataNotFetched is true
                <div className="alert alert-info alert-dismissible fade show text-center" role="alert">
                    <BsExclamationCircle />  No se encontraron datos asociados
                </div>
            ) : (
                <div className={styles['body-replacement-container']}>
                    {!loadingTable ? (
                        <>
                            <section id="contenido" className={`container justify-content-center ${styles['center-align']}`}>
                                <div className={styles['box-container']}>
                                    <ReplacementExportableComponent replacements={userReplacements} />
                                </div>
                            </section>
                            <ReplacementAssignmentTable replacements={userReplacements} onUpdate={setReload} handleClick={handleClick} />
                        </>
                    ) : (
                        <div className='d-flex justify-content-center'>
                            <div className="spinner-border text-primary"></div>
                        </div>
                    )}
                </div>
            )
            }
        </React.Fragment >
    )
}

export default ReplacementAssignment