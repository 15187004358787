import Swal from "sweetalert2";

export const handleCertificateError = (licenseSent,certificateSent,setLoadingSave,navigate,setCertificateError) => {
    if (licenseSent && !certificateSent) {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color:orange; font-size: 16px">Advertencia</div>',
            html: '<div style="text-align: center; font-size: 15px">Su licencia ha sido enviada sin archivo debido a un error en la subida, intentelo nuevamente desde el histórico</div>',
            confirmButtonColor: '#0169b5',
        }).then((result) => {
            setLoadingSave(false);
            if (result.isConfirmed) {
                navigate("historico");
            }
        })
    }
    setCertificateError(false);
}