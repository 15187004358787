import React, { useEffect, useState } from 'react';
import { Text, Image, StyleSheet, View, Page, Document, PDFDownloadLink } from "@react-pdf/renderer";
import defaultColaboradorImage from "../../../resources/images/default-colaborador-image.png";
import { formatDate } from "../../../services/utiles";

const SummaryRegional = ({
    colaborador,
    foto,
    statusPosition,
    loadingPosition
}) => {
    const DatosPuestoPDF = () => {
        const styles = StyleSheet.create({
            page: {
                backgroundColor: '#fff',
                padding: '20px',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
            },
            card: {
                padding: "20px",
                borderRadius: "10px",
                width: "48%",
                border: "1px solid #eeeeee",
                margin: " 0px 5px 20px 5px",
                height: "auto",
            },
            head: {
                display: "flex",
                marginBottom: "20px",
                paddingLeft: "10px",
            },
            body: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "20px"
            },
            h1: {
                fontSize: "16px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: "10px",
                color: "#0169b5"
            },
            table: {
                width: "100%",
                borderCollapse: "collapse",
                borderSpacing: "0",
                marginBottom: "20px"
            },
            tableHead: {
                backgroundColor: "#eeeeee",
                borderBottom: "1px solid #eeeeee",
                borderTop: "1px solid #eeeeee",
                borderLeft: "1px solid #eeeeee",
                borderRight: "1px solid #eeeeee",
                padding: "5px",
                fontSize: "8px",
            },
            tableBody: {
                borderBottom: "1px solid #eeeeee",
                borderLeft: "1px solid #eeeeee",
                borderRight: "1px solid #eeeeee",
                fontSize: "8px",
                textAlign: "center",
                color: "#777777",
            },
            tableRow: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                padding: "5px 5px 5px 5px",
                border: "1px solid #eeeeee",
            },
            labelHead: {
                textAlign: "center",
                color: "#777777",
                fontWeight: "900",
            },
            labelBody: {
                textAlign: "center",
                color: "#777777",
                fontWeight: "normal",
            },
            li: {
                display: "flex",
                flexDirection: "row",
                alignItems: 'flex-start',
                padding: "10px",
                borderBottom: "1px solid #eeeeee",
                position: "relative",
                justifyContent: "flex-start"
            },
            liTitle: {
                width: "50%",
            },
            liContent: {
                width: "50%",
            },
            titleLabel: {
                fontSize: "8px",
                fontWeight: "bold",
                color: "#777777"
            },
            contentLabel: {
                fontSize: "8px",
                fontWeight: "normal",
                color: "#777777"
            },
            foto: {
                width: "100px",
                height: "100px",
            },
            checkimg: {
                width: "20px",
                height: "20px",
            },
        });

        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={{ ...styles.card, width: "48%" }}>
                        <View style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
                            <Image source={colaborador.CN_PERS_LIST.foto || defaultColaboradorImage} style={styles.foto} />
                        </View>
                        <View style={styles.body}>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>NOMBRE</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_PERS_LIST.nombre}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>Codigo Impositivo</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_PERS_LIST.taxId}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>FECHA DE NACIMIENTO</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{formatDate(colaborador.CN_PERS_LIST.fecha_nacimiento)}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>EDAD</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_PERS_LIST.edad}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>PCD</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_PERS_LIST.pcd}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>NACIONALIDAD</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_PERS_LIST.nacionalidad}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        )
    }

    return (
        <>
            {loadingPosition ? null : statusPosition == 200 ? (<>

                {/* {colaborador ? ( */}
                <div className='col-12 col-xl-5 d-flex'>
                    <div className="box align-items-stretch w-100">
                        <div className="data-list-box text-uppercase">
                            <ul className="list-unstyled">
                                <li className="justify-content-center border-0">
                                    {!foto && <img src={defaultColaboradorImage} alt="foto colaborador" className="rounded-circle img-fluid mb-2" width="160" height="160" />}
                                    {foto && <img src={foto} alt="foto colaborador" className="rounded-circle img-fluid mb-2" width="160" height="160" />}
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-6">Nombre</span>
                                    <span className="data-list-box__text">{colaborador.CN_PERS_LIST.nombre}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-6">Identificador</span>
                                    <span className="data-list-box__text">{colaborador.CN_PERS_LIST.rut}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-6">Fecha de nacimiento</span>
                                    <span className="data-list-box__text">{formatDate(colaborador.CN_PERS_LIST.fecha_nacimiento)}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-6">Edad</span>
                                    <span className="data-list-box__text">{colaborador.CN_PERS_LIST.edad}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-6">Nacionalidad</span>
                                    <span className="data-list-box__text">{colaborador.CN_PERS_LIST.nacionalidad}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </>) : (
                <div>
                    <strong>Sin Registros</strong>
                </div>
            )}
        </>
    );
};

export default SummaryRegional;