import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState,useEffect } from 'react';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import ModalAvatar from '../elements/modal-avatar';
import DatosLaboralesService from '../../services/datos laborales/datos-laborales-service';



const ProfileImage = () => {
    const [avatar, setAvatar] = useState(null);
    // eslint-disable-next-line react/prop-types
    const [showModalAvatar, setShowModalAvatar] = useState(false);
    const closeModalAvatar = () => setShowModalAvatar(false);

    useEffect(() => {
        loadAvatar();
    }, []);

    const loadAvatar = async () => {
        try{
            const response = await DatosLaboralesService.getAvatarRegional();
            setAvatar(response.data);
        } catch (e){
            setAvatar(null);
        }
    }
    return (
        <>
            <div>
            <ModalAvatar openModal={showModalAvatar} closeModal={closeModalAvatar} updateAvatarHeader={setAvatar} />
                <div className="outer">
                    {avatar ? <img src={avatar} alt="" className="rounded-circle mb-3 mb-md-0" width="120" height="120" />
                        : (
                            <svg style={{ opacity: "0.75", fill: `url(#SVGID_1_)` }} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 170.4 169.8" xmlSpace="preserve">

                                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="2.8226" y1="83.7369" x2="166.4679" y2="83.7369">
                                    <stop offset="0.1534" style={{ "stop-color": "#0069B4" }} />
                                    <stop offset="0.2558" style={{ "stop-color": "#0069B4" }} />
                                    <stop offset="0.3273" style={{ "stop-color": "#0B73BB" }} />
                                    <stop offset="0.6238" style={{ "stop-color": "#3498D4" }} />
                                    <stop offset="0.8583" style={{ "stop-color": "#4DAFE3" }} />
                                    <stop offset="1" style={{ "stop-color": "#56B8E9" }} />
                                </linearGradient>
                                <path className="st0" d="M84.8,1.9c11.1,0,21.6,2.3,31.7,6.5s18.9,10.1,26.1,17.3s13.1,16.1,17.3,26.1s6.5,20.6,6.5,31.7
	s-2.3,21.6-6.5,31.7s-10.1,18.9-17.3,26.1s-16.1,13.1-26.1,17.6s-20.6,6.5-31.9,6.5s-21.9-2.3-31.9-6.5
	c-10.1-4.3-18.9-10.1-26.1-17.6c-6.8-7.3-12.8-16.1-17.1-26.1S2.8,94.7,2.8,83.6S5.1,62,9.4,51.9s10.1-18.9,17.3-26.1
	S42.8,12.7,53.1,8.5S73.7,1.9,84.8,1.9z M141.3,125.1c9-12.6,13.6-26.4,13.6-41.5c0-9.6-1.8-18.6-5.5-27.1s-8.8-16.1-15.1-22.4
	S120.5,22.8,111.9,19s-17.6-5.5-27.1-5.5S66.2,15.2,57.6,19s-16.1,8.8-22.4,15.1S23.9,47.9,20.2,56.5s-5.5,17.6-5.5,27.1
	c0,15.3,4.5,29.2,13.6,41.5c4-19.9,13.3-29.9,27.9-29.9c8,7.8,17.6,11.6,28.7,11.6s20.6-3.8,28.7-11.6
	C128,95.4,137.3,105.2,141.3,125.1z M119.7,66c0-9.6-3.5-17.8-10.3-24.9c-6.8-7-15.1-10.3-24.9-10.3s-17.8,3.5-24.9,10.3
	S49.3,56.2,49.3,66s3.5,17.8,10.3,24.9s15.1,10.3,24.9,10.3s17.8-3.5,24.9-10.3S119.7,75.8,119.7,66z"/>
                            </svg>)}
                            <div  className="inner" > 
                            <Tippy content="Actualizar Foto" placement="right"><span onClick={() =>  setShowModalAvatar(true)}><FontAwesomeIcon icon={faCamera}className="btn-ic" /></span></Tippy></div>
                </div>
               
            </div>

           
        </>
    )
}

export default ProfileImage
