import React, { FunctionComponent, useContext } from "react";
import FichaSomeErrorModal from "../commons/plain/modals/bigFileModal";
import SendingInformation from "../commons/plain/modals/sendingInfo";
import MainPage from "./mainPage";
import { Context } from "../../../context";
import { ContextInterface } from "../../../context/utilities/interfaces";

const FichaIngreso: FunctionComponent = () => {
    const contextt = useContext<ContextInterface | null>(Context);
    const step = contextt !== null ? contextt.step : 0;
    const setStep = contextt !== null ? contextt.setStep : () => { };
    const getSheet = contextt !== null ? contextt.getSheet : null;
    return (
        <>
           {getSheet !== null ? (
                <>
                    {step !== 0 && step < 8? (
                        <>
                            <nav className="breadcrumbs" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">{"Paso " + step + " de " + getSheet.numberOfPages}</li>
                                </ol>
                            </nav>
                            <h1>{"Registro Empleado"}</h1>
                        </>
                    ) : null}
                    <MainPage stepState={[step, setStep]} getSheet={getSheet} />
                    <SendingInformation />
                    <FichaSomeErrorModal />
                </>
            ) : (
                <>
                    <div className="text-center">
                        <h3>Cargando...</h3>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    </div>
                </>
            )}
        </>
    );
};

export default FichaIngreso
