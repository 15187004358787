import Auth from "@aws-amplify/auth";
import { urlsAws } from "../resources/foo/api-endpoints";
const datosPersonalesService = {
    datosPersonales: (setData, objectid, emplid) => {
        Auth.currentSession().then((auth) => {
            objectid = objectid.replace(" ", "_");
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: JSON.stringify({ request: { emplid: "", objectid: objectid.replace(" ", "_") } }),
            };
            fetch(urlsAws.getDatosPersonales , requestOptions)
                .then((response) => response.text())
                .then((result) => result.replaceAll(",}", "}"))
                .then((result) => JSON.parse(result))
                .then((data) => {
                    if (data.message) data = false;
                    if (data) {
                        caches.open("api-cache").then((cache) => {
                            data.response.sections = data.response.sections ? (Array.isArray(data.response.sections) ? data.response.sections : [data.response.sections]) : [];
                            data.response.sections =
                                data.response.sections.length > 0 &&
                                data.response.sections.map((section) => {
                                    section.headers = section.headers ? (Array.isArray(section.headers) ? section.headers : [section.headers]) : [];
                                    section.rows = section.rows ? (Array.isArray(section.rows) ? section.rows : [section.rows]) : [];
                                    if (section.style === "table") {
                                        section.rows =
                                            section.rows.length > 0 &&
                                            section.rows.map((row) => {
                                                if (row.items !== undefined) {
                                                    let itemsordenados = [];
                                                    for (let i = 0; i < section.headers.length; i++) for (let j = 0; j < row.items.length; j++) if (row.items[j].formid === section.headers[i].formid) itemsordenados.push(row.items[j]);
                                                    row.items = itemsordenados;
                                                }
                                                return row;
                                            });
                                    }
                                    section.rows =
                                        section.rows.length > 0 &&
                                        section.rows.map((row) => {
                                            row.items = row.items ? (Array.isArray(row.items) ? row.items : [row.items]) : [];
                                            return row;
                                        });
                                    section.forms = section.forms ? (Array.isArray(section.forms) ? section.forms : [section.forms]) : [];
                                    section.forms =
                                        section.forms.length > 0 &&
                                        section.forms.map((form) => {
                                            form.values = form.values ? (Array.isArray(form.values) ? form.values : [form.values]) : [];
                                            return form;
                                        });
                                    return section;
                                });
                            cache.put(urlsAws.getDatosPersonales + "_" + objectid, new Response(JSON.stringify(data)));
                            setData(data.response);
                        });
                    } else {
                        setData("error");
                    }
                })
                .catch((error) => {
                    caches.open("api-cache").then(() => {
                        caches.match(urlsAws.getDatosPersonales + "_" + objectid).then((response) => {
                            if (response) {
                                response.json().then((data) => {
                                    setData(data.response);
                                });
                            }
                        });
                    });
                    console.log("error", error);
                });
        }).catch((err) => console.log(err));
    },
    sendRequestModal: (request, endpoint, setSuccess, setRequestMessage, close, id) => {
        Auth.currentSession().then((auth) => {
            const head = { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() };
            const body = JSON.stringify({ request: request, endpoint: endpoint });
            const requestOptions = {
                method: "POST",
                headers: head,
                body: body,
            };
            fetch(urlsAws.sendRequestModal , requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    setSuccess(data.response.valid === "1" ? 1 : 0);
                    setRequestMessage(data.response.status);
                    setTimeout(() => {
                        document.getElementById("modal-close-" + id).click();
                        document.getElementById("success-btn-" + id).click();
                        close();
                    }, 600);
                })
                .catch((error) => {
                    setSuccess(0);
                    setRequestMessage(false);
                });
        }).catch((err) => console.log(err));
    },
    getTitles: async (emplid, objectIdList, setTitles) => {
        Auth.currentSession().then(async (auth) => {
            Promise.all(
                await objectIdList.map(async (objectId) => {
                    const requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                        body: JSON.stringify({ request: { emplid: "", objectid: objectId.toLowerCase() } }),
                    };
                    const response = await fetch(urlsAws.getDatosPersonales , requestOptions);
                    const result = await response.text();
                    const data = result.replaceAll(",}", "}");
                    const dataJson = await JSON.parse(data);
                    await caches.open("api-cache").then((cache) => {
                        cache.put(urlsAws.getDatosPersonales + "_" + objectId.toLowerCase(), new Response(data));
                    });
                    return dataJson.response.title;
                })
            )
            .then((data) => {
                window.sessionStorage.setItem("titles", JSON.stringify(data));
                setTitles(data);
            })
            .catch((error) => {
                console.log("error", error);
                setTitles(JSON.parse(window.sessionStorage.getItem("titles")));
            });
        }).catch((err) => console.log(err))
    },
};
export default datosPersonalesService;

