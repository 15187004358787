import Swal from "sweetalert2";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
  allowOutsideClick: false,
});

const successMessage = (callBack) => {
  swalWithBootstrapButtons
    .fire("Finalizado", "La acción se realizó correctamente.", "success")
    .then((result) => {
      if (result.isConfirmed) {
        if (typeof callBack === "function") {
          callBack();
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
};

export default successMessage;
