import React, { useEffect, useState } from 'react';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import TablaVacaciones from './tabla-vacaciones';
import VacacionesService from '../../services/solicitud-vacaciones-uy/vacaciones-uy-service';
import { Link } from 'react-router-dom';
import errorMessage from '../SharedComponents/error-message';



const SolicitudesVacaciones = () => {
  const estadosOption = [
    { value: "", description: "Todas" },
    { value: "P", description: "Pendientes" },
    { value: "A", description: "Aprobadas" },
    { value: "R", description: "Rechazadas" },
    { value: "C", description: "Canceladas" }
  ];

  const [estado, setEstado] = useState(estadosOption.value);
  const [absenceListFiltered, setAbsenceListFiltered] = useState([]);
  const [absenceList, setAbsenceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(null);
  const [noData, setNoData] = useState(null);


  useEffect(() => {
    loadAusencias();
  }, [])

  const handleFilter = (e) => {
    if (e.target.value != "") {
      setAbsenceListFiltered(absenceList.filter(x => e.target.value === x.ESTADO))
    }else{
      setAbsenceListFiltered(absenceList)
    }
  }

  const loadAusencias = async () => {
    try {        
      const response = await VacacionesService.getAbsenceList();
      if (
        response !== null &&
        response !== undefined &&
        (response?.statusCode === 200 || response?.status === 200)
      ) {
        setAbsenceList(response?.data);
        setAbsenceListFiltered(response?.data);
        setLoading(false)
      } else {
        setLoading(false)
      }

      setLoading(false);
    } catch (error) {
        errorMessage(null, error?.response?.data);
        setLoading(false);
    }
  }
  return (
    <>
      {loading ? <div className="full-spinner"><div className="spinner-border text-primary"></div></div> :
        <div className='historic-container'>
          <div className='container-header'>
            <h4 className="form-title tit">Filtrar</h4>
            <div className='filters-fields'>
              <label className='label fw-bold'>Estado</label>
              <select className="form-select filter-input" value={estado} onChange={handleFilter}>
                {estadosOption.map((op, i) => {
                  return <option key={i} value={op.value}>{op.description}</option>
                })}
              </select>
            </div>
          </div>
          <div className="container-header">
          <div className="table-title">
              <h4 className="form-title tit">Detalle</h4>
              <Link to="../" className="h5 mt-2 fl-normal fw-bold text-decoration-none fs-6">Nueva Solicitud<i className="fa fa-plus-square-o fa-lg ms-2 color-orange" aria-hidden="true"></i></Link>
            </div>
            <TablaVacaciones list={absenceListFiltered} loadAusencias={loadAusencias}></TablaVacaciones>
          </div>
        </div>}
    </>

  )
}

export default SolicitudesVacaciones
