const stepOnePersonal = [
    {
        title: "RUT",
        inputType: "text",
        classNameType: "form-control",
        id: "national_id",
        required: true,
        disabled: false,
        readOnly: true,
        onKeyUpText: "El RUT ingresado no existe",
        onInvalidText: "Debe ingresar un RUT válido",
        onInputText: "El RUT ingresado no existe",
        pattern: /.*/,
        optionSelect: "",
    },
    {
        title: "Primer Nombre",
        inputType: "text",
        classNameType: "form-control",
        id: "firstname",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar un nombre",
        onInputText: "",
        pattern: /^[a-zA-Z\u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1][a-zA-Z- \u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1]*[a-zA-Z\u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1]$/,
        optionSelect: "",
    },
    {
        title: "Segundo Nombre",
        inputType: "text",
        classNameType: "form-control",
        id: "middlename",
        required: false,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar un nombre válido",
        onInputText: "",
        pattern: /^[a-zA-Z\u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1][a-zA-Z- \u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1]*[a-zA-Z\u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1]$/,
        optionSelect: "",
    },
    {
        title: "Apellido Paterno",
        inputType: "text",
        classNameType: "form-control",
        id: "lastname",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar un apellido",
        onInputText: "",
        pattern: /^[a-zA-Z\u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1][a-zA-Z- \u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1]*[a-zA-Z\u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1]$/,
        optionSelect: "",
    },
    {
        title: "Apellido Materno",
        inputType: "text",
        classNameType: "form-control",
        id: "secondlastname",
        required: false,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar un apellido válido",
        onInputText: "",
        pattern: /^[a-zA-Z\u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1][a-zA-Z- \u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1]*[a-zA-Z\u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1]$/,
        optionSelect: "",
    },
    {
        title: "Fecha de Nacimiento",
        inputType: "date",
        classNameType: "form-control",
        id: "birthdate",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar una fecha tal que su edad sea mayor o igual a 15 años",
        onInputText: "",
        pattern: /.*/,
        optionSelect: "",
        dateValidation: "bir",
    },
    {
        title: "Sexo",
        inputType: "",
        classNameType: "form-select",
        id: "sex",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar un sexo",
        onInputText: "",
        pattern: /.*/,
        optionSelect: "sexOptions",
    },
    {
        title: "Estado Civil",
        inputType: "",
        classNameType: "form-select",
        id: "mar_status",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar un estado civil",
        onInputText: "",
        pattern: /.*/,
        optionSelect: "civilOptions",
    },
    {
        title: "Nacionalidad",
        inputType: "",
        classNameType: "form-select",
        id: "birthcountry",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar un país",
        onInputText: "",
        pattern: /.*/,
        optionSelect: "countryOptions",
    },
    {
        title: "Tipo de Visa",
        inputType: "",
        classNameType: "form-select",
        id: "visa_permit_type",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar una opción",
        onInputText: "",
        pattern: /.*/,
        optionSelect: "visaOptions",
    },
    {
        title: "Fecha Expiración",
        inputType: "date",
        classNameType: "form-control",
        id: "expiratn_dt",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar una fecha de expiración mayor a la actual",
        onInputText: "",
        pattern: /.*/,
        optionSelect: "",
        dateValidation: "exp",
    },
];

const stepOneContact = [
    {
        title: "Correo",
        inputType: "text",
        classNameType: "form-control",
        id: "emailaddr",
        required: true,
        disabled: false,
        readOnly: true,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar un email válido",
        onInputText: "",
        pattern: /.*/,
        optionSelect: "",
    },
    {
        title: "Teléfono Celular",
        inputType: "text",
        classNameType: "form-control",
        id: "countryCode",
        required: false,
        disabled: false,
        readOnly: true,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar un teléfono chileno válido",
        onInputText: "",
        pattern: /.*/,
        optionSelect: "",
        inputNumber: 2,
        nextInput: {
            title: "Teléfono Celular",
            inputType: "tel",
            classNameType: "form-control",
            id: "phone",
            required: true,
            disabled: false,
            readOnly: false,
            onKeyUpText: "",
            onInvalidText: "Debe ingresar un teléfono chileno válido",
            onInputText: "",
            pattern: /^[0-9]{8}$/,
            optionSelect: "",
            maxLength: 8,
        },
    },
];

const stepTwoAddress = [
    {
        title: "Calle",
        inputType: "text",
        classNameType: "form-control",
        id: "address1",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar una calle",
        onInputText: "",
        pattern: /^[a-zA-Z0-9\u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1][a-zA-Z0-9- \u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1]*[a-zA-Z0-9\u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1]$/,
        optionSelect: "",
    },
    {
        title: "Número",
        inputType: "text",
        classNameType: "form-control",
        id: "address2",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar un número válido",
        onInputText: "",
        pattern: /^[0-9a-zA-Z]$|^[0-9a-zA-Z][0-9a-zA-Z\/]*[0-9a-zA-Z]$/,
        optionSelect: "",
    },
    {
        title: "Número depto/casa",
        inputType: "text",
        classNameType: "form-control",
        id: "address4",
        required: false,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar un número válido",
        onInputText: "",
        pattern: /^[0-9a-zA-Z]$|^[0-9a-zA-Z][0-9a-zA-Z\/]*[0-9a-zA-Z]$/,
        optionSelect: "",
    },
    {
        title: "Región",
        inputType: "",
        classNameType: "form-select",
        id: "state",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar una región",
        onInputText: "",
        pattern: /.*/,
        optionSelect: "regionsOptions",
    },
    {
        title: "Comuna",
        inputType: "",
        classNameType: "form-select",
        id: "city",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar una comuna",
        onInputText: "",
        pattern: /.*/,
        optionSelect: "citiesOptions",
    },
    {
        title: "Provincia",
        inputType: "text",
        classNameType: "form-control",
        id: "county",
        required: true,
        disabled: false,
        readOnly: true,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar una provincia",
        onInputText: "",
        pattern: /.*/,
        optionSelect: "",
    },
];

const stepThreeHighschool = [
    {
        title: "Establecimiento",
        inputType: "text",
        classNameType: "form-control",
        id: "school",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar un establecimiento",
        onInputText: "",
        pattern: /^[a-zA-Z0-9\u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1][a-zA-Z0-9- \u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1]*[a-zA-Z0-9\u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1]$/,
        optionSelect: "",
    },
    {
        title: "Fecha de Graduación",
        inputType: "date",
        classNameType: "form-control",
        id: "date_acquired",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar una fecha menor o igual a la actual",
        onInputText: "",
        pattern: /.*/,
        optionSelect: "",
        dateValidation: "sch",
    },
];

const stepThreeCollege = [
    {
        title: "Titulado / Egresado",
        inputType: "",
        classNameType: "form-select",
        id: "isGraduated",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe seleccionar una opción",
        onInputText: "",
        pattern: /.*/,
        optionSelect: "isGraduatedOptions",
    },
    {
        title: "Universidad / IP / CFT",
        inputType: "text",
        classNameType: "form-control",
        id: "school_official",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar un establecimiento",
        onInputText: "",
        pattern: /^[a-zA-Z0-9\u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1][a-zA-Z0-9- \u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1]*[a-zA-Z0-9\u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1]$/,
        optionSelect: "",
    },
    {
        title: "Fecha de Titulación",
        inputType: "date",
        classNameType: "form-control",
        id: "dt_issued",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar una fecha menor o igual a la actual",
        onInputText: "",
        pattern: /.*/,
        optionSelect: "",
        dateValidation: "col",
    },
    {
        title: "Título",
        inputType: "text",
        classNameType: "form-control",
        id: "acomplishment_txt",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar un título",
        onInputText: "",
        pattern: /^[a-zA-Z0-9\u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1][a-zA-Z0-9- \u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1]*[a-zA-Z0-9\u00C1\u00C9\u00CD\u00D3\u00DA0\u00E1\u00E9\u00ED\u00F3\u00FA\u00f1\u00d1]$/,
        optionSelect: "",
    },
];

const stepFourPension = [
    {
        title: "AFP",
        inputType: "",
        classNameType: "form-select",
        id: "cn_afp_cd",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe seleccionar una AFP. Si no está afiliado, marque \"Sin AFP\"",
        onInputText: "",
        pattern: /.*/,
        optionSelect: "afpOptions",
    },
    {
        title: "Fecha de ingreso al sistema previsional",
        inputType: "date",
        classNameType: "form-control",
        id: "cn_fec_afp",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar una fecha menor o igual a la actual",
        onInputText: "",
        pattern: /.*/,
        optionSelect: "",
        requireModal: true,
        dateValidation: "afp",
    },
    {
        title: "Salud",
        inputType: "text",
        classNameType: "form-control",
        id: "health",
        required: true,
        disabled: false,
        readOnly: true,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar un sistema de salud",
        onInputText: "",
        pattern: /.*/,
        optionSelect: "",
    },
    {
        title: "",
        inputType: "text",
        classNameType: "form-control",
        id: "cn_cot_salud_cd",
        required: true,
        disabled: false,
        readOnly: true,
        hidden: true,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar un sistema de salud",
        onInputText: "",
        pattern: /.*/,
        optionSelect: "",
    },
    {
        title: "Nombre Isapre",
        inputType: "",
        classNameType: "form-select",
        id: "cn_isapre_cd",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar un sistema de salud",
        onInputText: "",
        pattern: /.*/,
        optionSelect: "isapresOptions",
    },
    {
        title: "Valor Plan (UF)",
        inputType: "text",
        classNameType: "form-control",
        id: "cn_cot_salud_mnt",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar el valor de su plan separada por \",\" con un máximo de tres dígitos decimales. El valor no puede superar las 21UF.",
        onInputText: "",
        pattern: /^(?:(20|[1]?[1-9])(?:\d{3}){0,4}?$|^(20|[1]?[0-9]))(?:,(?!0+$)\d{1,3})?$|^(21)$/,
        optionSelect: "",
    },
];

const stepSixAccount = [
    {
        title: "Banco",
        inputType: "",
        classNameType: "form-select",
        id: "bank",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar un banco",
        onInputText: "",
        pattern: /.*/,
        optionSelect: "bankOptions",
    },
    {
        title: "Tipo de Cuenta",
        inputType: "",
        classNameType: "form-select",
        id: "tipocta",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar un tipo",
        onInputText: "",
        pattern: /.*/,
        optionSelect: "accountTypeOptions",
    },
    {
        title: "Número cuenta",
        inputType: "text",
        classNameType: "form-control",
        id: "numcta",
        required: true,
        disabled: false,
        readOnly: false,
        onKeyUpText: "",
        onInvalidText: "Debe ingresar un número de cuenta válido",
        onInputText: "",
        pattern: /^[0-9]{5,35}$/,
        optionSelect: "",
    },
];

export {
    stepOnePersonal,
    stepOneContact,
    stepTwoAddress,
    stepThreeHighschool,
    stepThreeCollege,
    stepFourPension,
    stepSixAccount,
};