/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { HiOutlineArrowNarrowDown, HiOutlineArrowNarrowUp } from "react-icons/hi";
import moment from 'moment';
import { Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';

// eslint-disable-next-line react/prop-types
const TablaVacaciones = ({ isDisabled, action, info }) => {
  const [state, setState] = useState(false);
  const [ausencias, setAusencias] = useState([])
  const [ausenciasId, setAusenciasId] = useState([])
  const [idDropdown, setIdDropdown] = useState(null)
  const [disabled, setDisabled] = useState(true)
  const [order, setOrder] = useState("DES")

  const isPendiente = (ausencia) => {
    return ausencia && ausencia.state === 'P';
  }
  const sortByDate = () => {
    if (order === "ASC") {
      info = info.sort((a, b) => moment(b.beginDate, "AAAA-MM-DD").unix() - moment(a.beginDate, "AAAA-MM-DD").unix())
      setOrder("DES")
    } else {
      info = info.sort((a, b) => moment(a.beginDate, "AAAA-MM-DD").unix() - moment(b.beginDate, "AAAA-MM-DD").unix())
      setOrder("ASC")
    }
  }

  useEffect(() => {
    setDisabled(ausencias.length === 0)
  }, [ausencias])

  const onChangeInput = (e) => {
    if (e.target.checked) {
      setAusenciasId([...ausenciasId, e.target.id]);
      setAusencias([...ausencias, e.target.value]);
    } else {
      setAusencias(ausencias.filter(aus => aus !== e.target.value))
      setAusenciasId(ausenciasId.filter(aus => aus !== e.target.id))
    }
  };

  const selectAll = (e) => {
    if (e.target.checked) {
      setAusenciasId(info.filter(aus => isPendiente(aus)).map(aus => aus.applicationId))
      setAusencias(info.filter(aus => isPendiente(aus)).map(aus => JSON.stringify({
        applicationId: aus.applicationId,
        applicationState: aus.state,
        applicationComments: aus.applicantComments
      })))
    } else {
      setAusenciasId([])
      setAusencias([])
    }
  }

  isDisabled(disabled)

  useEffect(() => {
    action(ausencias)
  }, [ausencias])

  const dropdown = (e, i) => {
    e.preventDefault();
    setIdDropdown(i);
    if (state) {
      setState(false);
    } else {
      setState(true);
    }
  };

  return (
    <>
    <div className="container card-box table-header-fixed table-aprobacion-vacaciones mb-3">
    <h3>Detalle <div className='title-underlined'></div></h3>
      <div className="responsive-table">
        <table className="table">
          <thead className="table-header-border">
            <tr>
              <th>
                <input className="form-check-input me-2 h5" type="checkbox" onChange={e => selectAll(e)}></input>
              </th>
              <th className="cap">Nombre</th>
              <th className="cap">Fecha Inicio {order === "DES" ? <HiOutlineArrowNarrowDown className="color-orange fw-bold"  onClick={sortByDate} /> : <HiOutlineArrowNarrowUp className="color-orange fw-bold"  onClick={sortByDate}/>}</th>
              <th className="cap">Fecha Final</th>
              <th className="cap">Duracion</th>
              <th className="cap">Detalle</th>
              <th className="cap">Estado</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {info && info.map((employee, i) => {
              return (
              <tr  key={employee.applicationId}>
                <td>
                  <input className="form-check-input me-2 h5" type="checkbox" id={employee.applicationId} onChange={(e) => onChangeInput(e)} value={JSON.stringify({
                    applicationId: employee.applicationId,
                    applicationState: employee.state,
                    applicationComments: employee.applicantComments
                  })} disabled={!isPendiente(employee)} checked={ausenciasId.includes(employee.applicationId)}></input>
                </td>
                <td>{employee.employeeName}</td>
                <td>{employee.beginDate}</td>
                <td>{employee.returnDate}</td>
                <td>{employee.durationDays} dia/s</td>
                <td>{employee.applicantComments}</td>
                <td>
                  <div className={`boton-estado ${employee.state === 'A' ? "Aprobada" : employee.state === 'P' ? "Pendiente" : "Rechazada"}`}>{employee.state === 'A' ? "Aprobada" : employee.state === 'P' ? "Pendiente" : "Rechazada"}</div>
                </td>
                <td>
                  <div className="dropdown d-flex">
                    <div>
                      <button className="btn-dropdown" onClick={e => dropdown(e, i)}><BsThreeDotsVertical /></button>
                      {state && i === idDropdown ? (
                        <ul className="dropdown-item">
                          <li><Link to='detalle-aprobacion' state={employee}>Detalles</Link></li>
                        </ul>
                      ) : null}
                    </div>
                  </div>
                </td>
              </tr>)
            })
            }

          </tbody>
        </table>
        {info.length === 0 && <span className="d-flex justify-content-center">No se encontraron ausencias</span>}
      </div>
         {/*responsive-table */}
    </div>
      <div className="box mb-4  d-md-none">
      {  info.length > 0 ? 
      <div>
       <h2 className="h4 tit-section">Detalle</h2>
     <Accordion className="accordion accordion-flush accordion-light accordion-table"  defaultActiveKey="0">
    { info.map((employee, i) => {
        return ( 
     <Accordion.Item key={i} eventKey={employee.applicationId}>
     <Accordion.Header className="accordion-header title-emp" id="accordion">
     <strong>{employee.employeeName}</strong></Accordion.Header>
     <Accordion.Body className="accordion-body p-0">
     <div className="data-list-box data-list-box-even">
                      <ul className="data-list-section--item list-unstyled">
                       <li>
                       <input className="form-check-input me-2 h5" type="checkbox" id={employee.applicationId} onChange={(e) => onChangeInput(e)} value={JSON.stringify({
                 applicationId: employee.applicationId,
                 applicationState: employee.state,
                 applicationComments: employee.applicantComments
               })} disabled={!isPendiente(employee)} checked={ausenciasId.includes(employee.applicationId)}></input>
                       </li>
                          <li>
                              <span className="data-list-box__title">Fecha Inicio</span>
                              <span className="data-list-box__text">{moment(employee.beginDate).format("DD-MM-YYYY")}</span>
                          </li>
                          <li>
                              <span className="data-list-box__title">Fecha Final</span>
                              <span className="data-list-box__text">{moment(employee.returnDate).format("DD-MM-YYYY")}</span>
                          </li>
                          <li>
                              <span className="data-list-box__title">Día/s</span>
                              <span className="data-list-box__text">{employee.durationDays}</span>
                          </li>
                          <li>
                              <span className="data-list-box__title">Comentarios</span>
                              <span className="data-list-box__text text-truncate">{employee.applicantComments}</span>
                          </li>
                          <li>
                              <span className="data-list-box__title">Estado</span>
                              <span  className="data-list-box__text text-uppercase fw-bold">
                                  <span className={`boton-estado ${employee.state === 'A' ? "Aprobada" : employee.state === 'P' ? "Pendiente" : "Rechazada"}`}>{employee.state === 'A' ? "Aprobada" : employee.state === 'P' ? "Pendiente" : "Rechazada"}</span>
                              </span>
                          </li>
                          <li>
                          <div className="dropdown d-flex">
                 <div>
                   <button className="btn-dropdown" onClick={e => dropdown(e, i)}><BsThreeDotsVertical /></button>
                   {state && i === idDropdown ? (
                     <ul className="dropdown-item">
                       <li><Link to='detalle-aprobacion' state={employee}>Detalles</Link></li>
                     </ul>
                   ) : null}
                 </div>
               </div>
                          </li>
                       </ul>
                  </div>
     </Accordion.Body>
   </Accordion.Item>
        )
    })}
 </Accordion></div> : null }
     {/*responsive-table */}
 </div>
 </>
  )
}

export default TablaVacaciones
