import { Auth } from "aws-amplify";
import axios from "axios";

const url = process.env.REACT_APP_BACKEND_URL_GLOBAL;
const baseurl = process.env.REACT_APP_BACKEND_URL_GLOBAL;
// const baseurl = "http://localhost:5000";

const DatosPersonalesServices = {
  MockPersonalData: async (setInfo) => {
    setInfo({
      EMPLID: "00204594",
      MAR_STATUS: "S",
      SEX: "F",
      BLOOD_TYPE: "A+",
      LANG_CD: "ESP",
      CN_NACIONALIDAD: "CO",
      BIRTHCOUNTRY: "COL",
      BIRTHSTATE: "15",
      COUNTY_CD: "",
      BIRTHDATE: "1991-06-20",
      HIGHEST_EDUC_LVL: "T",
      ATTACH_DOC_NAME: "",
      ATTACH_DOC_DATA: "",
      ATTACH_CERT_NAME: "",
      ATTACH_CERT_DATA: "",
    });
  },
  MockPostPersonalData: (data) => {
    console.log(data, "data");
  },
  GetInformacionContacto: (setInfoContacto, setLoadInfo) => {
    try {
      setLoadInfo(true);
      Auth.currentSession()
        .then(async (auth) => {
          const head = {
            "Content-Type": "application/json",
            accesstoken: auth.getAccessToken().getJwtToken(),
          };
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/datospersonales/contact`,
            { headers: head }
          );
          setInfoContacto(data.CN_COL_DP_CONT_PUT);
        })
        .catch(function (error) {
          setInfoContacto(false);
        })
        .finally(() => {
          setLoadInfo(false);
        });
    } catch (error) {
      console.error(error);
    }
  },
  MockInformacionMobilidad: (setInfoMobilidad) => {
    const data = {
      EMPLID: "16831681C",
      CN_NACIONAL: "Y",
      CN_INTERNACIONAL: "N",
    };

    setInfoMobilidad(data);
  },
  getInformacionMobilidad: (setInfoMobilidad, setCreate, setLoadInfo) => {
    try {
      Auth.currentSession()
        .then(async (auth) => {
          const head = {
            "Content-Type": "application/json",
            accesstoken: auth.getAccessToken().getJwtToken(),
          };
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/datospersonales/mobility`,
            { headers: head }
          );
          setInfoMobilidad({
            EMPLID: data.CN_COL_MOVILIDAD.EMPLID,
            CN_INTERNACIONAL: data.CN_COL_MOVILIDAD.CN_INTERNACIONAL,
            CN_NACIONAL: data.CN_COL_MOVILIDAD.CN_NACIONAL,
          });

          setCreate(false);
        })
        .catch(function (error) {
          console.log(error);
          setCreate(true);
        })
        .finally(() => {
          setLoadInfo(false);
        });
    } catch (error) {
      console.error(error);
    }
  },
  getPersonalData: async (setInfo, setLoadingInfo, setNoData) => {
    try {
      Auth.currentSession()
        .then(async (auth) => {
          const head = {
            "Content-Type": "application/json",
            accesstoken: auth.getAccessToken().getJwtToken(),
          };
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/datospersonales`,
            { headers: head }
          );
          setInfo(data.CN_COL_DATPERS_GET);
        })
        .catch(function (error) {
          console.log(error);
          setLoadingInfo(false);
          setNoData(true);
        })
        .finally(() => {
          setLoadingInfo(false);
        });
    } catch (error) {
      console.error(error);
    }
  },
  editPersonalData: async (body, setError, setOk) => {
    try {
      Auth.currentSession().then(async (auth) => {
        const head = {
          "Content-Type": "application/json",
          accesstoken: auth.getAccessToken().getJwtToken(),
        };
        await axios
          .put(url + "/api/datospersonales", body, {
            headers: head,
          })
          .then((response) => {
            setOk(true)
          })
          .catch((error) => {
            setError(error.response.data.message)
          });
      });
    } catch (error) {
      console.error(error);
    }
  },
  editMobility: async (
    body,
    setEditMobility,
    setLoadingEditMobility,
    setNoEditMobilityData,
    setErrorEditMobility,
  ) => {
    try {
      setLoadingEditMobility(true);
      Auth.currentSession()
        .then(async (auth) => {
          const head = {
            "Content-Type": "application/json",
            accesstoken: auth.getAccessToken().getJwtToken(),
          };
          await axios.put(
            `${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/datospersonales/mobility`,
            { CN_COL_MOVILIDAD: body },
            { headers: head }
          );
          setEditMobility(true)
        })
        .catch(function (error) {
          console.log(error);
          setErrorEditMobility(error.respose.data)
          setEditMobility(false)
          setNoEditMobilityData(true)
        })
        .finally(() => {
          setLoadingEditMobility(false);
        });
    } catch (error) {
      console.error(error);
    }
  },
  createMobility: async (
    body,
    setEditMobility,
    setLoadingEditMobility,
    setNoEditMobilityData,
    setErrorEditMobility,
  ) => {
    try {
      setLoadingEditMobility(true);
      Auth.currentSession()
        .then(async (auth) => {
          const head = {
            "Content-Type": "application/json",
            accesstoken: auth.getAccessToken().getJwtToken(),
          };
          await axios.post(
            `${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/datospersonales/mobility`,
            { CN_COL_MOVILIDAD: body },
            { headers: head }
          );
          setEditMobility(true)
        })
        .catch(function (error) {
          console.log(error);
          setErrorEditMobility(error.respose.data)
          setEditMobility(false)
          setNoEditMobilityData(true)
        })
        .finally(() => {
          setLoadingEditMobility(false);
        });
    } catch (error) {
      console.error(error);
    }
  },
  getNacionality: async (setNacionality) => {
    try {
      Auth.currentSession()
        .then(async (auth) => {
          const head = {
            "Content-Type": "application/json",
            accesstoken: auth.getAccessToken().getJwtToken(),
          };
          const { data } = await axios.get(
            url + "/api/datospersonales/getNacionalidad",
            { headers: head }
          );
          setNacionality(data.CN_COL_XLAT.PSXLATITEM);
          // setLoadingNacionality(false);
        })
        .catch(function (error) {
          console.log(error);
          // setLoadingNacionality(false);
          // setNoNacionalityData(true);
        });
    } catch (error) {
      console.error(error);
    }
  },
  getMarStatus: async (setMarStatus) => {
    try {
      Auth.currentSession()
        .then(async (auth) => {
          const head = {
            "Content-Type": "application/json",
            accesstoken: auth.getAccessToken().getJwtToken(),
          };
          const { data } = await axios.get(
            url + "/api/datospersonales/getMARStatus",
            { headers: head }
          );
          setMarStatus(data.CN_COL_XLAT.PSXLATITEM);
          // setLoadingMarStatus(false);
        })
        .catch(function (error) {
          console.log(error);
          // setLoadingMarStatus(false);
          // setNoMarStatusData(true);
        });
    } catch (error) {
      console.error(error);
    }
  },
  getGenre: async (setGenre) => {
    try {
      Auth.currentSession()
        .then(async (auth) => {
          const head = {
            "Content-Type": "application/json",
            accesstoken: auth.getAccessToken().getJwtToken(),
          };
          const { data } = await axios.get(
            url + "/api/datospersonales/getSexo",
            { headers: head }
          );
          setGenre(data.CN_COL_XLAT.PSXLATITEM);
          // setLoadingGenre(false);
        })
        .catch(function (error) {
          console.log(error);
          // setLoadingGenre(false);
          // setNoGenreData(true);
        });
    } catch (error) {
      console.error(error);
    }
  },
  getBloodType: async (setBloodType) => {
    try {
      Auth.currentSession()
        .then(async (auth) => {
          const head = {
            "Content-Type": "application/json",
            accesstoken: auth.getAccessToken().getJwtToken(),
          };
          const { data } = await axios.get(
            url + "/api/datospersonales/getBloodType",
            { headers: head }
          );
          setBloodType(data.CN_COL_XLAT.PSXLATITEM);
          // setLoadingBloodType(false);
        })
        .catch(function (error) {
          console.log(error);
          // setLoadingBloodType(false);
          // setNoBloodTypeData(true);
        });
    } catch (error) {
      console.error(error);
    }
  },
  getIdioma: async (setIdioma) => {
    try {
      Auth.currentSession()
        .then(async (auth) => {
          const head = {
            "Content-Type": "application/json",
            accesstoken: auth.getAccessToken().getJwtToken(),
          };
          const { data } = await axios.get(
            url + "/api/datospersonales/getIdioma",
            { headers: head }
          );
          setIdioma(data.CN_COL_XLAT.PSXLATITEM);
          // setLoadingIdioma(false);
        })
        .catch(function (error) {
          console.log(error);
          // setLoadingIdioma(false);
          // setNoIdiomaData(true);
        });
    } catch (error) {
      console.error(error);
    }
  },
  getEducation: async (setEducation) => {
    try {
      Auth.currentSession()
        .then(async (auth) => {
          const head = {
            "Content-Type": "application/json",
            accesstoken: auth.getAccessToken().getJwtToken(),
          };
          const { data } = await axios.get(
            url + "/api/datospersonales/getEducacion",
            { headers: head }
          );
          setEducation(data.CN_COL_XLAT.PSXLATITEM);
          // setLoadingEducation(false);
        })
        .catch(function (error) {
          console.log(error);
          // setLoadingEducation(false);
          // setNoEducationData(true);
        });
    } catch (error) {
      console.error(error);
    }
  },
  GetCountries: async (setCountries) => {
    try {
      Auth.currentSession()
        .then(async (auth) => {
          const head = {
            "Content-Type": "application/json",
            accesstoken: auth.getAccessToken().getJwtToken(),
          };
          const { data } = await axios.get(
            baseurl + "/api/location/countries",
            { headers: head }
          );

          setCountries(data);
          // setLoadingCountries(false);
        })
        .catch(function (error) {
          console.log(error);
          // setLoadingCountries(false);
          // setNoDataCountries(true);
        });
    } catch (error) {
      console.error(error);
    }
  },
  GetStates: async (country, setStates, setLoadingStates) => {
    try {
      Auth.currentSession()
        .then(async (auth) => {
          const head = {
            "Content-Type": "application/json",
            accesstoken: auth.getAccessToken().getJwtToken(),
          };
          const { data } = await axios.get(
            baseurl + `/api/location/countries/${country}/states`,
            { headers: head }
          );
          setStates(data);
          setLoadingStates(false);
        })
        .catch(function (error) {
          console.log(error);
          setLoadingStates(false);
          // setNoDataStates(true);
        });
    } catch (error) {
      console.error(error);
    }
  },
  GetCounties: async (country, state, setCounties, setLoadingCounties) => {
    try {
      Auth.currentSession()
        .then(async (auth) => {
          const head = {
            "Content-Type": "application/json",
            accesstoken: auth.getAccessToken().getJwtToken(),
          };
          const { data } = await axios.get(
            baseurl +
            `/api/location/countries/${country}/states/${state}/counties`,
            { headers: head }
          );
          setCounties(data);
          setLoadingCounties(false);
        })
        .catch(function (error) {
          console.log(error);
          setLoadingCounties(false);
          // setNoDataCounties(true);
        });
    } catch (error) {
      console.error(error);
    }
  },
  GetLocation: async (country, state, county, setLocation) => {
    try {
      Auth.currentSession()
        .then(async (auth) => {
          const head = {
            "Content-Type": "application/json",
            accesstoken: auth.getAccessToken().getJwtToken(),
          };
          const { data } = await axios.post(
            baseurl + `/api/location/locations`,
            { country: country, state: state, county: county },
            { headers: head }
          );
          setLocation(data);
        })
        .catch(function (error) {
          console.log(error);
          // setNoDataCounties(true);
        });
    } catch (error) {
      console.error(error);
    }
  },
  getAvatar: async (setAvatar, setLoadingAvatar, setNoAvatar, setLoadInfo) => {
    try {
      Auth.currentSession()
        .then(async (auth) => {
          const head = {
            "Content-Type": "application/json",
            accesstoken: auth.getAccessToken().getJwtToken(),
          };
          const { data } = await axios.get(baseurl + "/api/emp/avatar", {
            headers: head,
          });
          setAvatar(data.avatar);
          setLoadingAvatar(false);
        })
        .catch(function (error) {
          console.log(error);
          setLoadingAvatar(false);
          setNoAvatar(true);
        })
        .finally(() => {
          setLoadInfo(false);
        });
    } catch (error) {
      console.error(error);
    }
  },
  getEmployee: async (
    setEmployeeInfo,
    setLoadingEmployeeInfo,
    setNoEmployeeData
  ) => {
    try {
      Auth.currentSession()
        .then(async (auth) => {
          const head = {
            "Content-Type": "application/json",
            accesstoken: auth.getAccessToken().getJwtToken(),
          };
          const { data } = await axios.get(baseurl + "/api/datospersonales", {
            headers: head,
          });
          setEmployeeInfo(data);
          setLoadingEmployeeInfo(false);
        })
        .catch(function (error) {
          console.log(error);
          setLoadingEmployeeInfo(false);
          setNoEmployeeData(true);
        });
    } catch (error) {
      console.error(error);
    }
  },
  getDetalleCargo: async (
    setEmployeeInfo,
    setLoadingEmployeeInfo,
    setNoEmployeeData
  ) => {
    try {
      Auth.currentSession()
        .then(async (auth) => {
          const head = {
            "Content-Type": "application/json",
            accesstoken: auth.getAccessToken().getJwtToken(),
          };
          const { data } = await axios.get(baseurl + "/api/emp", {
            headers: head,
          });
          setEmployeeInfo(data);
          setLoadingEmployeeInfo(false);
        })
        .catch(function (error) {
          console.log(error);
          setLoadingEmployeeInfo(false);
          setNoEmployeeData(true);
        });
    } catch (error) {
      console.error(error);
    }
  },
  updateInformationContact: async (info, setError, setOk) => {
    try {
      Auth.currentSession()
        .then(async (auth) => {
          const head = {
            "Content-Type": "application/json",
            accesstoken: auth.getAccessToken().getJwtToken(),
          };
          const { data } = await axios.put(
            url + "/api/datospersonales/contact",
            { CN_COL_DP_CONT_PUT: info },
            { headers: head }
          );
        })
        .then((response) => {
          setOk(true)
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      setError(error.response.data.message)
      console.error(error);
    }
  },
};

export default DatosPersonalesServices;
