import { useAxiosFetch } from "../../../hooks";
import type {
  Flag,
  MyTeamsResponse,
  TeamApi,
  UseMyTeamsFetchProps,
  UseFetchTableDataReturnType,
} from "./hooksTeamDashboard.types";
import defaultProfilImage from "../../../resources/images/default-colaborador-image.png";
import { countryDictionary } from "../../../utils";
import { useTeamDashboardProvider } from "../context/TeamDashboardContext";
import { useEffect } from "react";
import { managmentPayRoll } from "../context/carouselList.data";

export const useMyTeamsFetch = (
  props: UseMyTeamsFetchProps
): UseFetchTableDataReturnType<MyTeamsResponse> => {
  const { idSubLeader, selectedYear } = props;
  const { getSelectedMenu } = useTeamDashboardProvider();

  const [getMyTeams, dataMyTeams] = useAxiosFetch<TeamApi[]>({
    method: "GET",
    url: "/api/my-teams",
    params: {
      year: selectedYear,
    },
  });

  const [getSubTeams, dataSubTeam] = useAxiosFetch<TeamApi[]>({
    method: "GET",
    url: "api/my-teams/subteam",
    params: {
      subleader: idSubLeader,
      year: selectedYear,
    },
  });

  useEffect(() => {
    if (!getSelectedMenu) {
      getMyTeams();
    }
  }, []);

  useEffect(() => {
    if (idSubLeader) getSubTeams();
  }, [idSubLeader]);

  const teamsMapper = (
    response: typeof dataMyTeams | typeof dataSubTeam
  ): MyTeamsResponse => {
    const { data } = response;
    return (
      data?.map((item) => ({
        name: item.name || "-",
        last_name: item.last_name || "-",
        nro_document: item.nro_document || "-",
        position: item.position || "-",
        country: countryDictionary(item.country) || "-",
        photo: item.photo || defaultProfilImage,
        tr: item.tr || "-",
        assessment: item.assessment || "-",
        cl: item.cl || "-",
        cr: item.cr || "-",
        salary: item.salary || "-",
        lastDate: item.lastDate || "-",
        availableDays: item.availableDays || "-",
        hasSubordinates: item.hasSubordinates || false,
        employee_record_id: item.employee_record_id || "-"

      })) || []
    );
  };

  const removeDuplicateFlag = (flags: Flag[]) => {
    return flags.reduce((acc, flag) => {
      if (!acc.find((a) => a.id === flag.id)) {
        acc.push(flag);
      }

      return acc;
    }, [] as Flag[]);
  };

  const getAllFlags = () => {
    const mapFlag = (team: TeamApi) => ({
      id: countryDictionary(team.country),
      name: team.country,
    });

    if (idSubLeader) {
      return dataSubTeam.data?.map(mapFlag);
    }

    return dataMyTeams.data?.map(mapFlag);
  };

  return {
    key: managmentPayRoll.name,
    response: {
      dataTable: teamsMapper(dataMyTeams),
      subDatable: teamsMapper(dataSubTeam),
      isLoading: dataMyTeams.isLoading || dataSubTeam.isLoading,
      isError: dataMyTeams.isError || dataSubTeam.isError,
      getAllFlags: removeDuplicateFlag(getAllFlags() || []),
    },
  };
};
