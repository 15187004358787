import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Modal } from "react-bootstrap";
import { FaRegWindowClose } from 'react-icons/fa';
import moment from 'moment';
import CambioAsistenciaResumen from '../cambio-asistencia-resumen';
import Swal from 'sweetalert2';
import AsistenciaService from '../../../services/asistencia/asistencia-service';



const ApprovalModal = (props) => {
    ApprovalModal.propTypes = {
        active: PropTypes.any,
        setActive: PropTypes.any,
        data: PropTypes.any,
        setData: PropTypes.any,
        setReload: PropTypes.any
    }

    const active = props.active
    const openner = props.setActive
    const data = props.data
    const setData = props.setData
    const setReload = props.setReload
    const [solicitudCambio, setSolicitudCambio] = useState(null);
    const [rejectLoading, setRejectLoading] = useState(false);
    const [approvalLoading, setApprovalLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [reviewError, setReviewError] = useState(false);

    useEffect(() => {
        const request = {
            id: data.id,
            type: data.type,
            status: data.status,
            previous: {
                date: data.workingDate,
                startTime: data.previousStartTime,
                endTime: data.previousEndTime
            },
            changed: {
                date: data.type == 'Franco' ? data.dayOffDate : null,
                startTime: data.newStartTime,
                endTime: data.newEndTime
            }
        };
        let momentLocale = moment(request.previous.date);
        momentLocale.locale("es");

        request.previous.dateDescription = momentLocale.format('dddd DD [de] MMMM');
        request.previous.detail = request.previous.dateDescription;

        if (request.type === 'Turno') {
            request.previous.detail += ` de ${request.previous.startTime} a ${request.previous.endTime}`;
            request.previous.timeDescription = `${request.previous.startTime} - ${request.previous.endTime}`;
        }

        momentLocale = moment(request.changed.date);
        request.changed.dateDescription = momentLocale.format('dddd DD [de] MMMM');
        request.changed.timeDescription = `${request.changed.startTime} - ${request.changed.endTime}`;


        setSolicitudCambio(request);
    }, [data])

    const reviewShiftChange = (shiftChange, status) => {
        const shiftChangeApproval = {
            shiftChangeId: shiftChange.id,
            status: status
        };
        const loadingFunction = status === 'A' ? setApprovalLoading : setRejectLoading;
        loadingFunction(true);
        AsistenciaService.reviewShiftChangeRequest(shiftChangeApproval, loadingFunction, setSuccess, setReviewError);
    }
    const handleSuccess = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: green; font-size: 16px">Exitoso</div>',
            html: `<div style="text-align: center; font-size: 15px">${success}</div>`,
            confirmButtonColor: '#0169b5'
        }).then(result => {
            if (result.isConfirmed) {
                setSuccess(false);
                setReload(true);
                openner(false)
                setSolicitudCambio(null);

            }
        })
    }

    const handleError = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px">Error</div>',
            html: `<div style="text-align: center; font-size: 15px">${reviewError || 'Ha ocurrido un error, intentelo nuevamente'}</div>`,
            confirmButtonColor: '#0169b5',
        }).then((result) => {
            if (result.isConfirmed) {
                setReviewError(false);
                setReload(true)
                setSolicitudCambio(null);
            }
        })
    }


    return (
        <React.Fragment>
            {
                solicitudCambio &&
                <Modal show={active} data={solicitudCambio} centered dialogClassName="modal-width">
                    <Modal.Body>
                        <div className="d-flex justify-content-between">
                            <h2 className="title-modal m-3 text-center">Detalle</h2>
                            <div className='m-2' onClick={() => { openner(!active); setData(null) }}><FaRegWindowClose className='modal-close-btn'></FaRegWindowClose></div>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <CambioAsistenciaResumen tipo={solicitudCambio.type} cambioTurno={solicitudCambio}></CambioAsistenciaResumen>
                        </div>
                        <div className='d-flex align-items-center justify-content-center mt-2 mb-2'>
                            <button disabled={solicitudCambio?.status !== 'P'} onClick={() => reviewShiftChange(solicitudCambio, 'R')} className="button reject">Rechazar {rejectLoading ? <div className="spinner-border spinner-border-sm"></div> : <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>}</button>
                            <button disabled={solicitudCambio?.status !== 'P'} onClick={() => reviewShiftChange(solicitudCambio, 'A')} className="button save">Aprobar {approvalLoading ? <div className="spinner-border spinner-border-sm"></div> : <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>}</button>
                        </div>
                    </Modal.Body>
                </Modal>
            }
            {success ? handleSuccess() : null}
            {reviewError ? handleError() : null}
        </React.Fragment>
    )
}

export default ApprovalModal