import axios from 'axios';
import Auth from '@aws-amplify/auth';

const baseUrl = process.env.REACT_APP_BACKEND_URL_GLOBAL;

const FirstLoginService = {
    getTerminosYCondiciones: async () => {
        const auth = await Auth.currentSession();
        const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() };
        const response = await axios.get(baseUrl + "/api/common/termsAndConditions", { headers: head });
        return response.data;
    },
    register: async (email) => {
        const url = baseUrl + '/api/activacion/usuario/register';
        const auth = await Auth.currentSession();
        const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() };
        const body = JSON.stringify({ email });
        await axios.post(url, body, { headers: head });
    },
    resendMail: async (email) => {
        const url = baseUrl + '/api/activacion/usuario/resendMail';
        const auth = await Auth.currentSession();
        const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() };
        const body = JSON.stringify({ email });
        await axios.post(url, body, { headers: head });
    },
    activate: async (token) => {
        const url = baseUrl + '/api/activacion/usuario/activate';
        const body = JSON.stringify({ token });
        const head = { 'Content-Type': 'application/json' }
        await axios.post(url, body, { headers: head });
    },
    validateLogin: async () => {
        const url = baseUrl + '/api/activacion/usuario/validatelogin';
        const auth = await Auth.currentSession();
        const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() };
        const response = await axios.post(url, {}, { headers: head })
        return response.data;
    },
};

export default FirstLoginService;