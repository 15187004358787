/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useState, useRef } from 'react'
import { BiSolidBell } from 'react-icons/bi';
import { BiRightArrowAlt, BiLeftArrowAlt, BiSolidChevronRight } from 'react-icons/bi';
import { FaTrash } from 'react-icons/fa';
import NotificationsApi from '../services/notifications/notifications.service';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useNavigate } from 'react-router';
import { setNotificationIcon } from './icon-mapper';

//actionables types ("1": button left -not implemented, "2": redirection, "3": button right -not implemented, "4": approval, "5": rejection)
const Notifications = () => {
  const [userMessageCount, setUserMessageCount] = useState(0);
  const [isAlertBoxOpen, setIsAlertBoxOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isBellRinging, setIsBellRinging] = useState(null);
  const [isIncoming, setIsIncoming] = useState(false);
  const [flips, setFlips] = useState({});
  const [deletedNotifications, setDeletedNotifications] = useState({});
  const notificationRefs = useRef([]);
  const [notificationLoading, setNotificationLoading] = useState({});
  const [read, setRead] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false); //use to set the state of the "more notifications" loader
  const [currentPage, setCurrentPage] = useState(1); //use to set the notifications collection limit
  const offset = 20;
  const navigation = useNavigate();
  const [iconComponents, setIconComponents] = useState({});

  const setMessages = async () => {
    try {
      await NotificationsApi.getNotifications(setLoading, setNotifications, setUserMessageCount, currentPage, offset);

    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };
  // function formatTimestamp(timestamp) {
  //   const date = new Date(timestamp);
  //   const day = date.getDate().toString().padStart(2, '0');
  //   const month = (date.getMonth() + 1).toString().padStart(2, '0');
  //   const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
  //   const minutes = date.getMinutes().toString().padStart(2, '0');
  //   const amPm = date.getHours() < 12 ? 'am' : 'pm';

  //   return `${day}-${month} ${hours}:${minutes} ${amPm}`;
  // }

  const handleDelete = async (notificationIdToDelete) => {
    try {
      // Set the loading state for the specific notification to true
      setNotificationLoading((prevLoading) => ({
        ...prevLoading,
        [notificationIdToDelete]: true,
      }));

      const res = await NotificationsApi.addDeletedNotification(notificationIdToDelete);

      // Update your state accordingly
      setDeletedNotifications((prevDeleted) => ({
        ...prevDeleted,
        [notificationIdToDelete]: !prevDeleted[notificationIdToDelete],
      }));

      const updatedNotifications = notifications.filter(
        (element) => element.id !== notificationIdToDelete
      );
      setNotifications(updatedNotifications);

      // Set the loading state for the specific notification to false
      setNotificationLoading((prevLoading) => ({
        ...prevLoading,
        [notificationIdToDelete]: false,
      }));
    } catch (error) {
      // Handle errors and update the loading state as needed
      setNotificationLoading((prevLoading) => ({
        ...prevLoading,
        [notificationIdToDelete]: false,
      }));

      console.error('Error deleting notification:', error);
    }
  };

  const handleAction = (notification, type) => {
    const actionableAction = notification.actionables.find(actionable => actionable.type === type);

    if (actionableAction) {
      if (actionableAction.isInternal === 1) {
        navigation(actionableAction.link);
      } else {
        window.open(actionableAction.link, "_blank");
      }

      handleDelete(notification.id);
    }
  };

  const handleLoadMore = async () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage); // Update the page number
    await NotificationsApi.getMoreNotifications(setLoadingMore, setNotifications, nextPage, offset);
  };
  const getActionable = (actionables, type) => {
    return actionables.find(x => x.type === type);
  }

  useEffect(() => {
    setMessages();
  }, [])

  useEffect(() => {
    if (userMessageCount > 0) {
      setIsBellRinging(true);
      setIsIncoming(true);
    }
  }, [userMessageCount]);

  useEffect(() => {
    if (isAlertBoxOpen) {
      setRead(true)
      setIsBellRinging(false);
      setUserMessageCount(0);
      markNotificationsAsViewed();
    }
  }, [isAlertBoxOpen])

  const handleToggleFlip = (notificationId) => {
    // Toggle the flip state for the clicked notification
    setFlips((prevFlips) => ({
      ...prevFlips,
      [notificationId]: !prevFlips[notificationId],
    }));
  };

  const handleNotificationChange = (newNotifications) => {
    // Ensure that the array of refs matches the number of notifications
    notificationRefs.current = newNotifications.map(() => notificationRefs.current || useRef(null));
  };
  const markNotificationsAsViewed = async () => {
    try {
      await NotificationsApi.addViewedNotifications(true);
    } catch (error) {
      console.error('Error marking notifications as viewed:', error);
    } finally {
      setRead(false);
    }
  };

  useEffect(() => {
    if (notifications)
      handleNotificationChange(notifications);
  }, [notifications]);

  useEffect(() => {
    let animationTimeout;

    if (isBellRinging) {
      animationTimeout = setTimeout(() => {
        setIsBellRinging(false);
      }, 60000);
    }

    return () => {
      if (animationTimeout) {
        clearTimeout(animationTimeout);
      }
    };
  }, [isBellRinging]);

  return (
    <div>
      {/* <!-- Notificaciones--> */}
      {loading ? <div className='d-flex spinner-border spinner-border-sm text-white'></div>
        : <div className="btn btn-notification position-relative me-3 me-lg-4" role="button"
          onClick={() => {
            setIsAlertBoxOpen(!isAlertBoxOpen);
          }}>
          <BiSolidBell className={`bell-icon ${isBellRinging ? 'ring' : ''}`}></BiSolidBell>
          {isIncoming && userMessageCount > 0 ? <div className='red-counter incoming'>
            {userMessageCount}
          </div> : null}
        </div>}
      {isAlertBoxOpen ?
        <div className={`notification${isAlertBoxOpen ? ' show' : ''}`} id="notification">
          <span className="notification-title h5">Notificaciones</span>
          <TransitionGroup className="notification-group">
            {notifications.length === 0 ?
              <div className='d-flex justify-content-center p-2'>Sin Novedades</div> :
              <div>
                {notifications.map((element) => {
                  const actionables = element?.actionables;
                  //imported function to set notification icons 
                  const IconComponent = setNotificationIcon(element?.icon);
                  //card flipper index 
                  const isFlipped = flips[element?.id]
                  //deleted index
                  const isDeleted = deletedNotifications[element?.id];
                  //actionables mapper
                  const leftActionable = getActionable(actionables, '1');
                  const redirectActionable = getActionable(actionables, '2');
                  const rightActionable = getActionable(actionables, '3');
                  //detrás tarjeta izquierda
                  const backLeftButtonActionable = getActionable(actionables, '4');
                  //detrás tarjeta derecha
                  const backRightButtonActionable = getActionable(actionables, '5');



                  return (
                    <CSSTransition
                      in={isDeleted}
                      key={element.id}
                      nodeRef={notificationRefs.current[element?.id]}
                      timeout={400}
                      classNames="item"
                    >
                      <div ref={notificationRefs.current[element?.id]} key={element?.id} className={`flippable-card-container ${element?.isNew ? 'enhanced' : ''}`}>
                        <CSSTransition nodeRef={notificationRefs.current[element?.id]} in={isFlipped} timeout={400} classNames='flip'>
                          <div className='not-card' ref={notificationRefs.current[element?.id]}>
                            <div className='not-front col-12 w-100'>
                              <div className='notification-type-icon col-1'> {IconComponent}</div>
                              <div className='text-decoration-none col-9'>
                                <div className='elements-container'>
                                  <ul className="list-unstyled m-0">
                                    <li className='listed-element'><strong>{element?.title}</strong></li>
                                    <li className='listed-element'>{element?.content}</li>
                                    {/* <li className='listed-element'>{formatTimestamp(element?.createdAt)}</li> */}
                                  </ul>
                                  <div className='row mt-1'>
                                    <div className='col-4 text-center'>
                                      {leftActionable && (
                                        <div onClick={() => setIsAlertBoxOpen(false)} className='redirect-btn'>
                                          {leftActionable.label}
                                        </div>
                                      )}
                                    </div>
                                    <div className='col-4 text-center'>
                                      {redirectActionable && (
                                        <div
                                          onClick={() => {
                                            navigation(redirectActionable.link);
                                            setIsAlertBoxOpen(false);
                                          }}
                                          className='redirect-btn'
                                        >
                                          {redirectActionable.label}
                                        </div>
                                      )}
                                    </div>
                                    <div className='col-4 text-center'>
                                      {rightActionable && (
                                        <div onClick={() => setIsAlertBoxOpen(false)} className='redirect-btn'>
                                          {rightActionable.label}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='notification-action-items col-2'>
                                {!notificationLoading[element?.id] ?
                                  <div className='icon-wrapper col' onClick={() => handleDelete(element?.id)}><FaTrash className='color'></FaTrash></div>
                                  : <div className='spinner-border spinner-border-sm color-primary'></div>}
                                {backLeftButtonActionable || backRightButtonActionable ? (
                                  <div className="icon-wrapper col" onClick={() => handleToggleFlip(element?.id)}>
                                    <BiRightArrowAlt className='color fs-5'></BiRightArrowAlt>
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className='not-back col-12 w-100'>
                              <div className='text-decoration-none col-11'>
                                <div className='text-center'><strong>Que acción desea realizar?</strong></div>
                                <div className='btn-aprooval-container'>
                                  <button className='action-button reject-btn-main' onClick={() => handleAction(element, '4')}>{backLeftButtonActionable?.label} <BiSolidChevronRight /></button>
                                  <button className='action-button approve-btn-main' onClick={() => handleAction(element, '5')}>{backRightButtonActionable?.label} <BiSolidChevronRight /></button>
                                </div>
                              </div>
                              <div className='notification-action-items col-1'>
                                <div className='icon-wrapper' onClick={() => handleToggleFlip(element?.id)}><BiLeftArrowAlt className='color fs-5'></BiLeftArrowAlt></div>
                              </div>
                            </div>
                          </div>
                        </CSSTransition>

                      </div>
                    </CSSTransition>
                  )
                })} </div>}
            <div className="d-block text-center text-decoration-none p-2" onClick={() => handleLoadMore()}>
              {
                loadingMore ? <div className='spinner-border spinner-border-sm color-primary'></div> :
                  (
                    <div className='btn-loader-container'>
                      <div className='load-more-btn'>
                        Cargar Más <i className='fa fa-refresh update-icon'> </i>
                      </div>
                    </div>)
              }
            </div>

          </TransitionGroup>
        </div> : null
      }

    </div >
  )
}

export default Notifications