import React, { useContext } from "react";
import { httpClientEnv } from "../utilities/apiServices/httpClient";
import VoucherPage from "./voucher";
 
export const HttpClientContext = React.createContext(httpClientEnv);
export const useHttpClient = () => useContext(HttpClientContext);
  
export const PersonalVoucher = () => {
    return (
        <HttpClientContext.Provider value={httpClientEnv}>
            <VoucherPage />
        </HttpClientContext.Provider>
    )
};
