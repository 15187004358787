import { BannerComponentProps } from "../Helpers/interfaces/banner";
import { CarouselIconComponentProps } from "../Helpers/interfaces/carouselIcon";
import { SliderComponentProps } from "../Helpers/interfaces/slider";
import { CarouselComponentProps } from "../Helpers/interfaces/carousel";
import { TableComponentProps } from "../Helpers/interfaces/table";
import { TaskComponentProps } from "../Helpers/interfaces/task";
import { WidgetFactoryProps } from "./interfaces";
import { ReactNode } from "react";
import useResponsiveBreakpoint from "../hooks/useResponsiveBreakpoint";

const useWidgetProps = ({ type, id, data, width } :WidgetFactoryProps) => {
    const breakpoint = useResponsiveBreakpoint();
    let extraProps : BannerComponentProps | CarouselIconComponentProps | SliderComponentProps | CarouselComponentProps | TableComponentProps | TaskComponentProps = {} as BannerComponentProps | CarouselIconComponentProps | SliderComponentProps | CarouselComponentProps | TableComponentProps | TaskComponentProps;

    if(type === 'banner'){
        const size : 'small' | 'medium' | 'large' = (width === '1/3' && 'small') || (width === '2/3' && 'medium') || 'large';
        extraProps = { id, size, data } as BannerComponentProps;
    }
 
    if(type === 'carousel'){
        const itemsPerSlide = width === '3/3' ? 3 : width === '2/3' ? 2 : 1;
        extraProps =  { id, itemsPerSlide, data } as CarouselComponentProps;
    }

    if(type === 'table'){
        const view : 'table' | 'table-responsive' = width === '1/3' && 'table-responsive' || 'table'; 
        extraProps = { id, view, data } as TableComponentProps;
    }
 
    if(type === 'carousel-icon'){
        let chunkSize = (breakpoint === 'xs' || breakpoint === 'sm') ? 2 : 1;
        switch (width) {
            case '3/3': {
                chunkSize = (breakpoint === 'md' || breakpoint === 'lg' || breakpoint === 'xl' || breakpoint === 'xxl') ? 4 : 2;
                break;
            }
            case '2/3': {
                chunkSize = 2;
                break;
            }
            default: {
                chunkSize = (breakpoint === 'xs' || breakpoint === 'sm') ? 2 : 1;
                break;
            }
        }
        extraProps = { id, chunkSize, data } as CarouselIconComponentProps;
    }
 
    if(type === 'slider'){
        extraProps = { id, data } as SliderComponentProps;
    }

    if(type === 'task'){ 
        extraProps = { id, data } as TaskComponentProps;
    }
 
    return extraProps;
};

export default useWidgetProps;
