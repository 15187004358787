import React, { FC } from "react";
import ColaboradorCard from "../../warningLetter/commons/colaboratorCard";
import { PhotoProps } from "../../warningLetter/pages/warning";
import { NameCollaborator } from "../../warningLetter/utilities/interfaces";
interface Props {
    setOptionSelect: React.Dispatch<React.SetStateAction<string>>
    emplidColab: string;
    setDate: React.Dispatch<React.SetStateAction<string>>
    errorMsjDate: string 
    setMsjErrorDate: React.Dispatch<React.SetStateAction<string>>
    photos: PhotoProps[]
    collaborator: NameCollaborator[];
    optionSelect: string,
    date: string
}

const Form: FC<Props> = ({setOptionSelect, collaborator, date, optionSelect, photos, emplidColab, setDate, errorMsjDate, setMsjErrorDate}) => {
    
    const handleDateChange = (event:any) => {
        const dateValue = event.target.value;
        const today = new Date().toISOString().split("T")[0];
        if (dateValue >= today) {
            setMsjErrorDate("");
        } else {
            setMsjErrorDate("La fecha debe ser mayor o igual a la fecha actual");
        }
        setDate(dateValue)
    };

    const handleOptionChange = (event:any) => {
        const dateValue = event.target.value;
        setOptionSelect(dateValue)
    };

    return(
        <>
            <div className="row mb-4">
                <ColaboradorCard collaborator={collaborator} photos={photos} emplidColab={emplidColab}/>
                <div className="col-12 col-xl-8 d-flex">
                    <div className="box align-items-stretch w-100">
                    <h2 className="h4 tit-section">Gestión de SIC</h2>
                    <div className="data-list-box data-list-box-align-middle">
                        <ul className="list-unstyled">
                            <li>
                                <span className="data-list-box__title col-md-4">Tipo de Vacante <span className="color-red">*</span></span>
                                <span className="data-list-box__text col-md-4">
                                    <div className="input-group">
                                        <select
                                            className="form-select"
                                            onChange={handleOptionChange}
                                            value={optionSelect}
                                            disabled
                                        >
                                            {/* <option value="">Seleccione</option> */}
                                            <option value="01" selected>Reemplazo</option>
                                        </select>
                                    </div>
                                </span>
                            </li>
                            <li>
                                <span className="data-list-box__title col-md-4">Fecha Estimada Inicio <span className="color-red">*</span></span>
                                <span className="data-list-box__text col-md-4">
                                    <div className="input-group">
                                        <input
                                            className="form-control"
                                            type="date"
                                            id={"dateFormSic"}
                                            placeholder="dd/mm/aaaa"
                                            value={date}
                                            disabled={emplidColab===''}
                                            onChange={handleDateChange}
                                        />
                                        <i className="fa fa-calendar fa-fw mt-2 ms-2 fa-lg color-orange" aria-hidden="true"></i>
                                    </div>
                                    {errorMsjDate!=='' && <div className="invalid-feedback" style={{display:"block"}}>{errorMsjDate}
                                    </div>}
                                </span>
                            </li>
                        </ul>
                        <small>(*) Campo obligatorio</small>
                    </div>
                    <div hidden className="d-grid d-md-flex justify-content-md-end gap-2 flex-nowrap mt-3">
                        <button hidden type="submit" id='searchSic' className="btn btn-degradado order-md-last">Buscar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Form;