import { Auth } from "@aws-amplify/auth";
import apiInfo from "./apiEndpoints";
import { HttpClient } from "./httpClient";
import { UpdateLocalStorage } from '../helpers/updateStorage'
export class getUserInfo {
  httpClient = new HttpClient()
  localStorageService = new UpdateLocalStorage()

  async getEmployeeID(): Promise<any> {
    const id = await Auth.currentAuthenticatedUser().then((auth) => {
      let employeeId = auth.signInUserSession.idToken.payload["custom:emplid"];
      return employeeId
    })
    return Promise.resolve(id)
  }

  async getAuthInfo(): Promise<any> {
    const authID = await this.getEmployeeID()
    const savedID = localStorage.getItem('emplid')
    const userID = savedID !== null ? savedID : authID
    const jwtId = await Auth.currentSession().then((authResponse) => authResponse.getIdToken().getJwtToken());
    const token = await Auth.currentSession().then((authResponse) => authResponse.getAccessToken().getJwtToken());
    const response = await this.httpClient.postUserInfo(jwtId, apiInfo.urls.userInfo, userID, token)
    if(response.ok) {
      const responseBody = await response.json()
      const userData = responseBody.response
      const userInfo = {
        username: userData.name,
        country: userData.country,
        business_unit: userData.busunit_descr,
        flag: userData.bandera,
        location: userData.location_descr
      }
      this.localStorageService.updateSlot(this.localStorageService.keyConstants[2], JSON.stringify(userInfo))
    } else {
      return
    }
  }
}