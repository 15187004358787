import React, { useEffect } from "react";
import { useCencoTalent } from "../hooks/useCencoTalent";
import { SpinerFullScreen } from "../../commonComponents/SpinerFullScreen";
import ErrorScreen from "../../commonComponents/error-screen/error-screen";
import { useCencoUI } from "../hooks/useCencoUI";
import { useCencoInfo } from "../hooks/useCencoInfo";

export const CencoTalentPage = () => {
    const { startInitiCencoTalent } = useCencoTalent();
    const { getRegionalInfo } = useCencoInfo();
    const { actualPage, isLoading, hasError, pageToDisplay } = useCencoUI();

    const content = pageToDisplay[actualPage]();

    useEffect(() => {
        void getRegionalInfo();
        void startInitiCencoTalent();
    }, []);

    if (isLoading) return <SpinerFullScreen />;
    if (hasError)
        return (
            <ErrorScreen
                title=""
                etype="internalServerError"
                message="Ha ocurrido un error cargando las requisiciones."
            />
        );

    return <>{content}</>;
};
