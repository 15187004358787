import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SolicitudesVacaciones from './solicitudes-vacaciones-uy';
import Solicitud from './solicitud.js'
import DetallesSolicitud from "./detalles-solicitud";

const VacacionesUy = () => {

    useEffect(() => {


    }, [])
    return (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home color-orange" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Vacaciones
                    </li>
                </ol>
            </nav>
            <section className="noticias mb-3">
                <h1 className="mb-0">Vacaciones</h1>
                <div className="col-12 position-relative mb-0">
                    {/* <a className="btn btn-degradado btn-absolute" href="#">Ver más <i className="fa fa-chevron-right" aria-hidden="true"></i></a> */}
                </div>
            </section>
            <div className='container p-0'>
                <Routes>
                    <Route path="" element={<Solicitud />} />
                </Routes>
                <Routes>
                    <Route path="solicitudes-vacaciones-uy" element={<SolicitudesVacaciones />} />
                </Routes>
                <Routes>
                <Route path="solicitudes-vacaciones-uy/detalle-solicitud" element={<DetallesSolicitud/>}></Route>
                </Routes>
            </div>

        </>
    )
}

export default VacacionesUy
