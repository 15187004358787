import React from 'react';
import styles from './organigrama-ss.module.css';
import PersonCard from './PersonCard';

const HierarchyTree = ({ employee, onInfoClick, onSelectPersonClick }: any) => {
    const renderPerson = (person: any) => (
        <PersonCard 
            person={person} 
            onInfoClick={onInfoClick} 
            onSelectPersonClick={onSelectPersonClick} 
        />
    );

    const renderHierarchy = (employee: any) => {
        if (!employee || !employee.BaseLevel) {
            return null;
        }
        return (
            <React.Fragment>
                <div key={employee.BaseLevel ? employee.BaseLevel : 'root'} className={styles['hierarchy-container']}>
                    <div className='col'>
                        {employee.BaseLevel?.report_to && (
                            <div className={styles['boss-container']}>
                                {renderPerson(employee.BaseLevel?.report_to)}
                            </div>
                        )}
                        <div className={styles['root-connector-container']}>
                            <div className={styles['root-connector']}></div>
                        </div>
                        {employee.BaseLevel?.person &&
                            <div className={styles['boss-container']}>
                                {renderPerson(employee.BaseLevel?.person)}
                            </div>
                        }
                        {employee.LastLevel.length >= 1 && (<div className={styles['root-connector-container']}>
                            <div className={styles['root-connector']}></div>
                        </div>)}
                    </div>
                </div>
                {employee.LastLevel ?
                    <React.Fragment>
                        <div className={styles['container-spacing']}>
                            <div className={styles['children-connector-container']}>
                                {employee.LastLevel.length === 1 &&
                                    <React.Fragment>
                                        <div className={styles['boss-container']}>
                                            {renderPerson(employee.LastLevel[0])}
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                        {employee.LastLevel.length > 1 ? (
                            <div className="box">
                                <div className={styles['subordinate-container']}>
                                    {employee.LastLevel.map((subordinate: any) => {
                                        return renderPerson(subordinate);
                                    })}
                                </div>
                            </div>
                        ) : null}
                    </React.Fragment> : null}
            </React.Fragment>
        );
    };

    return (
        <>
            {employee.map((level: any, index: any) => (
                <div key={index} className={styles['page-container']}>
                    {level && level.BaseLevel && renderHierarchy(level)}
                </div>
            ))}
        </>
    );
};

export default HierarchyTree;
