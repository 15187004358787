import React, { FC } from "react";
import Box from "../Box";
import { WidgetStatusProps } from "../Widget/interfaces";
import InternalServerErrorIcon from '../error-screen/assets/internal-server-error';

const ErrorWidget: FC<WidgetStatusProps> = ({ width, error }: WidgetStatusProps) => {
    return (
        <Box width={width} box={false} border={true} >
            <div className="text-center d-flex justify-content-center align-items-center flex-column" style={{minHeight: '120px'}}>
                <div className="text-center">
                    <InternalServerErrorIcon />
                    <h4 className="mt-3">{error}</h4>
                </div>
            </div>
        </Box>
    );
};

export default ErrorWidget;
