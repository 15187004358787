import { useAxiosFetch } from "../../../hooks";
import type {
  WorkEnviromentApi,
  UseFetchTableDataReturnType,
  UseWorkEnvironmentFetchProps,
  WorkEnviromentResponse,
} from "./hooksTeamDashboard.types";
import defaultProfilImage from "../../../resources/images/default-colaborador-image.png";
import { useTeamDashboardProvider } from "../context/TeamDashboardContext";
import { useEffect } from "react";
import { subMenuListData } from "../context/carouselList.data";

export const useWorkEnvironmentFetch = (
  props: UseWorkEnvironmentFetchProps
    ): UseFetchTableDataReturnType<WorkEnviromentResponse> => {
    const { selectedYear } = props;
    const { getSelectedMenu } = useTeamDashboardProvider();
  const [getWorkEnviroment, dataWorkEnviroment] = useAxiosFetch<
    WorkEnviromentApi[]
  >({
    method: "GET",
    url: "/api/my-teams/work-environment",
    params: {
      year: selectedYear,
    },
  });

  useEffect(() => {
    if (getSelectedMenu?.name === subMenuListData[0].name) {
      getWorkEnviroment();
    }
}, [getSelectedMenu, selectedYear]);

  const workEnviromentMapper = (
    response: typeof dataWorkEnviroment
): WorkEnviromentResponse => {
    const { data } = response;
    return (
        data?.map((employee) => ({
          name: employee.name || "",
          photo: employee.photo || defaultProfilImage,
          last_name: employee.last_name || "",
          nro_document: employee.nro_document || "",
          cl: employee.cl || "-",
          position: employee.position || "-",
        })) || []
        );
    };
  
  return {
    key: subMenuListData[0].name,
    response: {
      dataTable: workEnviromentMapper(dataWorkEnviroment),
      subDatable: [],
      isLoading: dataWorkEnviroment.isLoading || dataWorkEnviroment.isLoading,
      isError: dataWorkEnviroment.isError || dataWorkEnviroment.isError,
      getAllFlags: [],
    },
  };
};