import { FC } from "react"
import InfoIcon from "./error-screen/assets/info-icon"

interface AlertInfoProps {
    text: string,
    color: string,
    widthIcon?: string;
    heightIcon?: string;
}

const AlertInfo: FC<AlertInfoProps> = ({color, text, heightIcon, widthIcon}) => {

    return(
        <>
            <div className={`alert ${color} d-flex align-items-start`} role="alert">
                <InfoIcon height={heightIcon} width={widthIcon}/>
                <span>
                    <p className="h5 color-normal mb-0 ms-2 mt-1">
                        {text}
                    </p>
                </span>
            </div>
        </>
    )
}
export default AlertInfo