import { FC, Children } from "react";
import { Controller } from "react-hook-form";
import { InputForm } from "../../interfaces/interfaces";

const RadioInput:FC<InputForm> = ({formState, input, control}) => {

    return(
        <>
        <div className="row justify-content-start ms-2">
            {Children.toArray(input.options.map((opt, i)=> {
                return(
                    <Controller
                        name={input.key}
                        control={control}
                        defaultValue={input.defaultValue || ''}
                        render={({ field }) => (
                        <>
                            <div className="col-auto">
                                <div className="form-check">
                                    <input
                                        onChange={e => field.onChange(e)}
                                        value={opt.value}
                                        className="form-check-input"
                                        type="radio"
                                        name={input.key}
                                        id={input.key + i}
                                    />
                                    <label className="form-check-label" htmlFor={input.key}>
                                        {opt.label}
                                    </label>
                                </div>
                            </div>
                        </>
                    )}
                />
                )
            }))}
            {formState.errors[input.key] && formState.errors[input.key]?.message ? (
                    <div className="invalid-feedback d-block">
                        {formState.errors[input.key]?.message}
                    </div>
                ) : null}
        </div>
        </>
    )
}

export default RadioInput;