import { useState, useCallback, useEffect } from "react";
import Auth from "@aws-amplify/auth";

type Method = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

type ValidationFunc = ( jsonData: any ) => { error: string; isGeneric: boolean } | undefined;

type FetchOptions = {
  initialUrl: string;
  submitUrl: string;
  initialMethod?: Method;
  initialBody?: string;
  submitMethod?: Method;
  cache?: boolean;
  initialValidation?: ValidationFunc;
  submitValidation?: ValidationFunc;
  initialResponseModifier?: (data: any) => any;
  submitResponseModifier?: (data: any) => any;
};

type FormProcessProps = {
  initialData: any;
  initialLoading: boolean;
  initialError: { message: string; isGeneric: boolean };
  submitData: any;
  submitLoading: boolean;
  submitError: { message: string; isGeneric: boolean };
  handleSubmit: (body: any) => void;
};

const useFormProcess = ({ initialUrl, submitUrl, initialMethod = "GET", submitMethod = "POST", cache = false, initialValidation, submitValidation, initialResponseModifier, submitResponseModifier }: FetchOptions): FormProcessProps => {
  const [initialData, setInitialData] = useState<any>(null);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [initialError, setInitialError] = useState({
    message: "",
    isGeneric: true,
  });

  const [submitData, setSubmitData] = useState<any>(null);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState({
    message: "",
    isGeneric: true,
  });

  const makeApiRequest = async ({ url, method, body, validateResponse }: { url: string; method: Method; body?: any; validateResponse?: ValidationFunc; }) => {
    try {
      const auth = await Auth.currentSession();
      const response = await fetch(url, {
        method,
        body: body ? JSON.stringify(body) : undefined,
        headers: {
          "Content-Type": "application/json",
          AccessToken: auth.getAccessToken().getJwtToken(),
        },
      });

      if (!response.ok) throw new Error();

      const jsonData = await response.json();

      if (validateResponse) {
        const validationResult = validateResponse(jsonData);
        if (validationResult) {
          const { error, isGeneric } = validationResult;
          return { error, isGeneric };
        }
      }

      return { data: jsonData };

    } catch (error: any) {
      return { error: "Hemos encontrado un error. Por favor intente más tarde.", isGeneric: true };
    }
  };

  const fetchData = useCallback(async () => {
    setInitialLoading(true);

    if (cache) {
      const cacheStorage = await caches.open("api-cache");
      const cachedResponse = await cacheStorage.match(initialUrl);
      if (cachedResponse) {
        const cachedData = await cachedResponse.json();
        setInitialData(cachedData);
        setInitialLoading(false);
        return;
      }
    }

    const { data, error, isGeneric } = await makeApiRequest({
      url: initialUrl,
      method: initialMethod,
      validateResponse: initialValidation,
    });

    if (error) {
      setInitialError({ message: error, isGeneric });
    } else {
        const modifiedData = initialResponseModifier ? initialResponseModifier(data) : data;
        setInitialData(modifiedData);
      if (cache) {
        const cacheStorage = await caches.open("api-cache");
        cacheStorage.put(initialUrl, new Response(JSON.stringify(data)));
      }
    }
    setInitialLoading(false);
  }, [initialUrl, initialMethod, cache]);

  const handleSubmit = useCallback(
    async (body: any) => {
      setSubmitLoading(true);

      const { data, error, isGeneric } = await makeApiRequest({
        url: submitUrl,
        method: submitMethod,
        body,
        validateResponse: submitValidation,
      });

      if (error) {
        setSubmitError({ message: error, isGeneric });
      } else {
        const modifiedData = submitResponseModifier ? submitResponseModifier(data) : data;
        setSubmitData(modifiedData);
      }

      setSubmitLoading(false);
    },
    [submitUrl, submitMethod]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    initialData,
    initialLoading,
    initialError,
    submitData,
    submitLoading,
    submitError,
    handleSubmit,
  };
};

export default useFormProcess;
