import Swal from "sweetalert2";

export const handleFileChange = (e,setFile,setLoadedFile,setModalView) => {
    if (e.target.files[0].type == "image/jpeg" || e.target.files[0].type == "image/jpg" || e.target.files[0].type == "image/png" || e.target.files[0].type == "application/pdf") {
        if (e.target.files[0].size <= 5000000) {
            setFile({ File: e.target.files[0], FileName: e.target.files[0].name, FileType: e.target.files[0].type });
            setLoadedFile(true);
            const f = e.target.files[0];
            let blob = new Blob([f], { type: e.target.files[0].type });
            const url = window.URL.createObjectURL(blob)
            setModalView(url)
        } else {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                html: '<div style="text-align: center; font-size: 15px">El tamaño del Certificado debe ser menor a 5Mb</div>',
                confirmButtonColor: '#0169b5',
            })
        }
    } else {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
            html: '<div style="text-align: center; font-size: 15px">Debe ingresar uno de los tipos de archivos permitidos</div>',
            confirmButtonColor: '#0169b5',
        })
    }
}