import { useState, useEffect, Children } from "react";
import { Base64 } from "js-base64";
import React from "react";
import { Link } from "react-router-dom";
import useOnlineState from "../elements/online-hook";
import newsService from '../../services/news/news-services';

const NewsList = (props) => {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        newsService.getNewsToDashboard(setItems, { fromPanel: 1 }, setLoading);
    }, []);


    const isOnline = useOnlineState();


    const base64ToArrayBuffer = (base64) => {
        const binaryString = Base64.atob(base64);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    };



    const News = () => (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Noticias
                    </li>
                </ol>
            </nav>
            <section className="noticias">
                <h1>Noticias</h1>
                <div className="col-12 position-relative mb-5">
                    <h2 className="h4 tit-section">#NoticiasCencosud</h2>
                    {loading && navigator.onLine ? (
                        <>
                            <div className="text-center">
                                <h3>Cargando...</h3>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div>
                        </>
                    ) : (
                        <div className="row">
                            {items.length ?
                                items.map((item, index) => (
                                    <div
                                        key={index}
                                        className="col-12 col-md-6 mb-4 position-relative"
                                    >
                                        <Link
                                            className="btn btn-degradado btn-absolute"
                                            to={isOnline ? "/noticias/" + item.id : "#"}
                                            onClick={(e) => {
                                                // if (isOnline) {
                                                //     setCantidadVistas(cantidadVistas - 1);
                                                // } else {
                                                //     e.preventDefault();
                                                //     setId(value.id);
                                                //     caches.open("api-cache").then((cache) => {
                                                //         cache.match(urlsAws.getNoticiasFull + "?id=" + value.id + "&rut=" + emplid).then((noticias) => {
                                                //             if (noticias) {
                                                //                 document.getElementById("offlineCache").click();
                                                //             } else {
                                                //                 document.getElementById("sinConexion").click();
                                                //             }
                                                //         });
                                                //     });
                                                // }
                                            }}
                                        >
                                            Ver más
                                            <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                        </Link>
                                        {item.banner_img_url && (
                                            <img
                                                style={{
                                                    width: "500px",
                                                    objectFit: "cover",
                                                }}
                                                src={URL.createObjectURL(new Blob([base64ToArrayBuffer(item.banner_img_url.split(";base64")[1])]))}
                                                className="d-block w-100 rounded-4"
                                                alt=""
                                            />
                                        )}
                                    </div>
                                ))
                                : (
                                    <div className="text-center">
                                        <h3>No hay noticias para mostrar</h3>
                                    </div>
                                )}
                        </div>
                    )}

                </div>
            </section>
        </>
    )

    return (
        <News />
    );
};

export default NewsList;
