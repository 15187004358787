import { urls } from "../../../resources/foo/api-endpoints";
import { RegionalNewsLetterApi } from "./subscriptionSetting.types";
import axios from "axios";
import Auth from "@aws-amplify/auth";

export const services = {
  async getRegionalNewsLetter() {
    const auth = await Auth.currentSession();

    try {
      const response = await axios.get<RegionalNewsLetterApi>(
        urls.regionalNewsLetter,
        {
          headers: {
            accesstoken: auth.getAccessToken().getJwtToken(),
          },
        }
      );
      return response.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.log(err.response?.data);
      }
    }
  },

  async updateRegionalNewsLetter(subscriptions: string[]) {
    const auth = await Auth.currentSession();
    try {
      const response = await axios.post(
        urls.regionalNewsLetter,
        {
          subscribed: true,
          subscriptions,
        },
        {
          headers: {
            accesstoken: auth.getAccessToken().getJwtToken(),
          },
        }
      );

      return response.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.log(err.response?.data);
      }
    }
  },
};
