import React from "react";
import { DetailsLeftMobile } from "./DetailsLeftMobile";
import { DetailsLeftWeb } from "./DetailsLeftWeb";

export const DetailsLeft = () => {
    return (
        <>
            {/* VERSION WEB */}
            <DetailsLeftWeb />

            {/* VERSION MOBILE */}
            <DetailsLeftMobile />
        </>
    );
};
