import { useTeamDashboardProvider } from "../../context/TeamDashboardContext";
import { CarouseItem } from "./CarouseItem";

export const CarouselSubMenuList = () => {
  const { subMenuList } = useTeamDashboardProvider();

  const isAnyChildSelected = subMenuList.some((menu) =>
    menu.carouselChildren?.some((child) => child.isSelected)
  );
  return (
    <>
      {/* Desktop */}

      <div className="my-4 d-md-block d-none">
        <div className="row">
          {!isAnyChildSelected ? subMenuList
                .filter((item) => !item.hideInCarousel)
                .map((item) => (
                  <CarouseItem key={item.id} item={item} />
                )): subMenuList.flatMap((item) =>

                item.carouselChildren
                  ?.filter((child) => !child.hideInCarousel)
                  .map((child) => (
                    <CarouseItem key={child.id} item={child} />
                  )
                )
              )}
        </div>
      </div>

      {/* Mobile */}
      <div
        id="carousel"
        className="carousel carousel-dark slide carousel-control-inner d-md-none"
        data-bs-ride="carousel"
        >
        <div className="carousel-indicators">
          {!isAnyChildSelected? subMenuList
                .filter((item) => !item.hideInCarousel)
                .map((item, i) => (
                  <button
                    key={item.id}
                    type="button"
                    data-bs-target="#carousel"
                    data-bs-slide-to={String(i)}
                    className={`${i < 1 && "active"}`}
                    aria-current={`${i < 1 && "true"}`}
                    aria-label={`Slide ${item.id}`}
                  />
                )): subMenuList.flatMap((item) =>
                item.carouselChildren
                  ?.filter((child) => !child.hideInCarousel)
                  .map((child, i) => (
                    <button
                      key={child.id}
                      type="button"
                      data-bs-target="#carousel"
                      data-bs-slide-to={String(i)}
                      className={`${i < 1 && "active"}`}
                      aria-current={`${i < 1 && "true"}`}
                      aria-label={`Slide ${child.id}`}
                    />
                  ))
              )}
        </div>
        <div className="carousel-inner">
          {!isAnyChildSelected ? subMenuList
                .filter((item) => !item.hideInCarousel)
                .map((item, i) => (
                  <CarouseItem
                    key={item.id}
                    item={item}
                    isActive={i < 1}
                  />
                )): subMenuList.flatMap((item) =>
                item.carouselChildren
                  ?.filter((child) => !child.hideInCarousel)
                  .map((child, i) => (
                    <CarouseItem
                      key={child.id}
                      item={child}
                      isActive={i < 1}
                    />
                  )
                )
              )}
        </div>
      </div>
    </>
  );
};
