
import { BreadCrumbs as BreadCrumbsConstants, Paths } from '../../../utilities/constantsTexts'
import { paths } from '../../../utilities/paths'
import { UpdateLocalStorage } from '../../../utilities/helpers/updateStorage'

const storageService = new UpdateLocalStorage()

export const formatSubLevels = (subLevels: string) => {
  const extractLevels = subLevels.split('/')
  const formated = extractLevels.map((level: string) => {
    let result = {
      label: '',
      path: ''
    }
    switch (level) {
      case BreadCrumbsConstants.allCategories:
        result = {
          label: level,
          path: paths[4].path
        }
        break;
      case BreadCrumbsConstants.category:
        const categoryInfo = localStorage.getItem(storageService.keyConstants[3])
        const parsedInfo = categoryInfo && JSON.parse(categoryInfo)
        result = {
          label: parsedInfo.categoryTitle,
          path: paths[2].path + parsedInfo.categoryID
        }
        break;
      default:
        result = {
          label: level,
          path: paths[1].path
        }
    }
    return result
  })
  return formated
}

export const trimTitle = (title: string, locationName: string) => {
  if (locationName.includes(Paths.article)) {
    const newTitle = title.length > 32 ? title.slice(0, 32) + '...' : title
    return newTitle
  } else {
    return title
  }
}