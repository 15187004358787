import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import { Base64 } from "js-base64";
import React, { Children, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import tiempoService from "../../services/tiempo-service";
import { dotNumber } from "../../services/utiles";
import CarrouselTiempo from "../elements/tiempo-carrousel";
import SaldoTiempo from "../elements/tiempo-saldo";
import PropTypes from 'prop-types';

const base64ToArrayBuffer = (base64) => {
  let binaryString = Base64.atob(base64);
  let binaryLen = binaryString.length;
  let bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    let ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

const SelectTipoJornada = ({ jornadaSeleccionada, cambioJornada }) => {
  SelectTipoJornada.propTypes = {
    jornadaSeleccionada: PropTypes.any,
    cambioJornada: PropTypes.any,
  };
  return (
    <>
      <select
        onChange={(e) => cambioJornada(e)}
        className="form-select"
        required="required"
      >
        <option disabled value="">
          Seleccione
        </option>
        {Children.toArray(
          ["Todos", "Medio Día", "Día Completo"].map((value, index) => {
            return (
              <option
                key={"array1" + index}
                value={index}
                defaultValue={
                  parseInt(jornadaSeleccionada) === index ? index : null
                }
              >{value}</option>
        
            );
          })
        )}
      </select>
    </>
  );
};

const BotonActiveView = ({ seleccionView, setSeleccionView }) => {
  BotonActiveView.propTypes = {
    seleccionView: PropTypes.any,
    setSeleccionView: PropTypes.any,
  };
  return (
    <>
      <button
        className={
          seleccionView === 1 ? "btn btn-default" : "btn btn-default active"
        }
        onClick={() => setSeleccionView(1)}
        type="button"
      >
        <i className="fa fa-th-list" aria-hidden="true"></i>
      </button>
      <button
        className={
          seleccionView === 1 ? "btn btn-default active" : "btn btn-default"
        }
        onClick={() => setSeleccionView(0)}
        type="button"
      >
        <i className="fa fa-th-large" aria-hidden="true"></i>
      </button>
    </>
  );
};

const GridView = ({ displayList }) => {
  GridView.propTypes = {
    displayList: PropTypes.any,
  };
  return (
    <>
      {/* <!-- Tabla // Desktop -->*/}
      <table className="table table-even-acordeon table-section table-borderless align-middle d-none d-md-table">
        <thead className="text-uppercase">
          <tr>
            <th className="col-3">Beneficio</th>
            <th className="col-3">Tiempo</th>
            <th className="col-2">Puntos</th>
            <th className="col-4 text-center">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {displayList ? (
            Children.toArray(
              displayList.map((e, index) => {
                return (
                  <>
                    <tr>
                      <td>{e.titulo}</td>
                      <td>{e.descr_tiempo}</td>
                      <td>{dotNumber(parseInt(e.puntos))}</td>
                      <td className="text-center">
                        <a
                          className="btn btn-link btn-sm"
                          href="#"
                          data-bs-toggle="collapse"
                          data-bs-target={"#ver-mas-" + index}
                          aria-expanded="true"
                          aria-controls={"ver-mas-" + index}
                        >
                          Ver más
                        </a>
                        <Link
                          className="btn btn-degradado btn-sm"
                          to={{ pathname: "/tiempo-canjear", search: `?id=${e.id_beneficio}`}}
                        >
                          Canjear{" "}
                          <i
                            className="fa fa-chevron-right fa-fw fa-xs"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-0" colSpan="4">
                        <div className="accordion accordion-flush">
                          <div className="accordion-item">
                            <div
                              id={"ver-mas-" + index}
                              className="accordion-collapse collapse"
                              aria-labelledby={"ver-mas-" + index}
                            >
                              <div className="row align-items-center p-2 pb-4">
                                <div className="col-4">
                                  <img
                                    className="img-fluid rounded-4"
                                    src={URL.createObjectURL(
                                      new Blob([base64ToArrayBuffer(e.imagen)])
                                    )}
                                    alt=""
                                  />
                                </div>
                                <div className="col-8">
                                  <p>{e.descr}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })
            )
          ) : (
            <></>
          )}
        </tbody>
      </table>
      {/* <!-- Fin tabla // Desktop -->*/}
      {/* <!-- Acordeón // Mobile -->*/}
      <div className="accordion accordion-flush accordion-light accordion-table d-block d-md-none">
        {displayList ? (
          Children.toArray(
            displayList.map((e, index) => {
              return (
                <div key={"array2" + index} className="accordion-item">
                  <h2
                    className={
                      index === 0
                        ? "accordion-header"
                        : "accordion-header collapsed"
                    }
                    id={"acordeon-" + index}
                  >
                    <button
                      className={
                        index === 0
                          ? "accordion-button"
                          : "accordion-button collapsed"
                      }
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={"#accordion-item-" + index}
                      aria-expanded="true"
                      aria-controls={"accordion-item-" + index}
                    >
                      <strong className="text-uppercase">Beneficio</strong>{" "}
                      {e.titulo}
                    </button>
                  </h2>
                  <div
                    id={"accordion-item-" + index}
                    className={
                      index === 0
                        ? "accordion-collapse collapse show"
                        : "accordion-collapse collapse"
                    }
                    aria-labelledby={"acordeon-" + index}
                  >
                    <div className="accordion-body p-0">
                      <div className="data-list-box data-list-box-even">
                        <ul className="list-column-1 list-unstyled">
                          <li>
                            <span className="data-list-box__title text-uppercase">
                              Tiempo
                            </span>
                            <span className="data-list-box__text">
                              {e.descr_tiempo}
                            </span>
                          </li>
                          <li>
                            <span className="data-list-box__title text-uppercase">
                              Puntos
                            </span>
                            <span className="data-list-box__text">
                              {dotNumber(parseInt(e.puntos))}
                            </span>
                          </li>
                          <li>
                            <span className="data-list-box__title text-uppercase">
                              Acciones
                            </span>
                            <span className="data-list-box__text">
                              <a
                                className="btn btn-link btn-sm w-100 mb-2"
                                href="#"
                                data-bs-toggle="collapse"
                                data-bs-target={"#ver-mas-" + index}
                                aria-expanded="true"
                                aria-controls={"ver-mas-" + index}
                              >
                                Ver más
                              </a>

                              <div className="accordion accordion-flush">
                                <div className="accordion-item">
                                  <div
                                    id={"ver-mas-" + index}
                                    className="accordion-collapse collapse"
                                    aria-labelledby={"ver-mas-" + index}
                                  >
                                    <div className="p-2 pb-4">
                                      <img
                                        className="img-fluid rounded-4 mb-3"
                                        src={URL.createObjectURL(
                                          new Blob([
                                            base64ToArrayBuffer(e.imagen),
                                          ])
                                        )}
                                        alt=""
                                      />
                                      <p>{e.descr}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <Link
                                className="btn btn-degradado w-100"
                                to={{ pathname: "/tiempo-canjear", search: `?id=${e.id_beneficio}`}}
                              >
                                Canjear{" "}
                                <i
                                  className="fa fa-chevron-right fa-fw fa-xs"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )
        ) : (
          <></>
        )}
      </div>
      {/* <!-- Fin acordeón // Mobile -->*/}
    </>
  );
};

const CardView = ({ displayList }) => {
  CardView.propTypes = {
    displayList: PropTypes.any,
  };
  return (
    <div className="row row-cols-1 row-cols-md-2 row-cols-xxl-3 g-5">
      {displayList ? (
        Children.toArray(
          displayList.map((e, index) => {
            return (
              <div key={"array3" + index} className="col d-flex">
                <article className="align-items-stretch w-100 box-card">
                  <div className="box-card__img">
                    <img
                      className="img-fluid"
                      src={URL.createObjectURL(
                        new Blob([base64ToArrayBuffer(e.imagen)])
                      )}
                      alt=""
                    />
                  </div>
                  <div className="box-card__body">
                    <h3>
                      <a href="#">{e.titulo}</a>
                    </h3>
                    <div className="h4 color-primary">
                      <span className="me-4">{e.descr_tiempo}</span>
                      <span>{dotNumber(parseInt(e.puntos))}</span>
                    </div>
                    <p>{e.descr}</p>
                    <div className="text-end">
                      <a
                        className="btn btn-link btn-sm"
                        href="#"
                        data-bs-toggle="collapse"
                        data-bs-target={"#ver-mas-" + index}
                        aria-expanded="true"
                        aria-controls={"ver-mas-" + index}
                      >
                        Ver más
                      </a>
                      <Link
                        className="btn btn-degradado"
                        to={{ pathname: "/tiempo-canjear", search: `?id=${e.id_beneficio}`}}
                      >
                        Canjear{" "}
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </div>
                  </div>
                </article>
              </div>
            );
          })
        )
      ) : (
        <></>
      )}
    </div>
  );
};

const ViewLista = ({
  seleccionView,
  setSeleccionView,
  jornadaSeleccionada,
  cambioJornada,
  displayList,
}) => {
  ViewLista.propTypes = {
    seleccionView: PropTypes.any,
    setSeleccionView: PropTypes.any,
    jornadaSeleccionada: PropTypes.any,
    cambioJornada: PropTypes.any,
    displayList: PropTypes.any,
  };
  return (
    <>
      <div className="box mb-4">
        <h2 className="h4 tit-section">Canje Cenco Flexible</h2>

        <div className="row mb-4 justify-content-end">
          <div className="col-12 col-md-3 mb-3 mb-md-0">
            <SelectTipoJornada
              jornadaSeleccionada={jornadaSeleccionada}
              cambioJornada={cambioJornada}
            />
          </div>

          <div className="col-auto">
            <div
              className="btn-group btn-group-switch"
              role="group"
              aria-label="Basic example"
            >
              <BotonActiveView
                seleccionView={seleccionView}
                setSeleccionView={setSeleccionView}
              />
            </div>
          </div>
        </div>

        {seleccionView === 1 ? (
          <GridView
            displayList={displayList}
            jornadaSeleccionada={jornadaSeleccionada}
          />
        ) : (
          <CardView
            displayList={displayList}
            jornadaSeleccionada={jornadaSeleccionada}
          />
        )}
      </div>
    </>
  );
};

const Canje = () => {
  let navigate = useNavigate();
  const emplid = localStorage.getItem("emplid");
  const [seleccionView, setSeleccionView] = useState(1);
  const [dataBeneficios, setDataBeneficios] = useState({});
  const [errorBeneficios, setErrorBeneficios] = useState(false);
  const [listadoBeneficios, setListadoBeneficios] = useState(null);
  const [jornadaSeleccionada, setJornadaSeleccionada] = useState(0);
  const [displayList, setDisplayList] = useState(listadoBeneficios);

  const handleClose = () => {
    setErrorBeneficios(false);
  };

  // //PAGINADOR
  const [totalValores, setTotalValores] = useState(0);
  const [valorInicial, setValorInicial] = useState(1);
  const valoresPagina = 4;

  const handlePageClick = (event) => {
    setValorInicial(event.selected + 1);
  };
  //FIN PAGINADOR
  const cambioJornada = (e) => {
    setValorInicial(1);
    setJornadaSeleccionada(e.target.value);
  };

  useEffect(() => {
    if (emplid !== null) {
      tiempoService.getBeneficios(setDataBeneficios);
    }
    return () => setDataBeneficios({});
  }, [emplid, navigate]);

  useEffect(() => {
    if (dataBeneficios === 2) {
      setErrorBeneficios(true);
    } else {
      if (dataBeneficios.response && dataBeneficios.response.beneficios) {
        let listaFiltrada = dataBeneficios.response.beneficios;

        if (parseInt(jornadaSeleccionada) === 1) {
          listaFiltrada = dataBeneficios.response.beneficios.filter(
            (e) => e.id_tiempo === "MED"
          );
        } else if (parseInt(jornadaSeleccionada) === 2) {
          listaFiltrada = dataBeneficios.response.beneficios.filter(
            (e) => e.id_tiempo === "LONG"
          );
        }
        if (valoresPagina) {
          setTotalValores(Math.round(listaFiltrada.length / valoresPagina));
        }
        let final = valorInicial * valoresPagina;
        let inicio = final - valoresPagina;
        let listadoMostrar = listaFiltrada.slice(inicio, final);

        if (dataBeneficios.response.valid === "1") {
          setListadoBeneficios(listadoMostrar);
        } else if (dataBeneficios.response.valid === "0") {
          setErrorBeneficios(true);
        }
      }
    }
  }, [dataBeneficios, valorInicial, jornadaSeleccionada]);

  useEffect(() => {
    if (listadoBeneficios !== null) {
      if (parseInt(jornadaSeleccionada) === 1) {
        setDisplayList(
          listadoBeneficios.filter(
            (value) => value.descr_tiempo === "Medio Día"
          )
        );
      } else if (parseInt(jornadaSeleccionada) === 2) {
        setDisplayList(
          listadoBeneficios.filter(
            (value) => value.descr_tiempo === "Día Completo"
          )
        );
      } else {
        setDisplayList(listadoBeneficios);
      }
    }
  }, [jornadaSeleccionada, listadoBeneficios]);

  return (
    <>
      {/* <!-- inicio breadcrumbs -->*/}
      <nav className="breadcrumbs" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/inicio">
              <i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true"></i> Inicio
            </Link>
          </li>
          <li className="breadcrumb-item">Mi Tiempo</li>
          <li className="breadcrumb-item active" aria-current="page">
            Canje Cenco Flexible
          </li>
        </ol>
      </nav>
      {/* <!-- fin breadcrumbs -->*/}

      {/* <!-- inicio Mi Tiempo -->*/}
      <section className="mi-tiempo">
        <div className="tit-group d-block d-lg-flex justify-content-between align-items-center mb-4">
          <h1 className="mb-3 mb-lg-0">Mi Tiempo</h1>
          <SaldoTiempo />
        </div>

        <CarrouselTiempo tituloSelect="Canje cenco flexible" />

        {displayList ? 
          <ViewLista
              seleccionView={seleccionView}
              setSeleccionView={setSeleccionView}
              jornadaSeleccionada={jornadaSeleccionada}
              cambioJornada={cambioJornada}
              displayList={displayList}
            />
          :
          <div className="box mb-4">
            <div className="alert" role="alert">
                <i className="fa fa-spinner fa-spin color-orange fa-fw" aria-hidden="true"></i>
                <span className="ms-2">Cargando...</span>
            </div>
        </div>
  }

        <nav className="mt-5" aria-label="Paginación detalle colaboradores">
          <ReactPaginate
            id={"paginationnav"}
            className="pagination justify-content-center align-items-center"
            breakLabel=" ... "
            nextLabel=" > "
            onPageChange={handlePageClick}
            pageRangeDisplayed={0}
            marginPagesDisplayed={1}
            pageCount={totalValores}
            forcePage={valorInicial > 0 ? valorInicial - 1 : 0}
            previousLabel=" < "
            renderOnZeroPageCount={null}
            previousLinkClassName={"page-link"}
            nextLinkClassName={"page-link"}
            breakLinkClassName={"page-link"}
            pageLinkClassName={"page-link"}
            pageClassName={"page-item"}
            breakClassName={"page-item"}
            previousClassName={"page-item"}
            nextClassName={"page-item"}
            activeClassName={"page-item active"}
            activeLinkClassName={"page-link active"}
          />
        </nav>
      </section>

      {errorBeneficios ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => handleClose(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-download color-orange fa-3x mb-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">Beneficios</h2>
                    <p>
                      Se ha producido un error de comunicación, vuelva a
                      intentarlo más tarde.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
export default Canje;
