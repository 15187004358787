import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import FirstLoginService from "../../services/firstlogin-service";
import LogoMiPortal from "../../resources/images/mi-portal.svg";
import logoCencosud from "../../resources/images/cencosud-logo.svg";

const Activate = () => {
    const [showSuccess, setShowSuccess] = useState(false);
    const navigate = useNavigate();
    const { search } = useLocation();
    const token = search.split("token=")[1];

    const activateAccount = async () => {
        try {
            await FirstLoginService.activate(token);
            setShowSuccess(true);
        } catch (error) {
            setShowSuccess(false);
        }
    }

    useEffect(() => {
        if (token) {
            activateAccount();
        }
    }, [token]);

    return (
            <ActivateBaseContent>
                { 
                    showSuccess ?
                    <>
                        <h1>¡Bienvenid@!</h1>
                        <p className="h6 mb-4">Ya puedes ingresar y disfrutar de los servicios de Mi Portal de Cencosud.</p>
                            <button type="button" className="btn btn-degradado px-5 col-12 col-md-auto new-login-button" onClick={() => navigate('/inicio')}>
                                Ingresar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                             </button>
                    </>
                    : 
                    <>
                        <h1 className="text-danger">Error</h1>
                        <p className="h6">Ocurrió un error al activar su cuenta. Por favor, contacte a su administrador.</p>
                    </>
                }
            </ActivateBaseContent>
    );
};


const ActivateBaseContent = (props) => {
    const { children } = props

    return (<div className="new-login">
        <div className="container d-flex justify-content-center">
            <div className="box col-12 col-md-6 position-relative">
                <img className="logo-login" src={LogoMiPortal} alt="" width="130" />
                <div className="text-center">
                    {children}
                </div>
            </div>
        </div>
        <footer>
            <div className="container">
                <div className="d-flex align-items-center">
                    <img className="me-3" src={logoCencosud} width="60" alt="Ir al inicio"></img>
                    <p className="m-0">Copyright © 2022 Cencosud</p>
                </div>
            </div>
        </footer>
    </div>
    )
}

export default Activate;