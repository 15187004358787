import { FilesSolicProps } from "../../interfaces/interfaces";
import React, { FC } from "react";
import { Controller } from "react-hook-form";

const FilesRemoteJob:FC<FilesSolicProps> = ({input, control, formState, setTotalFiles}) => {
    const handleFileChange = (e: any, field: any, key: string) => {
        const files = e.target.files[0];
        let totalSize = files?.size;

        if(files){
            setTotalFiles((prevDependencies) => {
                return {
                    ...prevDependencies,
                    [key]: totalSize,
                };
            
            });
            field.onChange(files);
        } else {
            setTotalFiles((prevDependencies) => {
                const updatedFiles = { ...prevDependencies };
                delete updatedFiles[key];
                return updatedFiles;
            });
        }
    };

    return(
        <>
            <div className="label-wrapper w-sm-70 ms-2">
                <div className="col">
                    <Controller
                        name={input.key}
                        control={control}
                        render={({ field }) => (
                            <>
                                <input
                                    id={input.key}
                                    name={field.name}
                                    type={input.type}
                                    onChange={(e) => {
                                        handleFileChange(e, field, input.key)
                                    }}
                                    ref={field.ref}
                                    accept=".jpg, .jpeg, .png, .pdf" 
                                    className="form-control"
                                />
                            </>
                        )}
                    />
                    {formState.errors[input.key] && formState.errors[input.key]?.message ? (
                        <div className="invalid-feedback d-block">
                            {formState.errors[input.key]?.message}
                        </div>
                    ) : null} 
                </div> 
            </div>
        </>
    )
}
export default FilesRemoteJob;