import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FirstLoginService from "../../services/firstlogin-service";
import ModalTermsAndConditions from "./modalTermsAndContiions";
import LogoMiPortal from "../../resources/images/mi-portal.svg";
import logoCencosud from "../../resources/images/cencosud-logo.svg";






const Register = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [conditionsAccepted, setConditionsAccepted] = useState(false);
    const [showModalConditions, setShowModalConditions] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClickConditions = () => setShowModalConditions(true);
    const closeModal = () => setShowModalConditions(false);

    const sendRegister = async () => {
        if (email) {
            setLoading(true);
            await FirstLoginService.register(email);
            setLoading(false);
            navigate("../maildeactivacion");
        }
    };

    return (
        <>
            <ModalTermsAndConditions openModal={showModalConditions} closeModal={closeModal} />

            <div className="new-login">
                <div className="container d-flex justify-content-center">
                    <div className="box col-12 col-md-6 position-relative">
                        <form>
                            <img className="logo-login" src={LogoMiPortal} alt="" width="130" />
                            <div className="text-center">
                                <p className="h6">Ingresa un Correo Electrónico de Activación.</p>
                            </div>
                            <div className="form-email my-4">
                                <label className="form-label text-uppercase fw-bold">Email</label>
                                <input className="form-control" type="text" id="titulo"
                                    onChange={(e) => setEmail(e.target.value)} required value={email} />
                            </div>
                            <div className="form-check my-4">
                                <input className="form-check-input h5" type="checkbox" value={conditionsAccepted} onChange={(e) => setConditionsAccepted(!conditionsAccepted)} />
                                <label className="form-check-label fw-normal">Acepto <a className="btnLink" onClick={handleClickConditions}>Término Legales</a>.</label>
                            </div>
                            <div className="text-center">
                                <button type="button" className="btn btn-degradado px-5 col-12 col-md-auto new-login-button" disabled={!conditionsAccepted} onClick={sendRegister}>
                                    Enviar {loading ? <div className="spinner-border spinner-border-sm ms-1"></div> : <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <footer>
                    <div class="container">
                        <div class="d-flex align-items-center">
                            <img class="me-3" src={logoCencosud} width="60" alt="Ir al inicio"></img>
                            <p class="m-0">Copyright © 2022 Cencosud</p>
                        </div>
                    </div>
                </footer>
            </div>



        </>
    );
};

export default Register;