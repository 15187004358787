import { useEffect } from "react";
import { DashboardTableContextProvider } from "./context/DashboardTableContext";
import { TeamDashboardProvider } from "./context/TeamDashboardContext";
import "../../components/datos personales col/datos-personales.css";
import { TeamDashboardContent } from "./TeamDashboardContent";

export const TeamDashboardPage = () => {
  useEffect(() => {
    const body = document.body;

    if (!body.classList.contains("shrink")) {
      body.classList.add("shrink");
    }

    return () => {
      body.classList.remove("shrink");
    };
  }, []);

  return (
    <TeamDashboardProvider>
      <DashboardTableContextProvider>
        <TeamDashboardContent />
      </DashboardTableContextProvider>
    </TeamDashboardProvider>
  );
};
