import React, { FC } from "react";
import { ModalSuccessProps } from "../../../utilities/interfaces";

const ModalSuccess: FC<ModalSuccessProps> = ({ success }) => {
    return <>
        <div className="modal fade" id="modal-success" tabIndex={-1} aria-labelledby="modal-success" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body text-center py-4">
                        {success.valid === 1 && <i className="fa fa-check-circle-o color-orange fa-3x" aria-hidden="true"></i>}
                        {success.valid === 0 && <i className="fa fa-times-circle-o color-orange fa-3x mb-2" aria-hidden="true"></i>}
                        <h2 className="h5">{`${success.valid === 1 ? "¡Éxito!" : "Error"}`}</h2>
                        <p>{success.message}</p>
                    </div>
                </div>
            </div>
        </div>
        <input hidden id={"modal-success-btn"} data-bs-toggle={"modal"} data-bs-target={"#modal-success"} />
    </>;
};

export default ModalSuccess;