import axios from "axios";
// eslint-disable-next-line no-undef
const url=process.env.REACT_APP_BACKEND_URL_GLOBAL;

const AbsenceActionService = {
    
    absenceActionMail:  async (data,setLoading,setResponseMsg, setError) => {
            const apiurl = url + '/api/Employee/AbsenceActionMail';
            const head = { 'Content-Type': 'application/json' }
            const body = JSON.stringify(data);
            axios({
              method: 'post',
              url: apiurl,
              headers: head,
              data: body
            }).then(res => {
                if (res.status === 200) {
                    setResponseMsg(res.data)
                    setLoading(false);
                    setError(false);
                } else {
                    setLoading(false);
                    setError(true);
                }
            
        }).catch((e) => {
            setLoading(false);
            setResponseMsg(e.response?.data);
            setError(true);
        })
        },
        licenseActionMail:  async (data,setLoading,setResponseMsg, setError) => {
            const apiurl = url + '/api/Employee/LicenceApprovalHash';
            const head = { 'Content-Type': 'application/json' }
            const body = JSON.stringify(data);
            axios({
              method: 'post',
              url: apiurl,
              headers: head,
              data: body
            }).then(res => {
                if (res.status === 200) {
                    setResponseMsg(res.data)
                    setLoading(false);
                    setError(false);
                } else {
                    console.log(res.data)
                    setLoading(false);
                    setError(true);
                }
        }).catch((e) => {
            setLoading(false);
            setResponseMsg(e.response?.data);
            setError(true);
        })
        }
    }
    
    export default AbsenceActionService;   
