const url = process.env.REACT_APP_BACKEND_URL_GLOBAL;
// const url = 'http://localhost:8000'; // for local testing only
import axios from 'axios';
import { Auth } from 'aws-amplify';

const ReplacementsManagementService = {
    getReplacements: async (setLoading, setReplacements, setDataNotFetched) => {
        try {
            setLoading(true);
            const authHeader = await getAuthHeader();
            const response = await axios.get(`${url}/api/replacements/getallreplacements`, { headers: authHeader });
            setReplacements(response.data);
        } catch (error) {
            setDataNotFetched(true);
            console.error('Error getting replacement prompt:', error.response?.data);
            throw error;
        } finally {
            setLoading(false);
        }
    },
    searchReplacements: async (query, setLoadingResults, setResults) => {
        try {
            setLoadingResults(true);
            const authHeader = await getAuthHeader();
            const response = await axios.get(`${url}/api/replacements/search?q=${query}`, { headers: authHeader });
            setResults(response.data);
        } catch (error) {
            console.error('Error searching replacements:', error.response?.data);
            setResults([]);
        } finally {
            setLoadingResults(false);
        }
    }, saveReplacement: async (setLoadingPost, replacementData, setResponse, setSuccess, setErrorMessage) => {
        try {
            setLoadingPost(true);
            const authHeader = await getAuthHeader();
            const response = await axios.post(
                `${url}/api/replacements/saveReplacement`,
                replacementData,
                { headers: authHeader }
            ).then((response) => {
                setResponse(response.data);
                setSuccess(true)
            });
        } catch (error) {
            setErrorMessage(error?.data?.message || error?.response?.data?.message)
            console.error('Error saving replacement:', error.response?.data);
            throw error;
        } finally {
            setLoadingPost(false);
        }
    },
    updateReplacement: async (setLoadingEdit, selectedReplacement, setResponse, setSuccess, setErrorMessage) => {
        setLoadingEdit(true);
        const authHeader = await getAuthHeader();
        const response = await axios.put(
            `${url}/api/replacements/updatereplacement`,
            selectedReplacement,
            { headers: authHeader }
        ).then((response) => {
            setResponse(response.data);
            setSuccess(true)
        }).catch(
            (error) => {
                setErrorMessage(error?.data?.message || error?.response?.data?.message)
                console.error('Error updating replacement:', error.response?.data);
                throw error;
            }
        ).finally(() => {
            setLoadingEdit(false);
        })

    },
    invalidateReplacement: async (id, setReplacementLoadingStates, setInvalidationMessage) => {
        const authHeader = await getAuthHeader();
        const response = await axios.post(`${url}/api/replacements/invalidatereplacement?id=${id}`, {}, { headers: authHeader }).then((res) => {
            setInvalidationMessage(res?.data);
        }).catch((error) => {
            setInvalidationMessage(error?.data?.message || error?.response?.data?.message);
            console.error('Error invalidating replacement:', error.response?.data);
            throw error;
        }).finally(() => {
            setReplacementLoadingStates(prevStates => ({
                ...prevStates,
                [id]: false
            }));

        })
    }

}
const getAuthHeader = async () => {
    const auth = await Auth.currentSession();
    return {
        'Content-Type': 'application/json',
        'AccessToken': auth.getAccessToken().getJwtToken(),
    };
};
export default ReplacementsManagementService;