/* eslint-disable no-unused-vars */
import FullCalendar from '@fullcalendar/react';
import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment';
import 'moment/locale/es';
import React, { useEffect, useState, useRef } from 'react';
import { BsExclamationCircle } from 'react-icons/bs';
import { Link, Routes, Route } from 'react-router-dom';
import AsistenciaService from './../../services/asistencia/asistencia-service';
import SolicitudCambioTurno from './solicitud-cambio-turno';
import Header from './calendario-header';
import CalendarResponsive from './calendar-responsive/calendar-responsive';
import SolicitudLicenciasService from '../../services/solicitud licencias/solicitud-licencias-service';
import Swal from 'sweetalert2';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import Alert from 'react-bootstrap/Alert';
import ModalResumen from './modal-resumen';

const MisHorarios = () => {

    const [shifts, setShifts] = useState([]);
    const [events, setEvents] = useState([]);
    const [cambioTurno, setCambioTurno] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [schedulesError, setSchedulesError] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const calendarRef = useRef();
    const [initial, setInitial] = useState(null);
    const [terms, setTerms] = useState(null);
    const [generalData, setGeneralData] = useState(null);
    const [showInfo, setShowInfo] = useState(true);
    const scrollRef = useRef();
    const [pendants, setPendants] = useState([]);
    const [isPendantClicked, setFlag] = useState(null);
    const [show, setShow] = useState({ active: false, data: null })
    const EVENT_STYLES = {
        Franco: { backgroundColor: '#f9deaa', textColor: '#777777' },
        Turno: { backgroundColor: '#faba00', textColor: '#777777' }
    };

    useEffect(() => {
        setTimeout(() => {
            setShowInfo(false)
        }, 5000)
    }, [])

    useEffect(() => {
        getShifts();
    }, [])

    useEffect(() => {
        if (startDate && endDate) {
            setLoading(true);
            AsistenciaService.getShifts(startDate, endDate, setLoading, setShifts, setEvents, setError);
        }
    }, [startDate, endDate]);

    useEffect(async () => {
        if (loading) {
            await SolicitudLicenciasService.getTermsBP(setTerms);
            await SolicitudLicenciasService.getData(setGeneralData);
        }
    }, [loading])


    const getEventDescription = (data) => {
        switch (data?.type) {
            case 'Franco': return 'Pendiente Cambio Franco';
            case 'Turno': return 'Pendiente Cambio Horario';
            default: return data?.type ?? '-';
        }
    }
    const handleModal = (ob) => {
        const data = ob;
        const wdMomentLocale = moment(data.workingDate);
        wdMomentLocale.locale("es");
        const diaATrabajar = wdMomentLocale.format('dddd DD [de] MMMM');

        const doMomentLocale = moment(data.dayOffDate);
        doMomentLocale.locale("es");
        const diaFranco = doMomentLocale.format('dddd DD [de] MMMM');

        const request = {
            id: data.id,
            type: data.type,
            previous: {
                dateDescription: diaATrabajar,
                startTime: data.previousStartTime,
                endTime: data.previousEndTime
            },
            changed: {
                dateDescription: data.type == 'Franco' ? diaFranco : diaATrabajar,
                startTime: data.newStartTime,
                endTime: data.newEndTime
            }
        }
        setShow({ active: true, data: request });
        setFlag(null)
    }

    const handleEvent = (ob) => {
        setCambioTurno(ob);
        scrollRef.current?.scrollIntoView({ behaviour: "smooth" })
        setFlag(null)
    }
    const renderEventContent = (eventInfo) => {
        const b = moment(new Date(eventInfo.event?.start)).format("DD-MM-YYYY")
        const a = pendants.filter(x => moment(new Date(x.workingDate)).format("DD-MM-YYYY") === b)
        const style = EVENT_STYLES[eventInfo.event?.extendedProps?.data?.type];
        if (eventInfo.event.extendedProps?.isHoliday) {
            return null;
        }

        return (
            <React.Fragment>
                <div className='d-flex flex-column'>
                    <section>
                        <div onClick={() => setFlag(false)} className={`event-turno calendar-event ${eventInfo.event.allDay ? 'calendar-event-allday' : ''}`}>
                            <div>{eventInfo.event.title}</div>
                        </div>
                    </section>
                    <section>
                        <div>
                            {a.length > 0 ? a.map((item, i) => {
                                return (
                                    <div onClick={() => { setCambioTurno(null); setFlag(true); handleModal(item) }} key={i} className='calendar-event'
                                        style={style ? { backgroundColor: style.backgroundColor, color: style.textColor } : {}}>
                                        <span className='mb-1'>
                                            {getEventDescription(item)}
                                        </span>
                                    </div>
                                )
                            }) : null}
                        </div>
                    </section>

                </div>
            </React.Fragment>
        )
    }
    const getShifts = async () => {
        AsistenciaService.getShiftsHistoryFiltered(setPendants, setLoading, setError, 'P');
    }


    const handleClick = (e) => {
        const event = e.event;
        const shift = shifts.find(shift => shift.id.toString() === event.id);
        if (shift.type === 'SinHorario') {
            return
        }
        const momentLocale = moment(shift.startDate);
        momentLocale.locale("es");

        shift.dateDescription = momentLocale.format('dddd DD [de] MMMM');
        shift.detail = shift.dateDescription;

        if (shift.type === 'Turno') {
            shift.detail += ` de ${shift.startTime} a ${shift.endTime}`;
            shift.timeDescription = `${shift.startTime} - ${shift.endTime}`;
        }

        const cambio = {
            previous: shift,
            changed: { startDate: '', startTime: '', endTime: '' },
            tipo: shift.type
        }

        if (!isPendantClicked) {
            handleEvent(cambio);
        }

    }

    const handleDatesSet = (e) => {
        setInitial(e)
        setStartDate(moment(e.start).format('YYYY-MM-DD'));
        setEndDate(moment(e.end).format('YYYY-MM-DD'))
    }
    const modalTerms = () => {
        return Swal.fire({
            title: 'BP',
            html: `<div style="text-align: left; height: 300px;"> ${terms?.bpsConditions} </div>`,
            confirmButtonColor: '#0169b5',
        })
    }
    const userInfo = () => {
        return (
            <React.Fragment>
                <Alert className="text-center" variant="info" onClose={() => setShowInfo(false)} dismissible>
                    <span><BsFillInfoCircleFill className="fs-5 info-icon-alert" /> Seleccione un dia para cambiar franco/horario</span>
                </Alert>
            </React.Fragment>
        )
    }
   
    return (
        <>
            <div className="card-box mb-3">
                <div className="header-page-container">
                    <div className="mb-2 mb-sm-4">
                        <h3>Calendario  <div className='title-underlined'></div></h3>
                    </div>
                    <div className="mb-3 mb-sm-4">
                        <button className='button history history-approval-btn'><Link to='historico'>Ver Historico <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></Link></button>
                    </div>
                </div>


                {error && <div className="alert alert-danger fade show text-center" role="alert">
                    <BsExclamationCircle className="mb-1" />  {`${error || 'Ha ocurrido un error al obtener su agenda.'}`}
                </div>}

                {schedulesError && <div className="alert alert-danger fade show text-center" role="alert">
                    <BsExclamationCircle className="mb-1" />  {`${schedulesError.data || 'Ha ocurrido un error al obtener los horarios.'}`}
                </div>}

                {loading && <div className="full-spinner mb-4"><div className="spinner-border text-primary"></div></div>}

                {showInfo ? userInfo() : null}
                {!loading ? <div className="title-card-container mb-2">
                    <h3 className="h5 fw-bold">Aprobador de Solicitudes: {generalData?.CN_RSP_ABCEN_EMPL?.MANAGER_NAME}</h3>
                    <p>Si tu aprobador no es el correcto contactá a tu BP. <a className="terms-anchor" onClick={() => modalTerms()}>Consultá acá</a> en caso de duda sobre tu BP.</p>
                </div> : null}

                <div className="mobile-table-licencias-historico">
                    {!loading && <CalendarResponsive initial={calendarRef} events={events} shifts={shifts} pendants={pendants} show={show} setShow={setShow}></CalendarResponsive>}
                    {/* {!loading ? console.log(pendants): null} */}
                </div>
                <div className="desktop-table-vacaciones-historico">
                    <Header reference={calendarRef}></Header>
                    <FullCalendar
                        ref={calendarRef}
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        headerToolbar={false}
                        locale={esLocale}
                        editable={true}
                        selectable={true}
                        weekends={true}
                        dayHeaderFormat={{ weekday: 'long' }}
                        eventContent={renderEventContent}
                        eventStartEditable={false}
                        events={events}
                        eventClick={handleClick}
                        height="auto" // controlar si es mobile
                        scrollTime={"09:00:00"}
                        views={{
                            customView: {
                                type: 'dayGridMonth',
                                duration: { weeks: 2 },
                                showNonCurrentDates: false,
                            }
                        }}

                        buttonText={{
                            next: 'Después',
                            prev: 'Antes'
                        }}

                        initialView={'customView'}
                        datesSet={handleDatesSet}

                    />
                </div>
            </div>

            {cambioTurno && <SolicitudCambioTurno cambioTurno={cambioTurno} setCambioTurno={setCambioTurno}></SolicitudCambioTurno>}
            <div ref={scrollRef}></div>
            {show && <ModalResumen show={show} setShow={setShow}></ModalResumen>}

        </>
    )
}

export default MisHorarios
