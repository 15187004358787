import { Modal } from "react-bootstrap";
import { useSubscriptionContext } from "../context";

export const ModalSaveSub = () => {
  const { saveSubcription, handleModalSave, openModalSave } =
    useSubscriptionContext();

  return (
    <Modal
      onHide={handleModalSave}
      id="newsletterstatus"
      show={openModalSave}
      centered
    >
      <Modal.Header className="border-0 pb-0" closeButton />
      <Modal.Body className="text-center py-4">
        {saveSubcription.isLoading && (
          <>
            <div className="text-center mb-4 ">
              <span className="spinner-border spinner-border-xl" />
            </div>
            <h2 className="h5 mb-2">Estamos guardando los cambios</h2>
          </>
        )}
        {saveSubcription.isError && (
          <>
            <i
              className="fa fa-times color-orange fa-3x mb-4"
              aria-hidden="true"
            />
            <h2 className="h5 mb-2">Ocurrió un error al guardar los cambios</h2>
          </>
        )}
        {saveSubcription.isSuccess && (
          <>
            <i
              className="fa fa-check-circle color-orange fa-3x mb-4"
              aria-hidden="true"
            />
            <h2 className="h5 mb-2">Cambios guardados exitosamente.</h2>
            <p className="mb-2">
              Las nuevas actualizaciones han quedado guardadas.
            </p>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
