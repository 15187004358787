import React, { Children } from "react";
import { Options, Conflicts } from "../../../utilities/interfaces";
import { handleConflicts, handleRemoveConflicts } from "../../../utilities/functions"

interface Props {
    conflictsOptions: Array<Options>;
    conflictsList: Array<Conflicts> | null;
    setConflictsList: React.Dispatch<React.SetStateAction<Array<Conflicts> | null>>;
    setConflictModal: React.Dispatch<React.SetStateAction<Conflicts | null>>;
}

const Table: React.FC<Props> = ({ conflictsOptions, conflictsList, setConflictsList, setConflictModal }) => {
    return (
        <>
            <div className="text-end border-primary border-bottom border-4 pb-2">
                <a href="/ficha-ingreso" className="mt-2" onClick={handleConflicts(
                        {
                            conflictValue: "",
                            relationshipValue: "",
                            rut: "",
                            name: "",
                            obs: "",
                            index: conflictsList !== null && conflictsList.length > 0 ? `${parseInt(conflictsList[conflictsList.length - 1]?.index) + 1}` : "0",
                        } as Conflicts, setConflictModal)
                    }
                >
                    <i className="fa fa-plus-square-o fa-lg" aria-hidden="true" />
                </a>
            </div>
            <table className="table table-even table-section table-borderless align-middle d-none d-md-table">
                <thead>
                    <tr>
                        <th className="col-5" children={"Conflicto"} />
                        <th className="col-5" children={"Rut"} />
                        <th className="col-2 text-center" children={"Acciones"} />
                    </tr>
                </thead>
                <tbody>
                    {conflictsList !== null && conflictsList.length > 0 ? (
                        Children.toArray(
                            conflictsList.map((conflict) => {
                                return (
                                    <>
                                        <tr>
                                            <td>{conflictsOptions.filter((option) => option.value === conflict.conflictValue)[0].label}</td>
                                            <td>{conflict.rut}</td>
                                            <td className="text-center">
                                                <a href="#" onClick={handleConflicts(conflict, setConflictModal)}>
                                                    <i className="fa fa-pencil fa-fw me-2" aria-hidden="true"></i>
                                                </a>
                                                <a href="#" onClick={handleRemoveConflicts(conflict, setConflictsList)}>
                                                    <i className="fa fa-trash-o fa-fw" aria-hidden="true"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </>
                                );
                            })
                        )
                    ) : (
                        null
                    )}
                </tbody>
            </table>
            <div className="data-list-section d-block d-md-none">
            {conflictsList !== null && conflictsList.length > 0
                ? Children.toArray(
                        conflictsList.map((conflict) => {
                            return (
                                <ul className="data-list-section--item list-unstyled mb-0">
                                    <li>
                                        <span className="text-uppercase d-block mb-1">
                                            <strong>{"Conflicto"}</strong>
                                        </span>
                                        <span>{conflictsOptions.filter((option) => option.value === conflict.conflictValue)[0].label}</span>
                                    </li>
                                    <li>
                                        <span className="text-uppercase d-block mb-1">
                                            <strong>{"RUT"}</strong>
                                        </span>
                                        <span>{conflict.rut}</span>
                                    </li>
                                    <li>
                                        <span className="text-uppercase d-block mb-1">
                                            <strong>{"Acciones"}</strong>
                                        </span>
                                        <a href="/ficha-ingreso" onClick={handleConflicts(conflict, setConflictModal)}>
                                            <i className="fa fa-pencil fa-fw me-2" aria-hidden="true"></i>
                                        </a>
                                        <a href="/fich-aingreso" onClick={handleRemoveConflicts(conflict, setConflictsList)}>
                                            <i className="fa fa-trash-o fa-fw" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                </ul>
                            );
                        })
                    )
                : null}
            </div>
        </>
    )
};

export default Table;