import React, { useState, useRef } from "react";
import styles from "../../resources/css/style-COL.module.css";
import { BsExclamationCircle } from "react-icons/bs";
import moment from "moment";
import warningMessage from "../SharedComponents/warning-message";
import EsquemaHibridoService from "../../services/esquemaHibrido/esquema-hibrido-service";
import errorMessage from "../SharedComponents/error-message";
import successMessage from "../SharedComponents/success-message";
import Swal from "sweetalert2";

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-degradado ms-md-2",
        cancelButton: "btn btn-primary",
    },
    buttonsStyling: false,
});

export default function AprobacionEsquema({
    pendientes,
    loadingPendientes,
    errorPendientes,
    resetValues,
    tipos
}) {
    const [selectedRowId, setSelectedRowId] = useState("0");
    const [selectedList, setSelectedList] = useState([]);
    const [commentList, setCommentList] = useState([]);

    const [isSaving, setIsSaving] = useState(false);
    const [isApproving, setIsApproving] = useState(false);
    const [isRejecting, setIsRejecting] = useState(false);

    const inputRef = useRef();

    function checkValue(e) {
        const value = e.target.value;
        const checked = e.target.checked;
        if (checked) {
            selectedList.push({ id: value, checked: true });
            setSelectedList(selectedList);
        } else {
            let selectedListFiltered = selectedList.filter((i) => i.id !== value);
            setSelectedList(selectedListFiltered);
        }

    }

    function addComment(e) {
        const id = e?.currentTarget?.id;
        setSelectedRowId(id);

        const comments = commentList.find((i) => i.id === id);
        if (comments === null || comments == undefined) {
            inputRef.current.value = "";
        } else {
            inputRef.current.value = comments?.comment;
        }
    }

    const saveComment = () => {

        const filteredCommentList = commentList.filter(
            (i) => i.id !== selectedRowId
        );
        filteredCommentList.push({
            id: selectedRowId,
            comment: inputRef.current.value,
        });
        setCommentList(filteredCommentList);
    };

    const rechazar = () => {
        if (selectedList?.length === 0) {
            warningMessage(null, "No hay items seleccionados.");
            return;
        }

        swalWithBootstrapButtons
            .fire({
                title: "Confirmación",
                text: "¿Desea continuar la operación?",
                // icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cancelar",
                reverseButtons: true,
                showCloseButton: true,
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    for (let index = 0; index < selectedList.length; index++) {
                        const element = selectedList[index];

                        const id = element?.id;
                        const comment = commentList.find((c) => c.id === id)?.comment;

                        const dto = {
                            aprobacion: "Rechazado",
                            idSolicitud: id,
                            comentario:
                                comment === null || comment === undefined ? null : comment,
                        };

                        setIsSaving(true);
                        setIsApproving(true);
                        // // Call to service
                        let response =
                            await EsquemaHibridoService.aprobacion(dto);

                        if (response === null) {
                            errorMessage(null, "");
                            setIsSaving(false);
                            setIsRejecting(false);
                            setSelectedList([]);
                        } else {
                            const status = response?.statusCode || response?.status;
                            switch (status) {
                                case 200:
                                    setIsSaving(false);
                                    setIsRejecting(false);
                                    successMessage(null);
                                    break;

                                case 400:
                                    errorMessage(null, response?.message);
                                    setIsSaving(false);
                                    setIsRejecting(false);
                                    setSelectedList([]);
                                    break;

                                case 500:
                                    errorMessage(null, response?.message);
                                    setIsSaving(false);
                                    setIsRejecting(false);
                                    setSelectedList([]);
                                    break;

                                default:
                                    break;
                            }
                        }
                    }
                    // reload
                    if (selectedList.length > 0) {
                        resetValues();
                    }
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                }
            });
    };

    const aprobar = () => {
        if (selectedList?.length === 0) {
            warningMessage(null, "No hay items seleccionados.");
            return;
        }

        swalWithBootstrapButtons
            .fire({
                title: "Confirmación",
                text: "¿Desea continuar la operación?",
                // icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cancelar",
                reverseButtons: true,
                showCloseButton: true,
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    for (let index = 0; index < selectedList.length; index++) {
                        const element = selectedList[index];

                        const id = element?.id;
                        const comment = commentList.find((c) => c.id === id)?.comment;

                        const dto = {
                            aprobacion: "Aprobado",
                            idSolicitud: id,
                            comentario:
                                comment === null || comment === undefined ? null : comment,
                        };

                        setIsSaving(true);
                        setIsApproving(true);
                        // // Call to service
                        let response =
                            await EsquemaHibridoService.aprobacion(dto);

                        if (response === null) {
                            errorMessage(null, "");
                            setIsSaving(false);
                            setIsApproving(false);
                            setSelectedList([]);
                        } else {
                            const status = response?.statusCode || response?.status;
                            switch (status) {
                                case 200:
                                    setIsSaving(false);
                                    setIsApproving(false);
                                    successMessage(null);
                                    break;

                                case 400:
                                    errorMessage(null, response?.message);
                                    setIsSaving(false);
                                    setIsApproving(false);
                                    setSelectedList([]);
                                    break;

                                case 500:
                                    errorMessage(null, response?.message);
                                    setIsSaving(false);
                                    setIsApproving(false);
                                    setSelectedList([]);
                                    break;

                                default:
                                    errorMessage(null, response?.message);
                                    setIsSaving(false);
                                    setIsApproving(false);
                                    setSelectedList([]);
                                    break;
                            }
                        }
                    }
                    // reload
                    if (selectedList.length > 0) {
                        resetValues();
                    }
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                }
            });
    };

    return (
        <>
                <h1>Aprobación Esquema de Trabajo</h1>

                <div className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}>
                    <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Solicitudes Pendientes</h2>
                    {/* <!-- Escritorio (Tabla) --> */}
                    {!loadingPendientes ? (!errorPendientes ? (pendientes?.length !== 0 ? <>
                        <div className={styles["table-responsive"]}>
                            <table className={`${styles["table"]} ${styles["table-even"]} ${styles["table-section"]} ${styles["table-borderless"]} ${styles["d-none"]} ${styles["d-md-table"]}`}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className={styles["text-nowrap"]}>Fecha <a href="#"><i className="fa fa-long-arrow-down fa-fw" aria-hidden="true"></i></a></th>
                                        <th className={styles["text-nowrap"]}>Solicitante</th>
                                        <th className={styles["text-nowrap"]}>Esquema</th>
                                        <th className={`${styles["text-nowrap"]} ${styles["text-center"]}`}>Registrar Comentario</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pendientes?.map((r, id) => {
                                        return (<tr key={id}>
                                            <td><input className={`${styles["form-check-input"]} ${styles["me-1"]} ${styles["h5"]} ${styles["m-0"]}`} type="checkbox" value={r?.idSolicitud} id="check-2" onChange={checkValue} /></td>
                                            <td>{r?.fecha}</td>
                                            <td>{r?.fullname}</td>
                                            <td>{tipos?.filter(e => e.key == r?.esquema)[0] == undefined ? "No definido" : tipos?.filter(e => e.key == r?.esquema)[0].value}</td>
                                            <td className={styles["text-center"]}><a href="#" data-bs-toggle="modal" data-bs-target="#comentario"><i className="fa fa-pencil fa-fw fa-lg" aria-hidden="true" onClick={addComment} id={r?.idSolicitud}></i></a></td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                        {/* <!-- Móvil (Acordeón) --> */}
                        <div className={`accordion accordion-flush accordion-light accordion-table ${styles["d-md-none"]}`}>

                            {/* <!-- Acordeón --> */}
                            {pendientes?.map((r, id) => {
                                return (<div key={id} className="accordion-item">
                                    <h2 className="accordion-header" id="accordion">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-item-${id}`} aria-expanded="false" aria-controls={`accordion-item-${id}`}>
                                            <strong>Fecha</strong> {r?.fecha}
                                        </button>
                                    </h2>
                                    <div id={`accordion-item-${id}`} className="accordion-collapse collapse" aria-labelledby="accordion">
                                        <div className="accordion-body p-0">
                                            {/* <!-- cuerpo item --> */}
                                            <div className="data-list-box data-list-box-even">
                                                <ul className="data-list-section--item list-unstyled">
                                                    <li>
                                                        <input className="form-check-input me-1 h5 m-0" type="checkbox" value={r?.idSolicitud} id={r?.idSolicitud} onChange={checkValue} />
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title">Solicitante</span>
                                                        <span className="data-list-box__text">{r?.fullname}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title">Esquema</span>
                                                        <span className="data-list-box__text">{tipos?.filter(e => e.key == r?.esquema)[0] == undefined ? "No definido" : tipos?.filter(e => e.key == r?.esquema)[0].value}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title">Registrar Comentario</span>
                                                        <span className="data-list-box__text"><a href="#" data-bs-toggle="modal" data-bs-target="#comentario"><i className="fa fa-pencil fa-fw fa-lg" aria-hidden="true" onClick={addComment} id={r?.idSolicitud}></i></a></span>
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* <!-- fin cuerpo item --> */}
                                        </div>
                                    </div>
                                </div>)
                            })}
                            {/* <!-- fin items acordeón --> */}
                        </div>
                        {/* <!-- fin Móvil (Acordeón) --> */}
                    </> : <div className={`${styles["alert"]} ${styles["alert-info"]} ${styles["alert-dismissible"]} ${styles["fade"]} ${styles["show"]} ${styles["text-center"]}`} role="alert">
                        <BsExclamationCircle />  No se encontraron solicitudes.
                    </div>) : <div className={`${styles["alert"]} ${styles["alert-info"]} ${styles["alert-dismissible"]} ${styles["fade"]} ${styles["show"]} ${styles["text-center"]}`} role="alert">
                        <BsExclamationCircle />  No se encontraron datos asociados
                    </div>) : <div data-testid="loading" className="full-spinner">
                        <div className="spinner-border text-primary"></div>
                    </div>}
                </div>

                <div className={`${styles["d-grid"]} ${styles["d-md-flex"]} ${styles["justify-content-md-end"]} ${styles["gap-3"]} ${styles["flex-nowrap"]} ${styles["my-5"]}`}>
                    <div className={`${styles["order-md-last"]} ${styles["d-grid"]} ${styles["d-md-flex"]} ${styles["gap-2"]}`}>
                        <button type="button" className={`${styles["btn"]} ${styles["btn-degradado"]}`} onClick={aprobar}
                            disabled={isSaving}>Aprobar{" "} {isSaving && isApproving ? (
                                <div className="spinner-border spinner-border-sm"></div>
                            ) : (
                                <i
                                    className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                                    aria-hidden="true"
                                ></i>
                            )}</button>
                    </div>
                    <button type="button" className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["order-md-first"]}`} onClick={rechazar}
                        disabled={isSaving}>No Aprobar{" "}
                        {isSaving && isRejecting ? (
                            <div className="spinner-border spinner-border-sm"></div>
                        ) : (
                            <i
                                className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                                aria-hidden="true"
                            ></i>
                        )}</button>
                </div>
                {/* <!-- Modal Comentario --> */}
                <div className="modal fade" id="comentario" tabIndex="-1" aria-labelledby="comentario" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header border-0 pb-0">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body py-4">
                                <h2 className="h4 mb-4">Registre Comentario</h2>
                                <textarea className="form-control" placeholder="Ingresar..." ref={inputRef}></textarea>
                            </div>
                            <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                                <button type="submit" className="btn btn-degradado col-12 col-md-auto my-auto px-5" data-bs-dismiss="modal" onClick={saveComment}>Guardar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- fin modals --> */}
        </>
    )
}