import React, { useState } from "react";
import { Link } from "react-router-dom";
import defaultColaboradorImage from "../../resources/images/default-colaborador-image.png";
import { getCountry } from '../organigrama/get-country';
import OrganigramaBusquedaService from '../../services/organigrama-busqueda/organigrama-busqueda';
import Tippy from '@tippyjs/react';

interface DataItem {
    fullname: string;
    country: string;
    position: string;
    photo: string;
    nro_document: string;
}

const InicioBusqueda = ({ verOrganigrama }: any) => {

    const [valueSearch, setValueSearch] = useState<string>("");
    const [data, setData] = useState<DataItem[] | null>(null);
    const [loading, setLoading] = useState<boolean>(false)
    const [valueSelect, setValueSelect] = useState<string>("");
    const countries = ['Argentina', 'Colombia', 'Chile', 'Uruguay', 'Perú', 'Brasil']

    const getSearch = async (name: string, country: string) => {
        try {
            setLoading(true)
            const response = await OrganigramaBusquedaService.getSearch(name, country);
            setData(response?.data)
            setLoading(false)
        } catch (error) {
            console.error("Error al obtener el resultado:", error);
        }
    }

    const handleSearch = (e: any) => {
        const query = e.target.value.trim().toLowerCase();
        setValueSearch(query)
    };

    const handleSelect = (value: any) => {
        setValueSelect(value)
    }

    const handleButtonSearch = () => {
        getSearch(valueSearch, valueSelect)
    };


    const handleClickItem = (dni: any) => {
        verOrganigrama(dni)
    }

    const renderTabla = (data: DataItem[]) => {
        return (
            <React.Fragment>
                <div className="box p-4">
                    <section className='m-0'>
                        <div className="tit-group d-flex justify-content-between mb-4 mb-md-0">
                            <h3 className="text-primary fw-bold">Resultado <div className='title-underlined'></div></h3>
                        </div>
                    </section>

                    <table className="table table-even table-section align-middle d-table">
                        <thead>
                            <tr>
                                <th className="align-middle">Nombre</th>
                                <th className="align-middle text-center">País</th>
                                <th className="align-middle text-center">Posición</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length != 0 ? (data.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <div className='d-flex' >
                                            <img src={item.photo !== undefined ? (item.photo !== "" ? `${item.photo}` : defaultColaboradorImage) : defaultColaboradorImage} alt="foto colaborador" className="rounded-circle me-2" width="35" height="35" />
                                            <Link to='/busqueda/organigrama' onClick={() => handleClickItem(item.nro_document)} className='text-decoration-none text-secondary text-capitalize d-flex align-items-center'>
                                                {item.fullname}
                                            </Link>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <Tippy content={item?.country}><div>{getCountry(item?.country)}</div></Tippy>
                                    </td>
                                    <td className="text-center">{item.position}</td>
                                </tr>
                            ))
                            ) : (
                                <tr>
                                    <th className="fw-bold"> Sin Resultado </th>
                                </tr>)}
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        )
    };

    return (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home color-orange" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active prueba" aria-current="page">
                        Búsqueda Organigrama
                    </li>
                </ol>
            </nav>

            <section className="mb-2">
                <div className="header-page-container">
                    <h1>Búsqueda Organigrama</h1>
                </div>
                <div className="box p-4">
                    <h3 className="text-primary fw-bold">Buscar<div className='title-underlined'></div></h3>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12">
                            <div className="fields">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-label">Nombre / Posición</div>
                                        <input name="nombre" className="form-control" type="text" placeholder='Buscar Empleado o Posición...' onChange={handleSearch} />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-label">País</div>
                                        <select className="form-select w-50" name="filtro" onChange={(e) => handleSelect(e.target.value)}>
                                            <option value="" selected disabled>Seleccionar País</option>
                                            {countries.map((country, index) => (
                                                <option key={index} value={country}>{country}</option>
                                            ))}
                                            <option value="">Mostrar Todos</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end mt-2">
                                    <button className='btn-degradado btn text-capitalize fw-bold text-nowrap' onClick={handleButtonSearch}> Buscar </button>
                                </div>
                                <span className="fw-light mt-1" style={{ fontSize: "12px" }}>
                                    <span className="align-middle" style={{ fontSize: "18px",  }}>*</span> El resultado de la búsqueda mostrará las primeras diez coincidencias. La precisión y relevancia de los resultados pueden variar según la consulta realizada.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {!loading ? (<section className='mt-4'>
                {data && renderTabla(data)}
            </section>) : <div className="text-center"><div className="spinner-border text-primary m-4"></div></div>}

        </>
    )
}

export default InicioBusqueda;