import React, { Children, useEffect, useState } from "react";
import PropTypes from 'prop-types';

const LibroAsistencia = (props) => {
    const data = props.libro;
    const [libro, setLibro] = useState(false);
    const [busqueda, setBusqueda] = useState(false);

    function formatearFecha(fecha, tipo) {
        if (tipo == "long") {
            let año = fecha.substring(0, 4);
            let mes = fecha.substring(4, 6);
            let dia = fecha.substring(6, 8);
            let horas = fecha.substring(8, 10);
            let minutos = fecha.substring(10, 12);
            let segundos = fecha.substring(12, 14);
            return (`${horas}:${minutos}`);
        } else if (tipo == "short") {
            let año = fecha.substring(0, 4);
            let mes = fecha.substring(4, 6);
            let dia = fecha.substring(6, 8);
            return (`${dia}/${mes}/${año}`);
        }
    }

    useEffect(() => {
        let array = [];
        if (data != null && data != undefined && data && data !== 2 && data.Users) {
            data.Users.forEach(element => {
                element.PlannedInterval.forEach(interval => {
                    let asistenciaObj = {};
                    asistenciaObj.Date = formatearFecha(interval.Date, "short");
                    asistenciaObj.totalHoras = interval.WorkedHours;
                    interval.Shifts.forEach(shift => {
                        asistenciaObj.Turno = shift.ShiftDisplay;
                        if ((shift.ShiftDisplay.toLowerCase()) == "break") {
                            asistenciaObj.Turno = "descanso";
                        }
                    })
                    interval.Punches.forEach(Punches => {
                        if (Punches.AssignedInBook) {
                            if (Punches.ShiftPunchType == "Entrada") {
                                asistenciaObj.setEntrada1 = formatearFecha(Punches.Date, "long");
                            }
                            if (Punches.ShiftPunchType == "SalidaColacion") {
                                asistenciaObj.setSalida1 = formatearFecha(Punches.Date, "long");
                            }
                            if (Punches.ShiftPunchType == "EntradaColacion") {
                                asistenciaObj.setEntrada2 = formatearFecha(Punches.Date, "long");
                            }
                            if (Punches.ShiftPunchType == "Salida") {
                                asistenciaObj.setSalida2 = formatearFecha(Punches.Date, "long");
                            }
                        }
                    });
                    interval.TimeOffs.forEach(timeOff => {
                        asistenciaObj.setPermiso = timeOff.TimeOffTypeDescription;
                    });
                    array.push(asistenciaObj)
                })
            });
            setLibro(array);
        }
    }, [data]);
    
    const buscarAsistencias = (e) => {
        e.preventDefault();
        setBusqueda(true);
        let fecha1 = document.getElementById("fecha1").value.replaceAll("-", "") + "000000";
        let fecha2 = document.getElementById("fecha2").value.replaceAll("-", "") + "000000";
        if (new Date(document.getElementById("fecha1").value) > new Date(document.getElementById("fecha2").value)) {
            document.getElementById("msjModalInvalid").innerHTML = "Fecha fin debe ser mayor a fecha inicio.";
            document.getElementById("btnModalInvalid").click();
        } else {
            let parameters = { "StartDate": fecha1, "EndDate": fecha2, "UserIds": props.emplid}
            props.setParameters(parameters);
            setLibro(false);
        }
    }

    return <>
        <form onSubmit={(e) => buscarAsistencias(e)}>
            <div className="box mb-4">
                <h2 className="h4 tit-section">Búsqueda</h2>
                <div className="data-list-box data-list-box-even data-list-box-align-middle data-list-box-form text-uppercase">
                    <ul className="list-column-1 list-unstyled">
                        <li>
                            <span className="data-list-box__title">Fecha inicio *</span>
                            <span className="data-list-box__text">
                                <div className="row">
                                    <div className="col-9">
                                        <input id="fecha1" className="form-control" type="date" placeholder="dd/mm/aaaa" required="required" /><div className="invalid-feedback">Debe ingresar una fecha
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-calendar fa-fw mt-2 fa-lg color-primary" aria-hidden="true"></i>
                                    </div>

                                </div>
                            </span>
                        </li>
                        <li>
                            <span className="data-list-box__title">Fecha fin *</span>
                            <span className="data-list-box__text">
                                <div className="row">
                                    <div className="col-9">
                                        <input id="fecha2" className="form-control" type="date" placeholder="dd/mm/aaaa" required="required" /><div className="invalid-feedback">Debe ingresar una fecha
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-calendar fa-fw mt-2 fa-lg color-primary" aria-hidden="true"></i>
                                    </div>

                                </div>
                            </span>
                        </li>
                    </ul>
                </div>
                <small className="mb-2 d-block">(*) Campo obligatorio</small>
                <div className="text-end d-grid d-md-block">
                    <button className="btn btn-primary" disabled={props.loading} type="submit">Buscar<i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                </div>
            </div>
        </form>

        {busqueda ?(
            <>  
                <div className="box mb-4">
                <h2 className="h4 tit-section">{libro && libro !== 2 && libro.length > 0?'Detalle de Asistencia':'Búsqueda'}</h2>
                    {props.loading && !libro ?
                    <div className="text-center">
                        <span className="spinner-border spinner-border-sm" />
                        <p> Cargando información... </p>
                    </div>
                    
                    : libro && libro !== 2 && libro.length > 0 ? (
                        <>
                        <div className="table-responsive">
                    <table className="table table-even table-section table-borderless  d-none d-md-table">
                        <thead>
                            <tr className="text-uppercase">
                                <th>Fecha</th>
                                <th>Permiso</th>
                                <th>Turno</th>
                                <th>Entró</th>
                                <th>Salió</th>
                                <th>Entró</th>
                                <th>Salió</th>
                            </tr>
                        </thead>
                        <tbody className="text-uppercase">
                            {Children.toArray(libro.map(asistencia => {
                                return <>
                                    {
                                        <tr>
                                            <td>{asistencia.Date}</td>
                                            <td>{asistencia.setPermiso}</td>
                                            <td>{asistencia.Turno}</td>
                                            <td>{asistencia.setEntrada1}</td>
                                            <td>{asistencia.setSalida1}</td>
                                            <td>{asistencia.setEntrada2}</td>
                                            <td>{asistencia.setSalida2}</td>
                                        </tr>
                                    }
                                </>
                            }))}
                        </tbody>
                    </table>
                    <div className="accordion accordion-flush d-block d-md-none accordion-light accordion-table text-uppercase">
                        {Children.toArray(libro.map((asistencia, i) => {
                            return (
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id={"acordeon-" + i}>
                                        <button className={i === 0 ? "accordion-button" : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={"#acordeon-item-" + i} aria-expanded="true" aria-controls={"accordion-item-" + i}>
                                            <strong className="text-uppercase">Fecha</strong> {asistencia.Date}
                                        </button>
                                    </h2>
                                    <div id={"acordeon-item-" + i} className={i === 0 ? "accordion-collapse collapse show" : "accordion-collapse collapse "} aria-labelledby={"acordeon-" + i}>
                                        <div className="accordion-body p-0">
                                            <div className="data-list-box data-list-box-even">
                                                <ul className="list-column-1 list-unstyled">
                                                    <li>
                                                        <span className="data-list-box__title">Permiso</span>
                                                        <span className="data-list-box__text">{asistencia.setPermiso}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title">Turno</span>
                                                        <span className="data-list-box__text">{asistencia.Turno}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title">Entró</span>
                                                        <span className="data-list-box__text">{asistencia.setEntrada1}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title">Salió</span>
                                                        <span className="data-list-box__text">{asistencia.setSalida1}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title">Entró</span>
                                                        <span className="data-list-box__text">{asistencia.setEntrada2}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title">Salió</span>
                                                        <span className="data-list-box__text">{asistencia.setSalida2}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }))}
                    </div>
                </div>
                        </>
                    ): libro && libro.length === 0 ? (
                        <>
                        <div className="d-flex align-items-center">
                            <i className="fa fa-exclamation-triangle color-orange fa-lg" aria-hidden="true"></i>
                            <span className="ms-2">No existe información disponible para la selección de fechas.</span>
                        </div>
                        </>
                    ): props.error && 
                    <div className="d-flex align-items-center">
                        <i className="fa fa-exclamation-triangle color-orange fa-lg" aria-hidden="true"></i>
                        <span className="ms-2">{props.error}</span>
                    </div>
                    }
                </div>
            </>
        ):null}
    </>
}

LibroAsistencia.propTypes = {
    libro: PropTypes.any,
    parameters: PropTypes.any,
    tokenSegundo: PropTypes.any,
};

export default LibroAsistencia;