import { urls, urlsAws } from "../resources/foo/api-endpoints";
import Auth from "@aws-amplify/auth";
import "dotenv/config";
import { encrypt } from "./utiles";

const datosLaboralesService = {
    datosLaborales: (setData) => {
        Auth.currentSession().then((auth) => {
            const head = { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() };
            const body = JSON.stringify({ request: { emplid: "" } });
            const requestOptions = {
                method: "POST",
                headers: head,
                body: body,
            };
            fetch(urlsAws.datosLaborales , requestOptions)
                .then((response) => response.text())
                .then((result) =>
                    result.includes("<?xml version=")
                        ? () => {
                            throw new Error(JSON.parse({ error: result }));
                        }
                        : result
                )
                .then((result) => result.replaceAll(",}", "}"))
                .then((result) => JSON.parse(result))
                .then((data) => {
                    if (data !== undefined)
                        if (data.response !== undefined) {
                            if (data.response.valid === "1") {
                                caches.open("api-cache").then((cache) => {
                                    cache.put(urlsAws.datosLaborales, new Response(JSON.stringify(data.response)));
                                    setData(data.response);
                                });
                            }
                        } else {
                            setData(data);
                        }
                })
                .catch((error) => {
                    console.log("error", error);
                    caches.match(urlsAws.datosLaborales).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                setData(data);
                            });
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },
    firstLogin: async () => {
        const auth = await Auth.currentSession()
        if(auth){
            const head = { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() };
            const requestOptions = {
                method: "POST",
                headers: head,
            };
            return await fetch(urlsAws.firstLogin , requestOptions)
                .then((response) => response.text())
                .then((result) => result.replaceAll(",}", "}"))
                .then((result) => JSON.parse(result))
                .then((data) => {
                    if (data.message !== undefined) data = false;
                    if (data) {
                        data = data.response;
                        //cambiar las keys del objeto data de minusculas a mayusculas
                        let data2 = {};
                        Object.keys(data).forEach((key) => {
                            data2[key.toUpperCase()] = data[key];
                        });
                        data = data2;

                        caches.open("api-cache").then((cache) => {
                            cache.put(urlsAws.firstLogin, new Response(JSON.stringify(data)));
                            if (data !== undefined) {
                                if (data.BOSS !== undefined) {
                                    localStorage.setItem(encrypt("boss"), encrypt(data.BOSS));
                                }
                                localStorage.setItem("name", encrypt(data.NAME));
                            }
                            window.sessionStorage.setItem("firstLogin", "true");
                            if (window.sessionStorage.getItem("redirect") !== null) window.location.href = window.sessionStorage.getItem("redirect");
                        });
                        localStorage.setItem("emplid", data.EMPLID);
                        localStorage.setItem("company", data.COMPANY);
                        
                    }
                    return(data);
                })
                .catch((error) => {
                    console.log("error", error);
                    caches.match(urlsAws.firstLogin).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                localStorage.setItem("emplid", data.EMPLID);
                                return(data);
                            });
                        } else {
                            return false;
                        }
                    });
                });
        }else{
            return false;
        }
    },
    LoginSendRequest: (request, endpoint, emplid) => {
        request["emplid"] = emplid;
        request["request"]["emplid"] = emplid;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify(request),
        };
        fetch(urls.LoginSendRequest, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.response !== undefined) {
                    undefined
                }
            })
            .catch((error) => console.log(error));
    },
    PhotoSendRequest: (request, setFotoGuardada, newRawPhoto, setNewPhoto) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: JSON.stringify(request),
            };
            fetch(urlsAws.PhotoSendRequest , requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.response.valid === '1') {
                        setNewPhoto(newRawPhoto);
                        setFotoGuardada(true);
                    } else {
                        setFotoGuardada(false);
                    }
                })
                .catch((error) => { 
                    setFotoGuardada(false);
                    console.log(error);
                });
        }).catch((err) => console.log(err));
    }
}

export default datosLaboralesService;
