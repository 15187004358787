import { cleanTerms, textContainWord } from "../../../commons/searcher/utils/filterFunctions"
import { Article, ArticleSubcategory } from "../../../utilities/interfaces"
import { helpCenterPath } from "../../../utilities/paths"

export const extractCategoryPath = (articles: Array<Article>) => {
  articles.map((article) => {
    const subCategoryData = article.attributes.sub_category
    if (!subCategoryData || subCategoryData.data === null) return
    addBreadCrumbProperty(article, subCategoryData)
  })
}

const addBreadCrumbProperty = (article: Article, subCategoryData: ArticleSubcategory) => {
  const categoryName = subCategoryData.data.attributes.category.data.attributes.title
  const subCategoryName = subCategoryData.data.attributes.title
  if (categoryName && subCategoryName) {
    const fullPath = helpCenterPath + '/ ' + categoryName + ' / ' + subCategoryName
    Object.defineProperty(article, 'breadCrumb', {
      value: fullPath,
      writable: true
    });
  } else {
    return
  }
}

export const extractResultContent = (articles: Array<Article>, search: string): Article[] => {
  const parsedArticles = articles.map((article) => {
    const content = article.attributes.content
    if (content === '') return article
    const htmlObject = document.createElement('div')
    htmlObject.innerHTML = content
    return addMatchTextProperty(htmlObject, search, article)
  })
  return parsedArticles
}

const addMatchTextProperty = (htmlObject: HTMLDivElement, search: string, article: Article) => {
  const childsElements = htmlObject.children
  const childsTexts = scannChildElements(childsElements)
  const childsTextsNoLinks = linksLastFilter(childsTexts)
  const childsTextsNoSpans = removeSpanElements(childsTextsNoLinks)
  const matchText = filterChildText(childsTextsNoSpans, search)
  if (matchText !== '') {
    Object.defineProperty(article, 'contentMatch', {
      value: matchText,
      writable: true,
      configurable: true
    });
  } else {
    article.contentMatch = ''
  }
  return article
}

const removeSpanElements = (childrensTexts: string[]) => {
  const cleanTexts = childrensTexts.map((text:string) => {
    return removeSpan(text)
  })
  return cleanTexts
}

export const removeSpan = (text: string) => {
  if(text.includes('<span')) {
    const spanText = replaceTextWithSpan(text)
    return spanText
  } else {
    return text
  }
}

const replaceTextWithSpan = (text: string) => {
    const newPElement = document.createElement('p')
    newPElement.innerHTML = text
    const cleanTexts = Array.from(newPElement.children).map(child => {
      const childHTML = child as HTMLElement
      const placeHolder = document.createElement('p')
      const spanText = childHTML.innerText
      placeHolder.innerHTML = spanText
      newPElement.insertBefore(placeHolder, childHTML)
      newPElement.removeChild(childHTML)
      const removeP = removeSymbols(newPElement.innerHTML)
      return removeP
    })
    if(cleanTexts) {
      return cleanTexts.join(' ')
    } else {
      return text
    }
}

const linksLastFilter = (childrensTexts: string[]) => {
  const cleanTexts = childrensTexts.map((text:string) => {
    if(text.includes('<a')) {
      const linkText = replaceTextWithLink(text)
      return linkText
    } else {
      return text
    }
  })
  return cleanTexts
}

export const extractFirstElement = (article: Article): string => {
  const content = article.attributes.content
  const htmlObject = document.createElement('div')
  htmlObject.innerHTML = content
  const childsElements = htmlObject.children
  if (childsElements.length > 0) {
    const cleanText = removeSymbols(childsElements[0].innerHTML)
    return cleanText
  } else {
    return ''
  }
}

const filterChildText = (childsTexts: Array<string>, search: string) => {
  const match = childsTexts.find((text) => {
    const include = filterByWords(text, search)
    if (include) {
      return text
    }
  })
  if(match) {return match} else {return ''}
}

export const replaceTextWithLink = (text: string) => {
  let textToShow = text
  const textWithLink = extractLinkText(text)
  if (textWithLink !== '') {
    textToShow = textWithLink
  }
  return textToShow
}

const extractLinkText = (text: string): string => {
  const htmlP = document.createElement('p')
  htmlP.innerHTML = text
  if (htmlP.childElementCount > 0) {
    const findLink = Array.from(htmlP.children).filter(child => {
      const childHTML = child as HTMLElement
      if (childHTML.nodeName === 'A') return childHTML
    })
    if(!findLink) return ''
    if(findLink.length === 1 ) {
      const soloLinktext = buildTextLink(findLink[0] as HTMLAnchorElement, htmlP)
      return soloLinktext
    } else {
      const multipleLinkText = buildTextMultipleLinks(findLink, htmlP)
      return multipleLinkText
    }
  }
  return ''
}

const buildTextMultipleLinks = (findLink: Element[], htmlP: HTMLParagraphElement) => {
  findLink.map((link) => {
    const linkE = link as HTMLAnchorElement
    const text = linkE.innerText
    const newPElement = document.createElement('p')
    newPElement.innerHTML = text
    htmlP.insertBefore(newPElement, linkE)
    htmlP.removeChild(linkE)
    return text
  })
  return removeSymbols(htmlP.innerHTML)
}

const buildTextLink = (link: HTMLAnchorElement, htmlP: HTMLParagraphElement) => {
  const linkText = link.innerText
  const linkPosition = htmlP.innerHTML.indexOf(linkText)
  const part1 = htmlP.innerHTML.slice(0, linkPosition).split('<a')[0]
  htmlP.removeChild(link)
  const completeText = part1 + ' ' + linkText
  return completeText
}

const filterByWords = (content: string, input: string) => {
  const textContent = cleanTerms(content)
  if (textContainWord(input, textContent)) {
    return textContent
  }
}

const scannChildElements = (childrens: HTMLCollection) => {
  let childrensTexts = ['']
  Array.from(childrens).map((element) => {
    const validElement = validHtmlElements(element as HTMLElement)
    if (validElement) {
      const texts = extractChildrensInnerText(validElement)
      texts.length > 0 && texts.map((text) => {
        childrensTexts.push(text)
      })
    }
  })
  childrensTexts = cleanTexts(childrensTexts)
  return childrensTexts
}

const validHtmlElements = (element: HTMLElement) => {
  const invalidNodeNames = ['FIGURE', 'IMG']
  const content = element.innerHTML
  if (element.nodeName === 'P' && content.includes('iframe')) {
    return
  }
  if(element.nodeName === 'A' || content.includes('<a')) {
    const cleanLink = document.createElement('p')
    cleanLink.innerHTML = replaceTextWithLink(content)
    return cleanLink
  }
  if (invalidNodeNames.indexOf(element.nodeName) !== -1) {
    return
  }
  return element
}

const extractChildrensInnerText = (element: HTMLElement) => {
  let childrensTexts = ['']
  if (element.childElementCount > 0) {
    const subTexts = extractSubElementsText(element)
    subTexts.map(text => text !== '' && childrensTexts.push(text))
  } else {
    const childText = removeSymbols(element.innerHTML)
    childrensTexts.push(childText)
  }
  const removeEmpty = childrensTexts.filter(text => { return text !== '' })
  return removeEmpty
}

const extractSubElementsText = (element: HTMLElement) => {
  let childrensTexts = ['']
  Array.from(element.children).map((subElement) => {
    if (subElement.nodeName === 'STRONG') {
      const cleanElement = removeSymbols(element.innerHTML)
      childrensTexts.push(cleanElement)
    }
    if (subElement.nodeName === 'A') {
      const linkText = (subElement as HTMLAnchorElement).innerText
      const cleanElement = removeSymbols(linkText)
      childrensTexts.push(cleanElement)
    }
    if (subElement.childElementCount > 0) {
      const subSubTexts = extractInnerChildsFromSubElements(subElement as HTMLElement)
      subSubTexts?.length > 0 && childrensTexts.concat(subSubTexts)
    }
    if(subElement.innerHTML.includes('<a')) {
      const subLinks = extractLinkText(subElement.innerHTML)
      childrensTexts.push(subLinks) 
    }
    const subChildText = removeSymbols(subElement.innerHTML)
    childrensTexts.push(subChildText)
  })
  return childrensTexts
}

const extractInnerChildsFromSubElements = (subElement: HTMLElement): string[] => {
  const validElements = validHtmlElements(subElement)
  let texts = ['']
  if (validElements) {
    if (validElements.childElementCount > 0) {
      Array.from(validElements.children).map(child => {
        const childHTML = child as HTMLElement
        const validChilds = validHtmlElements(childHTML)
        validChilds?.innerHTML !== '' && texts.push(childHTML.innerHTML)
      })
    }
    const subs = extractSubElementsText(validElements)
    texts = texts.concat(subs)
    const removeEmpty = texts.filter(text => { return text !== '' })
    return removeEmpty
  } else {
    return []
  }
}

const cleanTexts = (texts: Array<string>) => {
  const filterText = texts.filter((text) => {
    if (text !== '' && text !== '&nbsp;') {
      return removeSymbols(text)
    }
  })
  return filterText
}

export const removeSymbols = (text: string) => {
  const reg = /<strong>|<\/strong>|<br>|&nbsp;|&nbsp|<p>|<\/p>|<span>|<\/span>/g
  const remove = text.replace(/\\\"/g, '"').replace(reg, '')
  return remove
}