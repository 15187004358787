import React, { useState, useEffect, useContext } from "react";
import moduloDtService from '../services/modulo-dt-service';
import Modales from './elements/modal-dt';
import liquidacionService from "../services/liquidacion-service";
import { decrypt, encrypt } from '../services/utiles';
import { Base64 } from "js-base64";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Context } from '../context';
import { DTGobNorm } from "./commonComponents/DTGobNorm";

const LiquidacionDt = () => {
    const { setAuditoria, firstLoginData } = useContext(Context);
    const options = [
        {
            value: "seisMeses",
            label: "Ult. 6 meses",
        },
        {
            value: "doceMeses",
            label: "Ult. 12 meses",
        }
    ];

    const monthOption = [
        {
            value: "ene",
            int: 1,
            label: "Enero",
        },
        {
            value: "feb",
            int: 2,
            label: "Febrero",
        },
        {
            value: "mar",
            int: 3,
            label: "Marzo",
        },
        {
            value: "abr",
            int: 4,
            label: "Abril",
        },
        {
            value: "may",
            int: 5,
            label: "Mayo",
        },
        {
            value: "jun",
            int: 6,
            label: "Junio",
        },
        {
            value: "jul",
            int: 7,
            label: "Julio",
        },
        {
            value: "ago",
            int: 8,
            label: "Agosto",
        },
        {
            value: "sep",
            int: 9,
            label: "Septiembre",
        },
        {
            value: "oct",
            int: 10,
            label: "Octubre",
        },
        {
            value: "nov",
            int: 11,
            label: "Noviembre",
        },
        {
            value: "dic",
            int: 12,
            label: "Diciembre",
        },
    ];

    const [datosTrabajador, setDatosTrabajador] = useState(false);
    const [dataDt, setDataDt] = useState(false);
    const [msj, setMsj] = useState(false);
    const [rut, setRut] = useState(false);
    const [botonBuscar, setBotonBuscar] = useState(false);
    const [status, setStatus] = useState("Cargando...");
    const [statusData, setStatusData] = useState(false);
    const [selectedOption, setSelectedOption] = useState(options[0].value);
    const [seisCantidad, setSeisCantidad] = useState(true);
    const [documento, setDocumento] = useState(false);
    const ModalBasico = Modales.ModalBasico;

    const handleSubmit = async (e) => {
        e.preventDefault();
        const ruts = document.getElementById("rut").value;
        setDatosTrabajador(false);
        setRut(false);
        setDataDt(false);
        setStatusData(false);
        setSelectedOption("seisMeses");
        bloquearBotonBuscar();
        moduloDtService.validateRut(ruts, firstLoginData.USERDT, setDatosTrabajador, setMsj, setRut);
        setAuditoria({ user: encrypt(firstLoginData.USERDT), descripcion: encrypt("valida permisos para consultar el usuario liquidaciones"), rut: encrypt(ruts) })
    }

    const listarLiquidaciones = (fechas, cantidad) => {
        const ruts = document.getElementById("rut").value;
        liquidacionService.getLiquidaciones(rut, fechas[0], fechas[1], setDataDt, setStatus, cantidad, firstLoginData.USERDT)
        setAuditoria({ user: encrypt(firstLoginData.USERDT), descripcion: encrypt("consulta " + cantidad + " liquidaciones del usuario desde: " + fechas[0] + " hasta: " + fechas[1]), rut: encrypt(ruts) })
    }

    useEffect(() => {
        if (dataDt != false && !statusData && dataDt != 1) {
            dataDt.sort(sortNumber);
            let liquidaciones = dataDt.map(liquidacion => {
                let mes = monthOption.find(mes => mes.int == liquidacion.month);
                liquidacion.month = mes.label;
                return liquidacion;
            })
            setDataDt(liquidaciones);
            setStatusData(true);
        } else {
            if (dataDt == 1) {
                document.getElementById("abrirModalLiquidaciones").click();
            }
        }
    }, [dataDt])

    const bloquearBotonBuscar = () => {
        setBotonBuscar(true);
    }

    useEffect(() => {
        if (botonBuscar) {
            setTimeout(() => { setBotonBuscar(false) }, 1500)
        }
    }, [botonBuscar])

    const handleSubmiLiq = async (e) => {
        e.preventDefault();
        if (selectedOption === "seisMeses") {
            bloquearBotonBuscar();
            setDataDt(false)
            setStatusData(false);
            listarLiquidaciones(sumarMeses(6), 6);
            setSeisCantidad(true)
        } else {
            bloquearBotonBuscar();
            setDataDt(false)
            setStatusData(false);
            listarLiquidaciones(sumarMeses(12), 12);
            setSeisCantidad(false)
        }
    }

    useEffect(() => {
        if (msj == true && datosTrabajador != false && rut != false) {
            setTimeout(() => {
                listarLiquidaciones(sumarMeses(6), 6);
            }, 1000);
        } else if (msj == 2 && datosTrabajador == 2 && rut == 0) {
            document.getElementById("openModalPermisoDenegado").click();
            setDatosTrabajador(false);
            setMsj(false);
            setRut(false);
            setDataDt([]);
        }
    }, [rut]);

    function base64ToArrayBuffer(base64) {
        base64 = decrypt(base64);
        if (base64.charAt(0) === "0") {
            base64 = base64.substring(1).replaceAll("\n", "").replaceAll(" ", "");
        }
        let binaryString = Base64.atob(base64);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    function saveByteArray(reportName, byte) {
        let blob = new Blob([byte], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        let fileName = reportName;
        link.download = fileName;
        link.dispatchEvent(new MouseEvent("click"));
    }

    function sortNumber(elem1, elem2) {
        var as = [elem1.month, elem1.year];
        var bs = [elem2.month, elem2.year];
        if (parseInt(as[1], 10) > parseInt(bs[1], 10)) {  //Primero comparar el año
            return -1;
        } else if (parseInt(as[1], 10) < parseInt(bs[1], 10)) {  //o si es mayor
            return 1;
        } else if (parseInt(as[0], 10) > parseInt(bs[0], 10)) { //año igual => mes
            return -1;
        } else if (parseInt(as[0], 10) < parseInt(bs[0], 10)) { //o si el mes es mayor
            return 1;
        } else { //y si ambos son iguales
            return 0;
        }
    }

    function sumarMeses(meses) {
        let fecha = new Date(`${new Date().getFullYear()}-${(new Date().getMonth() + 1)}-01`);
        fecha.setMonth(fecha.getMonth() - meses);
        let desde = fechaSplit(fecha, true);
        let hasta = fechaSplit(new Date(), false);
        return [desde, hasta];
    }

    const fechaSplit = (fecha, desde) => {
        if (desde) {
            return ordenarFecha(fecha.getFullYear(), (fecha.getMonth() + 1));
        } else {
            return ordenarFecha(fecha.getFullYear(), fecha.getMonth());
        }
    };

    function ordenarFecha(año, mes) {
        return (año + "-" + ordenarMes(mes) + "-01")
    }

    function ordenarMes(mes) {
        if (mes > 0 && mes < 10) {
            mes = ("0" + mes);
        }
        return mes;
    }

    const handleSubmitPdfEmail = (e, año, mes) => {
        e.preventDefault();
        const ruts = document.getElementById("rut").value;
        let mes2 = monthOption.find(meses => meses.label == mes).int;
        liquidacionService.getEmail_att(rut, año, ordenarMes(mes2), "", setDocumento, firstLoginData.USERDT);
        setAuditoria({ user: encrypt(firstLoginData.USERDT), descripcion: encrypt("Descarga de pdf liquidacion correspondiente al mes: " + ordenarMes(mes2) + " año: " + año), rut: encrypt(ruts) })
        document.getElementById("openModalDescargando").click();
    };

    useEffect(() => {
        if (documento != 1 && documento != false && documento !== 2) {
            let base = base64ToArrayBuffer(documento[0]);
            saveByteArray("liquidacion_" + documento[1] + "_" + documento[2], base);
            setDocumento(false);
            setTimeout(() => {
                document.getElementById("closeModalBasicoA").click();
            }, 1000);
            setDocumento(false);
        } else {
            if (documento == 1) {
                setTimeout(() => {
                    document.getElementById("closeModalBasicoA").click();
                    document.getElementById("openModalNoDocumento").click();
                }, 1000);
            } else if (documento === 2) {
                setTimeout(() => {
                    document.getElementById("closeModalBasicoA").click();
                    document.getElementById("sinConexion").click();
                }, 1000);
            }
            setDocumento(false);
        }
    }, [documento]);

    const disabledBtn = (e) => {
        e.preventDefault();
        let input = e.target.value;
        let btn = document.getElementById("btnBuscar");
        if (input == "") {
            btn.disabled = true;
        } else {
            btn.disabled = false;
        }
    }

    return (
        <>
            <ModalBasico idBtn="openModalDescargando" idModal="modalDescargando" target="modalDescargando" msj="Descargando..." p="" modalCierre="closeModalBasicoA" icon="fa fa-exclamation-triangle color-orange fa-3x"></ModalBasico>
            <ModalBasico idBtn="openModalNoDocumento" target="modalNoHayDocumentos" idModal="modalNoHayDocumentos" msj="No hay documentos" p="No se encontraron documento" modalCierre="closeModalBasico" icon="fa fa-exclamation-triangle color-orange fa-3x" ></ModalBasico>

            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active" aria-current="page"><Link href="/liquidaciones-dt">Liquidaciones de Sueldo</Link></li>
                </ol>
            </nav>
            <section className="liquidaciones-de-sueldo">
                <h1>Liquidaciones de Sueldo</h1>
                <div className="box mb-4">
                    <h2 className="h4 tit-section">Liquidaciones</h2>
                    <h3 className="h5 my-4">Iniciar Búsqueda</h3>
                    <form onSubmit={async (e) => { handleSubmit(e) }} role="form" className="needs-validation" >
                        <div className="row mb-2">
                            <div className="col-xl-6">
                                <div className="mb-3 row">
                                    <label htmlFor="rut" className="col-md-2 col-form-label "><strong>RUT:</strong></label>
                                    <div className="col-sm-6 mb-3">
                                        <input type="text" onChange={(e) => disabledBtn(e)} className="form-control" id="rut" required="required"></input>
                                        <div className="invalid-feedback">
                                            * Ingresar rut sin puntos y con guión.
                                        </div>
                                        <p className="mt-3">
                                            Indicar RUT sin puntos y con guión, del colaborador que desea buscar.
                                        </p>
                                    </div>
                                    <div className="col d-grid d-md-block">
                                        <button type="submit" id='btnBuscar' disabled className="btn btn-degradado" >Buscar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="mb-3 row">
                                    <label htmlFor="name" className="col-md-2 col-xl-3 col-form-label "><strong>NOMBRE:</strong></label>
                                    <div className="col-md-10 col-xl-9">
                                        <input type="text" className="form-control" id="name" defaultValue={datosTrabajador.name} disabled readOnly></input>
                                    </div>
                                </div>
                            </div>
                            {/*  inicio modal */}
                            <a hidden id="openModalPermisoDenegado" data-bs-toggle="modal" data-bs-target="#permiso-rut" ></a>
                            <div className="modal fade" id="permiso-rut" tabIndex="-1" aria-labelledby="permiso-rut" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header border-0 pb-0">
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body text-center py-4">
                                            <h2 className="h5">No tiene permisos para consultar ese RUT</h2>
                                        </div>
                                        <div className="modal-footer border-0 pt-0 justify-content-center pb-5">
                                            <button type="button" className="btn btn-degradado" data-bs-dismiss="modal">Aceptar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*  fin modal */}
                        </div>
                        {rut != 0 && rut != false ?
                            <>
                                <hr className="mb-5"></hr>
                                <div className="row">
                                    <div className="col-12 col-xl-6 mb-4">
                                        <select className="form-select" value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                                            <option value={"seisMeses"}>Últimos 6 meses</option>
                                            <option value={"doceMeses"}>Últimos 12 meses</option>
                                        </select>
                                    </div>
                                    <div className="col d-grid d-md-block">
                                        <button type="button" disabled={botonBuscar} onClick={(e) => { handleSubmiLiq(e); }} className="btn btn-degradado" /*data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#fail-liquidacion" */>Buscar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </>
                            : null
                        }
                    </form>
                </div>
                {rut != 0 && rut != false ?
                    <>
                        <div className="box mb-4">
                            <h2 className="h4 tit-section">Detalle Liquidaciones <span className="h6 d-block d-sm-inline-block">
                                ({seisCantidad ? "Últimos 6 meses" : "Últimos 12 meses"})</span>
                            </h2>
                            <table className="table table-even table-section table-borderless align-middle text-uppercase">
                                {
                                    dataDt != false && dataDt != 1 && dataDt != undefined && statusData ?
                                        <>
                                            <thead>
                                                <tr>
                                                    <th className="col-5">Año</th>
                                                    <th className="col-5">Mes</th>
                                                    <th className="col-2 text-center">Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    dataDt.map((liquidacion, i) => {
                                                        return <>
                                                            <tr key={i}>
                                                                <td key={"liquidacion1" + i}>{liquidacion.year}</td>
                                                                <td key={"liquidacion2" + i}>{liquidacion.month}</td>
                                                                <td className="text-center">
                                                                    <a type="button" onClick={(e) => handleSubmitPdfEmail(e, liquidacion.year, liquidacion.month)}><i className="fa fa-file-pdf-o fa-fw me-2" aria-hidden="true"></i></a>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    })
                                                }
                                            </tbody>
                                        </>
                                        : status
                                }
                            </table>
                            <div className="modal fade" id="descarga" tabIndex="-1" aria-labelledby="descarga" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header border-0 pb-0">
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body text-center py-4">
                                            <i className="fa fa-check-circle-o color-orange fa-3x mb-2" aria-hidden="true"></i>
                                            <h2 className="h5">Descarga realizada exitosamente</h2>
                                        </div>
                                        <div className="modal-footer border-0 pt-0 justify-content-center pb-5">
                                            <button type="button" className="btn btn-degradado" data-bs-dismiss="modal">Aceptar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    : null}

                <DTGobNorm />
            </section>

            <a hidden id="abrirModalLiquidaciones" data-bs-toggle="modal" data-bs-target="#fail"></a>
            <div className="modal fade" id="fail" tabIndex="-1" aria-labelledby="fail" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body text-center py-4">
                            <i type="button" className="fa fa-times-circle-o color-orange fa-3x mb-2" data-bs-dismiss="modal" aria-hidden="true"></i>
                            <h2 className="h5">
                                No existe liquidación para el período consultado
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

            <a id="sinConexion" data-bs-toggle="modal" data-bs-target="#sinConexionModal"></a>
            <div className="modal fade" id="sinConexionModal" tabIndex="-1" aria-labelledby="sinConexionModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center py-4">
                            <i
                                className="fa fa-exclamation-triangle color-orange fa-3x"
                                aria-hidden="true"
                            ></i>
                            <h2 className="h5">No tienes conexión</h2>
                            <p>
                                No puedes ejecutar esta acción si no estás conectado.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

LiquidacionDt.propTypes = {
    userDt: PropTypes.any,
    auditoria: PropTypes.any
};

export default LiquidacionDt;