const SinConexion = () => {
    return (
        <div className="modal fade" id="sinConexionModal" tabindex="-1" aria-labelledby="sinConexionModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center py-4">
                        <i
                            className="fa fa-exclamation-triangle color-orange fa-3x"
                            aria-hidden="true"
                        ></i>
                        <h2 className="h5">No tienes conexión</h2>
                        <p>
                            No puedes ejecutar esta acción si no estás conectado.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SinConexion;