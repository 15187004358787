import React, { useState, useEffect } from 'react';
import TablaLicencias from './tabla-licencias'
import { Link } from 'react-router-dom';
import SolicitudLicenciasService from '../../services/solicitud licencias/solicitud-licencias-service';
import { BsExclamationCircle } from 'react-icons/bs';

export default function LicenciasHistorico() {
  const estadosOption = [
    { value: "", description: "Todas" },
    { value: "P", description: "Pendientes" },
    { value: "A", description: "Aprobadas" },
    { value: "R", description: "Rechazadas" }
  ];
  const [estado, setEstado] = useState(estadosOption.value);
  const [licenseList, setLicenseList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetched, setFetched] = useState(null);
  const [noData, setNoData] = useState(null);

  useEffect(async () => {
    if (!fetched) {
      loadLicencias();
      console.log("fetched")
    }
  }, [fetched])

  useEffect(() => {
    if (estado === "A") {
      SolicitudLicenciasService.UpdateTable(estado, setLicenseList, setFetched, setLoading);
    }
    if (estado === "R") {
      SolicitudLicenciasService.UpdateTable(estado, setLicenseList, setFetched, setLoading);
    }
    if (estado === "P") {
      SolicitudLicenciasService.UpdateTable(estado, setLicenseList, setFetched, setLoading);
    }
    if (estado === "") {
      loadLicencias();
    }

  }, [estado])

  const handleFilter = (e) => {
    setEstado(e.target.value)
  }

  const loadLicencias = async () => {
    await SolicitudLicenciasService.getLicenciasList(setLoading, setFetched, setLicenseList, setNoData);
  }

  return (
    <>
      {!noData ?
        <section className="historico-licencias">
          <div className="card-box mb-4">
            <h2 className="h4 tit-section">Filtrar</h2>
            <div className="row mb-2">
              <label className="col-sm-auto col-form-label fw-bold">Estado</label>
              <div className="col-sm-4">
                <select className="form-select" value={estado} onChange={handleFilter}>
                  <option disabled value="">Seleccione</option>
                  {estadosOption.map((op, i) => {
                    return <option key={i} value={op.value}>{op.description}</option>
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className="card-box mb-4">
            <div className="tit-group d-flex justify-content-between">
              <h2 className="h4 tit-section">Detalle</h2>
              <Link to="../" className="h5 mt-2 fl-normal fw-bold fs-6 text-decoration-none">Nueva Solicitud<i className="fa fa-plus-square-o fa-lg ms-2 color-orange" aria-hidden="true"></i></Link>
            </div>

            <TablaLicencias list={licenseList} update={loading} action={setFetched} />

          </div>

        </section>





        :
        <div className="alert alert-info alert-dismissible fade show text-center" role="alert">
          <BsExclamationCircle />  No se encontraron datos asociados
        </div>
      }


    </>
  )
}