import { useState, useEffect} from 'react';

//hook que retorna un estado de conexion a internet

const useOnlineState = () => {
    const [isOnline, setOnline] = useState(navigator.onLine);
    useEffect(() => {
      const goOnline = function(){
        setOnline(true);
      };
      const goOffline = function(){
        setOnline(false);
      };
      window.addEventListener('offline', goOffline);
      window.addEventListener('online', goOnline);
      return () => {
        window.removeEventListener('offline', goOffline);
        window.removeEventListener('online', goOnline);      
      }
    });
    return isOnline
  }
  export default useOnlineState;