export const textInternal = ["Declaro haber leído el Reglamento Interno de Orden Higiene \
y Seguridad de la Empresa Contratante y me comprometo a dar \
estricto cumplimiento a sus disposiciones y normas contenidas en dicho Reglamento. "];

export const textPolicy = ["Declaro haber leído la Política de Cumplimiento de Libre Competencia y sus Anexos."];

export const textEthic = ["Hago constar que he leído el Código de Ética de la empresa y que comprendo la importancia y el contexto de las reglas aquí contenidas. Además declaro que, el mismo, forma parte de las condiciones \
y prácticas que tiene la empresa para el desarrollo de las actividades en ella, las que me comprometo en este acto a respetar.",
"Asimismo, dejo constancia que la empresa me ha informado sobre el alcance de las normas contenidas \
                        en él y los efectos derivados de su eventual incumplimiento."];

export const textConflicts = ["A continuación, y según lo estipulado en el Código de Ética, doy a conocer las situaciones que actualmente pudieran \
“ser” o “parecer” un conflicto de interés respecto de mi cargo. Junto con ello, me pongo a \
disposición de la Compañía para entregarle información adicional, en caso que esto sea necesario (especificar la \
situación y en caso que corresponda: nombres, parentesco, número de documento de \
identificación, identificación de sociedades, vinculaciones con proveedores/terceros, etc.)."];

export const conflictsForm = [
    {
        tagType: "select",
        tagClassName: "form-select",
        labelTitle: "Tipo de conflicto*",
        optionsName: "conflictsOptions",
        target: "conflictValue",
        pattern: /.*/,
        message: "",
    },
    {
        tagType: "select",
        tagClassName: "form-select",
        labelTitle: "Relación*",
        optionsName: "relationshipOptions",
        target: "relationshipValue",
        pattern: /.*/,
        message: "",
    },
    {
        tagType: "text",
        tagClassName: "form-control",
        labelTitle: "Rut*",
        optionsName: "",
        target: "rut",
        pattern: /^(\d{1,2}(?:\d{3}){2}-[\dkK])$/,
        message: "Debe ingresar un rut válido; ej: 12345678-9",
    },
    {
        tagType: "text",
        tagClassName: "form-control",
        labelTitle: "Nombre Completo*",
        optionsName: "",
        target: "name",
        pattern: /^[a-zA-Z\s]+$/,
        message: "Debe ingresar un nombre sin espacios ni números",
    },
    {
        tagType: "textarea",
        tagClassName: "form-control",
        labelTitle: "Observaciones*",
        optionsName: "",
        target: "obs",
        pattern: /^\w[\w\s\.-:;]+$/,
        message: "",
    },
    {
        tagType: "hidden",
        tagClassName: "form-control",
        labelTitle: "Observaciones*",
        optionsName: "",
        target: "index",
        pattern: /^[0-9]+$/,
        message: "",
    },
];
