import React, { Children, useEffect, useState } from 'react'
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { Conflicts, Options } from '../../../../utilities/interfaces';

interface Field {
    tagType: string;
    tagClassName: string;
    labelTitle: string;
    optionsName: string;
    target: string;
    pattern: RegExp;
    message?: string;
}

interface Props {
    confVal: string;
    conflictsForm: Array<Field>;
    register: UseFormRegister<Conflicts>;
    errors: Partial<FieldErrorsImpl>;
    options: Map<string, Array<Options>>;
}

const ModalBody: React.FC<Props> = ({ confVal, conflictsForm, register, errors, options }) => {
    return (
        <div className="modal-body">
            <h2 className="h5 mb-4">{"Conflicto de interés"}</h2>
            {Children.toArray(conflictsForm.map((value) => {
                return (
                    <>
                        <div className="mb-3">
                            {value.tagType !== "hidden" ?
                                <label className={value.tagType !== "textarea" ? "form-label mb-2" : "form-label"}>
                                    <strong>{value.labelTitle}</strong>
                                </label>
                            : null}
                            {value.tagType === "select" ?
                                <>
                                    <select className={value.tagClassName} { ...register(value.target as keyof Conflicts, {
                                        required: true,
                                    })}>
                                        <option selected disabled value="">Seleccionar Valor</option>
                                        {Children.toArray(options.get(value.optionsName)?.map((o) => {
                                            return <option key={o.key} value={o.value} children={o.label} />
                                        }))}
                                    </select>
                                    {errors[value.target] &&
                                        <div className="invalid-feedback d-block">
                                            {"Debe seleccionar una opción"}
                                        </div>}
                                </>
                            : value.tagType === "text" ?
                                <>
                                    <input type={value.tagType} className={value.tagClassName} { ...register(value.target as keyof Conflicts, {
                                        required: true,
                                        pattern: {
                                            value: value.pattern,
                                            message: value.message as string,
                                        }
                                    })}/>
                                    {errors[value.target] &&
                                        <div className="invalid-feedback d-block">
                                           {errors[value.target]?.message as string}
                                        </div>}
                                </>
                            : value.tagType === "textarea" ?
                                <>
                                    <textarea className={value.tagClassName} { ...register(value.target as keyof Conflicts, {
                                        required: true,
                                        pattern: {
                                            value: value.pattern,
                                            message: "que no incluya comas (',')"
                                        }
                                    })}/>
                                    {errors[value.target] &&
                                        <div className="invalid-feedback d-block">
                                            <>
                                                {"Debe ingresar una observación "}{errors[value.target]?.type === "pattern" ? errors[value.target]?.message : ""}
                                            </>
                                        </div>}
                                </>

                            : null}
                        </div>
                        {value.tagType === "hidden" && <input type={"hidden"} { ...register(value.target as keyof Conflicts, {
                            required: true
                        })}/>}
                    </>
                )
            }))}
        </div>
  )
}

export default ModalBody;