import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import styles from "../../resources/css/style-COL.module.css";
import { BsExclamationCircle } from "react-icons/bs";
import { Link } from 'react-router-dom'
import CertificadosServices from '../../services/certificados col/certificados-col-services';
import moment from 'moment';
import { PDFDocument } from "pdf-lib";
import download from "downloadjs";
import { Auth } from "aws-amplify";
import axios from "axios";
import errorMessage from '../SharedComponents/error-message';
import successMessage from '../SharedComponents/success-message';
"../../resources/css/style-COL.css";
import Swal from "sweetalert2";

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-degradado ms-md-2",
        cancelButton: "btn btn-primary",
    },
    buttonsStyling: false,
});

export default function CertificadosCol() {
    const getHeader = () => {
        return new Promise((resolve, reject) => {
            Auth.currentSession()
                .then(async (auth) => {
                    const header = {
                        "Content-Type": "application/json",
                        accesstoken: auth.getAccessToken().getJwtToken(),
                    };
                    resolve(header);
                })
                .catch(function (error) {
                    return reject(error);
                });
        });
    };

    const [loadingGenerarCertificado, setLoadingGenerarCertificado] = useState(false)

    const [infoCertificados, setInfoCertificados] = useState([])
    const [loadingInfoCertificados, setLoadingInfoCertificados] = useState(true)
    const [noDataInfoCertificados, setNoDataInfoCertificados] = useState(false)

    const [infoTipoCertificado, setInfoTipoCertificado] = useState([])
    const [loadingTipoCertificado, setLoadingTipoCertificado] = useState(true)
    const [loadingFileCertificado, setLoadingFileCertificado] = useState(false)


    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue
    } = useForm();

    useEffect(() => {
        CertificadosServices.GetTipoCertificados(setInfoTipoCertificado, setLoadingTipoCertificado)
        CertificadosServices.GetCertificadosSinFile(setInfoCertificados, setLoadingInfoCertificados, setNoDataInfoCertificados)
    }, [loadingGenerarCertificado])

    const reloadCertificates = () => {
        setLoadingInfoCertificados(true)
        CertificadosServices.GetTipoCertificados(setInfoTipoCertificado, setLoadingTipoCertificado)
        CertificadosServices.GetCertificadosSinFile(setInfoCertificados, setLoadingInfoCertificados, setNoDataInfoCertificados)
    }
    const generate = async (e, guid) => {
        e.preventDefault()
        if (loadingFileCertificado) return;

        setLoadingFileCertificado(true)
        const data = await CertificadosServices.GetFileCertificado(guid, setLoadingFileCertificado)

        if (data?.certificado_file) {
            const file = data.certificado_file
            const newData = new Buffer.from(file, 'base64')
            const pdfDoc = await PDFDocument.load(newData);
            const pdfBytes = await pdfDoc.save();

            download(pdfBytes, "certificate.pdf");
        }
    };

    const onSubmit = async (data) => {
        try {
            const submit = {
                embajada: data.embajada && data.tipoCertificado == '04' ? data.embajada.toUpperCase() : "",
                tipoCertificado: data.tipoCertificado
            }
            setLoadingGenerarCertificado(true)
            const header = await getHeader();

            const response = await axios({
                method: "post",
                url: `${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/certificados/generarcertificado`,
                // url: `http://localhost:5000/api/certificados/generarcertificado`,
                data: submit,
                headers: {
                    accesstoken: header.accesstoken,
                },
            });
            if (response.status == 200) {
                if (data.tipoCertificado == "05") {
                    swalWithBootstrapButtons
                        .fire({
                            title: "Confirmación",
                            text: `Tu solicitud ha sido enviada al Departamento de Gestión Humana. Te enviaremos el certificado al correo corporativo.`,
                            confirmButtonText: "Aceptar",
                            reverseButtons: true,
                            showCloseButton: true,
                            icon: "success",
                        }).then(() => {
                            reloadCertificates()
                        })
                } else {
                    successMessage(reloadCertificates);
                }
                setValue("embajada", null)
                setValue("tipoCertificado", null)
                setLoadingGenerarCertificado(false)
            }
        } catch (error) {
            setLoadingGenerarCertificado(false);
            errorMessage(reloadCertificates, error?.response?.data.message)
            setValue("tipoCertificado", null)
            setValue("embajada", null)
        }

    }

    useEffect(() => {
        setValue("embajada", null)
    }, [watch("tipoCertificado")])
    return (
        <div>
            {/* <!-- inicio breadcrumbs -->   */}
            <nav className={`${styles["breadcrumbs"]} ${styles["d-none"]} ${styles["d-lg-block"]}`} aria-label="breadcrumb">
                <ol className={styles["breadcrumb"]}>
                    <li className={styles["breadcrumb-item"]}><Link to="/inicio"><i className="fa fa-home" aria-hidden="true"></i> Inicio</Link></li>
                    <li className={styles["breadcrumb-item"]}>Transacciones</li>
                    <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">Certificado Laboral</li>
                </ol>
            </nav>
            {/* <!-- fin breadcrumbs --> */}

            {/* <!-- inicio certificados --> */}
            {!noDataInfoCertificados ? <section className={styles["certificados"] + " " + "cencosud-col"}>

                <h1>Certificados</h1>

                {/* <!-- Solicitud de Certificados --> */}
                <div className={`${styles["box"]} ${styles["mb-4"]}`}>
                    <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Solicitud de Certificados</h2>
                    <form>
                        <div className={styles["row"]}>
                            <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                                <label className={styles["form-label"]}>Tipo de certificado</label>
                                <div className={styles["input-group"]}>
                                    {!loadingTipoCertificado ? <select className={styles["form-select"]} {...register("tipoCertificado", { required: "Seleccione un tipo de certificado" })}>
                                        <option value="" disabled selected>Seleccione</option>
                                        {infoTipoCertificado.map((c, id) => {
                                            return (
                                                <option key={id} value={c.FIELDVALUE}>{c.XLATLONGNAME}</option>
                                            )
                                        })}
                                    </select> : <div><div className="spinner-border spinner-border-sm"></div></div>}
                                </div>
                                <small className="form-error-message">{errors.tipoCertificado?.message}</small>
                            </div>
                            <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                                <label className={styles["form-label"]}>Embajada</label>
                                <input type="text" className={styles["form-control"]} {...register("embajada", { required: watch("tipoCertificado") == "04" ? "* Campo obligatorio" : false, maxLength: 50 })} disabled={watch("tipoCertificado") !== "04"} />
                                <small className="form-error-message">{errors.embajada?.message}</small>
                            </div>
                        </div>
                        <div className={`${styles["d-grid"]} ${styles["d-md-flex"]} ${styles["justify-content-md-end"]} ${styles["gap-2"]} ${styles["flex-nowrap"]} ${styles["mt-4"]}`}>
                            <div className={`${styles["d-grid"]} ${styles["d-md-flex"]} ${styles["gap-2"]}`}>
                                <button type="button" className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["ms-md-2"]}`} onClick={handleSubmit(onSubmit)} disabled={loadingGenerarCertificado || watch("tipoCertificado") === ""}>Solicitar {loadingGenerarCertificado ? <div className="spinner-border spinner-border-sm"></div> : <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i>}</button>
                            </div>
                        </div>
                    </form>
                </div>
                {/* <!-- Fin Solicitud de Certificados --> */}

                {/* <!-- Certificados Solicitados --> */}
                {loadingInfoCertificados ? <div data-testid="loading" className="full-spinner">
                    <div className="spinner-border text-primary"></div>
                </div> : noDataInfoCertificados ? <div className="alert alert-info alert-dismissible fade show text-center" role="alert">
                    <BsExclamationCircle />  Hubo un error en el sistema, intentelo nuevamente.
                </div> : <div className={`${styles["box"]} ${styles["mb-4"]}`}>
                    <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Certificados Solicitados</h2>
                    {infoCertificados.length !== 0 ? <>
                        {/* <!-- Escritorio (Tabla) --> */}
                        <div className={styles["table-responsive"]}>
                            <table className={`${styles["table"]} ${styles["table-even"]} ${styles["table-section"]} ${styles["table-borderless"]} ${styles["d-none"]} ${styles["d-md-table"]}`}>
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Tipo Certificado</th>
                                        <th>Vencimiento</th>
                                        <th>Estado</th>
                                        <th className={styles["text-center"]}>Ver</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {infoCertificados.map((c, id) => {
                                        return (<tr key={id}>
                                            <td>{moment(c.fecha_creacion).format("DD-MM-yyyy")}</td>
                                            <td>{infoTipoCertificado != undefined ? infoTipoCertificado.filter(t => c.tipocertificado === t.FIELDVALUE)[0] == undefined ? "No definido" : infoTipoCertificado.filter(t => c.tipocertificado === t.FIELDVALUE)[0].XLATLONGNAME :
                                                <div data-testid="loading" className="full-spinner">
                                                    <div className="spinner-border text-primary"></div>
                                                </div>}</td>
                                            <td>{moment(new Date(c.fecha_creacion).setUTCDate(new Date(c.fecha_creacion).getUTCDate() + 30)).format("DD-MM-yyyy")}</td>
                                            <td>{new Date(c.fecha_creacion).setUTCDate(new Date(c.fecha_creacion).getUTCDate() + 30) > new Date() ? "Vigente" : "Vencida"}</td>
                                            <td className={styles["text-center"]}>
                                                {new Date(c.fecha_creacion).setUTCDate(new Date(c.fecha_creacion).getUTCDate() + 30) > new Date() ? <Link to="#" onClick={e => generate(e, c.guid)}><i className={`${styles["fa"]} ${styles["fa-eye"]} ${styles["fa-fw"]} ${styles["fa-lg"]}`} aria-hidden="true"></i></Link> : null}
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                        {/* <!-- Móvil (Acordeón) --> */}
                        <div className={`accordion accordion-flush accordion-light accordion-table ${styles["d-md-none"]}`}>
                            {/* <!-- 1er acordeón --> */}
                            {infoCertificados.map((c, id) => {
                                return (<div key={id} className="accordion-item">
                                    <h2 className="accordion-header" id="accordion">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-item-${id}`} aria-expanded="false" aria-controls={`accordion-item-${id}`}>
                                            <strong>Fecha</strong> {moment(c.fecha_creacion).format("DD-MM-yyyy")}
                                        </button>
                                    </h2>
                                    <div id={`accordion-item-${id}`} className="accordion-collapse collapse" aria-labelledby="accordion">
                                        <div className={`accordion-body ${styles["p-0"]}`}>
                                            {/* <!-- cuerpo item --> */}
                                            <div className={`${styles["data-list-box"]} ${styles["data-list-box-even"]}`}>
                                                <ul className={`${styles["data-list-section--item"]} ${styles["list-unstyled"]}`}>
                                                    <li>
                                                        <span className={styles["data-list-box__title"]}>Tipo Certificado</span>
                                                        <span className={styles["data-list-box__text"]}>{infoTipoCertificado != undefined ? infoTipoCertificado.filter(t => c.tipocertificado === t.FIELDVALUE)[0] == undefined ? "No definido" : infoTipoCertificado.filter(t => c.tipocertificado === t.FIELDVALUE)[0].XLATLONGNAME :
                                                            <div data-testid="loading" className="full-spinner">
                                                                <div className="spinner-border text-primary"></div>
                                                            </div>}</span>
                                                    </li>
                                                    <li>
                                                        <span className={styles["data-list-box__title"]}>Vencimiento</span>
                                                        <span className={styles["data-list-box__text"]}>{moment(new Date(c.fecha_creacion).setUTCDate(new Date(c.fecha_creacion).getUTCDate() + 30)).format("DD-MM-yyyy")}</span>
                                                    </li>
                                                    <li>
                                                        <span className={styles["data-list-box__title"]}>Estado</span>
                                                        <span className={styles["data-list-box__text"]}>{new Date(c.fecha_creacion).setUTCDate(new Date(c.fecha_creacion).getUTCDate() + 30) > new Date() ? "Vigente" : "Vencida"}</span>
                                                    </li>
                                                    <li>
                                                        <span className={styles["data-list-box__title"]}>Ver</span>
                                                        <span className={styles["data-list-box__text"]}>
                                                            {new Date(c.fecha_creacion).setUTCDate(new Date(c.fecha_creacion).getUTCDate() + 30) > new Date() ? <i onClick={e => generate(e, c.certificado_file)} className={`opacity-5, ${styles["fa"]} ${styles["fa-eye"]} ${styles["fa-fw"]} ${styles["fa-lg"]} ${styles["opacity-5"]}`} aria-hidden="true"></i> : null}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* <!-- fin cuerpo item --> */}
                                        </div>
                                    </div>
                                </div>)
                            })}
                            {/* <!-- fin items acordeón --> */}
                        </div></> : <div className="alert alert-info alert-dismissible fade show text-center" role="alert">
                        <BsExclamationCircle />  No se solicitaron certificados
                    </div>}
                    {/* <!-- fin Móvil (Acordeón) --> */}
                </div>}




            </section> :
                <div className="alert alert-info alert-dismissible fade show text-center" role="alert">
                    <BsExclamationCircle />  Hubo un error en el sistema, intente de nuevo más tarde
                </div>}
        </div>

    )
}