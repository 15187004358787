import React, { useState, useEffect, useRef, Children } from 'react';
import { formatDate, groupBy, dotPerc } from "../../../services/utiles";
import { Text, Image, StyleSheet, View, Page, Document, PDFDownloadLink } from "@react-pdf/renderer";
import checkedimg from "../../../resources/images/checked-img.png";
import uncheckedimg from "../../../resources/images/unchecked-img.png";

const DiasTomados = ({
    diasTomadosData,
    statusDiasTomados,
    loadingDiasTomados
}) => {
    const [diasTomadosPorAno, setDiasTomadosPorAno] = useState(false);

    const styles = StyleSheet.create({
        page: {
            backgroundColor: '#fff',
            padding: '20px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        card: {
            padding: "20px",
            borderRadius: "10px",
            width: "48%",
            border: "1px solid #eeeeee",
            margin: " 0px 5px 20px 5px",
            height: "auto",
        },
        head: {
            display: "flex",
            marginBottom: "20px",
            paddingLeft: "10px",
        },
        body: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px"
        },
        h1: {
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "left",
            marginBottom: "10px",
            color: "#0169b5"
        },
        table: {
            width: "100%",
            borderCollapse: "collapse",
            borderSpacing: "0",
            marginBottom: "20px"
        },
        tableHead: {
            backgroundColor: "#eeeeee",
            borderBottom: "1px solid #eeeeee",
            borderTop: "1px solid #eeeeee",
            borderLeft: "1px solid #eeeeee",
            borderRight: "1px solid #eeeeee",
            padding: "5px",
            fontSize: "8px",
        },
        tableBody: {
            borderBottom: "1px solid #eeeeee",
            borderLeft: "1px solid #eeeeee",
            borderRight: "1px solid #eeeeee",
            fontSize: "8px",
            textAlign: "center",
            color: "#777777",
        },
        tableRow: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            padding: "5px 5px 5px 5px",
            border: "1px solid #eeeeee",
        },
        labelHead: {
            textAlign: "center",
            color: "#777777",
            fontWeight: "900",
        },
        labelBody: {
            textAlign: "center",
            color: "#777777",
            fontWeight: "normal",
        },
        li: {
            display: "flex",
            flexDirection: "row",
            alignItems: 'flex-start',
            padding: "10px",
            borderBottom: "1px solid #eeeeee",
            position: "relative",
            justifyContent: "flex-start"
        },
        liTitle: {
            width: "50%",
        },
        liContent: {
            width: "50%",
        },
        titleLabel: {
            fontSize: "8px",
            fontWeight: "bold",
            color: "#777777"
        },
        contentLabel: {
            fontSize: "8px",
            fontWeight: "normal",
            color: "#777777"
        },
        foto: {
            width: "100px",
            height: "100px",
        },
        checkimg: {
            width: "20px",
            height: "20px",
        },
    });

    useEffect(() => {
        if (diasTomadosData) {
            if (diasTomadosData !== "error") {
                let temp = []
                for (let i = diasTomadosData.length - 1; i > 0; i--) {
                    temp.push(diasTomadosData[i])
                }
                const groupdata = groupBy(temp, "year")
                setDiasTomadosPorAno(groupdata)
            }
        }
    }, [diasTomadosData]);



    const FichaVacacionesPDF = () => {

        return <Document>
            <Page size="A4" style={styles.page}>
                {/* Días Tomados */}
                <View style={{ ...styles.card, width: "100%" }}>
                    <View style={styles.head}>
                        <Text style={styles.h1}>Días Tomados</Text>
                    </View>
                    <View style={styles.body}>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={styles.tableRow}>
                                    <Text style={{ ...styles.labelHead, width: "20%" }}>AÑO</Text>
                                    <Text style={{ ...styles.labelHead, width: "20%" }}>F. INICIO</Text>
                                    <Text style={{ ...styles.labelHead, width: "20%" }}>F. FINAL</Text>
                                    <Text style={{ ...styles.labelHead, width: "20%" }}>DÍAS</Text>
                                    <Text style={{ ...styles.labelHead, width: "20%" }}>APROBADO</Text>
                                </View>
                            </View>
                            <View style={styles.tableBody}>
                                {
                                    diasTomadosData && diasTomadosData !== "error" ?
                                        Array.isArray(diasTomadosData) ?
                                            diasTomadosData.map((item, index) => {
                                                return (
                                                    <View style={styles.tableRow} key={"dt" + index}>
                                                        <Text style={{ ...styles.labelContent, width: "20%" }}>{item.year === "" ? "-" : item.year}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "20%" }}>{item.fecha_inicio === "" ? "-" : formatDate(item.fecha_inicio)}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "20%" }}>{item.fecha_retorno === "" ? "-" : formatDate(item.fecha_retorno)}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "20%" }}>{item.duracion_dias === "" ? "-" : item.duracion_dias}</Text>
                                                        <View style={{ ...styles.labelContent, width: "20%", paddingLeft: "38px" }}>
                                                            <Image style={{ ...styles.checkimg }} source={item.approved_flag === "" || item.approved_flag === "N" ? uncheckedimg : checkedimg} />
                                                        </View>
                                                    </View>
                                                )
                                            })
                                            :
                                            <View style={styles.tableRow} key={"dt0"}>
                                                <Text style={{ ...styles.labelContent, width: "20%" }}>{diasTomadosData.year === "" ? "-" : diasTomadosData.year}</Text>
                                                <Text style={{ ...styles.labelContent, width: "20%" }}>{diasTomadosData.fecha_inicio === "" ? "-" : formatDate(diasTomadosData.fecha_inicio)}</Text>
                                                <Text style={{ ...styles.labelContent, width: "20%" }}>{diasTomadosData.fecha_retorno === "" ? "-" : formatDate(diasTomadosData.fecha_retorno)}</Text>
                                                <Text style={{ ...styles.labelContent, width: "20%" }}>{diasTomadosData.duracion_dias === "" ? "-" : diasTomadosData.duracion_dias}</Text>
                                                <View style={{ ...styles.labelContent, width: "20%", paddingLeft: "38px" }}>
                                                    <Image style={{ ...styles.checkimg }} source={diasTomadosData.approved_flag === "" || diasTomadosData.approved_flag === "N" ? uncheckedimg : checkedimg} />
                                                </View>
                                            </View>
                                        :
                                        <View style={styles.tableRow}>
                                            <Text style={styles.labelContent}>Sin Registros</Text>
                                        </View>
                                }
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    }

    return (
        <>

            {(loadingDiasTomados) ? null : (statusDiasTomados == 200) ? (<>
                {/* <PDFDownloadLink
                    className="text-decoration-none d-inline-block mb-3 mt-4 color-orange"
                    fileName="fichaVacaciones"
                    document={<FichaVacacionesPDF />}
                >
                    <i className="fa fa-file-pdf-o me-1" aria-hidden="true"></i>
                    Descargar
                </PDFDownloadLink> */}


                <div className="col-12 col-xl-12 d-flex">
                    <div className="box align-items-stretch w-100">
                        <h2 className="h4 tit-section">Días Tomados</h2>
                        <table className="table table-even table-section table-borderless text-uppercase d-none d-md-table">
                            <thead>
                                <tr>
                                    <th>Año</th>
                                    <th>F. Inicio</th>
                                    <th>F. Final</th>
                                    <th>Días</th>
                                    <th className="text-center">Aprobado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {diasTomadosData && diasTomadosData !== "error" ? (
                                    Array.isArray(diasTomadosData) ? (
                                        diasTomadosData.map((item) => {
                                            return (
                                                <tr key={"item" + item.fecha_inicio}>
                                                    <td>{item.year}</td>
                                                    <td>{formatDate(item.fecha_inicio)}</td>
                                                    <td>{formatDate(item.fecha_retorno)}</td>
                                                    <td>{item.duracion_dias}</td>
                                                    <td className="text-center">
                                                        <input className="form-check-input" type="checkbox" value="" aria-label="..." checked={item.approved_flag === "Y" ? true : false} disabled={true} />
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr key={"item" + diasTomadosData.fecha_inicio}>
                                            <td>{diasTomadosData.year}</td>
                                            <td>{formatDate(diasTomadosData.fecha_inicio)}</td>
                                            <td>{formatDate(diasTomadosData.fecha_retorno)}</td>
                                            <td>{diasTomadosData.duracion_dias}</td>
                                            <td className="text-center">
                                                {diasTomadosData.approved_flag}
                                                <input className="form-check-input" type="checkbox" value="" aria-label="..." checked={diasTomadosData.approved_flag === "Y" ? true : false} disabled={true} />
                                            </td>
                                        </tr>
                                    )
                                ) : !diasTomadosData ? (
                                    <div>
                                        <strong>Sin Registros</strong>
                                    </div>
                                ) : (
                                    <div>
                                        <strong>Cargando...</strong>
                                    </div>
                                )}
                            </tbody>
                        </table>
                        <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
                            {diasTomadosData && diasTomadosData !== "error" ? (
                                Array.isArray(diasTomadosData) ? (
                                    Children.toArray(Object.keys(diasTomadosPorAno).reverse().map((year, yearIndex) => {
                                        return (
                                            <>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id={"accordion" + yearIndex}>
                                                        <button className={`accordion-button ${yearIndex === 0 ? " " : "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target={"#accordion-item-dias-tomados-" + yearIndex} aria-expanded={yearIndex === 0} aria-controls={"accordion-item-dias-tomados-" + yearIndex}>
                                                            <strong className="text-uppercase">Año</strong> {year}
                                                        </button>
                                                    </h2>
                                                    {Children.toArray(diasTomadosPorAno[year].map((item) => {
                                                        return (

                                                            <div id={"accordion-item-dias-tomados-" + yearIndex} className={`accordion-collapse collapse ${yearIndex === 0 ? "show" : " "}`} aria-labelledby={"accordion" + yearIndex} data-parent="#accordion-table">
                                                                <div className="data-list-box text-uppercase">
                                                                    <ul className="data-list-section--item list-unstyled">
                                                                        <li>
                                                                            <span className="data-list-box__title">Año</span>
                                                                            <span className="data-list-box__text">{item.year}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="data-list-box__title">Fecha inicio</span>
                                                                            <span className="data-list-box__text">{formatDate(item.fecha_inicio)}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="data-list-box__title">Fecha final</span>
                                                                            <span className="data-list-box__text">{formatDate(item.fecha_retorno)}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="data-list-box__title">Días</span>
                                                                            <span className="data-list-box__text">{item.duracion_dias}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="data-list-box__title">Aprobado</span>
                                                                            <span className="data-list-box__text">
                                                                                <input className="form-check-input" type="checkbox" value="" aria-label="..." checked={item.approved_flag ? true : false} disabled={true} />
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                        )
                                                    }))}
                                                </div>
                                            </>
                                        )
                                    }))
                                ) : (
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id={"accordion" + diasTomadosData.emplid}>
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#accordion-item-dias-tomados-" + diasTomadosData.emplid} aria-expanded="false" aria-controls={"accordion-item-dias-tomados-" + diasTomadosData.emplid}>
                                                <strong className="text-uppercase">Año</strong> {diasTomadosData.year}
                                            </button>
                                        </h2>
                                        <div id={"accordion-item-dias-tomados-" + diasTomadosData.emplid} className="accordion-collapse collapse show" aria-labelledby={"accordion" + diasTomadosData.emplid} data-parent="#accordion-table">
                                            <div className="data-list-box text-uppercase">
                                                <ul className="data-list-section--item list-unstyled">
                                                    <li>
                                                        <span className="data-list-box__title">Año</span>
                                                        <span className="data-list-box__text">{diasTomadosData.year}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title">Fecha inicio</span>
                                                        <span className="data-list-box__text">{diasTomadosData.fecha_inicio}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title">Fecha final</span>
                                                        <span className="data-list-box__text">{diasTomadosData.fecha_retorno}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title">Días</span>
                                                        <span className="data-list-box__text">{diasTomadosData.duracion_dias}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title">Aprobado</span>
                                                        <span className="data-list-box__text">
                                                            <input className="form-check-input" type="checkbox" value="" aria-label="..." checked={diasTomadosData.approved_flag === "Y" ? true : false} disabled={true} />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )
                            ) : !diasTomadosData ? (
                                <div>
                                    <strong>Sin Registros</strong>
                                </div>
                            ) : (
                                <div>
                                    <strong>Cargando...</strong>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>) : (
                <div>
                    <strong>Sin Registros</strong>
                </div>
            )}
        </>
    );
};

export default DiasTomados;