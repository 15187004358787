import React from 'react';

const InternalServerErrorIcon = () => (
    <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2_73395)">
            <path d="M123.933 96.261H117.937V23.0167C117.937 21.6862 117.411 20.4102 116.474 19.4694C115.537 18.5285 114.267 18 112.942 18H13.0227C11.6977 18 10.427 18.5285 9.49006 19.4694C8.55314 20.4102 8.02678 21.6862 8.02678 23.0167V96.261H2.03166C1.23665 96.261 0.47421 96.5781 -0.0879425 97.1426C-0.650095 97.7071 -0.965909 98.4727 -0.965909 99.271C-0.965909 104.358 -1.28565 105.502 0.0332794 106.656C2.71072 109.024 6.15711 110.33 9.7254 110.328H116.279C119.847 110.33 123.293 109.024 125.971 106.656C127.27 105.512 126.97 104.348 126.97 99.271C126.97 98.8723 126.891 98.4776 126.738 98.1098C126.585 97.7419 126.361 97.4083 126.078 97.1283C125.795 96.8482 125.46 96.6273 125.092 96.4785C124.724 96.3296 124.33 96.2556 123.933 96.261ZM10.0252 23.0167C10.0252 22.2184 10.341 21.4528 10.9031 20.8883C11.4653 20.3238 12.2277 20.0067 13.0227 20.0067H112.942C113.737 20.0067 114.499 20.3238 115.061 20.8883C115.623 21.4528 115.939 22.2184 115.939 23.0167V96.261H10.0252V23.0167ZM75.462 98.2676L74.2829 101.278H51.6813L50.5023 98.2676H75.462ZM124.622 105.1C122.317 107.138 119.35 108.262 116.279 108.261C0.373006 108.261 8.16667 108.672 4.84937 107.258C3.56799 106.724 2.38304 105.981 1.34222 105.06C0.952532 104.769 1.03247 104.89 1.03247 99.271C1.03247 99.0049 1.13774 98.7497 1.32512 98.5615C1.51251 98.3734 1.76665 98.2676 2.03166 98.2676H48.3041C50.1226 102.833 50.0826 103.284 50.9919 103.284C76.8808 103.284 75.5519 103.565 75.9716 102.642L77.6602 98.2676H123.933C124.198 98.2676 124.452 98.3734 124.639 98.5615C124.826 98.7497 124.932 99.0049 124.932 99.271C124.932 104.699 125.012 104.769 124.622 105.12V105.1Z" fill="#9D9D9C"/>
            <path d="M110.944 90.2416V26.0274C110.944 25.7613 110.838 25.5061 110.651 25.318C110.464 25.1298 110.209 25.0241 109.944 25.0241H16.0208C15.7558 25.0241 15.5017 25.1298 15.3143 25.318C15.1269 25.5061 15.0216 25.7613 15.0216 26.0274V90.2416C15.0216 90.5077 15.1269 90.7629 15.3143 90.951C15.5017 91.1392 15.7558 91.2449 16.0208 91.2449H109.944C110.209 91.2449 110.464 91.1392 110.651 90.951C110.838 90.7629 110.944 90.5077 110.944 90.2416ZM108.945 89.2382H17.02V27.0308H108.945V89.2382Z" fill="#9D9D9C"/>
            <path d="M33.7256 54.8444L39.0013 49.5468L44.277 54.8444C44.4678 55.036 44.7266 55.1437 44.9964 55.1437C45.2662 55.1437 45.525 55.036 45.7158 54.8444C45.9066 54.6528 46.0138 54.393 46.0138 54.122C46.0138 53.8511 45.9066 53.5912 45.7158 53.3996L40.4401 48.102L45.7158 42.8043C45.9066 42.6127 46.0138 42.3528 46.0138 42.0819C46.0138 41.8109 45.9066 41.5511 45.7158 41.3595C45.525 41.1679 45.2662 41.0602 44.9964 41.0602C44.7266 41.0602 44.4678 41.1679 44.277 41.3595L39.0013 46.6571L33.7256 41.3595C33.5348 41.1679 33.276 41.0602 33.0061 41.0602C32.7363 41.0602 32.4775 41.1679 32.2867 41.3595C32.0959 41.5511 31.9887 41.8109 31.9887 42.0819C31.9887 42.3528 32.0959 42.6127 32.2867 42.8043L37.5624 48.102L32.2867 53.3996C32.0959 53.5912 31.9887 53.8511 31.9887 54.122C31.9887 54.393 32.0959 54.6528 32.2867 54.8444C32.4775 55.036 32.7363 55.1437 33.0061 55.1437C33.276 55.1437 33.5348 55.036 33.7256 54.8444Z" fill="#9D9D9C"/>
            <path d="M81.6866 54.8444L86.9623 49.5468L92.238 54.8444C92.4288 55.036 92.6876 55.1437 92.9574 55.1437C93.2272 55.1437 93.486 55.036 93.6768 54.8444C93.8676 54.6528 93.9748 54.393 93.9748 54.122C93.9748 53.8511 93.8676 53.5912 93.6768 53.3996L88.4011 48.102L93.6768 42.8043C93.8676 42.6127 93.9748 42.3528 93.9748 42.0819C93.9748 41.8109 93.8676 41.5511 93.6768 41.3595C93.486 41.1679 93.2272 41.0602 92.9574 41.0602C92.6876 41.0602 92.4288 41.1679 92.238 41.3595L86.9623 46.6571L81.6866 41.3595C81.4958 41.1679 81.237 41.0602 80.9671 41.0602C80.6973 41.0602 80.4385 41.1679 80.2477 41.3595C80.0569 41.5511 79.9497 41.8109 79.9497 42.0819C79.9497 42.3528 80.0569 42.6127 80.2477 42.8043L85.5234 48.102L80.2477 53.3996C80.0569 53.5912 79.9497 53.8511 79.9497 54.122C79.9497 54.393 80.0569 54.6528 80.2477 54.8444C80.4385 55.036 80.6973 55.1437 80.9671 55.1437C81.237 55.1437 81.4958 55.036 81.6866 54.8444Z" fill="#9D9D9C"/>
            <path d="M46.9952 72.1801H78.9692C79.2342 72.1801 79.4884 72.0744 79.6758 71.8862C79.8631 71.6981 79.9684 71.4429 79.9684 71.1768C79.9684 70.9107 79.8631 70.6554 79.6758 70.4673C79.4884 70.2791 79.2342 70.1734 78.9692 70.1734H46.9952C46.7302 70.1734 46.4761 70.2791 46.2887 70.4673C46.1013 70.6554 45.996 70.9107 45.996 71.1768C45.996 71.4429 46.1013 71.6981 46.2887 71.8862C46.4761 72.0744 46.7302 72.1801 46.9952 72.1801Z" fill="#9D9D9C"/>
        </g>
        <defs>
            <clipPath id="clip0_2_73395">
            <rect width="128" height="128" fill="white"/>
        </clipPath>
        </defs>
    </svg>

);

export default InternalServerErrorIcon;