import React from 'react';
import { InterestSitesContent } from './interestSitesContent';
import { InterestSitesProvider } from './context/interestSiteContext';

export const InterestSitesPage: React.FC = () => {
    return (
        <InterestSitesProvider>
            <InterestSitesContent />
        </InterestSitesProvider>
    );
};
