import {
    InternalServerErrorIcon,
    NotFoundIcon,
    NotAuthorizedIcon,
    TimeoutIcon,
    MaintenanceIcon
} from './assets/index'

export default {
    "notFound": {
        "icon": NotFoundIcon,
        "title": "Lo sentimos, no pudimos encontrar esta página",
        "message": "Intenta verificar la URL o regresa a la página anterior."
    },
    "internalServerError": {
        "icon": InternalServerErrorIcon,
        "title": "Lo sentimos, el servicio no se encuentra disponible en este momento.",
        "message": "Vuelve a intentarlo en unos minutos."
    },
    "notAuthorized": {
        "icon": NotAuthorizedIcon,
        "title": "Lo sentimos, esta página requiere otros permisos.",
        "message": "Contacta a tu referente de RRHH de tu tienda o local."
    },
    "timeout": {
        "icon": TimeoutIcon,
        "title": "Sin respuesta del servidor",
        "message": "El sistema tardo demasiado en responder."
    },
    "Maintenance": {
        "icon": MaintenanceIcon,
        "title": "Pagina en mantenimiento",
        "message": "Vuelve a intentarlo en unos minutos."
    }
};