import React, { FC, useState, useEffect, SyntheticEvent, Children } from "react";
import { pipe } from "fp-ts/lib/function";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { asignation, AssignByJobCodeProps, directReports, indicador, kpi } from "../../../utilities/interfaces";
import KpiModal from "../kpiModal";
import ModalWarning from "../../../commons/modals/modalWarning";

interface HandleAssignationProps {
    kpiList: kpi[];
    keepSameModel?: string;
    modelId: string;
    emplid?: string;
    indicators?: indicador[];
    setAssignations: React.Dispatch<React.SetStateAction<asignation[]>>;
}

const handleAssignations = ({ kpiList, keepSameModel, modelId, emplid, indicators, setAssignations }: HandleAssignationProps) => {
    setAssignations([
        {
            emplid: emplid !== undefined ? emplid : "",
            modelId: modelId,
            keepSameModel: keepSameModel === "Y",
            allDependencies: false,
            asignationIndicators: indicators === undefined ?
                []
            :
                indicators.map((ind) => {
                    return {
                        indicatorCode: ind.indicatorCode,
                        indicatorWeight: ind.indicatorWeight,
                        indicatorType: ind.indicatorType,
                        kpi: ind.indicatorType !== "KPI" ?
                            []
                        : kpiList.map((k) => {
                            return {
                                kpiId: k.id,
                                kpiName: k.name,
                                kpiWeight: k.weight
                            }
                        })
                    }
                })
        }
    ]);
};

const getString = <A,>(as: A) => typeof as === "string" ? as : "";

const AssignByJobcode: FC<AssignByJobCodeProps> = ({ directReportsData, referenceKpi, contactEmail, models, period }) => {
    const [directReports, setDirectReports] = useState<directReports>(directReportsData);
    const [modelSelected, setModelSelected] = useState("");
    const [hasKpi, setHasKpi] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [kpiList, setKpiList] = useState<kpi[]>([]);
    const [isSelectingKpi, setIsSelectingKpi] = useState<boolean>(false);
    const [keepSameModelList, setKeepSameModelList] = useState<string[]>(A.map(() => "")(directReports));
    const [accWeight, setAccWeight] = useState<number>(0);
    const [assignations, setAssignations] = useState<asignation[]>([]);
    const [validPostResponse, setValidPostResponse] = useState<boolean>(false);
    const [selectedList, setSelectedList] = useState<boolean[]>(pipe(directReports, A.mapWithIndex((i) => i === 0)));

    useEffect(() => {
        selectedList.forEach((s, i) => s && setSelectedIndex(i));
    }, [selectedList]);

    useEffect(() => {
        setKpiList([]),
        setModelSelected(""),
        setIsSelectingKpi(false)
    }, [selectedIndex]);

    useEffect(() => {
        setIsSelectingKpi(false);
    }, [hasKpi]);

    useEffect(() => {
        pipe(kpiList, A.reduce(0, (acc, cur) => acc + cur.weight), setAccWeight);
    }, [kpiList]);

    useEffect(() => {
        assignations.length > 0 && document.getElementById("modal-warning-btn")?.click();
    }, [assignations]);

    useEffect(() => {
        validPostResponse && pipe(
            directReports,
            A.deleteAt(selectedIndex),
            O.getOrElse(() => directReports),
            setDirectReports
        )
    }, [validPostResponse]);

    useEffect(() => {
        pipe(directReports, A.mapWithIndex((i) => i === 0), setSelectedList),
        pipe(directReports, A.map(() => ""), setKeepSameModelList),
        setAssignations([])
    }, [directReports]);

    return (
        directReports.length > 0 ?
            <> 
                <div className="row d-none d-md-flex">
                    <div className="col-12 col-xl-6 d-flex">
                        <div className="border border-primary rounded-4 p-4 align-items-stretch w-100">
                            <div className="box-scroll box-scroll" style={{ maxHeight: "470px" }}>
                                {Children.toArray(directReports.map((r, i) => {
                                    return (
                                        <a
                                            className={`text-decoration-none link-list d-block p-3 border-bottom border-primary ${selectedList[i] ? "selected" : ""}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                pipe(selectedList, A.mapWithIndex((idx) => idx === i), setSelectedList);
                                            }}
                                            href="#"
                                        >
                                            <div className="row">
                                                <div className="col-10">
                                                    <div className="data-list-box">
                                                        <ul className="list-unstyled">
                                                            <li>
                                                                <span className="data-list-box__title col-6">Nombre</span>
                                                                <span className="data-list-box__text">{r.name}</span>
                                                            </li>
                                                            <li>
                                                                <span className="data-list-box__title col-6">Cargo</span>
                                                                <span className="data-list-box__text">{r.jobCodeDescr}</span>
                                                            </li>
                                                            <li>
                                                                <span className="data-list-box__title col-6">Estado</span>
                                                                <span className={`data-list-box__text color-${r.newHired ? "primary" : "orange"}`}>
                                                                    {!r.newHired ? "Cambio de puesto" : "Nuevo ingreso"}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-1 d-flex align-items-center">
                                                    <i className="fa fa-chevron-right color-primary fa-lg" aria-hidden="true" />
                                                </div>
                                            </div>
                                        </a>
                                    )
                                }))}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-6 d-flex mt-4 mt-xl-0">
                        <div className="border border-primary rounded-4 p-4 w-100">
                            {!(hasKpi && keepSameModelList.at(selectedIndex) !== "Y" && isSelectingKpi) && <h3 className="h4 mb-3">Seleccionar Modelo</h3>}
                            {hasKpi && keepSameModelList.at(selectedIndex) !== "Y" && isSelectingKpi &&
                                <>
                                    <div className="d-flex justify-content-between align-items-center mb-2">
                                        <h3 className="h4 mb-0">Definir KPI's</h3>
                                        <div>
                                            <div className="btn-group me-3">
                                                <button className="btn btn-outline-orange btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Copiar KPI</button>
                                                <ul className="dropdown-menu border-orange dropdown-menu-hover-orange">
                                                    {referenceKpi.some((rk) => rk?.emplid !== "") && Children.toArray(referenceKpi?.map((rk) => {
                                                        return (
                                                            <li>
                                                                <a
                                                                    className="dropdown-item"
                                                                    href="#"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        pipe(
                                                                            rk?.kpi,
                                                                            A.mapWithIndex((i, k) => {
                                                                                return {
                                                                                    id: `${i}`,
                                                                                    name: k.kpiName,
                                                                                    weight: parseInt(k.kpiWeight),
                                                                                    applySame: false
                                                                                }
                                                                            }),
                                                                            setKpiList,
                                                                        );
                                                                    }}
                                                                >
                                                                    {rk?.name}
                                                                </a>
                                                            </li>
                                                        )
                                                    }))}
                                                </ul>
                                            </div>
                                            <a href="#" data-bs-toggle="modal" data-bs-target="#kpiModal" onClick={(e) => e.preventDefault()}>
                                                <p className="btn btn-link order-md-first mt-3 text-decoration-none disabled fw-bolder">{accWeight === 0 ? "Crear " : "Editar "}</p>
                                                <i className={`fa fa-${accWeight === 0 ? "plus-square-o fa-lg" : "pencil fa-fw me-2"}`} aria-hidden="true" />
                                            </a>
                                            
                                        </div>
                                    </div>
                                    <div className="box-scroll box-scroll">
                                        <div className="table-responsive">
                                            <table className="table table-section table-borderless table-th-border align-middle table-even d-none d-md-table">
                                                <thead>
                                                    <tr>
                                                        <th className="col-9">Nombre KPI</th>
                                                        <th>Peso</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Children.toArray(kpiList.map((k) => {
                                                        return k && k.name !== "" && (
                                                            <tr>
                                                                <td className="col-9">{k.name}</td>
                                                                <td>{`${k.weight}%`}</td>
                                                            </tr>
                                                        )
                                                    }))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <small className="mt-5 pt-3 d-block border-top">Puedes ingresar hasta 5 KPI's. Si necesitas incorporar otro de manera excepcional, favor comunícate con {Children.toArray(contactEmail.split(", ").map((mail, index, arr) => {
                                        return (
                                            <><a href={`mailto:${mail}`}>{mail}</a>{index < arr.length -1 ? "," : ""}</>
                                        )
                                    }))}.</small>
                                </>
                            }
                            {!isSelectingKpi && !directReports.at(selectedIndex)?.newHired &&
                                <>
                                    <div className="border border-primary rounded-4 p-4 mb-4">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                id="keepSameModel"
                                                checked={keepSameModelList.at(selectedIndex) === "Y"}
                                                onClick={() => {
                                                    pipe(keepSameModelList, A.updateAt(selectedIndex, "Y"), O.getOrElse(() => keepSameModelList), setKeepSameModelList);
                                                    pipe(directReports.at(selectedIndex)?.models.modelId, getString, setModelSelected);
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="keepSameModel">{`Mantener ${directReports.at(selectedIndex)?.models.modelDescr}`}</label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                id="changeModel"
                                                checked={keepSameModelList.at(selectedIndex) === "N"}
                                                onClick={() => {
                                                    pipe(keepSameModelList, A.updateAt(selectedIndex, "N"), O.getOrElse(() => keepSameModelList), setKeepSameModelList);
                                                    setModelSelected("");
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="changeModel">
                                                Cambiar Modelo
                                            </label>
                                        </div>
                                    </div>
                                </>
                            }
                            {(!isSelectingKpi && (directReports.at(selectedIndex)?.newHired || keepSameModelList.at(selectedIndex) === "N")) &&
                                <>
                                    {Children.toArray(models.map((model) => {
                                        return (
                                            <label className={`btn btn-lg-2 fs-6 hover-white text-uppercase d-flex justify-content-between mb-4 align-items-center${modelSelected === model.modelId ? " btn-degradado-2" : ""}`}>
                                                <div>
                                                    <input
                                                        className="hidden-checkbox"
                                                        type="checkbox"
                                                        name={model.modelId}
                                                        value={model.modelId}
                                                        onClick={(e: SyntheticEvent) => {
                                                            e.preventDefault();
                                                            setModelSelected(model.modelId);
                                                            setHasKpi(model.modelContainsKpi);
                                                        }} 
                                                    />
                                                    <span className="align-self-center me-5 color-primary d-block mb-2 d-md-inline-block mb-md-0">{model.modelDescr}</span>
                                                    <span className="fw-normal">
                                                        {Children.toArray(model.indicators.sort((a,b)=> parseInt(b.indicatorWeight) - parseInt(a.indicatorWeight)).map((indicator, idx, indArr) => {
                                                            return (
                                                                <>
                                                                    <span className="color-primary">{indicator.indicatorCode}</span>
                                                                    <span className="color-orange">{" = "}</span>
                                                                    <span className="color-normal">{`${indicator.indicatorWeight}%`}</span>
                                                                    {idx < indArr.length - 1 && <span className="color-orange">{" + "}</span>}
                                                                </>
                                                            )
                                                        }))}
                                                    </span>
                                                </div>
                                                <i className="fa fa-chevron-right fa-2x" aria-hidden="true" />
                                            </label>
                                        )
                                    }))}
                                </>
                            }
                            {!isSelectingKpi && !directReports.at(selectedIndex)?.newHired && keepSameModelList.at(selectedIndex) === "Y" &&
                                <label className={`btn btn-lg-2 fs-6 hover-white text-uppercase d-flex justify-content-between mb-4 align-items-center`}>
                                    <div>
                                        <input
                                            className="hidden-checkbox"
                                            type="checkbox"
                                        />
                                        <span className="align-self-center me-5 color-primary d-block mb-2 d-md-inline-block mb-md-0">{directReports.find((_, i) => selectedIndex === i)?.models?.modelDescr}</span>
                                        <span className="fw-normal">
                                            {Children.toArray(directReports.find((_, i) => selectedIndex === i)?.models?.indicators.sort((a, b)=> parseInt(b.indicatorWeight) - parseInt(a.indicatorWeight)).map((indicator, idx, indArr) => {
                                                return (
                                                    <>
                                                        <span className="color-primary">{indicator.indicatorCode}</span>
                                                        <span className="color-orange">{" = "}</span>
                                                        <span className="color-normal">{`${indicator.indicatorWeight}%`}</span>
                                                        {idx < indArr.length - 1 && <span className="color-orange">{" + "}</span>}
                                                    </>
                                                )
                                            }))}
                                        </span>
                                    </div>
                                    <i className="fa fa-chevron-right fa-2x" aria-hidden="true" />
                                </label>
                            }
                            {!isSelectingKpi && modelSelected !== "" && hasKpi &&
                                <div className="d-flex mt-4">
                                    <i className="fa fa-exclamation-triangle fa-lg me-3 mt-1 color-red" aria-hidden="true" />
                                    <p className="m-0">{`A continuación, por favor cargar los KPI para el ${models.find((m) => m.modelId === modelSelected)?.modelDescr}.`}</p>
                                </div>
                            }
                            {modelSelected !== "" && 
                                <div className="row mt-5">
                                    <div className={isSelectingKpi ? "d-grid d-md-flex justify-content-md-between gap-2 flex-nowrap" : "d-flex justify-content-center"}>
                                        {(hasKpi && keepSameModelList.at(selectedIndex) !== "Y") && !isSelectingKpi &&
                                            <button type="button" className={`btn btn-primary`} onClick={() => setIsSelectingKpi(true)}>
                                                {"Definir KPI "}
                                                <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                                            </button>
                                        }
                                        {((!hasKpi || keepSameModelList.at(selectedIndex) === "Y") || isSelectingKpi) &&
                                            <button
                                                type="button"
                                                disabled={isSelectingKpi && accWeight !== 100}
                                                className={`btn btn-degradado`}
                                                onClick={() => handleAssignations({
                                                    kpiList: kpiList,
                                                    setAssignations: setAssignations,
                                                    keepSameModel: keepSameModelList.at(selectedIndex),
                                                    emplid: directReports.at(selectedIndex)?.emplid,
                                                    modelId: modelSelected,
                                                    indicators: models.find((m) => m.modelId === modelSelected)?.indicators
                                                })}>
                                                    {"Finalizar "}
                                                <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                                            </button>
                                        }
                                        {isSelectingKpi &&
                                            <button className="btn btn-link order-md-first" onClick={() => setIsSelectingKpi(false)}>
                                                Volver
                                            </button>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="accordion accordion-flush accordion-light accordion-table d-md-none d-lg-none">
                    {Children.toArray(directReports.map((r, i) => {
                        return (
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="accordion">
                                    <button className={`accordion-button${i !== selectedIndex ? " collapsed" : ""}`} onClick={() => pipe(selectedList, A.mapWithIndex((idx) => idx === i), setSelectedList)} type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-chad-${i}`} aria-expanded={`${i === selectedIndex}`} aria-controls={`accordion-chad-${i}`}>
                                        <strong>Nombre</strong>{` ${r.name}`}
                                    </button>
                                </h2>
                                <div id={`accordion-chad-${i}`} className={`accordion-collapse collapse${i === selectedIndex ? " show" : ""}`} aria-labelledby="accordion">
                                    <div className="accordion-body p-0">
                                        <div className="data-list-box">
                                            <ul className="data-list-section--item list-unstyled">
                                                <li className="d-block">
                                                    <span className="data-list-box__title d-block col-12">Cargo</span>
                                                    <span className="data-list-box__text d-block col-12">{r.jobCodeDescr}</span>
                                                </li>
                                                <li className="d-block">
                                                    <span className="data-list-box__title d-block col-12">Estado</span>
                                                    <span className={`data-list-box__text d-block col-12 color-${r.newHired ? "primary" : "orange"}`}>{r.newHired ? "Cambio de puesto" : "Nuevo ingreso"}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="mt-4 mb-5 px-3">
                                            {!(hasKpi && keepSameModelList.at(selectedIndex) !== "Y" && isSelectingKpi) && <h3 className="h4 mb-3">Seleccionar Modelo</h3>}
                                            {hasKpi && keepSameModelList.at(selectedIndex) !== "Y" && isSelectingKpi &&
                                                <>
                                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                                        <h3 className="h6 mb-0">Definir KPI's</h3>
                                                        <div>
                                                            <div className="btn-group me-3">
                                                                <button className="btn btn-outline-orange btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Copiar</button>
                                                                <ul className="dropdown-menu border-orange dropdown-menu-hover-orange">
                                                                    {referenceKpi.some((rk) => rk?.emplid !== "") && Children.toArray(referenceKpi?.map((rk) => {
                                                                        return (
                                                                            <li>
                                                                                <a
                                                                                    className="dropdown-item"
                                                                                    href="#"
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        pipe(
                                                                                            rk?.kpi,
                                                                                            A.mapWithIndex((i, k) => {
                                                                                                return {
                                                                                                    id: `${i}`,
                                                                                                    name: k.kpiName,
                                                                                                    weight: parseInt(k.kpiWeight),
                                                                                                    applySame: false
                                                                                                }
                                                                                            }),
                                                                                            setKpiList,
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {rk?.name}
                                                                                </a>
                                                                            </li>
                                                                        )
                                                                    }))}
                                                                </ul>
                                                            </div>
                                                            <a href="#" data-bs-toggle="modal" data-bs-target="#kpiModal" onClick={(e) => e.preventDefault()}>
                                                                <p className="btn btn-link order-md-first mt-3 text-decoration-none disabled fw-bolder">{accWeight === 0 ? "Crear " : "Editar "}</p>
                                                                <i className={`fa fa-${accWeight === 0 ? "plus-square-o fa-lg" : "pencil fa-fw me-2"}`} aria-hidden="true" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {Children.toArray(kpiList.map((k) => {
                                                        return (
                                                            <div className="data-list-box data-list-box-even">
                                                                <ul className="data-list-section--item list-unstyled">
                                                                    <li>
                                                                        <span className="data-list-box__title">Nombre KPI</span>
                                                                        <span className="data-list-box__text row">
                                                                            <div className="col-11">
                                                                                {k.name}
                                                                            </div>
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="data-list-box__title">Peso</span>
                                                                        <span className="data-list-box__text row">
                                                                            <div className="col-11">
                                                                                {`${k.weight}%`}
                                                                            </div>
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="data-list-box__text" />
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )
                                                    }))}
                                                    <small className="mt-5 pt-3 d-block border-top">Puedes ingresar hasta 5 KPI's. Si necesitas incorporar otro de manera excepcional, favor comunícate con {Children.toArray(contactEmail.split(", ").map((mail, index, arr) => {
                                                        return (
                                                            <><a href={`mailto:${mail}`}>{mail}</a>{index < arr.length - 1 ? "," : ""}</>
                                                        )
                                                    }))}.</small>
                                                </>
                                            }
                                            {!isSelectingKpi && !directReports.at(selectedIndex)?.newHired &&
                                                <>
                                                    <div className="border border-primary rounded-4 p-4 mb-4">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                id="keepSameModel"
                                                                checked={keepSameModelList.at(selectedIndex) === "Y"}
                                                                onClick={() => {
                                                                    pipe(keepSameModelList, A.updateAt(selectedIndex, "Y"), O.getOrElse(() => keepSameModelList), setKeepSameModelList);
                                                                    pipe(directReports.at(selectedIndex)?.models.modelId, getString, setModelSelected);
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="keepSameModel">{`Mantener ${directReports.at(selectedIndex)?.models.modelDescr}`}</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                id="changeModel"
                                                                checked={keepSameModelList.at(selectedIndex) === "N"}
                                                                onClick={() => {
                                                                    pipe(keepSameModelList, A.updateAt(selectedIndex, "N"), O.getOrElse(() => keepSameModelList), setKeepSameModelList);
                                                                    setModelSelected("");
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="changeModel">
                                                                Cambiar Modelo
                                                            </label>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {(!isSelectingKpi && (directReports.at(selectedIndex)?.newHired || keepSameModelList.at(selectedIndex) === "N")) &&
                                                <>
                                                    {Children.toArray(models.map((model) => {
                                                        return (
                                                            <label className={`btn btn-lg-2 fs-6 hover-white text-uppercase d-flex justify-content-between mb-4 align-items-center${modelSelected === model.modelId ? " btn-degradado-2" : ""}`}>
                                                                <div>
                                                                    <input
                                                                        className="hidden-checkbox"
                                                                        type="checkbox"
                                                                        name={model.modelId}
                                                                        value={model.modelId}
                                                                        onClick={(e: SyntheticEvent) => {
                                                                            e.preventDefault();
                                                                            setModelSelected(model.modelId);
                                                                            setHasKpi(model.modelContainsKpi);
                                                                        }} 
                                                                    />
                                                                    <span className="align-self-center me-5 color-primary d-block mb-2 d-md-inline-block mb-md-0">{model.modelDescr}</span>
                                                                    <span className="fw-normal">
                                                                        {Children.toArray(model.indicators.sort((a,b)=> parseInt(b.indicatorWeight) - parseInt(a.indicatorWeight)).map((indicator, idx, indArr) => {
                                                                            return (
                                                                                <>
                                                                                    <span className="color-primary">{indicator.indicatorCode}</span>
                                                                                    <span className="color-orange">{" = "}</span>
                                                                                    <span className="color-normal">{`${indicator.indicatorWeight}%`}</span>
                                                                                    {idx < indArr.length - 1 && <span className="color-orange">{" + "}</span>}
                                                                                </>
                                                                            )
                                                                        }))}
                                                                    </span>
                                                                </div>
                                                                <i className="fa fa-chevron-right fa-2x" aria-hidden="true" />
                                                            </label>
                                                        )
                                                    }))}
                                                </>
                                            }
                                            {!isSelectingKpi && !directReports.at(selectedIndex)?.newHired && keepSameModelList.at(selectedIndex) === "Y" &&
                                                <label className={`btn btn-lg-2 fs-6 hover-white text-uppercase d-flex justify-content-between mb-4 align-items-center`}>
                                                    <div>
                                                        <input
                                                            className="hidden-checkbox"
                                                            type="checkbox"
                                                        />
                                                        <span className="align-self-center me-5 color-primary d-block mb-2 d-md-inline-block mb-md-0">{directReports.find((_, i) => selectedIndex === i)?.models?.modelDescr}</span>
                                                        <span className="fw-normal">
                                                            {Children.toArray(directReports.find((_, i) => selectedIndex === i)?.models?.indicators.sort((a, b)=> parseInt(b.indicatorWeight) - parseInt(a.indicatorWeight)).map((indicator, idx, indArr) => {
                                                                return (
                                                                    <>
                                                                        <span className="color-primary">{indicator.indicatorCode}</span>
                                                                        <span className="color-orange">{" = "}</span>
                                                                        <span className="color-normal">{`${indicator.indicatorWeight}%`}</span>
                                                                        {idx < indArr.length - 1 && <span className="color-orange">{" + "}</span>}
                                                                    </>
                                                                )
                                                            }))}
                                                        </span>
                                                    </div>
                                                    <i className="fa fa-chevron-right fa-2x" aria-hidden="true" />
                                                </label>
                                            }
                                            {!isSelectingKpi && modelSelected !== "" && hasKpi &&
                                                <div className="d-flex mt-4">
                                                    <i className="fa fa-exclamation-triangle fa-lg me-3 mt-1 color-red" aria-hidden="true" />
                                                    <p className="m-0">{`A continuación, por favor cargar los KPI para el ${models.find((m) => m.modelId === modelSelected)?.modelDescr}.`}</p>
                                                </div>
                                            }
                                            {modelSelected !== "" && 
                                                <div className="row mt-5">
                                                    <div className={isSelectingKpi ? "d-grid d-md-flex justify-content-md-between gap-2 flex-nowrap" : "d-flex justify-content-center"}>
                                                        {(hasKpi && keepSameModelList.at(selectedIndex) !== "Y") && !isSelectingKpi &&
                                                            <button type="button" className={`btn btn-primary`} onClick={() => setIsSelectingKpi(true)}>
                                                                {"Definir KPI "}
                                                                <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                                                            </button>
                                                        }
                                                        {((!hasKpi || keepSameModelList.at(selectedIndex) === "Y") || isSelectingKpi) &&
                                                            <button
                                                                type="button"
                                                                disabled={isSelectingKpi && accWeight !== 100}
                                                                className={`btn btn-degradado`}
                                                                onClick={() => handleAssignations({
                                                                    kpiList: kpiList,
                                                                    setAssignations: setAssignations,
                                                                    keepSameModel: keepSameModelList.at(selectedIndex),
                                                                    emplid: directReports.at(selectedIndex)?.emplid,
                                                                    modelId: modelSelected,
                                                                    indicators: models.find((m) => m.modelId === modelSelected)?.indicators
                                                                })}>
                                                                    {"Finalizar "}
                                                                <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                                                            </button>
                                                        }
                                                        {isSelectingKpi &&
                                                            <button className="btn btn-link order-md-first" onClick={() => setIsSelectingKpi(false)}>
                                                                Volver
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }))}
                </div>
                {hasKpi && keepSameModelList.at(selectedIndex) !== "Y" && isSelectingKpi && <KpiModal contactEmail={contactEmail} kpiList={kpiList} setKpiList={setKpiList} nameToDiplay={directReports.at(selectedIndex)?.name} />}
                {<ModalWarning asignations={assignations} period={period} setValidPostResponse={setValidPostResponse} />}
            </>
        :
            <div className="row">
                <div className="col-12 col-xl-7 d-flex">
                    <div className="border border-primary rounded-4 p-4 align-items-stretch w-100">
                        <div className="bg-light p-3 fw-bold text-center">No existen modelos que asignar</div>
                    </div>
                </div>
            </div>
    );
};
    
export default AssignByJobcode;
