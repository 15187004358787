import React from 'react'
import { Link } from 'react-router-dom';
import SallaryReceiptsTable from './receipts-table-uy';

const RecibosUY = () => {


    return (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home color-orange" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                       Recibos de sueldo
                    </li>
                </ol>
            </nav>
            <section className="noticias mb-3">
                <h1>Recibos de sueldo </h1>
                <div className="col-12 position-relative mb-0">
                    {/* <a className="btn btn-degradado btn-absolute" href="#">Ver más <i className="fa fa-chevron-right" aria-hidden="true"></i></a> */}
                </div>
            </section>
            <div>
                <div className="row">
                <SallaryReceiptsTable></SallaryReceiptsTable>
                </div>
            </div>

        </>
    )
}

export default RecibosUY