import React , {useState, useEffect } from 'react';
import VacacionesService from "../../services/solicitud-vacaciones-uy/vacaciones-uy-service";
import Auth from '@aws-amplify/auth';
import { RiErrorWarningLine } from 'react-icons/ri';
import ProfileImage from '../FotoPerfil/profile-image';


const SummaryVacacionesUy = () => {
    const [data, setData]= useState({});
    const [fetched, setFetched] = useState(false);
    const [fullname,setFullname]=useState("");
    const [loadingSummary, setLoadingSummary]=useState(true);

    useEffect(() => {

        Auth.currentUserInfo().then(user => {
            setFullname(user.attributes.given_name + " " + user.attributes.family_name);
        }).catch(err => console.log(err))

    }, [fullname]);

    const getDays = async () => {
        try {
        setLoadingSummary(true);
    
          const response = await VacacionesService.getDays();
    
          if (
            response !== null &&
            response !== undefined &&
            (response?.statusCode === 200 || response?.status === 200)
          ) {
            setData(response?.data);
            setFetched(true)
          } else {
            setFetched(false)
          }
    
          setLoadingSummary(false);
        } catch (error) {
            setLoadingSummary(false);
            setFetched(false)
        }
      };

      useEffect(() => {
        getDays()
      },[])

    return (
        
        <div className="row space-around">
            <div className="holiday-container p-0">
                <div className="card-box summary-card">
                
                {!loadingSummary && fetched ? 
                <div>
                <div className="main-image">
                       <ProfileImage />                   
                        </div>
                    <div className='detail-user'>
                        <div className="title">{fullname}</div>
                        <div className="subtitle fw-bold">Solicitud de vacaciones</div>
                    </div>
                    <div className="datas-group-container">
                    <div className=" datas-group">
                        <div className="data-group">
                            <div className="days"><span className='ic-circle ic-circle-primary'>
                                {data?.diasPendientes}
                                </span></div><div className="data-text">Pendiente de aprobación</div>
                        </div>
                        <div className="data-group">
                            <div className="days"><span className='ic-circle ic-circle-primary'>
                                {data?.diasDisponibles}
                                </span></div><div className="data-text">Días disponibles</div>
                        </div>
                    </div>
                    </div>
                    </div>
            : !loadingSummary ? <div className="error-container-lb text-def"><div><RiErrorWarningLine className="color-primary error-icon fs-5"/></div><div>Se ha producido un error. Vuelva a intentarlo</div></div> : <div className="full-spinner"><div className="spinner-border text-primary"></div></div>}
                </div>
            </div>
        </div>

    )
}

export default SummaryVacacionesUy
