import Auth from "@aws-amplify/auth";
import { urlsAws } from "../resources/foo/api-endpoints";
import miEquipoService from "./mi-equipo-service";
const moduloDtService = {
    firstLogin: (emplid, setData, setMsj, msjf) => {
        miEquipoService.getColaborador([emplid], setData, setMsj, msjf);
    },

    validateRut: (emplid, user, setDatos, setMsj, setRut) => {
        Auth.currentSession().then((auth) => {
            const head = { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() };
            const requestOptions = {
                method: "GET",
                headers: head,
            };
            fetch(`${urlsAws.validateRUT}?user=${user}&rut=${emplid}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data != undefined) {
                        if (data.valid === "1" && data.name !== "") {
                            caches.open("valida-rut-dt-service").then((cache) => {
                                cache.put("user_rut" + emplid, new Response(JSON.stringify(data)));
                            });
                            setDatos(data);
                            setMsj(true);
                            setRut(emplid);
                        } else {
                            setMsj(2);
                            setDatos(2);
                            setRut(0);
                        }
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    caches.match("user_rut" + emplid).then((response) => {
                        if (response !== undefined) {
                            response.json().then((data) => {
                                setDatos(data);
                                setMsj(true);
                                setRut(emplid);
                            });
                        } else {
                            setMsj(2);
                            setDatos(2);
                            setRut(0);
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },

    auditoriaDt: (rut, user, descripcion) => {
        Auth.currentSession().then((auth) => {
            const head = { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() };
            const requestOptions = {
                method: "POST",
                headers: head,
                body: JSON.stringify({ rut: rut, user: user, descripcion: descripcion }),
            };
            fetch(`${urlsAws.auditoria}`, requestOptions)
                .catch((error) => {
                    console.log("error", error)
                });
        }).catch((err) => console.log(err));
    },
};
export default moduloDtService;
