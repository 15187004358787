import React, { FC, Children } from "react";
import { BannerProps, DefaultBannerProps, BannerComponentProps } from "./Helpers/interfaces/banner";
import { Link } from "react-router-dom";
import useResponsiveBreakpoint from "./hooks/useResponsiveBreakpoint";

const Banner: FC<BannerComponentProps> = ({ id, size, data: dataToDisplay }: BannerComponentProps) => {
  const breakpoint = useResponsiveBreakpoint();
  return (
    <div id={"banner-" + id} className={`carousel carousel-dark slide w-100`} data-bs-ride="carousel">
      <div className={`carousel-indicators`} style={{ zIndex: 3 }}>
        {Children.toArray(
          dataToDisplay.map((_: BannerProps, index: number) => (
            <button
              key={"carousel-indicator-" + index}
              type="button"
              data-bs-target={"#banner-" + id}
              data-bs-slide-to={index}
              aria-label={"Slide " + (index + 1)}
              className={index === 0 ? "active" : undefined}
              aria-current={index === 0 ? "true" : undefined}
            />
          ))
        )}
      </div>
      <div className="carousel-inner">
        {
          Children.toArray(dataToDisplay.map((item: BannerProps, itemIndex: number) =>
            React.isValidElement(item) ? item
              : renderBannerItem(item as DefaultBannerProps, size, itemIndex, breakpoint)
          ))}
      </div>
      {
        dataToDisplay.length > 1 && <>
          <button className={`carousel-control-prev h-100 ms-2 ${size === "small" ? breakpoint === 'sm' ? "d-none" : "d-block" : "d-block"} `}
            type="button" data-bs-target={"#banner-" + id} data-bs-slide="prev">
            <i className="fa fa-chevron-left color-dark" aria-hidden="true"></i>
            <span className="visually-hidden">Anterior</span>{" "}
          </button>
          <button
            className={`carousel-control-next h-100 me-2 ${size === "small" ? breakpoint === 'md' ? "d-none" : "d-block" : "d-block"} `} type="button" data-bs-target={"#banner-" + id} data-bs-slide="next">
            <i className="fa fa-chevron-right color-dark" aria-hidden="true" ></i>
            <span className="visually-hidden">Siguiente</span>
          </button>
        </>
      }
    </div>
  );
};

const renderBannerItem = (item: DefaultBannerProps, size: string, index: number, breakpoint: string) => {
  return (
    <div className={`carousel-item ${index == 0 ? "active" : ""}`} data-bs-interval="5000" >
      <div className="col-12 p-0">
        {item?.url && <Link to={item.url} style={{ zIndex: 2 }} className={`${size === "large" || size === "medium" || breakpoint === 'sm' ? "btn btn-degradado btn-absolute" : "w-100 h-100 position-absolute"}`}>
          {(size === "large" || size === "medium" || breakpoint === 'sm') && <>Ver más{" "}<i className="fa fa-chevron-right" aria-hidden="true"></i></>}
        </Link>}
        {item?.component ? item.component : <picture>
          <source media="(min-width: 992px)" srcSet={item.image} />
          <img src={item.image_mobile || item.image} className="d-block w-100 rounded-4" style={{ aspectRatio: (['xs', 'sm', 'md'].includes(breakpoint) && size === "large") || size !== "large" ? "5/2" : "5/1" }} />
        </picture>}
      </div>
    </div>
  );
};

export default Banner;
