import React from "react";
import { Card } from "../../../commonComponents";
import { useCencoInfo } from "../../hooks/useCencoInfo";

export const InfoCencoTalento = () => {
    const { applicationInfo } = useCencoInfo();
    return (
        <>
            <Card>
                <h2 className="h4 tit-section">
                    Conoce el Proceso de Postulación
                </h2>

                <p>
                    <strong>¡Cencosud Somos Todos!</strong>
                </p>

                <p>
                    En Cencosud promovemos espacios de trabajo respetuosos y libres de discriminación; 
                    ¡estamos convencidos que tener equipos diversos nos hace creativos e innovadores!
                </p>
                <p>
                    ¡Te invitamos a seguir impulsando tu desarrollo profesional con nosotros 
                    e impactar positivamente en la vida de nuestros clientes!
                </p>

                <div className="row mt-4 mb-4">
                    {applicationInfo.map(
                        ({ icon, title, description, country, isList }) => (
                            <Card
                                key={title}
                                type="secondary"
                                className="col-12 col-md-4 col-lg-6 col-xl-4 mb-4 d-flex"
                            >
                                <div className="text-center">
                                    <i
                                        className={`${icon} color-orange fa-fw fa-3x mb-4 mt-3`}
                                        aria-hidden="true"
                                    ></i>
                                </div>
                                <h3 className="h5 color-normal fw-bold">
                                    {title}
                                </h3>

                                {isList ? (
                                    <ul>
                                        {description.split(";").map((item) => (
                                            <li key={item}>{item}</li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p>{description}</p>
                                )}
                            </Card>
                        )
                    )}
                </div>
            </Card>
        </>
    );
};
