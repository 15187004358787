import { getAnnualBond, postAnnualBond, postDownloadDocumentAB, getMyModel, getTeamModel } from "../apiServices/apiRequest";
import { httpClientEnv } from "../apiServices/httpClient";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import * as RD from "@devexperts/remote-data-ts";
import { HttpError, AnnualBond, ResponsePostAnnualBond, PostAnnualBond, ResponsePostDocument, GetMyModelResponse, GetTeamAsignationResponse} from "../interfaces";
import { RemoteData } from "@devexperts/remote-data-ts";

interface GetProps {
    setRemoteData: React.Dispatch<React.SetStateAction<RemoteData<HttpError, AnnualBond>>>;
}

interface PostProps {
    setRemoteData: React.Dispatch<React.SetStateAction<RemoteData<HttpError, ResponsePostAnnualBond>>>;
    body: PostAnnualBond;
}
interface PostDocumentProps {
    setRemoteData: React.Dispatch<React.SetStateAction<RemoteData<HttpError, ResponsePostDocument>>>;
    body: PostAnnualBond;
}

interface GetMyModelProps {
    setRemoteData: React.Dispatch<React.SetStateAction<RemoteData<HttpError, GetMyModelResponse>>>;
}
interface GetTeamModelProps {
    setRemoteData: React.Dispatch<React.SetStateAction<RemoteData<HttpError, GetTeamAsignationResponse>>>;
}
export const getRemoteData = async ({ setRemoteData }: GetProps) => {
    await (async () => {
        setRemoteData(RD.pending);
        RTE.run(await getAnnualBond(), httpClientEnv)
            .then(E.fold(e => { return setRemoteData(RD.failure(e)) }, a => { return setRemoteData(RD.success(a)) }));
    })()
};

export const getPostResponse = async ({ setRemoteData, body }: PostProps) => {
    await (async () => {
        setRemoteData(RD.pending);
        RTE.run(await postAnnualBond(body), httpClientEnv)
            .then(E.fold(e => { return setRemoteData(RD.failure(e)) }, a => { return setRemoteData(RD.success(a)) }));
    })()
};

export const getPostDocument = async ({ setRemoteData, body }: PostDocumentProps) => {
    await (async () => {
        setRemoteData(RD.pending);
        RTE.run(await postDownloadDocumentAB(body), httpClientEnv)
            .then(E.fold(e => { return setRemoteData(RD.failure(e)) }, a => { return setRemoteData(RD.success(a)) }));
    })()
};

export const getRemoteMyModel = async ({ setRemoteData }: GetMyModelProps) => {
    await (async () => {
        setRemoteData(RD.pending);
        RTE.run(await getMyModel(), httpClientEnv)
            .then(E.fold(e => { return setRemoteData(RD.failure(e)) }, a => { return setRemoteData(RD.success(a)) }));
    })()
};
export const getRemoteTeamModel = async ({ setRemoteData }: GetTeamModelProps) => {
    await (async () => {
        setRemoteData(RD.pending);
        RTE.run(await getTeamModel(), httpClientEnv)
            .then(E.fold(e => { return setRemoteData(RD.failure(e)) }, a => { return setRemoteData(RD.success(a)) }));
    })()
};