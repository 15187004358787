import React, { Children, FC, SetStateAction, useContext, useEffect, useState } from "react";
import BreadCrumbs from "../../commons/breadCrumbs";
import {
  GetDashboard,
  HttpError,
  Section,
  DependencyArray,
  Graph,
  DirectReport,
  GetHomeDashboard,
  GetSectionDashboard,
  DashboardSection,
  Ninebox
} from "../../utilities/interfaces";
import { RemoteData } from "@devexperts/remote-data-ts";
import * as RD from "@devexperts/remote-data-ts";
import { GetProps, getRemoteData, getRemoteDataHome, getRemoteDataSection } from "../../utilities/helpers/setData";
import Carousel from "../../commons/carousel";
import { pipe } from "fp-ts/lib/function";
import * as A from "fp-ts/Array";
import * as S from "fp-ts/string";
import { contramap } from "fp-ts/Ord";
import Table from "../../commons/table";
import Option from "../../commons/option";
import DependencyCrumbs from "./dependencyCrumbs";
import PieChart from "../../commons/graphics/pieChart";
import BarChart from "../../commons/graphics/barChart";
import LineChart from "../../commons/graphics/lineChart";
import { Link } from "react-router-dom";
import { urlsAws } from "../../../../resources/foo/api-endpoints";
import { Context } from "../../../../context";
import { generateColorsGraph } from "../../utilities/generateColors";
import NineBox from "../../commons/ninebox/ninebox";

const byOrder = pipe(
  S.Ord,
  contramap((s: DashboardSection) => s.order)
);

type GetRemoteFunction = {
  (props: GetProps): Promise<void>;
};

const DashboardPages: FC = () => {
  const { setShrink } = useContext(Context) ?? { setShrink: () => {} };

  const currentYear = new Date().getFullYear().toString();
  const [carouselSelected, setCarouselSelected] = useState<number>(-1);
  
  const [remoteData, setRemoteData] = useState<
    RemoteData<HttpError, GetDashboard>
  >(RD.initial);
  
  const [remoteDataHome, setRemoteDataHome] = useState<
    RemoteData<HttpError, GetHomeDashboard>
  >(RD.initial);

  const [remoteDataSection, setRemoteDataSection] = useState<
    RemoteData<HttpError, GetSectionDashboard>
  >(RD.initial);

  const [request, setRequest] = useState<string>("");
  const emplid = window.localStorage.getItem("emplid");
  const [selectedOption, setSelectedOption] = useState("directo");
  const [selectedOptionEquipo, setselectedOptionEquipo] = useState("");
  const [selectedOptionYear, setselectedOptionYear] = useState(currentYear);
  const [dependencyArray, setDependencyArray] = useState<DependencyArray[]>([]);
  const [title, setTitle] = useState("");
  const [dataGraph, setDataGraph] = useState<Graph[]>([]);
  let initialStateNineBox = {
    show: false,
    title: '',
    yAxisLabel: { info: '', label: '' },
    xAxisLabel: { info: '', label: '' },
    yLabels: [],
    xLabels: [],
    cells: [],
  }
  const [dataNineBox, setDataNineBox] = useState<Ninebox>(initialStateNineBox);
  const [showYear, setShowYear] = useState(true);
  const [directReport,setDirectReport] = useState<DirectReport[]>([])
  const [isGraphLoaded, setIsGraphLoaded] = useState(false);
  const [rut, setRut] = useState<string>("");
  const [rutValid, setRutValid] = useState(false);
  const [valid, setValid] = useState(false)

  const closeSubItems = () =>{
    const navFolder = document.getElementsByClassName("nav-folder-cont");
    for (let i = 0; i < navFolder.length; i++) {
        let listClass =  navFolder[i].classList;
        if(listClass.contains('show')){
            navFolder[i].className = "collapse nav-folder-cont"
        }
    }
  }

  useEffect(()=>{
    setShrink(true);
    closeSubItems();
    return()=>{
      setShrink(false);
    }
  },[])

  useEffect(() => {
      request === '' && RemoteDataCache('', urlsAws.getDashboard, setRemoteData, getRemoteData,false)
      RemoteDataCache(request, urlsAws.getDashboardHome, setRemoteDataHome, getRemoteDataHome,true)
      RemoteDataCache(request, urlsAws.getDashboardSection, setRemoteDataSection, getRemoteDataSection,false)
  }, [request]);

  const RemoteDataCache = async (
    request: string, 
    url: string, 
    setRemote: React.Dispatch<any>,
    getRemote: GetRemoteFunction,
    special: boolean
    ) =>{

    const modifiedUrl = request.replace(`&year=${selectedOptionYear}`, '');
    let req = special ? modifiedUrl : request;
    caches.open("api-cache").then((cache) => {
      cache
        .match(url+req)
        .then((response) => {
          if (response) {
            return response.json();
          } else {
            throw new Error();
          }
        })
        .then((data) => {
          if (data) {
            setRemote(data);
          } else {
            throw new Error();
          }
        })
        .catch((err) => {
          const props: GetProps = { setRemoteData: setRemote, request: req };
          getRemote(props);
        });
    });
  }

  useEffect(() => {
    if (
      remoteDataSection._tag === "RemoteSuccess" &&
      carouselSelected > -1
    ) {
      if (remoteDataSection.value.response.sections[carouselSelected].total !== '0') {
        setDataGraph(
          remoteDataSection.value.response.sections[carouselSelected].graph
        );
      } else {
        setDataGraph([]);
      }

      if (
        remoteDataSection.value.response.sections[carouselSelected].ninebox !== undefined &&
        remoteDataSection.value.response.sections[carouselSelected].ninebox?.show
      ) {
        const nineboxValue = remoteDataSection.value.response.sections[carouselSelected].ninebox;
        let hasData = false;
        if(nineboxValue && nineboxValue.cells.length > 0){
          nineboxValue.cells.map((cell,i)=>{
            if(cell.data.length > 0){
              hasData = true;
            }
        })
        }

        if (nineboxValue && nineboxValue.show && hasData) {
          setDataNineBox(nineboxValue);
        } else {
          setDataNineBox(initialStateNineBox);
        }
      } else {
        setDataNineBox(initialStateNineBox);
      }
      
    }
  }, [remoteDataSection, carouselSelected]);
 
  useEffect(()=>{
    if(remoteData._tag === 'RemoteSuccess'){
      let directReports = remoteData.value.response.directReports;
      directReports = directReports.sort((reportA, reportB) => (reportA.fullName < reportB.fullName ? -1 : 1));
      setDirectReport(remoteData.value.response.directReports);
    }
  },[remoteData])

  useEffect(()=>{
    const updatedData = dependencyArray.map(item => {
      const updatedRequest = item?.request.replace(/&year=\d{4}/, `&year=${selectedOptionYear}`);
      return { ...item, request: updatedRequest };
    });
    setDependencyArray(updatedData);
  },[selectedOptionYear])


  return (
    <>
      <BreadCrumbs
        crumbs={[
          { name: "Inicio", url: "/inicio" },
          { name: "Mi Equipo", url: "/miequipo" },
          { name: "Dashboard", url: "/dashboard" },
        ]}
        actual={title}
        setCarouselSelected={setCarouselSelected}
        setDependencyArray={setDependencyArray}
        setTitle={setTitle}
        setselectedOptionEquipo={setselectedOptionEquipo}
        setShrink={setShrink}
        setRut={setRut}
      />
      <section className="dashboard-de-mi-equipo">
        <h1>Dashboard de mi Equipo</h1>
        <>
          <Carousel
            carouselSelected={carouselSelected}
            setCarouselSelected={setCarouselSelected}
            setTitle={setTitle}
            cards={pipe(
              remoteData._tag === "RemoteSuccess"
                ? remoteData.value.response.sections
                  : [],
              A.sortBy([byOrder]),
              A.map((c) => {
                return {
                  id: c.id,
                  title: c.title,
                  icon: c.icon,
                  total: c.total,
                  showYear: c.showYear
                };
              })
            )}
            setShowYear={setShowYear}
            setIsGraphLoaded={setIsGraphLoaded}
          />
        </>

        {remoteData._tag === 'RemotePending' && 
          <div className="text-center">
            <span className="spinner-border spinner-border-sm" />
            <p> Cargando información... </p>
          </div>
        }

        {(remoteData._tag === "RemoteFailure" || remoteDataHome._tag  === "RemoteFailure" || remoteDataSection._tag === 'RemoteFailure') ?
        <> 
          <div className="text-center">
            <p>Ha ocurrido un error al obtener la información. Por favor, intente más tarde.</p>
          </div>
        </>
        :
        <>


          {carouselSelected > -1 && dataNineBox.show  &&
            <NineBox
              dataNineBox={dataNineBox}
            />  
          }


          <div className="row mt-0 mx-0 w-auto">
          {dataGraph.length > 0 &&
            carouselSelected > -1 &&
            Children.toArray(
              dataGraph.map((data, i, arr) => {
                arr.sort(function (a, b) {
                  return a.order - b.order;
                });
                const colors = generateColorsGraph(data.dataSets.length);
                if(!Array.isArray(data.dataSets)){
                  data.dataSets = [data.dataSets]
                }
                if (data.type === "lineChart") {
                  return (
                    <LineChart
                      datos={data}
                      arrayColors={colors}
                      columns={data.columns}
                      title={data.title}
                      idGraph={data.id}
                      sizeGraph={300}
                      setIsGraphLoaded={setIsGraphLoaded}
                      isGraphLoaded={isGraphLoaded}
                    />
                  );
                }
                if (data.type === "barChart") {
                  return (
                    <BarChart
                      datos={data}
                      arrayColors={colors}
                      columns={data.columns}
                      title={data.title}
                      idGraph={data.id}
                      sizeGraph={300}
                      setIsGraphLoaded={setIsGraphLoaded}
                      isGraphLoaded={isGraphLoaded}
                    />
                  );
                }
                if (data.type === "pieChart") {
                  return (
                    <PieChart
                      datos={data}
                      arrayColors={colors}
                      columns={data.columns}
                      title={data.title}
                      idGraph={data.id}
                      sizeGraph={300}
                      setIsGraphLoaded={setIsGraphLoaded}
                      isGraphLoaded={isGraphLoaded}
                    />
                  );
                }
              })
            )}
          </div>

          <div className={remoteDataHome._tag === 'RemoteSuccess' && remoteData._tag === 'RemoteSuccess'  ? "box mt-4 mx-2 " : ''}>
            {remoteDataHome._tag === "RemoteSuccess" &&  remoteData._tag === 'RemoteSuccess' && (
              <Option
                setSelectedOption={setSelectedOption}
                setselectedOptionEquipo={setselectedOptionEquipo}
                setselectedOptionYear={setselectedOptionYear}
                selectedOption={selectedOption}
                selectedOptionEquipo={selectedOptionEquipo}
                selectedOptionYear={selectedOptionYear}
                directReport={directReport}
                setRequest={setRequest}
                emplid={emplid || ""}
                header={
                  remoteDataHome._tag === "RemoteSuccess" && carouselSelected === -1
                    ? remoteDataHome.value.response.headers : 
                    remoteDataSection._tag === "RemoteSuccess" && carouselSelected > -1 ?
                    remoteDataSection.value.response.sections[carouselSelected].table.headers:[]
                }
                rows={
                  remoteDataHome._tag === "RemoteSuccess" && carouselSelected === -1
                    ? remoteDataHome.value.response.rows : 
                    remoteDataSection._tag === "RemoteSuccess" && carouselSelected > -1 ?
                    remoteDataSection.value.response.sections[carouselSelected].table.rows:[]
                }
                title={
                  remoteDataHome._tag === "RemoteSuccess" && carouselSelected === -1
                  ? remoteDataHome.value.response.title
                  : remoteDataSection._tag === "RemoteSuccess" && carouselSelected > -1?
                    remoteDataSection.value.response.sections[carouselSelected].table.title:''}
                  showYear={carouselSelected === -1 ? false : showYear}
                  setDirectReport={setDirectReport}
                  rut={rut}
                  setRut={setRut}
                  rutValid={rutValid}
                  setRutValid={setRutValid}
                  setDependencyArray={setDependencyArray}
                  info={
                    remoteDataHome._tag === "RemoteSuccess" && carouselSelected === -1
                  ? remoteDataHome.value.response.info
                  : remoteDataSection._tag === "RemoteSuccess" && carouselSelected > -1 ?
                    remoteDataSection.value.response.sections[carouselSelected].table.info:''}
              />
            )}

            {remoteData._tag === 'RemoteSuccess' && 
              emplid &&  
              carouselSelected === -1 && (
                <>
                  {remoteDataHome._tag === 'RemoteSuccess' && 
                    <DependencyCrumbs
                      dependencyArray={dependencyArray}
                      setDependencyArray={setDependencyArray}
                      setRequest={setRequest}
                      setselectedOptionEquipo={setselectedOptionEquipo}
                      setRut={setRut}
                      setDirectReport={setDirectReport}
                      directReport={remoteData.value.response.directReports}
                      setSelectedOption={setSelectedOption}
                    />
                  }

                  <Table
                    header={
                      remoteDataHome._tag === "RemoteSuccess" 
                        ? remoteDataHome.value.response.headers : []
                    }
                    rows={
                      remoteDataHome._tag === "RemoteSuccess"
                        ? remoteDataHome.value.response.rows : []
                    }
                    setRequest={setRequest}
                    selectedOptionYear={selectedOptionYear}
                    emplid={emplid}
                    dependencyArray={dependencyArray}
                    setDependencyArray={setDependencyArray}
                    isLoadData={remoteDataHome._tag==='RemotePending'}
                    setSelectedOption={setSelectedOption}
                    setselectedOptionEquipo={setselectedOptionEquipo}
                    directReport={directReport}
                    setDirectReport={setDirectReport}
                  />
                </>
              )}

            {remoteData._tag === "RemoteSuccess" &&
              emplid &&
              carouselSelected > -1 && (
                <>
                {remoteDataHome._tag === 'RemoteSuccess' &&
                  <DependencyCrumbs
                  dependencyArray={dependencyArray}
                  setDependencyArray={setDependencyArray}
                  setRequest={setRequest}
                  setselectedOptionEquipo={setselectedOptionEquipo}
                  setRut={setRut}
                  setDirectReport={setDirectReport}
                  directReport={remoteData.value.response.directReports}
                  setSelectedOption={setSelectedOption}
                  />
                }
                  
                  <Table
                    header={
                        remoteDataSection._tag === "RemoteSuccess" && carouselSelected > -1 ?
                        remoteDataSection.value.response.sections[carouselSelected].table.headers:[]
                    }
                    rows={
                        remoteDataSection._tag === "RemoteSuccess" && carouselSelected > -1 ?
                        remoteDataSection.value.response.sections[carouselSelected].table.rows:[]
                    }
                    setRequest={setRequest}
                    selectedOptionYear={selectedOptionYear}
                    emplid={emplid}
                    dependencyArray={dependencyArray}
                    setDependencyArray={setDependencyArray}
                    isLoadData={remoteDataSection._tag==='RemotePending'}
                    setSelectedOption={setSelectedOption}
                    directReport={directReport}
                    setselectedOptionEquipo={setselectedOptionEquipo}
                    setDirectReport={setDirectReport}
                  />
                </>
              )}

            
          </div>
        </>
        }
      </section>

      <a hidden id="openModalHelpGraphic" data-bs-toggle="modal" data-bs-target="#modalHelpGraphic" />
      <div className="modal fade" id="modalHelpGraphic" tabIndex={-1} aria-labelledby="modalHelpGraphic" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                      <button id="closeSearch" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { } }></button>
                  </div>
                  <div className="modal-body text-center py-4">
                  <i
                  className="fa fa-exclamation-triangle color-orange fa-3x"
                  aria-hidden="true"
                  ></i>
                      <h2 className="h5">Ayudaaa.</h2>
                  </div>
              </div>
          </div>
      </div>
    </>
  );
};
export default DashboardPages;
