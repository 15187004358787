import Auth from "@aws-amplify/auth";
import { urlsAws } from "../resources/foo/api-endpoints";
import { decrypt, encrypt } from "./utiles";
const emplidUsuario = localStorage.getItem("emplid");

const handleCache = async (key, data, setData) => {
    const cache = await caches.open("api-cache");
    cache.put(key, new Response(JSON.stringify(data)));
    if (setData) setData(data);
};

const fetchData = async (url, method, body) => {
    const auth = await Auth.currentSession();
    const requestOptions = {
        method,
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: auth.getIdToken().getJwtToken(),
            AccessToken: auth.getAccessToken().getJwtToken(),
        },
        body: body ? JSON.stringify(body) : null,
    };
    return await fetch(url, requestOptions).then((response) => response.json()).catch((error) => { console.log(error); return false; });
};

const vacacionesService = {
    getFeriados: (setData, emplid) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.getFeriados , requestOptions)
                .then((response) => response.text())
                .then((result) => result.replaceAll(",}", "}"))
                .then((result) => JSON.parse(result))
                .then((data) => {
                    if (data) {
                        if (data.response) {
                            setData(data.response);
                        }
                    }
                })
                .catch((error) => {
                    console.log("error al cargar feriados: ", error);
                });
        }).catch((err) => console.log(err));
    },
    getDetalleDiasDisponibles: (setData, emplid) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.getDetalleDiasDisponibles + "?rut=" + emplid + "&rcd=0", requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data) {
                        if (data.response) {
                            setData(data.response);
                        }
                    }
                })
                .catch((error) => {
                    console.log("error al cargar dias: ", error);
                });
        }).catch((err) => console.log(err));
    },
    getVacacionesSubAlterno: (setData, emplid) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.getVacacionesSubAlterno , requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data !== undefined) {
                        if (data.message === "API rate limit exceeded") {
                            throw new Error("error 429");
                        } else {
                            if (data.message == undefined) {
                                try {
                                    if (data.subalternos !== undefined && !Array.isArray(data.subalternos)) {
                                        data.subalternos = [data.subalternos];
                                    }
                                    caches.open("api-cache").then((cache) => {
                                        cache.put(urlsAws.getVacacionesSubAlterno + "?rut=" + emplidUsuario, new Response(JSON.stringify(data)));
                                        setData(data.response);
                                    });
                                } catch {
                                    setData(2);
                                }
                            } else {
                                try {
                                    caches.match(urlsAws.getVacacionesSubAlterno + "?rut=" + emplidUsuario).then((response) => {
                                        if (response) {
                                            response.json().then((data) => {
                                                setData(data.response);
                                            });
                                        } else {
                                            setData(2);
                                        }
                                    });
                                } catch {
                                    setData(2);
                                }
                            }
                        }
                    } else {
                        setData(2);
                    }
                })
                .catch((error) => {
                    caches.match(urlsAws.getVacacionesSubAlterno + "?rut=" + emplidUsuario).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                setData(data.response);
                            });
                        } else {
                            console.log("error: ", error);
                            setData(2);
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },
    getDetalleVacaciones: (setData, emplid, userDt = undefined) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.getDetalleVacaciones + "?rut=" + emplid + "&rcd=0" + "&userDt=" + userDt, requestOptions)
                .then((response) => response.text())
                .then((result) => result.replaceAll(",}", "}"))
                .then((result) => JSON.parse(result))
                .then((data) => {
                    if (data != undefined && data.body !== null) {
                        if (data.message === "API rate limit exceeded") {
                            setData(2);
                        } else {
                            if (data.message == undefined) {
                                if (data.response.vacaciones !== undefined) {
                                    if (data.response.vacaciones !== undefined && !Array.isArray(data.response.vacaciones)) {
                                        data.response.vacaciones = [data.response.vacaciones];
                                    }
                                    if (Array.isArray(data.response.vacaciones)) {
                                        setData(data.response);
                                    } else {
                                        if (data.response.status == "RUT no encontrado") {
                                            setData(2);
                                        } else if (decrypt(data.response.vacaciones.emplid) == emplid) {
                                            data.response.vacaciones = [data.response.vacaciones];
                                            setData(data.response);
                                        } else {
                                            setData(2);
                                        }
                                    }
                                } else {
                                    setData(3);
                                }
                            } else {
                                setData(2);
                            }
                        }
                    } else {
                        setData(2);
                    }
                })
                .catch((error) => {
                    setData(2);
                });
        }).catch((err) => console.log(err));
    },
    getCartola: (setData, emplid) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.getCartola + "?rut=" + emplid, requestOptions)
                .then((response) => response.text())
                .then((result) => result.replaceAll(",}", "}"))
                .then((result) => result.replaceAll("(,", "}"))
                .then((result) => JSON.parse(result))
                .then((data) => {
                    if (data != undefined && data.body !== null) {
                        if (data.message === "API rate limit exceeded") {
                            setData(2);
                        } else {
                            if (data.message == undefined) {
                                if (data.response.detalle_vacacion !== undefined) {
                                    if (data.response.detalle_vacacion !== undefined && !Array.isArray(data.response.detalle_vacacion)) {
                                        data.response.detalle_vacacion = [data.response.detalle_vacacion];
                                    }
    
                                    if (Array.isArray(data.response.detalle_vacacion)) {
                                        try {
                                            caches.open("api-cache").then((cache) => {
                                                cache.put(urlsAws.getCartola + "?rut=" + emplid, new Response(JSON.stringify(data)));
                                                setData(data.response);
                                            });
                                        } catch {
                                            setData(2);
                                        }
                                    } else {
                                        setData(3);
                                    }
                                } else {
                                    setData(3);
                                }
                            } else {
                                try {
                                    caches.match(urlsAws.getCartola + "?rut=" + emplid).then((response) => {
                                        if (response) {
                                            response.json().then((data) => {
                                                setData(data.response);
                                            });
                                        } else {
                                            setData(2);
                                        }
                                    });
                                } catch {
                                    setData(2);
                                }
                            }
                        }
                    } else {
                        setData(2);
                    }
                })
                .catch((error) => {
                    caches.match(urlsAws.getCartola + "?rut=" + emplid).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                setData(data.response);
                            });
                        } else {
                            setData(2);
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },
    anularVacaciones: (emplidUsuarioSelect, emplid, num_req, setData) => {
        Auth.currentSession().then((auth) => {
            const body = JSON.stringify({ request: { emplid: emplidUsuarioSelect, approved_by: "", num_req: num_req } });
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: body,
            };
            fetch(urlsAws.anularVacaciones , requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data != undefined) {
                        if (data.message === "API rate limit exceeded") {
                            setData(2);
                        } else {
                            if (data.message == undefined) {
                                try {
                                    setData(data.response);
                                } catch {
                                    setData(2);
                                }
                            } else {
                                try {
                                    caches.match(urlsAws.anularVacaciones).then((response) => {
                                        if (response) {
                                            response.json().then((data) => {
                                                setData(data.response);
                                            });
                                        } else {
                                            setData(2);
                                        }
                                    });
                                } catch {
                                    setData(2);
                                }
                            }
                        }
                    } else {
                        setData(2);
                    }
                })
                .catch((error) => {
                    caches.match(urlsAws.anularVacaciones).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                setData(data.response);
                            });
                        } else {
                            setData(2);
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },
    rechazarVacaciones: (emplidUsuarioSelect, emplid, num_req, setData) => {
        Auth.currentSession().then((auth) => {
            const body = JSON.stringify({ request: { emplid: emplidUsuarioSelect, approved_by: "", num_req: num_req } });
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: body,
            };
            fetch(urlsAws.rechazarVacaciones , requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data != undefined) {
                        if (data.message === "API rate limit exceeded") {
                            setData(2);
                        } else {
                            if (data.message == undefined) {
                                try {
                                    caches.open("api-cache").then((cache) => {
                                        cache.put(urlsAws.rechazarVacaciones, new Response(JSON.stringify(data)));
                                        setData(data.response);
                                    });
                                } catch {
                                    setData(2);
                                }
                            } else {
                                try {
                                    caches.match(urlsAws.rechazarVacaciones).then((response) => {
                                        if (response) {
                                            response.json().then((data) => {
                                                setData(data.response);
                                            });
                                        } else {
                                            setData(2);
                                        }
                                    });
                                } catch {
                                    setData(2);
                                }
                            }
                        }
                    } else {
                        setData(2);
                    }
                })
                .catch((error) => {
                    caches.match(urlsAws.rechazarVacaciones).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                setData(data.response);
                            });
                        } else {
                            setData(2);
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },
    solicitarVacaciones: (emplid, fechaInicio, fechaTermino, setData) => {
        Auth.currentSession().then((auth) => {
            const body = JSON.stringify({ request: { emplid: "", fecha_inicio: fechaInicio, fecha_termino: fechaTermino } });
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: body,
            };
            fetch(urlsAws.solicitarVacaciones , requestOptions)
                .then((response) => response.json())
                .then((e) => setData(e))
                .catch((error) => {
                    setData(2);
                });
        }).catch((err) => console.log(err));
    },
    aprobarVacaciones: (emplidUsuarioSelect, emplid, num_req, setData) => {
        Auth.currentSession().then((auth) => {
            const body = JSON.stringify({ request: { emplid: emplidUsuarioSelect, approved_by: "", num_req: num_req } });
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: body,
            };
            fetch(urlsAws.aprobarVacaciones , requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data != undefined) {
                        if (data.message) {
                            setData(2);
                        } else {
                            if (data.message == undefined) {
                                try {
                                    caches.open("api-cache").then((cache) => {
                                        cache.put(urlsAws.aprobarVacaciones, new Response(JSON.stringify(data)));
                                        setData(data.response);
                                    });
                                } catch {
                                    setData(2);
                                }
                            } else {
                                try {
                                    caches.match(urlsAws.aprobarVacaciones).then((response) => {
                                        if (response) {
                                            response.json().then((data) => {
                                                setData(data.response);
                                            });
                                        } else {
                                            setData(2);
                                        }
                                    });
                                } catch {
                                    setData(2);
                                }
                            }
                        }
                    } else {
                        setData(2);
                    }
                })
                .catch((error) => {
                    caches.match(urlsAws.aprobarVacaciones).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                setData(data.response);
                            });
                        } else {
                            setData(2);
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },
    papeletaVacaciones: (setData, fechaInicio, emplid, userDt = undefined) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() }
            };
            fetch(urlsAws.papeletaVacaciones + "?fecha=" + fechaInicio + "&userDt=" + userDt + "&emplid=" + emplid, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    caches.open("api-cache").then((cache) => {
                        cache.put(urlsAws.papeletaVacaciones + "?rut=" + emplid ? emplid : emplidUsuario + "&fecha=" + fechaInicio, new Response(JSON.stringify(data.response)));
                        setData(data.response);
                    });
                })
                .catch((error) => {
                    caches.match(urlsAws.papeletaVacaciones + "?rut=" + emplid ? emplid : emplidUsuario + "&fecha=" + fechaInicio).then((response) => {
                        if (response != undefined) {
                            response
                                .json()
                                .then((data) => {
                                    if (data != undefined) {
                                        setData(data);
                                    } else {
                                        setData(2);
                                    }
                                })
                                .catch(() => {
                                    setData(2);
                                });
                        } else {
                            setData(2);
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },
    realizarReasignacion: (emplid, emplidReasignacion, setData) => {
        Auth.currentSession().then((auth) => {
            const body = JSON.stringify({ request: { emplid: "", emplid_to: emplidReasignacion } });
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: body,
            };
            fetch(urlsAws.solicitarReasignacion , requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data != undefined) {
                        if (data.message == undefined) {
                            caches.open("api-cache").then((cache) => {
                                cache.put(urlsAws.solicitarReasignacion, new Response(JSON.stringify(data)));
                                setData(data.response);
                            });
                        } else {
                            caches.match(urlsAws.solicitarReasignacion).then((response) => {
                                if (response) {
                                    try {
                                        response.json().then((data) => {
                                            setData(2);
                                        });
                                    } catch {
                                        setData(2);
                                    }
                                } else {
                                    setData(2);
                                }
                            });
                        }
                    }
                })
                .catch((error) => {
                    caches.match(urlsAws.solicitarReasignacion).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                setData(data.response);
                            });
                        } else {
                            setData(2);
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },
    buscarReasignacion: (setData, emplid) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.buscarReasignacion + "?rut=" + emplid, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data != undefined) {
                        if (data.message === "API rate limit exceeded") {
                            setData(2);
                        } else {
                            if (data.message == undefined) {
                                try {
                                    setData(data.response);
                                } catch {
                                    setData(2);
                                }
                            } else {
                                try {
                                    caches.match(urlsAws.buscarReasignacion + "?rut=" + emplid).then((response) => {
                                        if (response) {
                                            response.json().then((data) => {
                                                setData(data.response);
                                            });
                                        } else {
                                            setData(2);
                                        }
                                    });
                                } catch {
                                    setData(2);
                                }
                            }
                        }
                    } else {
                        setData(2);
                    }
                })
                .catch((error) => {
                    caches.match(urlsAws.buscarReasignacion + "?rut=" + emplid).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                setData(data.response);
                            });
                        } else {
                            setData(2);
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },
    eliminarReasignacion: (emplidReasignacion, setData) => {
        Auth.currentSession().then((auth) => {
            const body = JSON.stringify({ request: { emplid: emplidReasignacion } });
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: body,
            };
            fetch(urlsAws.eliminarReasignacion , requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data != undefined) {
                        if (data.message == undefined) {
                            caches.open("api-cache").then((cache) => {
                                cache.put(urlsAws.eliminarReasignacion, new Response(JSON.stringify(data)));
                                setData(data.response);
                            });
                        } else {
                            caches.match(urlsAws.eliminarReasignacion).then((response) => {
                                if (response) {
                                    try {
                                        response.Response.json().then((data) => {
                                            setData(data);
                                        });
                                    } catch {
                                        setData(2);
                                    }
                                } else {
                                    setData(2);
                                }
                            });
                        }
                    }
                })
                .catch((error) => {
                    caches.match(urlsAws.eliminarReasignacion).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                setData(data.response);
                            });
                        } else {
                            setData(2);
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },
    getMiEquipo: (emplid, setData) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.getMiEquipo , requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data != undefined) {
                        if (data.message === "API rate limit exceeded") {
                            setData(2);
                        } else {
                            if (data.message == undefined) {
                                try {
                                    caches.open("api-cache").then((cache) => {
                                        cache.put(urlsAws.getMiEquipo + "?emplid=" + emplid, new Response(JSON.stringify(data)));
    
                                        setData(data.response);
                                    });
                                } catch {
                                    setData(2);
                                }
                            } else {
                                try {
                                    caches.match(urlsAws.getMiEquipo + "?emplid=" + emplid).then((response) => {
                                        if (response) {
                                            response.json().then((data) => {
                                                setData(data.response);
                                            });
                                        } else {
                                            setData(2);
                                        }
                                    });
                                } catch {
                                    setData(2);
                                }
                            }
                        }
                    } else {
                        setData(2);
                    }
                })
                .catch((error) => {
                    caches.match(urlsAws.getMiEquipo + "?emplid=" + emplid).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                setData(data.response);
                            });
                        } else {
                            setData(2);
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },
    getValidCollaborator: (emplid, setUserData, setEmplidvalidator) => {
        Auth.currentSession().then((auth) => {
            const body = JSON.stringify({ request: { emplid: emplid } });
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: body,
            };
            fetch(urlsAws.getValidCollaborator , requestOptions)
                .then(async (response) => {
                    const thowErr = async (err) => { throw new Error(await err) };
                    const returnVal = response.status !== 200 ? thowErr(response.text()) : response.json();
                    return await returnVal;
                })
                .then((data) => {
                    data?.response?.valid !== undefined && data?.response?.valid !== "0" ? (
                            setUserData(data.response?.nombre) &&
                            setEmplidvalidator(false)
                        )
                    :
                        (
                            setUserData(data?.response?.status !== undefined ? data?.response?.status : "Error de comunicación. Intentar más tarde") &&
                            setEmplidvalidator(true)
                        )
                })
                .catch(async (error) => {
                    const err = await JSON.parse(error.message);
                    setUserData("Error en comunicación con servidor");
                    setEmplidvalidator(true)
                    console.log(err);
                });
        }).catch((err) => console.log(err));
    },
};

export default vacacionesService;
