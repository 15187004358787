/* eslint-disable react/display-name */
import React, { useEffect, useState, useRef, forwardRef} from 'react';
import Tippy from '@tippyjs/react';
import {PropTypes} from 'prop-types';
import 'tippy.js/dist/tippy.css';
import moment from 'moment';


const Header = forwardRef(({val,setVal} , ref) => {
    Header.propTypes = {
        val: PropTypes.any,
        setVal: PropTypes.any
    }

    const date = moment(val)
    const setter = setVal
    const [pickedDate, setPickedDate] = useState({});
    const [active, setActive] = useState(false);
    const picker = useRef();
    const counter = ref.current?.getApi();
    

    useEffect(() => {
       
    },[ref])

    useEffect(() => {
        if (active) {
            setter(pickedDate);
            setActive(false);
            counter.gotoDate(pickedDate)
        }
    }, [active])

    function prevRange() {
            counter?.prev()
            setter(date?.clone().subtract(2, "week"));
    }

    function nextRange() {
        counter?.next()
            setter(date?.clone().add(2, "week"));
    }

    function handlePickerChange(e) {
        setPickedDate(new Date(e.target.value));
        setActive(true)
    }

    return (
        <div>
            <div className="row">
                <div className="container">
                    <div className="header-title text-center text-capitalize">{date.format("MMMM")} {date.format("YYYY")}<Tippy content="Buscar periódo"><span onClick={() => picker?.current?.showPicker()}><i className="fa fa-calendar-o fa-fw color-orange date-selector" aria-hidden="true"></i></span></Tippy></div>
                    <div className="picker-container"><input value={pickedDate} ref={picker} onChange={(e) => handlePickerChange(e)} type="date" className="calendar-filter-responsive form-control"></input></div>
                    <div className="header-block mb-2">
                        <div className="calendar-header-btn" onClick={() => prevRange()}> <i className="fa fa-chevron-left fa-fw fa-xs color-orange" aria-hidden="true"></i> Antes</div>
                        <div className="calendar-header-btn" onClick={() => nextRange()} >Después  <i className="fa fa-chevron-right fa-fw fa-xs color-orange" aria-hidden="true"></i></div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default Header
