import getExcel from "../components/Dashboard/commons/excel/generateExcel";
import { TypeWithKey } from "../utils";

export type RowExportExcel<T> = TypeWithKey<T>[];
export type HeaderExportExcel = {
  label: string;
  value: string;
  hidden: boolean;
};

export const useDownloadExcel = <R>() => {
  const downloadExcel = (
    header: HeaderExportExcel[],
    rows: RowExportExcel<R>,
    filename: string
  ) => {
    if (filename && header) {
      let dataE = getExcel(header, rows);
      dataE.then((data) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = data.response.file;
        downloadLink.download = `${filename}.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
    }
  };

  return { downloadExcel };
};
