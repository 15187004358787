
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import { CircularProgress } from '@mui/material';
import { SearchContent } from '../../../../utilities/constantsTexts'
import { InputProps } from './interfaces';
import searchStyle from '../../styles/searchStyle.module.css'

const SearchInput = (props: InputProps) => {
  const { params, loading, open, onClearInput } = props
  const placeHolder = SearchContent.placeholder

  const clearInput = () => {
    onClearInput()
  }

  return (
  <React.Fragment>
    <SearchIcon className={searchStyle.search_icon} />
    <TextField
      {...params}
      label={placeHolder}
      InputProps={{
        ...params.InputProps,
        type: 'search',
        endAdornment: (
          <React.Fragment>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {open ? <ClearIcon onClick={clearInput} /> : null}
          </React.Fragment>
        ),
      }}
    />
  </React.Fragment>
  )
}

export default SearchInput