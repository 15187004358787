import React, { useState } from "react";
import { useForm } from "react-hook-form";
import errorMessage from "../SharedComponents/error-message";
import { PDFDocument } from "pdf-lib";
import download from "downloadjs";
import CertificadoIngresosRetencionesServices from "../../services/certificado-ingresos-retenciones/service";
import styles from "../../resources/css/style-COL.module.css";

const Form = ({ certificates }) => {
  const [isGenerating, setIsGenerating] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  const generate = async (data) => {
    try {
      setIsGenerating(true);

      // Call to service
      const existingPdfBytes =
        await CertificadoIngresosRetencionesServices.generateFilledPdf(
          data?.selectedCertificate
        );

      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      const pdfBytes = await pdfDoc.save();

      // Figure out the file name
      let fileName = "report.pdf";
      if (Array.isArray(certificates)) {
        const certificate = certificates?.find(
          (c) => c.id.toString() === data?.selectedCertificate.toString()
        );
        fileName = `${certificate?.year} - ${certificate?.name}`;
      }

      download(pdfBytes, fileName, "application/pdf");

      setIsGenerating(false);
    } catch (error) {
      errorMessage(null, "");
      setIsGenerating(false);
    }
  };

  return (
    <>
      <div className={`${styles["box"]} ${styles["mb-4"]}`}>
        <div className={styles["row"]}>
          <h2 className={`${styles["h4"]} ${styles["tit-section"]} ${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
            Generar Certificado
          </h2>
        </div>
        <form>
          {/* <!-- Fila 0 --> */}
          <div className={styles["row"]}>
            <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
              <label className={styles["form-label"]}>
                Año a Generar <span className={styles["color-red"]}>*</span>
              </label>
              <div className={styles["input-group"]}>
                <select
                  className={styles["form-select"]}
                  {...register("selectedCertificate", {
                    required: "* Campo obligatorio",
                  })}
                >
                  <option value="" selected>
                    Seleccione
                  </option>

                  {certificates
                    .filter((c) => c.enable === true)
                    .map((list) => {
                      return (
                        <option key={list.id} value={list.id}>
                          {`${list.year} - ${list.name}`}
                        </option>
                      );
                    })}
                </select>
              </div>
              <small className={styles["form-error-message"]}>
                {errors?.selectedCertificate?.message}
              </small>
            </div>
            <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}></div>
          </div>
        </form>

        <div
          className={
            styles["d-grid"] +
            " " +
            styles["d-md-flex"] +
            " " +
            styles["justify-content-md-between"] +
            " " +
            styles["gap-2"] +
            " " +
            styles["flex-nowrap"] +
            " " +
            styles["mt-4"]
          }
        >
          <div className={`${styles["d-grid"]} ${styles["d-md-flex"]} ${styles["gap-2"]}`}>
            <button
              type="button"
              className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["order-md-last"]} ${styles["ms-md-2"]}`}
              onClick={handleSubmit(generate)}
              disabled={isGenerating}
            >
              Generar{" "}
              {isGenerating ? (
                <div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div>
              ) : (
                <i
                className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                  aria-hidden="true"
                ></i>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
