import { useEffect, useMemo } from "react";
import { useTableProvider } from "../../context/DashboardTableContext";
import { useTeamDashboardProvider } from "../../context/TeamDashboardContext";
import {
  ColumnHelper,
  ColumnsConfig,
  Table,
} from "../../../../components/table";
import { Link } from "react-router-dom";
import { CompensationsMapper, CompensationsResponse } from "../../hooks";
import { DependenceCell } from "../table/DependenceCell";
import { CountryCell } from "../managmentPayRoll/CountryCell";
import { Accordion, AccordionItem } from "../../../../components/accordion";
import { NameCell } from "../table";

export const Compensations = () => {
  const {
    tableFetchInfo,
    flagSelect,
    setDataTableToExport,
    idSubLeader,
    sortingDataTable,
    filterByFlagDataTable,
  } = useTableProvider();
  const { getSelectedMenu } = useTeamDashboardProvider();
  const dataInfo = tableFetchInfo.find(
    (fetch) => fetch.key === getSelectedMenu?.name
  );

  const toRenderList = useMemo(() => {
    if (dataInfo) {
      const { subDatable, dataTable } = dataInfo.response;
      return idSubLeader
        ? (subDatable as CompensationsResponse)
        : (dataTable as CompensationsResponse);
    }

    return [];
  }, [dataInfo, idSubLeader]);

  const columnsConfig: ColumnsConfig[] = [
    { accessor: "name", header: "Nombre", className: "col-2" },
    { accessor: "country", header: "País", className: "text-nowrap" },
    { accessor: "salary", header: "Sueldo Base", className: "text-nowrap" },
    { accessor: "monthly_rent", header: "Renta Bruta Mensual" },
    { accessor: "current_date", header: "Fecha Posición Actual" },
    { accessor: "cr", header: "Cr" },
    { accessor: "position", header: "Posicionamiento" },
    {
      accessor: "dependence",
      header: "Dependencia",
      className: "text-center",
      enabledSort: false,
    },
  ];

  useEffect(() => {
    dataInfo?.response && buildExport();
  }, [dataInfo]);

  useEffect(() => {
    if (flagSelect) filterByFlagDataTable(getSelectedMenu!.name);
  }, [flagSelect]);

  const buildDataTable = useMemo(() => {
    return toRenderList.map((item) => ({
      ...item,
      name: (
        <NameCell
          name={item.name}
          photo={item.photo}
          lastName={item.last_name}
          countryCode={item.country}
        />
      ),
      country: <CountryCell country={item.country} />,
      dependence: (
        <DependenceCell
          document={item.nro_document}
          hasSubordinates={item.hasSubordinates}
        />
      ),
    }));
  }, [dataInfo?.response]);

  const buildExport = () => {
    setDataTableToExport({
      header: columnsConfig.map((value) => ({
        label: value.header,
        value: value.accessor,
        hidden: false,
      })),
      rows: toRenderList,
      filename: getSelectedMenu?.filenameExport || "",
    });
  };

  const handleSort = (id: string, desc: boolean) => {
    const sorted = toRenderList.sort((a, b) => {
      const sortID = id as keyof typeof a;
      let aValue = a[sortID] || -1;
      let bValue = b[sortID] || -1;
      if (aValue === "-") aValue = "";
      if (bValue === "-") bValue = "";

      if (aValue < bValue) return desc ? 1 : -1;
      if (aValue > bValue) return desc ? -1 : 1;

      return 0;
    });
    sortingDataTable(
      getSelectedMenu!.name,
      !!idSubLeader,
      (sorted as CompensationsResponse) || []
    );
  };

  const buildaccordionList = () => {
    return toRenderList.map((item) => ({
      headerLabel: `${item.name} ${item.last_name}`,
      items: columnsConfig.map((config) => ({
        label: config.header,
        value:
          config.accessor === "name"
            ? `${item.name} ${item.last_name}`
            : item[config.accessor as keyof CompensationsMapper],
      })),
    })) as AccordionItem[];
  };

  return (
    <>
      <Table
        data={buildDataTable as ColumnHelper[]}
        columnsConfig={columnsConfig}
        isLoading={dataInfo?.response.isLoading}
        handleSort={handleSort}
        responsiveClass="d-xs-none"
      />

      <Accordion itemList={buildaccordionList()} className="d-sm-none" />
    </>
  );
};
