import { useAxiosFetch } from "../../../hooks";
import type {
  PerformanceEvaluationApi,
  PerformanceEvaluationResponse,
  ChartDataApi,
  UseFetchTableDataReturnType,
  UsePerformanceEvaluationFetchProps,
} from "./hooksTeamDashboard.types";
import defaultProfilImage from "../../../resources/images/default-colaborador-image.png";
import { useEffect, useState } from "react";
import { useTeamDashboardProvider } from "../context/TeamDashboardContext";
import { subMenuListData } from "../context/carouselList.data";

export const usePerformanceEvaluationFetch = (
  props: UsePerformanceEvaluationFetchProps
): UseFetchTableDataReturnType<PerformanceEvaluationResponse> => {
  const { idSubLeader, selectedYear, dniList } = props;
  const { getSelectedMenu } = useTeamDashboardProvider();

  const [getPerformanceEvaluation, dataPerformanceEvaluation] = useAxiosFetch<
    PerformanceEvaluationApi[]
  >({
    method: "GET",
    url: "/api/my-teams/performance-review",
    params: {
      year: selectedYear,
    },
  });

  const [getSubPerformanceEvaluation, dataSubPerformanceEvaluation] =
    useAxiosFetch<PerformanceEvaluationApi[]>({
      method: "GET",
      url: "/api/my-teams/performance-review/subteam",
      params: {
        subleader: idSubLeader,
        year: selectedYear,
      },
    });

    const [getPerformanceChartData, responseChartData] = useAxiosFetch<ChartDataApi[]>({
      method: "GET",
      url: "/api/my-teams/performance-review/chart-data",
      params: {
        ids: dniList,
      }
    });

  useEffect(() => {
    if(dniList){
      getPerformanceChartData()
    }
  }, [dniList]);

  useEffect(() => {
    if (getSelectedMenu?.name === subMenuListData[2].name) {
      getPerformanceEvaluation();
    }
  }, [getSelectedMenu, selectedYear]);

  useEffect(() => {
    idSubLeader && getSubPerformanceEvaluation();
  }, [idSubLeader]);

  const performanceEvaluationMapper = (
    response:
      | typeof dataPerformanceEvaluation
      | typeof dataSubPerformanceEvaluation
  ): PerformanceEvaluationResponse => {
    const { data } = response;
    return (
      data?.map((employee) => ({
        nro_document: employee.nro_document || "",
        last_name: employee.last_name || "",
        name: employee.name || "",
        photo: employee.photo || defaultProfilImage,
        position: employee.position || "-",
        assessment: employee.assessment || "-",
        description: employee.description || "-",
        last_date: employee.last_date || "-",
        hasSubordinates: employee.hasSubordinates || false,
      })) || []
    );
  };

  return {
    key: subMenuListData[2].name,
    response: {
      dataTable: performanceEvaluationMapper(dataPerformanceEvaluation),
      subDatable: performanceEvaluationMapper(dataSubPerformanceEvaluation),
      isLoading:
        dataPerformanceEvaluation.isLoading ||
        dataSubPerformanceEvaluation.isLoading,
      isError:
        dataPerformanceEvaluation.isError ||
        dataSubPerformanceEvaluation.isError,
      getAllFlags: [],
      getPerformanceChartData: responseChartData.data,
    },
  };
};
