import React, { useEffect, useState } from "react";
import { BsExclamationCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import IdiomasService from "../../services/idiomas/idiomas-service";
import AgregarIdioma from "./agregar-idioma";
import DetalleIdioma from "./detalle-idioma";
import ModificarIdioma from "./modificar-idioma";
import styles from "../../resources/css/style-COL.module.css";
"../../resources/css/style-COL.css";

function Idiomas() {
    const [displayDetail, setDisplayDetail] = useState(false)
    const [displayEdit, setDisplayEdit] = useState(false)
    const [displayAdd, setDisplayAdd] = useState(false)
    const [listLanguages, setListLanguages] = useState([])
    const [loadingListLanguages, setLoadingListLanguages] = useState(false)
    const [noDataListLanguages, setNoDataListLanguages] = useState(false)

    const [writeLevel, setWriteLevel] = useState([])
    const [loadingWriteLevel, setLoadingWriteLevel] = useState(false)
    const [, setNoDataWriteLevel] = useState(false)

    const [speakLevel, setSpeakLevel] = useState([])
    const [loadingSpeakLevel, setLoadingSpeakLevel] = useState(false)
    const [, setNoDataSpeakLevel] = useState(false)

    const [infoLanguages, setInfoLanguages] = useState([])
    const [infoLanguage, setInfoLanguage] = useState({})
    const [loadingLanguages, setLoadingLanguages] = useState(false)
    const [, setNoDataLanguages] = useState(false)
    const [id, setId] = useState(null)
    const [idEdit, setIdEdit] = useState(null)

    const seeLanguage = (e, id) => {
        e.preventDefault()
        setId(id)
        setDisplayDetail(true)
        setDisplayEdit(false)
        setDisplayAdd(false)
    }

    const addLanguage = () => {
        setDisplayDetail(false)
        setDisplayEdit(false)
        setDisplayAdd(true)
    }

    const changeDisplay = (d, idx, infoLanguage) => {
        setInfoLanguage(infoLanguage)
        setIdEdit(idx)
        setDisplayEdit(d)
        setDisplayAdd(false)
        setDisplayDetail(false)
    }

    const reloadLanguages = () => {
        setDisplayEdit(false)
        setDisplayAdd(false)
        setDisplayDetail(false)
        setLoadingLanguages(true)
        setLoadingListLanguages(true)
        setLoadingSpeakLevel(true)
        setLoadingWriteLevel(true)
        IdiomasService.GetLanguages(setInfoLanguages, setLoadingLanguages, setNoDataLanguages)
        IdiomasService.GetListLanguages(setListLanguages, setLoadingListLanguages, setNoDataListLanguages)
        IdiomasService.GetSpeakLevels(setSpeakLevel, setLoadingSpeakLevel, setNoDataSpeakLevel)
        IdiomasService.GetWriteLevels(setWriteLevel, setLoadingWriteLevel, setNoDataWriteLevel)
    }

    useEffect(() => {
        setLoadingLanguages(true)
        setLoadingListLanguages(true)
        setLoadingSpeakLevel(true)
        setLoadingWriteLevel(true)
        IdiomasService.GetLanguages(setInfoLanguages, setLoadingLanguages, setNoDataLanguages)
        IdiomasService.GetListLanguages(setListLanguages, setLoadingListLanguages, setNoDataListLanguages)
        IdiomasService.GetSpeakLevels(setSpeakLevel, setLoadingSpeakLevel, setNoDataSpeakLevel)
        IdiomasService.GetWriteLevels(setWriteLevel, setLoadingWriteLevel, setNoDataWriteLevel)
    }, [])

    return (
        <>
            {/* <!-- inicio breadcrumbs -->   */}
            <nav className={`${styles["breadcrumbs"]} ${styles["d-none"]} ${styles["d-lg-block"]}`} aria-label="breadcrumb">
                <ol className={styles["breadcrumb"]}>
                    <li className={styles["breadcrumb-item"]}>
                        <Link to="/inicio">
                            <i className="fa fa-home" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className={styles["breadcrumb-item"]}>Actualización de Información</li>
                    <li className={styles["breadcrumb-item"]}>Idiomas</li>
                    {displayAdd && <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">Agregar Idiomas</li>}
                    {displayEdit && <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">Modificar Idiomas</li>}
                </ol>
            </nav>
            {/* <!-- fin breadcrumbs --> */}

            {/* <!-- inicio Idiomas --> */}
                <h1>Idiomas</h1>
                {(loadingLanguages && loadingSpeakLevel && loadingWriteLevel && loadingListLanguages) ? <div data-testid="loading" className={styles["full-spinner"]}>
                        <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
                    </div> : noDataListLanguages ? <div className={`${styles["alert"]} ${styles["alert-info"]} ${styles["alert-dismissible"]} ${styles["fade"]} ${styles["show"]} ${styles["text-center"]}`} role="alert">
                        <BsExclamationCircle />  No se encontraron datos asociados
                    </div> : <section className={styles["idiomas"] + " " + "cencosud-col"}>

                <div className={`${styles["box"]} ${styles["mb-4"]}`}>
                    <div className={`${styles["tit-group"]} ${styles["d-flex"]} ${styles["justify-content-between"]}`}>
                        <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Detalle</h2>
                        <Link to="#" onClick={addLanguage} className={`${styles["h5"]} ${styles["mt-2"]} ${styles["fl-normal"]}`}><span className={`${styles["d-none"]} ${styles["d-sm-inline-block"]}`}>Agregar idioma</span><i className={`${styles["fa"]} ${styles["fa-plus-square-o"]} ${styles["fa-lg"]} ${styles["ms-2"]}`} aria-hidden="true"></i></Link>
                    </div>

                    <p>Datos Obligatorios <span className={styles["color-red"]}>*</span></p>
                    {infoLanguages.length !== 0 ? <>
                    {/* <!-- Escritorio (Tabla) --> */}
                    <div className={styles["table-responsive"]}>
                        <table className={`${styles["table"]} ${styles["table-even"]} ${styles["table-section"]} ${styles["table-borderless"]} ${styles["d-none"]} ${styles["d-md-table"]}`}>
                            <thead>
                                <tr>
                                    <th>Idioma</th>
                                    <th>Nivel Hablado</th>
                                    <th>Nivel Escrito</th>
                                    <th>Ver</th>
                                </tr>
                            </thead>
                            <tbody>
                                {infoLanguages.map((i) => {
                                    return (<tr key={i.id}>
                                        <td>{listLanguages.filter(l => i.language === l.id).map(l => l.description)[0]}</td>
                                        <td>{speakLevel.filter(s => i.speaking_level === s.id).map(s => s.description)[0]}</td>
                                        <td>{writeLevel.filter(s => i.writing_level === s.id).map(s => s.description)[0]}</td>
                                        <td>
                                            <Link to="#" onClick={(e) => seeLanguage(e, i.language)}><i className={`${styles["fa"]} ${styles["fa-eye"]} ${styles["fa-fw"]} ${styles["fa-lg"]}`} aria-hidden="true"></i></Link>
                                        </td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                    { /* <!-- Móvil (Acordeón) --> */}
                    <div className={`accordion accordion-flush accordion-light accordion-table ${styles["d-md-none"]}`}>
                        {/* <!-- 1er acordeón --> */}
                        {infoLanguages.map((i, idx) => {
                            return (<div key={i.id} className="accordion-item">
                                <h2 className="accordion-header" id="accordion">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-item-${idx}`} aria-expanded="false" aria-controls={`accordion-item-${idx}`}>
                                        <strong>Idioma</strong> {listLanguages.filter(l => i.language === l.id).map(l => l.description)[0]}
                                    </button>
                                </h2>
                                <div id={`accordion-item-${idx}`} className="accordion-collapse collapse" aria-labelledby="accordion">
                                    <div className={`accordion-body ${styles["p-0"]}`}>
                                        {/* <!-- cuerpo item --> */}
                                        <div className={`${styles["data-list-box"]} ${styles["data-list-box-even"]}`}>
                                            <ul className={`${styles["data-list-section--item"]} ${styles["list-unstyled"]}`}>
                                                <li>
                                                    <span className={styles["data-list-box__title"]}>Nivel Hablado</span>
                                                    <span className={styles["data-list-box__text"]}>{speakLevel.filter(s => i.speaking_level === s.id).map(s => s.description)[0]}</span>
                                                </li>
                                                <li>
                                                    <span className={styles["data-list-box__title"]}>Nivel Escrito</span>
                                                    <span className={styles["data-list-box__text"]}>{writeLevel.filter(s => i.writing_level === s.id).map(s => s.description)[0]}</span>
                                                </li>
                                                <li>
                                                    <span className={styles["data-list-box__title"]}>Ver</span>
                                                    <span className={styles["data-list-box__text"]}>
                                                        <Link to="#" onClick={(e) => seeLanguage(e, i.language)}><i className={`${styles["fa"]} ${styles["fa-eye"]} ${styles["fa-fw"]} ${styles["fa-lg"]}`} aria-hidden="true"></i></Link>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* <!-- fin cuerpo item --> */}
                                    </div>
                                </div>
                            </div>)
                        })}
                        {/* <!-- fin items acordeón --> */}
                    </div></> : <div className={`${styles["alert"]} ${styles["alert-info"]} ${styles["alert-dismissible"]} ${styles["fade"]} ${styles["show"]} ${styles["text-center"]}`} role="alert">
                        <BsExclamationCircle />  No se cargaron idiomas
                    </div>}
                    {/* <!-- fin Móvil (Acordeón) --> */}
                </div>
                {/* <!-- Fin Detalle --> */}
                {displayAdd && <AgregarIdioma listLanguages={listLanguages} speakLevel={speakLevel} writeLevel={writeLevel} changeDisplay={changeDisplay} reloadLanguages={reloadLanguages} />}
                {displayDetail && <DetalleIdioma listLanguages={listLanguages} speakLevel={speakLevel} writeLevel={writeLevel} changeDisplay={changeDisplay} id={id} reloadLanguages={reloadLanguages} />}
                {displayEdit && <ModificarIdioma listLanguages={listLanguages} speakLevel={speakLevel} writeLevel={writeLevel} id={idEdit} infoLanguage={infoLanguage} reloadLanguages={reloadLanguages} />}
            </section>}
        </>
    )
}

export default Idiomas;