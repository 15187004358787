import { Dispatch, RefObject, SetStateAction } from "react";
import * as t from "io-ts";
import { Control, FieldErrors, UseFormClearErrors, UseFormRegister, UseFormSetError, UseFormSetValue, UseFormTrigger, UseFormWatch } from "react-hook-form";

interface refs {
    refStatus?: RefObject<HTMLButtonElement>;
    refConfirmAction?: RefObject<HTMLButtonElement>;
    refLoad?: RefObject<HTMLButtonElement>;
    refLoadClose?: RefObject<HTMLButtonElement>;
    refCloseStatus?: RefObject<HTMLButtonElement>;
}
interface formPropsBenefit {
    control: Control<BenefitProps>;
    errors: FieldErrors<BenefitProps>;
}
interface showTable {
    setShowTable: Dispatch<SetStateAction<boolean>>;
}
interface IndexProps extends refs, showTable {
    setNewBenefit: Dispatch<SetStateAction<boolean>>;
    setAdminCategory: Dispatch<SetStateAction<boolean>>;
    setIdBenefit: Dispatch<SetStateAction<number>>;
    setIsEditBenefit: Dispatch<SetStateAction<boolean>>;
    idBenefit: number;
    reload: boolean;
    setReload: Dispatch<SetStateAction<boolean>>;
    pais: string
}
interface TableProps extends refs, showTable {
    benefitList: ResponseBenefitListData;
    filterCountry: string;
    startDateFilter: string;
    endDateFilter: string;
    filterCategory: number;
    filterStatus: string;
    setIdBenefit: Dispatch<SetStateAction<number>>;
    setIsEditBenefit: Dispatch<SetStateAction<boolean>>;
    setDataModal: Dispatch<SetStateAction<DataModalDinamicProps>>;
}
interface SelectIndexProps {
    filters: filtersIndex;
    pais: string;
    filterCountry: string;
    setFilterCountry: Dispatch<SetStateAction<string>>;
    setStartDateFilter: Dispatch<SetStateAction<string>>;
    setEndDateFilter: Dispatch<SetStateAction<string>>;
    setFilterCategory: Dispatch<SetStateAction<number>>;
    setFilterStatus: Dispatch<SetStateAction<string>>;
}
interface TableCategoryProps extends refs {
    categoryList: CategoryProps[];
    setIsEditCategory: Dispatch<SetStateAction<boolean>>;
    setEditProps: Dispatch<SetStateAction<CategoryProps>>;
}
interface AdminCategoryProps extends refs, showTable {
    pais: string
    setAdminCategory: Dispatch<SetStateAction<boolean>>;
}
interface ContentBenefitProps extends formPropsBenefit {
    isEditBenef: boolean;
    paramFilter: ParametersFilter[];
    textDescr: string;
    textCond: string;
    errorUrl: string;
    selectModule: string;
    arrayLinks: typeLinks[];
    history: HistoryProp[];
    setArrayLinks: Dispatch<SetStateAction<typeLinks[]>>;
    setTextDescr: Dispatch<SetStateAction<string>>;
    setTextCond: Dispatch<SetStateAction<string>>;
    setValue: UseFormSetValue<BenefitProps>;
    setSelectModule: Dispatch<SetStateAction<string>>;
    register: UseFormRegister<BenefitProps>;
    trigger: UseFormTrigger<BenefitProps>;
}
interface NewBenefitProps extends refs {
    isEditBenefit: boolean;
    idBenefit: number;
    setShowTable: Dispatch<SetStateAction<boolean>>;
    setNewBenefit: Dispatch<SetStateAction<boolean>>;
    setIsEditBenefit: Dispatch<SetStateAction<boolean>>;
    setIdBenefit: Dispatch<SetStateAction<number>>;
    pais: string
}
interface SearchBarProps {
    wordEntered: string;
    iconArray: SearchProps[];
    errors: FieldErrors<CategoryProps>;
    register: UseFormRegister<CategoryProps>;
    setSearchResults: Dispatch<SetStateAction<SearchProps[]>>;
    setWordEntered: Dispatch<SetStateAction<string>>;
}
interface SearchProps {
    label: string;
    value: string;
}
interface DataModalDinamicProps {
    icon: string;
    msg: string;
    submsg?: string;
    callBack: () => void;
}
interface DynamicSelectProps {
    arrayData: Data[];
    setStatus: Dispatch<SetStateAction<any>>;
    status?: string;
    isLabel: boolean;
    pais?: string;
    isRequired?: boolean;
}
interface ModalAsignCatProps extends refs {
    editProps: CategoryProps;
    isEditCategory: boolean;
    defaultPropsEdit: CategoryProps
    countryFilters: Data[];
    pais: string;
    setEditProps: Dispatch<SetStateAction<CategoryProps>>;
    setReload: Dispatch<SetStateAction<boolean>>;
    setDataModal: Dispatch<SetStateAction<DataModalDinamicProps>>;
}
interface Groups {
    id: number | string;
    title: string;
    description?: string;
    disabled?: boolean
}
interface SelectGroupProps {
    arrayGroup: Groups[];
    setArrayGroup: Dispatch<SetStateAction<Groups[]>>;
    group: typeGroups[];
}
interface typeLinks {
    id?: number;
    url: string;
}
interface SelectLinkProps {
    arrayLinks: typeLinks[];
    setArrayLinks: Dispatch<SetStateAction<typeLinks[]>>;
}
interface typeFile {
    file: string;
    name_file: string;
}
interface FormBenefitProps extends formPropsBenefit {
    msjErrorDate: string;
    isEditBenefit: boolean;
    paramFilter: ParametersFilter[];
    arrayGroup: Groups[];
    selectCountry: string;
    errorSelectGroup: string;
    imgBanner: string;
    imgCard: string;
    setImgBanner: Dispatch<SetStateAction<string>>;
    setSelectCountry: Dispatch<SetStateAction<string>>;
    setImgCard: Dispatch<SetStateAction<string>>;
    setFile: Dispatch<SetStateAction<typeFile>>;
    setSelectCategory: Dispatch<SetStateAction<string>>;
    setArrayGroup: Dispatch<SetStateAction<Groups[]>>;
    register: UseFormRegister<BenefitProps>;
    isErrorPhoto: boolean;
    pais: string;
    isFile: boolean;
    idBenefit: number;
    clearErrors: UseFormClearErrors<BenefitProps>;
    setError: UseFormSetError<BenefitProps>;
    file: typeFile;
    setIsFile: Dispatch<SetStateAction<boolean>>;
    setValue: UseFormSetValue<BenefitProps>;
    watch: UseFormWatch<BenefitProps>;
}

interface modalPreview {
    watchedValues: any,
    imgCard: string,
    arrayLinks: typeLinks[];
}

//Groups

export const groupsArray = t.type({
    id: t.union([t.string, t.number]),
    title: t.string,
    disabled: t.union([t.undefined, t.boolean])
})

export type typeGroups = t.TypeOf<typeof groupsArray>

export const groupsParams = t.type({
    valid: t.string,
    status: t.string,
    groups: t.array(groupsArray)
});

export const dataSelects = t.type({
    id: t.union([t.string, t.number]),
    label: t.string,
    country_id: t.union([t.string, t.undefined]),
})

export type Data = t.TypeOf<typeof dataSelects>

export const FiltersTable = t.type({
    countryFilters: t.array(dataSelects),
    categoryFilters: t.array(dataSelects),
    statusFilter: t.array(dataSelects),
})

export const BenefitsTable = t.type({
    id: t.number,
    created_by: t.string,
    title: t.string,
    date_to: t.string,
    date_from: t.string,
    status: t.string,
    category: t.type({
        id: t.number,
        title: t.string,
        country: t.type({
            id: t.string,
            description: t.string
        })
    }),
    benef_group: t.array(t.type({
        title: t.string
    })),
    likes: t.array(t.type({
        total_likes: t.number,
    })),
    total_views: t.number,
})

export type BenefitTable = t.TypeOf<typeof BenefitsTable>

export type filtersIndex = t.TypeOf<typeof FiltersTable>;

export const ResponseBenefit = t.type({
    benefits: t.array(BenefitsTable),
    filters: FiltersTable,
});

export const fetchGetBenefitListCodec = t.type({
    response: ResponseBenefit
});

export type ResponseBenefitListData = t.TypeOf<typeof ResponseBenefit>;

export type ResponseBenefitList = t.TypeOf<typeof fetchGetBenefitListCodec>;

export const fetchPostBenefitCodec = t.type({
    valid: t.boolean
});
export interface bodyPostCategoryBenefit {
    id?: number,
    country_id?: string,
    title?: string,
    icon?: string,
}
export interface bodyPostBenefits {
    id?: number,
}

export type PostBenefit = t.TypeOf<typeof fetchPostBenefitCodec>;

//Category

export const CategoryTable = t.type({
    id: t.union([t.undefined, t.number]),
    country_id: t.string,
    description_country: t.string,
    icon: t.string,
    title: t.string,
})

export const ResponseCategory = t.type({
    categories: t.array(CategoryTable),
    countryFilter: t.array(dataSelects),
});

export type CategoryProps = t.TypeOf<typeof CategoryTable>

export const fetchGetCategoryListCodec = t.type({
    response: ResponseCategory
});

export type ResponseCategoryList = t.TypeOf<typeof fetchGetCategoryListCodec>;

//Benefit

export const Benefit = t.type({
    id: t.union([t.undefined, t.number]),
    title: t.string,
    is_visible_title: t.boolean,
    is_featured: t.boolean,
    subtitle: t.string,
    category_id: t.number,
    img_banner: t.string,
    img_card: t.string,
    date_to: t.string,
    date_from: t.string,
    file: t.string,
    name_file: t.string,
    description: t.string,
    condition: t.string,
    //is_condition_visible: t.boolean,
    // is_request: t.boolean,
    module_id: t.union([t.number, t.null]),
    status: t.string,
})

export interface bodyPostBenefit {
    id?: number,
    title: string,
    is_visible_title: boolean,
    is_featured: boolean,
    subtitle: string,
    category_id: number,
    img_banner: string,
    img_card: string,
    date_to: string,
    date_from: string,
    file: string,
    name_file: string,
    description: string,
    condition: string,
    //is_condition_visible: boolean,
    // is_request: boolean,
    module_id: string | null,
    status: string,
    country: string,
    groups: Groups[],
    links: typeLinks[]
}
//History
export const HistoryProps = t.type({
    full_name: t.string,
    created_at: t.string,
})

export type HistoryProp = t.TypeOf<typeof HistoryProps>

export const reponseGetBenefit = t.type({
    id: t.number,
    title: t.string,
    is_visible_title: t.boolean,
    is_featured: t.union([t.boolean, t.undefined]),
    subtitle: t.string,
    category_id: t.number,
    date_to: t.union([t.string, t.undefined]),
    date_from: t.union([t.string, t.undefined]),
    description: t.string,
    condition: t.string,
    //is_condition_visible: t.boolean,
    module: t.union([t.type({
        id: t.number,
        component: t.type({
            url: t.string
        })
    }), t.null]),
    module_id: t.union([t.number, t.null]),
    status: t.union([t.string, t.undefined]),
    country: t.string,
    country_id: t.string,
    created_by: t.union([t.string, t.undefined]),
    videos: t.array(t.type({
        id: t.number,
        url: t.string
    })),
    history: t.union([t.undefined, t.array(HistoryProps)]),
    total_likes: t.number,
    own_like: t.number,
})

export type BenefitProps = t.TypeOf<typeof Benefit>

export type ReponseGetBenefit = t.TypeOf<typeof reponseGetBenefit>


//Benefit by ID
export const moduleReqType = t.type({
    id: t.number,
    component_id: t.number,
    title: t.string,
    component: t.type({
        url: t.string
    })
});

export const fetchGetBenefitByIDCodec = t.type({
    response: t.type({
        benefit: reponseGetBenefit,
        groups: t.array(groupsArray),
        isFile: t.boolean,
    })
});

export type GetBenefitByIDProps = t.TypeOf<typeof fetchGetBenefitByIDCodec>;

//parameters filter

export const Parameters = t.type({
    country: t.string,
    countryDescr: t.string,
    categories: t.array(
        t.type({
            id: t.string,
            label: t.string,
            categories: t.array(dataSelects)
        })),
    countryFilters: t.array(dataSelects),
    modules: t.array(dataSelects),
    groups: t.array(groupsArray),
})

export const ParamsBenefit = t.type({
    parameters: t.array(Parameters)
});

export const fetchGetParamsBenefitCodec = t.type({
    response: ParamsBenefit
});

export type GetParamsBenefit = t.TypeOf<typeof fetchGetParamsBenefitCodec>;

export type ParametersFilter = t.TypeOf<typeof Parameters>

//Banner

export const bannerProps = t.type({
    id: t.number,
    img_banner: t.string,
    title: t.string,
    is_visible_title: t.boolean,
})

export type BannerProps = t.TypeOf<typeof bannerProps>;

export const bannerHomeProps = t.type({
    id: t.number,
    img_card: t.string,
    title: t.string,
    subtitle: t.string
})

export type BannerHomeProps = t.TypeOf<typeof bannerHomeProps>;


export const fetchGetBannersCodec = t.type({
    response: t.type({
        banner: t.array(bannerProps)
    })
});

export type GetBanner = t.TypeOf<typeof fetchGetBannersCodec>;

export const fetchGetBannersCodecHome = t.type({
    response: t.type({
        banner: t.array(bannerHomeProps)
    })
});

export type GetBannerHome = t.TypeOf<typeof fetchGetBannersCodecHome>;

//Benefit cards

export const Categories = t.type({
    id: t.number,
    title: t.string,
    icon: t.string
});

export type categories = t.TypeOf<typeof Categories>;

export const BenefitCards = t.type({
    id: t.number,
    title: t.string,
    subtitle: t.string,
    img_card: t.union([t.string, t.undefined]),
    category: Categories
});

export type benefitsCards = t.TypeOf<typeof BenefitCards>;

export const fetchGetBenefitCardCodec = t.type({
    response: t.type({
        categories: t.array(Categories),
        benefits: t.array(BenefitCards)
    })
});

export type GetBenefitCard = t.TypeOf<typeof fetchGetBenefitCardCodec>;

//photo benefit

export const photoBenefit = t.type({
    id: t.number,
    img_card: t.string,
    img_banner: t.union([t.string, t.undefined])
})

export type PhotoBenefitProps = t.TypeOf<typeof photoBenefit>;

export const fetchGetPhotoBenefitCodec = t.type({
    response: t.type({
        photo: t.array(photoBenefit)
    })
});

export type GetPhotoBenefit = t.TypeOf<typeof fetchGetPhotoBenefitCodec>;

//file

export const fetchGetFileBenefitCodec = t.type({
    response: t.type({
        file: t.string,
        name_file: t.string,
    })
});

export type GetFileBenefit = t.TypeOf<typeof fetchGetFileBenefitCodec>;

//banner benefit by ID

export const bannerBenefit = t.type({
    id: t.number,
    img_banner: t.string,
    title: t.string,
})

export type BannerBenefitProps = t.TypeOf<typeof bannerBenefit>;

export type {
    IndexProps,
    TableProps,
    SelectIndexProps,
    AdminCategoryProps,
    ContentBenefitProps,
    NewBenefitProps,
    ModalAsignCatProps,
    SelectGroupProps,
    Groups,
    FormBenefitProps,
    TableCategoryProps,
    SearchBarProps,
    SearchProps,
    DynamicSelectProps,
    SelectLinkProps,
    typeLinks,
    typeFile,
    DataModalDinamicProps,
    modalPreview
};