import React, { useState, useEffect, Children } from "react";
import ReactPaginate from "react-paginate";
import { transpose } from "../../services/utiles";

const PaginatorTable = ({ itemsPerPage, items, setResetOffset, resetOffset, constHeader, header, hiddenMediaColumn = [] }, props) => {
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [matrix, setMatrix] = useState([]);

    useEffect(() => {
        if (items !== "error" && Array.isArray(items) && items.length > 0) {
            let newMatrix = items.map((item) => {
                return item.rows;
            });
            setMatrix(transpose(newMatrix));
        }
    }, [items]);

    useEffect(() => {
        if (matrix !== "error" && Array.isArray(matrix) && matrix.length > 0) {
            let temp = matrix.slice(itemOffset, itemOffset + itemsPerPage);

            setCurrentItems(temp);
            setPageCount(Math.ceil(matrix.length / itemsPerPage));
        } else if (matrix !== "error" && Array.isArray(matrix) && matrix.length === 0) {
            setCurrentItems(null);
            setPageCount(0);
        }
    }, [itemOffset, itemsPerPage, matrix]);

    useEffect(() => {
        if (resetOffset) {
            setItemOffset(0);
            setResetOffset(false);
        }
    }, [resetOffset]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % matrix.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            <>
                <table className="table table-even table-section table-borderless align-middle d-none d-md-table">
                    <thead>
                        <tr>
                            {items
                                ? Children.toArray(
                                      items.map((column) => {
                                          return <th>{column.title}</th>;
                                      })
                                  )
                                : null}
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems ? (
                            Children.toArray(
                                currentItems.map((rows) => {
                                    return (
                                        <tr>
                                            {Children.toArray(
                                                rows.map((value) => {
                                                    return <td>{value}</td>;
                                                })
                                            )}
                                        </tr>
                                    );
                                })
                            )
                        ) : (
                            <tr>
                                <td>Sin resultados</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className="accordion accordion-flush d-block d-md-none accordion-light accordion-table">
                    {currentItems ? (
                        Children.toArray(
                            currentItems.map((rows, i) => {
                                return (
                                    <>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id={"acordeon-" + i}>
                                                <button className={i === 0 ? "accordion-button" : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={"#acordeon-item-" + i} aria-expanded="true" aria-controls={"accordion-item-" + i}>
                                                    <strong className="text-uppercase">{constHeader}</strong>
                                                    {header ? header[i+itemOffset] : <></>}
                                                </button>
                                            </h2>
                                            <div id={"acordeon-item-" + i} className={i === 0 ? "accordion-collapse collapse show" : "accordion-collapse collapse "} aria-labelledby={"acordeon-" + i}>
                                                <div className="accordion-body p-0">
                                                    <div className="data-list-box data-list-box-even">
                                                        <ul className="data-list-section--item list-unstyled">
                                                            {Children.toArray(
                                                                rows.map((value, index) => {
                                                                    let hidden = false;
                                                                    if (hiddenMediaColumn.length > 0) {
                                                                        hiddenMediaColumn.forEach((c) => {
                                                                            if (index === c) {
                                                                                hidden = true;
                                                                            }
                                                                        })
                                                                    }
                                                                    return (
                                                                        !hidden ?
                                                                        <li>
                                                                            <span className="data-list-box__title col-12 col-sm-4">{items[index].title}</span>
                                                                            <span className="data-list-box__text">{value}</span>
                                                                        </li>
                                                                        : <></>
                                                                    );
                                                                })
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                );
                            })
                        )
                    ) : (
                        <div>
                            <p>SIN RESULTADOS</p>
                        </div>
                    )}
                </div>
            </>
            {currentItems ? (
                <nav className="mt-5" aria-label="Paginación">
                    <ReactPaginate id={"paginationnav"} className="pagination justify-content-center align-items-center" breakLabel=" ... " nextLabel=" > " onPageChange={handlePageClick} pageRangeDisplayed={0} marginPagesDisplayed={1} pageCount={pageCount} previousLabel=" < " renderOnZeroPageCount={null} previousLinkClassName={"page-link"} nextLinkClassName={"page-link"} breakLinkClassName={"page-link"} pageLinkClassName={"page-link"} pageClassName={"page-item"} breakClassName={"page-item"} previousClassName={"page-item"} nextClassName={"page-item"} activeClassName={"page-item active"} activeLinkClassName={"page-link active"} />
                </nav>
            ) : null}
        </>
    );
};

export default PaginatorTable;
