import Auth from "@aws-amplify/auth";
import { urlsAws } from "../resources/foo/api-endpoints";
import { decrypt } from "./utiles";

const remuneracionService = {
    getremuneracion: (emplid, month, year, setData, userDt = undefined) => {
        Auth.currentSession().then((auth) => {
            let requestOptions = {};
            requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: JSON.stringify({ request: { emplid: emplid, month: month, year: year, userDt: userDt } }),
            };
            fetch(urlsAws.getRemuneracionLista , requestOptions)
                .then((response) => response.json())
                .then((data) => data.response)
                .then((data) => {
                    if (data !== undefined) {
                        if (!Array.isArray(data.haberes)) {
                            data.haberes = [data.haberes];
                        }
                        caches.open("api-cache").then((cache) => {
                            cache.put(urlsAws.getRemuneracionLista + "?month=" + month + "&year=" + year + "&emplid=" + emplid, new Response(JSON.stringify(data.haberes)));
                            setData(data.haberes);
                        });
                    } else {
                        throw new error("Error inesperado")
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    caches.match(urlsAws.getRemuneracionLista + "?month=" + month + "&year=" + year + "&emplid=" + emplid).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                if (data) {
                                    setData(data);
                                } else {
                                    setData("");
                                }
                            });
                        } else {
                            setData("");
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },

    getremuneracion2: (emplid, month, year, setData, email, format, data_1, concept, setValidarEmail, setModalOff, setCargando, userDt) => {
        Auth.currentSession().then((auth) => {
            let requestOptions = {};
    
            if (data_1 !== []) {
                if (email === "" && email !== undefined) {
                    requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                        body: JSON.stringify({ request: { emplid: emplid, month: String(month), year: String(year), email: "", concept: concept, type: "1", instance: "0", format: format, userDt: userDt } }),
                    };
                } else {
                    requestOptions = {
                        crossDomain: true,
                        method: "POST",
                        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                        body: JSON.stringify({ request: { emplid: emplid, month: String(month), year: String(year), email: email, concept: concept, type: "1", instance: "0", format: format, userDt: userDt } }),
                    };
                }
            }
            fetch(urlsAws.getRemuneracionDetalle , requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    //email
                    if (data !== undefined) {
                        if (!email) {
                            caches.open("api-cache").then((cache) => {
                                cache.put(urlsAws.getRemuneracionDetalle + "?month=" + month + "&year=" + year + "&format=" + format, new Response(JSON.stringify(data.response)));
                                setData(data.response);
                            });
                        } else {
                            setValidarEmail(true);
                        }
                    }
                })
                .catch((error) => {
                    caches.match(urlsAws.getRemuneracionDetalle + "?month=" + month + "&year=" + year + "&format=" + format).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                setData(data);
                            });
                        } else {
                            setCargando(false);
                            const urlBase = window.location.pathname.split("/", 4);
                            if (urlBase[2].includes("-dt")) {
                                setModalOff("offline");
                            } else {
                                document.body.removeChild(document.getElementById("modal-overlay"));
                                document.body.classList.remove("modal-open");
                                document.body.style.overflow = "auto";
                                document.body.style.paddingRight = "0";
    
                                document.getElementById("abrirModalcertificados").click();
                            }
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },
};

export default remuneracionService;
