import noticiasService from "../services/noticias-service";
import { useState, useEffect } from "react";
import { Base64 } from "js-base64";
import React from "react";
import { formatDate } from "../services/utiles";
import { Link, useParams } from "react-router-dom";
import useOnlineState from "./elements/online-hook";
import { useForm } from "react-hook-form";
import SinConexion from "./elements/modal-sin-conexion";
import { urls } from "../resources/foo/api-endpoints";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
} from "react-share";
const NoticiasDetalle = () => {
  const [dataNoticiaFull, setDataNoticiaFull] = useState(null);
  const [content, setContent] = useState("");
  const [ownLike, setOwnLike] = useState(false);
  const [totalLike, setTotallike] = useState(0);
  const [casillaComentario, setCasillaComentario] = useState(false);
  const [comentarios, setComentarios] = useState(false);
  const [estadoCargaComentarios, setestadoCargaComentarios] = useState(false);
  const [estadoCargaEnviarComentarios, setestadoCargaEnviarComentarios] = useState(false);
  const [estadoEliminado, setestadoEliminado] = useState(false);
  const [estadoGuardado, setestadoGuardado] = useState(false);
  const [eliminar, setEliminar] = useState(false);
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const isOnline = useOnlineState();
  const location = window.location.href;
  let id = useParams().id;
  const styles = {
    like: { color: "red" },
  };
  useEffect(() => {
    if (!dataNoticiaFull) {
      caches.open("api-cache")
        .then((cache) => {
          cache.match(urls.getListadoNoticiaUniqueLocal + "?id=" + id)
            .then((response) => response.json())
            .then((data) => {
              if (data && data.id) {
                  return data;
              }
              throw new Error();
            })
            .then((data) => {
              setDataNoticiaFull(data);
            })
            .catch((error) => {
              noticiasService.getNoticiasFull(id, setDataNoticiaFull);
            });
        });
    }
  }, []);
  useEffect(() => {
    if (comentarios === "error" || comentarios === false) {
      noticiasService.getComentarios(
        id,
        setComentarios,
        setestadoCargaComentarios
      );
    }
  }, [comentarios]);
  useEffect(() => {
    if (dataNoticiaFull && !dataNoticiaFull.message) {
      let temp =
        '<h2 className="h4 tit-section">' +
        (dataNoticiaFull.visible_title ? dataNoticiaFull.titulo : "") +
        "</h2>" +
        dataNoticiaFull.contenido;
      temp = temp.replaceAll("<img", '<img style="width:100%" ');
      setContent(temp);
      setOwnLike(dataNoticiaFull.like === 0 ? false : true);
      setTotallike(dataNoticiaFull.total_like)
    } else {
      setContent("");
    }
  }, [dataNoticiaFull]);

  const base64ToArrayBuffer = (base64) => {
    let binaryString = Base64.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };
  const saveByteArrayPDF = (reportName, byte) => {
    let blob = new Blob([byte], { type: "application/pdf" });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    let fileName = reportName;
    link.download = fileName;
    link.dispatchEvent(new MouseEvent("click"));

  };
  const handleSub = (e, descripcion, base64) => {
    e.preventDefault();
    var ascii = base64ToArrayBuffer(base64);
    saveByteArrayPDF(descripcion.replaceAll(" ", "_"), ascii);
  };
  const onSubmitComentario = (data) => {
    setEliminar(false);
    noticiasService.sendComentario(
      id,
      data.comentario,
      setestadoCargaEnviarComentarios,
      setestadoGuardado,
      setComentarios
    );
    setestadoCargaEnviarComentarios("enviando");
  };
  const handleDelete = (e, idComentario) => {
    e.preventDefault();
    setEliminar(true);
    noticiasService.eliminarComentario(
      idComentario,
      setestadoEliminado,
      setComentarios
    );
    setComentarios(false);
  };
  const handleLike = (e) => {
    e.preventDefault();
    if (ownLike) {
      noticiasService.substractLikeNoticia(id);
      setTotallike(totalLike - 1);
    } else {
      noticiasService.addLikeNoticia(id);
      setTotallike(totalLike + 1);
    }
    setOwnLike(!ownLike);
  };

  return dataNoticiaFull && !dataNoticiaFull.message &&
    <div className="noticias">
      <span className="h1 d-block">Noticias</span>
      <div className="box p-4">
        <article dangerouslySetInnerHTML={{ __html: content }} />
        {Object.keys(dataNoticiaFull).includes("archivo") && dataNoticiaFull && dataNoticiaFull.archivo !== "" &&
            <a
              href="/mis-beneficios"
              onClick={(e) => {
                handleSub(
                  e,
                  dataNoticiaFull.titulo,
                  dataNoticiaFull.archivo
                );
              }}
              className="text-decoration-none"
            >
              <span className="text-uppercase fw-bolder">
                {"Para mas información click aquí "}
              </span>
              <i
                className="fa fa-file-pdf-o color-primary ms-2 fa-lg"
                aria-hidden="true"
              />
            </a>
        }
        <ul className="list-share list-unstyled color-primary text-uppercase">
          <li className="me-4 mb-2 mb-md-0">
            {"Fecha: " + formatDate(dataNoticiaFull.vig_desde)}
          </li>
          <li>
            <a href="#" id="rojo" style={ownLike ? styles.like : null} onClick={(e) => { handleLike(e); }} >
              <i className={ ownLike ? "fa fa-heart fa-fw fa-lg" : "fa fa-heart-o fa-fw fa-lg"} type="button" aria-hidden="true"/>
              {totalLike + " Me Gusta"}
            </a>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setCasillaComentario(!casillaComentario);
              }}
            >
              <i
                className="fa fa-comment-o fa-fw fa-lg"
                type="button"
                aria-hidden="true"
              ></i>
            </a>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("compartir-btn").click();
              }}
            >
              <i
                className="fa fa-paper-plane-o fa-fw fa-lg"
                type="button"
                aria-hidden="true"
              ></i>
            </a>
          </li>
        </ul>
        {
          <>
            {casillaComentario ? (
              estadoCargaEnviarComentarios === "enviando" ? (
                <div className="text-center">
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  >
                    <span className="sr-only">Guardando comentario...</span>
                  </div>
                </div>
              ) : (
                <form onSubmit={handleSubmit(onSubmitComentario)}>
                  <ul
                    className="list-share list-unstyled color-primary text-uppercase"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "40px 10px 0 10px",
                      alignItems: "flex-end",
                    }}
                  >
                    <textarea
                      id="textoComentario"
                      className="form-control"
                      rows="2"
                      placeholder="Escribe un comentario..."
                      {...register("comentario", {
                        required: "*Escribe algo antes de enviar",
                        minLength: 3,
                        maxLength: 500,
                      })}
                    ></textarea>
                    {errors.comentario && (
                      <div className="invalid-feedback d-block">
                        {errors.comentario.message}
                      </div>
                    )}
                    <li
                      className="me-4 mb-2 mb-md-0"
                      style={{ paddingTop: "20px" }}
                    >
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={(e) => {
                          if (!isOnline) {
                            e.preventDefault();
                            document
                              .getElementById("compartir-btn")
                              .click();
                          }
                        }}
                      >
                        Comentar
                      </button>
                    </li>
                  </ul>
                </form>
              )
            ) : null}
            {
              <div>
                {estadoCargaComentarios ?
                  <div className="text-center">
                    <div
                      className="spinner-border text-primary"
                      role="status"
                    >
                      <span className="sr-only">Cargando...</span>
                    </div>
                  </div>
                : comentarios ? comentarios.map((comentario) => (
                      <ul
                        key={"comentario " + comentario.id}
                        className="list-share list-unstyled text-uppercase"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "0 10px 0 10px",
                          }}
                        >
                          <p>
                            <small className="text-muted">
                              {formatDate(comentario.created_at)}
                            </small>
                          </p>
                          <Link
                            to=""
                            onClick={(e) => handleDelete(e, comentario.id)}
                            className="mb-2"
                          >
                            <i
                              className="fa fa-trash-o fa-fw"
                              aria-hidden="true"
                              data-backdrop="static"
                              data-keyboard="false"
                            ></i>
                          </Link>
                        </div>
                        <div
                          style={{
                            margin: "5px 0 0 0",
                            padding: "0 15px 0 15px",
                          }}
                        >
                          <p className="card-text">
                            {comentario.comment}
                          </p>
                        </div>
                      </ul>
                    ))
                : <div className="text-center">
                    <div>
                      <strong>Sin Comentarios que mostrar</strong>
                    </div>
                  </div>
                }
                <div
                  className="modal fade show"
                  id="status-comentario"
                  tabIndex="-1"
                  aria-labelledby="status-comentario"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header border-0 pb-0">
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body text-center py-4">
                        <h2 className="h5">
                          {eliminar
                            ? estadoEliminado
                              ? "Comentario eliminado"
                              : "No se pudo eliminar el comentario"
                            : estadoGuardado
                              ? "Comentario guardado con exito"
                              : "No se pudo guardar el comentario"}
                        </h2>
                        <button
                          type="button"
                          className="btn btn-degradado mt-4 px-4"
                          data-bs-dismiss="modal"
                          onClick={(e) => {
                            e.preventDefault();
                            if (estadoGuardado) {
                              setCasillaComentario(false);
                              setValue("comentario", "");
                            }
                          }}
                        >
                          Aceptar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  hidden
                  id={"comentario-btn"}
                  data-bs-toggle={"modal"}
                  data-bs-target={"#status-comentario"}
                />
                {isOnline ? (
                  <div
                    className="modal fade show"
                    id={isOnline ? "compartir" : "sinConexionModal"}
                    tabIndex="-1"
                    aria-labelledby="compartir"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body text-center py-4">
                          {/* modal title */}
                          <h2
                            className="h4"
                            style={{ marginBottom: "30px" }}
                          >
                            Compartir
                          </h2>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginBottom: "30px",
                              justifyContent: "center",
                            }}
                          >
                            <FacebookShareButton
                              url={location}
                              style={{ margin: "0 15px 0 15px" }}
                            >
                              <FacebookIcon size={50} round={true} />
                            </FacebookShareButton>
                            <FacebookMessengerShareButton
                              url={location}
                              style={{ margin: "0 15px 0 15px" }}
                            >
                              <FacebookMessengerIcon
                                size={50}
                                round={true}
                              />
                            </FacebookMessengerShareButton>
                            <TwitterShareButton
                              url={location}
                              style={{ margin: "0 15px 0 15px" }}
                            >
                              <TwitterIcon size={50} round={true} />
                            </TwitterShareButton>
                            <WhatsappShareButton
                              url={location}
                              style={{ margin: "0 15px 0 15px" }}
                            >
                              <WhatsappIcon size={50} round={true} />
                            </WhatsappShareButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <SinConexion />
                )}
                <a
                  hidden
                  id={"compartir-btn"}
                  data-bs-toggle={"modal"}
                  data-bs-target={
                    isOnline ? "#compartir" : "#sinConexionModal"
                  }
                />
              </div>
            }
          </>
        }
      </div>
      <div className="mt-4 d-grid d-md-inline-block">
        <Link className="btn btn-degradado" to="/noticias">
          <i className="fa fa-chevron-left" aria-hidden="true"></i> Volver
        </Link>
      </div>
    </div>;
};

export default NoticiasDetalle;
