import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import DatosPersonalesServices from "../../services/datos personales col/datos-personales-services";
import DetalleCargo from "./detalle-cargo";
import styles from "../../resources/css/style-COL.module.css";

export default function OtrosCOL({ reloadPersonalData }) {
  const [infoMobilidad, setInfoMobilidad] = useState({
    CN_INTERNACIONAL: "",
    CN_NACIONAL: "",
  });
  const [editMobility, setEditMobility] = useState(false);
  const [loadingEditMobility, setLoadingEditMobility] = useState(false);
  const [noEditMobilityData, setNoEditMobilityData] = useState(false);
  const [, setErrorEditMobility] = useState(false);
  const [create, setCreate] = useState(false);
  const [loadInfo, setLoadInfo] = useState(true);

  const {
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {

    if (create)
      await DatosPersonalesServices.createMobility(
        data,
        setEditMobility,
        setLoadingEditMobility,
        setNoEditMobilityData,
        setErrorEditMobility,
        setLoadInfo
      );
    else
      await DatosPersonalesServices.editMobility(
        data,
        setEditMobility,
        setLoadingEditMobility,
        setNoEditMobilityData,
        setErrorEditMobility,
        setLoadInfo
      );
  };
  useEffect(() => {
    DatosPersonalesServices.getInformacionMobilidad(
      setInfoMobilidad,
      setCreate,
      setLoadInfo
    );
  }, []);

  useEffect(() => {
    reset(infoMobilidad);
  }, [infoMobilidad]);
  // Mensaje de error al enviar un post
  const handleStatusError = () => {
    Swal.fire({
      allowOutsideClick: false,
      position: "center",
      title:
        '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
      html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo.</div>`,
      confirmButtonColor: "#11427C",
    }).then(() => {
      setLoadInfo(true);
       DatosPersonalesServices.getInformacionMobilidad(
        setInfoMobilidad,
        setCreate,
        setLoadInfo
      );
    });
    setLoadingEditMobility(false);
    setNoEditMobilityData(false);
  };

  // Mensaje de éxito al enviar un post
  const handleStatusSuccess = () => {
    Swal.fire({
      allowOutsideClick: false,
      position: "center",
      title:
        '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
      html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
      confirmButtonColor: "#11427C",
    }).then(() => {
      setLoadInfo(true);
      DatosPersonalesServices.getInformacionMobilidad(
        setInfoMobilidad,
        setCreate,
        setLoadInfo
      );
    });
    setLoadingEditMobility(false);
    setNoEditMobilityData(false);
  };

  useEffect(() => {
    // noDataAdd ? handleStatusError() : null
    editMobility ? handleStatusSuccess() : null;
  }, [loadingEditMobility]);

  return (
    <>
      <div className={`${styles["row"]} ${styles["mb-4"]}`}>
        <DetalleCargo />
        {loadInfo ? (
          <div className={`${styles["col-12"]} ${styles["col-xl-8"]} ${styles["d-flex"]}`}>
            <div className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}>
              <div className={styles["alert"]} role="alert">
                <i
                  className={`${styles["fa"]} ${styles["fa-spinner"]} ${styles["fa-spin"]} ${styles["color-orange"]} ${styles["fa-fw"]}`}
                  aria-hidden="true"
                ></i>
                <span className={styles["ms-2"]}>Cargando...</span>
              </div>
            </div>
          </div>
        ) : (
          <div className={`${styles["col-12"]} ${styles["col-xl-8"]} ${styles["d-flex"]}`}>
            <div className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}>
              <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Otros</h2>
              <p className={styles["fw-bold"]}>
                Movilidad Geográfica <span className={styles["color-red"]}>*</span>
              </p>
              <form>
                <div className={styles["row"]}>
                  <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                    <label className={styles["form-label"]}>Nacional</label>
                    <div className={styles["form-check"]}>
                      <input
                        className={styles["form-check-input"]}
                        type="radio"
                        name="CN_NACIONAL"
                        id="nacional1"
                        value="Y"
                        defaultChecked={
                          infoMobilidad?.CN_NACIONAL === null ||
                          infoMobilidad?.CN_NACIONAL === undefined
                            ? true
                            : infoMobilidad?.CN_NACIONAL === "Y"
                        }
                        {...register("CN_NACIONAL", {
                          required: "* Campo obligatorio",
                        })}
                      />
                      <label className={styles["form-check-label"]} htmlFor="nacional1">
                        Si
                      </label>
                    </div>
                    <div className={styles["form-check"]}>
                      <input
                        className={styles["form-check-input"]}
                        type="radio"
                        name="CN_NACIONAL"
                        id="nacional2"
                        value="N"
                        defaultChecked={
                          infoMobilidad?.CN_NACIONAL === null ||
                          infoMobilidad?.CN_NACIONAL === undefined
                            ? true
                            : infoMobilidad?.CN_NACIONAL === "N"
                        }
                        {...register("CN_NACIONAL", {
                          required: "* Campo obligatorio",
                        })}
                      />
                      <label className={styles["form-check-label"]} htmlFor="nacional2">
                        No
                      </label>
                    </div>
                    <small className={styles["form-error-message"]}>
                      {errors.CN_NACIONAL?.message}
                    </small>
                  </div>
                  <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                    <label className={styles["form-label"]}>Internacional</label>
                    <div className={styles["form-check"]}>
                      <input
                        className={styles["form-check-input"]}
                        type="radio"
                        name="CN_INTERNACIONAL"
                        value="Y"
                        id="internacional1"
                        defaultChecked={
                          infoMobilidad?.CN_INTERNACIONAL === null ||
                          infoMobilidad?.CN_INTERNACIONAL === undefined
                            ? true
                            : infoMobilidad?.CN_INTERNACIONAL === "Y"
                        }
                        {...register("CN_INTERNACIONAL", {
                          required: "* Campo obligatorio",
                        })}
                      />
                      <label
                        className={styles["form-check-label"]}
                        htmlFor="internacional1"
                      >
                        Si
                      </label>
                    </div>
                    <div className={styles["form-check"]}>
                      <input
                        className={styles["form-check-input"]}
                        type="radio"
                        name="CN_INTERNACIONAL"
                        id="internacional2"
                        value="N"
                        defaultChecked={
                          infoMobilidad?.CN_INTERNACIONAL === null ||
                          infoMobilidad?.CN_INTERNACIONAL === undefined
                            ? true
                            : infoMobilidad?.CN_INTERNACIONAL === "N"
                        }
                        {...register("CN_INTERNACIONAL", {
                          required: "* Campo obligatorio",
                        })}
                      />
                      <label
                        className={styles["form-check-label"]}
                        htmlFor="internacional2"
                      >
                        No
                      </label>
                    </div>
                    <small className={styles["form-error-message"]}>
                      {errors.CN_INTERNACIONAL?.message}
                    </small>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <div className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mb-4"]}`}>
        <div className={`${styles["col-12"]} ${styles["col-md-auto"]}`}>
          <button
            type="submit"
            className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["w-100"]}`}
            disabled={loadingEditMobility}
            onClick={handleSubmit(onSubmit)}
          >
            Guardar{" "}
            {loadingEditMobility ? <div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div> : <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i>}
          </button>
        </div>
      </div>
      {noEditMobilityData ? handleStatusError() : null}
    </>
  );
}
