import React from 'react';
import Tippy from '@tippyjs/react';
import { Link } from 'react-router-dom';
import type { ColumnHelper, ColumnsConfig } from "../../../../components/table/table.types";
import { Table } from "../../../../components/table/Table";
import { flagByCountry, reverseCountryDictionary } from "../../../../utils/countries"
import { InterestSitesMaintenance } from '../../context/interestSiteMaintenanceContext';
import { MdImageNotSupported } from "react-icons/md";

interface ListProps {
    data: InterestSitesMaintenance[];
    toggleForm: (id: number | null | undefined) => void;
    onDelete: (id: number | undefined) => void
}

const List: React.FC<ListProps> = ({ data, toggleForm, onDelete }) => {

    const columnsConfig: ColumnsConfig[] = [
        { accessor: "title", header: "Titulo", className: "col-3" },
        { accessor: "url", header: "URL", className: "text-center" },
        { accessor: "svg", header: "Imagen", className: "" },
        { accessor: "country", header: "Pais", className: "text-center" },
        { accessor: "vpn", header: "VPN", className: "text-center" },
        { accessor: "password", header: "Contraseña", className: "text-center" },
        { accessor: "modificador", header: "", className: "text-center" },
        { accessor: "borrar", header: "", className: "text-center" },
    ];

    const buildDataTable = (): ColumnHelper[] => {
        return data.map((site) => ({
            title: (<div className='text-start'>{site.title}</div>),
            url: (<Link to={site.url} target="_blank" rel="noopener noreferrer">
                <Tippy content={site.url}>
                    <span className="d-inline-block text-truncate" style={{ maxWidth: '130px' }}>
                        {site.url}
                    </span>
                </Tippy>
            </Link>),
            svg: <div style={{ width: '35px', height: '35px' }}>
                {site.class && site.class.includes('image/svg+xml') ? (
                    <img src={site.class} alt="image" style={{ width: '100%' }} />
                ) : (
                    <MdImageNotSupported size={35} />
                )}</div>,
            country:
                (<Tippy content={reverseCountryDictionary(site.country)}>
                    <img src={flagByCountry[site.country]} alt={`${site.country} flag`} style={{ width: '20px', height: '20px' }} />
                </Tippy>),
            vpn: site.vpn === 1 ? 'Sí' : 'No',
            password: site.password === 1 ? 'Sí' : 'No',
            modificador: (
                <Tippy content="Modificar">
                    <i className="fa fa-pencil color-orange" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => toggleForm(site.id)}></i>
                </Tippy>
            ),
            borrar: (
                <Tippy content="Borrar">
                    <i className="fa fa-trash-o fa-fw color-orange" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => onDelete(site.id)}></i>
                </Tippy>
            ),
        }));
    };

    const dataTable = buildDataTable();

    return (
        <div className="w-100" >
            {dataTable.length > 0 ? (
                <Table columnsConfig={columnsConfig} data={dataTable} />
            ) : (
                <p>No hay datos disponibles</p>
            )}
        </div>
    );
};

export default List;
