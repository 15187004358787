import Swal from "sweetalert2";

export const handleError = (errorMessage,setErrorMessage,setError) => {
    if (errorMessage) {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px">Error</div>',
            html: `<div style="text-align: center; font-size: 15px">${errorMessage}</div>`,
            confirmButtonColor: '#0169b5',
        }).then((result) => {
            if (result.isConfirmed) {
                setErrorMessage('');
            }
        })
    } else {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px">Error</div>',
            html: `<div style="text-align: center; font-size: 15px">Ha ocurrido un error en la creación de la licencia, intentelo nuevamente</div>`,
            confirmButtonColor: '#0169b5',
        }).then((result) => {
            if (result.isConfirmed) {
                setErrorMessage('');
            }
        })
    }
    setError(false);
}