import { createContext, useContext, useMemo, useState } from "react";
import {
  TeamDashboardContextType,
  DashboardProviderProps,
} from "./teamdashboard.types";
import type { CarouselItem } from "../_components/carouselSubMenu";
import { subMenuListData } from "./carouselList.data";

export const TeamDashboardContext = createContext<
  TeamDashboardContextType | undefined
>(undefined);

export const TeamDashboardProvider = (props: DashboardProviderProps) => {
  const { children } = props;

  const [subMenuList, setSubMenuList] =
    useState<CarouselItem[]>(subMenuListData);

  const handleChangeSubMenu = (id: number) => {
    const updateList = subMenuList
      .map((menu) => {
        // Update the children items if they exist
        const updatedChildren =
          menu.carouselChildren?.map((child) => {
            return {
              ...child,
              isSelected: child.id === id,
            };
          }) || [];

        return {
          ...menu,
          isSelected: menu.id === id,
          carouselChildren: updatedChildren,
        };
      })
      .map((menu) => {
        // Ensure only one item (or child) is selected
        if (
          menu.isSelected ||
          menu.carouselChildren?.some((child) => child.isSelected)
        ) {
          return menu;
        }

        return {
          ...menu,
          isSelected: false,
          carouselChildren:
            menu.carouselChildren?.map((child) => ({
              ...child,
              isSelected: false,
            })) || [],
        };
      });

    setSubMenuList(updateList);
  };

  const findSelectedItem = (
    items: CarouselItem[]
  ): CarouselItem | undefined => {
    for (const item of items) {
      if (item.isSelected) {
        return item;
      }
      if (item.carouselChildren) {
        const found = findSelectedItem(item.carouselChildren);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  };

  const getSelectedMenu = findSelectedItem(subMenuList);

  const enabledFilterCountry = () => {
    if (getSelectedMenu) {
      return getSelectedMenu.enabledFilterCountry || false;
    }

    return true;
  };

  const disabledSelectYear = useMemo(() => {
    if (!getSelectedMenu) {
      return true;
    }
    return getSelectedMenu.disabledSelectYear || false;
  }, [getSelectedMenu]);

  const resetMenus = () => {
    const updateList = subMenuList.map((menu) => ({
      ...menu,
      isSelected: false,
      carouselChildren: menu.carouselChildren?.map((child) => ({
        ...child,
        isSelected: false,
      })),
    }));

    setSubMenuList(updateList);
  };

  // Data exported context
  const contextValue = {
    enabledFilterCountry: enabledFilterCountry(),
    handleChangeSubMenu,
    disabledSelectYear,
    getSelectedMenu,
    subMenuList,
    resetMenus,
  };

  return (
    <TeamDashboardContext.Provider value={contextValue}>
      {children}
    </TeamDashboardContext.Provider>
  );
};

export const useTeamDashboardProvider = (): TeamDashboardContextType => {
  const teamDashboardContext = useContext(TeamDashboardContext);

  if (!teamDashboardContext) return {} as TeamDashboardContextType;

  return teamDashboardContext;
};
