import React, { FC, ReactNode } from "react";
import { FaChevronRight, FaPlay } from "react-icons/fa";
import { IoMdPaperPlane } from "react-icons/io";

type buttonTypes =
    | "primary"
    | "secondary"
    | "info"
    | "link"
    | "video"
    | "share";

const classButtonByType: Record<
    buttonTypes,
    { class: string; icon?: ReactNode }
> = {
    primary: { class: " btn btn-degradado", icon: <FaChevronRight /> },
    secondary: { class: "btn btn-primary" },
    info: { class: "btn btn-info" },
    link: { class: "btn btn-link" },
    video: { class: "btn btn-video", icon: <FaPlay /> },
    share: {
        class: "btn btn-share fw-semibold",
        icon: <IoMdPaperPlane size={25} />,
    },
};

interface Props {
    children: ReactNode;
    type?: "button" | "submit" | "reset";
    styleType?: buttonTypes;
    className?: string;
    style?: React.CSSProperties;
    disabled?: boolean;
    onClick?: () => void;
}

export const Button: FC<Props> = ({
    children,
    type = "button",
    styleType = "primary",
    className = "",
    style,
    disabled,
    onClick,
}) => {
    return (
        <>
            <button
                type={type}
                className={`${classButtonByType[styleType].class} ${className}`}
                onClick={onClick}
                disabled={disabled}
                style={style}
            >
                {["video", "share"].includes(styleType) && (
                    <i className={`pe-2`}>
                        {classButtonByType[styleType].icon}
                    </i>
                )}

                {children}
                {styleType === "primary" && (
                    <i className={`ps-2`}>
                        {classButtonByType[styleType].icon}
                    </i>
                )}
            </button>
        </>
    );
};
