import axios from "axios";
import Auth from '@aws-amplify/auth';
import moment from 'moment'

const url=process.env.REACT_APP_BACKEND_URL_GLOBAL;

const AprobacionLicenciasServices = {
    getSubordinatesLicenceList: async (setFetched,estadoDeAprobacion, setInfo, setFilteredInfo, setLoadingLicences, setNoData) => {
       
        try {
            setLoadingLicences(true)
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
                axios.get(url + '/api/Employee/SubordinatesLicencesList?status=' + estadoDeAprobacion, { headers: head }).then(res => {
                if(res.data !== null){
                    setFetched(true);   
                    const response = res.data;
                    const sortedData = response.sort((a, b) => moment(b.beginDate, "YYYY-MM-DD").unix() - moment(a.beginDate, "YYYY-MM-DD").unix())
                    setInfo(sortedData)
                    setFilteredInfo(sortedData)
                    setLoadingLicences(false);
                }
            })
        }).catch(function (error) {
                console.log(error);
                setLoadingLicences(false);
                setNoData(true);
            })
        } catch (error) {
            console.error(error);
        }
    },

    licenceAction: (submit, setActionData, setLoadingAction, setNoDataAction, setErrorMessage, setSuccessMessage) => {
                Auth.currentSession().then(auth => {
                    const apiurl = url + '/api/Employee/LicencesApproval';
                    const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
                    const body = JSON.stringify(submit);
                    axios({
                      method: 'post',
                      url: apiurl,
                      headers: head,
                      data: body
                    }).then(res => {
                        if (res.status === 200) {
                            setSuccessMessage(res.data.descripcion)
                            setActionData(true)
                            setLoadingAction(false);
                        } else {
                            setLoadingAction(false);
                            setNoDataAction(true);
                        }
                    
            }).catch((error) => {
                setErrorMessage(error.response.data.descripcion)
                setLoadingAction(false);
                setNoDataAction(true);
            })
        }).catch(function (error) {
            console.log(error, "Error");
            setLoadingAction(false);
        })
    },
    getLicenceCertificate: (idSolicitud, setOpenFile,setLoadingIndices,index) => {
    
        Auth.currentSession().then(auth => {
            const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
            axios.get(url + '/api/Employee/LicenceCertificate?idSolicitud=' + idSolicitud, { headers: head }).then((response) => {
                  
                if (response.data !== null) {
                    setLoadingIndices((prev) => {
                        const updated = new Set(prev);
                        updated.delete(index);
                        return updated;
                      });
                    let byteChar = atob(response.data.certificateFile);
                    let byteArray = new Uint8Array(new ArrayBuffer(byteChar.length));
                    for (let i = 0; i < byteChar.length; i++) {
                        byteArray[i] = byteChar.charCodeAt(i);
                    }
                    let blob = new Blob([byteArray], { type: response.data.certificateFileType });
                    const url = window.URL.createObjectURL(blob)
                    setOpenFile({active:true,data: url, type: response.data.certificateFileType, title: response.data.certificateFileName});  
                }

            }).catch((error) => {
                console.log(error, "Error")
              
            })
        }).catch(function (error) {
            console.log(error, "Error");
           
        })
    },
    convertedFile: (archivo) => {
        console.log(archivo, "convertedFile")
        let byteChar = atob(archivo);

        let byteArray = new Uint8Array(new ArrayBuffer(byteChar.length));

        for (let i = 0; i < byteChar.length; i++) {
            byteArray[i] = byteChar.charCodeAt(i);
        }
        return byteArray;
    }
}

export default AprobacionLicenciasServices;