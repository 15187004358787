import React, { FC, useState, useEffect, Children } from "react";
import { AddKpiTableProps } from "../../utilities/interfaces";


const AddKpiTable: FC<AddKpiTableProps> = ({ kpiList }) => {
    const [weigth, setWeigth] = useState(kpiList.reduce((a, b) => a + b.weight, 0));

    useEffect(() => {
        kpiList.length && setWeigth(kpiList.reduce((a, b) => a + b.weight, 0));
    }, [kpiList]);

    return (
        <>
            <div className="my-5 collapse show">
                <div className="tit-group d-flex justify-content-between align-items-center mb-3">
                    <h3 className="h4 mb-0">Definir nuevos KPI</h3>
                    <div>
                        <button className="btn btn-link" data-bs-toggle="modal" data-bs-target="#kpiModal">
                            <p className="btn btn-link order-md-first mt-3 text-decoration-none disabled fw-bolder">{weigth === 0 ? "Crear " : "Editar "}</p>
                            <i className={`fa fa-${weigth === 0 ? "plus-square-o fa-lg" : "pencil fa-fw me-2"}`} aria-hidden="true"/>
                        </button>
                    </div>
                </div>
                <table className="table table-section table-borderless table-th-border align-middle table-even d-none d-md-table">
                    <thead>
                        <tr>
                            <th className="col-9">Nombre KPI</th>
                            <th className="text-center"><span className="color-primary">{`Peso (${weigth}/100%)`}</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {kpiList.length > 0 && Children.toArray(kpiList.map((kpi) =>{
                            return kpi.weight > 0 ? (
                                <tr>
                                    <td className="col-9">{kpi.name}</td>
                                    <td className="text-center">{kpi.weight}</td>
                                </tr>
                            ) : <></>
                        }
                        ))}
                    </tbody>
                </table>
                <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
                    {kpiList.length > 0 && Children.toArray(kpiList.map((kpi, index) => {
                        return kpi.weight !== 0 ? (
                            <div id={`accordion-item-${index}`} className="accordion-item">
                                <h2 className="accordion-header" id={`accordion-${index}`}>
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-${index}`} aria-expanded={index === 0} aria-controls={`accordion-${index}`}>
                                        <strong>Nombre KPI</strong>{` ${kpi.name}`}
                                    </button>
                                </h2>
                                <div id={`accordion-${index}`} className={`accordion-collapse collapse${index === 0 ? " show" : ""}`} aria-labelledby={`accordion-${index}`}>
                                    <div className="accordion-body p-0">
                                        <div className="data-list-box">
                                            <ul className="data-list-section--item list-unstyled">
                                                <li>
                                                    <span className="data-list-box__title">Peso <span className="color-primary">{`(${weigth}/100%)`}</span></span>
                                                    <span className="data-list-box__text">{`${kpi.weight}%`}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : <></>
                    }))}
                </div>
            </div>
        </>
    );
};

export default AddKpiTable;