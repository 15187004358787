import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import InicioBusqueda from "./InicioBusqueda";
import OrgChartTree from "../organigrama/org-chart-tree";

const BusquedaOrganigrama = () => {

    const [employee, setEmployee] = useState('');
    const verOrganigrama = (employee) => {
        setEmployee(employee);
    };

    return (
        <>
            <Routes>
                <Route path="/" element={<InicioBusqueda verOrganigrama={verOrganigrama} />} />
                <Route path="/organigrama" element={<OrgChartTree employee={employee} />} />
            </Routes>
        </>
    )
}

export default BusquedaOrganigrama;


