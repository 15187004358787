import { useState } from "react";
import axios, { AxiosRequestConfig } from "axios";
import Auth from "@aws-amplify/auth";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL_GLOBAL;

export const useAxiosFetch = <T>(params: AxiosRequestConfig<T>) => {
  const [data, setData] = useState<T>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onTriggerFetch = async (): Promise<T> => {
    const auth = await Auth.currentSession();
    const headers = {
      "Content-Type": "application/json",
      accessToken: auth.getAccessToken().getJwtToken(),
    };

    try {
      setIsLoading(true);
      const response = await axios.request<T>({ headers, ...params });
      setData(response.data);
      return response.data;
    } catch (error) {
      setIsError(true);
      throw error;
    } finally {
      setIsLoading(false);
      setIsError(false);
    }
  };

  const resetData = () => {
    setData(undefined);
    setIsLoading(false);
    setIsError(false);
  };

  return [onTriggerFetch, { data, isError, isLoading }, resetData] as const;
};