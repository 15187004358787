import CryptoJS from "crypto-js";
import iconv from "iconv-lite";
import moment from "moment";
import React, { useEffect } from "react";
import { Base64 } from "js-base64";

const useCarousel = (interval, idCarousel,useState) => {
    useEffect(() => {
        const initializeCarousel = () => {
          const carousel = document.getElementById(idCarousel);
          if (carousel) {
            const bsCarousel = new window.bootstrap.Carousel(carousel, {
              interval: interval
            });
            bsCarousel.cycle();
          }
        };
        (useState || useState == 1) && initializeCarousel();
        const handleDOMContentLoaded = () => {
          initializeCarousel();
        };
        document.addEventListener("DOMContentLoaded", handleDOMContentLoaded);
        return () => {
          document.removeEventListener("DOMContentLoaded", handleDOMContentLoaded);
        };
      }, [idCarousel, interval, useState]);

  };

const getOS = () => {
    let userAgent = window.navigator.userAgent,
        platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
        macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K", "macOS"],
        windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
        iosPlatforms = ["iPhone", "iPad", "iPod"],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = "Apple";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = "Apple";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = "Windows";
    } else if (/Android/.test(userAgent)) {
        os = "Android";
    } else if (/Linux/.test(platform)) {
        os = "Linux";
    }

    return os;
}

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
const getBuildDate = (epoch) => {
    const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
    return buildDate;
};

const ljust = (string, width, padding) => {
    padding = padding || " ";
    padding = padding.substr(0, 1);
    if (string.length < width) {
        return string + padding.repeat(width - string.length);
    } else {
        return string;
    }
};

const rjust = (string, width, padding) => {
    padding = padding || " ";
    padding = padding.substr(0, 1);
    if (string.length < width) {
        return padding.repeat(width - string.length) + string;
    } else {
        return string;
    }
};

const center = (string, width, padding) => {
    padding = padding || " ";
    padding = padding.substr(0, 1);
    if (string.length < width) {
        let len = width - string.length;
        let remain = len % 2 == 0 ? "" : padding;
        let pads = padding.repeat(parseInt(len / 2));
        return pads + string + pads + remain;
    } else {
        return string;
    }
};

const encrypt = (message) => {
    let key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CYPHER_KEY);
    let iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CYPHER_IV);
    if (message != null) {
        let latin1Message = CryptoJS.enc.Latin1.parse(message);
        let plainEncoded = CryptoJS.TripleDES.encrypt(latin1Message, key, { iv: iv, });
        let encoded = plainEncoded.toString();
        return encoded
    } else {
        return message;
    }
};

const encryptPhoto = (message) => {
    let key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CYPHER_KEY);
    let iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CYPHER_IV);
    if (message != null) {
        let plainEncoded = CryptoJS.TripleDES.encrypt(message, key, { iv: iv });
        let encoded = plainEncoded.toString();
        return Buffer.from(encoded, "base64").toString("base64");
    } else {
        return message;
    }
};

const decrypt = (message) => {
    let key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CYPHER_KEY);
    let iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CYPHER_IV);
    if (message != null && message !== "" && message.length > 1) {
        const plainTextBytes = CryptoJS.TripleDES.decrypt(message.replaceAll("\n", ""), key, { iv: iv });
        let decoded = plainTextBytes.toString(CryptoJS.enc.Base64);
        return Buffer.from(decoded, "base64").toString("latin1").replaceAll("&n", "ñ").replaceAll("&N", "Ñ").replace("\n", "");
    } else {
        return message;
    }
};

const validaRut = (rutCompleto) => {
    let tmp = rutCompleto.split("-");
    let digv = tmp[1];
    let rut = Number(tmp[0]);
    if (digv === "K") {
        digv = "k";
    }
    return dv(rut) == digv;
};

const dv = (T) => {
    let M = 0;
    let S = 1;
    for (; T; T = Math.floor(Number(T) / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
    return S ? S - 1 : "k";
};

const dateCheck = (q, month, year) => {
    let m = month;
    let y = year;
    let dateList = [];
    if (m === 0) {
        m = 12 + (m % 12);
    }
    for (let i = 0; i < q; i++) {
        let mAux = m;
        let yAux = y;
        mAux = mAux - i;
        if (mAux <= 0) {
            mAux = 12 + (mAux % 12);
            yAux = yAux - 1;
        }
        dateList.push([ordenarMes(mAux), yAux]);
    }
    return dateList;
};

function ordenarMes(mes) {
    if (mes > 0 && mes < 10) {
        mes = "0" + mes;
    }
    return mes;
}

const formatDate = (date) => {
    if (!date) return "-";
    let day, month, year;
    // YYYY-MM-DDTHH:mm:ss.sssZ
    if (date.includes("T")) {
        [year, month, day] = date.split("T")[0].split("-");
    }
    // DD-MM-YYYY o YYYY-MM-DD
    else if (date.includes("-")) {
      [day, month, year] = date.split("-");

      if (day.length === 4) {
        [year, month, day] = date.split("-");
      }
    }

    return `${day}/${month}/${year}`;
  }

const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const isEmpty = (obj) => {
    for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
        }
    }
    return JSON.stringify(obj) === JSON.stringify({});
};
const groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

const dotNumber = (x) => {
    x = Math.round(x);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const dotPerc = (x) => {
    if (x.toString().includes(".")) {
        x = x.toString().replace(".", ",");
        let xList = x.split(",");
        x = xList[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "," + ljust(xList[1], 3, "0");
        return x;
    } else {
        return x.toString() + ",000";
    }
};

const decodePayload = (jwtToken) => {
    const payload = jwtToken.split(".")[1];
    try {
        return JSON.parse(Buffer.from(payload, "base64").toString("utf8"));
    } catch (err) {
        return { errorNoToken: "no es valido" };
    }
};

/* global BigInt */
const pwd = (pwdLastSet) => {
    pwdLastSet = BigInt(pwdLastSet);
    const max_timestamp = 9223372036854775807n;
    const default_time = 116444736000000000n;
    let dias = 0;
    if (pwdLastSet !== 0n && pwdLastSet !== max_timestamp) {
        const timeEpoch = (pwdLastSet - default_time) / 10000000n;
        let fechaHoy = new Date();
        fechaHoy = BigInt(fechaHoy.getTime());
        const diffMiliseg = Math.abs(Number(fechaHoy - timeEpoch * 1000n));
        dias = Math.floor(diffMiliseg / 86400000).toString();
    }
    return dias;
};

const transpose = (matrix) => {
    return matrix.reduce((prev, next) => next.map((item, i) => (prev[i] || []).concat(next[i])), []);
};

const isUndefined = (value) => {
    return value === undefined;
};

const isNull = (value) => {
    return value === null;
};

const isBoolean = (value) => {
    return typeof value === 'boolean';
};

const isObject = (value) => {
    return value === Object(value);
};

const isArray = (value) => {
    return Array.isArray(value);
};

const isDate = (value) => {
    return value instanceof Date;
};

const isBlob = (value, isReactNative) => {
    return isReactNative
        ? isObject(value) && !isUndefined(value.uri)
        : isObject(value) &&
            typeof value.size === "number" &&
            typeof value.type === "string" &&
            typeof value.slice === "function";
};

const isFile = (value, isReactNative) => {
    return (
        isBlob(value, isReactNative) &&
        typeof value.name === "string" &&
        (isObject(value.lastModifiedDate) || typeof value.lastModified === "number")
    );
};

const initCfg = (value) => {
    return isUndefined(value) ? false : value;
};

const serialize = (obj, cfg, fd, pre) => {
    cfg = cfg || {};
    fd = fd || new FormData();

    cfg.indices = initCfg(cfg.indices);
    cfg.nullsAsUndefineds = initCfg(cfg.nullsAsUndefineds);
    cfg.booleansAsIntegers = initCfg(cfg.booleansAsIntegers);
    cfg.allowEmptyArrays = initCfg(cfg.allowEmptyArrays);
    cfg.noFilesWithArrayNotation = initCfg(cfg.noFilesWithArrayNotation);
    cfg.dotsForObjectNotation = initCfg(cfg.dotsForObjectNotation);

    const isReactNative = typeof fd.getParts === "function";

    if (isUndefined(obj)) {
        return fd;
    } else if (isNull(obj)) {
    if (!cfg.nullsAsUndefineds) {
        fd.append(pre, "");
    }
    } else if (isBoolean(obj)) {
    if (cfg.booleansAsIntegers) {
        fd.append(pre, obj ? 1 : 0);
    } else {
        fd.append(pre, obj);
    }
    } else if (isArray(obj)) {
        if (obj.length) {
            obj.forEach((value, index) => {
                let key = pre + "[" + (cfg.indices ? index : "") + "]";
                if (cfg.noFilesWithArrayNotation && isFile(value, isReactNative)) {
                    key = pre;
                }
                serialize(value, cfg, fd, key);
            });
        } else if (cfg.allowEmptyArrays) {
            fd.append(pre + "[]", "");
        }
    } else if (isDate(obj)) {
        fd.append(pre, obj.toISOString());
    } else if (isObject(obj) && !isBlob(obj, isReactNative)) {
        Object.keys(obj).forEach((prop) => {
            const keyIsNumber = !isNaN(parseInt(prop));
            const value = typeof obj[prop] === "string" && obj[prop].toLowerCase().substring(0, 4) === "del " ? `del&${obj[prop].substring(4, obj[prop].length)}` : obj[prop];
        if (isArray(value)) {
            while (prop.length > 2 && prop.lastIndexOf("[]") === prop.length - 2) {
                prop = prop.substring(0, prop.length - 2);
            }
        }
        const key = pre
            ? cfg.dotsForObjectNotation
                ? pre + "." + prop
                : !keyIsNumber ? pre + "[" + prop + "]" : pre + "[" + "'" + prop + "'" + "]"
            : prop;
        serialize(value, cfg, fd, key);
    });
    } else {
        fd.append(pre, obj);
    }
    return fd;
};

const scrollToTop = () => {
    window.scrollTo({ 
        top: 0, 
        left: 0, 
        behavior: 'instant'
    });
}

const capitalize = (str) => {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};
//capitalize first letter of each word in a string

const base64ToArrayBuffer = (base64) => {
    const binaryString = Base64.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
};

const downloadBase64 = (reportName, base64) => {
    const byte = base64ToArrayBuffer(base64.replace("data:application/pdf;base64,", ""));
    const blob = new Blob([byte], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    const fileName = reportName;
    link.download = fileName;
    link.dispatchEvent(new MouseEvent("click"));
}


const removeFileExt = (fileName) => {
    const fileNameWithoutExt = fileName.replace(/\.[^.]+$/, "");
    return fileNameWithoutExt;
}

const convertNumber = (number) => {
    if (number >= 1000000) {
        return Math.floor(number / 100000) / 10 + 'M';
      } else if (number >= 1000) {
        return Math.floor(number / 100) / 10 + 'K';
      } else {
        return number.toString();
      }
}

const CalculateTimeByDate = (date) =>{
    let actualDate = new Date();
    let dateInitial = new Date(date);
    let dif = actualDate.getTime() - dateInitial.getTime();
    let segundos = Math.floor(dif / 1000);
    let minutos = Math.floor(segundos / 60);
    let horas = Math.floor(minutos / 60);
    let dias = Math.floor(horas / 24);
    let years = Math.floor(dias / 365);

    segundos %= 60;
    minutos %= 60;
    horas %= 24;
    dias %= 365;

    if (years > 0) {
      return `hace ${years} año(s), ${dias} día(s), ${horas} hora(s) y ${minutos} minuto(s).`;
    } else if (dias > 0) {
      return `el ${formatDate(date)}`;
    } else if (horas > 0) {
      return `hace ${horas} hora(s) y ${minutos} minuto(s).`;
    } else if (minutos > 0) {
      return `hace ${minutos} minuto(s).`;
    } else {
      return `hace ${segundos} segundo(s).`;
    }
    
}

export { encrypt, encryptPhoto, decrypt, validaRut, dateCheck, formatDate, toBase64, rjust, ljust, center, isEmpty, groupBy, dotNumber, dotPerc, decodePayload, pwd, transpose, getBuildDate, getOS, serialize, scrollToTop, useCarousel, capitalize, base64ToArrayBuffer, convertNumber, CalculateTimeByDate, downloadBase64, removeFileExt };
