import React, { useState, useEffect, useMemo, Children, useContext } from "react";
import liquidacionService from "../services/liquidacion-service";
import { decrypt } from "../services/utiles";
import { Base64 } from "js-base64";
import Modales from "./elements/modal-dt";
import { Link } from "react-router-dom";
import {Context} from "../context";

const Liquidacion = () => {
    const emplid = localStorage.getItem("emplid");
    const {firstLoginData} = useContext(Context);
    const options = [
        {
            value: "seisMeses",
            label: "Ult. 6 meses",
        },
        {
            value: "tresMeses",
            label: "Ult. 3 meses",
        },
        {
            value: "porMes",
            label: "Por mes",
        },
    ];

    const monthOption = [
        {
            value: "ene",
            int: 1,
            label: "Enero",
        },
        {
            value: "feb",
            int: 2,
            label: "Febrero",
        },
        {
            value: "mar",
            int: 3,
            label: "Marzo",
        },
        {
            value: "abr",
            int: 4,
            label: "Abril",
        },
        {
            value: "may",
            int: 5,
            label: "Mayo",
        },
        {
            value: "jun",
            int: 6,
            label: "Junio",
        },
        {
            value: "jul",
            int: 7,
            label: "Julio",
        },
        {
            value: "ago",
            int: 8,
            label: "Agosto",
        },
        {
            value: "sep",
            int: 9,
            label: "Septiembre",
        },
        {
            value: "oct",
            int: 10,
            label: "Octubre",
        },
        {
            value: "nov",
            int: 11,
            label: "Noviembre",
        },
        {
            value: "dic",
            int: 12,
            label: "Diciembre",
        },
    ];

    const yearOption = useMemo(() => [], []);
    const today = new Date(Date.now());
    let year = today.getFullYear();
    const ModalBasico = Modales.ModalBasico;
    const [data, setData] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(false);
    const [selectedYear, setSelectedYear] = useState(false);
    const [selectedOption, setSelectedOption] = useState(false);
    const [email, setEmail] = useState("");
    const [botonBuscar, setBotonBuscar] = useState(false);
    const [status, setStatus] = useState("Cargando...");
    const [statusData, setStatusData] = useState(false);
    const [documento, setDocumento] = useState(false);
    const [statusEmail, setStatusEmail] = useState(false);
    const [senDateMail, setSenDateMail] = useState(false);
    const [mesesNombre, setMesesNombre] = useState("seisMeses");
    const [buttonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
        listarLiquidaciones(sumarMeses(6), 6);
    }, [emplid]);

    useEffect(() => {
        if (data != false && !statusData && data != 1) {
            data.sort(sortNumber);
            let liquidaciones = data.map((liquidacion) => {
                let mes = monthOption.find((mes) => mes.int == liquidacion.month);
                liquidacion.month = mes.label;
                return liquidacion;
            });
            setData(liquidaciones);
            setStatusData(true);
        } else {
            if (data == 1) {
                document.getElementById("abrirModalLiquidaciones").click();
            }
        }
    }, [data]);

    const listarLiquidaciones = (fechas, cantidad) => {
        liquidacionService.getLiquidaciones(emplid, fechas[0], fechas[1], setData, setStatus, cantidad);
    };

    useEffect(() => {
        if (yearOption.length === 0) {
            for (let y = year; y >= 2020; y--) {
                yearOption.push({
                    value: String(y),
                    label: String(y),
                    int: y,
                });
            }
        }
    }, [year, yearOption]);

    const openModal = (e, año, mes) => {
        e.preventDefault();
        setEmail(firstLoginData.EMAIL);
        setSenDateMail([año, mes]);
        if (navigator.onLine) {
            document.getElementById("abrirModalCorreo").click();
        } else {
            document.getElementById("sinConexion").click();
        }
    };

    const handleSubmitPdfEmail = (e, año, mes, isPdf) => {
        e.preventDefault();
        let mes2 = monthOption.find((meses) => meses.label == mes).int;
        if (isPdf) {
            liquidacionService.getEmail_att(emplid, año, ordenarMes(mes2), "", setDocumento);
            document.getElementById("openModalDescargando").click();
        } else {
            setButtonDisabled(true);
            liquidacionService.getEmail_att(emplid, año, ordenarMes(mes2), email, setStatusEmail);
            setTimeout(() => {
                document.getElementById("cerrarModalLiquidacionEmail").click();
                document.getElementById("openBtnEmailLiquidaciones").click();
            }, 100);
        }
    };

    async function handleSubmit(e) {
        e.preventDefault();
        setStatus("Cargando...");
        if (selectedOption === "seisMeses") {
            setMesesNombre("seisMeses");
            bloquearBotonBuscar();
            setData(false);
            setStatusData(false);
            listarLiquidaciones(sumarMeses(6), 6);
        } else if (selectedOption === "tresMeses") {
            setMesesNombre("tresMeses");
            bloquearBotonBuscar();
            setData(false);
            setStatusData(false);
            listarLiquidaciones(sumarMeses(3), 3);
        } else if (selectedOption === "porMes") {
            setMesesNombre("porMes");
            if (!selectedYear || !selectedMonth) {
                document.getElementById("openBtnSeleccioneFechaLiquidacion").click();
                setStatus("No hay registro para mostrar");
                setData(false);
            } else {
                bloquearBotonBuscar();
                setData(false);
                setStatusData(false);
                listarLiquidaciones([ordenarFecha(selectedYear, selectedMonth), ordenarFecha(selectedYear, selectedMonth)], 1);
            }
        }
    }

    const bloquearBotonBuscar = (estado = true) => {
        setBotonBuscar(estado);
    }

    useEffect(() => {
        if (selectedYear && selectedMonth) {
            setBotonBuscar(false);
        }

    }, [selectedMonth, selectedYear])

    useEffect(() => {
        if (botonBuscar === true) {
            setTimeout(() => { setBotonBuscar(false) }, 1000)
        }
    }, [botonBuscar]);

    function base64ToArrayBuffer(base64) {
        base64 = decrypt(base64);
        if (base64.charAt(0) === "0") {
            base64 = base64.substring(1).replaceAll("\n", "").replaceAll(" ", "");
        }
        let binaryString = Base64.atob(base64);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    function saveByteArray(reportName, byte) {
        let blob = new Blob([byte], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        let fileName = reportName;
        link.download = fileName;
        link.dispatchEvent(new MouseEvent("click"));
    }

    function sortNumber(elem1, elem2) {
        var as = [elem1.month, elem1.year];
        var bs = [elem2.month, elem2.year];
        if (parseInt(as[1], 10) > parseInt(bs[1], 10)) {
            //Primero comparar el año
            return -1;
        } else if (parseInt(as[1], 10) < parseInt(bs[1], 10)) {
            //o si es mayor
            return 1;
        } else if (parseInt(as[0], 10) > parseInt(bs[0], 10)) {
            //año igual => mes
            return -1;
        } else if (parseInt(as[0], 10) < parseInt(bs[0], 10)) {
            //o si el mes es mayor
            return 1;
        } else {
            //y si ambos son iguales
            return 0;
        }
    }

    const limpiarModal = () => {
        setEmail("");
        document.getElementById("btnEmail").disabled = false;
    };

    function sumarMeses(meses) {
        let fechahasta = new Date();
        let fechadesde = meses * 30 * 24 * 60 * 60 * 1000;
        fechadesde = new Date(fechahasta.getTime() - fechadesde);
        let desde = fechaSplit(fechadesde);
        let hasta = fechaSplit(fechahasta);
        return [desde, hasta];
    }
    const fechaSplit = (fecha) => ordenarFecha(fecha.getFullYear(), fecha.getMonth()+1);
    const ordenarFecha = (ano, mes) => ano + "-" + ordenarMes(mes) + "-01";
    const ordenarMes = (mes) => (mes > 0 && mes < 10) ? "0" + mes : mes;

    useEffect(() => {
        if (documento != 1 && documento != false && documento !== 2) {
            let base = base64ToArrayBuffer(documento[0]);
            saveByteArray("liquidacion_" + documento[1] + "_" + documento[2], base);
            setDocumento(false);
            setTimeout(() => {
                document.getElementById("closeModalBasicoA").click();
            }, 1000);
            setDocumento(false);
        } else {
            if (documento == 1) {
                setTimeout(() => {
                    document.getElementById("closeModalBasicoA").click();
                    document.getElementById("openModalNoDocumento").click();
                }, 1000);
            } else if (documento === 2) {
                setTimeout(() => {
                    document.getElementById("closeModalBasicoA").click();
                    document.getElementById("sinConexion").click();
                }, 1000);
            }
            setDocumento(false);
        }
    }, [documento]);

    useEffect(() => {
        if (statusEmail == true) {
            setTimeout(() => {
                document.getElementById("closeModalEnviandoEmailLiquidaciones").click();
                document.getElementById("openModalEmaliSend").click();
            }, 300);
            setStatusEmail(false);
            setButtonDisabled(false);
        }
    }, [statusEmail]);

    useEffect(() => {
        setSelectedYear(false);
        setSelectedMonth(false);
        if (selectedOption == "porMes") {
            bloquearBotonBuscar("perma")
        }
    }, [selectedOption]);

    useEffect(() => {
        if (mesesNombre === "tresMeses") {
            if (Array.isArray(data) && data.length > 3) {
                let dataCopy = data.filter((_, index) => {
                    return index <= 2;
                });
                setData(dataCopy);
            }
        } else if (mesesNombre === "seisMeses") {
            if (Array.isArray(data) && data.length > 6) {
                let dataCopy = data.filter((_, index) => {
                    return index <= 5;
                });
                setData(dataCopy);
            }
        }
    }, [data]);

    return (
        <>
            <ModalBasico idBtn="openModalDescargando" idModal="modalDescargando" target="modalDescargando" msj="Descargando..." p="" modalCierre="closeModalBasicoA" icon="fa fa-exclamation-triangle color-orange fa-3x"></ModalBasico>
            <ModalBasico idBtn="openModalNoDocumento" target="modalNoHayDocumentos" idModal="modalNoHayDocumentos" msj="No hay documentos" p="No se encontraron documento" modalCierre="closeModalBasico" icon="fa fa-exclamation-triangle color-orange fa-3x"></ModalBasico>
            <ModalBasico idBtn="openBtnEmailLiquidaciones" target="modalEmailLiquidaciones" idModal="modalEmailLiquidaciones" msj="Enviando Correo..." p="" modalCierre="closeModalEnviandoEmailLiquidaciones" icon="fa fa-envelope-o color-orange fa-3x mb-2"></ModalBasico>
            <ModalBasico idBtn="openBtnSeleccioneFechaLiquidacion" target="modalSeleccioneFechaLiquidacion" idModal="modalSeleccioneFechaLiquidacion" msj="Seleccione un Año y un Mes" p="" modalCierre="closeModalmodalSeleccioneFechaLiquidacion" icon="fa fa-exclamation-triangle color-orange fa-3x mb-2"></ModalBasico>

            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item">Liquidaciones y Anexos</li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Liquidaciones de Sueldo
                    </li>
                </ol>
            </nav>

            <section className="liquidaciones-de-sueldo">
                <h1>Liquidaciones de sueldo</h1>
                <div className="box pb-5 mb-4">
                    <div className="row">
                        <div className="col-12 col-xl-6 mb-4">
                            <select className="form-select" defaultValue={options[0].value} onChange={(e) => setSelectedOption(e.target.value)}>
                                <option disabled value={false}  > Seleccione rango</option>
                                {Children.toArray(options.map((o) => (
                                    <option key={o.value} value={o.value}>{o.label}</option>
                                )))}
                            </select>
                        </div>
                        {selectedOption !== "porMes" ?
                            <div className="col d-grid d-md-block">
                                <button disabled={!selectedOption || botonBuscar ? true : false} className="btn btn-degradado" onClick={(e) => { handleSubmit(e) }}>{botonBuscar ? "Cargando..." : `Buscar`}</button>
                            </div>
                            : null
                        }
                    </div>
                    {selectedOption === "porMes" ?
                        <>
                            <div className="row">
                                <div className="col-12 col-md-6 col-xl-6 mb-4">
                                    <select id="inputState" className="form-select" onChange={(e) => { setSelectedYear(e.target.value); }}>
                                        <option value={false} selected disabled>Seleccione un año</option>
                                        {Children.toArray(yearOption.map((o, i) => (
                                            <option key={o.value + i} value={o.value}>{o.label}</option>
                                        )))}
                                    </select>
                                </div>
                                <div className="col-12 col-md-6 col-xl-6 mb-4">
                                    <select className="form-select" onChange={(e) => { setSelectedMonth(e.target.value); }}>
                                        <option disabled selected value={false}>Seleccione un mes</option>
                                        {Children.toArray(monthOption.map((o, i) => (
                                            <>
                                                <option key={o.value + i} value={o.int}>{o.label}</option>
                                            </>
                                        )))}
                                    </select>
                                </div>
                                <div className="text-end d-grid d-md-block">
                                    <button disabled={botonBuscar == true || botonBuscar == "perma"} onClick={(e) => { handleSubmit(e) }} children={botonBuscar === true ? "Cargando..." : `Buscar`} style={{ float: "right" }} className="btn btn-degradado" ></button>
                                </div>
                            </div>
                        </>
                        : null
                    }

                </div>

                <a hidden id="abrirModalLiquidaciones" data-bs-toggle="modal" data-bs-target="#fail"></a>
                <div className="modal fade" id="fail" tabIndex="-1" aria-labelledby="fail" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body text-center py-4">
                                <i type="button" className="fa fa-times-circle-o color-orange fa-3x mb-2" data-bs-dismiss="modal" aria-hidden="true"></i>
                                <h2 className="h5">
                                    No existe liquidación para el período consultado
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box mb-4">
                    <>
                        <h2 className="h4 tit-section">
                            Detalle Liquidaciones <span className="h6 d-block d-sm-inline-block">({mesesNombre == "tresMeses" ? "Últimos 3 meses" : mesesNombre == "seisMeses" ? "Últimos 6 meses" : mesesNombre == "porMes" ? "por mes" : "Últimos 6 meses"})</span>
                        </h2>
                        {
                            data != false && data != 1 && data != undefined && statusData ?
                                <table className="table table-even table-section table-borderless align-middle">
                                    <thead>
                                        <tr>
                                            <th className="col-5">Año</th>
                                            <th className="col-5">Mes</th>
                                            <th className="col-2 text-center">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Children.toArray(data.map((liquidacion) => {
                                            return <>
                                                <tr>
                                                    <td >{liquidacion.year}</td>
                                                    <td >{liquidacion.month}</td>
                                                    <td className="text-center">
                                                        <a href="#" type="button" onClick={(e) => handleSubmitPdfEmail(e, liquidacion.year, liquidacion.month, true)}><i className="fa fa-file-pdf-o fa-fw me-2" aria-hidden="true"></i></a>
                                                        <a href="#" type="button" onClick={(e) => openModal(e, liquidacion.year, liquidacion.month)}><i className="fa fa-envelope-o fa-fw" aria-hidden="true"></i></a>
                                                    </td>
                                                </tr>
                                            </>
                                        }))
                                        }
                                    </tbody>
                                </table>
                                : status
                        }
                    </>
                </div>

                <a data-bs-toggle="modal" data-bs-target="#enviar-correo" hidden id="abrirModalCorreo"></a>
                <div className="modal fade" id="enviar-correo" tabIndex="-1" aria-labelledby="enviar-correo" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <form id="formLiquidacion" onSubmit={(e) => handleSubmitPdfEmail(e, senDateMail[0], senDateMail[1], false)}>
                                <div className="modal-header border-0 pb-0">
                                    <button onClick={() => limpiarModal()} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h2 className="h5 mb-2">Enviar a:</h2>
                                    <div className="mb-3">
                                        <label className="form-label mb-2">
                                            <strong>Dirección de correo electrónico</strong>
                                        </label>
                                        <input onChange={(e) => { setEmail(e.target.value); e.target.value.match("^[^@]+@[^@]+\.[a-zA-Z]{2,}") ? document.getElementById("btnEmail").disabled = false : document.getElementById("btnEmail").disabled = true }} type="email" required id="emailLiquidacion" pattern="^[^@]+@[^@]+\.[a-zA-Z]{2,}" className="form-control" placeholder="mailcontacto@cencosud.com" onInput={(e) => e.target.setCustomValidity("")} onInvalid={(e) => e.target.setCustomValidity("Formato invalido")} defaultValue={firstLoginData.EMAIL}></input>
                                    </div>
                                </div>
                                <div className="modal-footer border-0 pt-0">
                                    <button type="button" id="cerrarModalLiquidacionEmail" className="btn btn-primary col-12 col-md-auto" data-bs-dismiss="modal" onClick={() => limpiarModal()}>Cancelar<i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                    <button type="submit" id="btnEmail" disabled={buttonDisabled} className="btn btn-degradado col-12 col-md-auto" data-bs-target="#success">Confirmar{" "}<i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <a id="sinConexion" data-bs-toggle="modal" data-bs-target="#sinConexionModal"></a>
                <div className="modal fade" id="sinConexionModal" tabIndex="-1" aria-labelledby="sinConexionModal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header border-0 pb-0">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-center py-4">
                                <i
                                    className="fa fa-exclamation-triangle color-orange fa-3x"
                                    aria-hidden="true"
                                ></i>
                                <h2 className="h5">No tienes conexión</h2>
                                <p>
                                    No puedes ejecutar esta acción si no estás conectado.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <a hidden id="openModalEmaliSend" data-bs-toggle="modal" data-bs-target="#success">
                    {" "}
                </a>
                <div className="modal fade" id="success" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body text-center py-4">
                                <i className="fa fa-check-circle-o color-orange fa-3x" aria-hidden="true"></i>
                                <h2 className="h5">Correo enviado exitosamente</h2>
                                <p>Su detalle de liquidaciones ha sido enviado a la dirección de correo electrónico proporcionada.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ps-4">
                    <p>
                        <i className="fa fa-exclamation-triangle fa-fw fa-lg me-1 color-red" aria-hidden="true"></i> Si necesita la impresión de su liquidación de sueldo, favor mande un mail al responsable de RR.HH. de su Local/Tienda o CD.
                    </p>
                </div>
            </section>
        </>
    );
};
export default Liquidacion;
