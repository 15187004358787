import { useEffect, useMemo } from "react";
import { useTableProvider } from "../../context/DashboardTableContext";
import { useTeamDashboardProvider } from "../../context/TeamDashboardContext";
import {
  ColumnHelper,
  ColumnsConfig,
  Table,
} from "../../../../components/table";
import { WorkEnviromentApi } from "../../hooks";
import { Accordion, AccordionItem } from "../../../../components/accordion";
import { NameCell } from "../table";

export const TalentReview = () => {
  const { tableFetchInfo, setDataTableToExport } = useTableProvider();
  const { getSelectedMenu } = useTeamDashboardProvider();
  const dataInfo = tableFetchInfo.find(
    (fetch) => fetch.key === getSelectedMenu?.name
  );

  const columnsConfig: ColumnsConfig[] = [
    { accessor: "name", header: "Nombre", className: "col-4" },
    { accessor: "position", header: "Cargo", className: "text-center" },
    { accessor: "tr", header: "TR", className: "text-center" },
    { accessor: "detail", header: "Detalle", className: "text-center" },
  ];

  useEffect(() => {
    dataInfo?.response && buildExport();
  }, [dataInfo]);

  const buildExport = () => {
    if (dataInfo) {
      const { dataTable } = dataInfo.response;
      setDataTableToExport({
        header: columnsConfig.map((value) => ({
          label: value.header,
          value: value.accessor,
          hidden: false,
        })),
        rows: dataTable,
        filename: getSelectedMenu?.filenameExport || "",
      });
    }
  };

  const buildDataTable = useMemo(() => {
    if (dataInfo) {
      const { dataTable } = dataInfo.response;

      return dataTable.map((employee) => ({
        ...employee,
        name: (
          <NameCell
            name={employee.name}
            lastName={employee.last_name}
            photo={employee.photo}
          />
        ),
      }));
    }
    return [];
  }, [dataInfo?.response]);

  const buildaccordionList = () => {
    if (dataInfo) {
      const { dataTable } = dataInfo.response;

      return dataTable.map((item: WorkEnviromentApi) => ({
        headerLabel: `${item.name} ${item.last_name}`,
        items: columnsConfig.map((config) => ({
          label: config.header,
          value:
            config.accessor === "name"
              ? `${item.name} ${item.last_name}`
              : item[config.accessor as keyof WorkEnviromentApi],
        })),
      })) as AccordionItem[];
    }

    return [];
  };

  return (
    <>
      <Table
        data={buildDataTable as ColumnHelper[]}
        columnsConfig={columnsConfig}
        isLoading={dataInfo?.response.isLoading}
        responsiveClass="d-xs-none"
      />

      <Accordion itemList={buildaccordionList()} className="d-sm-none" />
    </>
  );
};
