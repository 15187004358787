import { Auth } from "aws-amplify";
import axios from "axios";

const url = process.env.REACT_APP_BACKEND_URL_GLOBAL;

// Backend localhost
// const backend_localhost_url_base = "http://localhost:5000";
// const getCertificates_URL =
//   backend_localhost_url_base + "/api/pdffiller/headers";
// const getCertificate_URL = backend_localhost_url_base + "/api/pdffiller/header";
// const createCertificate_URL =
//   backend_localhost_url_base + "/api/pdffiller/header";
// const modifyCertificate_URL =
//   backend_localhost_url_base + "/api/pdffiller/header";
// const deleteCertificate_URL =
//   backend_localhost_url_base + "/api/pdffiller/header";
// const getFields_URL = backend_localhost_url_base + "/api/pdffiller/fields";
// const getField_URL = backend_localhost_url_base + "/api/pdffiller/field";
// const createField_URL = backend_localhost_url_base + "/api/pdffiller/field";
// const modifyField_URL = backend_localhost_url_base + "/api/pdffiller/field";
// const deleteField_URL = backend_localhost_url_base + "/api/pdffiller/field";
// const getFonts_URL = backend_localhost_url_base + "/api/pdffiller/fonts";
// const getColors_URL = backend_localhost_url_base + "/api/pdffiller/colors";
// const getAligns_URL = backend_localhost_url_base + "/api/pdffiller/aligns";
// const generateFilledPdf_URL =
//   backend_localhost_url_base +
//   "/api/pdffiller/certificateincomewithholding/filledpdf";

// Backend beanstalk
// const backend_beanstalk_url_base = "http://autoservicio-bff-col.us-east-1.elasticbeanstalk.com";
// const getCertificates_URL = backend_beanstalk_url_base + "/api/pdffiller/headers";
// const getCertificate_URL = backend_beanstalk_url_base + "/api/pdffiller/header";
// const createCertificate_URL = backend_beanstalk_url_base+"/api/pdffiller/header";
// const modifyCertificate_URL = backend_beanstalk_url_base + "/api/pdffiller/header";
// const deleteCertificate_URL = backend_beanstalk_url_base + "/api/pdffiller/header";
// const getFields_URL = backend_beanstalk_url_base + "/api/pdffiller/fields";
// const getField_URL = backend_beanstalk_url_base + "/api/pdffiller/field";
// const createField_URL = backend_beanstalk_url_base + "/api/pdffiller/field";
// const modifyField_URL = backend_beanstalk_url_base + "/api/pdffiller/field";
// const deleteField_URL = backend_beanstalk_url_base + "/api/pdffiller/field";
// const getFonts_URL = backend_beanstalk_url_base + "/api/pdffiller/fonts";
// const getColors_URL = backend_beanstalk_url_base + "/api/pdffiller/colors";
// const getAligns_URL = backend_beanstalk_url_base + "/api/pdffiller/aligns";
// const generateFilledPdf_URL = backend_beanstalk_url_base + "/api/pdffiller/certificateincomewithholding/filledpdf";

// Gateway
const backend_gateway_url_base = url + "/api";
const getCertificates_URL = backend_gateway_url_base + "/pdffiller/headers";
const getCertificate_URL = backend_gateway_url_base + "/pdffiller/header";
const createCertificate_URL = backend_gateway_url_base + "/pdffiller/header";
const modifyCertificate_URL = backend_gateway_url_base + "/pdffiller/header";
const deleteCertificate_URL = backend_gateway_url_base + "/pdffiller/header";
const getFields_URL = backend_gateway_url_base + "/pdffiller/fields";
const getField_URL = backend_gateway_url_base + "/pdffiller/field";
const createField_URL = backend_gateway_url_base + "/pdffiller/field";
const modifyField_URL = backend_gateway_url_base + "/pdffiller/field";
const deleteField_URL = backend_gateway_url_base + "/pdffiller/field";
const getFonts_URL = backend_gateway_url_base + "/pdffiller/fonts";
const getColors_URL = backend_gateway_url_base + "/pdffiller/colors";
const getAligns_URL = backend_gateway_url_base + "/pdffiller/aligns";
const generateFilledPdf_URL =
  backend_gateway_url_base +
  "/pdffiller/certificateincomewithholding/filledpdf";

const getHeader = () => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(async (auth) => {
        const header = {
          "Content-Type": "application/json",
          accesstoken: auth.getAccessToken().getJwtToken(),
        };
        resolve(header);
      })
      .catch(function (error) {
        return reject(error);
      });
  });
};

const CertificadoIngresosRetencionesServices = {
  getCertificates: async () => {
    try {
      const header = await getHeader();

      const { data } = await axios.get(getCertificates_URL, {
        headers: header,
      });

      return data;
    } catch (error) {
      error?.response?.data;
    }
  },
  getCertificate: async (id) => {
    try {
      const header = await getHeader();

      const { data } = await axios.get(`${getCertificate_URL}/${id}`, {
        headers: header,
      });

      return data;
    } catch (error) {
      error?.response?.data;
    }
  },
  createCertificate: async (createDto, file) => {
    try {
      const formData = new FormData();
      const header = await getHeader();

      formData.append("name", createDto.name);
      formData.append("description", createDto.description);
      formData.append("year", createDto.year);
      formData.append("enable", createDto.enable);
      formData.append("default_text_size", createDto.default_text_size);
      formData.append("default_text_color", createDto.default_text_color);
      formData.append("default_text_font", createDto.default_text_font);
      formData.append("show_box_border", createDto.show_box_border);
      formData.append("box_background_color", createDto.box_background_color);

      formData.append("file", file);

      const data = await axios({
        method: "post",
        url: createCertificate_URL,
        data: formData,
        headers: {
          "Content-Type": `multipart/form-data;`,
          accesstoken: header.accesstoken,
        },
      });

      return data;
    } catch (error) {
      return error?.response?.data;
    }
  },
  modifyCertificate: async (modifyDto, file) => {
    try {
      const formData = new FormData();
      const header = await getHeader();

      formData.append("name", modifyDto.name);
      formData.append("description", modifyDto.description);
      formData.append("year", modifyDto.year);
      formData.append("enable", modifyDto.enable);
      formData.append("default_text_size", modifyDto.default_text_size);
      formData.append("default_text_color", modifyDto.default_text_color);
      formData.append("default_text_font", modifyDto.default_text_font);
      formData.append("id", modifyDto.id);
      formData.append("show_box_border", modifyDto.show_box_border);
      formData.append("box_background_color", modifyDto.box_background_color);

      formData.append("file", file);

      const data = await axios({
        method: "put",
        url: modifyCertificate_URL,
        data: formData,
        headers: {
          "Content-Type": `multipart/form-data;`,
          accesstoken: header.accesstoken,
        },
      });

      return data;
    } catch (error) {
      error?.response?.data;
    }
  },
  deleteCertificate: async (id) => {
    try {
      const header = await getHeader();

      const response = await axios.delete(`${deleteCertificate_URL}/${id}`, {
        headers: header,
      });

      return response;
    } catch (error) {
      error?.response?.data;
    }
  },
  getFields: async (id) => {
    try {
      const header = await getHeader();

      const { data } = await axios.get(`${getFields_URL}/${id}`, {
        headers: header,
      });

      return data;
    } catch (error) {
      error?.response?.data;
    }
  },
  getField: async (id) => {
    try {
      const header = await getHeader();

      const { data } = await axios.get(`${getField_URL}/${id}`, {
        headers: header,
      });

      return data;
    } catch (error) {
      error?.response?.data;
    }
  },
  createField: async (dto) => {
    try {
      const header = await getHeader();

      const data = await axios({
        method: "post",
        url: createField_URL,
        data: dto,
        headers: {
          "Content-Type": "application/json",
          accesstoken: header.accesstoken,
        },
      });

      return data;
    } catch (error) {
      return error?.response?.data;
    }
  },
  modifyField: async (dto) => {
    try {
      const header = await getHeader();

      const data = await axios({
        method: "put",
        url: modifyField_URL,
        data: dto,
        headers: {
          "Content-Type": "application/json",
          accesstoken: header.accesstoken,
        },
      });

      return data;
    } catch (error) {
      return error?.response?.data;
    }
  },
  deleteField: async (id) => {
    try {
      const header = await getHeader();

      const response = await axios.delete(`${deleteField_URL}/${id}`, {
        headers: header,
      });

      return response;
    } catch (error) {
      error?.response?.data;
    }
  },
  getFonts: async () => {
    try {
      const header = await getHeader();

      const { data } = await axios.get(getFonts_URL, { headers: header });

      return data;
    } catch (error) {
      error?.response?.data;
    }
  },
  getColors: async () => {
    try {
      const header = await getHeader();

      const { data } = await axios.get(getColors_URL, { headers: header });

      return data;
    } catch (error) {
      error?.response?.data;
    }
  },
  getAligns: async () => {
    try {
      const header = await getHeader();

      const { data } = await axios.get(getAligns_URL, { headers: header });

      return data;
    } catch (error) {
      error?.response?.data;
    }
  },
  generateFilledPdf: async (id) => {
    try {
      const header = await getHeader();

      const url = `${generateFilledPdf_URL}/${id}`;

      const response = await fetch(url, {
        headers: header,
      }).then((res) => res.arrayBuffer());

      return response;
    } catch (error) {
      error?.response?.data;
    }
  },
};

export default CertificadoIngresosRetencionesServices;
