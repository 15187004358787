import React from 'react';
import styles from './organigrama-ss.module.css';
import Tippy from '@tippyjs/react';
import { ReactComponent as IcoInternal } from '../../resources/images/ico_interno.svg';
import type { ColumnHelper, ColumnsConfig } from "../table/table.types";
import { Table } from "../table/Table";

type Person = {
    name: string;
    last_name: string;
    photo?: string;
    short_name: string;
    position?: string;
    area?: string;
    internal?: string;
};

type Employee = {
    BaseLevel: {
        person: Person;
        report_to: Record<string, unknown>;
    };
    LastLevel: Person[];
};

const List = ({ employee, onSelectPersonClick }: { employee: Employee[], onSelectPersonClick: (person: Person) => void }) => {
    const columnsConfig: ColumnsConfig[] = [
        { accessor: "name", header: "", className: "col-4" },
        { accessor: "position", header: "", className: "col-3" },
        { accessor: "area", header: "", className: "col-3" },
        { accessor: "colaborador", header: "", className: "col-2" },
    ];

    const buildDataTable = (employees: Employee[]): ColumnHelper[] => {
        let persons: Person[] = [];

        employees.forEach(employee => {
            if (employee.BaseLevel && employee.BaseLevel.person) {
                persons.push(employee.BaseLevel.person);
            }
            if (employee.LastLevel && Array.isArray(employee.LastLevel)) {
                persons = persons.concat(employee.LastLevel);
            }
        });

        return persons.map(person => ({
            name: (
                <div className='d-flex align-items-center ' style={{ cursor: 'pointer' }} onClick={() => onSelectPersonClick(person)} >
                    <div className={styles['employee-image']}>
                        {!person.photo ? <div className={styles['short-name']}>{person.short_name}</div> : <img className={styles['employee-image']} src={person.photo} alt='profile-pic' />}
                    </div>
                    <div className='ms-1'>
                        <p className='mb-0 text-uppercase fw-semibold'>{`${person.name} ${person.last_name}`}</p>
                    </div>
                </div>
            ),
            position: <div className='d-flex align-items-center text-uppercase fw-semibold text-primary'>{person.position ? person.position : "SIN CARGO ASOCIADO"}</div>,
            area: <div className='d-flex align-items-center text-uppercase fw-semibold'>{person.area ? person.area : "SIN AREA ASOCIADO"}</div>,
            colaborador: (
                <div className='d-flex align-items-center'>
                    <div className='me-2'>{person.internal ? person.internal : "0"}</div>
                    <Tippy content='Internos'>
                        <div className={styles['ico-internal']}>
                            <IcoInternal />
                        </div>
                    </Tippy>
                </div>
            ),
        }));
    };

    const data = buildDataTable(employee);

    return (
        <div className='d-flex flex-column w-100'>
            {data.length > 0 ? (
                <Table columnsConfig={columnsConfig} data={data} responsiveClass="d-block"/>
            ) : (
                <p>No hay datos disponibles</p>
            )}
        </div>
    );
};

export default List;
