import React, { FC, ReactNode, useReducer } from "react";
import { infoReducer } from "./infoReducer";
import { InfoContext } from "./InfoContext";
import {
    ApplicationData,
    RequirementData,
    TalentVideoData,
} from "../../interfaces/regionalInfoInterface";

export interface IInfoContext {
    applicationInfo: ApplicationData[];
    requirementsInfo: RequirementData[];
    talentVideo: { title: string, video_url: string };
    hasErrorInfo: boolean;
}

interface Props {
    children: ReactNode;
}

const INITIAL_STATE: IInfoContext = {
    applicationInfo: [
        {
            country: "",
            description: "",
            isList: false,
            icon: "",
            position: 0,
            title: "",
            id: 0,
        },
    ],
    requirementsInfo: [
        {
            country: "",
            isList: true,
            description: "",
            step: 0,
            title: "",
            id: 0,
        },
    ],
    talentVideo: { title: "", video_url: "" },
    hasErrorInfo: false,
};

export const InfoProvider: FC<Props> = ({ children }) => {
    const [state, dispatch] = useReducer(infoReducer, INITIAL_STATE);

    const setApplicationInfo = (applicationInfo: ApplicationData[]) => {
        dispatch({
            type: "REGIONAL - Set Application Info",
            payload: applicationInfo,
        });
    };

    const setRequirementsInfo = (requirementsInfo: RequirementData[]) => {
        dispatch({
            type: "REGIONAL - Set Requirement Info",
            payload: requirementsInfo,
        });
    };

    const setTalentVideo = (videoUrl: { title: string, video_url: string }) => {
        dispatch({ type: "REGIONAL - Set Video URL", payload: videoUrl });
    };

    const setHasErrorInfo = (hasErrorInfo: boolean) => {
        dispatch({
            type: "REGIONAL - Set Has Error",
            payload: hasErrorInfo,
        });
    };

    return (
        <InfoContext.Provider
            value={{
                //Variables
                ...state,

                //Funciones
                setApplicationInfo,
                setRequirementsInfo,
                setTalentVideo,
                setHasErrorInfo,
            }}
        >
            {children}
        </InfoContext.Provider>
    );
};
