import React, { useState, Children, useEffect } from 'react';
import { Text, StyleSheet, View, Page, Document, PDFDownloadLink } from "@react-pdf/renderer";
import { formatDate, dotPerc } from '../../../services/utiles';


const DatosRemuneraciones = ({
    colaborador,
    loadingColaborador,
    hiddenTd
}) => {
    const [remData, setRemData] = useState(null);

    useEffect(() => {

        if (!loadingColaborador) {
            setRemData(Array.isArray(colaborador.CN_REM_LIST) ? colaborador.CN_REM_LIST : [colaborador.CN_REM_LIST]);
        };

    }, [loadingColaborador]);



    const FichaDatosRemuneracionPDF = ({ colaborador }) => {

        if (!Array.isArray(colaborador.CN_REM_LIST)) {
            colaborador.CN_REM_LIST = [colaborador.CN_REM_LIST];
        }

        const styles = StyleSheet.create({
            page: {
                backgroundColor: '#fff',
                padding: '20px',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
            },
            card: {
                padding: "20px",
                borderRadius: "10px",
                width: "48%",
                border: "1px solid #eeeeee",
                margin: " 0px 5px 20px 5px",
                height: "auto",
            },
            head: {
                display: "flex",
                marginBottom: "20px",
                paddingLeft: "10px",
            },
            body: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "20px"
            },
            h1: {
                fontSize: "16px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: "10px",
                color: "#0169b5"
            },
            table: {
                width: "100%",
                borderCollapse: "collapse",
                borderSpacing: "0",
                marginBottom: "20px"
            },
            tableHead: {
                backgroundColor: "#eeeeee",
                borderBottom: "1px solid #eeeeee",
                borderTop: "1px solid #eeeeee",
                borderLeft: "1px solid #eeeeee",
                borderRight: "1px solid #eeeeee",
                padding: "5px",
                fontSize: "8px",
            },
            tableBody: {
                borderBottom: "1px solid #eeeeee",
                borderLeft: "1px solid #eeeeee",
                borderRight: "1px solid #eeeeee",
                fontSize: "8px",
                textAlign: "center",
                color: "#777777",
            },
            tableRow: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                padding: "5px 5px 5px 5px",
                border: "1px solid #eeeeee",
            },
            labelHead: {
                textAlign: "center",
                color: "#777777",
                fontWeight: "900",
            },
            labelBody: {
                textAlign: "center",
                color: "#777777",
                fontWeight: "normal",
            },
            li: {
                display: "flex",
                flexDirection: "row",
                alignItems: 'flex-start',
                padding: "10px",
                borderBottom: "1px solid #eeeeee",
                position: "relative",
                justifyContent: "flex-start"
            },
            liTitle: {
                width: "50%",
            },
            liContent: {
                width: "50%",
            },
            titleLabel: {
                fontSize: "8px",
                fontWeight: "bold",
                color: "#777777"
            },
            contentLabel: {
                fontSize: "8px",
                fontWeight: "normal",
                color: "#777777"
            },
            foto: {
                width: "100px",
                height: "100px",
            },
            checkimg: {
                width: "20px",
                height: "20px",
            },
        });

        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    {/* Datos Remuneraciones */}
                    <View style={{ ...styles.card, width: "100%" }}>
                        <View style={styles.head}>
                            <Text style={styles.h1}>Datos de Remuneraciones</Text>
                        </View>
                        <View style={styles.body}>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>AÑO</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_REM_LIST[0].anio}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>MES</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_REM_LIST[0].mes}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>DIAS TRABAJADOS</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_REM_LIST[0].dias_trabajados}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>BASE HORARIA</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_REM_LIST[0].base_horaria}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>ÚLTIMO AJUSTE RENTA</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{formatDate(colaborador.CN_REM_LIST[0].ult_ajuste_renta)}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>ÚLTIMO AJUSTE RENTA %</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_REM_LIST[0].ult_ajuste_renta_p}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>TIPO DE JORNADA</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_REM_LIST[0].tipo_jornada}</Text>
                                </View>
                            </View>
                            <View style={styles.li}>
                                <View style={styles.liTitle}>
                                    <Text style={styles.titleLabel}>TOTAL HABERES</Text>
                                </View>
                                <View style={styles.liContent}>
                                    <Text style={styles.contentLabel}>{colaborador.CN_REM_LIST[0].total_haberes}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>

        )
    }




    return (

        <>
            {console.log(remData)}
            {(!loadingColaborador && remData != null) ?
                <> {/*   <PDFDownloadLink
                    className="text-decoration-none d-inline-block mb-3 mt-4 color-orange"
                    fileName="Datos_de_Remuneraciones"
                    document={<FichaDatosRemuneracionPDF colaborador={colaborador} />}
                >
                    <i className="fa fa-file-pdf-o me-1" aria-hidden="true"></i>
                    Descargar
                </PDFDownloadLink> */}


                    <div className='col-12 col-xl-7 d-flex'>
                        <div className="box align-items-stretch w-100">
                            <h2 className="h4 tit-section">Datos de Remuneraciones</h2>
                            <div className="data-list-box data-list-box-even text-uppercase">
                                {Array.isArray(remData) ?
                                    Children.toArray(remData.map((item) => {
                                        return (
                                            <ul className="list-unstyled m-0" >
                                                <li>
                                                    <span className="data-list-box__title col-12 col-sm-5">AÑO</span>
                                                    <span className="data-list-box__text">{hiddenTd ? "****" : item.anio}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title col-12 col-sm-5">MES</span>
                                                    <span className="data-list-box__text">{hiddenTd ? "****" : item.mes}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title col-12 col-sm-5">DIAS TRABAJADOS</span>
                                                    <span className="data-list-box__text">{hiddenTd ? "****" : item.dias_trabajados}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title col-12 col-sm-5">BASE HORARIA</span>
                                                    <span className="data-list-box__text">{hiddenTd ? "****" : item.base_horaria}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title col-12 col-sm-5">ÚLTIMO AJUSTE RENTA</span>
                                                    <span className="data-list-box__text">{hiddenTd ? "****" : formatDate(item.ult_ajuste_renta)}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title col-12 col-sm-5">ÚLTIMO AJUSTE RENTA %</span>
                                                    <span className="data-list-box__text">{hiddenTd ? "****" : dotPerc(item.ult_ajuste_renta_p)}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title col-12 col-sm-5">TIPO DE JORNADA</span>
                                                    <span className="data-list-box__text">{hiddenTd ? "****" : item.tipo_jornada}</span>
                                                </li>
                                            </ul>
                                        );
                                    }))
                                    :
                                    <ul className="list-unstyled m-0">
                                        <li>
                                            <span className="data-list-box__title col-12 col-sm-5">AÑO</span>
                                            <span className="data-list-box__text">{hiddenTd ? "****" : remData.anio}</span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title col-12 col-sm-5">MES</span>
                                            <span className="data-list-box__text">{hiddenTd ? "****" : remData.mes}</span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title col-12 col-sm-5">DIAS TRABAJADOS</span>
                                            <span className="data-list-box__text">{hiddenTd ? "****" : remData.dias_trabajados}</span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title col-12 col-sm-5">BASE HORARIA</span>
                                            <span className="data-list-box__text">{hiddenTd ? "****" : remData.base_horaria}</span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title col-12 col-sm-5">ÚLTIMO AJUSTE RENTA</span>
                                            <span className="data-list-box__text">{hiddenTd ? "****" : formatDate(remData.ult_ajuste_renta)}</span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title col-12 col-sm-5">ÚLTIMO AJUSTE RENTA %</span>
                                            <span className="data-list-box__text">{hiddenTd ? "****" : dotPerc(remData.ult_ajuste_renta_p)}</span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title col-12 col-sm-5">TIPO DE JORNADA</span>
                                            <span className="data-list-box__text">{hiddenTd ? "****" : remData.tipo_jornada}</span>
                                        </li>
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>

                </> :
                null
            }

        </>);

};

export default DatosRemuneraciones;