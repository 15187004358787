import { useContext } from "react";
import { InfoContext } from "../context";
import { getInfoRegional } from "../helpers/cencoTalentsHelpers";
import { useCencoUI } from "./useCencoUI";

export const useCencoInfo = () => {
    const { setIsLoading } = useCencoUI();
    const {
        //Variables y constantes
        applicationInfo,
        requirementsInfo,
        talentVideo,
        hasErrorInfo,

        //Funciones y metodos
        setApplicationInfo,
        setRequirementsInfo,
        setTalentVideo,
        setHasErrorInfo,
    } = useContext(InfoContext);

    const getRegionalInfo = async () => {
        const data = await getInfoRegional(setIsLoading, setHasErrorInfo);

        if (!data) {
            // setHasError(true);
            // console.log("Entra aca");
            return;
        }

        const { applicationData, requirementData, talentVideoData } = data;

        setApplicationInfo(applicationData);
        setRequirementsInfo(requirementData);
        setTalentVideo({
            title: talentVideoData.title,
            video_url: talentVideoData.video_url,
        });
    };

    return {
        // Constantes y variables
        applicationInfo,
        requirementsInfo,
        talentVideo,
        hasErrorInfo,

        // Funciones y métodos
        getRegionalInfo,
        setHasErrorInfo
    };
};
