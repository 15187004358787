import React, { useEffect, useState } from "react";
import Form from "./form";
import CertificadoIngresosRetencionesServices from "../../services/certificado-ingresos-retenciones/service";
import styles from "../../resources/css/style-COL.module.css";
import { Link } from 'react-router-dom'

const CertificadoIngresosRetenciones = () => {
  const [certificates, setCertificates] = useState([]);
  const [isLoadingCertificates, setIsLoadingCertificates] = useState(false);

  const getCertificates = async () => {
    try {
      setIsLoadingCertificates(true);

      const response =
        await CertificadoIngresosRetencionesServices.getCertificates();

      if (Array.isArray(response)) {
        setCertificates(response);
      }

      setIsLoadingCertificates(false);
    } catch (error) {
      setIsLoadingCertificates(false);
    }
  };

  useEffect(() => {
    getCertificates();
  }, []);

  return (
    <>
      {/* <!-- inicio breadcrumbs -->   */}
      <nav className={`${styles["breadcrumbs"]} ${styles["d-none"]} ${styles["d-lg-block"]}`} aria-label="breadcrumb">
        <ol className={styles["breadcrumb"]}>
          <li className={styles["breadcrumb-item"]}>
            <Link to="/inicio">
              <i className="fa fa-home" aria-hidden="true"></i> Inicio
            </Link>
          </li>
          <li className={styles["breadcrumb-item"]}>
          Transacciones
          </li>
          <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">
            Certificado de Ingresos y Retenciones
          </li>
        </ol>
      </nav>
      {/* <!-- fin breadcrumbs --> */}

      {/* <!-- inicio Certificados --> */}
      <section>
        <h1>Certificado de Ingresos y Retenciones</h1>

        {isLoadingCertificates ? (
          <div data-testid="loading" className={styles["full-spinner"]}>
            <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
          </div>
        ) : null}

        {!isLoadingCertificates ? <Form certificates={certificates} /> : null}
      </section>
    </>
  );
};

export default CertificadoIngresosRetenciones;
