import React, { Children, FC, useEffect, useState } from "react";
import { Collaborator, InputFile } from "../../utilities/interfaces";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import { dotPerc } from "../../../../../services/utiles";

interface FileUploadPorps {
    collaborator: Collaborator | null;
    files: InputFile[];
    setFiles: React.Dispatch<React.SetStateAction<InputFile[]>>;
}

const deleteFile = (index: number) => {
    const dt = new DataTransfer();
    const input = (document.getElementById(`files${index}`) as HTMLInputElement);
    const fileList = input?.files;
    if (fileList !== null) {
        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i]
            if (0 !== i)
                dt.items.add(file);
        }
        input.files = dt.files;
    }
};

const FileUpload: FC<FileUploadPorps> = ({ collaborator, files, setFiles }) => {
    const [deletedFileIndex, setDeletedFileIndex] = useState(-1);

    useEffect(() => {
        const timer = setTimeout(() => {
            deletedFileIndex > -1 && (pipe(
                files,
                A.deleteAt(deletedFileIndex),
                O.getOrElse(() => files),
                setFiles,
            ))
        }, 420);
        return  () => clearTimeout(timer);
    }, [deletedFileIndex]);

    useEffect(() => {
        setDeletedFileIndex(-1);
    }, [files]);

    return (
        <>
            <li>
                <span className="data-list-box__title col-md-4">Adjuntar documento</span>
                <span className="data-list-box__text col-6 d-block">
                    <div className="d-flex align-items-center">
                        <input
                            id="files0"
                            type="file"
                            accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                            image/*,audio/*,video/*,.txt,.zip,.rar,.7z,.pdf,.xlsx,.csv,.xls"
                            className="form-control col-10"
                            aria-label="Adjuntar certificado"
                            readOnly={collaborator === null}
                            disabled={collaborator === null}
                            onChange={(e) => {
                                pipe(
                                    files,
                                    A.updateAt(0, e.target.files !== null ? { file: e.target.files[0], index: 0 } : { file: null, index: 0 }),
                                    O.getOrElse(() => files),
                                    setFiles
                                )
                            }}
                        />
                        {collaborator !== null &&
                            <>
                                <a
                                    className=""
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        pipe(
                                            files,
                                            A.append({ file: null as File | null, index: files[files.length - 1].index + 1 }),
                                            setFiles
                                        );
                                    }}
                                >
                                    <i className="fa fa-plus-square-o fa-fw fa-lg mx-3" aria-hidden="true" />
                                </a>
                                <a
                                    className=""
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        pipe(
                                            files,
                                            A.updateAt(0, { file: null as File | null, index: 0 }),
                                            O.getOrElse(() => files),
                                            setFiles
                                        );
                                        deleteFile(0);
                                    }}
                                >
                                    <i className="fa fa-trash-o fa-fw fa-lg" aria-hidden="true" />
                                </a>
                            </>
                        }
                    </div>
                </span>
            </li>
            {files[0].file !== null && files[0].file !== undefined &&
                <div>
                    <span className="data-list-box__title">{"Peso: "}</span>
                    <span className="data-list-box__text color-orange">{`${files[0].file !== undefined ? dotPerc((files[0].file.size / (1024**2)).toFixed(3)) : 0} MB`}</span>
                </div>
            }
            {files.length > 1 &&
                Children.toArray(files.map((file, index) => {
                    return index > 0 ? (
                        <>
                            <li>
                                <span className="data-list-box__title col-md-4" />
                                <span className="data-list-box__text col-6 d-block">
                                    <div className="d-flex align-items-center">
                                        <input
                                            id={`files${file.index}`}
                                            type="file"
                                            className="form-control col-10"
                                            accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                            image/*,audio/*,video/*,.txt,.zip,.rar,.7z,.pdf,.xlsx,.csv,.xls"
                                            aria-label="Adjuntar certificado"
                                            readOnly={collaborator === null}
                                            disabled={collaborator === null}
                                            onChange={(e) => {
                                                pipe(
                                                    files,
                                                    A.updateAt(index, e.target.files !== null ? { file: e.target.files[0], index: files[index].index } : { file: null as File | null, index: files[index].index }),
                                                    O.getOrElse(() => files),
                                                    setFiles,
                                                );
                                            }}
                                        />
                                        {collaborator !== null &&
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    deleteFile(file.index);
                                                    setDeletedFileIndex(index);
                                                }}
                                            >
                                                    <i className="fa fa-trash-o fa-fw fa-lg mx-3" aria-hidden="true" />
                                            </a>
                                        }
                                    </div>
                                </span>
                            </li>
                            {file.file !== null && file.file !== undefined &&
                                <div>
                                    <span className="data-list-box__title">{"Peso: "}</span>
                                    <span className="data-list-box__text color-orange">{`${file.file !== undefined ? dotPerc((file.file.size / (1024**2)).toFixed(3)) : 0} MB`}</span>
                                </div>
                            }
                        </>
                    ) : <></>
                }))
            }
        </>
    )
};

export default FileUpload;