import React, { FC, Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";

interface Props {
    setFichaSIC: Dispatch<SetStateAction<boolean>>;
    setCollaboratorEmplid: Dispatch<SetStateAction<string>>;
}

const BreadCrumbs: FC<Props> = ({ setFichaSIC, setCollaboratorEmplid }) => {
    return (
        <nav className="breadcrumbs" aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/inicio"><i className="fa fa-home" aria-hidden="true"></i> Inicio</Link></li>
                <li className="breadcrumb-item"><Link to="#" onClick={() => { setFichaSIC(false); setCollaboratorEmplid(''); }}>Mi Equipo</Link></li>
                <li className="breadcrumb-item active" aria-current="page">SIC de Reemplazo</li>
            </ol>
        </nav>
    )
}

export default BreadCrumbs;