import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import errorMessage from "../SharedComponents/error-message";
import successMessage from "../SharedComponents/success-message";
import VacationServices from "../../services/vacaciones-COL/service";
import styles from "../../resources/css/style-COL.module.css";

const AprobarVacacinesPublic = () => {
  const { search } = useLocation();
  const status = search.split("status=")[1].split("&")[0];
  const hash = search.split("hash=")[1];
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const approve = async () => {
    try {
      if (
        status === null ||
        status === undefined ||
        status === "" ||
        hash === null ||
        hash === undefined ||
        hash === ""
      ) {
        // errorMessage(null, "Bad request.");
        setShowError(true);
      } else {
        setIsSaving(true);

        const dto = {
          hash: hash,
          status: status,
        };

        let response = await VacationServices.updateVacationRequest(dto);

        // let response = null;

        if (response === null || response === undefined) {
          // errorMessage(null, "");
          setShowError(true);
          setIsSaving(false);
        } else {
          const status = response?.statusCode || response?.status;
          switch (status) {
            case 200:
              setIsSaving(false);
              // successMessage(null);
              setShowSuccess(true);
              break;

            case 400:
              setIsSaving(false);
              // errorMessage(null, response?.message);
              setShowError(true);
              break;

            case 500:
              setIsSaving(false);
              // errorMessage(null, response?.message);
              setShowError(true);
              break;

            default:
              break;
          }
        }
      }
    } catch (error) {
      setIsSaving(false);
      // errorMessage(null, "");
      setShowError(true);
    }
  };

  useEffect(() => {
    if (hash) {
      approve();
    }
  }, [hash]);

  return (
    <>
      {isSaving ? (
        <div data-testid="loading" className="full-spinner">
          <div className="spinner-border text-primary"></div>
        </div>
      ) : null}
      {showSuccess || showError ? (
        <div
          className={`${styles["container"]} ${styles["mt-5"]} ${styles["d-flex"]} ${styles["align-items-center"]} ${styles["justify-content-center"]}`}
        >
                     {" "}
          <div className={`${styles["box"]} ${styles["p-5"]}`}>
                           {" "}
            <div className={styles["icon-mail"]}>
              <i
                className={`${styles["fa"]} ${styles["fa-home"]} ${styles["main-icon-home"]} ${styles["fs-icon"]} ${styles["color-orange"]}`}
              />
              <h1
                className={`${styles["title-mail"]} ${styles["color-primary"]}`}
              >
                ¡Hola!
              </h1>
            </div>
                           {" "}
            <p className={`${styles["lead"]} ${styles["color-primary"]}`}>
              {showSuccess ? "La solicitud se procesó exitosamente." : null}
              {showError ? "La solicitud no pudo ser procesada." : null}
            </p>
                       {" "}
          </div>
                 {" "}
        </div>
      ) : null}
    </>
  );
};

export default AprobarVacacinesPublic;
