/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import getCalendar from '../calendar-responsive/calendar-fn';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import Header from '../calendar-responsive/responsive-header';
import SolicitudCambioTurno from "../solicitud-cambio-turno";



const CalendarResponsive = (props) => {

    CalendarResponsive.propTypes = {
        events: PropTypes.any,
        initial: PropTypes.any,
        shifts: PropTypes.any,
        pendants: PropTypes.any,
        setShow: PropTypes.any
    }

    const events = props.events;
    const initial = props.initial;
    const shifts = props.shifts;
    const pendants = props.pendants;
    const setShow = props.setShow;
    const ref = initial.current?.getApi();
    const now = moment(ref?.getDate())
    const [value, setValue] = useState(now)
    const [cambioTurno, setCambioTurno] = useState(null);
    const calendar = getCalendar(value)
    const [current, setCurrent] = useState(calendar)
    const scrollRef = useRef()
    const EVENT_STYLES = {
        Franco: { backgroundColor: '#f9deaa', textColor: '#777777' },
        Turno: { backgroundColor: '#faba00', textColor: '#777777' }
    };


    useEffect(() => {
        setCurrent(getCalendar(value))
    }, [value])

    function getPendants(fecha) {
        const b = moment(new Date(fecha)).format("DD-MM-YYYY")
        const a = pendants.filter(x => moment(new Date(x.workingDate)).format("DD-MM-YYYY") === b)
        return a;
    }

    const getEventDescription = (data) => {
        switch (data?.type) {
            case 'Franco': return 'Pendiente Cambio Franco';
            case 'Turno': return 'Pendiente Cambio Horario';
            default: return data?.type ?? '-';
        }
    }
    function isHoliday(fecha) {
        const a = shifts.filter(day => moment(day.startDate).format("DD-MM-YYYY") == moment(new Date(fecha)).format("DD-MM-YYYY"))
        return a[0]?.isHoliday
    }
    function getEventForDay(fecha) {
        const a = events.filter(day => moment(day.start).format("DD-MM-YYYY") == moment(new Date(fecha)).format("DD-MM-YYYY"))
        return a
    }
    const handleClick = (e) => {
        
        const shift = shifts.find(shift => shift.id === e.id);

        if(shift.type === 'SinHorario'){
            return
        }
        const momentLocale = moment(shift?.startDate);
        momentLocale.locale("es");

        shift.dateDescription = momentLocale.format('dddd DD [de] MMMM');
        shift.detail = shift.dateDescription;

        if (shift.type === 'Turno') {
            shift.detail += ` de ${shift.startTime} a ${shift.endTime}`;
            shift.timeDescription = `${shift.startTime} - ${shift.endTime}`;
        }

        const cambio = {
            previous: shift,
            changed: { startDate: '', startTime: '', endTime: '' },
            tipo: shift.type
        }

        setCambioTurno(cambio);
    }

    const handleModal = (ob) => {
        const data = ob;
        const wdMomentLocale = moment(data.workingDate);
          wdMomentLocale.locale("es");
          const diaATrabajar = wdMomentLocale.format('dddd DD [de] MMMM'); 

          const doMomentLocale = moment(data.dayOffDate);
          doMomentLocale.locale("es");
          const diaFranco = doMomentLocale.format('dddd DD [de] MMMM');

           const request = {
            id: data.id,
            type: data.type,
            previous: {
                dateDescription: diaATrabajar,
                startTime: data.previousStartTime,
                endTime: data.previousEndTime
            },
            changed: {
                dateDescription: data.type == 'Franco' ? diaFranco : diaATrabajar,
                startTime: data.newStartTime,
                endTime: data.newEndTime
            }
        }
            setShow({active:true, data:request});
            setCambioTurno(null);
    }
    
    return (
        <>
            <section>
                <Header val={value} ref={initial} setVal={setValue} />
                <div className="calendar__body">

                    {
                        current.map((days, i) => (
                            <div key={i} className={`calendar__body-row ${isHoliday(days) ? 'mob-calendar-holiday' : ''}
                            ${days.format("DD-MM-YYYY") === moment().format("DD-MM-YYYY") ? "mob-calendar-current" : ""}`}>
                                <div className="day-cell">
                                </div>
                                <div key={days} className="day-cell-name text-capitalize">{days?.format("dddd")}</div>
                                <a className="day-cell-body">
                                    <span className="day-number">
                                        {days.format("DD")}
                                    </span>
                                    {getEventForDay(days).map((e) => (
                                        <span key={e.id} className='day-hour' onClick={() => { handleClick(e); scrollRef.current?.scrollIntoView({ behaviour: "smooth" }); }}>
                                            {e.type === "Turno" ? e?.title : e?.type === 'SinHorario' ? 'No Asignado' : e?.type}
                                        </span>
                                    ))}
                                    <div>
                                        {getPendants(days).length > 0 ? getPendants(days).map((item, i) => {
                                            return (
                                                <div onClick={() => { handleModal(item) }} key={i} className='calendar-event'
                                                    style={{ backgroundColor: EVENT_STYLES[item?.type].backgroundColor, color: EVENT_STYLES[item?.type].textColor }}>
                                                    <span className='mb-1'>
                                                        {getEventDescription(item)}
                                                    </span>
                                                </div>
                                            )
                                        }) : null}
                                    </div>

                                </a>

                            </div>
                        ))}
                </div>
            </section>

            {cambioTurno && <SolicitudCambioTurno cambioTurno={cambioTurno} setCambioTurno={setCambioTurno}></SolicitudCambioTurno>}
            <div ref={scrollRef}></div>
        </>

    )
}

export default CalendarResponsive
