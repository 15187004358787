import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataServices from "../../services/data-test.service";
import { BsAwardFill } from 'react-icons/bs';
import { BsExclamationCircle } from "react-icons/bs";

const Goals = (props) => {


    const [goals, setGoals] = useState([]);
    const [loadingGoals, setLoadingGoals] = useState(true);
    const [noGoalData, setNoGoalData] = useState(false);
    const [requestError, setRequestError] = useState();

    useEffect(() => {
        getGoals();
    }, [])


    const getGoals = async () => {
        DataServices.getGoals(setGoals, setLoadingGoals, setNoGoalData);
    }

    const LoadingGoalsScreen = () => {
        return <div className="full-spinner" data-testid="goal-spinner">
            <div className="spinner-border text-primary"></div>
        </div>
    }
    const displayGoals = () => {

        if (!noGoalData) {
            for (let i = 0; i < goals.length; i += 3) {
                return <div className="row">
                    {goals.map(goal => {
                        return <div className="col-md-4 col-sm-4 col-lg-4">
                            <div className="card goal-card h-100">
                                <div className="card-header goal-header text-center">
                                    <div className="title"> <BsAwardFill /> {goal.goalTitle} </div>
                                </div>
                                <div className="card-body goal-description" data-testid="render-goals">
                                    {goal.goalDescr}
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            }
        } else {
            return <div className="alert alert-info alert-dismissible fade show text-center" role="alert">
                <BsExclamationCircle />  No se encontraron datos asociados
            </div>
        }
    }
    /*  const displayGoals = () => {
         if(!noGoalData){
             const cardGoals = goals.map(goal => {
                 return <div className="col-md-4 col-sm-4 col-lg-4"> 
             <div className="card goal-card h-100">
                 <div className="card-header goal-header text-center">
                     <div className="title"> <BsAwardFill/> {goal.goalTitle} </div>
                 </div>
                 <div className="card-body goal-description" data-testid="render-goals">
                   { goal.goalDescr }
                 </div>
             </div>
         </div>
         })
 
         for(let i = 0; i < cardGoals.length ; i += 3 ){
             return <div className="row">
                         { cardGoals }
                     </div>
                 } 
             
         }else{
             return <div className="alert alert-info alert-dismissible fade show text-center" role="alert">
             <BsExclamationCircle />  No se encontraron datos asociados
         </div>
         }         
     } */


    return (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Mis Objetivos
                    </li>
                </ol>
            </nav>
            <section className="noticias">
                <h1>Mis Objetivos</h1>
                <div className="col-12 position-relative mb-5">
                    {/* <a className="btn btn-degradado btn-absolute" href="#">Ver más <i className="fa fa-chevron-right" aria-hidden="true"></i></a> */}
                </div>
            </section>
            <div className="container">
                {loadingGoals ? LoadingGoalsScreen() : displayGoals()}
            </div>
        </>
    )

}


export default Goals;
