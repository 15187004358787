import { useEffect, useState } from "react";
import { useAxiosFetch } from "../../../hooks";
import { ProfileSummaryApi } from "./hooksTeamDashboard.types";
import { ProfileSummarySelected } from "../context/teamdashboard.types";

export const useProfileSummaryFetch = (profile: ProfileSummarySelected) => {
  const [getSummaryEmployee, responseSummaryEmployee] =
    useAxiosFetch<ProfileSummaryApi>({
      method: "GET",
      url: `/api/my-teams/profile/summary/employee/${profile.idEmpoyee}`,
      params: {
        countryCode: profile.countryCode,
      },
    });

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (profile.idEmpoyee && profile.countryCode) {
      getSummaryEmployee()
        .then((data) => {
          if (!data || !data.personalData.length) {
            setError("No hay información disponible para este usuario.");
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 501) {
            setError("No está disponible para éste país.");
          }
        });
    }
  }, [profile.idEmpoyee, profile.countryCode]);

  const sumaryEmployeeMapper = () => {
    return {
      ...responseSummaryEmployee,
      data: {
        personalData: responseSummaryEmployee.data?.personalData || [],
        jobData: responseSummaryEmployee.data?.jobData || [],
        positionHistory: responseSummaryEmployee.data?.positionHistory || [],
        locationHistory: responseSummaryEmployee.data?.locationHistory || [],
        vacations: responseSummaryEmployee.data?.vacations || [],
        daysTaken: responseSummaryEmployee.data?.daysTaken || [],
      },
      
    };
  };

  return {
    summaryEmployee: sumaryEmployeeMapper(),
    error,
    countryCode: profile.countryCode,
  } as const;
};
