import axios from "axios";
import Auth from '@aws-amplify/auth';
import { urlsAws } from "../../resources/foo/api-endpoints";

const url = process.env.REACT_APP_BACKEND_URL_GLOBAL;
//const url = 'http://localhost:8000'; // for local testing only

const RequestPlateService = {
    // getRequestPlateList: async (estado, setInfo, setLoadingAbsences, setNoData) => {
    //     try {
    //         Auth.currentSession().then(async auth => {
    //             const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
    //             const { data } = await axios.get(url + '/api/request-plate?state=' + estado, { headers: head })
    //             const sortedData = data.sort((a, b) => moment(b.beginDate, "AAAA-MM-DD").unix() - moment(a.beginDate, "AAAA-MM-DD").unix())
    //             setInfo(sortedData)
    //             setLoadingAbsences(false);
    //         }).catch(function (error) {
    //             console.log(error);
    //             setLoadingAbsences(false);
    //             setNoData(true);
    //         })
    //     } catch (error) {
    //         console.error(error);
    //     }
    // },
    getRequestPlateList: async (estado) => {
        const auth = await Auth.currentSession();
        const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() };
        const response = axios.get(url + '/api/request-plate?status=' + estado, { headers });
        return response;
    },
    getFilters: async () => {
        const auth = await Auth.currentSession();
        const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() };
        const response = await axios.get(`${url}/api/request-plate/filter-types`, { headers });
        return response.data;
    },
    approveRequest: async (payload) => {
        const auth = await Auth.currentSession();
        const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() };
        const promise = axios.post(`${url}/api/request-plate/approve`, payload, { headers });
        return promise;
    },
    rejectRequest: async (payload) => {
        const auth = await Auth.currentSession();
        const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() };
        const promise = axios.post(`${url}/api/request-plate/decline`, payload, { headers });
        return promise;
    },
    getLicenceCertificate: async (id,region) => {
        const auth = await Auth.currentSession();
        const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() };
        const response = axios.get(`${url}/api/request-plate/licenceCertificate?idSolicitud=${id}&region=${region}`, { headers });
        return response;
    },
    anularVacaciones: async (data) => {
        const auth = await Auth.currentSession();
        const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() };
        const body = JSON.stringify({ request: data });
        const response = axios.post(`${url}/api/request-plate/cancelVacation`, body, { headers });
        return response;
    }
}

export default RequestPlateService;