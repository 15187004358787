import codigoEtica from '../assets/images/codigoEtica.jpg';
import culturaSeguridad from '../assets/images/culturaSeguridad.jpg';
import adnCencosud from '../assets/images/adnCencosud.jpg';
import conflictoInteres from '../assets/images/conflictoInteres.jpg';
import culturaDigital from '../assets/images/culturaDigital.jpg';
import compraBienesServicios from '../assets/images/compraBienesServicios.jpg';
import alimentos from '../assets/images/alimentos.jpg';
import experienciasSM from '../assets/images/experienciasSM.jpg';
import lineaCajas from '../assets/images/lineaCajas.jpg';
import experienciasMDH from '../assets/images/experienciasMDH.jpg';
import programaLavadoActivos from '../assets/images/programaLavadoActivos.jpg';
import capacitacionLavadoActivos from '../assets/images/capacitacionLavadoActivos.jpg';

const coursesData = {
  todos: {
    title: 'Todas las unidades de negocio',
    courses: [
      {
        image: codigoEtica,
        title: 'Código de ética',
        url: 'https://cencosud.csod.com/samldefault.aspx?returnurl=%252fDeepLink%252fProcessRedirect.aspx%253fmodule%253dloRegisterAndLaunch%2526lo%253d8f6ab229-bdaa-4f63-9090-0b46e13c7e27',
      },
      {
        image: culturaSeguridad,
        title: 'Construyendo nuestra cultura de seguridad en el trabajo',
        url: 'https://cencosud.csod.com/samldefault.aspx?returnurl=%252fDeepLink%252fProcessRedirect.aspx%253fmodule%253dloRegisterAndLaunch%2526lo%253d74025219-afa8-4c95-a51e-fe2fc38bab0b',
      },
      {
        image: adnCencosud,
        title: 'ADN Cencosud: Sello de Liderazgo y Servicio',
        url: 'https://cencosud.csod.com/samldefault.aspx?returnurl=%252fDeepLink%252fProcessRedirect.aspx%253fmodule%253dloRegisterAndLaunch%2526lo%253d430bdb83-2890-4282-ba1c-10b9b5d86e01',
      },
      {
        image: conflictoInteres,
        title: 'Conflicto de interés',
        url: 'https://cencosud.csod.com/samldefault.aspx?returnurl=%252fDeepLink%252fProcessRedirect.aspx%253fmodule%253dlaunchtraining%2526lo%253dd9e27aca-aa37-44bd-8a85-f1f573ba2b5b',
      },
      {
        image: culturaDigital,
        title: 'Cultura Digital Segura: Buenas Prácticas',
        url: 'https://cencosud.csod.com/samldefault.aspx?returnurl=%252fDeepLink%252fProcessRedirect.aspx%253fmodule%253dlaunchtraining%2526lo%253df82e1f50-4752-4401-b66b-ea2dd1fc358a',
      },
      {
        image: compraBienesServicios,
        title: 'Para quienes compren bienes/servicios: Defensa de la competencia',
        url: 'https://cencosud.csod.com/samldefault.aspx?returnurl=%252fDeepLink%252fProcessRedirect.aspx%253fmodule%253dloRegisterAndLaunch%2526lo%253d22758b0b-16d9-4c2f-8584-4569b3aaac04',
      },
    ],
  },
  supermercados: {
    title: 'Cursos de Supermercados',
    courses: [
      {
        image: alimentos,
        title: 'Para locales, centros de distribución y plantas: Manejo Seguro de alimentos',
        url: 'https://cencosud.csod.com/samldefault.aspx?returnurl=%252fDeepLink%252fProcessRedirect.aspx%253fmodule%253dloRegisterAndLaunch%2526lo%253de5c4e880-b57b-4787-a81a-1a12eb7f6ca8',
      },
      {
        image: experienciasSM,
        title: 'Cenco Experiencias SM',
        url: 'https://cencosud.csod.com/samldefault.aspx?returnurl=%252fDeepLink%252fProcessRedirect.aspx%253fmodule%253dloRegisterAndLaunch%2526lo%253d01be574c-d37d-4f72-9f0c-8a9eb199c7a9',
      },
      {
        image: lineaCajas,
        title: 'Para cajas: haciendo lo correcto en línea de cajas',
        url: 'https://cencosud.csod.com/samldefault.aspx?returnurl=%252fDeepLink%252fProcessRedirect.aspx%253fmodule%253dlaunchtraining%2526lo%253d4759ab58-f025-4dfd-b979-4f69d87424d4',
      },
    ],
  },
  mejoramientoDelHogar: {
    title: 'Mejoramiento del Hogar',
    courses: [
      {
        image: experienciasMDH,
        title: 'Cenco Experiencias MDH',
        url: 'https://cencosud.csod.com/samldefault.aspx?returnurl=%252fDeepLink%252fProcessRedirect.aspx%253fmodule%253dloRegisterAndLaunch%2526lo%253d230b2e6b-2d72-4c80-967c-e6585118d7a1',
      },
    ],
  },
  retailFinanciero: {
    title: 'Retail financiero',
    courses: [
      {
        image: programaLavadoActivos,
        title: 'Programa de Prevención del Lavado de Activos y la Financiación del Terrorismo',
        url: 'https://cencosud.csod.com/samldefault.aspx?returnurl=%252fDeepLink%252fProcessRedirect.aspx%253fmodule%253dlaunchtraining%2526lo%253df743d675-c6c9-4a43-b528-050f053ecfc9',
      },
      {
        image: capacitacionLavadoActivos,
        title: 'Capacitación Anual de Prevención de Lavado de Activos y Financiación del Terrorismo',
        url: 'https://cencosud.csod.com/samldefault.aspx?returnurl=%252fDeepLink%252fProcessRedirect.aspx%253fmodule%253dloRegisterAndLaunch%2526lo%253df1eb7c71-7e32-462f-bef7-e9f49c2d2bfe',
      },
    ],
  },
};

export default coursesData;
