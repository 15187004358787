import React, { useState, useEffect } from 'react';
import MiEquipoService from '../../../services/mi-equipo/mi-equipo-service';

const useRemuneraciones = () => {
    const [remuneracionesData, setRemuneracionesData] = useState();

    const getRemuneraciones = async () => {
        const data = await MiEquipoService.getRemuneraciones();
        setRemuneracionesData(data);
    }

    useEffect(() => {
        getRemuneraciones();
    }, []);

    return { remuneracionesData };
};

export default useRemuneraciones;