import React, { useState, useEffect, FunctionComponent } from "react";
import { Props, Doc, EthicsData, RulesData, ConflictData, Options, Conflicts, AccountData, PolicyData, ConflictObject } from "../../utilities/interfaces";
import { useForm, SubmitHandler } from "react-hook-form";
import OfflineModal from "../../commons/plain/modals/offline";
import DownloadingModal from "../../commons/plain/modals/downloading";
import { handleDownload } from "../../utilities/functions";
import DocumentSection from "./documentSection";
import { textConflicts, textEthic, textInternal, textPolicy } from "./constants/constants";
import Table from "./table";
import ConflictModal from "./conflictModal";
import SubmitModal from "../../commons/modals/submitModal";
import fichaContratacionService from "../../../../services/ficha-contratacion-service";
import { scrollToTop } from "../../../../services/utiles";
interface Props1 extends Props {
    prevStep(): void;
    company: string,
    ethicsData: EthicsData;
    photoData: string;
    rulesData: RulesData;
    accountData: AccountData;
    conflictData: ConflictData;
    policyData: PolicyData;
    uniformData: {[K: string]: string} | null;
    postValues: any;
    setPostValues: React.Dispatch<React.SetStateAction<any>>;
    setToken: React.Dispatch<React.SetStateAction<string>>;
    token: string;
}

const NinthStep: FunctionComponent<Props1> = ({ options, company, nextStep, prevStep, ethicsData, photoData, rulesData, policyData, accountData, conflictData, uniformData, postValues, setPostValues, setToken, token }) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
    } = useForm<EthicsData | RulesData | ConflictData | PolicyData>({
        defaultValues: {
            ...rulesData,
            ...ethicsData,
            ...policyData,
            ...conflictData,
            cn_codeti: "0",
            cn_conint: "0",
            cn_regint: "0",
            cn_polcom: "0",
        }   
    });

    const [file1, setFile1] = useState<Doc | null>(null);
    const [downloadedFile1, setdownloadedFile1] = useState(false);
    const [checkedDoc1, setCheckedDoc1] = useState(false);

    const [file2, setFile2] = useState<Doc | null>(null);
    const [downloadedFile2, setdownloadedFile2] = useState(false);
    const [checkedDoc2, setCheckedDoc2] = useState(false);

    const [file3, setFile3] = useState<Doc | null>(null);
    const [downloadedFile3, setdownloadedFile3] = useState(false);
    const [checkedDoc3, setCheckedDoc3] = useState(false);

    const [checkedDoc4, setCheckedDoc4] = useState(false);
    const [conflictsList, setConflictsList] = useState<Array<Conflicts> | null>(null);
    const [conflictModal, setConflictModal] = useState<Conflicts | null>(null);

    const [postStatus, setPostStatus] = useState<any>(false);
    const [submitModalStatus, setSubmitModalStatus] = useState(false);

    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        let conf = sessionStorage.getItem("conflictsList");
        if (conf !== null) {
            let conflictsCopy = JSON.parse(conf);
            if (Array.isArray(conflictsCopy)) {
                let isConflictList = true;
                conflictsCopy.forEach((c) => {
                    if (c.conflictValue === undefined || c.relationshipValue ===undefined || c.rut === undefined || c.name == undefined || c.obs === undefined) {
                        isConflictList = false;
                    }
                });
                if (isConflictList) {
                    conflictsCopy = [...conflictsCopy] as Array<Conflicts>;
                    setConflictsList(conflictsCopy);
                }
            }
        }
    }, []);

    useEffect(() => {
        if (conflictModal !== null) {
            document.getElementById("openConflictModal")?.click();
        }
    }, [conflictModal]);

    const rules = watch("cn_regint");
    const ethics = watch("cn_codeti");
    const conflicts = watch("cn_conint");
    const policy = watch("cn_polcom");
    const hasConflicts = watch("cn_poseeconint");

    useEffect(() => {
        if (rules === "" || rules === "0") {
            setCheckedDoc1(false);
        } else if (rules === "1") {
            setCheckedDoc1(true);
        }
    }, [rules]);

    useEffect(() => {
        if (checkedDoc1) {
            setValue("cn_regint", "1");
        } else {
            setValue("cn_regint", "0");
        }
    }, [checkedDoc1]);

    useEffect(() => {
        if (file1 !== null) {
            handleDownload(file1, setdownloadedFile1, "closeDownloadModal");
        }
    }, [file1]);

    useEffect(() => {
        if (ethics === "" || ethics === "0") {
            setCheckedDoc2(false);
        } else if (ethics === "1") {
            setCheckedDoc2(true);
        }
    }, [ethics]);

    useEffect(() => {
        if (checkedDoc2) {
            setValue("cn_codeti", "1");
        } else {
            setValue("cn_codeti", "0");
        }
    }, [checkedDoc2]);

    useEffect(() => {
        if (file2 !== null) {
            handleDownload(file2, setdownloadedFile2, "closeDownloadModal");
        }
    }, [file2]);

    useEffect(() => {
        if (conflicts === "" || conflicts === "0") {
            setCheckedDoc4(false);
        } else if (conflicts === "1") {
            setCheckedDoc4(true);
        }
    }, [conflicts]);

    useEffect(() => {
        if (checkedDoc3) {
            setValue("cn_polcom", "1");
        } else {
            setValue("cn_polcom", "0");
        }
    }, [checkedDoc3]);

    useEffect(() => {
        if (file3 !== null) {
            handleDownload(file3, setdownloadedFile3, "closeDownloadModal");
        }
    }, [file3]);

    useEffect(() => {
        if (policy === "" || policy === "0") {
            setCheckedDoc3(false);
        } else if (policy === "1") {
            setCheckedDoc3(true);
        }
    }, [policy]);

    useEffect(() => {
        if (checkedDoc4) {
            setValue("cn_conint", "1");
        } else {
            setValue("cn_conint", "0");
        }
    }, [checkedDoc4]);
    

    const formSubmitHandler: SubmitHandler<RulesData | EthicsData | ConflictData | PolicyData> = (data: RulesData | EthicsData | ConflictData | PolicyData) => {
        if (navigator.onLine) {
            if (downloadedFile1 && downloadedFile2) {
                setDisableButton(true);
                let newData = { ...data as RulesData | EthicsData | ConflictData | PolicyData };
                let conflicts : ConflictObject[] = [];
                if (conflictsList !== null && conflictsList.length > 0) {
                    for (const c of conflictsList) {
                        conflicts.push({
                            conflictTypeCd : c.conflictValue,
                            relationshipCd : c.relationshipValue,
                            conflictedRut : c.rut,
                            conflictedName : c.name,
                            observation : c.obs
                        });
                    }
                }
                newData = { ...newData, coninttext: "", conflicts : JSON.stringify(conflicts) };
                setSubmitModalStatus(true);
                document.getElementById("openThanksModal")?.click()
                fichaContratacionService.postMethods.setFicha(postValues, newData, setPostValues, true, setPostStatus, setDisableButton, photoData, accountData, uniformData, setToken);
            }
        } else {
            document.getElementById("offline")?.click();
        }
    };

    return (
        <>
            <form
                className="needs-validation"
                onSubmit={handleSubmit(formSubmitHandler)}
            >
                <div id={"rules"} className="box mb-4">
                    <DocumentSection
                        title={"Reglamento Interno"}
                        checkontop={false}
                        text={textInternal}
                        checked={checkedDoc1}
                        company={company}
                        docType={"3"}
                        downloadedFile={downloadedFile1}
                        errors={errors}
                        register={register}
                        setChecked={setCheckedDoc1}
                        setFile={setFile1}
                        targetFile={"cn_regint"}
                    />
                </div>
                <div id={"policy"} className="box mb-4">
                    <DocumentSection
                        title={"Política Libre Competencia"}
                        checkontop={false}
                        text={textPolicy}
                        checked={checkedDoc3}
                        company={company}
                        docType={"7"}
                        downloadedFile={downloadedFile3}
                        errors={errors}
                        register={register}
                        setChecked={setCheckedDoc3}
                        setFile={setFile3}
                        targetFile={"cn_polcom"}
                    />
                </div>
                <div id={"ethics"} className="box mb-4">
                    <DocumentSection
                        title={"Código de Ética"}
                        checkontop={false}
                        text={textEthic}
                        checked={checkedDoc2}
                        company={company}
                        docType={"4"}
                        downloadedFile={downloadedFile2}
                        errors={errors}
                        register={register}
                        setChecked={setCheckedDoc2}
                        setFile={setFile2}
                        targetFile={"cn_codeti"}
                    />
                </div>
                <div id={"conflicts"} className="box mb-4">
                    <DocumentSection
                        title={"Declaración de Conflicto de Interés"}
                        message="A continuación y según lo estipulado en el código de ética, doy a conocer las situaciones que actualmente pudieran &ldquo;Ser&rdquo; o &ldquo;Parecer&rdquo; un conflicto de interés respecto de mi cargo. Junto con ello, me pongo a disposición de la compañía para entregarle información adicional, en caso que esto sea necesario (especificar la situación y en caso que corresponda: Nombre, parentesco, número de documentos de identificación, identificación de sociedades, vinculaciones con proveedores/terceros, etc)."
                        subtitle="Lea el siguiente texto y luego seleccione la opción que corresponda."
                        checkontop={true}
                        text={textConflicts}
                        checked={checkedDoc4}
                        errors={errors}
                        register={register}
                        setChecked={setCheckedDoc4}
                        targetFile={"cn_conint"}
                        targetRadio={"cn_poseeconint"}
                        radioValue={hasConflicts}
                    />
                    {hasConflicts === "1" ?
                        <Table
                            conflictsOptions={options.get("conflictsOptions") as Array<Options>}
                            conflictsList={conflictsList}
                            setConflictModal={setConflictModal}
                            setConflictsList={setConflictsList}
                        />
                    : null}
                    {hasConflicts === "1" && (conflictsList === null || conflictsList?.length === 0) &&
                    <>
                        <div className="invalid-feedback d-block">
                            {"Debe agregar al menos un conflicto presionando el botón (+)"}
                        </div>
                    </>}
                </div>
                <div className="d-grid d-md-flex justify-content-md-between gap-2">
                    <button type="button" className="btn btn-primary" onClick={(e) => { e.preventDefault(); prevStep() }}>
                        <i className="fa fa-chevron-left fa-fw fa-xs" aria-hidden="true" />
                        {"Anterior"}
                    </button>
                    <button type="submit" className="btn btn-degradado" disabled={hasConflicts === "1" && (conflictsList === null || conflictsList?.length === 0) ? true : disableButton}>
                        {"Siguiente"}
                        <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                    </button>
                </div>
                <SubmitModal postStatus={postStatus} nextStep={nextStep} token={token}/>
            </form>
            {conflictModal !== null &&
                <ConflictModal conflictModal={conflictModal} setConflictModal={setConflictModal} setConflictsList={setConflictsList} options={options} />
            }
            <DownloadingModal idBtn="downloadDocumentModal" target="downloadModal" closeTarget="closeDownloadModal"/>
            <OfflineModal />
        </>
    );
};

export default NinthStep;
