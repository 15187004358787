
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { List } from '@mui/material'
import { Article } from '../../../utilities/interfaces';
import { paths } from '../../../utilities/paths';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { extractFirstElement, removeSymbols, replaceTextWithLink, removeSpan } from '../utils/parseContent';
import { ResultsProps, TextExtractProps } from '../interfaces'
import textstyle from '../../../commons/styles/textStyles.module.css'
import resultsStyles from '../searchResultsStyle.module.css'

const Results = ({ articles, searchText }: ResultsProps) => {
  const navigate = useNavigate()

  const goToArticle = (value: Article) => {
    navigate(paths[3].path + value?.attributes.slug + '/' + value?.id)
  }

  const TextExtract = ({ listItem }: TextExtractProps): JSX.Element => {
    if (!listItem.contentMatch) {
      const text = extractFirstElement(listItem)
      const removeLinks = replaceTextWithLink(text)
      const removeSpans = removeSpan(removeLinks)
      const cleanText = removeSymbols(removeSpans)
      return (
        <p className={resultsStyles.article_preview} key={listItem.id}>
          ...{cleanText}...
        </p>
      )
    } else {
      const matches = match(listItem.contentMatch, searchText, { insideWords: true });
      const parts = parse(listItem.contentMatch, matches);

      return (
        <p className={resultsStyles.article_preview}>
          ...
          {parts.map((part, index) => {
            const cleanPart = removeSymbols(part.text)
            return (
              <span
                key={index}
                style={{ fontWeight: part.highlight ? 600 : 400 }}>
                {cleanPart}
              </span>
            )
          })}
          ...
        </p>
      )
    }

  }

  return (
    <List className={resultsStyles.table_list_wrapper}>
      {articles.map((listItem: Article) => {
        return (
          <div onClick={() => goToArticle(listItem)} className={resultsStyles.result_item} key={listItem.id}>
            <h3 className={textstyle.section_title}>{listItem.attributes.title}</h3>
            {listItem.breadCrumb &&
              <p className={resultsStyles.article_breadcrumb}>{listItem.breadCrumb}</p>
            }
            <TextExtract listItem={listItem} />
          </div>
        )
      })}
    </List>
  );
}

export default Results;