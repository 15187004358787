import Tippy from "@tippyjs/react";
import React from "react";

export const FieldDateCmp = ({ label, callback,valueState, Usetippy, textTippy,name }) => {
  return (
    <div className="col-12 col-md-4 mb-3">
      <label className="form-label fw-bold">
        {label}
        {Usetippy && (
          <Tippy content={textTippy}>
            <i
              className="fa fa-question fa-fw fa-lg color-orange"
              aria-hidden="true"
            ></i>
          </Tippy>
        )}
      </label>
      <div className="input-group">
        <input
          className="form-control license-picker"
          onChange={(e) => callback(e.target.value)}
          value={valueState ? valueState : "mm/dd/yyyY"}
          required
          name={name}
          type="date"
        ></input>
      </div>
    </div>
  );
};
