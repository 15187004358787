import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import styles from "../../resources/css/style-COL.module.css";
import InterestConflictServices from "../../services/conflicto-interes/service";
import errorMessage from "../SharedComponents/error-message";
import successMessage from "../SharedComponents/success-message";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-degradado ms-md-2",
    cancelButton: "btn btn-primary",
  },
  buttonsStyling: false,
});

const Historic = ({ historics, signatureDate, relationships, reload, conflictTypes}) => {
  const sortedHistorics = historics.sort((a, b) => moment(b.dateSignature).diff(moment(a.dateSignature)))
  const [isVigenciaLoading, setIsVigenciaLoading] = useState()
  const cambiarVigencia = async (conflict, e) => {
    const submit = {}
    submit.vigency = e.target.value
    submit.signatureDate = signatureDate
    submit.isThereConflict = conflict.isThereConflict == 'Sí' ? "Y" : "N"
    submit.relationship = relationships.filter(i => i.description == conflict.relationship)[0].id
    submit.conflictType = conflictTypes.filter(i => i.description == conflict.conflictType)[0].id
    submit.id = conflict.id
    submit.dateSignature = conflict.dateSignature
    submit.relationshipName = conflict.relationshipName
    submit.observation = conflict.observation
    try {
      swalWithBootstrapButtons
      .fire({
        title: "Confirmación",
        text: "¿Desea continuar la operación?",
        // icon: "warning", 
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
        showCloseButton: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          setIsVigenciaLoading(true);
          // Call to service    
          const response = await InterestConflictServices.putVigencia(submit);

          if (response === null) {
            errorMessage(null, "");
          } else {
            const status = response?.statusCode || response?.status;
            switch (status) {
              case 200:
                setIsVigenciaLoading(false);
                successMessage(reload);
                break;

              case 400:
                errorMessage(null, response?.message);
                setIsVigenciaLoading(false);
                break;

              case 404:
                errorMessage(null, response?.message);
                setIsVigenciaLoading(false);
                break;

              case 500:
                errorMessage(null, response?.message);
                setIsVigenciaLoading(false);
                break;

              default:
                errorMessage(null, response?.message); setIsVigenciaLoading(false); break;
            }
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          reload()
         }
      });
    } catch (error) {
      setIsVigenciaLoading(false);
    }
  }
  return (
      <div className={`${styles["mt-4"]} ${styles["box"]}`}>
        <div
          className={
            styles["tit-group"] +
            " " +
            styles["d-flex"] +
            " " +
            styles["justify-content-between"]
          }
        >
          <h2 className={styles["h4"] + " " + styles["tit-section"]}>
            Registros
          </h2>
        </div>

        {/* <!-- Escritorio (Tabla) --> */}
        <div className={styles["table-responsive"]}>
          <table
            className={
              styles.table +
              " " +
              styles["table-even"] +
              " " +
              styles["table-section"] +
              " " +
              styles["table-borderless td-none"] +
              " " +
              styles["d-none"] +
              " " +
              styles["d-md-table"]
            }
          >
            {!Array.isArray(sortedHistorics) ||
            sortedHistorics?.length === 0 ||
            sortedHistorics === null ||
            sortedHistorics === undefined ? (
              <caption>No se encontraron datos asociados.</caption>
            ) : null}

            <thead>
              <tr>
                <th>Fecha</th>
                <th>¿Conflicto?</th>
                <th>Tipo</th>
                <th>Relación</th>
                <th className="justify-content-center">Detalle</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(sortedHistorics)
                ? sortedHistorics.map((i, index) => {
                    return (
                      <tr key={i.id}>
                        <td>{i.dateSignature}</td>
                        <td>{i.isThereConflict}</td>
                        <td className={"text-break"}>{i.conflictType}</td>
                        <td className={"text-break"}>{i.relationship}</td>
                        <td>
                          {i.isThereConflict == 'Sí' ? (<><Link to="#" data-bs-toggle="modal"
                            data-bs-target={`#ver-detalle-${index}`}><i className={`${styles["fa"]} ${styles["fa-eye"]} ${styles["fa-fw"]} ${styles["fa-lg"]}`} aria-hidden="true"></i></Link>

                          <div
                            className="modal fade"
                            id={`ver-detalle-${index}`}
                            tabIndex="-1"
                            aria-labelledby={`ver-detalle-${index}`}
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                                <div className="modal-header border-0 pb-0">
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="modal-body py-4">
                                  <h2 className="h4 mb-4 text-center">{i.relationshipName ? i.relationshipName : "No se ingreso nombre"}</h2>
                                  <p>
                                   {i.observation ? i.observation : "No se ingresaron detalles"}
                                  </p>
                                </div>
                                <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                                  <button
                                    type="submit"
                                    className="btn btn-degradado col-12 col-md-auto my-auto px-5"
                                    data-bs-dismiss="modal"
                                  >
                                    Cerrar{" "}
                                    <i
                                      className="fa fa-chevron-right fa-fw fa-xs"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div></>) : null}
                          </td>
                        <td>{i.isThereConflict == 'Sí' ? (isVigenciaLoading? <div className="spinner-border spinner-border-sm color-primary"></div> : 
                        // <input className={`${styles["form-check-input"]}`} onChange={() => cambiarVigencia(i.id)} defaultChecked={i.vigente == 'N'} disabled={i.vigente == 'N'}></input>
                        <div className={styles["input-group"]}>
                        <select
                          className={styles["form-select"]}
                          onChange={(e) => cambiarVigencia(i, e)}
                        >
                          <option key={"key_no_0"} selected={i.vigency == 'N'} value={"N"}>
                            {"No vigente"}
                          </option>
                          <option key={"key_si_1"} selected={i.vigency == 'Y'} value={"Y"}>
                            {"Vigente"}
                          </option>
                        </select>
                      </div>
                      ) : null}</td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      {/* <!-- Móvil (Acordeón) --> */}
      <div className={`accordion accordion-flush accordion-light accordion-table ${styles["d-md-none"]}`}>
        {!Array.isArray(sortedHistorics) ||
          sortedHistorics?.length === 0 ||
          sortedHistorics === null ||
          sortedHistorics === undefined ? (
          <caption>No se encontraron datos asociados.</caption>
        ) : null}
        {Array.isArray(sortedHistorics)
          ? sortedHistorics.map((i, index) => {
            return (<div key={i.id} className="accordion-item">
              <h2 className="accordion-header" id="accordion">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-item-${index}`} aria-expanded="false" aria-controls={`accordion-item-${index}`}>
                  <strong>Fecha</strong> {i.dateSignature}
                </button>
              </h2>
              <div id={`accordion-item-${index}`} className="accordion-collapse collapse" aria-labelledby="accordion">
                <div className="accordion-body p-0">
                  {/* <!-- cuerpo item --> */}
                  <div className="data-list-box data-list-box-even">
                    <ul className="data-list-section--item list-unstyled">
                      <li>
                        <span className="data-list-box__title">Conflicto</span>
                        <span className="data-list-box__text">{i.isThereConflict}</span>
                      </li>
                      <li>
                        <span className="data-list-box__title">Tipo</span>
                        <span className="data-list-box__text">{i.conflictType}</span>
                      </li>
                      <li>
                        <span className="data-list-box__title">Relación</span>
                        <span className="data-list-box__text">{i.relationshipName}</span>
                      </li>
                      <li>
                        <span className="data-list-box__title">Detalle</span>
                        <span className="data-list-box__text"><Link to="#" data-bs-toggle="modal"
                            data-bs-target={`#ver-detalle-mobile-${index}`}><i className={`${styles["fa"]} ${styles["fa-eye"]} ${styles["fa-fw"]} ${styles["fa-lg"]}`} aria-hidden="true"></i></Link>
                          </span><div
                            className="modal fade"
                            id={`ver-detalle-mobile-${index}`}
                            tabIndex="-1"
                            aria-labelledby={`ver-detalle-mobile-${index}`}
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                                <div className="modal-header border-0 pb-0">
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="modal-body py-4">
                                  <h2 className="h4 mb-4 text-center">{i.relationshipName ? i.relationshipName : "No se ingreso nombre"}</h2>
                                  <p>
                                  {i.observation ? i.observation : "No se ingresaron detalles"}
                                  </p>
                                </div>
                                <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                                  <button
                                    type="submit"
                                    className="btn btn-degradado col-12 col-md-auto my-auto px-5"
                                    data-bs-dismiss="modal"
                                  >
                                    Cerrar{" "}
                                    <i
                                      className="fa fa-chevron-right fa-fw fa-xs"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                      </li>
                      <li>
                        <span className="data-list-box__title">No vigente</span>
                        <span className="data-list-box__text">{isVigenciaLoading ? <div className="spinner-border spinner-border-sm color-primary"></div> : <input type="checkbox" className={`${styles["form-check-input"]}`} onChange={() => cambiarVigencia(i.id)} defaultChecked={i.vigente == 'N'} disabled={i.vigente == 'N'}></input>}</span>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- fin cuerpo item --> */}
                </div>
              </div>
            </div>)
          })
          : null}
        {/* <!-- fin items acordeón --> */}
      </div>
      {/* <!-- fin Móvil (Acordeón) --> */}
      </div>
    );
};

export default Historic;
