import React, { useState, useEffect, FormEvent, FunctionComponent, Children } from "react";
import { FormList, Options, Props } from "../../utilities/interfaces";
import { useForm, SubmitHandler } from "react-hook-form";
import OfflineModal from "../../commons/plain/modals/offline";
import { scrollToTop } from "../../../../services/utiles";

interface Props1 extends Props {
    prevStep(): void;
    uniformList: Array<[Options, Array<Options>]> | null;
    uniformData: {[K: string]: string} | null;
    setUniformData: React.Dispatch<React.SetStateAction<{[K: string]: string} | null>>;
}

const SevethStep: FunctionComponent<Props1> = ({ options, nextStep, prevStep, uniformList, uniformData, setUniformData }) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
    } = useForm<{[K: string]: string}>({
        defaultValues: {
            ...uniformData,
        }
    });

    const [disableButton, setDisableButton] = useState(false);

    const formSubmitHandler: SubmitHandler<{[K: string]: string}> = (data: {[K: string]: string}) => {
        if (navigator.onLine) {
            setDisableButton(true);
            setUniformData(data)
            nextStep();
        } else {
            document.getElementById("offline")?.click();
        }
    };

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        return () => setDisableButton(false);
    }, []);

    return (
        <>
            <form
                className="needs-validation"
                onSubmit={handleSubmit(formSubmitHandler)}
            >
                <div id={"pension"} className="box mb-4">
                    <h2 className="h4 tit-section" children={"Uniforme"} />
                    <div className="data-list-box data-list-box-even data-list-box-align-middle data-list-box-form">
                        <ul className="list-column-1 list-unstyled">
                            <li className="d-none d-md-flex">
                                <span className="data-list-box__title color-primary">{"Prenda"}</span>
                                <span className="data-list-box__text fw-bolder color-primary">{"Talla"}</span>
                            </li>
                            {Children.toArray(
                                uniformList?.map((wear, index) => {
                                    return (
                                        <>
                                            <li>
                                                <span id={`wear:${wear[0].value}`} className="data-list-box__title" key={"detalle" + index}>
                                                    {`${wear[0].label}*`}
                                                </span>
                                                <span className="data-list-box__text" key={"detalleSelect" + index}>
                                                    <select
                                                        id={"inputState" + index}
                                                        key={"select" + index}
                                                        className="form-select"
                                                        {...register(wear[0].value as string,{
                                                            required: "Seleccionar una tala"
                                                        })}
                                                    >
                                                        <option disabled={true} value={""}>Seleccione una Opción</option>
                                                        {wear[1].map((o) => (
                                                            <option key={o.key} value={o.value}>
                                                                {o.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors[wear[0].value] &&
                                                        <div key={"error" + wear[0].key} className="invalid-feedback d-block">
                                                            {`Debe elegir una talla para ${wear[0].label}`}
                                                        </div>}
                                                </span>
                                            </li>
                                        </>
                                    );
                                })
                            )}
                        </ul>
                    </div>
                </div>
                <div className="d-grid d-md-flex justify-content-md-between gap-2">
                    <button type="button" className="btn btn-primary" onClick={(e) => { e.preventDefault(); prevStep() }}>
                        <i className="fa fa-chevron-left fa-fw fa-xs" aria-hidden="true" />
                        {"Anterior"}
                    </button>
                    <button type="submit" className="btn btn-degradado" disabled={disableButton}>
                        {"Siguiente"}
                        <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                    </button>
                </div>
            </form>
            <OfflineModal />
        </>
    );
};

export default SevethStep;