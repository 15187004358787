import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DatosPersonalesServices from "../../services/datos personales col/datos-personales-services";
import DetalleCargo from "./detalle-cargo";
import styles from "../../resources/css/style-COL.module.css";
import Swal from "sweetalert2";

export default function InformacionContacto() {
  const [infoContacto, setInfoContacto] = useState({});
  const [states, setStates] = useState([]);
  const [counties, setCounties] = useState([]);
  const [disable, setDisable] = useState(true);

  const [location, setLocation] = useState([]);

  const [loadInfo, setLoadInfo] = useState(true);

  const [loadingStates, setLoadingStates] = useState(false);
  const [loadingCounties, setLoadingCounties] = useState(false);
  const [error, setError] = useState(null);
  const [ok, setOk] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  useEffect(() => {
    DatosPersonalesServices.GetInformacionContacto(
      setInfoContacto,
      setLoadInfo
    );
    reset(infoContacto);
  }, []);

  const reloadInfo = () => {
    setLoadInfo(true);
    DatosPersonalesServices.GetInformacionContacto(
      setInfoContacto,
      setLoadInfo
    );
  };
  useEffect(async () => {
    if (infoContacto.STATE) {
      setLoadingStates(true);
      await DatosPersonalesServices.GetStates(
        "PE",
        setStates,
        setLoadingStates
      );
      await DatosPersonalesServices.GetCounties(
        "PE",
        infoContacto.STATE,
        setCounties,
        setLoadingCounties
      );
      await DatosPersonalesServices.GetLocation(
        "PE",
        infoContacto.STATE,
        infoContacto.COUNTY_CD,
        setLocation
      );
    }
  }, [infoContacto]);

  useEffect(async () => {
    if (watch("STATE")) {
      if (watch("STATE") != infoContacto.STATE) {
        setValue("COUNTY_CD", "");
        setValue("CN_CITY_CD", "");
      }
      setLoadingCounties(true);
      await DatosPersonalesServices.GetCounties(
        "PE",
        watch("STATE"),
        setCounties,
        setLoadingCounties
      );
    }
  }, [watch("STATE")]);

  useEffect(async () => {
    if (watch("COUNTY_CD")) {
      // setValue("CN_CITY_CD", undefined);
      // setLocation(true)
      await DatosPersonalesServices.GetLocation(
        "PE",
        watch("STATE"),
        watch("COUNTY_CD"),
        setLocation
      );
    }
  }, [watch("COUNTY_CD")]);

  useEffect(() => {
    if (error != null) handleStatusError(error);
  }, [error]);

  useEffect(() => {
    if (ok != null) handleStatusSuccess();
  }, [ok]);

  const onSubmit = async (data) => {
    setLoadInfo(true);
    var CN_COL_DP_CONT_PUT = {
      DIRECCION: data.DIRECCION,
      ADICIONAL: data.ADICIONAL,
      STATE: data.STATE,
      COUNTY_CD: data.COUNTY_CD,
      CN_CITY_CD: data.CN_CITY_CD,
      CN_DANE_CD: "169" + data.STATE + data.COUNTY_CD,
      HOME_PHONE: data.HOME_PHONE,
      EXTENSION: data.EXTENSION || "",
      DORM_EMAIL: data.DORM_EMAIL,
    };
    await DatosPersonalesServices.updateInformationContact(
      CN_COL_DP_CONT_PUT,
      setError,
      setOk
    );
  };

  const handleStatusError = (message) => {
    Swal.fire({
      allowOutsideClick: false,
      position: "center",
      title:
        '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
      html: `<div style="text-align: center; font-size: 15px"> ${message}</div>`,
      confirmButtonColor: "#11427C",
    }).then(() => {
      reloadInfo();
    });
    setError(null);
    setDisable(true);
  };

  // Mensaje de éxito al enviar un post
  const handleStatusSuccess = () => {
    Swal.fire({
      allowOutsideClick: false,
      position: "center",
      title:
        '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
      html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
      confirmButtonColor: "#11427C",
    }).then(() => {
      reloadInfo();
    });
    setOk(null);
    setDisable(true);
  };

  return (
    <div className={`${styles["row"]} ${styles["mb-4"]}`}>
      <DetalleCargo />
      {loadInfo ? (
        <div
          className={`${styles["col-12"]} ${styles["col-xl-8"]} ${styles["d-flex"]}`}
        >
          <div
            className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}
          >
            <div className={styles["alert"]} role="alert">
              <i
                className={`${styles["fa"]} ${styles["fa-spinner"]} ${styles["fa-spin"]} ${styles["color-orange"]} ${styles["fa-fw"]}`}
                aria-hidden="true"
              ></i>
              <span className={styles["ms-2"]}>Cargando...</span>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`${styles["col-12"]} ${styles["col-xl-8"]} ${styles["d-flex"]}`}
        >
          <div
            className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}
          >
            <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>
              Información de Contacto
            </h2>
            <p>
              Datos Obligatorios <span className={styles["color-red"]}>*</span>
            </p>

            <form>
              <div className={styles["row"]}>
                <div className={styles["mb-3"]}>
                  <label
                    className={`${styles["form-label"]} ${styles["mb-2"]}`}
                  >
                    <strong>Departamento</strong>{" "}
                    <span className={styles["color-red"]}>*</span>
                  </label>
                  <div
                    className={`${styles["row"]} ${styles["align-items-center"]}`}
                  >
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-6"]}`}
                    >
                      <div
                        className={`${styles["input-group"]} ${styles["input-group-btn"]}`}
                      >
                        <select
                          className={styles["select-col"]}
                          {...register("STATE", {
                            required: "* Campo obligatorio",
                          })}
                          disabled={disable}
                          defaultValue={infoContacto.STATE}
                        >
                          <option disabled value="">
                            Seleccione
                          </option>
                          {states.map((s) => {
                            return (
                              <option
                                key={s.state}
                                value={s.state}
                                selected={infoContacto.STATE == s.state}
                              >
                                {s.descrshort}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <small className={styles["form-error-message"]}>
                        {errors.STATE?.message}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles["row"]}>
                <div className={styles["mb-3"]}>
                  <label
                    className={`${styles["form-label"]} ${styles["mb-2"]}`}
                  >
                    <strong>Ciudad</strong>{" "}
                    <span className={styles["color-red"]}>*</span>
                  </label>
                  <div
                    className={`${styles["row"]} ${styles["align-items-center"]}`}
                  >
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-6"]}`}
                    >
                      <div
                        className={`${styles["input-group"]} ${styles["input-group-btn"]}`}
                      >
                        <select
                          className={styles["select-col"]}
                          {...register("COUNTY_CD", {
                            required: "* Campo obligatorio",
                          })}
                          disabled={disable}
                          defaultValue={infoContacto.COUNTY_CD}
                        >
                          <option disabled value="">
                            Seleccione
                          </option>
                          {counties.map((c) => {
                            return (
                              <option
                                key={c.county}
                                value={c.county}
                                selected={infoContacto.COUNTY_CD == c.county}
                              >
                                {c.descr}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <small className={styles["form-error-message"]}>
                        {errors.COUNTY_CD?.message}
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles["row"]}>
                <div className={styles["mb-3"]}>
                  <label
                    className={`${styles["form-label"]} ${styles["mb-2"]}`}
                  >
                    <strong>Localidad</strong>{" "}
                    <span className={styles["color-red"]}>*</span>
                  </label>
                  <div
                    className={`${styles["row"]} ${styles["align-items-center"]}`}
                  >
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-6"]}`}
                    >
                      <div
                        className={`${styles["input-group"]} ${styles["input-group-btn"]}`}
                      >
                        <select
                          className={styles["select-col"]}
                          {...register("CN_CITY_CD", {
                            required: "* Campo obligatorio",
                          })}
                          disabled={disable}
                        >
                          <option disabled value="">
                            Seleccione
                          </option>
                          {location.map((c) => {
                            return (
                              <option
                                key={c.CITY}
                                value={c.CITY}
                                selected={infoContacto.CN_CITY_CD === c.CITY}
                              >
                                {c.DESCR}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <small className={styles["form-error-message"]}>
                        {errors.CN_CITY_CD?.message}
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles["row"]}>
                <div className={styles["mb-3"]}>
                  <label
                    className={`${styles["form-label"]} ${styles["mb-2"]}`}
                  >
                    <strong>Dirección</strong>
                  </label>
                  <div
                    className={`${styles["row"]} ${styles["align-items-center"]}`}
                  >
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-9"]} ${styles["mb-3"]}`}
                    >
                      <div className={styles["input-group"]}>
                        <input
                          {...register("DIRECCION", {
                            required: "* Campo obligatorio",
                          })}
                          type="text"
                          className={styles["form-control"]}
                          defaultValue={infoContacto.DIRECCION}
                          disabled={disable}
                        />
                      </div>
                      <small className={styles["form-error-message"]}>
                        {errors.DIRECCION?.message}
                      </small>
                    </div>
                  </div>

                  <div>
                    <label className={styles["form-label"]}>
                      <strong>Adicional</strong>
                    </label>
                    <div
                      className={`${styles["row"]} ${styles["align-items-center"]}`}
                    >
                      <div
                        className={`${styles["col-12"]} ${styles["col-md-9"]}`}
                      >
                        <div className={styles["input-group"]}>
                          <input
                            {...register("ADICIONAL")}
                            type="text"
                            className={styles["form-control"]}
                            placeholder="Torre/ Apartamento /Casa (Opcional)"
                            disabled={disable}
                            defaultValue={infoContacto.ADICIONAL}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles["row"]}>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]} ${styles["m-0"]}`}
                >
                  <label className={styles["form-label"]}>
                    <strong>Teléfono de Residencia</strong>{" "}
                    <span className={styles["color-red"]}>*</span>
                  </label>
                  <div
                    className={`${styles["row"]} ${styles["align-items-center"]}`}
                  >
                    <div className={styles["input-group"]}>
                      <input
                        {...register("HOME_PHONE", {
                          required: "* Campo obligatorio",
                        })}
                        type="text"
                        className={styles["form-control"]}
                        defaultValue={infoContacto.HOME_PHONE}
                        disabled={disable}
                      />
                    </div>
                    <small className={styles["form-error-message"]}>
                      {errors.HOME_PHONE?.message}
                    </small>
                  </div>
                </div>

                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    <strong>Extensión Telefónica</strong>
                  </label>
                  <div
                    className={`${styles["row"]} ${styles["align-items-center"]}`}
                  >
                    <div className={styles["col-8"]}>
                      <div className={styles["input-group"]}>
                        <input
                          {...register("EXTENSION")}
                          type="text"
                          className={styles["form-control"]}
                          defaultValue={infoContacto.EXTENSION}
                          disabled={disable}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles["row"]}>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    <strong>Email Corporativo</strong>
                  </label>
                  <div
                    className={`${styles["row"]} ${styles["align-items-center"]}`}
                  >
                    <div className={styles["input-group"]}>
                      <input
                        {...register("BUSN_EMAIL")}
                        type="text"
                        className={styles["form-control"]}
                        defaultValue={infoContacto.BUSN_EMAIL}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    <strong> Email Personal</strong>{" "}
                    <span className={styles["color-red"]}>*</span>
                  </label>
                  <div
                    className={`${styles["row"]} ${styles["align-items-center"]}`}
                  >
                    <div className={styles["input-group"]}>
                      <input
                        {...register("DORM_EMAIL", {
                          required: "* Campo obligatorio",
                        })}
                        type="text"
                        className={styles["form-control"]}
                        defaultValue={infoContacto.DORM_EMAIL}
                        disabled={disable}
                      />
                    </div>
                    <small className={styles["form-error-message"]}>
                      {errors.DORM_EMAIL?.message}
                    </small>
                  </div>
                </div>
              </div>

              <div
                className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mb-4"]}`}
              >
                <div className={`${styles["col-12"]} ${styles["col-md-auto"]}`}>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setDisable(false);
                      reloadInfo();
                      reset(infoContacto);
                    }}
                    className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`}
                  >
                    Modificar{" "}
                  </button>
                </div>
                {disable == false && (
                  <div
                    className={`${styles["col-12"]} ${styles["col-md-auto"]}`}
                  >
                    <button
                      className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["w-100"]}`}
                      onClick={handleSubmit(onSubmit)}
                    >
                      Guardar{" "}
                      <i
                        className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
