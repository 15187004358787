import React, { useState, useEffect, Children } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { formatDate } from "../../services/utiles";
const Section = ({ section, hiddenTd, setData }) => {
  Section.propTypes = {
    section: section,
    hiddenTd: hiddenTd,
    setData: setData,
  };
  section.rows = Array.isArray(section.rows) ? section.rows : [section.rows];
  const rowcant = section.rows === undefined ? 0 : section.rows.length;
  let headvisiblevalues = [],
    indexheadvisiblevalue = -1,
    mobileindex = -1;
  const [modalValues, setModalValues] = useState(false);

  if (section.sectionid === "DIRECCIONES") {
    //delete section.rows
    for (let i = 0; i < section.forms.length; i++) {
      if (section.forms[i].formid === "PAIS_DIRECCION") {
        section.forms[i]["values"] = [
          {
            label: "Chile",
            value: "CHL",
            visible: true,
          },
        ];
      } else if (section.forms[i].formid === "REGION_DIRECCION") {
        let Direcciones = JSON.parse(localStorage.getItem("DireccionCHL"));
        if (Direcciones) {
          Direcciones = Direcciones.response;
          if (Direcciones) {
            section.forms[i]["values"] = Direcciones.regiones.map(
              (direccion) => {
                return {
                  label: direccion.name,
                  value: direccion.state,
                  visible: true,
                };
              }
            );
          }
        } else {
          section.forms[i]["values"] = [
            {
              label: "",
              value: "",
              visible: "",
            },
          ];
        }
      } else if (section.forms[i].formid === "PROVINCIA_DIRECCION") {
        section.forms[i].type = "text";
      } else if (section.forms[i].formid === "COMUNA_DIRECCION") {
        let Comunas = JSON.parse(localStorage.getItem("DireccionCHL"));
        let comunas = [];
        if (Comunas) {
          if (Comunas.response !== undefined) {
            for (let i = 0; i < Comunas.response.regiones.length; i++) {
              if (Array.isArray(Comunas.response.regiones[i].provincias)) {
                for (
                  let j = 0;
                  j < Comunas.response.regiones[i].provincias.length;
                  j++
                ) {
                  if (
                    Array.isArray(
                      Comunas.response.regiones[i].provincias[j].comunas
                    )
                  ) {
                    for (
                      let k = 0;
                      k <
                      Comunas.response.regiones[i].provincias[j].comunas.length;
                      k++
                    ) {
                      comunas.push(
                        Comunas.response.regiones[i].provincias[j].comunas[k]
                      );
                    }
                  } else {
                    comunas.push(
                      Comunas.response.regiones[i].provincias[j].comunas
                    );
                  }
                }
              } else {
                if (
                  Array.isArray(Comunas.response.regiones[i].provincias.comunas)
                ) {
                  for (
                    let j = 0;
                    j < Comunas.response.regiones[i].provincias.comunas.length;
                    j++
                  ) {
                    comunas.push(
                      Comunas.response.regiones[i].provincias.comunas[j]
                    );
                  }
                } else {
                  comunas.push(Comunas.response.regiones[i].provincias.comunas);
                }
              }
            }
            section.forms[i]["values"] = comunas.map((comuna) => {
              return {
                label: comuna.nombre,
                value: comuna.city,
                visible: true,
              };
            });
          }
        } else {
          section.forms[i]["values"] = [
            {
              label: "",
              value: "",
              visible: "",
            },
          ];
        }
      }
    }
  }

  if (section.sectionid === "DATOS_BANCARIOS") {
    let values = [];
    const bankList = JSON.parse(localStorage.getItem("bankList"));
    if (bankList !== null && bankList.message !== "API rate limit exceeded") {
      for (let i = 0; i < bankList.CN_LISTABANK_RP.length; i++) {
        values.push({
          label: bankList.CN_LISTABANK_RP[i].descr_bank,
          value: bankList.CN_LISTABANK_RP[i].Codigo,
          visible: true,
        });
      }
      section.forms[1]["values"] = values;
    } else {
      section.forms[1]["values"] = [{}];
    }
  }

  const openModal = (e, action, rowid) => {
    e.preventDefault();
    let values = {
      action: action,
      rowid: rowid,
    };
    setModalValues(values);
  };

  useEffect(() => {
    if (modalValues)
      document
        .getElementById("modal" + modalValues.action + section.sectionid)
        .click();
  }, [modalValues]);

  return (
    <>
      <div id={section.title} className="box mb-4" key={section.title}>
        <div className="tit-group d-flex justify-content-between">
          <h2 className="h4 tit-section">{section.title}</h2>
          {section.editable ? (
            section.maxlength === 0 || section.maxlength > rowcant ? (
              <>
                <Link
                  to=""
                  onClick={(e) => openModal(e, "add", null)}
                  className="mt-2"
                  data-bs-toggle={"modal"}
                  data-bs-target={"#modal" + section.sectionid}
                  data-backdrop="static"
                  data-keyboard="false"
                >
                  <i
                    className="fa fa-plus-square-o fa-lg"
                    aria-hidden="true"
                  ></i>
                </Link>
                <a
                  hidden
                  id={"modaladd" + section.sectionid}
                  data-bs-toggle={"modal"}
                  data-bs-target={"#modal" + section.sectionid}
                />
              </>
            ) : null
          ) : null}
        </div>
        {
          <>
            {section.style === "table" ? (
              <>
                <table className="table table-even table-section table-borderless align-middle text-uppercase d-none d-md-table">
                  <thead>
                    <tr>
                      {section.headers.map((head) => {
                        headvisiblevalues.push(head.visible);
                        return head.visible ? (
                          <th
                            className="col-5"
                            key={head.formid}
                          >{head.value}</th>
                        ) : null;
                      })}
                      <th className="col-2 text-center">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {section.rows !== undefined ? (
                      Children.toArray(
                        section.rows.map((row) => {
                          indexheadvisiblevalue = -1;
                          return (
                            <tr key={row.row + String(Math.random())}>
                              {row.items !== undefined ? (
                                <>
                                  {Children.toArray(
                                    row.items.map((item) => {
                                      indexheadvisiblevalue++;
                                      return !headvisiblevalues[
                                        indexheadvisiblevalue
                                      ] ? null : (
                                        <td
                                          className={
                                            item.value === "N" ||
                                            item.value === "Y"
                                              ? "text-center"
                                              : "text-left"
                                          }
                                          key={item.formid}
                                        >{
                                          hiddenTd ? (
                                            "*****"
                                          ) : item.description !== "" ? (
                                            item.description
                                          ) : item.value === "Y" ||
                                            item.value === "N" ? (
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              value=""
                                              id="flexCheckDefault"
                                              checked={
                                                item.value === "Y"
                                                  ? true
                                                  : false
                                              }
                                              disabled
                                            />
                                          ) : RegExp(
                                              /^\d{4}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$/
                                            ).test(item.value) ? (
                                            formatDate(item.value)
                                          ) : (
                                            item.value
                                          )
                                        }</td>
                                      );
                                    })
                                  )}
                                  <td
                                    className="text-center"
                                    key={"btns" + String(Math.random())}
                                  >
                                    {row.editable ? (
                                      <>
                                        <Link
                                          to=""
                                          onClick={(e) => {
                                            openModal(e, "edit", row.row);
                                          }}
                                          className="mt-2"
                                          data-bs-toggle="modal"
                                          data-bs-target={
                                            "#modal" + section.sectionid
                                          }
                                          data-backdrop="static"
                                          data-keyboard="false"
                                        >
                                          <i
                                            className="fa fa-pencil fa-fw me-2"
                                            aria-hidden="true"
                                          ></i>
                                        </Link>
                                        <a
                                          hidden
                                          id={"modaledit" + section.sectionid}
                                          data-bs-toggle={"modal"}
                                          data-bs-target={
                                            "#modal" + section.sectionid
                                          }
                                        />
                                      </>
                                    ) : null}
                                    {row.removable ? (
                                      <>
                                        <Link
                                          to=""
                                          onClick={(e) => {
                                            openModal(e, "del", row.row);
                                          }}
                                          className="mt-2"
                                          data-bs-toggle="modal"
                                          data-bs-target={
                                            "#modal" + section.sectionid
                                          }
                                        >
                                          <i
                                            className="fa fa-trash-o fa-fw"
                                            aria-hidden="true"
                                            data-backdrop="static"
                                            data-keyboard="false"
                                          ></i>
                                        </Link>
                                        <a
                                          hidden
                                          id={"modaldel" + section.sectionid}
                                          data-bs-toggle={"modal"}
                                          data-bs-target={
                                            "#modal" + section.sectionid
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </td>
                                </>
                              ) : null}
                            </tr>
                          );
                        })
                      )
                    ) : (
                      <tr style={{ width: "100%" }}>
                        <td style={{ textAlign: "center" }}>
                          <label style={{ margin: "15px 0 15px 0" }}>
                            Sin registros
                          </label>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="data-list-section d-block d-md-none">
                  <ul className="data-list-section--item list-unstyled mb-0">
                    {section.rows !== undefined ? (
                      Children.toArray(
                        section.rows.map((row) => {
                          return (
                            <>
                              <>
                                {row.items !== undefined ? (
                                  <>
                                    {Children.toArray(
                                      row.items.map((item) => {
                                        mobileindex++;
                                        if (
                                          mobileindex === section.headers.length
                                        )
                                          mobileindex = 0;
                                        return section.headers[mobileindex] !==
                                          undefined ? (
                                          section.headers[mobileindex]
                                            .visible !== undefined ? (
                                            !section.headers[mobileindex]
                                              .visible ? null : section.headers[
                                                mobileindex
                                              ].value !== undefined ? (
                                              <li
                                                key={
                                                  String(Math.random()) + "li"
                                                }
                                              >
                                                <span
                                                  key={
                                                    section.headers[mobileindex]
                                                      .formid +
                                                    "mov" +
                                                    String(Math.random())
                                                  }
                                                  className="text-uppercase d-block mb-1"
                                                >
                                                  <strong>
                                                    {
                                                      section.headers[
                                                        mobileindex
                                                      ].value
                                                    }
                                                  </strong>
                                                </span>
                                                <span
                                                  id={item.formid}
                                                  key={
                                                    item.formid +
                                                    "mov" +
                                                    String(Math.random())
                                                  }
                                                >{
                                                  hiddenTd ? (
                                                    "*****"
                                                  ) : item.description !==
                                                    "" ? (
                                                    item.description
                                                  ) : item.value === "Y" ||
                                                    item.value === "N" ? (
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      value=""
                                                      id="flexCheckDefault"
                                                      checked={
                                                        item.value === "Y"
                                                          ? true
                                                          : false
                                                      }
                                                      disabled
                                                    />
                                                  ) : RegExp(
                                                      /^\d{4}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$/
                                                    ).test(item.value) ? (
                                                    formatDate(item.value)
                                                  ) : (
                                                    item.value
                                                  )
                                                }</span>
                                              </li>
                                            ) : null
                                          ) : null
                                        ) : null;
                                      })
                                    )}
                                    <li key={"btns" + String(Math.random())}>
                                      <span className="text-uppercase d-block mb-1">
                                        <strong>Acciones</strong>
                                      </span>
                                      {row.editable ? (
                                        <>
                                          <Link
                                            to=""
                                            onClick={(e) => {
                                              openModal(e, "edit", row.row);
                                            }}
                                            className="mt-2"
                                            data-bs-toggle="modal"
                                            data-bs-target={
                                              "#modal" + section.sectionid
                                            }
                                            data-backdrop="static"
                                            data-keyboard="false"
                                          >
                                            <i
                                              className="fa fa-pencil fa-fw me-2"
                                              aria-hidden="true"
                                            ></i>
                                          </Link>
                                          <a
                                            hidden
                                            id={"modaledit" + section.sectionid}
                                            data-bs-toggle={"modal"}
                                            data-bs-target={
                                              "#modal" + section.sectionid
                                            }
                                          />
                                        </>
                                      ) : null}
                                      {row.removable ? (
                                        <>
                                          <Link
                                            to=""
                                            onClick={(e) => {
                                              openModal(e, "del", row.row);
                                            }}
                                            className="mt-2"
                                            data-bs-toggle="modal"
                                            data-bs-target={
                                              "#modal" + section.sectionid
                                            }
                                            data-backdrop="static"
                                            data-keyboard="false"
                                          >
                                            <i
                                              className="fa fa-trash-o fa-fw"
                                              aria-hidden="true"
                                            ></i>
                                          </Link>
                                          <a
                                            hidden
                                            id={"modaldel" + section.sectionid}
                                            data-bs-toggle={"modal"}
                                            data-bs-target={
                                              "#modal" + section.sectionid
                                            }
                                          />
                                        </>
                                      ) : null}
                                    </li>
                                  </>
                                ) : null}
                              </>
                            </>
                          );
                        })
                      )
                    ) : (
                      <tr style={{ width: "100%" }}>
                        <td style={{ textAlign: "center" }}>
                          <label style={{ margin: "15px 0 15px 0" }}>
                            Sin registros
                          </label>
                        </td>
                      </tr>
                    )}
                  </ul>
                </div>
              </>
            ) : null}
            {section.style === "grid" ? (
              <>
                <div className="data-list-box">
                  <ul className="list-column-2 text-uppercase list-unstyled">
                    {section.rows !== undefined ? (
                      Children.toArray(
                        section.rows.map((row) => {
                          if (row) {
                            if (row.items) {
                              return Children.toArray(
                                row.items.map((item) => {
                                  return (
                                    <li key={item.formid}>
                                      <span
                                        className="data-list-box__title"
                                        
                                      >{item.label + ":"}</span>
                                      <span
                                        id={item.formid}
                                        visibility={
                                          item.visible
                                            ? item.visible.toString()
                                            : null
                                        }
                                        className="data-list-box__text"
                                      >{
                                        hiddenTd ? (
                                          "*****"
                                        ) : item.description !== "" ? (
                                          item.description
                                        ) : item.value === "Y" ||
                                          item.value === "N" ? (
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="flexCheckDefault"
                                            checked={
                                              item.value === "Y"
                                                ? true
                                                : false
                                            }
                                            disabled
                                          />
                                        ) : RegExp(
                                            /^\d{4}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$/
                                          ).test(item.value) ? (
                                          formatDate(item.value)
                                        ) : (
                                          item.value
                                        )
                                      }</span>
                                    </li>
                                  );
                                })
                              );
                            } else {
                              return <></>;
                            }
                          } else {
                            return <></>;
                          }
                        })
                      )
                    ) : (
                      <tr style={{ textAlign: "center", marginTop: "15px" }}>
                        Sin registros
                      </tr>
                    )}
                  </ul>
                </div>
              </>
            ) : null}
          </>
        }
      </div>
    </>
  );
};

Section.propTypes = {
  section: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  direccion: PropTypes.string.isRequired,
  hiddenTd: PropTypes.bool.isRequired,
  bancos: PropTypes.array.isRequired,
};

export default Section;