import Swal from "sweetalert2";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-danger",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
  allowOutsideClick: false,
});

const warningMessage = (callBack, message) => {
  swalWithBootstrapButtons
    .fire(
      "¡Atención!",
      `${message}`,
      "warning"
    )
    .then((result) => {
      if (result.isConfirmed) {
        if (typeof callBack === "function") {
          callBack();
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
};

export default warningMessage;
