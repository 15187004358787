import React, { useState, useEffect } from 'react'
import { FeedBackProps } from '../../utilities/interfaces'
import {
  Grid,
} from '@mui/material'
import LikeButtons from './statesComponents/likeButtons'
import CommentBox from './statesComponents/comment'
import EndFeedback from './statesComponents/endState'
import { Article, FeedbackPost } from '../../../../utilities/interfaces'
import { ApiRequest } from '../../../../utilities/apiServices/apiRequest'
import { HttpClient } from '../../../../utilities/apiServices/httpClient'
import { feedbackOptions } from '../../utilities/constants'
import feedbackStyle from '../../styles/feedback.module.css'

const httpClient = new HttpClient()
const apiService = new ApiRequest(httpClient)

const FeedBack = ({ article }: FeedBackProps) => {
  const [state, setState] = useState({
    likeButton: '',
    comment: 'initial',
    sendStatus: 0
  })
  const [showLikeButtons, setShowLikeButtons] = useState(false)
  const [showCommentBox, setShowCommentBox] = useState(false)
  const [showEndFeedback, setShowEndFeedback] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [isSendingPost, setSendingPost] = useState(false)
  const [isNegativeFeedback, setNegativeFeedback] = useState(false)

  useEffect(() => {
    isValid(article) && setShowLikeButtons(true)
    return (() => {
      restoreInitalState()
    })
  }, [article])

  const isValid = (article: Article): boolean => {
    if (article && article?.attributes?.content !== '') {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (state.comment === 'initial') return
    sendFeedback()
  }, [state.comment])

  const handleClickLikeButton = (option: string) => {
    if (option === feedbackOptions.negative) {
      setNegativeFeedback(true)
    }
    setState({ ...state, likeButton: option })
    setShowLikeButtons(false)
    setShowCommentBox(true)
  }

  const handleSaveComment = (comment: string) => {
    setState({ ...state, comment: comment })
  }

  const sendFeedback = async () => {
    if (state.likeButton === '') return
    setSendingPost(true)
    const postBody: FeedbackPost = {
      data: {
        article_id: article.id,
        assessment: state.likeButton,
        comments: state.comment
      }
    }
    apiService.postFeedback(postBody)
      .then((response) => {
        response.status && handleFeedbackResponse(response.status)
      }).catch((error) => {
        setShowWarning(true)
      })
      .finally(() => {
        setSendingPost(false)
      })
  }

  const handleFeedbackResponse = (status: number) => {
    if (status === 200) {
      setShowCommentBox(false)
      setShowEndFeedback(true)
    } else {
      setShowWarning(true)
    }
  }

  const restoreInitalState = () => {
    setShowLikeButtons(false)
    setShowCommentBox(false)
    setShowEndFeedback(false)
    setShowWarning(false)
  }

  const restoreButton = () => {
    if (showWarning) {
      setShowWarning(false)
      setState({ ...state, comment: 'initial', sendStatus: 0 })
    }
  }

  const retryPost = (newComment: string) => {
    setState({ ...state, sendStatus: 0 })
    setShowWarning(false)
    if (newComment !== state.comment) {
      setState({ ...state, comment: newComment })
    } else {
      sendFeedback()
    }
  }

  return (
    <Grid item
      className={showLikeButtons ?
        feedbackStyle.feedback_container + ' feedback_container':
        `${feedbackStyle.feedback_container} ${feedbackStyle.no_padding}` + ' feedback_container'}>
      {showLikeButtons &&
        <LikeButtons selectOption={handleClickLikeButton} />
      }
      {showCommentBox &&
        <CommentBox
          saveComment={(comment: string) => handleSaveComment(comment)}
          showWarning={showWarning}
          isNegative={isNegativeFeedback}
          isSendingPost={isSendingPost}
          onLinkClicked={(comment) => retryPost(comment)}
          onToastClosed={() => restoreButton()}
        />
      }
      {showEndFeedback &&
        <EndFeedback />
      }
    </Grid>
  )
}

export default FeedBack