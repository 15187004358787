import { SubscriptionContent } from "./SubscriptionContent";
import { SubscriptionProvider } from "./context";

export const SubscriptionSetting = () => {
  return (
    <SubscriptionProvider>
      <SubscriptionContent />
    </SubscriptionProvider>
  );
};
