import React, { useState, useEffect, FormEvent, FunctionComponent } from "react";
import UnorderedList from "../../commons/forms";
import { FormList, Props, HighschoolData, CollegeData, GetSheet } from "../../utilities/interfaces";
import { stepThreeCollege, stepThreeHighschool } from "../../utilities/formValues";
import { useForm, SubmitHandler } from "react-hook-form";
import OfflineModal from "../../commons/plain/modals/offline";
import fichaContratacionService from "../../../../services/ficha-contratacion-service";
import { scrollToTop } from "../../../../services/utiles";
interface Props1 extends Props {
    prevStep(): void;
    highschoolData: HighschoolData;
    setHighschoolData: React.Dispatch<React.SetStateAction<HighschoolData>>;
    collegeData: CollegeData;
    setCollegeData: React.Dispatch<React.SetStateAction<CollegeData>>;
    postValues: any;
    setPostValues: React.Dispatch<React.SetStateAction<any>>;
}

interface IsGraduated {
    isGraduated?: string;
}

const ThirdStep: FunctionComponent<Props1> = ({ options, nextStep, prevStep, highschoolData, setHighschoolData, collegeData, setCollegeData, postValues, setPostValues }) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
    } = useForm<HighschoolData | CollegeData | IsGraduated>({
        defaultValues: {
            ...highschoolData,
            ...collegeData,
            isGraduated: highschoolData.date_acquired !== "" && highschoolData.school !== "" ? 
                collegeData.acomplishment_txt !== "" && collegeData.dt_issued !== "" && collegeData.school_official !== "" ? "Y" : "N"
            :
                "",
        }
    });

    const isGraduated = watch("isGraduated");
    const [college, setCollege] = useState(stepThreeCollege);
    const [postStatus, setPostStatus] = useState<any>(false);
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        if (isGraduated === "" || isGraduated === "N") {
            setCollege(stepThreeCollege.slice(0, stepThreeCollege.length - 3));
            setValue("acomplishment_txt", "");
            setValue("dt_issued", "");
            setValue("school_official", "");
        } else {
            setCollege(stepThreeCollege);
        }
    }, [isGraduated]);

    const formSubmitHandler: SubmitHandler<HighschoolData | CollegeData | IsGraduated> = (data: HighschoolData | CollegeData | IsGraduated) => {
        if (navigator.onLine) {
            setDisableButton(true);
            let newData = { ...data };
            if ("isGraduated" in newData) {
                delete newData["isGraduated"];
            }
            let newHighschoolData;
            if ("school" in newData && "date_acquired" in newData) {
                newHighschoolData = { school: newData["school"], date_acquired: newData["date_acquired"] };
            }
            let newCollegeData;
            if ("school_official" in newData && "dt_issued" in newData && "acomplishment_txt" in newData) {
                newCollegeData = { school_official: newData["school_official"], dt_issued: newData["dt_issued"], acomplishment_txt: newData["acomplishment_txt"] };
            }
            setHighschoolData(newHighschoolData as HighschoolData);
            setCollegeData(newCollegeData as CollegeData);
            let stringSheet = sessionStorage.getItem("ficha");
            if (stringSheet !== null) {
                let savedSheet = JSON.parse(stringSheet) as GetSheet;
                sessionStorage.setItem("ficha", JSON.stringify({
                    ...savedSheet,
                    sheet: {
                        ...savedSheet.sheet,
                        thirdStep: {
                            highschoolData: newHighschoolData,
                            collegeData: newCollegeData,
                        }
                    }
                }));
            }
            document.getElementById("sendingInfo")?.click();
            fichaContratacionService.postMethods.setFicha(postValues, newData, setPostValues, false, setPostStatus, setDisableButton);
        } else {
            document.getElementById("offline")?.click();
        }
    };

    useEffect(() => {
        if (postStatus) {
            document.getElementById("closeSendingInfo")?.click();
        }
        const timer = setTimeout(() => {
            setDisableButton(false);
            if (postStatus.status === "0") {
                nextStep();
            }
        }, 519);
        return () => clearTimeout(timer);
    }, [postStatus]);

    return (
        <>
            <form
                className="needs-validation"
                onSubmit={handleSubmit(formSubmitHandler)}
            >
                <div className="box mb-4">
                    <h2 className="h4 tit-section" children={"Enseñanza Media"} />
                    <UnorderedList formList={stepThreeHighschool as Array<FormList>} options={options} register={register} errors={errors} />
                </div>
                <div id={"college"} className="box mb-4">
                    <h2 className="h4 tit-section" children={"Enseñanza Superior"} />
                    <UnorderedList formList={college as Array<FormList>} options={options} register={register} errors={errors} />
                </div>
                <div className="d-grid d-md-flex justify-content-md-between gap-2">
                    <button type="button" className="btn btn-primary" onClick={(e) => { e.preventDefault(); prevStep() }}>
                        <i className="fa fa-chevron-left fa-fw fa-xs" aria-hidden="true" />
                        {"Anterior"}
                    </button>
                    <button type="submit" className="btn btn-degradado" disabled={disableButton}>
                        {"Siguiente"}
                        <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                    </button>
                </div>
            </form>
            <OfflineModal />
        </>
    );
};

export default ThirdStep;
