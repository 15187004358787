import React, { Children } from "react";
import { HttpError, Options, Voucher } from "../../utilities/interfaces";
import { RemoteData } from "@devexperts/remote-data-ts";
import Element from "./element";
import { getRemoteData } from "../../utilities/helpers/setData";

interface Props {
    voucherOptions: Options;
    yearOptions: Options;
    monthOptions: Options;
    voucher: string;
    setVoucher: React.Dispatch<React.SetStateAction<string>>;
    year: string;
    setYear: React.Dispatch<React.SetStateAction<string>>;
    month: string;
    setMonth: React.Dispatch<React.SetStateAction<string>>;
    disabled: boolean;
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    setRemoteData: React.Dispatch<React.SetStateAction<RemoteData<HttpError, Voucher>>>;
}

const Search: React.FC<Props> = ({ voucherOptions, yearOptions, monthOptions, voucher, setVoucher, year, setYear, month, setMonth, disabled, setDisabled, setRemoteData }) => {
    return (
        <div className="box mb-4">
            <h2 className="h4 tit-section">{"Búsqueda"}</h2>
            <div className="data-list-box data-list-box-align-middle data-list-box-odd">
                <ul className="list-unstyled">
                    <Element options={voucherOptions} title={"Tipo de Vale"} value={voucher} setValue={setVoucher} />
                    <Element options={yearOptions} title={"Año"} value={year} setValue={setYear} />
                    <Element options={monthOptions} title={"Mes"} value={month} setValue={setMonth} />
                </ul>
                <small>{"(*) Campo obligatorio"}</small>
            </div>
            <div className="row justify-content-end mt-4">
                <div className="col-12 col-md-auto">
                    <button type="button" className="btn btn-degradado w-100" disabled={disabled} onClick={(e) => { !disabled ? getRemoteData({ voucher, year, month, setDisabled, setRemoteData }) : e.preventDefault() }}>{"Buscar "}<i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                </div>
            </div>
        </div>
    )
}

export default Search;