import React, { Children, FC, useEffect, useRef, useState } from "react";
import { Collaborator, Fault, NameCollaborator } from "../../utilities/interfaces";
import { pointerInList } from "./styles/searchbar.module.css";
import * as RD from "@devexperts/remote-data-ts";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import { getWarningLetter } from "../../utilities/apiServices/apiRequet";
import { httpClientEnv } from "../../../../../services/apiServices/httpClient";
import { urlsAws } from "../../../../../resources/foo/api-endpoints";
import miEquipoService from "../../../../../services/mi-equipo-service";
import { PhotoProps } from "../../pages/warning";

interface SearchBarProps {
    placeholder: string;
    data: NameCollaborator[];
    setCollaborator: React.Dispatch<React.SetStateAction<Collaborator | null>>;
    setQueriedEmplid: React.Dispatch<React.SetStateAction<string>>;
    setFaults: React.Dispatch<React.SetStateAction<Fault[]>>
    setFailed:React.Dispatch<React.SetStateAction<boolean>>
    setPhotos: React.Dispatch<React.SetStateAction<PhotoProps[]>>
}

const SearchBar: FC<SearchBarProps> = ({ placeholder, setPhotos, data, setCollaborator, setQueriedEmplid, setFaults, setFailed }) => {
    const [filteredData, setFilteredData] = useState<NameCollaborator[]>([]);
    const [wordEntered, setWordEntered] = useState<string>("");
    const [focusedIndex, setFocusedIndex] = useState<number>(-1);
    const resultContainer = useRef<HTMLLIElement>(null);
    const [disabledSearch,setDisabledSearch] = useState(false)
    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchWord = event.target.value;
        setWordEntered(searchWord);
        const newFilter = data.filter((value) => {
            return value.NOMBRE.toLowerCase().includes(searchWord.toLowerCase());
        });

        if (searchWord === "") {
            setFilteredData([]);
        } else {
            setFilteredData(newFilter);
        }
    };

    const clearInput = () => {
        setFilteredData([]);
        setWordEntered("");
        setFocusedIndex(-1);
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = async (e) => {
        const { key } = e;
        const maxLength = filteredData.length < 5 ? filteredData.length : 5;
        let nextIndexCount = -1;

        if (key === "ArrowDown") {
            nextIndexCount = (((focusedIndex + 1) % maxLength) + maxLength) % maxLength;
        }

        if (key === "ArrowUp") {
            nextIndexCount = (((focusedIndex - 1) % maxLength) + maxLength) % maxLength;
        }
        
        if (key === "Escape") {
        }

        if (key === "Enter" && !disabledSearch && wordEntered!=='') {
            setDisabledSearch(true)
            const filteredCollaborator = data.find((c) => c.NOMBRE.toLowerCase() === wordEntered.toLowerCase()) || null;
            const queriedEmplid = filteredCollaborator === null ? wordEntered : filteredCollaborator.EMPLID
            const resp = await RTE.run(await getWarningLetter(`?emplid=${queriedEmplid.toLocaleUpperCase()}`), httpClientEnv)
                .then(E.fold(e => { return RD.failure(e) }, a => { return RD.success(a) }));
               
                resp._tag==='RemotePending' && setDisabledSearch(true)

                if(resp._tag === "RemoteSuccess" && resp.value.response.valid){
                    dataCachePhotos(resp.value.response.emplid)
                    setCollaborator({
                        byEmplid: {
                            valid: resp.value.response.valid,
                            status: resp.value.response.status,
                            fullName: resp.value.response.fullName,
                            company: resp.value.response.company,
                            location: resp.value.response.location,
                            postition: resp.value.response.postition,
                            emplid: resp.value.response.emplid,
                        }
                    });
                    setFaults(resp.value.response.reasons);
                    setDisabledSearch(false)
                }else if(resp._tag === "RemoteSuccess" && !resp.value.response.valid){
                    setFailed(true)
                    setDisabledSearch(false)
                }
                setQueriedEmplid(queriedEmplid);
                
               
            resp._tag === "RemoteFailure" && (setCollaborator(null), setDisabledSearch(false),setFailed(true),
            setDisabledSearch(false));
            clearInput();
        }
        setFocusedIndex(nextIndexCount);
    };

    useEffect(() => {
        focusedIndex >= 0 && filteredData.length > 0 && setWordEntered(filteredData[focusedIndex]?.NOMBRE);
    }, [focusedIndex, filteredData]);

    useEffect(()=>{
        if(typeof data === 'string' && data === 'error'){
            setDisabledSearch(true)
        }
    },[data])

    const dataCachePhotos = async (request: string) => {
        const getFoto = async () => {
            await caches.match(urlsAws.getPhotosEquipo + request).then((response) => {
                if (response) {
                    response.json().then((data) => {
                        if(data){
                            setPhotos([data]);
                        }
                    });
                }else{
                    miEquipoService.getPhotos(setPhotos, [request]);
                }
            })
        };
        getFoto();
      };
      
    return (
        <>
            <div className={`input-group input-group-white mb-3`}>
                <input
                    type="text"
                    className="form-control"
                    placeholder={placeholder}
                    aria-label={placeholder}
                    value={wordEntered}
                    onChange={handleFilter}
                    onKeyDown={handleKeyDown}
                    disabled={disabledSearch}
                />
                <button
                    className="input-group-text border border-1 border-solid border-color-#ced4da m-0"
                    onClick={async () => {
                        if(!disabledSearch){
                            setDisabledSearch(true)
                            const filteredCollaborator = data.find((c) => c.NOMBRE.toLowerCase() === wordEntered.toLowerCase()) || null;
                            const queriedEmplid = filteredCollaborator === null ? wordEntered : filteredCollaborator.EMPLID
                            const resp = await RTE.run(await getWarningLetter(`?emplid=${queriedEmplid.toLocaleUpperCase()}`), httpClientEnv)
                                .then(E.fold(e => { return RD.failure(e) }, a => { return RD.success(a) }));
                            if(resp._tag === "RemoteSuccess" && resp.value.response.valid){
                                dataCachePhotos(resp.value.response.emplid)
                                setCollaborator({
                                    byEmplid: {
                                        valid: resp.value.response.valid,
                                        status: resp.value.response.status,
                                        fullName: resp.value.response.fullName,
                                        company: resp.value.response.company,
                                        location: resp.value.response.location,
                                        postition: resp.value.response.postition,
                                        emplid: resp.value.response.emplid,
                                    }
                                })
                                setFaults(resp.value.response.reasons);
                                setDisabledSearch(false);
                            }else if(resp._tag === "RemoteSuccess" && !resp.value.response.valid){
                                setFailed(true)
                                setDisabledSearch(false);
                            }
                            setQueriedEmplid(queriedEmplid);
                            resp._tag === "RemoteFailure" && (setCollaborator(null),setDisabledSearch(false),setFailed(true),
                            setDisabledSearch(false));
                            clearInput();
                        }
                    }}
                >
                {filteredData.length === 0 ? (
                    <i className="fa fa-search" />
                ) : (
                    <i className="fa fa-times" onClick={clearInput} />
                )}
                </button>
            </div>
            {filteredData.length !== 0 && (
                <>
                    <div className="notification-group z-2">
                        <ul className="list-group">
                            {Children.toArray(filteredData.slice(0, 5).map((value, index) => {
                                return (
                                    <li
                                        ref={index === focusedIndex ? resultContainer : null}
                                        className="list-group-item list-group-item-action col-6"
                                        style={{ backgroundColor: index === focusedIndex ? "rgba(0,0,0,0.1)" : "" }}
                                        onClick={async () => {
                                            const filteredCollaborator = data.find((c) => c.NOMBRE.toLowerCase() === value.NOMBRE.toLowerCase()) || null;
                                            const queriedEmplid = filteredCollaborator === null ? wordEntered : filteredCollaborator.EMPLID;
                                            const resp = await RTE.run(await getWarningLetter(`?emplid=${queriedEmplid.toLocaleUpperCase()}`), httpClientEnv)
                                                .then(E.fold(e => { return RD.failure(e); }, a => { return RD.success(a); }));
                                            if(resp._tag === "RemoteSuccess" && resp.value.response.valid){
                                                dataCachePhotos(resp.value.response.emplid)
                                                setCollaborator({
                                                    byEmplid: {
                                                        valid: resp.value.response.valid,
                                                        status: resp.value.response.status,
                                                        fullName: resp.value.response.fullName,
                                                        company: resp.value.response.company,
                                                        location: resp.value.response.location,
                                                        postition: resp.value.response.postition,
                                                        emplid: resp.value.response.emplid,
                                                    }
                                                });
                                                setFaults(resp.value.response.reasons);
                                            }else{
                                                setFailed(true);
                                            }
                                            setQueriedEmplid(queriedEmplid);
                                            resp._tag === "RemoteFailure" && setCollaborator(null)
                                            clearInput();
                                        } }
                                    >
                                        <a className={pointerInList} target="_blank">
                                            {`${value.NOMBRE} `}<i className="fa fa-search float-end" />
                                        </a>
                                    </li>
                                );
                            }))}
                        </ul>
                     </div>
                </>
            )}
        </>
    );
};

export default SearchBar;