import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { countryDictionary } from "../../utils/countries";
import List from './_components/List/List';
import Form from './_components/Form/Form';
import { handleStatusError, handleStatusSuccess, handleDeleteConfirmation } from './_components/Alerts/alerts'
import useFetchInterestSitesMaintenance from './hooks/useInterestSitesMaintenanceFetch';
import { InterestSitesMaintenance } from './context/interestSiteMaintenanceContext';

export const InterestSitesMaintenanceContent: React.FC = () => {
    const [selectedCountry, setSelectedCountry] = useState<string>('');
    const [siteId, setSiteId] = useState<number | null>(null);
    const [siteIdToDelete, setSiteIdToDelete] = useState<number>();
    const [updatedData, setUpdatedData] = useState<InterestSitesMaintenance | undefined>(undefined);
    const [newdData, setNewdData] = useState<InterestSitesMaintenance | undefined>(undefined);
    const { data, isLoading, isError } = useFetchInterestSitesMaintenance({
        country: selectedCountry,
        siteId: siteId,
        updatedData: updatedData,
        newData: newdData,
        siteIdToDelete: siteIdToDelete,
    });
    const [form, setForm] = useState<boolean>(false);
    const [editId, setEditId] = useState<number | null>(null);
    const country = ['Argentina', 'Colombia', 'Chile', 'Uruguay', 'Perú', 'Brasil'];

    const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const countryCode = event.target.value;
        setSelectedCountry(countryCode);
    };

    const toggleForm = (id: number | null = null) => {
        setForm(!form);
        setEditId(id);
    };

    const handleSave = (id: number | null | undefined, data: InterestSitesMaintenance) => {
        if (id) {
            setSiteId(id);
            setEditId(id);
            setUpdatedData(data);
        } else {
            setNewdData(data)
        }
        popUp()
    };

    const popUp = () => {
        if (isError) {
            handleStatusError();
        } else {
            handleStatusSuccess()
        }
    }

    const handleDelete = (id: number | undefined) => {
        if (id) {
            handleDeleteConfirmation(() => {
                setSiteIdToDelete(id)
            });
        }
    };

    return (
        <>
            <nav className="breadcrumbs m-3" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: 'none' }} className="fa fa-home" aria-hidden="true"></i>{' '}
                            Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Gestión de Sitios de Interés
                    </li>
                </ol>
            </nav>
            <section>
                <h1 className='m-3'>Gestión de Sitios de Interés</h1>
                <div className="box">
                    <div className='tit-group d-flex justify-content-between'>
                        <h2 className="h4 tit-section">Sitios</h2>
                        <div>
                            <div className="input-group">
                                <select className='form-select' name="country" onChange={handleCountryChange}>
                                    <option value="">Selecciona un país</option>
                                    {country.map(country => (
                                        <option key={country} value={countryDictionary(country)}>{country}</option>
                                    ))}
                                </select>
                                <div className='d-flex align-items-center ms-2'>
                                    <i className='fa fa-plus-square-o color-orange'
                                        aria-hidden="true"
                                        style={{ cursor: 'pointer', fontSize: '30px' }}
                                        onClick={() => toggleForm(null)}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        {isLoading ? (
                            <div className="text-center"><div className="spinner-border text-primary m-4"></div></div>
                        ) : isError ? (
                            <p>Error al cargar los datos.</p>
                        ) : (
                            form ? (
                                data ? <Form editId={editId} toggleForm={toggleForm} data={data} onSave={handleSave} countriesArray={country} /> : null
                            ) : (
                                data && <List data={data} toggleForm={toggleForm} onDelete={handleDelete} />
                            )
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};

export default InterestSitesMaintenanceContent;
