import React, { Children, FC, useEffect, useRef, useState } from "react";
import { NameCollaborator } from "../../../warningLetter/utilities/interfaces";
import { pointerInList } from "./styles/searchbar.module.css";
import { validaRut } from "../../../../../services/utiles";

interface SearchBarProps {
    placeholder: string;
    data: NameCollaborator[];
    setCollaboratorEmplid: React.Dispatch<React.SetStateAction<string>>;
}

const SearchBar: FC<SearchBarProps> = ({ placeholder, data, setCollaboratorEmplid}) => {
    const [filteredData, setFilteredData] = useState<NameCollaborator[]>([]);
    const [wordEntered, setWordEntered] = useState<string>("");
    const [focusedIndex, setFocusedIndex] = useState<number>(-1);
    const resultContainer = useRef<HTMLLIElement>(null);
    const [disabledSearch,setDisabledSearch] = useState(false)
    const [validRut,setValidRut]= useState(false)
    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchWord = event.target.value;
        setWordEntered(searchWord);
        const newFilter = data.filter((value) => {
            return value.NOMBRE.toLowerCase().includes(searchWord.toLowerCase());
        });

        if (searchWord === "") {
            setFilteredData([]);
        } else {
            setFilteredData(newFilter);
        }
    };

    const clearInput = () => {
        setFilteredData([]);
        setFocusedIndex(-1);
        setDisabledSearch(false)
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = async (e) => {
        const { key } = e;
        const maxLength = filteredData.length < 5 ? filteredData.length : 5;
        let nextIndexCount = -1;

        if (key === "ArrowDown") {
            nextIndexCount = (((focusedIndex + 1) % maxLength) + maxLength) % maxLength;
        }

        if (key === "ArrowUp") {
            nextIndexCount = (((focusedIndex - 1) % maxLength) + maxLength) % maxLength;
        }

        if (key === "Enter" && !disabledSearch && wordEntered!=='') {
            setDisabledSearch(true)
            const filteredCollaborator = data.find((c) => c.NOMBRE.toLowerCase() === wordEntered.toLowerCase()) || null;
            const queriedEmplid = filteredCollaborator === null ? wordEntered : filteredCollaborator.EMPLID
            if(filteredCollaborator){
                setWordEntered(filteredCollaborator.NOMBRE)
            }else{
                setWordEntered(queriedEmplid)
            }
            setCollaboratorEmplid(queriedEmplid)
            clearInput();
        }
        setFocusedIndex(nextIndexCount);
    };

    useEffect(() => {
        focusedIndex >= 0 && filteredData.length > 0 && setWordEntered(filteredData[focusedIndex]?.NOMBRE);
    }, [focusedIndex, filteredData]);

    useEffect(()=>{
        if (wordEntered) {
            let isValid = validaRut(wordEntered)
            setValidRut(isValid)
        }
    },[wordEntered])

    useEffect(()=>{
        if (typeof data === 'string' && data === 'error') {
            setDisabledSearch(true)
        }
    },[data])

    return (
        <>
            <div className={`input-group input-group-white mb-3`}>
                <input
                    type="text"
                    className="form-control"
                    placeholder={placeholder}
                    aria-label={placeholder}
                    value={wordEntered}
                    onChange={handleFilter}
                    onKeyDown={handleKeyDown}
                    disabled={disabledSearch}
                />
                <button
                    className="input-group-text border border-1 border-solid border-color-#ced4da m-0"
                    onClick={async () => {
                        if(!disabledSearch && wordEntered!=='' && validRut){
                            setDisabledSearch(true)
                            const filteredCollaborator = data.find((c) => c.NOMBRE.toLowerCase() === wordEntered.toLowerCase()) || null;
                            const queriedEmplid = filteredCollaborator === null ? wordEntered : filteredCollaborator.EMPLID
                            if (filteredCollaborator) {
                                setWordEntered(filteredCollaborator.NOMBRE)
                            } else {
                                setWordEntered(queriedEmplid)
                            }
                            setCollaboratorEmplid(queriedEmplid)
                            clearInput();
                        }
                    }}
                >
                {filteredData.length === 0 ? (
                    <i className="fa fa-search" />
                ) : (
                    <i className="fa fa-times" onClick={clearInput} />
                )}
                </button>
            </div>
            {filteredData.length !== 0 && (
                <>
                    <div className="notification-group z-2">
                        <ul className="list-group">
                            {Children.toArray(filteredData.slice(0, 5).map((value, index) => {
                                return (
                                            <li
                                                ref={index === focusedIndex ? resultContainer : null}
                                                className="list-group-item list-group-item-action col-6"
                                                style={{ backgroundColor: index === focusedIndex ? "rgba(0,0,0,0.1)" : "" }}
                                                onClick={async () => {
                                                    setCollaboratorEmplid(value.EMPLID)
                                                    setWordEntered(value.NOMBRE)
                                                    clearInput();
                                                } }
                                            >
                                                <a className={pointerInList} target="_blank">
                                                    {`${value.NOMBRE} `}<i className="fa fa-search float-end" />
                                                </a>
                                            </li>
                                        )
                            }))}
                        </ul>
                    </div>
                </>
            )}
        </>
    )
};

export default SearchBar;