import { readerTaskEither as RTE } from "fp-ts";
import * as t from "io-ts";
import { urlsAws } from "../../../../resources/foo/api-endpoints";
import { HttpRequestError, HttpContentTypeError, HttpResponseStatusError, HttpClientEnv, verificationCodec, Verification } from "../../../verifyDocument/utilities/interfaces";
import { getJson } from "./httpClient";

export const verifyDocument = async (params: any) => {
    const requestOptions: RequestInit = {
        method: "GET",
        headers: { "Content-Type": "application/json; charset=utf-8"},
    };
    const response: RTE.ReaderTaskEither<
            HttpClientEnv,
            HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
            Verification
        > = getJson(urlsAws.verifyDocument, params, requestOptions, verificationCodec.decode)

    return response;
};
