import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { BsExclamationCircle } from 'react-icons/bs';
import { FaEye, FaTrash } from "react-icons/fa";
import SolicitudLicenciasService from '../../services/solicitud licencias/solicitud-licencias-service';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import moment from 'moment';

const CardLicencias = () => {
    const fileRef = useRef(null);
    const navigate = useNavigate();
    const [showFile, setShowFile] = useState(false);
    const [license, setLicense] = useState({
        id: '',
        licenceCode: '',
        userId: '',
        approver: '',
        beginDate: null,
        returnDate: null,
        status: '',
        comments: '',
        certificateFile: '',
        certificateFileName: '',
        certificateFileType: '',
        certificateFileSize: null
    });
    const [certificateSent, setCertificateSent] = useState(null);
    const [agreedTerms, setAgreedTerms] = useState(false);
    const [data, setData] = useState({});
    const [noData, setNoData] = useState(false);
    const [file, setFile] = useState({
        File: null,
        FileName: '',
        FileType: ''
    });
    const [manager, setManager] = useState({});
    const [licenseOption, setLicenseOption] = useState([]);
    const [loadedFile, setLoadedFile] = useState(false);
    const [modalView, setModalView] = useState(null);
    const [loadingSave, setLoadingSave] = useState(false)
    const [availableDays, setAvailableDays] = useState({})
    const [terms, setTerms] = useState({});
    const [fetched, setFetched] = useState(false);
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(true);
    const [reset, setReset] = useState(false);
    const [error, setError] = useState(false);
    const [licenseSent, setLicenseSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [certificateError, setCertificateError] = useState(false);
    const [responseId, setResponseId] = useState(null);

    useEffect(() => {
        if (reset) {
            setLicense({ ...license, beginDate: null, returnDate: null, comments: '' })
            setReset(false);
        }
    }, [reset])

    useEffect(() => {
        getLicenceData();
        termsBP();
        if (fetched) {
            console.log("fetched");
        }
    }, [fetched])

    // useEffect(() => {
    //     if (code === "009") {
    //         setAvailableDays(licenseOption.find(x => x.ABSENCE_TYPE === "009"))
    //     }
    //     if (code === "034") {
    //         setAvailableDays(licenseOption.find(x => x.ABSENCE_TYPE === "034"))
    //     }
    //     if (code === "012") {
    //         setAvailableDays(licenseOption.find(x => x.ABSENCE_TYPE === "012"))
    //     }
    //     if (code === "011") {
    //         setAvailableDays(licenseOption.find(x => x.ABSENCE_TYPE === "011"))
    //     }
    //     if (code === "010") {
    //         setAvailableDays(licenseOption.find(x => x.ABSENCE_TYPE === "010"))
    //     }
    // }, [code])

    useEffect(() => {
        if (code) {
            setAvailableDays(licenseOption.find(x => x.ABSENCE_TYPE === code));
        }
    }, [code, licenseOption]);

    useEffect(() => {
        const handleUpload = async () => {
            if (file.File && licenseSent) {
                await uploadCertificate(responseId);
            }
        };

        handleUpload();
    }, [file, licenseSent, responseId])

    const getLicenceData = async () => {
        await SolicitudLicenciasService.getLicensesInfo(setFetched, setLicenseOption, setData, setLoading, setNoData, setManager);
    }
    /* 
        const handleFile = () => {
            fileRef.current.click();
        } */
    const validateForm = () => {
        if (!license.beginDate || !license.returnDate) {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                html: '<div style="text-align: center; font-size: 15px"> Complete las fechas de la solicitud para continuar </div>',
                confirmButtonColor: '#0169b5',
            })

            return false;
        }
        else if (!agreedTerms) {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                html: '<div style="text-align: center; font-size: 15px"> Debe aceptar las condiciones para poder enviar la solicitud </div>',
                confirmButtonColor: '#0169b5',
            })

            return false;
        } else {

            return true;
        }

    }
    const validateDate = (beginDate, returnDate) => {
        const bd = new Date(beginDate);
        const rd = new Date(returnDate);
        const minYear = new Date().getFullYear() - 2;
        if (bd > rd) {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                html: '<div style="text-align: center; font-size: 15px"> la fecha de inicio no puede ser mayor a la fecha final </div>',
                confirmButtonColor: '#0169b5',
            })
            return false;
        }
        if (bd.getFullYear() < minYear || rd.getFullYear() < minYear) {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                html: '<div style="text-align: center; font-size: 15px">El año ingresado no es un año valido para la solicitud</div>',
                confirmButtonColor: '#0169b5',
            })
            return false;
        }
        var diffInTime = rd.getTime() + (rd.getTimezoneOffset() * 60000) - bd.getTime() + (bd.getTimezoneOffset() * 60000);
        var diffInDays = Math.round(diffInTime / (1000 * 60 * 60 * 24));
        if (diffInDays + 1 > Number(availableDays.availableDays)) {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                html: '<div style="text-align: center; font-size: 15px">La cantidad de dias solicitados se encuentra por fuera del rango de dias disponibles</div>',
                confirmButtonColor: '#0169b5',
            })
            return false;
        }

        return true;
    }
    const handleFileChange = e => {
        if (e.target.files[0].type == "image/jpeg" || e.target.files[0].type == "image/jpg" || e.target.files[0].type == "image/png" || e.target.files[0].type == "application/pdf") {
            if (e.target.files[0].size <= 5000000) {
                setFile({ File: e.target.files[0], FileName: e.target.files[0].name, FileType: e.target.files[0].type });
                setLoadedFile(true);
                const f = e.target.files[0];
                let blob = new Blob([f], { type: e.target.files[0].type });
                const url = window.URL.createObjectURL(blob)
                setModalView(url)
            } else {
                Swal.fire({
                    allowOutsideClick: false,
                    position: 'center',
                    title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                    html: '<div style="text-align: center; font-size: 15px">El tamaño del Certificado debe ser menor a 5Mb</div>',
                    confirmButtonColor: '#0169b5',
                })
            }
        } else {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                html: '<div style="text-align: center; font-size: 15px">Debe ingresar uno de los tipos de archivos permitidos</div>',
                confirmButtonColor: '#0169b5',
            })
        }
    }

    const uploadCertificate = (id) => {
        const fd = new FormData();
        fd.append("idSolicitud", id)
        fd.append("fileName", file.FileName);
        fd.append("file", file.File);
        // for (var key of fd.entries()) {
        //     console.log(key[0] + ', ' + key[1])
        // }
        SolicitudLicenciasService.uploadCertificate(setLoadingSave, fd, setCertificateSent, setError, setErrorMessage, setCertificateError);
    }
    const fileModal = () => {
        if (file.FileType == 'application/pdf') {
            Swal.fire({
                title: file.FileName,
                allowOutsideClick: false,
                width: 500,
                html: `
                    <object data="${modalView}" type="application/pdf" style="text-align: left; width: 100%; height: 600px;">
                        <p style="margin-bottom: 0; text-align: center; padding: 10px">
                            El navegador no puede previsualizar el pdf. <br/>
                            <a href="${modalView}" download="${file.FileName}">
                                Descargar archivo
                            </a>
                        </p>
                    </object>
                    `,
                confirmButtonColor: '#0169b5',
            }).then((result) => {
                if (result.isConfirmed) {
                    setShowFile(false);
                }
            })
        } else {
            Swal.fire({
                width: '40%',
                allowOutsideClick: false,
                heightAuto: false,
                imageWidth: '90%',
                imageUrl: `${modalView}`,
                confirmButtonColor: '#0169b5'
            }).then((result) => {
                if (result.isConfirmed) {
                    setShowFile(false);
                }
            })
        }
    }

    const termsBP = async () => {
        await SolicitudLicenciasService.getTermsBP(setTerms);
    }
    const modalTerms = () => {
        return Swal.fire({
            title: 'BP',
            html: `<div style="text-align: left; height: 300px;"> ${terms?.bpsConditions} </div>`,
            confirmButtonColor: '#0169b5',
        })
    }
    const handleSelect = (e) => {
        setCode(e.target.value);
    }
    const handleSuccess = () => {
        if (certificateSent || (licenseSent && !file.File)) {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: green; font-size: 16px">Exitoso</div>',
                html: '<div style="text-align: center; font-size: 15px">Su solicitud fue enviada correctamente</div>',
                confirmButtonColor: '#0169b5',
            }).then((result) => {
                if (result.isConfirmed) {
                    setLicenseSent(false);
                    navigate("historico");
                }
            })
        }
    }
    const handleError = () => {
        if (errorMessage) {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: red; font-size: 16px">Error</div>',
                html: `<div style="text-align: center; font-size: 15px">${errorMessage}</div>`,
                confirmButtonColor: '#0169b5',
            }).then((result) => {
                if (result.isConfirmed) {
                    setErrorMessage('');
                }
            })
        } else {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: red; font-size: 16px">Error</div>',
                html: `<div style="text-align: center; font-size: 15px">Ha ocurrido un error en la creación de la licencia, intentelo nuevamente</div>`,
                confirmButtonColor: '#0169b5',
            }).then((result) => {
                if (result.isConfirmed) {
                    setErrorMessage('');
                }
            })
        }
        setError(false);
    }

    const handleCertificateError = () => {
        if (licenseSent && !certificateSent) {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color:orange; font-size: 16px">Advertencia</div>',
                html: '<div style="text-align: center; font-size: 15px">Su licencia ha sido enviada sin archivo debido a un error en la subida, intentelo nuevamente desde el histórico</div>',
                confirmButtonColor: '#0169b5',
            }).then((result) => {
                setLoadingSave(false);
                if (result.isConfirmed) {
                    navigate("historico");
                }
            })
        }
        setCertificateError(false);
    }

    const sendLicence = () => {
        let validF = validateForm();
        if (validF) {
            var validD = validateDate(license.beginDate, license.returnDate);
            if (validD) {
                let info = {
                    approver: manager.MANAGER_ID,
                    approverName: manager.MANAGER_NAME,
                    licenseCode: availableDays.ABSENCE_TYPE,
                    licenseDescription: availableDays.ABSENCE_TYPE_DESCR,
                    beginDate: license.beginDate,
                    returnDate: license.returnDate,
                    commentsDate: moment(new Date()).format('YYYY-MM-DD'),
                    comments: license.comments,
                    fileType: "",
                    fileName: ""
                };
                // console.log(info)
                SolicitudLicenciasService.createLicence(setLoadingSave, info, setError, setErrorMessage, setLicenseSent, setResponseId);
            }
        }
    }

    return (
        <>
            <section className="licencias p-0">

                {!loading ? !noData ?
                    <div className="card-box align-items-stretch w-100 mb-4">
                        <div className="license-btn-container">
                            <div className='button-history-container'>
                                <button className="button history"><Link to='historico'>Ver Historico <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i> </Link> </button>
                            </div>
                            <div className="license-btn">
                                <button type="reset" className="button cancel" onClick={() => { setAgreedTerms(false); setCode("009"); setReset(!reset) }}>Cancelar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                <button type="submit" className="button save" onClick={sendLicence}>Guardar {loadingSave ? <div className="spinner-border spinner-border-sm"></div> : <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>}</button>
                            </div>
                        </div>
                        <div className="tit-group d-flex justify-content-between">
                            <h3>Solicitud de Licencia <div className='title-underlined'></div></h3>
                            {code ? <div><span className="ic-circle ic-circle-primary">{availableDays?.BALANCE}</span> {availableDays?.BALANCE == 1 ? 'Día' : 'Días'} {availableDays?.BALANCE == 1 ? 'Disponible' : 'Disponibles'}</div> : null}
                        </div>

                        <div className="title-card-container">
                            <h3 className="h5 fw-bold">Aprobador de Licencias: {manager?.MANAGER_NAME}</h3>
                            {/*onClick={() => modalTerms()}*/}
                            <p>Si tu aprobador no es el correcto contactá a tu BP. <a className="terms-anchor" >Consultá acá</a> en caso de duda sobre tu BP.</p>
                        </div>

                        <form className="mt-4">

                            <div className="row">
                                <div className="col-12 col-md-4 mb-3">
                                    <label className="form-label fw-bold">Tipo de Licencia
                                        <Tippy content="Ingrese el tipo correspondiente a su solicitud de Licencia">
                                            <i className="fa fa-question fa-fw fa-lg color-orange" aria-hidden="true"></i>
                                        </Tippy>
                                    </label>
                                    <select className="form-select" value={code} onChange={handleSelect} required type='select' defaultValue={""}>
                                        <option disabled value="">Escoger...</option>
                                        {licenseOption.map((licenseOption, i) => {
                                            return <option key={i} value={licenseOption?.ABSENCE_TYPE}>{licenseOption?.ABSENCE_TYPE_DESCR}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="col-12 col-md-4 mb-3">
                                    <label className="form-label fw-bold">Fecha Inicio
                                        <Tippy content="La fecha de inicio debe ser un día hábil.">
                                            <i className="fa fa-question fa-fw fa-lg color-orange" aria-hidden="true"></i>
                                        </Tippy>
                                    </label>
                                    <div className="input-group">
                                        <input className="form-control license-picker" value={license?.beginDate ? license.beginDate : "mm/dd/yyy"} onChange={e => { setLicense({ ...license, beginDate: e.target.value }) }} required name='beginDate' type="date"></input>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 mb-3">
                                    <label className="form-label fw-bold">Fecha Final
                                        <Tippy content="Para establecer la fecha fin considerá que los días deben ser corridos e incluir los fines de semana.">
                                            <i className="fa fa-question fa-fw fa-lg color-orange" aria-hidden="true"></i>
                                        </Tippy>
                                    </label>
                                    <div className="input-group">
                                        <input className="form-control license-picker" onChange={e => setLicense({ ...license, returnDate: e.target.value })} value={license?.returnDate ? license?.returnDate : "mm/dd/yyyy"} required name='returnDate' type='date'></input>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <label className="form-label fw-bold">Observaciones</label>
                                    <div className="input-group">
                                        <textarea type="text" className="form-control" rows="2" name='comments' value={license?.comments} onChange={e => setLicense({ ...license, comments: e.target.value })}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 mb-3">
                                    <label className="form-label fw-bold">Cargar Certificado
                                        <Tippy content="Puede cargar un certificado en los formatos .jpg, .jpeg, .png, .pdf">
                                            <i className="fa fa-question fa-fw fa-lg color-orange" aria-hidden="true"></i>
                                        </Tippy>
                                    </label>
                                    {loadedFile ? <div className="label-wrapper"><label className='file-name-label'>/{file?.FileName}</label> <FaEye className="certify-icons" onClick={() => setShowFile(true)} ></FaEye> <FaTrash onClick={() => { setLoadedFile(false); setModalView(null); setFile({ File: null, FileName: '', FileType: '' }); }} className="certify-icons"></FaTrash></div>
                                        : <input type="file" className="form-control" aria-label="Adjuntar certificado" ref={fileRef} onChange={handleFileChange} name="fileLoader" accept=".jpg, .jpeg, .png, .pdf"></input>}
                                    <div>
                                        <label className="file-name" htmlFor="file"><i></i></label>
                                    </div>
                                </div>
                            </div>
                            <div className="my-3">
                                <h4 className="h5 fw-bold">Términos y Condiciones</h4>
                                <p>Las licencias cuentan con sus respectivos topes:</p>
                                <ul className="list-orange">
                                    <li><strong>Donación de sangre:</strong> 1 día</li>
                                    <li><strong>Examen Terciario/Universitario:</strong> 20 días anuales, con un máximo de 4 días por examen.</li>
                                    <li><strong>Mudanza:</strong> 2 días</li>
                                </ul>
                                <p>Estoy en conocimiento del saldo de días disponibles a la solicitud de la licencia</p>
                            </div>
                            <div className='accept'>
                                <input type="checkbox" className='check form-check-input' name="approvedFlag" value={agreedTerms} checked={agreedTerms} onChange={() => setAgreedTerms(!agreedTerms)} required></input>
                                <label className='form-check-label-licenses' htmlFor="invalidCheck">
                                    Acepto términos y condiciones
                                </label>
                            </div>
                        </form>

                        {!showFile ? null : fileModal()}
                        {!licenseSent ? null : handleSuccess()}
                        {!error ? null : handleError()}
                        {certificateError ? handleCertificateError() : null}

                    </div>

                    : <div className="alert alert-info alert-dismissible fade show text-center" role="alert">
                        <BsExclamationCircle />  No se encontraron datos asociados
                    </div> : <div className="full-spinner"><div className="spinner-border text-primary"></div></div>
                }

            </section>

        </>

    )
}

export default CardLicencias