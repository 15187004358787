import React from "react";
import { MessageDinamic } from "../commonComponents/MessageDinamic";
import { postRegistroViaje } from "../../services/RegistroViaje-UY/registroViaje-services";

export const ButtonsRegistroViaje = ({
  setRegistroViaje,
  RegistroViaje,
  statusRequest,
  setstatusRequest,
}) => {


  const handeleSubmit = (e) => {
    if (RegistroViaje.beginDt === null) {
      return MessageDinamic(
        "error","error",
        "Ingrese una fecha de inicio"
      );
    }
    if (RegistroViaje.endDt === null) {
      return MessageDinamic(
        "error","error",
        "Ingrese una fecha de regreso"
      );
    }
    if (RegistroViaje.country === "") {
      return MessageDinamic("error","error", "Ingrese un pais");
    }
    if (RegistroViaje.beginDt > RegistroViaje.endDt) {
      return MessageDinamic(
        "error","error",
        "La fecha de inicio no puede ser mayor a la fecha de regreso"
      );
    }
    if (RegistroViaje?.comments && RegistroViaje?.comments.length > 200) {
      return MessageDinamic(
        "error","error",
        "El comentario no puede superar los 150 caracteres"
      );
    }
    setstatusRequest({ ...statusRequest, loading: true });
    postRegistroViaje(RegistroViaje, statusRequest, setstatusRequest);
  };

  return (
    <div className="license-btn-container">
      <div className="license-btn">
        <button
          type="reset"
          className="button cancel"
          onClick={() =>
            setRegistroViaje({
              beginDate: null,
              returnDate: null,
              country: "",
              comments: "",
            })
          }
        >
          Cancelar
          <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
        </button>
        <button
          type="submit"
          className="button save"
          onClick={() => handeleSubmit()}
        >
          Guardar
          {statusRequest.loading ? (
            <div className="spinner-border spinner-border-sm"></div>
          ) : (
            <i
              className="fa fa-chevron-right fa-fw fa-xs"
              aria-hidden="true"
            ></i>
          )}
        </button>
      </div>
    </div>
  );
};
