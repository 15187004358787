import certificadosService from "../services/certificados-service";
import React, { useState, useEffect, useContext } from "react";
import { Base64 } from "js-base64";
import {Context} from "../context";

const Certificados = (props) => {
    const [seguroCovid, setSeguroCovid] = useState(false);
    const [cetificadoAntiguedad, setCertificado] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const { emplid } = useContext(Context);

    const handleSubmit = (e) => {
        e.preventDefault();
        setButtonDisabled(true);
        document.getElementById("openModalDescargar").click();
        if (selectedOption === "certificadoAntiguedad") {
            certificadosService.certificadosAntiguedad(emplid, "", "SIN", setCertificado, setSuccess, setButtonDisabled);
        } else if (selectedOption === "certificadoAntiguedadConRenta") {
            certificadosService.certificadosAntiguedad(emplid, "", "CON", setCertificado, setSuccess, setButtonDisabled);
        } else if (selectedOption === "certificadoAntiguedadConRentaImponible") {
            certificadosService.certificadosAntiguedad(emplid, "", "IMP", setCertificado, setSuccess, setButtonDisabled);
        } else if (selectedOption === "seguroCovid") {
            const emplidCovid = Number(emplid.split("-")[0])
            certificadosService.getCertificadoCovid(emplidCovid, setSeguroCovid);
        }
    };

    useEffect(() => {
        if (cetificadoAntiguedad == 2 || seguroCovid == 2) {
            setTimeout(function () {
                document.getElementById("closeModalDescarga").click()
                document.getElementById("openModalNoDocumento").click();
            }, 1000);
            setCertificado(false);
            setSeguroCovid(false);
        } else {
            if (cetificadoAntiguedad && selectedOption == "certificadoAntiguedad") {
                let byte = base64ToArrayBuffer(cetificadoAntiguedad);
                saveByteArray("Certificado_Antiguedad", byte);
                setCertificado(false);
            } else if (cetificadoAntiguedad && selectedOption == "certificadoAntiguedadConRenta") {
                let byte = base64ToArrayBuffer(cetificadoAntiguedad);
                saveByteArray("Certificado_Antiguedad_Con_Renta", byte);
                setCertificado(false);
            } else if (cetificadoAntiguedad && selectedOption == "certificadoAntiguedadConRentaImponible") {
                let byte = base64ToArrayBuffer(cetificadoAntiguedad);
                saveByteArray("Certificado_Antiguedad_Con_Renta_Imponible", byte);
                setCertificado(false);
            } else if (seguroCovid && selectedOption == "seguroCovid") {
                desencriptarArchivo(seguroCovid.poliza, "Seguro_Covid");
                setSeguroCovid(false);
            }
        }
    }, [cetificadoAntiguedad, seguroCovid]);

    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);

    const handleSubmitEmail = (e) => {
        e.preventDefault();
        setButtonDisabled(true);
        const validarEmail = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;
        if (!email.match(validarEmail)) {
            setSuccess(false);
        } else {
            if (selectedOption === "certificadoAntiguedad") {
                certificadosService.certificadosAntiguedad(emplid, email, "SIN", setCertificado,setSuccess, setButtonDisabled);
            } else if (selectedOption === "certificadoAntiguedadConRenta") {
                certificadosService.certificadosAntiguedad(emplid, email, "CON", setCertificado, setSuccess, setButtonDisabled);
            } else if (selectedOption === "certificadoAntiguedadConRentaImponible") {
                certificadosService.certificadosAntiguedad(emplid, email, "IMP", setCertificado, setSuccess, setButtonDisabled);
            }
        }
    };

    useEffect(() => {
        if (success == true) {
            document.getElementById("cerrarModalLiquidacionEmail").click();
            document.getElementById("openModalEmaliSend").click();
            setSuccess(false);
            setEmail('')
            document.getElementById("emailLiquidacion").value = props.email
        } else if (success == 2) {
            document.getElementById("cerrarModalLiquidacionEmail").click();
            document.getElementById("openModalNoDocumento").click();
            setSuccess(false);
            setEmail('')
            document.getElementById("emailLiquidacion").value = props.email
        }
    }, [success]);

    function base64ToArrayBuffer(base64) {
        let binaryString = Base64.atob(base64);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    const desencriptarArchivo = (value, name) => {
        name = name.replaceAll(" ", "_").replaceAll(".", "_");
        value = Buffer.from(value, "base64");
        let link = document.createElement("a");
        let blob = new Blob([value], { type: "application/pdf" });
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.dispatchEvent(new MouseEvent("click"));
        setTimeout(() => {
            document.getElementById("closeModalDescarga").click();
        }, 1500);
    };

    function saveByteArray(reportName, byte) {
        let blob = new Blob([byte], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        let fileName = reportName;
        link.download = fileName;
        link.dispatchEvent(new MouseEvent("click"));
        setTimeout(() => {
            document.getElementById("closeModalDescarga").click();
        }, 1500);
    }

    const [selectedOption, setSelectedOption] = useState("false");

    const limpiarModal = () => {
        setEmail("");
    };

    const abrirModalCorreo = () => {
        setEmail(props.email);
        if (navigator.onLine) {
            document.getElementById("abrirModalCorreo").click();
        } else {
            document.getElementById("sinConexion").click();
        }
    };

    return (
        <>
            <section className="certificados">
                <div className="box pb-5">
                    <h2 className="h4 tit-section">Buscar certificado</h2>
                    <div className="row">
                        <div className="col-12 col-xl-6 mb-4 mb-xl-0">
                            <select className="form-select" defaultValue={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                                <option disabled value="false">
                                    Tipo de certificado
                                </option>
                                <optgroup label="Seguro Covid">
                                    <option value="seguroCovid">Seguro COVID</option>
                                </optgroup>
                                <optgroup label="Certificado Antiguedad">
                                    <option value="certificadoAntiguedad">
                                        Certificado Antiguedad
                                    </option>
                                    <option value="certificadoAntiguedadConRenta">
                                        Certificado Antiguedad con Renta
                                    </option>
                                    <option value="certificadoAntiguedadConRentaImponible">
                                        Certificado Antiguedad con Renta Imponible
                                    </option>
                                </optgroup>
                            </select>
                        </div>
                        {selectedOption != "false" &&
                            <div className="col d-grid d-md-block">
                                <button type="button" onClick={(e) => handleSubmit(e)} className="btn btn-degradado me-md-2 mb-2" disabled={selectedOption == ""}>Descargar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                {selectedOption != "seguroCovid" &&
                                    <button
                                        disabled={selectedOption == "seguroCovid" || selectedOption == ""}
                                        type="button"
                                        className="btn btn-primary mb-2"
                                        onClick={() => abrirModalCorreo()}>Enviar por correo<i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                                    </button>
                                }
                            </div>
                        }
                    </div>
                </div>
            </section>
            <a data-bs-toggle="modal" data-bs-target="#enviar-correo" hidden id="abrirModalCorreo"></a>
            <div className="modal fade" id="enviar-correo" tabIndex="-1" aria-labelledby="enviar-correo" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <form onSubmit={async (e) => { handleSubmitEmail(e); }}>
                            <div className="modal-header border-0 pb-0">
                                <button type="button" className="btn-close" onClick={() => limpiarModal()} data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <h2 className="h5 mb-2">Enviar a:</h2>
                                <div className="mb-3">
                                    <label className="form-label mb-2">
                                        <strong>Dirección de correo electrónico</strong>
                                    </label>
                                    <input
                                        type="email" id="emailLiquidacion" pattern="^[^@]+@[^@]+\.[a-zA-Z]{2,}" className="form-control"
                                        placeholder="mailcontacto@cencosud.com" onInput={(e) => e.target.setCustomValidity("")} onInvalid={(e) => e.target.setCustomValidity("Correo Invalido")} onChange={(e) => { setEmail(e.target.value); e.target.value.match("^[^@]+@[^@]+\.[a-zA-Z]{2,}") ? document.getElementById("btnEmailCert").disabled = false : document.getElementById("btnEmailCert").disabled = true }} defaultValue={props.email} required
                                    ></input>
                                </div>
                            </div>
                            <div className="modal-footer border-0 pt-0">
                                <button onClick={() => limpiarModal()} type="button" id="cerrarModalLiquidacionEmail" className="btn btn-primary col-12 col-md-auto" data-bs-dismiss="modal">Cancelar{" "}
                                    <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                                </button>
                                <button type="submit" id="btnEmailCert" disabled={buttonDisabled} className="btn btn-degradado col-12 col-md-auto">Confirmar<i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <a hidden id="sinConexion" data-bs-toggle="modal" data-bs-target="#sinConexionModal"></a>
            <div className="modal fade" id="sinConexionModal" tabIndex="-1" aria-labelledby="sinConexionModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body text-center py-4">
                            <i className="fa fa-exclamation-triangle color-orange fa-3x" aria-hidden="true"/>
                            <h2 className="h5">No tienes conexión</h2>
                            <p>
                                No puedes ejecutar esta acción si no estás conectado.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <a hidden id="openModalEmaliSend" data-bs-toggle="modal" data-bs-target="#success" />
            <div className="modal fade" id="success" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body text-center py-4">
                            <i className="fa fa-check-circle-o color-orange fa-3x" aria-hidden="true" />
                            <h2 className="h5">Correo enviado exitosamente</h2>
                            <p>
                                Su certificado ha sido enviado a la dirección de
                                correo electrónico proporcionada.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <a hidden id="openModalNoDocumento" data-bs-toggle="modal" data-bs-target="#noDocumento" />
            <div className="modal fade" id="noDocumento" tabIndex="-1" aria-labelledby="noDocumento" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body text-center py-4">
                            <i className="fa fa-exclamation-triangle color-orange fa-3x" aria-hidden="true" />
                            <h2 className="h5">No se encontró documento</h2>
                        </div>
                    </div>
                </div>
            </div>
            <a hidden id="openModalDescargar" data-bs-toggle="modal" data-bs-target="#modalDescargando" />
            <div className="modal fade" id="modalDescargando" tabIndex="-1" aria-labelledby="modalDescargando" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button id="closeModalDescarga" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center py-4">
                            <i className="fa fa-download color-orange fa-3x mb-2" data-bs-dismiss="modal" aria-label="Close" aria-hidden="true"></i>
                            <h2 className="h5">Descargando...</h2>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Certificados;
