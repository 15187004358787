import React, { useEffect, useState } from 'react';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import TablaVacaciones from './tabla-vacaciones';
import VacacionesService from '../../services/solicitud-vacaciones/vacaciones-arg-service';
import { Link } from 'react-router-dom';



const SolicitudesVacaciones = () => {
  const estadosOption = [
    { value: "", description: "Todas" },
    { value: "P", description: "Pendientes" },
    { value: "A", description: "Aprobadas" },
    { value: "R", description: "Rechazadas" }
  ];

  const [estado, setEstado] = useState(estadosOption.value);
  const [absenceList, setAbsenceList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetched, setFetched] = useState(null);
  const [noData, setNoData] = useState(null);


  useEffect(async () => {
    loadAusencias();
    if (fetched) {

    }
  }, [fetched])

  useEffect(() => {
    if (estado === "A") {
      VacacionesService.UpdateTable(estado, setAbsenceList, setFetched, setLoading);
    }
    if (estado === "R") {
      VacacionesService.UpdateTable(estado, setAbsenceList, setFetched, setLoading);
    }
    if (estado === "P") {
      VacacionesService.UpdateTable(estado, setAbsenceList, setFetched, setLoading);
    }
    if (estado === "") {
      loadAusencias();
    }

  }, [estado])

  const handleFilter = (e) => {
    setEstado(e.target.value)
  }

  const loadAusencias = async () => {
    await VacacionesService.getAbsenceList(setLoading, setFetched, setAbsenceList, setNoData);
  }

  return (
    <>
      {loading ? <div className="full-spinner"><div className="spinner-border text-primary"></div></div> :
        <div className='historic-container'>
          <div className='container-header'>
            <h4 className="form-title tit">Filtrar</h4>
            <div className='filters-fields'>
              <label className='label fw-bold'>Estado</label>
              <select className="form-select filter-input" value={estado} onChange={handleFilter}>
                {estadosOption.map((op, i) => {
                  return <option key={i} value={op.value}>{op.description}</option>
                })}
              </select>
            </div>
          </div>
          <div className="container-header">
          <div className="table-title">
              <h4 className="form-title tit">Detalle</h4>
              <Link to="../" className="h5 mt-2 fl-normal fw-bold text-decoration-none fs-6">Nueva Solicitud<i className="fa fa-plus-square-o fa-lg ms-2 color-orange" aria-hidden="true"></i></Link>
            </div>
            <TablaVacaciones list={absenceList}></TablaVacaciones>
          </div>
        </div>}
    </>

  )
}

export default SolicitudesVacaciones
