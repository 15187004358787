import React, { useEffect, useState, useRef } from "react";
import styles from "../../resources/css/style-COL.module.css";
import { BsExclamationCircle } from "react-icons/bs";
("../../resources/css/style-COL.css");

const VacacionesJefePendientesList = ({
  pendingRequest,
  selectedList,
  setSelectedList,
  commentList,
  setCommentList,
}) => {
  const [selectedRowId, setSelectedRowId] = useState("0");

  const inputRef = useRef();

  function checkValue(e) {
    const value = e.target.value;
    const checked = e.target.checked;
    // console.log("Value ", value);
    // console.log("Checked ", checked);
    if (checked) {
      selectedList.push({ id: value, checked: true });
      setSelectedList(selectedList);
    } else {
      selectedList = selectedList.filter((i) => i.id !== value);
      setSelectedList(selectedList);
    }

    console.log("selectedList ", selectedList);
  }

  function addComment(e) {
    const id = e?.currentTarget?.id;
    console.log("id ", id);
    console.log("selectedList ", selectedList);
    setSelectedRowId(id);

    const comments = commentList.find((i) => i.id === id);
    if (comments === null || comments == undefined) {
      inputRef.current.value = "";
    } else {
      inputRef.current.value = comments?.comment;
    }
  }

  const saveComment = () => {
    console.log("selectedRowId ", selectedRowId);
    console.log("inputRef.current.value ", inputRef.current.value);

    const filteredCommentList = commentList.filter(
      (i) => i.id !== selectedRowId
    );
    filteredCommentList.push({
      id: selectedRowId,
      comment: inputRef.current.value,
    });
    setCommentList(filteredCommentList);
  };

  return (
    <>
      <h2 className={styles["h4"] + " " + styles["tit-section"]}>
        Solicitudes Pendientes
      </h2>

      <form>
        {!pendingRequest.length ? (
          <>
            <div
              className={`${styles["alert"]} ${styles["alert-info"]} ${styles["alert-dismissible"]} ${styles["fade"]} ${styles["show"]} ${styles["text-center"]}`}
              role="alert"
            >
              <BsExclamationCircle /> No se encontraron solicitudes en el
              período y/o estado solicitado.
            </div>
          </>
        ) : (
          <>
            {/* <!-- Escritorio (Tabla) --> */}
            <div className={styles["table-responsive"]}>
              <table
                className={`${styles["table"]} ${styles["table-even"]} ${styles["table-section"]} ${styles["table-borderless"]} ${styles["d-none"]} ${styles["d-md-table"]}`}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th className={styles["text-nowrap"]}>
                      ID <br />
                      Solicitud{" "}
                    </th>
                    <th className={styles["text-nowrap"]}>
                      Nombre <br />
                      Solicitante{" "}
                    </th>
                    <th className={styles["text-nowrap"]}>
                      Fecha <br />
                      Inicio{" "}
                    </th>
                    <th className={styles["text-nowrap"]}>
                      Fecha <br />
                      Término{" "}
                    </th>
                    <th
                      className={`${styles["text-nowrap"]} ${styles["text-center"]}`}
                    >
                      Vacaciones{" "}
                    </th>
                    <th
                      className={`${styles["text-nowrap"]} ${styles["text-center"]}`}
                    >
                      Beneficio{" "}
                    </th>
                    <th
                      className={`${styles["text-nowrap"]} ${styles["text-center"]}`}
                    >
                      Dinero{" "}
                    </th>
                    <th
                      className={`${styles["text-nowrap"]} ${styles["text-center"]}`}
                    >
                      Disp/fecha{" "}
                    </th>
                    <th
                      className={`${styles["text-nowrap"]} ${styles["text-center"]}`}
                    >
                      Disfrut.{" "}
                    </th>
                    <th
                      className={`${styles["text-nowrap"]} ${styles["text-center"]}`}
                    >
                      Proyect.{" "}
                    </th>
                    <th
                      className={`${styles["text-nowrap"]} ${styles["text-center"]}`}
                    >
                      Comentario{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(pendingRequest)
                    ? pendingRequest.map((i) => {
                        return (
                          <tr key={i.id}>
                            <td>
                              <input
                                className={`${styles["form-check-input"]} ${styles["me-1"]} ${styles["h5"]} ${styles["m-0"]}`}
                                type="checkbox"
                                value={i.cn_id_solic_vac}
                                id={i.cn_id_solic_vac}
                                onChange={checkValue}
                              />
                            </td>
                            <td>{i.cn_id_solic_vac}</td>
                            <td>{i.name_display}</td>
                            <td>{i.inicio}</td>
                            <td>{i.fin}</td>
                            <td
                              className={`${styles["text-nowrap"]} ${styles["text-center"]}`}
                            >
                              {i.duracion - i.dias_beneficio}
                            </td>
                            <td
                              className={`${styles["text-nowrap"]} ${styles["text-center"]}`}
                            >
                              {i.dias_beneficio}
                            </td>
                            <td
                              className={`${styles["text-nowrap"]} ${styles["text-center"]}`}
                            >
                              {i.dias_dinero}
                            </td>
                            <td
                              className={`${styles["text-nowrap"]} ${styles["text-center"]}`}
                            >
                              {i.diasDisponibles}
                            </td>
                            <td
                              className={`${styles["text-nowrap"]} ${styles["text-center"]}`}
                            >
                              {i.diasDisfrutados}
                            </td>
                            <td
                              className={`${styles["text-nowrap"]} ${styles["text-center"]}`}
                            >
                              {i.diasProyectados}
                            </td>
                            <td className={styles["text-center"]}>
                              <a>
                                <i
                                  className={`${styles["fa"]} ${styles["fa-pencil"]} ${styles["fa-fw"]}`}
                                  aria-hidden="true"
                                  style={{ cursor: "pointer" }}
                                  onClick={addComment}
                                  id={i.cn_id_solic_vac}
                                  data-bs-toggle="modal"
                                  data-bs-target="#conocer-mas"
                                ></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
            {/* <!-- Móvil (Acordeón) --> */}
            <div
              className={`accordion accordion-flush accordion-light accordion-table ${styles["d-md-none"]}`}
            >
              {Array.isArray(pendingRequest)
                ? pendingRequest.map((i, id) => {
                    return (
                      <div key={id} className="accordion-item">
                        <h2 className="accordion-header" id="accordion">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#accordion-item-${id}`}
                            aria-expanded="false"
                            aria-controls={`accordion-item-${id}`}
                          >
                            <strong>ID Solicitud</strong> {i.cn_id_solic_vac}
                          </button>
                        </h2>
                        <div
                          id={`accordion-item-${id}`}
                          className="accordion-collapse collapse"
                          aria-labelledby="accordion"
                        >
                          <div className={`accordion-body ${styles["p-0"]}`}>
                            {/* <!-- cuerpo item --> */}
                            <div
                              className={`${styles["data-list-box"]} ${styles["data-list-box-even"]}`}
                            >
                              <ul
                                className={`${styles["data-list-section--item"]} ${styles["list-unstyled"]}`}
                              >
                                <li>
                                  <span
                                    className={styles["data-list-box__title"]}
                                  >
                                    <input
                                      className={`${styles["form-check-input"]} ${styles["me-1"]} ${styles["h5"]} ${styles["m-0"]}`}
                                      type="checkbox"
                                      value={i.cn_id_solic_vac}
                                      id={i.cn_id_solic_vac}
                                      onChange={checkValue}
                                    />
                                  </span>
                                </li>
                                <li>
                                  <span
                                    className={styles["data-list-box__title"]}
                                  >
                                    Nombre Solicitante
                                  </span>
                                  <span
                                    className={styles["data-list-box__text"]}
                                  >
                                    {i.name_display}
                                  </span>
                                </li>
                                <li>
                                  <span
                                    className={styles["data-list-box__title"]}
                                  >
                                    Fecha Inicio
                                  </span>
                                  <span
                                    className={styles["data-list-box__text"]}
                                  >
                                    {i.inicio}
                                  </span>
                                </li>
                                <li>
                                  <span
                                    className={styles["data-list-box__title"]}
                                  >
                                    Fecha Término
                                  </span>
                                  <span
                                    className={styles["data-list-box__text"]}
                                  >
                                    {i.fin}
                                  </span>
                                </li>
                                <li>
                                  <span
                                    className={styles["data-list-box__title"]}
                                  >
                                    Vacaciones
                                  </span>
                                  <span
                                    className={styles["data-list-box__text"]}
                                  >
                                    {i.duracion - i.dias_beneficio}
                                  </span>
                                </li>
                                <li>
                                  <span
                                    className={styles["data-list-box__title"]}
                                  >
                                    Beneficio
                                  </span>
                                  <span
                                    className={styles["data-list-box__text"]}
                                  >
                                    {i.dias_beneficio}
                                  </span>
                                </li>
                                <li>
                                  <span
                                    className={styles["data-list-box__title"]}
                                  >
                                    Dinero
                                  </span>
                                  <span
                                    className={styles["data-list-box__text"]}
                                  >
                                    {i.dias_dinero}
                                  </span>
                                </li>

                                <li>
                                  <span
                                    className={styles["data-list-box__title"]}
                                  >
                                    Disp/fecha
                                  </span>
                                  <span
                                    className={styles["data-list-box__text"]}
                                  >
                                    {i.diasDisponibles}
                                  </span>
                                </li>
                                <li>
                                  <span
                                    className={styles["data-list-box__title"]}
                                  >
                                    Disfrut.
                                  </span>
                                  <span
                                    className={styles["data-list-box__text"]}
                                  >
                                    {i.diasDisfrutados}
                                  </span>
                                </li>
                                <li>
                                  <span
                                    className={styles["data-list-box__title"]}
                                  >
                                    Proyect.
                                  </span>
                                  <span
                                    className={styles["data-list-box__text"]}
                                  >
                                    {i.diasProyectados}
                                  </span>
                                </li>
                                <li>
                                  <span
                                    className={styles["data-list-box__title"]}
                                  >
                                    Comentario
                                  </span>
                                  <span
                                    className={styles["data-list-box__text"]}
                                  >
                                    <a>
                                      <i
                                        className={`${styles["fa"]} ${styles["fa-pencil"]} ${styles["fa-fw"]}`}
                                        aria-hidden="true"
                                        style={{ cursor: "pointer" }}
                                        onClick={addComment}
                                        id={i.cn_id_solic_vac}
                                        data-bs-toggle="modal"
                                        data-bs-target="#conocer-mas"
                                      ></i>
                                    </a>
                                  </span>
                                </li>
                              </ul>
                            </div>
                            {/* <!-- fin cuerpo item --> */}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
              {/* <!-- 1er acordeón --> */}

              {/* <!-- 2do acordeón --> */}

              {/* <!-- fin items acordeón --> */}
            </div>
            {/* <!-- fin Móvil (Acordeón) --> */}
          </>
        )}
      </form>

      {/* <!-- Modal mensaje exitoso --> */}
      <div
        className="modal fade"
        id="conocer-mas"
        tabindex="-1"
        aria-labelledby="conocer-mas"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body py-4">
              <h2 className="h4 mb-4 text-center">Agregar Comentario</h2>
              <form>
                <div className="form-group">
                  <label for="message-text" className="col-form-label">
                    Comentario:
                  </label>
                  <textarea
                    className="form-control"
                    id="message-text"
                    ref={inputRef}
                    maxlength="200"
                  ></textarea>
                </div>
              </form>
            </div>
            <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
              <button
                type="submit"
                className="btn btn-degradado col-12 col-md-auto my-auto px-5"
                data-bs-dismiss="modal"
                onClick={saveComment}
              >
                Guardar{" "}
                <i
                  className="fa fa-chevron-right fa-fw fa-xs"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- fin modals --> */}
    </>
  );
};

export default VacacionesJefePendientesList;
