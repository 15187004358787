import React, { useState, useRef, useEffect } from "react";
import Swal from "sweetalert2";
import DatosLaboralesService from "../../services/datos laborales/datos-laborales-service";
import { toBase64 } from '../../services/utiles';
import fotoDefault from "../../resources/images/default-colaborador-image.png"
import styles from "../../resources/css/style-COL.module.css";
import Auth from "@aws-amplify/auth";
import axios from "axios";
import successMessage from "../SharedComponents/success-message";
import errorMessage from "../SharedComponents/error-message";
import { Link } from 'react-router-dom'

const DatosLaboralesCol = () => {
    const [avatar, setAvatar] = useState(null);
    const [loadingAvatar, setLoadingAvatar] = useState(true);
    const [loadingPostAvatar, setLoadingPostAvatar] = useState(false);
    const [errorPostAvatar, setErrorPostAvatar] = useState(false);
    const [postAvatar, setPostAvatar] = useState(false);
    const [info, setInfo] = useState({});
    const [loadingInfo, setLoadingInfo] = useState(true);
    const [noData, setNoData] = useState(false)
    const fileRef = useRef(null);
    const loadAvatar = async () => {
        setLoadingAvatar(true)
        try {
            const response = await DatosLaboralesService.getAvatarRegional();
            setAvatar(response.data);
            setLoadingAvatar(false);
            setLoadingPostAvatar(false);
            setErrorPostAvatar(false)
        } catch (e) {
            setAvatar(null);
            setLoadingAvatar(false);
            setLoadingPostAvatar(false);
            setErrorPostAvatar(false)
        }
    }

    useEffect(async () => {
        loadAvatar()
        DatosLaboralesService.getEmployee(setInfo, setLoadingInfo, setNoData)

    }, []);

    const cambiarFoto = () => {
        fileRef.current.click();
    };


    const handleFileChange = async (e) => {
        if (
            e.target.files[0]?.type === "image/jpeg" ||
            e.target.files[0]?.type === "image/jpg" ||
            e.target.files[0]?.type === "image/png"
        ) {
            if (e.target.files[0]?.size <= 5000000) {
                const image = await toBase64(e.target.files[0])
                setLoadingPostAvatar(true)
                try {
                    await DatosLaboralesService.saveAvatarRegional(image);
                    setLoadingPostAvatar(false)
                    handleStatusSuccess();
                } catch (e) {
                    console.log(e);
                    setLoadingPostAvatar(false)
                    errorMessage(loadAvatar, "Ocurrió un error al actualizar la imagen de perfil");
                }
            } else {
                Swal.fire({
                    allowOutsideClick: false,
                    position: 'center',
                    title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                    html: '<div style="text-align: center; font-size: 15px">El tamaño del Certificado debe ser menor a 5Mb</div>',
                    confirmButtonColor: '#11427C',
                }
                )
            }
        } else {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                html: '<div style="text-align: center; font-size: 15px">Debe ingresar uno de los tipos de archivos permitidos</div>',
                confirmButtonColor: '#0169b5',
            })

        }
    };

    const handleStatusSuccess = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: green; font-size: 16px">Exitoso</div>',
            html: '<div style="text-align: center; font-size: 15px">Archivo cargado correctamente</div>',
            confirmButtonColor: '#0169b5'
        }).then(() => {
            setLoadingAvatar(true)
            window.location.reload()
            DatosLaboralesService.getEmployee(setInfo, setLoadingInfo, setNoData)
        })
        setLoadingPostAvatar(false);
        setErrorPostAvatar(false)
    }

    // Mensaje de error al enviar un post
    const handleStatusError = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
            html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo.</div>`,
            confirmButtonColor: '#0169b5',
        }).then(() => {
            setLoadingAvatar(true)
            loadAvatar()
            DatosLaboralesService.getEmployee(setInfo, setLoadingInfo, setNoData)
        })
        setLoadingPostAvatar(false)
        setErrorPostAvatar(false)
    }
    useEffect(() => {
        postAvatar ? handleStatusSuccess() : null
    }, [loadingPostAvatar])

    return (
        <div>
            {/* <!-- inicio breadcrumbs -->   */}
            <nav className={`${styles["breadcrumbs"]} ${styles["d-none"]} ${styles["d-lg-block"]}`} aria-label="breadcrumb">
                <ol className={styles["breadcrumb"]}>
                    <li className={styles["breadcrumb-item"]}>
                        <Link to="/inicio">
                            <i className="fa fa-home" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">Datos Laborales</li>
                </ol>
            </nav>
            {/* <!-- fin breadcrumbs --> */}

            {/* <!-- inicio Datos Laborales --> */}
            {!noData ?
                (!loadingInfo ? <section className={styles["datos-laborales"]}>
                    <h1>Datos Laborales</h1>

                    <div className={styles["box"]}>
                        <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Perfil Laboral</h2>
                        <div className={`${styles["row"]} ${styles["flex-lg-row-reverse"]}`}>
                            <div className={`${styles["col-12"]} ${styles["col-lg-4"]} ${styles["d-flex"]} ${styles["justify-content-lg-center"]}`}>
                                {!loadingAvatar ?
                                    <div className={`${styles["img-profile"]} ${styles["mb-4"]} ${styles["mb-lg-0"]}`}>
                                        <input
                                            hidden
                                            ref={fileRef}
                                            name="file"
                                            type="file"
                                            onChange={handleFileChange}
                                            accept=".jpg, .jpeg, .png"
                                        />
                                        <a onClick={cambiarFoto} className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["rounded-circle"]}`}><i className={`${styles["fa"]} ${styles["fa-camera"]}`} aria-hidden="true"></i></a>
                                        <img src={avatar === null ? fotoDefault : avatar} alt="" className={styles["rounded-circle"]} width="160" height="160" />
                                    </div>
                                    :
                                    <div data-testid="loading" className={styles["full-spinner"]}>
                                        <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
                                    </div>
                                }
                            </div>
                            <div className={`${styles["col-12"]} ${styles["col-lg-8"]}`}>
                                <div className={styles["data-list-box"]}>
                                    <ul className={`${styles["list-column-1"]} ${styles["list-unstyled"]}`}>
                                        <li>
                                            <span className={styles["data-list-box__title"]}>Fecha de Ingreso</span>
                                            <span className={styles["data-list-box__text"]}>{info.last_hire_date}</span>
                                        </li>
                                        <li>
                                            <span className={styles["data-list-box__title"]}>Cargo</span>
                                            <span className={styles["data-list-box__text"]}>{info.position_description}</span>
                                        </li>
                                        <li>
                                            <span className={styles["data-list-box__title"]}>Ubicación</span>
                                            <span className={styles["data-list-box__text"]}>{info.location_description}</span>
                                        </li>
                                        <li>
                                            <span className={styles["data-list-box__title"]}>Centro Costo</span>
                                            <span className={styles["data-list-box__text"]}>{info.costCenter}</span>
                                        </li>
                                        <li>
                                            <span className={styles["data-list-box__title"]}>Estado</span>
                                            <span className={styles["data-list-box__text"]}>{info.status === 'A' ? "Activo" : info.status === 'I' ? "Inactivo" : null}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> :
                    <div data-testid="loading" className={styles["full-spinner"]}>
                        <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
                    </div>)
                : <div className={`${styles["alert"]} ${styles["alert-info"]} ${styles["alert-dismissible"]} ${styles["fade"]} ${styles["show"]} ${styles["text-center"]}`} role="alert">
                    No se encontraron datos asociados
                </div>}
            {errorPostAvatar ? handleStatusError() : null}
        </div>
    );
};

export default DatosLaboralesCol;
