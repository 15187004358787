import React, { FC, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import codigoService from "../services";
import ModalDetalle from "../components/Modal/detalle";
import ModalConflicto from "../components/Modal/conflicto";
import ModalNoConflicto from "../components/Modal/NoConflicto";
import ModalAnexo from "../components/Modal/anexo";
import ModalRevalidar from "../components/Modal/revalidar";
import { BreadCrumbsDinamic }  from "../../commonComponents/BreadCrumbsDinamic";
import FileDownload from "../components/FileDownload";
import { IFormData, IModalValues } from "../interfaces";
import ModalLoad from "../../commonComponents/ModalLoad";



const CodigoEtica: FC = () => {
    const [haveKnowledge, setHaveKnowledge] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [conflictsOptions, setConflictsOptions] = useState({
        conflictos: [{
            valor: "",
            descr: ""
        }],
        relaciones: [{
            valor: "",
            descr: ""
        }]
    });
    const [conflictRadio, setConflictRadio] = useState<undefined | boolean>(undefined);
    const [conflictos, setConflictos] = useState([]);
    const [modalValues, setModalValues] = useState<IModalValues>({
        metodo: "",
        index: -1,
        data: {
            nombre: "",
            rut: "",
            relacion: "",
            tipoconflicto: "",
            comentario: "",
        },
    });
    const haveConflicts = conflictos.length > 0 && conflictos[0]['conflictTypeCd'] !== "005" && conflictRadio === true;
    const refLoadOpen = useRef<HTMLButtonElement>(null);
    const refLoadClose = useRef<HTMLButtonElement>(null);

    const openModal = (met:string, conf:any, e:any, index:number) => {
        e.preventDefault();
        const dataf:IFormData = {
            nombre: conf["conflictedName"],
            rut: conf["conflictedRut"],
            relacion: conf["relationshipCd"],
            tipoconflicto: conf["conflictTypeCd"],
            comentario: conf["observation"],
        }

        setModalValues({
            metodo: met,
            index: index,
            data: dataf,
        });
    }
    
    const getConflicto = async () => {
        const data = await codigoService.getConflicto();
        setConflictRadio(data?.response?.existsConflict || false);
        setConflictos(data?.response?.conflicts || []);
        setHaveKnowledge(data?.response?.conflicts?.length > 0 ? true : false);
    }

    const modalConflictoFunction = ( e:any ) => {
        openModal("add", [], e, -1);
    }

    const modalNoConflictoFunction = async () => {
        const request = {
            existsConflict: false,
            action: "create",
        }
        setBtnDisabled(true);
        document.getElementById("btn-close")?.click();
        refLoadOpen.current?.click();
        await codigoService.setConflicto(request) 
        await getConflicto();
        refLoadClose.current?.click();
    }

    useEffect(() => {
        codigoService.getVariableList(setConflictsOptions);
        getConflicto();
    }, [])

    useEffect(() => {
        if (modalValues.metodo !== "") {
            setTimeout(() => {
                document.getElementById("open-modal")?.click();
            }, 200);
        }
    }, [modalValues])

    const closeModal = () => {
        document.getElementById("open-modal")?.click();
    }


    let date = new Date();
    let todayDate = String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + date.getFullYear();
    return (
        <>
            {/*inicio breadcrumbs */}
            <BreadCrumbsDinamic />
            {/*inicio Código de Ética */}
            <section className="mis-documentos">
                <h1>Código de Ética</h1>
                {/*Conoce nuestro Código de Ética */}
                <FileDownload />
                {/*Declaraciòn */}
                <div className="box mb-4">
                    <h2 className="h4 tit-section">Declaración de Conflicto de Interés</h2>
                    <h3 className="h5 mb-4">Lea el siguiente texto y luego seleccione la opción que corresponda.</h3>
                    <p>A continuación y según lo estipulado en el código de ética, doy a conocer las situaciones que actualmente pudieran &ldquo;Ser&rdquo; o &ldquo;Parecer&rdquo; un conflicto de interés respecto de mi cargo. Junto con ello, me pongo a disposición de la compañía para entregarle información adicional, en caso que esto sea necesario (especificar la situación y en caso que corresponda: Nombre, parentesco, número de documentos de identificación, identificación de sociedades, vinculaciones con proveedores/terceros, etc).</p>
                    <form className="needs-validation" noValidate={true}>
                        <div className="mt-4 mb-2">
                            <input 
                                className="form-check-input border-primary me-2 h5" 
                                type="checkbox" 
                                id="acepto" 
                                required 
                                defaultChecked={(conflictos.length > 0) ? true : false}
                                checked={haveKnowledge} 
                                onChange={() => setHaveKnowledge(!haveKnowledge) } 
                            />
                            <label className="form-check-label color-primary-strong" htmlFor="acepto">He leído y acepto mi declaración de conflictos de interés</label>
                            {
                                !haveKnowledge &&
                                <div className="invalid-feedback d-block">
                                    Debe aceptar que ha leído y aceptar su declaración <i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true"></i>
                                </div>
                            }
                        </div>
                        {
                            haveKnowledge &&
                            <div className="mb-3">
                                <h3 className="bg-light mb-0 h5 p-3">Tengo una situación que pudiera ser o parecer un conflicto de interés.</h3>
                                <div className="rounded-3 bg-light d-flex p-2 color-primary mb-2">
                                    <div className="form-check d-flex align-items-center d-inline-block mx-2">
                                        <input checked={conflictos.length > 0 && conflictRadio !== undefined ? conflictRadio : undefined} defaultChecked={conflictos.length > 0 && conflictRadio !== undefined && conflictRadio} className="form-check-input" type="radio" name="radioconflicto" id="flexRadioDefault2" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#conflictomodal" />
                                        <label className="form-check-label text-uppercase fw-bold" htmlFor="flexRadioDefault2">si</label>
                                    </div>
                                    <div className="form-check d-flex align-items-center d-inline-block mx-4">
                                        <input checked={conflictos.length > 0 && conflictRadio !== undefined ? !conflictRadio : undefined} defaultChecked={conflictos.length > 0 && conflictRadio !== undefined && !conflictRadio} className="form-check-input" type="radio" name="radioconflicto" id="flexRadioDefault1" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#no-conflictomodal" />
                                        <label className="form-check-label text-uppercase fw-bold" htmlFor="flexRadioDefault1">No</label>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            conflictRadio === undefined && haveKnowledge &&
                            <div className="invalid-feedback d-block">
                                Debe seleccionar una opción <i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true"></i>
                            </div>
                        }
                    </form>
                    <button  type="button"
                        disabled={!haveKnowledge} 
                        className="btn btn-degradado text-nowrap mt-4" 
                        data-bs-toggle="modal"  
                        onClick={() => document.getElementById('revalidatemodalbtn')?.click()} >
                        Revalidar Conflictos
                    </button>
                    <div className="border-bottom border-primary border-4 p-2 mt-5">
                        <div className="tit-group d-flex justify-content-between">
                            <span className="text-uppercase fw-bold">Mi historial</span>
                            {
                                haveConflicts && haveKnowledge &&
                                    <Link to="" className="mt-2" data-bs-toggle="modal" onClick={(e) => openModal("add", [], e, -1)} >
                                            <i className="fa fa-plus-square-o fa-lg" aria-hidden="true" />
                                    </Link>
                            }
                        </div>
                    </div>
                    {/*Escritorio */}
                    <table className="table table-even table-section table-borderless align-middle text-uppercase d-none d-md-table">
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Conflicto</th>
                                <th>RUT</th>
                                <th>Relación C/Empleado</th>
                                <th className="text-center">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                !haveConflicts &&
                                    <tr>
                                        <td colSpan={5} className="text-center">Declara que no tiene conflicto</td>
                                    </tr>
                            }
                            {
                                haveConflicts && conflictos.map((conflicto, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{conflicto["validationDate"]}</td>
                                            <td>{conflicto["conflictTypeDescr"]}</td>
                                            <td>{conflicto["conflictedRut"]}</td>
                                            <td>{conflicto["relationshipDescr"]}</td>
                                            <td className="text-center">
                                                {
                                                    haveConflicts && haveKnowledge && <>
                                                        <Link to="#" data-bs-toggle="modal" onClick={(e) => openModal("upd", conflicto, e, index)} >
                                                            <i className="fa fa-pencil fa-fw me-2" aria-hidden="true" />
                                                        </Link>
                                                        <Link to="#" data-bs-toggle="modal" onClick={(e) => openModal("del", conflicto, e, index)} >
                                                            <i className="fa fa-trash-o fa-fw" aria-hidden="true" />
                                                        </Link>
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    {/*Movil */}
                    <div className="data-list-section d-block d-md-none">
                        {
                            !haveConflicts &&
                            <ul className="data-list-section--item list-unstyled mb-0">
                                <li>
                                    <span className="text-uppercase d-block mb-1"><strong>Fecha</strong></span>
                                    <span>{todayDate}</span>
                                </li>
                                <li>
                                    <span className="text-uppercase d-block mb-1"><strong>Conflicto</strong></span>
                                    <span>Declara que no tiene conflicto</span>
                                </li>
                                <li>
                                    <span className="text-uppercase d-block mb-1"><strong>Acciones</strong></span>
                                </li>
                            </ul>
                        }
                        {
                            haveConflicts && conflictos.map((conflicto, index) => {
                                return (
                                    <ul key={index} className="data-list-section--item list-unstyled mb-0">
                                        <li>
                                            <span className="text-uppercase d-block mb-1"><strong>Fecha</strong></span>
                                            <span>{conflicto["validationDate"]}</span>
                                        </li>
                                        <li>
                                            <span className="text-uppercase d-block mb-1"><strong>Conflicto</strong></span>
                                            <span>{conflicto["conflictTypeDescr"]}</span>
                                        </li>
                                        <li>
                                            <span className="text-uppercase d-block mb-1"><strong>RUT</strong></span>
                                            <span>{conflicto["conflictedRut"]}</span>
                                        </li>
                                        <li>
                                            <span className="text-uppercase d-block mb-1"><strong>Relación C/Empleado</strong></span>
                                            <span>{conflicto["relationshipDescr"]}</span>
                                        </li>
                                        {
                                            haveConflicts && haveKnowledge && <>
                                                <li>
                                                    <Link to="#" data-bs-toggle="modal" onClick={(e) => openModal("upd", conflicto, e, index)} >
                                                        <i className="fa fa-pencil fa-fw me-2" aria-hidden="true" />
                                                    </Link>
                                                    <Link to="#" data-bs-toggle="modal" onClick={(e) => openModal("del", conflicto, e, index)} >
                                                        <i className="fa fa-trash-o fa-fw" aria-hidden="true" />
                                                    </Link>
                                                </li>
                                            </>
                                        }
                                    </ul>
                                )
                            })
                        }
                    </div>
                    <ModalNoConflicto modalNoConflictoFunction={modalNoConflictoFunction} />
                    <ModalConflicto modalConflictoFunction={modalConflictoFunction} />
                    <ModalAnexo getConflicto={getConflicto} />
                    <ModalDetalle 
                        conflictsOptions={conflictsOptions}
                        modalValues={modalValues}
                        setModalValues={setModalValues}
                        conflicts={conflictos}
                        btnDisabled={btnDisabled}
                        setBtnDisabled={setBtnDisabled}
                        refLoadOpen={refLoadOpen}
                        refLoadClose={refLoadClose}
                    />
                    <ModalRevalidar refLoadClose={refLoadClose} refLoadOpen={refLoadOpen} getConflicto={getConflicto} />
                    <a href="#" id="open-modal" hidden data-bs-toggle="modal" data-bs-target="#detalle" data-bs-backdrop="static" data-bs-keyboard="false"/>
                </div>
            </section>
            <ModalLoad refOpen={refLoadOpen} refClose={refLoadClose} />
        </>
    );
};

export default CodigoEtica;