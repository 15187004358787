import React, { Children, FC, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { CategoryProps, TableCategoryProps } from "../../../../../utilities/interfaces";
import { pipe } from "fp-ts/lib/function";
import * as A from "fp-ts/Array";

const TableCategory: FC<TableCategoryProps> = ({setIsEditCategory, setEditProps, categoryList, refConfirmAction}) => {
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageItems, setPageItems] = useState<CategoryProps[][]>([]);    

    let headers = [
        {
            label: "País",
            value: "country"
        },
        {
            label: "Categoría",
            value: "category"
        },
        {
            label: "Ícono",
            value: "icon"
        },
    ]

    const handlePageClick = (selectedItem: { selected: number }) => {
        setCurrentPage(selectedItem.selected);
    };

    useEffect(()=>{
        if(categoryList.length > 0 ){
            setCurrentPage(0);
            setPageItems(pipe(
                categoryList.filter((r) =>   r.title !== ''
                ), A.chunksOf(5)
            ))
        }
    },[categoryList])


return(
    <>
        <div className="table-responsive">
            <table className="table table-section table-borderless table-th-border align-middle table-even d-none d-md-table">
                <thead>
                    <tr>
                        {Children.toArray(headers.map((header,i)=>{
                            return(
                                <>
                                    <td>{header.label}</td>
                                </>
                            )
                        }))}
                        <td className="text-center">Acciones</td>
                    </tr>
                </thead>
                <tbody>
                {pageItems && pageItems.length > 0 && categoryList.length > 0 ? Children.toArray(pageItems[currentPage].map((category) => {
                    return (
                        <>
                            <tr>
                                <td>{category.description_country}</td>
                                <td>{category.title}</td>
                                <td>
                                    <span className="material-symbols-outlined color-primary">
                                        <i className={`fa ${category.icon} fa-fw fa-lg`} aria-hidden="true"></i>
                                    </span>
                                </td>
                                <td className="text-center">
                                    <a className="mt-2 text-decoration-none btnLink" data-bs-toggle="modal" data-bs-target="#asignacion"
                                    onClick={()=>{
                                        setIsEditCategory(true);
                                        setEditProps(category)
                                    }}>
                                        <i className="fa fa-pencil fa-fw fa-lg" title="Editar" aria-hidden="true"></i>
                                    </a>
                                    <a className="btnLink" onClick={()=>{
                                        setEditProps(category)
                                        refConfirmAction && refConfirmAction.current && refConfirmAction.current.click(); 
                                    }}>
                                        <i className="fa fa-trash fa-fw fa-lg" title="Eliminar" aria-hidden="true"></i>
                                    </a>
                                </td>
                            </tr>
                        </>
                    )
                }))
                :
                <>
                    <tr>
                        <td style={{maxWidth:'1px'}}>
                            <p>No se ha encontrado información.</p>
                        </td>
                        {Children.toArray(headers.map((a,b)=>{
                            return (
                                <>
                                    <td></td>
                                </>
                            )
                        }))}
                    </tr>
                </>
                }
                </tbody>
            </table>

            <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
                    { pageItems && pageItems.length > 0 && categoryList.length > 0 ? Children.toArray(pageItems[currentPage].map((category,i) => {
                        return(
                            <>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id={"acordeon-" + i}>
                                        <button className={i === 0 ? "accordion-button " : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={"#accordion-"+i} aria-expanded={i === 0 ? "true": "false"} aria-controls={"accordion-" + i}>
                                            <strong>País</strong> {category.description_country}
                                        </button>
                                        </h2>
                                        <div id={"accordion-"+i} className={i === 0 ? "accordion-collapse collapse show": "accordion-collapse collapse"} aria-labelledby={"accordion-"+i}>
                                            <div className="accordion-body p-0">
                                                <div className="data-list-box">
                                                    <ul className="data-list-section--item list-unstyled">
                                                        <li>
                                                            <span className="data-list-box__title">Categoría</span>
                                                            <span className="data-list-box__text">{category.title}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Ícono</span>
                                                            <span className="data-list-box__text">
                                                            <span className="material-symbols-outlined color-primary">
                                                                <i className={`fa ${category.icon} fa-fw fa-lg`} aria-hidden="true"></i>
                                                            </span>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Acciones</span>
                                                            <span className="data-list-box__text">
                                                                <a data-bs-toggle="modal" data-bs-target="#asignacion" onClick={()=>{
                                                                    setIsEditCategory(true);
                                                                    setEditProps(category);
                                                                }}><i className="fa fa-pencil fa-fw fa-lg" aria-hidden="true" title="Editar"></i></a>
                                                                <a onClick={()=>{
                                                                    setEditProps(category);
                                                                    refConfirmAction && refConfirmAction.current && refConfirmAction.current.click(); 
                                                                }}><i className="fa fa-trash fa-fw fa-lg" title="Eliminar" aria-hidden="true"></i></a>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </>
                        )
                    }))
                    :
                    <>
                        <div className="text-center">
                                <p className="">No se ha encontrado información.</p>
                        </div>
                    </>
                    }
            </div>
        </div>
        {pageItems !== undefined && pageItems.length > 0 ? (
            <nav className="mt-5" aria-label="Paginación">
                <ReactPaginate
                    className="pagination justify-content-center align-items-center"
                    breakLabel=" ... "
                    nextLabel=" > "
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={0}
                    marginPagesDisplayed={1}
                    pageCount={pageItems.length}
                    previousLabel=" < "
                    renderOnZeroPageCount={() => null}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                    breakLinkClassName={"page-link"}
                    pageLinkClassName={"page-link"}
                    pageClassName={"page-item"}
                    breakClassName={"page-item"}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    activeClassName={"page-item active"}
                    activeLinkClassName={"page-link active"}
                />
            </nav>
        ) : null}
    </>
)    
}
export default TableCategory;