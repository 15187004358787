import React, { useState, useEffect, useRef, Children, useLayoutEffect } from 'react';
import { Text, Image, StyleSheet, View, Page, Document, PDFDownloadLink } from "@react-pdf/renderer";
import { Chart as ChartJS, registerables } from "chart.js";
import { Bar, Chart } from "react-chartjs-2";
import { groupBy, formatDate, dotPerc, dotNumber } from '../../../services/utiles';



const CambiosDeRenta = ({
    colaborador,
    loadingColaborador,
    hiddenTd,
}) => {
    const [cambioRentaporAno, setCambioRentaporAno] = useState(false);


    useEffect(() => {
        if (!loadingColaborador) {
            const cambioRentaData = Array.isArray(colaborador.CN_RENTA_LIST) ? colaborador.CN_RENTA_LIST.slice(0, 10).reverse() : colaborador.CN_RENTA_LIST;
            if (cambioRentaData) {
                setCambioRentaporAno(groupBy(Array.isArray(colaborador.CN_RENTA_LIST) ? colaborador.CN_RENTA_LIST : [colaborador.CN_RENTA_LIST], "anio"));
            }
        }
    }, [loadingColaborador]);

    const FichaCambiosDeRentaPDF = ({ colaborador }) => {

        if (!Array.isArray(colaborador.CN_REM_LIST)) {
            colaborador.CN_REM_LIST = [colaborador.CN_REM_LIST];
        }

        const styles = StyleSheet.create({
            page: {
                backgroundColor: '#fff',
                padding: '20px',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
            },
            card: {
                padding: "20px",
                borderRadius: "10px",
                width: "48%",
                border: "1px solid #eeeeee",
                margin: " 0px 5px 20px 5px",
                height: "auto",
            },
            head: {
                display: "flex",
                marginBottom: "20px",
                paddingLeft: "10px",
            },
            body: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "20px"
            },
            h1: {
                fontSize: "16px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: "10px",
                color: "#0169b5"
            },
            table: {
                width: "100%",
                borderCollapse: "collapse",
                borderSpacing: "0",
                marginBottom: "20px"
            },
            tableHead: {
                backgroundColor: "#eeeeee",
                borderBottom: "1px solid #eeeeee",
                borderTop: "1px solid #eeeeee",
                borderLeft: "1px solid #eeeeee",
                borderRight: "1px solid #eeeeee",
                padding: "5px",
                fontSize: "8px",
            },
            tableBody: {
                borderBottom: "1px solid #eeeeee",
                borderLeft: "1px solid #eeeeee",
                borderRight: "1px solid #eeeeee",
                fontSize: "8px",
                textAlign: "center",
                color: "#777777",
            },
            tableRow: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                padding: "5px 5px 5px 5px",
                border: "1px solid #eeeeee",
            },
            labelHead: {
                textAlign: "center",
                color: "#777777",
                fontWeight: "900",
            },
            labelBody: {
                textAlign: "center",
                color: "#777777",
                fontWeight: "normal",
            },
            li: {
                display: "flex",
                flexDirection: "row",
                alignItems: 'flex-start',
                padding: "10px",
                borderBottom: "1px solid #eeeeee",
                position: "relative",
                justifyContent: "flex-start"
            },
            liTitle: {
                width: "50%",
            },
            liContent: {
                width: "50%",
            },
            titleLabel: {
                fontSize: "8px",
                fontWeight: "bold",
                color: "#777777"
            },
            contentLabel: {
                fontSize: "8px",
                fontWeight: "normal",
                color: "#777777"
            },
            foto: {
                width: "100px",
                height: "100px",
            },
            checkimg: {
                width: "20px",
                height: "20px",
            },
        });

        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    {/* Cambios de Renta */}
                    <View style={{ ...styles.card, width: "100%" }}>
                        <View style={styles.head}>
                            <Text style={styles.h1}>Cambios de Renta</Text>
                        </View>
                        <View style={styles.body}>
                            <View style={styles.table}>
                                <View style={styles.tableHead}>
                                    <View style={styles.tableRow}>
                                        <Text style={{ ...styles.labelHead, width: "15%" }}>AÑO</Text>
                                        <Text style={{ ...styles.labelHead, width: "15%" }}>RUT</Text>
                                        <Text style={{ ...styles.labelHead, width: "15%" }}>NOMBRE</Text>
                                        <Text style={{ ...styles.labelHead, width: "15%" }}>FECHA CAMBIO</Text>
                                        <Text style={{ ...styles.labelHead, width: "15%" }}>MOTIVO</Text>
                                        <Text style={{ ...styles.labelHead, width: "15%" }}>SAL. BASE</Text>
                                        <Text style={{ ...styles.labelHead, width: "15%" }}>% CAMBIO</Text>
                                    </View>
                                </View>
                                <View style={styles.tableBody}>
                                    {
                                        colaborador.CN_RENTA_LIST !== undefined ?
                                            Array.isArray(colaborador.CN_RENTA_LIST) ?
                                                colaborador.CN_RENTA_LIST.map((item, index) => {
                                                    return (
                                                        <View style={styles.tableRow} key={"dt" + index}>
                                                            <Text style={{ ...styles.labelContent, width: "15%" }}>{item.anio === "" ? "-" : item.anio}</Text>
                                                            <Text style={{ ...styles.labelContent, width: "15%" }}>{item.rut === "" ? "-" : item.rut}</Text>
                                                            <Text style={{ ...styles.labelContent, width: "15%" }}>{item.nombre === "" ? "-" : item.nombre}</Text>
                                                            <Text style={{ ...styles.labelContent, width: "15%" }}>{item.fecha_cambio === "" ? "-" : formatDate(item.fecha_cambio)}</Text>
                                                            <Text style={{ ...styles.labelContent, width: "15%" }}>{item.motivo_descr === "" ? "-" : item.motivo_descr}</Text>
                                                            <Text style={{ ...styles.labelContent, width: "15%" }}>{item.salario_base === "" ? "-" : item.salario_base}</Text>
                                                            <Text style={{ ...styles.labelContent, width: "15%" }}>{item.perc_cambio === "" ? "-" : item.perc_cambio}</Text>
                                                        </View>
                                                    )
                                                })
                                                :
                                                <View style={styles.tableRow} key={"dt0"}>
                                                    <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_RENTA_LIST.anio === "" ? "-" : colaborador.CN_RENTA_LIST.anio}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_RENTA_LIST.rut === "" ? "-" : colaborador.CN_RENTA_LIST.rut}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_RENTA_LIST.nombre === "" ? "-" : colaborador.CN_RENTA_LIST.nombre}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_RENTA_LIST.fecha_cambio === "" ? "-" : formatDate(colaborador.CN_RENTA_LIST.fecha_cambio)}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_RENTA_LIST.motivo_descr === "" ? "-" : colaborador.CN_RENTA_LIST.motivo_descr}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_RENTA_LIST.salario_base === "" ? "-" : colaborador.CN_RENTA_LIST.salario_base}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_RENTA_LIST.perc_cambio === "" ? "-" : colaborador.CN_RENTA_LIST.perc_cambio}</Text>
                                                </View>
                                            :
                                            <View style={styles.tableRow}>
                                                <Text style={styles.labelContent}>Sin Registros</Text>
                                            </View>
                                    }
                                </View>
                            </View>
                        </View>
                    </View>

                </Page>
            </Document>

        )
    }


    return (

        <>
            {!loadingColaborador ?

                <>
                    {/*  <PDFDownloadLink
                        className="text-decoration-none d-inline-block mb-3 mt-4 color-orange"
                        fileName="Cambios_de_Renta"
                        document={<FichaCambiosDeRentaPDF colaborador={colaborador} />}
                    >
                        <i className="fa fa-file-pdf-o me-1" aria-hidden="true"></i>
                        Descargar
                    </PDFDownloadLink> */}


                    <div className="col-12 col-xl-12 d-flex">
                        <div className="box align-items-stretch w-100">
                            <h2 className="h4 tit-section">Cambios de Renta</h2>
                            <table className="table table-even table-section table-borderless text-uppercase d-none d-md-table">
                                <thead>
                                    <tr>
                                        <th>Año</th>
                                        <th>Fecha cambio</th>
                                        <th>Motivo</th>
                                        <th>Sal. Base</th>
                                        <th>% Cambio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {colaborador.CN_RENTA_LIST !== undefined ?
                                        Array.isArray(colaborador.CN_RENTA_LIST) ? (
                                            Children.toArray(colaborador.CN_RENTA_LIST.map((item) => {
                                                return (
                                                    <tr>
                                                        <td>{hiddenTd ? "****" : item.anio === "" ? "-" : item.anio}</td>
                                                        <td>{hiddenTd ? "****" : item.fecha_cambio === "" ? "-" : formatDate(item.fecha_cambio)}</td>
                                                        <td>{hiddenTd ? "****" : item.motivo_descr === "" ? "-" : item.motivo_descr}</td>
                                                        <td>{hiddenTd ? "****" : item.salario_base === "" ? "-" : dotNumber(item.salario_base)}</td>
                                                        <td>{hiddenTd ? "****" : item.perc_cambio === "" ? "-" : dotPerc(item.perc_cambio)}</td>
                                                    </tr>
                                                );
                                            }))
                                        ) : (
                                            <tr>
                                                <td>{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.anio === "" ? "-" : colaborador.CN_RENTA_LIST.anio}</td>
                                                <td>{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.fecha_cambio === "" ? "-" : formatDate(colaborador.CN_RENTA_LIST.fecha_cambio)}</td>
                                                <td>{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.motivo_descr === "" ? "-" : colaborador.CN_RENTA_LIST.motivo_descr}</td>
                                                <td>{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.salario_base === "" ? "-" : dotNumber(colaborador.CN_RENTA_LIST.salario_base)}</td>
                                                <td>{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.perc_cambio === "" ? "-" : dotPerc(colaborador.CN_RENTA_LIST.perc_cambio)}</td>
                                            </tr>

                                        )
                                        :
                                        <tr>
                                            <td colSpan="7">No hay datos</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
                                {colaborador.CN_RENTA_LIST !== undefined ? (
                                    Array.isArray(colaborador.CN_RENTA_LIST) ? (
                                        Children.toArray(Object.keys(cambioRentaporAno).reverse().map((year, yearIndex) => {
                                            return (
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id={"accordion-" + yearIndex}>
                                                        <button className={`accordion-button ${yearIndex === 0 ? " " : "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target={"#accordion-item-cambios-renta-" + yearIndex} aria-expanded={yearIndex === 0} aria-controls={"accordion-item-cambios-renta-" + yearIndex}>
                                                            <strong className="text-uppercase">Año</strong> {year}
                                                        </button>
                                                    </h2>
                                                    {
                                                        Children.toArray(cambioRentaporAno[year].map((item) => {
                                                            return (
                                                                <div id={"accordion-item-cambios-renta-" + yearIndex} className={`accordion-collapse collapse ${yearIndex === 0 ? "show" : " "}`} aria-labelledby={"accordion-" + yearIndex}>
                                                                    <div className="accordion-body p-0">
                                                                        <div className="data-list-box data-list-box text-uppercase">
                                                                            <ul className="data-list-section--item list-unstyled">
                                                                                <li>
                                                                                    <span className="data-list-box__title">Año</span>
                                                                                    <span className="data-list-box__text">{hiddenTd ? "****" : item.anio === "" ? "-" : item.anio}</span>
                                                                                </li>
                                                                                <li>
                                                                                    <span className="data-list-box__title">Fecha cambio</span>
                                                                                    <span className="data-list-box__text">{hiddenTd ? "****" : item.fecha_cambio === "" ? "-" : formatDate(item.fecha_cambio)}</span>
                                                                                </li>
                                                                                <li>
                                                                                    <span className="data-list-box__title">Motivo</span>
                                                                                    <span className="data-list-box__text">{hiddenTd ? "****" : item.motivo_descr === "" ? "-" : item.motivo_descr}</span>
                                                                                </li>
                                                                                <li>
                                                                                    <span className="data-list-box__title">Sal. Base</span>
                                                                                    <span className="data-list-box__text">{hiddenTd ? "****" : item.salario_base === "" ? "-" : dotNumber(item.salario_base)}</span>
                                                                                </li>
                                                                                <li>
                                                                                    <span className="data-list-box__title">% Cambio</span>
                                                                                    <span className="data-list-box__text">{hiddenTd ? "****" : item.perc_cambio === "" ? "-" : dotPerc(item.perc_cambio)}</span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )

                                                        }))
                                                    }
                                                </div>
                                            );
                                        }))
                                    ) : (
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id={"accordion-" + colaborador.CN_RENTA_LIST.anio}>
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#accordion-item-cambios-renta-" + colaborador.CN_RENTA_LIST.anio} aria-expanded="true" aria-controls={"accordion-item-cambios-renta-" + colaborador.CN_RENTA_LIST.anio}>
                                                    <strong className="text-uppercase">Año</strong> {colaborador.CN_RENTA_LIST.anio}
                                                </button>
                                            </h2>
                                            <div id={"accordion-item-cambios-renta-" + colaborador.CN_RENTA_LIST.anio} className="accordion-collapse collapse show" aria-labelledby={"accordion-" + colaborador.CN_RENTA_LIST.anio}>
                                                <div className="accordion-body p-0">
                                                    <div className="data-list-box data-list-box text-uppercase">
                                                        <ul className="data-list-section--item list-unstyled">
                                                            <li>
                                                                <span className="data-list-box__title">Año</span>
                                                                <span className="data-list-box__text">{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.anio === "" ? "-" : colaborador.CN_RENTA_LIST.anio}</span>
                                                            </li>
                                                            <li>
                                                                <span className="data-list-box__title">Fecha cambio</span>
                                                                <span className="data-list-box__text">{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.fecha_cambio === "" ? "-" : formatDate(colaborador.CN_RENTA_LIST.fecha_cambio)}</span>
                                                            </li>
                                                            <li>
                                                                <span className="data-list-box__title">Motivo</span>
                                                                <span className="data-list-box__text">{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.motivo_descr === "" ? "-" : colaborador.CN_RENTA_LIST.motivo_descr}</span>
                                                            </li>
                                                            <li>
                                                                <span className="data-list-box__title">Sal. Base</span>
                                                                <span className="data-list-box__text">{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.salario_base === "" ? "-" : dotNumber(colaborador.CN_RENTA_LIST.salario_base)}</span>
                                                            </li>
                                                            <li>
                                                                <span className="data-list-box__title">% Cambio</span>
                                                                <span className="data-list-box__text">{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.perc_cambio === "" ? "-" : dotPerc(colaborador.CN_RENTA_LIST.perc_cambio)}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                ) : (
                                    <p>{"Sin Registros"}</p>
                                )}
                            </div>
                        </div>
                    </div>

                </> : null}
        </>);
};


export default CambiosDeRenta;