import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import RegistrosEmpleado from "./registros";
import SolicitudForm from "./solicitudForm";
import EsquemaHibridoService from "../../services/esquemaHibrido/esquema-hibrido-service";
import errorMessage from "../SharedComponents/error-message";
import styles from "../../resources/css/style-COL.module.css";

export default function EsquemaHibridoSolicitud(){
    const [registros, setRegistros] = useState([])
    const [loadingRegistros, setLoadingRegistros] = useState(true)
    const [errorRegistros, setErrorRegistros] = useState(false)
    const [info, setInfo] = useState()
    const [loadingInfo, setLoadingInfo] = useState(true)
    const [errorInfo, setErrorInfo] = useState(false)

    const getRegistros = async () => {
        try {
            let response = await EsquemaHibridoService.getRegistros();
            if (
                response !== null &&
                response !== undefined &&
                (response?.statusCode === 200 || response?.status === 200)
            ) {
                setRegistros(response?.data.sort((a, b) => {
                    if (!a.idSolicitud) return 1; // Mueve las entradas sin fecha al final.
                    if (!b.idSolicitud) return -1; // Mueve las entradas sin fecha al final.

                    return b.idSolicitud - a.idSolicitud
                }));
                setLoadingRegistros(false);
            } else {
                setErrorRegistros(true)
                setLoadingRegistros(false);
            }
    
        } catch (error) {
            setLoadingRegistros(false);
        }
    };

    const getInfoEmpleado = async () => {
        try {
            let response = await EsquemaHibridoService.getInfoEmpleado();
            if (
                response !== null &&
                response !== undefined &&
                (response?.statusCode === 200 || response?.status === 200)
            ) {
                setInfo(response.data);
                setLoadingInfo(false);
            } else {
                setErrorInfo(true)
                setLoadingInfo(false);
            }
    
        } catch (error) {
            setLoadingInfo(false);
        }
    };
    
    useEffect(()=>{
        getRegistros()
        getInfoEmpleado()
    },[])

    const resetValues = () => {
        getRegistros()
    }

    return(
        <div>
        {/* <!-- inicio breadcrumbs -->   */}
        <nav className={`${styles["breadcrumbs"]} ${styles["d-none"]} ${styles["d-lg-block"]}`} aria-label="breadcrumb">
            <ol className={styles["breadcrumb"]}>
                <li className={styles["breadcrumb-item"]}><Link to="/inicio"><i className={`${styles["fa"]} ${styles["fa-home"]}`} aria-hidden="true"></i> Inicio</Link></li>
                <li className={styles["breadcrumb-item"]}><a href="#">Esquema de Trabajo</a></li>
                <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">Definición de Esquema</li>
            </ol>
        </nav>
        {/* <!-- fin breadcrumbs --> */}
        <section className={styles["adhesion-esquema-hibrido"]}>
            <SolicitudForm info={info} loadingInfo={loadingInfo} errorInfo={errorInfo} resetValues={resetValues} registros={registros}/>
            <RegistrosEmpleado registros={registros} loadingRegistros={loadingRegistros} errorRegistros={errorRegistros} tipos={info?.tipoEsquema} loadingTipos={loadingInfo} resetValues={resetValues}/>
        </section>
        </div>
    )
}