import React, { Children } from 'react';
import { Text, Image, StyleSheet, View, Page, Document, PDFDownloadLink } from "@react-pdf/renderer";
import { formatDate } from "../../../services/utiles";

const HistorialPosicionesUbicaciones = ({
    data,
    loadingData
}) => {
    const styles = StyleSheet.create({
        page: {
            backgroundColor: '#fff',
            padding: '20px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        card: {
            padding: "20px",
            borderRadius: "10px",
            width: "48%",
            border: "1px solid #eeeeee",
            margin: " 0px 5px 20px 5px",
            height: "auto",
        },
        head: {
            display: "flex",
            marginBottom: "20px",
            paddingLeft: "10px",
        },
        body: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px"
        },
        h1: {
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "left",
            marginBottom: "10px",
            color: "#0169b5"
        },
        table: {
            width: "100%",
            borderCollapse: "collapse",
            borderSpacing: "0",
            marginBottom: "20px"
        },
        tableHead: {
            backgroundColor: "#eeeeee",
            borderBottom: "1px solid #eeeeee",
            borderTop: "1px solid #eeeeee",
            borderLeft: "1px solid #eeeeee",
            borderRight: "1px solid #eeeeee",
            padding: "5px",
            fontSize: "8px",
        },
        tableBody: {
            borderBottom: "1px solid #eeeeee",
            borderLeft: "1px solid #eeeeee",
            borderRight: "1px solid #eeeeee",
            fontSize: "8px",
            textAlign: "center",
            color: "#777777",
        },
        tableRow: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            padding: "5px 5px 5px 5px",
            border: "1px solid #eeeeee",
        },
        labelHead: {
            textAlign: "center",
            color: "#777777",
            fontWeight: "900",
        },
        labelBody: {
            textAlign: "center",
            color: "#777777",
            fontWeight: "normal",
        },
        li: {
            display: "flex",
            flexDirection: "row",
            alignItems: 'flex-start',
            padding: "10px",
            borderBottom: "1px solid #eeeeee",
            position: "relative",
            justifyContent: "flex-start"
        },
        liTitle: {
            width: "50%",
        },
        liContent: {
            width: "50%",
        },
        titleLabel: {
            fontSize: "8px",
            fontWeight: "bold",
            color: "#777777"
        },
        contentLabel: {
            fontSize: "8px",
            fontWeight: "normal",
            color: "#777777"
        },
        foto: {
            width: "100px",
            height: "100px",
        },
        checkimg: {
            width: "20px",
            height: "20px",
        },
    });

    const HistorialPosicionesUbicacionesPDF = () => {
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    {/* Historial de posiciones */}
                    <View style={{ ...styles.card, width: "100%" }}>
                        <View style={styles.head}>
                            <Text style={styles.h1}>Historial de Posiciones</Text>
                        </View>
                        <View style={styles.body}>
                            <View style={styles.table}>
                                <View style={styles.tableHead}>
                                    <View style={styles.tableRow}>
                                        <Text style={{ ...styles.labelHead, width: "20%" }}>UBICACIÓN</Text>
                                        <Text style={{ ...styles.labelHead, width: "20%" }}>CARGO</Text>
                                        <Text style={{ ...styles.labelHead, width: "20%" }}>DESDE</Text>
                                        <Text style={{ ...styles.labelHead, width: "20%" }}>HASTA</Text>
                                        <Text style={{ ...styles.labelHead, width: "20%" }}>AÑOS TOTAL</Text>
                                    </View>
                                </View>
                                <View style={styles.tableBody}>
                                    {
                                        data.CN_HISTPOS_LIST !== undefined ?
                                            data.CN_HISTPOS_LIST.map((item) => {
                                                return (
                                                    <View style={styles.tableRow} >
                                                        <Text style={{ ...styles.labelContent, width: "20%" }}>{item.ubicacion === "" ? "-" : item.ubicacion}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "20%" }}>{item.cargo === "" ? "-" : item.cargo}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "20%" }}>{item.desde === "" ? "-" : formatDate(item.desde)}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "20%" }}>{item.hasta === "" ? "-" : formatDate(item.hasta)}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "20%" }}>{item.total_anios === "" ? "-" : item.total_anios}</Text>
                                                    </View>
                                                )
                                            })
                                            :
                                            <View style={styles.tableRow}>
                                                <Text style={styles.labelContent}>Sin Registros</Text>
                                            </View>
                                    }
                                </View>
                            </View>
                        </View>
                    </View>
                    {/* Historial de ubicaciones */}
                    <View style={{ ...styles.card, width: "100%" }}>
                        <View style={styles.head}>
                            <Text style={styles.h1}>Historial de Ubicaciones</Text>
                        </View>
                        <View style={styles.body}>
                            <View style={styles.table}>
                                <View style={styles.tableHead}>
                                    <View style={styles.tableRow}>
                                        <Text style={{ ...styles.labelHead, width: "20%" }}>UBICACIÓN</Text>
                                        <Text style={{ ...styles.labelHead, width: "20%" }}>CARGO</Text>
                                        <Text style={{ ...styles.labelHead, width: "20%" }}>DESDE</Text>
                                        <Text style={{ ...styles.labelHead, width: "20%" }}>HASTA</Text>
                                        <Text style={{ ...styles.labelHead, width: "20%" }}>AÑOS TOTAL</Text>
                                    </View>
                                </View>
                                <View style={styles.tableBody}>
                                    {
                                        data.CN_HISTUBI_LIST !== undefined ?
                                            data.CN_HISTUBI_LIST.map((item) => {
                                                return (
                                                    <View style={styles.tableRow} >
                                                        <Text style={{ ...styles.labelContent, width: "20%" }}>{item.ubicacion === "" ? "-" : item.ubicacion}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "20%" }}>{item.cargo === "" ? "-" : item.cargo}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "20%" }}>{item.desde === "" ? "-" : formatDate(item.desde)}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "20%" }}>{item.hasta === "" ? "-" : formatDate(item.hasta)}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "20%" }}>{item.total_anios === "" ? "-" : item.total_anios}</Text>
                                                    </View>
                                                )
                                            })
                                            :
                                            <View style={styles.tableRow}>
                                                <Text style={styles.labelContent}>Sin Registros</Text>
                                            </View>
                                    }
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        )
    }

    return (
        <>

            {!loadingData ? (<>


                {/*    <PDFDownloadLink
                    className="text-decoration-none d-inline-block mb-3 mt-4 color-orange"
                    fileName="historial_posiciones_ubicaciones"
                    document={<HistorialPosicionesUbicacionesPDF />}
                >
                    <i className="fa fa-file-pdf-o me-1" aria-hidden="true"></i>
                    Descargar
                </PDFDownloadLink> */}

                <div className="col-12 col-xl-12 d-flex">
                    <div className="box align-items-stretch w-100">
                        <ul className="nav nav-tabs text-uppercase" role="tablist">
                            <li className="nav-item w-50" role="presentation">
                                <button className="nav-link w-100 active" id="historial-posiciones-tab" data-bs-toggle="tab" data-bs-target="#historial-posiciones" type="button" role="tab" aria-controls="historial-posiciones" aria-selected="true">
                                    Historial de Posiciones
                                </button>
                            </li>
                            <li className="nav-item w-50" role="presentation">
                                <button className="nav-link w-100" id="historial-ubicaciones-tab" data-bs-toggle="tab" data-bs-target="#historial-ubicaciones" type="button" role="tab" aria-controls="historial-ubicaciones" aria-selected="false">
                                    Historial de Ubicaciones
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane fade show pt-4 active" id="historial-posiciones" role="tabpanel" aria-labelledby="historial-posiciones-tab">
                                <h2 className="h4 tit-section">Historial de Posiciones</h2>
                                <table className="table table-even table-section table-borderless text-uppercase d-none d-md-table">
                                    <thead>
                                        <tr>
                                            <th className="col-2">Ubicación</th>
                                            <th className="col-4">Cargo</th>
                                            <th className="col-2">Desde</th>
                                            <th className="col-2">Hasta</th>
                                            <th className="col-2">Años total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.CN_HISTPOS_LIST !== undefined ? (
                                            Array.isArray(data.CN_HISTPOS_LIST) ? (
                                                Children.toArray(data.CN_HISTPOS_LIST.map((item) => {
                                                    return (
                                                        <tr >
                                                            <td>{item.ubicacion === "" ? "-" : item.ubicacion}</td>
                                                            <td>{item.cargo === "" ? "-" : item.cargo}</td>
                                                            <td>{item.desde === "" ? "-" : formatDate(item.desde)}</td>
                                                            <td>{item.hasta === "" ? "-" : formatDate(item.hasta)}</td>
                                                            <td>{item.total_anios === "" ? "-" : item.total_anios}</td>
                                                        </tr>
                                                    );
                                                }))
                                            ) : (
                                                <tr>
                                                    <td>{data.CN_HISTPOS_LIST.ubicacion === "" ? "-" : data.CN_HISTPOS_LIST.ubicacion}</td>
                                                    <td>{data.CN_HISTPOS_LIST.cargo === "" ? "-" : data.CN_HISTPOS_LIST.cargo}</td>
                                                    <td>{data.CN_HISTPOS_LIST.desde === "" ? "-" : formatDate(data.CN_HISTPOS_LIST.desde)}</td>
                                                    <td>{data.CN_HISTPOS_LIST.hasta === "" ? "-" : formatDate(data.CN_HISTPOS_LIST.hasta)}</td>
                                                    <td>{data.CN_HISTPOS_LIST.total_anios === "" ? "-" : data.CN_HISTPOS_LIST.total_anios}</td>
                                                </tr>
                                            )
                                        ) : (
                                            <tr>
                                                <td colSpan="3">No hay datos</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <div className="data-list-box data-list-box-sa text-uppercase d-md-none">
                                    {data?.CN_HISTPOS_LIST !== undefined ? (
                                        Array.isArray(data.CN_HISTPOS_LIST) ? (
                                            Children.toArray(data.CN_HISTPOS_LIST.map((item) => {
                                                return (
                                                    <ul className="data-list-section--item list-unstyled" >
                                                        <li>
                                                            <span className="data-list-box__title">Ubicación</span>
                                                            <span className="data-list-box__text">{item.ubicacion === "" ? "-" : item.ubicacion}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Cargo</span>
                                                            <span className="data-list-box__text">{item.cargo === "" ? "-" : item.cargo}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Desde</span>
                                                            <span className="data-list-box__text">{item.desde === "" ? "-" : formatDate(item.desde)}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Hasta</span>
                                                            <span className="data-list-box__text">{item.hasta === "" ? "-" : formatDate(item.hasta)}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Años total</span>
                                                            <span className="data-list-box__text">{item.total_anios === "" ? "-" : item.total_anios}</span>
                                                        </li>
                                                    </ul>
                                                )
                                            }))
                                        ) : (
                                            <ul className="data-list-section--item list-unstyled">
                                                <li>
                                                    <span className="data-list-box__title">Ubicación</span>
                                                    <span className="data-list-box__text">{data.CN_HISTPOS_LIST.ubicacion === "" ? "-" : data.CN_HISTPOS_LIST.ubicacion}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Cargo</span>
                                                    <span className="data-list-box__text">{data.CN_HISTPOS_LIST.cargo === "" ? "-" : data.CN_HISTPOS_LIST.cargo}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Desde</span>
                                                    <span className="data-list-box__text">{data.CN_HISTPOS_LIST.desde === "" ? "-" : formatDate(data.CN_HISTPOS_LIST.desde)}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Hasta</span>
                                                    <span className="data-list-box__text">{data.CN_HISTPOS_LIST.hasta === "" ? "-" : formatDate(data.CN_HISTPOS_LIST.hasta)}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Años total</span>
                                                    <span className="data-list-box__text">{data.CN_HISTPOS_LIST.total_anios === "" ? "-" : data.CN_HISTPOS_LIST.total_anios}</span>
                                                </li>
                                            </ul>
                                        )
                                    ) : (
                                        <li>
                                            <span className="data-list-box__title">No hay datos</span>
                                        </li>
                                    )}
                                </div>
                            </div>
                            <div className="tab-pane pt-4 fade" id="historial-ubicaciones" role="tabpanel" aria-labelledby="historial-ubicaciones-tab">
                                <h2 className="h4 tit-section">Historial de Ubicaciones</h2>
                                <table className="table table-even table-section table-borderless text-uppercase d-none d-md-table">
                                    <thead>
                                        <tr>
                                            <th className="col-2">Ubicación</th>
                                            <th className="col-4">Cargo</th>
                                            <th className="col-2">Desde</th>
                                            <th className="col-2">Hasta</th>
                                            <th className="col-2">Años total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.CN_HISTUBI_LIST !== undefined ? (
                                            Array.isArray(data.CN_HISTUBI_LIST) ? (
                                                Children.toArray(data.CN_HISTUBI_LIST.map((item) => {
                                                    return (
                                                        <tr >
                                                            <td>{item.ubicacion === "" ? "-" : item.ubicacion}</td>
                                                            <td>{item.cargo === "" ? "-" : item.cargo}</td>
                                                            <td>{item.desde === "" ? "-" : formatDate(item.desde)}</td>
                                                            <td>{item.hasta === "" ? "-" : formatDate(item.hasta)}</td>
                                                            <td>{item.total_anios === "" ? "-" : item.total_anios}</td>
                                                        </tr>
                                                    );
                                                }))
                                            ) : (
                                                <tr>
                                                    <td>{data.CN_HISTUBI_LIST.ubicacion === "" ? "-" : data.CN_HISTUBI_LIST.ubicacion}</td>
                                                    <td>{data.CN_HISTUBI_LIST.cargo === "" ? "-" : data.CN_HISTUBI_LIST.cargo}</td>
                                                    <td>{data.CN_HISTUBI_LIST.desde === "" ? "-" : formatDate(data.CN_HISTUBI_LIST.desde)}</td>
                                                    <td>{data.CN_HISTUBI_LIST.hasta === "" ? "-" : formatDate(data.CN_HISTUBI_LIST.hasta)}</td>
                                                    <td>{data.CN_HISTUBI_LIST.total_anios === "" ? "-" : data.CN_HISTUBI_LIST.total_anios}</td>
                                                </tr>
                                            )
                                        ) : (
                                            <tr>
                                                <td colSpan="3">No hay datos</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <div className="data-list-box data-list-box-sa text-uppercase d-md-none">
                                    {data?.CN_HISTUBI_LIST !== undefined ? (
                                        Array.isArray(data.CN_HISTUBI_LIST) ? (
                                            Children.toArray(data.CN_HISTUBI_LIST.map((item) => {
                                                return (
                                                    <ul className="data-list-section--item list-unstyled" >
                                                        <li>
                                                            <span className="data-list-box__title">Ubicación</span>
                                                            <span className="data-list-box__text">{item.ubicacion === "" ? "-" : item.ubicacion}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Cargo</span>
                                                            <span className="data-list-box__text">{item.cargo === "" ? "-" : item.cargo}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Desde</span>
                                                            <span className="data-list-box__text">{item.desde === "" ? "-" : formatDate(item.desde)}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Hasta</span>
                                                            <span className="data-list-box__text">{item.hasta === "" ? "-" : formatDate(item.hasta)}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Años total</span>
                                                            <span className="data-list-box__text">{item.total_anios === "" ? "-" : item.total_anios}</span>
                                                        </li>
                                                    </ul>
                                                )
                                            }))
                                        ) : (
                                            <ul className="data-list-section--item list-unstyled">
                                                <li>
                                                    <span className="data-list-box__title">Ubicación</span>
                                                    <span className="data-list-box__text">{data.CN_HISTUBI_LIST.ubicacion === "" ? "-" : data.CN_HISTUBI_LIST.ubicacion}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Cargo</span>
                                                    <span className="data-list-box__text">{data.CN_HISTUBI_LIST.cargo === "" ? "-" : data.CN_HISTUBI_LIST.cargo}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Desde</span>
                                                    <span className="data-list-box__text">{data.CN_HISTUBI_LIST.desde === "" ? "-" : formatDate(data.CN_HISTUBI_LIST.desde)}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Hasta</span>
                                                    <span className="data-list-box__text">{data.CN_HISTUBI_LIST.hasta === "" ? "-" : formatDate(data.CN_HISTUBI_LIST.hasta)}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Años total</span>
                                                    <span className="data-list-box__text">{data.CN_HISTUBI_LIST.total_anios === "" ? "-" : data.CN_HISTUBI_LIST.total_anios}</span>
                                                </li>
                                            </ul>
                                        )
                                    ) : (
                                        <li>
                                            <span className="data-list-box__title">No hay datos</span>
                                        </li>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>) : null}
        </>
    );
};

export default HistorialPosicionesUbicaciones;