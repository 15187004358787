import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import datosLaboralesService from "../../services/datos-laborales-service";
//import "bootstrap/dist/css/bootstrap.css";
import tiempoService from "../../services/tiempo-service";
import CarrouselTiempo from "../elements/tiempo-carrousel";
import SaldoTiempo from "../elements/tiempo-saldo";

const TiempoCanjear = () => {
  let navigate = useNavigate();
  const [emplid, setEmplid] = useState(localStorage.getItem("emplid"));
  const app_id = "221052ea-b3d1-4f8d-bf37-e37647414596";
  const [dataBeneficios, setDataBeneficios] = useState({});
  const [errorBeneficios, setErrorBeneficios] = useState(false);
  const [listadoBeneficios, setListadoBeneficios] = useState(null);
  const [beneficioSeleccionado, setBeneficioSeleccionado] = useState(null);
  const [estadoBeneficio, setEstadoBeneficio] = useState(null);
  const [desactivarDia, setDesactivarDia] = useState(false);
  const [solicitudResponse, setSolicitudResponse] = useState(null);
  const [desactivarBoton, setDesactivarBoton] = useState(true);
  const [respuestaSolicitudValid, setRespuestaSolicitudValid] = useState(false);
  const [respuestaSolicitudText, setRespuestaSolicitudText] = useState("");
  const [seleccionCanje, setSeleccionCanje] = useState(false);
  const [seleccionFecha, setSeleccionFecha] = useState(false);
  const [seleccionJornada, setSeleccionJornada] = useState(false);
  const [idNoticia] = useSearchParams();
  const [valorBeneficioDefault, setBeneficioDefault] = useState(null);
  const [icono, setIcono] = useState(null);

  useEffect(() => {
    if (desactivarDia) {
      if (seleccionFecha) {
        setDesactivarBoton(false);
      }
    } else {
      if (seleccionFecha && seleccionJornada) {
        setDesactivarBoton(false);
      }
    }
    return () => setDesactivarBoton(true)
  }, [seleccionFecha, seleccionJornada, desactivarDia]);

  useEffect(() => {
    tiempoService.getBeneficios(setDataBeneficios);
    return () => setDataBeneficios({});
  }, []);

  const handleClose = () => {
    setErrorBeneficios(false);
    setRespuestaSolicitudValid(false);
    navigate("/tiempo-beneficios");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var jornada = document.getElementById("jornada").value;
    var fecha = document.getElementById("fecha").value;
    var beneficio = document.getElementById("beneficio").value;

    if (emplid && beneficio && fecha) {
      tiempoService.solicitarBeneficio(
        emplid,
        beneficio,
        fecha,
        jornada,
        setSolicitudResponse
      );
      setDesactivarBoton(true);
    }
  };

  useEffect(() => {
    if (solicitudResponse) {
      if (solicitudResponse !== 2) {
        if (solicitudResponse.valid) {
          if (solicitudResponse.valid === "0") {
            setRespuestaSolicitudText(solicitudResponse.status);
            setIcono(
              <i
                className="fa fa-times-circle-o color-orange fa-3x"
                aria-hidden="true"
              ></i>
            );
          } else {
            setRespuestaSolicitudText("Solicitud enviada correctamente.");
            setIcono(
              <i
                className="fa fa-check-circle-o color-orange fa-3x"
                aria-hidden="true"
              ></i>
            );
          }
          setRespuestaSolicitudValid(true);
        } else {
          setErrorBeneficios(true);
        }
      } else {
        setErrorBeneficios(true);
      }
      setDesactivarBoton(false);
    }
  }, [solicitudResponse]);

  useEffect(() => {
    if (dataBeneficios === 2) {
      setErrorBeneficios(true);
    } else {
      if (dataBeneficios.response && dataBeneficios.response.valid === "1") {
        setListadoBeneficios(dataBeneficios.response.beneficios);
      } else if (dataBeneficios.response && dataBeneficios.response.valid === "0") {
        setErrorBeneficios(true);
      }
    }
  }, [dataBeneficios]);

  useEffect(() => {
    if (idNoticia.get("id") && idNoticia.get("id") !== null) {
      setBeneficioDefault(parseInt(idNoticia.get("id")));
    }
  }, [idNoticia]);

  useEffect(() => {
    if (beneficioSeleccionado && listadoBeneficios) {
      listadoBeneficios.map((e) => {
        if (beneficioSeleccionado === e.id_beneficio) {
          setEstadoBeneficio(e.id_tiempo);
        }
      });
    }
  }, [beneficioSeleccionado, listadoBeneficios]);

  useEffect(() => {
    if (estadoBeneficio) {
      if (estadoBeneficio === "LONG") {
        setDesactivarDia(true);
      } else {
        setDesactivarDia(false);
      }
    }
  }, [estadoBeneficio]);

  useEffect(() => {
    if (valorBeneficioDefault) {
      setBeneficioSeleccionado(valorBeneficioDefault.toString());
    }
  }, [valorBeneficioDefault]);

  return (
    <>
      {/* <!-- inicio breadcrumbs -->*/}
      <nav className="breadcrumbs" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/inicio">
              <i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true"></i> Inicio
            </Link>
          </li>
          <li className="breadcrumb-item">Mi Tiempo</li>
          <li className="breadcrumb-item active" aria-current="page">
            Canje Cenco Flexible
          </li>
        </ol>
      </nav>
      {/* <!-- fin breadcrumbs -->*/}

      {/* <!-- inicio Mi Tiempo -->*/}
      <section className="mi-tiempo">
        <div className="tit-group d-block d-lg-flex justify-content-between align-items-center mb-4">
          <h1 className="mb-3 mb-lg-0">Mi Tiempo</h1>

          <SaldoTiempo></SaldoTiempo>
        </div>

        <CarrouselTiempo tituloSelect="Canjear"></CarrouselTiempo>

        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="box mb-4">
            <h2 className="h4 tit-section">Canjear</h2>
            <div className="data-list-box data-list-box-even data-list-box-align-middle data-list-box-form">
              <ul className="list-column-1 list-unstyled">
                <li>
                  <span className="data-list-box__title text-uppercase">
                    Seleccionar Canje *
                  </span>
                  <span className="data-list-box__text">
                    <select
                      onChange={(e) => {
                        setBeneficioSeleccionado(e.target.value);
                        setSeleccionCanje(true);
                      }}
                      id="beneficio"
                      className="form-select"
                      required
                    >
                      {valorBeneficioDefault ? (
                        <>
                          <option disabled value="">
                            Seleccione
                          </option>
                          {listadoBeneficios ? (
                            listadoBeneficios.map((e) => {
                              if (
                                parseInt(e.id_beneficio) ===
                                parseInt(valorBeneficioDefault)
                              ) {
                                return (
                                  <option selected value={"" + e.id_beneficio}>
                                    {e.titulo}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={"" + e.id_beneficio}>
                                    {e.titulo}
                                  </option>
                                );
                              }
                            })
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <>
                          <option selected disabled value="">
                            Seleccione
                          </option>
                          {listadoBeneficios ? (
                            listadoBeneficios.map((e, index) => {
                              return (
                                <option
                                  key={"array11" + index}
                                  value={"" + e.id_beneficio}
                                >
                                  {e.titulo}
                                </option>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </select>
                    <div className="invalid-feedback">
                      Debe seleccionar un campo
                    </div>
                  </span>
                </li>
                <li>
                  <span className="data-list-box__title text-uppercase">
                    Fecha *
                  </span>
                  <span className="data-list-box__text">
                    <div className="row">
                      <div className="col-10">
                        <input
                          onChange={() => {
                            setSeleccionFecha(true);
                          }}
                          className="form-control"
                          id="fecha"
                          type="date"
                          placeholder="DD/MM/AAAA"
                          required
                        />
                        <div className="invalid-feedback">
                          Debe seleccionar una fecha
                        </div>
                      </div>
                      <div className="col-auto">
                        <i
                          className="fa fa-calendar fa-fw mt-2 fa-lg color-primary"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <span className="data-list-box__title text-uppercase">
                    Jornada *
                  </span>
                  <span className="data-list-box__text">
                    {desactivarDia ? (
                      <input
                        type="text"
                        class="form-control"
                        disabled={desactivarDia}
                        id="jornada"
                        value=""
                        placeholder="Día completo "
                      />
                    ) : (
                      <select
                        id="jornada"
                        onChange={(e) => {
                          e.target.value === ""
                            ? setSeleccionJornada(false)
                            : setSeleccionJornada(true);
                        }}
                        disabled={desactivarDia}
                        className="form-select"
                        required
                      >
                        <option selected disabled value="">
                          Seleccione
                        </option>
                        <option>AM</option>
                        <option>PM</option>
                      </select>
                    )}

                    <div className="invalid-feedback">
                      Debe seleccionar un campo
                    </div>
                  </span>
                </li>
              </ul>
              <small>* Campo obligatorio</small>
            </div>
          </div>

          <div className="text-end d-grid d-md-block">
            <button
              disabled={desactivarBoton}
              type="submit"
              className="btn btn-degradado"
              data-bs-toggle="modal"
              data-bs-target="#success"
            >
              Enviar{" "}
              <i
                className="fa fa-chevron-right fa-fw fa-xs"
                aria-hidden="true"
              ></i>
            </button>
          </div>
        </form>
      </section>

      {errorBeneficios ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => handleClose(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-download color-orange fa-3x mb-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">Canjear beneficios</h2>
                    <p>
                      Se ha producido un error de comunicación, vuelva a
                      intentarlo más tarde.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {respuestaSolicitudValid ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => handleClose(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    {icono}
                    <h2 className="h5">Solicitud beneficios.</h2>
                    <p>{respuestaSolicitudText}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
export default TiempoCanjear;
