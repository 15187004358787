import { Outlet, useNavigate } from "react-router-dom";
import FirstLoginService from "../../services/firstlogin-service";
import Auth from "@aws-amplify/auth";


const signIn = async (username, password) => {
    const currentConfig = Auth.configure();
    const { domain, redirectSignIn, redirectSignOut, responseType, scope } = currentConfig.oauth;
    const clientId = config.AWS_COGNITO_CLIENT_ID;
    const url = `https://${domain}/oauth2/authorize?identity_provider=${config.AWS_COGNITO_IDP_NAME}&redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${clientId}`;
    window.location.assign(url);
};


const RequireFirstLogin = ({ }) => {

    const navigate = useNavigate();


    Auth.currentAuthenticatedUser().then(() => {

        FirstLoginService.validateLogin().then(response => {


            const loginData = response;
            (loginData.loginstatus == 0) ? navigate("/terminosycondiciones") : null;
            (loginData.loginstatus == 1) ? navigate("/maildeactivacion") : null;
            if (loginData.loginstatus > 1) {
                return <Outlet />
            } else {
                return null;
            }



        })

    }).catch(() => {
        signIn();

    })

    return <Outlet />
}


export default RequireFirstLogin;
