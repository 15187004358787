import moment from "moment";

export default function getWeeklyCalendar(value) {
    const val = moment(value)
    const startDay = val?.clone().startOf("week");
    const day = startDay?.clone().subtract(1, "day");
    const endDay = startDay?.clone().add(6, "day");
    const calendar = [];

    while (day?.isBefore(endDay, "day")) {
        calendar.push(day?.add(1, "day").clone())
    }
    return calendar
}
