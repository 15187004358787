export const toastOptions = {
  green: 'success' as 'success',
  yellow: 'warning' as 'warning',
  blue: 'info' as 'info',
  red: 'error' as 'error',
  verticalTop: 'top' as 'top',
  verticalBottom: 'bottom' as 'bottom',
  horizontalCenter: 'center' as 'center',
  horizontalLeft: 'left' as 'left',
  horizontalRight: 'right' as 'right'
}