import React, { useEffect, useState } from 'react'
import { HighLighterProps } from './interfaces'
import style from './style.module.css'

const Highlighter = ({width}: HighLighterProps) => {
  const defaultWidth = 35
  const [totalWidth, setTotalWidth ] = useState(defaultWidth)

  useEffect(() =>{
    if(!width) return
    setTotalWidth(width)
}, [width]);

  return (
    <div>
      <span data-testid='highlighter' className={style.highlighter} style={{width: totalWidth + 'px'}}></span>
    </div>
  );
}

export default Highlighter;