import React from 'react';
import { Article } from "../../../../utilities/interfaces";
import SearchIcon from '@mui/icons-material/Search';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import { paths } from '../../../../utilities/paths';
import { SearchContent } from '../../../../utilities/constantsTexts';
import { FailProps } from './interfaces';
import textstyle from '../../../styles/textStyles.module.css'
import searchStyle from '../../styles/searchStyle.module.css'

const FailedSearch = ({ options }: FailProps) => {
  const content = SearchContent.failedSearch
  const navigate = useNavigate()

  const navigateToArticle = (value: Article) => {
    navigate(paths[3].path + value?.attributes.slug + '/' + value?.id)
  }

  return (
    <React.Fragment>
      <Grid className={searchStyle.searcher_fail_text_wrapper}>
        <SearchIcon className={searchStyle.search_icon_main} />
        <Grid item className={searchStyle.search_text_column}>
          <p className={`${textstyle.content_subtitle} ${searchStyle.main_text}`}>
            {content.mainText}
          </p>
          <p className={searchStyle.suggestions}>
            {content.suggestions}
          </p>
        </Grid>
      </Grid>
      <Grid className={searchStyle.searcher_fail_options_wrapper}>
        <p className={`${textstyle.content_subtitle} ${searchStyle.recomendations}`}>
          {content.recomendations}
        </p>
        <Grid item className={searchStyle.searcher_fail_options}>
          {options.slice(1, 6).map((option, index) => (
            <div key={index} onClick={() => navigateToArticle(option)}>
              <SearchIcon
                className={`${searchStyle.search_icon}
                ${searchStyle.label_icon}`}
              />
              <span
                className={searchStyle.option_text}>
                {option.attributes.title}
              </span>
            </div>
          ))}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default FailedSearch