import React, { useEffect, useState } from 'react'
import { BsExclamationCircle } from "react-icons/bs";
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import IdiomasService from '../../services/idiomas/idiomas-service';
import styles from "../../resources/css/style-COL.module.css";

function ModificarIdioma({ listLanguages, writeLevel, speakLevel, id, reloadLanguages }) {
    const [infoLanguage, setInfoLanguage] = useState({})
    const [loadingLanguage, setLoadingLanguage] = useState(false)
    const [noDataLanguage, setNoDataLanguage] = useState(false)
    const [editLanguageInfo, setEditLanguageInfo] = useState(false)
    const [loadingEditLanguage, setLoadingEditLanguage] = useState(false)
    const [noDataEdit, setNoDataEdit] = useState(false)
    const [deleteLanguage, setDeleteLanguage] = useState(false)
    const [loadingDeleteLanguage, setLoadingDeleteLanguage] = useState(false)
    const [noDataDelete, setNoDataDelete] = useState(false)
    const [language, setLanguage] = useState(infoLanguage.language)
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        data.language = language
        setLoadingEditLanguage(true)
        IdiomasService.EditLanguage(data, setEditLanguageInfo, setLoadingEditLanguage, setNoDataEdit)
    }
    useEffect(() => {
        if (id !== undefined) {
            setLoadingLanguage(true)
            IdiomasService.GetLanguage(id, setInfoLanguage, setLoadingLanguage, setNoDataLanguage)
        }
    }, [id])
    useEffect(() => {

        setLanguage(infoLanguage?.language)
    }, [infoLanguage])
    const deleteOneLanguage = () => {
        Swal
            .fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: red; font-size: 16px"> Eliminar </div>',
                html: '<div style="text-align: center; font-size: 15px"> ¿Desea continuar la operación? </div>',
                showCancelButton: true,
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                confirmButtonColor: "red",
                cancelButtonColor: "#0169b5"
            }).then((result) => {
                if (result.isConfirmed) {
                    setLoadingDeleteLanguage(true)
                    IdiomasService.DeleteLanguage(id, setDeleteLanguage, setLoadingDeleteLanguage, setNoDataDelete)
                }
            })
    }

    // Mensaje de error al enviar un post
    const handleStatusErrorEdit = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
            html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo.</div>`,
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadLanguages()
        })
        setLoadingEditLanguage(false)
        setNoDataEdit(false)
    }

    // Mensaje de éxito al enviar un post
    const handleStatusSuccessEdit = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
            html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadLanguages()
        })
        setLoadingEditLanguage(false)
        setNoDataEdit(false)
    }

    // Mensaje de error al enviar un post
    const handleStatusErrorDelete = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
            html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo.</div>`,
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadLanguages()
        })
        setLoadingDeleteLanguage(false)
        setNoDataDelete(false)
    }

    // Mensaje de éxito al enviar un post
    const handleStatusSuccessDelete = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
            html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadLanguages()
        })
        setLoadingDeleteLanguage(false)
        setNoDataDelete(false)
    }

    useEffect(() => {
        // noDataAdd ? handleStatusError() : null
        editLanguageInfo ? handleStatusSuccessEdit() : null
    }, [loadingEditLanguage])

    useEffect(() => {
        // noDataAdd ? handleStatusError() : null
        deleteLanguage ? handleStatusSuccessDelete() : null
    }, [loadingDeleteLanguage])
    return (<>
        {loadingLanguage ? <div data-testid="loading" className={styles["full-spinner"]}>
            <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
        </div> : noDataLanguage ? <div className={`${styles["alert"]} ${styles["alert-info"]} ${styles["alert-dismissible"]} ${styles["fade"]} ${styles["show"]} ${styles["text-center"]}`} role="alert">
            <BsExclamationCircle />  No se encontraron datos asociados
        </div> : <>
            {/* <!-- Inicio Ver Idiomas --> */}
            <div className={`${styles["box"]} ${styles["mb-4"]}`}>
                <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Modificar Idiomas</h2>
                <form>
                    {/* <!-- Fila 1 --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-3"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Idioma <span className={styles["color-red"]}>*</span></label>
                            <div className={styles["input-group"]}>
                                <select onChange={e => setLanguage(e.target.value)} name="language" className={styles["select-col"]}>
                                    {listLanguages.map(c => {
                                        if (c.id === infoLanguage.language) {
                                            return (
                                                <option key={c.id} value={c.id} selected>{c.description}</option>
                                            )
                                        } else {
                                            return (
                                                <option key={c.id} value={c.id}>{c.description}</option>
                                            )
                                        }
                                    })}

                                </select>
                                <small className={styles["form-error-message"]}>{errors.language?.message}</small>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Fila 2 --> */}
                    <p><strong>Nivel</strong> <span className={styles["color-red"]}>*</span></p>
                    {/* <!-- Fila 3 --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-10"]} ${styles["col-xl-8"]} ${styles["col-xxl-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Habla</label>
                            <div className={`${styles["row"]} ${styles["mt-2"]}`}>
                                {speakLevel?.sort((a, b) => a.id - b.id).map((s) => {
                                    return (
                                        <div key={s.id} className={`${styles["col-12"]} ${styles["col-md-3"]}`}>
                                            <div className={styles["form-check"]}>
                                                <input className={styles["form-check-input"]} type="radio" value={s.id} name="speaking_level" {...register("speaking_level")} id={s.id} defaultChecked={s.id === infoLanguage?.speaking_level} />
                                                <label className={styles["form-check-label"]} htmlFor={s.id}>{s.description}</label>
                                            </div>
                                        </div>)
                                })}
                            </div>
                        </div>
                    </div>
                    {/* <!-- Fila 4 --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-10"]} ${styles["col-xl-8"]} ${styles["col-xxl-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Escribe</label>
                            <div className={`${styles["row"]} ${styles["mt-2"]}`}>
                                {writeLevel?.sort((a, b) => a.id - b.id).map((s) => {
                                    return (
                                        <div key={s.id} className={`${styles["col-12"]} ${styles["col-md-3"]}`}>
                                            <div className={styles["form-check"]}>
                                                <input className={styles["form-check-input"]} type="radio" value={s.id} name="writing_level" {...register("writing_level")} id={s.id} defaultChecked={s.id === infoLanguage?.writing_level} />
                                                <label className={styles["form-check-label"]} htmlFor={s.id}>{s.description}</label>
                                            </div>
                                        </div>)
                                })}
                            </div>
                        </div>
                    </div>
                    {/* <!-- fin filas --> */}
                </form>

                <div className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mt-4"]}`}>
                    <div className={`${styles["col-12"]} ${styles["col-md-auto"]}`}>
                        <button type="button" className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`} onClick={() => deleteOneLanguage()} disabled={loadingDeleteLanguage} >Eliminar <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i>{loadingDeleteLanguage ? <div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div> : null}</button>
                    </div>
                </div>

            </div>
            {/* <!-- Fin Modificar --> */}

            <div className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mt-4"]}`}>
                <div className={`${styles["col-12"]} ${styles["col-md-auto"]}`}>
                    <button type="button" onClick={handleSubmit(onSubmit)} className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["w-100"]}`} disabled={loadingEditLanguage}>Guardar <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i>{loadingEditLanguage ? <div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div> : null}</button>
                </div>
            </div>
            {noDataEdit ? handleStatusErrorEdit() : null}
            {noDataDelete ? handleStatusErrorDelete() : null}
        </>}</>
    )
}

export default ModificarIdioma;