import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Text, Image, StyleSheet, View, Page, Document, PDFDownloadLink } from "@react-pdf/renderer";
import { Chart as ChartJS, registerables } from "chart.js";
import { Chart } from "react-chartjs-2";
import { groupBy } from '../../../services/utiles';

ChartJS.register(...registerables);
const regresionLineal = (cambioRentaData) => {
    const x = cambioRentaData.map((item, index) => index); //array con valores de x
    const y = cambioRentaData.map((item) => parseInt(item.salario_base)); //aray con valores de y
    const largo = x.length; //tamaño del array
    const sumX = x.reduce((a, b) => a + b, 0); //suma de todos los valores de x
    const sumY = y.reduce((a, b) => a + b, 0); //suma de todos los valores de y
    const xy = y.map((item, index) => item * index); //multiplica cada valor de y por x
    const sumXY = xy.reduce((a, b) => a + b, 0); //suma de todos los valores de xy
    const x2 = x.map((item) => item * item); //array de x elevado al cuadrado
    const sumX2 = x2.reduce((a, b) => a + b, 0); //suma de todos los valores de x elevado al cuadrado
    const m = (largo * sumXY - sumX * sumY) / (largo * sumX2 - sumX * sumX); //calcula la pendiente
    const b = sumY / largo - m * (sumX / largo); //calcula el valor de la ordenada al origen
    const listaRegresion = [];
    for (let i = 0; i < largo; i++) {
        listaRegresion.push(m * i + b); //calcula la regresion lineal
    }
    return listaRegresion;
};


const GraficoCambiosDeRentaRegional = ({
    colaborador,
    loadingColaborador,
    hiddenTd
}) => {
    const [dataGraficoCambiosRenta, setDataGraficoCambiosRenta] = useState(false);
    const graficoRemuneracionesBae64 = document.getElementById("graficoRemuneraciones2") !== null ? document.getElementById("graficoRemuneraciones2").toDataURL() : "";
    const graficoRemuneracionesRef = useRef(null);

    useEffect(() => {
        if (!loadingColaborador) {
            const cambioRentaData = Array.isArray(colaborador.CN_RENTA_LIST) ? colaborador.CN_RENTA_LIST.slice(0, 10).reverse() : colaborador.CN_RENTA_LIST;
            if (cambioRentaData) {
                let dataGraph = {
                    datasets: [
                        {
                            type: "line",
                            label: "Sal Base",
                            yAxisID: "yaxis1",
                            data: Array.isArray(cambioRentaData) ? cambioRentaData.map((item) => item.salario_base) : [cambioRentaData.salario_base],
                            borderColor: "#5071be",
                            backgroundColor: "#5071be",
                            datalabels: {
                                align: "top",
                                anchor: "end",
                                color: "grey",
                            },
                        },
                        {
                            type: "bar",
                            label: "% Cambio",
                            yAxisID: "yaxis2",
                            data: Array.isArray(cambioRentaData) ? cambioRentaData.map((item) => item.perc_cambio) : [cambioRentaData.perc_cambio],
                            borderColor: "#d49449",
                            backgroundColor: "#d49449",
                            datalabels: {
                                align: "top",
                                anchor: "end",
                                color: "transparent",
                            },
                        },
                        {
                            type: "line",
                            label: "lineal (Sal Base)",
                            yAxisID: "yaxis3",
                            data: Array.isArray(cambioRentaData) ? regresionLineal(cambioRentaData) : regresionLineal([cambioRentaData]),
                            borderColor: "#5071be",
                            backgroundColor: "transparent",
                            borderDash: [10, 5],
                            datalabels: {
                                align: "top",
                                anchor: "end",
                                color: "transparent",
                            },
                        },
                    ],
                    labels: Array.isArray(cambioRentaData)
                        ? cambioRentaData.map((item) => {
                            return `${item.motivo} ${item.anio}`;
                        })
                        : [cambioRentaData.motivo + " " + cambioRentaData.anio],
                };
                let options = {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                            position: "bottom",
                        },
                    },
                    layout: {
                        padding: {
                            top: 20,
                        },
                    },
                    scales: {
                        yaxis1: {
                            beginAtZero: true,
                            type: "linear",
                            position: "left",
                        },
                        yaxis2: {
                            beginAtZero: true,
                            type: "linear",
                            position: "right",
                            grid: {
                                drawOnChartArea: false,
                            },
                        },
                        yaxis3: {
                            type: "linear",
                            position: "left",
                            display: false,
                            grid: {
                                drawOnChartArea: false,
                            },
                        },
                    },
                }
                setDataGraficoCambiosRenta({ data: dataGraph, options: options });
            }
        }
    }, [loadingColaborador]);


    const FichaGraficoCambiosDeRentaPDF = () => {

        const styles = StyleSheet.create({
            page: {
                backgroundColor: '#fff',
                padding: '20px',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
            },
            card: {
                padding: "20px",
                borderRadius: "10px",
                width: "48%",
                border: "1px solid #eeeeee",
                margin: " 0px 5px 20px 5px",
                height: "auto",
            },
            head: {
                display: "flex",
                marginBottom: "20px",
                paddingLeft: "10px",
            },
            body: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "20px"
            },
            h1: {
                fontSize: "16px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: "10px",
                color: "#0169b5"
            },
            table: {
                width: "100%",
                borderCollapse: "collapse",
                borderSpacing: "0",
                marginBottom: "20px"
            },
            tableHead: {
                backgroundColor: "#eeeeee",
                borderBottom: "1px solid #eeeeee",
                borderTop: "1px solid #eeeeee",
                borderLeft: "1px solid #eeeeee",
                borderRight: "1px solid #eeeeee",
                padding: "5px",
                fontSize: "8px",
            },
            tableBody: {
                borderBottom: "1px solid #eeeeee",
                borderLeft: "1px solid #eeeeee",
                borderRight: "1px solid #eeeeee",
                fontSize: "8px",
                textAlign: "center",
                color: "#777777",
            },
            tableRow: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                padding: "5px 5px 5px 5px",
                border: "1px solid #eeeeee",
            },
            labelHead: {
                textAlign: "center",
                color: "#777777",
                fontWeight: "900",
            },
            labelBody: {
                textAlign: "center",
                color: "#777777",
                fontWeight: "normal",
            },
            li: {
                display: "flex",
                flexDirection: "row",
                alignItems: 'flex-start',
                padding: "10px",
                borderBottom: "1px solid #eeeeee",
                position: "relative",
                justifyContent: "flex-start"
            },
            liTitle: {
                width: "50%",
            },
            liContent: {
                width: "50%",
            },
            titleLabel: {
                fontSize: "8px",
                fontWeight: "bold",
                color: "#777777"
            },
            contentLabel: {
                fontSize: "8px",
                fontWeight: "normal",
                color: "#777777"
            },
            foto: {
                width: "100px",
                height: "100px",
            },
            checkimg: {
                width: "20px",
                height: "20px",
            },
        });

        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    {/* Grafico */}
                    <View style={{ ...styles.card, width: "100%" }}>
                        <View style={styles.head}>
                            <Text style={styles.h1}>Gráfico Cambios de Renta</Text>
                        </View>
                        <View style={styles.body}>
                            <Image source={graficoRemuneracionesBae64} style={{ width: "100%" }}></Image>
                        </View>
                    </View>
                </Page>
            </Document>
        )

    }





    return (

        <>
            {!loadingColaborador ?
                <>
                    {/* <PDFDownloadLink
                        className="text-decoration-none d-inline-block mb-3 mt-4 color-orange"
                        fileName="Remuneraciones"
                        document={<FichaGraficoCambiosDeRentaPDF />}
                    >
                        <i className="fa fa-file-pdf-o me-1" aria-hidden="true"></i>
                        Descargar
                    </PDFDownloadLink>  */}

                    <div className='col-12 col-xl-12 d-flex'>
                        <div className="box align-items-stretch w-100">
                            <h2 className="h4 tit-section">Gráfico Cambios de Renta</h2>
                            <div className="table-responsive">
                                {dataGraficoCambiosRenta ? (
                                    hiddenTd ? "****" :
                                        <Chart
                                            id="graficoRemuneraciones"
                                            ref={graficoRemuneracionesRef}
                                            data={dataGraficoCambiosRenta.data}
                                            type="line"
                                            width={645}
                                            height={null}
                                            options={dataGraficoCambiosRenta.options}
                                        />
                                ) : null}
                            </div>
                        </div>
                    </div>
                </> : null}
        </>);
};

export default GraficoCambiosDeRentaRegional;