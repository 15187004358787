import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import styles from './replacements.module.css';
import { FaRegEdit } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import { FaChevronRight } from 'react-icons/fa';
import { MdOutlineCancel } from "react-icons/md";
import { debounce } from 'lodash';
import ReplacementsManagementService from '../../services/asignar-reemplazos/management-replacements.service';
import Swal from 'sweetalert2';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import moment from 'moment';

const ReplacementsGrid = ({ replacements, onUpdate }) => {
    ReplacementsGrid.propTypes = {
        replacements: PropTypes.array,
    };
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedReplacement, setSelectedReplacement] = useState({
        id: null,
        replacement_username: '',
        file_number: '',
        nro_document: '',
        init_date: '',
        return_date: '',
        fullname: '',
        email: '',
        replaced_name: ''
    });
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [replacementsResults, setReplacementsResults] = useState([]);
    const [loadingReplacementsResults, setLoadingReplacementsResults] = useState(false);
    const [showAutoCompleteReplacementResults, setShowAutoCompleteReplacementResults] = useState(false);
    const [invalidationMessage, setInvalidationMessage] = useState(null);
    const [replacementLoadingStates, setReplacementLoadingStates] = useState({});
    const [response, setResponse] = useState(null);
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (success) {
            Swal.fire({
                position: 'center',
                title: '<div style="text-align: center; color: green; font-size: 16px"> Correcto</div>',
                html: `<div style="text-align: center; font-size: 15px"> Cambios Guardados </div>`,
                confirmButtonColor: '#0169b5',
            }).then((result) => {
                if (result.isConfirmed) {
                    setShowEditModal(false);
                    setReplacementsResults([]);
                    setSelectedReplacement({
                        id: null,
                        replacement_username: '',
                        file_number: '',
                        nro_document: '',
                        init_date: '',
                        return_date: '',
                        fullname: '',
                        email: '',
                        replaced_name: ''
                    });
                    onUpdate(true);
                }
            });
        }
    }, [success])

    useEffect(() => {
        if (errorMessage) {
            Swal.fire({
                position: 'center',
                title: '<div style="text-align: center; color: red; font-size: 16px"> Error</div>',
                html: `<div style="text-align: center; font-size: 15px"> ${errorMessage} </div>`,
                confirmButtonColor: '#0169b5',
            });
        }
    }, [errorMessage])

    useEffect(() => {
        if (invalidationMessage) {
            if (invalidationMessage.success) {
                Swal.fire({
                    position: 'center',
                    title: '<div style="text-align: center; color: green; font-size: 16px"> Correcto</div>',
                    html: `<div style="text-align: center; font-size: 15px"> ${invalidationMessage.message} </div>`,
                    confirmButtonColor: '#0169b5',
                }).then((result) => {
                    if (result.isConfirmed) {
                        onUpdate(true);
                    }
                });
            } else
                Swal.fire({
                    position: 'center',
                    title: '<div style="text-align: center; color: red; font-size: 16px"> Error</div>',
                    html: `<div style="text-align: center; font-size: 15px"> ${invalidationMessage.message} </div>`,
                    confirmButtonColor: '#0169b5',
                });
        }
    }, [invalidationMessage])

    const handleEdit = (replacement) => {
        setSelectedReplacement({
            ...selectedReplacement,
            replaced_name: replacement.replaced_name,
            init_date: replacement.init_date,
            return_date: replacement.return_date,
            fullname: replacement.fullname,
            id: replacement.id,
            replacement_username: replacement.replacement_username,
            file_number: replacement.file_number,
            nro_document: replacement.nro_document,
            email: replacement.email,
        });
        setShowEditModal(true);
    };

    const handleReplacementDynamicSearch = debounce(async (e) => {
        setShowAutoCompleteReplacementResults(true)
        const query = e.target.value.trim();
        if (!query) {
            setReplacementsResults([]);
            setLoadingReplacementsResults(false);
            return;
        }
        await ReplacementsManagementService.searchReplacements(query, setLoadingReplacementsResults, setReplacementsResults);
    }, 1000);

    const handleInputChange = (fieldName, value) => {
        setSelectedReplacement(prevState => ({
            ...prevState,
            [fieldName]: value
        }));
    };
    const handleInputListChange = (values) => {
        setSelectedReplacement(prevState => ({
            ...prevState,
            ...values
        }));

        if (values.fullname) {
            document.getElementById('fullname').value = values.fullname;
            setShowAutoCompleteReplacementResults(false);
        };
    };

    const handleEdition = () => {
        // console.log(selectedReplacement)
        const areDatesValid = validateDates(selectedReplacement.init_date, selectedReplacement.return_date);
        if (areDatesValid) {
            ReplacementsManagementService.updateReplacement(setLoadingEdit, selectedReplacement, setResponse, setSuccess, setErrorMessage);
        }
    };
    const handleInvalidation = async (id) => {
        Swal.fire({
            position: 'center',
            title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia</div>',
            html: `<div style="text-align: center; font-size: 15px"> Está seguro que desea invalidar este reemplazo?</div>`,
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#0169b5',
            confirmButtonText: 'Invalidar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setReplacementLoadingStates(prevStates => ({
                    ...prevStates,
                    [id]: true
                }));
                await ReplacementsManagementService.invalidateReplacement(id, setReplacementLoadingStates, setInvalidationMessage)


            };
        })
    }
    const validateDates = (init, end) => {
        let currentDate = moment().startOf('day');
        let selectedInitDate = moment(init);
        let selectedEndDate = moment(end);

        if (selectedInitDate.isAfter(selectedEndDate)) {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                html: '<div style="text-align: center; font-size: 15px"> La fecha de inicio del reemplazo no puede ser posterior a la fecha de fin.</div>',
                confirmButtonColor: '#0169b5',
            });

            return false;
        }

        if (selectedInitDate.isBefore(currentDate)) {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                html: '<div style="text-align: center; font-size: 15px"> La fecha de inicio del reemplazo no puede ser previa a la fecha actual.</div>',
                confirmButtonColor: '#0169b5',
            });
            return false;
        }
        if (selectedEndDate.isBefore(currentDate)) {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                html: '<div style="text-align: center; font-size: 15px"> La fecha de inicion del reemplazo no puede ser previa a la fecha actual.</div>',
                confirmButtonColor: '#0169b5',
            });
            return false;
        }
        return true;
    }


    return (
        <div className={styles['table-box-container']}>
            <table className={`table table-even table-section table-borderless d-md-table`}>
                <thead>
                    <tr>
                        <th className={styles['table-head']}>Nombre</th>
                        <th className={styles['table-head']}>Reemplazo Actual</th>
                        <th className={styles['table-head']}>Inicio</th>
                        <th className={styles['table-head']}>Fin</th>
                        <th className={styles['table-head']}>Estado</th>
                        <th className={styles['table-head']}>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {replacements.map((replacement) => {
                        return (
                            <tr className={'bg-white border-b dark:bg-gray-800 dark:border-gray-700'} key={replacement.id}>
                                <td className={styles['table-content']}>{replacement.replaced_name}</td>
                                <td className={styles['table-content']}>{replacement.fullname}</td>
                                <td className={styles['table-content']}>{replacement.init_date}</td>
                                <td className={styles['table-content']}>{replacement.return_date}</td>
                                <td className={`${styles['table-content']} ${styles.status} ${replacement.status === 1 ? styles.valid : styles.invalid}`}>{replacement.status === 1 ? "vigente" : "no vigente"}</td>
                                <td className={`${styles['table-content']}`}>
                                    <div className='d-flex align-items-center justify-content-center gap-2'>
                                        <Tippy content={"Editar este reemplazo"}><div className={styles['edit-btn']} onClick={replacement.status === 0 ? null : () => handleEdit(replacement)}>
                                            <FaRegEdit className={replacement.status === 0 ? styles['invalidation-disabled'] : ''} />
                                        </div></Tippy>
                                        <Tippy content={"Invalidar Reemplazo"}><div className={styles['edit-btn']} onClick={replacement.status === 0 ? null : () => handleInvalidation(replacement.id)}>
                                            {replacementLoadingStates[replacement.id] ? (
                                                <div className='d-flex justify-content-center'>
                                                    <div className={`spinner-border spinner-border-sm text-primary m-2`}></div>
                                                </div>
                                            ) : (
                                                <MdOutlineCancel className={replacement.status === 0 ? styles['invalidation-disabled'] : ''} />
                                            )}
                                        </div></Tippy>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            {/* Edit Modal */}
            <Modal
                show={showEditModal}
                onHide={() => {
                    setShowEditModal(false)
                    setReplacementsResults([]);
                    setSelectedReplacement({
                        id: null,
                        replacement_username: '',
                        file_number: '',
                        nro_document: '',
                        init_date: '',
                        return_date: '',
                        fullname: '',
                        email: '',
                        replaced_name: ''
                    })
                }
                }
                backdrop="static"
                keyboard={false}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {/* Modal content for editing a replacement */}
                <Modal.Header closeButton>
                    <Modal.Title className={styles['modal-title']} >Editar Reemplazo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Editable fields */}
                    <div className="mb-3">
                        <label htmlFor="replaced_name" className='mb-1'>Empleado Reemplazado:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="replaced_name"
                            value={selectedReplacement.replaced_name ? selectedReplacement.replaced_name : ''}
                            readOnly
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="fullname" className='mb-1'>Reemplazo Actual:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="fullname"
                            defaultValue={selectedReplacement.fullname ? selectedReplacement.fullname : ''}
                            onChange={handleReplacementDynamicSearch}
                        />
                        <div className={`${styles['employee-list']}`}>
                            <div className={styles['autocomplete-results']} style={{ display: showAutoCompleteReplacementResults ? 'block' : 'none' }}>
                                {loadingReplacementsResults && (
                                    <div className='d-flex justify-content-center'>
                                        <div className={`spinner-border spinner-border-sm text-primary m-2`}></div>
                                    </div>
                                )}
                                {!loadingReplacementsResults && (
                                    replacementsResults.map((employee) => (
                                        <div
                                            key={employee.legajo}
                                            className={`${styles['employee-item']} d-flex align-items-center gap-2 ${selectedReplacement.replacementEmployee === employee ? styles['selected'] : ''}`}
                                        >
                                            <div onClick={() => handleInputListChange({
                                                fullname: employee.fullname,
                                                nro_document: employee.nro_document,
                                                file_number: employee.legajo,
                                                email: employee.email,
                                                // email: "ggomez@ataway.com",
                                                replacement_username: employee.ad
                                                // replacement_username: "EE8067"
                                            })}>{employee.fullname}, {employee.position}, {employee.country}</div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="init_date" className='mb-1'>Fecha Inicio:</label>
                        <input
                            type="date"
                            className="form-control"
                            id="init_date"
                            onChange={(e) => handleInputChange("init_date", e.target.value)}
                            defaultValue={
                                selectedReplacement.init_date
                            }
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="return_date" className='mb-1'>Fecha Fin:</label>
                        <input
                            type="date"
                            className="form-control"
                            id="return_date"
                            onChange={(e) => handleInputChange("return_date", e.target.value)}
                            defaultValue={
                                selectedReplacement.return_date
                            }
                        />
                    </div>
                    <div className={'col-12'}>
                        <div className={`${styles['button-container']} `}>
                            <button onClick={() => handleEdition()}
                                disabled={loadingEdit || !selectedReplacement.init_date || !selectedReplacement.return_date || !selectedReplacement.fullname}
                                className={`${styles['save-button']} ${loadingEdit ? styles['save-button-load'] : ''}`}>
                                Guardar {loadingEdit && <div className="spinner-border spinner-border-sm"></div>}
                                {!loadingEdit && <FaChevronRight />}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ReplacementsGrid;
