import React from "react";
import {
  Text,
  Image,
  StyleSheet,
  View,
  Page,
  Document,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { Bar } from "react-chartjs-2";
import BarChart from "../../commons/graphics/barChart";

const GeneratePDF = ({ title, graphRef, idGraph }) => {
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.card}>
            <View style={styles.head}>
              <Text style={styles.h1}>{title}</Text>
            </View>
            <View style={styles.body}>
              <Image
                source={() => {
                  if (graphRef.current !== null)
                    return graphRef.current.toBase64Image();
                  else if (document.getElementById(idGraph) !== null)
                    return document.getElementById(idGraph).toDataURL();
                  else return "";
                }}
              ></Image>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
};
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    padding: "20px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  card: {
    padding: "20px",
    borderRadius: "10px",
    width: "100%",
    border: "1px solid #eeeeee",
    margin: " 0px 5px 20px 5px",
    height: "auto",
  },
  head: {
    display: "flex",
    marginBottom: "20px",
    paddingLeft: "10px",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
  },
  h1: {
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "left",
    marginBottom: "10px",
    color: "#0169b5",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    borderSpacing: "0",
    marginBottom: "20px",
  },
  tableHead: {
    backgroundColor: "#eeeeee",
    borderBottom: "1px solid #eeeeee",
    borderTop: "1px solid #eeeeee",
    borderLeft: "1px solid #eeeeee",
    borderRight: "1px solid #eeeeee",
    padding: "5px",
    fontSize: "8px",
  },
  tableBody: {
    borderBottom: "1px solid #eeeeee",
    borderLeft: "1px solid #eeeeee",
    borderRight: "1px solid #eeeeee",
    fontSize: "8px",
    textAlign: "center",
    color: "#777777",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "5px 5px 5px 5px",
    border: "1px solid #eeeeee",
  },
  labelHead: {
    textAlign: "center",
    color: "#777777",
    fontWeight: 900,
  },
  labelBody: {
    textAlign: "center",
    color: "#777777",
    fontWeight: "normal",
  },
  li: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "10px",
    borderBottom: "1px solid #eeeeee",
    position: "relative",
    justifyContent: "flex-start",
  },
  liTitle: {
    width: "50%",
  },
  liContent: {
    width: "50%",
  },
  titleLabel: {
    fontSize: "8px",
    fontWeight: "bold",
    color: "#777777",
  },
  contentLabel: {
    fontSize: "8px",
    fontWeight: "normal",
    color: "#777777",
  },
  foto: {
    width: "100px",
    height: "100px",
  },
  checkimg: {
    width: "20px",
    height: "20px",
  },
});

export default GeneratePDF;
