import React, { Children, Dispatch, SetStateAction, useEffect, useState } from "react";
import { getRemoteAllBanners, getBenefitCard, getItemsCache, getRemotePhotosBenefits, existsPhotoIds, handleCacheBenefits } from "../../utilities/apiServices/apiRequest";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import * as RD from "@devexperts/remote-data-ts";
import { RemoteData } from "@devexperts/remote-data-ts";
import { httpClientEnv } from "../../../../services/apiServices/httpClient";
import { HttpError } from "../../../../services/apiServices/interfaces";
import { SpinerFullScreen } from "../../../commonComponents/SpinerFullScreen";
import { scrollToTop, useCarousel } from "../../../../services/utiles";
import { benefitsCards, categories, GetBanner, GetBenefitCard, GetPhotoBenefit } from "../../utilities/interfaces";
import { useNavigate } from "react-router-dom";
import { BreadCrumbsDinamic } from "../../../commonComponents/BreadCrumbsDinamic";
import { urlsAws } from "../../../../resources/foo/api-endpoints";
import Slider from "../../../commonComponents/Slider";

const transformDataForSlider = (data: categories[], filterCategory: number, setFilterCategory: Dispatch<SetStateAction<number>>) => {
    return data.map((category, i) => (
        <div key={i} className={`col mb-4 mb-lg-0 p-0 ${data.length < 4 ? '' : data.length === 4 ? 'benefit-category-4': data.length === 5 ? 'benefit-category-5' : 'benefit-category-6'}`}>
            <div className="icons-big icons-big-ad text-center btnLink text-decoration-none mb-2">
                <a onClick={(e)=>{
                    if (filterCategory === category.id) {
                        setFilterCategory(0)
                    } else {
                        setFilterCategory(category.id)
                    }
                }} className={`text-decoration-none d-block ${filterCategory === category.id ? 'active' : ''}`}>
                    <span className="material-symbols-outlined fs-1">
                        <i className={`fa ${category.icon} fa-fw fa-sm`} aria-hidden="true"></i>
                    </span>
                    <span className="d-block mt-3 h4 color-dark fw-bold">{category.title}</span>
                </a>
            </div>
        </div>
    ));
};

const MyBenefits = () => {
    const [remoteDataBanners, setRemoteDataBanners] = useState<RemoteData<HttpError, GetBanner>>(RD.initial);
    const [remoteDataBenefitCard, setRemoteDataBenefitCard] = useState<RemoteData<HttpError, GetBenefitCard>>(RD.initial);
    const [remoteDataPhoto, setRemoteDataPhoto] = useState<RemoteData<HttpError, GetPhotoBenefit>>(RD.initial);
    const [filterCategory, setFilterCategory] = useState(0);
    const [tempBenefit, setTempBenefit] = useState<benefitsCards[]>([])
    const [allBenefits, setAllBenefits] = useState<benefitsCards[]>([])
    const [allCategories, setAllCategories] = useState<categories[]>([]);
    const [cantInitialBenef, setCantInitialBenef] = useState<number>(0);
    const [sigPag, setSigPag] = useState<number>(7);
    const [wordEntered, setWordEntered] = useState('');
    const [searchResults, setSearchResults] = useState<benefitsCards[]>([])
    const [idArrays, setIdsArray] = useState<{id: number, photo: string | undefined}[]>([])

    const navigate = useNavigate();

    const setCurrentPhoto = async (temp: RemoteData<HttpError, GetBenefitCard>, setTempBenefit: any) => {
        const newBenefits = await Promise.all(
            temp._tag === 'RemoteSuccess' ? temp.value.response.benefits.map(async (benefit) => {
                const response = await caches.match(urlsAws.getPhotoBenefit + "?id=" + benefit.id);
                if (response) {
                    const data = await response.json();
                    if (data.id === benefit.id) {
                        return { ...benefit, img_card: data.img_card };
                    } else {
                        return {...benefit}
                    }
                } else {
                    return {...benefit}
                }
            }) : []
        );
        temp._tag === 'RemoteSuccess' ? temp.value.response.benefits = newBenefits : temp
        setTempBenefit(temp._tag === 'RemoteSuccess' ? temp.value.response.benefits : [])
        setRemoteDataBenefitCard(temp);
    };

    const GetBenefitCard = async () => {
        setFilterCategory(0);
        setRemoteDataBenefitCard(RD.pending)
        const benefitCardCache = await getItemsCache(urlsAws.getBenefitCard);
        if (benefitCardCache) {
            setCurrentPhoto(benefitCardCache, setTempBenefit);       
            setAllCategories(prev => benefitCardCache._tag === 'RemoteSuccess' ? [...prev, ...benefitCardCache.value.response.categories]: [...prev] )
        } else {
            RTE.run(await getBenefitCard(), httpClientEnv)
            .then(E.fold(e => { return setRemoteDataBenefitCard(RD.failure(e)) }, async a => {
                let data = RD.success(a)
                if (data._tag === 'RemoteSuccess') {
                    data.value.response.benefits.length > 0 && handleCacheBenefits(urlsAws.getBenefitCard, data)
                    setAllCategories(prev => data._tag === 'RemoteSuccess' ? [...prev, ...data.value.response.categories]: [...prev] )
                    return setRemoteDataBenefitCard(RD.success(a));
                } else {
                    return setRemoteDataBenefitCard(RD.failure({ tag: 'httpRequestError', error: 'error GetBenefitCard' }));
                } 
            }))
        }
    };

    const handleSearch = (text: string) => {
        const filteredResults: benefitsCards[] = allBenefits.filter((item) => {
                return item.title.toLowerCase().includes(text.toLowerCase()) || item.subtitle.toLowerCase().includes(text.toLowerCase() ) || item.category.title.toLowerCase().includes(text.toLowerCase())
            }
        );
        setTempBenefit(filteredResults);
    };

    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchWord = event.target.value;
        setWordEntered(searchWord);
        const filteredResults: benefitsCards[] = allBenefits.filter((item) => {
            return item.title.toLowerCase().includes(searchWord.toLowerCase()) || item.subtitle.toLowerCase().includes(searchWord.toLowerCase() ) || item.category.title.toLowerCase().includes(searchWord.toLowerCase())
            }
        );

        if (searchWord === "") {
            setFilterCategory(0);
            setSearchResults([]);
        } else {
            setSearchResults(filteredResults);
        }
    };

    useEffect(()=>{
        scrollToTop();
        getRemoteAllBanners(setRemoteDataBanners);
        GetBenefitCard();
    },[])

    useEffect(() => {
        if (remoteDataBenefitCard._tag === 'RemoteSuccess') {
            const filteredBenefits = filterCategory > 0
                ? allBenefits.filter(benefit => benefit.category.id === filterCategory)
                : allBenefits;

            if(filterCategory){
                const ids = filteredBenefits.map((benef)=> {
                    return benef.id
                })
                existsPhotoIds(ids)
                .then((photoBenef) => {
                    if (photoBenef.length > 0) {
                        getRemotePhotosBenefits(photoBenef, setRemoteDataPhoto)
                    } else {
                        setIdsArray(allBenefits.map((benef) => ({
                            id: benef.id,
                            photo: benef.img_card,
                        })));
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            }
            
        }
    }, [remoteDataBenefitCard, filterCategory, allBenefits]);

    useEffect(()=> {
        if(remoteDataBenefitCard._tag === 'RemoteSuccess'){
            let temp = remoteDataBenefitCard.value.response.benefits.slice(cantInitialBenef, 1 + sigPag);
            let arrayEmplids = temp.map((obj) => {
                return obj.id;
            });
            setAllBenefits(remoteDataBenefitCard.value.response.benefits)
            existsPhotoIds(arrayEmplids)
            .then((photoBenef) => {
                if (photoBenef.length > 0) {
                    getRemotePhotosBenefits(photoBenef, setRemoteDataPhoto)
                } else {
                    setCurrentPhoto(remoteDataBenefitCard, setTempBenefit);      
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }
    },[remoteDataBenefitCard, sigPag, cantInitialBenef])

    useEffect(()=>{
        if(remoteDataPhoto._tag === 'RemoteSuccess' && remoteDataPhoto.value.response.photo.length > 0){
            remoteDataPhoto.value.response.photo.map(async (ph, i )=> {
                setIdsArray((prevIdsArray) => [...prevIdsArray, ...[{
                    id: ph.id,
                    photo: ph.img_card
                }]]);
                handleCacheBenefits(urlsAws.getPhotoBenefit + "?id=" + ph.id, ph)
            });
        }
    },[remoteDataPhoto])

    useEffect(() => {
        const fetchData = async () => {
            if (idArrays.length > 0 && remoteDataBenefitCard._tag === 'RemoteSuccess') {
                const newData = await Promise.all(remoteDataBenefitCard.value.response.benefits.map(async (benef) => {
                    const matchingPh = idArrays.find(
                        ph => ph.id === benef.id && ph.photo,
                      );
                    const response = await getItemsCache(urlsAws.getPhotoBenefit + '?id=' + benef.id);
                    if (matchingPh) {
                        return {
                          ...benef,
                          img_card: matchingPh.photo,
                        };
                      } else if (response) {
                        return {
                          ...benef,
                          img_card: response.img_card,
                        };
                      } else {
                        return benef;
                      }
                }));
    
                const filteredBenefits = filterCategory > 0
                    ? newData.filter(benefit => benefit.category.id === filterCategory)
                    : newData;
    
                setTempBenefit(filteredBenefits);
            }
        };
    
        fetchData();
    }, [idArrays, filterCategory, remoteDataBenefitCard]);
    
    useCarousel(10000,'carousel-beneficios-dest', remoteDataBanners._tag === 'RemoteSuccess');
    useCarousel(10000,'carousel-categorias-mobile', remoteDataBenefitCard._tag === 'RemoteSuccess');

    return(
        <>
        <section className="beneficios">
            <BreadCrumbsDinamic/>
            <h1>Mis Beneficios</h1>
            {remoteDataBanners._tag === 'RemoteSuccess' && remoteDataBanners.value.response.banner.length > 0 && (
                <>
                    <div id="carousel-beneficios-dest" className="carousel carousel-dark slide pointer-event btnLink" data-bs-ride="carousel">
                        <div className="carousel-indicators m-0" >
                            {remoteDataBanners.value.response.banner.map((banner, index) => {
                                if(banner && banner.img_banner) {
                                    return <button key={index} type="button" data-bs-target="#carousel-beneficios-dest" data-bs-slide-to={index} aria-label={"Slide " + index} className={index === 0 ? "active": ""} aria-current={index === 0 ? "true" : "false"}></button>
                                }
                                })
                            }
                        </div>
                        <div className="carousel-inner">
                            {Children.toArray(remoteDataBanners.value.response.banner.map((banner,i)=>{
                                return(
                                    <>
                                        <div className={i === 0 ? "carousel-item active" : "carousel-item"} data-bs-interval="10000" key={i}>
                                            <a onClick={(e)=>{ navigate(`/mis-beneficios/${banner.id}`) }}>
                                                <picture>
                                                    <source media="(max-width: 767px)" src={banner.img_banner} />
                                                    <source media="(min-width: 768px)" src={banner.img_banner}/>
                                                    <img src={banner.img_banner} className="d-block w-100 rounded-4 img-banner-benefits mb-2" alt=""/>
                                                </picture>
                                                { 
                                                    banner.is_visible_title && 
                                                    <div className="carousel-caption" style={{bottom: 0, transform: 'translateY(30%)'}}>
                                                        <h2 className="text-title-banner" style={{ color: 'white', textShadow: '2px 2px 2px black' }}>{banner.title}</h2>
                                                    </div>
                                                }
                                            </a>
                                        </div>
                                    </>
                                )
                            }))}
                        </div>
                        <button className="carousel-control-prev my-auto" type="button" data-bs-target="#carousel-beneficios-dest" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Anterior</span>
                        </button>
                        <button className="carousel-control-next my-auto" type="button" data-bs-target="#carousel-beneficios-dest" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Siguiente</span>
                        </button>
                    </div>
                </>
            )}

            {remoteDataBenefitCard._tag === 'RemoteSuccess' && remoteDataBenefitCard.value.response.benefits.length > 0 ?
                <>
                    <div className="tit-group d-block d-md-flex justify-content-between mt-4">
                        <h2 className="h4 tit-section mb-2">Categorías</h2>
                    </div>
                    <div className="row justify-content-end w-auto">
                            <div className="col-12 col-md-6 col-xl-5">
                                <div className="input-group input-group-white">
                                    <input type="text" className="form-control" onChange={(e)=>{ handleFilter(e) }} placeholder="Buscar"/>
                                    <button className="input-group-text border border-1 border-solid border-color-#ced4da m-0"
                                        onClick={()=>{ handleSearch(wordEntered)}}>
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                                {searchResults.length > 0 && (
                                    <div className="position-relative">
                                        <div className="search-results">
                                        <ul>
                                            {searchResults.map((result, index) => (
                                                <a key={index} onClick={()=>{
                                                    navigate(`/mis-beneficios/${result.id}`);
                                                }} className="text-decoration-none m-0">
                                                    <li className="d-flex align-items-center">
                                                        <i className="fa fa-search mx-2"></i>
                                                        {result.title +' - '+result.category.title}
                                                    </li>
                                                </a>
                                            ))}
                                        </ul>
                                    </div>
                                    </div>
                                )}
                            </div>
                    </div>
                    
                    <div id="carousel-categories" className="carousel carousel-dark carousel-border slide mb-5 mt-2 d-none d-lg-block pointer-event" data-bs-ride="carousel" >
                        <div className="row w-auto mb-2">
                            <Slider 
                                id="carousel-category" 
                                data={transformDataForSlider(allCategories, filterCategory, setFilterCategory)} 
                            />
                        </div>
                    </div>

                    <div id="carousel-categorias-mobile" className="carousel carousel-dark carousel-border mb-4 slide d-lg-none pointer-event" data-bs-ride="carousel">                
                        <div className="carousel-indicators">
                            {allCategories.reduce((chunks: categories[][], category, i) => {
                                    const index = Math.floor(i / 2);
                                    if (!chunks[index]) {
                                    chunks[index] = [];
                                    }
                                    chunks[index].push(category);
                                    return chunks;
                                    }, []).map((banner, index) => {
                                    return <button key={index} type="button" data-bs-target="#carousel-categorias-mobile" data-bs-slide-to={index} aria-label={"Slide " + index} className={index === 0 ? "active": ""} aria-current={index === 0 ? "true" : "false"}></button>
                                })
                            }
                        </div>
                        <div className="carousel-inner">
                            {allCategories.reduce((chunks: categories[][], category, i) => {
                                const index = Math.floor(i / 2);
                                if (!chunks[index]) {
                                chunks[index] = [];
                                }
                                chunks[index].push(category);
                                return chunks;
                                }, []).map((chunk, index) => (
                                    <div key={index} className={`carousel-item p-2 mb-4 ${index === 0 ? 'active' : ''}`} data-bs-interval="10000">
                                        <div className="row gap-0 w-auto">
                                        {chunk.map((category, i) => {
                                            if(chunk.length > 1) {
                                                return(
                                                    <div className="col-6 m-0 p-0">
                                                        <div className="icons-big icons-big-ad text-center">
                                                            <a onClick={(e)=>{
                                                                if (filterCategory === category.id) {
                                                                    setFilterCategory(0)
                                                                } else {
                                                                    setFilterCategory(category.id)
                                                                }
                                                            }} className={`text-decoration-none d-block ${filterCategory === category.id ? 'active' : ''}`}>
                                                            <span className="material-symbols-outlined fs-1">
                                                                <i className={`fa ${category.icon} fa-fw fa-sm`} aria-hidden="true"></i>
                                                            </span>
                                                            <span className="d-block mt-3 h4 color-dark fw-bold">{category.title}</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return(
                                                    <div className="text-center">
                                                        <div className="icons-big icons-big-ad">
                                                            <a onClick={(e)=>{
                                                                if (filterCategory === category.id) {
                                                                    setFilterCategory(0)
                                                                } else {
                                                                    setFilterCategory(category.id)
                                                                }
                                                            }} className={`text-decoration-none d-block ${filterCategory === category.id ? 'active' : ''}`}>
                                                            <span className="material-symbols-outlined fs-1">
                                                                <i className={`fa ${category.icon} fa-fw fa-sm`} aria-hidden="true"></i>
                                                            </span>
                                                            <span className="d-block mt-3 h4 color-dark fw-bold">{category.title}</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                        </div>
                                    </div>
                            ))}
                        </div>
                    </div>
                </>
            : remoteDataBenefitCard._tag === 'RemoteSuccess' && remoteDataBenefitCard.value.response.benefits.length === 0 ?
                <div className="text-center mt-4">
                    <p> No se han encontrado beneficios.</p>
                </div>
            : remoteDataBenefitCard._tag === 'RemotePending' ?(
                <>
                    <div className="text-center mt-4">
                        <p>Cargando beneficios...</p>
                        <SpinerFullScreen/>
                    </div>
                </>
            ): remoteDataBenefitCard._tag === 'RemoteFailure' &&
                <>
                    <div className="text-center mt-4">
                        <p>Ha ocurrido un error al obtener beneficios, por favor, intente más tarde.</p>
                    </div>
                </>
            }
            
            <div className="row row-cols-1 row-cols-md-4 row-cols-xl-4 row-cols-xxl-4 g-4 mb-4 ms-0">
                {tempBenefit.map((benefit, i)=>{
                    if (i <= sigPag) {
                        return(
                            <>
                                <div className="col d-flex padding-card-benef" >
                                    <article className="d-flex flex-column box-card w-100">
                                        <div className="box-card__img">
                                            <div className="card-icon card-icon-absolute">
                                                <span className="material-symbols-outlined fs-2">
                                                    <i className={`fa ${benefit.category.icon} fa-fw fa-sm`} aria-hidden="true"></i>
                                                </span>
                                            </div>
                                            {remoteDataPhoto._tag === 'RemotePending' || !benefit.img_card && 
                                                <div className="position-absolute" style={{ top: "45%", left: "45%"}}>
                                                    <span className="spinner-border spinner-border-xl" />
                                                </div>
                                            }
                                            <img className="img-fluid img-cards" src={benefit.img_card ? benefit.img_card : require('../../../../resources/images/default_img.png')} alt=""/>
                                        </div>
                                        <div className="box-card__body d-flex flex-column flex-grow-1">
                                            <h3>
                                                <a className="color-dark btnLink" href={"mis-beneficios/"+benefit.id}>{benefit.title}</a>
                                            </h3>
                                            <p className="h6">{benefit.subtitle.length > 100 ? benefit.subtitle.substring(0,100) + '...' : benefit.subtitle}</p>
                                            <div className="text-end mt-auto">
                                                <a onClick={()=>{ navigate(`/mis-beneficios/${benefit.id}`) }} className="text-decoration-none">
                                                    <a className="btn btn-degradado"> Ver más <i className="fa fa-chevron-right" aria-hidden="true"></i></a>
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </>
                        )
                    }
                })}
            </div>

            {remoteDataBenefitCard._tag === 'RemoteSuccess' && (sigPag + 1 < remoteDataBenefitCard.value.response.benefits.length) && filterCategory === 0 &&
                <div className="text-center mt-5">
                    <button className="btn btn-primary" onClick={()=>{
                        setCantInitialBenef(sigPag)
                        setSigPag( sigPag + 8 )
                    }}> Cargar más </button>
                </div>
            }
            </section>
        </>
    )
}

export default MyBenefits;