import React from "react";
import { Link } from "react-router-dom";
import { BreadCrumbsDinamic } from "../commonComponents/BreadCrumbsDinamic";
import TitleCmp from "../commonComponents/TitleCmp";

export const HeaderRegistroViaje = () => {
  return (
    <>
      <BreadCrumbsDinamic />
      <TitleCmp title="Registro de viajes" />
    </>
  );
};
