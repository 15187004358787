import { TrendingUp } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CertificadoIngresosRetencionesServices from "../../services/certificado-ingresos-retenciones/service";
import successMessage from "../SharedComponents/success-message";
import errorMessage from "../SharedComponents/error-message";
import Swal from "sweetalert2";
import confirmMessage from "../SharedComponents/confirm-message";
import ColorPicker from "../SharedComponents/color-picker";
import styles from "../../resources/css/style-COL.module.css";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-degradado",
    cancelButton: "btn btn-primary",
  },
  buttonsStyling: false,
});

const FormFields = ({
  isAddingField,
  isEditingField,
  setIsAddingField,
  setIsEditingField,
  colors,
  fonts,
  aligns,
  getFields,
  idField,
  isLoadingField,
  field,
  idCertificate,
  setIsConfiguring,
  certificate,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [color, setColor] = useState({
    r: "255",
    g: "255",
    b: "255",
    a: "1",
    hex: "#ffffff",
    wasModified: false,
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const cancel = () => {
    setIsAddingField(false);
    setIsEditingField(false);
  };

  const save = async (data) => {
    console.log("DATA", data);

    if (color?.hex === null) {
      return;
    }

    swalWithBootstrapButtons
      .fire({
        title: "Confirmación",
        text: "¿Desea continuar la operación?",
        // icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
        showCloseButton: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          setIsSaving(true);

          const dto = {
            id: idField,
            pdffillerid: idCertificate,
            code: data.code,
            name: data.name,
            page_number: parseInt(data.page_number),
            position_x: parseInt(data.position_x),
            position_y: parseInt(data.position_y),
            text_size: parseInt(data.text_size),
            text_color: parseInt(data.text_color),
            text_font: parseInt(data.text_font),
            box_background_color: color?.wasModified
              ? color?.hex
              : convertHexToRgb(certificate?.box_background_color)?.hex,
            box_width: parseInt(data.box_width),
            box_length: parseInt(data.box_length),
            box_text_align: parseInt(data.box_text_align),
            default_value: data?.default_value || "",
          };

          // Call to service
          let response = null;

          if (idField === null) {
            response = await CertificadoIngresosRetencionesServices.createField(
              dto
            );
          } else {
            response = await CertificadoIngresosRetencionesServices.modifyField(
              dto
            );
          }

          if (response === null) {
            errorMessage(null, "");
          } else {
            const status = response?.statusCode || response?.status;
            switch (status) {
              case 200:
                setIsAddingField(false);
                setIsEditingField(false);
                setIsSaving(false);
                successMessage(getFields(idCertificate));
                break;

              case 400:
                errorMessage(null, response?.message);
                setIsSaving(false);
                break;

              case 500:
                errorMessage(null, response?.message);
                setIsSaving(false);
                break;

              default:
                break;
            }
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
  };

  const deleteObj = () => {
    confirmMessage(deleteConfirm);
  };

  const deleteConfirm = async () => {
    setIsDeleting(true);
    // Call to service
    const response = await CertificadoIngresosRetencionesServices.deleteField(
      idField
    );

    if (response === null) {
      errorMessage(null, "");
    } else {
      const status = response?.statusCode || response?.status;
      switch (status) {
        case 200:
          setIsAddingField(false);
          setIsEditingField(false);
          setIsDeleting(false);
          successMessage(getFields(idCertificate));
          break;

        case 400:
          errorMessage(null, response?.message);
          setIsDeleting(false);
          break;

        case 500:
          errorMessage(null, response?.message);
          setIsDeleting(false);
          break;

        default:
          break;
      }
    }
  };

  const backToCertificate = () => {
    setIsConfiguring(false);
    setIsAddingField(false);
    setIsEditingField(false);
  };

  const convertHexToRgb = (hex) => {
    const returnRgb = {
      r: "255",
      g: "255",
      b: "255",
      a: "1",
      hex: "#ffffff",
    };

    if (hex != null && hex != undefined && typeof hex === "string") {
      const cleanHex = hex.replace("#", "");
      try {
        returnRgb.r = parseInt(cleanHex.slice(0, 2), 16);
        returnRgb.g = parseInt(cleanHex.slice(2, 4), 16);
        returnRgb.b = parseInt(cleanHex.slice(4, 6), 16);
      } catch (error) {
        console.log("Conversion error: hex to rgb");
      }
    }

    return returnRgb;
  };

  useEffect(() => {
    reset();
  }, [field]);

  return (
    <>
      {isLoadingField ? (
        <div data-testid="loading" className={styles["full-spinner"]}>
          <div
            className={`${styles["spinner-border"]} ${styles["text-primary"]}`}
          ></div>
        </div>
      ) : null}

      {!isLoadingField ? (
        <div className={styles["box"] + " " + styles["mb-4"]}>
          <h2 className={styles["h4"] + " " + styles["tit-section"]}>
            {isAddingField ? "Agregar Campo" : null}
            {isEditingField ? "Editar Campo" : null}
          </h2>

          <form>
            {/* <!-- Fila 1 --> */}
            <div className={styles["row"]}>
              <div
                className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
              >
                <label className={styles["form-label"]}>
                  Id Campo <span className={styles["color-red"]}>*</span>
                </label>
                <input
                  type="text"
                  className={styles["form-control"]}
                  {...register("code", {
                    required: "* Campo obligatorio",
                    maxLength: 255,
                  })}
                  defaultValue={field?.code}
                />
                <small className={styles["form-error-message"]}>
                  {errors?.code?.message}
                </small>
              </div>
              <div
                className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
              >
                <label class={styles["form-label"]}>
                  Nombre Campo <span className={styles["color-red"]}>*</span>
                </label>
                <input
                  type="text"
                  className={styles["form-control"]}
                  {...register("name", {
                    required: "* Campo obligatorio",
                    maxLength: 255,
                  })}
                  defaultValue={field?.name}
                />
                <small className={styles["form-error-message"]}>
                  {errors?.name?.message}
                </small>
              </div>
            </div>
            {/* <!-- Fila 2 --> */}
            <div className={styles["row"]}>
              <div
                className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
              >
                <label className={styles["form-label"]}>
                  Página <span className={styles["color-red"]}>*</span>
                </label>
                <input
                  type="number"
                  className={styles["form-control"]}
                  {...register("page_number", {
                    required: "* Campo obligatorio",
                    validate: {
                      graterOrEqualThan: (value) => parseFloat(value) >= 1,
                      lessOrEqualTha: (value) => parseFloat(value) <= 99,
                    },
                  })}
                  defaultValue={field?.page_number}
                  onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  min={0}
                />
                <small className={styles["form-error-message"]}>
                  {errors?.page_number?.message}
                  {errors?.page_number &&
                    errors?.page_number?.type === "graterOrEqualThan" &&
                    "El valor debe ser mayor o igual a 1"}
                  {errors?.page_number &&
                    errors?.page_number?.type === "lessOrEqualTha" &&
                    "El valor debe ser menor o igual a 99"}
                </small>
              </div>
              <div
                className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
              >
                <label className={styles["form-label"]}>
                  Posición X <span className={styles["color-red"]}>*</span>
                </label>
                <input
                  type="number"
                  className={styles["form-control"]}
                  {...register("position_x", {
                    required: "* Campo obligatorio",
                    validate: {
                      graterOrEqualThan: (value) => parseFloat(value) >= 1,
                      lessOrEqualTha: (value) => parseFloat(value) <= 1000,
                    },
                  })}
                  defaultValue={field?.position_x}
                  onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  min={0}
                />
                <small className={styles["form-error-message"]}>
                  {errors?.position_x?.message}
                  {errors?.position_x &&
                    errors?.position_x?.type === "graterOrEqualThan" &&
                    "El valor debe ser mayor o igual a 1"}
                  {errors?.position_x &&
                    errors?.position_x?.type === "lessOrEqualTha" &&
                    "El valor debe ser menor o igual a 1000"}
                </small>
              </div>
            </div>
            {/* <!-- Fila 3 --> */}
            <div className={styles["row"]}>
              <div
                className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
              >
                <label className={styles["form-label"]}>
                  Posición Y <span className={styles["color-red"]}>*</span>
                </label>
                <input
                  type="number"
                  className={styles["form-control"]}
                  {...register("position_y", {
                    required: "* Campo obligatorio",
                    validate: {
                      graterOrEqualThan: (value) => parseFloat(value) >= 1,
                      lessOrEqualTha: (value) => parseFloat(value) <= 1000,
                    },
                  })}
                  defaultValue={field?.position_y}
                  onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  min={0}
                />
                <small className={styles["form-error-message"]}>
                  {errors?.position_y?.message}
                  {errors?.position_y &&
                    errors?.position_y?.type === "graterOrEqualThan" &&
                    "El valor debe ser mayor o igual a 1"}
                  {errors?.position_y &&
                    errors?.position_y?.type === "lessOrEqualTha" &&
                    "El valor debe ser menor o igual a 1000"}
                </small>
              </div>
              <div
                className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
              >
                <label className={styles["form-label"]}>
                  Tamaño de letra <span className={styles["color-red"]}>*</span>
                </label>
                <input
                  type="number"
                  className={styles["form-control"]}
                  {...register("text_size", {
                    required: "* Campo obligatorio",
                    validate: {
                      graterOrEqualThan: (value) => parseFloat(value) >= 4,
                      lessOrEqualTha: (value) => parseFloat(value) <= 30,
                    },
                  })}
                  defaultValue={
                    field?.text_size || certificate?.default_text_size
                  }
                  onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  min={0}
                />
                <small className={styles["form-error-message"]}>
                  {errors?.text_size?.message}
                  {errors?.text_size &&
                    errors?.text_size?.type === "graterOrEqualThan" &&
                    "El valor debe ser mayor o igual a 4"}
                  {errors?.text_size &&
                    errors?.text_size?.type === "lessOrEqualTha" &&
                    "El valor debe ser menor o igual a 30"}
                </small>
              </div>
            </div>
            {/* <!-- Fila 4 --> */}
            <div className={styles["row"]}>
              <div
                className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
              >
                <label className={styles["form-label"]}>
                  Color de letra<span className={styles["color-red"]}>*</span>
                </label>
                <div className={styles["input-group"]}>
                  <select
                    className={styles["form-select"]}
                    {...register("text_color", {
                      required: "* Campo obligatorio",
                    })}
                  >
                    {/* {isAddingField ? (
                      <option value="" selected>
                        Seleccione
                      </option>
                    ) : null} */}

                    {colors.map((list) => {
                      return (
                        <option
                          key={list.id}
                          value={list.id}
                          selected={
                            field?.text_color?.toString() ===
                              list?.id?.toString() ||
                            certificate?.default_text_color?.toString() ===
                              list?.id?.toString()
                          }
                        >
                          {list.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <small className={styles["form-error-message"]}>
                  {errors?.text_color?.message}
                </small>
              </div>
              <div
                className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
              >
                <label className={styles["form-label"]}>
                  Fuente <span className={styles["color-red"]}>*</span>
                </label>
                <div className={styles["input-group"]}>
                  <select
                    className={styles["form-select"]}
                    {...register("text_font", {
                      required: "* Campo obligatorio",
                    })}
                  >
                    {/* {isAddingField ? (
                      <option value="" selected>
                        Seleccione
                      </option>
                    ) : null} */}

                    {fonts.map((list) => {
                      return (
                        <option
                          key={list.id}
                          value={list.id}
                          selected={
                            field?.text_font === list?.id ||
                            certificate?.default_text_font === list?.id
                          }
                        >
                          {list.description}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <small className={styles["form-error-message"]}>
                  {errors?.text_font?.message}
                </small>
              </div>
            </div>
            {/* <!-- Fila 5 --> */}
            <div className={styles["row"]}>
              <div
                className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
              >
                <label className={styles["form-label"]}>
                  Color fondo de caja{" "}
                  <span className={styles["color-red"]}>*</span>
                </label>
                <div className={styles["input-group"]}>
                  <ColorPicker
                    color={
                      color?.wasModified === true
                        ? color
                        : field?.box_background_color === null ||
                          field?.box_background_color === undefined ||
                          field?.box_background_color === ""
                        ? convertHexToRgb(certificate?.box_background_color)
                        : convertHexToRgb(field?.box_background_color)
                    }
                    setColor={setColor}
                  />
                  <small
                    className={styles["label"]}
                    style={{ marginLeft: "5px" }}
                  >
                    {/* {color?.hex === null ? "" : color?.hex} */}
                  </small>
                </div>
                <small className={styles["form-error-message"]}></small>
              </div>
              <div
                className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
              >
                <label className={styles["form-label"]}>
                  Ancho de caja <span className={styles["color-red"]}>*</span>
                </label>
                <input
                  type="number"
                  className={styles["form-control"]}
                  {...register("box_width", {
                    required: "* Campo obligatorio",
                    validate: {
                      graterOrEqualThan: (value) => parseFloat(value) >= 1,
                      lessOrEqualTha: (value) => parseFloat(value) <= 1000,
                    },
                  })}
                  defaultValue={field?.box_width}
                  onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  min={0}
                />
                <small className={styles["form-error-message"]}>
                  {errors?.box_width?.message}
                  {errors?.box_width &&
                    errors?.box_width?.type === "graterOrEqualThan" &&
                    "El valor debe ser mayor o igual a 1"}
                  {errors?.box_width &&
                    errors?.box_width?.type === "lessOrEqualTha" &&
                    "El valor debe ser menor o igual a 1000"}
                </small>
              </div>
            </div>
            {/* <!-- Fila 6 --> */}
            <div className={styles["row"]}>
              <div
                className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
              >
                <label className={styles["form-label"]}>
                  Alto de Caja <span className={styles["color-red"]}>*</span>
                </label>
                <input
                  type="number"
                  className={styles["form-control"]}
                  {...register("box_length", {
                    required: "* Campo obligatorio",
                    validate: {
                      graterOrEqualThan: (value) => parseFloat(value) >= 1,
                      lessOrEqualTha: (value) => parseFloat(value) <= 1000,
                    },
                  })}
                  defaultValue={field?.box_length}
                  onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  min={0}
                />
                <small className={styles["form-error-message"]}>
                  {errors?.box_length?.message}
                  {errors?.box_length &&
                    errors?.box_length?.type === "graterOrEqualThan" &&
                    "El valor debe ser mayor o igual a 1"}
                  {errors?.box_length &&
                    errors?.box_length?.type === "lessOrEqualTha" &&
                    "El valor debe ser menor o igual a 1000"}
                </small>
              </div>
              <div
                className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
              >
                <label className={styles["form-label"]}>
                  Alineación del Texto{" "}
                  <span className={styles["color-red"]}>*</span>
                </label>
                <div className={styles["input-group"]}>
                  <select
                    className={styles["form-select"]}
                    {...register("box_text_align", {
                      required: "* Campo obligatorio",
                    })}
                  >
                    {isAddingField ? (
                      <option value="" selected>
                        Seleccione
                      </option>
                    ) : null}

                    {aligns.map((list) => {
                      return (
                        <option
                          key={list.id}
                          value={list.id}
                          selected={field?.box_text_align === list.id}
                        >
                          {list.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <small className={styles["form-error-message"]}>
                  {errors?.box_text_align?.message}
                </small>
              </div>
            </div>
            {/* <!-- Fila 7 --> */}
            <div className={styles["row"]}>
              <div
                className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
              >
                <label class={styles["form-label"]}>
                  Valor por Defecto{" "}
                  <span className={styles["color-red"]}></span>
                </label>
                <input
                  type="text"
                  className={styles["form-control"]}
                  {...register("default_value", {
                    maxLength: 500,
                  })}
                  defaultValue={field?.default_value}
                  maxlength="500"
                />
                <small className={styles["form-error-message"]}>
                  {errors?.default_value?.message}
                </small>
              </div>
              <div
                className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
              ></div>
            </div>
          </form>

          <div
            className={
              styles["d-grid"] +
              " " +
              styles["d-md-flex"] +
              " " +
              (isEditingField === true
                ? styles["justify-content-md-between"]
                : styles["justify-content-md-end"]) +
              " " +
              styles["gap-2"] +
              " " +
              styles["flex-nowrap"] +
              " " +
              styles["mt-4"]
            }
          >
            <div
              className={`${styles["d-grid"]} ${styles["d-md-flex"]} ${styles["gap-2"]}`}
            >
              <button
                type="button"
                className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["order-md-last"]} ${styles["ms-md-2"]}`}
                onClick={handleSubmit(save)}
                disabled={isSaving || isDeleting}
              >
                Guardar{" "}
                {isSaving ? (
                  <div
                    className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}
                  ></div>
                ) : (
                  <i
                    className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                    aria-hidden="true"
                  ></i>
                )}
              </button>
              <button
                type="button"
                className={`${styles["btn"]} ${styles["btn-primary"]}`}
                onClick={cancel}
                disabled={isSaving || isDeleting}
              >
                Cancelar{" "}
                <i
                  className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                  aria-hidden="true"
                ></i>
              </button>
            </div>
            {isEditingField ? (
              <button
                type="button"
                className={`${styles["btn"]} ${styles["btn-secondary"]} ${styles["order-md-first"]}`}
                onClick={deleteObj}
                disabled={isAddingField || isSaving || isDeleting}
              >
                Eliminar{" "}
                {isDeleting ? (
                  <div
                    className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}
                  ></div>
                ) : (
                  <i
                    className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                    aria-hidden="true"
                  ></i>
                )}
              </button>
            ) : null}
          </div>

          <div
            className={`${styles["row"]} ${styles["justify-content-start"]} ${styles["mt-4"]}`}
          >
            <div className={`${styles["col-12"]} ${styles["col-md-auto"]}`}>
              <button
                type="button"
                className={`${styles["btn"]} ${styles["btn-link"]}`}
                onClick={backToCertificate}
              >
                Volver
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default FormFields;
