import AccordionBootstrap from "react-bootstrap/Accordion";
import { AccordionProps } from "./accordion.types";

export const Accordion = (props: AccordionProps) => {
  const { itemList, className } = props;
  return (
    <div>
      {itemList.map((item, index) => (
        <AccordionBootstrap
          key={`${index}${item.headerLabel}`}
          className={`accordion accordion-flush accordion-light accordion-table ${className}`}
          defaultActiveKey="0"
        >
          <AccordionBootstrap.Item key={index} eventKey={String(index)}>
            <AccordionBootstrap.Header
              className="accordion-header"
              id="accordion"
            >
              <strong>
                {/* {item.name} {item.last_name} */}
                {item.headerLabel}
              </strong>
            </AccordionBootstrap.Header>

            {item.items.map((body) => (
              <AccordionBootstrap.Body className="accordion-body p-0">
                <div className="data-list-box data-list-box-even">
                  <ul className="data-list-section--item list-unstyled">
                    <li className="mb-2">
                      <span className="fw-bold">{body.label}: </span>
                      <span>{body.value}</span>
                    </li>
                  </ul>
                </div>
              </AccordionBootstrap.Body>
            ))}
          </AccordionBootstrap.Item>
        </AccordionBootstrap>
      ))}
    </div>
  );
};
