import React, { useEffect, useState } from "react";
import styles from "../../resources/css/style-COL.module.css";
import moment from "moment";
import EthicalCodeServices from "../../services/codigo-etica/service";
import errorMessage from "../SharedComponents/error-message";
import { PDFDocument } from "pdf-lib";
import download from "downloadjs";
import { Auth } from "aws-amplify";
("../../resources/css/style-COL.css");

const List = ({ pdfs, setIsAdding, setIdPdf, setPdf }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [pais, setPais] = useState(null);

  const getCountry = async () => {
    const country = await Auth.currentUserInfo()
      .then((user) => {
        if (
          user.attributes["custom:cd_pais"] &&
          user.attributes["custom:cd_pais"] !== undefined &&
          user.attributes["custom:cd_pais"] !== null &&
          user.attributes["custom:cd_pais"] !== ""
        ) {
          return user.attributes["custom:cd_pais"];
        }
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
    setPais(country);
  };

  useEffect(() => {
    getCountry();
  }, []);

  const addPdf = () => {
    setIsAdding(true);
    setIdPdf(null);
    setPdf({
      id: null,
      description: "",
    });
  };

  const viewPdf = async (e, id) => {
    e.preventDefault();
    setIsAdding(false);
    setIdPdf(id);

    try {
      setIsDownloading(true);

      let existingPdfBytes = null;
      // Call to service
      existingPdfBytes = await EthicalCodeServices.downloadPdfById(
        id,
        pais ?? ""
      );

      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      const pdfBytes = await pdfDoc.save();

      // Figure out the file name
      let fileName = "report.pdf";
      if (Array.isArray(pdfs)) {
        const pdf = pdfs?.find((c) => c.id.toString() === id.toString());
        fileName = `${pdf?.description}`;
      }

      download(pdfBytes, fileName, "application/pdf");

      setIsDownloading(false);
    } catch (error) {
      errorMessage(null, "");
      setIsDownloading(false);
    }
  };

  return (
    <>
      {isDownloading ? (
        <div data-testid="loading" className={styles["full-spinner"]}>
          <div
            className={`${styles["spinner-border"]} ${styles["text-primary"]}`}
          ></div>
        </div>
      ) : null}

      {!isDownloading ? (
        <div className={styles["box"] + " " + styles["mb-4"]}>
          <div
            className={
              styles["tit-group"] +
              " " +
              styles["d-flex"] +
              " " +
              styles["justify-content-between"]
            }
          >
            <h2 className={styles["h4"] + " " + styles["tit-section"]}>Pdfs</h2>

            <a
              onClick={addPdf}
              className={
                styles["h5"] + " " + styles["mt-2"] + " " + styles["fl-normal"]
              }
            >
              <span
                className={styles["d-none"] + " " + styles["d-sm-inline-block"]}
              >
                Agregar
              </span>
              <i
                className={
                  styles["fa"] +
                  " " +
                  styles["fa-plus-square-o"] +
                  " " +
                  styles["fa-lg"] +
                  " " +
                  styles["ms-2"]
                }
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              ></i>
            </a>
          </div>

          {/* <!-- Escritorio (Tabla) --> */}
          <div className={styles["table-responsive"]}>
            <table
              className={
                styles.table +
                " " +
                styles["table-even"] +
                " " +
                styles["table-section"] +
                " " +
                styles["table-borderless td-none"] +
                " " +
                styles["d-none"] +
                " " +
                styles["d-md-table"]
              }
            >
              {!Array.isArray(pdfs) ||
              pdfs?.length === 0 ||
              pdfs === null ||
              pdfs === undefined ? (
                <caption>No se encontraron datos asociados.</caption>
              ) : null}

              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Fecha</th>
                  <th>Habilitado</th>
                  <th className={styles["text-center"]}>Ver</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(pdfs)
                  ? pdfs.map((i) => {
                      return (
                        <tr key={i.id}>
                          <td>{i.description}</td>
                          <td>{moment(i.date).format("DD/MM/YYYY")}</td>
                          <td>{i.enable ? "Sí" : "No"}</td>
                          <td className={styles["text-center"]}>
                            <a onClick={(e) => viewPdf(e, i.id)}>
                              <i
                                className={
                                  styles.fa +
                                  " " +
                                  styles["fa-eye"] +
                                  " " +
                                  styles["fa-fw"] +
                                  " " +
                                  styles["fa-lg"]
                                }
                                aria-hidden="true"
                                style={{ cursor: "pointer" }}
                              ></i>
                            </a>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>

          {/* <!-- Móvil (Acordeón) --> */}
          <div
            className={`accordion accordion-flush accordion-light accordion-table ${styles["d-md-none"]}`}
          >
            {/* <!-- Acordeón - ítems --> */}
            {Array.isArray(pdfs)
              ? pdfs.map((i, index) => {
                  return (
                    <div key={index} className="accordion-item">
                      <h2 className="accordion-header" id="accordion">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#accordion-item-${index}`}
                          aria-expanded="false"
                          aria-controls={`accordion-item-${index}`}
                        >
                          <strong>Nombre</strong> {" " + i?.description}
                        </button>
                      </h2>
                      <div
                        id={`accordion-item-${index}`}
                        className="accordion-collapse collapse"
                        aria-labelledby="accordion"
                      >
                        <div className={`accordion-body ${styles["p-0"]}`}>
                          {/* <!-- cuerpo item --> */}
                          <div
                            className={`${styles["data-list-box"]} ${styles["data-list-box-even"]}`}
                          >
                            <ul
                              className={`${styles["data-list-section--item"]} ${styles["list-unstyled"]}`}
                            >
                              <li>
                                <span
                                  className={styles["data-list-box__title"]}
                                >
                                  Fecha
                                </span>
                                <span className={styles["data-list-box__text"]}>
                                  {moment(i?.date).format("DD/MM/YYYY")}
                                </span>
                              </li>
                              <li>
                                <span
                                  className={styles["data-list-box__title"]}
                                >
                                  Habilitado
                                </span>
                                <span className={styles["data-list-box__text"]}>
                                  {i.enable ? "Sí" : "No"}
                                </span>
                              </li>
                              <li>
                                <span
                                  className={styles["data-list-box__title"]}
                                >
                                  Ver
                                </span>
                                <span className={styles["data-list-box__text"]}>
                                  <a onClick={(e) => viewPdf(e, i.id)}>
                                    <i
                                      className={
                                        styles["fa"] +
                                        " " +
                                        styles["fa-eye"] +
                                        " " +
                                        styles["fa-fw"] +
                                        " " +
                                        styles["fa-lg"]
                                      }
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </span>
                              </li>
                            </ul>
                          </div>
                          {/* <!-- fin cuerpo item --> */}
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
            {/* <!-- fin items acordeón --> */}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default List;
