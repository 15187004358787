import { useState, useEffect, useContext, FC } from "react";
import services from "./services"
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Context } from "../../context";
import { ContextInterface } from "../../context/utilities/interfaces";
import { NewsLetterItem, NewsLetterPost, NewsLetterStatus, NewsLetterProps } from "./types";
import Modal from "react-bootstrap/Modal";
const NewsLetter: FC = () => {
  const [newsLetterSubscribed, setNewsLetterSubscribed] = useState<boolean>(false);
  const [newsletter, setNewsletter] = useState<NewsLetterProps>({} as NewsLetterProps);
  const [categories, setCategories] = useState<string[]>([""]);
  const [newsLetterStatus, setNewsLetterStatus] = useState<NewsLetterStatus>({ show: false, status: "loading" });
  const [updatedAt, setUpdatedAt] = useState<string>("");
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const { register, handleSubmit, getValues, setValue } = useForm({ mode: "onChange" });
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const { showNewsletter, setShowNewsletter, showNewsletterButton } = useContext<ContextInterface>(Context);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const module = queryParams.get('module');
  const navigate = useNavigate();
 
 useEffect(() => {
    if(module !== 'newsletter'){
      setShowNewsletter(false)
    }
  }, []);

  useEffect(() => {
    handleOnChange();
  }, [newsLetterSubscribed]);

  useEffect(() => {
    if (showNewsletter) fetchData();
  }, []);
  
  const fetchData = async () => {
    const nl = await services.getNewsLetter();
    if (nl && nl.subscriptions && nl.subscriptions.length > 0) {
      const categories = nl.subscriptions.map((value:NewsLetterItem) => value.category).filter((value:NewsLetterItem, index:number, self:NewsLetterItem[]) => self.indexOf(value) === index);
      setCategories(categories);
      setNewsletter(nl);
      setDisabledButton(nl.subscriptions.every((value:NewsLetterItem) => value.selected === false) && !nl.subscribed);
      setUpdatedAt(nl.lastUpdate);
      setNewsLetterSubscribed(nl.subscribed);
    }
  };

  const save = async (request:NewsLetterPost) => {
    setNewsLetterStatus({ show: true, status: 'loading' });
    const data = await services.postNewsLetter( request );
    setNewsLetterStatus({ show: true,  status: data.valid ? 'success' : 'error' });
    fetchData();
  }
    // newsletter functions
    const onSubmit = (data:any) => {
      let request:NewsLetterPost = {
          request: {
            subscribed: true,
            subscriptions: []
        }
      };
      for (let key in data) {
        if (data[key]) {
          request.request.subscriptions.push(key);
        }
      }
      save(request);
    };
    const handleEliminarSuscripciones = () => {

      const currentValues = getValues();
      for (const key in currentValues) {
        if (currentValues.hasOwnProperty(key)) {
          setValue(key, false);
        }
      }
      let request:NewsLetterPost = {
          request: {
            subscribed: false,
            subscriptions: []
        }
      };
      save(request);
    };
      
    const handleOnChange = () => {
      const c1 = Object.values(getValues()).every(value => value === false);
      const c2 = newsLetterSubscribed;
      if ((c1 && c2) || (!c1 && !c2) || (c1 && !c2)) {
        setDisabledButton(true);
      } else {
        setDisabledButton(false);
      }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} >
        <div className="box mb-4">
          <div className="d-flex justify-content-between align-items-start pe-4 mb-4">
            <h2 className="h4 tit-section" > Suscripciones </h2>
            { updatedAt && updatedAt !== "" && <p className="mb-0 mt-1">Última modificación: {updatedAt}</p> }
          </div>
          {
            newsletter.subscriptions && newsletter.subscriptions[0].value !== "" &&
              <div className="container">
                <div className="row">
                  {
                    categories.map((category, index) =>
                      <div key={index} className="col-md-4 mb-2 mb-md-0">
                        <h3 className="h5 mb-3">{category}</h3>
                        <div className="ms-1">
                          {
                            newsletter.subscriptions.map((nl:NewsLetterItem, i:number) => category === nl.category &&
                              <div key={i} className="form-check mb-2 d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  id={nl.value}
                                  defaultChecked={nl.selected}
                                  className="form-check-input"
                                  {...register(nl.value, {
                                    onChange: () => { handleOnChange()}
                                  })}
                                />
                                <label className="form-check-label fw-normal ms-4" htmlFor={nl.value}>{nl.title}</label>
                              </div>
                            )
                          }
                        </div>
                      </div>)
                  }
                </div>
                <div className="pt-4 mt-4 border-top">
                  <div className="form-check mb-2 ms-1 mb-3">
                    <input
                      className="form-check-input h5"
                      type="checkbox"
                      defaultChecked={newsLetterSubscribed}
                      checked={newsLetterSubscribed}
                      onChange={() => { setNewsLetterSubscribed(!newsLetterSubscribed) }}
                    />
                    <label className="form-check-label fw-normal color-primary ms-2" htmlFor="consentimiento">
                      <strong>
                        Doy mi consentimiento para que Cencosud envíe información de mi interés como Noticias, Comunicados, Novedades sobre Beneficios, Servicios u otros, 
                        a mi correo personal, por mensaje de Texto o WhatsApp a mi número de teléfono y correos personal registrados en los sistemas de RR.HH.
                      </strong>
                    </label>
                  </div>
                  {/*
                  <div className="d-flex">
                    <i className="fa fa-exclamation-triangle fa-lg me-3 mt-1 color-red" aria-hidden="true"></i>
                    <p className="m-0 color-primary">El guardar los cambios generará un anexo relacionado que podrás revisar en tu Carpeta Digital</p>
                  </div>
                  */}
                  {
                    showNewsletter && showNewsletterButton && newsLetterSubscribed && newsletter.subscribed &&
                    <div className="d-flex justify-content-end mt-4"> 
                      <button
                        className="btn btn-primary"
                        onClick={(e) => { e.preventDefault(); setConfirmModal(true); }}
                        disabled={disabledButton}
                      >
                        Eliminar todas mis suscripciones
                      </button>
                    </div> 
                  }
                </div>
              </div>
          }
        </div>
        <div className="d-grid d-md-flex justify-content-md-between gap-2 flex-nowrap mt-5">
          { newsletter.subscriptions && newsletter.subscriptions[0].value !== "" && <div className="order-md-last d-grid d-md-flex gap-2">
              <button
                type="submit"
                className="btn btn-degradado"
                data-bs-toggle="modal"
                disabled={disabledButton}
                data-bs-target="#success"
              >Guardar<i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
              </button>
            </div>
          }
          <button onClick={()=> { setShowNewsletter(false); navigate("/noticias") }}type="button" className="btn btn-link order-md-first">Volver</button>
        </div>
        <Modal 
          show={newsLetterStatus.show} id="newsletterstatus" centered onHide={() => { setNewsLetterStatus({ show: false, status: "loading" }); }}>
          <Modal.Header className="border-0 pb-0" closeButton />
          <Modal.Body className="text-center py-4">
            {
              newsLetterStatus.status === 'loading' && <>
                  <div className="text-center mb-4 ">
                      <span className="spinner-border spinner-border-xl" />
                  </div>
                  <h2 className="h5 mb-2">Estamos guardando los cambios</h2>
              </>
            }
            {
              newsLetterStatus.status === 'error' && <>
                <i className="fa fa-times color-orange fa-3x mb-4" aria-hidden="true" />
                <h2 className="h5 mb-2">Ocurrió un error al guardar los cambios</h2>
              </>
            }
            {
              newsLetterStatus.status === 'success' && <>
                <i className="fa fa-check-circle color-orange fa-3x mb-4" aria-hidden="true" />
                <h2 className="h5 mb-2">Cambios guardados exitosamente.</h2>
                <p className="mb-2">Las nuevas actualizaciones han quedado guardadas.</p>
              </>
            }
          </Modal.Body>
        </Modal>
        <Modal show={confirmModal} id="confirm" centered onHide={() => { setConfirmModal(false); }}>
          <Modal.Header className="border-0 pb-0" closeButton />
          <Modal.Body className="text-center py-4">
            <i className="fa fa-exclamation-triangle fa-3x color-orange mb-4" aria-hidden="true" />
            <h2 className="h5 mb-2">¿Estás seguro que deseas eliminar todas tus suscripciones?</h2>
            <p className="mb-2">Si eliminas tus suscripciones, no recibirás más correos de Noticias, Novedades sobre Beneficios, Servicios y más.</p>
            <div className="d-flex justify-content-center mt-4">
              <button className="btn btn-degradado" onClick={() => { handleEliminarSuscripciones(); setConfirmModal(false); }}>Eliminar<i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
            </div>
          </Modal.Body>
        </Modal>
      </form>
    )

}

export default NewsLetter;
