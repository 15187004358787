import React from 'react';
import { Link } from 'react-router-dom';

interface CardProps {
  imageSrc: string;
  title: string;
  link: string;
}

const Card: React.FC<CardProps> = ({ imageSrc, title, link }) => {
  return (
    <div className="col d-flex">
      <article className="box-card d-flex flex-column align-items-stretch w-100 overflow-hidden">
        <img className="img-fluid w-100" src={imageSrc} alt={title} />
        <div className="d-flex flex-column justify-content-between h-100">
          <h3 className="fs-5 m-3 mb-md-4">
            <Link className="color-normal text-decoration-none" to={link}>
              {title}
            </Link>
          </h3>
          <div className="text-end m-3">
            <Link className="btn btn-degradado ps-3 pe-3" to={link} target="_blank">
              Ingresar <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </Link>
          </div>
        </div>
      </article>
    </div>
  );
};

export default Card;
