import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import Highlighter from '../../../commons/highlighter'
import CollapseList from '../../../commons/collapseList/collapseList'
import { SubCategory as SubcategoryType, Article } from '../../../utilities/interfaces';
import { SubCategoryProps } from './interfaces';
import textstyle from '../../../commons/styles/textStyles.module.css'
import categoryStyle from '../styles/categoryStyles.module.css'
import collapseStyle from '../../../commons/collapseList/collapseListStyle.module.css'

const SubCategory = ({ subCategory, categoryName }: SubCategoryProps) => {
  const [subCategoryInfo, setSubCategoryInfo] = useState<SubcategoryType>()
  const [articlesList, setArticlesList] = useState<Article[]>([])

  useEffect(() => {
    if (subCategory) {
      setSubCategoryInfo(subCategory)
      getSubCategoriesArticles(subCategory.attributes.articles.data)
    }
  }, [subCategory])

  const getSubCategoriesArticles = (articles: Article[]) => {
    if (!articles || articles.length === 0) return
    setArticlesList(articles)
  }

  const subcategoryName = (): string => {
    const title = subCategoryInfo?.attributes.title ? subCategoryInfo?.attributes.title : ''
    return title
  }

  return (
    <Grid item xs={12} className={collapseStyle.subcategory}>
      <h2 className={`${textstyle.section_title} ${categoryStyle.subcategory_title}`}>
        {subCategoryInfo?.attributes.title}
      </h2>
      <Highlighter width={35} />
      <CollapseList
        articlelist={articlesList}
        categoryName={categoryName}
        subCategoryName={subcategoryName()}
        maxItems={5}
      />
    </Grid>
  )
}

export default SubCategory