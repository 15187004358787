import React, { useState } from 'react';
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button
} from 'reactstrap';
import Swal from 'sweetalert2';

import menuService from '../../services/permissions/access-service';

const ModalConfirmDeleteRol = ({
	pageSelected,
    rolSelected,
	isOpen,
	toggle,
	getPages
}) => {
	const handleDesAssignRolToPage = async () => {
		try {
			const payload = {
				contentreferenceitemid: pageSelected,
				rol: rolSelected
			};
			await menuService.desassignRolToPage(payload);

			Swal.fire({
				allowOutsideClick: false,
				position: 'center',
				title: `<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px">Accion realizada</div>`,
				html: `<div style="text-align: center; font-size: 15px">Rol desasignado correctamente</div>`,
				confirmButtonColor: '#0169b5',
			}).then(() => {
				getPages();
			})
		} catch(err) {
			console.log(err);
			Swal.fire({
				allowOutsideClick: false,
				position: 'center',
				title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
				html: `<div style="text-align: center; font-size: 15px">${err?.response?.data?.message}</div>`,
				confirmButtonColor: '#0169b5',
			})
		} finally {
			toggle();
		}
	}

	return (
		<Modal isOpen={isOpen} toggle={toggle}>
			<ModalHeader toggle={toggle}>
				Quitar rol
			</ModalHeader>
			<ModalBody>
				¿Esta seguro que desea quitar el rol de la pantalla?
			</ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={handleDesAssignRolToPage}>
					Guardar
				</Button>{' '}
				<Button color="secondary" onClick={toggle}>
					Cancelar
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ModalConfirmDeleteRol;