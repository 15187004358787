import React, { useEffect, useRef, useState } from 'react'
import styles from '../../styles/errorStyle.module.css'
import { Grid } from '@mui/material'
import {ErrorContent} from '../../../../utilities/constantsTexts'
import ErrorButtons from '../actions/errorButtons'

const TimeoutError = () => {
  const notFoundImg : string = require('../../../../assets/errors/error504.svg').default;
  const parts = ErrorContent.title504.split('.')


  return (
    <Grid container className={styles.error_container}>
      <Grid item className={styles.error_img_container}>
        <img src={notFoundImg} className={styles.error_img}/>
      </Grid>
      <Grid item>
        <h1 className={styles.error_title}>{parts[0]}.</h1>
        <h1 className={styles.error_title}>{parts[1]}.</h1>
        <h3 className={styles.error_subtitle}>{ErrorContent.subtitle504}</h3>
      </Grid>
      <ErrorButtons/>
    </Grid>
  );
}

export default TimeoutError;