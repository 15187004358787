import React from 'react'
import Swal from 'sweetalert2';

//funcion que ejecuta callbacks de manera dinamica
export const MessageDinamic = async(type,title,message) => {

    let color = "";
    let confirmButtonColor = "";
    switch (type) {
        case "success":
            color = "#4CAF50";
            confirmButtonColor = "#0169b5";
            break;
        case "error":
            color = "#F44336";
            confirmButtonColor = "#0169b5";
            break;
        case "warning":
            color = "#FFC107";
            confirmButtonColor = "#0169b5";
            break;
        case "info":
            color = "#2196F3";
            confirmButtonColor = "#0169b5";
            break;
        case "question":
            color = "#2196F3";
            confirmButtonColor = "#0169b5";
            break;
        default:
            color = "#2196F3";
            confirmButtonColor = "#0169b5";
            break;
    }
 
   Swal.fire({
        allowOutsideClick: false,
        position: 'center',
        title: `<div style="text-align: center; color: ${color}; font-size: 16px">${title}</div>`,
        html: `<div style="text-align: center; font-size: 15px">${message}</div>`,
        confirmButtonColor,
      });
}
