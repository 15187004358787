import React, { useContext } from "react";
import { httpClientEnv } from "../utilities/apiServices/httpClient";
import VerifyDocumentPage from "./verifyDocument";

export const HttpClientContext = React.createContext(httpClientEnv);
export const useHttpClient = () => useContext(HttpClientContext);

export const VerifyDocument = () => {
    return (
        <HttpClientContext.Provider value={httpClientEnv}>
            <VerifyDocumentPage />
        </HttpClientContext.Provider>
    )
};
