import React, { FC } from "react";

interface DownloadingAnnualBondProps {
    downloadingError: boolean;
    setDownloadingError: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalDownloadingAnnualBond: FC<DownloadingAnnualBondProps> = ({ downloadingError, setDownloadingError }) => {
    return (
        <>
            <div className="modal fade" id="downloadingModal" tabIndex={-1} aria-labelledby="downloadingModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button id="closeDownloadingAnnualBondModal" hidden={!downloadingError} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setDownloadingError(false)} />
                        </div>
                        <div className="modal-body text-center py-4">
                        {!downloadingError && <i className="fa-sharp fa-solid fa-file-arrow-down" />}
                        {downloadingError && <i className="fa-solid fa-triangle-exclamation" />}
                        <h2 className="h5">
                            {downloadingError ? "Error al descargar el documento" : "Descargando..."}
                        </h2>
                        </div>
                    </div>
                </div>
            </div>
            <button hidden id={"downloadingAnnualBondModal"} data-bs-toggle={"modal"} data-bs-target={"#downloadingModal"} data-bs-backdrop="static" data-bs-keyboard="false" />
        </>
    ) 
};

export default ModalDownloadingAnnualBond;