import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import React, { Children, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import datosLaboralesService from "../../services/datos-laborales-service";
import { urlsAws } from "../../resources/foo/api-endpoints";
import { decrypt } from "../../services/utiles";
import vacacionesService from "../../services/vacaciones-service";
import CarrouselVacaciones from "../elements/vacaciones-carrousel";

const VerDetalle = () => {
    const [, setLoginData] = useState({});
    const [getVacacionesSubAlterno, setVacacionesSubAlterno] = useState(null);
    const [respuestaSolicitudText, setRespuestaSolicitudText] = useState("");
    const [respuestaSolicitudValid, setRespuestaSolicitudValid] = useState(false);
    const [rowsYear, setRowsYear] = useState([]);
    const [rowsYearOb, setRowsYearOb] = useState([]);
    const [pdf, setPdf] = useState("");
    const [misDias, setMisDias] = useState(0);
    const [cargando, setCargando] = useState(true);
    const [existDataModal, setExistDataModal] = useState(false);
    const [existDataModalComu, setExistDataModalComu] = useState(false);
    const [emplid, setEmplid] = useState(localStorage.getItem("emplid"));
    const [diasDisponibles, setDiasDisponibles] = useState(null);
    const [textmodal, setTextModal] = useState("Cargando información..");
    const [tieneNet, setTieneNet] = useState(true);
    const [noExisteData, setnoExisteData] = useState(false);

    //paginador
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [matrix, setMatrix] = useState([]);
    const itemsPerPage = 5;
    const [items, setItems] = useState(null);
    const rowAnio = [];
    const rowAnioNoRepetido = [];
    const rowFechaInicio = [];
    const rowFechaTermino = [];
    const rowDias = [];
    const rowEstado = [];
    const rowDownload = [];
    const [resetOffset, setResetOffset] = useState(false);
    const [encabezados, setEncabezados] = useState(null);

    const transpose = (matrix) => {
        return matrix.reduce(
            (prev, next) => next.map((item, i) => (prev[i] || []).concat(next[i])),
            []
        );
    };

    useEffect(() => {
        if (
            items !== "error" &&
            Array.isArray(items) &&
            items.length > 0 &&
            items
        ) {
            let newMatrix = items.map((item) => {
                return item.rows;
            });
            setMatrix(transpose(newMatrix));
        }
    }, [items]);

    useEffect(() => {
        if (matrix !== "error" && Array.isArray(matrix) && matrix.length > 0) {
            let temp = matrix.slice(itemOffset, itemOffset + itemsPerPage);

            setCurrentItems(temp);
            setPageCount(Math.ceil(matrix.length / itemsPerPage));
        } else if (
            matrix !== "error" &&
            Array.isArray(matrix) &&
            matrix.length === 0
        ) {
            setCurrentItems(null);
            setPageCount(0);
        }
    }, [itemOffset, itemsPerPage, matrix]);

    useEffect(() => {
        if (resetOffset) {
            setItemOffset(0);
            setResetOffset(false);
        }
    }, [resetOffset]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % matrix.length;
        setItemOffset(newOffset);
    };

    //fin paginador

    const handleClose = () => {
        setRespuestaSolicitudValid(false);
        setExistDataModal(false);
        setExistDataModalComu(false);
        setTieneNet(true);
        setnoExisteData(false);
    };
    let navigate = useNavigate();

    var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    useEffect(() => {
        if (emplid !== null) {
            vacacionesService.getDetalleDiasDisponibles(setDiasDisponibles, emplid);
            vacacionesService.getDetalleVacaciones(setVacacionesSubAlterno, emplid);
            caches.match(urlsAws.firstLogin).then((response) => {
                if (response && response.body !== null) {
                    response.json().then((data) => {
                        setLoginData(data);
                    });
                } else {
                    datosLaboralesService.firstLogin(emplid);
                }
            });
        } else {
            navigate("/");
        }
    }, [emplid]);

    useEffect(() => {
        if (diasDisponibles) {
            if (decrypt(diasDisponibles.balance) !== undefined) {
                setMisDias(decrypt(diasDisponibles.balance));
            }
        }
    }, [diasDisponibles]);

    useEffect(() => {
        //console.log("pdf: ", pdf);
        if (pdf === 2) {
            if (navigator.onLine) {
                setCargando(false);
                setExistDataModal(true);
            } else {
                setRespuestaSolicitudValid(true);
                setCargando(false);
                setPdf("");
                setRespuestaSolicitudText(
                    "La conexión no está disponible en estos momentos, favor de intentar más tarde."
                );
            }
        } else {
            if (pdf !== undefined) {
                if (pdf !== "") {
                    desencriptarArchivo(pdf.archivo, "documento_vacaciones");
                    setPdf("");
                    setCargando(false);
                }
            } else {
                setCargando(false);
                setExistDataModal(true);
            }
        }
    }, [pdf]);

    const isEmpty = (obj) => {
        for (var prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                return false;
            }
        }
        return JSON.stringify(obj) === JSON.stringify({});
    };

    useEffect(() => {
        if (!isEmpty(groupBy(rowsYear, "anio"))) {
            //console.log('aa: ',Object.keys(groupBy(rowsYear, "anio")))
            rowsYearOb.push(Object.keys(groupBy(rowsYear, "anio")));
            rowsYearOb.pop();
        }

        // console.log('reas2: ',rowsYearOb)
    }, [rowsYear.length]);

    if (rowsYearOb.length <= 0) {
        if (!isEmpty(groupBy(rowsYear, "anio"))) {
            rowsYearOb.push(Object.keys(groupBy(rowsYear, "anio")));
            //   console.log('reas: ',rowsYearOb)
        }
    }
    function formato(texto) {
        return texto.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1");
    }

    useEffect(() => {
        if (getVacacionesSubAlterno) {
            setCargando(false);
            if (getVacacionesSubAlterno.vacaciones) {
                if (getVacacionesSubAlterno.vacaciones.length > 0) {
                    getVacacionesSubAlterno.vacaciones.map((e) => {
                        rowAnio.push(decrypt(e.year));
                        if (!rowAnioNoRepetido.includes(decrypt(e.year))) {
                            rowAnioNoRepetido.push(decrypt(e.year));
                        }

                        rowFechaInicio.push(formato(decrypt(e.fecha_inicio)));
                        rowFechaTermino.push(formato(decrypt(e.fecha_retorno)));
                        rowDias.push(decrypt(e.duracion_dias));

                        let fechaDeInicio = decrypt(e.fecha_inicio);

                        if (decrypt(e.approved_flag) === "Y") {
                            rowEstado.push("Aprobado");
                            rowDownload.push(
                                <a href="#" onClick={(e) => downloadPDF(e, fechaDeInicio)}>
                                    <i
                                        className="fa fa-file-pdf-o fa-fw me-2"
                                        aria-hidden="true"
                                    ></i>
                                </a>
                            );
                        } else {
                            rowEstado.push("Pendiente");
                            rowDownload.push("");
                        }
                    });

                    if (
                        rowAnio.length > 0 ||
                        rowFechaInicio.length > 0 ||
                        rowFechaTermino.length > 0 ||
                        rowDias.length > 0 ||
                        rowEstado.length > 0
                    )
                        setEncabezados(rowFechaInicio);
                    setItems([
                        {
                            title: "Año",
                            rows: rowAnio,
                        },
                        {
                            title: "Fecha inicio",
                            rows: rowFechaInicio,
                        },
                        {
                            title: "Fecha término",
                            rows: rowFechaTermino,
                        },
                        {
                            title: "Días",
                            rows: rowDias,
                        },
                        {
                            title: "Estado",
                            rows: rowEstado,
                        },
                        {
                            title: "Descargar",
                            rows: rowDownload,
                        },
                    ]);
                }
            } else {
                setnoExisteData(true);
            }
        }
    }, [getVacacionesSubAlterno]);

    useEffect(() => {
        if (items) {
            undefined;
        }
    }, [items]);

    const desencriptarArchivo = (value, name) => {
        name = name.replaceAll(" ", "").replaceAll(".", "");
        value = Buffer.from(value, "base64");
        let link = document.createElement("a");
        let blob = new Blob([value], { type: "application/pdf" });
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.dispatchEvent(new MouseEvent("click"));
    };

    const downloadPDF = (e, fechaInicio) => {
        e.preventDefault();
        vacacionesService.papeletaVacaciones(setPdf, fechaInicio, emplid);
        setTextModal("Descargando información.");
        setCargando(true);
    };

    return (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i className="fa fa-home" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Días tomados - Papeleta
                    </li>
                </ol>
            </nav>
            <section className="vacaciones">
                <div className="tit-group d-block d-xxl-flex justify-content-between">
                    <h1>Vacaciones</h1>
                    <div className="saldo-vacaciones rounded-3 bg-light p-3 color-primary mb-4">
                        <div className="row align-items-center">
                            <label
                                htmlFor="inputPassword"
                                className="col-6 col-sm-4 col-md-3 col-form-label"
                            >
                                Saldo Vacaciones:
                            </label>
                            <div
                                className="col-3 col-md-2 col-xl-1"
                                style={{ width: "100px" }}
                            >
                                <input
                                    type="text"
                                    className="form-control text-center bg-white"
                                    value={misDias}
                                    disabled
                                />
                            </div>
                            <small className="col-12 col-md-6 col-lg-7 col-xl-15 mt-4 mt-md-0">
                                * Su saldo de vacaciones actual, incluye los días futuros
                                aprobados
                            </small>
                        </div>
                    </div>
                </div>

                <CarrouselVacaciones tituloSelect="Días tomados / Papeleta"></CarrouselVacaciones>
                <div className="box mb-4">
                    <h2 className="h4 tit-section">Detalle Vacaciones</h2>
                    <table className="table table-even table-section table-borderless align-middle d-none d-md-table">
                        <thead>
                            <tr>
                                {items
                                    ? Children.toArray(
                                        items.map((column, index) => {
                                            return <th key={"array1" + index}>{column.title}</th>;
                                        })
                                    )
                                    : null}
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems ? (
                                Children.toArray(
                                    currentItems.map((rows, index) => {
                                        return (
                                            <tr key={"array2" + index}>
                                                {Children.toArray(
                                                    rows.map((value, index) => {
                                                        return <td key={"array3" + index}>{value}</td>;
                                                    })
                                                )}
                                            </tr>
                                        );
                                    })
                                )
                            ) : (
                                <></>
                            )}
                        </tbody>
                    </table>

                    <div className="accordion accordion-flush d-block d-md-none accordion-light accordion-table">
                        {currentItems ? (
                            Children.toArray(
                                currentItems.map((rows, i) => {
                                    return (
                                        <>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id={"acordeon-" + i}>
                                                    <button
                                                        className={
                                                            i === 0
                                                                ? "accordion-button"
                                                                : "accordion-button collapsed"
                                                        }
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={"#acordeon-item-" + i}
                                                        aria-expanded="true"
                                                        aria-controls={"accordion-item-" + i}
                                                    >
                                                        <strong className="text-uppercase">Periodo </strong>
                                                        {encabezados[i]}
                                                    </button>
                                                </h2>
                                                <div
                                                    id={"acordeon-item-" + i}
                                                    className={
                                                        i === 0
                                                            ? "accordion-collapse collapse show"
                                                            : "accordion-collapse collapse "
                                                    }
                                                    aria-labelledby={"acordeon-" + i}
                                                >
                                                    <div className="accordion-body p-0">
                                                        <div className="data-list-box data-list-box-even">
                                                            <ul className="data-list-section--item list-unstyled">
                                                                {Children.toArray(
                                                                    rows.map((value, index) => {
                                                                        return (
                                                                            <li key={"array5" + index}>
                                                                                <span className="data-list-box__title col-12 col-sm-4">
                                                                                    {items[index].title}
                                                                                </span>
                                                                                <span className="data-list-box__text">
                                                                                    {value}
                                                                                </span>
                                                                            </li>
                                                                        );
                                                                    })
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    );
                                })
                            )
                        ) : (
                            <></>
                        )}
                    </div>
                </div>

                {currentItems ? (
                    <nav className="mt-5" aria-label="Paginación">
                        <ReactPaginate
                            id={"paginationnav"}
                            className="pagination justify-content-center align-items-center"
                            breakLabel=" ... "
                            nextLabel=" > "
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={0}
                            marginPagesDisplayed={1}
                            pageCount={pageCount}
                            previousLabel=" < "
                            renderOnZeroPageCount={null}
                            previousLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                            breakLinkClassName={"page-link"}
                            pageLinkClassName={"page-link"}
                            pageClassName={"page-item"}
                            breakClassName={"page-item"}
                            previousClassName={"page-item"}
                            nextClassName={"page-item"}
                            activeClassName={"page-item active"}
                            activeLinkClassName={"page-link active"}
                        />
                    </nav>
                ) : null}
            </section>
            {cargando ? (
                <>
                    <div className="col">
                        <div
                            className="modal fade show"
                            id="foto-credencial"
                            tabIndex={"-1"}
                            aria-labelledby="enviar-por-correo"
                            aria-modal="true"
                            role="dialog"
                            style={{ display: "block", textAlign: "left" }}
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header border-0 pb-0"></div>
                                    <div className="modal-body text-center py-4">
                                        <i
                                            className="fa fa-download color-orange fa-3x mb-2"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            aria-hidden="true"
                                        ></i>
                                        <h2 className="h5">{textmodal}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
            {existDataModal ? (
                <>
                    <div className="col">
                        <div
                            className="modal fade show"
                            id="foto-credencial"
                            tabIndex={"-1"}
                            aria-labelledby="enviar-por-correo"
                            aria-modal="true"
                            role="dialog"
                            style={{ display: "block", textAlign: "left" }}
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header border-0 pb-0">
                                        <button
                                            type="submit"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            id="enviar"
                                            onClick={(e) => handleClose(e)}
                                        ></button>
                                    </div>
                                    <div className="modal-body text-center py-4">
                                        <i
                                            className="fa fa-download color-orange fa-3x mb-2"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            aria-hidden="true"
                                        ></i>
                                        <h2 className="h5">Días tomados - Papeleta</h2>
                                        <p>No se ha encontrado información.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
            {noExisteData ? (
                <>
                    <div className="col">
                        <div
                            className="modal fade show"
                            id="foto-credencial"
                            tabIndex={"-1"}
                            aria-labelledby="enviar-por-correo"
                            aria-modal="true"
                            role="dialog"
                            style={{ display: "block", textAlign: "left" }}
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header border-0 pb-0">
                                        <button
                                            type="submit"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            id="enviar"
                                            onClick={(e) => handleClose(e)}
                                        ></button>
                                    </div>
                                    <div className="modal-body text-center py-4">
                                        <i
                                            className="fa fa-download color-orange fa-3x mb-2"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            aria-hidden="true"
                                        ></i>
                                        <h2 className="h5">Días tomados - Papeleta</h2>
                                        <p>No se ha encontrado información.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
            {existDataModalComu ? (
                <>
                    <div className="col">
                        <div
                            className="modal fade show"
                            id="foto-credencial"
                            tabIndex={"-1"}
                            aria-labelledby="enviar-por-correo"
                            aria-modal="true"
                            role="dialog"
                            style={{ display: "block", textAlign: "left" }}
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header border-0 pb-0">
                                        <button
                                            type="submit"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            id="enviar"
                                            onClick={(e) => handleClose(e)}
                                        ></button>
                                    </div>
                                    <div className="modal-body text-center py-4">
                                        <i
                                            className="fa fa-download color-orange fa-3x mb-2"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            aria-hidden="true"
                                        ></i>
                                        <h2 className="h5">Días tomados - Papeleta</h2>
                                        <p>
                                            Se ha producido un error de comunicación, vuelva a
                                            intentarlo más tarde.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
            {respuestaSolicitudValid ? (
                <>
                    <div className="col">
                        <div
                            className="modal fade show"
                            id="foto-credencial"
                            tabIndex={"-1"}
                            aria-labelledby="enviar-por-correo"
                            aria-modal="true"
                            role="dialog"
                            style={{ display: "block", textAlign: "left" }}
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header border-0 pb-0">
                                        <button
                                            type="submit"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            id="enviar"
                                            onClick={(e) => handleClose(e)}
                                        ></button>
                                    </div>
                                    <div className="modal-body text-center py-4">
                                        <h2 className="h5">Solicitud de vacaciones.</h2>
                                        <p>{respuestaSolicitudText}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
            {!tieneNet ? (
                <>
                    <div className="col">
                        <div
                            className="modal fade show"
                            id="foto-credencial"
                            tabIndex={"-1"}
                            aria-labelledby="enviar-por-correo"
                            aria-modal="true"
                            role="dialog"
                            style={{ display: "block", textAlign: "left" }}
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header border-0 pb-0">
                                        <button
                                            type="submit"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            id="enviar"
                                            onClick={(e) => handleClose(e)}
                                        ></button>
                                    </div>
                                    <div className="modal-body text-center py-4">
                                        <i
                                            className="fa fa-exclamation-triangle color-orange fa-3x"
                                            aria-hidden="true"
                                        ></i>
                                        <h2 className="h5">No tienes conexión</h2>
                                        <p>No puedes ejecutar esta acción si no tienes conexión</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default VerDetalle;
