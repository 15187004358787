import { Base64 } from "js-base64";
import React, { Children, useEffect, useState } from "react";
import CarpetaDigitalService from "../services/carpeta-digital-service";
import Modales from "./elements/modal-dt";

const CarpetaDigital = (props) => {
    const rut = localStorage.getItem("emplid");
    const [data, setData] = useState(false);
    const [status, setStatus] = useState("cargando...");
    const [, setStatusDescarga] = useState(false);
    const [doc, setDoc] = useState(false);
    const [docSeleccionado, setDocSeleccionado] = useState(false);
    const [email, setEmail] = useState("");
    const [statusEmail, setStatusEmail] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const ModalBasico = Modales.ModalBasico;

    useEffect(() => {
        if (rut != undefined) {
            CarpetaDigitalService.getCarpetaDigital(rut, setData, setStatus);
        }
    }, [rut])

    const descargarArchivo = (e, email) => {
        e.preventDefault();
        if (email == "" && docSeleccionado != false) {
            document.getElementById("openBtnDescargarCarpetaDigital").click();
            CarpetaDigitalService.descargarDocumento(rut, docSeleccionado, setDoc, setStatusDescarga, "")
        } else if (email != "" && docSeleccionado != false) {
            setButtonDisabled(true);
            setTimeout(() => {
                document.getElementById("cerrarModalCarpetaDigitalEmail").click();
                document.getElementById("openBtnEmailCarpetaDigital").click();
            }, 1000)
            CarpetaDigitalService.descargarDocumento(rut, docSeleccionado, setDoc, setStatusEmail, email)
        }
        document.getElementById("emailCarpetaDigital").value = props.email;
        
    }

    useEffect(() => {
        if (doc != false && doc != 1) {
            let name = data.filter(data => data.codigo == docSeleccionado)[0];
            let byte = base64ToArrayBuffer(doc);
            saveByteArray(name.descripcion, byte);
        } else if (doc == 1) {
            setTimeout(() => {
                document.getElementById("closeModalDescargaCarpetaDigital").click();
                document.getElementById("openModalNoDocumentoCarpetaDigital").click();
            }, 1000);
            setDoc(false);
        }
    }, [doc])

    function base64ToArrayBuffer(base64) {
        let binaryString = Base64.atob(base64);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    function saveByteArray(reportName, byte) {
        if (reportName.includes(".")) {
            reportName = reportName.replaceAll(".", "-");
        }
        let blob = new Blob([byte], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        let fileName = reportName;
        link.download = fileName;
        link.dispatchEvent(new MouseEvent("click"));
        setTimeout(() => {
            document.getElementById("closeModalDescargaCarpetaDigital").click();
        }, 1000);
        setDoc(false)
    }

    const limpiarModal = () => {
        setEmail("");
    };

    useEffect(() => {
        if (statusEmail) {
            setTimeout(() => {
                document.getElementById("closeModalEnviandoEmailCarpetaDigital").click();
                document.getElementById("btnEmailEnviadoCarpetaDigital").click();
            }, 1000);
            setStatusEmail(false);
        }
    }, [statusEmail])

    const abrirModalCorreo = () => {
        setButtonDisabled(false);
        setEmail(props.email);
        if (navigator.onLine) {
            document.getElementById("abrirModalCorreoCarpetaDigital").click();
        } else {
            document.getElementById("sinConexionCarpetaDigital").click();
        }
    };
    return (
        <>
            <ModalBasico idBtn="openBtnDescargarCarpetaDigital" target="modalDescargarCarpetaDigital" idModal="modalDescargarCarpetaDigital" msj="Descargando..." p="" modalCierre="closeModalDescargaCarpetaDigital" icon="fa fa-download color-orange fa-3x mb-2" ></ModalBasico>
            <ModalBasico idBtn="openBtnEmailCarpetaDigital" target="modalEmailCarpetaDigital" idModal="modalEmailCarpetaDigital" msj="Enviando Correo..." p="" modalCierre="closeModalEnviandoEmailCarpetaDigital" icon="fa fa-envelope-o color-orange fa-3x mb-2" ></ModalBasico>
            <ModalBasico idBtn="openModalNoDocumentoCarpetaDigital" target="modalNoHayDocumentosCarpetaDigital" idModal="modalNoHayDocumentosCarpetaDigital" msj="No hay documentos" p="No se encontraron documento" modalCierre="closeModalBasicoCarpetaDigital" icon="fa fa-exclamation-triangle color-orange fa-3x" ></ModalBasico>
            <section className="mis-documentos">
                <div className="box pb-5">
                    <h2 className="h4 tit-section">Buscar documento</h2>
                    <div className="row">
                        <div className="col-12 col-xl-6 mb-4 mb-xl-0">
                            <select defaultValue={"false"} onChange={(e) => setDocSeleccionado(e.target.value)} id="inputState" className="form-select" required="required">
                                {data != false && data != 1 && !status ?
                                    <>
                                        <option disabled={docSeleccionado != false ? true : false} value="false">Seleccione Documento</option>
                                        {Children.toArray(data.map((document, i) => {
                                            return <>
                                                <option key={"docCarpeta" + i} value={document.codigo}>{document.descripcion}</option>
                                            </>
                                        }))}
                                    </>
                                    : <option disabled={docSeleccionado != false ? true : false} value="false">{status}</option>}
                            </select>
                        </div>
                        <div className="col d-grid d-md-block">
                            {docSeleccionado != false ?
                                <>
                                    <button type="button" disabled={docSeleccionado == false ? true : false} className="btn btn-degradado me-md-2 mb-2" onClick={(e) => descargarArchivo(e, "")} >Descargar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                    <button type="button" disabled={docSeleccionado == false ? true : false} className="btn btn-primary mb-2" onClick={() => abrirModalCorreo()}>Enviar por correo <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                </>
                                : null
                            }
                            <a hidden id="abrirModalCorreoCarpetaDigital" data-bs-toggle="modal" data-bs-target="#enviar-correo-carpeta-digital"></a>
                            <div className="modal fade" id="enviar-correo-carpeta-digital" tabIndex="-1" aria-labelledby="enviar-correo-carpeta-digital" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <form onSubmit={(e) => descargarArchivo(e, email)}>
                                            <div className="modal-header border-0 pb-0">
                                                <button type="button" className="btn-close" onClick={() => limpiarModal()} data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <h2 className="h5 mb-2">Enviar a:</h2>
                                                <div className="mb-3">
                                                    <label className="form-label mb-2">
                                                        <strong>Dirección de correo electrónico</strong>
                                                    </label>
                                                    <input
                                                        type="email" id="emailCarpetaDigital" pattern="^[^@]+@[^@]+\.[a-zA-Z]{2,}" className="form-control"
                                                        placeholder="mailcontacto@cencosud.com" 
                                                        onInput={(e) => e.target.setCustomValidity("")} 
                                                        onInvalid={(e) => e.target.setCustomValidity("Correo Invalido")} 
                                                        onChange={(e) => { setEmail(e.target.value); e.target.value.match("^[^@]+@[^@]+\.[a-zA-Z]{2,}") ? document.getElementById("btnEmailCarpeta").disabled = false : document.getElementById("btnEmailCarpeta").disabled = true }} 
                                                        defaultValue={props.email} required>
                                                    </input>
                                                </div>
                                            </div>
                                            <div className="modal-footer border-0 pt-0">
                                                <button onClick={() => limpiarModal()} type="button" id="cerrarModalCarpetaDigitalEmail" className="btn btn-primary col-12 col-md-auto" data-bs-dismiss="modal">Cancelar{" "}
                                                    <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                                                </button>
                                                <button type="submit" id="btnEmailCarpeta"  disabled={buttonDisabled} className="btn btn-degradado col-12 col-md-auto">Confirmar<i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <a data-bs-toggle="modal" data-bs-target="#emailEnviadoCarpetaDigital" hidden id="btnEmailEnviadoCarpetaDigital"></a>
                            <div className="modal fade" id="emailEnviadoCarpetaDigital" tabIndex="-1" aria-labelledby="emailEnviadoCarpetaDigital" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body text-center py-4">
                                            <i className="fa fa-check-circle-o color-orange fa-3x" aria-hidden="true"></i>
                                            <h2 className="h5">Correo enviado exitosamente</h2>
                                            <p>Su documento ha sido enviado a la dirección de correo electrónica proporcionada.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <a hidden id="sinConexionCarpetaDigital" data-bs-toggle="modal" data-bs-target="#sinConexionModalCarpetaDigital"></a>
                            <div className="modal fade" id="sinConexionModalCarpetaDigital" tabIndex="-1" aria-labelledby="sinConexionModalCarpetaDigital" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header border-0 pb-0">
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body text-center py-4">
                                            <i className="fa fa-exclamation-triangle color-orange fa-3x" aria-hidden="true"></i>
                                            <h2 className="h5">No tienes conexión</h2>
                                            <p>
                                                No puedes ejecutar esta acción si no estás conectado.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default CarpetaDigital;