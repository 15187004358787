import React from 'react'

export const CommentsCmp = ({label,stateValue,callback}) => {
  return (
    <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="fields">
              <div className="label fw-bold">{label}</div>
              <textarea
                disabled={false}
                type="text"
                rows="2"
                value={stateValue}
                name="applicantComments"
                className="form-control"
                onChange={(e) => {
                 callback(e.target.value)
                }}
              ></textarea>
            </div>
          </div>
        </div>
  )
}
