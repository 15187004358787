import React, { useState, useRef, useEffect } from 'react';
import Swal from 'sweetalert2';
import useSecurityTree from './useSecurityTree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import CheckboxTree from 'react-checkbox-tree';
import { Link } from 'react-router-dom';
import menuService from '../../services/permissions/access-service';
import styles from "../../resources/css/style.module.css"
import UncheckedImg from "../../resources/images/unchecked.svg"
import CheckedImg from "../../resources/images/checked.svg"

const ArbolModulos = ({

}) => {
    const treeRef = useRef(null);
    const { tree, enabledNodes } = useSecurityTree();
    const [checkedNodes, setCheckedNodes] = useState(enabledNodes);
    const [expanded, setExpanded] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleStatusError = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
            html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo.</div>`,
            confirmButtonColor: '#0169b5',
        })
    }

    const handleStatusSuccess = (message) => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
            html: `<div style="text-align: center; font-size: 15px"> ${message} </div>`,
            confirmButtonColor: '#0169b5',
        })
    }

    useEffect(() => {
        setCheckedNodes(enabledNodes);
    }, [enabledNodes]);

    const handleCheck = (checked, node) => {
        setCheckedNodes(checked);
    }

    const handleExpand = (nodes) => {
        setExpanded(nodes);
    }

    const handleSave = async () => {
        try {
            setLoading(true);
            const { flatNodes } = treeRef?.current?.state.model;
            const status = ['Disabled', 'Enabled', 'Undetermined']
            const nodes = Object.values(flatNodes).map(f => ({
                key: f.value,
                status: status[f.checkState]
            }));
            await menuService.saveArbolModulos(nodes);
            handleStatusSuccess('Los datos se guardaron correctamente');
        } catch (err) {
            console.log(err);
            handleStatusError();
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home color-orange" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Modulo de seguridad
                    </li>
                </ol>
            </nav>
            <section className="noticias mb-3 p-0">
                <div className="header-page-container">
                    <h1 className={`mb-3 ${styles["page-title"]}`}>Modulo de seguridad</h1>
                </div>
                <div className="col-12 position-relative mb-0">
                    {/* <a className="btn btn-degradado btn-absolute" href="#">Ver más <i className="fa fa-chevron-right" aria-hidden="true"></i></a> */}
                </div>
            </section>

            <section className="container p-0">
                <div className="card-box mb-3">
                    <div><h4 className={styles['card-description']}>Aplicaciones en todas las unidades de organizacion.</h4></div>
                    <div className='mt-4'>
                        <CheckboxTree
                            icons={{
                                check: <img src={CheckedImg} />,
                                uncheck: <img src={UncheckedImg} />,
                                halfCheck: <img src={UncheckedImg} />,
                            }}
                            ref={treeRef}
                            nodes={tree}
                            checked={checkedNodes}
                            expanded={expanded}
                            onCheck={handleCheck}
                            onExpand={handleExpand}
                        />
                    </div>
                </div>
                <button className="button save float-sm-end" onClick={handleSave}>
                    Aceptar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                </button>
                {/* <button onClick={handleSave} className="btn btn-primary">Guardar</button> */}
                {loading && (
                    <div className="spinner-border text-primary"></div>
                )}
            </section>
        </>
    );
};

export default ArbolModulos;