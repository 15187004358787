import ProfilePicture from "@dsalvagni/react-profile-picture";
import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { urlsAws } from "../resources/foo/api-endpoints";
import defaultImg from "../resources/images/default_profile_foto.png";
import datosLaboralesService from "../services/datos-laborales-service";
import listService from "../services/list-service";
import { encryptPhoto } from "../services/utiles";
import Section from "./elements/section2";
import PropTypes from 'prop-types';
import { useContext } from "react";
import { Context } from "../context";

const CambioImg = (props) => {
  const [showButton, setShowButton] = useState(true);
  const [fotoGuardada, setFotoGuardada] = useState(false);
  const [newPhoto, setNewPhoto] = useState("");
  let profilePictureRef = React.createRef();
  //props
  CambioImg.propTypes = {
    rut: PropTypes.any,
    nombre: PropTypes.any,
    pais: PropTypes.any,
    photoUser: PropTypes.any,
  };

  const rut = props.rut;
  const nombre = props.nombre;
  const pais = props.pais;
  const photoUser = props.photoUser;
  const handleClose = () => {
    setFotoGuardada(false);
  };

  const cambiarfoto = () => {
    if (navigator.onLine) {
      setShowButton(false);
    }
  };

  const eliminarfoto = () => {
    setShowButton(true);
  };

  const handleUpload = () => {
    const PP = profilePictureRef.current;
    const imageAsDataURL = PP.getImageAsDataUrl();
    setShowButton(showButton);
    let imgUrl = imageAsDataURL.replace("data:image/png;base64,", "");
    if (
      imgUrl !==
      "iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAsxJREFUeF7t0jENAAAMw7CVP+nByOMSqBR5ZwqEBRZ+u1bgAIQgLQBgmt85gAykBQBM8zsHkIG0AIBpfucAMpAWADDN7xxABtICAKb5nQPIQFoAwDS/cwAZSAsAmOZ3DiADaQEA0/zOAWQgLQBgmt85gAykBQBM8zsHkIG0AIBpfucAMpAWADDN7xxABtICAKb5nQPIQFoAwDS/cwAZSAsAmOZ3DiADaQEA0/zOAWQgLQBgmt85gAykBQBM8zsHkIG0AIBpfucAMpAWADDN7xxABtICAKb5nQPIQFoAwDS/cwAZSAsAmOZ3DiADaQEA0/zOAWQgLQBgmt85gAykBQBM8zsHkIG0AIBpfucAMpAWADDN7xxABtICAKb5nQPIQFoAwDS/cwAZSAsAmOZ3DiADaQEA0/zOAWQgLQBgmt85gAykBQBM8zsHkIG0AIBpfucAMpAWADDN7xxABtICAKb5nQPIQFoAwDS/cwAZSAsAmOZ3DiADaQEA0/zOAWQgLQBgmt85gAykBQBM8zsHkIG0AIBpfucAMpAWADDN7xxABtICAKb5nQPIQFoAwDS/cwAZSAsAmOZ3DiADaQEA0/zOAWQgLQBgmt85gAykBQBM8zsHkIG0AIBpfucAMpAWADDN7xxABtICAKb5nQPIQFoAwDS/cwAZSAsAmOZ3DiADaQEA0/zOAWQgLQBgmt85gAykBQBM8zsHkIG0AIBpfucAMpAWADDN7xxABtICAKb5nQPIQFoAwDS/cwAZSAsAmOZ3DiADaQEA0/zOAWQgLQBgmt85gAykBQBM8zsHkIG0AIBpfucAMpAWADDN7xxABtICAKb5nQPIQFoAwDS/cwAZSAsAmOZ3DiADaQEA0/zOAWQgLQBgmt85gAykBQBM8zsHkIG0AIBpfucAMpAWADDN7xxABtICAKb5nQPIQFoAwDS/cwAZSAsAmOZ3/pJhAKGjqUGRAAAAAElFTkSuQmCC"
    ) {
      let request = { request: { photo:  encryptPhoto(imgUrl) } };
      datosLaboralesService.PhotoSendRequest(request, setFotoGuardada, imgUrl, setNewPhoto);
    }
  };
  
  useEffect(() => {
    if (fotoGuardada) {
      console.log('newPhoto', newPhoto);
      caches.open('api-cache').then(cache => {
          return cache.match(urlsAws.getPhotosEquipo + rut);
        }).then(response => {
          return response.json();
        }).then(data => {
            data.photo = newPhoto;
            caches
              .open('api-cache')
              .then(cache => {
                return cache.put(
                  urlsAws.getPhotosEquipo + rut,
                  new Response(JSON.stringify(data)),
                );
              })
              .then(()=> {
                console.log("Foto actualizada")
                photoUser();
              });
        });
      setFotoGuardada(false);
    }
  }, [fotoGuardada])

  const validaciones = {
    DEFAULT: "Suelta tu foto aquí o toca para seleccionar.",
    DRAGOVER: "Suelta tu foto",
    INVALID_FILE_TYPE: "Solo se permiten imágenes."
  };

  return (
    <>
      <div
        className="modal fade"
        id="seleccionar-foto"
        tabIndex="-1"
        aria-labelledby="seleccionar-foto"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h2 className="h5">Seleccionar foto</h2>
              <div
                className="rounded-3 border border-primary p-4 "
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <div className="img-profile me-0 mb-4 text-center">
                  {
                    <ProfilePicture
                      ref={profilePictureRef}
                      useHelper={true}
                      debug={false}
                      messages={validaciones}
                      onStatusChange={(e) => cambiarfoto(e)}
                      onImageRemoved={(e) => eliminarfoto(e)}
                      minImageSize={1}
                    />
                  }
                </div>
                <ul className="text-uppercase list-unstyled">
                  <li>
                    <span
                      className="data-list-box__title"
                      style={{ marginRight: "5%" }}
                    >
                      RUT:
                    </span>
                    <span className="data-list-box__text"> {rut}</span>
                  </li>
                  <li>
                    <span
                      className="data-list-box__title"
                      style={{ marginRight: "3%" }}
                    >
                      Nombre:
                    </span>
                    <span className="data-list-box__text">{nombre}</span>
                  </li>
                  <li>
                    <span
                      className="data-list-box__title"
                      style={{ marginRight: "5%" }}
                    >
                      País:
                    </span>
                    <span className="data-list-box__text">{pais}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="modal-footer border-0 pt-0">
              <button
                type="button"
                className="btn btn-degradado col-12 col-md-auto"
                data-bs-dismiss="modal"
              >
                Cancelar{" "}
                <i
                  className="fa fa-chevron-right fa-fw fa-xs"
                  aria-hidden="true"
                ></i>
              </button>
              <button
                id="botonEnviar"
                type="button"
                disabled={showButton}
                className="btn btn-primary col-12 col-md-auto"
                onClick={(e) => handleUpload(e)}
                data-bs-dismiss="modal"
                data-bs-toggle="modal"
                data-bs-target="#success"
              >
                Guardar{" "}
                <i
                  className="fa fa-chevron-right fa-fw fa-xs"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {fotoGuardada ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => handleClose(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-check-circle-o color-orange fa-3x"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">¡Éxito!</h2>
                    <p>Sus cambios se han guardado correctamente.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const DatosLaborales = () => {
  DatosLaborales.propTypes = {
    DireccionCHL: PropTypes.any,
  };
  const [data, setData] = useState(false);
  const {firstLoginData, setFirstLoginData, DireccionCHL, setDireccionCHL, employeeId, emplid, setEmplid, foto, photoUser} = useContext(Context);
  const loginData= firstLoginData;
  const setLoginData = setFirstLoginData;
  let navigate = useNavigate();
  const delay = (ms = 2000) => new Promise((r) => setTimeout(r, ms));
  const newCall = async (reload = true) => {
    await delay(
      reload
        ? 3500
        : loginData !== null
        ? 0
        : 500
    );
    caches.match(urlsAws.datosLaborales).then((response) => {
      if (response) {
        response.json().then((data) => {
          setData(data);
        });
      } else {
        datosLaboralesService.datosLaborales(setData, emplid);
      }
    });
    if (reload) {
      window.location.reload();
    } else if (DireccionCHL === null || DireccionCHL.message) {
      await delay(1500);
      listService.getFullDirecciones(setDireccionCHL);
    }
  };
  useEffect(() => {
    if (
      employeeId !== null &&
      (loginData === null || loginData === undefined || loginData === false)
    ) {
      caches.match(urlsAws.firstLogin).then((response) => {
        if (response && response.body !== null) {
          response.json().then((data) => {
            setEmplid(data.EMPLID);
            setLoginData(data);
            newCall(false);
          });
        } else {
          setEmplid(localStorage.getItem("emplid"));
          setLoginData(loginData === undefined ? false : response);
        }
      });
    } else {
      newCall(false);
    }
  }, [employeeId, navigate, loginData]);

  return (
    <>
      {data && !data.message ? (
        <>
          {data.sections !== undefined ? (
            <>
              <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/inicio">
                      <i
                        style={{ pointerEvents: "none" }}
                        className="fa fa-home"
                        aria-hidden="true"
                      ></i>{" "}
                      Inicio
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Datos Laborales
                  </li>
                </ol>
              </nav>
              <section className="datos-laborales">
                <h1>Datos Laborales</h1>{" "}
                <div className="box mb-4">
                  <h2 className="h4 tit-section">Información Personal</h2>
                  <div className="data-list-box">
                    <div className="d-block d-md-flex">
                      <div className="img-profile me-4 mb-4 mb-md-0">
                        {navigator.onLine ? (
                          <a
                            href="#"
                            className="btn btn-degradado rounded-circle"
                            data-bs-toggle="modal"
                            data-bs-target="#seleccionar-foto"
                          >
                            <i className="fa fa-camera" aria-hidden="true"></i>
                          </a>
                        ) : (
                          <a
                            href="#"
                            className="btn btn-degradado rounded-circle"
                            data-bs-toggle="modal"
                            data-bs-target="#sinConexionModal"
                          >
                            <i className="fa fa-camera" aria-hidden="true"></i>
                          </a>
                        )}
                        {foto.length > 0 && foto[0].photo !== '' ? (
                            <img
                              src={`data:image/png;base64, ${foto[0].photo}`}
                              alt=""
                              className="rounded-circle"
                              width="160"
                              height="160"
                            />
                          ) : (
                            <>
                              <img
                                src={defaultImg}
                                alt=""
                                className="rounded-circle"
                                width="160"
                                height="160"
                              />
                            </>
                          )}
                      </div>
                      <CambioImg
                        ID={loginData.EMPLID}
                        rut={loginData.EMPLID}
                        nombre={loginData.NAME}
                        pais={loginData.COUNTRY}
                        setLoginData={setLoginData}
                        photoUser={photoUser}
                      ></CambioImg>
                      <ul className="text-uppercase list-unstyled">
                        <li>
                          <span
                            style={{ marginRight: "3%" }}
                            className="data-list-box__title"
                          >
                            RUT:&nbsp;
                          </span>
                          <span className="data-list-box__text">
                            {loginData.EMPLID}
                          </span>
                        </li>
                        <li>
                          <span
                            style={{ marginRight: "3%" }}
                            className="data-list-box__title"
                          >
                            Nombre:
                          </span>
                          <span className="data-list-box__text">
                            {loginData.NAME}
                          </span>
                        </li>
                        <li>
                          <span
                            style={{ marginRight: "3%" }}
                            className="data-list-box__title"
                          >
                            País:
                          </span>
                          <span className="data-list-box__text">
                            {loginData.COUNTRY}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="sinConexionModal"
                  tabIndex="-1"
                  aria-labelledby="sinConexionModal"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header border-0 pb-0">
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body text-center py-4">
                        <i
                          className="fa fa-exclamation-triangle color-orange fa-3x"
                          aria-hidden="true"
                        ></i>
                        <h2 className="h5">No tienes conexión</h2>
                        <p>
                          No puedes ejecutar esta acción si no estás conectado.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {data.sections.map((section) => {
                  return <Section section={section} key={section.sectionid} />;
                })}
              </section>
            </>
          ) : null}
        </>
      ) : (
        <>
          <div className="text-center">
            <h3>Cargando...</h3>
          </div>
        </>
      )}
    </>
  );
};

export default DatosLaborales;
