import React, { FC, useEffect, useState } from "react";
import { RemoteData } from "@devexperts/remote-data-ts";
import * as RD from "@devexperts/remote-data-ts";
import { HttpError, ModalWarningProps, PostAnnualBond, ResponsePostAnnualBond } from "../../../utilities/interfaces";
import { getPostResponse } from "../../../utilities/helpers/setData";

interface PostProps {
    setRemoteData: React.Dispatch<React.SetStateAction<RemoteData<HttpError, ResponsePostAnnualBond>>>;
    body: PostAnnualBond;
}

const sendData = ({ setRemoteData, body }: PostProps): void => {
    getPostResponse({ setRemoteData: setRemoteData, body: body })
};

const ModalWarning: FC<ModalWarningProps> = ({ asignations, setCarouselSelected, period, setValidPostResponse }) => {
    const [remoteData, setRemoteData] = useState<RemoteData<HttpError, ResponsePostAnnualBond>>(RD.initial);

    useEffect(() => {
        setValidPostResponse !== undefined && remoteData._tag === "RemoteSuccess" && remoteData.value.response.valid === "1" && setValidPostResponse(true);
    }, [remoteData]);

    return (
        <>
            <div className="modal fade" id="modal-warning" tabIndex={-1} aria-labelledby="modal-warning" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        {remoteData._tag !== "RemotePending" && remoteData._tag !== "RemoteSuccess" &&
                            <div className="modal-header border-0 pb-0">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                    setRemoteData(RD.initial);
                                    setValidPostResponse !== undefined && setValidPostResponse(false);
                                }} />
                            </div>}
                        <div className="modal-body text-center py-4">
                            {remoteData._tag === "RemoteSuccess" && <i className="fa fa-check-circle-o color-orange fa-3x" aria-hidden="true" />}
                            <h2 className="h5">
                                {remoteData._tag === "RemoteInitial" && <>Recuerde que este proceso enviará un correo electrónico a cada colaborador con la información del Modelo seleccionado</>}
                                {remoteData._tag === "RemoteSuccess" ?
                                    remoteData.value.response.valid === "1" ?
                                        <>
                                            Envío exitoso<br/>
                                            En minutos te notificaremos por email el resultado del proceso:<br/>
                                            {`Asignación de Modelo Bono Anual (Ejercicio ${parseInt(period)} - Pago ${parseInt(period) + 1})`}<br />
                                            Por favor, permanece atento.
                                        </>
                                    :
                                        <>{remoteData.value.response.status}</>
                                :
                                    <></>
                                }
                                {remoteData._tag === "RemotePending" && <>Enviando información</>}
                                {remoteData._tag === "RemoteFailure" && <>Ha ocurrido un problema. Intentar más tarde.</>}
                            </h2>
                        </div>
                        {remoteData._tag !== "RemotePending" && 
                            <div className="modal-footer border-0 pt-0 pb-5 justify-content-center gap-2">
                                <button type="button" className="btn btn-primary order-md-last col-12 col-md-auto my-auto px-5" data-bs-dismiss="modal" onClick={() => {
                                    setRemoteData(RD.initial);
                                    setCarouselSelected !== undefined && remoteData._tag === "RemoteSuccess" && setCarouselSelected(2);
                                    setValidPostResponse !== undefined && setValidPostResponse(false);
                                }}>
                                    {remoteData._tag === "RemoteInitial" ? "Cancelar " : "Cerrar "}
                                    <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                                </button>
                                {remoteData._tag === "RemoteInitial" &&
                                    <button type="button" className="btn btn-degradado order-md-last col-12 col-md-auto my-auto px-5" onClick={() => sendData({ setRemoteData: setRemoteData, body: { request: { emplid: "", asignations: asignations } } })} >
                                        {"Enviar "}
                                        <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                                    </button>}
                            </div>}
                    </div>
                </div>
            </div>
            <button hidden id={"modal-warning-btn"} data-bs-toggle={"modal"} data-bs-target={"#modal-warning"} data-bs-backdrop="static" data-bs-keyboard="false" />
        </>
    ) 
};

export default ModalWarning;