import React from 'react'
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AprobacionVacaciones from './aprobacion-vacaciones';
import DetalleVacaciones from './detalle-vacaciones-arg';

const ListadoAprobacionVacaciones = () => {



  return (
   <>
      <div className='container p-0'>
        <div className='row'>
          <Routes>
            <Route path="" element={<AprobacionVacaciones />} />
          </Routes>
          <Routes>
            <Route path="detalle-aprobacion" element={<DetalleVacaciones />} />
          </Routes>
        </div>

      </div>

    </>
  )
}

export default ListadoAprobacionVacaciones
