import React, { FC } from "react";
const RetryModal: FC = () => <>
    <div className='modal fade' id="modal-retry" tabIndex={-1} aria-hidden="true" aria-labelledby="vista-previa">
        <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content rounded">
                <div className="modal-header border-0 p-0">
                </div>
                <div className="modal-body py-4 text-center">
                    <i
                        className="fa fa-exclamation-triangle color-orange fa-3x"
                        aria-hidden="true"
                    ></i>
                    <p>No hemos logrado completar la petición, vuelve a intentar porfavor.</p>
                </div>
            </div>
        </div>
    </div>
    <button hidden type="button" id="btn-modal-retry" data-bs-toggle="modal" data-bs-target="#modal-retry" />
</>;

export default RetryModal;