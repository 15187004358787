import { createContext } from "react";
import {
    ApplicationData,
    RequirementData,
} from "../../interfaces/regionalInfoInterface";

interface Props {
    applicationInfo: ApplicationData[];
    requirementsInfo: RequirementData[];
    talentVideo: { title: string; video_url: string };
    hasErrorInfo: boolean;

    setApplicationInfo: (jobRequisition: ApplicationData[]) => void;
    setRequirementsInfo: (jobRequisition: RequirementData[]) => void;
    setTalentVideo: (videoUrl: { title: string; video_url: string }) => void;
    setHasErrorInfo: (hasErrorInfo: boolean) => void;
}

export const InfoContext = createContext({} as Props);
