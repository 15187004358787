import React, { useState, useEffect, useRef, Children } from 'react';
import { Text, Image, StyleSheet, View, Page, Document, PDFDownloadLink } from "@react-pdf/renderer";
import { Bar, Chart } from "react-chartjs-2";


const GraficoCartola = ({
    cartolaVacaciones,
    statusCartolaVacaciones,
    loadingCartolaVacaciones
}) => {
    const [dataGraficoCartolaVacaciones, setDataGraficoCartolaVacaciones] = useState(false);
    const graficoVacacionesRef = useRef(null);

    const styles = StyleSheet.create({
        page: {
            backgroundColor: '#fff',
            padding: '20px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        card: {
            padding: "20px",
            borderRadius: "10px",
            width: "48%",
            border: "1px solid #eeeeee",
            margin: " 0px 5px 20px 5px",
            height: "auto",
        },
        head: {
            display: "flex",
            marginBottom: "20px",
            paddingLeft: "10px",
        },
        body: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px"
        },
        h1: {
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "left",
            marginBottom: "10px",
            color: "#0169b5"
        },
        table: {
            width: "100%",
            borderCollapse: "collapse",
            borderSpacing: "0",
            marginBottom: "20px"
        },
        tableHead: {
            backgroundColor: "#eeeeee",
            borderBottom: "1px solid #eeeeee",
            borderTop: "1px solid #eeeeee",
            borderLeft: "1px solid #eeeeee",
            borderRight: "1px solid #eeeeee",
            padding: "5px",
            fontSize: "8px",
        },
        tableBody: {
            borderBottom: "1px solid #eeeeee",
            borderLeft: "1px solid #eeeeee",
            borderRight: "1px solid #eeeeee",
            fontSize: "8px",
            textAlign: "center",
            color: "#777777",
        },
        tableRow: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            padding: "5px 5px 5px 5px",
            border: "1px solid #eeeeee",
        },
        labelHead: {
            textAlign: "center",
            color: "#777777",
            fontWeight: "900",
        },
        labelBody: {
            textAlign: "center",
            color: "#777777",
            fontWeight: "normal",
        },
        li: {
            display: "flex",
            flexDirection: "row",
            alignItems: 'flex-start',
            padding: "10px",
            borderBottom: "1px solid #eeeeee",
            position: "relative",
            justifyContent: "flex-start"
        },
        liTitle: {
            width: "50%",
        },
        liContent: {
            width: "50%",
        },
        titleLabel: {
            fontSize: "8px",
            fontWeight: "bold",
            color: "#777777"
        },
        contentLabel: {
            fontSize: "8px",
            fontWeight: "normal",
            color: "#777777"
        },
        foto: {
            width: "100px",
            height: "100px",
        },
        checkimg: {
            width: "20px",
            height: "20px",
        },
    });

    useEffect(() => {
        if (cartolaVacaciones) {
            const data = {};
            data.labels = cartolaVacaciones ? (Array.isArray(cartolaVacaciones) ? cartolaVacaciones.map((item) => `${item.periodo}`.slice(0, 4)) : [`${cartolaVacaciones.periodo}`]) : ["vacio"];
            data.datasets = [
                {
                    label: "Vacaciones tomadas",
                    data: cartolaVacaciones ? (Array.isArray(cartolaVacaciones) ? cartolaVacaciones.map((item) => `${item.dys_take}`) : [`${cartolaVacaciones.dys_take}`]) : ["vacio"],
                    borderColor: "#3e95cd",
                    backgroundColor: "#3e95cd",
                    datalabels: {
                        align: "top",
                        anchor: "end",
                        color: "grey",
                    },
                },
            ];
            data.labels = data.labels.reverse();
            data.datasets[0].data = data.datasets[0].data.reverse();
            let options = {
                responsive: true,
                maintainAspectRatio: false,
                animation: false,
                plugins: {
                    legend: {
                        display: true,
                        position: "bottom",
                    },
                },
                layout: {
                    padding: {
                        top: 20,
                    },
                },
            }
            setDataGraficoCartolaVacaciones({ data: data, options: options });
        }
    }, [cartolaVacaciones]);

    const FichaVacacionesPDF = () => {

        return <Document>
            <Page size="A4" style={styles.page}>
                {/* Grafico */}
                <View style={{ ...styles.card, width: "100%" }}>
                    <View style={styles.head}>
                        <Text style={styles.h1}>Gráfico Vacaciones</Text>
                    </View>
                    <View style={styles.body}>
                        <Image source={() => {
                            if (graficoVacacionesRef.current !== null) return graficoVacacionesRef.current.toBase64Image();
                            else if (document.getElementById("graficoVacaciones") !== null) return document.getElementById("graficoVacaciones").toDataURL();
                            else return "";
                        }}></Image>
                    </View>
                </View>
            </Page>
        </Document>
    }

    return (
        <>

            {(loadingCartolaVacaciones) ? null : (statusCartolaVacaciones == 200) ? (<>
                {/*  <PDFDownloadLink
                    className="text-decoration-none d-inline-block mb-3 mt-4 color-orange"
                    fileName="fichaVacaciones"
                    document={<FichaVacacionesPDF />}
                >
                    <i className="fa fa-file-pdf-o me-1" aria-hidden="true"></i>
                    Descargar
                </PDFDownloadLink> */}

                <div className="col-12 col-xl-12 d-flex">
                    <div className="box align-items-stretch w-100">
                        <h2 className="h4 tit-section">Gráfico Vacaciones</h2>
                        <div className="table-responsive">
                            {dataGraficoCartolaVacaciones ?
                                <Bar
                                    id="graficoVacaciones"
                                    ref={graficoVacacionesRef}
                                    data={dataGraficoCartolaVacaciones.data}
                                    width={645}
                                    options={dataGraficoCartolaVacaciones.options}
                                />
                                :
                                null}</div>
                    </div>
                </div>


            </>) : (
                <div>
                    <strong>Sin Registros</strong>
                </div>
            )}
        </>
    );
};

export default GraficoCartola;