
import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import CategoryCard from '../../components/card'
import { Category, APICategories } from '../../../../utilities/interfaces'
import { LoadingContent } from '../../../../utilities/constantsTexts'
import { HttpClient } from '../../../../utilities/apiServices/httpClient'
import { ApiRequest } from '../../../../utilities/apiServices/apiRequest'
import { setCategoryIcon } from '../../../../utilities/helpers/imgUrlSeter'
import { errorsMessages } from '../../../../utilities/apiServices/apiErrors'
import Loading from '../../../../commons/dataStatus/loading/loading'
import { UpdateLocalStorage } from '../../../../utilities/helpers/updateStorage'

const httpClient = new HttpClient()
const apiService = new ApiRequest(httpClient)

const TopCategories = () => {
  const [categoriesToShow, setCategories] = useState<Category[]>([])
  const [statusMessage, setStatusMessage] = useState(LoadingContent.loading)
  const [validData, setValidData] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const update = new UpdateLocalStorage()
  
  useEffect(() => {
    setIsLoading(true)
    fetchCategoriesList()
  }, []);

  const isValidData = (apiResponse: APICategories): boolean => {
    if (!apiResponse.data ||
      apiResponse.data.length === 0 ||
      apiResponse.data[0]?.attributes?.title === null) {
      setStatusMessage(LoadingContent.notValid)
      emitError(500)
      return false
    } else {
      setValidData(true)
      return true
    }
  }

  const fetchCategoriesList = () => {
    apiService.getCategories().then((categories: APICategories) => {
      if (!isValidData(categories)) return
      if (categories.data.length > 3) {
        setCategories(categories.data.splice(0, 3))
      } else {
        setCategories(categories.data)
      }
    })
      .catch((err) => {
        emitError(err)
      }).finally(() => {
        setIsLoading(false)
      })
  }

  const emitError = (err: number) => {
    const newError = {
      errorStatus: err,
      errorFire: 'getCategories',
      errorComponent: 'TopCategories'
    }
    update.updateSlot(update.keyConstants[7], JSON.stringify(newError))
  }

  return (
    <Grid item xs={12}>
      {isLoading && <Loading normalMessage={true} />}
      {validData && !isLoading &&
          categoriesToShow.map((category: Category) => {
            const icon = setCategoryIcon(category)
            const infoMap = {
              id: category.id,
              cardIcon: icon,
              cardTitle: category.attributes.title,
              cardSubtitle: category.attributes.subtitle,
            }
            return (
              <CategoryCard key={category.id} CardInfo={infoMap} />
            )
          })
      }
    {!validData && !isLoading &&  <p>{statusMessage}</p> }
    </Grid>
  );
}

export default TopCategories;