import React  from 'react';
import EditCardVacaciones from './edit-card-vacaciones';
import SummaryVacacionesUy from './summary-vacaciones-uy';


const Solicitud = () => {

   

 
    return (
        <>
            <div className='row container  p-0 data-container'>
                
                <div className="col-sm-12 col-md-5 mb-3 col-lg-3  p-0">
                <SummaryVacacionesUy></SummaryVacacionesUy>
                </div>     
                <div className="col-sm-12 col-md-7 col-lg-9  p-0">
                <EditCardVacaciones></EditCardVacaciones>
                    </div>         
            </div>
        </>
    )
}

export default Solicitud
