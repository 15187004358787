/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import styles from "../../resources/css/style-COL.module.css";
"../../resources/css/style-COL.css";
import moment from 'moment';
import { CertificadosArgService } from '../../services/certificados-arg/certificates-service-arg';
import { BsExclamationCircle } from 'react-icons/bs';
import { useForm } from "react-hook-form";
import { PDFDocument } from "pdf-lib";
import download from "downloadjs";
import Swal from 'sweetalert2';
import { FaFilter } from 'react-icons/fa'


const CertificadosARG = () => {
    const [loadingTipoCertificado, setLoadingTipoCertificado] = useState(false);
    const [infoTipoCertificado, setInfoTipoCertificado] = useState([]);
    const [loadingGenerarCertificado, setLoadingGenerarCertificado] = useState(false);
    const [loadingInfoCertificados, setLoadingInfoCertificados] = useState(false);
    const [infoCertificados, setInfoCertificados] = useState([]);
    const [error, setError] = useState(null)
    const [noDataInfoCertificados, setNoDataInfoCertificados] = useState(false);
    const [loadingFileCertificado, setLoadingFileCertificado] = useState(false)
    const [order, setOrder] = useState("asc")
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue
    } = useForm();

    useEffect(() => {
        getCertificatesType();
        getCertificates();
    }, [])

    function sortDates() {
        if (order === "asc") {
            setInfoCertificados(infoCertificados.sort((a, b) => moment(a.rundate) - moment(b.rundate)))
            setOrder("desc")
        } else {
            setInfoCertificados(infoCertificados.sort((a, b) => moment(b.rundate) - moment(a.rundate)))
            setOrder("asc")
        }
    }

    async function getCertificatesType() {
        setLoadingTipoCertificado(true)
        await CertificadosArgService.getTipoCertificado().then((data) => {
            setInfoTipoCertificado(data.reduce((acc, el) => {
                if (el.FIELDVALUE === "01" || el.FIELDVALUE === "02") {
                    acc.push(el)
                }
                return acc
            }, []))
        }).catch((err) => {
            setError(err.response.data.message)
        }).finally(() => {
            setLoadingTipoCertificado(false)
        })
    }
    async function getCertificates() {
        setLoadingInfoCertificados(true)
        await CertificadosArgService.getCertificatesSinFile().then((data) => {
            setInfoCertificados(data.sort((a, b) => moment(b.rundate) - moment(a.rundate)))
        }).catch((err) => {
            setError(err.response.data.message)
            setNoDataInfoCertificados(true);
        }).finally(() => {
            setLoadingInfoCertificados(false)
        })
    }
    async function onSubmit(data) {
        setLoadingGenerarCertificado(true);
        await CertificadosArgService.generateCertificate(data).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    allowOutsideClick: false,
                    position: 'center',
                    title: '<div style="text-align: center; color: green; font-size: 16px">Realizado</div>',
                    html: '<div style="text-align: center; font-size: 15px">Solicitud enviada correctamente!</div>',
                    confirmButtonColor: '#0169b5',
                }).then((result) => {
                    if (result.isConfirmed) {
                        setValue('tipo_certificado', '');
                        setValue('dirigido_a', 'quien corresponda');
                        getCertificates();
                    }
                })
            }
        }).catch((err) => {
            setError(err.response.data.message)
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: `<div style="text-align: center; color: red; font-size: 16px">Error</div>`,
                html: '<div style="text-align: center; font-size: 15px">Ha ocurrido un error al general la solicitud. Intentelo nuevamente.</div>',
                confirmButtonColor: '#0169b5',
            })
        }).finally(() => {
            setLoadingGenerarCertificado(false);
        })
    }
    async function generate(event, guid) {
        event.preventDefault()
        setLoadingFileCertificado(true)
        const data = await CertificadosArgService.getFileCertificado(guid)
        console.log(data)
        try {
            if (data?.certificado_file) {
                const file = new Buffer.from(data.certificado_file.data, 'base64')
                console.log(file)

                const pdfDocument = await PDFDocument.load(file);
                const bytes = await pdfDocument.save();
                download(bytes, "certificate.pdf");
            }
        } catch {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: `<div style="text-align: center; color: red; font-size: 16px">Error</div>`,
                html: '<div style="text-align: center; font-size: 15px">No se encontro el certificado.</div>',
                confirmButtonColor: '#0169b5',
            })
        } finally {
            setLoadingFileCertificado(false)
        }
    }

    return (
        <React.Fragment>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home color-orange" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Certificados
                    </li>
                </ol>
            </nav>
            <section className="noticias mb-3">
                <h1>Certificados</h1>
                <div className="col-12 position-relative mb-0">
                    {/* <a className="btn btn-degradado btn-absolute" href="#">Ver más <i className="fa fa-chevron-right" aria-hidden="true"></i></a> */}
                </div>
            </section>
            <section>
                {/* <!-- Solicitud de Certificados --> */}
                <div className={`${styles["box"]} ${styles["mb-4"]}`}>
                    <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Solicitud de Certificados</h2>
                    <form>
                        <div className={styles["row"]}>
                            <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                                <label className={styles["form-label"]}>Tipo de certificado</label>
                                <div className={styles["input-group"]}>
                                    {loadingTipoCertificado ? <div><div className="spinner-border text-primary spinner-border-sm"></div></div> :
                                        <select className={`${styles["form-select"]} mb-1`} {...register("tipo_certificado", { required: "*Seleccione un tipo de certificado válido" })}>
                                            <option value="" disabled selected>Seleccione</option>
                                            {infoTipoCertificado?.map((certificate, idx) => {
                                                return (
                                                    <option key={idx} value={certificate.FIELDVALUE} >
                                                        {certificate.XLATLONGNAME}
                                                    </option>
                                                )
                                            })
                                            }
                                        </select>
                                    }
                                </div>
                                <small style={{ fontSize: 13 }} className="form-error-message">{errors.tipo_certificado?.message}</small>
                            </div>
                            <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                                <label className={styles["form-label"]}>Para presentar ante</label>
                                <input type="text" className={`${styles["form-control"]} mb-1`} defaultValue={"quien corresponda"} {...register("dirigido_a", { required: "*Debe proporcionar un destinatario válido" })} />
                                <small style={{ fontSize: 13 }} className="form-error-message">{errors.dirigido_a?.message}</small>
                            </div>
                        </div>
                        <div className={`${styles["d-grid"]} ${styles["d-md-flex"]} ${styles["justify-content-md-end"]} ${styles["gap-2"]} ${styles["flex-nowrap"]} ${styles["mt-4"]}`}>
                            <div className={`${styles["d-grid"]} ${styles["d-md-flex"]} ${styles["gap-2"]}`}>
                                <button type="button" className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["ms-md-2"]}`} onClick={handleSubmit(onSubmit)} disabled={loadingGenerarCertificado || watch("tipoCertificado") === ""}>Solicitar {loadingGenerarCertificado ? <div className="spinner-border spinner-border-sm"></div> : <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i>}</button>
                            </div>
                        </div>
                    </form>
                </div>
                {/* <!-- Fin Solicitud de Certificados --> */}
            </section >
            <section>
                {/* <!-- Certificados Solicitados --> */}
                <React.Fragment>
                    {loadingInfoCertificados ? <div data-testid="loading" className="full-spinner">
                        <div className="spinner-border text-primary"></div>
                    </div>
                        : noDataInfoCertificados ? <div className="alert alert-info alert-dismissible fade show text-center" role="alert">
                            <BsExclamationCircle />  Hubo un error en el sistema, intentelo nuevamente.
                        </div> :
                            <div className={`${styles["box"]} ${styles["mb-4"]}`}>
                                <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Certificados Solicitados</h2>

                                {/* <!-- Escritorio (Tabla) --> */}
                                <div className={styles["table-responsive"]}>
                                    <table className={`${styles["table"]} ${styles["table-even"]} ${styles["table-section"]} ${styles["table-borderless"]} ${styles["d-none"]} ${styles["d-md-table"]}`}>
                                        <thead>
                                            <tr>
                                                <th className="cap d-flex align-items-center">Fecha {infoCertificados.length > 0 ? <div className="filter-pipe" onClick={() => sortDates()}><FaFilter /></div> : null}</th>
                                                <th className="cap">Tipo Certificado</th>
                                                <th className="cap">Vencimiento</th>
                                                <th className="cap">Estado</th>
                                                <th className={`${styles["text-center"]} cap`}>Ver</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {infoCertificados.length > 0 ? infoCertificados.map((c) => {
                                                return (<tr key={c?.idcertificado}>
                                                    <td>{moment(c?.rundate).format("DD/MM/yyyy")}</td>
                                                    <td>{infoTipoCertificado != undefined ? infoTipoCertificado.filter(t => c?.tipocertificado === t?.FIELDVALUE)[0] == undefined ? "No definido" : infoTipoCertificado.filter(t => c?.tipocertificado === t?.FIELDVALUE)[0].XLATLONGNAME :
                                                        <div data-testid="loading" className="full-spinner">
                                                            <div className="spinner-border text-primary"></div>
                                                        </div>}</td>
                                                    <td>{moment(new Date(c?.rundate).setUTCDate(new Date(c?.rundate).getUTCDate() + 30)).format("DD/MM/yyyy")}</td>
                                                    <td className={`${new Date(c?.rundate).setUTCDate(new Date(c?.rundate).getUTCDate() + 30) > new Date() ? "Aprobada" : "Vencida"}`}>{new Date(c?.rundate).setUTCDate(new Date(c?.rundate).getUTCDate() + 30) > new Date() ? "Vigente" : "Vencida"}</td>
                                                    <td className={styles["text-center"]}>
                                                        {new Date(c?.rundate).setUTCDate(new Date(c?.rundate).getUTCDate() + 30) > new Date() ? <div className={!loadingFileCertificado ? "certificate-dl-icon" : "certificate-dl-icon-disabled"} disabled={loadingFileCertificado} onClick={(e) => generate(e, c?.guid)}><i className="fa fa-eye fa-lg fa-fw" aria-hidden="true"></i></div> : null}
                                                    </td>
                                                </tr>)
                                            }) : <tr><td className='text-center' colSpan={5}>No se encontraron solicitudes</td></tr>}
                                        </tbody>
                                    </table>
                                </div>
                                {/* <!-- Fin Escritorio (Tabla) --> */}
                                {/* <!-- Móvil (Acordeón) --> */}
                                {infoCertificados.length > 0 ?
                                    <div className={`accordion accordion-flush accordion-light accordion-table ${styles["d-md-none"]}`}>
                                        <button className='btn btn-primary mb-2' onClick={() => sortDates()}>Ordenar <FaFilter style={{ marginLeft: 2 }} /></button>
                                        {/* <!-- 1er acordeón --> */}
                                        {infoCertificados.map((c, idx) => {
                                            return (
                                                <div key={c?.idcertificado} className="accordion-item">
                                                    <h2 className="accordion-header" id="accordion">
                                                        <button className="accordion-button collapsed header-button" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-item-${idx}`} aria-expanded="false" aria-controls={`accordion-item-${idx}`}>
                                                            <strong>Fecha</strong> {moment(c?.rundate).format("DD/MM/yyyy")}
                                                        </button>
                                                    </h2>
                                                    <div id={`accordion-item-${idx}`} className="accordion-collapse collapse" aria-labelledby="accordion">
                                                        <div className={`accordion-body ${styles["p-0"]}`}>
                                                            {/* <!-- cuerpo item --> */}
                                                            <div className={`${styles["data-list-box"]} ${styles["data-list-box-even"]}`}>
                                                                <ul className={`${styles["data-list-section--item"]} ${styles["list-unstyled"]}`}>
                                                                    <li>
                                                                        <span className={styles["data-list-box__title"]}>Tipo Certificado</span>
                                                                        <span className={styles["data-list-box__text"]}>{infoTipoCertificado != undefined ? infoTipoCertificado.filter(t => c?.tipocertificado === t?.FIELDVALUE)[0] == undefined ? "No definido" : infoTipoCertificado.filter(t => c?.tipocertificado === t?.FIELDVALUE)[0].XLATLONGNAME :
                                                                            <div data-testid="loading" className="full-spinner">
                                                                                <div className="spinner-border text-primary"></div>
                                                                            </div>}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className={styles["data-list-box__title"]}>Vencimiento</span>
                                                                        <span className={styles["data-list-box__text"]}>{moment(new Date(c?.rundate).setUTCDate(new Date(c?.rundate).getUTCDate() + 30)).format("DD/MM/yyyy")}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className={styles["data-list-box__title"]}>Estado</span>
                                                                        <span className={`${new Date(c?.rundate).setUTCDate(new Date(c?.rundate).getUTCDate() + 30) > new Date() ? "Aprobada" : "Vencida"} data-list-box__text`}>{new Date(c?.rundate).setUTCDate(new Date(c?.rundate).getUTCDate() + 30) > new Date() ? "Vigente" : "Vencida"}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className={styles["data-list-box__title"]}>Ver</span>
                                                                        <span className={styles["data-list-box__text"]}>
                                                                            {new Date(c?.rundate).setUTCDate(new Date(c?.rundate).getUTCDate() + 30) > new Date() ? <div className={!loadingFileCertificado ? "certificate-dl-icon" : "certificate-dl-icon-disabled"} onClick={(e) => generate(e, c.guid)}><i className="fa fa-eye fa-fw fa-lg" aria-hidden="true"></i></div> : null}
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            {/* <!-- fin cuerpo item --> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </div > : <div className='d-md-none d-flex justify-content-center'><span >No se encontraron solicitudes</span></div>}
                            </div>
                    }
                </React.Fragment>
            </section>
        </React.Fragment >
    )
}

export default CertificadosARG