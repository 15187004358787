import Auth from "@aws-amplify/auth";
import { urls } from "../resources/foo/api-endpoints";

const fetchData = async (url, method, body) => {
    const auth = await Auth.currentSession();
    const requestOptions = {
        method,
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: auth.getIdToken().getJwtToken(),
            AccessToken: auth.getAccessToken().getJwtToken(),
        },
        body: body ? JSON.stringify(body) : null,
    };
    return await fetch(url, requestOptions).then((response) => response.json()).catch((error) => { console.log(error); return false; });
};

const baseDatosService = {
    getAllgrupos: async (setData) => {
        const data = await fetchData(urls.getAllgrupos, "GET");
        if (data && data.response && data.response.grupos) {
            if (setData) setData(data.response.grupos);
            else return data.response.grupos;
        }
        else return data;
    },
    modNoticia: async (lID, lTITULO, lFECHADESDE, lFECHAHASTA, lURL_IMA_BANNER, lURL_IMA_DESTACADA, lCONTENIDO, lDESTACADA, lARCHIVO, lESTADO, GRUPOS, setData, visible_title) => {
        const data = await fetchData(urls.modNoticia, "POST", {
            id: lID,
            title: lTITULO,
            start_date: lFECHADESDE,
            end_date: lFECHAHASTA,
            banner_image: lURL_IMA_DESTACADA,
            banner_image_small: lURL_IMA_BANNER,
            content: lCONTENIDO,
            is_featured: lDESTACADA,
            file: lARCHIVO,
            status: lESTADO,
            groups: GRUPOS,
            visible_title: visible_title,
        });
        if(data) setData(data);
    },
    getHistorialNoticia: async (id, setData) => {
        const data = await fetchData(urls.getHistorialNoticia, "POST", { id });
        if(data && data.response && data.response.valid === 1)  setData(data);
    },
    getNoticiaById: async (id) => {
        const data = await fetchData(urls.getNoticiaById, "POST", { id });
        return data;
    },
    getAllNoticias: async (fechaFiltro1, fechaFiltro2, estadoFiltro, grupoFiltro, setData, setGrupos) => {
        const noticias = JSON.parse(localStorage.getItem("canal-noticias"));
        const grupos = JSON.parse(localStorage.getItem("canal-grupos"));
        if (noticias && grupos) {
            if (fechaFiltro1 === "" && fechaFiltro2 === "" && estadoFiltro === "" && grupoFiltro === "") {
                setData(noticias);
                setGrupos(grupos);
                return;
            }
            const filteredNoticias = noticias.filter(noticia => {
                const fechaDesde = new Date(noticia.start_date);
                const fechaHasta = new Date(noticia.end_date);
                const fechaFiltro1Date = new Date(fechaFiltro1);
                const fechaFiltro2Date = new Date(fechaFiltro2);
                const fechaDesdeValida = fechaFiltro1 === "" || fechaDesde >= fechaFiltro1Date;
                const fechaHastaValida = fechaFiltro2 === "" || fechaHasta <= fechaFiltro2Date;
                const estadoValido = estadoFiltro === "" || estadoFiltro === noticia.status;
                const grupoValido = grupoFiltro === "" || noticia.Groups.find(group => group.id === parseInt(grupoFiltro));
                return fechaDesdeValida && fechaHastaValida && estadoValido && grupoValido;
            });
            setGrupos(grupos);
            setData(filteredNoticias);
            return;
        }
        const data = await fetchData(urls.getAllNoticias, "POST", {
            start_date: fechaFiltro1,
            end_date: fechaFiltro2,
            status_filter: estadoFiltro,
            group_filter: grupoFiltro,
        });
        if (data && data.response && data.response.valid === 1) {
            const { noticias } = data.response;
            const uniqueGroups = noticias.reduce((acc, curr) => {
                curr.Groups.forEach(group => {
                    if (!acc[group.id]) {
                        acc[group.id] = [];
                    }
                    if (!acc[group.id].includes(group)) {
                        acc[group.id].push(group);
                    }
                });
                return acc;
            }, {});

            const uniqueGroupsArray = Object.values(uniqueGroups).flat();
            localStorage.setItem("canal-grupos", JSON.stringify(uniqueGroupsArray));
            localStorage.setItem("canal-noticias", JSON.stringify(noticias));
            setGrupos(uniqueGroupsArray);
            setData(noticias);
        }
        else {
            setData([]);
        }
    },
    deleteNoticia: async (id, setData) => {
        const data = await fetchData(urls.deleteNoticia, "POST", { id });
        if (data && data.response && data.response.valid === 1) setData(data.response.status);
    },
    undoNoticia: async (id, setData) => {
        const data = await fetchData(urls.undoNoticia, "POST", { id });
        if (data && data.response && data.response.valid === 1) setData(data.response.status);
    },
    newNoticia: async (lTITULO, lFECHADESDE, lFECHAHASTA, lURL_IMA_BANNER, lURL_IMA_DESTACADA, lCONTENIDO, lDESTACADA, lARCHIVO, lESTADO, GRUPOS, setData, visible_title) => {
        const data = await fetchData(urls.newNoticia, "POST", {
                    title: lTITULO,
                    start_date: lFECHADESDE,
                    end_date: lFECHAHASTA,
                    banner_image: lURL_IMA_DESTACADA,
                    banner_image_small: lURL_IMA_BANNER,
                    content: lCONTENIDO,
                    is_featured: lDESTACADA,
                    file: lARCHIVO,
                    status: lESTADO,
                    groups: GRUPOS,
                    visible_title: visible_title
        });
        if(data && data.response && data.response.valid) setData(data.response);
    },
    getExcelResume: async (id) => {
        const data = await fetchData(`${urls.getExcelResume}?id=${id}`, "GET");
        if (data && data.response && data.response.valid === 1) {
            const downloadLink = document.createElement("a");
            downloadLink.href = data.response.file;
            downloadLink.download = `Resumen_Noticias_${id}.xlsx`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    },
};

export default baseDatosService;
