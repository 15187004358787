import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { BsExclamationCircle } from "react-icons/bs";
import styles from "../../resources/css/style-COL.module.css";
import ContactosEmergenciaFamiliaresServices from '../../services/contactos emergencia y familiares/contactos-emergencia-y-familiares-service';
import ContactoEmergencia from "./contactoEmergencia";
import ContactoFamiliar from './contactoFamiliar';
import Swal from "sweetalert2";
"../../resources/css/style-COL.css"

const ContactosEmergenciaYFamiliares = () => {
    const [infoContactsFamily, setInfoContactsFamily] = useState([])
    const [loadingInfoContactsFamily, setLoadingInfoContactsFamily] = useState(false)
    const [noDataInfoContactsFamily, setNoDataInfoContactsFamily] = useState(false)
    const [infoContactsEmergency, setInfoContactsEmergency] = useState([])
    const [loadingInfoContactsEmergency, setLoadingInfoContactsEmergency] = useState(false)
    const [noDataInfoContactsEmergency, setNoDataInfoContactsEmergency] = useState(false)
    const [idFamiliar, setIdFamiliar] = useState(null)
    const [parentesco, setParentesco] = useState([])
    const [infoContactoEmergenciaModal, setinfoContactoEmergenciaModal] = useState(false);
    const [infoContactoFamiliarModal, setInfoContactoFamiliarModal] = useState(false);
    const [newData, setNewData] = useState(true);
    const [edit, setEdit] = useState(true);
    const [discard, setDiscard] = useState(false);

    const seeContact = (e, id) => {
        setIdFamiliar(id)
        setNewData(false)
        setinfoContactoEmergenciaModal(true)
        setInfoContactoFamiliarModal(false)
        e.preventDefault()
    }

    const seeFamiliar = (e, id) => {
        e.preventDefault()
        setIdFamiliar(id)
        setNewData(false)
        setInfoContactoFamiliarModal(true)
        setinfoContactoEmergenciaModal(false)
    }
    
    const addContact = () => {
            setIdFamiliar("")
            setNewData(true)
            setinfoContactoEmergenciaModal(true)
            setInfoContactoFamiliarModal(false)
    }

    const addFamiliar = () => {
            setIdFamiliar("")
            setNewData(true)
            setInfoContactoFamiliarModal(true)
            setinfoContactoEmergenciaModal(false)
    }

    const cancelData = (discard) => {
        if(discard == true){
            Swal.fire({
                allowOutsideClick: false,
                position: "center",
                title:
                    '<div style="text-align: center; color: red; font-size: 16px"> Descartar cambios </div>',
                html: '<div style="text-align: center; font-size: 15px"> Atencion, si descarta cambios perdera las modificaciones realizadas. </div>',
                showCancelButton: true,
                confirmButtonText: "Descartar cambios",
                cancelButtonText: "Cancelar",
                confirmButtonColor: "red",
                cancelButtonColor: "#0169b5",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    setNewData(false)
                    setInfoContactoFamiliarModal(false)
                    setinfoContactoEmergenciaModal(false)

                }
                else
                    return
            });
        }else{
            setNewData(false)
            setInfoContactoFamiliarModal(false)
            setinfoContactoEmergenciaModal(false)
        }
    }


    const reloadInfo = () => {
        setInfoContactoFamiliarModal(false)
        setinfoContactoEmergenciaModal(false)
        ContactosEmergenciaFamiliaresServices.GetParentesco(setParentesco)
        ContactosEmergenciaFamiliaresServices.Getcontactsfamily(setInfoContactsFamily, setLoadingInfoContactsFamily, setNoDataInfoContactsFamily)
        ContactosEmergenciaFamiliaresServices.Getcontactsemergency(setInfoContactsEmergency, setLoadingInfoContactsEmergency, setNoDataInfoContactsEmergency)
    }

    useEffect(() => {
        setLoadingInfoContactsFamily(true)
        setLoadingInfoContactsEmergency(true)
        ContactosEmergenciaFamiliaresServices.GetParentesco(setParentesco)
        ContactosEmergenciaFamiliaresServices.Getcontactsfamily(setInfoContactsFamily, setLoadingInfoContactsFamily, setNoDataInfoContactsFamily)
        ContactosEmergenciaFamiliaresServices.Getcontactsemergency(setInfoContactsEmergency, setLoadingInfoContactsEmergency, setNoDataInfoContactsEmergency)
    }, [])



    return (
        <div className={styles["cencosud-col"]}>
            {/* <!-- inicio breadcrumbs -->   */}
            <nav className={`${styles["breadcrumbs"]} ${styles["d-none"]} ${styles["d-lg-block"]}`}
                aria-label="breadcrumb">
                <ol className={styles["breadcrumb"]}>
                    <li className={styles["breadcrumb-item"]}>
                        <Link to="/inicio">
                            <i className="fa fa-home" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className={styles["breadcrumb-item"]}>Actualización de Información</li>
                    <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">Contactos e Información Familiar</li>
                </ol>
            </nav>
            {/* <!-- fin breadcrumbs --> */}

            {/* <!-- inicio Contactos e Información Familiar --> */}
            <h1>Contactos e Información Familiar</h1>
            {loadingInfoContactsFamily || loadingInfoContactsEmergency ?

                <div data-testid="loading" className={styles["full-spinner"]}>
                    <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
                </div>
                :
                noDataInfoContactsEmergency || noDataInfoContactsFamily ?

                    <div className={`${styles["alert"]} ${styles["alert-info"]} ${styles["alert-dismissible"]} ${styles["fade"]} ${styles["show"]} ${styles["text-center"]}`} role="alert">
                        <BsExclamationCircle />  No se encontraron datos asociados
                    </div> :
                    <section className={styles["contacto-e-inf-familiar"] + " " + "cencosud-col"}>
                        <div className={`${styles["box"]} ${styles["mb-4"]}`}>
                            <div className={`${styles["tit-group"]} ${styles["d-flex"]} ${styles["justify-content-between"]}`}>
                                <h2 className={`${styles["h4"]} ${styles["tit-section"]}`} >Detalle</h2>
                                <div className={styles["mt-2"]}>
                                    <Link to="#" onClick={addContact} className={`${styles["h5"]} ${styles["fl-normal"]} ${styles["me-3"]}`}><span className={`${styles["d-none"]} ${styles["d-sm-inline-block"]}`}>Agregar Contacto</span><i className={`${styles["fa"]} ${styles["fa-phone"]} ${styles["fa-lg"]} ${styles["ms-2"]}`} aria-hidden="true"></i></Link>
                                    <Link to="#" onClick={addFamiliar} className={`${styles["h5"]} ${styles["fl-normal"]}`}><span className={`${styles["d-none"]} ${styles["d-sm-inline-block"]}`}>Agregar Familiar</span><i className={`${styles["fa"]} ${styles["fa-plus-square-o"]} ${styles["fa-lg"]} ${styles["ms-2"]}`} aria-hidden="true"></i></Link>
                                </div>
                            </div>

                            <p>Datos Obligatorios <span className={styles["color-red"]}>*</span></p>
                            {infoContactsEmergency.concat(infoContactsFamily).length !== 0 ? <>
                                {/* <!-- Escritorio (Tabla) --> */}
                                <div className={styles["table-responsive"]}>
                                    <table className={`${styles["table"]} ${styles["table-even"]} ${styles["table-section"]} ${styles["table-borderless"]} ${styles["d-none"]} ${styles["d-md-table"]}`}>
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Contacto</th>
                                                <th>Relación</th>
                                                <th>Ver</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {infoContactsEmergency && infoContactsEmergency?.map(c => {
                                                return (<tr key={c.CONTACT_NAME}>
                                                    <td>{c.CONTACT_NAME}</td>
                                                    <td>{parentesco != undefined ?
                                                        parentesco.filter(p =>
                                                            p.FIELDVALUE === c.RELATIONSHIP
                                                        )[0] == undefined ? "No definido" : parentesco.filter(p => p.FIELDVALUE === c.RELATIONSHIP)[0].XLATLONGNAME
                                                        :
                                                        <div data-testid="loading" className={styles["full-spinner"]}>
                                                            <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
                                                        </div>}</td>
                                                    <td><strong className={styles["color-primary"]}>Contacto</strong></td>
                                                    <td>
                                                        <Link to="#" onClick={(e) => seeContact(e, c.CONTACT_NAME)}><i className={`${styles["fa"]} ${styles["fa-eye"]} ${styles["fa-fw"]} ${styles["fa-lg"]}`} aria-hidden="true"></i></Link>
                                                    </td>
                                                </tr>)
                                            })}
                                            {infoContactsFamily && infoContactsFamily?.map(f => {
                                                return (<tr key={f.DEPENDENT_BENEF}>
                                                    <td>{f.FIRST_NAME + " " + f.LAST_NAME}</td>
                                                    <td>{parentesco != undefined ?
                                                        parentesco.filter(p =>
                                                            p.FIELDVALUE === f.RELATIONSHIP
                                                        )[0] == undefined ? "No definido" : parentesco.filter(p => p.FIELDVALUE === f.RELATIONSHIP)[0].XLATLONGNAME
                                                        :
                                                        <div data-testid="loading" className={styles["full-spinner"]}>
                                                            <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
                                                        </div>}</td>

                                                    <td><strong className={styles["color-primary"]}>Familiar</strong></td>
                                                    <td>
                                                        <Link to="#" onClick={(e) => seeFamiliar(e, f.DEPENDENT_BENEF)}><i className={`${styles["fa"]} ${styles["fa-eye"]} ${styles["fa-fw"]} ${styles["fa-lg"]}`} aria-hidden="true"></i></Link>
                                                    </td>
                                                </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                {/* <!-- Móvil (Acordeón) --> */}
                                <div className={`accordion accordion-flush accordion-light accordion-table ${styles["d-md-none"]}`}>
                                    {/* <!-- 1er acordeón --> */}
                                    {infoContactsFamily && infoContactsFamily?.map((f, id) => {
                                        return (<div key={f.DEPENDENT_BENEF} className="accordion-item">
                                            <h2 className="accordion-header" id="accordion">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-item-${id}-f`} aria-expanded="false" aria-controls={`accordion-item-${id}-f`}>
                                                    <strong>Nombre</strong> {f.FIRST_NAME + " " + f.LAST_NAME}
                                                </button>
                                            </h2>
                                            <div id={`accordion-item-${id}-f`} className="accordion-collapse collapse" aria-labelledby="accordion">
                                                <div className={`accordion-body ${styles["p-0"]}`}>
                                                    {/* <!-- cuerpo item --> */}
                                                    <div className={`${styles["data-list-box"]} ${styles["data-list-box-even"]}`}>
                                                        <ul className={`${styles["data-list-section--item"]} ${styles["list-unstyled"]}`}>
                                                            <li>
                                                                <span className={styles["data-list-box__title"]}>Contacto</span>
                                                                <span className={styles["data-list-box__text"]}>{parentesco != undefined ?
                                                                    parentesco.filter(p =>
                                                                        p.FIELDVALUE === f.RELATIONSHIP
                                                                    )[0] == undefined ? "No definido" : parentesco.filter(p => p.FIELDVALUE === f.RELATIONSHIP)[0].XLATLONGNAME
                                                                    :
                                                                    <div data-testid="loading" className={styles["full-spinner"]}>
                                                                        <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
                                                                    </div>}</span>
                                                            </li>
                                                            <li>
                                                                <span className={styles["data-list-box__title"]}>Relación</span>
                                                                <span className={styles["data-list-box__text"]}><strong className={styles["color-primary"]}>Familiar</strong></span>
                                                            </li>
                                                            <li>
                                                                <span className={styles["data-list-box__title"]}>Ver</span>
                                                                <span className={styles["data-list-box__text"]}>
                                                                    <Link to="#" onClick={(e) => seeFamiliar(e, f.DEPENDENT_BENEF)}><i className={`${styles["fa"]} ${styles["fa-eye"]} ${styles["fa-fw"]} ${styles["fa-lg"]}`} aria-hidden="true"></i></Link>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {/* <!-- fin cuerpo item --> */}
                                                </div>
                                            </div>
                                        </div>)
                                    })}
                                    {infoContactsEmergency && infoContactsEmergency?.map((c, id) => {
                                        return (<div key={c.CONTACT_NAME} className="accordion-item">
                                            <h2 className="accordion-header" id="accordion">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-item-${id}-c`} aria-expanded="false" aria-controls={`accordion-item-${id}-c`}>
                                                    <strong>Nombre</strong> {c.CONTACT_NAME}
                                                </button>
                                            </h2>
                                            <div id={`accordion-item-${id}-c`} className="accordion-collapse collapse" aria-labelledby="accordion">
                                                <div className={`accordion-body ${styles["p-0"]}`}>
                                                    {/* <!-- cuerpo item --> */}
                                                    <div className={`${styles["data-list-box"]} ${styles["data-list-box-even"]}`}>
                                                        <ul className={`${styles["data-list-section--item"]} ${styles["list-unstyled"]}`}>
                                                            <li>
                                                                <span className={styles["data-list-box__title"]}>Contacto</span>
                                                                <span className={styles["data-list-box__text"]}>{parentesco != undefined ?
                                                                    parentesco.filter(p =>
                                                                        p.FIELDVALUE === c.RELATIONSHIP
                                                                    )[0] == undefined ? "No definido" : parentesco.filter(p => p.FIELDVALUE === c.RELATIONSHIP)[0].XLATLONGNAME
                                                                    :
                                                                    <div data-testid="loading" className={styles["full-spinner"]}>
                                                                        <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
                                                                    </div>}</span>
                                                            </li>
                                                            <li>
                                                                <span className={styles["data-list-box__title"]}>Relación</span>
                                                                <span className={styles["data-list-box__text"]}><strong className={styles["color-primary"]}>Contacto</strong></span>
                                                            </li>
                                                            <li>
                                                                <span className={styles["data-list-box__title"]}>Ver</span>
                                                                <span className={styles["data-list-box__text"]}>
                                                                    <Link to="#" onClick={(e) => seeContact(e, c.CONTACT_NAME)}><i className={`${styles["fa"]} ${styles["fa-eye"]} ${styles["fa-fw"]} ${styles["fa-lg"]}`} aria-hidden="true"></i></Link>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {/* <!-- fin cuerpo item --> */}
                                                </div>
                                            </div>
                                        </div>)
                                    })}
                                    {/* <!-- fin items acordeón --> */}
                                </div></> : <div className={`${styles["alert"]} ${styles["alert-info"]} ${styles["alert-dismissible"]} ${styles["fade"]} ${styles["show"]} ${styles["text-center"]}`} role="alert">
                                <BsExclamationCircle />  No se cargaron datos
                            </div>}
                            {/* <!-- fin Móvil (Acordeón) --> */}
                        </div>
                        {/* <!-- Fin Detalle --> */}

                        {infoContactoEmergenciaModal && <ContactoEmergencia newData={newData} nameContact={idFamiliar} close={cancelData} parentesco={parentesco} setNameContact={setIdFamiliar} reloadInfo={reloadInfo} setEditG={setEdit} listContact={infoContactsEmergency} />}
                        {infoContactoFamiliarModal && <ContactoFamiliar newData={newData} nameContact={idFamiliar} close={cancelData} parentesco={parentesco} setNameContact={setIdFamiliar} reloadInfo={reloadInfo} setEditG={setEdit} listContact={infoContactsEmergency} />}
  
                    </section>}
        </div>

    )
}

export default ContactosEmergenciaYFamiliares;