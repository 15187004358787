import React, { useEffect, useState } from "react";
import styles from "../../resources/css/style-COL.module.css";
import EthicalCodeServices from "../../services/codigo-etica/service";
import Swal from "sweetalert2"; import successMessage from "../SharedComponents/success-message";
import errorMessage from "../SharedComponents/error-message";
import moment from "moment";


const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-degradado ms-md-2",
    cancelButton: "btn btn-primary",
  },
  buttonsStyling: false,
});

const Form = ({ ethicalcode, pdf, reLoad }) => {
  const [isSaving, setIsSaving] = useState(false);

  // const sign = () => {
  //   reLoad(); 
  // };

  const sign = async () => {
    swalWithBootstrapButtons
      .fire({
        title: "Confirmación",
        text: "¿Desea continuar la operación?",
        // icon: "warning", 
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
        showCloseButton: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          setIsSaving(true);

          // Call to service    
          let response = await EthicalCodeServices.sign();

          if (response === null) {
            errorMessage(null, "");
          } else {
            const status = response?.statusCode || response?.status;
            switch (status) {
              case 200:
                setIsSaving(false);
                successMessage(reLoad);
                break;

              case 400:
                errorMessage(null, response?.message);
                setIsSaving(false);
                break;

              case 404:
                errorMessage(null, response?.message);
                setIsSaving(false);
                break;

              case 500:
                errorMessage(null, response?.message);
                setIsSaving(false);
                break;

              default:
                errorMessage(null, response?.message); setIsSaving(false); break;
            }
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) { }
      });
  };

  const formatDate = (date) => {
    if (!date) return '';
    return moment(date).format("DD/MM/YYYY");
  };

  return (
    <div className={`${styles["row"]} ${styles["mb-4"]}`}>
      {/* <!-- Nombre y cargo --> */}
      <div
        className={`${styles["col-12"]} ${styles["col-xl-4"]} ${styles["d-flex"]} ${styles["mb-4"]} ${styles["mb-xl-0"]}`}
      >
        <div
          className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}
        >
          {/* <img src="assets/images/foto-usuario-h.png" alt="" className={`${styles["rounded-circle"]}
           ${styles["mb-3"]}`} width="120" height="120"> 
                      <h2 className={`${styles["h3"]} ${styles["mb-2"]}`}>Pablo Torres A.</h2>
                        <p className={`${styles["h5"]} ${styles["fw-bold"]}`}>Especialista en Aplicaciones</p> */}

          <div
            className={
              styles["data-list-box"] +
              " " +
              styles["data-list-box-align-middle"] +
              " " +
              styles["mt-3"]
            }
          >
            <ul className={styles["list-unstyled"]}>
              <li>
                <span
                  className={`${styles["data-list-box__title"]} ${styles["col-6"]} ${styles["color-primary"]} ${styles["d-inline-block"]}`}
                >
                  Estado:
                </span>
                <span className={styles["data-list-box__text"]}>{ethicalcode?.status}</span>
              </li>
              <li>
                <span
                  className={`${styles["data-list-box__title"]} ${styles["col-6"]} ${styles["color-primary"]} ${styles["d-inline-block"]}`}
                >
                  Fecha Firma:
                </span>
                <span className={styles["data-list-box__text"]}>
                  {formatDate(ethicalcode?.signatureDate)}
                </span>
              </li>
              <li>
                <span
                  className={`${styles["data-list-box__title"]} ${styles["col-6"]} ${styles["color-primary"]} ${styles["d-inline-block"]}`}
                >
                  Vencimiento:
                </span>
                <span className={styles["data-list-box__text"]}>
                  {formatDate(ethicalcode?.expirationDate)}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!-- Detalle --> */}
      <div
        className={`${styles["col-12"]} ${styles["col-xl-8"]} ${styles["d-flex"]}`}
      >
        <div
          className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}
        >
          <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>
            Detalle
          </h2>
          <object data={pdf} type="application/pdf" width="100%" height="500">
            <iframe src={pdf} width="100%" height="500">
              <p>El navegador no soporta PDF.</p>
            </iframe>
          </object>
          <br />
          <br />
          <br />
          <div className={`${styles["row"]} ${styles["justify-content-end"]}`}>
            <div className={`${styles["col-12"]} ${styles["col-md-auto"]}`}>
              <button
                type="submit"
                className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["w-100"]}`}
                onClick={sign}
                disabled={isSaving}
              >
                He leído y acepto{" "}
                {isSaving ? (
                  <div
                    className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}
                  ></div>
                ) : (
                  <i
                    className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                    aria-hidden="true"
                  ></i>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- fin Detalle --> */}
    </div>
  );
};

export default Form;