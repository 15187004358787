import SearchIcon from '@mui/icons-material/Search';
import React from 'react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { filterCommonWords } from "../../utils/filterFunctions";
import { removeSymbols } from '../../../../pages/searchResults/utils/parseContent';
import { OptionsProps } from './interfaces';
import searchStyle from '../../styles/searchStyle.module.css'

const SearchOptions = ({ searchText, option, liProps }: OptionsProps) => {
  let inputParsed = filterCommonWords(searchText)
  let matchText = option.attributes.title
  if(option.contentMatch) {
    matchText = removeSymbols(option.contentMatch)
  }
  const matches = match(matchText, inputParsed, { insideWords: true });
  const parts = parse(matchText, matches);

  return (
    <li {...liProps} key={option.id}>
      <div>
        <SearchIcon className={`${searchStyle.search_icon} ${searchStyle.label_icon}`}/>
        {parts.map((part, index) => (
          <span key={index}
            style={{ fontWeight: part.highlight ? 600 : 400 }}
            className={searchStyle.option_text}>
            {part.text}
          </span>
        ))}
      </div>
    </li>
  );
}

export default SearchOptions