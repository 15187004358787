import React from 'react'
import CardLicencias from './card-licencias-ury';
import {  HeaderPages } from '../commonComponents';

const LicenciasUy = () => {

    return (
        <>
           <HeaderPages/> 
            <div className='container p-0'>
                <div className="row">
                <CardLicencias/>

                </div>
            </div>

        </>
    )
}

export default LicenciasUy