import Auth from "@aws-amplify/auth";
import { urlsAws } from "../resources/foo/api-endpoints";

const FirmaDocumentosService = {
    getFirmaDocumentos: (emplid, setData, setEstadoCarga, equipo = false) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.getFirmaDocumentos + "?emplid=" + emplid + "&equipo=" + equipo, requestOptions)
                .then((response) => response.text())
                .then((result) => result.replaceAll(",}", "}"))
                .then((result) => result.replaceAll("(,", "}"))
                .then((result) => JSON.parse(result))
                .then((data) => {
                    if (data != undefined) {
                        if (data.message == undefined) {
                            if (data.request != undefined) {
                                if (data.request.valid == "1") {
                                    if (data.request.CN_HOMOFFSOL_RP && data.request.CN_HOMOFFSOL_RP.length > 0) {
                                        const filterData = data.request.CN_HOMOFFSOL_RP.filter((obj)=>obj.request_id)
                                        if (filterData.length === 0) {
                                            return [];
                                        }
                                        return filterData                                        
                                    } else {
                                        return [];
                                    }
                                } else {
                                    throw new Error("valid");
                                }
                            } else {
                                throw new Error("error 429 ");
                            }
                        } else {
                            throw new Error("Firma documento error 429", data.message);
                        }
                    } else {
                        throw new Error("error 429");
                    }
                })
                .then((data) => {
                    if (!Array.isArray(data)) {
                        if (data.message == undefined) {
                            if (data.request_id == "" && data.estado == "") {
                                data = [];
                            } else {
                                data = [data];
                            }
                        } else {
                            throw new Error("error 429", data.message);
                        }
                    }
                    if (data !== undefined) {
                        caches.open("api-cache").then((cache) => {
                            if (data.length > 0) {
                                cache.put(urlsAws.getFirmaDocumentos + "?emplid=" + emplid, new Response(JSON.stringify(data)));
                                setData(data);
                            } else {
                                setData(false);
                                setEstadoCarga("No hay documentos para firmar");
                            }
                        });
                    }
                })
                .catch((error) => {
                    if (error.message != undefined && error.message.includes("Unexpected token")) {
                        console.log("error 500", error);
                        setData(false);
                        setEstadoCarga("Error al cargar la informacion");
                    } else if (error == "valid") {
                        console.log("error valid", error);
                        setData(false);
                        setEstadoCarga("Error al cargar la informacion");
                    } else {
                        console.log("error", error);
                        caches.match(urlsAws.getFirmaDocumentos + "?emplid=" + emplid).then((response) => {
                            if (response != undefined) {
                                response.json().then((data) => {
                                    if (data.length > 0) {
                                        setData(data);
                                    } else {
                                        setData(false);
                                        setEstadoCarga("No hay documentos para firmar");
                                    }
                                });
                            } else {
                                setData(false);
                                setEstadoCarga("No hay documentos para firmar");
                            }
                        });
                    }
                });
        }).catch((err) => console.log(err));
    },

    setFirmar: (emplid, setResponse, requesId, setCargar, cargar, item) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.setFirmar + "?requesId=" + requesId + "&ip=207.248.210.20", requestOptions)
                .then((response) => response.text())
                .then((result) => result.replaceAll(",}", "}"))
                .then((result) => JSON.parse(result))
                .then((data) => {
                    if (data.response.return_cd === "0") {
                        caches.open("api-cache").then((cache) => {
                            caches.match(urlsAws.getFirmaDocumentos + "?emplid" + emplid).then((response) => {
                                if (response != undefined) {
                                    response.json().then((data) => {
                                        const index = data.findIndex((dataCaches) => JSON.stringify(dataCaches) == JSON.stringify(item));
                                        data = data.splice(0, index);
                                        cache.put(urlsAws.getFirmaDocumentos + "?emplid=" + emplid, new Response(JSON.stringify(data)));
                                    });
                                }
                            });
                        });
                        setTimeout(() => {
                            setResponse(true);
                            cargar ? setCargar(false) : setCargar(true);
                        }, 1000);
                    } else {
                        setTimeout(() => {
                            setResponse(1);
                            cargar ? setCargar(false) : setCargar(true);
                        }, 1000);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setTimeout(() => {
                        setResponse(1);
                        cargar ? setCargar(false) : setCargar(true);
                    }, 1000);
                });
        }).catch((err) => console.log(err));
    },

    setRechazar: (emplid, setResponse, requesId, setCargar, cargar, item) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.setRechazar + "?requesId=" + requesId, requestOptions)
                .then((response) => response.text())
                .then((result) => result.replaceAll(",}", "}"))
                .then((result) => JSON.parse(result))
                .then((data) => {
                    if (data.response.return_cd === "0") {
                        caches.open("api-cache").then((cache) => {
                            caches.match(urlsAws.getFirmaDocumentos + "?emplid=" + emplid).then((response) => {
                                response.json().then((data) => {
                                    const index = data.findIndex((dataCaches) => JSON.stringify(dataCaches) == JSON.stringify(item));
                                    data = data.splice(0, index);
                                    cache.put(urlsAws.getFirmaDocumentos + "?emplid=" + emplid, new Response(JSON.stringify(data)));
                                });
                            });
                        });
                        setTimeout(() => {
                            setResponse(true);
                            cargar ? setCargar(false) : setCargar(true);
                        }, 1000);
                    } else {
                        setTimeout(() => {
                            setResponse(1);
                            cargar ? setCargar(false) : setCargar(true);
                        }, 1000);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setTimeout(() => {
                        setResponse(1);
                        cargar ? setCargar(false) : setCargar(true);
                    }, 1000);
                });
        }).catch((err) => console.log(err));
    },

    documento: (request_id, emplid, setData, setEstadoCarga, nombre) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.documento + "?rut=" + emplid + "&request_id=" + request_id, requestOptions)
                .then((response) => response.text())
                .then((result) => result.replaceAll(",}", "}"))
                .then((result) => JSON.parse(result))
                .then((data) => {
                    if (data != undefined && data.response != undefined && data.response.valid === '1') {
                        caches.open("api-cache").then((cache) => {
                            cache.put(urlsAws.documento + "?emplid=" + emplid + "requesId" + request_id, new Response(JSON.stringify(data.response.archivo)));
                            setData([data.response.archivo, nombre])
                            document.getElementById("closeButtonDownloadingDocument")?.click();
                        });
                    } else {
                        throw new Error("Error de validacion de informacion")
                    }
                })
                .catch((error) => {
                    document.getElementById("closeButtonDownloadingDocument")?.click();
                    console.log(error);
                    caches.match(urlsAws.documento + "?emplid=" + emplid + "requesId" + request_id).then((response) => {
                        if (response != undefined) {
                            response.json().then((data) => {
                                if (data != undefined) {
                                    setData([data, nombre]);
                                } else {
                                    setData([]);
                                    setEstadoCarga(true);
                                }
                            });
                        } else {
                            setData([]);
                            setEstadoCarga(true);
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },
};
export default FirmaDocumentosService;
