import React, { FC } from "react";
import { Link } from "react-router-dom";
import { BreadCrumbsProps } from "../../utilities/interfaces";

const BreadCrumbs: FC<BreadCrumbsProps> = ( routes ) => {
    return (
        <nav id="pansito" className="breadcrumbs" aria-label="breadcrumb">
            <ol className="breadcrumb">
                {routes.crumbs.map((route, index) => (
                    <li className="breadcrumb-item" key={ index }>
                        <Link to={route.url}><i className="fa fa-home" aria-hidden="true" />{` ${route.name}`}</Link>
                    </li>
                ))}
                <li className={`breadcrumb-item${routes.actual === "" ? " active" : ""}`} aria-current="page">Bono Anual</li>
                {routes.actual !== "" &&
                    <li className="breadcrumb-item active" aria-current="page">
                        {routes.actual}
                    </li>}
            </ol>
        </nav>
    );
};

export default BreadCrumbs;