import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "../../resources/css/style-COL.module.css";
import AprobacionRegionalService from "../../services/Aprobacion-regional/service";
import { FaCheckCircle } from "react-icons/fa";
import { BiSolidErrorCircle } from "react-icons/bi";
import Header from "../elements/header";
import Footer from "../elements/footer";

const AprobacionRegional = () => {
  const { search } = useLocation();
  const action = search.split("action=")[1].split("&")[0];
  const hash = search.split("hash=")[1];
  const [sendRequest, setSendRequest] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [title, setTitle] = useState("Aprobación");

  useEffect(() => {
    setSendRequest(true);
  }, []);

  useEffect(() => {
    if (sendRequest) {
      approve();
    }
  }, [sendRequest]);

  const approve = async () => {
    if (action) {
      const dto = {
        hash,
        action,
      };

      await AprobacionRegionalService.aprobacionPublico(
        dto,
        setIsSaving,
        setShowError,
        setSendRequest,
        setShowSuccess,
        setErrorMessage,
        setTitle
      );
    } else {
      setShowError(true);
    }
  };

  return (
    <div
      className="d-flex flex-column space-between"
      style={{ height: "calc(100vh - 32px)" }}
    >
      <Header />

      {isSaving || sendRequest ? (
        <div
          data-testid="loading"
          className={`${styles["full__spinner_container"]}`}
        >
          <div className="spinner-border text-primary"></div>
        </div>
      ) : null}

      <div className={`${styles["container"]} ${styles["procces__container"]}`}>
        <div
          className={`${styles["box"]} ${styles["procces_box"]} ${
            showSuccess || showError ? styles.show : ""
          }`}
        >
          {showSuccess || showError ? (
            <>
              <h2 className="h4 tit-section">{title}</h2>
              <div className="content-mail">
                {showSuccess ? (
                  <div className={`${styles["error__container"]}`}>
                    <FaCheckCircle className={`${styles["error__icon"]}`} />
                    <p
                      className={`${styles["message__text"]} ${styles["color-primary"]}`}
                    >
                      La solicitud se procesó exitosamente.
                    </p>
                  </div>
                ) : null}
                {showError ? (
                  <div className={`${styles["error__container"]}`}>
                    <BiSolidErrorCircle
                      className={`${styles["error__icon"]}`}
                    />
                    <div className={`${styles["error__message"]}`}>
                      <p
                        className={`${styles["message__text"]} ${styles["color-primary"]}`}
                      >
                        Se ha producido un error.{errorMessage}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AprobacionRegional;
