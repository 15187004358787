import { countryDictionary } from '../../../../utils/countries';
import { InterestSitesMaintenance } from '../../context/interestSiteMaintenanceContext';
import { toBase64 } from '../../../../services/utiles'
import { handleErrorFile } from '../Alerts/alerts';
import { useForm, Controller } from 'react-hook-form';
import React, { useEffect, useState } from 'react';

interface FormProps {
    editId: number | null;
    toggleForm: (id?: number | null) => void;
    data: InterestSitesMaintenance[];
    onSave: (id: number | null | undefined, data: InterestSitesMaintenance) => void;
    countriesArray: string[];
}

const Form: React.FC<FormProps> = ({ editId, toggleForm, data, onSave, countriesArray }) => {
    const [fileError, setFileError] = useState<string | null>(null);
    const { register, handleSubmit, setValue, control, formState: { errors }, reset } = useForm<InterestSitesMaintenance>({
        defaultValues: {
            title: '',
            url: '',
            class: '',
            country: '',
            vpn: 0,
            password: 0,
            bgcolor: ''
        }
    });

    useEffect(() => {
        const siteToEdit = data.find(site => site.id === editId);
        if (editId && siteToEdit) {
            reset(siteToEdit);
        }
    }, [editId, data, reset]);

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        if (files && files[0].type === 'image/svg+xml') {
            const base64SVG = await toBase64(files[0]);
            setValue('class', base64SVG);
            setFileError(null);
            return;
        }
        setFileError('*Solo se permiten archivos en formato SVG.');
        setValue('class', '');
    };

    const onSubmit = (formData: InterestSitesMaintenance) => {
        if (fileError) {
            handleErrorFile()
            return;
        }
        onSave(editId, formData);
        toggleForm();
    };

    return (
        <div className='box'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h3>{editId ? 'Modificar' : 'Agregar'}</h3>
                {/* Fila 1 */}
                <div className='row'>
                    <div className='col-12 col-md-6 mb-3'>
                        <label className='form-label'>Título</label>
                        <input type="text"
                            className='form-control'
                            {...register('title', { required: 'El título es requerido' })}
                        />
                        {errors.title && <small className="text-danger">{errors.title.message}</small>}

                    </div>
                    <div className='col-12 col-md-6 mb-3'>
                        <label className='form-label'>URL</label>
                        <input
                            type="text"
                            className='form-control'
                            {...register('url', { required: 'La URL es requerida' })}
                        />
                        {errors.url && <small className="text-danger">{errors.url.message}</small>}
                    </div>
                </div>
                {/* Fila 2 */}
                <div className='row'>
                    <div className='col-12 col-md-6 mb-3'>
                        <label className='form-label'>País</label>
                        <select
                            {...register('country', { required: 'El país es requerido' })}
                            className='form-select'
                        >
                            <option value="" selected disabled >Selecciona un país</option>
                            {countriesArray.map(country => (
                                <option key={country} value={countryDictionary(country)}>{country}</option>
                            ))}
                        </select>
                        {errors.country && <small className="text-danger">{errors.country.message}</small>}
                    </div>
                    <div className='col-12 col-md-6 mb-3'>
                        <label className='form-label'>SVG</label>
                        <input type="file" accept=".svg" onChange={handleFileChange} className='form-control' />
                        {fileError && <small className="mt-2 d-block text-danger">{fileError}</small>}
                    </div>
                </div>
                {/* Fila 3 */}
                <div className='row'>
                    <div className='col-12 col-md-6 mb-3'>
                        <label className='form-label'>VPN</label>
                        <div className='d-flex align-items-center gap-2'>
                            <Controller
                                control={control}
                                name="vpn"
                                render={({ field }) => (
                                    <input
                                        type="checkbox"
                                        checked={field.value === 1}
                                        onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
                                        className='form-check-input'
                                    />
                                )}
                            />
                            <label htmlFor="vpn-checkbox" className='fw-normal'>Requerido</label>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 mb-3'>
                        <label className='form-label'>Password</label>
                        <div className='d-flex align-items-center gap-2'>
                            <Controller
                                control={control}
                                name="password"
                                render={({ field }) => (
                                    <input
                                        type="checkbox"
                                        checked={field.value == 1 ? true : false}
                                        onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
                                        className='form-check-input'
                                    />
                                )}
                            />
                            <label htmlFor="password-checkbox" className='fw-normal text-center'>Requerido</label>
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-end'>
                    <button type="button" className='btn btn-secondary mx-2' onClick={() => toggleForm()}>Cancelar</button>
                    <button type="submit" className='btn btn-degradado'>Guardar</button>
                </div>
            </form>
        </div>
    );
};

export default Form;
