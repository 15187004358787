import React from "react";
import { CheckBoxSample } from "../commonComponents/CheckBoxSample";
import { countrys } from "./CountryDefn";
export const MaintenancePaises = ({ data, setdata }) => {

  return (
    <div className="table-responsive">
      <table className="table table-even table-section table-borderless d-md-table">
        <thead>
          <tr>
            <th className="cap">Pais</th>
            <th className="cap">Activo</th>
            <th className="cap">Mensaje</th>
          </tr>
        </thead>

        <tbody>
          {data &&
            data?.map((item, index) => {
              
              return (
                <tr key={index}>
                  <td>{countrys.find((item2) => item2.valor === item.Country).descripcion}</td>
                  <td>
                    <CheckBoxSample
                      onChange={() => (
                        setdata(
                          data.map((item2) => {
                            if (item2.Country === item.Country) {
                              return {
                                ...item2,
                                Active: !item.Active ,
                              };
                            } else {
                              return item2;
                            }
                          })
                        )
                      )}
                      defaultValue={item.Active }
                    />
                  </td>
                  <td>
                    <input
                      name="Message"
                      className="form-control"
                      type="text"
                      defaultValue={item.Message}
                        onChange={(e) => {
                            console.log("e", e.target.value);
                            setdata(
                                data.map((item2) => {
                                    if (item2.Country === item.Country) {
                                        return {
                                            ...item2,
                                            Message: e.target.value,
                                        };
                                    } else {
                                        return item2;
                                    }
                                })
                            );
                        }}

                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};
