import { Modal } from "react-bootstrap";
import { useSubscriptionContext } from "../context";

export const ModalConfirmTerms = () => {
  const { openModalConfirm, handleModalConfirm, deleteAllSubs } =
    useSubscriptionContext();

  return (
    <Modal
      show={openModalConfirm}
      id="confirm"
      centered
      onHide={handleModalConfirm}
    >
      <Modal.Header className="border-0 pb-0" closeButton />
      <Modal.Body className="text-center py-4">
        <i
          className="fa fa-exclamation-triangle fa-3x color-orange mb-4"
          aria-hidden="true"
        />
        <h2 className="h5 mb-2">
          ¿Estás seguro que deseas eliminar todas tus suscripciones?
        </h2>
        <p className="mb-2">
          Si eliminas tus suscripciones, no recibirás más correos de Noticias,
          Novedades sobre Beneficios, Servicios y más.
        </p>
        <div className="d-flex justify-content-center mt-4">
          <button className="btn btn-degradado" onClick={deleteAllSubs}>
            Eliminar
            <i
              className="fa fa-chevron-right fa-fw fa-xs"
              aria-hidden="true"
            ></i>
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
