import React from 'react'
import { FeedBackContent } from '../../../../../utilities/constantsTexts'
import ToastAlert from '../../../../../commons/toast/toast'
import { toastOptions } from '../../../../../commons/toast/constants'
import { Box } from '@mui/material'
import feedbackStyle from '../../../styles/feedback.module.css'

const EndFeedback = () => {
  return (
    <Box className={feedbackStyle.end_feedback + ' end_feedback'}>
      <ToastAlert message={FeedBackContent.successToast} variant={toastOptions.green}/>
    </Box>
  )
}

export default EndFeedback