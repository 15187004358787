import React, { useEffect } from "react";
import { InfoCencoTalento } from "./InfoCencoTalento";
import { RequisitosCencoTalento } from "./RequisitosCencoTalento";
import { Button } from "../../../commonComponents";
import { useCencoUI } from "../../hooks/useCencoUI";
import { useCencoInfo } from "../../hooks/useCencoInfo";
import ErrorScreen from "../../../commonComponents/error-screen/error-screen";

export const InfoDetails = () => {
    const { setPage } = useCencoUI();
    const { applicationInfo, hasErrorInfo } = useCencoInfo();

    if (hasErrorInfo) {
        return (
            <ErrorScreen
                title=""
                etype="internalServerError"
                message="Ha ocurrido un error cargando el proceso de postulación."
            />
        );
    }

    return (
        <>
            <section className="info-cenco-talento">
                <h1>Impulsa</h1>

                <RequisitosCencoTalento />

                <InfoCencoTalento />

                <div className="d-grid d-md-flex justify-content-md-between gap-2 flex-nowrap mt-5">
                    <Button
                        styleType="link"
                        onClick={() => setPage("SEARCH_APPLY")}
                    >
                        Volver
                    </Button>
                </div>
            </section>
        </>
    );
};
