import { createContext } from "react";
import {
    JobInfo,
    SearchFilters,
    SearchFiltersV2,
} from "../../interfaces/applyInterface";

interface Props {
    activeJob?: JobInfo;
    applyList?: JobInfo[];
    searchFilters?: SearchFilters;
    searchFiltersV2: SearchFiltersV2;
    filterSearchInput: string;
    filterArea: string;
    filterFlag: string;
    filterworkDay: string;
    filterUbication: string;

    setActiveAllAplys: () => void;
    setActiveRequisition: (jobRequisition: JobInfo) => void;
    setApplyList: (applyList: JobInfo[]) => void;
    setSearchFilters: (filters: SearchFilters) => void;
    setSearchFiltersV2: (filters: SearchFiltersV2) => void;
    setToggleActiveApply: (applyId: string[]) => void;
    setFilterSearchInput: (SearchInput: string) => void;
    setFilterArea: (Area: string) => void;
    setFilterFlag: (Flag: string) => void;
    setFilterworkDay: (workDay: string) => void;
    setFilterUbication: (Ubication: string) => void;
}

export const ApplyContext = createContext({} as Props);
