export const findMenuItemsByPath = (menuItems, path, parents = []) => {

  // Recorrer todos los elementos en menuItems
  for (let i = 0; i < menuItems.length; i++) {
    const menuItem = menuItems[i];

    // Verificar si el path coincide con el valor de "url"
    if (menuItem.url === path) {
      return [...parents, menuItem]; // Se encontró el elemento y sus padres
    }

    // Verificar si hay subitems y llamar recursivamente a la función
    if (menuItem.subitems && menuItem.subitems.length > 0) {
      const foundMenuItem = findMenuItemsByPath(menuItem.subitems, path, [
        ...parents,
        menuItem,
      ]);
      if (foundMenuItem) {
        return foundMenuItem; // Se encontró el elemento en los subitems y sus padres
      }
    }
  }
  // No se encontró el elemento en menuItems y sus subitems
  return null;
};
