import React from "react";
import styles from '../../resources/css/style-COL.module.css';
import { Link } from "react-router-dom";


const SolicitudesJefe = ()=>{

    return (
        <>
        
            
            <nav className={`${styles["breadcrumbs"]} ${styles["d-none"]} ${styles["d-lg-block"]}`} aria-label="breadcrumb">
                <ol className={styles["breadcrumb"]}>
                    <li className={styles["breadcrumb-item"]}>
                        <Link to="/inicio">
                            <i
                                className={`${styles["fa"]} ${styles["fa-home"]}`}
                                aria-hidden="true"
                            ></i>{" "}
                            Inicio
                        </Link>
                    </li>
                    <li className={styles["breadcrumb-item"]}>
                        Vacaciones
                    </li>
                    {/* <li className={styles["breadcrumb-item"]}><a href="#"><i className={`${styles["fa"]} ${styles["fa-home"]}`} aria-hidden="true"></i> Inicio</a></li>
                    <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">Vacaciones</li> */}
                </ol>
            </nav>
         
            <section className={styles["vacaciones"]}>

                <h1>Vacaciones</h1>

                <div className={`${styles["row"]} ${styles["mb-4"]}`}>
               
                    <div className={`${styles["col-12"]} ${styles["col-xl-4"]} ${styles["d-flex"]} ${styles["mb-4"]} ${styles["mb-xl-0"]}`}>
                        <div className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}>
                            <img src="assets/images/foto-usuario-h.png" alt="" className={`${styles["rounded-circle"]} ${styles["mb-3"]}`} width="120" height="120" />
                            <h2 className={`${styles["h3"]} ${styles["mb-2"]}`}>Joel Méndez</h2>
                            <p className={`${styles["h5"]} ${styles["fw-bold"]}`}>Nombre de Cargo</p>

                            <div className={`${styles["data-list-box"]} ${styles["data-list-box-align-middle"]} ${styles["mb-3"]}`}>
                                <ul className={`${styles["list-unstyled"]} ${styles["m-0"]}`}>
                                    <li>
                                        <span className={`${styles["ic-circle"]} ${styles["ic-circle-primary"]}`}>2</span>
                                        Solicitudes Pendientes
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    
                    <div className={`${styles["col-12"]} ${styles["col-xl-8"]} ${styles["d-flex"]}`}>
                        <div className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}>
                            <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Solicitudes Pendientes</h2>
                            
                            <div className={styles["table-responsive"]}>
                                <table className={`${styles["table"]} ${styles["table-even"]} ${styles["table-section"]} ${styles["table-borderless"]} ${styles["d-none"]} ${styles["d-md-table"]}`}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th className={styles["text-nowrap"]}>ID Solicitud <Link href="#"><i className={`${styles["fa"]} ${styles["fa-long-arrow-down"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></th>
                                            <th className={styles["text-nowrap"]}>Nombre Solicitante <Link href="#"><i className={`${styles["fa"]} ${styles["fa-long-arrow-down"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></th>
                                            <th className={styles["text-nowrap"]}>Fecha Inicio <Link href="#"><i className={`${styles["fa"]} ${styles["fa-long-arrow-down"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></th>
                                            <th className={styles["text-nowrap"]}>Fecha Término <Link href="#"><i className={`${styles["fa"]} ${styles["fa-long-arrow-down"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></th>
                                            <th className={`${styles["text-nowrap"]} ${styles["text-center"]}`}>Días <Link href="#"><i className={`${styles["fa"]} ${styles["fa-long-arrow-down"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></th>
                                            <th className={`${styles["text-nowrap"]} ${styles["text-center"]}`}>Beneficio <Link href="#"><i className={`${styles["fa"]} ${styles["fa-long-arrow-down"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></th>
                                            <th className={`${styles["text-nowrap"]} ${styles["text-center"]}`}>Comentario <Link href="#"><i className={`${styles["fa"]} ${styles["fa-long-arrow-down"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><input className={`${styles["form-check-input"]} ${styles["me-1"]} ${styles["h5"]} ${styles["m-0"]}`} type="checkbox" value="" id="check-1"/></td>
                                            <td>00001293</td>
                                            <td>María Julia Rodriguez</td>
                                            <td>30/11/2022</td>
                                            <td>09/12/2022</td>
                                            <td className={styles["text-center"]}>7</td>
                                            <td className={styles["text-center"]}>3</td>
                                            <td className={styles["text-center"]}><Link href="#"><i className={`${styles["fa"]} ${styles["fa-pencil"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></td>
                                        </tr>
                                        <tr>
                                            <td><input className={`${styles["form-check-input"]} ${styles["me-1"]} ${styles["h5"]} ${styles["m-0"]}`} type="checkbox" value="" id="check-2"/></td>
                                            <td>00007654</td>
                                            <td>Manuel Gutierrez</td>
                                            <td>01/12/2022</td>
                                            <td>20/12/2022</td>
                                            <td className={styles["text-center"]}>5</td>
                                            <td className={styles["text-center"]}>-</td>
                                            <td className={styles["text-center"]}><Link href="#"><i className={`${styles["fa"]} ${styles["fa-pencil"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                           
                        </div>

                    </div>
               
                </div>

                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-4"]}`}></div>
                    <div className={`${styles["col-12"]} ${styles["col-xl-8"]}`}>
                        <div className={`${styles["row"]} ${styles["justify-content-between"]}`}>
                            <div className={`${styles["col-12"]} ${styles["col-lg-auto"]} ${styles["mb-3"]} ${styles["mb-lg-0"]}`}>
                                <button type="button" className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`}>Ver Históricos <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i></button>
                            </div>
                            <div className={`${styles["col-12"]} ${styles["col-lg-auto"]}`}>
                                <div className={styles["row"]}>
                                    <div className={`${styles["col-6"]} ${styles["col-lg-auto"]}`}>
                                        
                                        <button type="button" className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`} disabled>Rechazar <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i></button>
                                    </div>
                                    <div className={`${styles["col-6"]} ${styles["col-lg-auto"]}`}>
                                        <button type="submit" className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["w-100"]}`}>Aprobar <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        
        </>
    )
}
export default SolicitudesJefe;