import React, { FC } from "react";

interface ModalLoadProps {
    text?: string,
    refOpen: any,
    refClose: any,
    data_target? : string
}

const ModalLoad: FC<ModalLoadProps>= ({text = "", refOpen, refClose, data_target}) => {

    return (
        <>
        <a hidden ref={refOpen} data-bs-toggle="modal" data-bs-target={data_target ? `#${data_target}`: "#modalLoad"}></a>

        <div className="modal fade" id={data_target ? `${data_target}`: "modalLoad"} aria-labelledby={'modalLoad'} aria-hidden="true" data-bs-target="#modalLoad" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                        <button hidden ref={refClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                        <div className="modal-body text-center py-4">
                            <div className="modal-body text-center py-4">
                            <div className="text-center">
                                <span className="spinner-border spinner-border-sm" />
                                <p> {text || 'Cargando información...'} </p>
                            </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        </>
    )

}

export default ModalLoad;