import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Card, CardContent, Button } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CardProps from './utilities/interfaces'
import { categoryPath } from '../../../../utilities/paths'
import cardStyle from './styles/cardStyle.module.css'

const CategoryCard = ({ CardInfo }: CardProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToCategory = (id: number) => {
    navigate(categoryPath + '/' + id, { state: { from: location.pathname } });
  };

  return (
      <Card variant="outlined" className={cardStyle.category_card}
        onClick={() => navigateToCategory(CardInfo.id)}>
        <CardContent className={cardStyle.card_content_wrapper}>
          <img src={CardInfo.cardIcon} className={cardStyle.card_icon}/>
          <div className={cardStyle.card_text_wrapper}>
            <h4 className={cardStyle.card_title}>{CardInfo.cardTitle}</h4>
            <p className={cardStyle.card_content}>{CardInfo.cardSubtitle}</p>
          </div>
          <Button className={cardStyle.card_button}>
            <ArrowForwardIosIcon/>
          </Button>
        </CardContent>
      </Card>
  );
}

export default CategoryCard;