import { Article } from "../../../utilities/interfaces"
import { ignoredWordsList } from '../utils/ignoredWords';
import match from 'autosuggest-highlight/match';
import { extractResultContent } from "../../../pages/searchResults/utils/parseContent";
import parse from "autosuggest-highlight/parse";

export const customFilter = ((options: Article[], inputValue: string): Article[] => {
  // filter by title
  const matchs = filterOptions(options, inputValue)
  if(matchs.length > 0) {
    restoreOptions(options)
    return matchs
  } else {
    // filter by content 
    return searchByContent(options, inputValue)
  }
})

export const restoreOptions = ((options: Article[]): Article[] => {
  options.map((article) => {
    delete article["contentMatch"]
  })
  return options
})

const searchByContent = (options: Article[], search: string): Article[] => {
  const input = cleanTerms(search)
  const articlesWithContent = extractResultContent(options, input)
  const validMatches = filterByContentMatch(articlesWithContent)
  if(validMatches.length > 0) {
    const articlesWithContentFragment = extractContentFragment(validMatches, input)
    const sortedOptions = sortResultsByMatches(articlesWithContentFragment, input)
    return sortedOptions
  } else {
    return []
  }
}

const extractContentFragment = (options: Article[], input: string): Article[] => {
  options.map((article: Article) => {
    if(!article.contentMatch) return
    const matches = match(article.contentMatch, input, { insideWords: true });
    const lastIndex = matches.map((match: number[]) => {return match[0]})
    const trimAfterFragment = lastIndex.map(index => {
      const partFragment = article.contentMatch?.slice(index, -1)
      return partFragment
    })
    if(trimAfterFragment.length > 0) {
      const newIndex = article.contentMatch && checkIsWordFragment(article.contentMatch, trimAfterFragment)
      if(newIndex) {
        const newFragment = article.contentMatch?.slice(newIndex, -1)
        article.contentMatch = '...' + newFragment.split(' ').slice(0, 7).join(' ') + '...'
      } else {
        article.contentMatch = '...' + trimAfterFragment[0]?.split(' ').slice(0, 7).join(' ') + '...'
      }
    }
  })
  return options
}

const checkIsWordFragment = (content: string, fragment: (string | undefined)[] ) => {
  const separateWords = content.split(' ')
  const separateFragment = fragment[0]?.split(' ')
  if(!separateFragment) return
  const fragmentFirstWord = separateFragment[0]
  const matchWord = separateWords.find(word => {
    if(word.includes(fragmentFirstWord)) {
      return word
    }
  })
  if(matchWord) {
    const startIndex = content.indexOf(matchWord)
    return startIndex
  }
}

const filterByContentMatch = (articlesWithContent: Article[]): Article[] => {
  const validMatches =  articlesWithContent.filter((match) => {
    if(match.contentMatch && match.contentMatch !== '') {
      return match
    }
  })
  return validMatches
}

const filterOptions = (options: Article[], text: string): Article[] => {
  const input = cleanTerms(text)
  const matchByAnyWord = filterByWords(options, input)
  if(matchByAnyWord.length > 0) {
    const sortedOptions = sortResultsByMatches(matchByAnyWord, input)
    return sortedOptions
  } else {
    return []
  }
}

const filterByWords = (options: Article[], input: string) => {
  const matches = options.filter((option: Article) => {
    const optionTitle = cleanTerms(option.attributes.title)
    if(textContainWord(input, optionTitle)) {
      return option
    }
  })
  return matches
}

export const textContainWord = (input: string, optionTitle: string) => {
  const matches = match(optionTitle, input, { insideWords: true });
  if(matches.length > 0 ) {
    return true
  } else {
    return false
  }
}

export const cleanTerms = (text: string): string => {
  const inputNormalize = normalizeTerms(text.trim())
  const inputClean = filterCommonWords(inputNormalize)
  if (inputClean !== '') {
    return inputClean
  } else {
    return inputNormalize
  }
}

const normalizeTerms = (text: string): string => {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
}

export const filterCommonWords = (text: string): string => {
  const separateWords = text.split(' ')
  const cleanWords = separateWords.filter(word => { if (!ignoredWordsList.includes(word)) return word })
  return cleanWords.join(' ')
}

const sortResultsByMatches = (options: Article[], text: string) => {
  const sorted = options.sort((a, b) => {
    const optionTitleA = cleanTerms(a.attributes.title)
    const optionTitleB = cleanTerms(b.attributes.title)
    const matchesA = match(optionTitleA, text, { insideWords: true });
    const matchesB = match(optionTitleB, text, { insideWords: true });
    return matchesB.length - matchesA.length
  })
  return sorted
}