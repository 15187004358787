import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import ExperienciaLaboralServices from '../../services/experiencia laboral/experiencia-laboral-service';
import styles from "../../resources/css/style-COL.module.css";

function AgregarExperienciaLaboral({reloadWorkExperiences, countries, changeDisplay}) {
    const [states, setStates] = useState([])
    const [loadingStates, setLoadingStates] = useState(false)
    const [counties, setCounties] = useState([])
    const [loadingCounties, setLoadingCounties] = useState(false)
    const [addWorkExperienceInfo, setAddWorkExperienceInfo] = useState(false)
    const [loadingAddWorkExperience, setLoadingAddWorkExperience] = useState(false)
    const [, setInfoDataAddError] = useState(null)
    const [noDataAdd, setNoDataAdd] = useState(false)
    const [industries, setIndustries] = useState([])
    const [loadingIndustries, setLoadingIndustries] = useState(false)
    const [sectors, setSectors] = useState([])
    const [loadingSectors, setLoadingSectors] = useState(false)
    const [workAreas, setWorkAreas] = useState([])
    const [loadingWorkAreas, setLoadingWorkAreas] = useState(false)
    const [reasonsRetirement, setReasonsRetirement] = useState([])
    const [loadingReasonsRetirement, setLoadingReasonsRetirement] = useState(false)
    const [submitValidated, setSubmitValidated] = useState(false)
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm();

    const onSubmit = async (data) => {
        if(data.subordinates < 0){
            setSubmitValidated(true);
            return;
        }
        const submit = {
            city: data.country === "COL" ? "" : data.city,
            country: data.country,
            county: data.country === "COL" ? data.county : "",
            state: data.country === "COL" ? data.state : "",
            company: data.company,
            industry: data.industry,
            sector: data.sector,
            workarea: data.workarea,
            position: data.position,
            subordinates: data.subordinates.toString(),
            reasons_retirement: data.reasons_retirement,
            start_date: data.start_date,
            end_date: data.end_date
        }
        setLoadingAddWorkExperience(true)
        ExperienciaLaboralServices.AddWorkExperience(submit, setAddWorkExperienceInfo, setLoadingAddWorkExperience, setNoDataAdd, setInfoDataAddError)
    }

    useEffect(() => {
        if (watch("country") === "COL") {
            setLoadingStates(true)
            ExperienciaLaboralServices.GetStates(watch("country"), setStates, setLoadingStates)
        }
    }, [watch("country")])

    useEffect(() => {
        if (watch("country") === "COL") {
            setLoadingCounties(true)
            ExperienciaLaboralServices.GetCounties(watch("country"), watch("state"), setCounties, setLoadingCounties)
        }
    }, [watch("state")])

    useEffect(() => {
        ExperienciaLaboralServices.GetIndustries(setIndustries, setLoadingIndustries)
        ExperienciaLaboralServices.GetSectors(setSectors, setLoadingSectors)
        ExperienciaLaboralServices.GetWorkAreas(setWorkAreas, setLoadingWorkAreas)
        ExperienciaLaboralServices.GetReasonsRetirement(setReasonsRetirement, setLoadingReasonsRetirement)
    }, [])

    // Mensaje de error al enviar un post
    const handleStatusError = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
            html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo.</div>`,
            confirmButtonColor: '#11427C',
        }).then(() => {
            reloadWorkExperiences()
        })
        setLoadingAddWorkExperience(false)
        setNoDataAdd(false)
    }

    // Mensaje de éxito al enviar un post
    const handleStatusSuccess = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
            html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadWorkExperiences()
        })
        setLoadingAddWorkExperience(false)
        setNoDataAdd(false)
    }

    useEffect(() => {
        // noDataAdd ? handleStatusError() : null
        addWorkExperienceInfo ? handleStatusSuccess() : null
    }, [loadingAddWorkExperience])
    return (<>
        {/* <!-- Inicio Agregar Experiencia --> */}
        <div className={`${styles["box"]} ${styles["mb-4"]}`}>
            <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Agregar Experiencia</h2>
            <form>
                {/* <!-- Fila 1 --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Nombre de la Empresa <span className={styles["color-red"]}>*</span></label>
                        <input type="text" className={styles["form-control"]} {...register("company", { required: "* Campo obligatorio", maxLength: 50 })} />
                        <small className={styles["form-error-message"]}>{errors.company?.message}</small>
                    </div>
                </div>
                {/* <!-- Fila 2 --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>País <span className={styles["color-red"]}>*</span></label>
                        <div className={styles["input-group"]}>
                            <select className={styles["select-col"]} {...register("country", { required: "* Campo obligatorio", maxLength: 3 })}>
                                <option disabled value="" selected>Seleccione</option>
                                {
                                    countries.map((c) => {
                                        return (
                                            <option key={c.country} value={c.country}>{c.descr}</option>
                                        )
                                    })
                                }
                            </select>
                            <small className={styles["form-error-message"]}>{errors.country?.message}</small>
                        </div>
                    </div>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]} ${watch("country") === "COL" ? "d-none" : null}`}>
                        <label className={styles["form-label"]}>Ciudad <span className={styles["color-red"]}>*</span></label>
                        <input type="text" className={styles["form-control"]} {...register("city", { required: watch("country") !== "COL" ? "* Campo obligatorio" : false, maxLength: 35 })} />
                        <small className={styles["form-error-message"]}>{errors.city?.message}</small>
                    </div>
                </div>
                {/* <!-- Fila 3 --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]} ${watch("country") !== "COL" ? "d-none" : null}`}>
                        <label className={styles["form-label"]}>Departamento <span className={styles["color-red"]}>*</span></label>
                        <div className={styles["input-group"]}>
                            {!loadingStates ? <select className={styles["select-col"]} {...register("state", { required: watch("country") === "COL" ? "* Campo obligatorio" : false, maxLength: 3 })}>
                                <option disabled value="" selected>Seleccione</option>
                                {
                                    states.map(s => {
                                        return (
                                            <option key={s.state} value={s.state}>{s.descrshort}</option>
                                        )
                                    })
                                }
                            </select> : <div><div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                            <small className={styles["form-error-message"]}>{errors.state?.message}</small>
                        </div>
                    </div>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]} ${watch("country") !== "COL" ? "d-none" : null}`}>
                        <label className={styles["form-label"]}>Ciudad <span className={styles["color-red"]}>*</span></label>
                        <div className={styles["input-group"]}>
                            {!loadingCounties ? <select className={styles["select-col"]} {...register("county", { required: watch("country") === "COL" ? "* Campo obligatorio" : false, maxLength: 3 })}>
                                <option disabled value="" selected>Seleccione</option>
                                {
                                    counties.map((c) => {
                                        return (
                                            <option key={c.county} value={c.county}>{c.descr}</option>
                                        )
                                    })
                                }
                            </select> : <div><div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                            <small className={styles["form-error-message"]}>{errors.county?.message}</small>
                        </div>
                    </div>
                </div>
                {/* <!-- Fila 4 --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Industria <span className={styles["color-red"]}>*</span></label>
                        <div className={styles["input-group"]}>
                            {!loadingIndustries ? <select className={styles["select-col"]} {...register("industry", { required: "* Campo obligatorio", maxLength: 4 })}>
                                <option disabled value="" selected>Seleccione</option>
                                {
                                    industries.map(s => {
                                        return (
                                            <option key={s.id} value={s.id}>{s.description}</option>
                                        )
                                    })
                                }
                            </select> : <div><div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                            <small className={styles["form-error-message"]}>{errors.industry?.message}</small>
                        </div>
                    </div>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Sector <span className={styles["color-red"]}>*</span></label>
                        <div className={styles["input-group"]}>
                            {!loadingSectors ? <select className={styles["select-col"]} {...register("sector", { required: "* Campo obligatorio", maxLength: 4 })}>
                                <option disabled value="" selected>Seleccione</option>
                                {
                                    sectors.map(s => {
                                        return (
                                            <option key={s.id} value={s.id}>{s.description}</option>
                                        )
                                    })
                                }
                            </select> : <div><div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                            <small className={styles["form-error-message"]}>{errors.sector?.message}</small>
                        </div>
                    </div>
                </div>
                {/* <!-- Fila 5 --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Área de Trabajo <span className={styles["color-red"]}>*</span></label>
                        <div className={styles["input-group"]}>
                                {!loadingWorkAreas ? <select className={styles["select-col"]} {...register("workarea", { required: "* Campo obligatorio", maxLength: 4 })}>
                                    <option disabled value="" selected>Seleccione</option>
                                    {
                                        workAreas.map(s => {
                                            return (
                                                <option key={s.id} value={s.id}>{s.description}</option>
                                            )
                                        })
                                    }
                                </select> : <div><div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                            <small className={styles["form-error-message"]}>{errors.workarea?.message}</small>
                        </div>
                    </div>
                </div>
                {/* <!-- Fila 6 --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Cargo Ocupado <span className={styles["color-red"]}>*</span></label>
                        <input type="text" className={styles["form-control"]} {...register("position", { required: "* Campo obligatorio", maxLength: 50 })} />
                        <small className={styles["form-error-message"]}>{errors.position?.message}</small>
                    </div>
                </div>
                {/* <!-- Fila 7 --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Número de Personas a Cargo <span className={styles["color-red"]}>*</span></label>
                        <input type="number" className={styles["form-control"]} min={0} max={99999} defaultValue={0} {...register("subordinates", { required: "* Campo obligatorio", maxLength: 5 })} />
                        <small className={styles["form-error-message"]}>{errors.subordinates?.message}</small>
                        <small className={styles["form-error-message"]}>
                                    {submitValidated
                                        ? "* El numero de personas debe ser mayor o igual a cero"
                                        : null}
                                </small>
                    </div>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Motivo Retiro <span className={styles["color-red"]}>*</span></label>
                        <div className={styles["input-group"]}>
                            {!loadingReasonsRetirement ? <select className={styles["select-col"]} {...register("reasons_retirement", { required: "* Campo obligatorio", maxLength: 3 })}>
                                <option disabled value="" selected>Seleccione</option>
                                {
                                    reasonsRetirement.map(s => {
                                        return (
                                            <option key={s.id} value={s.id}>{s.description}</option>
                                        )
                                    })
                                }
                            </select> : <div><div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                            <small className={styles["form-error-message"]}>{errors.reasons_retirement?.message}</small>
                        </div>
                    </div>
                </div>
                {/* <!-- Fila 8 --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Fecha de Ingreso <span className={styles["color-red"]}>*</span></label>
                        <input type="date" className={styles["form-control"]} {...register("start_date", { required: "* Campo obligatorio", maxLength: 10 })} />
                        <small className={styles["form-error-message"]}>{errors.start_date?.message}</small>
                    </div>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Fecha de Retiro <span className={styles["color-red"]}>*</span></label>
                        <input type="date" className={styles["form-control"]} {...register("end_date", { required: "* Campo obligatorio", maxLength: 10 })} />
                        <small className={styles["form-error-message"]}>{errors.end_date?.message}</small>
                    </div>                        
                </div>
                {/* <!-- fin filas --> */}
            </form>

            {/* <div className="row justify-content-end mt-4">
                <div className="col-6 col-md-auto">
                    <button type="button" className="btn btn-primary w-100" disabled>Eliminar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                </div>
                <div className="col-6 col-md-auto">
                    <button type="button" className="btn btn-primary w-100" disabled>Modificar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                </div>
            </div>
 */}

            <div className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mt-4"]}`}>
                <div className={`${styles["col-6"]} ${styles["col-md-auto"]}`}>
                    <button type="button" className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`} onClick={() => changeDisplay(false, null, null)}>Cancelar <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i></button>
                </div>
            </div>

        </div>
        {/* <!-- Fin Agregar --> */}

        <div className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mt-4"]}`}>
            <div className={`${styles["col-12"]} ${styles["col-md-auto"]}`}>
                <button type="button" className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["w-100"]}`} onClick={handleSubmit(onSubmit)} disabled={loadingAddWorkExperience}>Guardar {loadingAddWorkExperience ? <div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div> : <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i>}</button>
            </div>
        </div>
        {noDataAdd ? handleStatusError() : null}
    </>)
}

export default AgregarExperienciaLaboral;