import React from 'react'
import { FeedBackContent } from '../../../../../utilities/constantsTexts'
import {
  IconButton,
  Grid,
} from '@mui/material'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import { LikeButtonsProps } from '../../../utilities/interfaces'
import { feedbackOptions } from '../../../utilities/constants'
import textstyles from '../../../../../commons/styles/textStyles.module.css'
import feedbackStyle from '../../../styles/feedback.module.css'

const LikeButtons = ({ selectOption }: LikeButtonsProps) => {

  const onOptionSelected = (option: string) => {
    selectOption(option)
  }


  return (
    <React.Fragment>
      <p className={`${textstyles.page_subtitle} ${feedbackStyle.feedback_title}`}>
        {FeedBackContent.firstMessage}
      </p>
      <Grid>
        <IconButton
          className={feedbackStyle.positive_feedback_button}
          edge='end'
          aria-label='positive feedback'
          onClick={() => onOptionSelected(feedbackOptions.positive)}
        >
          <span>{FeedBackContent.positiveOption}</span>
          <ThumbUpOffAltIcon />
        </IconButton>
        <IconButton
          className={feedbackStyle.negtive_feedback_button}
          edge='end'
          aria-label='negative feedback'
          onClick={() => onOptionSelected(feedbackOptions.negative)}
        >
          <span>{FeedBackContent.negativeOption}</span>
          <ThumbDownOffAltIcon />
        </IconButton>
      </Grid>
    </React.Fragment>
  )
}

export default LikeButtons