import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Card from './_components/Card'
import useFetchInterestSite from './hooks/useInterestSitesFetch';
import { countryDictionary, reverseCountryDictionary } from '../../utils';

export const InterestSitesContent: React.FC = () => {
    const { data: interestSites, isError, isLoading, country, setCountry } = useFetchInterestSite();

    const countries = ['Argentina', 'Colombia', 'Chile', 'Uruguay', 'Perú', 'Brasil']

    const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedCountryCode = countryDictionary(event.target.value);
        setCountry(selectedCountryCode);
    };

    return (
        <>
            <nav className="breadcrumbs m-3" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: 'none' }} className="fa fa-home" aria-hidden="true"></i>{' '}
                            Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Sitios de Interés
                    </li>
                </ol>
            </nav>
            <section>
                <h1 className='m-3'>Sitios de Interés</h1>
                <div className="box">
                    <div className='tit-group d-flex justify-content-between'>
                        <h2 className="h4 tit-section">Sitios</h2>
                        <div>
                            <div className="input-group">
                                <select
                                    className='form-select'
                                    name="country"
                                    onChange={handleCountryChange}
                                    value={reverseCountryDictionary(country)}
                                >
                                    <option value="" disabled>Selecciona un país</option>
                                    {countries.map(country => (
                                        <option key={country} value={country} >
                                            {country}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        {isError && <p>Error al cargar los sitios de interés.</p>}
                        {isLoading ? (<div className="text-center"><div className="spinner-border text-primary m-4"></div></div>) : (
                            <>
                                {interestSites && interestSites.length > 0 ? (
                                    interestSites.map((site, index) => (
                                        <div key={index} className="col-md-6">
                                            <Card
                                                url={site.url}
                                                icon={site.class}
                                                titulo={site.title}
                                                requiresVPN={site.vpn === 1}
                                                requiresPassword={site.password === 1}
                                            />
                                        </div>
                                    ))
                                ) : (<div>Sin datos</div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};
