import React from 'react';
import { Link } from "react-router-dom";
import defaultColaboradorImage from "../../resources/images/default-colaborador-image.png";
import useMiEquipo from './hooks/useMiEquipo';

const ListaMiEquipo = ({ seeDetail }) => {
    const { equipo, statusEquipo, loadingEquipo } = useMiEquipo();

    return (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        <Link to="#" onClick={() => (isOnline ? (colaborador === "error" ? window.location.reload() : null) : null)}>
                            <i style={{ pointerEvents: "none" }} aria-hidden="true"></i> Mi Equipo
                        </Link>
                    </li>
                </ol>
            </nav>
            <section className="mi-equipo">
                <h1>Mi Equipo</h1>
                <div className="box mb-4">

                    {loadingEquipo ? <div><div data-testid="loading" className="full-spinner">
                        <div className="spinner-border text-primary"></div>
                    </div></div> : <table className="table table-even table-section table-borderless align-middle d-none d-md-table">
                        <thead>
                            <tr>
                                <th className="col-4">Nombre</th>
                                <th className="col-2">País</th>
                                <th className="col-2">Posición</th>
                                <th className="col-3 text-center">Ver ficha</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                statusEquipo == 200 && equipo.length > 0 ? equipo.map((item, index) => {
                                    return (
                                        <tr key={item.legajo === 'object' ? item.legajo.low : item.legajo}>
                                            <td>
                                                <img src={item.photo !== undefined ? (item.photo !== "" ? `data:image/png;base64,${item.photo}` : defaultColaboradorImage) : defaultColaboradorImage} alt="foto colaborador" className="rounded-circle me-2" width="40" height="40" />
                                                {item.name} {item.last_name}
                                            </td>
                                            <td>{item.country}</td>
                                            <td>{item.position}</td>
                                            <td className="text-center">
                                                <button className="btn" onClick={() => seeDetail(item)}>
                                                    <Link className="btn-degradado btn btn-sm text-capitalize ps-3 pe-3" to='./detalleEmpleado'>
                                                        Ver Ficha
                                                    </Link>
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })
                                    :
                                    <tr>
                                        <td>Sin resultados</td>
                                    </tr>}
                        </tbody>
                    </table>
                    }
                </div>
            </section>
        </>
    );
};

export default ListaMiEquipo;