import { PropTypes } from 'prop-types';
import React from 'react';



const CambioAsistenciaResumen = ({ tipo, cambioTurno }) => {

    CambioAsistenciaResumen.propTypes = {
        tipo: PropTypes.any,
        cambioTurno: PropTypes.any
    }

    return (
        <>
            <div className="row d-md-flex">
                <div className="col-12 col-md-6 mb detail-label">
                <div className="detail-label">Antes</div>
                <div className="p-3 align-items-stretch w-100 border bg-light-2 boxes">
                <div className="data-list-box data-list-box-align-middle">
                    <ul className="list-unstyled list-unstyled">
                        {tipo === "Franco" ?
                            <li>
                                <span className="data-list-box__title col-6 box-label d-inline-block">Día {tipo === 'Franco' && 'Franco'}:</span>
                                <span className="data-list-box__text text-capitalize">{cambioTurno?.previous?.dateDescription}</span>
                            </li>
                            : 
                            <React.Fragment>
                                 <li>
                                <span className="data-list-box__title col-6 box-label d-inline-block">Día a trabajar: </span>
                                <span className="data-list-box__text text-capitalize">{cambioTurno?.previous?.dateDescription}</span>
                            </li>
                            <li>
                                <span className="data-list-box__title col-6 box-label d-inline-block">Horario: </span>
                                <span className="data-list-box__text text-capitalize">{cambioTurno?.previous?.startTime} - {cambioTurno?.previous?.endTime}</span>
                            </li>
                            </React.Fragment>
                           }
                    </ul>
                </div>
            </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="detail-label">Después</div>
                <div className="p-3 align-items-stretch w-100 border boxes">
                <div className="data-list-box data-list-box-align-middle">
                    <ul className="list-unstyled list-unstyled">
                        {tipo === "Franco" ?
                            <div>
                                <li>
                                    <span className="data-list-box__title col-6 box-label d-inline-block"> Día Franco:</span>
                                    <span className="data-list-box__text text-capitalize">{cambioTurno?.changed?.dateDescription}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-6 box-label d-inline-block"> Día a Trabajar:</span>
                                    <span className="data-list-box__text text-capitalize">{cambioTurno?.previous?.dateDescription}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-6 box-label d-inline-block">Horario: </span>
                                    <span className="data-list-box__text text-capitalize">{cambioTurno?.changed?.startTime} - {cambioTurno?.changed?.endTime}</span>
                                </li>
                            </div>
                            :
                            <div>
                                <li>
                                    <span className="data-list-box__title col-6 box-label d-inline-block"> Día a Trabajar:</span>
                                    <span className="data-list-box__text text-capitalize">{cambioTurno?.previous?.dateDescription}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-6 box-label d-inline-block">Horario: </span>
                                    <span className="data-list-box__text text-capitalize">{cambioTurno?.changed?.startTime} - {cambioTurno?.changed?.endTime}</span>
                                </li>
                            </div>}
                    </ul>
                </div>
            </div>
                </div>
            </div>
        
        </>
    )
}

export default CambioAsistenciaResumen;