import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import AprobacionLicenciasServices from '../../services/aprobacion licencias/aprobacion-licencias-service'
import TablaAprobacionLicencias from './tabla-aprobacion-licencias'
import { Link } from 'react-router-dom';

export default function AprobacionLicencias() {
  const [disabled, setDisabled] = useState(true)
  const [toSubmit, setToSubmit] = useState([])
  const [info, setInfo] = useState([])
  const [loadingLicences, setLoadingLicences] = useState(false);
  const [noData, setNoData] = useState(false)
  const [estadoDeAprobacion, setEstadoDeAprobacion] = useState('F')
  const [filteredData, setFilteredInfo] = useState([])
  const [fetched, setFetched] = useState(false);

  // estados de rechazo/aprobacion vacaciones
  const [actionData, setActionData] = useState(false)
  const [loadingAction, setLoadingAction] = useState(false)
  const [noDataAction, setNoDataAction] = useState(false)
  const [loadingApproveAction, setLoadingApproveAction] = useState(false)
  const [loadingRejectAction, setLoadingRejectAction] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)

  const stateOptions = [{ value: "F", description: "Todas" }, { value: "P", description: "Pendientes" }, { value: "A", description: "Aprobadas" }, { value: "R", description: "Rechazadas" }]

  useEffect(() => {
    if (estadoDeAprobacion === "A") {
      AprobacionLicenciasServices.getSubordinatesLicenceList(setFetched, estadoDeAprobacion, setInfo, setFilteredInfo, setLoadingLicences, setNoData)
    }
    if (estadoDeAprobacion === "R") {
      AprobacionLicenciasServices.getSubordinatesLicenceList(setFetched, estadoDeAprobacion, setInfo, setFilteredInfo, setLoadingLicences, setNoData)
    }
    if (estadoDeAprobacion === "P") {
      AprobacionLicenciasServices.getSubordinatesLicenceList(setFetched, estadoDeAprobacion, setInfo, setFilteredInfo, setLoadingLicences, setNoData)
    }
    if (estadoDeAprobacion === "F") {
      AprobacionLicenciasServices.getSubordinatesLicenceList(setFetched, estadoDeAprobacion, setInfo, setFilteredInfo, setLoadingLicences, setNoData)
    }

  }, [estadoDeAprobacion])
  /*   // Funcion para activar botones
    const isDisabled = (ausencias) => {
      setDisabled(ausencias)
    } */
  // Funcion que trae objeto para enviar al post
  /*  const action = (ausencias) => {
     const aus = ausencias.map(aus => JSON.parse(aus))
     setToSubmit(aus)
   } */

  // Filtro por empleados
  const handleSelectEmpleado = (e) => {
    e.preventDefault()
    if (e.target.value === "todos") {
      setFilteredInfo(info)
    } else {
      let infoFiltered = info.filter(aus => aus.userId === e.target.value)
      setFilteredInfo(infoFiltered)
    }
  }

  // Filtro por estado
  const handleSelectOption = (e) => {
    setEstadoDeAprobacion(e.target.value)
    // setLoadingLicences(true)
  }

  // Fetching de datos 
  useEffect(() => {
    getAbsencesList();
    if (fetched)
      console.log("fetched")
  }, [fetched])

  const getAbsencesList = () => {
    AprobacionLicenciasServices.getSubordinatesLicenceList(setFetched, estadoDeAprobacion, setInfo, setFilteredInfo, setLoadingLicences, setNoData)
  }
  const filterOptions =
    Array.from(new Set(info?.map(item => item.userId)))?.map(userId => {
      return {
        userId: userId,
        userName: info.find(i => i.userId === userId)?.userName
      }
    })
  // Mensaje de error al enviar un post
  const handleStatusError = () => {
    setLoadingLicences(true)
    Swal.fire({
      allowOutsideClick: false,
      position: 'center',
      title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
      html: `<div style="text-align: center; font-size: 15px"> ${errorMessage}</div>`,
      confirmButtonColor: '#0169b5',
    })
      .then(
        () => {
          getAbsencesList()
        }
      )
    setNoDataAction(false)
    setLoadingRejectAction(false)
    setLoadingApproveAction(false)
  }

  // Mensaje de éxito al enviar un post
  const handleStatusSuccess = () => {
    setLoadingLicences(true)
    Swal.fire({
      allowOutsideClick: false,
      position: 'center',
      title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
      html: `<div style="text-align: center; font-size: 15px"> ${successMessage} </div>`,
      confirmButtonColor: '#0169b5',
    })
      .then(
        () => {
          getAbsencesList()
        }
      )

    setLoadingRejectAction(false)
    setLoadingApproveAction(false)
    setActionData(false)
  }

  // Rechazar peticion de vacaciones
  const onRejectSubmit = async () => {
    setLoadingRejectAction(true)
    const approvals = toSubmit.map(aus => {
      return {
        idSolicitud: `${aus}`,
      }
    })
    const submit = {
      "status": "R",
      "approvals": approvals
    }
    AprobacionLicenciasServices.licenceAction(submit, setActionData, setLoadingAction, setNoDataAction, setErrorMessage, setSuccessMessage)
  }

  // Aprobar peticion de vacaciones
  const onApproveSubmit = () => {
    setLoadingApproveAction(true)
    const approvals = toSubmit.map(aus => {
      return {
        idSolicitud: `${aus}`,
      }
    })
    const submit = {
      "status": "A",
      "approvals": approvals
    }
    AprobacionLicenciasServices.licenceAction(submit, setActionData, setLoadingAction, setNoDataAction, setErrorMessage, setSuccessMessage)
  }
  return (
    <>
      <nav className="breadcrumbs" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/inicio">
              <i style={{ pointerEvents: "none" }} className="fa fa-home color-orange" aria-hidden="true"></i> Inicio
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Aprobación de Licencias
          </li>
        </ol>
      </nav>
      <section className="header-page-container">
        <h1>Aprobación de Licencias</h1>
        <div>
          <div className="buttons">
            <button disabled={disabled} type="button" className="button reject" onClick={onRejectSubmit} >
              Rechazar {loadingRejectAction ? <div className="spinner-border spinner-border-sm"></div> : <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>}
            </button>

            <button disabled={disabled} type="submit" className="button save" onClick={onApproveSubmit} >
              Aprobar {loadingApproveAction ? <div className="spinner-border spinner-border-sm"></div> : <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>}
            </button>
          </div>
        </div>
      </section>
      <section className="mb-3">
        <div className="card-box mb-3">
        <h3>Filtrar <div className='title-underlined'></div></h3>
          <div className="row">
            <div className="mb-2 mb-sm-4 col-lg-4">
              <label className="col-sm-2 col-form-label fw-bold">Estado</label>
              <div className="col">
                <select className="form-select" onChange={e => handleSelectOption(e)}>
                  {stateOptions && stateOptions.map(state => {
                    return <option key={state.description} value={state.value} defaultValue={state.value === 'F'}>{state.description}</option>
                  })}
                </select>
              </div>
            </div>

            <div className="mb-2 col-lg-4">
              <label className="col-sm-2 col-form-label fw-bold">Empleado</label>
              <div className="col">
                <select className="form-select" onChange={e => handleSelectEmpleado(e)}>
                  <option value="todos">Todos</option>
                  {filterOptions && filterOptions.map(emp => {
                    return <option key={emp.userId} value={emp.userId}>{emp.userName}</option>
                  })}
                </select>
              </div>
            </div>

          </div>

        </div>

        {!loadingLicences ? <TablaAprobacionLicencias info={filteredData} action={setToSubmit} allowAction={setDisabled} /> :
          <div data-testid="loading" className="full-spinner">
            <div className="spinner-border text-primary"></div>
          </div>}

        {noDataAction ? handleStatusError() : null}
        {actionData ? handleStatusSuccess() : null}



      </section>
    </>
  )
}
