import React, { useRef, useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { HttpClient } from '../../utilities/apiServices/httpClient'
import { ApiRequest } from '../../utilities/apiServices/apiRequest'
import { parseHtmlContent } from './utilities/contentParser'
import { UpdateLocalStorage } from '../../utilities/helpers/updateStorage'
import { Article, RelatedArticles as RelatedArticlesType } from '../../utilities/interfaces'
import { LoadingContent, BackButton, BreadCrumbs } from '../../utilities/constantsTexts'
import FeedBack from './components/feedback/feedback'
import RelatedArticles from './components/relatedArticles/relatedArticles'
import { Button, Grid } from '@mui/material'
import { paths } from '../../utilities/paths'
import styles from '../../commons/styles/rootStyle.module.css'
import articleStyles from './styles/articleStyle.module.css'
import Loading from '../../commons/dataStatus/loading/loading'
import './styles/articleMedia.css'

const httpClient = new HttpClient()
const apiService = new ApiRequest(httpClient)
const update = new UpdateLocalStorage()

const HCArticle = () => {
  const { id } = useParams()
  const backButtonTitle = BackButton.simple
  const containerRef = useRef<HTMLDivElement>(null);
  const [contentToShow, setContent] = useState<HTMLElement>()
  const [loading, setLoading] = useState(true)
  const [statusMessage, setStatusMessage] = useState(LoadingContent.loading)
  const [validData, setValidData] = useState(false)
  const [articleData, setArticleData] = useState<Article>()
  const [relatedArticles, setRelatedArticles] = useState<RelatedArticlesType[]>()
  const [comesFromPrevArticle, setFromPrevArticle] = useState(false)
  const [prevArticle, setPrevArticle] = useState('')
  const navigate = useNavigate()
  const location = useLocation();

  useEffect(() => {
    let from = location.state?.from;
    from && update.updateSlot(update.keyConstants[6], from)
  }, [])

  useEffect(() => {
    setLoading(true)
    fetchData()
      .then(article => {
        if (!isValidData(article)) return
        setValidData(true)
        setLoading(false)
        const content = article.attributes
        const pageTitle = article.attributes.title
        setArticleData(article)
        setContent(parseHtmlContent(content.content, pageTitle))
        getRelatedArticles(article)
        updateBreadCrumb(article)
        checkForPrevArticle()
      })
      .catch((err) => {
        setValidData(false)
        emitError(err)
      }).finally(() => {
        setLoading(false)
      })

    return () => {
      if (!containerRef.current?.hasChildNodes) return
      contentToShow?.parentNode?.removeChild(contentToShow);
    }
  }, [id])

  const emitError = (err: number) => {
    const newError = {
      errorStatus: err,
      errorFire: 'getArticleByID',
      errorComponent: 'HCArticle'
    }
    update.updateSlot(update.keyConstants[7], JSON.stringify(newError))
  }

  const getRelatedArticles = (article: Article) => {
    if (article.related_articles) {
      setRelatedArticles(article.related_articles)
    }
  }

  const isValidData = (apiResponse: Article): boolean => {
    if (!apiResponse || !apiResponse.attributes) {
      setValidData(false)
      setStatusMessage(LoadingContent.notValid)
      emitError(500)
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    if (containerRef.current === null) return
    containerRef.current.innerHTML = ''
    contentToShow && contentToShow && containerRef.current?.appendChild(contentToShow)
  }, [contentToShow])


  const fetchData = async () => {
    const data = await apiService.getArticleByID(Number(id))
    sendUserInfo()
    return data
  }

  const sendUserInfo = async () => {
    const response = await apiService.sendUserHistory(Number(id))
    console.log(response)
  }

  const backToPrevUrl = () => {
    const prevPath = localStorage.getItem(update.keyConstants[6])
    let from = comesFromPrevArticle ? prevArticle : prevPath
    if (from && from !== null) {
      navigate(from)
      removeLastItem()
    } else {
      navigate(paths[1].path)
    }
  }

  const checkForPrevArticle = () => {
    const saved = localStorage.getItem(update.keyConstants[5])
    const isSamePath = checkSamePath(saved)
    const areSavedArticles = checkForSavedArticles(saved)
    if (areSavedArticles && !isSamePath) {
      getLastArticle(saved)
      setFromPrevArticle(true)
    } else {
      setFromPrevArticle(false)
    }
  }

  const getLastArticle = (prevArticles: string | null) => {
    const articlesList = prevArticles?.split(',')
    const lastItem = articlesList && articlesList[articlesList.length - 1]
    if (lastItem) {
      setPrevArticle(lastItem)
    }
  }

  const removeLastItem = () => {
    const saved = localStorage.getItem(update.keyConstants[5])
    const articlesList = saved?.split(',')
    if (articlesList && articlesList.length >= 1) {
      articlesList.pop()
      update.updateSlot(update.keyConstants[5], articlesList.toString())
    } else if (saved === null || articlesList && articlesList.length === 0) {
      update.clearSlot(update.keyConstants[5])
    }
  }

  const checkForSavedArticles = (prevArticles: string | null) => {
    if (prevArticles && prevArticles !== null) {
      return true
    } else {
      return false
    }
  }

  const checkSamePath = (prevArticle: string | null) => {
    const currentPath = location.pathname
    if (prevArticle === currentPath) {
      return true
    } else {
      return false
    }
  }

  const updateBreadCrumb = (article: Article) => {
    const pageTitle = article.attributes.title
    update.updateSlot(update.keyConstants[0], pageTitle)
    const categoryTitle = article.attributes.sub_category?.data.attributes.category.data.attributes.title
    const categoryID = article.attributes.sub_category?.data.attributes.category.data.id
    if (categoryTitle && categoryTitle !== null) {
      const subRoute = BreadCrumbs.allCategories + '/' + BreadCrumbs.category
      update.updateSlot(update.keyConstants[1], subRoute)
      update.updateSlot(update.keyConstants[3], JSON.stringify({ categoryTitle, categoryID }))
    }
  }

  return (
    <>
      {loading && 
        <Grid item className={articleStyles.loading_wrapper}><Loading noMessage={true} bigSpinner={true}/></Grid> }
        {validData && !loading && 
          <React.Fragment>
            <div className={articleStyles.article_wrapper} ref={containerRef}>
            </div>
            {articleData &&
              <FeedBack article={articleData} />
            }
            {relatedArticles && <RelatedArticles articles={relatedArticles} />}
            <Grid item xs={12} className={articleStyles.article_back_button_wrapper}>
              <Button className={styles.terciary_button} onClick={() => backToPrevUrl()}>
                {backButtonTitle}
              </Button>
            </Grid>
          </React.Fragment>
        }
      {!validData && !loading && <p>{statusMessage}</p>}
    </>
  );
}

export default HCArticle;