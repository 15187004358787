import { Auth } from "aws-amplify";
import axios from "axios";

const url = process.env.REACT_APP_BACKEND_URL_GLOBAL;

const IdiomasService = {
    GetListLanguages: async (setListLanguages, setLoadingListLanguages, setNoDataListLanguages) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(url + '/api/rrhhbase/languages', { headers: head })
                setListLanguages(data)
                setLoadingListLanguages(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingListLanguages(false);
                setNoDataListLanguages(true);
            })
        } catch (error) {
            console.error(error);
        }

    },
    GetLanguages: async (setInfoLanguages, setLoadingLanguages, setNoDataLanguages) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(url + '/api/emp/languages', { headers: head })
                setInfoLanguages(data)
                setLoadingLanguages(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingLanguages(false);
                setNoDataLanguages(true);
            })
        } catch (error) {
            console.error(error);
        }

    },
    GetLanguage: async (id, setInfoLanguage, setLoadingLanguage, setNoDataLanguage) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(url + `/api/emp/languages/${id}`, { headers: head })
                setInfoLanguage(data)
                setLoadingLanguage(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingLanguage(false);
                setNoDataLanguage(true);
            })
        } catch (error) {
            console.error(error);
        }

    },
    DeleteLanguage: async (id, setDeleteLanguage, setLoadingDeleteLanguage, setNoDataDelete) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                await axios.delete(url + '/api/emp/languages/' + id, { headers: head })
                setDeleteLanguage(true)
                setLoadingDeleteLanguage(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingDeleteLanguage(false);
                setNoDataDelete(true);
            })
        } catch (error) {
            console.error(error);
        }

    },
    AddLanguage: async (body, setAddLanguageInfo, setLoadingAddLanguage, setNoDataAdd) => {
        console.log(body, "add body")
        try {
            Auth.currentSession().then(auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                axios.post(url + '/api/emp/languages', body, { headers: head }).then(() => {
                    setAddLanguageInfo(true)
                    setLoadingAddLanguage(false);
                })
            }).catch(function (error) {
                console.log(error);
                setLoadingAddLanguage(false);
                setNoDataAdd(true);
            })
        } catch (error) {
            console.error(error);
        }

    },
    EditLanguage: async (body, setEditLanguageInfo, setLoadingEditLanguage, setNoDataEdit) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                await axios.put(url + '/api/emp/languages', body, { headers: head })
                setEditLanguageInfo(true)
                setLoadingEditLanguage(false);
            }).catch(function (response) {
                console.log(response.response)
                setLoadingEditLanguage(false);
                setNoDataEdit(true);
            })
        } catch (error) {
            console.error(error);
        }

    },
    GetSpeakLevels: async (setSpeakLevel, setLoadingSpeakLevel, setNoDataSpeakLevel) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(url + '/api/rrhhbase/languagespeaklevels', { headers: head })
                setSpeakLevel(data)
                setLoadingSpeakLevel(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingSpeakLevel(false);
                setNoDataSpeakLevel(true);
            })
        } catch (error) {
            console.error(error);
        }
    },
    GetWriteLevels: async (setWriteLevel,setLoadingWriteLevel, setNoDataWriteLevel) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(url + `/api/rrhhbase/languagewritelevels`, { headers: head })
                setWriteLevel(data)
                setLoadingWriteLevel(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingWriteLevel(false);
                setNoDataWriteLevel(true);
            })
        } catch (error) {
            console.error(error);
        }
    },
}

export default IdiomasService;