import React from "react";

interface Props {
    idBtn: string;
    target: string;
    closeTarget: string;
}

const DownloadingModal: React.FC<Props> = ({ idBtn, target, closeTarget }) => {
    return (
        <>
            <button hidden id={idBtn} data-bs-target={"#" + target} data-bs-toggle="modal" data-bs-backdrop="static" data-bs-keyboard="false"  />
            <div className="modal fade" id={target} tabIndex={-1} aria-labelledby={target} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button type="button" hidden id={closeTarget} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center py-4">
                            <i className="fa fa-exclamation-triangle color-orange fa-3x" aria-hidden="true"></i>
                            <h2 className="h5">{"Descargando..."}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DownloadingModal;