import moment from "moment";
import React from "react";
import { FaTrash } from "react-icons/fa";
import { deleteRegistroViaje } from "../../services/RegistroViaje-UY/registroViaje-services";
import { SpinerFullScreen } from "../commonComponents/SpinerFullScreen";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Item } from "semantic-ui-react";
import { Accordion } from "react-bootstrap";
import { Modal, ModalFooter } from "reactstrap";
import { useEffect } from "react";
import { MessageDinamic } from "../commonComponents/MessageDinamic";

export const ViajesRegistrados = ({
  viajesRegistrados,
  loading,
  statusRequest,
  setstatusRequest,
}) => {
  const { loadingDelete } = statusRequest;
  const [indexDelete, setIndexDelete] = useState(null);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [textComemnt, settextComemnt] = useState("");

  return (
    <div className="card-box d-md-block align-items-center mb-5 text-center text-md-start">
      <div className="tit-group d-block justify-content-between">
        <div className="d-flex justify-content-between">
          <h3>
            Viajes Registrados
            <div className="title-underlined"></div>
          </h3>
        </div>
        {loading ? (
          <SpinerFullScreen />
        ) : (
          <div className="table-responsive desktop-table">
            <table className="table table-even table-section table-borderless d-md-table">
              <thead>
                <tr>
                  <th className="cap">FECHA INICIO</th>
                  <th className="cap">FECHA FINAL</th>
                  <th className="cap">DESTINO</th>
                  <th className="cap">ELIMINAR</th>
                  <th className="cap">JUSTIFICACIÓN DE VIAJE</th>
                </tr>
              </thead>
              <tbody>
                {viajesRegistrados &&
                  viajesRegistrados?.map((viaje, index) => {
                    const RegistroViajeData = {
                      beginDt: moment(viaje.BEGIN_DT).format("DD/MM/YYYY"),
                      endDt: moment(viaje.END_DT).format("DD/MM/YYYY"),
                      country: viaje.PAIS,
                    };

                    return (
                      <tr key={index}>
                        <td>{moment(viaje.BEGIN_DT).format("DD/MM/YYYY")}</td>
                        <td>{moment(viaje.END_DT).format("DD/MM/YYYY")}</td>
                        <td>{viaje.DESCRIPTION}</td>
                        <td>
                          {loadingDelete && indexDelete == index ? (
                            <SpinerFullScreen />
                          ) : (
                            <FaTrash
                              onClick={() => {
                                //valido que no se pueda eliminar un viaje que ya paso

                                if (
                                  viaje.BEGIN_DT > moment().format("YYYY-MM-DD")
                                ) {
                                  setstatusRequest({
                                    ...statusRequest,
                                    loadingDelete: true,
                                  });
                                  deleteRegistroViaje(
                                    RegistroViajeData,
                                    setstatusRequest,
                                    statusRequest
                                  );
                                  setIndexDelete(index);
                                }
                              }}
                              className={
                                viaje.BEGIN_DT < moment().format("YYYY-MM-DD")
                                  ? "disabled"
                                  : "delete-icon-registr"
                              }
                            ></FaTrash>
                          )}
                        </td>
                        <td>
                          <div className="dropdown d-flex">
                            <button
                              type="button"
                              className="btn-dropdown"
                              onClick={() => {
                                settextComemnt(viaje.COMMENTS), toggle();
                              }}
                            >
                              <BsThreeDotsVertical />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
        {loading ? (
          <SpinerFullScreen />
        ) : (
          <div className="d-md-none">
            <Accordion
              className="accordion accordion-flush accordion-light accordion-table"
              defaultActiveKey="0"
            >
              {viajesRegistrados &&
                viajesRegistrados?.map((viaje, index) => {
                  const RegistroViajeData = {
                    beginDt: moment(viaje.BEGIN_DT).format("DD/MM/YYYY"),
                    endDt: moment(viaje.END_DT).format("DD/MM/YYYY"),
                    country: viaje.PAIS,
                  };
                  return (
                    <Accordion.Item key={index} eventKey={index}>
                      <Accordion.Header
                        className="accordion-header title-emp"
                        id="accordion"
                      >
                        <strong>
                          {moment(viaje.BEGIN_DT).format("DD/MM/YYYY")}
                        </strong>
                      </Accordion.Header>
                      <Accordion.Body className="accordion-body p-0">
                        <div className="data-list-box data-list-box-even">
                          <ul className="data-list-section--item list-unstyled">
                            <li>
                              <span className="data-list-box__title">
                                Fecha de inicio
                              </span>
                              <span className="data-list-box__text">
                                {moment(viaje.BEGIN_DT).format("DD/MM/YYYY")}
                              </span>
                            </li>
                            <li>
                              <span className="data-list-box__title">
                                Fecha Final
                              </span>
                              <span className="data-list-box__text">
                                {moment(viaje.END_DT).format("DD/MM/YYYY")}
                              </span>
                            </li>
                            <li>
                              <span className="data-list-box__title">
                                Destino
                              </span>
                              <span className="data-list-box__text">
                                {viaje.DESCRIPTION}
                              </span>
                            </li>
                            <li>
                              <span className="data-list-box__title">
                              Justificación de Viaje
                              </span>
                              <span className="data-list-box__text text-truncate">
                                {viaje.COMMENTS}
                              </span>
                            </li>
                            <li>
                              <span className="data-list-box__title">
                                Eliminar
                              </span>
                              {loadingDelete && indexDelete == index ? (
                                <SpinerFullScreen />
                              ) : (
                                <FaTrash
                                  onClick={() => {
                                    if (
                                      viaje.BEGIN_DT > moment().format("YYYY-MM-DD")
                                    ) {
                                    setstatusRequest({
                                      ...statusRequest,
                                      loadingDelete: true,
                                    });
                                    deleteRegistroViaje(
                                      RegistroViajeData,
                                      setstatusRequest,
                                      statusRequest
                                    );
                                    setIndexDelete(index);
                                  }}}
                                  className={
                                    viaje.BEGIN_DT <
                                    moment().format("YYYY-MM-DD")
                                      ? "disabled"
                                      : "delete-icon-registr"
                                  }
                                ></FaTrash>
                              )}
                            </li>
                          </ul>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
            </Accordion>
          </div>
        )}
      </div>
      <Modal
        modalClassName="modal-properties"
        style={{ padding: 24 }}
        size="md"
        centered
        isOpen={modal}
      >
        <div className="modal-container">
          <div className="modal-data-title">Justificación de Viaje</div>
          <div className="modal-data-body paddingModal">{textComemnt}</div>
          <div className="text-center mb-sm-4"></div>
        </div>
        <ModalFooter>
          <div className="text-center mb-sm-4">
            <button className="btn btn-primary" onClick={() => toggle()}>
              Cerrar
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};
