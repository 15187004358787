import React, { useEffect, useState } from 'react'
import IdiomasService from '../../services/idiomas/idiomas-service'
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import styles from "../../resources/css/style-COL.module.css";

function AgregarIdioma({ listLanguages, writeLevel, speakLevel, reloadLanguages, changeDisplay }) {
    const [addLanguageInfo, setAddLanguageInfo] = useState(false)
    const [loadingAddLanguage, setLoadingAddLanguage] = useState(false)
    const [noDataAdd, setNoDataAdd] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        setLoadingAddLanguage(true)
        IdiomasService.AddLanguage(data, setAddLanguageInfo, setLoadingAddLanguage, setNoDataAdd)
    }

    // Mensaje de error al enviar un post
    const handleStatusError = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
            html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo.</div>`,
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadLanguages()

        })
        setLoadingAddLanguage(false)
        setNoDataAdd(false)
    }

    // Mensaje de éxito al enviar un post
    const handleStatusSuccess = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
            html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadLanguages()

        })
        setLoadingAddLanguage(false)
        setNoDataAdd(false)
    }

    useEffect(() => {
        // noDataAdd ? handleStatusError() : null
        addLanguageInfo ? handleStatusSuccess() : null
    }, [loadingAddLanguage])

    return (
        <>
            {/* <!-- Inicio Ver Idiomas --> */}
            <div className={`${styles["box"]} ${styles["mb-4"]}`}>
                <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Agregar Idiomas</h2>
                <form>
                    {/* <!-- Fila 1 --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-3"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Idioma <span className={styles["color-red"]}>*</span></label>
                            <div className={styles["input-group"]}>
                                <select {...register("language", { required: "* Campo obligatorio" })} className={styles["select-col"]}>
                                    <option value="" selected>Seleccione</option>
                                    {listLanguages?.map((l) => { return (<option key={l.id} value={l.id}>{l.description}</option>) })}
                                </select>
                                <small className={styles["form-error-message"]}>{errors.language?.message}</small>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Fila 2 --> */}
                    <p><strong>Nivel</strong> Principiante <span className={styles["color-red"]}>*</span></p>
                    {/* <!-- Fila 3 --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-10"]} ${styles["col-xl-8"]} ${styles["col-xxl-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Habla</label>
                            <div className={`${styles["row"]} ${styles["mt-2"]}`}>
                                {speakLevel?.sort((a,b) => a.id - b.id).map((s) => {
                                    return (
                                        <div key={s.id} className={`${styles["col-12"]} ${styles["col-md-3"]}`}>
                                            <div className={styles["form-check"]}>
                                                <input className={styles["form-check-input"]} type="radio" value={s.id} name="speaking_level" {...register("speaking_level")} id={s.id} defaultChecked={s.id === "1"} />
                                                <label className={styles["form-check-label"]} htmlFor={s.id}>{s.description}</label>
                                            </div>
                                        </div>)
                                })}
                            </div>
                        </div>
                    </div>
                    {/* <!-- Fila 4 --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-10"]} ${styles["col-xl-8"]} ${styles["col-xxl-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Escribe</label>
                            <div className={`${styles["row"]} ${styles["mt-2"]}`}>
                            {writeLevel?.sort((a,b) => a.id - b.id).map((s) => {
                                    return (
                                        <div key={s.id} className={`${styles["col-12"]} ${styles["col-md-3"]}`}>
                                            <div className={styles["form-check"]}>
                                                <input className={styles["form-check-input"]} type="radio" value={s.id} name="writing_level" {...register("writing_level")} id={s.id} defaultChecked={s.id === "1"}/>
                                                <label className={styles["form-check-label"]} htmlFor={s.id}>{s.description}</label>
                                            </div>
                                        </div>)
                                })}
                            </div>
                        </div>
                    </div>
                    {/* <!-- fin filas --> */}
                </form>

                <div className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mt-4"]}`}>
                    <div className={`${styles["col-6"]} ${styles["col-md-auto"]}`}>
                        <button type="button" className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`} onClick={() => changeDisplay(false, null,null)}>Cancelar <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
            {/* <!-- Fin Agregar --> */}
            <div className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mt-4"]}`}>
                <div className={`${styles["col-12"]} ${styles["col-md-auto"]}`}>
                    <button type="button" onClick={handleSubmit(onSubmit)} className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["w-100"]}`}  disabled={loadingAddLanguage}>Guardar <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i>{loadingAddLanguage ?<div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div>: null}</button>
                </div>
            </div>
            {noDataAdd ? handleStatusError() : null}
        </>
    )
}

export default AgregarIdioma;