import React, { useState } from "react";
import { Button, Share } from "../../../commonComponents";
import { useCencoTalent } from "../../hooks/useCencoTalent";
import { useCencoUI } from "../../hooks/useCencoUI";

export const DetailsTitle = () => {
    const { activeJob } = useCencoTalent();
    const { setPage } = useCencoUI();

    const onClickApply = () => {
        window.open(activeJob?.postulationUrl, "_blank", "noopener,noreferrer");
    };

    const [shareActive, setShareActive] = useState<boolean>(false);

    return (
        <>
            <div className="d-flex flex-column flex-md-row justify-content-between">
                <div className="w-100">
                    <h1 className="m-0">{activeJob?.title ?? ""}</h1>
                    <p className="h6 fw-normal m-0">({activeJob?.id ?? ""})</p>
                </div>

                <div className="d-flex flex-column justify-content-end w-100 w-md-50 w-lg-70">
                    <div className="d-flex justify-content-md-end justify-content-center mb-2">
                        <Button
                            styleType="link"
                            onClick={() => setPage("SEARCH_APPLY")}
                        >
                            <i
                                className="fa fa-chevron-left fa-fw fa-xs color-primary"
                                aria-hidden="true"
                            ></i>
                            Volver a la búsqueda
                        </Button>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-end">
                        <Button
                            onClick={() => setShareActive(true)}
                            styleType="share"
                            className="me-4"
                        >
                            Compartir
                        </Button>

                        <Button styleType="primary" onClick={onClickApply}>
                            Postular
                        </Button>
                    </div>
                </div>
                <Share
                    modalTitle="Compartir Oferta"
                    urlToShare={activeJob?.postulationUrl ?? ""}
                    activeState={shareActive}
                    toggleState={setShareActive}
                    shareIn={[
                        "Whatsapp",
                    ]}
                    emailTitle="Compartir Oferta"
                    emailBody=""
                    infoAlert="Esta vacante sólo puede ser compartida con colaboradores internos de Cencosud."
                />
            </div>
        </>
    );
};
