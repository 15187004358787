import React, { useState, useContext } from 'react';
import FirmaDocumentos from './firma-documentos';
import Certificados from './certificados';
import CarpetaDigital from './carpeta-digital';
import {Context} from '../context';
import { Link } from 'react-router-dom';
import { useCarousel } from '../services/utiles';

const MisDocumentos = (props) => {
    const [carruselSelect, setCarruselSelect] = useState(0);
    const [count, setCount] = useState("cargando...");
    const { firstLoginData } = useContext(Context);
    useCarousel(0,'carousel-mis-doc', firstLoginData);

    return (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">Mis Documentos</li>
                    <li className="breadcrumb-item active" aria-current="page">Carpeta Digital</li>
                </ol>
            </nav>
            <section className="mis-documentos">
                <h1>Mis Documentos</h1>
                <div className="d-none d-md-block mb-4">
                    <div className="row">
                        <div className="col-3 mb-4">
                            <a onClick={(e) => { e.preventDefault(); setCarruselSelect(0) }} className={carruselSelect == 0 ? "btn btn-xl text-uppercase btn-degradado-2" : "btn btn-xl text-uppercase"}>
                                <span className="align-self-center">Carpeta digital</span>
                                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div className="col-3 mb-4">
                            <a onClick={(e) => { e.preventDefault(); setCarruselSelect(1) }} className={carruselSelect == 1 ? "btn btn-xl text-uppercase btn-degradado-2" : "btn btn-xl text-uppercase"}>
                                <span className="align-self-center">Certificados</span>
                                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div className="col-3 mb-4">
                            <a onClick={(e) => { e.preventDefault(); setCarruselSelect(2) }} className={carruselSelect == 2 ? "btn btn-xl text-uppercase btn-degradado-2" : "btn btn-xl text-uppercase"}>
                                <span className="align-self-center">Firma <br></br> Documentos ({count})</span>
                                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div id="carousel-mis-doc" className="carousel carousel-dark slide carousel-control-inner d-md-none" data-bs-ride="carousel" data-bs-interval="false">
                    <div className="carousel-indicators">
                        <button onClick={() => setCarruselSelect(0)} type="button" data-bs-target="#carousel-mis-doc" data-bs-slide-to="0" className={carruselSelect == 0 ? "active" : ""} aria-current="true" aria-label="Slide 1"></button>
                        <button onClick={() => setCarruselSelect(1)} type="button" data-bs-target="#carousel-mis-doc" data-bs-slide-to="1" className={carruselSelect == 1 ? "active" : ""} aria-label="Slide 2"></button>
                        <button onClick={() => setCarruselSelect(2)} type="button" data-bs-target="#carousel-mis-doc" data-bs-slide-to="2" className={carruselSelect == 2 ? "active" : ""} aria-label="Slide 3"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className={carruselSelect == 0 ? "carousel-item p-2 active" : "carousel-item p-2"} data-bs-interval="false">
                            <div className="row">
                                <div className="col mb-4">
                                    <a onClick={(e) => { e.preventDefault(); setCarruselSelect(0) }} className={carruselSelect == 0 ? "btn btn-xl text-uppercase btn-degradado-2" : "btn btn-xl text-uppercase"} >
                                        <span className="align-self-center">Carpeta digital</span>
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={carruselSelect == 1 ? "carousel-item p-2 active" : "carousel-item p-2"} data-bs-interval="false">
                            <div className="row">
                                <div className="col mb-4">
                                    <a onClick={(e) => { e.preventDefault(); setCarruselSelect(1) }} className={carruselSelect == 1 ? "btn btn-xl text-uppercase btn-degradado-2" : "btn btn-xl text-uppercase"} href="#">
                                        <span className="align-self-center">Certificados</span>
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={carruselSelect == 2 ? "carousel-item p-2 active" : "carousel-item p-2"} data-bs-interval="false">
                            <div className="row">
                                <div className="col mb-4">
                                    <a onClick={(e) => { e.preventDefault(); setCarruselSelect(2) }} className={carruselSelect == 2 ? "btn btn-xl text-uppercase btn-degradado-2" : "btn btn-xl text-uppercase"} href="#">
                                        <span className="align-self-center">Firma Documentos ({count})</span>
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: carruselSelect == 0 ? "block" : "none" }} id='seccionCarpetaDigital'>
                    <CarpetaDigital email={firstLoginData.EMAIL}></CarpetaDigital>
                </div>
                <div style={{ display: carruselSelect == 1 ? "block" : "none" }} id="seccionCertificados">
                    <Certificados email={firstLoginData.EMAIL}></Certificados>
                </div>
                <div style={{ display: carruselSelect == 2 ? "block" : "none" }} id='seccionFirmaDocumento'>
                    <FirmaDocumentos count={setCount}></FirmaDocumentos>
                </div>
            </section>
        </>
    )
}
export default MisDocumentos;