import { Auth } from "aws-amplify";
import axios from "axios";

const url = process.env.REACT_APP_BACKEND_URL_GLOBAL;

// Backend localhost
// const backend_localhost_url_base = "http://localhost:5000";
// const getRelationShips_URL =
//   backend_localhost_url_base + "/api/interestconflict/relationships";
// const getConflictTypes_URL =
//   backend_localhost_url_base + "/api/interestconflict/conflicttypes";
// const getInterestConflicts_URL =
//   backend_localhost_url_base + "/api/interestconflict/interestconflicts";
// const createInterestConflict_URL =
//   backend_localhost_url_base + "/api/interestconflict/interestconflict";
// const putVigencia_URL =
//   backend_localhost_url_base + "/api/interestconflict/vigencia";

// Backend beanstalk
// const backend_beanstalk_url_base = "http://autoservicio-bff-col.us-east-1.elasticbeanstalk.com";
// const getRelationShips_URL = backend_beanstalk_url_base + "/api/interestconflict/relationships";
// const getConflictTypes_URL = backend_beanstalk_url_base + "/api/interestconflict/conflicttypes";
// const getInterestConflicts_URL  = backend_beanstalk_url_base + "/api/interestconflict/interestconflicts";
// const createInterestConflict_URL = backend_beanstalk_url_base + "/api/interestconflict/interestconflict";
// const getStatus_URL = backend_beanstalk_url_base + "/api/interestconflict/status";
// const putVigencia_URL = backend_beanstalk_url_base + "/api/interestconflict/vigencia";

// Gateway
const backend_gateway_url_base = url;
const getRelationShips_URL =
  backend_gateway_url_base + "/api/interestconflict/relationships";
const getConflictTypes_URL =
  backend_gateway_url_base + "/api/interestconflict/conflicttypes";
const getInterestConflicts_URL =
  backend_gateway_url_base + "/api/interestconflict/interestconflicts";
const createInterestConflict_URL =
  backend_gateway_url_base + "/api/interestconflict/interestconflict";
const getStatus_URL = backend_gateway_url_base + "/api/interestconflict/status";
const putVigencia_URL = backend_gateway_url_base + "/api/interestconflict/vigencia";

const getHeader = () => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(async (auth) => {
        const header = {
          "Content-Type": "application/json",
          accesstoken: auth.getAccessToken().getJwtToken(),
        };
        resolve(header);
      })
      .catch(function (error) {
        return reject(error);
      });
  });
};

const InterestConflictServices = {
  getRelationShips: async () => {
    try {
      const header = await getHeader();

      const data = await axios.get(getRelationShips_URL, {
        headers: header,
      });

      return data;
    } catch (error) {
      error?.response?.data;
    }
  },
  getConflictTypes: async () => {
    try {
      const header = await getHeader();

      const data = await axios.get(getConflictTypes_URL, {
        headers: header,
      });

      return data;
    } catch (error) {
      error?.response?.data;
    }
  },
  getInterestConflicts: async () => {
    try {
      const header = await getHeader();
      console.log(getInterestConflicts_URL)
      const data = await axios.get(getInterestConflicts_URL, {
        headers: header,
      });

      return data;
    } catch (error) {
      error?.response?.data;
    }
  },
  createInterestConflict: async (dto) => {
    try {
      const header = await getHeader();

      const data = await axios({
        method: "post",
        url: createInterestConflict_URL,
        data: dto,
        headers: {
          "Content-Type": "application/json",
          accesstoken: header.accesstoken,
        },
      });

      return data;
    } catch (error) {
      return error?.response?.data;
    }
  },
  getStatus: async () => {
    try {
      const header = await getHeader();

      const response = await axios.get(getStatus_URL, {
        headers: header,
      });

      return response;
    } catch (error) {
      return error?.response?.data;
    }
  },
  putVigencia: async (data) => {
    try {
      const header = await getHeader();

      const response = await axios.put(putVigencia_URL, data, {
        headers: header 
      });

      return response;
    } catch (error) {
      return error?.response?.data;
    }
  }
};

export default InterestConflictServices;
