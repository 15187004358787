import React,{ useState, useEffect, Children } from "react";
import PanelAdministracionService from "../services/panel-administracion-service";
import {useForm} from "react-hook-form";
//agregar rol: rol, editar rol: rol y prevrol, eliminar rol prevrol
const PanelAdministracionRoles = () => {
    const [roles, setRoles] = useState(false);
    const [prevRol, setPrevRol] = useState(false);
    const [metodo, setMetodo] = useState("");
    const [paises, setPaises] = useState([{
        CODIGO:"",
        NOMBRE:"",
    }]);
    const [status, setStatus] = useState({
        status: 0,
        message: ""
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue
    } = useForm();
    useEffect(() => {
        if(!roles){
            PanelAdministracionService.getRol(setRoles);
        }
    }, [roles]);
    useEffect(() => {
        if(paises[0].CODIGO === "" && paises[0].NOMBRE === ""){
            PanelAdministracionService.getPaises(setPaises);
        }
    }, [paises]);
    const onSubmit = (data) => {
        let request
        if(metodo === "add"){
            request = {
                request:{
                    rol: {
                        nombre: data.rol,
                        pais: data.pais
                    }
                }
            }
        }else if(metodo === "upd"){
            request = {
                request:{
                    rol: {
                        nombre: data.rol,
                        pais: data.pais
                    },
                    prevrol: {
                        nombre: prevRol.nombre,
                        pais: prevRol.codigopais
                    }
                }
            }

        }else if(metodo === "del"){
            request = {
                request:{
                    prevrol: {
                        nombre: data.rol,
                        pais: data.pais
                    }
                }
            }
        }
        PanelAdministracionService.setRol(request, setStatus);
    }
    const openModal = (e, rol, metodo) => {
        reset();
        setMetodo(metodo);
        if (metodo === "del" || metodo === "upd") {
            setValue("rol",rol.nombre);
            setValue("pais",rol.codigopais);
            if(metodo === "upd"){
                setPrevRol(rol);
            }
        }
        else{
            setValue("rol","");
            setValue("pais","");
        }
    }
    return (
        <>
            {/* <!-- inicio breadcrumbs -->   */}
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">Rol Administrador</li>
                </ol>
            </nav>
            {/* <!-- inicio Rol Administrador --> */}
            <section className="rol-administrador">
                <h1>Rol Administrador</h1>
                {/* <!-- inicio Formulario --> */}
                <div className="box mb-4">
                    <h2 className="h4 tit-section">Configuración del Rol</h2>

                    {/* <!-- Inicio Tabla // Desktop --> */}
                    <table className="table table-section table-borderless table-th-border align-middle table-color-reset d-none d-md-table">
                        <thead className="text-uppercase">
                            <tr>
                                <th className="col-4">País</th>
                                <th className="col-4">Rol</th>
                                <th className="col-4 text-center">Acciones</th>
                                <th className="text-center col-auto">
                                    <a href="#" data-bs-toggle="modal" data-bs-target="#modal-rol" onClick={(e)=>{openModal(e,"","add")}}><i className="fa fa-plus-square-o fa-fw fa-lg me-2" aria-hidden="true"></i></a>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                roles && roles.map((rol, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="col-4">{rol.pais}</td>
                                            <td>
                                                <input className="form-control" type="text" value={rol.nombre} disabled/>
                                            </td>
                                            <td className="col-4 text-center">
                                                <a href="#" data-bs-toggle="modal" data-bs-target="#modal-rol" onClick={(e)=>{openModal(e,rol,"upd")}}><i className="fa fa-pencil-square-o fa-fw fa-lg me-2" aria-hidden="true"></i></a>
                                                <a href="#" data-bs-toggle="modal" data-bs-target="#eliminar-rol" onClick={(e)=>{openModal(e,rol,"del")}}><i className="fa fa-trash-o fa-fw fa-lg me-2" aria-hidden="true"></i></a>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                    {/* <!-- Botton + mobile // --> */}
                    <div className="text-end mb-3 d-block d-md-none">
                        <a href="#" data-bs-toggle="modal" data-bs-target="#modal-rol" onClick={(e)=>{openModal(e,"","add")}}><i className="fa fa-plus-square-o fa-fw fa-lg me-2" aria-hidden="true"></i></a>
                    </div>
                    {/* <!-- Inicio Acordeón // Mobile --> */}
                    <div className="accordion accordion-flush accordion-light accordion-table text-uppercase d-block d-md-none">
                        {/* <!-- 1er acordeón --> */}
                        {
                            roles && Children.toArray(roles.map((rol, index) => {
                                return (
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id={"acordeon-"+index}>
                                            <button className={`accordion-button ${index === 0 ? "" : "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target={"#accordion-item-"+index} aria-expanded={index=== 0 ? "true" : "false"} aria-controls={"accordion-item-"+index}>
                                                <strong className="text-uppercase">País</strong> {rol.pais}
                                            </button>
                                        </h2>
                                        <div id={"accordion-item-"+index} className={`accordion-collapse collapse ${index=== 0 ? "show" : ""}`} aria-labelledby={"acordeon-"+index}>
                                            <div className="accordion-body p-0">
                                                {/* <!-- cuerpo item --> */}
                                                <div className="data-list-box data-list-box-even">
                                                    <ul className="list-column-1 list-unstyled">
                                                        <li>
                                                            <span className="data-list-box__title">Rol</span>
                                                            <span className="data-list-box__text">
                                                                <input className="form-control" type="text" value={rol.nombre} disabled/>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Acciones</span>
                                                            <span className="data-list-box__text">
                                                                <a href="#" data-bs-toggle="modal" data-bs-target="#modal-rol" onClick={(e)=>{openModal(e,rol,"upd")}}><i className="fa fa-pencil fa-fw fa-lg me-2" aria-hidden="true"></i></a>
                                                                <a href="#" data-bs-toggle="modal" data-bs-target="#eliminar-rol" onClick={(e)=>{openModal(e,rol,"del")}}><i className="fa fa-trash fa-fw fa-lg" aria-hidden="true"></i></a>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }))
                        }
                    </div>
                </div>
            </section>
            <div className="modal fade" id="eliminar-rol" tabIndex="-1" aria-labelledby="eliminar-rol" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="modal-header border-0 pb-0">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-center py-4">
                                <i className="fa fa-check-circle-o color-orange fa-3x" aria-hidden="true"></i>
                                <input type="hidden" {...register("rol")}/>
                                <input type="hidden" {...register("pais")}/>
                                <h2 className="h5">¿Estás seguro que deseas eliminar el Rol?</h2>
                            </div>
                            <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                                <button type="submit" className="btn btn-degradado col-12 col-md-auto order-md-last px-5" data-bs-dismiss="modal">Sí <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                <button type="button" className="btn btn-primary col-12 col-md-auto order-md-first px-5" data-bs-dismiss="modal">No <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="modal-rol" tabIndex="-1" aria-labelledby="modal-rol"  aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <form role="form" className="needs-validation" noValidate="novalidate" onSubmit={handleSubmit(onSubmit)}>
                            <div className="modal-header border-0 pb-0">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <h2 className="h5 text-uppercase mb-4">Nuevo rol</h2>
                                
                                    <div className="mb-3">
                                        <label className="form-label mb-2 text-uppercase"><strong>País *</strong></label>
                                        <select className="form-select" {
                                            ...register("pais",{
                                                required: true,
                                            })
                                        }>
                                            <option value="">Seleccione</option>
                                            {
                                                paises && Children.toArray(paises.map((pais) => {
                                                    return (
                                                        <option value={pais.CODIGO}>{pais.NOMBRE}</option>
                                                    )
                                                }))
                                            }
                                        </select>
                                        {
                                            errors && errors.pais && <span className="invalid-feedback">*Seleccione un pais</span>
                                        }
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label col-12 mb-2" htmlFor="rol"><strong>Rol</strong></label>
                                        <input type="tel" className="form-control" placeholder="Ingrese" {
                                            ...register("rol",{
                                                required: true,
                                            })
                                        }/>
                                        {
                                            errors && errors.pais && <span className="invalid-feedback">*Ingrese un rol</span>
                                        }
                                    </div>
                            </div>
                            <div className="modal-footer border-0 pt-0">
                                <button type="submit" className="btn btn-degradado col-12 col-md-auto order-md-last" data-bs-dismiss="modal" >Guardar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                <button type="button" className="btn btn-primary col-12 col-md-auto order-md-first" data-bs-dismiss="modal">Cancelar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="success" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center py-4">
                            <i className="fa fa-check-circle-o color-orange fa-3x" aria-hidden="true"></i>
                            <h2 className="h5">{status.status === 1 ? "Operación realizada exitosamente" : status.message}</h2>
                        </div>
                        <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                            <button type="button" className="btn btn-degradado col-12 col-md-auto my-auto px-5" data-bs-dismiss="modal" onClick={(e)=>{e.preventDefault();PanelAdministracionService.getRol(setRoles)}}>Cerrar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <a id="btn-success" href="#"  data-bs-toggle="modal" data-bs-target="#success" hidden></a>
        </>
    )
}

export default PanelAdministracionRoles;