import axios from 'axios';
import Auth from '@aws-amplify/auth';

// eslint-disable-next-line no-undef
const baseUrl = process.env.REACT_APP_BACKEND_URL_GLOBAL;
// const baseUrl = "http://localhost:5000";

const getHeader = () => {
    return new Promise((resolve, reject) => {
        Auth.currentSession()
            .then(async (auth) => {
                const header = {
                    "Content-Type": "application/json",
                    accesstoken: auth.getAccessToken().getJwtToken(),
                };
                resolve(header);
            })
            .catch(function (error) {
                return reject(error);
            });
    });
};

const RenunciaVoluntariaService = {
    getInfoColaborador: async () => {
        try {
            const header = await getHeader();

            const data = await axios.get(baseUrl + "/api/baja-voluntaria/colaborador", {
                headers: header,
            });

            return data;
        } catch (error) {
            return error?.response?.data;
        }
    },
    crearPdf: async (body) => {
        try {
            const header = await getHeader();

            const data = await axios({
                method: "post",
                url: `${baseUrl}/api/baja-voluntaria/crearpdf`,
                data: body,
                headers: {
                    "Content-Type": "application/json",
                    accesstoken: header.accesstoken,
                },
            })
            return data;
        } catch (error) {
            return error?.response?.data;
        }
    },
    getPdf: async () => {
        try {
            const header = await getHeader();

            const data = await axios.get(baseUrl + "/api/baja-voluntaria/traerpdf", {
                headers: header,
            });
            
            return data;
        } catch (error) {
            return error?.response?.data;
        }
    },
    ratificarPdf: async () => {
        try {
            const header = await getHeader();

            const data = await axios({
                method: "post",
                url: `${baseUrl}/api/baja-voluntaria/ratificar`,
                headers: {
                    "Content-Type": "application/json",
                    accesstoken: header.accesstoken,
                },
            })

            console.log("ratificar",data)

            return data;
        } catch (error) {
            return error?.response?.data;
        }
    },
    verEstado: async () => {
        try {
            const header = await getHeader();

            const data = await axios.get(baseUrl + "/api/baja-voluntaria/estado", {
                headers: header,
            });

            return data;
        } catch (error) {
            return error?.response?.data;
        }
    },
}

export default RenunciaVoluntariaService;