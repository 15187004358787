import axios from "axios";
import Auth from '@aws-amplify/auth';


// eslint-disable-next-line no-undef
const url = process.env.REACT_APP_BACKEND_URL_GLOBAL;

const DataServiceUY = {
  getUserData: async (setFetched, setAddressToSet, setData, setFormContactValues, setFormAddressValues, setNoData, setLoadingUser) => {
    try {
      setLoadingUser(true)
      Auth.currentSession().then(auth => {
        const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
        axios.get(url + '/api/Employee', { headers: head }).then(response => {
          if (response.data !== null || response.status === 200) {
            const responseJSON = response.data;
            setAddressToSet(responseJSON.currentAddress);
            setData(responseJSON)
            setFetched(true);
            setFormContactValues({
              personalEmail: responseJSON.personalEmail, personalPhone: responseJSON.personalPhone, whatsappPhone: responseJSON.whatsappPhone
            })
            setFormAddressValues({
              street: responseJSON.currentAddress.street, doorNumber: responseJSON.currentAddress.doorNumber, country: responseJSON.currentAddress.country, status: responseJSON.currentAddress.status,
              floor: responseJSON.currentAddress.floor, apartment: responseJSON.currentAddress.apartment, betweenStreets: responseJSON.currentAddress.betweenStreets,
              stateCode: responseJSON.currentAddress.stateCode, cityCode: responseJSON.currentAddress.cityCode, postalCode: responseJSON.currentAddress.postalCode
            })
          } else {
            setNoData(true);
          }
        }).catch(function (error) {
          console.log(error.response);
          setNoData(true);
          setLoadingUser(false)
        })
      }).catch(function (error) {
        console.log(error.response);
        setLoadingUser(false)
        setNoData(true);
      })
    } catch (error) {
      console.error(error);
      setLoadingUser(false)
    }

  },
  getLocalidad: async (addressToSet, setAddressString, setLoadingUser) => {
    //setLoadingUser(true);
    const countryId = "URY";
    const stateId = addressToSet.stateCode;
    const countyId = addressToSet.cityCode;
    const uri = url + `/api/Location/countries/${countryId}/states/${stateId}/counties/${countyId}`;
    Auth.currentSession().then(auth => {
      const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
      axios.get(uri, { headers: head }).then(response => {
        if (response.data !== null) {

          setAddressString(addressToSet.street + ' ' + addressToSet.doorNumber + ', ' + response.data[0].countyDescription)
          setLoadingUser(false)
        } else {
          setLoadingUser(false);
        }
      }).catch(function (error) {
        console.log(error.response);
        setLoadingUser(false);
      })
    })
  },
  sendPhoneUpdate: async (phoneData, setFlag1, setFlag2, setSent) => {
    setSent(true);
    const apiUrl = url + "/api/Employee/ContactData";
    Auth.currentSession().then(auth => {
      const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
      const body = JSON.stringify(phoneData);
      axios({
        method: 'post',
        url: apiUrl,
        headers: head,
        data: body
      }).then(response => {
        if (response.status === 200) {
          setFlag1(true);
          setSent(false)
        } else {
          setFlag2(true)
        }

      }).catch(function (error) {
        console.log(error.response)
        setFlag2(true)
      })
    })
  },

  sendAddressUpdate: async (addressData, setFlag1, setFlag2, setSentAddress) => {
    setSentAddress(true)
    const apiUrl = url + "/api/Employee/Address";
    Auth.currentSession().then(auth => {
      const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
      const body = JSON.stringify(addressData);
      axios({
        method: 'post',
        url: apiUrl,
        headers: head,
        data: body
      }).then(response => {
        if (response.status == 200) {
          setFlag1(true)
          setSentAddress(false);
        } else {
          setSentAddress(false);
          setFlag2(true);
        }
        // console.log(response)
      }).catch(function (error) {
        console.log(error.response)
        setSentAddress(false);
        setFlag2(true)
      })
    })
  },
  getGoals: async (setGoals, setLoadingGoals, setNoGoalData) => {

    Auth.currentSession().then(auth => {
      try {
        const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
        axios.get(url + "/api/Employee/Goals", { headers: head }).then(response => {
          // console.log(response.data)
          if (response.data !== null) {
            const responseJSON = response.data;
            setGoals(responseJSON);
            setLoadingGoals(false);
          } else {
            setLoadingGoals(false);
            setNoGoalData(true);
          }
        }).catch(function (error) {
          console.log(error.response);
          setLoadingGoals(false);
          setNoGoalData(true);
        })
      } catch {
        setLoadingGoals(false);
        setNoGoalData(true);
      }
    })

  },

  getTarjetaRegalo: async (setGiftcard, setLoadingGifts, setNoInfo) => {
    try {

      Auth.currentSession().then(auth => {
        const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
        axios.get(url + "/api/Employee/GiftCard", { headers: head }).then(response => {
          // console.log(response)
          if (response.data !== null) {
            const responseJSON = response.data;
            setGiftcard(responseJSON);
            setLoadingGifts(false);
          } else {
            setLoadingGifts(false);
            setNoInfo(true)
          }
        }).catch(function (e) {
          console.log(e)
          setNoInfo(true)
          setLoadingGifts(false);
        })
      })
    }
    catch (error) {
      setLoadingGifts(false)
      setNoInfo(true)
      console.log(error.response)
    }
  },
  /* getTarjetaRegalo: async () => {
      Auth.currentSession().then(auth => {
      const url = urls.getGiftcard;
      const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
      const response = axios.get(url, {headers: head});
      return response.data
      
      })
  }, */
  getDescuentos: async (setLoadingDiscounts, setInfo, setNoDiscountInfo, setSuperPurchases, setEasyPurchases, setNoSuperPurchases, setNoEasyPurchases, setPassedValue) => {


    Auth.currentSession().then(auth => {
      const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
      axios.get(url + "/api/Employee/Discounts", { headers: head }).then(response => {
        if (response.data !== null) {
          const responseJSON = response.data;
          // console.log(responseJSON);
          setInfo(responseJSON);
          setPassedValue(responseJSON.barCode);
          if (responseJSON.super.purchases !== null) {
            setSuperPurchases(responseJSON.super.purchases.purchase);
          } else {
            setNoSuperPurchases(true);
          }
          if (responseJSON.easy.purchases !== null) {
            setEasyPurchases(responseJSON.easy.purchases.purchase);
          } else {
            setNoEasyPurchases(true);
          }
          setLoadingDiscounts(true)
        } else {
          setLoadingDiscounts(true)
          setNoDiscountInfo(true);
        }
      }).catch(function (error) {
        console.log(error.response)
        setNoDiscountInfo(true);
        setLoadingDiscounts(true);
      })
    })
  },
  getStates: async (setStates, setLoadingStates) => {
    const countryId = "URY";
    const apiUrl = `${url}/api/Location/countries/${countryId}/states`;
    Auth.currentSession().then(auth => {
      const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
      axios.get(apiUrl, { headers: head }).then(response => {
        if (response.data !== null) {
          setStates(response.data);
          setLoadingStates(false);
        } else {
          setStates([]);
          setLoadingStates(false);
        }
      })
    }).catch(function (error) {
      console.log(error.response)
    })
  },

  getCounties: async (setCounties, addressToSet, setLoadingCounties) => {
    const countryId = "URY";
    const stateId = addressToSet.stateCode;
    const apiUrl = `${url}/api/Location/countries/${countryId}/states/${stateId}/counties`;
    Auth.currentSession().then(auth => {
      const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
      axios.get(apiUrl, { headers: head }).then(response => {
        if (response.data !== null) {
          setCounties(response.data);
          setLoadingCounties(false);
        } else {
          setCounties([]);
          setLoadingCounties(false);
        }
      }).catch(function (error) {
        console.log(error.response)
      })
    })
  },
  getCountyById: async (addressToSet, setZipCodes, setLoadingZip) => {
    const countryId = "URY";
    const stateId = addressToSet.stateCode;
    const countyId = addressToSet.cityCode;
    const apiUrl = `${url}/api/Location/countries/${countryId}/states/${stateId}/counties/${countyId}`;
    Auth.currentSession().then(auth => {
      const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken(), 'Authorization': auth.getIdToken().getJwtToken() }
      axios.get(apiUrl, { headers: head }).then(response => {
        if (response.data !== null || response.data !== undefined) {

          setZipCodes(response.data[0].zipCodes);
          setLoadingZip(false);
        } else {
          setZipCodes([]);
          setLoadingZip(false);
        }
      }).catch(function (error) {
        console.log(error.response)
        setLoadingZip(false);
      })
    })
  },
}

export default DataServiceUY;