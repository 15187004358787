import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import React, { Children, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import newsService from '../../services/news/news-services';

const NewsManagerList = () => {
  const [noticias, SetNoticias] = useState(null);
  const [fechaFiltro1, setFechaFiltro1] = useState("");
  const [fechaFiltro2, setFechaFiltro2] = useState("");
  const [estadoFiltro, setEstadoFiltro] = useState(null);
  const [grupoFiltro, setGrupoFiltro] = useState("");
  const [noticiaDel, setNoticiaDel] = useState(null);
  const [noticiaUndo, setNoticiaUndo] = useState(null);
  const [statusDel, setStatusDel] = useState(null);
  const [statusUndo, setStatusUndo] = useState(null);
  const [respuestaSolicitudValid, setRespuestaSolicitudValid] = useState(false);
  const [textoRespuesta, setTextoRespuesta] = useState(null);

  //paginador
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [matrix, setMatrix] = useState([]);
  const itemsPerPage = 5;
  const [items, setItems] = useState(null);
  const rowFechas = [];
  const rowTitulo = [];
  const rowAutor = [];
  const rowEstado = [];
  const rowAccionEliminar = [];
  const [resetOffset, setResetOffset] = useState(false);
  const [encabezados, setEncabezados] = useState(null);

  const transpose = (matrix) => {
    return matrix.reduce(
      (prev, next) => next.map((item, i) => (prev[i] || []).concat(next[i])),
      []
    );
  };

  useEffect(() => {
    if (
      items !== "error" &&
      Array.isArray(items) &&
      items.length > 0 &&
      items
    ) {
      let newMatrix = items.map((item) => {
        return item.rows;
      });
      setMatrix(transpose(newMatrix));
    }
  }, [items]);

  useEffect(() => {
    if (matrix !== "error" && Array.isArray(matrix) && matrix.length > 0) {
      let temp = matrix.slice(itemOffset, itemOffset + itemsPerPage);

      setCurrentItems(temp);
      setPageCount(Math.ceil(matrix.length / itemsPerPage));
    } else if (
      matrix !== "error" &&
      Array.isArray(matrix) &&
      matrix.length === 0
    ) {
      setCurrentItems(null);
      setPageCount(0);
    }
  }, [itemOffset, itemsPerPage, matrix]);

  useEffect(() => {
    if (resetOffset) {
      setItemOffset(0);
      setResetOffset(false);
    }
  }, [resetOffset]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % matrix.length;
    setItemOffset(newOffset);
  };

  //fin paginador

  useEffect(() => {
    try {
      newsService.getNews(SetNoticias, { status: estadoFiltro });

    } catch {}
  }, [fechaFiltro1, fechaFiltro2, estadoFiltro, grupoFiltro]);

  function formato(texto) {
    return texto.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1");
  }

  useEffect(() => {
    if (noticias) {
      noticias.map((e, index) => {
        rowTitulo.push(e.title);
        rowFechas.push(
          formato(e.date_from) + " al " + formato(e.date_to)
        );
        let IDNoticias = e.id;
        rowAutor.push(e.author);
        if (e.status === 'draft') {
          rowEstado.push("Borrador");
          rowAccionEliminar.push(
            <>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setNoticiaDel(IDNoticias);
                }}
              >
                <i class="fa fa-trash-o fa-fw me-2" aria-hidden="true"></i>
              </a>
              <Link to={"form/" + IDNoticias}>
                <i class="fa fa-pencil fa-fw me-2" aria-hidden="true"></i>
              </Link>
            </>
          );
        } else if (e.status === 'available') {
          rowEstado.push("Publicada");
          rowAccionEliminar.push(
            <>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setNoticiaDel(IDNoticias);
                }}
              >
                <i class="fa fa-trash-o fa-fw me-2" aria-hidden="true"></i>
              </a>
              <Link to={"form/" + IDNoticias}>
                <i class="fa fa-pencil fa-fw me-2" aria-hidden="true"></i>
              </Link>
            </>
          );
        } else {
          rowEstado.push("Despublicada");
          rowAccionEliminar.push(
            <>
              <a
                href="#"
                onClick={(e) => {
                  console.log('enter')
                  e.preventDefault();
                  setNoticiaUndo(IDNoticias);
                }}
              >
                <i class="fa fa-undo fa-fw me-2" aria-hidden="true"></i>
              </a>
              <Link to={"form/" + IDNoticias}>
                <i class="fa fa-pencil fa-fw me-2" aria-hidden="true"></i>
              </Link>
            </>
          );
        }
      });
      if (
        rowTitulo.length > 0 ||
        rowFechas.length > 0 ||
        rowAutor.length > 0 ||
        rowEstado.length > 0 ||
        rowAccionEliminar.length > 0
      )
        setEncabezados(rowFechas);

      setItems([
        {
          title: "Vigencia",
          rows: rowFechas,
        },
        {
          title: "Título",
          rows: rowTitulo,
        },
        {
          title: "Autor",
          rows: rowAutor,
        },
        {
          title: "Estado",
          rows: rowEstado,
        },
        {
          title: "Acciones",
          rows: rowAccionEliminar,
        },
      ]);
    }
  }, [noticias]);

  useEffect(() => {
    const status = noticiaDel ? 'unavailable' : noticiaUndo ? 'available' : null;
    const newsId = noticiaDel ? noticiaDel : noticiaUndo;
    const setStatus = noticiaDel ? setStatusDel : setStatusUndo;

    if (noticiaDel || noticiaUndo) {
      newsService.changeStatus(setStatus, { id: newsId, status })
    }
  }, [noticiaDel, noticiaUndo]);

  useEffect(() => {
    if (statusDel) {
      if (statusDel === "unavailable") {
        setTextoRespuesta("Noticia despublicada con exito!");
        setRespuestaSolicitudValid(true);
        setStatusDel(null);
      }
    }
    if (statusUndo) {
      if (statusUndo === "available") {
        setRespuestaSolicitudValid(true);
        setTextoRespuesta("Noticia publicada con exito!");
        setStatusUndo(null);
      }
    }
  }, [statusDel, statusUndo]);

  return (
    <>
      <main class="wrapper d-flex">
        {/* Sidebar Holder */}

        <div style={{ width: "100%" }} id="content">
          {/* inicio breadcrumbs */}
          <nav class="breadcrumbs" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to={"/canal-de-comunicacion"}>
                  <i class="fa fa-home" aria-hidden="true"></i> Noticias
                </Link>
              </li>
            </ol>
          </nav>
          {/* fin breadcrumbs */}

          {/* inicio Gestión de Noticias */}
          <section class="gestion-de-noticias">
            <h1>Gestión de Noticias</h1>

            {/* Noticias Publicadas */}
            <div class="box mb-4">
              <div class="tit-group d-flex justify-content-between">
                <h2 class="h4 tit-section">Noticias Publicadas</h2>
                <Link
                  to={"form"}
                  class="mt-2 text-decoration-none fw-bold text-uppercase"
                >
                  <span class="d-none d-md-inline-block">Nueva noticia</span>{" "}
                  <i
                    class="fa fa-plus-square-o fa-lg ms-2"
                    aria-hidden="true"
                  ></i>
                </Link>
              </div>

              <div class="col-12 mb-4">
                <div class="row justify-content-end">
                  <div class="col-12 col-md-4 col-xl-auto mb-3 mb-md-0">
                    <select
                      onChange={(e) => {
                        if (e.target.value === undefined) {
                          setEstadoFiltro("");
                        } else {
                          setEstadoFiltro(e.target.value);
                        }
                      }}
                      id="inputState"
                      class="form-select"
                    >
                      <option selected value="">
                        Estado de la Publicación
                      </option>
                      <option value={"available"}>Publicada</option>
                      <option value={"unavailable"}>Despublicada</option>
                      <option value={"draft"}>Borrador</option>
                    </select>
                  </div>
                </div>
              </div>

              <>
                <>
                  <table className="table table-even table-section table-borderless align-middle d-none d-md-table">
                    <thead>
                      <tr>
                        {items
                          ? Children.toArray(
                              items.map((column, index) => {
                                return (
                                  <th key={"array3" + index}>{column.title}</th>
                                );
                              })
                            )
                          : null}
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems ? (
                        Children.toArray(
                          currentItems.map((rows, index) => {
                            return (
                              <tr key={"array4" + index}>
                                {Children.toArray(
                                  rows.map((value, index) => {
                                    return (
                                      <td key={"array5" + index}>{value}</td>
                                    );
                                  })
                                )}
                              </tr>
                            );
                          })
                        )
                      ) : (
                        <tr>
                          <td>Sin resultados</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="accordion accordion-flush d-block d-md-none accordion-light accordion-table">
                    {currentItems ? (
                      Children.toArray(
                        currentItems.map((rows, i) => {
                          return (
                            <>
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id={"acordeon-" + i}
                                >
                                  <button
                                    className={
                                      i === 0
                                        ? "accordion-button"
                                        : "accordion-button collapsed"
                                    }
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#acordeon-item-" + i}
                                    aria-expanded="true"
                                    aria-controls={"accordion-item-" + i}
                                  >
                                    <strong className="text-uppercase">
                                      Vigencia
                                    </strong>
                                    {encabezados[i]}
                                  </button>
                                </h2>
                                <div
                                  id={"acordeon-item-" + i}
                                  className={
                                    i === 0
                                      ? "accordion-collapse collapse show"
                                      : "accordion-collapse collapse "
                                  }
                                  aria-labelledby={"acordeon-" + i}
                                >
                                  <div className="accordion-body p-0">
                                    <div className="data-list-box data-list-box-even">
                                      <ul className="data-list-section--item list-unstyled">
                                        {Children.toArray(
                                          rows.map((value, index) => {
                                            return (
                                              <li key={"array6" + index}>
                                                <span className="data-list-box__title col-12 col-sm-4">
                                                  {items[index].title}
                                                </span>
                                                <span className="data-list-box__text">
                                                  {value}
                                                </span>
                                              </li>
                                            );
                                          })
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })
                      )
                    ) : (
                      <div>
                        <p>SIN RESULTADOS</p>
                      </div>
                    )}
                  </div>
                </>
                {currentItems ? (
                  <nav className="mt-5" aria-label="Paginación">
                    <ReactPaginate
                      id={"paginationnav"}
                      className="pagination justify-content-center align-items-center"
                      breakLabel=" ... "
                      nextLabel=" > "
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={0}
                      marginPagesDisplayed={1}
                      pageCount={pageCount}
                      previousLabel=" < "
                      renderOnZeroPageCount={null}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                      breakLinkClassName={"page-link"}
                      pageLinkClassName={"page-link"}
                      pageClassName={"page-item"}
                      breakClassName={"page-item"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      activeClassName={"page-item active"}
                      activeLinkClassName={"page-link active"}
                    />
                  </nav>
                ) : null}
              </>

              {/* Modal Firma enviada correctamente */}
              <div
                class="modal fade"
                id="success"
                tabindex="-1"
                aria-labelledby="success"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header border-0 pb-0">
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body text-center py-4">
                      <i
                        class="fa fa-check-circle-o color-orange fa-3x mb-2"
                        aria-hidden="true"
                      ></i>
                      <h2 class="h5">Firma enviada correctamente</h2>
                      <button
                        type="button"
                        class="btn btn-degradado mt-4"
                        data-bs-dismiss="modal"
                      >
                        Aceptar{" "}
                        <i
                          class="fa fa-chevron-right fa-fw fa-xs"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* fin modal */}

              {/* Modal Debe descargar el documento - Llamar con data-bs-target="#alert" en el botón */}
              <div
                class="modal fade"
                id="alert"
                tabindex="-1"
                aria-labelledby="alert"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header border-0 pb-0">
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body text-center py-4">
                      <i
                        class="fa fa-exclamation-triangle color-red fa-3x mb-2"
                        aria-hidden="true"
                      ></i>
                      <h2 class="h5">Debe descargar el documento</h2>
                      <button
                        type="button"
                        class="btn btn-degradado mt-4"
                        data-bs-dismiss="modal"
                      >
                        Aceptar{" "}
                        <i
                          class="fa fa-chevron-right fa-fw fa-xs"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="modal fade"
                id="no-existen"
                tabindex="-1"
                aria-labelledby="no-existen"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header border-0 pb-0">
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body text-center py-4">
                      <h2 class="h5">No existen solicitudes pendientes</h2>
                      <button
                        type="button"
                        class="btn btn-degradado mt-4"
                        data-bs-dismiss="modal"
                      >
                        Aceptar{" "}
                        <i
                          class="fa fa-chevron-right fa-fw fa-xs"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      {respuestaSolicitudValid ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div class="modal-header border-0 pb-0">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        newsService.getNews(SetNoticias, { status: estadoFiltro });
                        setRespuestaSolicitudValid(false);
                      }}
                    ></button>
                  </div>
                  <div class="modal-body text-center py-4 pt-0 pb-1">
                    <i
                      class="fa fa-check-circle-o color-orange fa-3x mb-2"
                      aria-hidden="true"
                    ></i>

                    <h2 class="h5">
                      {textoRespuesta}
                      <br />
                    </h2>
                  </div>
                  <div class="modal-footer border-0 pt-0 pb-5 justify-content-center">
                    <button
                      onClick={() => {
                        newsService.getNews(SetNoticias, { status: estadoFiltro });
                        setRespuestaSolicitudValid(false);
                      }}
                      type="button"
                      class="btn btn-degradado px-5"
                      data-bs-dismiss="modal"
                    >
                      Aceptar{" "}
                      <i
                        class="fa fa-chevron-right fa-fw fa-xs"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
export default NewsManagerList;
