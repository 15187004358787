import React from 'react'
import { Link } from 'react-router-dom';

const Breadcrumb = () => {
    return (
        <nav className="breadcrumbs" aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/inicio"><i className="fa fa-home" aria-hidden="true"></i>{" Inicio"}</Link></li>
                <li className="breadcrumb-item"><Link to="/beneficios" onClick={(e) => e.preventDefault()}>{"Beneficios"}</Link></li>
                <li className="breadcrumb-item active" aria-current="page">{"Vale Personal"}</li>
            </ol>
        </nav>
    )
};

export default Breadcrumb;