import Auth from "@aws-amplify/auth";

const handleCache = async (key: string, response: Response) => {
    const data = await response.json();
    const cache = await caches.open("api-cache");
    await cache.put(key, new Response(JSON.stringify(data)));
};

const fetchData = async ({ url = "", method = "GET", body = null, saveCache = true }) => {
    try {
        if (!url) return false;

        if (saveCache) {
            const cacheStorage = await caches.open("api-cache");
            const cachedResponse = await cacheStorage.match(url);
    
            if (cachedResponse) {
                return cachedResponse.json();
            }
        }

        const auth = await Auth.currentSession();
        const requestOptions = {
            method,
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                AccessToken: auth.getAccessToken().getJwtToken(),
                Authorization: auth.getIdToken().getJwtToken()
            },
            body: body ? JSON.stringify(body) : null,
        };

        const response = await fetch(url, requestOptions);

        if (response.ok) {
            const data = await response.json();
            if (saveCache) {
                await handleCache(url, new Response(JSON.stringify(data)));
            }
            return data;
        } else {
            return false;
        }
    } catch (error) {
        return false; 
    }
};

export { fetchData };