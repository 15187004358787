import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { BsExclamationCircle } from "react-icons/bs";
import IdiomasService from '../../services/idiomas/idiomas-service'
import styles from "../../resources/css/style-COL.module.css";

function DetalleIdioma({ listLanguages, writeLevel, speakLevel, id, changeDisplay, reloadLanguages }) {
    const [infoLanguage, setInfoLanguage] = useState({})
    const [loadingLanguage, setLoadingLanguage] = useState(false)
    const [noDataLanguage, setNoDataLanguage] = useState(false)
    const [deleteLanguage, setDeleteLanguage] = useState(false)
    const [loadingDeleteLanguage, setLoadingDeleteLanguage] = useState(false)
    const [noDataDelete, setNoDataDelete] = useState(false)

    useEffect(() => {
        if (id !== undefined) {
            setLoadingLanguage(true)
            IdiomasService.GetLanguage(id, setInfoLanguage, setLoadingLanguage, setNoDataLanguage)
        }
    }, [id])

    const deleteOneLanguage = () => {
        Swal
            .fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: red; font-size: 16px"> Eliminar </div>',
                html: '<div style="text-align: center; font-size: 15px"> ¿Desea continuar la operación? </div>',
                showCancelButton: true,
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                confirmButtonColor: "red",
                cancelButtonColor: "#0169b5"
            }).then((result) => {
                if (result.isConfirmed) {
                    setLoadingDeleteLanguage(true)
                    IdiomasService.DeleteLanguage(id, setDeleteLanguage, setLoadingDeleteLanguage, setNoDataDelete)
                }
            })
    }

    // Mensaje de error al enviar un post
    const handleStatusError = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
            html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo.</div>`,
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadLanguages()

        })
        setDeleteLanguage(false)
        setNoDataDelete(false)
    }

    // Mensaje de éxito al enviar un post
    const handleStatusSuccess = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
            html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadLanguages()

        })
        setDeleteLanguage(false)
        setNoDataDelete(false)
    }

    useEffect(() => {
        // noDataAdd ? handleStatusError() : null
        deleteLanguage ? handleStatusSuccess() : null
    }, [loadingDeleteLanguage])
    return (
        <>
            {loadingLanguage ? <div data-testid="loading" className={styles["full-spinner"]}>
                <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
            </div> : noDataLanguage ? <div className={`${styles["alert"]} ${styles["alert-info"]} ${styles["alert-dismissible"]} ${styles["fade"]} ${styles["show"]} ${styles["text-center"]}`} role="alert">
                <BsExclamationCircle />  No se encontraron datos asociados
            </div> : <>
                {/* <!-- Inicio Ver Idiomas --> */}
                <div className={`${styles["box"]} ${styles["mb-4"]}`}>
                    <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Ver Idiomas</h2>
                    <form>
                        {/* <!-- Fila 1 --> */}
                        <div className={styles["row"]}>
                            <div className={`${styles["col-12"]} ${styles["col-md-3"]} ${styles["mb-3"]}`}>
                                <label className={styles["form-label"]}>Idioma <span className={styles["color-red"]}>*</span></label>
                                <div className={styles["input-group"]}>
                                    <select className={styles["select-col"]} defaultValue={infoLanguage.language} disabled>
                                        {listLanguages?.filter(l => l.id === infoLanguage.language).map((l) => { return (<option key={l.id} value={l.id}>{l.description}</option>) })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Fila 2 --> */}
                        <p><strong>Nivel</strong> <span className={styles["color-red"]}>*</span></p>
                        {/* <!-- Fila 3 --> */}
                        <div className={styles["row"]}>
                            <div className={`${styles["col-12"]} ${styles["col-md-10"]} ${styles["col-xl-8"]} ${styles["col-xxl-6"]} ${styles["mb-3"]}`}>
                                <label className={styles["form-label"]}>Habla</label>
                                <div className={`${styles["row"]} ${styles["mt-2"]}`}>
                                    {speakLevel?.sort((a, b) => a.id - b.id).map((s) => {
                                        return (
                                            <div key={s.id} className={`${styles["col-12"]} ${styles["col-md-3"]}`}>
                                                <div className={styles["form-check"]}>
                                                    <input className={styles["form-check-input"]} type="radio" value={s.id} name="speaking_level" checked={s.id === infoLanguage.speaking_level} disabled />
                                                    <label className={styles["form-check-label"]} htmlFor={s.id}>{s.description}</label>
                                                </div>
                                            </div>)
                                    })}
                                </div>
                            </div>
                        </div>
                        {/* <!-- Fila 4 --> */}
                        <div className={styles["row"]}>
                            <div className={`${styles["col-12"]} ${styles["col-md-10"]} ${styles["col-xl-8"]} ${styles["col-xxl-6"]} ${styles["mb-3"]}`}>
                                <label className={styles["form-label"]}>Escribe</label>
                                <div className={`${styles["row"]} ${styles["mt-2"]}`}>
                                    {writeLevel?.sort((a, b) => a.id - b.id).map((s) => {
                                        return (
                                            <div key={s.id} className={`${styles["col-12"]} ${styles["col-md-3"]}`}>
                                                <div className={styles["form-check"]}>
                                                    <input className={styles["form-check-input"]}  type="radio" value={s.id} name="writing_level" id={s.id} checked={s.id === infoLanguage.writing_level} disabled />
                                                    <label className={styles["form-check-label"]} htmlFor={s.id}>{s.description}</label>
                                                </div>
                                            </div>)
                                    })}
                                </div>
                            </div>
                        </div>
                        {/* <!-- fin filas --> */}
                    </form>

                    <div className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mt-4"]}`}>
                        <div className={`${styles["col-6"]} ${styles["col-md-auto"]}`}>
                            <button type="submit" className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`} onClick={() => deleteOneLanguage()} disabled={loadingDeleteLanguage} >Eliminar <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i>{loadingDeleteLanguage ? <div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div> : null}</button>
                        </div>
                        <div className={`${styles["col-6"]} ${styles["col-md-auto"]}`}>
                            <button type="submit" onClick={() => changeDisplay(true, id, infoLanguage)} className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`}>Modificar <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i></button>
                        </div>
                    </div>
                    {noDataDelete ? handleStatusError() : null}
                </div>
                {/* <!-- Fin Ver Idiomas --> */}
            </>}</>
    )
}

export default DetalleIdioma;