const config = {
    AWS_REGION: process.env.REACT_APP_REGION,
    AWS_COGNITO_IDENTITY_POOL_ID: 'SETME',
    AWS_COGNITO_USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    AWS_COGNITO_CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    AWS_COGNITO_CLIENT_DOMAIN_NAME: process.env.REACT_APP_CLIENT_DOMAIN_NAME,
    AWS_COGNITO_IDP_NAME: process.env.REACT_APP_IDP_NAME,
    AWS_COGNITO_IDP_SIGNIN_URL: process.env.REACT_APP_IDP_SIGNIN_URL,
    AWS_COGNITO_IDP_SIGNOUT_URL: process.env.REACT_APP_IDP_SIGNOUT_URL,
    AWS_COGNITO_IDP_OAUTH_CLAIMS: [process.env.REACT_APP_IDP_OAUTH_CLAIMS0, process.env.REACT_APP_IDP_OAUTH_CLAIMS1, process.env.REACT_APP_IDP_OAUTH_CLAIMS2, process.env.REACT_APP_IDP_OAUTH_CLAIMS3, process.env.REACT_APP_IDP_OAUTH_CLAIMS4,],
    AWS_COGNITO_IDP_GRANT_FLOW: process.env.REACT_APP_IDP_GRANT_FLOW,
};
export default config;
