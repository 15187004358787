import { JobInfo } from "../interfaces/applyInterface";

export const paginationHelper = () => {
    
    const startPaginateArray = (
        pageNumber: number,
        elementsPerPage: number,
        dataArray: any[] = []
    ): JobInfo[] => {
        const startIndex = (pageNumber - 1) * elementsPerPage;
        const endIndex = Math.min(
            startIndex + elementsPerPage - 1,
            dataArray.length - 1
        );
        return dataArray.slice(startIndex, endIndex + 1);
    }

    return {
        startPaginateArray
    }
};
