import React from 'react';
import { ReactComponent as IcoArg } from '../../resources/images/ico_ar.svg';
import { ReactComponent as IcoBr } from '../../resources/images/ico_br.svg';
import { ReactComponent as IcoPe } from '../../resources/images/ico_pe.svg';
import { ReactComponent as IcoCl } from '../../resources/images/ico_cl.svg';
import { ReactComponent as IcoCol } from '../../resources/images/ico_col.svg';
import { ReactComponent as IcoUsa } from '../../resources/images/flag_usa.svg';
import { ReactComponent as IcoUy } from '../../resources/images/flag_uruguay.svg';

export function getCountry(country: string) {
    if (country) {
        country = country.toLowerCase();
        switch (country) {
            case 'chile':
                return <IcoCl />;
            case 'brasil':
                return <IcoBr />;
            case 'peru':
                return <IcoPe />;
            case 'argentina':
                return <IcoArg />;
            case 'colombia':
                return <IcoCol />;
            case 'uruguay':
                return <IcoUy />;
            case 'estados unidos':
                return <IcoUsa />;
            default:
                return '';
        }
    } else {
        return '';
    }
}