/* eslint-disable no-undef */
import axios from "axios";
import Auth from '@aws-amplify/auth';
import Swal from 'sweetalert2';


const baseUrl = process.env.REACT_APP_BACKEND_URL_GLOBAL;
// const baseUrl = "http://localhost:5000";

const convertedFile = (archivo) => {
  let byteChar = atob(archivo);
  let byteArray = new Uint8Array(new ArrayBuffer(byteChar.length));
  for (let i = 0; i < byteChar.length; i++) {
    byteArray[i] = byteChar.charCodeAt(i);
  }
  return byteArray;
}

const SolicitudLicenciasService = {
  getData: async (setData) => {
    try {
      Auth.currentSession().then(auth => {
        const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
        axios.get(baseUrl + '/api/Employee/LicencesData', { headers: head }).then(response => {
          const responseJSON = response.data;
          setData(responseJSON)
        }).catch(function (error) {
          console.log(error)
        })
      })
    } catch (error) {
      console.error(error);
    }
  },
  getLicensesInfo: async (setFetched, setLicenseOption, setData, setLoading, setNoData, setManager) => {
    try {
      Auth.currentSession().then(auth => {
        const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
        axios.get(baseUrl + '/api/Employee/LicencesData', { headers: head }).then(response => {
          const responseJSON = response.data;
          setData(responseJSON.CN_RSP_ABCEN_EMPL)
          setManager({
            MANAGER_ID: responseJSON.CN_RSP_ABCEN_EMPL.MANAGER_ID,
            MANAGER_NAME: responseJSON.CN_RSP_ABCEN_EMPL.MANAGER_NAME,
            MANAGER_EMAIL: responseJSON.CN_RSP_ABCEN_EMPL.MANAGER_EMAIL,
          })
          // setCode(responseJSON.CN_RSP_ABCEN_EMPL.CN_RSP_ABCEN_tTYPE.)
          setLicenseOption(responseJSON.CN_RSP_ABCEN_EMPL.CN_RSP_ABCEN_tTYPE)
          setFetched(true)
          setLoading(false)
        }).catch(function (error) {
          console.log(error)
          setFetched(true);
          setNoData(true);
          setLoading(false)
        })
      }).catch(function (error) {
        console.log(error)
        setFetched(true);
        setNoData(true);
        setLoading(false)
      })
    } catch (error) {
      console.error(error);
      setFetched(true);
      setNoData(true);
      setLoading(false)
    }
  },

  createLicence: async (setLoadingSave, info, setError, setErrorMessage, setLicenseSent, setResponseId) => {
    setLoadingSave(true);
    const url = baseUrl + '/api/Employee/CreateLicence';
    Auth.currentSession().then(auth => {
      const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
      const body = JSON.stringify(info);
      axios({
        method: 'post',
        url: url,
        headers: head,
        data: body
      }).then(response => {
        if (response.status === 200) {
          setResponseId(response.data.idSolicitud)
          setLicenseSent(true);
          setLoadingSave(false);
        } else {
          setLoadingSave(false);
          setError(true);
        }
      }).catch(function (error) {
        if (error.status === 500) {
          setLoadingSave(false);
          setError(true)
        } else {
          setLoadingSave(false);
          setErrorMessage(error.response.data.message)
          setError(true)
        }

      })
    })
  },
  uploadCertificate: async (setLoadingSave, fd, setCertificateSent, setError, setErrorMessage, setCertificateError) => {
    setLoadingSave(true);
    const url = baseUrl + '/api/Employee/UploadLicenceFile';
    Auth.currentSession().then(auth => {
      const head = { 'Content-Type': 'multipart/form-data', 'AccessToken': auth.getAccessToken().getJwtToken() }
      const body = fd;
      axios({
        method: 'post',
        url: url,
        headers: head,
        data: body
      }).then(response => {
        if (response.status === 200) {
          setLoadingSave(false);
          setCertificateSent(true);
          setCertificateError(false);
          setError(false);
        } else {
          setLoadingSave(false);
          setError(true);
          setCertificateError(true);
        }
      }).catch(function (error) {
        setErrorMessage(error.response.data?.message)
        setCertificateSent(false);
        setLoadingSave(false);
        setError(true);
        setCertificateError(true);
      })
    })
  },
  getLicenciasList: async (setLoading, setFetched, setLicenseList, setNoData) => {

    try {
      setLoading(true);
      Auth.currentSession().then(auth => {
        const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
        axios.get(baseUrl + '/api/Employee/LicencesList', { headers: head }).then(response => {
          const responseJSON = response.data;
          setLicenseList(responseJSON);
          setLoading(false);
          setFetched(true);
        }).catch(function (error) {
          console.log(error)
          setLoading(false);
          setNoData(true)
        })
      }).catch(function (error) {
        console.log(error)
        setLoading(false);
        setNoData(true)
      })
    } catch (error) {
      console.error(error);
    }

  },
  UpdateTable: async (estado, setLicenseList, setFetched, setLoading) => {
    setLoading(true);
    Auth.currentSession().then(auth => {
      const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
      axios.get(baseUrl + "/api/Employee/LicencesList", { headers: head }).then(response => {
        if (response.data !== null) {
          setFetched(true)
          const responseJSON = response.data.filter(x => x.CN_ABSENCE_STATUS === estado);
          setLicenseList(responseJSON)
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
    }).catch(function (error) {
      console.log(error.response);
      setLoading(false);
    })
  },
  getLicenceCertificate: async (idSolicitud, setLoadingIndices) => {
    try {
      Auth.currentSession().then(auth => {
        const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
        axios.get(baseUrl + '/api/Employee/LicenceCertificate?idSolicitud=' + idSolicitud, { headers: head }).then(response => {
          const file = new Blob([convertedFile(response.data.certificateFile)], { type: response.data.certificateFileType });
          const url = window.URL.createObjectURL(file);
          if (response.data.certificateFileType === "application/pdf") {
            Swal.fire({
              title: response.data.certificateFileName,
              allowOutsideClick: false,
              width: 600,
              html: `
                    <object data="${url}" type="application/pdf" style="text-align: left; width: 100%; height: 600px;">
                        <p style="margin-bottom: 0; text-align: center; padding: 10px">
                            El navegador no puede previsualizar el pdf. <br/>
                            <a href="${url}" download="${response.data.FILENAME}">
                                Descargar archivo
                            </a>
                        </p>
                    </object>
                    `,
              confirmButtonColor: '#0169b5',
            })
            setLoadingIndices((prev) => {
              const updated = new Set(prev);
              updated.delete(idSolicitud);
              return updated;
            });
          } else {
            Swal.fire({
              width: '70%',
              allowOutsideClick: false,
              heightAuto: false,
              imageWidth: '90%',
              imageUrl: `${url}`,
              confirmButtonColor: '#0169b5'
            })
            setLoadingIndices((prev) => {
              const updated = new Set(prev);
              updated.delete(idSolicitud);
              return updated;
            });
          }
        }).catch(function (error) {
          console.error(error);
        })
      }).catch(function (error) {
        console.error(error);
      })
    } catch (error) {
      console.error(error);
    }

  },
  deleteLicenceCertificate: async (idSolicitud, setDeleteFileFlag, setErrorDelete, setFileDeleted) => {
    try {
      setDeleteFileFlag(true);
      Auth.currentSession().then(auth => {
        const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
        axios.delete(baseUrl + '/api/Employee/DeleteLicenceFile?idSolicitud=' + idSolicitud, { headers: head }).then(response => {
          if (response.status === 200) {
            setFileDeleted(true)
            setDeleteFileFlag(false);
          } else {
            setDeleteFileFlag(false);
            setErrorDelete(true);
          }
        }).catch(function (error) {
          console.error(error);
          setErrorDelete(true);
          setDeleteFileFlag(false);
        })
      }).catch(function (error) {
        console.error(error);
      })
    } catch (error) {
      console.error(error);
    }

  },
  getTermsBP: (setTerms) => {
    Auth.currentSession().then(auth => {
      const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
      axios.get(baseUrl + "/api/User/TermsBP", { headers: head }).then(response => {
        if (response.data !== null) {
          const responseJSON = response.data;
          setTerms(responseJSON);
        }
      }).catch(function (error) {
        console.log(error.response)
      })
    })
  },




}

export default SolicitudLicenciasService;