import { ChartBar } from "../../../../components/chart";
import { useMemo } from "react";
import { DistributionChartProps } from "./compensations.types";

export const DistributionChart = (props: DistributionChartProps) => {
  const { chartInfo, isLoading } = props;

  const xAxisLabels = [
    "Bajo Rango",
    "Crecimiento",
    "Madurez",
    "Mercado",
    "Sobre Rango",
  ];

  const dataBar = useMemo(() => {
    const counts = xAxisLabels.map(() => 0);

    if (!chartInfo) return counts;

    chartInfo.forEach((item) => {
      const index = xAxisLabels.indexOf(item.position);
      if (index !== -1) {
        counts[index]++;
      }
    });

    return counts;
  }, [chartInfo]);

  if (isLoading)
    return (
      <div className="text-center">
        <span className="spinner-border spinner-border-sm" />
        <p> Cargando información... </p>
      </div>
    );

  return (
    <>
      <div className="box mb-4">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h4 className="text-primary fw-bold header-card tit">
              Distribucion de Posicionamiento
            </h4>
            <div className="line"></div>
          </div>
        </div>

        <div className="table-responsive">
          <ChartBar xAxisLabels={xAxisLabels} values={dataBar} />
        </div>
      </div>
    </>
  );
};
