import React, { useEffect, useState } from 'react';
import ApprovalResponsiveHeader from './approval-responsive-header';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import getWeeklyCalendar from '../aprobacion-responsive/weekly-calendar';
import Accordion from 'react-bootstrap/Accordion';
import ApprovalModal from './approval-modal';
import getChangeType from './change-type-fn';
import { useNavigate } from 'react-router';

const CalendarApprovalResponsive = (props) => {
    CalendarApprovalResponsive.propTypes = {
        initial: PropTypes.any,
        iterator: PropTypes.any,
        sub: PropTypes.any,
        shiftChange: PropTypes.any,
        approval: PropTypes.any,
        rejector: PropTypes.any,
        setReload: PropTypes.any
    }

    const initial = props.initial;
    const employeeIterator = props.iterator;
    const subordinates = props.sub;
    const setReload = props.setReload;
    const ref = initial.current?.getApi();
    const now = moment(ref?.getDate());
    const [value, setValue] = useState(now);
    const calendar = getWeeklyCalendar(value);
    const [current, setCurrent] = useState(calendar);
    const [active, setActive] = useState(false);
    const [modalInfo, setModalInfo] = useState(null);
    const navigation = useNavigate();

    useEffect(() => {
        setCurrent(getWeeklyCalendar(value))
    }, [value])

    useEffect(() => {
        getEvents(employeeIterator)
    }, [])

    const getEvent = (fecha, id) => {
        const employee = employeeIterator.filter(element => element.employeeId === id)
        const events = employee[0].events
        let a = []

        for (let i = 0; i <= events.length; i++) {
           

            if ( moment(new Date(fecha)).format("DD-MM-YYYY") >= moment(events[i]?.data?.startDate).format("DD-MM-YYYY") 
             && moment(new Date(fecha)).format("DD-MM-YYYY")<= moment(events[i]?.data?.endDate).format("DD-MM-YYYY")
               ) {
                if (events[i]) {
                    a.push(events[i])
                }
            }
        }
        return a;

    }

    function getEvents(collection) {

        collection.forEach(employee => {
            console.log(employee)
            let results = employee.events.filter(x => {
                return x.type !== "Turno" && x.type !== "Franco" && x.type !== "SinHorario"
            })
            let arr = results.filter(x => { x.id !== null })
            arr.forEach(event => {
                let intervals = spliceEvents(event.data);
                const first = intervals[0]
                const match = employee.events.find(x => x.id === first.id)
                const idx = employee.events.indexOf(match);
                employee.events.splice(idx, 1)
                intervals.forEach(element => {
                    employee.events.push({
                        id: element.id,
                        end: element.endDate,
                        start: element.startDate,
                        type: element.type,
                        data: element,
                        allDay: true,
                        priority: 0,
                        title: null,
                    })
                })
            })

        })
    }

    function spliceEvents(obj) { //function that splits holidays or licenses into day intervals, otherwise object would have single enddate
        const arr = [];
        const start = new Date(obj.startDate);
        const end = new Date(obj.endDate);

        for (let i = moment(start); i.diff(end, "day") <= 0; i.add(1, "day")) {

            const event = {
                duration: obj.duration,
                startDate: new Date(i),
                endTime: obj.endDate,
                id: obj.id,
                isHoliday: obj.isHoliday,
                shiftChange: obj.shiftChange,
                endDate: new Date(moment(i).add(1, "day")),
                startTime: obj.startTime,
                status: obj.status,
                type: obj.type
            }
            arr.push(event)
        }
        return arr;
    }
    const getSubordinate = (id) => {
        const a = subordinates.filter(emp => emp.employeeId == id)
        return a
    }

    function isHoliday(fecha, id) {
        const employee = employeeIterator.filter(element => element.employeeId === id)
        const events = employee[0].events
        let a = null
        events.forEach((element) => {
            if (moment(element.start).format("DD-MM-YYYY") == moment(new Date(fecha)).format("DD-MM-YYYY")) {
                a = element.data?.isHoliday;
            }
        })
        return a
    }

    function getInfo(fecha, id, eventId) {//for modals only
        const employee = employeeIterator.filter(element => element.employeeId === id)
        const events = employee[0].shifts
        let a = {}
        events.forEach((element) => {
            if (moment(element?.startDate).format("DD-MM-YYYY") === moment(new Date(fecha)).format("DD-MM-YYYY") && element.id === eventId) {
                a = element.shiftChange
            }
        })
        return a
    }
    const getClass = (param) => {
        switch (param) {
            case 'Franco': return 'day-franco';
            case 'Licencia': return 'day-licencias';
            case 'Vacaciones': return 'day-vacaciones';
            case 'FrancoPendiente': return 'day-pendiente-horario-franco';
            case 'HorarioPendiente': return 'day-pendiente-horario-franco';
            case 'LicenciaPendiente': return 'day-pendiente-licencia';
            case 'VacacionesPendiente': return 'day-pendiente-vacaciones';
            default: return '';
        }
    }

    return (
        <section>
            <ApprovalResponsiveHeader val={value} ref={initial} setVal={setValue} />
            <div className="calendar__body">
                {employeeIterator && employeeIterator.map((emp, i) => {
                    return (
                        <div key={i}>
                            <Accordion className="accordion accordion-flush accordion-light accordion-table d-md-none" defaultActiveKey="0">
                                <Accordion.Item key={i} eventKey={emp.id} className="accordion-item">
                                    <Accordion.Header className="accordion-header" id="accordion">
                                        {getSubordinate(emp.employeeId).map((employee) => (
                                            <strong className="strong-header" key={i}>{employee?.name}</strong>
                                        ))}
                                    </Accordion.Header>
                                    <Accordion.Body className="accordion-body p-0">
                                        {
                                            current.map((days, i) => (
                                                <div key={i} className={`calendar__body-row ${days.format("DD-MM-YYYY") === moment().format("DD-MM-YYYY") ? "mob-calendar-current" : ""}`}>
                                                    {/* {console.log(current)} */}
                                                    <div className="day-cell-approval">
                                                        <div key={days} className="day-cell-name-approv text-capitalize">{days?.format("dddd")}  {days.format("DD")}</div>
                                                        {getEvent(days, emp.employeeId).map(el => {
                                                            return (
                                                                <span key={el?.id}>
                                                                    <span className={`day-cell-body ${isHoliday(days, emp.employeeId) ? 'mob-calendar-holiday' : ''}`}>
                                                                        <span onClick={() => {
                                                                            if (el?.type === "HorarioPendiente" || el?.type === "FrancoPendiente") {
                                                                                setModalInfo(getInfo(days, emp.employeeId, el.id))
                                                                                setActive(true);
                                                                            } else if (el?.type === "LicenciaPendiente" || el?.type === "VacacionesPendiente") {
                                                                                navigation('../arg/centro-de-aprobaciones')
                                                                            } else {
                                                                                return
                                                                            }
                                                                        }} className={getClass(el?.type)}>
                                                                            {el?.type === "Turno" ? el?.title : getChangeType(el?.type)}
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            ))}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    )
                })}
            </div>
            <section>
                {modalInfo && active && <ApprovalModal active={active} setActive={setActive} data={modalInfo} setData={setModalInfo} setReload={setReload}></ApprovalModal>}
            </section>
        </section >

    )
}

export default CalendarApprovalResponsive
