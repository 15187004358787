import React from 'react';
import { Link } from 'react-router-dom';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import Tippy from '@tippyjs/react';

interface CardProps {
    url: string;
    icon: string;
    titulo: string;
    requiresVPN?: boolean;
    requiresPassword?: boolean;
}

const Card: React.FC<CardProps> = ({ url, icon, titulo, requiresVPN, requiresPassword }) => {
    return (
        <div className="me-3 mb-3 card">
            <Link to={url} target="_blank" className='text-decoration-none'>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center w-75">
                        <div className="me-3">
                            <div
                                className="rounded-3 p-3 d-flex align-items-center justify-content-center"
                                style={{ width: '50px', height: '50px'}}
                            >
                                <img src={icon} alt="icon" style={{ maxWidth: '50px', maxHeight: '50px'}}/>
                            </div>
                        </div>
                        <div style={{width:"90%"}}>
                            <div className="fw-bold mb-2 fs-6 container-fluid p-0">
                                <Tippy content={titulo}>
                                    <span className="d-inline-block text-truncate w-100">
                                        {titulo}
                                    </span>
                                </Tippy>
                            </div>
                            <div className="text-start">
                                {/* Tooltip para Requiere VPN */}
                                {requiresVPN && (
                                    <Tippy content='Requiere VPN'>
                                        <span className="me-2"><VpnLockIcon /></span>
                                    </Tippy>
                                )}
                                {/* Tooltip para Requiere Password */}
                                {requiresPassword && (
                                    <Tippy content='Requiere Password'>
                                        <span className="me-2"><LockPersonIcon /></span>
                                    </Tippy>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* Icono de flecha derecha */}
                    <div className='w-auto'>
                        <i className="fa fa-chevron-right fa-lg color-orange" aria-hidden="true"></i>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default Card;
