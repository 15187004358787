import React, { Children, FC, useEffect, useState } from "react";
import { Row, TableProps } from "../../utilities/interfaces";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import ReactPaginate from "react-paginate";
import { pipe } from "fp-ts/lib/function";
import * as A from "fp-ts/Array";
import * as RD from "@devexperts/remote-data-ts";

library.add(faEye);

const Table: FC<TableProps> = ({
  header,
  rows,
  selectedOptionYear,
  setRequest,
  emplid,
  dependencyArray,
  setDependencyArray,
  isLoadData,
  setSelectedOption,
  directReport,
  setselectedOptionEquipo,
  setDirectReport
}) => {
  if(!Array.isArray(rows)){
    rows = [rows]
  }

  const keysToDisplay = header.filter((h) => !h.hidden).map((h) => h.value);
  const [showTitle, setShowTitle] = useState(false);
  const pageItems: Row[][] = pipe(
    rows.filter((r) => r.emplid !== "" && r.fullName !== ""),
    A.chunksOf(5)
  );
  const [currentPage, setCurrentPage] = useState<number>(0);

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };
  useEffect(() => {
    setCurrentPage(0);
  }, [rows]);

  useEffect(() => {
    if (pageItems.length > 0) {
      pageItems[currentPage]!== undefined && pageItems[currentPage].length > 0 && pageItems[currentPage].map((r) => {
        if (r.reports !== undefined) {
          setShowTitle(true);
        }
      });
    }
  }, [pageItems, currentPage]);

  return rows.length > 0 && header.length > 0 && !isLoadData ? (
    <>
     <div className="table-responsive">
      <table className="table table-even table-section table-th-border table-borderless align-middle d-none d-md-table">
          <thead>
            <tr>
              {Children.toArray(
                header.length > 0 &&
                  header.map((h) => {
                    return !h.hidden && 
                      <th>{h.label}</th>;
                  })
              )}
              {showTitle && <th>Dependencias</th>}
            </tr>
          </thead>
          <tbody>
            {pageItems !== undefined && pageItems.length > 0 && !isLoadData ? (
              Children.toArray(
                pageItems[currentPage].map((r) => {
                  return r.fullName !== "" && r.emplid !== "" ? (
                    <tr>
                      {Children.toArray(
                        keysToDisplay.map((k) => {
                          return <td>{ r[k]!=='' ? r[k] : '-'}</td>;
                        })
                      )}
                      {r.reports === true}
                      <td>
                        <a
                          href={r.reports === "true" ? "#" : undefined}
                          onClick={(e) => {
                            if (r.reports === "true") {
                              const req = `?target=${r.emplid}&reports=true&year=${selectedOptionYear}`;
                              e.preventDefault();
                              setRequest(req);
                              setCurrentPage(0);
                              pipe(
                                dependencyArray,
                                A.append({
                                  emplid: `${r.emplid}`,
                                  fullName: `${r.fullName}`,
                                  request: req,
                                  disabled:false,
                                }),
                                setDependencyArray
                              );
                              let existsEmplid = directReport?.some(emp=> emp.emplid === r.emplid);
                              let filter = directReport?.filter((dir)=>!dir.disabled);
                              if(!existsEmplid){
                                filter?.unshift({
                                  emplid: `${r.emplid}`,
                                    fullName: `${r.fullName}`,
                                    disabled: true
                                })
                              }
                              filter!==undefined && setDirectReport(filter);
                              setSelectedOption('equipo');
                              setselectedOptionEquipo(`${r.emplid}`);
                            
                            }
                          }}
                          style={r.reports === "true" ? {} : { color: "#d9d9d9" }}
                        >
                          {r.reports !== undefined && (
                            <small> {"Ver Equipo"} </small>
                          )}
                        </a>
                      </td>
                    </tr>
                  ) : (
                    <>
                    </>
                  );
                })
              )
            ) : isLoadData ?(
              <tr>
                <td style={{maxWidth:'1px'}}>
                  <p>Cargando información...</p>
                </td>
              </tr>
            ): pageItems.length === 0 ? (
              <tr>
                <td style={{maxWidth:'1px'}}>
                  <p>No se ha encontrado información.</p>
                </td>
                {header.map((a,b)=>{
                  return <>
                  <td></td>
                  </>
                })}
              </tr>
            ) : <></>}
          </tbody>
      </table>

        <div className="accordion accordion-flush accordion-light accordion-table d-block d-md-none">
          {pageItems !== undefined && pageItems.length && !isLoadData ? (
            Children.toArray(
              pageItems[currentPage].map((r, indexR) => {
                return r.fullName !== "" && r.emplid !== "" ? (
                  <div className="accordion-item">
                    <h2 className="accordion-header" id={`accordion-${indexR}`}>
                      <button
                        className={`accordion-button${
                          indexR !== 0 ? " collapsed" : ""
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#accordion-item-${indexR}`}
                        aria-expanded={`${indexR === 0}`}
                        aria-controls={`accordion-item-${indexR}`}
                      >
                        <strong>Nombre Colaborador</strong>
                        {` ${r.fullName}`}
                      </button>
                    </h2>
                    <div
                      id={`accordion-item-${indexR}`}
                      className={`accordion-collapse collapse${
                        indexR === 0 ? " show" : ""
                      }`}
                      aria-labelledby={`accordion-${indexR}`}
                    >
                      <div className="accordion-body p-0">
                        <div className="data-list-box data-list-box-even">
                          <ul className="list-column-1 list-unstyled">
                            {Children.toArray(
                              header.map((h) => {
                                return !h.hidden ? (
                                  <li>
                                    <span className="data-list-box__title">
                                      {h.label}
                                    </span>
                                    <span className="data-list-box__text">
                                      {r[h.value] !== '' ? r[h.value]: '-'}
                                    </span>
                                  </li>
                                ) : (
                                  <></>
                                );
                              })
                            )}
                            {r.reports !== undefined ? (
                              <li>
                                <span className="data-list-box__title">
                                  Dependencias
                                </span>
                                <span className="data-list-box__text">
                                  <a
                                    href={r.reports === "true" ? "#" : undefined}
                                    onClick={(e) => {
                                      if (r.reports === "true") {
                                        const req = `?target=${r.emplid}&reports=true&year=${selectedOptionYear}`;
                                        e.preventDefault();
                                        setRequest(req);
                                        setCurrentPage(0);
                                        pipe(
                                          dependencyArray,
                                          A.append({
                                            emplid: `${r.emplid}`,
                                            fullName: `${r.fullName}`,
                                            request: req,
                                            disabled: false
                                          }),
                                          setDependencyArray
                                        );
                                        let existsEmplid = directReport?.some(emp=> emp.emplid === r.emplid);
                                        let filter = directReport?.filter((dir)=>!dir.disabled);
                                        if(!existsEmplid){
                                          filter?.unshift({
                                            emplid: `${r.emplid}`,
                                              fullName: `${r.fullName}`,
                                              disabled: true
                                          })
                                        }
                                        filter!==undefined && setDirectReport(filter);
                                        setSelectedOption('equipo');
                                        setselectedOptionEquipo(`${r.emplid}`);
                                      }
                                    }}
                                    style={
                                      r.reports === "true"
                                        ? {}
                                        : { color: "#d9d9d9" }
                                    }
                                  >
                                    {r.reports !== undefined && (
                                      <small> {"Ver Equipo"} </small>
                                    )}
                                  </a>
                                </span>
                              </li>
                            ) : (
                              <></>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                );
              })
            )
          ) : isLoadData ?(
            <div className="mx-2">
              <small> Cargando información... </small>
            </div>
          ): pageItems.length === 0 ? (
            <div className="mx-2">
              <p>No se han encontrado resultados.</p>
            </div>
          ) : null}
        </div>
     </div>
     

      {pageItems !== undefined && pageItems.length > 0 && !isLoadData ? (
        <nav className="mt-5" aria-label="Paginación">
          <ReactPaginate
            className="pagination justify-content-center align-items-center"
            breakLabel=" ... "
            nextLabel=" > "
            onPageChange={handlePageClick}
            pageRangeDisplayed={0}
            marginPagesDisplayed={1}
            pageCount={pageItems.length}
            previousLabel=" < "
            renderOnZeroPageCount={() => null}
            previousLinkClassName={"page-link"}
            nextLinkClassName={"page-link"}
            breakLinkClassName={"page-link"}
            pageLinkClassName={"page-link"}
            pageClassName={"page-item"}
            breakClassName={"page-item"}
            previousClassName={"page-item"}
            nextClassName={"page-item"}
            activeClassName={"page-item active"}
            activeLinkClassName={"page-link active"}
          />
        </nav>
      ) : null}
    </>
  ) : isLoadData ? (
    <>
    <div className="text-center">
        <span className="spinner-border spinner-border-sm" />
        <p> Cargando información... </p>
    </div>
    </>
  ):<>
    </>;
};
export default Table;
