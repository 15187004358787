import { Tab } from "../../../../components/tab/tab";
import { Tabs } from "../../../../components/tab/tabs";
import { ColumnsConfig } from "../../../../components/table";
import { useProfileSummaryProvider } from "../../context/EmployeeProfileSummaryContext";
import { LayoutCard } from "./LayoutCard";

export const HistoriesCard = () => {
  const { summaryEmployee } = useProfileSummaryProvider();

  const columnsConfigPositions: ColumnsConfig[] = [
    {
      accessor: "location",
      header: "Ubicación",
      className: "text-center"
    },
    {
      accessor: "position",
      header: "Cargo",
      className: "text-center"
    },
    {
      accessor: "startDate",
      header: "Desde",
      className: "text-center"
    },
    {
      accessor: "endDate",
      header: "Hasta",
      className: "text-center"
    },
    {
      accessor: "totalYears",
      header: "Años Total",
      className: "text-center"
    }
  ];

  const columnsConfigUbications: ColumnsConfig[] = [
    {
      accessor: "location",
      header: "Ubicación",
      className: "text-center"
    },
    {
      accessor: "position",
      header: "Cargo",
      className: "text-center"
    },
    {
      accessor: "startDate",
      header: "Desde",
      className: "text-center"
    },
    {
      accessor: "endDate",
      header: "Hasta",
      className: "text-center"
    },
    {
      accessor: "totalYears",
      header: "Años Total",
      className: "text-center"
    }
  ];

  return (
    <div className="col-12 col-xl-12 d-flex">
      <div className="box align-items-stretch w-100">
        <Tabs>
          <Tab label="Historial de posiciones">
            <LayoutCard
              title="Historial de posiciones"
              columnsConfig={columnsConfigPositions}
              data={summaryEmployee.data.positionHistory}
            />
          </Tab>
          <Tab label="Historial de Ubicaciones">
            <LayoutCard
              title="Historial de Ubicaciones"
              columnsConfig={columnsConfigUbications}
              data={summaryEmployee.data.locationHistory}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};
