import { FC } from "react";
import { SearchBarProps, SearchProps } from "../../../../../utilities/interfaces";

const SearchBar: FC<SearchBarProps> = ({register, setSearchResults, setWordEntered, wordEntered, iconArray, errors}) => {

    const handleSearch = (icon: string) => {
        const filteredResults: SearchProps[] = iconArray.filter((item) => {
                return item.value.toLowerCase().includes(icon.toLowerCase()) || item.label.toLowerCase().includes(icon.toLowerCase())
            }
        );
        setSearchResults(filteredResults);
    };

    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchWord = event.target.value;
        if (!searchWord.startsWith('fa-')) {
            errors["icon"] = {
                message: 'Por favor, ingrese ícono válido.',
                type: 'required'
            }
        } else {
            delete errors["icon"];
        }
        setWordEntered(searchWord);
        const filteredResults = iconArray.filter((item) => {
                return item.value.toLowerCase().includes(searchWord.toLowerCase()) || item.label.toLowerCase().includes(searchWord.toLowerCase())
            }
        );

        if (searchWord === "") {
            setSearchResults([]);
        } else {
            setSearchResults(filteredResults);
        }
    };

    return(
        <>
            <input
                type="text"
                className="form-control"
                value={wordEntered}
                placeholder="Ej: fa-heartbeat"
                {
                    ...register('icon',{
                        required: 'Ingresar título.',
                        onChange: handleFilter
                    })
                }
            />
            <button
                type="button"
                className="input-group-text border border-1 border-solid border-color-#ced4da m-0"
                onClick={()=>{
                    handleSearch(wordEntered);
                }}>
                <i className="fa fa-search" />
            </button>
        </>
    )
}

export default SearchBar