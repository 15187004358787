import React, { FC, useRef, FormEvent, useEffect, useState } from "react";
import { Button, Card } from "../../../commonComponents";
import { DropdowCmp } from "../../../commonComponents/DropdowCmp";
import { useCencoTalent } from "../../hooks/useCencoTalent";
import { COUNTRIES_DESC } from "../../constants/constants";
import { CountriesTypes } from "../../interfaces/applyInterface";

interface Props {
    className?: string;
    style?: React.CSSProperties;
}

export const SearchCard: FC<Props> = ({ className, style }) => {
    const {
        //Variables
        filterArea,
        filterFlag,
        searchFiltersV2,
        filterSearchInput,
        filterUbication,
        filterworkDay,

        //Funciones
        resetForn,
        searchApply,
        setFilterArea,
        setFilterFlag,
        setFilterSearchInput,
        setFilterUbication,
        setFilterworkDay,
    } = useCencoTalent();

    const formRef = useRef<HTMLFormElement>(null);

    const country = Object.keys(searchFiltersV2 || {})[0] || "CHL";
    const [ubications, setUbications] = useState<string[]>([]);
    const [workType, setWorkType] = useState<string[]>([]);
    const [flags, setFlags] = useState<string[]>([]);

    // * Filtro Ubicaciones
    useEffect(() => {
        // ? Effect para mantener actualizado el filtro de Ubicaciones segun lo que se ha llenado anteriormente
        let ciudades: Set<string> = new Set();

        const traverseUbications = (obj: any) => {
            if (typeof obj !== "object" || obj === null) return;
            for (const key in obj) {
                if (Array.isArray(obj[key])) {
                    obj[key].forEach((city: string) => ciudades.add(city)); // Add the cities
                } else if (typeof obj[key] === "object") {
                    traverseUbications(obj[key]);
                }
            }
        };

        if (country && searchFiltersV2[country]) {
            if (filterArea && searchFiltersV2[country][filterArea]) {
                if (
                    filterFlag &&
                    searchFiltersV2[country][filterArea][filterFlag]
                ) {
                    if (
                        filterworkDay &&
                        searchFiltersV2[country][filterArea][filterFlag][
                            filterworkDay
                        ]
                    ) {
                        searchFiltersV2[country][filterArea][filterFlag][
                            filterworkDay
                        ].forEach((city: string) => ciudades.add(city));
                    } else {
                        for (const workDayKey in searchFiltersV2[country][
                            filterArea
                        ][filterFlag]) {
                            searchFiltersV2[country][filterArea][filterFlag][
                                workDayKey
                            ].forEach((city: string) => ciudades.add(city));
                        }
                    }
                } else if (filterworkDay) {
                    for (const flagKey in searchFiltersV2[country][
                        filterArea
                    ]) {
                        if (
                            searchFiltersV2[country][filterArea][flagKey][
                                filterworkDay
                            ]
                        ) {
                            searchFiltersV2[country][filterArea][flagKey][
                                filterworkDay
                            ].forEach((city: string) => ciudades.add(city));
                        }
                    }
                } else {
                    for (const flagKey in searchFiltersV2[country][
                        filterArea
                    ]) {
                        for (const workDayKey in searchFiltersV2[country][
                            filterArea
                        ][flagKey]) {
                            searchFiltersV2[country][filterArea][flagKey][
                                workDayKey
                            ].forEach((city: string) => ciudades.add(city));
                        }
                    }
                }
            } else if (filterFlag) {
                for (const areaKey in searchFiltersV2[country]) {
                    if (searchFiltersV2[country][areaKey][filterFlag]) {
                        if (
                            filterworkDay &&
                            searchFiltersV2[country][areaKey][filterFlag][
                                filterworkDay
                            ]
                        ) {
                            searchFiltersV2[country][areaKey][filterFlag][
                                filterworkDay
                            ].forEach((city: string) => ciudades.add(city));
                        } else {
                            for (const workDayKey in searchFiltersV2[country][
                                areaKey
                            ][filterFlag]) {
                                searchFiltersV2[country][areaKey][filterFlag][
                                    workDayKey
                                ].forEach((city: string) => ciudades.add(city));
                            }
                        }
                    }
                }
            } else if (filterworkDay) {
                for (const areaKey in searchFiltersV2[country]) {
                    for (const flagKey in searchFiltersV2[country][areaKey]) {
                        if (
                            searchFiltersV2[country][areaKey][flagKey][
                                filterworkDay
                            ]
                        ) {
                            searchFiltersV2[country][areaKey][flagKey][
                                filterworkDay
                            ].forEach((city: string) => ciudades.add(city));
                        }
                    }
                }
            } else {
                traverseUbications(searchFiltersV2[country]);
            }
        } else if (filterFlag) {
            for (const pais in searchFiltersV2) {
                for (const areaKey in searchFiltersV2[pais]) {
                    if (searchFiltersV2[pais][areaKey][filterFlag]) {
                        if (
                            filterworkDay &&
                            searchFiltersV2[pais][areaKey][filterFlag][
                                filterworkDay
                            ]
                        ) {
                            searchFiltersV2[pais][areaKey][filterFlag][
                                filterworkDay
                            ].forEach((city: string) => ciudades.add(city));
                        } else {
                            for (const workDayKey in searchFiltersV2[pais][
                                areaKey
                            ][filterFlag]) {
                                searchFiltersV2[pais][areaKey][filterFlag][
                                    workDayKey
                                ].forEach((city: string) => ciudades.add(city));
                            }
                        }
                    }
                }
            }
        } else if (filterworkDay) {
            for (const pais in searchFiltersV2) {
                for (const areaKey in searchFiltersV2[pais]) {
                    for (const flagKey in searchFiltersV2[pais][areaKey]) {
                        if (
                            searchFiltersV2[pais][areaKey][flagKey][
                                filterworkDay
                            ]
                        ) {
                            searchFiltersV2[pais][areaKey][flagKey][
                                filterworkDay
                            ].forEach((city: string) => ciudades.add(city));
                        }
                    }
                }
            }
        } else {
            traverseUbications(searchFiltersV2);
        }

        setUbications(Array.from(ciudades));
    }, [country, filterArea, filterFlag, filterworkDay, searchFiltersV2]);

    // * Filtro Jornada
    useEffect(() => {
        // ? Effect para mantener actualizado el filtro de Jornadas segun lo que se ha llenado anteriormente
        let horarios: Set<string> = new Set();

        const traverseWorkdays = (obj: any) => {
            if (typeof obj !== "object" || obj === null) return;
            for (const key in obj) {
                if (Array.isArray(obj[key])) {
                    horarios.add(key); // Add the key at this level if it's an array (horario)
                } else if (typeof obj[key] === "object") {
                    traverseWorkdays(obj[key]);
                }
            }
        };

        if (country && searchFiltersV2[country]) {
            if (filterArea && searchFiltersV2[country][filterArea]) {
                if (
                    filterFlag &&
                    searchFiltersV2[country][filterArea][filterFlag]
                ) {
                    traverseWorkdays(
                        searchFiltersV2[country][filterArea][filterFlag]
                    );
                } else {
                    for (const flagKey in searchFiltersV2[country][
                        filterArea
                    ]) {
                        traverseWorkdays(
                            searchFiltersV2[country][filterArea][flagKey]
                        );
                    }
                }
            } else if (filterFlag) {
                for (const areaKey in searchFiltersV2[country]) {
                    if (searchFiltersV2[country][areaKey][filterFlag]) {
                        traverseWorkdays(
                            searchFiltersV2[country][areaKey][filterFlag]
                        );
                    }
                }
            } else {
                for (const areaKey in searchFiltersV2[country]) {
                    for (const flagKey in searchFiltersV2[country][areaKey]) {
                        traverseWorkdays(
                            searchFiltersV2[country][areaKey][flagKey]
                        );
                    }
                }
            }
        } else if (filterFlag) {
            for (const pais in searchFiltersV2) {
                for (const areaKey in searchFiltersV2[pais]) {
                    if (searchFiltersV2[pais][areaKey][filterFlag]) {
                        traverseWorkdays(
                            searchFiltersV2[pais][areaKey][filterFlag]
                        );
                    }
                }
            }
        }

        setWorkType(Array.from(horarios));
    }, [country, filterArea, filterFlag, searchFiltersV2]);

    // * Filtro Bandera
    useEffect(() => {
        // ? Effect para mantener actualizado el filtro de Jornadas segun lo que se ha llenado anteriormente
        let banderas: Set<string> = new Set();

        const traverseFlags = (obj: any) => {
            if (typeof obj !== "object" || obj === null) return;
            for (const key in obj) {
                if (Array.isArray(obj[key])) {
                    continue;
                } else if (
                    typeof obj[key] === "object" &&
                    !Array.isArray(obj[key])
                ) {
                    banderas.add(key);
                }
            }
        };

        if (country && searchFiltersV2[country]) {
            if (filterArea && searchFiltersV2[country][filterArea]) {
                traverseFlags(searchFiltersV2[country][filterArea]);
            } else {
                for (const areaKey in searchFiltersV2[country]) {
                    traverseFlags(searchFiltersV2[country][areaKey]);
                }
            }
        }

        setFlags(Array.from(banderas));
    }, [country, filterArea, searchFiltersV2]);

    // * Aplicar Filtros
    useEffect(() => {
        if (
            filterArea === "" &&
            filterFlag === "" &&
            filterUbication === "" &&
            filterworkDay === ""
        )
            return;

        const formEvent = {
            preventDefault: () => {},
        } as FormEvent<HTMLFormElement>;

        searchApply(formEvent);
    }, [filterArea, filterFlag, filterUbication, filterworkDay]);

    return (
        <>
            <Card className={className} style={style}>
                <h2 className="h4 tit-section">Buscador</h2>
                <form ref={formRef} onSubmit={searchApply}>
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="input-group input-group-white mb-3">
                                <button
                                    className="input-group-text border border-1 border-end-0 m-0"
                                    style={{ borderColor: "#ced4da" }}
                                    type="submit"
                                >
                                    <i className="fa fa-search"></i>
                                </button>
                                <input
                                    value={filterSearchInput}
                                    onChange={(e: any) =>
                                        setFilterSearchInput(e.target.value)
                                    }
                                    type="text"
                                    className="form-control"
                                    style={{ borderRightColor: "#ced4da" }}
                                    placeholder="Jumbo, Tecnico, Mantención, etc."
                                    aria-label="Buscar oferta"
                                />
                            </div>
                        </div>
                    </div>
                    <h2 className="h4 tit-section">Filtrar</h2>
                    <div className="row">
                        <DropdowCmp
                            className="col-12 col-md-4 col-lg-6 col-xl-4 mb-3"
                            label={"País"}
                            callback={() => {}}
                            stateValue={country}
                            valueList={Object.keys(searchFiltersV2 || []).map(
                                (area) => ({
                                    label: COUNTRIES_DESC[
                                        area as CountriesTypes
                                    ],
                                    value: area,
                                })
                            )}
                            tippy={undefined}
                            textTippy={""}
                            placeholder={"Seleccione un País..."}
                            disabled={true}
                            required={false}
                        />

                        <DropdowCmp
                            className="col-12 col-md-4 col-lg-6 col-xl-4 mb-3"
                            label="Unidad de Negocio"
                            callback={(e: string) => setFilterArea(e)}
                            stateValue={filterArea}
                            valueList={Object.keys(
                                searchFiltersV2?.[country] || []
                            ).map((area) => ({
                                label: area,
                                value: area,
                            }))}
                            tippy={undefined}
                            textTippy={""}
                            placeholder={"Seleccione una Area de Negocio..."}
                            required={false}
                        />

                        <DropdowCmp
                            className="col-12 col-md-4 col-lg-6 col-xl-4 mb-3"
                            label="Bandera"
                            callback={(e: string) => setFilterFlag(e)}
                            stateValue={filterFlag}
                            valueList={flags.map((flag) => ({
                                label: flag,
                                value: flag,
                            }))}
                            tippy={undefined}
                            textTippy={""}
                            placeholder={"Seleccione una Bandera..."}
                            required={false}
                        />

                        <DropdowCmp
                            className="col-12 col-md-4 col-lg-6 col-xl-4 mb-3"
                            label="Tipo de Jornada"
                            callback={(e: string) => setFilterworkDay(e)}
                            stateValue={filterworkDay}
                            valueList={workType.map((workType) => ({
                                label: workType,
                                value: workType,
                            }))}
                            tippy={undefined}
                            textTippy={""}
                            placeholder={"Seleccione un Tipo de Jornada..."}
                            required={false}
                        />
                        <DropdowCmp
                            className="col-12 col-md-4 col-lg-6 col-xl-4 mb-3"
                            label="Ciudad"
                            callback={(e: string) => setFilterUbication(e)}
                            stateValue={filterUbication}
                            valueList={ubications.map((ubication) => ({
                                label: ubication,
                                value: ubication,
                            }))}
                            tippy={undefined}
                            textTippy={""}
                            placeholder={"Seleccione una Ciudad..."}
                            required={false}
                        />

                        <div className="col-12 col-md-4 col-lg-6 col-xl-4 mb-3 pt-4 pe-0">
                            <div className="row">
                                <div className="col-12 col-md-6 mb-3 pe-0">
                                    <Button
                                        styleType="secondary"
                                        className="w-100"
                                        onClick={resetForn}
                                    >
                                        Reiniciar
                                    </Button>
                                </div>
                                <div className="col-12 col-md-6 pe-0">
                                    <Button
                                        type="submit"
                                        styleType="primary"
                                        className="w-100"
                                    >
                                        Filtrar
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Card>
        </>
    );
};
