import axios from 'axios';
import Auth from '@aws-amplify/auth';

// eslint-disable-next-line no-undef
const baseUrl = process.env.REACT_APP_BACKEND_URL_GLOBAL;
//const baseUrl = "https://localhost:5001";
const VacacionesService = {

  getTermsBP: (setTerms) => {
    Auth.currentSession().then(auth => {
      const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
      axios.get(baseUrl + "/api/User/TermsBP", { headers: head }).then(response => {
        if (response.data !== null) {
          const responseJSON = response.data;
          setTerms(responseJSON);
        }
      }).catch(function (error) {
        console.log(error.response)
      })
    })
  },
  getSummary: (setData, setFetched, setLoadingSummary) => {
    Auth.currentSession().then(auth => {
      const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
      axios.get(baseUrl + "/api/Employee/SummaryDays", { headers: head }).then(response => {
        if (response.data !== null) {
          const responseJSON = response.data;
          setData(responseJSON);
          setFetched(true)
          setLoadingSummary(false);
        }
        else{
          setLoadingSummary(false);
        }
      }).catch(function (error) {
        setLoadingSummary(false);
        console.log(error.response)
      })
    })
  },
  getSummaryCard: (setData, setFetched, setLoadingCard, setAbsence, absence, setSinAprobador, setDisabled) => {
    Auth.currentSession().then(auth => {
      const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
      axios.get(baseUrl + "/api/Employee/SummaryDays", { headers: head }).then(response => {
        if (response.data !== null) {
          const responseJSON = response.data;
          setData(responseJSON);
          setFetched(true)
          setLoadingCard(false);
          setAbsence({ ...absence, tieneAdvanceDate: !responseJSON.advanceDate ? false : true, advanceDate: !responseJSON.advanceDate ? null : responseJSON.advanceDate });
          setDisabled(!responseJSON.aproverName ? true : false)
          setSinAprobador(!responseJSON.aproverName ? true : false);
        }else{
          setLoadingCard(false);
        }
      }).catch(function (error) {
        setLoadingCard(false);
        console.log(error.response)
      })
    })
  },
  getAbsenceList: (setLoading, setFetched, setAbsenceList, setNoData) => {
    setLoading(true);
    Auth.currentSession().then(auth => {
      const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
      axios.get(baseUrl + "/api/Employee/AbsencesList", { headers: head }).then(response => {
        if (response.data !== null) {
          setFetched(true);
          console.log(response.data);
          const responseJSON = response.data;
          setAbsenceList(responseJSON.sort((a, b) => a.beginDate < b.beginDate ? 1 : a.beginDate > b.beginDate ? -1 : 0))
          //setAbsenceList(responseJSON.filter(s => !estado || s.state === estado))
          setLoading(false);
        } else {
          setNoData(true);
          setLoading(false);
        }

      })
    }).catch(function (error) {
      console.log(error.response);
      setNoData(true)
      setLoading(false);
    })
  },
  postSolicitud: async (applicationData, setAppSent, setLoadingSave, setErrorFlag, setErrorMessage) => {
    setLoadingSave(true)
    const url = baseUrl + '/api/Employee/AbsencesApplication';
    Auth.currentSession().then(auth => {
      const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
      const body = JSON.stringify(applicationData);
      axios({
        method: 'post',
        url: url,
        headers: head,
        data: body
      }).then(response => {
        if (response.status === 200) {
          setLoadingSave(false);
          setAppSent(true);
        } else {
          setLoadingSave(false);
          setErrorFlag(true);
        }
      }).catch(function (error) {
        setErrorMessage(error.response.data ? error.response.data.descripcion : error.response.data.descripcion)
        setLoadingSave(false);
        setErrorFlag(true)

      })
    })
  },
  UpdateTable: (estado, setAbsenceList, setFetched, setLoading) => {
    setLoading(true);
    Auth.currentSession().then(auth => {
      const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
      axios.get(baseUrl + "/api/Employee/AbsencesList", { headers: head }).then(response => {
        if (response.data !== null) {
          setFetched(true)
          const responseJSON = response.data;
          setAbsenceList(responseJSON.filter(x => x.state === estado))
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
    }).catch(function (error) {
      console.log(error.response);
      setLoading(false);
    })
  }
}
export default VacacionesService;