import React, { FC } from 'react';
import { ErrorScreenType } from './types';
import errorMessages from './error-messages';

const ErrorScreen: FC<ErrorScreenType> = ({etype, title, message, retryCallback, retryText, backCallback, backText}) => {
    const getTitle = title ? title : etype ? errorMessages[etype].title : 'Error';
    const getMessage = message ? message : etype ? errorMessages[etype].message : 'Ha ocurrido un error inesperado';
    const Icon = etype ? errorMessages[etype].icon : errorMessages.internalServerError.icon;

    return (
        <div className="min-vh-100 text-center mt-5">
            <Icon />
            <h2 className="mt-2">{ title ?  title  : getTitle }</h2>
            <h5>{ message ? message : getMessage }</h5>
            <div className="mt-3">
                {backCallback && <button className="btn btn-primary btn-lg mx-4" onClick={backCallback}>{backText || 'Volver'}</button>}
                {retryCallback && <button className="btn btn-degradado btn-lg mx-4" onClick={retryCallback}>{retryText || 'Reintentar'}</button>}
            </div>
        </div>
    );
}

export default ErrorScreen;
