import Auth from "@aws-amplify/auth";
import { readerTaskEither as RTE } from "fp-ts";
import * as t from "io-ts";
import { urlsAws } from "../../../../../resources/foo/api-endpoints";
import { HttpRequestError, HttpContentTypeError, HttpResponseStatusError, HttpClientEnv, FetchGetProps, fetchGetCodec, FetchPostProps, fetchPostCodec, FormValues } from "../interfaces";
import { getJson } from "./httpClient";

export const get = async (params: string) => {
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: "GET",
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
    };
    const response: RTE.ReaderTaskEither<
            HttpClientEnv,
            HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
            FetchGetProps
        > = getJson(urlsAws.getFichaSic + "?emplid=", params, requestOptions, fetchGetCodec.decode)
    return response;
};


export const post = async (body: FormValues) => {
    const params = "";
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
        body: JSON.stringify(body)
    };
    const response: RTE.ReaderTaskEither<
            HttpClientEnv,
            HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
            FetchPostProps
        > = getJson(urlsAws.setFichaSic, params, requestOptions, fetchPostCodec.decode)
    return response;
}