import axios from "axios";
import Auth from '@aws-amplify/auth';
import moment from 'moment/moment.js';



const baseUrl = process.env.REACT_APP_BACKEND_URL_GLOBAL;

const convertedFile = (archivo) => {
  let byteChar = atob(archivo);
  let byteArray = new Uint8Array(new ArrayBuffer(byteChar.length));
  for (let i = 0; i < byteChar.length; i++) {
    byteArray[i] = byteChar.charCodeAt(i);
  }
  return byteArray;
}

const RecibosArgService = {
  ObtenerRecibos: async (setFetched, setLoading, setData, setNoData,setDocsDisplay, setServerError) => {
    try {
      Auth.currentSession().then(auth => {
        const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
        axios.get(baseUrl + '/api/Employee/Documents', { headers: head }).then(response => {
          if (response.data !== null) {
            let responseJSON = response.data;
            responseJSON = responseJSON.map(item => {
              return {
                ...item,
                loading: false
              }
            });
            const sorted = responseJSON.sort((a,b) => moment(b.period, "MM-YYYY").unix() - moment(a.period, "MM-YYYY").unix())
            setData(sorted)
            setDocsDisplay(responseJSON.length)
            setFetched(true)
            setLoading(false);
            setServerError(null);
            console.log(responseJSON);
          } 
    
          else {
            setNoData(true);
            setLoading(false);
            setServerError(null);
          }
        }).catch(function (error) {
          if(error.status === 500)
          setNoData(true);
          setLoading(false);
          //setServerError(error.response?.data?.message ? error.response?.data?.message : error.response?.data);
          setServerError("El servidor ha detectado un error, inténtelo de nuevo mas tarde")
        })
      })
    } catch (error) {
      console.log(error)
    }
  },

  ObtenerReciboId: async (element,index,setOpenFile,setCanSign,setLoadingIndices,setIsReceipt) => {
    //const bsUrl = "http://autoservicio-bff-arg.us-east-1.elasticbeanstalk.com";

    try {
      Auth.currentSession().then(auth => {
        const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
        axios.get(baseUrl + `/api/Employee/document/${element.id}?company=${element.company}`, { headers: head }).then(response => {
          if (response.data !== null) {
            const file = new Blob([convertedFile(response.data)], { type:"application/pdf"});
            const url = window.URL.createObjectURL(file);
            setCanSign(element.canSign? true : false);
            setIsReceipt(element.isReceipt ? true : false);
            setOpenFile({ active: true, data: url, id: element.id, company: element.company})
            setLoadingIndices((prev) => {
              const updated = new Set(prev);
              updated.delete(index);
              return updated;
            });

          } else {
           console.log("error")
          }
        }).catch(function (error) {
          console.log(error)
        })
      })
    } catch (error) {
      console.log(error)
    }
  },

  downloadDoc: async (documentId, name, company) => {
    //const bsUrl = "http://autoservicio-bff-arg.us-east-1.elasticbeanstalk.com";
    try {
      Auth.currentSession().then(auth => {
        const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
        axios.get( baseUrl + `/api/Employee/document/${documentId}?company=${company}`, { headers: head }).then(response => {
          if (response.data !== null) {
            const file = new Blob([convertedFile(response.data)], { type:"application/pdf"});
            const url = window.URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `${name}`,
    );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
           console.log("error")
          }
        }).catch(function (error) {
          console.log(error)
        })
      })
    } catch (error) {
      console.log(error)
    }
  },
   enviarSignature: async (documentId,setLoadingSave,fd,setSignatureSent,setError,setErrorMessage) => {
    setLoadingSave(true);
    // const bsUrl = "http://autoservicio-bff-arg.us-east-1.elasticbeanstalk.com";
    const url = baseUrl + `/api/Employee/document/${documentId}/Sign`;
    Auth.currentSession().then(auth => {
      const head = { 'Content-Type': 'application/json','AccessToken': auth.getAccessToken().getJwtToken() }
      const body = fd;
      axios({
        method: 'post',
        url: url,
        headers: head,
        data: body
      }).then(response => {
        if (response.status === 200) {
          setLoadingSave(false);
          setSignatureSent(true);
        }else {
          setLoadingSave(false);
          setError(true);
        }
      }).catch(function (error) {
        setErrorMessage(error.response.data.message ? error.response.data.message : error.response.data)
        setLoadingSave(false);
        setError(true)
      })
    })
  }

}

export default RecibosArgService;