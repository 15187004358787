import React, { FC, useEffect, useRef } from 'react';
import useFormProcess from '../hooks/useFormProcess';
import { BreadCrumbsDinamic } from '../../commonComponents/BreadCrumbsDinamic';
import { useForm } from 'react-hook-form';
import { urlsAws } from '../../../resources/foo/api-endpoints';
import { ComplianceForm, IAnswers, IForm } from '../interfaces';	
import Loading from '../../commonComponents/ModalLoad';
import Status from '../../commonComponents/ModalnfoDinamic';
import { Button} from '../../commonComponents/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { PSValidationGET, PSValidationPOST, PSResponseModifier } from '../utils';

const TaskManager:FC = () => {
    const { state, search, pathname } = useLocation();
    const navigate = useNavigate();
    const parameters = state?.parameters || search.split('?')[1] || '';
    const title = state?.title || '';
    const {
        initialData,
        initialLoading,
        initialError,
        submitData,
        submitLoading,
        submitError,
        handleSubmit
    } = useFormProcess({
        initialUrl: urlsAws.ethicsManager + '?' + parameters,
        submitUrl: urlsAws.ethicsManager,
        initialMethod: 'GET',
        submitMethod: 'POST',
        initialValidation: PSValidationGET,
        submitValidation: PSValidationPOST,
        initialResponseModifier: PSResponseModifier,
        submitResponseModifier: PSResponseModifier
    });
    const openLoadingModal = useRef<HTMLButtonElement>(null);
    const closeLoadingModal = useRef<HTMLButtonElement>(null);
    const openStatusModal = useRef<HTMLButtonElement>(null);
    const closeStatusModal = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (initialLoading || submitLoading) openLoadingModal.current && openLoadingModal.current.click();
        else closeLoadingModal.current && closeLoadingModal.current.click();
    }, [initialLoading, submitLoading]);

    useEffect(() => {
        if(initialError.message || submitData || submitError.message) openStatusModal.current && openStatusModal.current.click();
    }, [initialError.message, submitError.message, submitData]);

    const name = initialData?.subName || '';
    return <>
        <BreadCrumbsDinamic paths={[{label: 'Codigo de Etica', url: '/codigoetica'}, {label: title, url: '/codigoetica/manager'}]} />
        {initialData && <section>
            <h1>Conflicto de Interés</h1>
            <div className="box mb-4">
                <h2 className="h4 tit-section">Formulario</h2>
                <p>A continuación favor de llenar el siguiente formulario por declaración de <strong>Conflicto de interés</strong>:</p>
                <div className="row">
                    <div className="col-12 col-md-4 mb-3">
                        <label className="form-label">Empleado</label>
                        <input type="text" className="form-control" defaultValue={name} disabled/>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <label className="form-label">Fecha Solicitud</label>
                        <input className="form-control" type="date" defaultValue={initialData.createdAt} disabled />
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <label className="form-label">Tipo de Conflicto</label>
                        <input type="text" className="form-control" defaultValue={initialData.conflictType} disabled />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <label className="form-label">Observaciones</label>
                        <textarea className="form-control" defaultValue={initialData.description} rows={4} readOnly />
                    </div>
                </div>
                <h3 className="h4 mt-4">Detalle</h3>
                <Form initialData={initialData} handleSubmit={handleSubmit} parameters={parameters} />
            </div>
        </section>}
        <Loading refOpen={openLoadingModal} refClose={closeLoadingModal} />
        <Status 
            refOpen={openStatusModal} 
            refClose={closeStatusModal}
            text={(initialError.message || submitError.message) ? 'Error' : 'Éxito'} 
            icon={(initialError.message || submitError.message) ? 'fa-exclamation-circle' : 'fa-check-circle-o'}
            subtext={
                initialError.message ? (
                    initialError.isGeneric ? 'No hemos logrado encontrar ningún formulario de preguntas pendientes que haya sido enviado por Compliance.' : initialError.message
                ) : 
                submitError.message ? (
                    submitError.isGeneric ? 'Ha ocurrido un error al enviar el formulario, por favor intente de nuevo.' : submitError.message
                ) : 
                'El formulario ha sido enviado correctamente.'
            }
            callBack={() => { 
                closeStatusModal.current && closeStatusModal.current.click(); 
                if(initialError.message || submitData) navigate('/inicio');
            }}
        />
    </>
}

const Form:FC<IForm> = ({ initialData, handleSubmit, parameters }) => {
    const { register, handleSubmit:formSubmit,formState:{errors} } = useForm();

    const onSubmit = (data:any) => {
        const conflictId = parameters.split('&')[0].split('=')[1] || '';
        const request = {
            request : {
                conflictId: conflictId,
                subEmplid: initialData.subEmplid,
                answers: [] as any[]
            }
        }
        initialData.form.forEach((item:IAnswers, index:number) => {
            request.request.answers.push({
                order: item.order,
                answer: data[`answer-option-${index}`],
                comment: data[`answer-comment-${index}`] || null,
            });
        });
        
        handleSubmit(request);
    };

    return (
        <>
            <form onSubmit={formSubmit(onSubmit)}>
                {
                    initialData.form.map((item:ComplianceForm, index: number) => {
                        return <div key={index}>
                            <div className="d-flex row p-3">
                                <label className="form-label col-12 p-0">{item.question}</label>
                                {
                                     item.options && item.options.map((option: string, indexValues: number) => {
                                        return (
                                            <div key={indexValues}>
                                                <div className="form-check col-12 col-md-2 align-items-center">
                                                    <input className="form-check-input" type="radio" value={option} defaultChecked={false}
                                                    {...register("answer-option-"+index, { required: true, value: option })}
                                                    />
                                                    <label className="form-check-label">{option}</label>
                                                </div>
                                                {errors["answer-option-"+index] && <span className="pt-2 d-block text-danger">*Debe seleccionar una opción</span>}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            {item.hasComment && <div className="px-1">
                                <div className="d-flex flex-column fw-bold mb-3">
                                    <p className="mb-2">Comentar</p>
                                    <textarea className="form-control" {...register("answer-comment-"+index, { required: true, maxLength: 5000 })} maxLength={5000} />
                                </div>
                                {errors["answer-comment-"+index] && <span className="pt-2 d-block text-danger">*Debe ingresar un comentario</span>}
                            </div>}
                        </div>;
                    })
                }
                <div className="d-flex justify-content-end pe-3">
                    <Button type="submit" styleType="primary">Enviar</Button>
                </div>
            </form>
        </>
    );
}

export default TaskManager;