import { useTeamDashboardProvider } from "../../context/TeamDashboardContext";
import { Accordion, AccordionItem } from "../../../../components/accordion";
import { useTableProvider } from "../../context/DashboardTableContext";
import { DependenceCell } from "../table/DependenceCell";
import { useEffect, useMemo } from "react";
import { NameCell } from "../table";

import {
  ColumnHelper,
  ColumnsConfig,
  Table,
} from "../../../../components/table";

import {
  PerformanceEvaluationApi,
  PerformanceEvaluationResponse,
} from "../../hooks";

export const PerformanceEvaluation = () => {
  const { tableFetchInfo, setDataTableToExport, idSubLeader } =
    useTableProvider();
  const { getSelectedMenu } = useTeamDashboardProvider();
  const dataInfo = tableFetchInfo.find(
    (fetch) => fetch.key === getSelectedMenu?.name
  );

  const columnsConfig: ColumnsConfig[] = [
    { accessor: "name", header: "Nombre", className: "col-4" },
    { accessor: "position", header: "Cargo" },
    { accessor: "assessment", header: "Evaluación" },
    { accessor: "description", header: "Detalle" },
    { accessor: "last_date", header: "Fecha Último Ajuste" },
    {
      accessor: "dependence",
      header: "Dependencia",
      className: "text-center",
      enabledSort: false,
    },
  ];

  const toRenderList = useMemo(() => {
    if (dataInfo) {
      const { subDatable, dataTable } = dataInfo.response;
      return idSubLeader
        ? (subDatable as PerformanceEvaluationResponse)
        : (dataTable as PerformanceEvaluationResponse);
    }

    return [];
  }, [dataInfo, idSubLeader]);

  useEffect(() => {
    dataInfo?.response && buildExport();
  }, [dataInfo]);

  const buildExport = () => {
    setDataTableToExport({
      header: columnsConfig.map((value) => ({
        label: value.header,
        value: value.accessor,
        hidden: false,
      })),
      rows: toRenderList,
      filename: getSelectedMenu?.filenameExport || "",
    });
  };

  const buildDataTable = useMemo(() => {
    return toRenderList.map((employee) => ({
      ...employee,
      name: (
        <NameCell
          name={employee.name}
          lastName={employee.last_name}
          photo={employee.photo}
        />
      ),
      dependence: (
        <DependenceCell
          document={employee.nro_document}
          hasSubordinates={employee.hasSubordinates}
        />
      ),
    }));
  }, [dataInfo?.response]);

  const buildaccordionList = () => {
    return toRenderList.map((item) => ({
      headerLabel: `${item.name} ${item.last_name}`,
      items: columnsConfig.map((config) => ({
        label: config.header,
        value:
          config.accessor === "name"
            ? `${item.name} ${item.last_name}`
            : item[config.accessor as keyof PerformanceEvaluationApi],
      })),
    })) as AccordionItem[];
  };

  return (
    <>
      <Table
        data={buildDataTable as ColumnHelper[]}
        columnsConfig={columnsConfig}
        isLoading={dataInfo?.response.isLoading}
        responsiveClass="d-xs-none"
      />

      <Accordion itemList={buildaccordionList()} className="d-sm-none" />
    </>
  );
};
