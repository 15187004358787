import { either as E, taskEither as TE, readerTaskEither as RTE } from "fp-ts";
import { pipe } from "fp-ts/function";
import { HttpRequestError, HttpContentTypeError, HttpResponseStatusError, HttpClient, HttpClientEnv, HttpError } from "../interfaces";

export const fetchHttpClient: HttpClient = {
    request: (input, init) =>
        TE.tryCatch(
            () => {
                return fetch(input, init)
            },
            (e: unknown) => ({
                tag: 'httpRequestError',
                error: e,
            }),
        ),
};

export const httpClientEnv: HttpClientEnv = { httpClient: fetchHttpClient };

export const request = (input: RequestInfo, init?: RequestInit): RTE.ReaderTaskEither<HttpClientEnv, HttpRequestError, Response> =>
    pipe(
        RTE.asks<HttpClientEnv, HttpClient, never>(
            (env: HttpClientEnv) => env.httpClient,
        ),
        RTE.chainTaskEitherKW((httpClient: HttpClient) => 
            httpClient.request(input, init),
        ),
    )

// Helper for extracting `json` response into `unknown` for decoding
export const toJson = (response: Response): TE.TaskEither<HttpContentTypeError, unknown> =>
    TE.tryCatch(
        () => response.json(),
        (e: unknown) => ({ tag: 'httpContentTypeError', error: e }),
    )
  
// Helper for validating response status
export const ensureStatus = (min: number, max: number) => (response: Response): E.Either<HttpResponseStatusError, Response> =>
    min <= response.status && response.status < max
        ? E.right(response)
        : E.left({ tag: 'httpResponseStatusError', status: response.status })
  
// "High-level" helper for issuing a simple GET to a JSON endpoint
export const getJson = <A, DecodeError>(url: string, params: string, init: RequestInit, decode: (raw: unknown) => E.Either<DecodeError, A>): RTE.ReaderTaskEither<
    HttpClientEnv,
    | HttpRequestError
    | HttpContentTypeError
    | HttpResponseStatusError
    | DecodeError,
    A
  > =>
    pipe(
        request(url + params, init),
        RTE.chainEitherKW(ensureStatus(200, 300)), // ensureStatus operates on Either, so lift it (and widen error type) with chainEitherKW
        RTE.chainTaskEitherKW(toJson), // toJson operates on TaskEither, so lift into RTE (with widening)
        RTE.chainEitherKW(decode), // decode operates on Either... same deal
    )    