import Swal from 'sweetalert2';

export const handleStatusError = () => {
    Swal.fire({
        allowOutsideClick: false,
        position: 'center',
        title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
        html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo.</div>`,
        icon: 'error',
        confirmButtonColor: '#0169b5',
    });
};

export const handleStatusSuccess = () => {
    Swal.fire({
        allowOutsideClick: false,
        position: 'center',
        title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
        html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
        icon: 'success', 
        confirmButtonColor: '#0169b5'
    });
};

export const handleErrorFile = () => {
    Swal.fire({
        allowOutsideClick: false,
        position: 'center',
        title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia</div>',
        html: `<div style="text-align: center; font-size: 15px"> El archivo seleccionado es incorrecto. Solo se permiten archivos en formato SVG.</div>`,
        icon: 'warning', 
        confirmButtonColor: '#0169b5'
    });
};

export const handleDeleteConfirmation = (onConfirm: () => void) => {
    Swal.fire({
        allowOutsideClick: false,
        position: 'center',
        title: '<div style="text-align: center; color: orange; font-size: 16px">Advertencia</div>',
        html: '<div style="text-align: center; font-size: 15px">¿Estás seguro de que deseas eliminar este elemento? Esta acción no se puede deshacer.</div>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar'
    }).then((result) => {
        if (result.isConfirmed) {
            onConfirm();
            Swal.fire({
                title: 'Eliminado!',
                text: 'El elemento ha sido eliminado.',
                icon: 'success',
                confirmButtonColor: '#3085d6'
            });
        }
    });
};