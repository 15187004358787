import { FC, ReactElement, useContext } from "react";
import { Context } from "../../../context";
import { BreadCrumbsDinamic } from "../../commonComponents/BreadCrumbsDinamic";
import HomeCL from "../components/HomeCL";
import HomeAR from "../components/HomeAR";
import HomeCO from "../components/HomeCO";
import HomeUY from "../components/HomeUY";
import HomePE from "../components/HomePE";
import { TypeWithKey } from "../../../utils";

const Home: FC = () => {
  const { pais } = useContext<any>(Context);

  const countryComponents: TypeWithKey<ReactElement> = {
    CL: <HomeCL />,
    AR: <HomeAR />,
    CO: <HomeCO />,
    UY: <HomeUY />,
    PE: <HomePE />,
  };

  const selectedComponent = countryComponents[pais] || null;

  return (
    <>
      <BreadCrumbsDinamic />
      <div className="row ps-3">{selectedComponent}</div>
    </>
  );
};

export default Home;
