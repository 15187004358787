import React, { FC } from "react";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Context } from "../../context";
import { findMenuItemsByPath } from "./Helpers/findMenuItemsByPath";

type Props = {
  menuItemWithParentsParam?: any;
  paths?: {
    label: string;
    url: string;
  }[];
};

export const BreadCrumbsDinamic : FC<Props> = ({ menuItemWithParentsParam = null, paths }: Props) => {
  let menuItemWithParents = [];

  const { menuItems } = useContext(Context);
  const path = useLocation().pathname;
  menuItemWithParentsParam
    ? (menuItemWithParents = menuItemWithParentsParam)
    : (menuItemWithParents = findMenuItemsByPath(menuItems, path));

  return (
    <nav className="breadcrumbs" aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/inicio">
            <i
              style={{ pointerEvents: "none" }}
              className="fa fa-home color-orange"
              aria-hidden="true"
            ></i>{" "}
            Inicio
          </Link>
        </li>
        {paths ? (
          <>
            {paths.map((path, index) => (
              <li key={index} className={`breadcrumb-item ${paths.length - 1 === index ? 'active' : ''}`}>
                {paths.length - 1 === index ? path.label : <Link to={path.url}>{path.label}</Link>}
              </li>
            ))}
          </>
        ) : (
        menuItemWithParents && menuItemWithParents.map((menuItem : any, index : number) => {
            if (menuItemWithParents.length - 1 === index) {
              return (
                <li
                  key={menuItem.id}
                  className="breadcrumb-item active"
                  aria-current="page"
                >
                  {menuItem.label}
                </li>
              );
            }
            <li className="breadcrumb-item">
              <Link key={menuItem.id} to={menuItem.url}>
                <i
                  style={{ pointerEvents: "none" }}
                  className="fa fa-home color-orange"
                  aria-hidden="true"
                ></i>{" "}
                {menuItem.label}
              </Link>
            </li>;
          }
        ))}
      </ol>
    </nav>
  );
};
