import React, { useContext, useRef, useState } from "react";
import AdminCategory from "./adminCategory/adminCategory";
import NewNews from "./newNews/newNews";
import { BreadCrumbsDinamic } from "../../../commonComponents/BreadCrumbsDinamic";
import IndexNews from "./indexNews/indexNews";
import { Context } from "../../../../context";

const NewsManager = () => {
    const [newNews, setNewNews] = useState(false);
    const [showTable, setShowTable] = useState(true);
    const [isEditNews, setIsEditNews] = useState(false);
    const [adminCategory, setAdminCategory] = useState(false);
    const [isSubtitleVisible, setIsSubtitleVisible] = useState(false);
    const [idNews, setIdNews] = useState(0);
    const [reload, setReload] = useState(false);
    const refLoad = useRef<HTMLButtonElement>(null);
    const refLoadClose = useRef<HTMLButtonElement>(null);
    const refStatus = useRef<HTMLButtonElement>(null);
    const refConfirmAction = useRef<HTMLButtonElement>(null);
    const { pais } = useContext(Context);

    return (
        <>
            <main className="wrapper d-flex">
                <div className="w-100 p-0" id="content">
                    <BreadCrumbsDinamic />
                    {showTable &&
                        <IndexNews
                            setAdminCategory={setAdminCategory}
                            setNewNews={setNewNews}
                            setShowTable={setShowTable}
                            setIsEditNews={setIsEditNews}
                            setIdNews={setIdNews}
                            idNews={idNews}
                            reload={reload}
                            setReload={setReload}
                            isSubtitleVisible={isSubtitleVisible}
                            setIsSubtitleVisible={setIsSubtitleVisible}
                            refConfirmAction={refConfirmAction}
                            refStatus={refStatus}
                            pais={pais}
                            refLoad={refLoad}
                            refLoadClose={refLoadClose}
                        />
                    }

                    {(newNews || isEditNews) && (
                        <NewNews
                            isEditNews={isEditNews}
                            idNews={idNews}
                            refLoad={refLoad}
                            refLoadClose={refLoadClose}
                            refConfirmAction={refConfirmAction}
                            refStatus={refStatus}
                            setNewNews={setNewNews}
                            setShowTable={setShowTable}
                            setIsEditNews={setIsEditNews}
                            setIdNews={setIdNews}
                            pais={pais}
                            isSubtitleVisible={isSubtitleVisible}
                            setIsSubtitleVisible={setIsSubtitleVisible}
                        />
                    )}

                    {adminCategory &&
                        <AdminCategory
                            setAdminCategory={setAdminCategory}
                            setShowTable={setShowTable}
                            refLoad={refLoad}
                            refLoadClose={refLoadClose}
                            refConfirmAction={refConfirmAction}
                            refStatus={refStatus}
                            pais={pais}
                        />
                    }
                </div>
            </main>
        </>
    )
}
export default NewsManager;