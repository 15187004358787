import { Auth } from "aws-amplify";
import axios from "axios";

const url = process.env.REACT_APP_BACKEND_URL_GLOBAL;

const getHeader = () => {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(async (auth) => {
          const header = {
            "Content-Type": "application/json",
            accesstoken: auth.getAccessToken().getJwtToken(),
          };
          resolve(header);
        })
        .catch(function (error) {
          return reject(error);
        });
    });
  };

const ContactosEmergenciaFamiliaresServices = {
    Getcontactsemergency: async (setInfoContactsEmergency, setLoadingInfoContactsEmergency, setNoDataInfoContactsEmergency) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(url + '/api/datospersonales/contactsemergency', { headers: head })
                setInfoContactsEmergency(data)
                setLoadingInfoContactsEmergency(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingInfoContactsEmergency(false);
                setNoDataInfoContactsEmergency(true);
            })
        } catch (error) {
            console.log(error)
        }
    },
    
    Getcontactemergency: async (id,setInfo) => {

       var i = {};

        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                    const { data } = await axios.get(`${url}/api/datospersonales/contactemergency/${id}`, {
                    headers: head
                })
                setInfo(data.CN_COL_CNT_EMERG_GET.CN_COL_CNT_EMERG)
                
            }).catch(function (error) {
                console.log(error);
               
            }).finally(()=>{ 
                
            })
        } catch (error) {
            console.log(error)
        }
        
    },
    Addcontactemergency: async (body) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                await axios.post(url + '/api/datospersonales/contactemergency/', body, { headers: head })
                
            }).catch(function (error) {
                console.log(error.response);
            })
        } catch (error) {
            console.log(error)
        }
    },
    Deletecontactemergency: async (id) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                await axios.delete(`${url}/api/datospersonales/contactemergency/${id}`, {
                     headers: head })
            }).catch(function (error) {
                console.log(error);
            })
        } catch (error) {
            console.log(error)
        }
    },
    Editcontactemergency: async (id, body) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                await axios.put(`${url}/api/datospersonales/contactemergency/${id}`, body, { headers: head })
               
            }).catch(function (error) {
                console.log(error);
                
            })
        } catch (error) {
            console.log(error)
        }
    },
    Getcontactsfamily: async (setInfoContactsFamily, setLoadingInfoContactsFamily, setNoDataInfoContactsFamily) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(url + '/api/datospersonales/contactsfamily', { headers: head })
                setInfoContactsFamily(data)
                setLoadingInfoContactsFamily(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingInfoContactsFamily(false);
                setNoDataInfoContactsFamily(true);
            })
        } catch (error) {
            console.log(error)
        }
    },
    Getcontactfamily: async (id,setInfo) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(`${url}/api/datospersonales/contactfamily/${id}`, {
                    headers: head
                })
                setInfo(data.CN_COL_FLIA_GET.CN_COL_FLIA_GET1)
            }).catch(function (error) {
                console.log(error);
            })
        } catch (error) {
            console.log(error)
        }
    },
    Addcontactfamily: async (body) => {
        try {
            const header = await getHeader();
          const data = await axios({
            method: "post",
            url: `${url}/api/datospersonales/contactfamily`,
            data: body,
            headers: {
              "Content-Type": "application/json",
              accesstoken: header.accesstoken,
            },
          });
    
          return data;
        } catch (error) {
          return error?.response?.data;
        }
      },
    Deletecontactfamily: async (id) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                await axios.delete(`${url}/api/datospersonales/contactfamily/${id}`, {
                    headers: head })
                
            }).catch(function (error) {
                console.log(error);
                
            })
        } catch (error) {
            console.log(error)
        }
    },
    Editcontactfamily: async (id, body) => {
        try {
            const header = await getHeader();
          const data = await axios({
            method: "put",
            url: `${url}/api/datospersonales/contactfamily/${id}`,
            data: body,
            headers: {
              "Content-Type": "application/json",
              accesstoken: header.accesstoken,
            },
          });
          return data;
        } catch (error) {
          return error?.response?.data;
        }
      },
    GetGenre: async (setGenre) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(url + '/api/datospersonales/getSexo', { headers: head })
                setGenre(data.CN_COL_XLAT.PSXLATITEM)
                
            }).catch(function (error) {
                console.log(error);
                
            })
        } catch (error) {
            console.log(error)
        }
    },
    GetMarStatus: async (setMarStatus) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(url + '/api/datospersonales/getMARStatus', { headers: head })
                setMarStatus(data.CN_COL_XLAT.PSXLATITEM)
                
            }).catch(function (error) {
                console.log(error);
               
            })
        } catch (error) {
            console.log(error)
        }
    },
    GetParentesco: async (setParentesco) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(url + '/api/datospersonales/getrelationship', { headers: head })
                setParentesco(data.CN_COL_XLAT.PSXLATITEM)
            }).catch(function (error) {
                console.log(error);
            })
        } catch (error) {
            console.log(error)
        }
    },
    GetProfession: async (setProfession) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(url + '/api/rrhhbase/professions', { headers: head })
                setProfession(data)
            }).catch(function (error) {
                console.log(error);
            })
        } catch (error) {
            console.log(error)
        }
    },
    GetOcupation: async (setOcupation) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(url + '/api/datospersonales/getocupacion', { headers: head })
                setOcupation(data.CN_COL_XLAT.PSXLATITEM)
            }).catch(function (error) {
                console.log(error);
            })
        } catch (error) {
            console.log(error)
        }
    },
    GetEducationLevel: async (setEducation) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(url + '/api/datospersonales/getEducacion', { headers: head })
                setEducation(data.CN_COL_XLAT.PSXLATITEM)
            }).catch(function (error) {
                console.log(error);
            })
        } catch (error) {
            console.log(error)
        }
    },
}

export default ContactosEmergenciaFamiliaresServices;