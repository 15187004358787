// orgChartTree.tsx
import React, { useEffect, useState } from 'react';
import styles from './organigrama-ss.module.css';
// import { data } from './data';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { ReactComponent as IcoChart } from '../../resources/images/organigrama/ico_chart.svg';
import { ReactComponent as IcoList } from '../../resources/images/organigrama/ico_list.svg';

import { Link } from 'react-router-dom';
import UserModal from './user-modal';
import AreaModal from './area-modal';
import Tippy from '@tippyjs/react';
import OrganigramaService from '../../services/organigrama/organigrama';
import { Auth } from "aws-amplify";
import HierarchyTree from './HierarchyTree';
import List from './List';

const OrgChartTree = ({ employee }: any) => {

    const [selectedPerson, setSelectedPerson] = useState<any>(null);
    const [loadingChart, setLoadingChart] = useState<boolean>(true);
    const [hierarchyTree, setHierarchyTree] = useState<any>([]);
    const [emplid, setEmplid] = useState<any>(null);
    const [datePerson, setDatePerson] = useState<any>(null);
    const [area, setArea] = useState<any>(null);
    const [selectedComponent, setSelectedComponent] = useState<string>('HierarchyTree');

    useEffect(() => {
        if (employee) {
            setEmplid(employee);
        } else {
            Auth.currentUserInfo().then(user => {
                if (user.attributes['custom:emplid'] != undefined) {
                    setEmplid(user.attributes['custom:emplid'])
                }
            }).catch(err => console.log(err))
        }
    }, []);

    useEffect(() => {
        if (emplid) {
            getTree(emplid)
        }
    }, [emplid])

    useEffect(() => {
        const body = document.body;

        if (!body.classList.contains("shrink")) {
            body.classList.add("shrink");
        }

        return () => {
            body.classList.remove("shrink");
        };
    }, []);

    const getTree = async (id: number) => {
        setLoadingChart(true);
        const response = await OrganigramaService.getTree(id.toString());
        if (!Array.isArray(response)) {
            setHierarchyTree([response]);
        } else {
            setHierarchyTree(response);
        }
        setDatePerson(response.BaseLevel?.person)
        setLoadingChart(false)
    }
    const handleInfoIconClick = (person: any) => {
        setSelectedPerson(person);
    };

    const handleModalClose = () => {
        setSelectedPerson(null);

        setArea(null)
    };

    const handleAreaClick = (dni: any) => {
        setArea(dni)
    }

    const handleSelectPersonClick = (person: any) => {
        const dni = person.nro_document
        setEmplid(dni)
    }

    const componentMap: { [key: string]: JSX.Element } = {
        HierarchyTree: (
        <HierarchyTree employee={hierarchyTree} onInfoClick={handleInfoIconClick} onSelectPersonClick={handleSelectPersonClick}/>),
        List: (
            <List employee={hierarchyTree} onSelectPersonClick={handleSelectPersonClick} />
        )
    };

    return (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home color-orange" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active prueba" aria-current="page">
                        Organigrama
                    </li>
                </ol>
            </nav>
            <section className="noticias m-0">
                <div className="header-page-container">
                    <h1>Organigrama</h1>
                </div>
            </section>
            {!loadingChart ?
                (
                    <>
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <div className="d-flex align-items-center">
                                <div className="text-uppercase fw-bold me-1">{datePerson.area}</div>
                                <Tippy content="Ver Info"><IoMdInformationCircleOutline onClick={() => handleAreaClick(datePerson.nro_document)} className="info-icon" style={{ cursor: 'pointer', marginRight: '1rem' }} /></Tippy>
                                <div style={{ cursor: 'pointer', marginRight: '1rem' }}><IcoChart onClick={() => setSelectedComponent('HierarchyTree')} className='m-1' /></div>
                                <div style={{ cursor: 'pointer' }}><IcoList onClick={() => setSelectedComponent('List')} className='m-1' /></div>
                            </div>
                            <div className="col-auto">
                                <Link className="btn btn-degradado ps-3 pe-3" to='/busqueda'>Ir a Busqueda</Link>
                            </div>
                        </div>

                        <div className={`${styles['organigrama-container']} d-flex justify-content-center`}>
                            {/* {selectedComponent === 'HierarchyTree' && (
                                <HierarchyTree
                                    employee={hierarchyTree}
                                    onInfoClick={handleInfoIconClick}
                                    onSelectPersonClick={handleSelectPersonClick}
                                />
                            )}
                            {selectedComponent === 'List' && (
                                <List employee={hierarchyTree} onSelectPersonClick={handleSelectPersonClick} />
                            )} */}
                                                        {componentMap[selectedComponent]}
                            {selectedPerson && (
                                <UserModal person={selectedPerson} onHide={handleModalClose} />
                            )}
                            {area && (
                                <AreaModal dni={area} onHide={handleModalClose} datePerson={datePerson} hierarchyTree={hierarchyTree} />
                            )}

                        </div>
                    </>
                ) : <div className="text-center"><div className="spinner-border text-primary m-4"></div></div>}

        </>
    );
};

export default OrgChartTree;
