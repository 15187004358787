
import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import  DataServices from "../../services/data-test.service";
import { BsExclamationCircle } from 'react-icons/bs';

import GiftCardIcon from '../../resources/images/Gift-Card-Icon.png';
import AppStoreLogo from '../../resources/images/AppStore-Dark.png';
import GooglePlayLogo from '../../resources/images/GooglePlay-Dark.png';
import GiftCardImage from '../../resources/images/giftcard.png';


const Giftcard = (props) => {

    const [loadingGifts, setLoadingGifts] = useState(true);
    const [noInfo,setNoInfo]=useState(false);
    const [giftCard, setGiftCard] = useState({});

    const LoadingGiftcardScreen = () => {
            return <div data-testid="loading-info" className="full-spinner">
            <div className="spinner-border text-primary"></div>
        </div>
        
    }

    const getTarjetaRegalo = async () => {
        await DataServices.getTarjetaRegalo(setGiftCard,setLoadingGifts,setNoInfo);
    }

    useEffect(()=>{
        getTarjetaRegalo();
    },[])

    const currencyNormalization = (value,
        currencySign = '$',
        decimalLength = 2,
        chunkDelimiter = '.',
        decimalDelimiter = ',',
        chunkLength = 3) =>{
        
            if (!value && (value !== 0)) return null;
            value /= 100;

            let result = '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
            let num = value.toFixed(Math.max(0, ~~decimalLength));
            return currencySign+(decimalDelimiter ? num.replace('.', decimalDelimiter) : num).replace(new RegExp(result, 'g'), '$&' + chunkDelimiter);
    }
    
    const displayGiftcard = () => {
        if(!noInfo){
            return <>
            <section className="gif-card">

                <div className="box mb-4 pb-5" data-testid ="render-giftcard">
                    <div className="space-between">
                        <img className="image-card" width="100" height="40" src={GiftCardIcon} alt="" />
                        <div className="credit-card fw-bold">
                            {giftCard.product?.cardNumber}
                        </div>
                    </div>
                    <hr/>

                    <h2 className="h4 tit-section mt-4">Monto Disponible</h2>
                    <div className="d-flex align-items-center">
                        <p className="fs-1 fw-bold color-orange m-0">{currencyNormalization(giftCard.product?.availableAmount)}</p>
                        <img className="img-fluid d-none d-md-inline-block ms-auto me-5" src={GiftCardImage} alt="" width="150" />
                    </div>
                </div>

                <div className="box mb-4 pb-5">
                    <h2 className="h4 tit-section">Descarga APP</h2>
                    <p>Descargá la APP de Giftcard para ver los movimientos y más!</p>
                    <div className="d-md-flex align-items-center">
                        <div className="mt-5 mt-md-0">
                            <a href="https://play.google.com/store/apps/details?id=com.cencosud.giftcard_cencosud&hl=es&gl=US" target="_blank">
                                <img className="img-fluid me-3" src={AppStoreLogo} alt="" width="150" />
                            </a>
                            <a href="https://apps.apple.com/us/app/cencosud-giftcard/id1536806576" target="_blank">
                                <img className="img-fluid" src={GooglePlayLogo} alt="" width="165" />
                            </a>
                        </div>
                    </div>
                </div>

            </section>

            </>

        }else{
            return <div className="alert alert-info alert-dismissible fade show text-center" role="alert">
            <BsExclamationCircle />  No se encontraron datos asociados
        </div>
        }
       
    }

    return (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home color-orange" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Giftcard
                    </li>
                </ol>
            </nav>
            <section className="noticias mb-3">
                <h1>Giftcard</h1>
                <div className="col-12 position-relative mb-0">
                    {/* <a className="btn btn-degradado btn-absolute" href="#">Ver más <i className="fa fa-chevron-right" aria-hidden="true"></i></a> */}
                </div>
            </section>
            <div className="container">
                {loadingGifts ? LoadingGiftcardScreen() : displayGiftcard()}
            </div>
        </>
    )
}

export default  Giftcard 