import { Bar } from "react-chartjs-2";
import { ChartBarProps } from "./chart.types";
import { chartColors } from "../../utils";

export const ChartBar = (props: ChartBarProps) => {
  const { xAxisLabels, type = "linear", height = 90, values, scaleY = 1 } = props;

  const option = {
    type,
    scales: {
      y: {
        ticks: {
          stepSize: scaleY,
          beginAtZero: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const background = xAxisLabels.map(
    (_, index) => chartColors[index % chartColors.length]
  );

  const data = {
    labels: xAxisLabels,
    datasets: [
      {
        label: "",
        data: values,
        backgroundColor: background,
      },
    ],
  };

  return <Bar data={data} options={option} height={height} />;
};
