import axios from 'axios';
import Auth from '@aws-amplify/auth';

// eslint-disable-next-line no-undef
const baseUrl = process.env.REACT_APP_BACKEND_URL_GLOBAL;
// const baseUrl = "http://localhost:5000";

const getHeader = () => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(async (auth) => {
        const header = {
          "Content-Type": "application/json",
          accesstoken: auth.getAccessToken().getJwtToken(),
        };
        resolve(header);
      })
      .catch(function (error) {
        return reject(error);
      });
  });
};

const OrganigramaService = {
  getTree: async (id) => {
    try {
      const header = await getHeader();
      const data = await axios.get(baseUrl + `/api/organigrama/get_people_relations/${id}`, {
        headers: header,
      })
      return data?.data?.responseTree;
    } catch (error) {
      error?.response?.data;
    }
  },
  getApprover: async () => {
    try {
      const header = await getHeader();

      const data = await axios.get(baseUrl + "/api/vacaciones/aprobador", {
        headers: header,
      });

      return data;
    } catch (error) {
      error?.response?.data;
    }
  },
  getDuration: async (inicio, fin) => {
    try {
      const header = await getHeader();

      const data = await axios.get(`${baseUrl}/api/vacaciones/duracion?inicio=${inicio}&fin=${fin}`, {
        headers: header,
      });

      return data;
    } catch (error) {
      error?.response?.data;
    }
  },
  getAbsenceList: async () => {
    try {
      const header = await getHeader();

      const data = await axios.get(`${baseUrl}/api/vacaciones/solicitudes`, {
        headers: header,
      });

      return data;
    } catch (error) {
      error?.response?.data;
    }
  },
  cancelarSolicitud: async (id) => {
    try {
      const header = await getHeader();

      const data = await axios({
        method: "post",
        url: `${baseUrl}/api/vacaciones/cancelarsolicitud`,
        data: { idSolicitud: id },
        headers: {
          "Content-Type": "application/json",
          accesstoken: header.accesstoken,
        },
      });

      return data;
    } catch (error) {
      error?.response?.data;
    }
  },
  postSolicitud: async (body) => {
    try {
      const header = await getHeader();

      const data = await axios({
        method: "post",
        url: `${baseUrl}/api/vacaciones/crearsolicitud`,
        data: body,
        headers: {
          "Content-Type": "application/json",
          accesstoken: header.accesstoken,
        },
      });

      return data;
    } catch (error) {
      error?.response?.data;
    }
  },
  getArea: async (id) => {
    try {
      const header = await getHeader();
      const response = await axios.get(baseUrl + `/api/organigrama/get_report_by_area/${id}`, {
        headers: header,
      })
      return response.data
    } catch (error) {
      error?.response?.data;
    }
  },

  getCsv: async (id) => {
    try {
      const header = await getHeader();
      const response = await axios.get(baseUrl + `/api/organigrama/get_csv_people_relations/${id}`, {
        headers: header,
      })
      return response
    } catch (error) {
      error?.response;
    }
  }

}
export default OrganigramaService;