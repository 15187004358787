
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import BreadCrumbs from '../commons/breadcrumbs'
import HCHome from './home'
import Category from './category'
import Article from './article'
import AllCategories from './allCategories'
import Searcher from '../commons/searcher'
import SearchResults from './searchResults/resultsPage'
import { categoryPath, articlePath, categoriesPath } from '../utilities/paths'
import { UpdateLocalStorage } from '../utilities/helpers/updateStorage'
import styles from '../commons/styles/rootStyle.module.css'
import textstyles from '../commons/styles/textStyles.module.css'
import { BackButton, HomeContent } from '../utilities/constantsTexts'
import { Button, Grid } from '@mui/material'
import { getUserInfo } from '../utilities/apiServices/getUserInfo'
import '../commons/styles/generalStyle.css'
import ErrorScreen from '../commons/dataStatus/errors/errorScreen'

const userService = new getUserInfo()

const HelpCenter = () => {
  const [showSearchBar, setShowSearchBar] = useState(true)
  const [showSearchResult, setShowSearchResults] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [showError, setShowError] = useState(false)
  const [errorStatus, setErrorStatus] = useState(0)
  const location = useLocation()
  const storageService = new UpdateLocalStorage()

  useLayoutEffect(() => {
    setShowSearchResults(false)
    setTimeout(() => {
      window.focus()
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }, 200)
    if(location.pathname.includes(articlePath)) {
      setShowSearchBar(false)
    } else {
      setShowSearchBar(true)
    }
  }, [location.pathname]);

  useEffect(() => {
    saveUserData()
    window.addEventListener('storage', readErrosUpdate)
    return (() => {
      window.removeEventListener('storage', readErrosUpdate)
      storageService.keyConstants.map((key: string) => {
        storageService.clearSlot(key)
      })
    })
  }, []);

  const saveUserData = () => {
    userService.getAuthInfo()
  }

  const handleSearchEnter = (searchText: string) => {
    if (searchText !== '') {
      setSearchValue(searchText)
      setShowSearchResults(true)
    }
  }

  const handleHideSearchResults = () => {
    setShowSearchResults(false)
  }

  const readErrosUpdate = (event: StorageEvent): void => {
    if (event.key === storageService.keyConstants[7] && event.newValue) {
      const errorParsed = JSON.parse(event.newValue)
      setErrorStatus(errorParsed.errorStatus)
      setShowError(true)
    } else {
      return
    }
  }

  return (
    <div className={styles.helpCenter_page_container}>
      <BreadCrumbs />
      {showSearchBar && !showError &&
        <Grid item xs={12}>
          <h1 className={textstyles.page_title}>{HomeContent.title}</h1>
          <Searcher onSearchEnter={handleSearchEnter} />
        </Grid>
      }
      {showSearchResult && !showError ?
        (
          <Grid item xs={12}>
            <SearchResults searchText={searchValue} emmitCardClicked={() => setShowSearchResults(false)}/>
            <Grid item xs={12} className={styles.results_back_btn_grid}>
              <Button className={styles.terciary_button} onClick={() => handleHideSearchResults()}>
                {BackButton.simple}
              </Button>
            </Grid>
          </Grid>) : (
            !showError && 
            <Routes>
              <Route path="" element={<HCHome />} />
              <Route path={categoryPath + '/:id'} element={<Category />} />
              <Route path={categoriesPath} element={<AllCategories />} />
              <Route path={articlePath + '/:slug' + '/:id'} element={<Article />} />
            </Routes>
          )
      }
      {showError && <ErrorScreen errorStatus={errorStatus}/>}
    </div>
  );
}

export default HelpCenter;