import { taskEither as TE } from "fp-ts";
import * as t from "io-ts";

export const Row = t.type({
    label: t.string,
    value: t.string,
});

export type Row = t.TypeOf<typeof Row>;

export const ResponseFetch = t.type({
    valid: t.boolean,
    rows:  t.array(Row)
});

export type ResponseFetch = t.TypeOf<typeof ResponseFetch>;

export type HttpRequestError = {
    tag: 'httpRequestError';
    error: unknown;
}
  
export type HttpContentTypeError = {
    tag: 'httpContentTypeError';
    error: unknown;
}
  
export type HttpResponseStatusError = {
    tag: 'httpResponseStatusError';
    status: number;
}

export interface HttpClient {
    request(
        input: RequestInfo,
        init?: RequestInit,
    ): TE.TaskEither<HttpRequestError, Response>
}
  
export interface HttpClientEnv {
    httpClient: HttpClient;
}

export const verificationCodec = t.type(ResponseFetch.props, 'VerificationResponse');

export type Verification = t.TypeOf<typeof verificationCodec>;

export type HttpError =
  | HttpRequestError
  | HttpContentTypeError
  | HttpResponseStatusError
  | t.Errors
