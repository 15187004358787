import { MdCardGiftcard, MdWorkOutline } from 'react-icons/md';
import { TbBeach } from 'react-icons/tb';
import { GrContactInfo } from 'react-icons/gr';
import { RiSurveyLine } from 'react-icons/ri';
import { FiInfo } from 'react-icons/fi';
import { HiOutlineClock } from "react-icons/hi2";
import { FaUserPlus } from "react-icons/fa";
import React from 'react';

//add react icons imports as needed, then add case in function
export function setNotificationIcon(icon) {


    if (!icon) {
        return <GrContactInfo />;
    }
    switch (true) {
        //giftcard
        case icon.includes("MdCardGiftcard"):
            return <MdCardGiftcard />;
        //vacaciones
        case icon.includes("TbBeach"):
            return <TbBeach />;
        //encuesta
        case icon.includes("RiSurveyLine"):
            return <RiSurveyLine />;
        //info general
        case icon.includes("FiInfo"):
            return <FiInfo />;
        //esquema hibrido
        case icon.includes("MdWorkOutline"):
            return <MdWorkOutline />;
        //tiempo de espera
        case icon.includes("HiOutlineClock"):
            return <HiOutlineClock />;
        //cambio de horario    
        case icon.includes("FaUserPlus"):
            return <FaUserPlus />;
        default:
            return "String does not contain any of the specified words";
    }
}
