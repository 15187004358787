import {Paths, BreadCrumbs, PathDetails} from './constantsTexts'

const paths = [
    {
        path: Paths.home,
        breadCrumb: BreadCrumbs.home
    },
    {
        path: Paths.helpCenter,
        breadCrumb: BreadCrumbs.helpCenter
    },
    {
        path: Paths.category,
        breadCrumb: BreadCrumbs.categoryTitle
    },
    {
        path: Paths.article,
        breadCrumb: BreadCrumbs.article
    },
    {
        path: Paths.allCategories,
        breadCrumb: BreadCrumbs.allCategories
    },
    {
        path: Paths.searchResults,
        breadCrumb: ''
    }
]

const categoryPath = PathDetails.categoryPath
const articlePath = PathDetails.articlePath
const categoriesPath = PathDetails.categoriesPath
const searchPath = PathDetails.searchPath
const helpCenterPath = PathDetails.helpCenterPath

export {
    categoryPath,
    articlePath,
    categoriesPath,
    searchPath,
    helpCenterPath,
    paths
}

