import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import ExperienciaLaboralServices from '../../services/experiencia laboral/experiencia-laboral-service';
import styles from "../../resources/css/style-COL.module.css";

function ModificarExperienciaLaboral({ id, reloadWorkExperiences, countries, infoWorkExperience }) {
    const [workExperience, setWorkExperience] = useState({})
    const [loadingWorkExperience, setLoadingWorkExperience] = useState(false)
    const [, setNoDataWorkExperience] = useState(false)
    const [deleteWorkExperience, setDeleteWorkExperience] = useState(false)
    const [loadingDeleteWorkExperience, setLoadingDeleteWorkExperience] = useState(false)
    const [noDataDelete, setNoDataDelete] = useState(false)
    const [states, setStates] = useState([])
    const [loadingStates, setLoadingStates] = useState(false)
    const [counties, setCounties] = useState([])
    const [loadingCounties, setLoadingCounties] = useState(false)
    const [infoDataDeleteError, setInfoDataDeleteError] = useState(null)
    const [industries, setIndustries] = useState([])
    const [loadingIndustries, setLoadingIndustries] = useState(false)
    const [sectors, setSectors] = useState([])
    const [loadingSectors, setLoadingSectors] = useState(false)
    const [workAreas, setWorkAreas] = useState([])
    const [loadingWorkAreas, setLoadingWorkAreas] = useState(false)
    const [reasonsRetirement, setReasonsRetirement] = useState([])
    const [loadingReasonsRetirement, setLoadingReasonsRetirement] = useState(false)
    const [editWorkExperience, setEditWorkExperience] = useState(false)
    const [loadingEditWorkExperience, setLoadingEditWorkExperience] = useState(false)
    const [noDataEdit, setNoDataEdit] = useState(false)
    const [infoDataEditError, setInfoDataEditError] = useState(false)
    const [submitValidated, setSubmitValidated] = useState(false)

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        reset
    } = useForm();
    const onSubmit = (data) => {
        if(data.subordinates < 0){
            setSubmitValidated(true);
            return;
        }
        const submit = {
            id: id.toString(),
            city: data.country === "COL" ? "" : data.city,
            country: data.country,
            county: data.country === "COL" ? data.county : " ",
            state: data.country === "COL" ? data.state : " ",
            company: data.company,
            industry: data.industry,
            sector: data.sector,
            workarea: data.workarea,
            position: data.position,
            subordinates: data.subordinates.toString(),
            reasons_retirement: data.reasons_retirement,
            start_date: data.start_date,
            end_date: data.end_date
        }
        setLoadingEditWorkExperience(true)
        ExperienciaLaboralServices.EditWorkExperience(submit, setEditWorkExperience, setLoadingEditWorkExperience, setNoDataEdit, setInfoDataEditError)
    }

    useEffect(() => {
        if (id !== null) {
            setLoadingWorkExperience(true)
            ExperienciaLaboralServices.GetWorkExperience(id, setWorkExperience, setLoadingWorkExperience, setNoDataWorkExperience)
        }
    }, [id])

    useEffect(() => {
        if (watch("country") === "COL") {
            setLoadingStates(true)
            ExperienciaLaboralServices.GetStates(watch("country"), setStates, setLoadingStates)
        }
    }, [watch("country")])

    useEffect(() => {
        if (watch("country") === "COL") {
            setLoadingCounties(true)
            ExperienciaLaboralServices.GetCounties(watch("country"), watch("state"), setCounties, setLoadingCounties)
        }
    }, [watch("state")])

    useEffect(() => {
        if (workExperience.country === "COL") {
            ExperienciaLaboralServices.GetStates(workExperience.country, setStates, setLoadingStates)
            ExperienciaLaboralServices.GetCounties(workExperience.country, workExperience.state, setCounties, setLoadingCounties)
        }
        ExperienciaLaboralServices.GetIndustries(setIndustries, setLoadingIndustries)
        ExperienciaLaboralServices.GetSectors(setSectors, setLoadingSectors)
        ExperienciaLaboralServices.GetWorkAreas(setWorkAreas, setLoadingWorkAreas)
        ExperienciaLaboralServices.GetReasonsRetirement(setReasonsRetirement, setLoadingReasonsRetirement)
        reset(infoWorkExperience)
    }, [id])


    const deleteOneWorkExperience = () => {
        Swal
            .fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: red; font-size: 16px"> Eliminar </div>',
                html: '<div style="text-align: center; font-size: 15px"> ¿Desea continuar la operación? </div>',
                showCancelButton: true,
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                confirmButtonColor: "red",
                cancelButtonColor: "#0169b5"
            }).then((result) => {
                if (result.isConfirmed) {
                    setLoadingDeleteWorkExperience(true)
                    ExperienciaLaboralServices.DeleteWorkExperience(id, setDeleteWorkExperience, setLoadingDeleteWorkExperience, setNoDataDelete, setInfoDataDeleteError)
                }
            })
    }

    // Mensaje de error al enviar un post
    const handleStatusErrorEdit = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
            html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo.</div>`,
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadWorkExperiences()
        })
        setLoadingEditWorkExperience(false)
        setNoDataEdit(false)
    }

    // Mensaje de éxito al enviar un post
    const handleStatusSuccessEdit = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
            html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadWorkExperiences()
        })
        setLoadingEditWorkExperience(false)
        setNoDataEdit(false)
    }

    // Mensaje de error al enviar un post
    const handleStatusErrorDelete = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
            html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo.</div>`,
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadWorkExperiences()
        })
        setLoadingDeleteWorkExperience(false)
        setNoDataDelete(false)
    }

    // Mensaje de éxito al enviar un post
    const handleStatusSuccessDelete = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
            html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadWorkExperiences()
        })
        setLoadingDeleteWorkExperience(false)
        setNoDataDelete(false)
    }

    useEffect(() => {
        // noDataDelete ? handleStatusErrorDelete() : null
        deleteWorkExperience ? handleStatusSuccessDelete() : null
    }, [loadingDeleteWorkExperience])

    useEffect(() => {
        // noDataEdit ? handleStatusErrorEdit() : null
        editWorkExperience ? handleStatusSuccessEdit() : null
    }, [loadingEditWorkExperience])
    return (<>
        {/* <!-- Inicio Modificar Experiencia --> */}
        {!loadingWorkExperience ? <>
            <div className={`${styles["box"]} ${styles["mb-4"]}`}>
                <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Modificar Experiencia</h2>
                <form>
                    {/* <!-- Fila 1 --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Nombre de la Empresa <span className={styles["color-red"]}>*</span></label>
                            <input type="text" className={styles["form-control"]} {...register("company", { required: "* Campo obligatorio", maxLength: 50, value: workExperience.company })}
                            // defaultValue={workExperience.company}  
                            />
                            <small className={styles["form-error-message"]}>{errors.company?.message}</small>
                        </div>
                    </div>
                    {/* <!-- Fila 2 --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>País <span className={styles["color-red"]}>*</span></label>
                            <div className={styles["input-group"]}>
                                <select className={styles["select-col"]} {...register("country", { required: "* Campo obligatorio", maxLength: 3 })}>
                                    <option disabled value="" selected>Seleccione</option>
                                    {
                                        countries.map((c) => {
                                            return (
                                                <option key={c.country} value={c.country} selected={workExperience.country === c.country}>{c.descr}</option>
                                            )
                                        })
                                    }
                                </select>
                                <small className={styles["form-error-message"]}>{errors.country?.message}</small>
                            </div>
                        </div>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]} ${watch("country") === "COL" ? "d-none" : null}`}>
                            <label className={styles["form-label"]}>Ciudad <span className={styles["color-red"]}>*</span></label>
                            <input type="text" className={styles["form-control"]} defaultValue={workExperience.city} {...register("city", { required: watch("country") !== "COL" ? "* Campo obligatorio" : false, maxLength: 35 })} />
                            <small className={styles["form-error-message"]}>{errors.city?.message}</small>
                        </div>
                    </div>
                    {/* <!-- Fila 3 --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]} ${watch("country") !== "COL" ? "d-none" : null}`}>
                            <label className={styles["form-label"]}>Departamento <span className={styles["color-red"]}>*</span></label>
                            <div className={styles["input-group"]}>
                                {!loadingStates ? <select className={styles["select-col"]} {...register("state", { required: watch("country") === "COL" ? "* Campo obligatorio" : false, maxLength: 3 })}>
                                    <option disabled value="" selected>Seleccione</option>
                                    {
                                        states.map(s => {
                                            return (
                                                <option key={s.state} value={s.state} selected={workExperience.state === s.state}>{s.descrshort}</option>
                                            )
                                        })
                                    }
                                </select> : <div><div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                                <small className={styles["form-error-message"]}>{errors.state?.message}</small>
                            </div>
                        </div>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]} ${watch("country") !== "COL" ? "d-none" : null}`}>
                            <label className={styles["form-label"]}>Ciudad <span className={styles["color-red"]}>*</span></label>
                            <div className={styles["input-group"]}>
                                {!loadingCounties ? <select className={styles["select-col"]} {...register("county", { required: watch("country") === "COL" ? "* Campo obligatorio" : false, maxLength: 3 })}>
                                    <option disabled value="" selected>Seleccione</option>
                                    {
                                        counties.map((c) => {
                                            return (
                                                <option key={c.county} value={c.county} selected={workExperience.county === c.county}>{c.descr}</option>
                                            )
                                        })
                                    }
                                </select> : <div><div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                                <small className={styles["form-error-message"]}>{errors.county?.message}</small>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Fila 4 --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Industria <span className={styles["color-red"]}>*</span></label>
                            <div className={styles["input-group"]}>
                                {!loadingIndustries ? <select className={styles["select-col"]} {...register("industry", { required: "* Campo obligatorio", maxLength: 4 })}>
                                    <option disabled value="" selected>Seleccione</option>
                                    {
                                        industries.map(s => {
                                            return (
                                                <option key={s.id} value={s.id} selected={workExperience.industry === s.id}>{s.description}</option>
                                            )
                                        })
                                    }
                                </select> : <div><div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                                <small className={styles["form-error-message"]}>{errors.industry?.message}</small>
                            </div>
                        </div>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Sector <span className={styles["color-red"]}>*</span></label>
                            <div className={styles["input-group"]}>
                                {!loadingSectors ? <select className={styles["select-col"]} {...register("sector", { required: "* Campo obligatorio", maxLength: 4 })}>
                                    <option disabled value="" selected>Seleccione</option>
                                    {
                                        sectors.map(s => {
                                            return (
                                                <option key={s.id} value={s.id} selected={workExperience.sector === s.id}>{s.description}</option>
                                            )
                                        })
                                    }
                                </select> : <div><div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                                <small className={styles["form-error-message"]}>{errors.sector?.message}</small>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Fila 5 --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Área de Trabajo <span className={styles["color-red"]}>*</span></label>
                            <div className={styles["input-group"]}>
                                {!loadingWorkAreas ? <select className={styles["select-col"]}  {...register("workarea", { required: "* Campo obligatorio", maxLength: 4 })}>
                                    {
                                        workAreas.map(s => {
                                            return (
                                                <option key={s.id} value={s.id} selected={workExperience.workarea === s.id}>{s.description}</option>
                                            )
                                        })
                                    }
                                </select> : <div><div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                            </div>
                        </div>
                    </div>
                    {/* <!-- Fila 6 --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Cargo Ocupado <span className={styles["color-red"]}>*</span></label>
                            <input type="text" className={styles["form-control"]} defaultValue={workExperience.position} {...register("position", { required: "* Campo obligatorio", maxLength: 50 })} />
                            <small className={styles["form-error-message"]}>{errors.position?.message}</small>
                        </div>
                    </div>
                    {/* <!-- Fila 7 --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Número de Personas a Cargo <span className={styles["color-red"]}>*</span></label>
                            <input type="number" className={styles["form-control"]} defaultValue={parseInt(workExperience.subordinates)} {...register("subordinates", { required: "* Campo obligatorio", maxLength: 5 })} />
                            <small className={styles["form-error-message"]}>{errors.subordinates?.message}</small>
                            <small className={styles["form-error-message"]}>
                                    {submitValidated
                                        ? "* El numero de personas debe ser mayor o igual a cero"
                                        : null}
                                </small>
                        </div>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Motivo Retiro <span className={styles["color-red"]}>*</span></label>
                            <div className={styles["input-group"]}>
                                {!loadingReasonsRetirement ? <select className={styles["select-col"]} {...register("reasons_retirement", { required: "* Campo obligatorio", maxLength: 3 })}>
                                    <option disabled value="" selected>Seleccione</option>
                                    {
                                        reasonsRetirement.map(s => {
                                            return (
                                                <option key={s.id} value={s.id} selected={workExperience.reasons_retirement === s.id}>{s.description}</option>
                                            )
                                        })
                                    }
                                </select> : <div><div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                                <small className={styles["form-error-message"]}>{errors.reasons_retirement?.message}</small>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Fila 8 --> */}
                    <div className={styles["row"]}>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Fecha de Ingreso <span className={styles["color-red"]}>*</span></label>
                            <input type="date" className={styles["form-control"]} defaultValue={workExperience.start_date} {...register("start_date", { required: "* Campo obligatorio", maxLength: 10 })} />
                            <small className={styles["form-error-message"]}>{errors.start_date?.message}</small>
                        </div>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <label className={styles["form-label"]}>Fecha de Retiro <span className={styles["color-red"]}>*</span></label>
                            <input type="date" className={styles["form-control"]} defaultValue={workExperience.end_date} {...register("end_date", { required: "* Campo obligatorio", maxLength: 10 })} />
                            <small className={styles["form-error-message"]}>{errors.end_date?.message}</small>
                        </div>
                    </div>
                    {/* <!-- fin filas --> */}
                </form>

                <div className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mt-4"]}`}>
                    <div className={`${styles["col-12"]} ${styles["col-md-auto"]}`}>
                        <button type="button" className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`} onClick={deleteOneWorkExperience} disabled={loadingDeleteWorkExperience}>Eliminar {loadingDeleteWorkExperience ? <div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div> : <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i>}</button>
                    </div>
                </div>

            </div>
            {/* <!-- Fin Modificar --> */}

            <div className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mt-4"]}`}>
                <div className={`${styles["col-12"]} ${styles["col-md-auto"]}`}>
                    <button type="button" className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["w-100"]}`} onClick={handleSubmit(onSubmit)} disabled={loadingEditWorkExperience}>Guardar {loadingEditWorkExperience ? <div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div> : <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i>}</button>
                </div>
            </div>
            {noDataDelete ? handleStatusErrorDelete() : null}
            {noDataEdit ? handleStatusErrorEdit() : null}
        </>
            : <div data-testid="loading" className={styles["full-spinner"]}>
                <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div> </div>}
    </>)
}

export default ModificarExperienciaLaboral;