import React, { useState, useEffect, FunctionComponent } from "react";
import UnorderedList from "../../commons/forms";
import { FormList, Props, AddressData, Options, PostValues, GetSheet } from "../../utilities/interfaces";
import { stepTwoAddress } from "../../utilities/formValues";
import { useForm, SubmitHandler } from "react-hook-form";
import OfflineModal from "../../commons/plain/modals/offline";
import fichaContratacionService from "../../../../services/ficha-contratacion-service";
import { scrollToTop } from "../../../../services/utiles";
interface Props1 extends Props {
    prevStep(): void;
    addressData: AddressData;
    setAddressData: React.Dispatch<React.SetStateAction<AddressData>>;
    postValues: PostValues;
    setPostValues: React.Dispatch<React.SetStateAction<PostValues>>;
}

const SecondStep: FunctionComponent<Props1> = ({ options, nextStep, prevStep, addressData, setAddressData, postValues, setPostValues }) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
    } = useForm<AddressData>({
        defaultValues: {
            ...addressData,
        }
    });
    const [optionsCopy, setOptionsCopy] = useState(new Map(options));
    const [citiesOptions, setCitiesOptions] = useState([...options.get("citiesOptions") as Array<Options>]);
    const [postStatus, setPostStatus] = useState<any>(false);
    const [disableButton, setDisableButton] = useState(false);
    const state = watch("state");
    const city = watch("city");

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        if (addressData.city === "" || addressData.county === "" || addressData.state === "") {
            setValue("city", "");
            setValue("county", "");
        }
        if (state === "") {
            let citiesCopy = [{ key: "", value: "", label: "" }];
            let newOptions = new Map(optionsCopy);
            newOptions.set("citiesOptions", citiesCopy);
            setOptionsCopy(newOptions);
        } else {
            let allCities = [...new Map(options).get("citiesOptions") as Array<Options>];
            let newCities = allCities?.filter((option) => {
                return option.key.split(",")[0] === state;
            });
            setCitiesOptions(newCities);
            let newOptions = optionsCopy;
            newOptions.set("citiesOptions", newCities as Array<Options>);
            setOptionsCopy(newOptions);
        }
    }, [state]);

    useEffect(() => {
        let newOptions = new Map(optionsCopy);
        newOptions.set("citiesOptions", citiesOptions as Array<Options>);
        setOptionsCopy(newOptions);
    }, [citiesOptions]);

    useEffect(() => {
        if (city !== "") {
            let allCities = [...new Map(options).get("citiesOptions") as Array<Options>];
            for (const cityOption of allCities) {
                if (cityOption.key.split(",")[2] === city) {
                    setValue("county", cityOption.key.split(",")[1] as string);
                }
            }
        }
    }, [city]);

    const formSubmitHandler: SubmitHandler<AddressData> = (data: AddressData) => {
        if (navigator.onLine) {
            setDisableButton(true);
            let newData = { ...data };
            let stringSheet = sessionStorage.getItem("ficha");
            setAddressData(newData);
            if (stringSheet !== null) {
                let savedSheet = JSON.parse(stringSheet) as GetSheet;
                sessionStorage.setItem("ficha", JSON.stringify({
                    ...savedSheet,
                    sheet: {
                        ...savedSheet.sheet,
                        secondStep: {
                            addressData: newData,
                        }
                    }
                }));
            }
            document.getElementById("sendingInfo")?.click();
            fichaContratacionService.postMethods.setFicha(postValues, newData, setPostValues, false, setPostStatus, setDisableButton);
        } else {
            document.getElementById("offline")?.click();
        }
    };

    useEffect(() => {
        if (postStatus) {
            document.getElementById("closeSendingInfo")?.click();
        }
        const timer = setTimeout(() => {
            setDisableButton(false);
            if (postStatus.status === "0") {
                nextStep();
            }
        }, 519);
        return () => clearTimeout(timer);
    }, [postStatus]);

    return (
        <>
            <form
                className="needs-validation"
                onSubmit={handleSubmit(formSubmitHandler)}
            >
                <div id={"domicilio"} className="box mb-4">
                    <h2 className="h4 tit-section" children={"Domicilio"} />
                    <UnorderedList formList={stepTwoAddress as Array<FormList>} options={optionsCopy} register={register} errors={errors} />
                </div>
                <div className="d-grid d-md-flex justify-content-md-between gap-2">
                    <button type="button" className="btn btn-primary" onClick={(e) => { e.preventDefault(); prevStep() }}>
                        <i className="fa fa-chevron-left fa-fw fa-xs" aria-hidden="true" />
                        {"Anterior"}
                    </button>
                    <button type="submit" className="btn btn-degradado" disabled={disableButton}>
                        {"Siguiente"}
                        <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                    </button>
                </div>
            </form>
            <OfflineModal />
        </>
    );
};

export default SecondStep;