
export class UpdateLocalStorage {
    constructor() {}
    
    keyConstants = [
      'breadCrumbTitle',
      'nestedBreadcrumb',
      'userInfoHC',
      'categoryInfo',
      'prevPath',
      'prevArticle',
      'prevArticlePath',
      'onCatchError'
    ]

    updateSlot = (key: string,  value: string) => {
        localStorage.setItem(key, value)
        const event = new StorageEvent('storage', {
            key: key,
            newValue: value
            });
            
        window.dispatchEvent(event);
    }

    clearSlot = (key: string) => {
        localStorage.removeItem(key)
        const event = new StorageEvent( 'storage', {key: key});
        window.dispatchEvent(event);
    }

}
