const Excel = require("exceljs");

const getExcel = async (header, rows) => {
  try {
    const keysToDisplay = header.filter((h) => !h.hidden).map((h) => h.value);
    const workbook = new Excel.Workbook();
    const sheet1 = workbook.addWorksheet("Dashboard");
    // pasarle el array de header
    const obj = {};

    sheet1.columns = header.map((h) => {
      return {
        header: h.label,
        key: h.value,
        width: 30,
        hidden: h.hidden,
      };
    });

    const row = [];

    rows.map((r) => {
      const dataRow = {};
      keysToDisplay.map((k) => {
        const value = r[k] || "";
        dataRow[k] = value;
      });
      row.push(dataRow);
    });
    row.length > 0 && sheet1.addRows(row);

    //sheet1.spliceRows;

    const buffer = await workbook.xlsx.writeBuffer();
    const base64String = buffer.toString("base64");
    const excelFile = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;

    return {
      response: {
        file: excelFile,
        status: "Archivo generado correctamente",
        valid: 1,
      },
    };
  } catch (error) {
    return {
      response: {
        valid: 0,
        status: "Error al obtener la información",
        file: "",
      },
    };
  }
};

export default getExcel;
