// Calculated last four years
export const currentYear = new Date().getFullYear();

export const lastFourYears = Array.from({ length: 4 }, (_, i) =>
  (currentYear - i).toString()
);

export const lastFourYearsReverse = [...lastFourYears].reverse();

// Values Chart
export const chartColors = [
  "rgb(212, 212, 83)",
  "rgb(135, 205, 99)",
  "rgb(91, 212, 132)",
  "rgb(170, 231, 231)",
  "rgb(131, 162, 223)",
  "rgb(145, 113, 209)",
  "rgb(203, 94, 203)",
  "rgb(208, 76, 120)",
  "rgb(222, 163, 133)",
] as const;
