import React, { useEffect, useState } from "react";
import styles from "../../resources/css/style-COL.module.css";
import { BsExclamationCircle } from "react-icons/bs";
import VacacionesServices from "../../services/vacaciones-COL/vacaciones-service";
import errorMessage from "../SharedComponents/error-message";
"../../resources/css/style-COL.css"

const VacacionesJefeHistoricalList = ({ historicalRequests }) => {
  const [estados, setEstados] = useState([])
  const [loadingEstados, setLoadingEstados] = useState(true)

  useEffect(() => {
    getEstados()
  }, [])

  const getEstados = async () => {
    try {
        let response = await VacacionesServices.getEstados();
        if (
            response !== null &&
            response !== undefined &&
            (response?.statusCode === 200 || response?.status === 200)
        ) {
            setEstados(response?.data.CN_COL_XLAT.PSXLATITEM);
            setLoadingEstados(false);
        } else {
            errorMessage(
                null,
                response?.message === null || response?.message === undefined
                    ? ""
                    : response?.message
            );
            setLoadingEstados(false);
        }

    } catch (error) {
        setLoadingEstados(false);
    }
};

  return (
    <>
      <h2 className={styles["h4"] + " " + styles["tit-section"]}>
        Pendiente e Histórico
      </h2>

      <form>
        {!historicalRequests.length ? (
          <>
            <div
              className={`${styles["alert"]} ${styles["alert-info"]} ${styles["alert-dismissible"]} ${styles["fade"]} ${styles["show"]} ${styles["text-center"]}`}
              role="alert"
            >
              <BsExclamationCircle /> No se encontraron solicitudes pendientes e
              históricas.
            </div>
          </>
        ) : (
          <>
            {/* <!-- Escritorio (Tabla) --> */}
            <div className={styles["table-responsive"]}>
              <table className={`${styles["table"]} ${styles["table-even"]} ${styles["table-section"]} ${styles["table-borderless"]} ${styles["d-none"]} ${styles["d-md-table"]}`}>
                <thead>
                  <tr>
                    <th className={styles["text-nowrap"]}>
                      ID <br />
                      Solicitud{" "}
                    </th>
                    <th className={styles["text-nowrap"]}>
                      Nombre <br />
                      Solicitante{" "}
                    </th>
                    <th className={styles["text-nowrap"]}>
                      Fecha <br />
                      Inicio{" "}
                    </th>
                    <th className={styles["text-nowrap"]}>
                      Fecha <br />
                      Término{" "}
                    </th>
                    <th className={`${styles["text-nowrap"]} ${styles["text-center"]}`}>Vacaciones </th>
                    <th className={`${styles["text-nowrap"]} ${styles["text-center"]}`}>Beneficio </th>
                    <th className={`${styles["text-nowrap"]} ${styles["text-center"]}`}>Dinero </th>
                    <th className={`${styles["text-nowrap"]} ${styles["text-center"]}`}>Estado </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(historicalRequests)
                    ? historicalRequests.map((i) => {
                        return (
                          <tr key={i.id}>
                            <td>{i.cn_id_solic_vac}</td>
                            <td>{i.name_display}</td>
                            <td>{i.inicio}</td>
                            <td>{i.fin}</td>
                            <td className={`${styles["text-nowrap"]} ${styles["text-center"]}`}>
                              {i.duracion - i.dias_beneficio}
                            </td>
                            <td className={`${styles["text-nowrap"]} ${styles["text-center"]}`}>
                              {i.dias_beneficio}
                            </td>
                            <td className={`${styles["text-nowrap"]} ${styles["text-center"]}`}>
                              {i.dias_dinero}
                            </td>
                            <td className={`${styles["text-uppercase"]} ${styles["fw-bold"]}`}>
                              {estados && estados?.filter((e) => e.FIELDVALUE === i.cn_c_estado_vac)[0] !== undefined ? estados.filter((e) => e.FIELDVALUE === i.cn_c_estado_vac)[0].XLATLONGNAME : "No definido"}
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
            {/* <!-- Móvil (Acordeón) --> */}
            <div className={`accordion accordion-flush accordion-light accordion-table ${styles["d-md-none"]}`}>
              {Array.isArray(historicalRequests)
                ? historicalRequests.map((i, id) => {
                    return (
                      <div key={id} className="accordion-item">
                        <h2 className="accordion-header" id="accordion">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#accordion-item-${id}`}
                            aria-expanded="false"
                            aria-controls={`accordion-item-${id}`}
                          >
                            <strong>ID Solicitud</strong> {i.cn_id_solic_vac}
                          </button>
                        </h2>
                        <div
                          id={`accordion-item-${id}`}
                          className="accordion-collapse collapse"
                          aria-labelledby="accordion"
                        >
                          <div className={`accordion-body ${styles["p-0"]}`}>
                            {/* <!-- cuerpo item --> */}
                            <div className={`${styles["data-list-box"]} ${styles["data-list-box-even"]}`}>
                              <ul className={`${styles["data-list-section--item"]} ${styles["list-unstyled"]}`}>
                                <li>
                                  <span className={styles["data-list-box__title"]}>
                                    Nombre Solicitante
                                  </span>
                                  <span className={styles["data-list-box__text"]}>
                                    {i.name_display}
                                  </span>
                                </li>
                                <li>
                                  <span className={styles["data-list-box__title"]}>
                                    Fecha Inicio
                                  </span>
                                  <span className={styles["data-list-box__text"]}>
                                    {i.inicio}
                                  </span>
                                </li>
                                <li>
                                  <span className={styles["data-list-box__title"]}>
                                    Fecha Término
                                  </span>
                                  <span className={styles["data-list-box__text"]}>
                                    {i.fin}
                                  </span>
                                </li>
                                <li>
                                  <span className={styles["data-list-box__title"]}>
                                    Vacaciones
                                  </span>
                                  <span className={styles["data-list-box__text"]}>
                                  {i.duracion - i.dias_beneficio}
                                  </span>
                                </li>
                                <li>
                                  <span className={styles["data-list-box__title"]}>
                                    Beneficio
                                  </span>
                                  <span className={styles["data-list-box__text"]}>
                                    {i.dias_beneficio}
                                  </span>
                                </li>
                                <li>
                                  <span className={styles["data-list-box__title"]}>
                                    Dinero
                                  </span>
                                  <span className={styles["data-list-box__text"]}>
                                    {i.dias_dinero}
                                  </span>
                                </li>
                                <li>
                                  <span className={styles["data-list-box__title"]}>
                                    Estado
                                  </span>
                                  <span className={styles["data-list-box__text"]}>
                                    <span className={`${styles["text-uppercase"]} ${styles["fw-bold"]}`}>
                                    {estados && estados?.filter((e) => e.FIELDVALUE === i.cn_c_estado_vac)[0] !== undefined ? estados.filter((e) => e.FIELDVALUE === i.cn_c_estado_vac)[0].XLATLONGNAME : "No definido"}
                                    </span>
                                  </span>
                                </li>
                              </ul>
                            </div>
                            {/* <!-- fin cuerpo item --> */}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}

              {/* <!-- fin items acordeón --> */}
            </div>
            {/* <!-- fin Móvil (Acordeón) --> */}
          </>
        )}
      </form>
    </>
  );
};

export default VacacionesJefeHistoricalList;
