import React, { useEffect, useState } from 'react';
import MiEquipoService from '../../../services/mi-equipo/mi-equipo-service';

const useMiEquipo = () => {
    const [equipo, setEquipo] = useState();
    const [statusEquipo, setStatusEquipo] = useState();
    const [loadingEquipo, setLoadingEquipo] = useState(true);

    const getMiEquipo = async (selectedYear) => {
        const response = await MiEquipoService.getMiEquipo(selectedYear);
        setEquipo(response.data);
        setStatusEquipo(response.status);
        setLoadingEquipo(false);
    }

    useEffect(() => {
        getMiEquipo();
    }, []);

    return { equipo, statusEquipo, loadingEquipo };
};

export default useMiEquipo;