import { urlsAws } from "../resources/foo/api-endpoints";

const fetchData = async (url, requestOptions) => {
    return await fetch(url, requestOptions)
        .then((response) => response.text())
        .then((result) => result.replaceAll(",}", "}"))
        .then((result) => JSON.parse(result))
        .then((data) => data.response)
        .catch((error) => { console.log("error", error); return null });
}
const Service = {
    getEncuestas: async (auth) => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", "Authorization": auth }
        };
        return await fetchData(urlsAws.getEncuestas, requestOptions);
    },
    sendEncuestas: async (auth, body) => {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", "Authorization": auth },
            body: JSON.stringify({ request: body })
        };
        return await fetchData(urlsAws.sendEncuestas, requestOptions);
    }
};
export default Service;
