import React , {useState, useEffect } from 'react';
import VacacionesService from "../../services/solicitud-vacaciones/vacaciones-arg-service";
import Auth from '@aws-amplify/auth';
import { RiErrorWarningLine } from 'react-icons/ri';
import ProfileImage from '../FotoPerfil/profile-image';
import styles from '../../resources/css/style-ARG.module.css';


const SummaryVacacionesArg = () => {
    
    const [data, setData]= useState({});
    const [fetched, setFetched] = useState(false); 
    const [fullname,setFullname]=useState("");
    const [loadingSummary, setLoadingSummary]=useState(true);

    useEffect(() => {

        Auth.currentUserInfo().then(user => {
            setFullname(user.attributes.given_name + " " + user.attributes.family_name);
        }).catch(err => console.log(err))

    }, [fullname]);

    useEffect(() => {
        getSummaryData();
        if(fetched){
            console.log("fetched!")
        }
    }, [fetched])

    const getSummaryData = async () => {
        await VacacionesService.getSummary(setData,setFetched,setLoadingSummary);
    }

    return (
        
        <div className="row space-around">
            <div className={`${styles['holiday-container']} p-0`}>
                <div className={`${styles['card-box']} ${styles['summary-card']}`}>
                
                {!loadingSummary && fetched ? <div>
                <div className={`${styles['main-image']}`}>
                       <ProfileImage />                   
                        </div>
                    <div className={`${styles['detail-user']}`}>
                        <div className={`${styles.title}`}>{fullname}</div>
                        <div className={`${styles.subtitle} fw-bold`}>Solicitud de vacaciones</div>
                    </div>
                    <div className={`${styles['datas-group-container']}`}>
                    <div className={`${styles['datas-group']}`}>
                        <div className={`${styles['data-group']}`}>
                            <div className={styles.days}><span className='ic-circle ic-circle-primary'>{data?.toApproveDays}</span></div><div className={`${styles['data-text']}`}>Pendiente de aprobación</div>
                        </div>
                        <div className={`${styles['data-group']}`}>
                            <div className={styles.days}><span className='ic-circle ic-circle-primary'>{data?.availableDays}</span></div><div className={`${styles['data-text']}`}>Días disponibles</div>
                        </div>
                    </div>
                    </div>
                    </div>
            : !loadingSummary ? <div className={`${styles['error-container-lb']} ${styles['text-def']}`}><div><RiErrorWarningLine className={`color-primary ${styles['error-icon']} fs-5`}/></div><div>Se ha producido un error. Vuelva a intentarlo</div></div> : <div className={`${styles['full-spinner']}`}><div className={`${styles['spinner-border']} ${styles['text-primary']} fw-bold`}></div></div>}
                </div>
            </div>
        </div>

    )
}

export default SummaryVacacionesArg
