import * as A from "fp-ts/lib/Array";
import React, { Children, FC, useState, useEffect } from "react";
import { SelectModelProps } from "../../../utilities/interfaces";
import SelectModelTable from "./selectModelTable";

const SelectModelModal: FC<SelectModelProps> = ({ data, setDirectReportsList, setShowTable, hasKpiByReport, setHasKpiByReport, setSuccess, lastAssignation }) => {
    const [modelCheckedPerReport, setModelCheckedPerReport] = useState<string[]>(A.makeBy(data.directReports.length, () => ""));
    const [everyoneUnder, setEveryoneUnder] = useState<boolean[]>(A.makeBy(data.directReports.length, () => lastAssignation));
    const [preview, setPreview] = useState<boolean>(false);
    const [anyKpi, setAnyKpi] = useState<boolean>(false);
    const handleClick = () => {
        setDirectReportsList((oldDIrectReportList) => oldDIrectReportList.map((d, i) => {
            return { ...d, allDependencies: everyoneUnder[i], modelId: modelCheckedPerReport[i], asignationIndicators: data.models.filter((m) => m.modelId === modelCheckedPerReport[i])[0].indicators.map((ind) => {
                return {
                    indicatorCode: ind.indicatorCode,
                    indicatorWeight: ind.indicatorWeight,
                    indicatorType: ind.indicatorType,
                }
            }) }
        }));
        anyKpi ? setShowTable(true) : setSuccess({ valid: 1, message: "Éxito" });;
        document.getElementById("closeSelectModelModal")?.click();
    };

    useEffect(() => {
        setAnyKpi(hasKpiByReport.some((k) => k));
    }, [hasKpiByReport]);

    return (
        <div className="modal fade" id="select-model" tabIndex={-1} aria-labelledby="seleccione-modelo" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                        <button id="closeSelectModelModal" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body py-4">
                        <h2 className="h5">Seleccionar Modelo</h2>
                        <div className="border border-primary p-3 rounded-4 fs-6 text-uppercase my-4">
                            {Children.toArray(data.models.map((model) => {
                                return (
                                    <div>
                                        <span className="align-self-center me-5 color-primary d-block mb-2 d-md-inline-block mb-md-0">{model.modelDescr}</span>
                                        <span className="fw-normal">
                                            {Children.toArray(model.indicators.sort((a,b)=> parseInt(b.indicatorWeight) - parseInt(a.indicatorWeight)).map((indicator, idx, indArr) => {
                                                return (
                                                    <>
                                                        <span className="color-primary">{indicator.indicatorCode}</span>
                                                        <span className="color-orange">{" = "}</span>
                                                        <span className="color-normal">{`${indicator.indicatorWeight}%`}</span>
                                                        {idx < indArr.length - 1 && <span className="color-orange">{" + "}</span>}
                                                    </>
                                                )
                                            }))}
                                        </span>
                                    </div>
                                )
                            }))}
                        </div>
                        <SelectModelTable
                            directReports={data.directReports}
                            models={data.models}
                            hasKpiByReport={hasKpiByReport}
                            setHasKpiByReport={setHasKpiByReport}
                            modelCheckedPerReport={modelCheckedPerReport}
                            setModelCheckedPerReport={setModelCheckedPerReport}
                            preview={preview}
                            setPreview={setPreview}
                            everyoneUnder={everyoneUnder}
                            setEveryoneUnder={setEveryoneUnder}
                            lastAssignation={lastAssignation}
                        />
                        {preview && anyKpi &&
                            <div>
                                <p>
                                    <i className="fa fa-exclamation-triangle fa-fw fa-lg me-1 color-red" aria-hidden="true"/>
                                    {`A continuación, por favor cargar los KPI para modelos con FRI.`}
                                </p>
                            </div>
                        }
                    </div>
                    <div className="modal-footer border-0 pt-0 pb-5 justify-content-center gap-2">
                        <button
                            disabled={modelCheckedPerReport.some((m) => m === "")}
                            type="button"
                            className="btn btn-degradado order-md-last col-12 col-md-auto my-auto px-5"
                            onClick={() => !preview ? setPreview(true) : handleClick()}
                        >
                            {!preview ? "Aceptar " : anyKpi ? "Definir KPI " : "Continuar "}
                            <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"/>
                        </button>
                        {preview ?
                            <button
                                type="button"
                                className="btn btn-link col-12 col-md-auto my-auto px-5"
                                onClick={() => setPreview(false)}
                            >
                                {"Volver "}
                            </button>
                        :
                            <button type="button" className="btn btn-primary col-12 col-md-auto my-auto px-5" data-bs-dismiss="modal">
                                {"Cancelar "}
                                <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"/>
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SelectModelModal;