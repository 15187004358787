import React from 'react';

//componente de loading que contiene un spinner de carga que rota

const Loading = () => {
    return (
        <main id="main" className="wrapper min-vh-100 text-center mt-5">
            <div className="custom_spinner"/>
            <h2 className="h5 mb-3">Cargando</h2>
        </main>
    );
}

export default Loading;
