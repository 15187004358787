import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import React, { Children, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import baseDatosService from "../services/bd-service";
import { formatDate } from "../services/utiles";

const IndexNoticias = () => {
  const [noticias, setNoticias] = useState(null);
  const [grupos, setGrupos] = useState(null);
  const [estadoFiltro, setEstadoFiltro] = useState("");
  const [grupoFiltro, setGrupoFiltro] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [noticiaDel, setNoticiaDel] = useState(null);
  const [noticiaUndo, setNoticiaUndo] = useState(null);
  const [statusDel, setStatusDel] = useState(null);
  const [statusUndo, setStatusUndo] = useState(null);
  const [textoRespuesta, setTextoRespuesta] = useState(null);
  const [respuestaSolicitudValid, setRespuestaSolicitudValid] = useState(false);
  //paginador
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [matrix, setMatrix] = useState([]);
  const [items, setItems] = useState(null);
  const itemsPerPage = 5;
  const rowAutor = [];
  const rowFechas = [];
  const rowTitulo = [];
  const rowGrupos = [];
  const rowEstado = [];
  const rowIcons = [];
  const rowAccionEliminar = [];
  const [resetOffset, setResetOffset] = useState(false);
  const transpose = (matrix) => matrix.reduce((prev, next) => next.map((item, i) => (prev[i] || []).concat(next[i])),[]);
  useEffect(() => {
    localStorage.removeItem("canal-grupos");
    localStorage.removeItem("canal-noticias");
  }, []);

  useEffect(() => {
    if (items !== "error" && Array.isArray(items) && items.length > 0 && items) {
      let newMatrix = items.map((item) => {
        return item.rows;
      });
      setMatrix(transpose(newMatrix));
    }
  }, [items]);

  useEffect(() => {
    if (matrix !== "error" && Array.isArray(matrix) && matrix.length > 0) {
      let temp = matrix.slice(itemOffset, itemOffset + itemsPerPage);
      setCurrentItems(temp);
      setPageCount(Math.ceil(matrix.length / itemsPerPage));
    } else if (matrix !== "error" && Array.isArray(matrix) && matrix.length === 0) {
      setCurrentItems(null);
      setPageCount(0);
    }
  }, [itemOffset, itemsPerPage, matrix]);

  useEffect(() => {
    if (resetOffset) {
      setItemOffset(0);
      setResetOffset(false);
    }
  }, [resetOffset]);


  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % matrix.length;
    setItemOffset(newOffset);
  };

  const listadoGrupos = (noticia) => {
    const grupos = JSON.parse(JSON.stringify(noticia.Groups.reverse()));
    return <td>
      {grupos.map((grupo, index) => {
        if (index === noticia.Groups.length -1) return <>{grupo.title}</>;
          return <>{grupo.title}, </>;
        })}
    </td>;
  }

  //fin paginador
  useEffect(() => {
    baseDatosService.getAllNoticias(
      startDate,
      endDate,
      estadoFiltro,
      grupoFiltro,
      setNoticias,
      setGrupos
    );
  }, [startDate, endDate, estadoFiltro, grupoFiltro]);

  useEffect(() => {
    setGrupoFiltro(grupoFiltro);
  }, [grupos]);
  useEffect(() => {
    if (noticias) {
      noticias.map((e) => {
        rowTitulo.push(e.title);
        rowFechas.push(
          formatDate(e.start_date) + " al " + formatDate(e.end_date)
        );
        rowGrupos.push(listadoGrupos(e));
        rowAutor.push(e.created_by);
        rowIcons.push(
          <div className="d-flex justify-content-evenly align-items-center mb-1">
            <i className="fa fa-eye fa-fw color-orange" aria-hidden="true" title="Vistas"></i>
            <span title="Vistas">{e.total_views}</span>
            <i className="fa fa-heart fa-fw color-orange" aria-hidden="true" title="Likes"></i>
            <span title="Likes">{e.total_likes}</span>
            <i className="fa fa-comment fa-fw color-orange" aria-hidden="true" title="Comentarios"></i>
            <span title="Comentarios">{e.total_comments}</span>
          </div>
        );
        if (e.status === 'draft') {
          rowEstado.push("Borrador");
          rowAccionEliminar.push(
            <div className="d-flex justify-content-evenly align-items-center mb-1">
              <Link title="Despublicar" className="m-0" to="" onClick={(ev) => { ev.preventDefault(); setNoticiaDel(e.id);}}>
                <i className="fa fa-trash-o fa-fw" aria-hidden="true"></i>
              </Link>
              <Link title="Modificar" className="m-0" to={"/modificar-noticias/" + e.id}>
                <i className="fa fa-pencil fa-fw" aria-hidden="true"></i>
              </Link>
              <Link title="Descargar Resumen" className="m-0" to="#" onClick={(ev) => { ev.preventDefault(); baseDatosService.getExcelResume(e.id);}}>
                <i className="fa fa-file-excel-o fa-fw color-orange" aria-hidden="true"></i>
              </Link>
            </div>
          );
        } else if (e.status === 'published') {
          rowEstado.push("Publicada")
          rowAccionEliminar.push(
            <div className="d-flex justify-content-evenly align-items-center mb-1">
              <Link title="Despublicar" className="m-0" to="" onClick={(ev) => { ev.preventDefault(); setNoticiaDel(e.id);}}>
                <i className="fa fa-trash-o fa-fw" aria-hidden="true"></i>
              </Link>
              <Link title="Modificar" className="m-0" to={"/modificar-noticias/" + e.id}>
                <i className="fa fa-pencil fa-fw" aria-hidden="true"></i>
              </Link>
              <Link title="Descargar Resumen" className="m-0" to="#" onClick={(ev) => { ev.preventDefault(); baseDatosService.getExcelResume(e.id);}}>
                <i className="fa fa-file-excel-o fa-fw color-orange" aria-hidden="true"></i>
              </Link>
            </div>
          );
        } else {
          rowEstado.push("Despublicada");
          rowAccionEliminar.push(
            <div className="d-flex justify-content-evenly align-items-center mb-1">
              <Link title="Publicar" className="m-0" to="" onClick={(ev) => { ev.preventDefault(); setNoticiaUndo(e.id);}}>
                <i className="fa fa-undo fa-fw" aria-hidden="true"></i>
              </Link>
              <Link title="Modificar" className="m-0" to={"/modificar-noticias/" + e.id}>
                <i className="fa fa-pencil fa-fw" aria-hidden="true"></i>
              </Link>
              <Link title="Descargar Resumen" className="m-0" to="#" onClick={(ev) => { ev.preventDefault(); baseDatosService.getExcelResume(e.id);}}>
                <i className="fa fa-file-excel-o fa-fw color-orange" aria-hidden="true"></i>
              </Link>
            </div>
          );
        }
      });
      setItems([
        {
          title: "Vigencia",
          rows: rowFechas,
        },
        {
          title: "Título",
          rows: rowTitulo,
        },
        {
          title: "Grupos",
          rows: rowGrupos,
        },
        {
          title: "Autor",
          rows: rowAutor,
        },
        {
          title: "Estado",
          rows: rowEstado,
        },
        {
          title: "",
          rows: rowIcons,
        },
        {
          title: "Acciones",
          rows: rowAccionEliminar,
        },
      ]);
    }
  }, [noticias]);

  useEffect(() => {
    if (noticiaDel) {
      baseDatosService.deleteNoticia(noticiaDel, setStatusDel);
    }
  }, [noticiaDel]);

  useEffect(() => {
    if (noticiaUndo) {
      baseDatosService.undoNoticia(noticiaUndo, setStatusUndo);
    }
  }, [noticiaUndo]);

  useEffect(() => {
    if (statusDel) {
      if (statusDel === "Noticia despublicada con exito!") {
        setTextoRespuesta("Noticia despublicada con exito!");
        setRespuestaSolicitudValid(true);
        setStatusDel(null);
      }
    }
    if (statusUndo) {
      if (statusUndo === "Noticia publicada con exito!") {
        setRespuestaSolicitudValid(true);
        setTextoRespuesta("Noticia publicada con exito!");
        setStatusUndo(null);
      }
    }
  }, [statusDel, statusUndo]);
  return <>
    <main className="wrapper d-flex">
      <div style={{ width: "100%" }} id="content">
        <nav className="breadcrumbs" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/canal-de-comunicacion"}>
                <i className="fa fa-home" aria-hidden="true"></i> Noticias
              </Link>
            </li>
          </ol>
        </nav>
        <section className="gestion-de-noticias">
          <h1>Gestión de Noticias</h1>
          <div className="box mb-4">
            <div className="tit-group d-flex justify-content-between">
              <h2 className="h4 tit-section">Noticias Publicadas</h2>
              <Link to={"/crear-noticias"} className="mt-2 text-decoration-none fw-bold text-uppercase">
                <span className="d-none d-md-inline-block">Nueva noticia</span>{" "}
                <i className="fa fa-plus-square-o fa-lg ms-2" aria-hidden="true" />
              </Link>
            </div>
            <div className="d-flex flex-wrap justify-content-around">
                <div className="mx-2 my-2 mb-3 col-12 col-md-3">
                  Estado:
                  <select
                    onChange={(e) => {
                      if (e.target.value === undefined) {
                        setEstadoFiltro("");
                      } else {
                        setEstadoFiltro(e.target.value);
                      }
                    }}
                    id="inputState"
                    className="form-select"
                    defaultValue={estadoFiltro || ""}
                  >
                    <option value="">
                      Estado de la Publicación
                    </option>
                    <option value='published'>Publicada</option>
                    <option value='unpublished'>Despublicada</option>
                    <option value='draft'>Borrador</option>
                  </select>
                </div>
                <div className="mx-2 my-2 mb-3 col-12 col-md-2 canal-date-filter">
                  Desde:
                  <input type="date" placeholder=""
                    className="form-control d-flex justify-content-center align-items-center"
                    defaultValue={startDate || ""}
                    onChange={(e) => { setStartDate(e.target.value); }}
                  />
                </div>
                <div className="mx-2 my-2 mb-3 col-12 col-md-2 canal-date-filter">
                  Hasta:
                  <input type="date" placeholder=""
                    className="form-control d-flex justify-content-center align-items-center"
                    defaultValue={endDate || ""}
                    onChange={(e) => { setEndDate(e.target.value); }}
                  />
                </div>
                <div className="mx-2 my-2 mb-3 col-12 col-md-2">
                  Grupo:
                  <select
                    onChange={(e) => {
                      if (e.target.value === undefined) {
                        setGrupoFiltro(localStorage.getItem("grupoFiltro") || "");
                      } else {
                        setGrupoFiltro(e.target.value);
                      }
                    }}
                    id="inputState"
                    className="form-select"
                    defaultValue={grupoFiltro}
                  >
                    <option value="">
                      Todos los Grupos
                    </option>
                  {grupos && grupos.map((grupo, index) => {
                      if (index === 0) {
                        return <option key={"array1" + index} value={parseInt(grupo.id)}>{grupo.title}</option>
                      }
                      else if (index !== 0 && grupos[index - 1].id !== grupos[index].id) {
                          return <option key={"array2" + index} value={parseInt(grupo.id)}>{grupo.title}</option>
                        }
                      }
                    )}
                  </select>
                </div>
            </div>

            <table className="table table-even table-section table-borderless align-middle d-none d-lg-table">
              <thead>
                <tr>
                  {items
                    ? Children.toArray(
                        items.map((column, index) => {
                          return (
                            <th key={"array3" + index} className="text-nowrap" >{column.title}</th>
                          );
                        })
                      )
                    : null}
                </tr>
              </thead>
              <tbody>
                {currentItems ? (
                  Children.toArray(
                    currentItems.map((rows, index) => {
                      return (
                        <tr key={"array4" + index}>
                          {Children.toArray(
                            rows.map((value, index) => {
                              return (
                                <td key={"array5" + index} >{value}</td>
                              );

                            })
                          )}
                        </tr>
                      );
                    })
                  )
                ) : (
                  <tr>
                    <td>Sin resultados</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="accordion accordion-flush d-block d-lg-none accordion-light accordion-table">
              {currentItems ? (
                Children.toArray(
                  currentItems.map((rows, i) => {
                    return (
                      <>
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id={"acordeon-" + i}
                          >
                            <button
                              className={
                                i === 0
                                  ? "accordion-button"
                                  : "accordion-button collapsed"
                              }
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={"#acordeon-item-" + i}
                              aria-expanded="true"
                              aria-controls={"accordion-item-" + i}
                            >
                              <strong className="text-uppercase">
                                Vigencia
                              </strong>
                              {rows[0]}
                            </button>
                          </h2>
                          <div
                            id={"acordeon-item-" + i}
                            className={
                              i === 0
                                ? "accordion-collapse collapse show"
                                : "accordion-collapse collapse "
                            }
                            aria-labelledby={"acordeon-" + i}
                          >
                            <div className="accordion-body p-0">
                              <div className="data-list-box data-list-box-even">
                                <ul className="data-list-section--item list-unstyled">
                                  {Children.toArray(
                                    rows.map((value, index) => {
                                      return (
                                        <li key={"array6" + index}>
                                          <span className="data-list-box__title col-12 col-sm-4">
                                            {items[index].title}
                                          </span>
                                          <span className="data-list-box__text">
                                            {value}
                                          </span>
                                        </li>
                                      );
                                    })
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                )
              ) : (
                <div>
                  <p>SIN RESULTADOS</p>
                </div>
              )}
            </div>
            {currentItems &&
              <nav className="mt-5" aria-label="Paginación">
                <ReactPaginate
                  id={"paginationnav"}
                  className="pagination justify-content-center align-items-center"
                  breakLabel=" ... "
                  nextLabel=" > "
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={0}
                  marginPagesDisplayed={1}
                  pageCount={pageCount}
                  previousLabel=" < "
                  renderOnZeroPageCount={null}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                  breakLinkClassName={"page-link"}
                  pageLinkClassName={"page-link"}
                  pageClassName={"page-item"}
                  breakClassName={"page-item"}
                  previousClassName={"page-item"}
                  nextClassName={"page-item"}
                  activeClassName={"page-item active"}
                  activeLinkClassName={"page-link active"}
                />
              </nav>
            }
            <div className="modal fade" id="success" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-check-circle-o color-orange fa-3x mb-2"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">Firma enviada correctamente</h2>
                    <button type="button" className="btn btn-degradado mt-4" data-bs-dismiss="modal">
                      Aceptar<i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="alert"
              tabIndex="-1"
              aria-labelledby="alert"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-exclamation-triangle color-red fa-3x mb-2"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">Debe descargar el documento</h2>
                    <button
                      type="button"
                      className="btn btn-degradado mt-4"
                      data-bs-dismiss="modal"
                    >
                      Aceptar{" "}
                      <i
                        className="fa fa-chevron-right fa-fw fa-xs"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="no-existen"
              tabIndex="-1"
              aria-labelledby="no-existen"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <h2 className="h5">No existen solicitudes pendientes</h2>
                    <button
                      type="button"
                      className="btn btn-degradado mt-4"
                      data-bs-dismiss="modal"
                    >
                      Aceptar{" "}
                      <i
                        className="fa fa-chevron-right fa-fw fa-xs"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
    {respuestaSolicitudValid &&
      <div className="col">
        <div
          className="modal fade show"
          id="foto-credencial"
          tabIndex={"-1"}
          aria-labelledby="enviar-por-correo"
          aria-modal="true"
          role="dialog"
          style={{ display: "block", textAlign: "left" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0 pb-0">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    const refresh = async () => {
                      localStorage.removeItem("canal-grupos");
                      localStorage.removeItem("canal-noticias");
                      await baseDatosService.getAllNoticias(
                        "",
                        "",
                        "",
                        "",
                        setNoticias,
                        setGrupos
                      );
                      await baseDatosService.getAllNoticias(
                        startDate,
                        endDate,
                        estadoFiltro,
                        grupoFiltro,
                        setNoticias,
                        setGrupos
                      );
                      setRespuestaSolicitudValid(false);
                    };
                    refresh();
                  }}
                ></button>
              </div>
              <div className="modal-body text-center py-4 pt-0 pb-1">
                <i
                  className="fa fa-check-circle-o color-orange fa-3x mb-2"
                  aria-hidden="true"
                ></i>

                <h2 className="h5">
                  {textoRespuesta}
                  <br />
                </h2>
              </div>
              <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                <button
                  onClick={() => {
                    const refresh = async () => {
                      localStorage.removeItem("canal-grupos");
                      localStorage.removeItem("canal-noticias");
                      await baseDatosService.getAllNoticias(
                        "",
                        "",
                        "",
                        "",
                        setNoticias,
                        setGrupos
                      );
                      await baseDatosService.getAllNoticias(
                        startDate,
                        endDate,
                        estadoFiltro,
                        grupoFiltro,
                        setNoticias,
                        setGrupos
                      );
                      setRespuestaSolicitudValid(false);
                    };
                    refresh();
                  }}
                  type="button"
                  className="btn btn-degradado px-5"
                  data-bs-dismiss="modal"
                >
                  Aceptar{" "}
                  <i
                    className="fa fa-chevron-right fa-fw fa-xs"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  </>;
};
export default IndexNoticias;
