import React from "react";
import { useCencoTalent } from "../../hooks/useCencoTalent";
import { JobCardIconInfo } from "../ui/JobCardIconInfo";
import {
    FaBriefcase,
    FaBuilding,
    FaCalendarAlt,
    FaFileAlt,
    FaMapMarkerAlt,
    FaRegClock,
    FaUniversalAccess,
    FaUsers,
} from "react-icons/fa";

export const DetailsLeftWeb = () => {
    const { activeJob } = useCencoTalent();

    return (
        <div className="col-12 col-md-4 d-md-flex mb-5 pb-5">
            <div className="d-none d-md-block">
                <h2 className="h3 color-normal mb-4">Detalles de la oferta</h2>
                <ul className="list-unstyled fs-6">
                    {activeJob?.disability && (
                        <JobCardIconInfo
                            text="Apto para personas con discapacidad"
                            icon={
                                <FaUniversalAccess className="color-orange" />
                            }
                            className="mb-1"
                        />
                    )}

                    <JobCardIconInfo
                        text={activeJob?.workingHours ?? ""}
                        icon={<FaCalendarAlt className="color-orange" />}
                        className="mb-1"
                    />

                    <JobCardIconInfo
                        text={activeJob?.area ?? ""}
                        icon={<FaBriefcase className="color-orange" />}
                        className="mb-1"
                    />

                    <JobCardIconInfo
                        text={
                            activeJob?.country ? 
                                activeJob?.address ? 
                                    `${activeJob?.country}\n${activeJob?.address}`
                                    : activeJob?.country 
                                : ""
                        }
                        icon={<FaMapMarkerAlt className="color-orange" />}
                        className="mb-1"
                    />

                    <JobCardIconInfo
                        text={activeJob?.employmentType ?? ""}
                        icon={<FaFileAlt className="color-orange" />}
                        className="mb-1"
                    />

                    <JobCardIconInfo
                        text={activeJob?.flag ?? ""}
                        icon={<FaBuilding className="color-orange" />}
                        className="mb-1"
                    />

                    <JobCardIconInfo
                        text={activeJob?.workModality ?? ""}
                        icon={<FaUsers className="color-orange" />}
                        className="mb-1"
                    />

                    <JobCardIconInfo
                        text={activeJob?.datetimeCreation ?? ""}
                        icon={<FaRegClock className="color-orange" />}
                        className="mb-1"
                    />
                </ul>
            </div>
        </div>
    );
};
