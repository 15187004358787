/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { BiSortDown, BiSortUp } from 'react-icons/bi';
import { BsExclamationCircle } from 'react-icons/bs';
import { ImEye } from 'react-icons/im';
import { MdCloudDownload } from 'react-icons/md';
import { BiChevronRight, BiChevronLeft } from 'react-icons/bi';
import { FaRegCheckCircle } from 'react-icons/fa';
import Tippy from '@tippyjs/react';
import { RiCloseCircleLine } from 'react-icons/ri';
import 'tippy.js/dist/tippy.css';
import RecibosUYService from '../../services/recibos-uy/receipts-service';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import { TbEyeCheck, TbEyeOff } from 'react-icons/tb';
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment';
import './receipts-table-styles.css';


const SallaryReceiptsTable = () => {

  const [data, setData] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const [periodOrder, setPeriodOrder] = useState('ASC');
  const currentPage = useRef(1);
  let docs = Array(data.length).fill().map((_, i) => i + 1);
  const [docsDisplay, setDocsDisplay] = useState();
  const totalPageCount = useRef(Math.ceil(data.length / docsDisplay));
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [indexOfLastRecord, setIndexOfLastRecord] = useState(currentPage.current * docsDisplay);
  const [indexOfFirstRecord, setIndexofFirstRecord] = useState(indexOfLastRecord - docsDisplay);
  const [currentDocs, setCurrentDocs] = useState(data.slice(indexOfFirstRecord, indexOfLastRecord));
  const [openFile, setOpenFile] = useState({ active: false, data: null, id: null, company: null });
  const [signConform, setSignConform] = useState({ active: false, data: null, id: null, company: null });
  const [signDisconform, setSignDisconform] = useState({ active: false, data: null, id: null, company: null });
  const [pin, setPin] = useState('');
  const [conform, setConform] = useState(null);
  const [comments, setComments] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const [signatureSent, setSignatureSent] = useState(false);
  const [canSign, setCanSign] = useState(null);
  const [isReceipt, setIsReceipt] = useState(null);
  const [visible, setVisible] = useState(true);
  const [inputType, setInputType] = useState("");
  const [loadingIndices, setLoadingIndices] = useState(new Set());
  const [modalMetadata, setModalMetadata] = useState({ des: '', periodo: '', estado: '',sig: null,isR:null});
  const [serverError, setServerError] = useState(null);


  useEffect(() => {
    getReceipts();
    if (fetched) {
      console.log("fetched")
    }
  }, [fetched])

  useEffect(() => {
    totalPageCount.current = Math.ceil(data.length / docsDisplay)
    setIndexOfLastRecord(currentPage.current * docsDisplay);
    setIndexofFirstRecord(indexOfLastRecord - docsDisplay);
    setCurrentDocs(data.slice(indexOfFirstRecord, indexOfLastRecord))
  }, [data, docsDisplay, indexOfFirstRecord, indexOfLastRecord, currentPage])

  useEffect(() => {
    if (next) {
      totalPageCount.current = Math.ceil(data.length / docsDisplay)
      currentPage.current = currentPage.current + 1;
      setIndexOfLastRecord(currentPage.current * docsDisplay);
      setIndexofFirstRecord(indexOfLastRecord - docsDisplay);
      setCurrentDocs(data.slice(indexOfFirstRecord, indexOfLastRecord))
      setNext(false);
    }
  }, [next, docsDisplay, indexOfFirstRecord, indexOfLastRecord, data])

  useEffect(() => {
    if (prev) {
      totalPageCount.current = Math.ceil(data.length / docsDisplay)
      currentPage.current = currentPage.current - 1;
      setIndexOfLastRecord(currentPage.current * docsDisplay);
      setIndexofFirstRecord(indexOfLastRecord - docsDisplay);
      setCurrentDocs(data.slice(indexOfFirstRecord, indexOfLastRecord))
      setPrev(false);
    }
  }, [prev, docsDisplay, indexOfFirstRecord, indexOfLastRecord, data])

  useEffect(() => {
    if (!visible) {
      setInputType("text");
    } else {
      setInputType("password");
    }
  }, [visible])

  const sortPeriod = () => {
    if (periodOrder === "ASC") {
      const sorted = currentDocs.sort((a, b) => moment(a.period, "MM-YYYY").unix() - moment(b.period, "MM-YYYY").unix())
      setPeriodOrder("DESC")
    } else {
      const sorted = currentDocs.sort((a, b) => moment(b.period, "MM-YYYY").unix() - moment(a.period, "MM-YYYY").unix())
      setPeriodOrder("ASC")
    }
  }
  const getReceipts = () => {
    setLoading(true);
    RecibosUYService.ObtenerRecibos(setFetched, setLoading, setData, setNoData, setDocsDisplay, setServerError);
  }
  const getReceipt = (element, index) => {
    setModalMetadata({ des: element.batchName, periodo: element.period, estado: element.status,sig:element.signatureInProgress,isR:element.isReceipt })
    setLoadingIndices((prev) => new Set([...prev, index]));
    RecibosUYService.ObtenerReciboId(element, index, setOpenFile, setCanSign, setLoadingIndices, setIsReceipt);
  }
  const download = (id, name, company) => {
    RecibosUYService.downloadDoc(id, name, company);
  }

  const sendSignature = (param, company) => {

    const fd = { signaturePin: pin, signedInAccordance: conform, reason: comments, company: company };
    RecibosUYService.enviarSignature(param, setLoadingSave, fd, setSignatureSent, setError, setErrorMessage)
  }

  const handleSuccess = () => {
    Swal.fire({
      allowOutsideClick: false,
      position: 'center',
      title: '<div style="text-align: center; color: green; font-size: 16px">Exitoso</div>',
      html: '<div style="text-align: center; font-size: 15px">Documento firmado correctamente</div>',
      confirmButtonColor: '#0169b5',
    }).then((result) => {
      if (result.isConfirmed) {
        setSignatureSent(false);
        setOpenFile({ active: false, data: null, id: null, company: null });
        setSignConform({ active: false, data: null });
        setComments('');
        setPin('');
        setSignConform({ active: false, data: null });
        setSignDisconform({ active: false, data: null });
        setCanSign(null);
        setIsReceipt(null);
        getReceipts();
        
        // Volver a refrescar 5seg despues.
        setTimeout(() => {
        getReceipts();
        }, 5000);
      }
    })
  }
  const handleError = () => {
    Swal.fire({
      allowOutsideClick: false,
      position: 'center',
      title: '<div style="text-align: center; color: red; font-size: 16px">Error</div>',
      html: `<div style="text-align: center; font-size: 15px">${errorMessage}</div>`,
      confirmButtonColor: '#0169b5',
    })
    setComments('');
    setPin('');
    setErrorMessage('');
    setError(false);

  }


  return (
    <>
      {loading ? <div className="full-spinner"><div className="spinner-border text-primary"></div></div>
        : noData && !serverError ? <div className="alert alert-info alert-dismissible fade show text-center" role="alert">
          <BsExclamationCircle className="mb-1"/>  No se encontraron datos asociados
        </div> :
            data?.length === 0 && !serverError ? <div className="alert alert-info alert-dismissible fade show text-center" role="alert">
            <BsExclamationCircle className="mb-1"/>  No tenés recibos de sueldo para visualizar.
          </div>
            : serverError !== null ? <div className="alert alert-danger alert-dismissible fade show text-center" role="alert">
            <BsExclamationCircle className="mb-1"/>  {serverError}
          </div>
            : <div className="p-0">
          <div className='page-container justify-content-between'>
            <div className=''>
              <div className="pagination m-1" >
                <div className='pag-label'>Página</div>
                <button disabled={currentPage.current <= 1} onClick={() => setPrev(true)} className='page-btn'><BiChevronLeft className="chevron" /></button>
                <input className='page-input' type="text" disabled value={currentPage.current}>
                </input>
                <button disabled={currentPage.current === totalPageCount.current} className='page-btn' onClick={() => setNext(true)}><BiChevronRight className="chevron" /></button>
                <div className='page-total'> de {totalPageCount.current} </div>
                <div className='mostrar'> | Mostrar </div>
                <div className=''>
                  <select className='form-select doc-showing mb-0' value={docsDisplay} onChange={e => setDocsDisplay(e.target.value)}>
                    {docs.map((el, n) => {
                      return (
                        <option key={n} value={el}>{el}</option>
                      )
                    })}
                  </select>
                </div>
                <div className='docs-label'> Documentos</div>
              </div>
            </div>

            <button className="btn btn-outline-primary btn-icon-hover" onClick={() => getReceipts()}>
                <span className="btn-hidden-text-sm">Volver a cargar</span>
                <i className="color-primary-strong fa fa-rotate-right fa-fw fa-lg" onClick={() => getReceipts()}></i>
            </button>

          </div>
          <div className='card-box'>
          <h3>Detalle  <div className='title-underlined'></div></h3>
            <table className="table table-borderless table-even table-section desktop-table-recibos">
              <thead>
                <tr>
                  <th scope="col" className="cap">Descripción</th>
                  <th scope="col" className="cap">
                    <div className='icon-container'> Periodo
                      {periodOrder === "ASC" ? <Tippy className="tool-tip" content="Ordenar"><div onClick={sortPeriod} className="color-orange"><BiSortDown className='sort-icon' /></div></Tippy> : periodOrder === "DESC" ? <Tippy content="Ordenar"><div className="color-orange"><BiSortUp onClick={sortPeriod} className='sort-icon' /></div></Tippy> : null} </div></th>
                  {/* <th scope="col"> <div className='icon-container'>Fecha de Emisión {emittedOrder === "ASC" ? <Tippy content="Ordenar"><div onClick={sortEmitted}><BiSortDown className='sort-icon' /></div></Tippy> : emittedOrder === "DESC" ? <Tippy content="Ordenar"><div><BiSortUp onClick={sortEmitted} className='sort-icon' /> </div></Tippy> : null}</div></th> */}
                  <th scope="col" className="cap">Estado</th>
                  <th scope="col" className="cap">Acciones</th>
                  <th scope="col" className="cap text-center">Firmar</th>
                </tr>
              </thead>
              <tbody>
                {currentDocs.map((el, i) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <tr className="align-middle">
                      <td key={i}>{el.name}</td>
                      <td key={i}>{el.period}</td>
                      <td key={i}><div className={`layer-estado ${!el.isReceipt || el.status === "Enviado (No firmable)" ? "no-aplica" : el.status === "Conforme" && !el.signatureInProgress ? "firmado" : el.status === "No Conforme" ? "no-conform" : el.status === "Conforme" || el.status === "No Conforme" && el.signatureInProgress ? "inprocess" : "pendiente"}`}>{!el.isReceipt || el.status === "Enviado (No firmable)" ? "No Aplica" : el.status === "Conforme" && !el.signatureInProgress ? "firmado" : el.status === "No Conforme" ? "No Conforme" : el.signatureInProgress ? "En Proceso" : "Pendiente"}</div></td>
                      <td key={i}><div className='icon-container'>
                        <Tippy key={i} className="tool-tip" content="Ver archivo"><div onClick={() => getReceipt(el, i)}> {loadingIndices.has(i) ? <div className="spinner-border spinner-border-sm color-primary"></div> : <ImEye className='table-icon color-orange' />}</div></Tippy>
                        <Tippy key={i} className="tool-tip" content="Descargar archivo"><div onClick={() => download(el.id, el.name, el.company)}><MdCloudDownload className='table-icon color-orange' /></div></Tippy>
                      </div></td>
                      <td className="text-center p-0" key={i}>
                        {el.isReceipt && el.canSign ?
                          <>
                            <div className='d-flex justify-content-center'>
                              <button className="button save no-conform-btn" disabled={!el.canSign} onClick={() => { setSignConform({ active: true, data: el.id, company: el.company }); setConform(true) }}>Conforme <i className="fa fa-chevron-right fa-fw fa-xs"></i></button>
                              <button className="button reject no-conform-btn" disabled={!el.canSign} onClick={() => { setSignDisconform({ active: true, data: el.id, company: el.company }); setConform(false) }} > NO Conforme <i className="fa fa-chevron-right fa-fw fa-xs"></i></button>
                            </div>
                          </> : null}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            {/*responsive-table */}
            {currentDocs.length > 0 ?
              <Accordion className="accordion accordion-flush accordion-light accordion-table d-md-none" defaultActiveKey="0">
                {currentDocs.map((el, i) => {
                  return (
                    <Accordion.Item key={i} eventKey={i}>
                      <Accordion.Header className="accordion-header" id="accordion">
                        <strong>Descripción</strong> {el.name}</Accordion.Header>
                      <Accordion.Body className="accordion-body p-0">
                        <div className="data-list-box data-list-box-even">
                          <ul className="data-list-section--item list-unstyled">
                            <li>
                              <span className="data-list-box__title">Periodo</span>
                              <span className="data-list-box__text">{el.period}</span>
                            </li>
                            <li>
                              <span className="data-list-box__title">Estado</span>
                              <div className={`layer-estado ${!el.isReceipt || el.status === "Enviado (No firmable)" ? "no-aplica" : el.status === "Conforme" && !el.signatureInProgress ? "firmado" : el.status === "No Conforme" ? "no-conform" : el.status === "Conforme" || el.status === "No Conforme" && el.signatureInProgress ? "inprocess" : "pendiente"}`}>
                                {!el.isReceipt || el.status === "Enviado (No firmable)" ? "No Aplica" : el.status === "Conforme" && !el.signatureInProgress ? "firmado" : el.status === "No Conforme" ? "No Conforme" : el.signatureInProgress ? "En Proceso" : "Pendiente"}
                              </div>
                            </li>
                            <li>
                              <span className="data-list-box__title">Acciones</span>
                              <span className="data-list-box__text">
                                <div className='icon-container'>
                                  <div onClick={() => getReceipt(el, i)}> {loadingIndices.has(i) ? <div className="spinner-border spinner-border-sm color-primary"></div> : <ImEye className='table-icon color-orange' />}</div>
                                  <div onClick={() => download(el.id, el.name, el.company)}><MdCloudDownload className='table-icon color-orange' /></div>
                                </div>

                              </span>
                            </li>

                            <li>
                             
                              {el.isReceipt && el.canSign ?
                               <>
                              <span className="data-list-box__title">Firmar</span>
                              <span className="data-list-box__text">
                           
                            <div>
                              <button className="button save" disabled={!el.canSign} onClick={() => { setSignConform({ active: true, data: el.id, company: el.company }); setConform(true) }}>Conforme <i className="fa fa-chevron-right fa-fw fa-xs"></i></button>
                              <button className="button reject no-conform-btn" disabled={!el.canSign} onClick={() => { setSignDisconform({ active: true, data: el.id, company: el.company }); setConform(false) }} > NO Conforme <i className="fa fa-chevron-right fa-fw fa-xs"></i></button>
                            </div>
                              </span>
                              </>
                                : null}
                            </li>
                          </ul>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  )
                })}
              </Accordion> : null}
            {/*responsive-table */}


            {!signatureSent ? null : handleSuccess()}
            {!error ? null : handleError()}
          </div>
          <Modal size="lg" show={openFile.active} data={openFile.data}>
            <Modal.Header closeButton onClick={() => { setOpenFile({ active: false, data: null, id: null, company: null }); setCanSign(null); setIsReceipt(null); setModalMetadata({ des: '', periodo: '', estado: '', sig:null,isR:null}) }}></Modal.Header>
            <div className="container p-0">
              <div className="row p-0">
                <div className="col-lg-8 col-sm-12 p-0">
                  <div className="file-container-if">

                    <object data={openFile.data} type="application/pdf" style={{ width: "100%", height: "400px" }}>
                        <p style={{marginBottom: "0", textAlign: 'center', padding: '10px'}}>
                            El navegador no puede previsualizar el pdf. <br/>
                            <a href={openFile.data} download={modalMetadata?.des}>
                                Descargar archivo
                            </a>
                        </p>
                    </object>

                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 p-0">
                  <div className="meta-container">
                    <div className="spacing">
                      <div className="sign-fields">
                        <label className="label fw-bold">Descripción</label>
                        <span>{modalMetadata?.des}</span>
                      </div>
                    </div>
                    <div className="spacing">
                      <div className="sign-fields">
                        <label className="label fw-bold">Periodo</label>
                        <span>{modalMetadata?.periodo}</span>
                      </div>
                    </div>
                    <div className="spacing">
                      <div className="sign-fields">
                        <label className="label fw-bold">Estado</label>
                        <span className={`layer-estado ${!modalMetadata.isR ? "no-aplica" : modalMetadata.estado === "Conforme" && !modalMetadata.sig ? "firmado" : modalMetadata.estado === "No Conforme" ? "no-conform" : modalMetadata.estado === "Conforme" || modalMetadata.estado === "No Conforme" && modalMetadata.sig ? "inprocess" : "pendiente"}`}>
                        {!modalMetadata.isR ? "No Aplica" : modalMetadata.estado === "Conforme" && !modalMetadata.sig ? "firmado" : modalMetadata.estado === "No Conforme" ? "No Conforme" : modalMetadata.sig ? "En Proceso" : "Pendiente"}
                          </span>
                      </div>

                    </div>
                    <div>
                      {isReceipt ? <div className="spacing">
                        <div className="sign-fields">
                          <label className="label fw-bold">Firmar</label>
                          <div className="sign-btn-container">
                            <button className="modal-btn save" title="Los botones se activarán cuando esté autorizado a firmar" disabled={!canSign} onClick={() => { setSignConform({ active: true, data: openFile?.id, company: openFile?.company }); setConform(true); setOpenFile({ active: false, data: null, id: null, company: null }); setCanSign(null); setIsReceipt(null) }}>Conforme<i className="fa fa-chevron-right fa-fw fa-xs"></i></button>
                            <button className="modal-btn reject no-conform-btn" title="Los botones se activarán cuando esté autorizado a firmar" disabled={!canSign} onClick={() => { setSignDisconform({ active: true, data: openFile?.id, company: openFile?.company }); setConform(false); setOpenFile({ active: false, data: null, id: null, company: null }); setCanSign(null); setIsReceipt(null) }} >NO Conforme <i className="fa fa-chevron-right fa-fw fa-xs"></i></button>
                          </div>
                        </div>
                      </div> : null}

                    </div>

                  </div>
                </div>

              </div>
            </div>


          </Modal>

          <Modal show={signConform.active} data={signConform?.data}>
            <Modal.Header closeButton onClick={() => {
              if (!loadingSave) {
                setSignConform({ active: false, data: null }); setPin('')
              }
            }
            }>
            </Modal.Header>
            <div className="sign-modal-container">
              <div className="items-wrap">
                <div className="item-container">
                  <div><FaRegCheckCircle className="color-orange check-sign-icon" /></div>
                  <h4 className="modal-title-sign">Firma Conforme</h4>
                  <div className="sign-label">Por favor, ingrese su <div className="bold-parag">Pin de Firma</div> : </div>
                </div>
                <input maxLength={20} value={pin} onChange={(e) => {
                  setPin(e.target.value)
                }
                } type={inputType} className="pin-input"></input>
                <span onClick={() => setVisible(vis => !vis)}>{visible ? <TbEyeOff className="pass-icon password-off" /> : <TbEyeCheck className="pass-icon password-on" />}</span>
              </div>
              <p className="mt-3 pin-recovery">
                En caso de olvidar su pin de firma puede recordarlo utilizando su palabra clave o resetearlo ingresando <a href="https://cencosud.tulegajo.com/" target="_blank" rel="noreferrer" className="pin-recovery-link">aquí</a> y luego en la opción Mi Firma &gt; Olvide mi PIN
              </p>
            </div>

            <Modal.Footer style={{
              display: "flex",
              justifyContent: "center",
            }} >
              <button disabled={loadingSave || !pin} onClick={() => { sendSignature(signConform.data, signConform.company); setPin('') }} className="button save">Conforme {!loadingSave ? <i className="fa fa-chevron-right fa-fw fa-xs"></i> : <div className="spinner-border spinner-border-sm"></div>}</button>
            </Modal.Footer>
          </Modal>
          <Modal show={signDisconform.active} data={signDisconform.data}>
            <Modal.Header closeButton onClick={() => {
              if (!loadingSave) {
                setSignDisconform({ active: false, data: null }); setPin(''); setComments('')
              }
            }
            }>
            </Modal.Header>
            <div className="sign-modal-container">

              <div className="items-wrap">

                <div className="item-container">
                  <div><RiCloseCircleLine className="color-orange check-sign-icon" /></div>
                  <h4 className="modal-title-sign">Firma NO Conforme</h4>

                  <div className="sign-label">Por favor, ingrese su <div className="bold-parag">Pin de Firma</div> : </div>
                </div>

                <input maxLength={20} value={pin} onChange={(e) => {
                  setPin(e.target.value)
                }
                } type={inputType} className="pin-input"></input>
                <span onClick={() => setVisible(vis => !vis)}>{visible ? <TbEyeOff className="pass-icon-disconform password-off" /> : <TbEyeCheck className="pass-icon-disconform password-on" />}</span>
                <p className="mt-3 pin-recovery">
                  En caso de olvidar su pin de firma puede recordarlo utilizando su palabra clave o resetearlo ingresando <a href="https://cencosud.tulegajo.com/" target="_blank" rel="noreferrer" className="pin-recovery-link">aquí</a> y luego en la opción Mi Firma &gt; Olvide mi PIN
                </p>
                <div className="comments-label">Comentarios:</div>
                <div>
                  <textarea value={comments} onChange={(e) => setComments(e.target.value)} className="comments-area" rows={2}></textarea>
                </div>
              </div>


            </div>

            <Modal.Footer style={{
              display: "flex",
              justifyContent: "center",
            }} >
              <button disabled={loadingSave || !pin} onClick={() => { sendSignature(signDisconform.data, signDisconform.company); setPin(''); setComments('') }} className="button reject">NO Conforme {!loadingSave ? <i className="fa fa-chevron-right fa-fw fa-xs"></i> : <div className="spinner-border spinner-border-sm"></div>}</button>
            </Modal.Footer>
          </Modal>
        </div>

      }
    </>
  )
}

export default SallaryReceiptsTable