import React, { FunctionComponent } from "react";
import { FormList, Onchange } from "../../utilities/interfaces";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";


interface Props {
    element: FormList;
    register: UseFormRegister<any>;
    errors: Partial<FieldErrorsImpl>;
    contractDate?: string;
}

const today = (n: number) => {
    let today = new Date();
    let dd = today.getDate().toString();
    let mm = (today.getMonth() + 1).toString();
    let yyyy = (today.getFullYear() - n).toString();

    if (parseInt(dd) < 10) {
        dd = "0" + dd;
    }

    if (parseInt(mm) < 10) {
        mm = "0" + mm;
    }

    return `${yyyy}-${mm}-${dd}`;
};

const InputForm: FunctionComponent<Props> = ({ element, register, errors, contractDate }) => {
    return (
        <>
            <input
                className={element.classNameType}
                type={element.inputType}
                id={element.id}
                maxLength={element.maxLength}
                { ...register(element.id, {
                    required: element.required ? element.onInvalidText : undefined,
                    disabled: element.disabled,
                    pattern: {
                        value: element.pattern,
                        message: element.onInvalidText,
                    },
                    maxLength: element.maxLength,
                })}
                readOnly={element.readOnly}
                max={
                    element.inputType === "date" && (
                    element?.dateValidation === "bir" ||
                    element?.dateValidation === "sch" ||
                    element?.dateValidation === "col") ? today(0) : element?.dateValidation === "afp" ? contractDate : undefined}
                min={element?.dateValidation === "exp" ? today(0) : element?.dateValidation === "afp" ? "1981-05-01" : element.inputType === "date" ? today(100) : undefined}
            />
            {  
                <>
                {errors[element.id] &&
                    <div className="invalid-feedback d-block">
                        {errors[element.id]?.message as string}
                        {errors[element.id]?.type === "maxLength" && <span>Tamaño máximo superado</span> }
                    </div>}
                </>    
            }
        </>
    )
}

export default InputForm;