import { verifyDocument } from "../apiServices/apiRequest";
import { httpClientEnv } from "../apiServices/httpClient";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import * as RD from "@devexperts/remote-data-ts";
import { HttpError, Verification } from "../../../verifyDocument/utilities/interfaces";
import { RemoteData } from "@devexperts/remote-data-ts";

interface Props {
    code: string;
    setRemoteData: React.Dispatch<React.SetStateAction<RemoteData<HttpError, Verification>>>;
    token: string;
}

export const getRemoteData = async ({ code, setRemoteData, token }: Props) => {
    const transfer = async() => {
        setRemoteData(RD.pending);
        RTE.run(await verifyDocument(`?code=${code}&captcha=${token}`), httpClientEnv)
            .then(E.fold(e => { return setRemoteData(RD.failure(e)) }, a => { return setRemoteData(RD.success(a)) }));
    }
    await transfer();
};
