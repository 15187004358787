import React from "react";
import imgBannerCondiciones from "../../../../resources/images/CencoTalent/proceso-postulacion.jpg";
import imgBannerCondicionesSmall from "../../../../resources/images/CencoTalent/proceso-postulacion-xs.jpg";
import imgAdnCencosud from "../../../../resources/images/CencoTalent/impulsa_desktop.jpg";
import { SearchCard } from "./SearchCard";
import { ResultsApply } from "./ResultsApply";
import { Button } from "../../../commonComponents";
import { ModalVideo } from "../ui/ModalVideo";
import { useCencoUI } from "../../hooks/useCencoUI";
import { useCencoInfo } from "../../hooks/useCencoInfo";
import ErrorScreen from "../../../commonComponents/error-screen/error-screen";

export const SearchApply = () => {
    const {
        isModalVideoOpen,

        setPage,
        setIsModalVideoOpen,
        setHasError,
    } = useCencoUI();
    const { talentVideo } = useCencoInfo();

    const openVideo = () => {
        if (talentVideo.video_url === "") {
            setHasError(true);
            return;
        }
        setIsModalVideoOpen(true);
    };

    return (
        <>
            <section className="cenco-talento">
                <h1>Impulsa</h1>

                <div className="d-flex justify-content-center">
                    <div className="row">
                        <div className="col-12 col-md-8 position-relative p-0 p-sm-1">
                            <img
                                src={imgBannerCondicionesSmall}
                                className="img-fluid rounded-4 d-md-none mb-3"
                                alt="Imagen 1"
                            />
                            <img
                                src={imgBannerCondiciones}
                                className="img-fluid rounded-4 d-none d-md-block"
                                alt="Imagen 1"
                            />
                            <Button
                                onClick={() => setPage("INFO_DETAILS")}
                                styleType="primary"
                                className="position-absolute mb-1 mb-md-0"
                                style={{ bottom: "20px", right: "20px" }}
                            >
                                Ver más
                            </Button>
                        </div>

                        <div className="col-12 col-md-4 position-relative p-0 p-sm-1">
                            <img
                                src={imgAdnCencosud}
                                className="img-fluid rounded-4"
                                alt="Imagen 2"
                            />
                            { talentVideo.video_url !== "" &&
                                <Button
                                    onClick={() => openVideo()}
                                    styleType="video"
                                    className="position-absolute"
                                    style={{ bottom: "10px", right: "20px" }}
                                >
                                    Dale Play
                                </Button>
                            }
                        </div>
                    </div>
                </div>

                <SearchCard className="mb-4" />

                <ResultsApply />
                <ModalVideo
                    activeState={isModalVideoOpen}
                    videoUrl={talentVideo.video_url}
                    toggleState={setIsModalVideoOpen}
                    videoTitle={talentVideo.title}
                    width="640"
                    height="360"
                />
            </section>
        </>
    );
};
