import React, { Children } from "react";
import { Options } from "../../../utilities/interfaces";

interface Props {
    options: Options;
    title: string;
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
}

const Element: React.FC<Props> = ({ options, title, value, setValue }) => {
    return (
        <li>
            <span className="data-list-box__title col-md-4">{`${title} `}<span className="color-red">{"*"}</span></span>
            <span className="data-list-box__text col-md-4">
                <div className="input-group">
                    <select className="form-select" value={value} onChange={(e) => setValue(e.target.value)}>
                        <option value="" selected disabled>{"Seleccione"}</option> 
                        {Children.toArray(options.map((o) => {
                            return <option key={o.key} value={o.value}>{o.label}</option>
                        }))}
                    </select>
                </div>
            </span>
        </li>
    )
};

export default Element;