import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import styles from '../../resources/css/style-COL.module.css';
import VacacionesServices from '../../services/vacaciones-COL/vacaciones-service';
import DatosLaboralesService from "../../services/datos laborales/datos-laborales-service";
import errorMessage from "../SharedComponents/error-message";
import { useForm } from "react-hook-form";
import { BsExclamationCircle } from "react-icons/bs";
import Swal from 'sweetalert2';
import fotoDefault from "../../resources/images/default-colaborador-image.png";
"../../resources/css/style-COL.css";

const HistoricoEmpleado = () => {
    const [historicoEmpleado, setHistoricoEmpleado] = useState([])
    const [loadingHistoricoEmpleado, setLoadingHistoricoEmpleado] = useState(true)
    const [primerCarga, setPrimerCarga] = useState(false)
    const [estados, setEstados] = useState([])
    const [loadingEstados, setLoadingEstados] = useState(true)
    const [noData, setNoData] = useState(false)
    const [avatar, setAvatar] = useState(null);
    const [loadingAvatar, setLoadingAvatar] = useState(true);

    const [employeeInfo, setEmployeeInfo] = useState({})
    const [loadingEmployeeInfo, setLoadingEmployeeInfo] = useState(false)
    const [noEmployeeData, setNoEmployeeData] = useState(false)

    const [diasTotales, setDiasTotales] = useState({})
    const [loadingDiasTotales, setLoadingDiasTotales] = useState(true)
    const [noDiasTotales, setNoDiasTotales] = useState(false)

    const [totalVacaciones, setTotalVacaciones] = useState({})
    const [loadingTotalVacaciones, setLoadingTotalVacaciones] = useState(true)
    const [noDiasTotalVacaciones, setNoTotalVacaciones] = useState(false)
    const navigate = useNavigate()

    const loadAvatar = async () => {
        try{
            const response = await DatosLaboralesService.getAvatarRegional();
            setAvatar(response.data);
            setLoadingAvatar(false);
        } catch (e){
            setAvatar(null);
            setLoadingAvatar(false);
        }
    }

    useEffect(() => {
        getEstados()
        loadAvatar()
        VacacionesServices.getEmployee(setEmployeeInfo, setLoadingEmployeeInfo, setNoEmployeeData)
        VacacionesServices.getDiasTotales(setDiasTotales, setLoadingDiasTotales, setNoDiasTotales)
        VacacionesServices.getTotalVacaciones(setTotalVacaciones, setLoadingTotalVacaciones, setNoTotalVacaciones)
    }, [])
    useEffect(() => {
        getHistoricoEmpleado()
    }, [primerCarga])

    const {
        register,
        handleSubmit,
    } = useForm();

    const getEstados = async () => {
        try {
            let response = await VacacionesServices.getEstados();
            if (
                response !== null &&
                response !== undefined &&
                (response?.statusCode === 200 || response?.status === 200)
            ) {
                setEstados(response?.data.CN_COL_XLAT.PSXLATITEM);
                setLoadingEstados(false);
            } else {
                errorMessage(
                    null,
                    response?.message === null || response?.message === undefined
                        ? ""
                        : response?.message
                );
                setLoadingEstados(false);
            }

        } catch (error) {
            setLoadingEstados(false);
        }
    };

    const getHistoricoEmpleado = async () => {
        try {
            let response = await VacacionesServices.getHistoricoEmpleado("", "", "");
            if (
                response !== null &&
                response !== undefined &&
                (response?.statusCode === 200 || response?.status === 200)
            ) {
                setHistoricoEmpleado(response?.data.sort((a,b) => b.CN_ID_SOLIC_VAC - a.CN_ID_SOLIC_VAC));
                setPrimerCarga(true)
                setLoadingHistoricoEmpleado(false);
            } else {
                errorMessage(
                    null,
                    response?.message === null || response?.message === undefined
                        ? ""
                        : response?.message
                );
                setLoadingHistoricoEmpleado(false);
                setNoData(true)
            }

        } catch (error) {
            setLoadingHistoricoEmpleado(false);
        }
    }
    const onSubmit = async (data) => {
        if (data.fechaInicio && data.fechaFin) {
            if (data.fechaInicio > data.fechaFin) {
                Swal.fire({ title: "Fechas mal ingresadas", text: "La fecha inicial no puede ser mayor a la fecha final.", icon: "error" })
                return
            }
        }
        const fechaInicio = data.fechaInicio ? data.fechaInicio : "";
        const fechaFin = data.fechaFin ? data.fechaFin : "";
        const estado = data.estado ? data.estado : "";
        setLoadingHistoricoEmpleado(true)
        try {
            let response = await VacacionesServices.getHistoricoEmpleado(fechaInicio, fechaFin, estado);
            if (
                response !== null &&
                response !== undefined &&
                (response?.statusCode === 200 || response?.status === 200)
            ) {
                setHistoricoEmpleado(response?.data);
                setLoadingHistoricoEmpleado(false);
            } else {
                errorMessage(
                    null,
                    response?.message === null || response?.message === undefined
                        ? ""
                        : response?.message
                );
                setLoadingHistoricoEmpleado(false);
                setNoData(true)
            }

        } catch (error) {
            setLoadingHistoricoEmpleado(false);
        }
    }

    return (
        <>
            <nav className={`${styles["breadcrumbs"]} ${styles["d-none"]} ${styles["d-lg-block"]}`} aria-label="breadcrumb">
                <ol className={styles["breadcrumb"]}>
                    <li className={styles["breadcrumb-item"]}><Link to="/inicio"><i className={`${styles["fa"]} ${styles["fa-home"]}`} aria-hidden="true"></i> Inicio</Link></li>
                    <li className={styles["breadcrumb-item"]}>Vacaciones</li>
                    <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">Pendientes e Histórico de Vacaciones</li>
                </ol>
            </nav>

            {!noData ? <section className={styles["vacaciones"] + " " + "cencosud-col"}>

                <h1>Vacaciones</h1>

                <div className={`${styles["row"]} ${styles["mb-4"]}`}>

                    {!noEmployeeData ? (<div className={`${styles["col-12"]} ${styles["col-xl-4"]} ${styles["d-flex"]} ${styles["mb-4"]} ${styles["mb-xl-0"]}`}>
                        <div className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}>
                            {!loadingAvatar ? <img
                                src={avatar === null ? fotoDefault : avatar}
                                alt=""
                                className={`${styles["rounded-circle"]} ${styles["mb-3"]}`}
                                width="120"
                                height="120"
                            /> : <div data-testid="loading" className="full-spinner">
                                <div className="spinner-border text-primary"></div>
                            </div>}
                            {!loadingEmployeeInfo ?
                                <><h2 className={`${styles["h3"]} ${styles["mb-2"]}`}>{employeeInfo.firstname ? employeeInfo.firstname + " " + employeeInfo.lastname : null}</h2>
                                    <p className={`${styles["h5"]} ${styles["fw-bold"]}`}>{employeeInfo.position_description}</p>
                                </> : <div data-testid="loading" className="full-spinner">
                                    <div className="spinner-border text-primary"></div>
                                </div>}
                            <div className={`${styles["data-list-box"]} ${styles["data-list-box-align-middle"]} ${styles["mb-3"]}`}>
                                {(!loadingDiasTotales && !loadingTotalVacaciones) ? <><ul className={`${styles["list-unstyled"]} ${styles["m-0"]}`}>
                                    <li>
                                        <span className={`${styles["ic-circle"]} ${styles["ic-circle-primary"]}`}>{totalVacaciones.diasDisponibles}</span>
                                        Días Disponibles a la fecha
                                    </li>
                                    <li>
                                        <span className={`${styles["ic-circle"]} ${styles["ic-circle-primary"]}`}>{totalVacaciones.diasDisfrutados}</span>
                                        Días Disfrutados
                                    </li>
                                    <li>
                                        <span className={`${styles["ic-circle"]} ${styles["ic-circle-primary"]}`}>{diasTotales.CN_DIAS_BENEFICIO}</span>
                                        Días Beneficio
                                    </li>
                                    <li>
                                        <span className={`${styles["ic-circle"]} ${styles["ic-circle-primary"]}`}>{totalVacaciones.diasProyectados}</span>
                                        Días Proyectados{" "}
                                    </li>
                                </ul></> : <div data-testid="loading" className="full-spinner">
                                <div className="spinner-border text-primary"></div>
                            </div>}
                            </div>
                        </div>
                    </div>
                    ) : <div className="alert alert-info alert-dismissible fade show text-center" role="alert">
                        No se encontraron datos asociados
                    </div>}
                    <div className={`${styles["col-12"]} ${styles["col-xl-8"]}`}>

                        <div className={`${styles["box"]} ${styles["mb-4"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}>
                            <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Filtrar</h2>
                            <form>
                                <div className={`${styles["row"]} ${styles["align-items-end"]}`}>
                                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["col-lg-12"]} ${styles["col-xl-6"]} ${styles["mb-3"]}`}>
                                        <label className={styles["form-label"]}>Periodo</label>
                                        <div className={styles["row"]}>
                                            <div className={styles["col-6"]}>
                                                <div className={styles["input-group"]}>
                                                    <input className={styles["form-control"]} type="date" {...register("fechaInicio")} placeholder="dd/mm/aaaa" />
                                                </div>
                                            </div>
                                            <div className={styles["col-6"]}>
                                                <div className={styles["input-group"]}>
                                                    <input className={styles["form-control"]} type="date" {...register("fechaFin")} placeholder="dd/mm/aaaa" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles["col-12"]} ${styles["col-md-4"]} ${styles["col-lg-9"]} ${styles["col-xl-3"]} ${styles["mb-3"]}`}>
                                        <label className={styles["form-label"]}>Estado</label>
                                        <div className={styles["input-group"]}>
                                            {!loadingEstados ? <select className={styles["form-select"]} {...register("estado")}>
                                                <option value="" selected disabled>Seleccione</option>
                                                {estados && estados?.map((e, id) => {
                                                    return (<option key={id} value={e.FIELDVALUE}>{e.XLATLONGNAME}</option>)
                                                })}
                                            </select> : <div className="spinner-border spinner-border-sm"></div>}
                                        </div>
                                    </div>
                                    <div className={`${styles["col-12"]} ${styles["col-md-2"]} ${styles["col-lg-3"]} ${styles["col-xl-3"]} ${styles["mb-3"]}`}>
                                        <button type="submit" className={`${styles["btn"]} ${styles["btn-degradado"]}`} disabled={loadingHistoricoEmpleado} onClick={handleSubmit(onSubmit)}>Buscar{loadingHistoricoEmpleado ? <div className="spinner-border spinner-border-sm"></div> : <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i>}</button>
                                    </div>
                                </div>
                            </form>

                        </div>


                        <div className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}>

                            <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Pendiente e Histórico</h2>

                            {!loadingHistoricoEmpleado ? (historicoEmpleado.length !== 0 ? <>
                                <div className={styles["table-responsive"]}>
                                    <table className={`${styles["table"]} ${styles["table-even"]} ${styles["table-section"]} ${styles["table-borderless"]} ${styles["d-none"]} ${styles["d-md-table"]}`}>
                                        <thead>
                                            <tr>
                                                <th className={styles["text-nowrap"]}>ID Solicitud <Link to="#"><i className={`${styles["fa"]} ${styles["fa-long-arrow-down"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></th>
                                                <th className={styles["text-nowrap"]}>Fecha Inicio <Link to="#"><i className={`${styles["fa"]} ${styles["fa-long-arrow-down"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></th>
                                                <th className={styles["text-nowrap"]}>Fecha Final <Link to="#"><i className={`${styles["fa"]} ${styles["fa-long-arrow-down"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></th>
                                                <th className={styles["text-nowrap"]}>Duración <Link to="#"><i className={`${styles["fa"]} ${styles["fa-long-arrow-down"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></th>
                                                <th className={styles["text-nowrap"]}>Dinero <Link to="#"><i className={`${styles["fa"]} ${styles["fa-long-arrow-down"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></th>
                                                <th className={styles["text-nowrap"]}>Aprobador <Link to="#"><i className={`${styles["fa"]} ${styles["fa-long-arrow-down"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></th>
                                                <th className={styles["text-nowrap"]}>Estado <Link to="#"><i className={`${styles["fa"]} ${styles["fa-long-arrow-down"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {historicoEmpleado && historicoEmpleado?.map((h, id) => {
                                                return (<tr key={id}>
                                                    <td>{h.CN_ID_SOLIC_VAC}</td>
                                                    <td>{h.INICIO}</td>
                                                    <td>{h.FIN}</td>
                                                    <td>{h.DURACION}</td>
                                                    <td>{h.CN_DIAS_DINERO}</td>
                                                    <td>{h.NAME_DISPLAY}</td>
                                                    <td className={`${styles["text-uppercase"]} ${styles["fw-bold"]} ${styles["color-primary"]}`}>{estados && estados?.filter((e) => e.FIELDVALUE === h.CN_C_ESTADO_VAC)[0] !== undefined ? estados.filter((e) => e.FIELDVALUE === h.CN_C_ESTADO_VAC)[0].XLATLONGNAME : "No definido"}</td>
                                                </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className={`accordion accordion-flush accordion-light accordion-table ${styles["d-md-none"]}`}>

                                    {historicoEmpleado && historicoEmpleado?.map((h, id) => {
                                        return (<div key={id} className="accordion-item">
                                            <h2 className="accordion-header" id="accordion">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-item-${id}`} aria-expanded="false" aria-controls={`accordion-item-${id}`}>
                                                    <strong>ID Solicitud</strong> {h.CN_ID_SOLIC_VAC}
                                                </button>
                                            </h2>
                                            <div id={`accordion-item-${id}`} className="accordion-collapse collapse" aria-labelledby="accordion">
                                                <div className={`accordion-body ${styles["p-0"]}`}>

                                                    <div className={`${styles["data-list-box"]} ${styles["data-list-box-even"]}`}>
                                                        <ul className={`${styles["data-list-section--item"]} ${styles["list-unstyled"]}`}>
                                                            <li>
                                                                <span className={styles["data-list-box__title"]}>Fecha Inicio</span>
                                                                <span className={styles["data-list-box__text"]}>{h.INICIO}</span>
                                                            </li>
                                                            <li>
                                                                <span className={styles["data-list-box__title"]}>Fecha Final</span>
                                                                <span className={styles["data-list-box__text"]}>{h.FIN}</span>
                                                            </li>
                                                            <li>
                                                                <span className={styles["data-list-box__title"]}>Duración</span>
                                                                <span className={styles["data-list-box__text"]}>{h.DURACION}</span>
                                                            </li>
                                                            <li>
                                                                <span className={styles["data-list-box__title"]}>Dinero</span>
                                                                <span className={styles["data-list-box__text"]}>{h.CN_DIAS_DINERO}</span>
                                                            </li>
                                                            <li>
                                                                <span className={styles["data-list-box__title"]}>Aprobador</span>
                                                                <span className={styles["data-list-box__text"]}>{h.NAME_DISPLAY}</span>
                                                            </li>
                                                            <li>
                                                                <span className={styles["data-list-box__title"]}>Estado</span>
                                                                <span className={styles["data-list-box__text"]}>
                                                                    <span className={styles["text-uppercase fw-bold"]}>{estados && estados?.filter((e) => e.FIELDVALUE === h.CN_C_ESTADO_VAC)[0] !== undefined ? estados.filter((e) => e.FIELDVALUE === h.CN_C_ESTADO_VAC)[0].XLATLONGNAME : "No definido"}</span>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>)
                                    })}

                                </div>
                            </> : <div className={`${styles["alert"]} ${styles["alert-info"]} ${styles["alert-dismissible"]} ${styles["fade"]} ${styles["show"]} ${styles["text-center"]}`} role="alert">
                                <BsExclamationCircle />  No se encontraron solicitudes en el período y/o estado solicitado.
                            </div>) : <div data-testid="loading" className="full-spinner">
                                <div className="spinner-border text-primary"></div>
                            </div>}
                        </div>

                    </div>

                </div>

                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-4"]}`}></div>
                    <div className={`${styles["col-12"]} ${styles["col-xl-8"]}`}>
                        <div className={`${styles["row"]} ${styles["justify-content-between"]}`}>
                            <div className={`${styles["col-6"]} ${styles["col-lg-auto"]}`}>

                                <button type="button" className={`${styles["btn"]} ${styles["btn-link"]} ${styles["w-100"]}`} onClick={() => navigate("/col/vacaciones")}>Volver a Solicitud de Vacaciones</button>
                            </div>
                        </div>
                    </div>
                </div>

            </section> :
                <div className="alert alert-info alert-dismissible fade show text-center" role="alert">
                    <BsExclamationCircle />  Hubo un error en el sistema, inténtelo de nuevo más tarde
                </div>}
        </>
    );
}

export default HistoricoEmpleado;