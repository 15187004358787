import React, { useEffect, useRef, useState } from 'react'
import { PropTypes } from 'prop-types';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'

const CalendarHeader = (props) => {

    CalendarHeader.propTypes = {
        reference: PropTypes.any
    }
    const ref = props?.reference;
    const fecha = ref.current?.getApi();
    const headerTitle = fecha?.getCurrentData().viewTitle;
    const datePickerRef = useRef();
    const [pickedDate, setPickedDate] = useState({});
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (active) {
            const navigate = ref?.current?.getApi();
            navigate.gotoDate(pickedDate);
            setActive(false);
        }
    }, [pickedDate, ref, active])

    const handlePreviousClick = () => {
        const calendarAPI = ref?.current?.getApi();
        calendarAPI?.prev();
    };
    const handleNextClick = () => {
        const calendarAPI = ref?.current?.getApi();
        calendarAPI?.next();
    }
    const handleDateChange = () => {
        datePickerRef?.current?.showPicker();
    }
    const handlePickerChange = (e) => {
        setPickedDate(e.target.value)
        setActive(true);
    }

    return (
        <div>
            <div className="row">
                <div className="container">
                    <div className="header-title text-center">{headerTitle} <Tippy content="Buscar periódo"><span onClick={() => handleDateChange()}><i className="fa fa-calendar-o fa-fw color-orange date-selector" aria-hidden="true"></i></span></Tippy>
                        <div className="picker-container"><input value={pickedDate} ref={datePickerRef} onChange={(e) => handlePickerChange(e)} type="date" className="calendar-date-filter form-control"></input></div></div>
                    <div className="header-block mb-2">
                        <div className="calendar-header-btn" onClick={() => handlePreviousClick()}>  <i className="fa fa-chevron-left fa-fw fa-xs color-orange" aria-hidden="true"></i> Antes</div>
                        <div className="calendar-header-btn" onClick={() => handleNextClick()}>Después  <i className="fa fa-chevron-right fa-fw fa-xs color-orange" aria-hidden="true"></i></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CalendarHeader
