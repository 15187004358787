import React, { CSSProperties, FC } from "react";

type buttonTypes = "number" | "prev" | "next" | "first" | "last";

const classButtonByType: Record<buttonTypes, { class: string; icon?: string }> =
    {
        number: { class: "page-item" },
        prev: { class: "page-item", icon: "fa fa-chevron-left" },
        next: { class: "page-item", icon: "fa fa-chevron-right" },
        first: { class: "page-item", icon: "fa fa-angle-double-left" },
        last: { class: "page-item", icon: "fa fa-angle-double-right" },
    };

interface Props {
    label?: string | number;
    styleType?: buttonTypes;
    className?: string;
    style?: CSSProperties;
    disabled?: boolean;
    onClick?: () => void;
}

export const PaginationButton: FC<Props> = ({
    label = "",
    styleType = "number",
    className,
    style,
    onClick,
}) => {
    return (
        <li
            className={`${className} ${classButtonByType[styleType].class}`}
            style={style}
        >
            <a className="page-link" onClick={onClick}>
                {label}
                {classButtonByType[styleType].icon && (
                    <i
                        className={classButtonByType[styleType].icon}
                        aria-hidden="true"
                    ></i>
                )}
            </a>
        </li>
    );
};
