import React, { FC, ReactNode } from "react";
import { Button } from "./Button";

interface ModalProps {
    text: string,
    subtext? :string,
    callBack: () => void,
    icon: string,
    refOpen: any,
    refClose?: any,
}

const ModalInfoDinamic: FC<ModalProps> = ({text, icon, callBack, refOpen, refClose, subtext}) => {

    return (
        <>
            <a hidden ref={refOpen} data-bs-toggle="modal" data-bs-target="#modalStatus"/>
            <div className="modal fade" id={'modalStatus'} aria-labelledby={'modalStatus'} aria-hidden="true" data-bs-target="#modalStatus" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                        <button
                            ref={refClose}
                            onClick={() => { callBack && callBack() }}
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                        </div>
                        <div className="modal-body text-center py-4 pt-0 pb-1">
                        <i
                            className={`fa ${icon} color-orange fa-3x mb-2`}
                            aria-hidden="true"
                        ></i>
                        <h2 className="h5">
                            {text}
                        </h2>
                        <p>{subtext}</p>
                        </div>
                            <div className="modal-footer border-0 justify-content-end">
                              <Button
                                  onClick={() => { callBack && callBack() }}
                                  type="button"
                                  styleType='primary'
                                  data-bs-dismiss="modal"
                              >
                                  Aceptar
                              </Button>
                          </div> 
                    </div>
                </div>
            </div>
        </>
    )

}

export default ModalInfoDinamic;