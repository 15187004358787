import { useEffect } from "react";

const events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];

const SignOutInactivity = ({ signOut, children, time }) => {
    let timer;
    const handleLogoutTimer = () => {
        timer = setTimeout(() => {
            resetTimer();
            Object.values(events).forEach((item) => {
                window.removeEventListener(item, resetTimer);
            });
            signOut();
        }, time);
    };
    
    const resetTimer = () => {
        if (timer) {
            clearTimeout(timer);
        }
    };

    useEffect(() => {
        Object.values(events).forEach((item) => {
            window.addEventListener(item, () => {
                resetTimer();
                handleLogoutTimer();
            });
        });
    }, []);
    
    return children;
};

export default SignOutInactivity;