import React, { FC, useContext } from "react";
import { httpClientEnv } from "../utilities/apiServices/httpClient";
import AB from "./AB";
import "../commons/styles/generalStyle.css";
import { Context } from "../../../context";

export const HttpClientContext = React.createContext(httpClientEnv);
export const useHttpClient = () => useContext(HttpClientContext);

export const AnnualBond: FC = () => {
    const context = useContext(Context);
    const boss = context?.firstLoginData?.BOSS !== undefined ? context?.firstLoginData?.BOSS : "N";
    return (
            <HttpClientContext.Provider value={httpClientEnv}>
                <AB boss={boss} />
            </HttpClientContext.Provider>
        )
};
