import React, { useState, useEffect, useRef, Children, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import defaultColaboradorImage from "../../resources/images/default-colaborador-image.png";
import checkedimg from "../../resources/images/checked-img.png";
import uncheckedimg from "../../resources/images/unchecked-img.png";
import { urlsAws } from "../../resources/foo/api-endpoints";
import miEquipoService from "../../services/mi-equipo-service";
import { formatDate, groupBy, useCarousel } from "../../services/utiles";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { Bar, Chart } from "react-chartjs-2";
//import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, registerables } from "chart.js";
import useOnlineState from "../elements/online-hook";
import { Text, Image, StyleSheet, View, Page, Document, PDFDownloadLink } from "@react-pdf/renderer";
import { dotNumber, dotPerc } from '../../services/utiles'
import firmaDocumentosService from "../../services/firma-documentos-service";
import { FichaSIC }  from "./fichaSIC/pages";
import { WarningLetter } from "./warningLetter/pages";
import WarningHistory from "./warningLetter/pages/warningHistory";

ChartJS.register(...registerables);
const regresionLineal = (cambioRentaData) => {
    const x = cambioRentaData.map((item, index) => index); //array con valores de x
    const y = cambioRentaData.map((item) => parseInt(item.salario_base)); //aray con valores de y
    const largo = x.length; //tamaño del array
    const sumX = x.reduce((a, b) => a + b, 0); //suma de todos los valores de x
    const sumY = y.reduce((a, b) => a + b, 0); //suma de todos los valores de y
    const xy = y.map((item, index) => item * index); //multiplica cada valor de y por x
    const sumXY = xy.reduce((a, b) => a + b, 0); //suma de todos los valores de xy
    const x2 = x.map((item) => item * item); //array de x elevado al cuadrado
    const sumX2 = x2.reduce((a, b) => a + b, 0); //suma de todos los valores de x elevado al cuadrado
    const m = (largo * sumXY - sumX * sumY) / (largo * sumX2 - sumX * sumX); //calcula la pendiente
    const b = sumY / largo - m * (sumX / largo); //calcula el valor de la ordenada al origen
    const listaRegresion = [];
    for (let i = 0; i < largo; i++) {
        listaRegresion.push(m * i + b); //calcula la regresion lineal
    }
    return listaRegresion;
};
const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        const updateSize = () => {
            setSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
};
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        padding: '20px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    card: {
        padding: "20px",
        borderRadius: "10px",
        width: "48%",
        border: "1px solid #eeeeee",
        margin: " 0px 5px 20px 5px",
        height: "auto",
    },
    head: {
        display: "flex",
        marginBottom: "20px",
        paddingLeft: "10px",
    },
    body: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "20px"
    },
    h1: {
        fontSize: "16px",
        fontWeight: "bold",
        textAlign: "left",
        marginBottom: "10px",
        color: "#0169b5"
    },
    table: {
        width: "100%",
        borderCollapse: "collapse",
        borderSpacing: "0",
        marginBottom: "20px"
    },
    tableHead: {
        backgroundColor: "#eeeeee",
        borderBottom: "1px solid #eeeeee",
        borderTop: "1px solid #eeeeee",
        borderLeft: "1px solid #eeeeee",
        borderRight: "1px solid #eeeeee",
        padding: "5px",
        fontSize: "8px",
    },
    tableBody: {
        borderBottom: "1px solid #eeeeee",
        borderLeft: "1px solid #eeeeee",
        borderRight: "1px solid #eeeeee",
        fontSize: "8px",
        textAlign: "center",
        color: "#777777",
    },
    tableRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: "5px 5px 5px 5px",
        border: "1px solid #eeeeee",
    },
    labelHead: {
        textAlign: "center",
        color: "#777777",
        fontWeight: "900",
    },
    labelBody: {
        textAlign: "center",
        color: "#777777",
        fontWeight: "normal",
    },
    li: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'flex-start',
        padding: "10px",
        borderBottom: "1px solid #eeeeee",
        position: "relative",
        justifyContent: "flex-start"
    },
    liTitle: {
        width: "50%",
    },
    liContent: {
        width: "50%",
    },
    titleLabel: {
        fontSize: "8px",
        fontWeight: "bold",
        color: "#777777"
    },
    contentLabel: {
        fontSize: "8px",
        fontWeight: "normal",
        color: "#777777"
    },
    foto: {
        width: "100px",
        height: "100px",
    },
    checkimg: {
        width: "20px",
        height: "20px",
    },
})
const DatosPuesto = ({ colaborador, pdf, width }) => {
    const [foto, setFoto] = useState(sessionStorage.getItem("fotoColaborador"));
    const emplid = window.sessionStorage.getItem("emplidColaborador");
    useEffect(() => {
        if (!foto || foto === null || foto === "") {
            const getFoto = async () => {
                await caches.match(urlsAws.getPhotosEquipo + emplid).then((response) => {
                    if (response) {
                        response.json().then((data) => {
                            setFoto(data.photo);
                        });
                    }
                })
            };
            getFoto();
        }
    }, [foto]);
    return (pdf ?
        <View style={{ ...styles.card, width: width }}>
            <View style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
                <Image source={foto === "" ? defaultColaboradorImage : foto[0] === "i" ? `data:image/png;base64,${foto}` : foto[0] === "/" ? `data:image/jpeg;base64,${foto}` : defaultColaboradorImage} style={styles.foto} />
            </View>
            <View style={styles.body}>
                <View style={styles.li}>
                    <View style={styles.liTitle}>
                        <Text style={styles.titleLabel}>NOMBRE</Text>
                    </View>
                    <View style={styles.liContent}>
                        <Text style={styles.contentLabel}>{colaborador.CN_PERS_LIST.nombre}</Text>
                    </View>
                </View>
                <View style={styles.li}>
                    <View style={styles.liTitle}>
                        <Text style={styles.titleLabel}>RUT</Text>
                    </View>
                    <View style={styles.liContent}>
                        <Text style={styles.contentLabel}>{colaborador.CN_PERS_LIST.rut}</Text>
                    </View>
                </View>
                <View style={styles.li}>
                    <View style={styles.liTitle}>
                        <Text style={styles.titleLabel}>FECHA DE NACIMIENTO</Text>
                    </View>
                    <View style={styles.liContent}>
                        <Text style={styles.contentLabel}>{formatDate(colaborador.CN_PERS_LIST.fecha_nacimiento)}</Text>
                    </View>
                </View>
                <View style={styles.li}>
                    <View style={styles.liTitle}>
                        <Text style={styles.titleLabel}>EDAD</Text>
                    </View>
                    <View style={styles.liContent}>
                        <Text style={styles.contentLabel}>{colaborador.CN_PERS_LIST.edad}</Text>
                    </View>
                </View>
                <View style={styles.li}>
                    <View style={styles.liTitle}>
                        <Text style={styles.titleLabel}>PCD</Text>
                    </View>
                    <View style={styles.liContent}>
                        <Text style={styles.contentLabel}>{colaborador.CN_PERS_LIST.pcd}</Text>
                    </View>
                </View>
                <View style={styles.li}>
                    <View style={styles.liTitle}>
                        <Text style={styles.titleLabel}>NACIONALIDAD</Text>
                    </View>
                    <View style={styles.liContent}>
                        <Text style={styles.contentLabel}>{colaborador.CN_PERS_LIST.nacionalidad}</Text>
                    </View>
                </View>
                <View style={styles.li}>
                    <View style={styles.liTitle}>
                        <Text style={styles.titleLabel}>GRUPO DE RIESGO</Text>
                    </View>
                    <View style={styles.liContent}>
                        <Text style={styles.contentLabel}>{colaborador.CN_PERS_LIST.grupo_riesgo}</Text>
                    </View>
                </View>
                <View style={styles.li}>
                    <View style={styles.liTitle}>
                        <Text style={styles.titleLabel}>FUERO</Text>
                    </View>
                    <View style={styles.liContent}>
                        <Text style={styles.contentLabel}>{colaborador.CN_PERS_LIST.fuero}</Text>
                    </View>
                </View>
            </View>
        </View>
        :
        <div className="col-12 col-xl-5 d-flex">
            <div className="box align-items-stretch w-100">
                <div className="data-list-box text-uppercase">
                    <ul className="list-unstyled">
                        <li className="justify-content-center border-0">
                            {!foto && <img src={defaultColaboradorImage} alt="foto colaborador" className="rounded-circle img-fluid mb-2" width="160" height="160" />}
                            {foto && <img src={`data:image/png;base64,${foto}`} alt="foto colaborador" className="rounded-circle img-fluid mb-2" width="160" height="160" />}
                        </li>
                        <li>
                            <span className="data-list-box__title col-12 col-sm-6">Nombre</span>
                            <span className="data-list-box__text">{colaborador.CN_PERS_LIST.nombre}</span>
                        </li>
                        <li>
                            <span className="data-list-box__title col-12 col-sm-6">RUT</span>
                            <span className="data-list-box__text">{colaborador.CN_PERS_LIST.rut}</span>
                        </li>
                        <li>
                            <span className="data-list-box__title col-12 col-sm-6">Fecha de nacimiento</span>
                            <span className="data-list-box__text">{formatDate(colaborador.CN_PERS_LIST.fecha_nacimiento)}</span>
                        </li>
                        <li>
                            <span className="data-list-box__title col-12 col-sm-6">Edad</span>
                            <span className="data-list-box__text">{colaborador.CN_PERS_LIST.edad}</span>
                        </li>
                        <li>
                            <span className="data-list-box__title col-12 col-sm-6">PCD</span>
                            <span className="data-list-box__text">{colaborador.CN_PERS_LIST.pcd}</span>
                        </li>
                        <li>
                            <span className="data-list-box__title col-12 col-sm-6">Nacionalidad</span>
                            <span className="data-list-box__text">{colaborador.CN_PERS_LIST.nacionalidad}</span>
                        </li>
                        <li>
                            <span className="data-list-box__title col-12 col-sm-6">Grupo de riesgo</span>
                            <span className="data-list-box__text">{colaborador.CN_PERS_LIST.grupo_riesgo}</span>
                        </li>
                        <li>
                            <span className="data-list-box__title col-12 col-sm-6">Fuero</span>
                            <span className="data-list-box__text">{colaborador.CN_PERS_LIST.fuero}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
};
const FichaColaborador = ({ colaborador, setColaborador, items, setItems, setWarningLetter, setCollaboratorEmplid, setFichaSIC, setFromEquipo }) => {
    const [carouselSelected, setCarouselSelected] = useState(0);
    const [diasTomadosData, setDiasTomadosData] = useState(false);
    const cambioRentaData = Array.isArray(colaborador.CN_RENTA_LIST) ? colaborador.CN_RENTA_LIST.slice(0, 10).reverse() : colaborador.CN_RENTA_LIST;
    const [dataGraficoCambiosRenta, setDataGraficoCambiosRenta] = useState(false);
    const [cambioRentaporAno, setCambioRentaporAno] = useState(false);
    const [cartolaVacaciones, setCartolaVacaciones] = useState(false);
    const [dataGraficoCartolaVacaciones, setDataGraficoCartolaVacaciones] = useState(false);
    const [estadoDiasTomados, setEstadoDiasTomados] = useState(false);
    const [estadoCartola, setEstadoCartola] = useState(false);
    const [firmaDocumentosData, setFirmaDocumentosData] = useState(false);
    const [width, ] = useWindowSize();
    const [hiddenTd, setHiddenTd] = useState(true);
    let col = colaborador;
    const getItems = () => {
        let temp = JSON.parse(window.sessionStorage.getItem("miEquipo"));
        if (temp !== null) {
            for (let i = 0; i < temp.length; i++) {
                caches.match(urlsAws.getColaborador + "?rut=" + temp[i].EMPLID)
                    .then((response) => {
                        if (response !== undefined) temp[i]["cached"] = true;
                        else temp[i]["cached"] = false;
                    })
                    .catch(() => {
                        temp[i]["cached"] = false;
                    });
            }
            const emplid = localStorage.getItem("emplid");
            caches.open("api-cache").then((cache) => {
                cache.put(urlsAws.getMiEquipo + "?emplid=" + emplid, new Response(JSON.stringify({ response: { CN_JEFESUB_LIST: temp } })));
            });
        }
        return temp;
    }

    useEffect(() => {
        if (cambioRentaData) {
            let data = {
                datasets: [
                    {
                        type: "line",
                        label: "Sal Base",
                        yAxisID: "yaxis1",
                        data: Array.isArray(cambioRentaData) ? cambioRentaData.map((item) => item.salario_base) : [cambioRentaData.salario_base],
                        borderColor: "#5071be",
                        backgroundColor: "#5071be",
                        datalabels: {
                            align: "top",
                            anchor: "end",
                            color: "grey",
                        },
                    },
                    {
                        type: "bar",
                        label: "% Cambio",
                        yAxisID: "yaxis2",
                        data: Array.isArray(cambioRentaData) ? cambioRentaData.map((item) => item.perc_cambio) : [cambioRentaData.perc_cambio],
                        borderColor: "#d49449",
                        backgroundColor: "#d49449",
                        datalabels: {
                            align: "top",
                            anchor: "end",
                            color: "transparent",
                        },
                    },
                    {
                        type: "line",
                        label: "lineal (Sal Base)",
                        yAxisID: "yaxis3",
                        data: Array.isArray(cambioRentaData) ? regresionLineal(cambioRentaData) : regresionLineal([cambioRentaData]),
                        borderColor: "#5071be",
                        backgroundColor: "transparent",
                        borderDash: [10, 5],
                        datalabels: {
                            align: "top",
                            anchor: "end",
                            color: "transparent",
                        },
                    },
                ],
                labels: Array.isArray(cambioRentaData)
                    ? cambioRentaData.map((item) => {
                        return `${item.motivo} ${item.anio}`;
                    })
                    : [cambioRentaData.motivo + " " + cambioRentaData.anio],
            };
            let options = {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: "bottom",
                    },
                },
                layout: {
                    padding: {
                        top: 20,
                    },
                },
                scales: {
                    yaxis1: {
                        beginAtZero: true,
                        type: "linear",
                        position: "left",
                    },
                    yaxis2: {
                        beginAtZero: true,
                        type: "linear",
                        position: "right",
                        grid: {
                            drawOnChartArea: false,
                        },
                    },
                    yaxis3: {
                        type: "linear",
                        position: "left",
                        display: false,
                        grid: {
                            drawOnChartArea: false,
                        },
                    },
                },
            }
            setDataGraficoCambiosRenta({ data: data, options: options });
            setCambioRentaporAno(groupBy(colaborador.CN_RENTA_LIST, "anio"));
        }
    }, []);

    useEffect(() => {
        if (cartolaVacaciones) {
            if (cartolaVacaciones !== "error") {
                let data = {};
                data.labels = cartolaVacaciones ? (Array.isArray(cartolaVacaciones.detalle_vacacion) ? cartolaVacaciones.detalle_vacacion.map((item) => `${item.periodo}`.slice(0, 4)) : [`${cartolaVacaciones.detalle_vacacion.periodo}`]) : ["vacio"];
                data.datasets = [
                    {
                        label: "Vacaciones tomadas",
                        data: cartolaVacaciones ? (Array.isArray(cartolaVacaciones.detalle_vacacion) ? cartolaVacaciones.detalle_vacacion.map((item) => `${item.dys_take}`) : [`${cartolaVacaciones.detalle_vacacion.dys_take}`]) : ["vacio"],
                        borderColor: "#3e95cd",
                        backgroundColor: "#3e95cd",
                        datalabels: {
                            align: "top",
                            anchor: "end",
                            color: "grey",
                        },
                    },
                ];
                data.labels = data.labels.reverse();
                data.datasets[0].data = data.datasets[0].data.reverse();
                let options = {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                            position: "bottom",
                        },
                    },
                    layout: {
                        padding: {
                            top: 20,
                        },
                    },
                }
                setDataGraficoCartolaVacaciones({ data: data, options: options });
            } else if (cartolaVacaciones === "error") {
                const emplid = sessionStorage.getItem("emplidColaborador");
                miEquipoService.getCartola(setCartolaVacaciones, emplid, setEstadoCartola);
            }
        } else {
            let data = {};
            data.labels = [];
            data.datasets = [
                {
                    label: "Vacaciones tomadas",
                    data: [],
                    borderColor: "#3e95cd",
                    backgroundColor: "#3e95cd",
                    datalabels: {
                        align: "top",
                        anchor: "end",
                        color: "grey",
                    },
                },
            ];
            data.labels = data.labels.reverse();
            data.datasets[0].data = data.datasets[0].data.reverse();
            let options = {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: "bottom",
                    },
                },
                layout: {
                    padding: {
                        top: 20,
                    },
                },
            }
            setDataGraficoCartolaVacaciones({ data: data, options: options });
            const emplid = sessionStorage.getItem("emplidColaborador");
            miEquipoService.getCartola(setCartolaVacaciones, emplid, setEstadoCartola);
        }
        if (diasTomadosData) {
            if (diasTomadosData === "error") {
                miEquipoService.getDiasTomados(setDiasTomadosData, colaborador.CN_PERS_LIST.rut, setEstadoDiasTomados);
            }
        } else {
            miEquipoService.getDiasTomados(setDiasTomadosData, colaborador.CN_PERS_LIST.rut, setEstadoDiasTomados);
        }
    }, [cartolaVacaciones, diasTomadosData]);

    useEffect(() => {
        if (carouselSelected === "1") {
            setCarouselSelected(1);
        }
    }, [carouselSelected]);

    useCarousel(0,'carousel-ficha-resumen-mobile', 1);
 

    return (
        <>
            <nav id="pansito" className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link
                            to="#"
                            onClick={(e) => {
                                e.preventDefault();
                                // const items = getItems()
                                // setItems(items);
                                setColaborador(false);
                                setCollaboratorEmplid("");
                            }}
                        >
                            {" "}
                            Mi Equipo
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Ficha Resumen
                    </li>
                </ol>
            </nav>
            <section className="ficha-resumen" id="ficha-resumen">
                <h1 id="titulo ">Ficha Resumen</h1>
                { 
                    colaborador &&
                    <div className="text-md-end mx-4 mb-2">
                        <button className="btn btn-outline-orange btn-lg dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Acciones</button>
                        <ul className="dropdown-menu border-orange dropdown-menu-hover-orange w-auto">
                            <li>
                                <Link className="dropdown-item" to="#" onClick={(e) => { e.preventDefault(); setFichaSIC(true); setFromEquipo(true); setCollaboratorEmplid(colaborador.CN_PERS_LIST.rut) } }>Solicitar SIC de Reemplazo</Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="#" onClick={(e) => { e.preventDefault(); setWarningLetter(true); setFromEquipo(true); setCollaboratorEmplid(colaborador.CN_PERS_LIST.rut) }}>Solicitud de Amonestación</Link>
                            </li>
                        </ul>
                    </div>
                }
                {/* CARRUSEL ESCRITORIO */}
                <div id="carousel-ficha-resumen" className="d-none d-md-block">
                    <div className="row">
                        <div className="col mb-4">
                            <Link
                                onClick={() => { setCarouselSelected(0) }}
                                className={`btn btn-xl text-uppercase ${carouselSelected === 0 ? "btn-degradado-2" : ""}`}
                                to="#"
                            >
                                <span className="align-self-center">Datos de puesto / Compensación</span>
                                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                            </Link>
                        </div>
                        <div className="col mb-4">
                            <Link
                                onClick={() => { setCarouselSelected("1"); }}
                                className={`btn btn-xl text-uppercase ${carouselSelected === 1 ? "btn-degradado-2" : ""}`}
                                to="#"
                            >
                                <span className="align-self-center">Datos de Remuneraciones</span>
                                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                            </Link>
                        </div>
                        <div className="col mb-4">
                            <Link
                                onClick={() => { setCarouselSelected(2); }}
                                className={`btn btn-xl text-uppercase ${carouselSelected === 2 ? "btn-degradado-2" : ""}`}
                                to="#"
                            >
                                <span className="align-self-center">{'Documentos y amonestaciones'}</span>
                                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                            </Link>
                        </div>
                        <div className="col mb-4">
                            <Link
                                onClick={() => {
                                    setCarouselSelected(3);
                                }}
                                className={`btn btn-xl text-uppercase ${carouselSelected === 3 ? "btn-degradado-2" : ""}`}
                                to="#"
                            >
                                <span className="align-self-center">Datos de Vacaciones</span>
                                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                            </Link>
                        </div>
                    </div>
                </div>
                {/* CARRUSEL MOBILE */}
                <div id="carousel-ficha-resumen-mobile" className="carousel carousel-dark slide carousel-control-inner d-md-none" data-bs-ride="carousel" data-bs-interval="false">
                    <div className="carousel-indicators">
                        <button
                            onClick={() => {
                                setCarouselSelected(0);
                            }}
                            type="button"
                            data-bs-target="#carousel-ficha-resumen-mobile"
                            data-bs-slide-to="0"
                            className={`${carouselSelected === 0 ? "active" : ""}`}
                            aria-current={carouselSelected === 0}
                            aria-label="Slide 1"
                        ></button>
                        <button
                            onClick={() => {
                                setCarouselSelected(1);
                            }}
                            type="button"
                            data-bs-target="#carousel-ficha-resumen-mobile"
                            data-bs-slide-to="1"
                            className={`${carouselSelected === 1 ? "active" : ""}`}
                            aria-current={carouselSelected === 1}
                            aria-label="Slide 2"
                        ></button>
                        <button
                            onClick={() => {
                                setCarouselSelected(2);
                            }}
                            type="button"
                            data-bs-target="#carousel-ficha-resumen-mobile"
                            data-bs-slide-to="2"
                            className={`${carouselSelected === 2 ? "active" : ""}`}
                            aria-current={carouselSelected === 2}
                            aria-label="Slide 3"
                        ></button>
                        <button
                            onClick={() => {
                                setCarouselSelected(3);
                            }}
                            type="button"
                            data-bs-target="#carousel-ficha-resumen-mobile"
                            data-bs-slide-to="3"
                            className={`${carouselSelected === 3 ? "active" : ""}`}
                            aria-current={carouselSelected === 3}
                            aria-label="Slide 4"
                        ></button>
                    </div>
                    <div className="carousel-inner">
                        <div className={`carousel-item p-2 ${carouselSelected === 0 ? 'active' : ''}`} data-bs-interval="10000">
                            <div className="row">
                                <div className="col mb-4">
                                    <Link
                                        onClick={() => {
                                            setCarouselSelected(0);
                                        }}
                                        className={`btn btn-xl text-uppercase ${carouselSelected === 0 ? "btn-degradado-2" : ""}`}
                                        to="#"
                                    >
                                        <span className="align-self-center">Datos de puesto / Compensación</span>
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className={`carousel-item p-2 ${carouselSelected === 1 ? 'active' : ''}`} data-bs-interval="10000">
                            <div className="row">
                                <div className="col mb-4">
                                    <Link
                                        onClick={() => {
                                            setCarouselSelected(1);
                                        }}
                                        className={`btn btn-xl text-uppercase ${carouselSelected === 1 ? "btn-degradado-2" : ""}`}
                                        to="#"
                                    >
                                        <span className="align-self-center">Datos de remuneraciones</span>
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className={`carousel-item p-2 ${carouselSelected === 2 ? 'active' : ''}`} data-bs-interval="10000">
                            <div className="row">
                                <div className="col mb-4">
                                    <Link
                                        onClick={() => {
                                            setCarouselSelected(2);
                                        }}
                                        className={`btn btn-xl text-uppercase ${carouselSelected === 2 ? "btn-degradado-2" : ""}`}
                                        to="#"
                                    >
                                        <span className="align-self-center">{'Documentos y amonestaciones'}</span>
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className={`carousel-item p-2 ${carouselSelected === 3 ? 'active' : ''}`} data-bs-interval="10000">
                            <div className="row">
                                <div className="col mb-4">
                                    <Link
                                        onClick={() => {
                                            setCarouselSelected(3);
                                        }}
                                        className={`btn btn-xl text-uppercase ${carouselSelected === 3 ? "btn-degradado-2" : ""}`}
                                        to="#"
                                    >
                                        <span className="align-self-center">Datos de Vacaciones</span>
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

               
                <div className="text-md-end mx-4 mb-2" id="descargarpdf">
                    {
                        carouselSelected === 0 ||
                            (carouselSelected === 1 && dataGraficoCambiosRenta) ||
                            (carouselSelected === 2 && firmaDocumentosData && firmaDocumentosData !== 'error') ||
                            (carouselSelected === 3 && ((cartolaVacaciones && cartolaVacaciones !== 'error') || (diasTomadosData && diasTomadosData !== 'error') || dataGraficoCartolaVacaciones)) ?
                            <PDFDownloadLink className="text-decoration-none d-inline-block mb-3 mt-4 color-orange" fileName={`FICHA-${carouselSelected === 0 ? "PUESTO" : carouselSelected === 1 ? "REMUNERACION" : carouselSelected === 2 ? "DOCUMENTOS" : carouselSelected === 3 ? "VACACIONES" : ""}-${colaborador.CN_PERS_LIST.nombre.replaceAll(' ', '-')}`}
                                document={
                                    carouselSelected === 0 ? <FichaPuesto colaborador={colaborador} pdf={true} />
                                        : carouselSelected === 1 ? <FichaRemuneracion colaborador={colaborador} pdf={true} dataGraficoCambiosRenta={dataGraficoCambiosRenta} cambioRentaporAno={cambioRentaporAno} setCambioRentaporAno={setCambioRentaporAno} />
                                            : carouselSelected === 2 ? <FichaDocumentos colaborador={colaborador} pdf={true} firmaDocumentosData={firmaDocumentosData} setFirmaDocumentosData={setFirmaDocumentosData} />
                                                : <FichaVacaciones colaborador={colaborador} pdf={true} cartolaVacaciones={cartolaVacaciones} setCartolaVacaciones={setCartolaVacaciones} diasTomadosData={diasTomadosData} setDiasTomadosData={setDiasTomadosData} dataGraficoCartolaVacaciones={dataGraficoCartolaVacaciones} setDataGraficoCartolaVacaciones={setDataGraficoCartolaVacaciones} estadoDiasTomados={estadoDiasTomados} setEstadoDiasTomados={setEstadoDiasTomados} estadoCartola={estadoCartola} setEstadoCartola={setEstadoCartola} />}>
                                <i className="fa fa-file-pdf-o me-1" aria-hidden="true"></i> Descargar
                            </PDFDownloadLink>
                            :
                            null

                    }
                    {
                        carouselSelected === 1 &&
                        <div className="form-check form-switch d-inline-block ms-4">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                defaultChecked={!hiddenTd}
                                checked={!hiddenTd}
                                id="datos-ocultos"
                                onChange={() => {
                                    setHiddenTd(!hiddenTd);
                                }}
                            />
                            <label className="form-check-label text-uppercase" htmlFor="datos-ocultos">
                                Datos visibles
                            </label>
                        </div>
                    }
                </div>
                {carouselSelected === 0 ? <FichaPuesto colaborador={col} pdf={false} />
                    : carouselSelected === 1 ? <FichaRemuneracion hiddenTd={hiddenTd} setHiddenTd={setHiddenTd} colaborador={col} pdf={false} dataGraficoCambiosRenta={dataGraficoCambiosRenta} cambioRentaporAno={cambioRentaporAno} setCambioRentaporAno={setCambioRentaporAno} />
                        : carouselSelected === 2 ? <FichaDocumentos colaborador={col} pdf={false} firmaDocumentosData={firmaDocumentosData} setFirmaDocumentosData={setFirmaDocumentosData} />
                            : <FichaVacaciones colaborador={col} pdf={false} cartolaVacaciones={cartolaVacaciones} setCartolaVacaciones={setCartolaVacaciones} diasTomadosData={diasTomadosData} setDiasTomadosData={setDiasTomadosData} dataGraficoCartolaVacaciones={dataGraficoCartolaVacaciones} setDataGraficoCartolaVacaciones={setDataGraficoCartolaVacaciones} estadoDiasTomados={estadoDiasTomados} setEstadoDiasTomados={setEstadoDiasTomados} estadoCartola={estadoCartola} setEstadoCartola={setEstadoCartola} />}
                <div style={{ "visibility": "hidden", "position": "absolute", "top": "-9999px", "maxWidth": `${width - 50}px` }}>
                    {dataGraficoCambiosRenta ? (
                        <Chart
                            id="graficoRemuneraciones2"
                            data={dataGraficoCambiosRenta.data}
                            type="line"
                            width={645}
                            height={null}
                            //plugins={[ChartDataLabels]}
                            options={dataGraficoCambiosRenta.options}
                        />
                    ) : null}
                </div>
            </section>
        </>
    );

};
const FichaPuesto = ({ colaborador, pdf }) => {
    colaborador.CN_EVALDES_LIST = Array.isArray(colaborador.CN_EVALDES_LIST) ? colaborador.CN_EVALDES_LIST : [colaborador.CN_EVALDES_LIST];
    colaborador.CN_TR_LIST = Array.isArray(colaborador.CN_TR_LIST) ? colaborador.CN_TR_LIST : [colaborador.CN_TR_LIST];
    colaborador.CN_HISTPOS_LIST = Array.isArray(colaborador.CN_HISTPOS_LIST) ? colaborador.CN_HISTPOS_LIST : [colaborador.CN_HISTPOS_LIST];
    colaborador.CN_HISTUBI_LIST = Array.isArray(colaborador.CN_HISTUBI_LIST) ? colaborador.CN_HISTUBI_LIST : [colaborador.CN_HISTUBI_LIST];
    colaborador.CN_CURNORM_LIST = Array.isArray(colaborador.CN_CURNORM_LIST) ? colaborador.CN_CURNORM_LIST : [colaborador.CN_CURNORM_LIST];
    colaborador.CN_CLIMA_LIST = Array.isArray(colaborador.CN_CLIMA_LIST) ? colaborador.CN_CLIMA_LIST : [colaborador.CN_CLIMA_LIST];
    const formattDate = (date) => {
        const dateArray = date.split('-');
        return `${dateArray[0]}/${dateArray[1]}/${dateArray[2]}`;
    }
    return (pdf ?
        <Document>
            <Page size="A4" style={styles.page}>
                <DatosPuesto colaborador={colaborador} pdf={pdf} width={"48%"} />
                {/* Datos de puesto / Compensacion */}
                <View style={styles.card}>
                    <View style={styles.head}>
                        <Text style={styles.h1}>Datos de Puesto / Compensación</Text>
                    </View>
                    <View style={styles.body}>
                        <View style={styles.li}>
                            <View style={styles.liTitle}>
                                <Text style={styles.titleLabel}>UBICACION</Text>
                            </View>
                            <View style={styles.liContent}>
                                <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.ubicacion}</Text>
                            </View>
                        </View>
                        <View style={styles.li}>
                            <View style={styles.liTitle}>
                                <Text style={styles.titleLabel}>CARGO</Text>
                            </View>
                            <View style={styles.liContent}>
                                <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.cargo}</Text>
                            </View>
                        </View>
                        <View style={styles.li}>
                            <View style={styles.liTitle}>
                                <Text style={styles.titleLabel}>E-MAIL</Text>
                            </View>
                            <View style={styles.liContent}>
                                <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.email}</Text>
                            </View>
                        </View>
                        <View style={styles.li}>
                            <View style={styles.liTitle}>
                                <Text style={styles.titleLabel}>FECHA DE INGRESO</Text>
                            </View>
                            <View style={styles.liContent}>
                                <Text style={styles.contentLabel}>{formatDate(colaborador.CN_JOB_LIST.fecha_ingreso)}</Text>
                            </View>
                        </View>
                        <View style={styles.li}>
                            <View style={styles.liTitle}>
                                <Text style={styles.titleLabel}>AÑOS DE ANTIGÜEDAD</Text>
                            </View>
                            <View style={styles.liContent}>
                                <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.antiguedad}</Text>
                            </View>
                        </View>
                        <View style={styles.li}>
                            <View style={styles.liTitle}>
                                <Text style={styles.titleLabel}>DÍAS DE AUSENCIA</Text>
                            </View>
                            <View style={styles.liContent}>
                                <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.dias_ausencia}</Text>
                            </View>
                        </View>
                        <View style={styles.li}>
                            <View style={styles.liTitle}>
                                <Text style={styles.titleLabel}>SALDO VACACIONES</Text>
                            </View>
                            <View style={styles.liContent}>
                                <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.saldo_vacaciones}</Text>
                            </View>
                        </View>
                        <View style={styles.li}>
                            <View style={styles.liTitle}>
                                <Text style={styles.titleLabel}>JEFE DIRECTO</Text>
                            </View>
                            <View style={styles.liContent}>
                                <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.jefe_directo}</Text>
                            </View>
                        </View>
                        <View style={styles.li}>
                            <View style={styles.liTitle}>
                                <Text style={styles.titleLabel}>GGS</Text>
                            </View>
                            <View style={styles.liContent}>
                                <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.ggs}</Text>
                            </View>
                        </View>
                        <View style={styles.li}>
                            <View style={styles.liTitle}>
                                <Text style={styles.titleLabel}>BONOS</Text>
                            </View>
                            <View style={styles.liContent}>
                                <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.bonos}</Text>
                            </View>
                        </View>
                        <View style={styles.li}>
                            <View style={styles.liTitle}>
                                <Text style={styles.titleLabel}>MES DE PAGO</Text>
                            </View>
                            <View style={styles.liContent}>
                                <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.mes_pago}</Text>
                            </View>
                        </View>
                        <View style={styles.li}>
                            <View style={styles.liTitle}>
                                <Text style={styles.titleLabel}>CR</Text>
                            </View>
                            <View style={styles.liContent}>
                                <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.cr}</Text>
                            </View>
                        </View>
                        <View style={styles.li}>
                            <View style={styles.liTitle}>
                                <Text style={styles.titleLabel}>CONVENIO COLECTIVO</Text>
                            </View>
                            <View style={styles.liContent}>
                                <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.conv_colectivo}</Text>
                            </View>
                        </View>
                        <View style={styles.li}>
                            <View style={styles.liTitle}>
                                <Text style={styles.titleLabel}>SINDICATO</Text>
                            </View>
                            <View style={styles.liContent}>
                                <Text style={styles.contentLabel}>{colaborador.CN_JOB_LIST.sindicato}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                {/* Evaluacion de desempeño */}
                <View style={{ ...styles.card, width: "100%" }}>
                    <View style={styles.head}>
                        <Text style={styles.h1}>Evaluación de Desempeño</Text>
                    </View>
                    <View style={styles.body}>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={styles.tableRow}>
                                    <Text style={{ ...styles.labelHead, width: "33%" }}>AÑO</Text>
                                    <Text style={{ ...styles.labelHead, width: "33%" }}>NOTA CALIBRADA</Text>
                                    <Text style={{ ...styles.labelHead, width: "33%" }}>EVALUADOR</Text>
                                </View>
                            </View>
                            <View style={styles.tableBody}>
                                {
                                    colaborador.CN_EVALDES_LIST !== undefined ?
                                        colaborador.CN_EVALDES_LIST.map((item, index) => {
                                            return (
                                                <View style={styles.tableRow} key={index}>
                                                    <Text style={{ ...styles.labelContent, width: "33%" }}>{item.anio_evalua === "" ? "-" : item.anio_evalua}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "33%" }}>{item.nota_calibrada === "" ? "-" : item.nota_calibrada}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "33%" }}>{item.evaluador === "" ? "-" : item.evaluador}</Text>
                                                </View>
                                            )
                                        })
                                        :
                                        <View style={styles.tableRow}>
                                            <Text style={styles.labelContent}>Sin Registros</Text>
                                        </View>
                                }
                            </View>
                        </View>
                    </View>
                </View>
                {/* TR */}
                <View style={{ ...styles.card, width: "100%" }}>
                    <View style={styles.head}>
                        <Text style={styles.h1}>TR</Text>
                    </View>
                    <View style={styles.body}>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={styles.tableRow}>
                                    <Text style={{ ...styles.labelHead, width: "33%" }}>AÑO</Text>
                                    <Text style={{ ...styles.labelHead, width: "33%" }}>NOTA</Text>
                                    <Text style={{ ...styles.labelHead, width: "33%" }}>DESCRIPCIÓN</Text>
                                </View>
                            </View>
                            <View style={styles.tableBody}>
                                {
                                    colaborador.CN_TR_LIST !== undefined ?
                                        colaborador.CN_TR_LIST.map((item, index) => {
                                            return (
                                                <View style={styles.tableRow} key={index}>
                                                    <Text style={{ ...styles.labelContent, width: "33%" }}>{item.anio === "" ? "-" : item.anio}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "33%" }}>{item.nota === "" ? "-" : item.nota}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "33%" }}>{item.descripcion === "" ? "-" : item.descripcion}</Text>
                                                </View>
                                            )
                                        })
                                        :
                                        <View style={styles.tableRow}>
                                            <Text style={styles.labelContent}>Sin Registros</Text>
                                        </View>
                                }
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
                {/* Historial de posiciones */}
                <View style={{ ...styles.card, width: "100%" }}>
                    <View style={styles.head}>
                        <Text style={styles.h1}>Historial de Posiciones</Text>
                    </View>
                    <View style={styles.body}>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={styles.tableRow}>
                                    <Text style={{ ...styles.labelHead, width: "20%" }}>UBICACIÓN</Text>
                                    <Text style={{ ...styles.labelHead, width: "20%" }}>CARGO</Text>
                                    <Text style={{ ...styles.labelHead, width: "20%" }}>DESDE</Text>
                                    <Text style={{ ...styles.labelHead, width: "20%" }}>HASTA</Text>
                                    <Text style={{ ...styles.labelHead, width: "20%" }}>AÑOS TOTAL</Text>
                                </View>
                            </View>
                            <View style={styles.tableBody}>
                                {
                                    colaborador.CN_HISTPOS_LIST !== undefined ?
                                        colaborador.CN_HISTPOS_LIST.map((item, index) => {
                                            return (
                                                <View style={styles.tableRow} key={index}>
                                                    <Text style={{ ...styles.labelContent, width: "20%" }}>{item.ubicacion === "" ? "-" : item.ubicacion}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "20%" }}>{item.cargo === "" ? "-" : item.cargo}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "20%" }}>{item.desde === "" ? "-" : formatDate(item.desde)}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "20%" }}>{item.hasta === "" ? "-" : formatDate(item.hasta)}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "20%" }}>{item.total_anios === "" ? "-" : item.total_anios}</Text>
                                                </View>
                                            )
                                        })
                                        :
                                        <View style={styles.tableRow}>
                                            <Text style={styles.labelContent}>Sin Registros</Text>
                                        </View>
                                }
                            </View>
                        </View>
                    </View>
                </View>
                {/* Historial de ubicaciones */}
                <View style={{ ...styles.card, width: "100%" }}>
                    <View style={styles.head}>
                        <Text style={styles.h1}>Historial de Ubicaciones</Text>
                    </View>
                    <View style={styles.body}>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={styles.tableRow}>
                                    <Text style={{ ...styles.labelHead, width: "20%" }}>UBICACIÓN</Text>
                                    <Text style={{ ...styles.labelHead, width: "20%" }}>CARGO</Text>
                                    <Text style={{ ...styles.labelHead, width: "20%" }}>DESDE</Text>
                                    <Text style={{ ...styles.labelHead, width: "20%" }}>HASTA</Text>
                                    <Text style={{ ...styles.labelHead, width: "20%" }}>AÑOS TOTAL</Text>
                                </View>
                            </View>
                            <View style={styles.tableBody}>
                                {
                                    colaborador.CN_HISTUBI_LIST !== undefined ?
                                        colaborador.CN_HISTUBI_LIST.map((item, index) => {
                                            return (
                                                <View style={styles.tableRow} key={index}>
                                                    <Text style={{ ...styles.labelContent, width: "20%" }}>{item.ubicacion === "" ? "-" : item.ubicacion}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "20%" }}>{item.cargo === "" ? "-" : item.cargo}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "20%" }}>{item.desde === "" ? "-" : formatDate(item.desde)}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "20%" }}>{item.hasta === "" ? "-" : formatDate(item.hasta)}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "20%" }}>{item.total_anios === "" ? "-" : item.total_anios}</Text>
                                                </View>
                                            )
                                        })
                                        :
                                        <View style={styles.tableRow}>
                                            <Text style={styles.labelContent}>Sin Registros</Text>
                                        </View>
                                }
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
                {/* Cursos Formativos */}
                <View style={{ ...styles.card, width: "100%" }}>
                    <View style={styles.head}>
                        <Text style={styles.h1}>Cursos Formativos</Text>
                    </View>
                    <View style={styles.body}>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={styles.tableRow}>
                                    <Text style={{ ...styles.labelHead, width: "50%" }}>CURSO</Text>
                                    <Text style={{ ...styles.labelHead, width: "50%" }}>ESTADO</Text>
                                </View>
                            </View>
                            <View style={styles.tableBody}>
                                {
                                    colaborador.CN_CURNORM_LIST !== undefined ?
                                        colaborador.CN_CURNORM_LIST.map((item, index) => {
                                            return (
                                                <View style={styles.tableRow} key={index}>
                                                    <Text style={{ ...styles.labelContent, width: "50%" }}>{item.curso === "" ? "-" : item.curso}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "50%" }}>{item.estado === "" ? "-" : item.estado}</Text>
                                                </View>
                                            )
                                        })
                                        :
                                        <View style={styles.tableRow}>
                                            <Text style={styles.labelContent}>Sin Registros</Text>
                                        </View>
                                }
                            </View>
                        </View>
                    </View>
                </View>
                {/* Clima */}
                <View style={{ ...styles.card, width: "100%" }}>
                    <View style={styles.head}>
                        <Text style={styles.h1}>Clima</Text>
                    </View>
                    <View style={styles.body}>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={styles.tableRow}>
                                    <Text style={{ ...styles.labelHead, width: "50%" }}>AÑO</Text>
                                    <Text style={{ ...styles.labelHead, width: "50%" }}>NOTA</Text>
                                </View>
                            </View>
                            <View style={styles.tableBody}>
                                {
                                    colaborador.CN_CLIMA_LIST !== undefined ?
                                        colaborador.CN_CLIMA_LIST.map((item, index) => {
                                            return (
                                                <View style={styles.tableRow} key={index}>
                                                    <Text style={{ ...styles.labelContent, width: "50%" }}>{item.anio === "" ? "-" : item.anio}</Text>
                                                    <Text style={{ ...styles.labelContent, width: "50%" }}>{item.nota === "" ? "-" : item.nota}</Text>
                                                </View>
                                            )
                                        })
                                        :
                                        <View style={styles.tableRow}>
                                            <Text style={styles.labelContent}>Sin Registros</Text>
                                        </View>
                                }
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
        :
        <div id="fichaPuesto">
            <div className="row row-cols-1 g-4 mb-4">
                <DatosPuesto colaborador={colaborador} />
                <div className="col-12 col-xl-7 d-flex">
                    <div className="box align-items-stretch w-100">
                        <h2 className="h4 tit-section">Datos de Puesto / Compensación</h2>
                        <div className="data-list-box data-list-box-even text-uppercase">
                            <ul className="list-unstyled m-0">
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">Ubicación</span>
                                    <span className="data-list-box__text"> {colaborador.CN_JOB_LIST.ubicacion}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">Cargo</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.cargo}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">E-mail</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.email}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">Fecha de ingreso</span>
                                    <span className="data-list-box__text">{formattDate(colaborador.CN_JOB_LIST.fecha_ingreso)}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">Años de Antigüedad</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.antiguedad}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">Días de ausencia</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.dias_ausencia}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">Saldo vacaciones</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.saldo_vacaciones}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">Jefe directo</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.jefe_directo}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">GGS</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.ggs}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">Bonos</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.bonos}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">Mes de pago</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.mes_pago}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">CR</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.cr}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">Convenio colectivo</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.conv_colectivo}</span>
                                </li>
                                <li>
                                    <span className="data-list-box__title col-12 col-sm-5">Sindicato</span>
                                    <span className="data-list-box__text">{colaborador.CN_JOB_LIST.sindicato}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="box mb-4">
                <ul className="nav nav-tabs text-uppercase" role="tablist">
                    <li className="nav-item w-50" role="presentation">
                        <button className="nav-link w-100 active" id="detalle-evaluaciones-tab" data-bs-toggle="tab" data-bs-target="#detalle-evaluaciones" type="button" role="tab" aria-controls="detalle-evaluaciones" aria-selected="true">
                            Detalle evaluaciones
                        </button>
                    </li>
                    <li className="nav-item w-50" role="presentation">
                        <button className="nav-link w-100" id="tr-tab" data-bs-toggle="tab" data-bs-target="#tr" type="button" role="tab" aria-controls="tr" aria-selected="false">
                            TR
                        </button>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane fade show pt-4 active" id="detalle-evaluaciones" role="tabpanel" aria-labelledby="detalle-evaluaciones-tab">
                        <h2 className="h4 tit-section">Detalle</h2>
                        <table className="table table-even table-section table-borderless text-uppercase d-none d-md-table">
                            <thead>
                                <tr>
                                    <th className="col-2">Año</th>
                                    <th className="col-2">Nota Calibrada</th>
                                    <th className="col-2">Evaluador</th>
                                </tr>
                            </thead>
                            <tbody>
                                {colaborador.CN_EVALDES_LIST !== undefined ? (
                                    Array.isArray(colaborador.CN_EVALDES_LIST) ? (
                                        Children.toArray(colaborador.CN_EVALDES_LIST.map((item) => {
                                            return (
                                                <tr >
                                                    <td>{item.anio_evalua === "" ? "-" : item.anio_evalua}</td>
                                                    <td>{item.nota_calibrada === "" ? "-" : item.nota_calibrada}</td>
                                                    <td>{item.evaluador === "" ? "-" : item.evaluador}</td>
                                                </tr>
                                            );
                                        }))
                                    ) : (
                                        <tr>
                                            <td>{colaborador.CN_EVALDES_LIST.anio_evalua === "" ? "-" : colaborador.CN_EVALDES_LIST.anio_evalua}</td>
                                            <td>{colaborador.CN_EVALDES_LIST.nota_calibrada === "" ? "-" : colaborador.CN_EVALDES_LIST.nota_calibrada}</td>
                                            <td>{colaborador.CN_EVALDES_LIST.evaluador === "" ? "-" : colaborador.CN_EVALDES_LIST.evaluador}</td>
                                        </tr>
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan="3">No hay datos</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
                            {colaborador.CN_EVALDES_LIST !== undefined ? (
                                Array.isArray(colaborador.CN_EVALDES_LIST) ? (
                                    Children.toArray(colaborador.CN_EVALDES_LIST.map((item, index) => {
                                        return (
                                            <div className="accordion-item" >
                                                <h2 className="accordion-header" id={"accordion" + index}>
                                                    <button className={`accordion-button ${index === 0 ? " " : "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target={"#accordion-item-evaluaciones-" + index} aria-expanded={index === 0 ? "true" : "false"} aria-controls={"accordion-item-evaluaciones-" + index}>
                                                        <strong className="text-uppercase">Evaluación año {item.anio_evalua}</strong>
                                                    </button>
                                                </h2>
                                                <div id={"accordion-item-evaluaciones-" + index} className={`accordion-collapse collapse ${index === 0 ? "show" : " "}`} aria-labelledby={"accordion-" + index}>
                                                    <div className="accordion-body p-0">
                                                        <div className="data-list-box data-list-box-even text-uppercase">
                                                            <ul className="list-column-1 list-unstyled">
                                                                <li>
                                                                    <span className="data-list-box__title">Nota calibrada</span>
                                                                    <span className="data-list-box__text">{item.nota_calibrada === "" ? "-" : item.nota_calibrada}</span>
                                                                </li>
                                                                <li>
                                                                    <span className="data-list-box__title">Evaluador</span>
                                                                    <span className="data-list-box__text">{item.evaluador === "" ? "-" : item.evaluador}</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }))
                                ) : (
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="accordion-one">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-item-evaluaciones-1" aria-expanded="true" aria-controls="accordion-item-evaluaciones-1">
                                                <strong className="text-uppercase">Evaluación año {colaborador.CN_EVALDES_LIST.anio_evalua}</strong>
                                            </button>
                                        </h2>
                                        <div id="accordion-item-evaluaciones-1" className="accordion-collapse collapse show" aria-labelledby="accordion-one">
                                            <div className="accordion-body p-0">
                                                <div className="data-list-box data-list-box-even text-uppercase">
                                                    <ul className="list-column-1 list-unstyled">
                                                        <li>
                                                            <span className="data-list-box__title">Nota calibrada</span>
                                                            <span className="data-list-box__text">{colaborador.CN_EVALDES_LIST.nota_calibrada === "" ? "-" : colaborador.CN_EVALDES_LIST.nota_calibrada}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Evaluador</span>
                                                            <span className="data-list-box__text">{colaborador.CN_EVALDES_LIST.evaluador === "" ? "-" : colaborador.CN_EVALDES_LIST.evaluador}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <li>
                                    <span className="data-list-box__title">No hay datos</span>
                                </li>
                            )}
                        </div>
                    </div>
                    <div className="tab-pane pt-4 fade" id="tr" role="tabpanel" aria-labelledby="tr-tab">
                        <h2 className="h4 tit-section">TR</h2>
                        <table className="table table-even table-section table-borderless text-uppercase d-none d-md-table">
                            <thead>
                                <tr>
                                    <th className="col-2">Año</th>
                                    <th className="col-2">Nota</th>
                                    <th className="col-2">Descripción</th>
                                </tr>
                            </thead>
                            <tbody>
                                {colaborador.CN_TR_LIST !== undefined ? (
                                    Array.isArray(colaborador.CN_TR_LIST) ? (
                                        Children.toArray(colaborador.CN_TR_LIST.map((item, index) => {
                                            const indice = (colaborador.CN_TR_LIST.length - 1) - index;
                                            return (
                                                <tr >
                                                    <td>{colaborador.CN_TR_LIST[indice].anio === "" ? "-" : colaborador.CN_TR_LIST[indice].anio}</td>
                                                    <td>{colaborador.CN_TR_LIST[indice].nota === "" ? "-" : colaborador.CN_TR_LIST[indice].nota}</td>
                                                    <td>{colaborador.CN_TR_LIST[indice].descripcion === "" ? "-" : colaborador.CN_TR_LIST[indice].descripcion}</td>
                                                </tr>
                                            );
                                        }))
                                    ) : (
                                        <tr>
                                            <td>{colaborador.CN_TR_LIST.anio === "" ? "-" : colaborador.CN_TR_LIST.anio}</td>
                                            <td>{colaborador.CN_TR_LIST.nota === "" ? "-" : colaborador.CN_TR_LIST.nota}</td>
                                            <td>{colaborador.CN_TR_LIST.descripcion === "" ? "-" : colaborador.CN_TR_LIST.descripcion}</td>
                                        </tr>
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan="3">No hay datos</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
                            {colaborador.CN_TR_LIST !== undefined ? (
                                Array.isArray(colaborador.CN_TR_LIST) ? (
                                    Children.toArray(colaborador.CN_TR_LIST.map((item, index) => {
                                        const indice = (colaborador.CN_TR_LIST.length - 1) - index;
                                        return (
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id={"accordion" + index}>
                                                    <button className={`accordion-button ${index === 0 ? " " : "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target={"#accordion-item-tr-" + index} aria-expanded={index === 0 ? "true" : "false"} aria-controls={"accordion-item-tr-" + index}>
                                                        <strong className="text-uppercase">Evaluación año {colaborador.CN_TR_LIST[indice].anio}</strong>
                                                    </button>
                                                </h2>
                                                <div id={"accordion-item-tr-" + index} className={`accordion-collapse collapse ${index === 0 ? "show" : " "}`} aria-labelledby={"accordion-" + index}>
                                                    <div className="accordion-body p-0">
                                                        <div className="data-list-box data-list-box-even text-uppercase">
                                                            <ul className="list-column-1 list-unstyled">
                                                                <li>
                                                                    <span className="data-list-box__title">Nota calibrada</span>
                                                                    <span className="data-list-box__text">{colaborador.CN_TR_LIST[indice].nota === "" ? "-" : colaborador.CN_TR_LIST[indice].nota}</span>
                                                                </li>
                                                                <li>
                                                                    <span className="data-list-box__title">Desc</span>
                                                                    <span className="data-list-box__text">{colaborador.CN_TR_LIST[indice].descripcion === "" ? "-" : colaborador.CN_TR_LIST[indice].descripcion}</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }))
                                ) : (
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="accordion-one">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-item-evaluaciones-1" aria-expanded="true" aria-controls="accordion-item-evaluaciones-1">
                                                <strong className="text-uppercase">Evaluación año {colaborador.CN_TR_LIST.anio}</strong>
                                            </button>
                                        </h2>
                                        <div id="accordion-item-evaluaciones-1" className="accordion-collapse collapse show" aria-labelledby="accordion-ad-one">
                                            <div className="accordion-body p-0">
                                                <div className="data-list-box data-list-box-even text-uppercase">
                                                    <ul className="list-column-1 list-unstyled">
                                                        <li>
                                                            <span className="data-list-box__title">Nota calibrada</span>
                                                            <span className="data-list-box__text">{colaborador.CN_TR_LIST.nota === "" ? "-" : colaborador.CN_TR_LIST.nota}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Descripción</span>
                                                            <span className="data-list-box__text">{colaborador.CN_TR_LIST.descripcion === "" ? "-" : colaborador.CN_TR_LIST.descripcion}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <li>
                                    <span className="data-list-box__title">No hay datos</span>
                                </li>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="box mb-4">
                <ul className="nav nav-tabs text-uppercase" role="tablist">
                    <li className="nav-item w-50" role="presentation">
                        <button className="nav-link w-100 active" id="historial-posiciones-tab" data-bs-toggle="tab" data-bs-target="#historial-posiciones" type="button" role="tab" aria-controls="historial-posiciones" aria-selected="true">
                            Historial de Posiciones
                        </button>
                    </li>
                    <li className="nav-item w-50" role="presentation">
                        <button className="nav-link w-100" id="historial-ubicaciones-tab" data-bs-toggle="tab" data-bs-target="#historial-ubicaciones" type="button" role="tab" aria-controls="historial-ubicaciones" aria-selected="false">
                            Historial de Ubicaciones
                        </button>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane fade show pt-4 active" id="historial-posiciones" role="tabpanel" aria-labelledby="historial-posiciones-tab">
                        <h2 className="h4 tit-section">Historial de Posiciones</h2>
                        <table className="table table-even table-section table-borderless text-uppercase d-none d-md-table">
                            <thead>
                                <tr>
                                    <th className="col-2">Ubicación</th>
                                    <th className="col-4">Cargo</th>
                                    <th className="col-2">Desde</th>
                                    <th className="col-2">Hasta</th>
                                    <th className="col-2">Años total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {colaborador.CN_HISTPOS_LIST !== undefined ? (
                                    Array.isArray(colaborador.CN_HISTPOS_LIST) ? (
                                        Children.toArray(colaborador.CN_HISTPOS_LIST.map((item) => {
                                            return (
                                                <tr >
                                                    <td>{item.ubicacion === "" ? "-" : item.ubicacion}</td>
                                                    <td>{item.cargo === "" ? "-" : item.cargo}</td>
                                                    <td>{item.desde === "" ? "-" : formatDate(item.desde)}</td>
                                                    <td>{item.hasta === "" ? "-" : formatDate(item.hasta)}</td>
                                                    <td>{item.total_anios === "" ? "-" : item.total_anios}</td>
                                                </tr>
                                            );
                                        }))
                                    ) : (
                                        <tr>
                                            <td>{colaborador.CN_HISTPOS_LIST.ubicacion === "" ? "-" : colaborador.CN_HISTPOS_LIST.ubicacion}</td>
                                            <td>{colaborador.CN_HISTPOS_LIST.cargo === "" ? "-" : colaborador.CN_HISTPOS_LIST.cargo}</td>
                                            <td>{colaborador.CN_HISTPOS_LIST.desde === "" ? "-" : formatDate(colaborador.CN_HISTPOS_LIST.desde)}</td>
                                            <td>{colaborador.CN_HISTPOS_LIST.hasta === "" ? "-" : formatDate(colaborador.CN_HISTPOS_LIST.hasta)}</td>
                                            <td>{colaborador.CN_HISTPOS_LIST.total_anios === "" ? "-" : colaborador.CN_HISTPOS_LIST.total_anios}</td>
                                        </tr>
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan="3">No hay datos</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="data-list-box data-list-box-sa text-uppercase d-md-none">
                            {colaborador.CN_HISTPOS_LIST !== undefined ? (
                                Array.isArray(colaborador.CN_HISTPOS_LIST) ? (
                                    Children.toArray(colaborador.CN_HISTPOS_LIST.map((item) => {
                                        return (
                                            <ul className="data-list-section--item list-unstyled" >
                                                <li>
                                                    <span className="data-list-box__title">Ubicación</span>
                                                    <span className="data-list-box__text">{item.ubicacion === "" ? "-" : item.ubicacion}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Cargo</span>
                                                    <span className="data-list-box__text">{item.cargo === "" ? "-" : item.cargo}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Desde</span>
                                                    <span className="data-list-box__text">{item.desde === "" ? "-" : formatDate(item.desde)}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Hasta</span>
                                                    <span className="data-list-box__text">{item.hasta === "" ? "-" : formatDate(item.hasta)}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Años total</span>
                                                    <span className="data-list-box__text">{item.total_anios === "" ? "-" : item.total_anios}</span>
                                                </li>
                                            </ul>
                                        )
                                    }))
                                ) : (
                                    <ul className="data-list-section--item list-unstyled">
                                        <li>
                                            <span className="data-list-box__title">Ubicación</span>
                                            <span className="data-list-box__text">{colaborador.CN_HISTPOS_LIST.ubicacion === "" ? "-" : colaborador.CN_HISTPOS_LIST.ubicacion}</span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title">Cargo</span>
                                            <span className="data-list-box__text">{colaborador.CN_HISTPOS_LIST.cargo === "" ? "-" : colaborador.CN_HISTPOS_LIST.cargo}</span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title">Desde</span>
                                            <span className="data-list-box__text">{colaborador.CN_HISTPOS_LIST.desde === "" ? "-" : formatDate(colaborador.CN_HISTPOS_LIST.desde)}</span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title">Hasta</span>
                                            <span className="data-list-box__text">{colaborador.CN_HISTPOS_LIST.hasta === "" ? "-" : formatDate(colaborador.CN_HISTPOS_LIST.hasta)}</span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title">Años total</span>
                                            <span className="data-list-box__text">{colaborador.CN_HISTPOS_LIST.total_anios === "" ? "-" : colaborador.CN_HISTPOS_LIST.total_anios}</span>
                                        </li>
                                    </ul>
                                )
                            ) : (
                                <li>
                                    <span className="data-list-box__title">No hay datos</span>
                                </li>
                            )}
                        </div>
                    </div>
                    <div className="tab-pane pt-4 fade" id="historial-ubicaciones" role="tabpanel" aria-labelledby="historial-ubicaciones-tab">
                        <h2 className="h4 tit-section">Historial de Ubicaciones</h2>
                        <table className="table table-even table-section table-borderless text-uppercase d-none d-md-table">
                            <thead>
                                <tr>
                                    <th className="col-2">Ubicación</th>
                                    <th className="col-4">Cargo</th>
                                    <th className="col-2">Desde</th>
                                    <th className="col-2">Hasta</th>
                                    <th className="col-2">Años total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {colaborador.CN_HISTUBI_LIST !== undefined ? (
                                    Array.isArray(colaborador.CN_HISTUBI_LIST) ? (
                                        Children.toArray(colaborador.CN_HISTUBI_LIST.map((item) => {
                                            return (
                                                <tr >
                                                    <td>{item.ubicacion === "" ? "-" : item.ubicacion}</td>
                                                    <td>{item.cargo === "" ? "-" : item.cargo}</td>
                                                    <td>{item.desde === "" ? "-" : formatDate(item.desde)}</td>
                                                    <td>{item.hasta === "" ? "-" : formatDate(item.hasta)}</td>
                                                    <td>{item.total_anios === "" ? "-" : item.total_anios}</td>
                                                </tr>
                                            );
                                        }))
                                    ) : (
                                        <tr>
                                            <td>{colaborador.CN_HISTUBI_LIST.ubicacion === "" ? "-" : colaborador.CN_HISTUBI_LIST.ubicacion}</td>
                                            <td>{colaborador.CN_HISTUBI_LIST.cargo === "" ? "-" : colaborador.CN_HISTUBI_LIST.cargo}</td>
                                            <td>{colaborador.CN_HISTUBI_LIST.desde === "" ? "-" : formatDate(colaborador.CN_HISTUBI_LIST.desde)}</td>
                                            <td>{colaborador.CN_HISTUBI_LIST.hasta === "" ? "-" : formatDate(colaborador.CN_HISTUBI_LIST.hasta)}</td>
                                            <td>{colaborador.CN_HISTUBI_LIST.total_anios === "" ? "-" : colaborador.CN_HISTUBI_LIST.total_anios}</td>
                                        </tr>
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan="3">No hay datos</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="data-list-box data-list-box-sa text-uppercase d-md-none">
                            {colaborador.CN_HISTUBI_LIST !== undefined ? (
                                Array.isArray(colaborador.CN_HISTUBI_LIST) ? (
                                    Children.toArray(colaborador.CN_HISTUBI_LIST.map((item) => {
                                        return (
                                            <ul className="data-list-section--item list-unstyled" >
                                                <li>
                                                    <span className="data-list-box__title">Ubicación</span>
                                                    <span className="data-list-box__text">{item.ubicacion === "" ? "-" : item.ubicacion}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Cargo</span>
                                                    <span className="data-list-box__text">{item.cargo === "" ? "-" : item.cargo}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Desde</span>
                                                    <span className="data-list-box__text">{item.desde === "" ? "-" : formatDate(item.desde)}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Hasta</span>
                                                    <span className="data-list-box__text">{item.hasta === "" ? "-" : formatDate(item.hasta)}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Años total</span>
                                                    <span className="data-list-box__text">{item.total_anios === "" ? "-" : item.total_anios}</span>
                                                </li>
                                            </ul>
                                        )
                                    }))
                                ) : (
                                    <ul className="data-list-section--item list-unstyled">
                                        <li>
                                            <span className="data-list-box__title">Ubicación</span>
                                            <span className="data-list-box__text">{colaborador.CN_HISTUBI_LIST.ubicacion === "" ? "-" : colaborador.CN_HISTUBI_LIST.ubicacion}</span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title">Cargo</span>
                                            <span className="data-list-box__text">{colaborador.CN_HISTUBI_LIST.cargo === "" ? "-" : colaborador.CN_HISTUBI_LIST.cargo}</span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title">Desde</span>
                                            <span className="data-list-box__text">{colaborador.CN_HISTUBI_LIST.desde === "" ? "-" : formatDate(colaborador.CN_HISTUBI_LIST.desde)}</span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title">Hasta</span>
                                            <span className="data-list-box__text">{colaborador.CN_HISTUBI_LIST.hasta === "" ? "-" : formatDate(colaborador.CN_HISTUBI_LIST.hasta)}</span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title">Años total</span>
                                            <span className="data-list-box__text">{colaborador.CN_HISTUBI_LIST.total_anios === "" ? "-" : colaborador.CN_HISTUBI_LIST.total_anios}</span>
                                        </li>
                                    </ul>
                                )
                            ) : (
                                <li>
                                    <span className="data-list-box__title">No hay datos</span>
                                </li>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="box mb-4">
                <ul className="nav nav-tabs text-uppercase" role="tablist">
                    <li className="nav-item w-50" role="presentation">
                        <button className="nav-link w-100 active" id="cursos-formativos-tab" data-bs-toggle="tab" data-bs-target="#cursos-formativos" type="button" role="tab" aria-controls="cursos-formativos" aria-selected="true">
                            Cursos Formativos
                        </button>
                    </li>
                    <li className="nav-item w-50" role="presentation">
                        <button className="nav-link w-100" id="clima-tab" data-bs-toggle="tab" data-bs-target="#clima" type="button" role="tab" aria-controls="clima" aria-selected="false">
                            Clima
                        </button>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane fade show pt-4 active" id="cursos-formativos" role="tabpanel" aria-labelledby="cursos-formativos-tab">
                        <h2 className="h4 tit-section">Cursos Formativos</h2>
                        <table className="table table-even table-section table-borderless text-uppercase d-none d-md-table">
                            <thead>
                                <tr>
                                    <th className="col-2">Curso</th>
                                    <th className="col-4">Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {colaborador.CN_CURNORM_LIST !== undefined ? (
                                    Array.isArray(colaborador.CN_CURNORM_LIST) ? (
                                        Children.toArray(colaborador.CN_CURNORM_LIST.map((item) => {
                                            return (
                                                <tr >
                                                    <td>{item.curso === "" ? "-" : item.curso}</td>
                                                    <td>{item.estado === "" ? "-" : item.estado}</td>
                                                </tr>
                                            );
                                        }))
                                    ) : (
                                        <tr>
                                            <td>{colaborador.CN_CURNORM_LIST.curso === "" ? "-" : colaborador.CN_CURNORM_LIST.curso}</td>
                                            <td>{colaborador.CN_CURNORM_LIST.estado === "" ? "-" : colaborador.CN_CURNORM_LIST.estado}</td>
                                        </tr>
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan="3">No hay datos</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="data-list-box data-list-box-sa text-uppercase d-md-none">
                            {colaborador.CN_CURNORM_LIST !== undefined ? (
                                Array.isArray(colaborador.CN_CURNORM_LIST) ? (
                                    Children.toArray(colaborador.CN_CURNORM_LIST.map((item) => {
                                        return (
                                            <ul className="data-list-section--item list-unstyled" >
                                                <li>
                                                    <span className="data-list-box__title">Curso</span>
                                                    <span className="data-list-box__text">{item.curso === "" ? "-" : item.curso}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Estado</span>
                                                    <span className="data-list-box__text">{item.estado === "" ? "-" : item.estado}</span>
                                                </li>
                                            </ul>
                                        );
                                    }))
                                ) : (
                                    <ul className="data-list-section--item list-unstyled">
                                        <li>
                                            <span className="data-list-box__title">Curso</span>
                                            <span className="data-list-box__text">{colaborador.CN_CURNORM_LIST.curso === "" ? "-" : colaborador.CN_CURNORM_LIST.curso}</span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title">Estado</span>
                                            <span className="data-list-box__text">{colaborador.CN_CURNORM_LIST.estado === "" ? "-" : colaborador.CN_CURNORM_LIST.estado}</span>
                                        </li>
                                    </ul>
                                )
                            ) : (
                                <li>
                                    <span className="data-list-box__title">No hay datos</span>
                                </li>
                            )}
                        </div>
                    </div>
                    <div className="tab-pane pt-4 fade" id="clima" role="tabpanel" aria-labelledby="clima-tab">
                        <h2 className="h4 tit-section">Clima</h2>
                        <table className="table table-even table-section table-borderless text-uppercase d-none d-md-table">
                            <thead>
                                <tr>
                                    <th className="col-2">Año</th>
                                    <th className="col-4">Nota</th>
                                </tr>
                            </thead>
                            <tbody>
                                {colaborador.CN_CLIMA_LIST !== undefined ? (
                                    Array.isArray(colaborador.CN_CLIMA_LIST) ? (
                                        Children.toArray(colaborador.CN_CLIMA_LIST.map((item, index) => {
                                            const indice = colaborador.CN_CLIMA_LIST.length - 1 - index
                                            return (
                                                <tr >
                                                    <td>{colaborador.CN_CLIMA_LIST[indice].anio === "" ? "-" : colaborador.CN_CLIMA_LIST[indice].anio}</td>
                                                    <td>{colaborador.CN_CLIMA_LIST[indice].nota === "" ? "-" : colaborador.CN_CLIMA_LIST[indice].nota}</td>
                                                </tr>
                                            );
                                        }))
                                    ) : (
                                        <tr>
                                            <td>{colaborador.CN_CLIMA_LIST.anio === "" ? "-" : colaborador.CN_CLIMA_LIST.anio}</td>
                                            <td>{colaborador.CN_CLIMA_LIST.nota === "" ? "-" : colaborador.CN_CLIMA_LIST.nota}</td>
                                        </tr>
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan="3">No hay datos</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="data-list-box data-list-box-sa text-uppercase d-md-none">
                            {colaborador.CN_CLIMA_LIST !== undefined ? (
                                Array.isArray(colaborador.CN_CLIMA_LIST) ? (
                                    Children.toArray(colaborador.CN_CLIMA_LIST.map((item, index) => {
                                        const indice = colaborador.CN_CLIMA_LIST.length - 1 - index;
                                        return (
                                            <ul className="data-list-section--item list-unstyled" >
                                                <li>
                                                    <span className="data-list-box__title">Año</span>
                                                    <span className="data-list-box__text">{colaborador.CN_CLIMA_LIST[indice].anio === "" ? "-" : colaborador.CN_CLIMA_LIST[indice].anio}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Nota</span>
                                                    <span className="data-list-box__text">{colaborador.CN_CLIMA_LIST[indice].nota === "" ? "-" : colaborador.CN_CLIMA_LIST[indice].nota}</span>
                                                </li>
                                            </ul>
                                        );
                                    }))
                                ) : (
                                    <ul className="data-list-section--item list-unstyled">
                                        <li>
                                            <span className="data-list-box__title">Año</span>
                                            <span className="data-list-box__text">{colaborador.CN_CLIMA_LIST.anio === "" ? "-" : colaborador.CN_CLIMA_LIST.anio}</span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title">Nota</span>
                                            <span className="data-list-box__text">{colaborador.CN_CLIMA_LIST.nota === "" ? "-" : colaborador.CN_CLIMA_LIST.nota}</span>
                                        </li>
                                    </ul>
                                )
                            ) : (
                                <li>
                                    <span className="data-list-box__title">No hay datos</span>
                                </li>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
const FichaRemuneracion = ({ colaborador, pdf, dataGraficoCambiosRenta, cambioRentaporAno, hiddenTd }) => {
    const remData = Array.isArray(colaborador.CN_REM_LIST) ? colaborador.CN_REM_LIST : [colaborador.CN_REM_LIST];
    const [lastRemData, setLastRemData] = useState(Array.isArray(colaborador.CN_REM_LIST) ? colaborador.CN_REM_LIST[0] : colaborador.CN_REM_LIST);
    const graficoRemuneracionesRef = useRef(null);
    const [anioValue, setAnioValue] = useState(Array.isArray(colaborador.CN_REM_LIST) ? colaborador.CN_REM_LIST[0]?.anio ?? "" : colaborador.CN_REM_LIST?.anio ?? "");
    const graficoRemuneracionesBae64 = document.getElementById("graficoRemuneraciones2") !== null ? document.getElementById("graficoRemuneraciones2").toDataURL() : "";
    return (pdf && colaborador && remData && lastRemData && dataGraficoCambiosRenta ?
        <Document>
            <Page size="A4" style={styles.page}>
                <DatosPuesto colaborador={colaborador} pdf={true} width={"48%"} />
                {/* Datos Remuneraciones */}
                <View style={styles.card}>
                    <View style={styles.head}>
                        <Text style={styles.h1}>Datos de Remuneraciones</Text>
                    </View>
                    <View style={styles.body}>
                        <View style={styles.li}>
                            <View style={styles.liTitle}>
                                <Text style={styles.titleLabel}>AÑO</Text>
                            </View>
                            <View style={styles.liContent}>
                                <Text style={styles.contentLabel}>{colaborador.CN_REM_LIST[0].anio}</Text>
                            </View>
                        </View>
                        <View style={styles.li}>
                            <View style={styles.liTitle}>
                                <Text style={styles.titleLabel}>MES</Text>
                            </View>
                            <View style={styles.liContent}>
                                <Text style={styles.contentLabel}>{colaborador.CN_REM_LIST[0].mes}</Text>
                            </View>
                        </View>
                        <View style={styles.li}>
                            <View style={styles.liTitle}>
                                <Text style={styles.titleLabel}>DIAS TRABAJADOS</Text>
                            </View>
                            <View style={styles.liContent}>
                                <Text style={styles.contentLabel}>{colaborador.CN_REM_LIST[0].dias_trabajados}</Text>
                            </View>
                        </View>
                        <View style={styles.li}>
                            <View style={styles.liTitle}>
                                <Text style={styles.titleLabel}>BASE HORARIA</Text>
                            </View>
                            <View style={styles.liContent}>
                                <Text style={styles.contentLabel}>{colaborador.CN_REM_LIST[0].base_horaria}</Text>
                            </View>
                        </View>
                        <View style={styles.li}>
                            <View style={styles.liTitle}>
                                <Text style={styles.titleLabel}>ÚLTIMO AJUSTE RENTA</Text>
                            </View>
                            <View style={styles.liContent}>
                                <Text style={styles.contentLabel}>{formatDate(colaborador.CN_REM_LIST[0].ult_ajuste_renta)}</Text>
                            </View>
                        </View>
                        <View style={styles.li}>
                            <View style={styles.liTitle}>
                                <Text style={styles.titleLabel}>ÚLTIMO AJUSTE RENTA %</Text>
                            </View>
                            <View style={styles.liContent}>
                                <Text style={styles.contentLabel}>{colaborador.CN_REM_LIST[0].ult_ajuste_renta_p}</Text>
                            </View>
                        </View>
                        <View style={styles.li}>
                            <View style={styles.liTitle}>
                                <Text style={styles.titleLabel}>TIPO DE JORNADA</Text>
                            </View>
                            <View style={styles.liContent}>
                                <Text style={styles.contentLabel}>{colaborador.CN_REM_LIST[0].tipo_jornada}</Text>
                            </View>
                        </View>
                        <View style={styles.li}>
                            <View style={styles.liTitle}>
                                <Text style={styles.titleLabel}>TOTAL HABERES</Text>
                            </View>
                            <View style={styles.liContent}>
                                <Text style={styles.contentLabel}>{colaborador.CN_REM_LIST[0].total_haberes}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                {/* Ultima Remuneracion */}
                <View style={{ ...styles.card, width: "100%" }}>
                    <View style={styles.head}>
                        <Text style={styles.h1}>Última Remuneración</Text>
                    </View>
                    <View style={styles.body}>
                        <Text style={{ ...styles.labelHead, margin: "5px 5px 5px 5px", alignSelf: "flex-end", fontSize: "10px" }}>
                            {lastRemData && (lastRemData.mes === "01" ? "Enero, " :
                                lastRemData.mes === "02" ? "Febrero, " :
                                    lastRemData.mes === "03" ? "Marzo, " :
                                        lastRemData.mes === "04" ? "Abril, " :
                                            lastRemData.mes === "05" ? "Mayo, " :
                                                lastRemData.mes === "06" ? "Junio, " :
                                                    lastRemData.mes === "07" ? "Julio, " :
                                                        lastRemData.mes === "08" ? "Agosto, " :
                                                            lastRemData.mes === "09" ? "Septiembre, " :
                                                                lastRemData.mes === "10" ? "Octubre, " :
                                                                    lastRemData.mes === "11" ? "Noviembre, " :
                                                                        lastRemData.mes === "12" ? "Diciembre, " :
                                                                            "")}{lastRemData && lastRemData.anio}
                        </Text>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={styles.tableRow}>
                                    <Text style={{ ...styles.labelHead, width: "15%" }}>HABER</Text>
                                    <Text style={{ ...styles.labelHead, width: "15%" }}>VALOR HABER</Text>
                                </View>
                            </View>
                            <View style={styles.tableBody}>
                                {
                                    colaborador.CN_REM_LIST[0].CN_HABER_LIST !== undefined ?
                                        Array.isArray(colaborador.CN_REM_LIST[0].CN_HABER_LIST) ?
                                            colaborador.CN_REM_LIST[0].CN_HABER_LIST.map((item, index) => {
                                                return (
                                                    <View style={styles.tableRow} key={"dt" + index}>
                                                        <Text style={{ ...styles.labelContent, width: "15%" }}>{item.haber === "" ? "-" : item.haber}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "15%" }}>{item.valor === "" ? "-" : item.valor}</Text>
                                                    </View>
                                                )
                                            })
                                            :
                                            <View style={styles.tableRow} key={"dt0"}>
                                                <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_REM_LIST[0].CN_HABER_LIST.haber === "" ? "-" : colaborador.CN_REM_LIST[0].CN_HABER_LIST.haber}</Text>
                                                <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_REM_LIST[0].CN_HABER_LIST.valor === "" ? "-" : colaborador.CN_REM_LIST[0].CN_HABER_LIST.valor}</Text>
                                            </View>
                                        :
                                        <View style={styles.tableRow}>
                                            <Text style={styles.labelContent}>Sin Registros</Text>
                                        </View>
                                }
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
                {/* Grafico */}
                <View style={{ ...styles.card, width: "100%" }}>
                    <View style={styles.head}>
                        <Text style={styles.h1}>Gráfico Cambios de Renta</Text>
                    </View>
                    <View style={styles.body}>
                        <Image source={graficoRemuneracionesBae64} style={{ width: "100%" }}></Image>
                    </View>
                </View>
                {/* Cambios de Renta */}
                <View style={{ ...styles.card, width: "100%" }}>
                    <View style={styles.head}>
                        <Text style={styles.h1}>Cambios de Renta</Text>
                    </View>
                    <View style={styles.body}>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={styles.tableRow}>
                                    <Text style={{ ...styles.labelHead, width: "15%" }}>AÑO</Text>
                                    <Text style={{ ...styles.labelHead, width: "15%" }}>RUT</Text>
                                    <Text style={{ ...styles.labelHead, width: "15%" }}>NOMBRE</Text>
                                    <Text style={{ ...styles.labelHead, width: "15%" }}>FECHA CAMBIO</Text>
                                    <Text style={{ ...styles.labelHead, width: "15%" }}>MOTIVO</Text>
                                    <Text style={{ ...styles.labelHead, width: "15%" }}>SAL. BASE</Text>
                                    <Text style={{ ...styles.labelHead, width: "15%" }}>% CAMBIO</Text>
                                </View>
                            </View>
                            <View style={styles.tableBody}>
                                {
                                    colaborador.CN_RENTA_LIST !== undefined ?
                                        Array.isArray(colaborador.CN_RENTA_LIST) ?
                                            colaborador.CN_RENTA_LIST.map((item, index) => {
                                                return (
                                                    <View style={styles.tableRow} key={"dt" + index}>
                                                        <Text style={{ ...styles.labelContent, width: "15%" }}>{item.anio === "" ? "-" : item.anio}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "15%" }}>{item.rut === "" ? "-" : item.rut}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "15%" }}>{item.nombre === "" ? "-" : item.nombre}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "15%" }}>{item.fecha_cambio === "" ? "-" : formatDate(item.fecha_cambio)}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "15%" }}>{item.motivo_descr === "" ? "-" : item.motivo_descr}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "15%" }}>{item.salario_base === "" ? "-" : item.salario_base}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "15%" }}>{item.perc_cambio === "" ? "-" : item.perc_cambio}</Text>
                                                    </View>
                                                )
                                            })
                                            :
                                            <View style={styles.tableRow} key={"dt0"}>
                                                <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_RENTA_LIST.anio === "" ? "-" : colaborador.CN_RENTA_LIST.anio}</Text>
                                                <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_RENTA_LIST.rut === "" ? "-" : colaborador.CN_RENTA_LIST.rut}</Text>
                                                <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_RENTA_LIST.nombre === "" ? "-" : colaborador.CN_RENTA_LIST.nombre}</Text>
                                                <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_RENTA_LIST.fecha_cambio === "" ? "-" : formatDate(colaborador.CN_RENTA_LIST.fecha_cambio)}</Text>
                                                <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_RENTA_LIST.motivo_descr === "" ? "-" : colaborador.CN_RENTA_LIST.motivo_descr}</Text>
                                                <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_RENTA_LIST.salario_base === "" ? "-" : colaborador.CN_RENTA_LIST.salario_base}</Text>
                                                <Text style={{ ...styles.labelContent, width: "15%" }}>{colaborador.CN_RENTA_LIST.perc_cambio === "" ? "-" : colaborador.CN_RENTA_LIST.perc_cambio}</Text>
                                            </View>
                                        :
                                        <View style={styles.tableRow}>
                                            <Text style={styles.labelContent}>Sin Registros</Text>
                                        </View>
                                }
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
        :
        <div id="fichaRemuneracion">
            <div className="row row-cols-1 g-4 mb-4">
                <DatosPuesto colaborador={colaborador} />
                <div className="col-12 col-xl-7 d-flex">
                    <div className="box align-items-stretch w-100">
                        <h2 className="h4 tit-section">Datos de Remuneraciones</h2>
                        <div className="data-list-box data-list-box-even text-uppercase">
                            {Array.isArray(remData) ?
                                Children.toArray(remData.map((item) => {
                                    return (
                                        <ul className="list-unstyled m-0" >
                                            <li>
                                                <span className="data-list-box__title col-12 col-sm-5">AÑO</span>
                                                <span className="data-list-box__text">{hiddenTd ? "****" : item.anio}</span>
                                            </li>
                                            <li>
                                                <span className="data-list-box__title col-12 col-sm-5">MES</span>
                                                <span className="data-list-box__text">{hiddenTd ? "****" : item.mes}</span>
                                            </li>
                                            <li>
                                                <span className="data-list-box__title col-12 col-sm-5">DIAS TRABAJADOS</span>
                                                <span className="data-list-box__text">{hiddenTd ? "****" : item.dias_trabajados}</span>
                                            </li>
                                            <li>
                                                <span className="data-list-box__title col-12 col-sm-5">BASE HORARIA</span>
                                                <span className="data-list-box__text">{hiddenTd ? "****" : item.base_horaria}</span>
                                            </li>
                                            <li>
                                                <span className="data-list-box__title col-12 col-sm-5">ÚLTIMO AJUSTE RENTA</span>
                                                <span className="data-list-box__text">{hiddenTd ? "****" : formatDate(item.ult_ajuste_renta)}</span>
                                            </li>
                                            <li>
                                                <span className="data-list-box__title col-12 col-sm-5">ÚLTIMO AJUSTE RENTA %</span>
                                                <span className="data-list-box__text">{hiddenTd ? "****" : dotPerc(item.ult_ajuste_renta_p)}</span>
                                            </li>
                                            <li>
                                                <span className="data-list-box__title col-12 col-sm-5">TIPO DE JORNADA</span>
                                                <span className="data-list-box__text">{hiddenTd ? "****" : item.tipo_jornada}</span>
                                            </li>
                                        </ul>
                                    );
                                }))
                                :
                                <ul className="list-unstyled m-0">
                                    <li>
                                        <span className="data-list-box__title col-12 col-sm-5">AÑO</span>
                                        <span className="data-list-box__text">{hiddenTd ? "****" : remData.anio}</span>
                                    </li>
                                    <li>
                                        <span className="data-list-box__title col-12 col-sm-5">MES</span>
                                        <span className="data-list-box__text">{hiddenTd ? "****" : remData.mes}</span>
                                    </li>
                                    <li>
                                        <span className="data-list-box__title col-12 col-sm-5">DIAS TRABAJADOS</span>
                                        <span className="data-list-box__text">{hiddenTd ? "****" : remData.dias_trabajados}</span>
                                    </li>
                                    <li>
                                        <span className="data-list-box__title col-12 col-sm-5">BASE HORARIA</span>
                                        <span className="data-list-box__text">{hiddenTd ? "****" : remData.base_horaria}</span>
                                    </li>
                                    <li>
                                        <span className="data-list-box__title col-12 col-sm-5">ÚLTIMO AJUSTE RENTA</span>
                                        <span className="data-list-box__text">{hiddenTd ? "****" : formatDate(remData.ult_ajuste_renta)}</span>
                                    </li>
                                    <li>
                                        <span className="data-list-box__title col-12 col-sm-5">ÚLTIMO AJUSTE RENTA %</span>
                                        <span className="data-list-box__text">{hiddenTd ? "****" : dotPerc(remData.ult_ajuste_renta_p)}</span>
                                    </li>
                                    <li>
                                        <span className="data-list-box__title col-12 col-sm-5">TIPO DE JORNADA</span>
                                        <span className="data-list-box__text">{hiddenTd ? "****" : remData.tipo_jornada}</span>
                                    </li>
                                </ul>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="row row-cols-1 g-4 mb-4">
                <div className="col-12 col-xl-6 d-flex">
                    <div className="box align-items-stretch w-100">
                        <h2 className="h4 tit-section">Última Remuneración</h2>
                        <div className="row g-3 align-items-center justify-content-end mb-4">
                            <div className="col-auto">
                                <label className="form-label m-0">
                                    <strong>Buscar por:</strong>
                                </label>
                            </div>
                            <div className="col-auto">
                                <select
                                    id="anioremdata"
                                    className="form-select"
                                    defaultValue={lastRemData.anio}
                                    onChange={(e) => {
                                        setAnioValue(e.target.value)
                                        if (e.target.value !== "") {
                                            if (Array.isArray(remData)) {
                                                const filtred = remData.filter((item) => item.anio === e.target.value);
                                                setLastRemData(filtred);
                                            } else {
                                                setLastRemData(colaborador.CN_REM_LIST);
                                            }
                                        } else {
                                            setLastRemData(colaborador.CN_REM_LIST);
                                        }
                                    }}
                                >
                                    <option value="">Año</option>
                                    {Array.isArray(remData) ? (
                                        Children.toArray(remData.map((remuneracion) => {
                                            return <option value={remuneracion.anio} >{remuneracion.anio}</option>;
                                        }))
                                    ) : remData.anio === "" ? null : (
                                        <option value={remData.anio}>{remData.anio}</option>
                                    )}
                                </select>
                            </div>
                            <div className="col-auto">
                                <select
                                    id="mesremdata"
                                    disabled={anioValue === ""}
                                    className="form-select"
                                    defaultValue={lastRemData.mes}
                                    onChange={(e) => {
                                        const anioSelect = document.getElementById("anioremdata").value;
                                        if (e.target.value !== "") {
                                            if (Array.isArray(remData)) {
                                                let filtred = remData.filter((item) => item.anio === anioSelect);
                                                filtred = filtred.filter((item) => item.mes === e.target.value);
                                                setLastRemData(filtred);
                                            } else {
                                                setLastRemData(colaborador.CN_REM_LIST);
                                            }
                                        } else {
                                            setLastRemData(colaborador.CN_REM_LIST);
                                        }
                                    }}
                                >
                                    <option value="" >Mes</option>
                                    {Array.isArray(remData) ? (
                                        Children.toArray(remData.map((remuneracion) => {
                                            return <option value={remuneracion.mes} >{remuneracion.mes}</option>;
                                        }))
                                    ) : remData.mes === "" ? null : (
                                        <option value={remData.mes}>{remData.mes}</option>
                                    )}
                                </select>
                            </div>
                        </div>

                        <table className="table table-even table-section table-borderless text-uppercase d-md-table">
                            <thead>
                                <tr>
                                    <th className="col-2">Haber</th>
                                    <th className="col-4">Valor Haber</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(remData) ? (
                                    Children.toArray(remData.map((rem) => Children.toArray(rem.CN_HABER_LIST.map((item) => {
                                        return (
                                            <tr>
                                                <td>{hiddenTd ? "*****" : item.haber === "" ? "-" : item.haber}</td>
                                                <td>{hiddenTd ? "*****" : item.valor === "" ? "-" : "$ " + dotNumber(item.valor)}</td>
                                            </tr>
                                        );
                                    }))))
                                ) : (
                                    <tr>
                                        <td colSpan="3">No hay datos</td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Suma total de Haberes</td>
                                    <td>{hiddenTd ? "*****" : '$ ' + dotNumber(remData[0].total_haberes)}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div className="col-12 col-xl-6 d-flex">
                    <div className="box align-items-stretch w-100">
                        <h2 className="h4 tit-section">Gráfico Cambios de Renta</h2>
                        <div className="table-responsive">
                            {dataGraficoCambiosRenta ? (
                                hiddenTd ? "****" :
                                    <Chart
                                        id="graficoRemuneraciones"
                                        ref={graficoRemuneracionesRef}
                                        data={dataGraficoCambiosRenta.data}
                                        type="line"
                                        width={645}
                                        height={null}
                                        //plugins={[ChartDataLabels]}
                                        options={dataGraficoCambiosRenta.options}
                                    />
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className="box">
                <h2 className="h4 tit-section">Cambios de Renta</h2>
                <table className="table table-even table-section table-borderless text-uppercase d-none d-md-table">
                    <thead>
                        <tr>
                            <th>Año</th>
                            <th>Fecha cambio</th>
                            <th>Motivo</th>
                            <th>Sal. Base</th>
                            <th>% Cambio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {colaborador.CN_RENTA_LIST !== undefined ?
                            Array.isArray(colaborador.CN_RENTA_LIST) ? (
                                Children.toArray(colaborador.CN_RENTA_LIST.map((item) => {
                                    return (
                                        <tr>
                                            <td>{hiddenTd ? "****" : item.anio === "" ? "-" : item.anio}</td>
                                            <td>{hiddenTd ? "****" : item.fecha_cambio === "" ? "-" : formatDate(item.fecha_cambio)}</td>
                                            <td>{hiddenTd ? "****" : item.motivo_descr === "" ? "-" : item.motivo_descr}</td>
                                            <td>{hiddenTd ? "****" : item.salario_base === "" ? "-" : dotNumber(item.salario_base)}</td>
                                            <td>{hiddenTd ? "****" : item.perc_cambio === "" ? "-" : dotPerc(item.perc_cambio)}</td>
                                        </tr>
                                    );
                                }))
                            ) : (
                                <tr>
                                    <td>{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.anio === "" ? "-" : colaborador.CN_RENTA_LIST.anio}</td>
                                    <td>{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.fecha_cambio === "" ? "-" : formatDate(colaborador.CN_RENTA_LIST.fecha_cambio)}</td>
                                    <td>{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.motivo_descr === "" ? "-" : colaborador.CN_RENTA_LIST.motivo_descr}</td>
                                    <td>{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.salario_base === "" ? "-" : dotNumber(colaborador.CN_RENTA_LIST.salario_base)}</td>
                                    <td>{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.perc_cambio === "" ? "-" : dotPerc(colaborador.CN_RENTA_LIST.perc_cambio)}</td>
                                </tr>

                            )
                            :
                            <tr>
                                <td colSpan="7">No hay datos</td>
                            </tr>
                        }
                    </tbody>
                </table>
                <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
                    {colaborador.CN_RENTA_LIST !== undefined ? (
                        Array.isArray(colaborador.CN_RENTA_LIST) ? (
                            Children.toArray(Object.keys(cambioRentaporAno).reverse().map((year, yearIndex) => {
                                return (
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id={"accordion-" + yearIndex}>
                                            <button className={`accordion-button ${yearIndex === 0 ? " " : "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target={"#accordion-item-cambios-renta-" + yearIndex} aria-expanded={yearIndex === 0} aria-controls={"accordion-item-cambios-renta-" + yearIndex}>
                                                <strong className="text-uppercase">Año</strong> {year}
                                            </button>
                                        </h2>
                                        {
                                            Children.toArray(cambioRentaporAno[year].map((item) => {
                                                return (
                                                    <div id={"accordion-item-cambios-renta-" + yearIndex} className={`accordion-collapse collapse ${yearIndex === 0 ? "show" : " "}`} aria-labelledby={"accordion-" + yearIndex}>
                                                        <div className="accordion-body p-0">
                                                            <div className="data-list-box data-list-box text-uppercase">
                                                                <ul className="data-list-section--item list-unstyled">
                                                                    <li>
                                                                        <span className="data-list-box__title">Año</span>
                                                                        <span className="data-list-box__text">{hiddenTd ? "****" : item.anio === "" ? "-" : item.anio}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="data-list-box__title">Fecha cambio</span>
                                                                        <span className="data-list-box__text">{hiddenTd ? "****" : item.fecha_cambio === "" ? "-" : formatDate(item.fecha_cambio)}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="data-list-box__title">Motivo</span>
                                                                        <span className="data-list-box__text">{hiddenTd ? "****" : item.motivo_descr === "" ? "-" : item.motivo_descr}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="data-list-box__title">Sal. Base</span>
                                                                        <span className="data-list-box__text">{hiddenTd ? "****" : item.salario_base === "" ? "-" : dotNumber(item.salario_base)}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="data-list-box__title">% Cambio</span>
                                                                        <span className="data-list-box__text">{hiddenTd ? "****" : item.perc_cambio === "" ? "-" : dotPerc(item.perc_cambio)}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )

                                            }))
                                        }
                                    </div>
                                );
                            }))
                        ) : (
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={"accordion-" + colaborador.CN_RENTA_LIST.anio}>
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#accordion-item-cambios-renta-" + colaborador.CN_RENTA_LIST.anio} aria-expanded="true" aria-controls={"accordion-item-cambios-renta-" + colaborador.CN_RENTA_LIST.anio}>
                                        <strong className="text-uppercase">Año</strong> {colaborador.CN_RENTA_LIST.anio}
                                    </button>
                                </h2>
                                <div id={"accordion-item-cambios-renta-" + colaborador.CN_RENTA_LIST.anio} className="accordion-collapse collapse show" aria-labelledby={"accordion-" + colaborador.CN_RENTA_LIST.anio}>
                                    <div className="accordion-body p-0">
                                        <div className="data-list-box data-list-box text-uppercase">
                                            <ul className="data-list-section--item list-unstyled">
                                                <li>
                                                    <span className="data-list-box__title">Año</span>
                                                    <span className="data-list-box__text">{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.anio === "" ? "-" : colaborador.CN_RENTA_LIST.anio}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Fecha cambio</span>
                                                    <span className="data-list-box__text">{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.fecha_cambio === "" ? "-" : formatDate(colaborador.CN_RENTA_LIST.fecha_cambio)}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Motivo</span>
                                                    <span className="data-list-box__text">{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.motivo_descr === "" ? "-" : colaborador.CN_RENTA_LIST.motivo_descr}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Sal. Base</span>
                                                    <span className="data-list-box__text">{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.salario_base === "" ? "-" : dotNumber(colaborador.CN_RENTA_LIST.salario_base)}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">% Cambio</span>
                                                    <span className="data-list-box__text">{hiddenTd ? "****" : colaborador.CN_RENTA_LIST.perc_cambio === "" ? "-" : dotPerc(colaborador.CN_RENTA_LIST.perc_cambio)}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    ) : (
                        <p>{"Sin Registros"}</p>
                    )}
                </div>
            </div>
        </div>
    );
};
const FichaDocumentos = ({ colaborador, pdf, firmaDocumentosData, setFirmaDocumentosData }) => {
    const [estadoCargaDocumentos, setEstadoCargaDocumentos] = useState(false);
    const emplid = window.sessionStorage.getItem("emplidColaborador");
    useEffect(() => {
        if (firmaDocumentosData === false || firmaDocumentosData === null) {
            firmaDocumentosService.getFirmaDocumentos(emplid, setFirmaDocumentosData, setEstadoCargaDocumentos, true)
        }
    }, [firmaDocumentosData]);

    if (
        colaborador && colaborador?.CN_WARNING_LIST && !Array.isArray(colaborador.CN_WARNING_LIST)
      ) {
        colaborador.CN_WARNING_LIST = [colaborador.CN_WARNING_LIST];
      }

    return (pdf ?
        <Document>
            <Page size="A4" style={styles.page}>
                <DatosPuesto colaborador={colaborador} pdf={true} width={"48%"} />
                {/* Documentos Pendientes de Firma */}
                <View style={styles.card}>
                    <View style={styles.head}>
                        <Text style={styles.h1}>Documentos Pendientes de Firma</Text>
                    </View>
                    <View style={styles.body}>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={styles.tableRow}>
                                    <Text style={{ ...styles.labelHead, width: "50%" }}>FECHA DE ENVÍO</Text>
                                    <Text style={{ ...styles.labelHead, width: "50%" }}>TIPO</Text>
                                </View>
                            </View>
                            <View style={styles.tableBody}>
                                {
                                    firmaDocumentosData ?
                                        Array.isArray(firmaDocumentosData) ?
                                            firmaDocumentosData.map((item, index) => {
                                                return (
                                                    <View style={styles.tableRow} key={index}>
                                                        <Text style={{ ...styles.labelContent, width: "50%" }}>{item.end_dt === "" ? "-" : formatDate(item.end_dt)}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "50%" }}>{item.descrdoc === "" ? "-" : item.descrdoc}</Text>
                                                    </View>
                                                )
                                            })
                                            :
                                            <View style={styles.tableRow} key={"fd0"}>
                                                <Text style={{ ...styles.labelContent, width: "50%" }}>{firmaDocumentosData.end_dt === "" ? "-" : formatDate(firmaDocumentosData.end_dt)}</Text>
                                                <Text style={{ ...styles.labelContent, width: "50%" }}>{firmaDocumentosData.descrdoc === "" ? "-" : firmaDocumentosData.descrdoc}</Text>
                                            </View>
                                        :
                                        <View style={styles.tableRow}>
                                            <Text style={styles.labelContent}>Sin Registros</Text>
                                        </View>
                                }
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
        :
        <div id="fichaDocumentos">
            <div className="row row-cols-1 g-4 mb-4">
                <DatosPuesto colaborador={colaborador} />
                <div className="col-12 col-xl-7 d-flex">
                    <div className="box align-items-stretch w-100">
                        <h2 className="h4 tit-section">Documentos Pendientes de Firma</h2>
                        <table className="table table-even table-section table-borderless text-uppercase d-none d-md-table">
                            <thead>
                                <tr>
                                    <th className="col-5">Fecha de envío</th>
                                    <th className="col-7">Tipo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {firmaDocumentosData ? (
                                    Array.isArray(firmaDocumentosData) ? (
                                        Children.toArray(firmaDocumentosData.map((item) => {
                                            return (
                                                <tr key={"item" + item.request_id + item.end_dt}>
                                                    <td>{formatDate(item.end_dt)}</td>
                                                    <td>{item.descrdoc}</td>
                                                </tr>
                                            );
                                        }))
                                    ) : (
                                        <tr key={"item" + firmaDocumentosData.request_id + firmaDocumentosData.end_dt}>
                                            <td>{formatDate(firmaDocumentosData.end_dt)}</td>
                                            <td>{firmaDocumentosData.descrdoc}</td>
                                        </tr>
                                    )
                                ) : estadoCargaDocumentos ? (
                                    <tr>
                                        <td colSpan="3">Sin Registros</td>
                                    </tr>
                                ) : (
                                    <tr>
                                        <td colSpan="3">Cargando...</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="data-list-box data-list-box-sa text-uppercase d-md-none">
                            {firmaDocumentosData ? (
                                Array.isArray(firmaDocumentosData) ? (
                                    Children.toArray(firmaDocumentosData.map((item) => {
                                        return (
                                            <ul className="data-list-section--item list-unstyled">
                                                <li>
                                                    <span className="data-list-box__title col-12 col-sm-5">Fecha de Envío</span>
                                                    <span className="data-list-box__text">{formatDate(item.end_dt)}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title col-12 col-sm-5">Tipo</span>
                                                    <span className="data-list-box__text">{item.descrdoc}</span>
                                                </li>
                                            </ul>
                                        );
                                    }))
                                ) : (
                                    <ul className="data-list-section--item list-unstyled">
                                        <li>
                                            <span className="data-list-box__title col-12 col-sm-5">Fecha de Envío</span>
                                            <span className="data-list-box__text">{formatDate(firmaDocumentosData.end_dt)}</span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title col-12 col-sm-5">Tipo</span>
                                            <span className="data-list-box__text">{firmaDocumentosData.descrdoc}</span>
                                        </li>
                                    </ul>
                                )
                            ) : estadoCargaDocumentos ? (
                                <div>
                                    <p>Sin Registros</p>
                                </div>
                            ) : (
                                <div>
                                    <p>Cargando...</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {colaborador && colaborador?.CN_WARNING_LIST && <WarningHistory warningHistory={colaborador.CN_WARNING_LIST} emplid={sessionStorage.getItem("emplidColaborador")} />}
        </div>
    )
};
const FichaVacaciones = ({ colaborador, pdf, cartolaVacaciones, diasTomadosData, dataGraficoCartolaVacaciones, estadoDiasTomados, estadoCartola }) => {
    const [diasTomadosPorAno, setDiasTomadosPorAno] = useState(false);
    const graficoVacacionesRef = useRef(null);
    useEffect(() => {
        if (diasTomadosData) {
            if (diasTomadosData !== "error") {
                let temp = []
                for (let i = diasTomadosData.length - 1; i > 0; i--) {
                    temp.push(diasTomadosData[i])
                }
                const groupdata = groupBy(temp, "year")
                setDiasTomadosPorAno(groupdata)
            }
        }
    }, [diasTomadosData]);

    return (pdf ?
        <Document>
            <Page size="A4" style={styles.page}>
                <DatosPuesto colaborador={colaborador} pdf={true} width={"100%"} />
                {/* Días Tomados */}
                <View style={{ ...styles.card, width: "100%" }}>
                    <View style={styles.head}>
                        <Text style={styles.h1}>Días Tomados</Text>
                    </View>
                    <View style={styles.body}>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={styles.tableRow}>
                                    <Text style={{ ...styles.labelHead, width: "20%" }}>AÑO</Text>
                                    <Text style={{ ...styles.labelHead, width: "20%" }}>F. INICIO</Text>
                                    <Text style={{ ...styles.labelHead, width: "20%" }}>F. FINAL</Text>
                                    <Text style={{ ...styles.labelHead, width: "20%" }}>DÍAS</Text>
                                    <Text style={{ ...styles.labelHead, width: "20%" }}>APROBADO</Text>
                                </View>
                            </View>
                            <View style={styles.tableBody}>
                                {
                                    diasTomadosData && diasTomadosData !== "error" ?
                                        Array.isArray(diasTomadosData) ?
                                            diasTomadosData.map((item, index) => {
                                                return (
                                                    <View style={styles.tableRow} key={"dt" + index}>
                                                        <Text style={{ ...styles.labelContent, width: "20%" }}>{item.year === "" ? "-" : item.year}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "20%" }}>{item.fecha_inicio === "" ? "-" : formatDate(item.fecha_inicio)}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "20%" }}>{item.fecha_retorno === "" ? "-" : formatDate(item.fecha_retorno)}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "20%" }}>{item.duracion_dias === "" ? "-" : item.duracion_dias}</Text>
                                                        <View style={{ ...styles.labelContent, width: "20%", paddingLeft: "38px" }}>
                                                            <Image style={{ ...styles.checkimg }} source={item.approved_flag === "" || item.approved_flag === "N" ? uncheckedimg : checkedimg} />
                                                        </View>
                                                    </View>
                                                )
                                            })
                                            :
                                            <View style={styles.tableRow} key={"dt0"}>
                                                <Text style={{ ...styles.labelContent, width: "20%" }}>{diasTomadosData.year === "" ? "-" : diasTomadosData.year}</Text>
                                                <Text style={{ ...styles.labelContent, width: "20%" }}>{diasTomadosData.fecha_inicio === "" ? "-" : formatDate(diasTomadosData.fecha_inicio)}</Text>
                                                <Text style={{ ...styles.labelContent, width: "20%" }}>{diasTomadosData.fecha_retorno === "" ? "-" : formatDate(diasTomadosData.fecha_retorno)}</Text>
                                                <Text style={{ ...styles.labelContent, width: "20%" }}>{diasTomadosData.duracion_dias === "" ? "-" : diasTomadosData.duracion_dias}</Text>
                                                <View style={{ ...styles.labelContent, width: "20%", paddingLeft: "38px" }}>
                                                    <Image style={{ ...styles.checkimg }} source={diasTomadosData.approved_flag === "" || diasTomadosData.approved_flag === "N" ? uncheckedimg : checkedimg} />
                                                </View>
                                            </View>
                                        :
                                        <View style={styles.tableRow}>
                                            <Text style={styles.labelContent}>Sin Registros</Text>
                                        </View>
                                }
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
                {/* Grafico */}
                <View style={{ ...styles.card, width: "100%" }}>
                    <View style={styles.head}>
                        <Text style={styles.h1}>Gráfico Vacaciones</Text>
                    </View>
                    <View style={styles.body}>
                        <Image source={() => {
                            if (graficoVacacionesRef.current !== null) return graficoVacacionesRef.current.toBase64Image();
                            else if (document.getElementById("graficoVacaciones") !== null) return document.getElementById("graficoVacaciones").toDataURL();
                            else return "";
                        }}></Image>
                    </View>
                </View>
                {/* Cartola de Vacaciones */}
                <View style={{ ...styles.card, width: "100%" }}>
                    <View style={styles.head}>
                        <Text style={styles.h1}>Cartola de Vacaciones</Text>
                    </View>
                    <View style={styles.body}>
                        <View style={styles.table}>
                            <View style={styles.tableHead}>
                                <View style={styles.tableRow}>
                                    <Text style={{ ...styles.labelHead, width: "10%" }}>PERÍODO</Text>
                                    <Text style={{ ...styles.labelHead, width: "15%" }}>DÍAS ARRASTRE PERÍODO ANTERIOR</Text>
                                    <Text style={{ ...styles.labelHead, width: "15%" }}>DÍAS DEVENGADOS NORMALES</Text>
                                    <Text style={{ ...styles.labelHead, width: "15%" }}>DÍAS DEVENGADOS PROGRESIVOS</Text>
                                    <Text style={{ ...styles.labelHead, width: "15%" }}>DÍAS TOMADOS</Text>
                                    <Text style={{ ...styles.labelHead, width: "10%" }}>AJUSTE</Text>
                                    <Text style={{ ...styles.labelHead, width: "10%" }}>SALDO</Text>
                                </View>
                            </View>
                            <View style={styles.tableBody}>
                                {
                                    cartolaVacaciones && cartolaVacaciones !== "error" ?
                                        Array.isArray(cartolaVacaciones.detalle_vacacion) ?
                                            cartolaVacaciones.detalle_vacacion.map((item, index) => {
                                                return (
                                                    <View style={styles.tableRow} key={"dt" + index}>
                                                        <Text style={{ ...styles.labelContent, width: "10%" }}>{item.periodo !== undefined ? item.periodo.slice(0, 4) : ""}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "15%" }}>{item.dias_arrastre}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "15%" }}>{item.dias_normales}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "15%" }}>{item.dias_progresivos}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "15%" }}>{item.dys_take}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "15%" }}>{item.ajuste_vac}</Text>
                                                        <Text style={{ ...styles.labelContent, width: "15%" }}>{item.balance}</Text>
                                                    </View>
                                                )
                                            })
                                            :
                                            <View style={styles.tableRow} key={"dt0"}>
                                                <Text style={{ ...styles.labelContent, width: "10%" }}>{cartolaVacaciones.detalle_vacacion.periodo !== undefined ? cartolaVacaciones.detalle_vacacion.periodo.slice(0, 4) : ""}</Text>
                                                <Text style={{ ...styles.labelContent, width: "15%" }}>{cartolaVacaciones.detalle_vacacion.dias_arrastre}</Text>
                                                <Text style={{ ...styles.labelContent, width: "15%" }}>{cartolaVacaciones.detalle_vacacion.dias_normales}</Text>
                                                <Text style={{ ...styles.labelContent, width: "15%" }}>{cartolaVacaciones.detalle_vacacion.dias_progresivos}</Text>
                                                <Text style={{ ...styles.labelContent, width: "15%" }}>{cartolaVacaciones.detalle_vacacion.dys_take}</Text>
                                                <Text style={{ ...styles.labelContent, width: "15%" }}>{cartolaVacaciones.detalle_vacacion.ajuste_vac}</Text>
                                                <Text style={{ ...styles.labelContent, width: "15%" }}>{cartolaVacaciones.detalle_vacacion.balance}</Text>
                                            </View>
                                        :
                                        <View style={styles.tableRow}>
                                            <Text style={styles.labelContent}>Sin Registros</Text>
                                        </View>
                                }
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
        :
        <div id="fichaVacaciones">
            <div className="row row-cols-1 g-4 mb-4">
                <DatosPuesto colaborador={colaborador} />
                <div className="col-12 col-xl-7 d-flex">
                    <div className="box align-items-stretch w-100">
                        <h2 className="h4 tit-section">Días Tomados</h2>
                        <table className="table table-even table-section table-borderless text-uppercase d-none d-md-table">
                            <thead>
                                <tr>
                                    <th>Año</th>
                                    <th>F. Inicio</th>
                                    <th>F. Final</th>
                                    <th>Días</th>
                                    <th className="text-center">Aprobado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {diasTomadosData && diasTomadosData !== "error" ? (
                                    Array.isArray(diasTomadosData) ? (
                                        diasTomadosData.map((item) => {
                                            return (
                                                <tr key={"item" + item.fecha_inicio}>
                                                    <td>{item.year}</td>
                                                    <td>{formatDate(item.fecha_inicio)}</td>
                                                    <td>{formatDate(item.fecha_retorno)}</td>
                                                    <td>{item.duracion_dias}</td>
                                                    <td className="text-center">
                                                        <input className="form-check-input" type="checkbox" value="" aria-label="..." checked={item.approved_flag === "Y" ? true : false} disabled={true} />
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr key={"item" + diasTomadosData.fecha_inicio}>
                                            <td>{diasTomadosData.year}</td>
                                            <td>{formatDate(diasTomadosData.fecha_inicio)}</td>
                                            <td>{formatDate(diasTomadosData.fecha_retorno)}</td>
                                            <td>{diasTomadosData.duracion_dias}</td>
                                            <td className="text-center">
                                                {diasTomadosData.approved_flag}
                                                <input className="form-check-input" type="checkbox" value="" aria-label="..." checked={diasTomadosData.approved_flag === "Y" ? true : false} disabled={true} />
                                            </td>
                                        </tr>
                                    )
                                ) : estadoDiasTomados ? (
                                    <div>
                                        <strong>Sin Registros</strong>
                                    </div>
                                ) : (
                                    <div>
                                        <strong>Cargando...</strong>
                                    </div>
                                )}
                            </tbody>
                        </table>
                        <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
                            {diasTomadosData && diasTomadosData !== "error" ? (
                                Array.isArray(diasTomadosData) ? (
                                    Children.toArray(Object.keys(diasTomadosPorAno).reverse().map((year, yearIndex) => {
                                        return (
                                            <>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id={"accordion" + yearIndex}>
                                                        <button className={`accordion-button ${yearIndex === 0 ? " " : "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target={"#accordion-item-dias-tomados-" + yearIndex} aria-expanded={yearIndex === 0} aria-controls={"accordion-item-dias-tomados-" + yearIndex}>
                                                            <strong className="text-uppercase">Año</strong> {year}
                                                        </button>
                                                    </h2>
                                                    {Children.toArray(diasTomadosPorAno[year].map((item) => {
                                                        return (

                                                            <div id={"accordion-item-dias-tomados-" + yearIndex} className={`accordion-collapse collapse ${yearIndex === 0 ? "show" : " "}`} aria-labelledby={"accordion" + yearIndex} data-parent="#accordion-table">
                                                                <div className="data-list-box text-uppercase">
                                                                    <ul className="data-list-section--item list-unstyled">
                                                                        <li>
                                                                            <span className="data-list-box__title">Año</span>
                                                                            <span className="data-list-box__text">{item.year}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="data-list-box__title">Fecha inicio</span>
                                                                            <span className="data-list-box__text">{formatDate(item.fecha_inicio)}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="data-list-box__title">Fecha final</span>
                                                                            <span className="data-list-box__text">{formatDate(item.fecha_retorno)}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="data-list-box__title">Días</span>
                                                                            <span className="data-list-box__text">{item.duracion_dias}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="data-list-box__title">Aprobado</span>
                                                                            <span className="data-list-box__text">
                                                                                <input className="form-check-input" type="checkbox" value="" aria-label="..." checked={item.approved_flag ? true : false} disabled={true} />
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                        )
                                                    }))}
                                                </div>
                                            </>
                                        )
                                    }))
                                ) : (
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id={"accordion" + diasTomadosData.emplid}>
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#accordion-item-dias-tomados-" + diasTomadosData.emplid} aria-expanded="false" aria-controls={"accordion-item-dias-tomados-" + diasTomadosData.emplid}>
                                                <strong className="text-uppercase">Año</strong> {diasTomadosData.year}
                                            </button>
                                        </h2>
                                        <div id={"accordion-item-dias-tomados-" + diasTomadosData.emplid} className="accordion-collapse collapse show" aria-labelledby={"accordion" + diasTomadosData.emplid} data-parent="#accordion-table">
                                            <div className="data-list-box text-uppercase">
                                                <ul className="data-list-section--item list-unstyled">
                                                    <li>
                                                        <span className="data-list-box__title">Año</span>
                                                        <span className="data-list-box__text">{diasTomadosData.year}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title">Fecha inicio</span>
                                                        <span className="data-list-box__text">{diasTomadosData.fecha_inicio}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title">Fecha final</span>
                                                        <span className="data-list-box__text">{diasTomadosData.fecha_retorno}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title">Días</span>
                                                        <span className="data-list-box__text">{diasTomadosData.duracion_dias}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title">Aprobado</span>
                                                        <span className="data-list-box__text">
                                                            <input className="form-check-input" type="checkbox" value="" aria-label="..." checked={diasTomadosData.approved_flag === "Y" ? true : false} disabled={true} />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )
                            ) : estadoDiasTomados ? (
                                <div>
                                    <strong>Sin Registros</strong>
                                </div>
                            ) : (
                                <div>
                                    <strong>Cargando...</strong>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="box mb-4">
                <h2 className="h4 tit-section">Gráfico Vacaciones</h2>
                <div className="table-responsive">
                    {dataGraficoCartolaVacaciones ?
                        <Bar
                            id="graficoVacaciones"
                            ref={graficoVacacionesRef}
                            data={dataGraficoCartolaVacaciones.data}
                            width={645}
                            //plugins={[ChartDataLabels]}
                            options={dataGraficoCartolaVacaciones.options}
                        />
                        :
                        null}</div>
            </div>
            <div className="box">
                <h2 className="h4 tit-section">Cartola de Vacaciones</h2>
                <table className="table table-even table-section table-borderless text-uppercase d-none d-md-table">
                    <thead>
                        <tr>
                            <th>Período</th>
                            <th>Días arrastre período anterior</th>
                            <th>Días devengados normales</th>
                            <th>Días devengados progresivos</th>
                            <th>Días tomados</th>
                            <th>Ajuste</th>
                            <th>Saldo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cartolaVacaciones && cartolaVacaciones !== "error" ? (
                            Array.isArray(cartolaVacaciones.detalle_vacacion) ? (
                                Children.toArray(cartolaVacaciones.detalle_vacacion.map((item) => {
                                    return (
                                        <tr >
                                            <td>{item.periodo !== undefined ? item.periodo.slice(0, 4) : ""}</td>
                                            <td>{dotPerc(item.dias_arrastre)}</td>
                                            <td>{dotPerc(item.dias_normales)}</td>
                                            <td>{dotPerc(item.dias_progresivos)}</td>
                                            <td>{dotPerc(item.dys_take)}</td>
                                            <td>{dotPerc(item.ajuste_vac)}</td>
                                            <td>{item.balance.replace(".", ",")}</td>
                                        </tr>
                                    );
                                }))
                            ) : (
                                <tr key={cartolaVacaciones.detalle_vacacion.periodo}>
                                    <td>{cartolaVacaciones.detalle_vacacion.periodo !== undefined ? cartolaVacaciones.detalle_vacacion.periodo.slice(0, 4) : ""}</td>
                                    <td>{dotPerc(cartolaVacaciones.detalle_vacacion.dias_arrastre)}</td>
                                    <td>{dotPerc(cartolaVacaciones.detalle_vacacion.dias_normales)}</td>
                                    <td>{dotPerc(cartolaVacaciones.detalle_vacacion.dias_progresivos)}</td>
                                    <td>{dotPerc(cartolaVacaciones.detalle_vacacion.dys_take)}</td>
                                    <td>{dotPerc(cartolaVacaciones.detalle_vacacion.ajuste_vac)}</td>
                                    <td>{cartolaVacaciones.detalle_vacacion.balance.replace(".", ",")}</td>
                                </tr>
                            )
                        ) : estadoCartola ? (
                            <tr>
                                <td>Sin Registros</td>
                            </tr>
                        ) : (
                            <tr>
                                <td>Cargando...</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
                    {cartolaVacaciones && cartolaVacaciones !== "error" ? (
                        Array.isArray(cartolaVacaciones.detalle_vacacion) ? (
                            Children.toArray(cartolaVacaciones.detalle_vacacion.map((item, index) => {
                                return (
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id={"accordion" + index}>
                                            <button className={`accordion-button ${index === 0 ? " " : "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target={"#accordion-item-cartola-vacaciones-" + index} aria-expanded={index === 0} aria-controls={"accordion-item-cartola-vacaciones-" + index}>
                                                <strong className="text-uppercase">Período</strong> {item.periodo.slice(0, 4)}
                                            </button>
                                        </h2>
                                        <div id={"accordion-item-cartola-vacaciones-" + index} className={`accordion-collapse collapse ${index === 0 ? "show" : " "}`} aria-labelledby="accordion">
                                            <div className="accordion-body p-0">
                                                <div className="data-list-box data-list-box-even text-uppercase">
                                                    <ul className="data-list-section--item list-unstyled">
                                                        <li>
                                                            <span className="data-list-box__title">Días arrastre período anterior</span>
                                                            <span className="data-list-box__text">{dotPerc(item.dias_arrastre)}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Días devengados normales</span>
                                                            <span className="data-list-box__text">{dotPerc(item.dias_normales)}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Días devengados progresivos</span>
                                                            <span className="data-list-box__text">{dotPerc(item.dias_progresivos)}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Días tomados</span>
                                                            <span className="data-list-box__text">{dotPerc(item.dys_take)}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Ajuste vacacional</span>
                                                            <span className="data-list-box__text">{dotPerc(item.ajuste_vac)}</span>
                                                        </li>
                                                        <li>
                                                            <span className="data-list-box__title">Saldo</span>
                                                            <span className="data-list-box__text">{item.balance.replace(".", ",")}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }))
                        ) : (
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="accordion">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-item-cartola-vacaciones-1" aria-expanded="true" aria-controls="accordion-item-cartola-vacaciones-1">
                                        <strong className="text-uppercase">Período</strong> {cartolaVacaciones.periodo.slice(0, 4)}
                                    </button>
                                </h2>
                                <div id="accordion-item-cartola-vacaciones-1" className="accordion-collapse collapse show" aria-labelledby="accordion">
                                    <div className="accordion-body p-0">
                                        <div className="data-list-box data-list-box-even text-uppercase">
                                            <ul className="data-list-section--item list-unstyled">
                                                <li>
                                                    <span className="data-list-box__title">Días arrastre período anterior</span>
                                                    <span className="data-list-box__text">{dotPerc(cartolaVacaciones.detalle_vacacion.dias_arrastre)}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Días devengados normales</span>
                                                    <span className="data-list-box__text">{dotPerc(cartolaVacaciones.detalle_vacacion.dias_normales)}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Días devengados progresivos</span>
                                                    <span className="data-list-box__text">{dotPerc(cartolaVacaciones.detalle_vacacion.dias_progresivos)}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Días tomados</span>
                                                    <span className="data-list-box__text">{dotPerc(cartolaVacaciones.detalle_vacacion.dys_take)}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Ajuste vacacional</span>
                                                    <span className="data-list-box__text">{dotPerc(cartolaVacaciones.detalle_vacacion.ajuste_vac)}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Saldo</span>
                                                    <span className="data-list-box__text">{cartolaVacaciones.detalle_vacacion.balance.replace(".", ",")}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    ) : estadoCartola ? (
                        <div>
                            <strong>Sin Registros</strong>
                        </div>
                    ) : (
                        <div>
                            <strong>Cargando...</strong>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
};
const Button = ({ cached, verResumenEmpl, emplid, item, index }) => {
    return (
        <button id={"btn_" + emplid + "_" + index} disabled={cached} className="btn btn-degradado btn btn-sm text-capitalize ps-3 pe-3" onClick={() => verResumenEmpl(emplid, item, index)}>
            {"Ver Ficha"}
        </button>
    );
};
const PaginatedItems = ({ itemsPerPage, items, setColaborador, viewSelect, setResetOffset, resetOffset, fotos, setFotos, setCollaboratorEmplid, setFichaSIC,setWarningLetter, setFromEquipo }) => {
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [buttonReq, setButtonReq] = useState(false);
    const isOnline = useOnlineState();


    useEffect(() => {
        if (items !== "error" && Array.isArray(items) && items.length > 0) {
            items.sort((item1, item2) => {
                if (item1.NOMBRE < item2.NOMBRE) return -1;
                if (item1.NOMBRE > item2.NOMBRE) return 1;
                return 0;
            });
        }
    }, [items]);

    if (items !== "error" && Array.isArray(items) && items.length > 0) {
        for (let i = 0; i < items.length; i++) {
            caches.match(urlsAws.getColaborador + "?rut=" + items[i].EMPLID)
                .then((response) => {
                    if (response !== undefined) items[i]["cached"] = true;
                    else items[i]["cached"] = false;
                })
                .catch(() => {
                    items[i]["cached"] = false;
                });
        }
    }

    const getEmplidPhoto = async (arrayEmplids) => {
        let newArrayEmpl = arrayEmplids.map(async (emplid) => {
            const response = await caches.match(urlsAws.getPhotosEquipo + emplid);
            if (!response) {
                return emplid;
            }
            return null;
        });
        const results = await Promise.all(newArrayEmpl);
        return results.filter((ob) => ob !== null);
    };

    const setCurrentPhoto = async (temp) => {
        const newCurrent = await Promise.all(
            temp.map(async (item) => {
                const response = await caches.match(urlsAws.getPhotosEquipo + item.EMPLID);
                if (response) {
                    const data = await response.json();
                    const updatedItem = { ...item, PHOTO: data.photo };
                    return updatedItem;
                } else {
                    return item;
                }
            })
        );
        setCurrentItems(newCurrent);
    };

    useEffect(() => {
        if (items !== "error" && Array.isArray(items) && items.length > 0) {
            let temp = items.slice(itemOffset, itemOffset + itemsPerPage);
            let arrayEmplids = temp.map((obj) => {
                return obj.EMPLID;
            });

            getEmplidPhoto(arrayEmplids)
                .then((emplidPhoto) => {
                    if (emplidPhoto.length > 0 && fotos.length === 0) {
                        miEquipoService.getPhotos(setFotos, emplidPhoto);
                    } else {
                        setCurrentPhoto(temp, setCurrentItems);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            setPageCount(Math.ceil(items.length / itemsPerPage));

        } else if (items !== "error" && Array.isArray(items) && items.length === 0) {
            setCurrentItems(null);
            setPageCount(0);
        }
    }, [itemOffset, itemsPerPage, items, fotos]);
    
    useEffect(() => {
        if (resetOffset) {
            setFotos([]);
            setItemOffset(0);
            setResetOffset(false);
        }
    }, [resetOffset]);

    const handlePageClick = (event) => {
        setFotos([]);
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
    };

    const verResumenEmpl = (emplid, item, index) => {
        setButtonReq(true);
        document.getElementById("btn_" + emplid + "_" + index).innerHTML = "";
        document.getElementById("btn_" + emplid + "_" + index).appendChild(document.createElement("span")).className = "spinner-border spinner-border-sm";
        document.getElementById("searchBar").disabled = true;
        document.getElementById("searchButton").disabled = true;
        document.getElementById("listviewbtn").disabled = true;
        document.getElementById("cardviewbtn").disabled = true;
        window.sessionStorage.setItem("emplidColaborador", emplid);
        setCollaboratorEmplid(emplid);
        window.sessionStorage.setItem("fotoColaborador", item.PHOTO);
        miEquipoService.getColaborador(emplid, setColaborador);
    };

    const ButtonActions = ({items,responsive}) => {
        return(
            <>
            {responsive?(
                <>
                <button className="btn btn-outline-orange btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Acciones</button>
                <ul className="dropdown-menu border-orange dropdown-menu-hover-orange w-auto">
                    <Link className="dropdown-item" to="#" onClick={() => { setFichaSIC(true); setCollaboratorEmplid(items.EMPLID); setFromEquipo(true)  } }>Solicitar SIC de Reemplazo</Link>
                    <Link className="dropdown-item" to="#" onClick={() => { setWarningLetter(true); setCollaboratorEmplid(items.EMPLID); setFromEquipo(true); } }>Solicitud de Amonestación</Link>
                </ul>
                </>
            ):  <>
            <i className="fa btn fa-ellipsis-v" style={{color: '#6c757d'}} data-bs-toggle="dropdown"></i>
            <ul className="dropdown-menu border-orange dropdown-menu-hover-orange w-auto">
                <Link className="dropdown-item" to="#" onClick={() => { setFichaSIC(true); setCollaboratorEmplid(items.EMPLID); setFromEquipo(true)  } }>Solicitar SIC de Reemplazo</Link>
                <Link className="dropdown-item" to="#" onClick={() => { setWarningLetter(true); setCollaboratorEmplid(items.EMPLID); setFromEquipo(true); } }>Solicitud de Amonestación</Link>
            </ul>
                </>}
            </>
        )
    }

    
    return (
        <>
            {viewSelect ? (
            <>
                {currentItems ? (
                    <>
                    <div className="row row-cols-1 row-cols-md-2 row-cols-xxl-3 g-4">
                        {Children.toArray(currentItems.map((item, index) => {
                            return (
                                <div className="col d-flex">
                                    <div className="card align-items-stretch w-100">
                                        <div className="data-list-box data-list-box-align-middle data-list-box-sa text-uppercase">
                                            <ul className="data-list-section--item list-unstyled" style={{ padding: "18px" }}>
                                                <li className="justify-content-center border-0">
                                                    <img src={item.PHOTO !== undefined ? (item.PHOTO !== "" && item.PHOTO !=="" ? `data:image/png;base64,${item.PHOTO}` : defaultColaboradorImage) : defaultColaboradorImage} alt="foto colaborador" className="rounded-circle img-fluid mb-2" width="160" height="160" />
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title col-12 col-sm-4">Nombre</span>
                                                    <span className="data-list-box__text">{item.NOMBRE}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title col-12 col-sm-4">Rut</span>
                                                    <span className="data-list-box__text">{item.EMPLID}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title col-12 col-sm-4">Cargo</span>
                                                    <span className="data-list-box__text">{item.CARGO}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title col-12 col-sm-4">Ver ficha</span>
                                                    <span className="data-list-box__text">
                                                        <Button key={"btn_" + item.EMPLID} index={index} buttonReq={buttonReq} emplid={item.EMPLID} cached={buttonReq ? true : isOnline ? false : item.cached !== undefined ? !item.cached : false} verResumenEmpl={verResumenEmpl} item={item} />
                                                    </span>
                                                </li>
                                                <li>
                                                <span className="data-list-box__title col-12 col-sm-4">Acciones</span>
                                                <span className="data-list-box__text text-capitalize">
                                                    <ButtonActions items={item} responsive={false}/>
                                                </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            );
                        }))}
                        </div>
                    </>
                ):  items === 'error' ? (
                    <>
                        <div className="text-start text-capitalize">
                            <p>No se ha encontrado información.</p>
                        </div>
                    </>
                ): items.length === 0 || currentItems === null && 
                    <>
                        <div className="text-center">
                            <span className="spinner-border spinner-border-sm" />
                            <p> Cargando información... </p>
                        </div>
                    </>
                }
            </>
               
            ) : (
                <>
                <table className="table table-even table-section table-borderless align-middle d-none d-md-table">
                {currentItems ? (
                    <>
                        <thead>
                                <tr>
                                    <th className="col-4">Nombre</th>
                                    <th className="col-2">Rut</th>
                                    <th className="col-2">Posición</th>
                                    <th className="col-3 text-center">Ver ficha</th>
                                    <th className="col-3 text-center">Acciones</th>
                                </tr>
                        </thead>
                        <tbody>
                            {Children.toArray(currentItems.map((item, index) => {
                                return (
                                    <tr key={item.EMPLID}>
                                        <td>
                                            <img src={item.PHOTO !== undefined ? (item.PHOTO !== "" && item.PHOTO !==undefined ? `data:image/png;base64,${item.PHOTO}` : defaultColaboradorImage) : defaultColaboradorImage} alt="foto colaborador" className="rounded-circle me-2" width="40" height="40" />
                                            {item.NOMBRE}
                                        </td>
                                        <td>{item.EMPLID}</td>
                                        <td>{item.POSICION}</td>
                                        <td className="text-center">
                                            <Button key={"btn_" + item.EMPLID} index={index} buttonReq={buttonReq} emplid={item.EMPLID} cached={buttonReq ? true : isOnline ? false : item.cached !== undefined ? !item.cached : false} verResumenEmpl={verResumenEmpl} item={item} />
                                        </td>
                                        <td className="text-center">
                                            <ButtonActions items={item} responsive={false}/>
                                        </td>
                                    </tr>
                                );
                            }))}
                        </tbody>
                    </>
                ):  items === 'error' ? (
                    <>
                        <div className="text-start text-capitalize">
                            <p>No se ha encontrado información.</p>
                        </div>
                    </>
                ): items.length === 0 || currentItems === null && 
                    <>
                        <div className="text-center">
                            <span className="spinner-border spinner-border-sm" />
                            <p> Cargando información... </p>
                        </div>
                    </>
                }
                </table>  
                <div className="data-list-box data-list-box-align-middle data-list-box-sa text-uppercase d-md-none">
                    {currentItems ?(
                        <>
                            {Children.toArray(currentItems.map((item, index) => {
                                return (
                                    <ul key={item.EMPLID} className="data-list-section--item list-unstyled">
                                        <li>
                                            <span className="data-list-box__title col-12 col-sm-4">Nombre</span>
                                            <span className="data-list-box__text">{item.NOMBRE}</span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title col-12 col-sm-4">Rut</span>
                                            <span className="data-list-box__text">{item.EMPLID}</span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title col-12 col-sm-4">Cargo</span>
                                            <span className="data-list-box__text">{item.CARGO}</span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title col-12 col-sm-4">Ver Ficha</span>
                                            <span className="data-list-box__text">
                                                <Button key={"btn_" + item.EMPLID} index={index} buttonReq={buttonReq} emplid={item.EMPLID} cached={buttonReq ? true : isOnline ? false : item.cached !== undefined ? !item.cached : false} verResumenEmpl={verResumenEmpl} item={item} />
                                            </span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__text text-capitalize">
                                                <ButtonActions items={item} responsive={true}/>
                                            </span>
                                        </li>
                                    </ul>
                                );
                            }))}
                        </>
                    ):  items === 'error' ? (
                        <>
                            <div className="text-start text-capitalize">
                                <p>No se ha encontrado información.</p>
                            </div>
                        </>
                    ): items.length === 0 || currentItems === null && 
                        <>
                            <div className="text-center">
                                <span className="spinner-border spinner-border-sm" />
                                <p> Cargando información... </p>
                            </div>
                        </>
                    }
                </div>
                </>
            )}
            {currentItems ? (
                <nav className="mt-5" aria-label="Paginación detalle colaboradores">
                    <ReactPaginate id={"paginationnav"} style={{ pointerEvents: buttonReq ? "none" : "all" }} className="pagination justify-content-center align-items-center" breakLabel=" ... " nextLabel=" > " onPageChange={handlePageClick} pageRangeDisplayed={0} marginPagesDisplayed={1} pageCount={pageCount} previousLabel=" < " renderOnZeroPageCount={null} previousLinkClassName={"page-link"} nextLinkClassName={"page-link"} breakLinkClassName={"page-link"} pageLinkClassName={"page-link"} pageClassName={"page-item"} breakClassName={"page-item"} previousClassName={"page-item"} nextClassName={"page-item"} activeClassName={"page-item active"} activeLinkClassName={"page-link active"} />
                </nav>
            ) : null}
        </>
    );
};
const MiEquipo = () => {
    const [items, setItems] = useState([]);
    const [colaborador, setColaborador] = useState(false);
    const [fichaSIC, setFichaSIC ] = useState(false);
    const [emplidColab, setEmplidColab ] = useState('');
    const [fromEquipo, setFromEquipo ] = useState(false);
    const [warningLetter, setWarningLetter] = useState(false);
    const [searchBar, setSearchBar] = useState("");
    const [viewSelect, setViewSelect] = useState(false);
    const [resetOffset, setResetOffset] = useState(false);
    const [fotos, setFotos] = useState([]);
    const [collaboratorEmplid, setCollaboratorEmplid] = useState("");
    const emplid = localStorage.getItem("emplid");

    useEffect(() => {
        if (emplid !== null) miEquipoService.getMiEquipo(emplid, setItems, fichaSIC);
    }, [fichaSIC]);

    const salida = window.setTimeout((emplid) => {
        let btn = document.getElementById("btn_" + emplid);
        if (btn) {
            btn.disabled = false;
            btn.innerHTML = "Intentar otra vez";
        }
    }, 10000);

    useEffect(() => {
        if (colaborador === "error") {
            const emplid = sessionStorage.getItem("emplidColaborador");
            miEquipoService.getColaborador(emplid, setColaborador, salida);
        }
    }, [colaborador]);

    const handleChange = (e) => {
        setSearchBar(e.target.value);
        let emplid = localStorage.getItem("emplid");
        if (e.target.value === "") {
            setFotos([]);
            caches.match(urlsAws.getMiEquipo + "?emplid=" + emplid)
                .then((response) => {
                    if (response) response.json().then((data) => setItems(data.response.CN_JEFESUB_LIST));
                })
        }
    };
    return (
        <>
            {(colaborador === false || colaborador === "error") && !fichaSIC && !warningLetter && (
                <>
                    <nav className="breadcrumbs" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/inicio">
                                    <i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true"></i> Inicio
                                </Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                <Link to="#" onClick={() => {setFichaSIC(false); setWarningLetter(false)}}>
                                    <i style={{ pointerEvents: "none" }} aria-hidden="true"></i> Mi Equipo
                                </Link>
                            </li>
                        </ol>
                    </nav>
                  
                    <section className="mi-equipo">
                        <h1>Mi Equipo</h1>
                        <div className="box mb-4">
                            <div className="tit-group d-flex justify-content-between">
                                <h2 className="h4 tit-section">Detalle Colaboradores</h2>
                                <div>
                                    <>
                                        <button className="btn btn-outline-orange btn-lg dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Acciones</button>
                                        <ul className="dropdown-menu border-orange dropdown-menu-hover-orange w-auto">
                                            <li>
                                                 <Link className="dropdown-item" to="#" onClick={() => { setFichaSIC(true); setFromEquipo(false) } }>Solicitar SIC de Reemplazo</Link>
                                            </li>
                                            {items && 
                                                <li>
                                                    <Link className="dropdown-item" to="#" onClick={() => { setWarningLetter(true);setFromEquipo(false) } }>Solicitud de Amonestación</Link>
                                                </li>
                                            }
                                        </ul>
                                    </>
                                </div>
                            </div>
                            <div className="row mb-3 justify-content-end">
                                <div className="col-12 col-md-6 col-xl-5">
                                    <div className="input-group input-group-white mb-3">
                                        <input id="searchBar" className="form-control" type="text" placeholder="Buscar por Nombre y/o Rut" aria-label="Buscar por Nombre y/o Rut" onChange={(e) => handleChange(e)} />
                                        <button
                                            id="searchButton"
                                            className="input-group-text border border-1 border-solid border-color-#ced4da m-0"
                                            onClick={() => {
                                                let emplid = localStorage.getItem("emplid");
                                                caches.match(urlsAws.getMiEquipo + "?emplid=" + emplid)
                                                    .then((response) => {
                                                        if (response) {
                                                            response.json().then((data) => {
                                                                if (searchBar === "") setItems(data.response.CN_JEFESUB_LIST);
                                                                else {
                                                                    if (data.response.CN_JEFESUB_LIST.length > 0) {
                                                                        const newData = data.response.CN_JEFESUB_LIST.filter((item) => item.NOMBRE.toLowerCase().includes(searchBar.toLowerCase()) || item.EMPLID.includes(searchBar));
                                                                        if (newData.length > 0) setItems(newData);
                                                                        else setItems([]);
                                                                        setResetOffset(true);
                                                                    }
                                                                }
                                                            });
                                                        }
                                                    })
                                            }}
                                        >
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="btn-group btn-group-switch" role="group" aria-label="Basic example">
                                        <button
                                            className={`btn btn-default ${!viewSelect && "active"}`}
                                            type="button"
                                            id="listviewbtn"
                                            onClick={() => {
                                                setViewSelect(false);
                                            }}
                                        >
                                            <i className="fa fa-th-list" aria-hidden="true"></i>
                                        </button>
                                        <button
                                            className={`btn btn-default ${viewSelect && "active"}`}
                                            type="button"
                                            id="cardviewbtn"
                                            onClick={() => {
                                                setViewSelect(true);
                                            }}
                                        >
                                            <i className="fa fa-th-large" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {items ? <PaginatedItems
                                resetOffset={resetOffset} 
                                setResetOffset={setResetOffset} 
                                itemsPerPage={viewSelect ? 6 : 5} 
                                items={items} 
                                colaborador={colaborador} 
                                setColaborador={setColaborador} 
                                viewSelect={viewSelect} 
                                fotos={fotos} 
                                setFotos={setFotos} 
                                setCollaboratorEmplid={setCollaboratorEmplid} 
                                setFichaSIC={setFichaSIC} 
                                setEmplidColab={setEmplidColab} 
                                setFromEquipo={setFromEquipo} 
                                setWarningLetter={setWarningLetter}/>
                            : null}
                        </div>
                    </section>
                </>
            )}
            {colaborador && !fichaSIC && !warningLetter && (
                <FichaColaborador colaborador={colaborador} setColaborador={setColaborador} items={items} setItems={setItems} setWarningLetter={setWarningLetter} setCollaboratorEmplid={setCollaboratorEmplid} setFichaSIC={setFichaSIC} setFromEquipo={setFromEquipo}/>
            )}
            {fichaSIC && <FichaSIC setFichaSIC={setFichaSIC} collaborators={items} emplidColab={collaboratorEmplid} setCollaboratorEmplid={setCollaboratorEmplid} fromEquipo={fromEquipo} setFromEquipo={setFromEquipo}/> }
            {warningLetter && <WarningLetter collaborators={items} setWarningLetter={setWarningLetter} collaboratorEmplid={collaboratorEmplid} fromEquipo={fromEquipo} setFromEquipo={setFromEquipo} setCollaboratorEmplid={setCollaboratorEmplid} /> }
        </>
    );
};

DatosPuesto.propTypes = {
    datosPuesto: PropTypes.object,
    setDatosPuesto: PropTypes.func,
    setColaborador: PropTypes.func,
    setItems: PropTypes.func,
    setFotos: PropTypes.func,
    setResetOffset: PropTypes.func,
    resetOffset: PropTypes.bool,
    setViewSelect: PropTypes.func,
    viewSelect: PropTypes.bool,
    items: PropTypes.array,
    fotos: PropTypes.array,
    colaborador: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.string]),
    pdf: PropTypes.bool,
    width: PropTypes.number,
};

FichaColaborador.propTypes = {
    colaborador: PropTypes.object,
    setColaborador: PropTypes.func,
    items: PropTypes.array,
    setItems: PropTypes.func,
    setWarningLetter: PropTypes.func,
    setCollaboratorEmplid: PropTypes.func,
    pdf: PropTypes.bool,
};

PaginatedItems.propTypes = {
    resetOffset: PropTypes.bool,
    setResetOffset: PropTypes.func,
    itemsPerPage: PropTypes.number,
    items: PropTypes.array,
    colaborador: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.string]),
    setColaborador: PropTypes.func,
    viewSelect: PropTypes.bool,
    fotos: PropTypes.array,
    setFotos: PropTypes.func,
    width: PropTypes.number,
    setCollaboratorEmplid: PropTypes.func,
};

FichaPuesto.propTypes = {
    datosPuesto: PropTypes.object,
    setDatosPuesto: PropTypes.func,
    setColaborador: PropTypes.func,
    setItems: PropTypes.func,
    setFotos: PropTypes.func,
    setResetOffset: PropTypes.func,
    resetOffset: PropTypes.bool,
    setViewSelect: PropTypes.func,
    viewSelect: PropTypes.bool,
    items: PropTypes.array,
    fotos: PropTypes.array,
    colaborador: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.string]),
    pdf: PropTypes.bool,
};

FichaRemuneracion.propTypes = {
    datosPuesto: PropTypes.object,
    setDatosPuesto: PropTypes.func,
    setColaborador: PropTypes.func,
    setItems: PropTypes.func,
    setFotos: PropTypes.func,
    setResetOffset: PropTypes.func,
    resetOffset: PropTypes.bool,
    setViewSelect: PropTypes.func,
    viewSelect: PropTypes.bool,
    items: PropTypes.array,
    fotos: PropTypes.array,
    colaborador: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.string]),
    pdf: PropTypes.bool,
    dataGraficoCambiosRenta: PropTypes.array,
    cambioRentaporAno: PropTypes.array,
    hiddenTd: PropTypes.bool,
    width: PropTypes.number,
};

FichaDocumentos.propTypes = {
    datosPuesto: PropTypes.object,
    setDatosPuesto: PropTypes.func,
    setColaborador: PropTypes.func,
    setItems: PropTypes.func,
    setFotos: PropTypes.func,
    setResetOffset: PropTypes.func,
    resetOffset: PropTypes.bool,
    setViewSelect: PropTypes.func,
    viewSelect: PropTypes.bool,
    items: PropTypes.array,
    fotos: PropTypes.array,
    colaborador: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.string]),
    pdf: PropTypes.bool,
    firmaDocumentosData: PropTypes.array,
    setFirmaDocumentosData: PropTypes.func,
};

FichaVacaciones.propTypes = {
    datosPuesto: PropTypes.object,
    setDatosPuesto: PropTypes.func,
    setColaborador: PropTypes.func,
    setItems: PropTypes.func,
    setFotos: PropTypes.func,
    setResetOffset: PropTypes.func,
    resetOffset: PropTypes.bool,
    setViewSelect: PropTypes.func,
    viewSelect: PropTypes.bool,
    items: PropTypes.array,
    fotos: PropTypes.array,
    colaborador: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.string]),
    pdf: PropTypes.bool,
    cartolaVacaciones: PropTypes.array,
    setCartolaVacaciones: PropTypes.func,
    diasTomadosData: PropTypes.array,
    setDiasTomadosData: PropTypes.func,
    dataGraficoCartolaVacaciones: PropTypes.array,
    setDataGraficoCartolaVacaciones: PropTypes.func,
    estadoDiasTomados: PropTypes.array,
    setEstadoDiasTomados: PropTypes.func,
    estadoCartola: PropTypes.array,
    setEstadoCartola: PropTypes.func,
    width: PropTypes.number,
};

Button.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    cached: PropTypes.bool,
    verResumenEmpl: PropTypes.bool,
    emplid: PropTypes.string,
    item: PropTypes.object,
    index: PropTypes.number,
};

MiEquipo.propTypes = {
    datosPuesto: PropTypes.object,
    setDatosPuesto: PropTypes.func,
    setColaborador: PropTypes.func,
    setItems: PropTypes.func,
    setFotos: PropTypes.func,
    setResetOffset: PropTypes.func,
    resetOffset: PropTypes.bool,
    setViewSelect: PropTypes.func,
    viewSelect: PropTypes.bool,
    items: PropTypes.array,
    fotos: PropTypes.array,
    colaborador: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.string]),
    emplid: PropTypes.string,
    item: PropTypes.object,
    width: PropTypes.number,
};

export default MiEquipo;
