import type { CountriesTypes } from "../interfaces/applyInterface";

export const COUNTRIES_DESC: Record<CountriesTypes, string> = {
    CHL: "Chile",
    ARG: "Argentina",
    COL: "Colombia",
    PER: "Perú",
    BRA: "Brasil",
    URY: "Uruguay",
};
