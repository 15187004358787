import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import useFetchInterestSitesMaintenance, { FetchParams } from '../hooks/useInterestSitesMaintenanceFetch';

export interface InterestSitesMaintenance {
  id?: number;
  title: string;
  url: string;
  class: string;
  country: string;
  vpn: number;
  password: number;
  bgcolor: string; 
}

interface InterestSitesMaintenanceContextType {
  interestSites: InterestSitesMaintenance[];
  isLoading: boolean;
  isError: boolean;
  selectedCountry: string;
  setSelectedCountry: React.Dispatch<React.SetStateAction<string>>;
  fetchInterestSites: () => void;
}

const InterestSitesMaintenanceContext = createContext<InterestSitesMaintenanceContextType | undefined>(undefined);

export const InterestSitesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [interestSites, setInterestSites] = useState<InterestSitesMaintenance[]>([]);
  const { data, isLoading, isError } = useFetchInterestSitesMaintenance({ country: selectedCountry });

  const fetchInterestSites = () => {
    if (data) {
      setInterestSites(data);
    }
  };

  useEffect(() => {
    fetchInterestSites();
  }, [data]);

  return (
    <InterestSitesMaintenanceContext.Provider value={{ 
      interestSites, 
      isLoading,
      isError,
      selectedCountry,
      setSelectedCountry,
      fetchInterestSites,
    }}>
      {children}
    </InterestSitesMaintenanceContext.Provider>
  );
};

export const useInterestSitesMaintenance = (): InterestSitesMaintenanceContextType => {
  const context = useContext(InterestSitesMaintenanceContext);
  if (!context) {
    throw new Error('useInterestSitesMaintenance debe ser usado dentro de un InterestSitesProvider');
  }
  return context;
};

export default InterestSitesMaintenanceContext;