import React, { FC, useState, useEffect } from "react";
import { urlsAws } from "../../../resources/foo/api-endpoints";
import { Link } from "react-router-dom";
import codigoService from "../services";


const FileDownload: FC<any> = () => {
    const [file, setFile] = useState("");
    const [company, setCompany] = useState("");

    useEffect(() => {
        if (localStorage.getItem("company") === null) {
            caches.match(urlsAws.firstLogin).then(data => {
                if (data !== null && data !== undefined && data.body !== null) {
                    return data.json();
                } else {
                    throw new Error("mal");
                }
            }).then(data => {
                if (data !== null && data !== undefined) {
                    setCompany(data.COMPANY);
                }
            }).catch(error => console.log("error", error));
        } else {
            const company = localStorage.getItem("company") || "";
            setCompany(company);
        }
    }, []);

    const downloadFile = async () => {
        setFile("loading");
        if (company && company !== null) {
            const tempfile = await codigoService.getFile(company, "4");
            const fileName = "codigo-etica.pdf";
            let archivo = Buffer.from(tempfile, "base64");
            let link = document.createElement("a");
            let blob = new Blob([archivo], { type: "application/pdf" });
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.dispatchEvent(new MouseEvent("click"));
            setFile("");
        }
    }

    return <div className="box mb-4">
    <h2 className="h4 tit-section">Conoce nuestro Código de Ética <span className="h6 d-block d-sm-inline-block">(Recuerda leerlo)</span></h2>
    {file === "" ?
            <Link 
                to="#" 
                className="text-uppercase text-decoration-none f-bold d-block mb-4" 
                onClick={(e) => { e.preventDefault(); downloadFile(); }}>
                    <strong>Descargar</strong> 
                    <i className="fa fa-file-pdf-o fa-fw me-2" aria-hidden="true"></i>
            </Link>
            :
            file === "error" ?
                <p className="text-uppercase text-decoration-none f-bold d-block mb-4">
                    Error al intentar traer el documento.
                </p>
                :
                <p className="text-uppercase text-decoration-none f-bold d-block mb-4">
                    Cargando documento...
                </p>
    }
    <p>Hago constar que he leído el Código de Ética de la empresa y que comprendo la importancia y el contexto de las reglas aquí contenidas. Además declaro que, el mismo, forma parte de las condiciones
        y prácticas que tiene la empresa para el desarrollo de las actividades en ella, las que me comprometo en este acto a respetar.<br></br><br></br>
        Asimismo, dejo constancia que la empresa me ha informado sobre el alcance de las normas contenidas
        en él y los efectos derivados de su eventual incumplimiento.</p>
</div>;
};

export default FileDownload;