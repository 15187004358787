import React, { FC, useContext } from "react";
import { Context } from "../../../../../context";
import { ContextInterface } from "../../../../../context/utilities/interfaces";

const EncuestaErrorModal: FC = () => {
    const { signOut } = useContext<ContextInterface | null>(Context) as ContextInterface;
    return <>
    <div className='modal fade' id="encuesta-error-modal" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content rounded">
                <div className="modal-header border-0 p-0">
                </div>
                <div className="modal-body py-4 text-center">
                    <div className="fs-5">
                        <p>¡Muchas Gracias!</p>
                        <p>
                            Hemos recibido satisfactoriamente tus opciones
                            de suscripción.<br/>Puedes modificar tus preferencias
                            en cualquier momento en Mi Portal.
                        </p>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button"
                        onClick={(e) => {
                            signOut();
                        }}
                        className="btn btn-primary px-5" data-bs-dismiss="modal">
                        Finalizar
                        <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
    </div>
    <button hidden type="button" id="btn-encuesta-error-modal" data-bs-toggle="modal" data-bs-target="#encuesta-error-modal" />
    </>;
}

export default EncuestaErrorModal;