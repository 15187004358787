import { Auth } from "aws-amplify";
import axios from "axios";

const url = process.env.REACT_APP_BACKEND_URL_GLOBAL;

const CertificadosServices = {
    GenerarCertificado: async (body, setGenerarCertificado, setLoadingGenerarCertificado, setNoDataGenerarCertificado) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                await axios.post(url + '/api/certificados/generarcertificado', body, { headers: head })
                // await axios.post('http://localhost:5000/api/certificados/generarcertificado', body, { headers: head })
                setGenerarCertificado(true)
                setLoadingGenerarCertificado(false);
            }).catch(function (error) {
                console.log(error.response);
                setLoadingGenerarCertificado(false);
                setNoDataGenerarCertificado(true);
            })
        } catch (error) {
            console.log(error)
        }
    },
    Getcertificados: async (setInfoCertificados, setLoadingInfoCertificados, setNoDataInfoCertificados) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(url + '/api/certificados/certificados', { headers: head })
                // const { data } = await axios.get('http://localhost:5000/api/certificados/certificados', { headers: head })
                const sortedData = data.sort((a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion))
                setInfoCertificados(sortedData)
                setLoadingInfoCertificados(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingInfoCertificados(false);
                setNoDataInfoCertificados(true);
            })
        } catch (error) {
            console.log(error)
        }
    },
    GetCertificadosSinFile: async (setInfoCertificados, setLoadingInfoCertificados, setNoDataInfoCertificados) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(url + '/api/certificados/certificadosSinFile', { headers: head })
                // const { data } = await axios.get('http://localhost:5001/api/certificados/certificadosSinFile', { headers: head })
                const sortedData = data.sort((a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion))
                setInfoCertificados(sortedData)
                setLoadingInfoCertificados(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingInfoCertificados(false);
                setNoDataInfoCertificados(true);
            })
        } catch (error) {
            console.log(error)
        }
    },
    GetTipoCertificados: async (setInfoTipoCertificado, setLoadingTipoCertificado) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(url + '/api/certificados/tipoCertificado', { headers: head })
                // const { data } = await axios.get('http://localhost:5000/api/certificados/tipoCertificado', { headers: head })
                setInfoTipoCertificado(data.CN_COL_XLAT.PSXLATITEM)
                setLoadingTipoCertificado(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingTipoCertificado(false);
            })
        } catch (error) {
            console.log(error)
        }
    },
    GetFileCertificado: async (guid, setLoadingFileCertificado) =>
        await Auth.currentSession().then(async auth => {
            const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
            const { data } = await axios.get(url + `/api/certificados/certificadoFile?guid=${guid}`, { headers: head })
            // const { data } = await axios.get(`http://localhost:5001/api/certificados/certificadoFile?guid=${guid}`, { headers: head })

            setLoadingFileCertificado(false);
            return data;
        }).catch(function (error) {
            console.log(error);
            setLoadingFileCertificado(false);
        })
}

export default CertificadosServices