import React, { useEffect, useState } from "react";
import tiempoService from "../../services/tiempo-service";
import { dotNumber } from "../../services/utiles";

const SaldoTiempo = () => {
  const [dataResumen, setDataResumen] = useState({});
  const [valorSaldo, setValorSaldo] = useState(0);

  useEffect(() => {
    if (valorSaldo === 0) {
      tiempoService.getResumen(setDataResumen);
    }
    return () => setDataResumen({});
  }, []);

  useEffect(() => {
    if (valorSaldo === 0) {
      if (dataResumen === 2) {
        setValorSaldo(0);
      } else {
        if (dataResumen.valid) {
          if (dataResumen.valid === "1") {
            setValorSaldo(dotNumber(parseInt(dataResumen.saldo)));
          } else if (dataResumen.valid === "0") {
            setValorSaldo(0);
          }
        }
      }
    }
  }, [dataResumen]);

  return (
    <>
      <div className="saldo-puntos rounded-3 bg-light px-4 py-3 color-primary">
        <span className="me-4">Saldo Puntos:</span>
        <div className="text-end d-inline-block mt-2 mt-md-0">
          <span
            className="px-4 py-2 bg-white d-inline-block border border-primary rounded-3"
            href="#"
          >
            {valorSaldo}
          </span>
        </div>
      </div>
    </>
  );
};

export default SaldoTiempo;
