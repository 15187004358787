import React, { useState, useEffect } from 'react';
import MiEquipoService from '../../../services/mi-equipo/mi-equipo-service';

const usePhoto = () => {
    const [photo, setPhoto] = useState();

    const getPhoto = async () => {
        const data = await MiEquipoService.getFoto();
        setPhoto(data);
    }

    useEffect(() => {
        getPhoto();
    }, []);

    return { photo };
};

export default usePhoto;