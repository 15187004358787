/* eslint-disable no-unused-vars */
import axios from "axios";
import Auth from '@aws-amplify/auth';

const NotificationsApi = {
    getNotifications: async (setLoading, setNotifications, setUserMessageCount, page, pageSize) => {
        try {
            setLoading(true)
            const url = `${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/notifications/bell/all?page=${page}&pagesize=${pageSize}`;
            Auth.currentSession().then(async auth => {
                const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
                axios.get(url, { headers: headers }).then(res => {
                    const response = res.data;
                    let counter = 0;
                    setNotifications(response);
                    response.forEach(element => {
                        element.isNew ? counter++ : null;
                    });
                    setUserMessageCount(counter)
                }).catch(function (error) {
                    console.log(error.response?.data);
                }).finally(() => {
                    setLoading(false)
                })
            })
        } catch (error) {
            console.log(error.response?.data);
        }
    },
    getMoreNotifications: async (setLoadingMore, setNotifications, page, pageSize) => {
        try {
            setLoadingMore(true);
            const url = `${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/notifications/bell/all?page=${page}&pagesize=${pageSize}`;
            Auth.currentSession().then(async auth => {
                const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
                axios.get(url, { headers: headers }).then(res => {
                    const newNotifications = res.data;
                    setNotifications((prevNotifications) => [...prevNotifications, ...newNotifications]);
                }).catch(function (error) {
                    console.log(error.response?.data);
                }).finally(() => {
                    setLoadingMore(false)
                })
            });
        } catch (error) {
            console.log(error.response?.data);
        }
    },

    addDeletedNotification: async (notificationItemId) => {
        try {
            const url = `${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/notifications/bell/removedNotifications`;
            Auth.currentSession().then(async auth => {
                const headers = {
                    'Content-Type': 'application/json',
                    'AccessToken': auth.getAccessToken().getJwtToken()
                };
                const data = {
                    notificationItemId: notificationItemId
                };
                return axios.post(url, data, { headers: headers })
            });
        } catch (error) {
            console.error('Error deleting notification:', error.response?.data);
        }
    },
    addViewedNotifications: async (areNotificationsSeen) => {
        try {
            const url = `${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/notifications/bell/viewedNotifications`;

            Auth.currentSession().then(async (auth) => {
                const headers = {
                    'Content-Type': 'application/json',
                    'AccessToken': auth.getAccessToken().getJwtToken(),
                };

                const data = {
                    areNotificationsSeen: areNotificationsSeen,
                };

                return axios.post(url, data, { headers: headers });
            });
        } catch (error) {
            console.error('Error marking notifications as viewed:', error.response?.data);
        }
    }
}

export default NotificationsApi;