import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { urlsAws } from "../../resources/foo/api-endpoints";
import datosLaboralesService from "../../services/datos-laborales-service";
import PropTypes from 'prop-types';
import { useCarousel } from "../../services/utiles";
const CarrouselVacaciones = (props) => {
  CarrouselVacaciones.propTypes = {
    tituloSelect: PropTypes.any,
  };
  const [loginData, setLoginData] = useState(null);
  const employeeID = localStorage.getItem("employeeId");
  const [rutas, setRutas] = useState(null);

  useEffect(() => {
    caches.match(urlsAws.firstLogin).then((response) => {
      if (response && response.body !== null) {
        response.json().then((data) => {
          setLoginData(data);
        });
      } else {
        datosLaboralesService.firstLogin(employeeID);
      }
    });
  }, []);

  useEffect(() => {
    if (loginData) {
      if (loginData.BOSS === "Y") {
        setRutas([
          {
            titulo: "Aprobar",
            ruta: "/admin/aprobar-vacaciones",
          },
          {
            titulo: "Solicitar",
            ruta: "/solicitar-vacaciones",
          },
          {
            titulo: "Ver mi reemplazo",
            ruta: "/reemplazo-vacaciones",
          },
          {
            titulo: "Ver cartola",
            ruta: "/ver-cartola",
          },
          {
            titulo: "Días tomados / Papeleta",
            ruta: "/ver-detalle-vacaciones",
          },
        ]);
      } else if (loginData.REPLACEMENT !== "") {
        setRutas([
          {
            titulo: "Aprobar",
            ruta: "/admin/aprobar-vacaciones",
          },
          {
            titulo: "Solicitar",
            ruta: "/solicitar-vacaciones",
          },
          {
            titulo: "Ver cartola",
            ruta: "/ver-cartola",
          },
          {
            titulo: "Días tomados / Papeleta",
            ruta: "/ver-detalle-vacaciones",
          },
        ]);
      } else {
        setRutas([
          {
            titulo: "Solicitar",
            ruta: "/solicitar-vacaciones",
          },
          {
            titulo: "Ver cartola",
            ruta: "/ver-cartola",
          },
          {
            titulo: "Días tomados / Papeleta",
            ruta: "/ver-detalle-vacaciones",
          },
        ]);
      }
    }
  }, [loginData]);

  useCarousel(0,'carousel-vacaciones-mobile', rutas);

  return (
    <>
      <div className="row mb-4 d-none d-lg-flex">
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carousel-datos-personales"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item p-0 active" data-bs-interval="10000">
            <div class="row">
              {rutas ? (
                rutas.map((e) => {
                  if (props.tituloSelect === e.titulo) {
                    return (
                      <div className="col mb-4">
                        <Link
                          className="btn btn-xl text-uppercase btn-degradado-2"
                          to={e.ruta}
                        >
                          <span className="align-self-center">{e.titulo}</span>
                          <i
                            className="fa fa-chevron-right"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </div>
                    );
                  } else {
                    return (
                      <div className="col mb-4">
                        <Link className="btn btn-xl text-uppercase" to={e.ruta}>
                          <span className="align-self-center">{e.titulo}</span>
                          <i
                            className="fa fa-chevron-right"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </div>
                    );
                  }
                })
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        id="carousel-vacaciones-mobile"
        className="carousel carousel-dark slide carousel-control-inner mt-4 mb-4 d-lg-none"
        data-bs-ride="carousel"
        data-bs-interval="false"
      >
        <div className="carousel-indicators">
          {rutas ? (
            rutas.map((e, index) => {
              if (props.tituloSelect === e.titulo) {
                return (
                  <button
                    type="button"
                    data-bs-target="#carousel-vacaciones-mobile"
                    data-bs-slide-to={"" + index}
                    className="active"
                    aria-current="true"
                    aria-label={"Slide " + index}
                  ></button>
                );
              } else {
                return (
                  <button
                    type="button"
                    data-bs-target="#carousel-vacaciones-mobile"
                    data-bs-slide-to={"" + index}
                    aria-label={"Slide " + index}
                  ></button>
                );
              }
            })
          ) : (
            <></>
          )}
        </div>
        <div className="carousel-inner">
          {rutas ? (
            rutas.map((e) => {
              if (props.tituloSelect === e.titulo) {
                return (
                  <div className="carousel-item p-2 active">
                    <Link
                      className="btn btn-xl text-uppercase btn-degradado-2"
                      to={e.ruta}
                    >
                      <span className="align-self-center">{e.titulo}</span>
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </Link>
                  </div>
                );
              } else {
                return (
                  <div className="carousel-item p-2 ">
                    <Link className="btn btn-xl text-uppercase " to={e.ruta}>
                      <span className="align-self-center">{e.titulo}</span>
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </Link>
                  </div>
                );
              }
            })
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default CarrouselVacaciones;
