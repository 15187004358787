import React, { useState, useEffect } from 'react';
import { BotonsSaveCancel } from "../commonComponents/BotonsSave";
import styles from "../../resources/css/style-COL.module.css";
import { countrys } from './CountryDefn';
import { useForm } from "react-hook-form";
import { addItem } from "../../services/maintanceServives/maintanceService";
import Swal from 'sweetalert2';

export const AgregarContenido = ({ onCancel, onSave }) => {

    const [noDataAdd, setNoDataAdd] = useState(false)
    const [dataAdd, setDataAdd] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSaveClick = async (data) => {
        setDataAdd(true)
        await addItem(data, setNoDataAdd, setDataAdd)
        onSave();

    };

    const handleStatusError = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
            html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo.</div>`,
            confirmButtonColor: '#0169b5',
        })
    }

    const handleStatusSuccess = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
            html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
            confirmButtonColor: '#0169b5',
        })
    }

    return (
        <div className={`${styles["box"]} ${styles["mb-4"]}`}>
            <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Agregar</h2>

            <form>
                {/* <!-- Primera Fila --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}> Título </label>
                        <input type="text" className={styles["form-control"]} {...register("title", { required: "* Campo obligatorio", maxLength: 50 })} />
                        <small className={styles["form-error-message"]}>{errors.title?.message}</small>
                    </div>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Región</label>
                        <div className={styles["input-group", { required: "* Campo obligatorio" }]}>
                            <select {...register("region")} className={styles["select-col"]}>
                                {/* <option disabled value="" selected>Seleccione</option> */}
                                {countrys?.map((l, index) => { return (<option key={index} value={l.valor === 'PL' ? '' : l.valor}>{l.descripcion === 'Publicas' ? 'Ninguno' : l.descripcion}</option>) })}
                            </select>
                            <small className={styles["form-error-message"]}>{errors.region?.message}</small>
                        </div>
                    </div>
                </div>

                {/* <!-- Segunda Fila --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}> Componente </label>
                        <input type="text" className={styles["form-control"]} {...register("component", {  maxLength: 50 })} />
                    </div>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}> Url </label>
                        <input type="text" className={styles["form-control"]} {...register("url", { maxLength: 50 })} />
                    </div>
                </div>

                {/* <!-- Tercer Fila --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Publico</label>
                        <div className={styles["input-group", { required: "* Campo obligatorio" }]}>
                            <select {...register("publico")} className={styles["select-col"]}>
                                <option value="1" >Si</option>
                                <option value="0" selected>No</option>
                            </select>
                            <small className={styles["form-error-message"]}>{errors.publico?.message}</small>
                        </div>
                    </div>

                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Has childrens</label>
                        <div className={styles["input-group", { required: "* Campo obligatorio" }]}>
                            <select {...register("hasChildrens")} className={styles["select-col"]}>
                                <option value="1" >Si</option>
                                <option value="0" selected>No</option>
                            </select>
                            <small className={styles["form-error-message"]}>{errors.hasChildrens?.message}</small>
                        </div>
                    </div>
                </div>

                {/* <!-- Cuarta Fila --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}> Class </label>
                        {/* hay que cambiarlo por classValue */}
                        <input type="text" className={styles["form-control"]} {...register("classValue", { maxLength: 50 })} />
                    </div>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}> Parent </label>
                        {/* hay que cambiarlo por hasParent */}
                        <input type="number" className={styles["form-control"]} min={0} defaultValue={0} {...register("hasParent", { required: "* Campo obligatorio" })} />
                        <small className={styles["form-error-message"]}>{errors.parent?.message}</small>
                    </div>
                </div>

                {/* <!-- Quinta Fila --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}> Level </label>
                        <input type="number" className={styles["form-control"]} min={0} defaultValue={0}  {...register("level")} />
                    </div>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Componente Default</label>
                        <div className={styles["input-group", { required: "* Campo obligatorio" }]}>
                            <select {...register("defaultComponent")} className={styles["select-col"]}>
                                <option value="1" >Si</option>
                                <option value="0" selected>No</option>
                            </select>
                            <small className={styles["form-error-message"]}>{errors.defaultComponent?.message}</small>
                        </div>
                    </div>

                </div>

                {/* <!-- Sexta Fila --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}> Order </label>
                        <input type="number" className={styles["form-control"]} min={1} defaultValue={0} {...register("order", { required: "* Campo obligatorio" })} />
                        <small className={styles["form-error-message"]}>{errors.order?.message}</small>
                    </div>

                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}> Label </label>
                        <input type="text" className={styles["form-control"]} {...register("label", { required: "* Campo obligatorio" })} />
                        <small className={styles["form-error-message"]}>{errors.label?.message}</small>
                    </div>

                </div>
                
                {/* <!-- Septima Fila --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                    <label className={styles["form-label"]}>Enabled</label>
                    <div className={styles["input-group", { required: "* Campo obligatorio" }]}>
                        <select {...register("enabled")} className={styles["select-col"]}>
                            <option value="1" >Si</option>-
                            <option value="0" selected>No</option>
                        </select>
                        <small className={styles["form-error-message"]}>{errors.enabled?.message}</small>
                    </div>
                </div>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Mantenimiento</label>
                        <div className={styles["input-group", { required: "* Campo obligatorio" }]}>
                            <select {...register("mantainance")} className={styles["select-col"]}>
                                <option value="1" >Si</option>
                                <option value="0" selected>No</option>
                            </select>
                            <small className={styles["form-error-message"]}>{errors.mantainance?.message}</small>
                        </div>
                    </div>
                </div>

                 {/* <!-- Septima Fila --> */}
                 <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}> Feature </label>
                        <input type="text" className={styles["form-control"]} {...register("feature", { required: "* Campo obligatorio", maxLength: 50 })} />
                        <small className={styles["form-error-message"]}>{errors.feature?.message}</small>
                    </div>
                </div>

                {/* <!-- Final de las Filas --> */}
            </form>

            <BotonsSaveCancel cancelHandler={onCancel} saveHandler={handleSubmit(onSaveClick)} />
            {noDataAdd ? handleStatusError() : null}
            {dataAdd ? handleStatusSuccess() : null}
        </div>
    )
}