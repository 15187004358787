import React, { FC } from "react";
import Box from "../Box";
import { WidgetStatusProps } from "../Widget/interfaces";


const LoadingWidget : FC<WidgetStatusProps> = ({ width } : WidgetStatusProps) => {
  return (
    <Box width={width} box={false}>
        <div className="text-center d-flex justify-content-center align-items-center " style={{minHeight: '120px'}}>
          <span className="spinner-border spinner-border-xl text-primary" />
        </div>
    </Box>
  );
};

export default LoadingWidget;