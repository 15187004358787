import type { CarouseItemProps } from "./CarouselSubMenu.types";
import styles from "../../../../resources/css/style-COL.module.css";
import clsx from "../../../../utils/clsx";
import { useIsMobile } from "../../../../hooks";
import { useTeamDashboardProvider } from "../../context/TeamDashboardContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const CarouseItem = (props: CarouseItemProps) => {
  const { item, isActive } = props;

  const { handleChangeSubMenu } = useTeamDashboardProvider();
  const isMobile = useIsMobile();

  const button = (
    <button
      onClick={() => handleChangeSubMenu(item.id)}
      className={clsx("btn btn-xl text-uppercase", {
        [styles["btn-degradado-2"]]: item.isSelected,
        [styles["text-white"]]: item.isSelected,
      })}
    >
      <span className="align-self-center">{item.label}</span>
      <FontAwesomeIcon
        className={`fa fa-chevron-right`}
        icon={`${item.icon}` as IconProp}
        aria-hidden="true"
      />
    </button>
  );

  return (
    <>
      {isMobile ? (
        <div
          data-bs-interval="10000"
          className={`carousel-item p-2 ${isActive && "active"}`}
        >
          <div className="row">
            <div className="col mb-4">{button}</div>
          </div>
        </div>
      ) : (
        <div className="col-3 mb-4">{button}</div>
      )}
    </>
  );
};
