import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { urlsAws } from "../../resources/foo/api-endpoints";
import datosLaboralesService from "../../services/datos-laborales-service";
import carpetaDigitalDt from "../../services/modulo-dt-service";
import { decrypt } from "../../services/utiles";
import vacacionesService from "../../services/vacaciones-service";
import CarrouselVacaciones from "../elements/vacaciones-carrousel";
const ReemplazoVacaciones = () => {
  const [loginData, setLoginData] = useState({});
  const [, setData] = useState(false);
  const [newData, setNewData] = useState(null);
  const [asigReemplazo, setAsigReemplazo] = useState(false);
  const [dataAsig, setDataAsig] = useState(false);
  const [rutAsignado, setRutAsignado] = useState("");
  const [nombreAsig, setNombreAsig] = useState("");
  const [emplidAsig, setEmplidAsig] = useState("");
  const [respuestaReemplazo, setRespuestaReemplazo] = useState(false);
  const [dataUsuario, setDataUsuario] = useState(false);
  const [mensajeFist, setMensajeFirst] = useState("");
  const [estadoBuscar, setEstadoBuscar] = useState(false);
  const [mensajeName, setMensajeName] = useState("");
  const [success, setSuccess] = useState(false);
  const [, setValidMesaje] = useState(false);
  const [misDias, setMisDias] = useState(0);
  const [respuestaSolicitudText, setRespuestaSolicitudText] = useState("");
  const [respuestaSolicitudValid, setRespuestaSolicitudValid] = useState(false);
  const [diasDisponibles, setDiasDisponibles] = useState(null);
  const [, setValidadorRut] = useState(true);
  const [errorRegistrar, setErrorRegistrar] = useState(false);
  const [existDataModal, setExistDataModal] = useState(false);
  const [existDataModalDelete, setExistDataModalDelete] = useState(false);
  const [encuentradataPreviamente, setEncuentradataPreviamente] =
    useState(false);
  const [bloquearBoton, setBloquearBoton] = useState(false);
  const [insertadoExito, setInsertadoConExito] = useState(false);
  const [eliminarReemplazo, setEliminarReemplazo] = useState(false);
  const [visibleModal, setVisibleModal] = useState(true);
  const [cargando, setCargando] = useState(false);
  const [cargandoDelete, setCargandoDelete] = useState(false);
  const [elimina, setElimina] = useState(false);
  let navigate = useNavigate();
  const [emplid, setEmplid] = useState(localStorage.getItem("emplid"));
  const [desactivarInput, setDesactivarInput] = useState(true);
  const [tieneNet, setTieneNet] = useState(true);
  const delay = (ms = 6000) => new Promise((r) => setTimeout(r, ms));
  const newCall2 = async () => {
    await delay();
    setCargando(false);
    setCargandoDelete(false);
    setInsertadoConExito(true);
    setEmplidAsig(newData.emplid_to);
    setNombreAsig(newData.name_ac);
    setDataAsig(true);
    setNewData(undefined);
    setValidMesaje(true);
  };
  const newCall = async () => {
    await delay();
    setCargando(false);
    setElimina(false);
    setEmplidAsig("");
    setNombreAsig("");
    setDataAsig(false);
    setRutAsignado("");
    setCargandoDelete(false);
  };

  useEffect(() => {
    if (emplid !== null) {
      vacacionesService.getDetalleDiasDisponibles(setDiasDisponibles, emplid);

      vacacionesService.buscarReasignacion(setRutAsignado, emplid);

      caches.match(urlsAws.firstLogin).then((response) => {
        if (response && response.body !== null) {
          response.json().then((data) => {
            setLoginData(data);
          });
        } else {
          datosLaboralesService.firstLogin(emplid);
        }
      });
    } else {
      datosLaboralesService.adfsLogin(setEmplid);
      setEmplid(window.sessionStorage.getItem("emplid"));
      if (emplid !== "" && emplid !== null) {
        datosLaboralesService.datosLaborales(setData, emplid);
      } else {
        navigate("/");
      }
    }
  }, [emplid, navigate, cargando]);

  useEffect(() => {
    if (diasDisponibles) {
      if (diasDisponibles.balance) {
        setMisDias(decrypt(diasDisponibles.balance));
      }
    }
  }, [diasDisponibles]);

  const handleClose = () => {
    setRespuestaSolicitudValid(false);
    setExistDataModal(false);
    setExistDataModalDelete(false);
    setErrorRegistrar(false);
    setTieneNet(true);
    setInsertadoConExito(false);
  };
  useEffect(() => {
    if (respuestaReemplazo === 2) {
      setExistDataModalDelete(true);
    } else if (respuestaReemplazo) {
      if (respuestaReemplazo.status === "Problemas al borrar solicitud") {
        setExistDataModalDelete(true);
      } else {
        if (elimina) {
          setCargandoDelete(true);
          newCall();
        } else {
          setCargando(true);
          newCall();
        }
      }
    }
  }, [respuestaReemplazo, eliminarReemplazo]);

  const eliminarReasignacion = (e) => {
    e.preventDefault();
    if (navigator.onLine) {
      setElimina(true);
      vacacionesService.eliminarReasignacion(emplid, setRespuestaReemplazo);
    } else {
      setRespuestaSolicitudValid(true);
      setRespuestaSolicitudText(
        "La conexión no está disponible en estos momentos, favor de intentar más tarde."
      );
    }
  };

  const handleSubmitSearch = (e) => {
    e.preventDefault();
    setEstadoBuscar(true);
    if (navigator.onLine) {
      setMensajeName("Cargando..");
      const emplidob = document.getElementById("rut").value;

      setDataUsuario(false);
      vacacionesService.getValidCollaborator(emplidob, setMensajeName, setValidadorRut)

    } else {
      setMensajeName("No tienes conexión.");
    }
  };

  useEffect(() => {
    if (encuentradataPreviamente) {
      setErrorRegistrar(false);
    } else {
      setErrorRegistrar(true);
    }
  }, [encuentradataPreviamente]);

  useEffect(() => {
    if (rutAsignado !== 2 && rutAsignado !== undefined && rutAsignado !== "") {
      if (rutAsignado.emplid_to !== "") {
        setEmplidAsig(rutAsignado.emplid_to);
        setNombreAsig(rutAsignado.name_ac);
        setDataAsig(true);
        setErrorRegistrar(false);
        setEncuentradataPreviamente(true);
        setBloquearBoton(true);
        setRutAsignado("");
      } else {
        if (rutAsignado.name_ac === "NO TIENE") {
          setEncuentradataPreviamente(true);
          setBloquearBoton(true);
          setRutAsignado("");
        } else {
          setEncuentradataPreviamente(false);
          setBloquearBoton(false);
          setRutAsignado("");
        }
      }
    } else if (rutAsignado === 2) {
      setVisibleModal(false);
    }
  }, [rutAsignado]);

  useEffect(() => {
    if (newData !== 2 && newData) {
      if (newData.emplid_to !== "") {
        setCargando(true);
        newCall2();
      }
    } else if (newData === 2) {
      setExistDataModal(true);
    }
  }, [asigReemplazo, newData]);

  const asignarReemplazo = () => {
    var emplidSol = document.getElementById("rut").value;
    if (emplidSol !== null) {
      if (mensajeName !== "" && mensajeName !== "Cargando..") {
        setSuccess(true);
        setMensajeName("");
        setNombreAsig("");
        setValidadorRut(true);
        if (navigator.onLine) {
          vacacionesService.realizarReasignacion(emplid, emplidSol, setNewData);
          setRutAsignado("");
          setAsigReemplazo(true);
          emplidSol = null;
        } else {
          setRespuestaSolicitudValid(true);
          setRespuestaSolicitudText(
            "La conexión no está disponible en estos momentos, favor de intentar más tarde."
          );
        }
      }
    }
  };

  const cancelarReemplazo = () => {
    setMensajeName("");
    setNombreAsig("");
  };

  return (
    <>
      <nav className="breadcrumbs" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#">
              <i className="fa fa-home" aria-hidden="true"></i> Inicio
            </a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Reemplazo vacaciones
          </li>
        </ol>
      </nav>{" "}
      <div className="tit-group d-block d-xxl-flex justify-content-between">
        <h1>Vacaciones</h1>
        <div className="saldo-vacaciones rounded-3 bg-light p-3 color-primary mb-4">
          <div className="row align-items-center">
            <label
              htmlFor="inputPassword"
              className="col-6 col-sm-4 col-md-3 col-form-label"
            >
              Saldo Vacaciones:
            </label>
            <div className="col-3 col-md-2 col-xl-1" style={{ width: "100px" }}>
              <input
                type="text"
                className="form-control text-center bg-white"
                value={misDias}
                disabled
              />
            </div>
            <small className="col-12 col-md-6 col-lg-7 col-xl-15 mt-4 mt-md-0">
              * Su saldo de vacaciones actual incluye los días futuros aprobados
            </small>
          </div>{" "}
        </div>
      </div>
      <>
        <CarrouselVacaciones tituloSelect="Ver mi reemplazo"></CarrouselVacaciones>
      </>
      <div className="box mb-4">
        <div className="tit-group d-flex justify-content-between">
          <h2 className="h4 tit-section">Reemplazo de vacaciones</h2>
          {!dataAsig && (
            bloquearBoton && (
              <a
                href="#"
                onClick={(e) => { e.preventDefault(); setSuccess(false) }}
                className="mt-2"
                data-bs-toggle="modal"
                data-bs-target="#reemplazo"
              >
                <i className="fa fa-plus-square-o fa-lg" aria-hidden="true"></i>
              </a>
            )
          )}
        </div>
        <div
          className="modal fade"
          id="reemplazo"
          tabIndex="-1"
          aria-labelledby="reemplazo"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <form onSubmit={(e) => handleSubmitSearch(e)}>
                <div className="modal-header border-0 pb-0">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <h2 className="h5 text-uppercase mb-4">
                    Reemplazo de vacaciones
                  </h2>{" "}
                  <div className="mb-3">
                    <label className="form-label mb-2">
                      <strong>RUT</strong>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="rut"
                      id="rut"
                      onChange={(e) => {
                        e.target.value === ""
                          ? setDesactivarInput(true)
                          : setDesactivarInput(false);
                      }}
                      maxLength={10}
                      required
                      pattern="[0-9]+-[0-9kK]{1}"
                      placeholder="Ingrese rut"
                      onInput={(e) => e.target.setCustomValidity("")}
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          "Debe ingresar un RUT válido (ej: 11111111-8)"
                        )
                      }
                    />{" "}
                    <div className="invalid-feedback">
                      * Debe ingresar un RUT válido
                    </div>
                    <p className="mt-3">
                      Indicar RUT sin puntos y con guión, de la persona que
                      aprobará las solicitudes de su equipo en su ausencia.
                    </p>
                  </div>
                  <div className="mb-3 d-grid d-md-block">
                    <button type="submit" className="btn btn-primary">
                      Buscar{" "}
                      <i
                        className="fa fa-chevron-right fa-fw fa-xs"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>{" "}
                  <div className="mb-3">
                    <label
                      className="form-label col-12 mb-2"
                      htmlFor="validar-numero"
                    >
                      <strong>Nombre</strong>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      value={mensajeName}
                      disabled
                    />
                  </div>
                </div>
                <div className="modal-footer border-0 pt-0">
                  <button
                    type="button"
                    className="btn btn-primary col-12 col-md-auto"
                    onClick={(e) => cancelarReemplazo(e)}
                    data-bs-dismiss="modal"
                  >
                    Cancelar{" "}
                    <i
                      className="fa fa-chevron-right fa-fw fa-xs"
                      aria-hidden="true"
                    ></i>
                  </button>

                  <button
                    type="button"
                    className="btn btn-degradado col-12 col-md-auto"
                    disabled={desactivarInput}
                    onClick={(e) => asignarReemplazo(e)}
                  >
                    Guardar{" "}
                    <i
                      className="fa fa-chevron-right fa-fw fa-xs"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="success"
          tabIndex="-1"
          aria-labelledby="success"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content"> </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-even table-section table-borderless text-uppercase d-none d-md-table">
            <thead>
              <tr>
                {" "}
                <th>Rut</th>
                <th>Nombre</th>
                <th className="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {!dataAsig ? (
                  <></>
                ) : (
                  <>
                    <td>{emplidAsig}</td>
                    <td>{nombreAsig}</td>
                    <td className="text-center">
                      <a href="#">
                        <i
                          className="fa fa-trash-o fa-fw me-2"
                          onClick={(event) => eliminarReasignacion(event)}
                          aria-hidden="true"
                        ></i>
                      </a>
                    </td>
                  </>
                )}{" "}
              </tr>
            </tbody>
          </table>
        </div>
        <div className="data-list-box data-list-box-even d-block d-md-none">
          <ul className="list-unstyled mb-0">
            {" "}
            <li>
              <span className="text-uppercase d-block mb-1">
                <strong>Rut</strong>
              </span>
              {!dataAsig ? (
                <></>
              ) : (
                <>
                  <span>{emplidAsig}</span>
                </>
              )}{" "}
            </li>
            <li>
              <span className="text-uppercase d-block mb-1">
                <strong>Nombre</strong>
              </span>
              {!dataAsig ? (
                <></>
              ) : (
                <>
                  <span>{nombreAsig}</span>
                </>
              )}{" "}
            </li>
            <li>
              <span className="text-uppercase d-block mb-1">
                <strong>Acciones</strong>
              </span>
              {!dataAsig ? (
                <></>
              ) : (
                <>
                  {" "}
                  <a href="#">
                    <i
                      onClick={(event) => eliminarReasignacion(event)}
                      className="fa fa-trash-o fa-fw me-2"
                      aria-hidden="true"
                    ></i>
                  </a>
                </>
              )}{" "}
            </li>
          </ul>
        </div>
      </div>
      {existDataModalDelete ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => handleClose(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-download color-orange fa-3x mb-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">Reemplazo vacaciones.</h2>
                    <p>Ha ocurrido un error al borrar el reemplazo.</p>
                    <br></br>
                    <p>Favor de intentar nuevamente.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {errorRegistrar ? (
        <>
          <div className="col" hidden={visibleModal}>
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => handleClose(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-download color-orange fa-3x mb-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">Reemplazo vacaciones.</h2>
                    <p>
                      Por un error de comunicación no se ha obtenido la
                      información, por favor inténtelo más tarde.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {insertadoExito ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => handleClose(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-check-circle-o color-orange fa-3x mb-2"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">Reemplazo realizado con éxito</h2>
                    <p>
                      Sus solicitudes serán derivadas <br /> al reemplazo
                      seleccionado.
                    </p>
                    <br></br>
                    <b>En breve se verán reflejados sus cambios.</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {existDataModal ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => handleClose(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-download color-orange fa-3x mb-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">Reemplazo vacaciones.</h2>
                    <p>Ha ocurrido un error al registrar el reemplazo.</p>
                    <br></br>
                    <p>Favor de intentar nuevamente.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {cargando ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-download color-orange fa-3x mb-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">Reemplazo vacaciones.</h2>
                    <p>Enviando solicitud.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {cargandoDelete ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-download color-orange fa-3x mb-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">Reemplazo vacaciones.</h2>
                    <p>Eliminando solicitud.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {respuestaSolicitudValid ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => handleClose(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    {" "}
                    <h2 className="h5">Solicitud de vacaciones.</h2>
                    <p>{respuestaSolicitudText}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {!tieneNet ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => handleClose(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-exclamation-triangle color-orange fa-3x"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">No tienes conexión</h2>
                    <p>No puedes ejecutar esta acción si no tienes conexión</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      <div className="ps-4 color-red">
        <p>
          <i
            className="fa fa-exclamation-triangle fa-fw fa-lg me-1"
            aria-hidden="true"
          ></i>{" "}
          Sólo es posible asignar un reemplazo a la vez, si desea cambiar el
          reemplazo debe eliminar el actual.
        </p>
      </div>{" "}
    </>
  );
};
export default ReemplazoVacaciones;
