import Auth from "@aws-amplify/auth";
import axios from 'axios';

const url = process.env.REACT_APP_BACKEND_URL_GLOBAL;

const newsService = {
    getNews: (setItems, { fromPanel = undefined, status = null } = {}, setLoading = undefined) => {
        Auth.currentSession().then((auth) => {
            if (setLoading) setLoading(true);
            const headers = {
                'Content-Type': 'application/json',
                'AccessToken': auth.getAccessToken().getJwtToken()
            }

            const params = {};

            if (fromPanel) params['fromPanel'] = 1;
            if (status) params['status'] = status;

            axios.get(`${url}/api/news`, { headers, params })
                .then(({ data }) => {
                    setItems(data);
                    if (setLoading) setLoading(false);
                }).catch((error) => {
                    console.log(error)
                    if (setLoading) setLoading(false);
                })
        });
    },
    getNewsToDashboard: (setItems, { fromPanel = undefined, status = null } = {}, setLoading = undefined) => {
        Auth.currentSession().then((auth) => {
            if (setLoading) setLoading(true);
            const headers = {
                'Content-Type': 'application/json',
                'AccessToken': auth.getAccessToken().getJwtToken()
            }

            const params = {};

            if (fromPanel) params['fromPanel'] = 1;
            if (status) params['status'] = status;

            axios.get(`${url}/api/news`, { headers, params })
                .then(({ data }) => {
                    const news = [];
                    data.forEach(d => {
                        if (d.status == 'available') news.push(d);
                    });

                    setItems(news);
                    if (setLoading) setLoading(false);
                }).catch((error) => {
                    console.log(error)
                    if (setLoading) setLoading(false);
                })
        });
    },
    getNew: (setData, id) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "AccessToken": auth.getAccessToken().getJwtToken()
                },
            };

            fetch(`${url}/api/news/${id}`, requestOptions)
                .then((response) => response.text())
                .then((response) => JSON.parse(response.replaceAll(",}", "}")))
                .then((data) => {
                    setData(data);
                });
        });
    },
    changeStatus: (setStatus, data = {}) => {
        Auth.currentSession().then((auth) => {
            const headers = {
                'Content-Type': 'application/json',
                'AccessToken': auth.getAccessToken().getJwtToken()
            }

            axios.put(`${url}/api/news/change-status`, data, { headers })
                .then(() => {
                    setStatus(data.status);
                }).catch((error) => console.log(error))
        });
    },
    createNew: (setData, data, setRespuestaSolicitudFallida) => {
        Auth.currentSession().then((auth) => {
            const headers = {
                'Content-Type': 'application/json',
                'AccessToken': auth.getAccessToken().getJwtToken()
            }

            axios.post(`${url}/api/news`, data, { headers })
                .then((data) => {
                    setData(data);
                }).catch((error) => setRespuestaSolicitudFallida(true))
        });
    },
    updateNew: (setData, data, id, setRespuestaSolicitudFallida) => {
        Auth.currentSession().then((auth) => {
            const headers = {
                'Content-Type': 'application/json',
                'AccessToken': auth.getAccessToken().getJwtToken()
            }

            axios.put(`${url}/api/news/${id}`, data, { headers })
                .then((data) => {
                    setData(data);
                }).catch((error) => setRespuestaSolicitudFallida(true))
        });
    },
}

export default newsService;
