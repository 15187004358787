import React from 'react'
import { Routes, Route } from "react-router-dom";
import NewsManagerForm from './news-manager-form';
import NewsManagerList from './news-manager-list'

const NewsManager = () => {
  return (
    <>
    <Routes>
        <Route path="" element={<NewsManagerList />} />
        <Route path="form" element={<NewsManagerForm />} />
        <Route path="form/:id" element={<NewsManagerForm />} />
    </Routes>
    </>
  )
}

export default NewsManager
