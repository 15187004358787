import { useTableProvider } from "../../context/DashboardTableContext";
import { DependenceCellProps } from "./table.types";

export const DependenceCell = (props: DependenceCellProps) => {
  const { document, hasSubordinates } = props;
  const { setIdSubLeader } = useTableProvider();

  const handleClick = () => {
    setIdSubLeader(document);
  };

  return (
    <button
      className="btn-degradado btn btn-sm text-capitalize fw-bold text-nowrap"
      onClick={handleClick}
      disabled={!hasSubordinates}
    >
      Ver Equipo
    </button>
  );
};
