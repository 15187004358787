import { createContext, useContext, useEffect, useState } from "react";
import {
  SubscriptionContextType,
  SubscriptionProviderProps,
} from "./subscription.types";
import { Newsletter, services, useSubscriptionSetting } from "../utils";
import { useSubscriptionSave } from "../utils/useSubscriptionSave";

export const SubscriptionContext = createContext<
  SubscriptionContextType | undefined
>(undefined);

export const SubscriptionProvider = (props: SubscriptionProviderProps) => {
  const { children } = props;
  const {
    subscriptions,
    isLoading: isLoadingSubscriptions,
    isError: isErrorSubscription,
  } = useSubscriptionSetting();

  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [disabledDelete, setdisabledDelete] = useState(true);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const {
    saveSubscription,
    setOpenModalSave,
    openModalSave,
    isLoading: isLoadingSave,
    isError: isErrorSave,
    isSuccess: isSuccessSave,
  } = useSubscriptionSave();
  const [subscriptionsData, setSubscriptions] = useState<Newsletter>(
    {} as Newsletter
  );

  useEffect(() => {
    subscriptions && setSubscriptions(subscriptions);
  }, [subscriptions]);

  useEffect(() => {
    const selectedSubs = subscriptionsData.newsletter?.flatMap((item) =>
      item.subscription.filter((sub) => sub.selected)
    );
    selectedSubs && setdisabledDelete(selectedSubs.length < 1);
  }, [subscriptionsData.newsletter]);

  const handleChange = (value: string) => {
    setSubscriptions({
      ...subscriptionsData,
      newsletter: subscriptionsData.newsletter.map((sub) => ({
        ...sub,
        subscription: sub.subscription.map((item) => ({
          ...item,
          selected: item.value === value ? !item.selected : item.selected,
        })),
      })),
    });
  };

  const deleteAllSubs = () => {
    handleModalConfirm()
    setSubscriptions({
      ...subscriptionsData,
      newsletter: subscriptionsData.newsletter.map((sub) => ({
        ...sub,
        subscription: sub.subscription.map((item) => ({
          ...item,
          selected: false,
        })),
      })),
    });
    saveSubscription([])
  };

  const checkSubsTerms = () => {
    setDisabledSubmit(!disabledSubmit);
  };

  const saveNewsLetter = async () => {
    const selectedSubs = subscriptionsData.newsletter.flatMap((item) =>
      item.subscription.filter((sub) => sub.selected)
    );

    const stringSelected = selectedSubs.map((selected) => selected.value);
    saveSubscription(stringSelected);
  };

  const handleModalSave = () => {
    setOpenModalSave(!openModalSave);
  };

  const handleModalConfirm = () => {
    setOpenModalConfirm(!openModalConfirm);
  };

  const contextValue = {
    isLoadingSubscriptions,
    isErrorSubscription,
    setDisabledSubmit,
    subscriptionsData,
    disabledDelete,
    checkSubsTerms,
    disabledSubmit,
    deleteAllSubs,
    handleChange,
    saveNewsLetter,
    saveSubcription: {
      isLoading: isLoadingSave,
      isError: isErrorSave,
      isSuccess: isSuccessSave,
    },
    openModalSave,
    handleModalSave,
    openModalConfirm,
    handleModalConfirm
  };

  return (
    <SubscriptionContext.Provider value={contextValue}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscriptionContext = (): SubscriptionContextType => {
  const subscriptionContext = useContext(SubscriptionContext);

  if (!subscriptionContext) return {} as SubscriptionContextType;

  return subscriptionContext;
};
