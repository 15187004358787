import React, { useEffect, useState } from "react";
import styles from "../../resources/css/style-COL.module.css";
import { useForm } from "react-hook-form";
import ContactosEmergenciaFamiliaresServices from "../../services/contactos emergencia y familiares/contactos-emergencia-y-familiares-service";
import Swal from "sweetalert2";
import { toBase64 } from "../../services/utiles";
import errorMessage from "../SharedComponents/error-message";
import successMessage from "../SharedComponents/success-message";


const ContactoFamiliar = ({
  nameContact,
  parentesco,
  close,
  setNameContact,
  newData,
  reloadInfo,
  setEditG,
  listContact,
}) => {
  const [marStatus, setMarStatus] = useState([]);
  const [ocupation, setOcupation] = useState([]);
  const [professions, setProfession] = useState([]);
  const [education, setEducation] = useState([]);
  const [info, setInfo] = useState({});
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [infoAdded, setInfoAdded] = useState(false);
  const [noDataAdd, setNoDataAdd] = useState(false);
  const [errorMessageAdd, setErrorMessageAdd] = useState(null);
  const [genre, setGenre] = useState([]);
  const [birthdateNotValidated, setBirthdateNotValidated] = useState(false);
  const [dateOfDeathNotValidated, setDateOfDeathNotValidated] = useState(false);
  const [dateOfDeathNotValidated2, setDateOfDeathNotValidated2] =
    useState(false);
  const [errorAttachment, setErrorAttachment] = useState(null)
  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      RELATIONSHIP: "",
      FIRST_NAME: "",
      LAST_NAME: "",
      NID_SPECIAL_CHAR: "",
      SEX: "",
      BIRTHDATE: "",
      DT_OF_DEATH: "",
      MAR_STATUS: "",
      CN_OCUPACION: "",
      CN_NIVEL_EDUC: "",
      CN_PROFESION: "",
      STUDENT: "",
      ATTACHSYSFILENAME: "",
      FILE_DATA: "",
      CN_DEPEND_FLAG: "",
      EMERGENCY_CONTACT: "",
      TEL_PHONE: "",
      CEL_PHONE: "",
      CN_AFILIADO_EPS: "",
    },
  });

  const onSubmit = async (data) => {
    setErrorAttachment(null)
    if(data.RELATIONSHIP == "C" && data.attachment == null){
      setErrorAttachment("*Campo obligatorio");
      return
    }
    if(data.attachment && data.attachment[0].name.length > 30){
      setErrorAttachment("El nombre del archivo supera el máximo de caracteres.")
      return
  }
    if (data.EMERGENCY_CONTACT == "Y")
      if (
        listContact.filter((e) => e.CONTACT_NAME == data.FIRST_NAME).length > 0
      ) {
        handleStatusError(
          "No es posible registrar dos contactos con el mismo nombre. Por favor modifique el nombre."
        );
        return;
      }

    setLoading(true);
    setBirthdateNotValidated(false);
    setDateOfDeathNotValidated(false);
    setDateOfDeathNotValidated2(false);

    if (new Date(data.BIRTHDATE) > new Date()) {
      setBirthdateNotValidated(true);
      return;
    }
    if (data.DT_OF_DEATH !== "") {
      if (new Date(data.BIRTHDATE) > new Date(data.DT_OF_DEATH)) {
        setDateOfDeathNotValidated(true);
        return;
      }
      if (new Date(data.DT_OF_DEATH) > new Date()) {
        setDateOfDeathNotValidated2(true);
        return;
      }
    }
const submit = {
  CN_COL_FLIA_POST: {
    RELATIONSHIP: data.RELATIONSHIP,
    FIRST_NAME: data.FIRST_NAME,
    LAST_NAME: data.LAST_NAME,
    NID_SPECIAL_CHAR: data.NID_SPECIAL_CHAR,
    SEX: data.SEX,
    BIRTHDATE: data.BIRTHDATE,
    DT_OF_DEATH: data.DT_OF_DEATH ? data.DT_OF_DEATH : "",
    MAR_STATUS: data.MAR_STATUS,
    CN_OCUPACION: data.CN_OCUPACION ? data.CN_OCUPACION : "",
    CN_NIVEL_EDUC: data.CN_NIVEL_EDUC ? data.CN_NIVEL_EDUC : "",
    CN_PROFESION: data.CN_PROFESION ? data.CN_PROFESION : "",
    STUDENT: data.STUDENT ? data.STUDENT : +"",
    ATTACHSYSFILENAME: data.attachment ? data.attachment[0].name : "",
    FILE_DATA: data.attachment
      ? await toBase64(data.attachment[0])
      : "",
    CN_DEPEND_FLAG: data.CN_DEPEND_FLAG,
    EMERGENCY_CONTACT: data.EMERGENCY_CONTACT
      ? data.EMERGENCY_CONTACT
      : "N",
    TEL_PHONE: data.TEL_PHONE ? data.TEL_PHONE : "",
    CEL_PHONE: data.CEL_PHONE ? data.CEL_PHONE : "",
    CN_AFILIADO_EPS: data.CN_AFILIADO_EPS,
  },
}
    if (newData == true){
      try {
      let response = await ContactosEmergenciaFamiliaresServices.Addcontactfamily(submit);
        // let response = null;
        if (response === null || response === undefined) {
          errorMessage(reloadInfo, "Intentelo nuevamente en unos minutos.");
          setLoading(false);
        } else {
          const status = response?.statusCode || response?.status;
          switch (status) {
            case 200:
              setLoading(false);
              successMessage(reloadInfo);
              setInfoAdded(true);
              break;

            case 400:
              setLoading(false);
              errorMessage(reloadInfo, response?.message);
              break;

            case 500:
              setLoading(false);
              errorMessage(reloadInfo, response?.message);
              break;

            default:
              break;
          }
        }
    } catch (error) {
      setLoading(false);
      errorMessage(reloadInfo, "Intentelo nuevamente en unos minutos.");
    }
  }
  else{
    const submit = {
      CN_COL_FLIA_PUT: {
        CN_DEPEN_BENEF:info.DEPENDENT_BENEF,
        RELATIONSHIP: data.RELATIONSHIP,
        FIRST_NAME: data.FIRST_NAME,
        LAST_NAME: data.LAST_NAME,
        NID_SPECIAL_CHAR: data.NID_SPECIAL_CHAR,
        SEX: data.SEX,
        BIRTHDATE: data.BIRTHDATE,
        DT_OF_DEATH: data.DT_OF_DEATH ? data.DT_OF_DEATH : "",
        MAR_STATUS: data.MAR_STATUS,
        CN_OCUPACION: data.CN_OCUPACION,
        CN_NIVEL_EDUC: data.CN_NIVEL_EDUC,
        CN_PROFESION: data.CN_PROFESION,
        STUDENT: data.STUDENT ? data.STUDENT : "N",
        ATTACHSYSFILENAME: data.attachment ? data.attachment[0].name : "",
        FILE_DATA: data.attachment
          ? await toBase64(data.attachment[0])
          : "",
        CN_DEPEND_FLAG: data.CN_DEPEND_FLAG ? data.CN_DEPEND_FLAG : "N",
        EMERGENCY_CONTACT: data.EMERGENCY_CONTACT
          ? data.EMERGENCY_CONTACT
          : "N",
        TEL_PHONE: data.TEL_PHONE ? data.TEL_PHONE : "",
        CEL_PHONE: data.CEL_PHONE ? data.CEL_PHONE : "",
        CN_AFILIADO_EPS: data.CN_AFILIADO_EPS? data.CN_AFILIADO_EPS : info.CN_AFILIADO_EPS,
      },
    }
      try {
        let response = await ContactosEmergenciaFamiliaresServices.Editcontactfamily(
          nameContact, submit );
          if (response === null || response === undefined) {
            errorMessage(reloadInfo,  "Intentelo nuevamente en unos minutos.");
            setLoading(false);
          } else {
            const status = response?.statusCode || response?.status;
            switch (status) {
              case 200:
                setLoading(false);
                successMessage(reloadInfo);
                break;
  
              case 400:
                setLoading(false);
                errorMessage(reloadInfo, response?.message);
                break;
  
              case 500:
                setLoading(false);
                errorMessage(reloadInfo, response?.message);
                break;
  
              default:
                break;
            }
          }
      } catch (error) {
        setLoading(false);
        errorMessage(reloadInfo, "Intentelo nuevamente en unos minutos.");
      }
    }
  }
  ;

  useEffect(() => {
    ContactosEmergenciaFamiliaresServices.GetGenre(setGenre);
    ContactosEmergenciaFamiliaresServices.GetMarStatus(setMarStatus);
    ContactosEmergenciaFamiliaresServices.GetEducationLevel(setEducation);
    ContactosEmergenciaFamiliaresServices.GetOcupation(setOcupation);
    ContactosEmergenciaFamiliaresServices.GetProfession(setProfession);
  }, []);

  // Mensaje de error al enviar un post
  const handleStatusError = (message) => {
    Swal.fire({
      allowOutsideClick: false,
      position: "center",
      title:
        '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
      html: `<div style="text-align: center; font-size: 15px"> ${message}</div>`,
      confirmButtonColor: "#11427C",
    }).then(() => {});
  };

  // Mensaje de éxito al enviar un post
  const handleStatusSuccess = () => {
    Swal.fire({
      allowOutsideClick: false,
      position: "center",
      title:
        '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
      html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
      confirmButtonColor: "#0169b5",
    }).then(() => {
      reloadInfo();
    });
  };

  useEffect(async () => {
    if (nameContact != "" && nameContact != null) {
      setEdit(true);
      await ContactosEmergenciaFamiliaresServices.Getcontactfamily(
        nameContact,
        setInfo
      );
    } else {
      reset({
        RELATIONSHIP: "",
        FIRST_NAME: "",
        LAST_NAME: "",
        NID_SPECIAL_CHAR: "",
        SEX: "",
        BIRTHDATE: "",
        DT_OF_DEATH: "",
        MAR_STATUS: "",
        CN_OCUPACION: "",
        CN_NIVEL_EDUC: "",
        CN_PROFESION: "",
        STUDENT: "",
        ATTACHSYSFILENAME: "",
        FILE_DATA: "",
        CN_DEPEND_FLAG: "",
        EMERGENCY_CONTACT: "",
        TEL_PHONE: "",
        CEL_PHONE: "",
        CN_AFILIADO_EPS: "",
      });
      setEdit(false);
      setLoading(false);
    }
  }, [nameContact]);

  useEffect(async () => {
    reset(info);
    setLoading(false);
  }, [info]);

  const deleteContact = () => {
    Swal.fire({
      allowOutsideClick: false,
      position: "center",
      title:
        '<div style="text-align: center; color: red; font-size: 16px"> Eliminar </div>',
      html: '<div style="text-align: center; font-size: 15px"> ¿Desea continuar la operación? </div>',
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "red",
      cancelButtonColor: "#0169b5",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await ContactosEmergenciaFamiliaresServices.Deletecontactfamily(
          nameContact
        )
          .then((res) => handleStatusSuccess())
          .catch((err) => handleStatusError());
      }
    });
  };

  const flagsParentesco =  watch("RELATIONSHIP", "")
  
  useEffect(() => {
    setErrorAttachment(null)
},[watch("attachment")])

  return (
    <>
      {loading ? (
        <div data-testid="loading" className={styles["full-spinner"]}>
          <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
        </div>
      ) : (
        <div className={styles["cencosud-col"]}>
          {/* <!-- Inicio Agregar Familiar --> */}
          <div className={`${styles["box"]} ${styles["mb-4"]}`}>
            <div className={`${styles["tit-group"]} ${styles["d-flex"]} ${styles["justify-content-between"]}`}>
            <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>
              Agregar Familiar
            </h2>
            {info.CN_APPROVAL_STATUS == "P" && <div className={styles["Pendiente"]}>Familiar pendiente de aprobacion</div>}
                    </div>
            <form>
              <h3
                className={`${styles["h4"]} ${styles["mt-4"]} ${styles["mb-3"]} ${styles["fw-bold"]}`}
              >
                Datos Básicos
              </h3>
              {/* <!-- Fila 1 --> */}
              <div className={styles["row"]}>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    Parentesco <span className={styles["color-red"]}>*</span>
                  </label>
                  <div className={styles["input-group"]}>
                    <select
                      className={styles["form-select"]}
                      {...register("RELATIONSHIP", {
                        required: "* Campo obligatorio",
                        maxLength: 2,
                      })}
                      disabled={edit}
                    >
                      <option disabled value="" selected>
                        Seleccione
                      </option>
                      {parentesco.map((p) => {
                        return (
                          <option key={p.FIELDVALUE} value={p.FIELDVALUE}>
                            {p.XLATLONGNAME}
                          </option>
                        );
                      })}
                    </select>
                    <small className={styles["form-error-message"]}>
                      {errors.RELATIONSHIP?.message}
                    </small>
                  </div>
                </div>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    Identificación{" "}
                    <span className={styles["color-red"]}>*</span>
                  </label>
                  <input
                    type="text"
                    className={styles["form-control"]}
                    {...register("NID_SPECIAL_CHAR", {
                      required: "* Campo obligatorio",
                      maxLength: 20,
                    })}
                    disabled={edit}
                  />
                  <small className={styles["form-error-message"]}>
                    {errors.NID_SPECIAL_CHAR?.message}
                  </small>
                </div>
              </div>
              {/* <!-- Fila 2 --> */}
              <div className={styles["row"]}>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    Nombre <span className={styles["color-red"]}>*</span>
                  </label>
                  <input
                    type="text"
                    className={styles["form-control"]}
                    {...register("FIRST_NAME", {
                      required: "* Campo obligatorio",
                      maxLength: 30,
                    })}
                    disabled={edit}
                  />
                  <small className={styles["form-error-message"]}>
                    {errors.FIRST_NAME?.message}
                  </small>
                </div>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    Apellido <span className={styles["color-red"]}>*</span>
                  </label>
                  <input
                    type="text"
                    className={styles["form-control"]}
                    {...register("LAST_NAME", {
                      required: "* Campo obligatorio",
                      maxLength: 30,
                    })}
                    disabled={edit}
                  />
                  <small className={styles["form-error-message"]}>
                    {errors.LAST_NAME?.message}
                  </small>
                </div>
              </div>
              {/* <!-- Fila 2 --> */}
              <div className={styles["row"]}>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    Género <span className={styles["color-red"]}>*</span>
                  </label>
                  <div className={styles["input-group"]}>
                    <select
                      className={styles["form-select"]}
                      {...register("SEX", {
                        required: "* Campo obligatorio",
                        maxLength: 1,
                      })}
                      disabled={edit}
                    >
                      <option disabled value="" selected>
                        Seleccione
                      </option>
                      {genre.map((g) => {
                        return (
                          <option key={g.FIELDVALUE} value={g.FIELDVALUE}>
                            {g.XLATLONGNAME}
                          </option>
                        );
                      })}
                      <small className={styles["form-error-message"]}>
                        {errors.SEX?.message}
                      </small>
                    </select>
                  </div>
                </div>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    Fecha de Nacimiento{" "}
                    <span className={styles["color-red"]}>*</span>
                  </label>
                  <div className={styles["input-group"]}>
                    <input
                      className={styles["form-control"]}
                      type="date"
                      {...register("BIRTHDATE", {
                        required: "* Campo obligatorio",
                      })}
                      disabled={edit}
                    />
                  </div>
                  <small className={styles["form-error-message"]}>
                    {errors.BIRTHDATE?.message}
                  </small>
                  <small className={styles["form-error-message"]}>
                    {birthdateNotValidated
                      ? "* La fecha de nacimiento debe ser anterior a la fecha actual."
                      : null}
                  </small>
                </div>
              </div>
              {/* <!-- Fila 4 --> */}
              <div className={styles["row"]}>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>Fallecido </label>
                  <div className={styles["input-group"]}>
                    <input
                      className={styles["form-control"]}
                      type="date"
                      {...register("DT_OF_DEATH")}
                      disabled={edit}
                    />
                  </div>
                  <small className={styles["form-error-message"]}>
                    {dateOfDeathNotValidated
                      ? "* La fecha de fallecimiento no puede ser anterior a la fecha de nacimiento."
                      : null}
                  </small>
                  <small className={styles["form-error-message"]}>
                    {dateOfDeathNotValidated2
                      ? "* La fecha de fallecimiento no puede ser posterior a la fecha actual."
                      : null}
                  </small>
                </div>
              </div>
              {/* <!-- Fila 5 --> */}
              <div className={styles["row"]}>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    Estado Civil <span className={styles["color-red"]}>*</span>
                  </label>
                  <div className={styles["input-group"]}>
                    <select
                      className={styles["form-select"]}
                      {...register("MAR_STATUS", {
                        required: "* Campo obligatorio",
                        maxLength: 1,
                      })}
                      disabled={edit}
                    >
                      <option disabled value="" selected>
                        Seleccione
                      </option>
                      {marStatus.map((m) => {
                        return (
                          <option key={m.FIELDVALUE} value={m.FIELDVALUE}>
                            {m.XLATLONGNAME}
                          </option>
                        );
                      })}
                    </select>
                    <small className={styles["form-error-message"]}>
                      {errors.MAR_STATUS?.message}
                    </small>
                  </div>
                </div>
              </div>
              {/* <!-- fin filas Datos Básicos --> */}

              <h3
                className={`${styles["h4"]} ${styles["mt-4"]} ${styles["mb-3"]} ${styles["fw-bold"]}`}
              >
                Datos Adicionales
              </h3>
              {/* <!-- Fila 1 --> */}
              <div className={styles["row"]}>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>Ocupación </label>
                  <div className={styles["input-group"]}>
                    <select
                      className={styles["form-select"]}
                      {...register("CN_OCUPACION", { maxLength: 4 })}
                      disabled={edit}
                    >
                      <option disabled value="" selected>
                        Seleccione
                      </option>
                      {ocupation.map((o) => {
                        return (
                          <option key={o.FIELDVALUE} value={o.FIELDVALUE}>
                            {o.XLATLONGNAME}
                          </option>
                        );
                      })}
                    </select>
                    <small className={styles["form-error-message"]}>
                      {errors.CN_OCUPACION?.message}
                    </small>
                  </div>
                </div>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    Nivel Educativo{" "}
                  </label>
                  <div className={styles["input-group"]}>
                    <select
                      className={styles["form-select"]}
                      {...register("CN_NIVEL_EDUC", { maxLength: 1 })}
                      disabled={edit}
                    >
                      <option disabled value="" selected>
                        Seleccione
                      </option>
                      {education.map((b) => {
                        return (
                          <option key={b.FIELDVALUE} value={b.FIELDVALUE}>
                            {b.XLATLONGNAME}
                          </option>
                        );
                      })}
                    </select>
                    <small className={styles["form-error-message"]}>
                      {errors.CN_NIVEL_EDUC?.message}
                    </small>
                  </div>
                </div>
              </div>
              {/* <!-- Fila 2 --> */}
              <div className={styles["row"]}>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>Profesión </label>
                  <div className={styles["input-group"]}>
                    <select
                      className={styles["form-select"]}
                      {...register("CN_PROFESION", { maxLength: 3 })}
                      disabled={edit}
                    >
                      <option disabled value="" selected>
                        Seleccione
                      </option>
                      {professions.map((p) => {
                        return (
                          <option key={p.id} value={p.id}>
                            {p.description}
                          </option>
                        );
                      })}
                    </select>
                    <small className={styles["form-error-message"]}>
                      {errors.CN_PROFESION?.message}
                    </small>
                  </div>
                </div>
              </div>
              {/* <!-- Fila 3 --> */}
              <div className={styles["row"]}>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    Estudia Actualmente{" "}
                  </label>
                  <div className={`${styles["row"]} ${styles["mt-2"]}`}>
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-4"]}`}
                    >
                      <div className={styles["form-check"]}>
                        <input
                          className={styles["form-check-input"]}
                          type="radio"
                          name="estudio"
                          id="estudio1"
                          value="Y"
                          {...register("STUDENT", {
                            maxLength: 1,
                          })}
                          disabled={edit}
                        />
                        <label
                          className={styles["form-check-label"]}
                          htmlFor="estudio1"
                        >
                          Si
                        </label>
                      </div>
                    </div>
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-4"]}`}
                    >
                      <div className={styles["form-check"]}>
                        <input
                          className={styles["form-check-input"]}
                          type="radio"
                          name="estudio"
                          id="estudio2"
                          value="N"
                          defaultChecked
                          {...register("STUDENT", {
                            maxLength: 1,
                          })}
                          disabled={edit}
                        />
                        <label
                          className={styles["form-check-label"]}
                          htmlFor="estudio2"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Fila 4 --> */}
              <div className={styles["row"]}>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    Adjuntar identificacion hijo {watch("RELATIONSHIP")=="C" ? <span className={styles["color-red"]}>*</span> : null}
                  </label>
                  <input
                    type="file"
                    className={styles["form-control"]}
                    aria-label="Adjuntar certificado"
                    id="file"
                    onChange={(e) => {
                      if (
                        e.target.files[0]?.type === "image/jpeg" ||
                        e.target.files[0]?.type === "image/jpg" ||
                        e.target.files[0]?.type === "application/pdf" ||
                        e.target.files[0]?.type === "image/png"
                      ) {
                        if (e.target.files[0].size <= 5000000) {
                          setValue("attachment", e.target?.files, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                        } else {
                          Swal.fire({
                            allowOutsideClick: false,
                            position: "center",
                            title:
                              '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                            html: '<div style="text-align: center; font-size: 15px">El tamaño del Certificado debe ser menor a 5Mb</div>',
                            confirmButtonColor: "#11427C",
                          });
                        }
                      } else {
                        Swal.fire({
                          allowOutsideClick: false,
                          position: "center",
                          title:
                            '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                          html: '<div style="text-align: center; font-size: 15px">Debe ingresar uno de los tipos de archivos permitidos</div>',
                          confirmButtonColor: "#11427C",
                        });
                      }
                    }}
                    formcontrolname="file"
                    name="file"
                    accept=".pdf, .jpeg, .jpg, .png"
                  />
                  <small className={styles["form-error-message"]}>{errorAttachment}</small>
                </div>
              </div>
              {/* <!-- Fila 5 --> */}
              <div className={styles["row"]}>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    Afiliado al Seguro{" "}
                  </label>
                  <div className={`${styles["row"]} ${styles["mt-2"]}`}>
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-4"]}`}
                    >
                      <div className={styles["form-check"]}>
                        <input
                          className={styles["form-check-input"]}
                          type="radio"
                          name="seguro"
                          id="seguro1"
                          value="Y"
                          {...register("CN_AFILIADO_EPS", {
                            required: "* Campo obligatorio",
                            maxLength: 1,
                          })}
                          disabled={edit}
                        />
                        <label
                          className={styles["form-check-label"]}
                          htmlFor="seguro1"
                        >
                          Si
                        </label>
                      </div>
                    </div>
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-4"]}`}
                    >
                      <div className={styles["form-check"]}>
                        <input
                          className={styles["form-check-input"]}
                          type="radio"
                          name="seguro"
                          id="seguro2"
                          value="N"
                          {...register("CN_AFILIADO_EPS", {
                            required: "* Campo obligatorio",
                            maxLength: 1,
                          })}
                          disabled={edit}
                          defaultChecked
                        />
                        <label
                          className={styles["form-check-label"]}
                          htmlFor="seguro2"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    Depende Económicamente del Empleado{" "}
                  </label>
                  <div className={`${styles["row"]} ${styles["mt-2"]}`}>
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-4"]}`}
                    >
                      <div className={styles["form-check"]}>
                        <input
                          className={styles["form-check-input"]}
                          type="radio"
                          name="depende"
                          id="depende1"
                          value="Y"
                          {...register("CN_DEPEND_FLAG", {
                            required: "* Campo obligatorio",
                            maxLength: 1,
                          })}
                          disabled={edit}
                        />
                        <label
                          className={styles["form-check-label"]}
                          htmlFor="depende1"
                        >
                          Si
                        </label>
                      </div>
                    </div>
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-4"]}`}
                    >
                      <div className={styles["form-check"]}>
                        <input
                          className={styles["form-check-input"]}
                          type="radio"
                          name="depende"
                          id="depende2"
                          value="N"
                          {...register("CN_DEPEND_FLAG", {
                            required: "* Campo obligatorio",
                            maxLength: 1,
                          })}
                          disabled={edit}
                          defaultChecked
                        />
                        <label
                          className={styles["form-check-label"]}
                          htmlFor="depende2"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {
                flagsParentesco == 'C' && newData ==true ?
                (<></>)
                :
                (
                  newData ? <>
                  {/* <!-- Fila 6 --> */}
              <div className={styles["row"]}>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    Contacto de Emergencia
                  </label>
                  <div className={`${styles["row"]} ${styles["mt-2"]}`}>
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-4"]}`}
                    >
                      <div className={styles["form-check"]}>
                        <input
                          className={styles["form-check-input"]}
                          type="checkbox"
                          name="EMERGENCY_CONTACT"
                          id="EMERGENCY_CONTACT"
                          disabled={edit}
                          value="Y"
                          defaultChecked={info.EMERGENCY_CONTACT == "N"}
                          {...register("EMERGENCY_CONTACT")}
                        />

                        <label
                          className={styles["form-check-label"]}
                          htmlFor="emergencia1"
                        >
                          Hacer contacto de Emergencia
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Fila 7 --> */}
              <div
                className={`${styles["row"]} ${
                  watch("EMERGENCY_CONTACT") === "Y" ? null : styles["d-none"]
                }`}
              >
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    Teléfono <span className={styles["color-red"]}>*</span>
                  </label>
                  <input
                    type="text"
                    className={styles["form-control"]}
                    {...register("TEL_PHONE", {
                      required:
                        watch("EMERGENCY_CONTACT") !== "Y"
                          ? false
                          : "* Campo obligatorio",
                      maxLength: 24,
                    })}
                    disabled={edit}
                  />
                </div>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>Celular</label>
                  <input
                    type="text"
                    className={styles["form-control"]}
                    {...register("CEL_PHONE", { maxLength: 24 })}
                    disabled={edit}
                  />
                </div>
              </div>
              {/* <!-- Fin filas Datos Adicionales --> */}
                  </> : null
                )
               
              }
              
            </form>
            <div
              className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mt-4"]}`}
            >
              {info.CN_APPROVAL_STATUS == "P" ? (
                <></>
              ) : (
                newData == false && (
                  <>
                    <div
                      className={`${styles["col-6"]} ${styles["col-md-auto"]}`}
                    >
                      <button
                        type="submit"
                        className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`}
                        onClick={deleteContact}
                      >
                        Eliminar{" "}
                      </button>
                    </div>
                    <div
                      className={`${styles["col-6"]} ${styles["col-md-auto"]}`}
                    >
                      {edit == false ? (
                        <></>
                      ) : (
                        <button
                          type="submit"
                          className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`}
                          onClick={() => {
                            setEditG(false);
                            setEdit(false);
                            // changeDisplayContact(true, idContact, infoContactEmergency)
                          }}
                        >
                          Modificar{" "}
                          <i
                            className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                            aria-hidden="true"
                          ></i>
                        </button>
                      )}
                    </div>
                  </>
                )
              )}
              <div className={`${styles["col-6"]} ${styles["col-md-auto"]}`}>
                <button
                  type="button"
                  onClick={() => {
                    setNameContact("");
                    close(edit ? false : true);
                  }}
                  className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`}
                >
                  Cancelar{" "}
                  <i
                    className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
              <div
                className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mt-4"]}`}
              >
                <div className={`${styles["col-12"]} ${styles["col-md-auto"]}`}>
                  {/* <button type="button" className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["w-100"]}`} onClick={handleSubmit(onSubmit)} disabled={loadingAddContactEmergency}>Guardar {loadingAddContactEmergency ? <div className="spinner-border spinner-border-sm"></div> : <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i>}</button> */}
                  <button
                    type="button"
                    className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["w-100"]}`}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Fin Agregar --> */}
        </div>
      )}
    </>
  );
};

export default ContactoFamiliar;
