import React, { useContext } from "react";
import { Context } from "../../../../../context";
import { ContextInterface } from "../../../../../context/utilities/interfaces";
interface Props {
    postStatus: { status:string }|false;
    nextStep: () => void;
    token: string;
}

const SubmitModal: React.FC<Props> = ({ postStatus, nextStep, token }) => {

    const { signOut, showNewsletterButton } = useContext<ContextInterface | null>(Context) as ContextInterface;

    return (
        <>
            <button hidden id="openThanksModal" data-bs-target="#thanks" data-bs-toggle="modal" data-bs-backdrop="static" data-bs-keyboard="false" />
            <div className="modal fade show" id="thanks" tabIndex={-1} aria-labelledby="thanks" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        {postStatus ? (
                            postStatus.status === '0' ?
                                <>
                                    <div className="modal-header border-0 pb-0">
                                        <button onClick={async () => {
                                            if (showNewsletterButton && token !== "") nextStep();
                                            else signOut();
                                        }} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body text-center py-4">
                                        {showNewsletterButton && token !== "" ? 
                                            <>
                                                <h2 className="h2 fw-normal tit-section tit-section-center">Cuéntanos más acerca de ti</h2>
                                                <div className="fs-5">
                                                    <p>Muchas gracias por completar la ficha de inscripción.</p>
                                                    <p>
                                                        Antes de finalizar tu incorporación
                                                        quisieramos invitarte a unirte a nuestras
                                                        suscripciones y contestar unas preguntas, que
                                                        nos permitirán conocerte un poco más.
                                                    </p>
                                                </div>
                                            </>
                                                :
                                            <>
                                                <h2 className="h2 fw-normal tit-section tit-section-center">Gracias</h2>
                                                <div className="fs-5">
                                                    <p>Muchas gracias por finalizar la ficha de inscripción</p>
                                                    <p>Su información será revisada por el área de RR.HH.</p>
                                                    <p>Posterior a este proceso te va llegar un correo dándole la bienvenida a Cencosud.</p>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button"
                                            onClick={(e) => {
                                                if (showNewsletterButton) nextStep();
                                                else signOut();
                                            }}
                                            className="btn btn-primary px-5" data-bs-dismiss="modal">
                                            Continuar
                                            <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                                        </button>
                                    </div>
                                </>
                                :
                                <div className="modal-body text-center py-4">
                                    <h2 className="h2 fw-normal tit-section tit-section-center">Lo sentimos</h2>
                                    <div className="fs-5">
                                        <p>{"Ha ocurrido un problema al intentar guardar la información, intentalo mas tarde."}</p>
                                </div>
                                <div className=" mt-5 d-flex justify-content-center">
                                    <button type="button"
                                        className="btn btn-primary px-5" data-bs-dismiss="modal">
                                        <i className="fa fa-chevron-left fa-fw fa-xs" aria-hidden="true" />
                                        Cerrar
                                    </button>
                                </div>
                                </div>
                        ) :
                            <>
                                <div className="modal-body text-center py-4">
                                    <h2 className="h2 fw-normal tit-section tit-section-center">Gracias</h2>
                                    <div className="fs-5">
                                        <p>{"Su información está siendo procesada, esta acción puede tardar unos segundos."}</p>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
};

export default SubmitModal;
