import React, { FC, useEffect, useState } from "react";
import { DownloadDocumentProps, HttpError, PostAnnualBond, ResponsePostDocument } from "../../utilities/interfaces";
import { RemoteData } from "@devexperts/remote-data-ts";
import * as RD from "@devexperts/remote-data-ts";
import { getPostDocument } from "../../utilities/helpers/setData";
import ModalDownloadingAnnualBond from "../modals/modalDownloadingAnnualBond";

interface PostProps {
    setRemoteData: React.Dispatch<React.SetStateAction<RemoteData<HttpError, ResponsePostDocument>>>;
    body: PostAnnualBond;
}

const sendData = ({ setRemoteData, body }: PostProps): void => {
    getPostDocument({ setRemoteData: setRemoteData, body: body })
};

const downloadDocument = (file: string, period: string, setDownloaded: React.Dispatch<React.SetStateAction<boolean>>): void => {
    const fileName = `Detalle_Bono_Anual_${period}`;
    const archivo = Buffer.from(file, "base64");
    let link = document.createElement("a");
    let blob = new Blob([archivo], { type: "application/pdf" });
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.dispatchEvent(new MouseEvent("click"));
    setDownloaded(true);
};

const DownloadDocument: FC<DownloadDocumentProps> = ({ assignations, selectedModel, setSuccess, nameDisplay, period, allManagement }) => {
    const [remoteData, setRemoteData] = useState<RemoteData<HttpError, ResponsePostDocument>>(RD.initial);
    const [downloaded, setDownloaded] = useState(false);
    const [downloadingError, setDownloadingError] = useState(false);

    useEffect(() => {
        remoteData._tag === "RemotePending" && document.getElementById("downloadingAnnualBondModal")?.click();
        remoteData._tag === "RemoteSuccess" && downloadDocument(remoteData.value.response.file, period, setDownloaded);
    }, [remoteData]);

    useEffect(() => {
        downloaded && document.getElementById("closeDownloadingAnnualBondModal")?.click();
    }, [downloaded])

    return (
        <div className="box mb-4">
            <h2 className="h4 tit-section">Plataforma de Gestión Bono Anual</h2>
            <div className="rounded-3 bg-light p-3 color-primary mb-2">
                <p>{`${nameDisplay}:`}</p>
                <ul>
                    {allManagement ?
                        <>
                            <li>Has seleccionado el <strong>{selectedModel}</strong> para toda tu Gerencia.</li>
                            <li>A continuación, podrás descargar un resumen de la asignación realizada</li>
                        </>
                    :
                        <>
                            <li>Has seleccionado el/los modelo(s) para tus reportes y áreas.</li>
                            <li>A continuación, podrás descargar un resumen de la asignación realizada.</li>
                        </>
                    }
                    <a className="btn btn-outline-primary btn-lg my-3" href="#" onClick={(e) => {
                        e.preventDefault();
                        setDownloaded(false);
                        sendData({ setRemoteData: setRemoteData, body: { request: { asignations: assignations, emplid: "" } } })
                    }}>
                        <i className="fa fa-file-pdf-o fa-fw" aria-hidden="true" />
                        {" Descargar Resumen "}
                        {downloaded && <i className="fa-sharp fa-solid fa-circle-check" />}
                    </a>
                    <li>
                        <strong>
                            Para finalizar, presiona el botón <u>Enviar Cartas</u>{` para informar a los colaboradores el modelo por el cual serán evaluados respecto al Bono Anual Ejercicio Año ${parseInt(period)} – Pago Año ${parseInt(period) + 1}.`}
                        </strong>
                    </li>
                </ul>
            </div>
            <div className="d-grid d-md-flex justify-content-md-between gap-2 flex-nowrap mt-5">
                <button id={"chad"} type="button" className="btn btn-degradado order-md-last" onClick={() => document.getElementById("modal-warning-btn")?.click()}>
                    {"Enviar Cartas "}
                    <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                </button>
                <button type="button" className="btn btn-link order-md-first" onClick={() => setSuccess({ valid: 2, message: "" })}>Volver</button>
            </div>
            <ModalDownloadingAnnualBond downloadingError={downloadingError} setDownloadingError={setDownloadingError} />
        </div>
    )
};

export default DownloadDocument;
