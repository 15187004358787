import React from "react";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Context } from "../../context";
import { findMenuItemsByPath } from "../commonComponents/Helpers/findMenuItemsByPath";
import { BreadCrumbsDinamic } from "../commonComponents/BreadCrumbsDinamic";
import TitleCmp from "../commonComponents/TitleCmp";

export const HeaderPages = ({ title }) => {
  const { menuItems } = useContext(Context);
  const path = useLocation().pathname;
 
  const menuItemWithParents = findMenuItemsByPath(menuItems, path);



   const titleToUse = title || menuItemWithParents[menuItemWithParents.length-1].label;
  return (
    <>
       <BreadCrumbsDinamic menuItemWithParentsParam={menuItemWithParents} /> 
      <TitleCmp title={titleToUse} />
    </>
  );
};

export  default HeaderPages;