import React, { Children, FC } from "react";
import { updateAt } from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { SelectModelTableProps } from "../../../../utilities/interfaces";

const SelectModelTable: FC<SelectModelTableProps> = ({ directReports, models, hasKpiByReport, setHasKpiByReport, modelCheckedPerReport, setModelCheckedPerReport, everyoneUnder, setEveryoneUnder, preview, lastAssignation }) => {
    return (
        <>
            <div className="ratio ratio-21x9 d-none d-md-block">
                <div className="table-responsive">
                    <table className="table table-section table-borderless table-th-border align-middle table-even">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Cargo</th>
                                <th className="text-center">Modelo</th>
                                <th className="text-center">Área</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Children.toArray(directReports.map((report, reportIndex) => {
                                return (
                                    <tr>
                                        <td>{report.name}</td>
                                        <td>{report.jobCodeDescr}</td>
                                        <td className="text-nowrap">
                                            {preview ?
                                                models.find((m) => m.modelId === modelCheckedPerReport[reportIndex])?.modelDescr
                                            :
                                                <div>
                                                    {Children.toArray(models.map((model) => {
                                                        return (
                                                            <div className="col-6">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        id={`${model.modelId}-${reportIndex}`}
                                                                        onChange={() => {
                                                                            modelCheckedPerReport[reportIndex] !== model.modelId && (
                                                                                pipe(hasKpiByReport, updateAt(reportIndex, model.modelContainsKpi), O.getOrElse(() => hasKpiByReport), setHasKpiByReport),
                                                                                pipe(modelCheckedPerReport, updateAt(reportIndex, model.modelId), O.getOrElse(() => modelCheckedPerReport), setModelCheckedPerReport)
                                                                            )
                                                                        }}
                                                                        checked={modelCheckedPerReport[reportIndex] === model.modelId}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`${model.modelId}-${reportIndex}`}>{model.modelDescr}</label>
                                                                </div>
                                                            </div>
                                                        )
                                                    }))}
                                                </div>
                                            }
                                        </td>
                                        <td className="text-nowrap">
                                        {preview ?
                                            everyoneUnder[reportIndex] && "Aplicar a toda su área"
                                        :
                                            <>
                                                <input
                                                    className="form-check-input me-2 h5"
                                                    type="checkbox"
                                                    onChange={() => {
                                                        pipe(everyoneUnder, updateAt(reportIndex, !everyoneUnder[reportIndex]), O.getOrElse(() => everyoneUnder), setEveryoneUnder);
                                                    }}
                                                    checked={everyoneUnder[reportIndex]}
                                                    readOnly={lastAssignation}
                                                    disabled={lastAssignation}
                                                />
                                                Aplicar a toda su área
                                            </>
                                        }
                                        </td>
                                    </tr>
                                )
                            }))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
                {Children.toArray(directReports.map((report, reportIndex) => {
                    return (
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#accordionM-${reportIndex}`} aria-expanded={reportIndex === 0} aria-controls={`#accordionM-${reportIndex}`}>
                                    <strong className="text-uppercase">Nombre</strong>{` ${report.name}`}
                                </button>
                            </h2>
                            <div id={`accordionM-${reportIndex}`} className={`accordion-collapse collapse${reportIndex === 0 ? " show" : ""}`} aria-labelledby={`accordionM-${reportIndex}`}>
                                <div className="accordion-body p-0">
                                    <div className="data-list-box">
                                        <ul className="data-list-section--item list-unstyled">
                                            <li>
                                                <span className="data-list-box__title">Cargo</span>
                                                <span className="data-list-box__text">{report.jobCodeDescr}</span>
                                            </li>
                                            <li>
                                                <span className="data-list-box__title">Modelo</span>
                                                <span className="data-list-box__text">
                                                    {preview ?
                                                        models.find((m) => m.modelId === modelCheckedPerReport[reportIndex])?.modelDescr
                                                    :
                                                        Children.toArray(models.map((model) => {
                                                            return (
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        id={`${model.modelId}-${reportIndex}`}
                                                                        onChange={() => {
                                                                            modelCheckedPerReport[reportIndex] !== model.modelId && (
                                                                                pipe(hasKpiByReport, updateAt(reportIndex, model.modelContainsKpi), O.getOrElse(() => hasKpiByReport), setHasKpiByReport),
                                                                                pipe(modelCheckedPerReport, updateAt(reportIndex, model.modelId), O.getOrElse(() => modelCheckedPerReport), setModelCheckedPerReport)
                                                                            )
                                                                        }}
                                                                        checked={modelCheckedPerReport[reportIndex] === model.modelId}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`${model.modelId}-${reportIndex}`}>{model.modelDescr}</label>                                                                        
                                                                </div>
                                                            )
                                                        }))
                                                    }
                                                </span>
                                            </li>
                                            <li>
                                                <span className="data-list-box__title">Área</span>
                                                <span className="data-list-box__text">
                                                {preview ?
                                                    everyoneUnder[reportIndex] && "Aplicar a toda su área"
                                                :
                                                    <>
                                                        <input
                                                            className="form-check-input me-2 h5"
                                                            type="checkbox"
                                                            onChange={() => {
                                                                pipe(everyoneUnder, updateAt(reportIndex, !everyoneUnder[reportIndex]), O.getOrElse(() => everyoneUnder), setEveryoneUnder);
                                                            }}
                                                            checked={everyoneUnder[reportIndex]}
                                                        />
                                                        Aplicar a toda su área
                                                    </>
                                                }
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }))}
            </div>
        </>
    )
};

export default SelectModelTable;