import Tippy from "@tippyjs/react";
import React from "react";

export const DropdowCmp = ({label,callback,stateValue, valueList,tippy,textTippy, placeholder='Escoger...', className='', disabled=false, required=true}) => {
  return (
    <div className={ className }>
      <label className="form-label fw-bold">
        {label}
        {tippy && (
          <Tippy content={textTippy}>
            <i
              className="fa fa-question fa-fw fa-lg color-orange"
              aria-hidden="true"
            ></i>
          </Tippy>
        )}
      </label>
      <select
        className="form-select"
        value={stateValue}
        onChange={(e) =>
            callback(e.target.value)
        }
        required={ required }
        type="select"
        defaultValue={""}
        disabled={disabled}
      >
        <option disabled value="">
          { placeholder }
        </option>
        {valueList.map((item, i) => {
          return (
            <option key={i} value={item.value}>
              {item.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};
