import { useProfileSummaryProvider } from "../../context/EmployeeProfileSummaryContext";
import { useTableProvider } from "../../context/DashboardTableContext";

export const EmployeeDetailCard = () => {
  const { summaryEmployee } = useProfileSummaryProvider();
  const { profileSummarySelected } = useTableProvider();

  if (summaryEmployee.isLoading)
    return (
      <div className="col-12 col-xl-5">
        <div data-testid="loading" className="full-spinner">
          <div className="spinner-border text-primary"></div>
        </div>
      </div>
    );
    
  return (
    <div className="col-12 col-xl-5 d-flex">
      <div className="box align-items-stretch w-100">
        <div className="data-list-box text-uppercase">
          <ul className="list-unstyled">
            <li className="justify-content-center border-0">
              <img
                src={profileSummarySelected.photo}
                alt="foto colaborador"
                className="rounded-circle img-fluid mb-2"
                width="160"
                height="160"
              />
            </li>
            {summaryEmployee.data.personalData.map(
              (data) =>
                !data.hiddeAttribute && (
                  <li key={data.id}>
                    <span className="data-list-box__title col-12 col-sm-6">
                      {data.keyLabel}
                    </span>
                    <span className="data-list-box__text">{data.KeyValue}</span>
                  </li>
                )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};
