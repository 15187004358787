import React, { FC, useEffect, useRef, useState } from "react";
import { AdminCategoryProps, bodyPostNewsList, CategoryProps, DataModalDinamicProps, PostNews, ResponseCategoryList } from "../../../utilities/interfaces";
import ModalAsignCategory from "./components/modalAsig";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import * as RD from "@devexperts/remote-data-ts";
import { RemoteData } from "@devexperts/remote-data-ts";
import { httpClientEnv } from "../../../../../services/apiServices/httpClient";
import { HttpError } from "../../../../../services/apiServices/interfaces";
import { crudCategoryNews, getCategoryNewsList } from "../../../utilities/apiServices/apiRequest";
import TableCategory from "./components/tableCategory/tableCategory";
import { SpinerFullScreen } from "../../../../commonComponents/SpinerFullScreen";
import DynamicSelect from "../../../commons/DynamicSelect";
import ModalInfoDinamic from "../../../../commonComponents/ModalnfoDinamic";
import ModalLoad from "../../../../commonComponents/ModalLoad";
import ModalConfirmAction from "../../../../commonComponents/ModalConfirmAction";

const AdminCategory: FC<AdminCategoryProps> = ({
    setAdminCategory,
    setShowTable,
    refConfirmAction,
    refLoad,
    refLoadClose,
    refStatus,
    pais
}) => {
    const [isEditCategory, setIsEditCategory] = useState(false);
    const [categoryList, setCategoryList] = useState<RemoteData<HttpError, ResponseCategoryList>>(RD.initial);
    const [countryFilter, setCountryFilter] = useState(pais || "");
    const [filterData, setFilterData] = useState<CategoryProps[]>([]);
    const [remoteDeleteCatNews, setRemoteDeleteCatNews] = useState<RemoteData<HttpError, PostNews>>(RD.initial);
    const refCloseStatus = useRef<HTMLButtonElement>(null);
    const [reload, setReload] = useState(false);
    let defaultPropsEdit = {
        id: 0,
        description_country: '',
        title: '',
        icon: '',
        country_id: '',
    }
    const [editProps, setEditProps] = useState<CategoryProps>(defaultPropsEdit)
    const [dataModal, setDataModal] = useState<DataModalDinamicProps>({
        callBack: () => null,
        icon: '',
        msg: ''
    })

    const GetNewsList = async () => RTE.run(await getCategoryNewsList(), httpClientEnv)
        .then(E.fold(e => { return setCategoryList(RD.failure(e)) }, a => {
            let data = RD.success(a)
            if (data._tag === 'RemoteSuccess') {
                return setCategoryList(RD.success(a))
            } else {
                return setCategoryList(RD.failure({ tag: 'httpRequestError', error: 'GetNewsList' }))
            }
        }));

    const DeleteCategoryNews = async () => {
        let body: bodyPostNewsList = {
            id: editProps.id
        }
        RTE.run(await crudCategoryNews(body, 'DELETE'), httpClientEnv)
            .then(E.fold(e => { return setRemoteDeleteCatNews(RD.failure(e)) }, a => {
                let data = RD.success(a)
                if (data._tag === 'RemoteSuccess' && data.value.valid) {
                    refStatus && refStatus.current && refStatus.current.click();
                    setDataModal({
                        msg: 'Categoría eliminada correctamente.',
                        icon: 'fa-check-circle-o',
                        callBack: () => {
                            refCloseStatus && refCloseStatus.current && refCloseStatus.current.click();
                            setReload(true)
                        }
                    })
                    return setRemoteDeleteCatNews(RD.success(a))
                } else {
                    return setRemoteDeleteCatNews(RD.failure({ tag: 'httpRequestError', error: 'Valid DeleteCategoryNews' }))
                }
            }))
    };

    useEffect(() => {
        GetNewsList();
        if (reload) {
            GetNewsList();
            setReload(false)
        }
    }, [reload])

    useEffect(() => {
        if (categoryList._tag === 'RemoteSuccess') {
            let filters = categoryList.value.response.categories.filter((category) => {
                const status = countryFilter ? countryFilter === category.country_id : category;
                return status
            })
            setFilterData(filters)
        }
    }, [categoryList, countryFilter])

    useEffect(() => {
        if (remoteDeleteCatNews._tag === 'RemoteFailure') {
            refStatus && refStatus.current && refStatus.current.click();
            setDataModal({
                msg: 'Ha ocurrido un error al intentar eliminar la categoría. Por favor, revise si no está siendo utilizada por otro noticia o intente más tarde.',
                icon: 'fa-exclamation-triangle',
                callBack: () => {
                    refCloseStatus && refCloseStatus.current && refCloseStatus.current.click();
                }
            })
        }
    }, [remoteDeleteCatNews])

    return (
        <>
            <section className="gestion-de-noticias">
                <h1>Gestión de Noticias</h1>
                <div className="box mb-4">
                    <div className="tit-group d-flex justify-content-between">
                        <h2 className="h4 tit-section">Configuración de Categorías</h2>
                        <a href="#" onClick={() => {
                            setIsEditCategory(false);
                        }} className="mt-2 text-decoration-none" data-bs-toggle="modal" data-bs-target="#asignacion"><span className="fw-bold d-none d-md-inline-block">Crear categoría</span> <i className="fa fa-plus-square-o fa-lg ms-2" aria-hidden="true"></i></a>
                    </div>

                    <div className="row w-auto">
                        <div className="col-12 col-md-3 ms-auto mb-3">
                            <label className="form-label">País</label>
                            <div className="input-group">
                                {categoryList._tag === 'RemoteSuccess' && categoryList.value.response.countryFilter.length > 0 ?
                                    <>
                                        <DynamicSelect arrayData={categoryList.value.response.countryFilter} setStatus={setCountryFilter} isLabel={false} pais={pais} />
                                    </>
                                    :
                                    <>
                                        <select className="form-select" disabled>
                                            <option selected disabled>Seleccione</option>
                                        </select>
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                    {categoryList._tag === 'RemoteSuccess' ?
                        <TableCategory setIsEditCategory={setIsEditCategory} setEditProps={setEditProps} categoryList={filterData} refConfirmAction={refConfirmAction} />
                        : categoryList._tag === 'RemoteInitial' || categoryList._tag === 'RemotePending' ?
                            <SpinerFullScreen />
                            : categoryList._tag === 'RemoteFailure' &&
                            <div>
                                <p>Ha ocurrido un error al guardar información. Por favor, intente más tarde.</p>
                            </div>
                    }
                </div>
                <div className="d-grid d-md-flex justify-content-md-between gap-2 flex-nowrap">
                    <button type="button" className="btn btn-link order-md-first" onClick={() => { setAdminCategory(false); setShowTable(true) }}> Volver </button>
                </div>
            </section>

            <ModalAsignCategory
                refLoad={refLoad}
                setDataModal={setDataModal}
                refLoadClose={refLoadClose}
                refStatus={refStatus}
                editProps={editProps}
                setReload={setReload}
                countryFilters={categoryList._tag === 'RemoteSuccess' ? categoryList.value.response.countryFilter : []}
                setEditProps={setEditProps}
                defaultPropsEdit={defaultPropsEdit}
                isEditCategory={isEditCategory}
                pais={pais}
                refCloseStatus={refCloseStatus}
            />

            <ModalInfoDinamic
                refOpen={refStatus}
                icon={dataModal.icon}
                text={dataModal.msg}
                callBack={dataModal.callBack}
                refClose={refCloseStatus}
            />

            <ModalLoad text="Cargando información..." refOpen={refLoad} refClose={refLoadClose} data_target="modalLoadCategory" />
            <ModalConfirmAction refOpen={refConfirmAction} text={`¿Estás seguro que deseas eliminar?`} callBack={() => { DeleteCategoryNews(); }} />
        </>
    )
}
export default AdminCategory;