import React, { useState, useEffect, Children } from "react";
import { Link } from "react-router-dom";
import * as RD from "@devexperts/remote-data-ts";
import { Collaborator, Fault, InputFile, NameCollaborator, EvidenceWarningLetter } from "../../utilities/interfaces";
import { postWarningLetter } from "../../utilities/apiServices/apiRequet";
import SearchBar from "../../commons/searchBar";
import FileUpload from "../../commons/fileUpload";
import { dotPerc } from "../../../../../services/utiles";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import { getWarningLetter } from "../../utilities/apiServices/apiRequet";
import { httpClientEnv } from "../../../../../services/apiServices/httpClient";
import { Base64 } from "js-base64";
import { urlsAws } from "../../../../../resources/foo/api-endpoints";
import ColaboradorCard from "../../commons/colaboratorCard";
import miEquipoService from "../../../../../services/mi-equipo-service";

const deleteFile = (index: number) => {
    const dt = new DataTransfer();
    const input = (document.getElementById(`files${index}`) as HTMLInputElement);
    const fileList = input?.files;

    if (fileList !== null && fileList!==undefined) {
        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i]
            if (0 !== i)
                dt.items.add(file);
        }
        input.files = dt.files;
    }
};

const fileToBase64 = async(file: File) =>{
    return Base64.fromUint8Array(new Uint8Array(await file.arrayBuffer()))
}
interface WarningProps {
    collaborators: NameCollaborator[];
    setWarningLetter: React.Dispatch<React.SetStateAction<boolean>>;
    collaboratorEmplid: string;
    fromEquipo: boolean;
    setFromEquipo: React.Dispatch<React.SetStateAction<boolean>>;
    setCollaboratorEmplid: React.Dispatch<React.SetStateAction<string>>;
}
interface PostWarningLetterProps{
    emplid: string,
    reasonCode: string,
    comments: string,
    evidence: EvidenceWarningLetter[]
}

export interface PhotoProps{
    emplid: string,
    photo: string,
    valid: string,
    status: string
}

const Warning: React.FC<WarningProps> = ({ setWarningLetter, collaborators, setCollaboratorEmplid, collaboratorEmplid, fromEquipo, setFromEquipo }) => {
    const [collaborator, setCollaborator] = useState<Collaborator | null>(null);
    const [photos, setPhotos] = useState<PhotoProps[]>([]);
    const [selectedFault, setSelectedFault] = useState<string>("");
    const [files, setFiles] = useState<InputFile[]>([{ file: null, index: 0 }]);
    const [queriedEmplid, setQueriedEmplid] = useState("");
    const [faults, setFaults] = useState<Fault[]>([]);
    const [comments, setComments] = useState<string>("");
    const totalSize = files.reduce((x, y) => y.file !== undefined && y.file !== null ? x + y.file.size : x, 0);
    const [arrayFiles, setArrayFiles] = useState<EvidenceWarningLetter[]>([])
    const [statusPost, setStatusPost] = useState('')
    const [failed, setFailed]= useState(false)
    const [errorPost, setErrorPost] = useState(false);
    useEffect(()=>{
        if(failed){
            document.getElementById("openModalSearch")?.click();
            setFailed(false)
        }
    },[failed])

    useEffect(() => {
        collaboratorEmplid !== "" && (
            (async () => {
                const resp = await RTE.run(await getWarningLetter(`?emplid=${collaboratorEmplid}`), httpClientEnv)
                    .then(E.fold(e => { return RD.failure(e) }, a => { return RD.success(a) }));
                if(resp._tag === 'RemoteSuccess' && resp.value.response.valid){
                    setCollaborator({
                        byEmplid: {
                            valid: resp.value.response.valid,
                            status: resp.value.response.status,
                            fullName: resp.value.response.fullName,
                            company: resp.value.response.company,
                            location: resp.value.response.location,
                            postition: resp.value.response.postition,
                            emplid: resp.value.response.emplid,
                        }
                    })
                    setFaults(resp.value.response.reasons);
                }else{
                    setFailed(true)
                }
                
                setQueriedEmplid(collaboratorEmplid);
                dataCachePhotos(collaboratorEmplid.toLocaleUpperCase());
                resp._tag === "RemoteFailure" && setCollaborator(null);
            })()
        )
    }, [collaboratorEmplid]);

    useEffect(() => {
        if(queriedEmplid !== ""){
            setSelectedFault("");
            setComments("");
            setFiles([{ file: null, index: 0 }]);
            deleteFile(0);
        }

        return () => {
            setSelectedFault("");
            setComments("");
            setFiles([{ file: null, index: 0 }]);
            deleteFile(0);
            setPhotos([]);
        }
    }, [queriedEmplid]);


    const dataCachePhotos = async (request: string) => {
        const getFoto = async () => {
            await caches.match(urlsAws.getPhotosEquipo + request).then((response) => {
                if (response) {
                    response.json().then((data) => {
                        if(data){
                            setPhotos([data]);
                        }
                    });
                }else {
                    miEquipoService.getPhotos(setPhotos, [request]);
                }
            })
        };
        getFoto();
    };

    useEffect(()=>{
        if( files.length>0) {
            let arrayF: EvidenceWarningLetter[]= []
            files.map(async(f,i)=>{
                if(f.file!==null && f.file !== undefined && f.file.name !== undefined){
                    const ext = f.file.name.substring(f.file.name.lastIndexOf('.') + 1);
                    const name = f.file.name.substring(0, f.file.name.lastIndexOf('.'));              
                    arrayF.push({
                        title:name,
                        extension:'.'+ext,
                        file: await fileToBase64(f.file)
                    })
                }
            })
            setArrayFiles(arrayF)
        }
    },[files])

    const onSubmitPost = async(data:PostWarningLetterProps) => {
        document.getElementById("sendingInfo")?.click();

        const body = {
            emplid: data.emplid,
            reasonCode: data.reasonCode,
            comments: data.comments,
            evidence: data.evidence
        }
        
        const resp = await RTE.run(await postWarningLetter({request:body}), httpClientEnv)
                    .then(E.fold(e => { return RD.failure(e) }, a => { return RD.success(a) }));
        
        document.getElementById("closeSendingInfo")?.click();
        if (resp._tag === 'RemoteSuccess' && resp.value.response?.valid) {
            document.getElementById("openModalPost")?.click();
            setStatusPost(resp.value.response.status);
        } else {
            setErrorPost(true);
            document.getElementById("openModalPost")?.click();
            let statusMsg = (resp._tag === 'RemoteSuccess') ? resp.value.response.status : 'Ha ocurrido un problema, por favor intente más tarde.';
            setStatusPost(statusMsg);
        }
        
    }

    const cleanInputs = () =>{
        setSelectedFault('');
        setFiles([{ file: null, index: 0 }]);
        setArrayFiles([]);
        setComments('');
        files.map((file,i)=>{
            (document.getElementById('files'+i) as HTMLInputElement).value = ''
        });
        setCollaborator(null);
        setFromEquipo(false);
        setPhotos([]);
    }

    return (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/inicio"><i className="fa fa-home" aria-hidden="true" />{" Inicio"}</Link></li>
                    <li className="breadcrumb-item">
                        <Link to="#" onClick={(e) => { 
                            e.preventDefault(); 
                            setWarningLetter(false); 
                            if(fromEquipo){
                                setFromEquipo(false)
                                setCollaboratorEmplid('')
                            }
                        }}>Mi Equipo</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Solicitud de Amonestación</li>
                </ol>
            </nav>
            <section className="carta-de-amonestacion">
                <h1>Carta de Amonestación</h1>
                {!fromEquipo && collaboratorEmplid==='' && <div className="box mb-4">
                    <h2 className="h4 tit-section">Buscar Colaborador</h2>
                    <div className="row">
                        <div className="col-md-8">
                            <SearchBar data={collaborators} setPhotos={setPhotos} placeholder={"Por nombre de reporte directo o rut colaborador..."} setCollaborator={setCollaborator} setQueriedEmplid={setQueriedEmplid} setFaults={setFaults} setFailed={setFailed}/>
                        </div>
                    </div>
                </div>}
                <div className="row mb-4">
                    <ColaboradorCard collaborator={collaborators} photos={photos} emplidColab={collaborator && collaborator?.byEmplid?.emplid!==undefined ? collaborator?.byEmplid?.emplid : ''}/>
                    <div className="col-12 col-xl-8 d-flex">
                        <div className="box align-items-stretch w-100">
                            <h2 className="h4 tit-section">Solicitar</h2>
                            <div className="data-list-box data-list-box-align-middle">
                                <ul className="list-unstyled">
                                    <li>
                                        <span className="data-list-box__title col-md-4">{"Tipo de Falta *"}</span>
                                        <span className="data-list-box__text col-md-8">
                                            <div className="input-group">
                                                <select className="form-select" disabled={collaborator === null} onChange={(e) => { setSelectedFault(e.target.value) }} value={selectedFault}>
                                                    <option value="" defaultValue="" disabled>Seleccionar</option>
                                                    {Children.toArray(faults.map((fault) => {
                                                        return (
                                                            <option value={fault.code}>{fault.descr}</option>
                                                        )
                                                    }))}
                                                </select>
                                            </div>
                                        </span>
                                    </li>
                                    <FileUpload collaborator={collaborator} files={files} setFiles={setFiles} />
                                    {files.length > 0 && totalSize > 9 * 1024**2 &&
                                        <div>
                                            <br />
                                            <span className="data-list-box__title color-red">{"El peso total no puede superar los 9,000 MB. Peso actual: "}</span>
                                            <span className="data-list-box__text color-red"><strong>{`${dotPerc((totalSize/(1024**2)).toFixed(3))} MB`}</strong></span>
                                        </div>
                                    }
                                    <li>
                                        <span className="data-list-box__title col-md-4">Comentarios *</span>
                                        <span className="data-list-box__text col-md-8">
                                            <textarea value={comments} maxLength={2000} onChange={(e) => { setComments(e.target.value) }} className="form-control" readOnly={collaborator === null} />
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="row justify-content-end">
                                <div className="col-12 col-md-auto">
                                    <button
                                        type="submit"
                                        className="btn btn-degradado w-100"
                                        data-bs-toggle="modal"
                                        data-bs-target="#success"
                                        onClick={()=>{
                                            onSubmitPost({
                                                emplid: collaboratorEmplid !== "" ? collaboratorEmplid : queriedEmplid,
                                                comments: comments,
                                                reasonCode: selectedFault,
                                                evidence: arrayFiles
                                            })
                                        }}
                                        disabled={collaborator === null || selectedFault === "" || comments === "" || totalSize > 9 * 1024**2}
                                    >
                                        {"Enviar "}
                                        <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" className="btn btn-link" onClick={() => { setWarningLetter(false); fromEquipo && setCollaboratorEmplid('') }}>Volver</button>
            </section>


            <a hidden id="openModalPost" data-bs-toggle="modal" data-bs-target="#modalPost" />
            <div className="modal fade" id="modalPost" tabIndex={-1} aria-labelledby="modalPost" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button id="closeModalPost" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{ cleanInputs() }}></button>
                        </div>
                        <div className="modal-body text-center py-4">
                            {errorPost?
                                <i className="fa fa-exclamation-triangle color-orange fa-3x" aria-hidden="true"></i> : 
                                <i className="fa fa-check-circle-o color-orange fa-3x mb-2" aria-hidden="true"></i>
                            } 
                            
                            <h2 className="h5">{statusPost}</h2>
                        </div>
                    </div>
                </div>
            </div>

            <a hidden id="openModalSearch" data-bs-toggle="modal" data-bs-target="#modalSearch" />
            <div className="modal fade" id="modalSearch" tabIndex={-1} aria-labelledby="modalSearch" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button id="closeSearch" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { } }></button>
                        </div>
                        <div className="modal-body text-center py-4">
                        <i
                        className="fa fa-exclamation-triangle color-orange fa-2x"
                        aria-hidden="true"
                        ></i>
                            <h2 className="h5">Colaborador no encontrado o fuera de las dependencias directas.</h2>
                        </div>
                    </div>
                </div>
            </div>
                    
            <button hidden id="sendingInfo" data-bs-target="#sendingInfoModal" data-bs-toggle="modal" data-bs-backdrop="static" data-bs-keyboard="false"  />
            <div className="modal fade" id="sendingInfoModal" tabIndex={-1} aria-labelledby="sending-info" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button hidden id="closeSendingInfo" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center py-4">
                            <div className="modal-body text-center py-4">
                                <i className="fa fa-exclamation-triangle color-orange fa-3x" aria-hidden="true"></i>
                                <h2 className="h5">{"Enviando solicitud"}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Warning;
