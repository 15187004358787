import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment';
import 'moment/locale/es';
import React, { useEffect, useRef, useState } from 'react';
import { BsExclamationCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import AsistenciaService from '../../services/asistencia/asistencia-service';
import Header from "./calendario-header";
import CalendarApprovalResponsive from './aprobacion-responsive/calendar-aprob-responsive';
import ApprovalModal from './aprobacion-responsive/approval-modal';
import { useNavigate } from 'react-router-dom';



const AprobacionHorarios = () => {
    const navigation = useNavigate();

    const EVENT_STYLES = {
        Franco: { backgroundColor: '#d9d9d9', textColor: '#777777' },
        Licencia: { backgroundColor: '#a6cbff', textColor: '#777777' },
        Vacaciones: { backgroundColor: '#ace8af', textColor: '#777777' },
        FrancoPendiente: { backgroundColor: '#f9deaa', textColor: '#777777' },
        HorarioPendiente: { backgroundColor: '#faba00', textColor: '#777777' },
        LicenciaPendiente: { backgroundColor: '#fac8ae', textColor: '#777777' },
        VacacionesPendiente: { backgroundColor: '#f8bbb5', textColor: '#777777' },
    };
    const itemsRef = useRef([]);
    const [departmentsOption, setDepartmentsOption] = useState([]);
    const [positionOption, setPositionOption] = useState([]);
    const [solicitudCambio, setSolicitudCambio] = useState(null);
    const [workSchedules, setWorkSchedules] = useState([]);
    const [schedulesError, setSchedulesError] = useState(null);
    const [employeesSchedules, setEmployeesSchedules] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState(false);
    const [modalInfo, setModalInfo] = useState(null)
    const [reload, setReload] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [loadingFilters, setLoadingFilters] = useState(false);
    const [subordinates, setSubordinates] = useState([]);
    const [employeesToSearch, setEmployeesToSearch] = useState([]);

    const [beginTimes, setBeginTimes] = useState([]);
    const [endTimes, setEndTimes] = useState([]);
    let calendarRef = useRef();
    const [selectedBeginTime, setSelectedBeginTime] = useState(undefined);
    const [selectedEndTime, setSelectedEndTime] = useState(undefined);
    const [selectedDepartament, setSelectedDepartament] = useState(undefined);
    const [selectedJob, setSelectedJob] = useState(undefined);
    // eslint-disable-next-line no-unused-vars
    const [fetched, setFetched] = useState(false);

    useEffect(() => {
        setLoadingFilters(true);
        AsistenciaService.getWorkSchedules(null, setWorkSchedules, setSchedulesError, null);
        AsistenciaService.getSubordinatesData(setFetched, setSubordinates, setLoadingFilters, setError);
    }, []);

    useEffect(() => {
        setDepartmentsOption(getDepartments());
        setPositionOption(getPositions());
    }, [subordinates])

    const getEventDescription = (data) => {
        switch (data?.type) {
            case 'SinHorario': return 'No Asignado';
            case 'Turno': return `${data?.startTime} - ${data?.endTime}`;
            case 'Franco': return 'Franco';
            case 'Licencia': return 'Licencia';
            case 'Vacaciones': return 'Vacaciones';
            case 'FrancoPendiente': return 'Pendiente Cambio Franco';
            case 'HorarioPendiente': return 'Pendiente Cambio Horario';
            case 'LicenciaPendiente': return 'Pendiente Licencia';
            case 'VacacionesPendiente': return 'Pendiente Vacaciones';
            default: return data?.type ?? '-';
        }
    }
    useEffect(() => {
        if (reload) {
            loadSchedules()
        }
    }, [reload])

    useEffect(() => {
        if (startDate && endDate) {
            loadSchedules();
        }
    }, [startDate, endDate, employeesToSearch]);



    useEffect(() => {
        itemsRef.current = itemsRef.current.slice(0, employeesSchedules.length);
    }, [employeesSchedules]);


    useEffect(() => {
        setBeginTimes(getStartTimes());
        setEndTimes(getEndTimes());
    }, [workSchedules]);


    useEffect(() => {
        setPositionOption(getPositions())
    }, [selectedDepartament])

    function getDepartments() {
        let departments = [];
        subordinates.forEach(subordinate => {
            const value = subordinate.departmentDescription;
            if (departments.length == 0) {
                const defaultOption = { value: "", description: "Todos" };
                departments.push(defaultOption);
            }
            const match = departments.some(x => x.value === value)
            if (!match) {
                departments.push({ value: value, description: value });
            }
        });
        return departments;
    }

    function getPositions() {
        let positions = [];
        if (!selectedDepartament) {
            subordinates.forEach(subordinate => {
                const value = subordinate.positionDescription;
                if (positions.length == 0) {
                    const defaultOption = { value: "", description: "Todos" };
                    positions.push(defaultOption);
                }
                const match = positions.some(x => x.value === value)
                if (!match) {
                    positions.push({ value: value, description: value });
                }
            });
        } else {
            if (positions.length == 0) {
                const defaultOption = { value: "", description: "Todos" };
                positions.push(defaultOption);
            }
            const matches = subordinates.filter(x => x.departmentDescription === selectedDepartament)
            matches.forEach(employee => {
                const match = positions.some(x => x.value === employee.positionDescription)
                if (!match) {
                    positions.push({ value: employee.positionDescription, description: employee.positionDescription })
                }
            })
        }
        return positions;
    }
    function getStartTimes() {
        let times = [];
        const defaultOption = { value: "", description: "--" }
        times.push(defaultOption)
        workSchedules.forEach(startTime => {
            const match = times.some(x => x.value === startTime.startTime)
            if (!match) {
                times.push({ value: startTime.startTime, description: startTime.startTime })
            }
        })
        let sorted = times.sort((a, b) => a.value.replace(':', '') - b.value.replace(':', ''));
        return sorted;
    }
    function getEndTimes() {
        let times = [];
        const defaultOption = { value: "", description: "--" }
        times.push(defaultOption)
        workSchedules.forEach(endTime => {
            const match = times.some(x => x.value === endTime.endTime)
            if (!match) {
                times.push({ value: endTime.endTime, description: endTime.endTime })
            }
        })
        let sorted = times.sort((a, b) => a.value.replace(':', '') - b.value.replace(':', ''));
        return sorted;
    }
    const loadSchedules = () => {
        AsistenciaService.getSubordinatesSchedules(startDate, endDate, employeesToSearch, selectedBeginTime, selectedEndTime, setLoading, setEmployeesSchedules, setError, setReload);
    }
    const handleSelectDepartment = (departament) => {
        if (departament === '') {
            setSelectedDepartament('');
        }
        if (departament !== selectedDepartament) {
            setSelectedJob('');
        }
        setSelectedDepartament(departament);
    }

    const handleSelectJob = (job) => {
        setSelectedJob(job);
    }

    const handleSelectBeginTime = (beginTime) => {
        if (beginTime === "") {
            setSelectedBeginTime(null);
        }
        setSelectedBeginTime(beginTime);
    }

    const handleSelectEndTime = (endTime) => {
        if (endTime === "") {
            setSelectedEndTime(null)
        }
        setSelectedEndTime(endTime);
    }


    const renderEventContentEmployee = (eventInfo) => {
        const style = EVENT_STYLES[eventInfo.event?.extendedProps?.data?.type];
        const eventData = eventInfo.event?.extendedProps?.data;
        return (
            <>

                <div className={`calendar-event ${eventData?.shiftChange ? 'calendar-event-clickable' : ''}`}
                    style={style ? { backgroundColor: style.backgroundColor, color: style.textColor } : {}}>
                    <span>
                        {getEventDescription(eventData)}
                    </span>
                </div>

            </>
        )
    }

    const handleClick = (e) => {
        const event = e.event;
        const data = event.extendedProps?.data;
        if (data.type === "LicenciaPendiente") {
            navigation('../arg/centro-de-aprobaciones')
        }
        if (data.type === "VacacionesPendiente") {
            navigation('../arg/centro-de-aprobaciones')
        }
        if (data.type === 'FrancoPendiente' || data.type === 'HorarioPendiente') {
            if (!data.shiftChange) {
                return;
            }
            setSolicitudCambio(data);
            setModalInfo(data.shiftChange)
            setActive(true)
        }
    }

    const handleDatesSet = (e) => {
        setStartDate(moment(e.start).format('DD-MM-YYYY'));
        setEndDate(moment(e.end).format('DD-MM-YYYY'))
        let calendarsRefs = itemsRef.current;
        calendarsRefs?.forEach(calendarRef => {
            calendarRef?.getApi().gotoDate(e.start)
        })
    }

    const searchSubordinatesSchedules = () => {
        setEmployeesToSearch([])
        let employeesIdsToSearch = [];
        if (selectedJob) {
            let employee = subordinates.filter(x => x.positionDescription === selectedJob)
            employee.forEach(element => {
                const match = employeesIdsToSearch.some(x => x.employeeId === element.employeeId)
                if (!match) {
                    employeesIdsToSearch.push(element.employeeId)
                }
            })
        } else if (selectedDepartament) {
            let employee = subordinates.filter(x => x.departmentDescription === selectedDepartament)
            employee.forEach(element => {
                const match = employeesIdsToSearch.some(x => x.employeeId === element.employeeId)
                if (!match) {
                    employeesIdsToSearch.push(element.employeeId)
                }
            })
        }
        setEmployeesToSearch(employeesIdsToSearch);
    }

    const getSubordinate = (id) => {
        const a = subordinates.filter(emp => emp.employeeId == id)
        return a
    }

    return (
        <>
            {!loading ? <div className="card-box mb-3">
                <h3>Filtrar <div className='title-underlined'></div></h3>
                {schedulesError && <div className="alert alert-danger fade show text-center mt-2" role="alert">
                    <BsExclamationCircle className="mb-1" />  {`${schedulesError || 'Ha ocurrido un error al obtener los horarios.'}`}
                </div>}

                <div className="row">
                    <div className="col-12 col-xl-6 mb-3">
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <label className="col-form-label">Departamento</label>
                            </div>

                            <div className="col-12 col-md-6">
                                <select className="form-select" value={selectedDepartament} onChange={e => handleSelectDepartment(e.target.value)}>
                                    {departmentsOption && departmentsOption.map((item, i) => {
                                        return <option key={i} value={item.value}>{item.description}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                    </div>
                    <div className="col-12 col-xl-6 mb-3">
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <label className="col-form-label">Franja Horaria</label>
                            </div>
                            <div className="form-group col-md-8">
                                <div className="wrapper-filter col-xl-12 col-md-8">
                                    <label className="col-form-label time-label">Desde</label>
                                    <select className="form-select time-select" onChange={e => handleSelectBeginTime(e.target.value)} value={selectedBeginTime}>
                                        {beginTimes.map((time, i) => {
                                            return <option className='form-option' value={time.value} key={i}>{time.description}</option>
                                        })}
                                    </select>
                                    <label className="col-form-label time-label">Hasta</label>
                                    <select className="form-select time-select" onChange={e => handleSelectEndTime(e.target.value)} value={selectedEndTime}>
                                        {endTimes.map((time, i) => {
                                            return <option className='form-option' value={time.value} key={i}>{time.description}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-xl-6 mb-3">
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <label className="col-form-label">Puesto</label>
                            </div>
                            <div className="col-12 col-md-6">
                                <select className="form-select" value={selectedJob} onChange={e => handleSelectJob(e.target.value)}>
                                    {positionOption && positionOption.map((job, i) => {
                                        return <option key={i} value={job.value}>{job.description}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-6 mb-3 calendar-search-btn">
                        <button className="btn btn-outline-primary approval-search-btn" onClick={() => searchSubordinatesSchedules()}>
                            Buscar
                            <i className="color-primary-strong btn-icon-color-parent fa fa-search ms-2"></i>
                        </button>
                    </div>
                </div>

            </div>
                : null}
            <section>
                <div className="card-box mb-3">
                    <div className="header-page-container">
                        <div className="mb-2 mb-sm-4">
                            <h3>Calendario  <div className='title-underlined'></div></h3>

                        </div>
                        <div className="mb-2 mb-sm-4 ">
                            <button className='button history history-approval-btn'><Link to='aprobacion-historico'>Ver Historico <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></Link></button>
                        </div>
                    </div>
                    <div>
                        {error && <div className="alert alert-danger fade show text-center mt-4" role="alert">
                            <BsExclamationCircle className="mb-1" />  {`${error || 'Ha ocurrido un error al la agenda.'}`}
                        </div>}


                        {loading && <div className="full-spinner mb-4 mt-5"><div className="spinner-border text-primary"></div></div>}

                        <div className="calendar mt-5">
                            <div className="calendar__employees-title d-md-flex d-none"></div>
                            <div className="calendar__widget">
                                <div className="mobile-table-licencias-historico">
                                    {!loading && <CalendarApprovalResponsive initial={calendarRef} iterator={employeesSchedules} sub={subordinates} setReload={setReload}></CalendarApprovalResponsive>}
                                </div>
                                <div className="desktop-table-vacaciones-historico">
                                    <Header reference={calendarRef}></Header>
                                    <FullCalendar
                                        ref={calendarRef}
                                        plugins={[dayGridPlugin]}
                                        headerToolbar={false
                                        }
                                        editable={true}
                                        selectable={true}
                                        weekends={true}
                                        events={[]}
                                        eventClick={handleClick}
                                        initialView={'dayGridWeek'}
                                        height="auto" // controlar si es mobile
                                        dayCellClassNames={'d-none'}
                                        locale={esLocale}
                                        dayHeaderFormat={{ weekday: 'long', day: 'numeric' }}

                                        datesSet={handleDatesSet}

                                        buttonText={{
                                            next: 'Después',
                                            prev: 'Antes'
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="desktop-table-vacaciones-historico">
                            {employeesSchedules.length > 0 ? employeesSchedules.map((employeeSchedules, i) => {

                                return <React.Fragment key={i}>
                                    <div className="calendar">
                                        <div className="calendar__employee">
                                            {getSubordinate(employeeSchedules.employeeId).map((emp) => (
                                                <span key={i}>{emp.name}</span>
                                            ))}
                                        </div>
                                        <div className="calendar__widget">
                                            <FullCalendar
                                                ref={el => itemsRef.current[i] = el}
                                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                                headerToolbar={false}
                                                dayHeaderClassNames={'d-none'}
                                                editable={false}
                                                selectable={true}
                                                weekends={true}
                                                eventContent={renderEventContentEmployee}
                                                events={employeeSchedules.events}
                                                eventClick={handleClick}
                                                height="auto"
                                                initialView={'dayGridWeek'}
                                                locale={esLocale}
                                                eventOrder={'priority'}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            }) : <div className='text-center fst-italic fs-6 mt-2'>No se encontraron horarios</div>}

                        </div>
                        {(!loading || (loading && employeesSchedules.length > 0)) &&
                            <React.Fragment>
                                <div className="mt-2 row d-md-block d-none">
                                    <div className='col'>
                                        <span className='reference'><i className="fa fa-circle fa-fw fa-lg m-2" style={{ color: '#ee4a33' }} aria-hidden="true"></i>Pendiente Vacaciones</span>
                                        <span className='reference'><i className="fa fa-circle fa-fw fa-lg m-2" style={{ color: '#f76e10' }} aria-hidden="true"></i>Pendiente Licencia</span>
                                        <span className="reference"><i className="fa fa-circle fa-fw fa-lg m-2" style={{ color: '#faba00' }} aria-hidden="true"></i>Pendiente Cambio Horario/Franco</span>
                                        <span className="reference"><i className="fa fa-circle fa-fw fa-lg m-2" style={{ color: '#d9d9d9' }} aria-hidden="true"></i>Franco</span>
                                        <span className="reference"><i className="fa fa-circle fa-fw fa-lg m-2" style={{ color: '#43c400' }} aria-hidden="true"></i>Vacaciones</span>
                                        <span className="reference"><i className="fa fa-circle fa-fw fa-lg m-2" style={{ color: '#1573ff' }} aria-hidden="true"></i>Licencias</span>
                                        <span className="reference"><i className="fa fa-circle fa-fw fa-lg m-2" style={{ color: '#dc3545' }} aria-hidden="true"></i>Feriado</span>
                                        <span className="reference"><i className="fa fa-circle fa-fw fa-lg m-2" style={{ color: '#FFD6A6' }} aria-hidden="true"></i>Hoy</span>
                                    </div>
                                </div>
                                <div className="mt-2 row d-md-none d-flex">
                                    <span className='reference'><i className="fa fa-circle fa-fw fa-lg m-2" style={{ color: '#ee4a33' }} aria-hidden="true"></i>Pendiente Vacaciones</span>
                                    <span className='reference'><i className="fa fa-circle fa-fw fa-lg m-2" style={{ color: '#f76e10' }} aria-hidden="true"></i>Pendiente Licencia</span>
                                    <span className="reference"><i className="fa fa-circle fa-fw fa-lg m-2" style={{ color: '#faba00' }} aria-hidden="true"></i>Pendiente Cambio Horario/Franco</span>
                                    <span className="reference"><i className="fa fa-circle fa-fw fa-lg m-2" style={{ color: '#d9d9d9' }} aria-hidden="true"></i>Franco</span>
                                    <span className="reference"><i className="fa fa-circle fa-fw fa-lg m-2" style={{ color: '#43c400' }} aria-hidden="true"></i>Vacaciones</span>
                                    <span className="reference"><i className="fa fa-circle fa-fw fa-lg m-2" style={{ color: '#1573ff' }} aria-hidden="true"></i>Licencias</span>
                                    <span className="reference"><i className="fa fa-circle fa-fw fa-lg m-2" style={{ color: '#dc3545' }} aria-hidden="true"></i>Feriado</span>
                                    <span className="reference"><i className="fa fa-circle fa-fw fa-lg m-2" style={{ color: '#FFD6A6' }} aria-hidden="true"></i>Hoy</span>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </section>

            {solicitudCambio && active && <ApprovalModal active={active} setActive={setActive} data={modalInfo} setData={setModalInfo} setReload={setReload}></ApprovalModal>}



        </>
    )
}

export default AprobacionHorarios