import React from "react";
import { useCencoTalent } from "../../hooks/useCencoTalent";

export const DetailsDescription = () => {
    const { activeJob } = useCencoTalent();

    return (
        <>
            <div className="col-12 col-md-8 d-flex mt-2">
                <div className="ps-0 ps-md-4 border-md-start">
                    <div className="mb-5">
                        <h2 className="h4 tit-section">Funciones del cargo</h2>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: activeJob?.description ?? "",
                            }}
                            style={{
                                textAlign: "justify",
                                textJustify: "inter-word",
                            }}
                        ></div>
                    </div>
                    <div className="mb-5">
                        <h2 className="h4 tit-section">Requisitos</h2>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: activeJob?.requisites ?? "",
                            }}
                            style={{
                                textAlign: "justify",
                                textJustify: "inter-word",
                            }}
                        ></div>
                    </div>
                </div>
            </div>
        </>
    );
};
