import React, { FC } from "react";
import { Button, Card } from "../../../commonComponents";
import { JobInfo } from "../../interfaces/applyInterface";
import {
    FaUniversalAccess,
    FaMapMarkerAlt,
    FaBuilding,
    FaBriefcase,
    FaFileAlt,
} from "react-icons/fa";
import { JobCardIconInfo } from "./JobCardIconInfo";

interface Props {
    applyInfo: JobInfo;
    onDetailsClick: () => void;

    className?: string;
    style?: React.CSSProperties;
}

export const JobCard: FC<Props> = ({
    applyInfo,
    className = "",
    style,
    onDetailsClick,
}) => {
    return (
        <>
            <Card className={className} style={style}>
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div>
                        {applyInfo.disability && (
                            <JobCardIconInfo
                                text="Apto para personas con discapacidad"
                                icon={<FaUniversalAccess color="white" />}
                                className="align-items-center bg-primary color-white p-1 rounded rounded-1"
                            />
                        )}
                    </div>
                    <div>
                        <div className="text-nowrap">
                            {/* <span className="material-symbols-outlined fs-5 me-1">
                                <i
                                    className="fa fa-clock-o fa-fw"
                                    aria-hidden="true"
                                ></i>
                            </span>
                            <small className="fw-bold">
                                {applyInfo.creationDate ?? ""}
                            </small> */}
                            <div className="text-end">
                                <small className="fw-bold">
                                    <i
                                        className="fa fa-clock-o fa-fw"
                                        aria-hidden="true"
                                    ></i>
                                    {applyInfo.creationDate ?? ""}
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 className="fs-9 fs-md-5 fw-bold text-gray">
                    {applyInfo.title}
                </h5>
                <div className="d-flex justify-content-center">
                    <div className="row mt-3">
                        <div className="col-12 col-md-6 px-0 d-block d-md-none">
                            <div className="border-start-md ps-0 ps-md-3">
                                <p
                                    className="lh-base"
                                    style={{
                                        // height: "120px",
                                        overflow: "hidden",
                                    }}
                                >
                                    {applyInfo.description.length > 200
                                        ? applyInfo.description
                                              .replace(
                                                  /<[^>]*>|&nbsp;|&amp;|&quot;|&apos;|&lt;|&gt;/g,
                                                  " "
                                              )
                                              .substring(0, 200) + "..."
                                        : applyInfo.description.replace(
                                              /<[^>]*>|&nbsp;|&amp;|&quot;|&apos;|&lt;|&gt;/g,
                                              " "
                                          )}
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 ps-0 pe-0 pe-md-3">
                            <ul className="list-unstyled lh-xl">
                                <JobCardIconInfo
                                    text={applyInfo.country ?? ""}
                                    icon={<FaMapMarkerAlt />}
                                />
                                <JobCardIconInfo
                                    text={applyInfo.flag ?? ""}
                                    icon={<FaBuilding />}
                                />
                                <JobCardIconInfo
                                    text={applyInfo.area ?? ""}
                                    icon={<FaBriefcase />}
                                />
                                <JobCardIconInfo
                                    text={applyInfo.employmentType ?? ""}
                                    icon={<FaFileAlt />}
                                />
                            </ul>
                        </div>
                        <div className="col-12 col-md-6 px-0">
                            <div className="border-start-md ps-0 ps-md-3">
                                <p
                                    className="lh-base d-none d-md-block"
                                    style={{
                                        height: "120px",
                                        overflow: "hidden",
                                    }}
                                >
                                    {applyInfo.description.length > 200
                                        ? applyInfo.description
                                              .replace(
                                                  /<[^>]*>|&nbsp;|&amp;|&quot;|&apos;|&lt;|&gt;/g,
                                                  " "
                                              )
                                              .substring(0, 200) + "..."
                                        : applyInfo.description.replace(
                                              /<[^>]*>|&nbsp;|&amp;|&quot;|&apos;|&lt;|&gt;/g,
                                              " "
                                          )}
                                </p>
                                <div className="text-end">
                                    <Button
                                        styleType="primary"
                                        className="w-100 w-md-0"
                                        onClick={onDetailsClick}
                                    >
                                        Ver detalle
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
};
