import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../resources/css/style-COL.module.css";
import VacacionesServices from "../../services/vacaciones-COL/vacaciones-service";
import DatosLaboralesService from "../../services/datos laborales/datos-laborales-service";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import fotoDefault from "../../resources/images/default-colaborador-image.png";
import { Auth } from "aws-amplify";
import axios from "axios";
import errorMessage from "../SharedComponents/error-message";
import successMessage from "../SharedComponents/success-message";
import ReplacementsService from "../../services/asignacion-reemplazo/replacements-service";
import ReplacementExportableComponent from "../asignacion-reemplazo/replacement-exportable";

const SolicitudesEmpleados = () => {
  const getHeader = () => {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(async (auth) => {
          const header = {
            "Content-Type": "application/json",
            accesstoken: auth.getAccessToken().getJwtToken(),
          };
          resolve(header);
        })
        .catch(function (error) {
          return reject(error);
        });
    });
  };
  const navigate = useNavigate();
  const [avatar, setAvatar] = useState(null);
  const [loadingAvatar, setLoadingAvatar] = useState(true);

  const [employeeInfo, setEmployeeInfo] = useState({});
  const [loadingEmployeeInfo, setLoadingEmployeeInfo] = useState(false);
  const [noEmployeeData, setNoEmployeeData] = useState(false);

  const [dates, setDates] = useState({
    start_date: null,
    end_date: null,
  });
  const [startDatesNotValidated, setStartDatesNotValidated] = useState(false);
  const [endDatesNotValidated, setEndDatesNotValidated] = useState(false);
  const [startDateNotValidated, setStartDateNotValidated] = useState(false);
  const [duration, setDuration] = useState(0);
  const [loadingDuration, setLoadingDuration] = useState(false);
  const [noData, setNoData] = useState(false);
  const [errorMessageDuration, setErrorMessageDuration] = useState(null);
  const [durationError, setDurationError] = useState(null);

  const [diasTotales, setDiasTotales] = useState({});
  const [loadingDiasTotales, setLoadingDiasTotales] = useState(true);
  const [noDiasTotales, setNoDiasTotales] = useState(false);

  const [totalVacaciones, setTotalVacaciones] = useState({});
  const [loadingTotalVacaciones, setLoadingTotalVacaciones] = useState(true);
  const [noDiasTotalVacaciones, setNoTotalVacaciones] = useState(false);

  const [postSolicitud, setPostSolicitud] = useState();
  const [loadingPostSolicitud, setLoadingPostSolicitud] = useState(false);
  const [noPostSolicitud, setNoPostSolicitud] = useState(false);
  const [errorPostSolicitud, setErrorPostSolicitud] = useState("");

  const [showBenefitContent, setBenefitContent] = useState(false);
  const [replacementsActive, setReplacementsActive] = useState(true);
  const [notValidatedDaysToSell, setNotValidatedDaysToSell] = useState(false);
  const [isApprover, setIsApprover] = useState(true);
  const [loadingApproverStatus, setLoadingApproverStatus] = useState(false);
  const [replacements, setReplacements] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [dataNotFetched, setDataNotFetched] = useState(false);
  const [currentReplacement, setCurrentReplacement] = useState({});
  const [appSent, setAppSent] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    const fetchUserReplacements = async () => {
      setLoadingTable(true);
      try {
        const response = await ReplacementsService.getUserReplacements(
          setLoadingTable,
          setReplacements,
          setDataNotFetched
        );
        setReplacements(response.data);
      } catch (error) {
        setDataNotFetched(true);
      } finally {
        setLoadingTable(false);
      }
    };
    fetchUserReplacements();
  }, []);

  useEffect(() => {
    doesReplacementExist();
  }, []);

  useEffect(() => {
    hasSubordinates();
  }, []);

  useEffect(() => {
    const fetchFeatureFlag = async () => {
      try {
        setLoadingApproverStatus(true);
        const response = await ReplacementsService.getReplacementsFeatureFlag();
        setIsEnabled(response.data.isEnabled);
      } catch (error) {
        console.error(error);
      }finally {
        setLoadingApproverStatus(false);
      }
    };
  
    fetchFeatureFlag();
  }, []);

  const hasSubordinates = async () => {
    try {
      await ReplacementsService.hasSubordinates(
        setIsApprover,
        setLoadingApproverStatus
      );
    } catch (error) {
      console.error("Error while setting values:", error);
    }
  };
  const doesReplacementExist = async () => {
    try {
      await ReplacementsService.getCurrentReplacement(
        setLoadingTable,
        setCurrentReplacement,
        setDataNotFetched
      );
    } catch (error) {
      console.error("Error while setting values:", error);
    }
  };

  const loadAvatar = async () => {
    try {
      const response = await DatosLaboralesService.getAvatarRegional();
      setAvatar(response.data);
      setLoadingAvatar(false);
    } catch (e) {
      setAvatar(null);
      setLoadingAvatar(false);
    }
  };

  useEffect(() => {
    VacacionesServices.getEmployee(
      setEmployeeInfo,
      setLoadingEmployeeInfo,
      setNoEmployeeData
    );
    VacacionesServices.getDiasTotales(
      setDiasTotales,
      setLoadingDiasTotales,
      setNoDiasTotales
    );
    VacacionesServices.getTotalVacaciones(
      setTotalVacaciones,
      setLoadingTotalVacaciones,
      setNoTotalVacaciones
    );
    loadAvatar();
  }, []);

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const generateDays = async (e) => {
    e.preventDefault();
    setStartDatesNotValidated(false);
    setEndDatesNotValidated(false);
    if (
      dates.start_date == undefined ||
      dates.start_date == "" ||
      dates.start_date == null
    ) {
      setStartDatesNotValidated(true);
      return;
    }
    if (
      dates.end_date == undefined ||
      dates.end_date == "" ||
      dates.end_date == null
    ) {
      setEndDatesNotValidated(true);
      return;
    }
    if (new Date(dates.start_date).getUTCDate() == "31") {
      Swal.fire({
        title: "Fechas mal ingresadas",
        text: "No se puede seleccionar esta fecha inicio. Por favor, seleccione otra o comuníquese con RH",
        icon: "error",
      });
      return;
    }
    if (new Date(dates.start_date) < new Date()) {
      setStartDateNotValidated(true);
      return;
    }
    if (dates.start_date > dates.end_date) {
      Swal.fire({
        title: "Fechas mal ingresadas",
        text: "La fecha inicial no puede ser mayor a la fecha final.",
        icon: "error",
      });
      // setDatesValidated(false)
      setDuration(0);
      return;
    }
    setStartDateNotValidated(false);
    setLoadingDuration(true);
    try {
      const header = await getHeader();

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/vacaciones/diasatomarfechas?fechaInicio=${dates.start_date}&fechaFin=${dates.end_date}`,
        {
          // const response = await axios.get(`http://localhost:5000/api/vacaciones/diasatomarfechas?fechaInicio=${dates.start_date}&fechaFin=${dates.end_date}`, {
          headers: header,
        }
      );

      setDuration(response.data.DURACION);
      setLoadingDuration(false);
    } catch (error) {
      errorMessage(null, error?.response?.data);
      setLoadingDuration(false);
    }
  };

  const onInputChange = (e) => {
    e.preventDefault();
    setDates({
      ...dates,
      [e.target.name]: e.target.value,
    });
    setDuration(0);
    setDurationError(null);
    setStartDateNotValidated(false);
    setStartDatesNotValidated(false);
    setEndDatesNotValidated(false);
    setValue("daysToSell", 0);
  };

  const onSubmit = async (data) => {
    setDurationError(null);
    if (duration === 0) {
      setDurationError(
        "Antes de solicitar, se requiere calcular la duración del período ingresado. Presione el botón cálculo de días."
      );
      // Swal.fire({ title: "¡Atencion!", text: "Antes de solicitar, se requiere calcular la duración del período ingresado. Presione el botón cálculo de días.", icon: "error" })
      return;
    }
    if (diasTotales.REPORT_TO === 0) {
      Swal.fire({
        allowOutsideClick: false,
        position: "center",
        title:
          '<div style="text-align: center; color: red; font-size: 16px"> Advertencia! </div>',
        html: '<div style="text-align: center; font-size: 15px"> Usted no tiene aprobador, contacta a tu BP o Jefe de Recursos Humanos. </div>',
        confirmButtonColor: "#0169b5",
      });
      return;
    }

    setNotValidatedDaysToSell(false);
    if (diasTotales.CN_DIAS_BENEFICIO > 0 && showBenefitContent) {
      if (
        data.daysToSell > duration - diasTotales.CN_DIAS_BENEFICIO ||
        data.daysToSell < 0
      ) {
        setNotValidatedDaysToSell(true);
        return;
      }
    } else {
      if (data.daysToSell > duration || data.daysToSell < 0) {
        setNotValidatedDaysToSell(true);
        return;
      }
    }

    const submit = {
      CN_COL_VACACIONES_POST: {
        INICIO: dates.start_date,
        FIN: dates.end_date,
        DURACION: duration,
        CN_DIAS_DERECHO: totalVacaciones.diasDisponibles,
        CN_DIAS_GOZADOS: totalVacaciones.diasDisfrutados,
        CN_DIAS_PROYECTADO: totalVacaciones.diasProyectados,
        CN_DIAS_BENEFICIO: diasTotales.CN_DIAS_BENEFICIO,
        APLICA_BENEFICIO: data.benefits,
        CN_DIAS_DINERO: data.daysToSell ? data.daysToSell : 0,
      },
    };
    setLoadingPostSolicitud(true);

    try {
      const header = await getHeader();
      const data = await axios({
        method: "post",
        // url: "http://localhost:5000/api/vacaciones",
        url: `${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/vacaciones`,
        data: submit,
        headers: {
          accesstoken: header.accesstoken,
        },
      });
      const response = data;
      if (response === null) {
        setErrorPostSolicitud("");
      } else {
        const status = response?.statusCode || response?.status;
        switch (status) {
          case 200:
            setLoadingPostSolicitud(false);
            setPostSolicitud(true);
            successMessage(restartValues);
            setAppSent(true);
            break;

          case 400:
            setNoPostSolicitud(true);
            setErrorPostSolicitud(response?.message);
            setLoadingPostSolicitud(false);
            setAppSent(false);
            break;

          case 404:
            setNoPostSolicitud(true);
            setErrorPostSolicitud(response?.message);
            setLoadingPostSolicitud(false);
            setAppSent(false);
            break;

          case 500:
            setNoPostSolicitud(true);
            setErrorPostSolicitud(response?.message);
            setLoadingPostSolicitud(false);
            setAppSent(false);
            break;

          default:
            setNoPostSolicitud(true);
            setErrorPostSolicitud(response?.message);
            setLoadingPostSolicitud(false);
            setAppSent(false);
            break;
        }
      }
    } catch (error) {
      setNoPostSolicitud(true);
      errorMessage(restartValues, error?.response?.data.message);
      setLoadingPostSolicitud(false);
      setAppSent(false);
    }
  };

  const restartValues = () => {
    setDates({
      start_date: "",
      end_date: "",
    });
    setDuration(0);
    setValue("daysToSell", 0);
  };

  const handleRadioButtonChange = (event) => {
    setNotValidatedDaysToSell(false);
    setValue("daysToSell", 0);
    if (event.target.value === "Y") {
      setBenefitContent(true);
    } else {
      setBenefitContent(false);
    }
  };

  const validateDaysToSell = (e) => {
    setNotValidatedDaysToSell(false);
    if (diasTotales.CN_DIAS_BENEFICIO > 0 && showBenefitContent) {
      if (
        e.target.value > duration - diasTotales.CN_DIAS_BENEFICIO ||
        e.target.value < 0
      ) {
        setNotValidatedDaysToSell(true);
      }
    } else {
      if (e.target.value > duration || e.target.value < 0) {
        setNotValidatedDaysToSell(true);
      }
    }
  };

  return (
    <>
      <nav
        className={`${styles["breadcrumbs"]} ${styles["d-none"]} ${styles["d-lg-block"]}`}
        aria-label="breadcrumb"
      >
        <ol className={styles["breadcrumb"]}>
          <li className={styles["breadcrumb-item"]}>
            <Link to="/inicio">
              <i
                className={`${styles["fa"]} ${styles["fa-home"]}`}
                aria-hidden="true"
              ></i>{" "}
              Inicio
            </Link>
          </li>
          <li className={styles["breadcrumb-item"]}>Vacaciones</li>
          <li
            className={`${styles["breadcrumb-item"]} ${styles["active"]}`}
            aria-current="page"
          >
            Solicitud de Vacaciones
          </li>
        </ol>
      </nav>
      <section className={styles["vacaciones"]}>
        <h1>Vacaciones</h1>
        {!noEmployeeData && !noDiasTotales && !noDiasTotalVacaciones ? (
          <>
            <div className={`${styles["row"]} ${styles["mb-4"]}`}>
              <div
                className={`${styles["col-12"]} ${styles["col-xl-4"]} ${styles["d-flex"]} ${styles["mb-4"]} ${styles["mb-xl-0"]}`}
              >
                <div
                  className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}
                >
                  {!loadingAvatar ? (
                    <img
                      src={avatar === null ? fotoDefault : avatar}
                      alt=""
                      className={`${styles["rounded-circle"]} ${styles["mb-3"]}`}
                      width="120"
                      height="120"
                    />
                  ) : (
                    <div data-testid="loading" className="full-spinner">
                      <div className="spinner-border text-primary"></div>
                    </div>
                  )}
                  {!loadingEmployeeInfo ? (
                    <>
                      <h2 className={`${styles["h3"]} ${styles["mb-2"]}`}>
                        {employeeInfo.firstname
                          ? employeeInfo.firstname + " " + employeeInfo.lastname
                          : null}
                      </h2>
                      <p className={`${styles["h5"]} ${styles["fw-bold"]}`}>
                        {employeeInfo.position_description}
                      </p>
                    </>
                  ) : (
                    <div data-testid="loading" className="full-spinner">
                      <div className="spinner-border text-primary"></div>
                    </div>
                  )}
                  <div
                    className={`${styles["data-list-box"]} ${styles["data-list-box-align-middle"]} ${styles["mb-3"]}`}
                  >
                    {!loadingDiasTotales && !loadingTotalVacaciones ? (
                      <>
                        <ul
                          className={`${styles["list-unstyled"]} ${styles["m-0"]}`}
                        >
                          <li>
                            <span
                              className={`${styles["ic-circle"]} ${styles["ic-circle-primary"]}`}
                            >
                              {totalVacaciones.diasDisponibles}
                            </span>
                            Días Disponibles a la fecha
                          </li>
                          <li>
                            <span
                              className={`${styles["ic-circle"]} ${styles["ic-circle-primary"]}`}
                            >
                              {totalVacaciones.diasDisfrutados}
                            </span>
                            Días Disfrutados
                          </li>
                          <li>
                            <span
                              className={`${styles["ic-circle"]} ${styles["ic-circle-primary"]}`}
                            >
                              {diasTotales.CN_DIAS_BENEFICIO}
                            </span>
                            Días Beneficio
                          </li>
                          <li>
                            <span
                              className={`${styles["ic-circle"]} ${styles["ic-circle-primary"]}`}
                            >
                              {totalVacaciones.diasProyectados}
                            </span>
                            Días Proyectados{" "}
                          </li>
                        </ul>
                        <div
                          className={`${styles["row"]} ${styles["justify-content-center"]} ${styles["mt-4"]}`}
                        >
                          <div
                            className={`${styles["col-12"]} ${styles["col-lg-auto"]} ${styles["mb-2"]}`}
                          >
                            <button
                              type="button"
                              className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`}
                              data-bs-toggle="modal"
                              data-bs-target="#conocer-mas"
                            >
                              Conocer más{" "}
                              <i
                                className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div data-testid="loading" className="full-spinner">
                        <div className="spinner-border text-primary"></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`${styles["col-12"]} ${styles["col-xl-8"]} ${styles["d-flex"]}`}
              >
                <div
                  className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}
                >
                  <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>
                    Solicitud de Vacaciones
                  </h2>
                  {diasTotales.REPORT_TO !== 0 ? (
                    <>
                      <h3 className={`${styles["h5"]} ${styles["fw-bold"]}`}>
                        Aprobador de Vacaciones: {diasTotales.NAME_DISPLAY}
                      </h3>
                      <p>
                        Si tu aprobador no es correcto y eres de la Operación
                        contacta a tu Jefe de RRHH, si eres de Administración
                        contacta al área de Compensación y Beneficios.
                      </p>
                    </>
                  ) : (
                    <h3 className={`${styles["h5"]} ${styles["fw-bold"]}`}>
                      No cuenta con aprobador. Contacta a tu BP o Jefe de
                      Recursos Humanos.
                    </h3>
                  )}
                  <form className={styles["mt-4"]}>
                    <div className={styles["row"]}>
                      <div
                        className={`${styles["col-12"]} ${styles["col-md-3"]} ${styles["mb-3"]}`}
                      >
                        <label className={styles["form-label"]}>
                          Fecha Inicio
                        </label>
                        <input
                          className={styles["form-control"]}
                          type="date"
                          placeholder="dd/mm/aaaa"
                          name="start_date"
                          value={dates.start_date}
                          onChange={(e) => onInputChange(e)}
                        />
                        <small className="form-error-message">
                          {startDateNotValidated
                            ? "* La fecha de inicio debe ser posterior a la fecha actual."
                            : null}
                        </small>
                        <small className="form-error-message">
                          {startDatesNotValidated
                            ? "* Campo obligatorio"
                            : null}
                        </small>
                      </div>
                      <div
                        className={`${styles["col-12"]} ${styles["col-md-3"]} ${styles["mb-3"]}`}
                      >
                        <label className={styles["form-label"]}>
                          Fecha Final
                        </label>
                        <input
                          className={styles["form-control"]}
                          type="date"
                          placeholder="dd/mm/aaaa"
                          name="end_date"
                          value={dates.end_date}
                          onChange={(e) => onInputChange(e)}
                        />
                        <small className="form-error-message">
                          {endDatesNotValidated ? "* Campo obligatorio" : null}
                        </small>
                      </div>
                      <div
                        className={`${styles["col-12"]} ${styles["col-md-4"]} ${styles["mb-3"]}`}
                      >
                        <button
                          type="button"
                          disabled={loadingDuration}
                          onClick={(e) => generateDays(e)}
                          className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["w-100"]} ${styles["mt-4"]}`}
                        >
                          Calcular duración{" "}
                          {loadingDuration ? (
                            <div className="spinner-border spinner-border-sm"></div>
                          ) : (
                            <i
                              className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                              aria-hidden="true"
                            ></i>
                          )}
                        </button>
                      </div>
                      <div
                        className={`${styles["col-12"]} ${styles["col-md-2"]} ${styles["mb-3"]}`}
                      >
                        <label className={styles["form-label"]}>Duración</label>
                        <div>
                          {loadingDuration ? (
                            <div className="spinner-border spinner-border-sm"></div>
                          ) : (
                            <input
                              className={styles["form-control"]}
                              type="text"
                              value={`${duration} días`}
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <p className={styles["color-red"]}>{durationError}</p>
                    </div>
                    <div className={`${styles["row"]}}`}>
                      <div
                        className={`${styles["col-12"]} ${styles["col-md-4"]} ${
                          styles["mb-3"]
                        } ${
                          diasTotales.CN_DIAS_BENEFICIO === 0 &&
                          styles["d-none"]
                        }`}
                      >
                        <label className={styles["form-label"]}>
                          Incluir días beneficios
                        </label>
                        <div className={styles["row"]}>
                          <div
                            className={`${styles["col-12"]} ${styles["col-md-6"]}`}
                          >
                            <div
                              className={`${styles["form-check"]} ${styles["mt-1"]}`}
                            >
                              <input
                                className={styles["form-check-input"]}
                                value={"Y"}
                                type="radio"
                                name="dias-beneficio"
                                id="dias-beneficio-1"
                                {...register("benefits")}
                                disabled={
                                  duration < diasTotales.CN_DIAS_BENEFICIO
                                }
                                onChange={handleRadioButtonChange}
                              />
                              <label
                                className={styles["form-check-label"]}
                                htmlFor="dias-beneficio-1"
                              >
                                Si
                              </label>
                            </div>
                          </div>
                          <div
                            className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mt-1"]}`}
                          >
                            <div className={styles["form-check"]}>
                              <input
                                className={styles["form-check-input"]}
                                value={"N"}
                                type="radio"
                                name="dias-beneficio"
                                id="dias-beneficio-2"
                                {...register("benefits")}
                                disabled={
                                  duration < diasTotales.CN_DIAS_BENEFICIO
                                }
                                defaultChecked
                                onChange={handleRadioButtonChange}
                              />
                              <label
                                className={styles["form-check-label"]}
                                htmlFor="dias-beneficio-2"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        {showBenefitContent && (
                          <div
                            className={`${styles["mt-2"]} ${styles["border"]} ${styles["border-primary"]} ${styles["p-2"]} ${styles["rounded-4"]} ${styles["mb-2"]}`}
                          >
                            <div className={`${styles["box-scroll"]}`}>
                              <p>
                                Estos días de beneficio están incluidos en las
                                fechas que ha seleccionado.
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className={`${styles["col-12"]} ${styles["col-md-3"]} ${
                          styles["mb-3"]
                        } ${
                          totalVacaciones.diasDisponibles <
                            diasTotales.CN_DIAS_DINERO && styles["d-none"]
                        }`}
                      >
                        <label className={styles["form-label"]}>
                          Vacaciones en dinero
                        </label>
                        <input
                          className={styles["form-control"]}
                          type="number"
                          min={0}
                          max={9999}
                          defaultValue={0}
                          disabled={
                            duration <= 0 ||
                            (showBenefitContent &&
                              duration <= diasTotales.CN_DIAS_BENEFICIO)
                          }
                          {...register("daysToSell")}
                          onChange={(e) => validateDaysToSell(e)}
                        />
                        <small className="form-error-message">
                          {notValidatedDaysToSell &&
                            "La cantidad de días ingresados supera el máximo establecido"}
                        </small>
                      </div>
                    </div>
                    <div
                      className={`${styles["mt-4"]} ${styles["mb-4"]} ${
                        diasTotales.CN_DIAS_BENEFICIO === 0 && styles["d-none"]
                      }`}
                    >
                      {loadingApproverStatus ? (
                        <div className="d-flex justify-content-center">
                          <div className="spinner-border text-primary"></div>
                        </div>
                      ) : (
                        <div className="mt-3">
                          {isApprover && isEnabled ? (
                            <>
                              <div className={styles.fields}>
                                <div className={`${styles.label} fw-bold mb-2`}>
                                  Asignar Reemplazo
                                  <div
                                    onClick={() =>
                                      setReplacementsActive(!replacementsActive)
                                    }
                                  ></div>
                                </div>
                              </div>
                              <div
                                className={
                                  !replacementsActive
                                    ? styles.replacements
                                    : styles["replacements-active"]
                                }
                              >
                                <ReplacementExportableComponent
                                  initDate={dates.start_date}
                                  endDate={dates.end_date}
                                  current={currentReplacement}
                                  replacements={replacements}
                                  setReplacements={setReplacements}
                                  hideSendButton={true}
                                  isSubmitForm={appSent}
                                />
                              </div>
                            </>
                          ) : null}
                        </div>
                      )}
                      <h3 className={`${styles["h4"]} ${styles["mb-3"]}`}>
                        Tener en cuenta las siguientes condiciones al momento de
                        solicitar días de beneficio
                      </h3>
                      <p>
                        1. Por cumplir tres (3) o cuatro (4) años de antigüedad,
                        al 30 de diciembre del año inmediatamente anterior: Se
                        concederán tres (3) días hábiles de descanso remunerado.
                      </p>
                      <p>
                        2. Por cumplir cinco (5) o más años de antigüedad, al 30
                        de diciembre del año inmediatamente anterior: Se
                        concederán cinco (5) días hábiles de descanso.
                      </p>
                      <p>
                        3. Al momento de disfrutar los días de descanso por
                        antigüedad, la o el trabajador beneficiado deberá tener
                        al 30 de diciembre del año en curso, menos de 15 días
                        pendientes por disfrutar y haber tomado 15 días de
                        vacaciones en el año en curso, conforme las condiciones
                        de reconocimiento y causación que determine LA EMPRESA;
                        este beneficio no es acumulable de un año a otro.
                      </p>
                      <p>
                        4. La fecha límite para disfrutar estos días es el 14 de
                        enero del año siguiente, si para esta fecha no se han
                        tomado no es posible solicitarlos de manera posterior.
                      </p>
                    </div>
                    <div
                      className={`${styles["mt-4"]} ${
                        styles["border-bottom"]
                      } ${styles["pb-3"]} ${
                        diasTotales.CN_DIAS_BENEFICIO === 0 && styles["d-none"]
                      }`}
                    >
                      <h4 className={`${styles["h5"]} ${styles["fw-bold"]}`}>
                        Condiciones
                      </h4>
                      <div
                        className={`${styles["border"]} ${styles["border-primary"]} ${styles["pb-3"]} ${styles["rounded-4"]} ${styles["mb-3"]}`}
                      >
                        <div
                          className={styles["box-scroll"]}
                          style={{ maxHeight: "100px" }}
                        >
                          <p>
                            Certifico que he delegado formalmente los permisos
                            de acceso a los sistemas TI correspondientes, de
                            manera de garantizar la continuidad normal del flujo
                            de los procesos/negocio de la Compañía. Con el envío
                            de la presente solicitud y para todos los efectos
                            legales, el funcionario declara bajo la gravedad del
                            juramento que, si está realizando solicitud del pago
                            parcial en dinero de las vacaciones causadas, fue
                            solicitado unilateralmente por parte del trabajador
                            de manera libre y voluntaria y será valorado por la
                            Compañía de conformidad a las facultades y límites
                            establecidos por el artículo 20 de la Ley 1429 de
                            2011 que reforma el artículo 189 del C. S. T - Con
                            la presentación de esta solicitud CENCOSUD COLOMBIA
                            S.A. valorará el mismo y autorizará o no su pago en
                            dinero de acuerdo con la conveniencia y
                            posibilidades económicas, como también de acuerdo a
                            la política de la empresa al respecto.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={styles["mt-3"]}>
                      <h4 className={`${styles["h5"]} ${styles["fw-bold"]}`}>
                        Términos Legales
                      </h4>
                      <div
                        className={`${styles["border"]} ${styles["border-primary"]} ${styles["pb-3"]} ${styles["rounded-4"]} ${styles["mb-3"]}`}
                      >
                        <div
                          className={styles["box-scroll"]}
                          style={{ maxHeight: "100px" }}
                        >
                          <p>
                            {" "}
                            Estoy en conocimiento del saldo de días disponibles
                            posterior a la solicitud de vacaciones, declarando
                            mi conformidad con la información proporcionada por
                            el sistema. Si por algún motivo terminara mi
                            contrato de trabajo con la Compañía y aún quedaran
                            saldos pendientes por vacaciones, autorizo
                            descontarlos del valor de mis salarios y/o
                            prestaciones sociales.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={styles["form-check"]}>
                      <input
                        className={`${styles["form-check-input"]} ${styles["h5"]}`}
                        type="checkbox"
                        value={true}
                        id="acepto"
                        {...register("terms", {
                          required:
                            "Debe leer y aceptar los terminos legales antes de continuar",
                        })}
                      />
                      <label
                        className={`${styles["form-check-label"]} ${styles["fw-normal"]}`}
                        htmlFor="acepto"
                      >
                        Acepto Términos Legales
                      </label>
                    </div>
                    <small className="form-error-message">
                      {errors.terms?.message}
                    </small>
                  </form>
                </div>
              </div>
            </div>
            <div
              className={`${styles["d-grid"]} ${styles["d-md-flex"]} ${styles["justify-content-md-end"]} ${styles["gap-3"]} ${styles["flex-nowrap"]} ${styles["mt-5"]}`}
            >
              <div
                className={`${styles["order-md-last"]} ${styles["d-grid"]} ${styles["d-md-flex"]} ${styles["gap-2"]}`}
              >
                <button
                  type="button"
                  className={`${styles["btn"]} ${styles["btn-degradado"]}`}
                  onClick={handleSubmit(onSubmit)}
                  disabled={loadingPostSolicitud}
                >
                  Solicitar{" "}
                  {loadingPostSolicitud ? (
                    <div className="spinner-border spinner-border-sm"></div>
                  ) : (
                    <i
                      className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                      aria-hidden="true"
                    ></i>
                  )}
                </button>
              </div>
              <button
                type="button"
                className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["order-md-first"]}`}
                onClick={() => {
                  navigate("historicoempleado-col");
                }}
              >
                Ver Histórico{" "}
                <i
                  className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                  aria-hidden="true"
                ></i>
              </button>
            </div>
            <div
              className="modal fade"
              id="conocer-mas"
              tabIndex="-1"
              aria-labelledby="conocer-mas"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body py-4">
                    <h2 className="h4 mb-4 text-center">Información general</h2>
                    <p>
                      <span className="color-primary fw-bold">
                        Días disponibles
                      </span>
                      : Son los días causados y pendientes por disfrutar a la
                      fecha de la solicitud.
                    </p>
                    <p>
                      <span className="color-primary fw-bold">
                        Días disfrutados
                      </span>
                      : Corresponde a los días que ha tomado el empleado en el
                      transcurso del año actual.
                    </p>
                    <p>
                      <span className="color-primary fw-bold">
                        Días beneficio
                      </span>
                      : Son los días adicionales por antigüedad que tiene el
                      colaborador siempre y cuando cumpla con las condiciones
                      para el disfrute de estos.
                    </p>
                    <p>
                      <span className="color-primary fw-bold">
                        Días proyectados
                      </span>
                      : Proyección de días que tendrá acumulados el colaborador
                      al 31 de diciembre del año en curso. Este dato sirve para
                      validar con cuántos días disponibles se cerrará el año en
                      curso.
                    </p>
                  </div>
                  <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                    <button
                      type="submit"
                      className="btn btn-degradado col-12 col-md-auto my-auto px-5"
                      data-bs-dismiss="modal"
                    >
                      Cerrar{" "}
                      <i
                        className="fa fa-chevron-right fa-fw fa-xs"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div
            className="alert alert-info alert-dismissible fade show text-center"
            role="alert"
          >
            No se encontraron datos asociados
          </div>
        )}
      </section>
    </>
  );
};

export default SolicitudesEmpleados;
