import React, { useState, useEffect, Children } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Service from "../services/encuestas-service";
import useOnlineState from "./elements/online-hook";
import { useLocation } from "react-router-dom";
import useWindowSize from "./elements/window-size-hook";
import Header from "./elements/header";
import Footer from "./elements/footer";
import { encrypt } from "../services/utiles";
import { scrollToTop } from "../services/utiles";

const Section = ({ id, titulo, tipo, section, register, errors, handleChange, data, watch }) => {
    const { width, } = useWindowSize();
    if (tipo === "SELC" || tipo === "LIKR" || tipo === "NPS" || tipo === "MULT") {
        if (!Array.isArray(section.opcion)) section.opcion = [section.opcion];
        if (tipo === "LIKR" || tipo === "NPS") if (!Array.isArray(section.likert)) section.likert = [section.likert];
    }
    let req = false, nomObj = watch(section.id), otros = ""
    if (tipo === "OPEN") {
        if (Array.isArray(data.preguntas)) {
            for (let i = 0; i < data.preguntas.length; i++) {
                if (data.preguntas[i].id === (parseInt(id[id.length - 1]) - 1).toString()) {
                    if (data.preguntas[i].otros === 'N') {
                        req = true;
                    }
                    else {
                        otros = data.preguntas[i].opcion[data.preguntas[i].opcion.length - 1]
                        nomObj = id.replace(id[id.length - 1], parseInt(id[id.length - 1]) - 1);
                    }
                }
            }
        }
    }
    return (
        <>
            {tipo === "OPEN" ? (
                <div className="box mb-4" style={{ display: req ? "block" : (watch(nomObj) === otros) ? "block" : "none" }}>
                    <h2 className="h3 tit-section">{titulo}</h2>
                    <textarea
                        className="form-control"
                        id={id}
                        {...register(id, {
                            required: req ? "*Esta respuesta es requerida" : (watch(nomObj) === otros) ? "*Esta respuesta es requerida" : false,
                            onChange: (e) => handleChange(e, section)
                        })}

                    />
                    {errors[id] !== undefined ? <div className="invalid-feedback d-block"> {errors[id].message} </div> : null}
                </div>
            ) : tipo === "SELC" ? (
                <div className="box mb-4">
                    <h2 className="h3 tit-section">{titulo}</h2>
                    <div className="form-check-group form-check-group-even">
                        {Children.toArray(section.opcion.map((opcion) => {
                            return (
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        value={opcion}
                                        {...register(id, {
                                            required: "*Esta pregunta es obligatoria.",
                                            onChange: (e) => handleChange(e, section)

                                        })}
                                    />
                                    <label className="form-check-label" htmlFor={id + "radio"}>
                                        {opcion}
                                    </label>
                                </div>
                            );
                        }))}
                    </div>
                    {errors[id] !== undefined ? <div className="invalid-feedback d-block"> {errors[id].message} </div> : null}
                </div>
            ) : tipo === "LIKR" ? (
                <div className="box mb-4">
                    <h2 className="h3 tit-section">{titulo}</h2>
                    <table className="table table-2 table-even align-middle table-borderless d-none d-lg-table">
                        <thead>
                            <tr>
                                <th>Pregunta</th>
                                {Children.toArray(section.likert.map((likert) => {
                                    return (
                                        <>
                                            <th className={`text-center col-likr`} >{likert}</th>
                                        </>
                                    );
                                }))}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Children.toArray(section.opcion.map((opcion, index) => {
                                    return (
                                        <tr>
                                            <td>
                                                {opcion}
                                                {errors[id + "radio" + index] !== undefined ? <div className="invalid-feedback d-block"> {errors[id + "radio" + index].message} </div> : null}
                                            </td>
                                            {section.likert.map((likert, index2) => {
                                                return (
                                                    <td className="text-center">
                                                        {
                                                            width > 991 &&
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                value={index2 + 1}
                                                                onChange={(e) => handleChange(e, section)}
                                                                {...register(id + "radio" + index, {
                                                                    required: "*Esta pregunta es obligatoria.",
                                                                })}
                                                            />
                                                        }
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                }))}
                        </tbody>
                    </table>
                    <div className="d-lg-none">
                        {Children.toArray(section.opcion.map((opcion, index) => {
                            return (
                                <div className="form-check-group form-check-group-even">
                                    <div className="form-check__title">{opcion}</div>
                                    {errors[id + "radio" + index] !== undefined ? <div className="invalid-feedback d-block"> {errors[id + "radio" + index].message} </div> : null}
                                    {Children.toArray(section.likert.map((likert, index2) => {
                                        const radioid = id + "radio" + index
                                        return (
                                            <div className="form-check">
                                                {
                                                    width < 992 &&
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        value={index2 + 1}
                                                        onChange={(e) => handleChange(e, section)}
                                                        {...register(radioid, {
                                                            required: "*Esta pregunta es obligatoria."
                                                        })}
                                                    />
                                                }
                                                <label className="form-check-label" >
                                                    {likert}
                                                </label>
                                            </div>
                                        );
                                    }))}
                                </div>
                            );
                        }))}
                    </div>
                </div>
            ) : tipo === "NPS" ? (
                <div className="box mb-4">
                    <h2 className="h3 tit-section">{titulo}</h2>
                    <table className="table table-2 table-even table-borderless text-center table-w-100 d-none d-lg-table">
                        <thead>
                            <tr>
                                {Children.toArray(section.likert.map(() => {
                                    return (
                                        <>
                                            {Children.toArray(section.opcion.map((opcion) => {
                                                return <th className="align-top">{opcion}</th>;
                                            }))}
                                        </>
                                    );
                                }))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {Children.toArray(section.likert.map(() => {
                                    return (
                                        <>
                                            {Children.toArray(section.opcion.map((opcion) => {
                                                return (
                                                    <td>
                                                        {
                                                            width > 991 &&
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                defaultValue={opcion}
                                                                onChange={(e) => handleChange(e, section)}
                                                                {...register(id, {
                                                                    required: "*Esta pregunta es obligatoria."
                                                                })}

                                                            />
                                                        }
                                                    </td>
                                                );
                                            }))}
                                        </>
                                    );
                                }))}
                            </tr>
                        </tbody>
                    </table>
                    <div className="form-check-group form-check-group-even d-lg-none">
                        {section.likert.map(() => Children.toArray(section.opcion.map((opcion) => {
                            return (
                                <div className="form-check">
                                    {
                                        width < 992 &&
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            defaultValue={opcion}
                                            onChange={(e) => handleChange(e, section)}
                                            {...register(id, {
                                                required: "*Esta pregunta es obligatoria."
                                            })}
                                        />
                                    }
                                    <label className="form-check-label" htmlFor={id}>
                                        {opcion}
                                    </label>
                                </div>
                            );
                        })))}
                    </div>
                    {errors[id] !== undefined ? <div className="invalid-feedback d-block"> {errors[id].message} </div> : null}
                </div>
            ) : tipo === "MULT" ? (
                <div className="box position-relative mb-5">
                    <h2 className="h3 tit-section">{titulo}</h2>
                    <div>
                        {Children.toArray(section.opcion.map((opcion, index) => {
                            return (
                                <div className="form-check mb-2 ms-1 mb-3">
                                    <input
                                        className="form-check-input h5"
                                        type="checkbox"
                                        value={opcion}
                                        {...register(id + index)}
                                    />
                                    <label className="form-check-label fw-normal color-primary ms-2" htmlFor={id + index}>
                                        {opcion}
                                    </label>
                                </div>
                            );
                        }))}
                        {
                            section.otros === "Y" &&
                            <div className="d-flex align-items-center mb-2 mb-3 justify-content-start">
                                <div className="form-check ms-1 my-1 me-3">
                                    <input
                                        className="form-check-input h5"
                                        type="checkbox"
                                        defaultValue={false}
                                        {...register(id + (section.opcion.length))}
                                    />
                                    <label className="form-check-label fw-normal color-primary ms-2" htmlFor={id + (section.opcion.length)}>
                                        Otros
                                    </label>
                                </div>
                                {
                                    watch(id + (section.opcion.length)) &&
                                    <div>
                                        <input
                                            className="form-control form-control-sm"
                                            type="text"
                                            placeholder="Tu respuesta"
                                            name={id + (section.opcion.length) + 'otros'}
                                            onChange={(e) => handleChange(e, section)}
                                        />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    {section.icono !== "" && <i className={`fa ${section.icono} opacity-25 color-orange fa-5x encuesta-icon`} aria-hidden="true"></i>}
                </div>
            )
            : null}

        </>
    );
};

const Encuestas = (props) => {
    const [status, setStatus] = useState('init');
    const [respuesta, setRespuesta] = useState(null);
    const [data, setData] = useState(null);
    const isOnline = useOnlineState();
    const { search } = useLocation();
    const { register, handleSubmit, formState: { errors }, watch, setValue, getValues } = useForm();
    const rutcode = props.rutcode ? props.rutcode : search.split("Authorization=")[1];

    useEffect(() => {
        scrollToTop();
    }, [status]);
    
    useEffect(() => {
        const fetch = async () => {
            if (data === null) {
                setStatus('loading');
                const data = await Service.getEncuestas(rutcode);
                if (props.rutcode && data.valid !== "1") {
                    props.initialErrorHandler();
                }
                setStatus('loaded');
                setData(data);
            }
        };
        fetch();
    }, [data]);

    const handleChange = (e, section) => {
        if (section.tipo === "SELC") {
            if (section.otros === 'Y') {
                if (e.target.value === section.opcion[section.opcion.length - 1]) {
                    const nomObj = e.target.name.replace(e.target.name[e.target.name.length - 1], parseInt(e.target.name[e.target.name.length - 1]) + 1);
                    let obj = document.getElementById(nomObj);
                    if (obj) obj.required = true
                }
                else {
                    const nomObj = e.target.name.replace(e.target.name[e.target.name.length - 1], parseInt(e.target.name[e.target.name.length - 1]) + 1);
                    let obj = document.getElementById(nomObj);
                    if (obj) {
                        obj.value = '';
                        obj.required = false
                    }
                }
            }
        }
        if (section.tipo === "MULT") {
            if (section.otros === 'Y') {
                setValue(e.target.name, e.target.value);
            }
        }
    };

    const onSubmitSendEncuesta = (submitdata) => {
        setStatus('loading');
        const request = {
                emplid: encrypt(data.rut),
                id: data.id,
                titulo: data.title,
                descrip: data.descr,
                notificar: true,
                respuestas: [],
        };
        // iterar objeto de submitdata
        data.preguntas = Array.isArray(data.preguntas) ? data.preguntas : [data.preguntas];
        for (let i = 0; i < data.preguntas.length; i++) {
            request.respuestas.push({
                id: data.preguntas[i].id,
                titulo: data.preguntas[i].titulo,
                tipo: data.preguntas[i].tipo,
                items: [],
            });
            if (data.preguntas[i].tipo === "NPS") {
                request.respuestas[i].items.push({
                    nombre: data.preguntas[i].titulo,
                    valor: submitdata["pregunta" + data.preguntas[i].id],
                });
            } else if (data.preguntas[i].tipo === "OPEN") {
                request.respuestas[i].items.push({
                    nombre: data.preguntas[i].titulo,
                    valor: submitdata["pregunta" + data.preguntas[i].id] === undefined ? "" : submitdata["pregunta" + data.preguntas[i].id],
                });
            } else if (data.preguntas[i].tipo === "SELC") {
                for (let j = 0; j < data.preguntas[i].opcion.length; j++) {
                    if (submitdata["pregunta" + data.preguntas[i].id] === data.preguntas[i].opcion[j]) {
                        request.respuestas[i].items.push({
                            nombre: data.preguntas[i].opcion[j],
                            valor: "1",
                        });
                    } else {
                        request.respuestas[i].items.push({
                            nombre: data.preguntas[i].opcion[j],
                            valor: "0",
                        });
                    }
                }
            } else if (data.preguntas[i].tipo === "LIKR") {
                request.respuestas[i]["likert"] = [];
                for (let j = 0; j < data.preguntas[i].likert.length; j++) {
                    request.respuestas[i].likert.push(data.preguntas[i].likert[j]);
                }
                for (let j = 0; j < data.preguntas[i].opcion.length; j++) {
                    if (submitdata["pregunta" + data.preguntas[i].id + "radio" + j] === data.preguntas[i].opcion[j]) {
                        request.respuestas[i].items.push({
                            nombre: data.preguntas[i].opcion[j],
                            valor: submitdata["pregunta" + data.preguntas[i].id + "radio" + j],
                        });
                    } else {
                        request.respuestas[i].items.push({
                            nombre: data.preguntas[i].opcion[j],
                            valor: submitdata["pregunta" + data.preguntas[i].id + "radio" + j],
                        });
                    }
                }
            }
            else if (data.preguntas[i].tipo === "MULT") {
                for (let j = 0; j < data.preguntas[i].opcion.length; j++) {
                    if (submitdata["pregunta" + data.preguntas[i].id  + j] === data.preguntas[i].opcion[j]) {
                        request.respuestas[i].items.push({
                            nombre: data.preguntas[i].opcion[j],
                            valor: "1",
                        });
                    } else {
                        request.respuestas[i].items.push({
                            nombre: data.preguntas[i].opcion[j],
                            valor: "0",
                        });
                    }
                }
                if (data.preguntas[i].otros === 'Y') {
                    const otherAnswer = submitdata["pregunta" + data.preguntas[i].id + (data.preguntas[i].opcion.length) + 'otros']
                    if (otherAnswer !== undefined && otherAnswer !== '') {
                        request.respuestas[i].items.push({
                            nombre: 'Otros',
                            valor: '1?' + otherAnswer.replaceAll('?', "")
                        });
                    }
                    else {
                        request.respuestas[i].items.push({
                            nombre: 'Otros',
                            valor: '0?',
                        });
                    }
                }
            }
        }

        if (request.respuestas.length > 0) {
            const fetch = async () => {
                if (props.rutcode) {
                    props.onSubmit(props.rutcode, request, setRespuesta);
                }
                else {
                    const resp = await Service.sendEncuestas(rutcode, request);
                    setRespuesta(resp);
                }
                setStatus('loaded');
            };
            fetch();
        }
    };
    return <>
        {
            props.rutcode ?
        <>
            {
                data && !respuesta && <>
                    <form id="encuesta" className="needs-validation" noValidate="novalidate" onSubmit={handleSubmit(onSubmitSendEncuesta)}>
                        {data !== undefined && Array.isArray(data.preguntas) ?
                            Children.toArray(data.preguntas.map((section) => {
                                const id = "pregunta" + section.id;
                                return <Section register={register} id={id} titulo={section.titulo} tipo={section.tipo} section={section} errors={errors} handleChange={handleChange} data={data} watch={watch} />
                            }))
                            : data !== undefined && <Section register={register} id={"pregunta" + data.preguntas.id} titulo={data.preguntas.titulo} tipo={data.preguntas.tipo} section={data.preguntas} errors={errors} handleChange={handleChange} data={data} watch={watch} />
                        }
                        <div className="text-center my-5">
                            {props.PreviewBtn && <props.PreviewBtn token={rutcode} encuesta={data} getValues={getValues} />}
                            <button id="btn-submit-encuesta" type="submit" hidden />
                        </div>
                    </form>
                </>
            }
        </>
        :
        <>
            <Header />
            <main id="main" className="wrapper no-menu mt-5">
                <div id="content" className="container">
                    <div>
                        {
                            data && !respuesta && <>
                                <h1>{data.title}</h1>
                                <p>{data.descr}</p>
                                <form id="encuesta" className="needs-validation" noValidate="novalidate" onSubmit={handleSubmit(onSubmitSendEncuesta)}>
                                    {data !== undefined && Array.isArray(data.preguntas) ?
                                        Children.toArray(data.preguntas.map((section) => {
                                            const id = "pregunta" + section.id;
                                            return <Section register={register} id={id} titulo={section.titulo} tipo={section.tipo} section={section} errors={errors} handleChange={handleChange} data={data} watch={watch} />
                                        }))
                                        : data !== undefined && <Section register={register} id={"pregunta" + data.preguntas.id} titulo={data.preguntas.titulo} tipo={data.preguntas.tipo} section={data.preguntas} errors={errors} handleChange={handleChange} data={data} watch={watch} />
                                    }
                                    <div className="text-center my-5">
                                        <button type="submit" className="btn btn-degradado" disabled={(isOnline ? false : true)}>
                                            Enviar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </form>
                            </>
                        }
                    </div>
                </div>
            </main>
            <Footer />
            {
                data && data.valid !== "1" && <>
                    <div className="modal fade show" id="user-encuesta" tabIndex="-1" aria-labelledby="user-encuesta" aria-hidden="false"  data-bs-backdrop="static" data-bs-keyboard="false">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body text-center py-4">
                                    <i className="fa fa-times-circle-o color-orange fa-3x mb-2" aria-hidden="true"></i>
                                    <h2 className="h5">Error </h2>
                                    <p>este link ya no está disponible</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a hidden id={"user-encuesta-btn"} data-bs-toggle={"modal"} data-bs-target={"#user-encuesta"}  data-bs-backdrop="static" data-bs-keyboard="false"/>
                </>
            }
        </>
        }
        {
            respuesta && status !== 'loading' && <div id="encuesta">
                <div className="box mb-4">
                    <h2 className="h3 tit-section">{respuesta.valid === "1" ? "¡Muchas Gracias!" : "Error"}</h2>
                    <p>{
                        props.rutcode ? 
                            respuesta.status
                        :
                            respuesta.valid === "1" ? 
                            "Hemos recibido satisfactoriamente tus respuestas. Recuerda que son confidenciales y para uso exclusivo de RRHH. Gracias por ayudarnos a mejorar la experiencia de nuestr@s colaboradores." 
                            : respuesta.status
                        }</p>
                </div>
            </div>
        }
        {
            status === 'loading' &&
            <div className="text-center text-primary">
                <span className="spinner-border spinner-border-xl"></span>
            </div>
        }
    </>;
};
Section.propTypes = {
    register: PropTypes.func.isOptional,
    id: PropTypes.string.isOptional,
    titulo: PropTypes.string.isOptional,
    tipo: PropTypes.string.isOptional,
    section: PropTypes.object.isOptional,
    errors: PropTypes.object.isOptional,
    handleChange: PropTypes.func.isOptional,
    data: PropTypes.object.isOptional,
    watch: PropTypes.func.isRequired
}
Encuestas.propTypes = {
    rutcode: PropTypes.string.isOptional,
    validatePollUser: PropTypes.object.isOptional,
    status: PropTypes.string.isOptional,
    data: PropTypes.object.isOptional,
    isOnline: PropTypes.bool.isOptional,
    respuesta: PropTypes.object.isOptional,
    onSubmit: PropTypes.func.isOptional,
    handleChange: PropTypes.func.isOptional,
    watch: PropTypes.func.isOptional,
    register: PropTypes.func.isOptional,
    errors: PropTypes.object.isOptional,
    encuesta: PropTypes.object.isOptional,
    respuestaEncuesta: PropTypes.object.isOptional,
    PreviewBtn: PropTypes.func.isOptional,
    initialErrorHandler: PropTypes.func.isOptional
}
export default Encuestas;
