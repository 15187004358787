import React, { useEffect, useState } from 'react'
import { Breadcrumbs, Link, Popover, Button, Box } from '@mui/material'
import breadCrumStyle from '../styles/breadcrumbs.module.css'
import { paths } from '../../../utilities/paths'
import { BreadcrumbLevels, LevelsProps } from '../../../utilities/interfaces'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const LevelsCollapsed = ({ levelsToShow }: LevelsProps) => {
  const [levels, setLevels] = useState<Array<BreadcrumbLevels>>([])
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    levelsToShow.length > 0 && setLevels(levelsToShow)
  }, [levelsToShow])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        className={breadCrumStyle.breadcrumb_collapse}
        aria-describedby={id}
        variant="text"
        onClick={handleClick}>
        ...
        <ExpandMoreIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className='popper_menu'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Link className={breadCrumStyle.breadcrumb_item} href={paths[1].path}>
          {paths[1].breadCrumb}
        </Link>
        {levels?.map((level, index) => {
          return (
            <Link
              key={index}
              className={breadCrumStyle.breadcrumb_item}
              href={level.path}
            >
              {level.label}
            </Link>
          )
        })}
      </Popover>
    </React.Fragment>
  )
}

export default LevelsCollapsed