import clsx from "../../utils/clsx";
import { TabProps } from "./tab.types";

export const Tab = (props: TabProps) => {
  const { label, children, onClick, selected, ...rest } = props;
  return (
    <div className="nav-item w-50">
      <button
        {...rest}
        role="tab"
        aria-selected={selected}
        onClick={onClick}
        className={clsx("nav-link w-100", { active: selected })}
      >
        {label}
      </button>
    </div>
  );
};
