import React, { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import OtrosEstudiosServices from "../../services/otros estudios/otros-estudios-service";
import styles from "../../resources/css/style-COL.module.css";

function DetalleOtrosEstudios({ id, changeDisplay, subjectAreas, countries, reloadAcademicStudies }) {
    const [infoStudy, setInfoStudy] = useState({})
    const [loadingStudy, setLoadingStudy] = useState(false)
    const [, setNoDataStudy] = useState([])
    const [deleteStudy, setDeleteStudy] = useState(false)
    const [loadingDeleteStudy, setLoadingDeleteStudy] = useState(false)
    const [noDataDelete, setNoDataDelete] = useState(false)
    const [states, setStates] = useState([])
    const [loadingStates, setLoadingStates] = useState(false)
    const [counties, setCounties] = useState([])
    const [loadingCounties, setLoadingCounties] = useState(false)
    useEffect(() => {
        if (id !== null) {
            setLoadingStudy(true)
            OtrosEstudiosServices.GetOtherStudy(id, setInfoStudy, setLoadingStudy, setNoDataStudy)
        }
    }, [id])

    useEffect(() => {
        if (infoStudy.country === "COL") {
            setLoadingStates(true)
            OtrosEstudiosServices.GetStates(infoStudy.country, setStates, setLoadingStates)
        }
    }, [infoStudy.country])

    useEffect(() => {
        if (infoStudy.country === "COL") {
            setLoadingCounties(true)
            OtrosEstudiosServices.GetCounties(infoStudy.country, infoStudy.state, setCounties, setLoadingCounties)
        }
    }, [infoStudy.state])

    const deleteOneStudy = () => {
        Swal
            .fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: red; font-size: 16px"> Eliminar </div>',
                html: '<div style="text-align: center; font-size: 15px"> ¿Desea continuar la operación? </div>',
                showCancelButton: true,
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                confirmButtonColor: "red",
                cancelButtonColor: "#0169b5"
            }).then((result) => {
                if (result.isConfirmed) {
                    setLoadingDeleteStudy(true)
                    OtrosEstudiosServices.DeleteOtherStudy(id, setDeleteStudy, setLoadingDeleteStudy, setNoDataDelete)
                }
            })
    }

    // Mensaje de error al enviar un post
    const handleStatusError = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
            html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo.</div>`,
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadAcademicStudies()
        })
        setLoadingDeleteStudy(false)
        setNoDataDelete(false)
    }

    // Mensaje de éxito al enviar un post
    const handleStatusSuccess = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
            html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadAcademicStudies()
        })
        setLoadingDeleteStudy(false)
        setNoDataDelete(false)
    }

    useEffect(() => {
        // noDataDelete ? handleStatusError() : null
        deleteStudy ? handleStatusSuccess() : null
    }, [loadingDeleteStudy])

    return (
        <>
            {!loadingStudy ?
                <div className={`${styles["box"]} ${styles["mb-4"]}`}>
                    <div className={styles["row"]}>
                        <h2 className={`${styles["h4"]} ${styles["tit-section"]} ${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>Ver Estudios</h2>
                        <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                            <div className={` ${styles["btn"]} ${infoStudy.approval_status === "P" ? styles.Pendiente : infoStudy.approval_status === "A" ? styles.Aprobada : null}`}>
                                {infoStudy.approval_status === "P" ? "Pendiente" : infoStudy.approval_status === "A" ? "Aprobada" : null}
                            </div>
                        </div>
                    </div>
                    <form>
                        {/* <!-- Fila 1 --> */}
                        <div className={styles["row"]}>
                            <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                                <label className={styles["form-label"]}>Nombre del Programa <span className={styles["color-red"]}>*</span></label>
                                <input type="text" className={styles["form-control"]} defaultValue={infoStudy.program_name} disabled />
                            </div>
                            <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                                <label className={styles["form-label"]}>Institución <span className={styles["color-red"]}>*</span></label>
                                <input type="text" className={styles["form-control"]} defaultValue={infoStudy.institution} disabled />
                            </div>
                        </div>
                        {/* <!-- Fila 2 --> */}
                        <div className={styles["row"]}>
                            <div className={`${styles["col-12"]} ${styles["mb-3"]}`}>
                                <label className={styles["form-label"]}>Área Temática <span className={styles["color-red"]}>*</span></label>
                                <div className={styles["input-group"]}>
                                    <select className={styles["select-col"]} disabled>
                                        <option value="">Seleccione</option>
                                        {
                                            subjectAreas?.map((inst) => {
                                                return (
                                                    <option key={inst.id} value={inst.id} selected={inst.id === infoStudy.subject_area}>{inst.description}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Fila 3 --> */}
                        <div className={styles["row"]}>
                            <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                                <label className={styles["form-label"]}>País <span className={styles["color-red"]}>*</span></label>
                                <div className={styles["input-group"]}>
                                    <select className={styles["select-col"]} disabled>
                                        {
                                            countries.map((c) => {
                                                return (
                                                    <option key={c.country} value={c.country} selected={infoStudy.country === c.country}>{c.descr}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]} ${infoStudy.country === "COL" ? "d-none" : null}`}>
                                <label className={styles["form-label"]}>Ciudad <span className={styles["color-red"]}>*</span></label>
                                <input type="text" className={styles["form-control"]}value={infoStudy.city} disabled />
                            </div>
                        </div>
                        {/* <!-- Fila 4 --> */}
                        <div className={styles["row"]}>
                            <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]} ${infoStudy.country !== "COL" ? "d-none" : null}`}>
                                <label className={styles["form-label"]}>Departamento <span className={styles["color-red"]}>*</span></label>
                                <div className={styles["input-group"]}>
                                    {!loadingStates ? <select className={styles["select-col"]} disabled>
                                        {
                                            states.map(s => {
                                                return (
                                                    <option key={s.state} value={s.state} selected={infoStudy.state === s.state}>{s.descrshort}</option>
                                                )
                                            })
                                        }
                                    </select> : <div><div  className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                                </div>
                            </div>
                            <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]} ${infoStudy.country !== "COL" ? "d-none" : null}`}>
                                <label className={styles["form-label"]}>Ciudad <span className={styles["color-red"]}>*</span></label>
                                <div className={styles["input-group"]}>
                                    {!loadingCounties ? <select className={styles["select-col"]} disabled>
                                        {
                                            counties.map((c) => {
                                                return (
                                                    <option key={c.county} value={c.county} selected={infoStudy.county === c.county}>{c.descr}</option>
                                                )
                                            })
                                        }
                                    </select> : <div><div  className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                                </div>
                            </div>
                        </div>
                        {/* <!-- Fila 5 --> */}
                        <div className={styles["row"]}>
                            <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                                <label className={styles["form-label"]}>Estado <span className={styles["color-red"]}>*</span></label>
                                <div className={styles["row"]}>
                                    <div className={`${styles["col-12"]} ${styles["col-md-4"]}`}>
                                        <div className={styles["form-check"]}>
                                            <input className={styles["form-check-input"]} type="radio" name="study_status" id="estado1" value="T"
                                                checked={infoStudy.study_status === "T"} disabled />
                                            <label className={styles["form-check-label"]} htmlFor="estado1">Titulado</label>
                                        </div>
                                    </div>
                                    <div className={`${styles["col-12"]} ${styles["col-md-4"]}`}>
                                        <div className={styles["form-check"]}>
                                            <input className={styles["form-check-input"]} type="radio" name="study_status" id="estado2" value="P"
                                                checked={infoStudy.study_status === "P"} disabled />
                                            <label className={styles["form-check-label"]} htmlFor="estado2">En curso</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Fila 6 --> */}
                        <div className={styles["row"]}>
                                <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                                    <label className={styles["form-label"]}>Fecha de Inicio <span className={styles["color-red"]}>*</span></label>
                                    <input type="date" className={styles["form-control"]} defaultValue={infoStudy.study_start_date} disabled />
                                </div>
                                <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                                    <label className={styles["form-label"]}>Fecha de Finalización </label>
                                    <input type="date" className={styles["form-control"]}  defaultValue={infoStudy.study_end_date} disabled />
                                </div>
                            </div>
                        {/* <!-- fin filas --> */}
                    </form>

                    <div className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mt-4"]}`}>
                        <div className={`${styles["col-6"]} ${styles["col-md-auto"]}`}>
                            <button type="submit" className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`} onClick={() => deleteOneStudy()} disabled={loadingDeleteStudy || infoStudy.approval_status === "P"}>Eliminar {loadingDeleteStudy ? <div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div> : <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i>}</button>
                        </div>
                        <div className={`${styles["col-6"]} ${styles["col-md-auto"]}`}>
                            <button type="submit" className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`} onClick={() => changeDisplay(true, id, infoStudy)}
                                disabled={infoStudy.approval_status === "P"}
                            >Modificar <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i></button>
                        </div>
                    </div>

                </div>
                : <div data-testid="loading" className={styles["full-spinner"]}>
                    <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div> </div>}
            {noDataDelete ? handleStatusError() : null}
        </>
    );
}

export default DetalleOtrosEstudios;