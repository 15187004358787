import React, { useEffect, useRef, useState } from 'react'
import { LoadingContent } from '../../../utilities/constantsTexts'
import styles from '../styles/loadingStyle.module.css'
import { LoadingProps } from '../utils/interfaces'

const Loading = (props: LoadingProps) => {
  const { normalMessage, infoMessage, noMessage, smallSpinner, bigSpinner } = props
  const [loadingMessage, setLoadingMessage] = useState('')
  const spinner = useRef<HTMLDivElement>(null)

  useEffect(() => {
    normalMessage && setLoadingMessage(LoadingContent.simpleLoading)
    infoMessage && setLoadingMessage(LoadingContent.loading)
    noMessage && setLoadingMessage('')
    addCustomClass()
  }, [])

  const addCustomClass = () => {
    if(smallSpinner && spinner.current) {
      spinner.current.classList.add(styles.small_spinner)
    }
    if(bigSpinner && spinner.current) {
      spinner.current.classList.add(styles.big_spinner)
    }
  }

  return (
    <div className={styles.loading_wrapper}>
      <div className={styles.custom_spinner} ref={spinner}></div>
      {loadingMessage !== '' && <p className={styles.loading_text}>{loadingMessage}</p>}
    </div>
  );
}

export default Loading;