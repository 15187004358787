import { useSubscriptionContext } from "./context";
import { useNavigate } from "react-router-dom";

export const SubscriptionFooter = () => {
  const navigate = useNavigate();
  const { saveNewsLetter, disabledSubmit, disabledDelete } =
    useSubscriptionContext();

  const onBack = () => {
    navigate("/inicio");
  };

  return (
    <div className="d-grid d-md-flex justify-content-md-between gap-2 flex-nowrap mt-5">
      <div className="order-md-last d-grid d-md-flex gap-2">
        <button
          type="submit"
          className="btn btn-degradado"
          data-bs-toggle="modal"
          disabled={disabledSubmit || disabledDelete}
          data-bs-target="#success"
          onClick={saveNewsLetter}
        >
          Guardar
          <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
        </button>
      </div>

      <button onClick={onBack} className="btn btn-link order-md-first">
        Volver
      </button>
    </div>
  );
};
