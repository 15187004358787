import React, { useEffect, useState } from 'react';
import menuService from '../../../services/permissions/access-service';

const usePagesByRoles = () => {
    const [pages, setPages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const getPages = async () => {
        try {
            setIsLoading(true);
            const { data } = await menuService.getPagesByRoles();
            setPages(data);
        } catch(err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getPages();
    }, []);

    useEffect(() => {
        if (pages.length > 0) {
            const uniqueCountriesSet = new Set(pages.map(p => p.country));
            setCountries(Array.from(uniqueCountriesSet));
        }
    }, [pages]);

    return { isLoading, pages, countries, getPages };
}

export default usePagesByRoles;