import React from "react";

import "tippy.js/dist/tippy.css";
import { useState } from "react";
import { SolicitudRegistroViaje } from "./SolicitudRegistroViaje";
import { ViajesRegistrados } from "./ViajesRegistrados";
import { HeaderRegistroViaje } from "./HeaderRegistroViaje";
import { useEffect } from "react";
import { MessageDinamic } from "../commonComponents/MessageDinamic";
import { getRegistroViaje } from "../../services/RegistroViaje-UY/registroViaje-services";

const RegistroViaje = () => {
  const defaultRegistroViajeData = {
    beginDt: null,
    endDt: null,
    country: "",
    comments: "",
  };
  const defaultRequest = {
    loading: false,
    loadingDelete: false,
    error: false,
    success: false,
    errorDelete: false,
    successDelete: false,
    message: "",
  };

  const [RegistroViajeData, setRegistroViaje] = useState(
    defaultRegistroViajeData
  );

  const [statusRequest, setstatusRequest] = useState(defaultRequest);

  const [viajesRegistrados, setviajesRegistrados] = useState([]);

  useEffect(() => {
    setloadingData(true);
    getRegistroViaje(setviajesRegistrados, setloadingData);
  }, []);

  useEffect(() => {
    if (statusRequest.error) {
      MessageDinamic("error","error", statusRequest.message );
      setstatusRequest(defaultRequest);
    }
    if (statusRequest.success) {
      MessageDinamic("success","correcto", statusRequest.message);
      getRegistroViaje(setviajesRegistrados, setloadingData);
      setRegistroViaje(defaultRegistroViajeData);
      setstatusRequest(defaultRequest);
    }
    if (statusRequest.errorDelete) {
      MessageDinamic("error","error", statusRequest.message);
      setstatusRequest(defaultRequest);
    }
    if (statusRequest.successDelete) {
      MessageDinamic("success","correcto", statusRequest.message);
      getRegistroViaje(setviajesRegistrados, setloadingData);
      setstatusRequest(defaultRequest);
    }
  }, [statusRequest]);

  const [loadingData, setloadingData] = useState(false);
  return (
    <>
      <HeaderRegistroViaje />
      <div>
        <div className="card-box d-md-flex align-items-center mb-5  text-md-start">
          <SolicitudRegistroViaje
            RegistroViaje={RegistroViajeData}
            setRegistroViaje={setRegistroViaje}
            RegistroViajeData={RegistroViajeData}
            statusRequest={statusRequest}
            setstatusRequest={setstatusRequest}
          />
        </div>
      </div>
       <ViajesRegistrados
        viajesRegistrados={viajesRegistrados}
        loading={loadingData}
        statusRequest={statusRequest}
        setstatusRequest={setstatusRequest}
      /> 
    </>
  );
};

export default RegistroViaje;
