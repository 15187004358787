import { SpinerFullScreen } from "../commonComponents/SpinerFullScreen";
import { SubscriptionActions } from "./SubscriptionActions";
import { SubscriptionFooter } from "./SubscriptionFooter";
import { SubscriptionItem } from "./SubscriptionItem";
import { useSubscriptionContext } from "./context";
import { ModalConfirmTerms, ModalSaveSub } from "./modals";

export const SubscriptionContent = () => {
  const { isLoadingSubscriptions, subscriptionsData } =
    useSubscriptionContext();

  if (isLoadingSubscriptions) return <SpinerFullScreen />;

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-start pe-4 mb-4">
        <h2 className="h4 tit-section">Suscripciones</h2>
        <p className="mb-0 mt-1">
          Última modificación: {subscriptionsData.lastUpdate}
        </p>
      </div>

      <div className="row">
        {subscriptionsData.newsletter?.map((item, i) => (
          <div key={i} className="col-md-4 mb-2 mb-md-0">
            <h3 className="h5 mb-3">{item.category}</h3>

            {item.subscription.map((sub) => (
              <div className="ms-1" key={`${sub.value}${i}`}>
                <SubscriptionItem subscription={sub} />
              </div>
            ))}
          </div>
        ))}
      </div>

      <SubscriptionActions />

      <SubscriptionFooter />

      <ModalSaveSub />
      <ModalConfirmTerms />
    </div>
  );
};
