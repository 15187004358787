import React, { Children, useState } from "react";
import { UseFormRegister, FieldErrorsImpl } from "react-hook-form";
import { handleClick } from "../../../utilities/functions";
import { ConflictData, Doc, EthicsData, PolicyData, RulesData } from "../../../utilities/interfaces";

interface Props {
    title: string;
    message?: string;
    subtitle?: string;
    checkontop?: boolean;
    text: Array<string>;
    setFile?: React.Dispatch<React.SetStateAction<Doc | null>>;
    company?: string;
    docType?: string;
    downloadedFile?: boolean;
    register: UseFormRegister<EthicsData | RulesData | ConflictData | PolicyData>;
    targetFile: keyof EthicsData | keyof RulesData | keyof ConflictData | keyof PolicyData;
    checked: boolean;
    setChecked: React.Dispatch<React.SetStateAction<boolean>>;
    radioValue?: string;
    targetRadio?: keyof ConflictData;
    errors: Partial<FieldErrorsImpl>;
}

const DocumentSection: React.FC<Props> = ({ title, message, subtitle, checkontop, text, setFile, company, docType, downloadedFile, register, targetFile, radioValue, targetRadio, checked, setChecked, errors }) => {
    const [showRadios, setShowRadios] = useState<boolean>(false);

    return (
        <>
            <h2 className="h4 tit-section" children={title} />
            {subtitle && <h3 className="h5 mb-4">Lea el siguiente texto y luego seleccione la opción que corresponda.</h3>}
            {message && <p>{message}</p>}
            {checkontop &&
                <div className="d-flex align-items-center px-2">
                    <input
                        className="form-check-input border-primary me-2 h5"
                        type="checkbox"
                        id={targetFile}
                        onClick={() => setChecked((oldChecked) => !oldChecked)}
                        checked={checked}
                        onChange={(e) => { e.preventDefault; setShowRadios(!showRadios) }}
                    />
                    <label className="form-check-label color-primary-strong mb-2" htmlFor={targetFile}>
                        {"He leído y acepto mi declaración de conflictos de interés"}
                    </label>
                    {errors[targetFile] && (
                        <div className="invalid-feedback d-block">
                            {"Debe aceptar haber leído el documento"}
                        </div>
                    )}
                </div>}
            <div className="mb-3">
                {setFile !== undefined && company !== undefined && docType !== undefined ?
                    <>
                        <a
                            href="/ficha-ingreso"
                            onClick={handleClick({ setDocument: setFile, company: company, docType: docType, idBtn: "downloadDocumentModal" })}
                            className="text-decoration-none"
                        >
                            <span className="text-uppercase fw-bolder">{"Descargar"}</span>
                            <i className="fa fa-file-pdf-o color-primary ms-2 fa-lg" aria-hidden="true" />
                        </a>
                        {downloadedFile ? (
                            <>
                                <i className="fa fa-check color-primary ms-2 fa-lg" aria-hidden="true" />
                            </>
                        ) : null}
                        {!downloadedFile ? (
                            <div className="invalid-feedback d-block mt-2">
                                {"Debe descargar el documento"}
                                <i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" />
                            </div>
                        ) : null}
                    </>
                    :
                    targetRadio !== undefined && checkontop && showRadios ?
                        <>
                            <h3 className="bg-light mb-0 h5 p-3">Tengo una situación que pudiera ser o parecer un conflicto de interés.</h3>
                            <div className="rounded-3 bg-light d-flex p-2 color-primary mb-2">
                                <div className="form-check d-flex align-items-center d-inline-block mx-2">
                                    <input
                                        id="inlineRadio1"
                                        {...register(targetRadio, {
                                            required: "Debe seleccionar una opción"
                                        })}
                                        className="form-check-input border-primary"
                                        defaultChecked={radioValue === "1" ? true : false}
                                        type="radio"
                                        value="1"
                                    />
                                    <label className="form-check-label text-primary" htmlFor="inlineRadio2">
                                        {"Si"}
                                    </label>
                                </div>
                                <div className="form-check d-flex align-items-center d-inline-block mx-4">
                                    <input
                                        id="inlineRadio1"
                                        {...register(targetRadio, {
                                            required: "Debe seleccionar una opción"
                                        })}
                                        className="form-check-input border-primary"
                                        defaultChecked={radioValue === "1" ? false : true}
                                        type="radio"
                                        value="0"
                                    />
                                    <label className="form-check-label text-primary" htmlFor="inlineRadio1">
                                        {"No"}
                                    </label>
                                </div>
                            </div>
                        </>
                        : null}
                <input type="hidden" {...register(targetFile, {
                    required: "Debe aceptar",
                    pattern: {
                        value: /[^0]/,
                        message: "Debe aceptar haber leído el documento",
                    }
                })} />
            </div>
            {!message && Children.toArray(text.map((p, i) => {
                return (
                    <p className="lh-base" key={i}>{p}</p>
                )
            }))}
            {!checkontop &&
                <div>
                    <input
                        className="form-check-input border-primary me-2 h5"
                        type="checkbox"
                        id={targetFile}
                        onClick={() => setChecked((oldChecked) => !oldChecked)}
                        checked={checked}
                    />
                    <label className="form-check-label color-primary-strong mt-1" htmlFor={targetFile}>
                        {"He leído y acepto el documento"}
                    </label>
                    {errors[targetFile] && (
                        <div className="invalid-feedback d-block">
                            {"Debe aceptar haber leído el documento"}
                        </div>
                    )}
                </div>}      
        </>
    )
}

export default DocumentSection;