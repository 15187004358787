import React from "react";
import { useCencoTalent } from "../../hooks/useCencoTalent";
import { JobCardIconInfo } from "../ui/JobCardIconInfo";
import {
    FaBriefcase,
    FaBuilding,
    FaCalendarAlt,
    FaFileAlt,
    FaMapMarkerAlt,
    FaRegClock,
    FaUniversalAccess,
    FaUsers,
} from "react-icons/fa";

export const DetailsLeftMobile = () => {
    const { activeJob } = useCencoTalent();

    return (
        <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
            <div className="accordion-item">
                <h2 className="accordion-header mb-2" id="accordion">
                    <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-1"
                        aria-expanded="true"
                        aria-controls="accordion-1"
                    >
                        <strong>Detalles de la oferta</strong>
                    </button>
                </h2>
                <div
                    id="accordion-1"
                    className="accordion-collapse collapse show"
                    aria-labelledby="accordion"
                >
                    <div className="accordion-body p-0">
                        <ul className="list-unstyled fs-6">
                            {activeJob?.disability && (
                                <JobCardIconInfo
                                    text="Apto para personas con discapacidad"
                                    icon={
                                        <FaUniversalAccess className="color-orange" />
                                    }
                                    className="mb-1"
                                />
                            )}

                            <JobCardIconInfo
                                text={activeJob?.workingHours ?? ""}
                                icon={
                                    <FaCalendarAlt className="color-orange" />
                                }
                                className="mb-1"
                            />

                            <JobCardIconInfo
                                text={activeJob?.area ?? ""}
                                icon={<FaBriefcase className="color-orange" />}
                                className="mb-1"
                            />

                            <JobCardIconInfo
                                text={
                                    `${activeJob?.country} ${activeJob?.address}` ??
                                    ""
                                }
                                icon={
                                    <FaMapMarkerAlt className="color-orange" />
                                }
                                className="mb-1"
                            />

                            <JobCardIconInfo
                                text={activeJob?.employmentType ?? ""}
                                icon={<FaFileAlt className="color-orange" />}
                                className="mb-1"
                            />

                            <JobCardIconInfo
                                text={activeJob?.flag ?? ""}
                                icon={<FaBuilding className="color-orange" />}
                                className="mb-1"
                            />

                            <JobCardIconInfo
                                text={activeJob?.workModality ?? ""}
                                icon={<FaUsers className="color-orange" />}
                                className="mb-1"
                            />

                            <JobCardIconInfo
                                text={activeJob?.datetimeCreation ?? ""}
                                icon={<FaRegClock className="color-orange" />}
                                className="mb-1"
                            />
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};
