import { Link } from "react-router-dom";
import clsx from "../../../../utils/clsx";
import { useTeamDashboardProvider } from "../../context/TeamDashboardContext";

export const Breadcrumb = () => {
  const { getSelectedMenu, resetMenus } = useTeamDashboardProvider();

  const onBack = () => {
    getSelectedMenu && resetMenus();
  };

  return (
    <nav className="breadcrumbs" aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/inicio">
            <i
              style={{ pointerEvents: "none" }}
              className="fa fa-home"
              aria-hidden="true"
            ></i>{" "}
            Inicio
          </Link>
        </li>

        <li
          className={clsx("breadcrumb-item", {
            active: !getSelectedMenu?.label,
          })}
          aria-current="page"
        >
          <Link to="" onClick={onBack}>
            Dashboard
          </Link>
        </li>

        {getSelectedMenu?.label && (
          <li className="breadcrumb-item active" aria-current="page">
            <Link to="">{getSelectedMenu?.label}</Link>
          </li>
        )}
      </ol>
    </nav>
  );
};
