import React, { useState, useEffect } from 'react';
import styles from './replacement-assignment.module.css';
import PropTypes from 'prop-types';
import moment from 'moment';
import Swal from 'sweetalert2';
import { MdOutlineCancel } from 'react-icons/md';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import ReplacementsService from '../../services/asignacion-reemplazo/replacements-service';

const ReplacementAssignmentTable = ({ replacements, onUpdate, handleClick }) => {

    ReplacementAssignmentTable.propTypes = {
        replacements: PropTypes.shape({
            fullname: PropTypes.string,
            init_date: PropTypes.string,
            return_date: PropTypes.string,
        }),
    };

    const [currentLoadingState, setCurrentLoadingState] = useState(false);
    const [invalidationMessage, setInvalidationMessage] = useState('');

    useEffect(() => {
        if (invalidationMessage) {
            if (invalidationMessage.success) {
                Swal.fire({
                    position: 'center',
                    title: '<div style="text-align: center; color: green; font-size: 16px"> Correcto</div>',
                    html: `<div style="text-align: center; font-size: 15px"> ${invalidationMessage.message} </div>`,
                    confirmButtonColor: '#0169b5',
                }).then((result) => {
                    if (result.isConfirmed) {
                        onUpdate(true);
                    }
                });
            } else
                Swal.fire({
                    position: 'center',
                    title: '<div style="text-align: center; color: red; font-size: 16px"> Error</div>',
                    html: `<div style="text-align: center; font-size: 15px"> ${invalidationMessage.message} </div>`,
                    confirmButtonColor: '#0169b5',
                });
        }
    }, [invalidationMessage])
    const handleInvalidation = async (id) => {
        Swal.fire({
            position: 'center',
            title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia</div>',
            html: `<div style="text-align: center; font-size: 15px"> Está seguro que desea invalidar este reemplazo?</div>`,
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#0169b5',
            confirmButtonText: 'Invalidar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setCurrentLoadingState(true);
                await ReplacementsService.invalidateReplacement(id, setCurrentLoadingState, setInvalidationMessage)
            };
        })
    }

    return (
        <div className={`container justify-content-center ${styles['center-align']}`}>
            <div className={styles['table-box-container']}>
                <div class="d-flex justify-content-end">
                    <Tippy content={"Recargar Tabla"}><span onClick={() => { handleClick() }} style={{ cursor: 'pointer' }}><i class="fa fa-refresh"></i></span></Tippy>
                </div>
                <table className={`table table-even table-section table-borderless d-md-table`}>
                    <thead>
                        <tr>
                            <th className={styles['table-head']}>Reemplazo Actual</th>
                            <th className={styles['table-head']}>Fecha Inicio</th>
                            <th className={styles['table-head']}>Fecha Fin</th>
                            <th className={styles['table-head']}>Estado</th>
                            <th className={styles['table-head']}>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            replacements.map(replacement => {
                                return (
                                    <tr>
                                        <td className={styles['table-content']}>{replacement?.fullname ? replacement.fullname : 'Sin reemplazo'}</td>
                                        <td className={styles['table-content']}>{replacement?.init_date ? moment(replacement?.init_date).format('DD-MM-YYYY') : '-'}</td>
                                        <td className={styles['table-content']}>{replacement?.return_date ? moment(replacement?.return_date).format('DD-MM-YYYY') : '-'}</td>
                                        <td className={`${styles['table-content']} ${styles.status} ${!replacement ? '' : replacement.status === 1 ? styles.valid : styles.invalid}`}>
                                            {!replacement ? '-' : replacement.status === 1 ? "vigente" : "no vigente"}
                                        </td>
                                        <td className={`${styles['table-content']}`}>
                                            {currentLoadingState ?
                                                <div className={`d-flex ${styles['table-loading-spinner']}`}>
                                                    <div className={`spinner-border spinner-border-sm text-primary m-2`}></div>
                                                </div> :
                                                replacement ? (
                                                    <div className={styles['edit-btn']} onClick={replacement.status === 0 ? null : () => handleInvalidation(replacement.id)}>
                                                        <Tippy content={"Invalidar Reemplazo"}><div><MdOutlineCancel className={replacement.status === 0 ? styles['invalidation-disabled'] : ''} /></div></Tippy>
                                                    </div>
                                                ) : '-'
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ReplacementAssignmentTable;
