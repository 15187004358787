import React, { FC } from "react";


const ModalNoConflicto : FC<any> = ({ modalNoConflictoFunction }) => {
    return <>
        {/*Modal no-conflicto */}
        <div className="modal fade" id="no-conflictomodal" tabIndex={-1} aria-labelledby="no-conflictomodal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                        <button className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center py-4">
                        <h2 className="h5">Usted está declarando que no tiene una situación que pudiera ser o parecer un conflicto de interés.</h2>
                    </div>
                    <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                        <button className="btn btn-primary col-12 col-md-auto" data-bs-dismiss="modal">Cancelar</button>
                        <button onClick={() => { modalNoConflictoFunction() }} className="btn btn-degradado col-12 col-md-auto" data-bs-dismiss="modal">Confirmar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default ModalNoConflicto;