import { Dispatch, SetStateAction } from "react";
import * as t from "io-ts";

interface DonateProps {
    setShowDonations: Dispatch<SetStateAction<boolean>>;
}

interface DonationPost {
    amount: number,
    type: string
}

interface GetDonate {
    response: {
        valid: boolean,
        status: string,
        canDonate: boolean,
        amount: number,
    }
}

interface PostDonate {
    response: {
        valid: boolean,
        status: string,
    }
}

interface DataModalDinamicProps {
    icon: string;
    msg: string;
    submsg?: string;
    callBack: () => void;
}

export const donation = t.type({
    amount: t.number,
    created: t.string,
    type: t.string
})

export const ResponseDonate = t.type({
    canDonate: t.boolean,
    amount: t.number,
    status: t.string,
    valid: t.boolean,
});

export const fetchGetDonateListCodec = t.type({
    response: ResponseDonate
});

export type ResponseGetDonate = t.TypeOf<typeof fetchGetDonateListCodec>;

export const ResponseDonatePost = t.type({
    status: t.string,
    valid: t.boolean,
});

export const fetchPostDonateListCodec = t.type({
    response: ResponseDonatePost
});

export type ResponsePostDonate = t.TypeOf<typeof fetchPostDonateListCodec>;

export type {
    DonateProps,
    GetDonate,
    PostDonate,
    DataModalDinamicProps,
    DonationPost
}
