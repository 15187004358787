import React, { useEffect, useState, useContext } from 'react';
import moduloDtService from '../services/modulo-dt-service';
import Modales from './elements/modal-dt';
import CarpetaDigitalService from '../services/carpeta-digital-service';
import { encrypt } from '../services/utiles';
import PropTypes from 'prop-types';
import { Context } from '../context';
import { DTGobNorm } from './commonComponents/DTGobNorm';

const CarpetaDigitalDt = () => {
    const { setAuditoria, firstLoginData } = useContext(Context);
    const [datosTrabajador, setDatosTrabajador] = useState(false);
    const [data, setData] = useState([]);
    const [msj, setMsj] = useState(false);
    const [status, setStatus] = useState(false);
    const [rut, setRut] = useState(false);
    const [doc, setDoc] = useState(false);
    const [docStatus, setDocStatus] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const ModalBasico = Modales.ModalBasico;
    const ModalNoPermiso = Modales.ModalNoPermiso;

    const handleSubmit = async (e) => {
        const ruts = document.getElementById("rut").value;
        e.preventDefault();
        setDatosTrabajador(false);
        setMsj(false);
        setRut(false);
        setData([]);
        moduloDtService.validateRut(ruts, firstLoginData.USERDT, setDatosTrabajador, setMsj, setRut);
        setAuditoria({ user: encrypt(firstLoginData.USERDT), descripcion: encrypt("valida permisos para consultar el usuario carpeta digital"), rut: encrypt(ruts) })
    }

    useEffect(() => {
        if (msj == true && datosTrabajador != false && rut != false) {
            const ruts = document.getElementById("rut").value;
            document.getElementById("openModalCarga").click();
            setData([]);
            CarpetaDigitalService.getCarpetaDigital(rut, setData, setStatus, firstLoginData.USERDT);
            setAuditoria({ user: encrypt(firstLoginData.USERDT), descripcion: encrypt("solicitar documentos carpeta digital"), rut: encrypt(ruts) })

        } else if (msj == 2 && datosTrabajador == 2 && rut == 0) {
            document.getElementById("openModalPermiso").click();
            setDatosTrabajador(false);
            setMsj(false);
            setRut(false);
            setData(false);
        }
    }, [rut]);

    useEffect(() => {
        if (status != false) {
            setTimeout(function () {
                document.getElementById("closeModalCargando").click();
                document.getElementById("openModalNoDocumento").click();
            }, 1000);
        }
    }, [status])

    useEffect(() => {
        if (data === false) {
            setTimeout(function () {
                document.getElementById("closeModalCargando").click();
            }, 500);
        } else if (data.length > 0) {
            setTimeout(function () {
                document.getElementById("closeModalCargando").click();
            }, 500);
        }
    }, [data]);

    const desencriptarArchivo = (value, name) => {
        name = name.replaceAll(" ", "_").replaceAll(".", "_");
        value = Buffer.from(value, "base64");
        let link = document.createElement("a");
        let blob = new Blob([value], { type: "application/pdf" });
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.dispatchEvent(new MouseEvent("click"));
        setDoc(false);
    };

    const disabledBtn = (e) => {
        e.preventDefault();
        let input = e.target.value;
        let btn = document.getElementById("btnBuscar");
        if (input == "") {
            btn.disabled = true;
        } else {
            btn.disabled = false;
        }
    }

    const descargarDoc = (doc) => {
        const ruts = document.getElementById("rut").value;
        document.getElementById("openModalDescarga").click();
        CarpetaDigitalService.descargarDocumento(rut, doc, setDoc, setDocStatus, "", firstLoginData.USERDT);
        setAuditoria({ user: encrypt(firstLoginData.USERDT), descripcion: encrypt("descargar documento carpeta digital ID: " + doc), rut: encrypt(ruts) })
    }

    useEffect(() => {
        if (doc != false && doc !== 2) {
            setTimeout(() => {
                document.getElementById("closeModalDescargando").click();
            }, 1000);
            const nombre = data.find(document => document.codigo == itemSelected);
            desencriptarArchivo(doc, nombre.descripcion);
        }
    }, [doc])

    return (
        <>
            <ModalBasico idBtn="openModalNoDocumento" target="modalNoHayDocumentos" idModal="modalNoHayDocumentos" msj="No hay documentos" p="No se encontraron documento" modalCierre="closeModalBasico" icon="fa fa-exclamation-triangle color-orange fa-3x" ></ModalBasico>
            <ModalNoPermiso idBtnOpen="openModalPermiso"></ModalNoPermiso>
            <ModalBasico idBtn="openModalCarga" target="modalCargando" idModal="modalCargando" msj="Cargando..." p="" modalCierre="closeModalCargando" icon="fa fa-exclamation-triangle color-orange fa-3x" ></ModalBasico>
            <ModalBasico idBtn="openModalDescarga" target="modalDescarga" idModal="modalDescarga" msj="Descargando..." p="" modalCierre="closeModalDescargando" icon="fa fa-exclamation-triangle color-orange fa-3x" ></ModalBasico>

            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">Carpeta Digital</li>
                </ol>
            </nav>
            <section className="carpeta-digital">
                <h1>Carpeta Digital</h1>
                <div className="box mb-4">
                    <h2 className="h4 tit-section">Documentos Digitales</h2>
                    <h3 className="h5 my-4">Iniciar Búsqueda</h3>
                    <form onSubmit={async (e) => { handleSubmit(e); }} role="form" className="needs-validation" noValidate="noValidate">
                        <div className="row mb-2">
                            <div className="col-xl-6">
                                <div className="mb-3 row">
                                    <label htmlFor="rut" className="col-md-2 col-form-label "><strong>RUT:</strong></label>
                                    <div className="col-sm-6 mb-3">
                                        <input type="text" onChange={(e) => disabledBtn(e)} className="form-control" id="rut" required="required"></input>
                                        <div className="invalid-feedback">
                                            * Ingresar rut sin puntos y con guión.
                                        </div>
                                        <p className="mt-3">
                                            Indicar RUT sin puntos y con guión, del colaborador que desea buscar.
                                        </p>
                                    </div>
                                    <div className="col d-grid d-sm-block">
                                        <button type="submit" id='btnBuscar' disabled className="btn btn-degradado" >Buscar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="mb-3 row">
                                    <label htmlFor="name" className="col-md-2 col-xl-3 col-form-label"><strong>NOMBRE:</strong></label>
                                    <div className="col-md-10 col-xl-9">
                                        <input type="text" className="form-control" id="name" defaultValue={datosTrabajador.name} disabled readOnly></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {rut ?
                            <>
                                <hr className="mb-5"></hr>
                                <div className="row">
                                    <div className="col-12 col-xl-6 mb-4">
                                        <select onChange={(e) => setItemSelected(e.target.value)} id="inputState" className="form-select">
                                            {
                                                <>
                                                    {data.length > 0 ?
                                                        <>
                                                            <option selected disabled value={false}>Seleccione una opción</option>
                                                            {data.map((documento) => {
                                                                return (
                                                                    <>
                                                                        <option value={documento.codigo}> {documento.descripcion}</option>
                                                                    </>)
                                                            })}
                                                        </>
                                                        : <option selected>No hay documentos para mostrar</option>
                                                    }
                                                </>
                                            }
                                        </select>
                                    </div>
                                    <div className="col d-grid d-md-block">
                                        <button type="button" disabled={itemSelected == false} onClick={() => descargarDoc(itemSelected)} className="btn btn-degradado">Descargar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </>
                            : null
                        }
                    </form>
                </div>
                <DTGobNorm />
            </section>
        </>
    );
}

CarpetaDigitalDt.propTypes = {
    userDt: PropTypes.any,
    auditoria: PropTypes.any,
};
export default CarpetaDigitalDt;