import React, { FC, useEffect, useRef, useState } from "react";
import { crudNews, getNewsList, getExcelAllResumen } from "../../../utilities/apiServices/apiRequest";
import { DataModalDinamicProps, IndexProps, PostNews, ResponseNewsList } from "../../../utilities/interfaces";
import TableIndex from "./components/tableIndex/tableNews";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import * as RD from "@devexperts/remote-data-ts";
import { RemoteData } from "@devexperts/remote-data-ts";
import { httpClientEnv } from "../../../../../services/apiServices/httpClient";
import { HttpError } from "../../../../../services/apiServices/interfaces";
import { SpinerFullScreen } from "../../../../commonComponents/SpinerFullScreen";
import SelectIndex from "./components/selectsTable/selectIndex";
import ModalConfirmAction from "../../../../commonComponents/ModalConfirmAction";
import ModalInfoDinamic from "../../../../commonComponents/ModalnfoDinamic";
import { scrollToTop } from "../../../../../services/utiles";

const IndexNews: FC<IndexProps> = ({
    idNews,
    reload,
    refConfirmAction,
    refStatus,
    setAdminCategory,
    setReload,
    setIdNews,
    setIsEditNews,
    setNewNews,
    setShowTable,
    pais,
    refLoad,
    refLoadClose,
    isSubtitleVisible,
    setIsSubtitleVisible
}) => {
    const [filterCountry, setFilterCountry] = useState(pais || '');
    const [startDateFilter, setStartDateFilter] = useState('');
    const [endDateFilter, setEndDateFilter] = useState('');
    const [filterCategory, setFilterCategory] = useState(0);
    const [filterStatus, setFilterStatus] = useState('');
    const [newsList, setNewsList] = useState<RemoteData<HttpError, ResponseNewsList>>(RD.initial);
    const [excelList, setExcelList] = useState(-1);
    const refCloseStatus = useRef<HTMLButtonElement>(null);
    const [remoteDataDeleteNews, setRemoteDataDeleteNews] = useState<RemoteData<HttpError, PostNews>>(RD.initial);
    const [dataModal, setDataModal] = useState<DataModalDinamicProps>({
        callBack: () => { },
        icon: '',
        msg: ''
    })

    const GetNewsList = async () => {
        setNewsList(RD.pending)
        RTE.run(await getNewsList(), httpClientEnv)
            .then(E.fold(e => { return setNewsList(RD.failure(e)) }, a => {
                let data = RD.success(a)
                if (data._tag === 'RemoteSuccess') {
                    return setNewsList(RD.success(a));
                } else {
                    return setNewsList(RD.failure({ tag: 'httpRequestError', error: 'error GetNewsList' }));
                }
            }))
    };

    const DeleteNews = async () => {
        setRemoteDataDeleteNews(RD.pending)

        let body = {
            id: idNews
        }

        RTE.run(await crudNews(body, 'DELETE'), httpClientEnv)
            .then(E.fold(e => { return setRemoteDataDeleteNews(RD.failure(e)) }, a => {
                let data = RD.success(a)
                if (data._tag === 'RemoteSuccess' && data.value.valid) {
                    refStatus && refStatus.current && refStatus.current.click();
                    setDataModal({
                        msg: 'Noticia despublicado correctamente.',
                        icon: 'fa-check-circle-o',
                        callBack: () => {
                            setReload(true)
                            refCloseStatus && refCloseStatus.current && refCloseStatus.current.click();
                        }
                    })
                    return setRemoteDataDeleteNews(RD.success(a))
                } else {
                    return setRemoteDataDeleteNews(RD.failure({ tag: 'httpRequestError', error: 'Valid deleteNews' }))
                }
            }))
    };

    const HandleDownloadExcel = (date_from: string, date_to: string) => {

        if (!date_from || !date_to) {
            refStatus && refStatus.current && refStatus.current.click();
            setDataModal({
                msg: 'Para descargar la información, por favor seleccione una fecha de Vigencia Desde y Hasta.',
                icon: 'fa-exclamation-triangle',
                callBack: () => {
                    refCloseStatus && refCloseStatus.current && refCloseStatus.current.click();
                }
            })
        } else {
            refLoad && refLoad.current && refLoad.current.click();
            getExcelAllResumen(setExcelList, filterCountry, filterCategory, startDateFilter, endDateFilter, filterStatus, refLoadClose)
        }
    }

    useEffect(() => {
        GetNewsList();
        scrollToTop();
    }, [])

    useEffect(() => {
        if (reload) {
            GetNewsList();
            setReload(false);
        }
    }, [reload])

    useEffect(() => {
        if (remoteDataDeleteNews._tag === 'RemoteFailure') {
            setDataModal({
                msg: 'Ha ocurrido un error al eliminar noticia, por favor, intente más tarde.',
                icon: 'fa-exclamation-triangle',
                callBack: () => {
                    refCloseStatus && refCloseStatus.current && refCloseStatus.current.click();
                }
            })

            setTimeout(() => {
                refStatus && refStatus.current && refStatus.current.click();
            }, 500)
        }
    }, [remoteDataDeleteNews])

    useEffect(() => {
        if (excelList === 1) {
            setDataModal({
                msg: 'Ha ocurrido un error al obtener el reporte. Por favor, intente más tarde.',
                icon: 'fa-exclamation-triangle',
                callBack: () => {
                    refCloseStatus && refCloseStatus.current && refCloseStatus.current.click();
                }
            })

            setTimeout(() => {
                refStatus && refStatus.current && refStatus.current.click();
                setExcelList(-1);
            }, 500)
        }
    }, [excelList])

    useEffect(() => {
        if (filterCountry) {
            setFilterCategory(0);
        }
    }, [filterCountry])

    return (
        <>
            <section className="gestion-de-noticias">
                <h1>Gestión de Noticias</h1>
                <div className="box">
                    <div className="tit-group d-md-flex justify-content-between">
                        <h2 className="h4 tit-section">Noticias</h2>
                        <div className="mb-3 mb-md-0">
                            <div className="d-grid d-md-flex gap-2">
                                <button type="button" className="btn btn-primary"
                                    onClick={() => {
                                        setShowTable(false);
                                        setAdminCategory(true);
                                    }}> Administrar Categoría
                                </button>
                                <button type="button" className="btn btn-degradado ms-md-2"
                                    onClick={() => {
                                        setShowTable(false);
                                        setNewNews(true);
                                    }}> Nueva Noticia <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                                </button>
                                {newsList._tag === 'RemoteSuccess' &&
                                    <a onClick={() => {
                                        HandleDownloadExcel(startDateFilter, endDateFilter);
                                    }}>
                                        <i className="fa fa-file-excel-o fa-2x mt-1 btnLink" aria-hidden="true" title="Descargar reporte de noticias"></i>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>

                    {newsList._tag === 'RemoteSuccess' ?
                        <>
                            <SelectIndex
                                filters={newsList.value.response.filters}
                                setEndDateFilter={setEndDateFilter}
                                setFilterCountry={setFilterCountry}
                                setStartDateFilter={setStartDateFilter}
                                setFilterCategory={setFilterCategory}
                                setFilterStatus={setFilterStatus}
                                pais={pais}
                                filterCountry={filterCountry}
                            />
                            <TableIndex
                                newsList={newsList.value.response}
                                endDateFilter={endDateFilter}
                                filterCountry={filterCountry}
                                startDateFilter={startDateFilter}
                                filterCategory={filterCategory}
                                filterStatus={filterStatus}
                                setIdNews={setIdNews}
                                setIsEditNews={setIsEditNews}
                                setShowTable={setShowTable}
                                refConfirmAction={refConfirmAction}
                                refStatus={refStatus}
                                setDataModal={setDataModal}
                                refLoad={refLoad}
                                refLoadClose={refLoadClose}
                                refCloseStatus={refCloseStatus}
                            />
                        </>
                        : newsList._tag === 'RemoteInitial' || newsList._tag === 'RemotePending' ?
                            <div className="text-center mt-5">
                                <SpinerFullScreen />
                            </div>
                            : newsList._tag === 'RemoteFailure' &&
                            <div className="text-center mt-5">
                                <p>Ha ocurrido un error al obtener la información, por favor, intente más tarde.</p>
                            </div>
                    }
                </div>

            </section>

            <ModalConfirmAction refOpen={refConfirmAction} text={`¿Estás seguro que deseas despublicar?`} callBack={() => { DeleteNews(); }} />

            <ModalInfoDinamic refOpen={refStatus} text={dataModal.msg} icon={dataModal.icon} callBack={dataModal.callBack} refClose={refCloseStatus} />
        </>
    )

}
export default IndexNews;