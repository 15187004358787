import { MessageDinamic } from "../../commonComponents";

export const validateForm = (license, agreedTerms,availableDays ) => {
  const bd = new Date(license.beginDate);
  const rd = new Date(license.returnDate);
  const minYear = new Date().getFullYear() - 2;

  if (!license.beginDate || !license.returnDate) {
    MessageDinamic(
      "error",
      "Advertencia!",
      "Complete las fechas de la solicitud para continuar"
    );
    return false;
  } else if (!agreedTerms) {
    MessageDinamic(
      "error",
      "Advertencia!",
      "Debe aceptar las condiciones para poder enviar la solicitud "
    );
    return false;
  }
  if (bd > rd) {
    MessageDinamic(
      "error",
      "Advertencia!",
      "la fecha de inicio no puede ser mayor a la fecha final"
    );
    return false;
  }
  if (bd.getFullYear() < minYear || rd.getFullYear() < minYear) {
    MessageDinamic(
      "error",
      "Advertencia!",
      "El año ingresado no es un año valido para la solicitud"
    );
    return false;
  }
  var diffInTime =
    rd.getTime() +
    rd.getTimezoneOffset() * 60000 -
    bd.getTime() +
    bd.getTimezoneOffset() * 60000;
  var diffInDays = Math.round(diffInTime / (1000 * 60 * 60 * 24));
  if (diffInDays + 1 > Number(availableDays.availableDays)) {
    MessageDinamic(
      "error",
      "Advertencia!",
      "La cantidad de dias solicitados se encuentra por fuera del rango de dias disponibles"
    );
    return false;
  }

  return true;

};
