import React, { useEffect } from "react";
import { DetailsTitle } from "./DetailsTitle";
import { DetailsLeft } from "./DetailsLeft";
import { DetailsDescription } from "./DetailsDescription";
import { useCencoTalent } from "../../hooks/useCencoTalent";
import { Button } from "../../../commonComponents/Button";

export const ApplyDetails = () => {
    const { activeJob } = useCencoTalent();

    const onClickApply = () => {
        window.open(activeJob?.postulationUrl, "_blank", "noopener,noreferrer");
    };

    return (
        <>
            <section className="cenco-talennto">
                <DetailsTitle />

                <div className="row">
                    <DetailsLeft />

                    <DetailsDescription />
                </div>
                <div className="d-flex justify-content-end">
                    <Button
                        styleType="primary"
                        className="w-100 w-md-0"
                        onClick={onClickApply}
                    >
                        Postular
                    </Button>
                </div>
            </section>
        </>
    );
};
