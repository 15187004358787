import React, { Fragment } from 'react';
import { Link, Routes, Route, useLocation } from 'react-router-dom';
import AprobacionHorarios from './aprobacion-horarios';
import ApprovalsHistory from './aprobacion-historico/history-approvals';

const CalendarioAprobacionAsistencia = () => {
    const location = useLocation();

    return (
        <Fragment>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home color-orange" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        {location.pathname && location.pathname.split('/')[location.pathname.split('/').length - 1] == 'aprobacion-historico' ? 'Histórico Aprobación de Horarios' : 'Aprobación de Horarios'}
                    </li>
                </ol>
            </nav>
            <section className="noticias mb-3">
                <h1>{location.pathname && location.pathname.split('/')[location.pathname.split('/').length - 1] == 'aprobacion-historico' ? 'Histórico Aprobación de Horarios' : 'Aprobación de Horarios'}</h1>
                <div className="col-12 position-relative mb-0">
                    {/* <a className="btn btn-degradado btn-absolute" href="#">Ver más <i className="fa fa-chevron-right" aria-hidden="true"></i></a> */}
                </div>
            </section>
            <div>
                <Routes>
                    <Route path="" element={<AprobacionHorarios></AprobacionHorarios>}></Route>
                </Routes>
                <Routes>
                    <Route path="aprobacion-historico" element={<ApprovalsHistory></ApprovalsHistory>}></Route>
                </Routes>
            </div>
        </Fragment>
    )
}

export default CalendarioAprobacionAsistencia