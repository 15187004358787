import benefitsIcon from '../../assets/categories/beneficios.svg'
import { Category } from '../interfaces'

export const setCategoryIcon = (category: Category) => {
  const iconUrl = category.attributes.icon?.data?.attributes?.url
  if (iconUrl && iconUrl !== null) {
    return iconUrl
  } else {
    return benefitsIcon
  }
}
