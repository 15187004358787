import React, { FC, useRef, useState, Children } from 'react';
import { SliderProps, DefaultSliderProps, SliderComponentProps } from './Helpers/interfaces/slider';
import { Link } from 'react-router-dom';
import { base64ToArrayBuffer } from '../../services/utiles';
import useResponsiveBreakpoint from './hooks/useResponsiveBreakpoint';

const Slider: FC<SliderComponentProps> = ({ id, data: dataToDisplay} : SliderComponentProps ) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const breakpoint = useResponsiveBreakpoint();

  const onDragStart = (e: React.MouseEvent | React.TouchEvent) => {
    setIsDragging(true);
    setStartX((e as React.MouseEvent).pageX || (e as React.TouchEvent).touches[0].pageX);
    // Guarda el valor inicial de scroll
    const slider = sliderRef.current || { scrollLeft: 0 };
    setScrollLeft(slider.scrollLeft);
  };

  const onDragMove = (e: React.MouseEvent | React.TouchEvent) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = (e as React.MouseEvent).pageX || (e as React.TouchEvent).touches[0].pageX;
    const walk = (x - startX) * 1.2; // Multiplicador de velocidad del arrastre
    const slider = sliderRef.current || { scrollLeft: 0 };
    slider.scrollLeft = scrollLeft - walk;
  };

  const onDragEnd = () => {
      setIsDragging(false);
  };

  const indicatorsSlide = (direction: 'left' | 'right') => {
    const slider = sliderRef.current;
    const scrollingUnit = 240; // Ancho de cada elemento
    if (slider) {
        const amount = direction === 'left' ? -scrollingUnit : scrollingUnit;
        slider.scrollLeft += amount;
    }
  };

  const getCols = () => {
    if(breakpoint === 'xs') return "1";
    else if(breakpoint === 'sm') return "2";
    else if(breakpoint === 'md') return "3";
    else if(breakpoint === 'lg') return "4";
    else if(breakpoint === 'xl') return "5";
    else return "6";
  }
  return (
      <div id={"slider-"+id} className="carousel slide" data-bs-ride="carousel">
        <div  
          ref={sliderRef}
          onMouseDown={onDragStart}
          onMouseMove={onDragMove}
          onMouseUp={onDragEnd}
          onMouseLeave={onDragEnd}
          onTouchStart={onDragStart}
          onTouchMove={onDragMove}
          onTouchEnd={onDragEnd}
          className="carousel-inner"
          style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
          >
            <div className={`carousel-item active`} >
              <div className={`row d-flex flex-row flex-nowrap g-4 ps-2 row-cols-md-${getCols()} overflow-x-hidden`}>                   
                {Children.toArray(dataToDisplay.map((item : SliderProps) => 
                  React.isValidElement(item) ? item
                  : renderSliderItem( item as DefaultSliderProps )
                ))}
              </div>
            </div>
        </div>
        {
          dataToDisplay.length > 0 ?
          <>
            <button onClick={() => indicatorsSlide('left')} className="carousel-control-prev ms-2 d-block" type="button" data-bs-slide="prev" style={{zIndex:"4"}}>
                <i className="fa fa-chevron-left color-dark" aria-hidden="true"></i>
                <span className="visually-hidden">Anterior</span>
            </button>
            <button  onClick={() => indicatorsSlide('right')} className="carousel-control-next me-2 d-block" type="button" data-bs-slide="next" style={{zIndex:"4"}}>
                <i className="fa fa-chevron-right color-dark" aria-hidden="true"></i>
                <span className="visually-hidden">Siguiente</span>
            </button>
          </>
            :
          <></>
        }
      </div>
  );
};

const renderSliderItem = ( item: DefaultSliderProps ) => {
  const truncateString = (str : string, maxLength : number) => str.length > maxLength ? str.slice(0, maxLength) + '...' : str;

  return <div className="col d-flex p-2" style={{width:"240px"}} >
    <article className="d-flex flex-column box-card" style={{width:"240px"}}>
        {/* <div className="box-card__header pt-2 px-2">
            <h2 className="h4 tit-section px-2">{item.subtitle}</h2>
        </div> */}
        <div className="box-card__img">
          <img className="img-fluid img-cards-home" src={ item.image.includes('data:image/') ? item.image : URL.createObjectURL(new Blob([base64ToArrayBuffer(item.image)]))} alt={item.title}/>
        </div>
        <div className="box-card__body d-flex flex-column flex-grow-1 p-3">
            {item.title && <h3><Link className="color-dark" to="">{item.title}</Link></h3>}
            {item.description && <p>{truncateString(item.description, 100)}</p>}
            <div className="text-end mt-auto">
                <Link className="btn btn-degradado" to={item.url}>Ver más <i className="fa fa-chevron-right" aria-hidden="true"></i></Link>
            </div>
        </div>
    </article>
  </div>
};

export default Slider;
