import { IApplyContext } from "./ApplyProvider";
import {
    SearchFilters,
    JobInfo,
    SearchFiltersV2,
} from "../../interfaces/applyInterface";

type TalentActionsType =
    | { type: "SEARCH - Set Filters Data"; payload: SearchFilters }
    | { type: "SEARCH - Set Filters V2 Data"; payload: SearchFiltersV2 }
    | { type: "SEARCH - Set Apply List"; payload: JobInfo[] }
    | { type: "SEARCH - Set Toggle Active Apply"; payload: string[] }
    | { type: "SEARCH - Set All Applys Active" }
    | { type: "SEARCH - Set Select SearchInput"; payload: string }
    | { type: "SEARCH - Set Select Area"; payload: string }
    | { type: "SEARCH - Set Select Flag"; payload: string }
    | { type: "SEARCH - Set Select workDay"; payload: string }
    | { type: "SEARCH - Set Select Ubication"; payload: string }
    | { type: "JOB - Set Active Requisition"; payload: JobInfo };

export const applyReducer = (
    state: IApplyContext,
    action: TalentActionsType
): IApplyContext => {
    switch (action.type) {
        /**************** SEARCH ACTIONS ****************/
        case "SEARCH - Set Filters Data":
            return {
                ...state,
                searchFilters: action.payload,
            };

        case "SEARCH - Set Filters V2 Data":
            return {
                ...state,
                searchFiltersV2: action.payload,
            };

        case "SEARCH - Set Apply List":
            return {
                ...state,
                applyList: action.payload,
            };

        case "SEARCH - Set Toggle Active Apply":
            return {
                ...state,
                applyList: state.applyList!.map((item) => {
                    const isSameId = action.payload.includes(item.id);
                    return {
                        ...item,
                        active: isSameId,
                    };
                }),
            };

        case "SEARCH - Set All Applys Active":
            return {
                ...state,
                applyList:
                    state.applyList?.map((item) => ({
                        ...item,
                        active: true,
                    })) ?? [],
            };

        case "SEARCH - Set Select Area":
            return {
                ...state,
                filterArea: action.payload,
                filterFlag: "",
                filterworkDay: "",
                filterUbication: "",
            };

        case "SEARCH - Set Select Flag":
            return {
                ...state,
                filterFlag: action.payload,
                filterworkDay: "",
                filterUbication: "",
            };

        case "SEARCH - Set Select SearchInput":
            return {
                ...state,
                filterSearchInput: action.payload,
            };

        case "SEARCH - Set Select Ubication":
            return {
                ...state,
                filterUbication: action.payload,
            };

        case "SEARCH - Set Select workDay":
            return {
                ...state,
                filterworkDay: action.payload,
                filterUbication: "",
            };

        /**************** DETAILS JOB ACTIONS ****************/
        case "JOB - Set Active Requisition":
            return {
                ...state,
                activeJob: action.payload,
            };

        default:
            return state;
    }
};
