import React, { FC } from 'react';
import { BoxProps } from './Helpers/interfaces/box';

const Box: FC<BoxProps> = ({ title, children, width, box, border }) => {
    let widthClass = 
        width === '1/3' ? 'col-12 col-md-4' :
        width === '2/3' ? 'col-12 col-md-8' :
        'col-12';


    return <div className={"py-2 m-0 px-0 " + widthClass}>
        <div className={`${box ? ' box h-100 py-3' : ''} mx-2 ${box && title ? 'pt-3' : ''} ${border ? 'border border-secondary rounded' : ''}`}>
            <div className='tit-group d-flex justify-content-between'>
                {title && <h2 className={`${width === '1/3' ? 'fs-5' : ''} tit-section`}>{title}</h2>}
            </div>
            {children}
        </div>
    </div>
};

export default Box;
