import { ReactElement, useContext } from "react";
import { UIContext } from "../context";
import { PageTypes } from "../interfaces/applyInterface";
import { SearchApply } from "../components/SearchApply/SearchApply";
import { ApplyDetails } from "../components/ApplyDetails/ApplyDetails";
import { InfoDetails } from "../components/InfoDetails";

const pageToDisplay: Record<PageTypes, () => ReactElement> = {
    SEARCH_APPLY: SearchApply,
    APPLY_DETAILS: ApplyDetails,
    INFO_DETAILS: InfoDetails,
};

export const useCencoUI = () => {
    const {
        // Vartiables y contantes
        actualPage,
        hasError,
        isLoading,
        isModalVideoOpen,
        paginationPage,

        // Funciones y metodos
        setHasError,
        setIsLoading,
        setIsModalVideoOpen,
        setPage,
        setPaginationPage,
    } = useContext(UIContext);

    return {
        // Vartiables y contantes
        actualPage,
        hasError,
        isLoading,
        isModalVideoOpen,
        pageToDisplay,
        paginationPage,

        // Funciones y metodos
        setHasError,
        setIsLoading,
        setIsModalVideoOpen,
        setPage,
        setPaginationPage,
    };
};
