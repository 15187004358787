import React,{FC} from 'react';

interface InfoIconProps {
    width?: string;
    height?: string;
}

const InfoIcon:FC<InfoIconProps> = ({width = "25", height = "25"}) => (
    <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_268_2926)">
            <path d="M12.5 23.4375C9.59919 23.4375 6.8172 22.2852 4.76602 20.234C2.71484 18.1828 1.5625 15.4008 1.5625 12.5C1.5625 9.59919 2.71484 6.8172 4.76602 4.76602C6.8172 2.71484 9.59919 1.5625 12.5 1.5625C15.4008 1.5625 18.1828 2.71484 20.234 4.76602C22.2852 6.8172 23.4375 9.59919 23.4375 12.5C23.4375 15.4008 22.2852 18.1828 20.234 20.234C18.1828 22.2852 15.4008 23.4375 12.5 23.4375ZM12.5 25C15.8152 25 18.9946 23.683 21.3388 21.3388C23.683 18.9946 25 15.8152 25 12.5C25 9.18479 23.683 6.00537 21.3388 3.66117C18.9946 1.31696 15.8152 0 12.5 0C9.18479 0 6.00537 1.31696 3.66117 3.66117C1.31696 6.00537 0 9.18479 0 12.5C0 15.8152 1.31696 18.9946 3.66117 21.3388C6.00537 23.683 9.18479 25 12.5 25V25Z" fill="#217DBE"/>
            <path d="M13.9531 10.2937L10.375 10.7422L10.2469 11.3359L10.95 11.4656C11.4094 11.575 11.5 11.7406 11.4 12.1984L10.2469 17.6172C9.94375 19.0187 10.4109 19.6781 11.5094 19.6781C12.3609 19.6781 13.35 19.2844 13.7984 18.7438L13.9359 18.0937C13.6234 18.3687 13.1672 18.4781 12.8641 18.4781C12.4344 18.4781 12.2781 18.1766 12.3891 17.6453L13.9531 10.2937ZM14.0625 7.03125C14.0625 7.44565 13.8979 7.84308 13.6049 8.1361C13.3118 8.42913 12.9144 8.59375 12.5 8.59375C12.0856 8.59375 11.6882 8.42913 11.3951 8.1361C11.1021 7.84308 10.9375 7.44565 10.9375 7.03125C10.9375 6.61685 11.1021 6.21942 11.3951 5.9264C11.6882 5.63337 12.0856 5.46875 12.5 5.46875C12.9144 5.46875 13.3118 5.63337 13.6049 5.9264C13.8979 6.21942 14.0625 6.61685 14.0625 7.03125Z" fill="#217DBE"/>
        </g>
        <defs>
            <clipPath id="clip0_268_2926">
            <rect width="25" height="25" fill="white"/>
            </clipPath>
        </defs>
    </svg>

);

export default InfoIcon;