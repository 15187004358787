import React, { useEffect, useState } from 'react';
import { Box, Alert, Snackbar, Fade } from '@mui/material';
import { ToastProps } from './interfaces';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import './toastStyle.css'

const ToastAlert = (props: ToastProps) => {
  const { variant, message, duration, origin, link, onToastClosed } = props
  const [open, setOpen] = useState(true)
  const vertical = origin ? origin.vertical : 'bottom'
  const horizontal = origin ? origin.horizontal : 'center'
  const Transition = Fade

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    onToastClosed && onToastClosed()
  };

  const getAlertIcon = (variant: string) => {
    switch (variant) {
      case 'success':
        return <CheckCircleOutlineIcon />
      case 'warning':
        return <ErrorOutlineIcon />
      case 'info':
        return <InfoOutlinedIcon />
      case 'error':
        return <CancelOutlinedIcon />
      default:
        return <CheckCircleOutlineIcon />
    }
  }

  return (
    <Box sx={{ width: '100%' }} className='help-center-toast-container'>
      <Snackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{vertical, horizontal }}
        autoHideDuration={duration ? duration : 5000}
        TransitionComponent={Transition}
        >
        <Alert
          onClose={handleClose}
          severity={variant}
          sx={{ width: '100%' }}
          icon={getAlertIcon(variant)}
        >
          <span>{message}</span> 
          {link ? link : null}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default ToastAlert