/* eslint-disable no-undef */
import axios from 'axios';
import { Auth } from 'aws-amplify';

const API_URL = process.env.REACT_APP_BACKEND_URL_GLOBAL;

const getTipoCertificado = async () => {
    return Auth.currentSession().then(async auth => {
        const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
        return axios.get(`${API_URL}/api/certificados/tipoCertificado`, { headers: headers }).then(res => res.data.CN_COL_XLAT.PSXLATITEM);
    })
}
const getCertificates = async () => {
    return Auth.currentSession().then(async auth => {
        const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
        const url = `${API_URL}/api/certificados/certificados`;
        return axios.get(url, { headers: headers }).then(res => res.data)
    })
}
const getCertificatesSinFile = async () => {
    return Auth.currentSession().then(async auth => {
        const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
        const url = `${API_URL}/api/certificados/certificadosSinFile`;
        return axios.get(url, { headers: headers }).then(res => res.data)
    })
}
const generateCertificate = async (data) => {
    return Auth.currentSession().then(async auth => {
        const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
        const url = `${API_URL}/api/certificados/generarcertificado`;
        return axios({
            method: 'post',
            url: url,
            headers: headers,
            data: JSON.stringify(data),
        })
    })
}
const getFileCertificado = async (guid) => {
    return Auth.currentSession().then(async auth => {
        const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
        const url = `${API_URL}/api/certificados/certificadoFile?guid=${guid}`;
        return axios.get(url, { headers: headers }).then(res => res.data)
    })
}
export const CertificadosArgService = {
    getTipoCertificado,
    getCertificates,
    generateCertificate,
    getCertificatesSinFile,
    getFileCertificado
}

