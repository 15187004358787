import React, { useContext } from "react";
import { httpClientEnv } from "../../../services/apiServices/httpClient";
import NewsManager from "./NewsManager/NewsManager";

export const HttpClientContext = React.createContext(httpClientEnv);
export const useHttpClient = () => useContext(HttpClientContext);

export const News = () => {
    return (
        <HttpClientContext.Provider value={httpClientEnv}>
            <NewsManager />
        </HttpClientContext.Provider>
    )
};