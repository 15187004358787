import React, { FC } from "react";

const ModalConflicto : FC<any> = ({ modalConflictoFunction }) => {
    return <>
    {/*Modal conflicto */}
    <div className="modal fade" id="conflictomodal" tabIndex={-1} aria-labelledby="conflictomodal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header border-0 pb-0">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body text-center py-4">
                    <h2 className="h5">Usted está declarando que tiene una situación que pudiera ser o parecer un conflicto de interés, necesitamos nos entregue mayor información.</h2>
                </div>
                <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                    <button type="button" className="btn btn-degradado col-12 col-md-auto" data-bs-dismiss="modal" data-bs-toggle="modal" onClick={(e) => {modalConflictoFunction(e)}}>Aceptar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                </div>
            </div>
        </div>
    </div>
    
    </>;
}

export default ModalConflicto;