import { taskEither as TE } from "fp-ts";
import * as t from "io-ts";

export const Balance = t.type({
    tipo: t.string,
    descr: t.string,
    monto: t.string,
    vigencia: t.string,
});

export type Balance = t.TypeOf<typeof Balance>;

export const Transaction = t.type({
    tx: t.string,
    tipo: t.string,
    descr: t.string,
    monto: t.string,
    fecha: t.string,
});

export type Transaction = t.TypeOf<typeof Transaction>;

export const Option = t.type({
    key: t.string,
    value: t.string,
    label: t.string,
});

export type Option = t.TypeOf<typeof Option>;

export const Options = t.array(Option);

export type Options = t.TypeOf<typeof Options>;

export const ResponseFetch = t.type({
    valid: t.string,
    status: t.string,
    saldos: Balance,
    transacciones: t.array(Transaction),
    monthOptions: t.array(Option),
    yearOptions: t.array(Option),
    voucherOptions: t.array(Option)
});

export type ResponseFetch = t.TypeOf<typeof ResponseFetch>;

export type HttpRequestError = {
    tag: 'httpRequestError';
    error: unknown;
}
  
export type HttpContentTypeError = {
    tag: 'httpContentTypeError';
    error: unknown;
}
  
export type HttpResponseStatusError = {
    tag: 'httpResponseStatusError';
    status: number;
}

export interface HttpClient {
    request(
        input: RequestInfo,
        init?: RequestInit,
    ): TE.TaskEither<HttpRequestError, Response>
}
  
export interface HttpClientEnv {
    httpClient: HttpClient;
}

export const voucherCodec = t.type({
    response: ResponseFetch,
});
      
export type Voucher = t.TypeOf<typeof voucherCodec>;

export type HttpError =
  | HttpRequestError
  | HttpContentTypeError
  | HttpResponseStatusError
  | t.Errors