import React, { useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import {AiOutlineWarning} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import Accordion from 'react-bootstrap/Accordion';


const TablaVacaciones = (props) => {
  const [state, setState] = useState(false);
  let tableLayout = props.list;
  const [idDropdown, setIdDropdown] = useState(null)
  const [sortList, setSortList] = useState("ASC");


const toggleOrder = () =>{
  if (sortList === "DESC") {
    //tableLayout = tableLayout.sort((a, b) => moment(a.beginDate, "AAAA-MM-DD").unix() < moment(b.beginDate, "AAAA-MM-DD").unix() ? 1 :moment(b.beginDate, "AAAA-MM-DD").unix() < moment(a.beginDate, "AAAA-MM-DD").unix()? -1 : 0 )
    tableLayout = tableLayout.sort((a,b)=> a.beginDate < b.beginDate ? 1 : b.beginDate < a.beginDate ? -1 : 0)
    setSortList("ASC")
  } else {
    //tableLayout = tableLayout.sort((a, b) => moment(a.beginDate, "AAAA-MM-DD").unix() > moment(b.beginDate, "AAAA-MM-DD").unix() ? 1 :moment(b.beginDate, "AAAA-MM-DD").unix() > moment(a.beginDate, "AAAA-MM-DD").unix()? -1 : 0 )
    tableLayout = tableLayout.sort((a,b)=> a.beginDate > b.beginDate ? 1 : b.beginDate > a.beginDate ? -1 : 0)
    setSortList("DESC")
  }
}

  const dropdownEvent = (e, i) => {
    e.preventDefault();
    setIdDropdown(i);
    setState(!state ? true : false)
  };
 
  return (

   <>
      <div className="table-responsive desktop-table-vacaciones-historico">
        <table className="table table-even table-section table-borderless d-md-table">
          <thead>
            <tr>
            <th className="cap">Fecha Inicio {sortList === "ASC"? <BsArrowDown className="color-orange fw-bold" onClick={toggleOrder}/> : sortList === "DESC"?<BsArrowUp className="color-orange" onClick={toggleOrder}/> : null} </th>
              <th className="cap">Fecha Final</th>
              <th className="cap">Duracion</th>
              <th className="cap">Aprobador</th>
              <th className="cap" colSpan="2">Estado</th>

            </tr>
          </thead>
          <tbody>  
             {tableLayout.length === 0 ? <td colSpan={6} className="no-absence-text alert alert-info alert-dismissible fade show text-center" role="alert">
            <AiOutlineWarning></AiOutlineWarning> No se encontraron datos asociados
        </td> : tableLayout.map((absence, i) => {
              return (
                <tr>
                  <td key={i}>{absence.beginDate}</td>
                  <td key={i}>{absence.returnDate}</td>
                  <td key={i}>{absence.durationDays} {absence.durationDays == 1 ? "Día" : "Días"}</td>
                  <td key={i}>{absence.aprovedName}</td>
                  <td>
                    <div className={`boton-estado ${absence.state === 'A' ? "Aprobada" : absence.state === 'P' ? "Pendiente" : "Rechazada"}`}> {absence.state === 'A' ? "Aprobada" : absence.state === 'P' ? "Pendiente" : "Rechazada"}</div>
                  </td>
                  <td>
                    <div className="dropdown">
                      <div>
                        <button className="btn-dropdown" onClick={e => dropdownEvent(e, i)}><BsThreeDotsVertical /></button>
                        {state && i === idDropdown ? (
                          <ul className="dropdown-item">
                            <li className="mx-auto"><Link to='detalle-solicitud' state={absence}>Detalles</Link></li>
                          </ul>
                        ) : null}
                      </div>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
         
         {  tableLayout.length > 0 ? 
        <Accordion className="accordion accordion-flush accordion-light accordion-table d-md-none"  defaultActiveKey="0">
       { tableLayout.map((absence, i) => {
           return ( 
        <Accordion.Item eventKey={i}>
        <Accordion.Header className="accordion-header" id="accordion">
        <strong>Fecha Inicio</strong> {absence.beginDate}</Accordion.Header>
        <Accordion.Body className="accordion-body p-0">
        <div className="data-list-box data-list-box-even">
                         <ul className="data-list-section--item list-unstyled">
                             <li>
                                 <span className="data-list-box__title">Fecha Final</span>
                                 <span className="data-list-box__text">{absence.returnDate}</span>
                             </li>
                             <li>
                                 <span className="data-list-box__title">Duración</span>
                                 <span className="data-list-box__text">{absence.durationDays}</span>
                             </li>
                             <li>
                                 <span className="data-list-box__title">Aprobador</span>
                                 <span className="data-list-box__text">{absence.aprovedName}</span>
                             </li>
                             <li>
                                 <span className="data-list-box__title">Estado</span>
                                 <span  className="data-list-box__text">
                                     <span className={`boton-estado ${absence.state === 'A' ? "Aprobada" : absence.state === 'P' ? "Pendiente" : "Rechazada"}`}> {absence.state === 'A' ? "Aprobada" : absence.state === 'P' ? "Pendiente" : "Rechazada"}</span>
                                 </span>
                             </li>
                         </ul>
                     </div>
        </Accordion.Body>
      </Accordion.Item>
           )
       })}
    </Accordion> : null }

    </>
  )
}

export default TablaVacaciones
