import type { CarouselItem } from "../_components/carouselSubMenu";

export const managmentPayRoll = {
  name: "managmentPayRoll",
  label: "Nómina de Gestión",
  enabledFilterCountry: true,
  filenameExport: "dashboard_resumen_nomina",
};

export const subMenuListData: CarouselItem[] = [
  {
    id: 0,
    name: "workEnvironment",
    label: "Clima Laboral",
    isSelected: false,
    disabledSelectYear: false,
    enabledFilterCountry: false,
    filenameExport: "dashboard_resumen_clima_laboral",
    showSelectYear: false,
    icon: "star",
  },
  {
    id: 1,
    name: "talentReview",
    label: "Talent Review",
    isSelected: false,
    disabledSelectYear: false,
    enabledFilterCountry: false,
    filenameExport: "dashboard_resumen_talent_review",
    showSelectYear: true,
    icon: "chart-line",
  },
  {
    id: 2,
    name: "performanceEvaluation",
    label: "Evaluación de Desempeño",
    isSelected: false,
    disabledSelectYear: false,
    enabledFilterCountry: false,
    filenameExport: "dashboard_resumen_evaluacion_de_desempenio",
    showSelectYear: true,
    icon: "comments",
  },
  {
    id: 3,
    name: "compensations",
    label: "Compensaciones",
    isSelected: false,
    disabledSelectYear: true,
    enabledFilterCountry: true,
    filenameExport: "dashboard_resumen_compensaciones",
    showSelectYear: true,
    icon: "dollar-sign",
  },
  {
    id: 4,
    name: "employeeProfileSummary",
    label: "Ficha Resumen",
    hideLayout: true,
    hideInCarousel: true,
    showSelectYear: true,
    carouselChildren: [
      {
        id: 5,
        name: "jobDetails",
        label: "Datos de puesto / Compensacion",
        hideLayout: true,
        hideInCarousel: false,
        isSelected: false,
        icon: "chevron-right",
      },
      {
        id: 6,
        name: "vacationData",
        label: "Datos de vacaciones",
        hideLayout: true,
        hideInCarousel: false,
        isSelected: false,
        icon: "chevron-right",
      },
    ],
  },
];
