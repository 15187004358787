import { PageTypes } from "../../interfaces/applyInterface";
import { IUIContext } from "./UIProvider";

type UIActionsType =
    | { type: "UI - Set Actual Page"; payload: PageTypes }
    | { type: "UI - Set Is Loading"; payload: boolean }
    | { type: "UI - Set Pagination Page"; payload: number }
    | { type: "UI - Set Is Modal Video Open"; payload: boolean }
    | { type: "UI - Set Has Error"; payload: boolean };

export const uiReducer = (
    state: IUIContext,
    action: UIActionsType
): IUIContext => {
    switch (action.type) {
        case "UI - Set Actual Page":
            return {
                ...state,
                actualPage: action.payload,
            };

        case "UI - Set Is Loading":
            return {
                ...state,
                isLoading: action.payload,
            };

        case "UI - Set Pagination Page":
            return {
                ...state,
                paginationPage: action.payload,
            };

        case "UI - Set Is Modal Video Open":
            return {
                ...state,
                isModalVideoOpen: action.payload,
            };

        case "UI - Set Has Error":
            return {
                ...state,
                hasError: action.payload,
            };
    }
};
