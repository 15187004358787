import React, { useEffect, useRef, useState } from "react";
import { FaTrash, FaEye } from "react-icons/fa";
import moment from 'moment';
import SolicitudLicenciasService from "../../services/solicitud-licencias-uy/licencias-uy-service";
import { Modal } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Swal from 'sweetalert2';


const TablaLicencias = (props) => {
  let tableLayout = props.list;
  let loading = props.update;
  let setFetched = props.action;
  const [sortList, setSortList] = useState("DESC");
  const fileRef = useRef(null);
  const [file, setFile] = useState({
    certificateFile: null,
    certificateFileName: '',
    certificateFileType: ''
  });
  const [show, setShow] = useState({ active: false, data: null });
  const [showDelete, setShowDelete] = useState({ active: false, data: null });
  const [loadedFile, setLoadedFile] = useState(false);
  const [modalView, setModalView] = useState(null);
  const [showFile, setShowFile] = useState(false);
  const [DeleteFileFlag, setDeleteFileFlag] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [certificateSent, setCertificateSent] = useState(false);
  const [error, setError] = useState(false);
  const [errorDelete, setErrorDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [fileDeleted, setFileDeleted] = useState(false);
  const [loadingIndices, setLoadingIndices] = useState(new Set());



  const order = () => {
    if (sortList === "ASC") {
      let sorted = tableLayout.sort((a, b) => moment(a.BEGIN_DT, "YYYY-MM-DD").unix() - moment(b.BEGIN_DT, "YYYY-MM-DD").unix())
      tableLayout = sorted
      setSortList("DESC")
    } else {
      let sorted = tableLayout = tableLayout.sort((a, b) => moment(b.BEGIN_DT, "YYYY-MM-DD").unix() - moment(a.BEGIN_DT, "YYYY-MM-DD").unix())
      tableLayout = sorted;
      setSortList("ASC")
    }
  }

  useEffect(() => {
    !loading &&order()
  
  
  }, [loading])
  
  const handleFileChange = (e) => {
    setFile({
      certificateFile: null,
      certificateFileName: '',
      certificateFileType: ''
    });
    fileRef.current.click();


    if (e.target.files[0].type == "image/jpeg" || e.target.files[0].type == "image/jpg" || e.target.files[0].type == "image/png" || e.target.files[0].type == "application/pdf") {
      if (e.target.files[0].size <= 5000000) {
        setFile({ certificateFile: e.target.files[0], certificateFileName: e.target.files[0].name, certificateFileType: e.target.files[0].type });
        setLoadedFile(true);
        const f = e.target.files[0];
        let blob = new Blob([f], { type: e.target.files[0].type });
        const url = window.URL.createObjectURL(blob)
        setModalView(url)
      } else {
        Swal.fire({
          allowOutsideClick: false,
          position: 'center',
          title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
          html: '<div style="text-align: center; font-size: 15px">El tamaño del Certificado debe ser menor a 5Mb</div>',
          confirmButtonColor: '#0169b5',
        })
      }
    } else {
      Swal.fire({
        allowOutsideClick: false,
        position: 'center',
        title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
        html: '<div style="text-align: center; font-size: 15px">Debe ingresar uno de los tipos de archivos permitidos</div>',
        confirmButtonColor: '#0169b5',
      })
    }
  }

  const fileModal = () => {
    let archivo = modalView
    if (file.certificateFileType == 'application/pdf') {
      Swal.fire({
        title: file.certificateFileName,
        allowOutsideClick: false,
        width: 500,
        html: `
                <object data="${archivo}" type="application/pdf" style="text-align: left; width: 100%; height: 600px;">
                    <p style="margin-bottom: 0; text-align: center; padding: 10px">
                        El navegador no puede previsualizar el pdf. <br/>
                        <a href="${archivo}" download="${file.certificateFileName}">
                            Descargar archivo
                        </a>
                    </p>
                </object>
                `,
        confirmButtonColor: '#0169b5',
      })
    } else {
      Swal.fire({
        title: file.certificateFileName,
        width: '50%',
        allowOutsideClick: false,
        heightAuto: false,
        imageWidth: '90%',
        imageUrl: `${archivo}`,
        confirmButtonColor: '#0169b5'
      })
    }
    setShowFile(false);
  }

  const handleFileUpload = (id) => {
    const fd = new FormData();
    fd.append("idSolicitud", id)
    fd.append("fileName", file.certificateFileName);
    fd.append("file", file.certificateFile);

    SolicitudLicenciasService.uploadCertificate(setLoadingSave, fd, setCertificateSent, setError, setErrorMessage, setError);
  }
  const handleSuccess = () => {
    Swal.fire({
      allowOutsideClick: false,
      position: 'center',
      title: '<div style="text-align: center; color: green; font-size: 16px">Exitoso</div>',
      html: '<div style="text-align: center; font-size: 15px">Archivo cargado correctamente</div>',
      confirmButtonColor: '#0169b5'
    }).then((result) => {
      if (result.isConfirmed) {
        setCertificateSent(false);
        setShow({ active: false, data: null })
        setFile({ certificateFile: null, certificateFileName: '', certificateFileType: '' });
        setLoadedFile(false)
        setFetched(false);
      }
    })
  }
  const handleDeleteSuccess = () => {
    Swal.fire({
      allowOutsideClick: false,
      position: 'center',
      title: '<div style="text-align: center; color: green; font-size: 16px">Exitoso</div>',
      html: '<div style="text-align: center; font-size: 15px">Archivo eliminado correctamente</div>',
      confirmButtonColor: '#0169b5'
    }).then((result) => {
      if (result.isConfirmed) {
        setFileDeleted(false);
        setShowDelete({ active: false, data: null })
        setFetched(false);
      }
    })
  }

  const handleFileDelete = async (id) => {
    await SolicitudLicenciasService.deleteLicenceCertificate(id, setDeleteFileFlag, setErrorDelete, setFileDeleted);
    if (fileDeleted) {
      Swal.fire({
        allowOutsideClick: false,
        position: 'center',
        title: '<div style="text-align: center; color: green; font-size: 16px">Exitoso</div>',
        html: '<div style="text-align: center; font-size: 15px">Archivo Eliminado correctamente</div>',
        confirmButtonColor: '#0169b5'
      }).then((result) => {
        if (result.isConfirmed) {
          setFileDeleted(false);
          setShowDelete({ active: false, data: null })
          setFetched(false);
        }
      })
    }
  }

  const handleDeleteError = () => {
    Swal.fire({
      allowOutsideClick: false,
      position: 'center',
      title: '<div style="text-align: center; color: red; font-size: 16px">Error</div>',
      html: `<div style="text-align: center; font-size: 15px">No se pudo eliminar correctamente el archivo</div>`,
      confirmButtonColor: '#0169b5',
    })
    setErrorDelete(false);
  }

  const handleError = () => {
    Swal.fire({
      allowOutsideClick: false,
      position: 'center',
      title: '<div style="text-align: center; color: red; font-size: 16px">Error</div>',
      html: `<div style="text-align: center; font-size: 15px">${errorMessage || 'Error al subir el certificado.'}</div>`,
      confirmButtonColor: '#0169b5',
    }).then(result => {
      if (result.isConfirmed) {
        setErrorMessage('');
      }
    });
    setError(false);
  }
  /*   const sortByDate = () => {
      console.log("Sort by date");
    };
   */

  const getLicenceCertificate = async (idSolicitud) => {
    let index = idSolicitud;
    setLoadingIndices((prev) => new Set([...prev, index]));
    await SolicitudLicenciasService.getLicenceCertificate(idSolicitud, setLoadingIndices);
  }

  return (
    <>
      {!loading ?
        <div>
          <div className="table-responsive">
            {!tableLayout ? (<div data-testid="loading" className="full-spinner">
              <div className="spinner-border text-primary"></div>
            </div>) : (
              <table className="table table-even table-section table-borderless d-md-table desktop-table-licencias-historico">
                <thead>
                  <tr>
                    <th className="cap container_row">
                      Fecha Inicio
                      <a onClick={order} className="icon_btn" >
                        {sortList === "ASC" ? <i className="fa fa-long-arrow-up fa-fw color-orange" aria-hidden="true"></i>
                          : <i className="fa fa-long-arrow-down fa-fw color-orange" aria-hidden="true"></i>}
                      </a>
                    </th>
                    <th className="cap">Fecha Final</th>
                    <th className="cap">Tipo</th>
                    <th className="cap">Aprobador</th>
                    <th className="cap">Estado</th>
                    <th className="cap">Certificado</th>
                  </tr>
                </thead>
                <tbody>
                  {tableLayout &&
                    tableLayout.map((licence) => {
                      return (
                        <tr key={licence.CN_ID_SOLICITUD}>
                          <td>{moment(licence.BEGIN_DT).format('DD/MM/YYYY')}</td>
                          <td>{moment(licence.END_DT).format('DD/MM/YYYY')}</td>
                          <td>{licence.DESCR}</td>
                          <td>{licence.NAME_DISPLAY_APPR}</td>
                          <td>
                            <div className={`text-uppercase fw-bold ${licence.CN_ABSENCE_STATUS === "A" ? "color-orange" : licence.CN_ABSENCE_STATUS === "R" ? "color-primary" : ""}`}>
                              {licence.CN_ABSENCE_STATUS === "R" ? "Rechazada" : licence.CN_ABSENCE_STATUS === "A" ? "Aprobada" : licence.CN_ABSENCE_STATUS === "C" ? "Cancelado" : "Pendiente"}
                            </div>
                          </td>
                          <td>
                          <div>
                            {licence.FILENAME !== "" ? (
                              <div className="dropdown">
                                <div className="icons-table">
                                  <button className="btn-dropdown" onClick={() => getLicenceCertificate(licence.CN_ID_SOLICITUD)}>
                                    {loadingIndices.has(licence.CN_ID_SOLICITUD) ? <div className="spinner-border spinner-border-sm"></div> : <i className="fa fa-eye fa-fw fa-lg color-orange" aria-hidden="true"></i>}
                                  </button>
                                  {licence.status !== "R" ? (
                                    <button disabled={licence.VALIDATED === "Y"} className="btn-dropdown" onClick={() => setShowDelete({ active: true, data: licence })}>
                                      <i className={`fa fa-trash fa-fw fa-lg ${licence.VALIDATED === "Y" ? "color-disabled" : "color-orange"}`} aria-hidden="true"></i>
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            ) : (
                              licence.status !== 'R' ? (
                                <div className="icons-table">
                                  <button className="btn-dropdown color-orange" disabled={licence.status === "R" || licence.VALIDATED === "Y"} onClick={() => setShow({ active: true, data: licence })} role="button">
                                    <i className={`fa fa-plus fa-fw fa-lg ${(licence.status === 'R' || licence.VALIDATED === "Y") ? "color-disabled" : null}`} aria-hidden="true" disabled={licence.status === "R"} onClick={() => setShow({ active: true, data: licence })} role="button" style={licence.status === "R" ? { color: "B9BABE", cursor: "not-allowed" } : null}></i>
                                  </button>
                                </div>
                              ) : null
                            )}
                          </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>

          {/*responsive-table */}
          {tableLayout.length > 0 ?
            <Accordion className="accordion accordion-flush accordion-light accordion-table d-md-none" defaultActiveKey="0">
              {tableLayout.map((licence, id) => {
                return (
                  <Accordion.Item key={id} eventKey={id}>
                    <Accordion.Header className="accordion-header" id="accordion">
                      <strong>Fecha Inicio</strong> {moment(licence.BEGIN_DT).format('DD/MM/YYYY')}</Accordion.Header>
                    <Accordion.Body className="accordion-body p-0">
                      <div className="data-list-box data-list-box-even">
                        <ul className="data-list-section--item list-unstyled">
                          <li>
                            <span className="data-list-box__title">Fecha Final</span>
                            <span className="data-list-box__text">{moment(licence.END_DT).format('DD/MM/YYYY')}</span>
                          </li>
                          <li>
                            <span className="data-list-box__title">Tipo</span>
                            <span className="data-list-box__text">{licence.DESCR}</span>
                          </li>
                          <li>
                            <span className="data-list-box__title">Aprobador</span>
                            <span className="data-list-box__text">{licence.NAME_DISPLAY_APPR}</span>
                          </li>
                          <li>
                            <span className="data-list-box__title">Estado</span>
                            <span className="data-list-box__text">
                              <span className={`boton-estado ${licence.CN_ABSENCE_STATUS === 'A' ? "Aprobada" : licence.CN_ABSENCE_STATUS === 'P' ? "Pendiente" : "Rechazada"}`}> {licence.CN_ABSENCE_STATUS === 'A' ? "Aprobada" : licence.CN_ABSENCE_STATUS === 'P' ? "Pendiente" : "Rechazada"}</span>
                            </span>
                          </li>
                          <li>
                            <span className="data-list-box__title">Certificado</span>
                            <span className="data-list-box__text">
                              {licence.FILENAME !== "" ? (
                                <div className="dropdown">
                                  <div className="icons-table-responsive">
                                    <button className="btn-dropdown" onClick={() => getLicenceCertificate(licence.CN_ID_SOLICITUD)}>
                                      {loadingIndices.has(licence.id) ? <div className="spinner-border spinner-border-sm color-primary"></div> : <i className="fa fa-eye fa-fw fa-lg color-orange" aria-hidden="true"></i>}
                                    </button>
                                    {licence.status !== "R" ? <button
                                      className="btn-dropdown" onClick={() => setShowDelete({ active: true, data: licence })}>
                                      <i className="fa fa-trash fa-fw fa-lg color-orange" aria-hidden="true"></i>
                                    </button> : null}
                                  </div>
                                </div>
                              ) : (
                                <div className="icons-table-responsive">
                                  <button className="btn-dropdown color-orange" disabled={licence.CN_ABSENCE_STATUS === "R"} onClick={() => setShow({ active: true, data: licence })} role="button">
                                    <i className={`fa fa-plus fa-fw fa-lg ${licence.CN_ABSENCE_STATUS === 'R' ? "color-disabled" : null}`} aria-hidden="true" ></i>
                                  </button>
                                </div>
                              )}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )
              })}
            </Accordion> : <div className="text-center">No se encontraron licencias</div>}
          {/*responsive-table */}

          {/*MODAL SUBIR CERTIFICADO */}
          <Modal show={show.active} data={show.data} >
            <div>
              <Modal.Header closeButton onClick={() => { setShow({ active: false, data: null }); setFile({ certificateFile: null, certificateFileName: '', certificateFileType: '' }); setLoadedFile(false) }}>
                <h2 className="title-modal m-3 text-center">Cargar certificado</h2>
              </Modal.Header>
              <div className="container-modal upload-content m-3">
                <div className="fields fields-file">
                  <label className="label fw-bold">Tipo:</label>
                  <span>{show.data?.DESCR}</span>
                </div>
                <div className="fields fields-file">
                  <label className="label fw-bold">Fecha Inicio:</label>
                  <span>{moment(show.data?.BEGIN_DT).format('DD/MM/YYYY')}</span>
                </div>
                <div className="fields fields-file">
                  <label className="label fw-bold">Fecha Final:</label>
                  <span>{moment(show.data?.END_DT).format('DD/MM/YYYY')}</span>
                </div>
                <div className="fields-file">
                  <label className="label fw-bold label-special-m">Certificado: </label>
                  <input hidden ref={fileRef} type="file" onChange={handleFileChange} name="fileLoader" accept=".jpeg, .png, .pdf" />
                  {!loadedFile ?
                    <input type="file" className="form-control" aria-label="Cargar certificado" ref={fileRef} onChange={handleFileChange} name="fileLoader" accept=".jpg, .jpeg, .png, .pdf"></input>
                    : <div className="label-wrapper"><label className='file-name-label'>/{file?.certificateFileName}</label> <FaEye className="certify-icons" onClick={() => { setShowFile(true); console.log(modalView) }} ></FaEye> <FaTrash onClick={() => { setLoadedFile(false); setFile({ certificateFile: null, certificateFileName: '', certificateFileType: '' }); setModalView(null) }} className="certify-icons"></FaTrash></div>}
                  <div>
                    <label className="file-name" htmlFor="file"><i></i></label>
                  </div>
                </div>
              </div>
              <Modal.Footer>
                <button disabled={!loadedFile} onClick={() => handleFileUpload(show.data?.CN_ID_SOLICITUD)} className="button history send-certify">
                  {loadingSave ? <div className="spinner-border spinner-border-sm"></div> : "Enviar"}
                </button>
              </Modal.Footer>
            </div>
          </Modal>
          {/*MODAL SUBIR CERTIFICADO */}
          {/*MODAL ELIMINAR CERTIFICADO */}
          <Modal show={showDelete.active} data={showDelete.data} >
            <div>
              <Modal.Header closeButton onClick={() => setShowDelete({ active: false, data: null })}>
                <h2 className="title-modal m-3 text-center">Eliminar certificado</h2>
              </Modal.Header>
              <div className="container-modal upload-content m-3">
                <div className="fields fields-file">
                  <label className="label fw-bold">Tipo:</label>
                  <span>{showDelete.data?.DESCR}</span>
                </div>
                <div className="fields fields-file">
                  <label className="label fw-bold">Fecha Inicio:</label>
                  <span>{moment(showDelete.data?.BEGIN_DT).format('DD/MM/YYYY')}</span>
                </div>
                <div className="fields fields-file">
                  <label className="label fw-bold">Fecha Final:</label>
                  <span>{moment(showDelete.data?.END_DT).format('DD/MM/YYYY')}</span>
                </div>
                <div className="fields fields-file">
                  <label className="label fw-bold">Certificado: </label>
                  <label className="file-name-label">{showDelete.data?.FILENAME}</label>
                </div>
              </div>
              <Modal.Footer>
                <button onClick={() => handleFileDelete(showDelete.data?.CN_ID_SOLICITUD)} className="button button-modal delete">
                  {DeleteFileFlag ? <div className="spinner-border spinner-border-sm"></div> : "Eliminar"}
                </button>
              </Modal.Footer>
            </div>
          </Modal>
          {/*MODAL ELIMINAR CERTIFICADO */}
          {!showFile ? null : fileModal()}
          {!error ? null : handleError()}
          {!errorDelete ? null : handleDeleteError()}
          {!certificateSent ? null : handleSuccess()}
          {!fileDeleted ? null : handleDeleteSuccess()}
        </div> : <div className="full-spinner"><div className="spinner-border text-primary"></div></div>}
    </>
  );
}
export default TablaLicencias;