import React from 'react'
import { Routes, Route } from "react-router-dom";
import NewsList from './news-list';
import NewsDetail from './news-detail';

const News = () => {
  return (
    <>
    <Routes>
        <Route path="" element={<NewsList />} />
        <Route path=":id" element={<NewsDetail />} />
    </Routes>
    </>
  )
}

export default News
