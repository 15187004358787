import React from "react";
import { CheckBoxSample } from "../commonComponents/CheckBoxSample";
import TitleCmp from "../commonComponents/TitleCmp";
import { TryRounded } from "@mui/icons-material";
import { MaintanceFunctionPais } from "./MaintanceFunctionPais";
import { countrys } from "./CountryDefn";



export const MaintanceFunction = ({
  features,
  dataFeature,
  setdataFeature,
  updateContent,
}) => {
 
  return (
    <div className="d-flex flex-column">
      {countrys.map((item, index) => {
        return (
          <MaintanceFunctionPais item={item} index={index} features={features}  dataFeature={dataFeature} setdataFeature={setdataFeature} updateContent={updateContent} key={index}/>
        );
      })}
    </div>
  );
};
