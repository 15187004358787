import axios from "axios";
import Auth from '@aws-amplify/auth';
import moment from 'moment'

const url=process.env.REACT_APP_BACKEND_URL_GLOBAL;

const AprobacionVacacionesServices = {
    mockGetSobordinatesAbsenceList: () => {
        const data = [
            {
                employeeId: "00061645",
                employeeRCD: "0",
                applicationId: "201900061644001",
                applicationRequestDate: "2019-10-07",
                applicationApprovedFlag: "Y",
                applicationApprovedBy: "00064111",
                applicationApprovedDate: "2019-10-10",
                state: "A",
                beginDate: "2019-10-11",
                returnDate: "2019-10-11",
                durationDays: "1",
                periodEnd: "2019-12-31",
                employeeName: "PAOLA INES",
                applicantComments: "",
                aprovedComments: "Aprobado",
                advanceDate: "2019-10-25"
            },
            {
                employeeId: "00061644",
                employeeRCD: "0",
                applicationId: "201900061644002",
                applicationRequestDate: "2019-11-06",
                applicationApprovedFlag: "Y",
                applicationApprovedBy: "00064111",
                applicationApprovedDate: "2019-11-06",
                state: "P",
                beginDate: "2019-11-07",
                returnDate: "2019-11-08",
                durationDays: "2",
                periodEnd: "2019-12-31",
                employeeName: "PAOLA INES LOPEZ",
                applicantComments: "",
                aprovedComments: "Vale aprobación se debe contemplar Sábado y Domingo en el próximo pedido de 3 días",
                advanceDate: "2019-10-25"
            },
            {
                employeeId: "00061644",
                employeeRCD: "0",
                applicationId: "201900061644003",
                applicationRequestDate: "2019-11-06",
                applicationApprovedFlag: "Y",
                applicationApprovedBy: "00064111",
                applicationApprovedDate: "2019-11-06",
                state: "R",
                beginDate: "2019-12-09",
                returnDate: "2019-12-15",
                durationDays: "7",
                periodEnd: "2019-12-31",
                employeeName: "PAOLA INES LOPEZ",
                applicantComments: "",
                aprovedComments: "",
                advanceDate: "2019-10-25"
            },
            {
                employeeId: "00061644",
                employeeRCD: "0",
                applicationId: "201900061644004",
                applicationRequestDate: "2019-10-07",
                applicationApprovedFlag: "Y",
                applicationApprovedBy: "00064111",
                applicationApprovedDate: "2019-10-10",
                state: "A",
                beginDate: "2019-10-11",
                returnDate: "2019-10-11",
                durationDays: "1",
                periodEnd: "2019-12-31",
                employeeName: "PAOLA INES LOPEZ",
                applicantComments: "",
                aprovedComments: "Aprobado",
                advanceDate: "2019-10-25"
            },
            {
                employeeId: "00061644",
                employeeRCD: "0",
                applicationId: "201900061644005",
                applicationRequestDate: "2019-11-06",
                applicationApprovedFlag: "Y",
                applicationApprovedBy: "00064111",
                applicationApprovedDate: "2019-11-06",
                state: "P",
                beginDate: "2019-11-07",
                returnDate: "2019-11-08",
                durationDays: "2",
                periodEnd: "2019-12-31",
                employeeName: "PAOLA INES LOPEZ",
                applicantComments: "",
                aprovedComments: "Vale aprobación se debe contemplar Sábado y Domingo en el próximo pedido de 3 días",
                advanceDate: "2019-10-25"
            },
            {
                employeeId: "00061644",
                employeeRCD: "0",
                applicationId: "201900061644006",
                applicationRequestDate: "2019-11-06",
                applicationApprovedFlag: "Y",
                applicationApprovedBy: "00064111",
                applicationApprovedDate: "2019-11-06",
                state: "R",
                beginDate: "2019-12-09",
                returnDate: "2019-12-15",
                durationDays: "7",
                periodEnd: "2019-12-31",
                employeeName: "PAOLA INES LOPEZ",
                applicantComments: "",
                aprovedComments: "",
                advanceDate: "2019-10-25"
            }
        ];
        return data;
    },
    getSubordinatesAbsenceList: async (estado, setInfo, setFilteredInfo, setLoadingAbsences, setNoData) => {
      
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(url + '/api/Employee/SubordinatesAbsenceList?state=' + estado, { headers: head })
                const sortedData = data.sort((a, b) => moment(b.beginDate, "AAAA-MM-DD").unix() - moment(a.beginDate, "AAAA-MM-DD").unix())
                setInfo(sortedData)
                setFilteredInfo(sortedData)
                setLoadingAbsences(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingAbsences(false);
                setNoData(true);
            })
        } catch (error) {
            console.error(error);
        }
    },
    AbsenceAction: (actions, setActionData, setLoadingAction, setNoDataAction, setServerMessage) => {
      
        let body = {
            "actions": actions,
        }
        Auth.currentSession().then(auth => {
            const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
            axios.post(url + '/api/Employee/AbsenceAction', body, { headers: head }).then((response) => {
                setServerMessage(response.data.descripcion);
                setActionData(true);
                if (response.status === 200) {
                    setLoadingAction(false);
                  }else {
                    setLoadingAction(false);
                    setNoDataAction(true);
                  }
          
            }).catch((error) => {
                console.log(error)
                setServerMessage(error.response?.data?.descripcion);
                setLoadingAction(false);
                setNoDataAction(true);
            })
        }).catch(function (error) {
            console.log(error, "Error");
            setLoadingAction(false);
        })
    }
   
}

export default AprobacionVacacionesServices;