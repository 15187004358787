import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Article, RelatedArticles } from '../../utilities/interfaces'
import { paths } from '../../utilities/paths'
import { ArticleContent } from '../../utilities/constantsTexts'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CircleIcon from '@mui/icons-material/Circle';
import {
  List,
  ListItem,
  IconButton,
  Grid,
  Button,
} from '@mui/material'
import { CollapseListProps } from './interfaces'
import styles from '../../commons/styles/rootStyle.module.css'
import collapseStyle from './collapseListStyle.module.css'
import tableStyle from '../tableList/styles/tableList.module.css'
import { UpdateLocalStorage } from '../../utilities/helpers/updateStorage'

const CollapseList = (props: CollapseListProps) => {
  const {
    articlelist,
    categoryName,
    subCategoryName,
    maxItems,
    isRelatedArticle,
    relatedArticles,
    isTopArticles
  } = props
  const [listToShow, setListToShow] = useState<Article[]>([])
  const [relatedList, setRelatedList] = useState<RelatedArticles[]>([])
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [showCollapseButton, setShowCollapseButton] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const update = new UpdateLocalStorage()

  useEffect(() => {
    if (!articlelist) return
    if (articlelist.length > maxItems) {
      setListToShow(articlelist.slice(0, maxItems))
      setShowCollapseButton(true)
      setIsCollapsed(true)
    } else {
      setListToShow(articlelist)
      setShowCollapseButton(false)
      setIsCollapsed(false)
    }
  }, [articlelist])

  useEffect(() => {
    if (!relatedArticles) return
    if (relatedArticles.length > maxItems) {
      setRelatedList(relatedArticles.slice(0, maxItems))
    } else {
      setRelatedList(relatedArticles)
    }
  }, [relatedArticles])

  useEffect(() => {
    if (!articlelist) return
    if (isCollapsed) {
      setListToShow(articlelist.slice(0, maxItems))
    } else {
      setListToShow(articlelist)
    }
  }, [isCollapsed])

  const navigateToArticle = (listItem: Article | RelatedArticles) => {
    if ('attributes' in listItem) {
      navigate(paths[3].path + listItem.attributes.slug + '/' + listItem.id, { state: { from: location.pathname } })
    } else if ('slug' in listItem) {
      checkForPrevArticle()
      navigate(paths[3].path + listItem.slug + '/' + listItem.id, { state: { from: location.pathname } })
    }
  };

  const checkForPrevArticle = () => {
    const savedPrevArticles = localStorage.getItem(update.keyConstants[5])
    if(savedPrevArticles === null) {
      update.updateSlot(update.keyConstants[5], location.pathname)
    } else {
      const formated = [savedPrevArticles, location.pathname]
      update.updateSlot(update.keyConstants[5], formated.toString())
    }
  }

  const collapseList = () => {
    setIsCollapsed(!isCollapsed)
  }

  const collapseButton = (): string => {
    if (!articlelist) return ''
    if (isCollapsed) {
      const questions = articlelist.length
      return ArticleContent.collapseButtonClosed + '(' + questions + ')'
    } else {
      return ArticleContent.collapseButtonOpen
    }
  }

  const InnerList = (): Array<any> => {
    let list = isRelatedArticle ? relatedList : listToShow
    return list
  }

  const extractValidTitles = (listItem: Article) => {
    const categoryValid =
      listItem.attributes.sub_category?.data.attributes.category.data.attributes.title ?
        listItem.attributes.sub_category?.data.attributes.category.data.attributes.title : ''
    const subValid = listItem.attributes.sub_category?.data.attributes.title ?
      listItem.attributes.sub_category?.data.attributes.title : ''
    const validInfo = { categoryValid, subValid }
    return validInfo
  }

  const standarizedArticles = (listItem: Article | RelatedArticles) => {
    let articleFormated = {
      title: '',
      views: '',
      sub: '',
      categoryTitle: ''
    }
    if (isRelatedArticle && 'title' in listItem && listItem.sub_category) {
      articleFormated = {
        title: listItem.title,
        views: listItem.views?.toString(),
        sub: listItem.sub_category.title,
        categoryTitle: listItem.sub_category.category.title
      }
      return articleFormated
    } else if (!isRelatedArticle && !isTopArticles && 'attributes' in listItem) {
      const hasViews = listItem.attributes.views?.toString()
      articleFormated = {
        title: listItem.attributes.title,
        views: hasViews ? hasViews : '',
        sub: subCategoryName ? subCategoryName : '',
        categoryTitle: categoryName ? categoryName : '',
      }
      return articleFormated
    } else if (isTopArticles && 'attributes' in listItem) {
      const hasViews = listItem.attributes.views?.toString()
      articleFormated = {
        title: listItem.attributes.title,
        views: hasViews ? hasViews : '',
        sub: extractValidTitles(listItem).subValid,
        categoryTitle: extractValidTitles(listItem).categoryValid,
      }
      return articleFormated
    } else {
      return null
    }
  }

  const InnerListItem = (listItem: Article | RelatedArticles) => {
    const formatedItem = standarizedArticles(listItem)
    if (formatedItem === null) return
    return (
      <Grid item>
        <p className={`${tableStyle.list_item_title} ${collapseStyle.collapse_list_item_title}`}>
          {formatedItem.title}
        </p>
        <p className={collapseStyle.collapse_list_item_subtitle}>
          {formatedItem.views} {ArticleContent.views}
          <CircleIcon />
          {formatedItem.categoryTitle}
          <CircleIcon />
          {formatedItem.sub}
        </p>
      </Grid>
    )
  }

  return (
    <div className={tableStyle.table_list_container}>
      <List className={`${tableStyle.table_list_wrapper} ${collapseStyle.collapse_list}`}>
        {InnerList().map((listItem: Article | RelatedArticles) => {
          return (
            <ListItem
              onClick={() => navigateToArticle(listItem)}
              className={`${tableStyle.list_item} ${collapseStyle.collapse_list_item}`}
              key={listItem.id}
              secondaryAction={
                <IconButton
                  className={`${tableStyle.list_item_button} ${collapseStyle.collapse_list_item_button}`}
                  edge="end"
                  aria-label="ir al artículo"
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              }
            >
              {InnerListItem(listItem)}
            </ListItem>
          )
        })}
      </List>
      {showCollapseButton &&
        <Grid item xs={12} className={collapseStyle.collapse_button_wrapper}>
          <Button className={styles.terciary_button} onClick={() => collapseList()}>
            {collapseButton()}
          </Button>
        </Grid>
      }
    </div>
  );
}

export default CollapseList;
