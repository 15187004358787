import React, { useState } from 'react';


import usePosition from '../mi equipo/hooks/usePosition';
import { Link } from "react-router-dom";
import usePhoto from '../mi equipo/hooks/usePhoto';
import useDiasTomados from '../mi equipo/hooks/useDiasTomados';
import useCartolaVacaciones from '../mi equipo/hooks/useCartolaVacaciones';

const DetailMiEquipo = ({ employeeDetailed }) => {

    const codigosPais = {
        "Argentina": "AR",
        "Chile": "CL",
        "Colombia": "CO",
        "Uruguay": "UY",
        "Perú": "PE",
        "Brasil": "BR"
      };

    const region = codigosPais[employeeDetailed.country];
    const legajo = employeeDetailed.legajo === 'object' ? employeeDetailed.legajo.low : employeeDetailed.legajo;
    const valor = region === 'CL' ? employeeDetailed.nro_document : legajo.toString();

    const { position, statusPosition, loadingPosition } = usePosition(valor, region);
    const { photo } = usePhoto();
    const [hiddenTd, setHiddenTd] = useState(true);
    const { diasTomados, statusDiasTomados, loadingDiasTomados } = useDiasTomados(valor, region);
    const [carouselSelected, setCarouselSelected] = useState(0);
    const { cartolaVacaciones, statusCartolaVacaciones, loadingCartolaVacaciones } = useCartolaVacaciones(valor, region);

    const paises = {
        AR: {
            puesto: [
                [{
                    name: "summary/SummaryRegional",
                    props: {
                        colaborador: position,
                        foto: photo,
                        statusPosition: statusPosition,
                        loadingPosition: loadingPosition
                    }
                },
                {
                    name: "datosPuesto/DatosPuestoRegional",
                    props: {
                        colaborador: position,
                        foto: photo,
                        statusPosition: statusPosition,
                        loadingPosition: loadingPosition
                    }
                }],
                {
                    name: "historialPosicionesUbicaciones/HistorialPosicionesUbicaciones",
                    props: {
                        data: position,
                        loadingData: loadingPosition
                    }
                }

            ],
            remuneraciones: [
                [{
                    name: "summary/SummaryRegional",
                    props: {
                        colaborador: position,
                        foto: photo,
                        statusPosition: statusPosition,
                        loadingPosition: loadingPosition
                    }
                },
                {
                    name: "datosRemuneraciones/DatosRemuneracionesRegional",
                    props: {
                        colaborador: position,
                        loadingColaborador: loadingPosition,
                        hiddenTd: hiddenTd,
                    }
                }],
                {
                    name: "graficoCambiosDeRenta/GraficoCambiosDeRentaRegional",
                    props: {
                        colaborador: position,
                        loadingColaborador: loadingPosition,
                        hiddenTd: hiddenTd,

                    }
                },
                {
                    name: "cambiosDeRenta/CambiosDeRenta",
                    props: {
                        colaborador: position,
                        loadingColaborador: loadingPosition,
                        hiddenTd: hiddenTd,
                    }
                },
            ],


            vacaciones: [
                {
                    name: "diasTomados/DiasTomados",
                    props: {
                        diasTomadosData: diasTomados,
                        statusDiasTomados: statusDiasTomados,
                        loadingDiasTomados: loadingDiasTomados,
                    }

                },
                {
                    name: "graficoCartola/GraficoCartola",
                    props: {
                        cartolaVacaciones: cartolaVacaciones,
                        statusCartolaVacaciones: statusCartolaVacaciones,
                        loadingCartolaVacaciones: loadingCartolaVacaciones
                    }
                },
                {
                    name: "cartola/Cartola",
                    props: {
                        cartolaVacaciones: cartolaVacaciones,
                        statusCartolaVacaciones: statusCartolaVacaciones,
                        loadingCartolaVacaciones: loadingCartolaVacaciones
                    }
                },

            ]
        },
        UY: {
            puesto: [
                [{
                    name: "summary/SummaryRegional",
                    props: {
                        colaborador: position,
                        foto: photo,
                        statusPosition: statusPosition,
                        loadingPosition: loadingPosition
                    }
                },
                {
                    name: "datosPuesto/DatosPuestoRegional",
                    props: {
                        colaborador: position,
                        foto: photo,
                        statusPosition: statusPosition,
                        loadingPosition: loadingPosition
                    }
                }],
                {
                    name: "historialPosicionesUbicaciones/HistorialPosicionesUbicaciones",
                    props: {
                        data: position,
                        loadingData: loadingPosition
                    }
                }

            ],
            remuneraciones: [
                [{
                    name: "summary/SummaryRegional",
                    props: {
                        colaborador: position,
                        foto: photo,
                        statusPosition: statusPosition,
                        loadingPosition: loadingPosition
                    }
                },
                {
                    name: "datosRemuneraciones/DatosRemuneracionesRegional",
                    props: {
                        colaborador: position,
                        loadingColaborador: loadingPosition,
                        hiddenTd: hiddenTd,
                    }
                }],
                {
                    name: "graficoCambiosDeRenta/GraficoCambiosDeRentaRegional",
                    props: {
                        colaborador: position,
                        loadingColaborador: loadingPosition,
                        hiddenTd: hiddenTd,

                    }
                },
                {
                    name: "cambiosDeRenta/CambiosDeRenta",
                    props: {
                        colaborador: position,
                        loadingColaborador: loadingPosition,
                        hiddenTd: hiddenTd,
                    }
                },
            ],
        },
        CO: {
            puesto: [
                [{
                    name: "summary/SummaryRegional",
                    props: {
                        colaborador: position,
                        foto: photo,
                        statusPosition: statusPosition,
                        loadingPosition: loadingPosition
                    }
                },
                {
                    name: "datosPuesto/DatosPuestoRegional",
                    props: {
                        colaborador: position,
                        foto: photo,
                        statusPosition: statusPosition,
                        loadingPosition: loadingPosition
                    }
                }],
                {
                    name: "historialPosicionesUbicaciones/HistorialPosicionesUbicaciones",
                    props: {
                        data: position,
                        loadingData: loadingPosition
                    }
                }

            ],
            remuneraciones: [
                [{
                    name: "summary/SummaryRegional",
                    props: {
                        colaborador: position,
                        foto: photo,
                        statusPosition: statusPosition,
                        loadingPosition: loadingPosition
                    }
                },
                {
                    name: "datosRemuneraciones/DatosRemuneracionesRegional",
                    props: {
                        colaborador: position,
                        loadingColaborador: loadingPosition,
                        hiddenTd: hiddenTd,
                    }
                }],
                {
                    name: "graficoCambiosDeRenta/GraficoCambiosDeRentaRegional",
                    props: {
                        colaborador: position,
                        loadingColaborador: loadingPosition,
                        hiddenTd: hiddenTd,

                    }
                },
                {
                    name: "cambiosDeRenta/CambiosDeRenta",
                    props: {
                        colaborador: position,
                        loadingColaborador: loadingPosition,
                        hiddenTd: hiddenTd,
                    }
                },
            ],
        },
        CL: {
            puesto: [
                [{
                    name: "summary/Summary",
                    props: {
                        colaborador: position,
                        foto: photo,
                        statusPosition: statusPosition,
                        loadingPosition: loadingPosition
                    }
                }, {
                    name: "datosPuesto/DatosPuesto",
                    props: {
                        colaborador: position,
                        foto: photo,
                        statusPosition: statusPosition,
                        loadingPosition: loadingPosition
                    }
                }],
                {
                    name: "evaluacionDesempenio/EvaluacionDesempenio",
                    props: {
                        data: position,
                        loadingData: loadingPosition
                    }
                },
                {
                    name: "historialPosicionesUbicaciones/HistorialPosicionesUbicaciones",
                    props: {
                        data: position,
                        loadingData: loadingPosition
                    }
                }
            ],
            remuneraciones: [
                [{
                    name: "summary/Summary",
                    props: {
                        colaborador: position,
                        foto: photo,
                        statusPosition: statusPosition,
                        loadingPosition: loadingPosition
                    }
                },
                {
                    name: "datosRemuneraciones/DatosRemuneraciones",
                    props: {
                        colaborador: position,
                        loadingColaborador: loadingPosition,
                        hiddenTd: hiddenTd,
                    }
                }], [
                    {
                        name: "ultimaRemuneracion/UltimaRemuneracion",
                        props: {
                            colaborador: position,
                            loadingColaborador: loadingPosition,
                            hiddenTd: hiddenTd,
                        }
                    },
                    {
                        name: "graficoCambiosDeRenta/GraficoCambiosDeRenta",
                        props: {
                            colaborador: position,
                            loadingColaborador: loadingPosition,
                            hiddenTd: hiddenTd,

                        }
                    }],
                {
                    name: "cambiosDeRenta/CambiosDeRenta",
                    props: {
                        colaborador: position,
                        loadingColaborador: loadingPosition,
                        hiddenTd: hiddenTd,
                    }
                },

            ],
            vacaciones: [
                {
                    name: "diasTomados/DiasTomados",
                    props: {
                        diasTomadosData: diasTomados,
                        statusDiasTomados: statusDiasTomados,
                        loadingDiasTomados: loadingDiasTomados,
                    }

                },
                {
                    name: "graficoCartola/GraficoCartola",
                    props: {
                        cartolaVacaciones: cartolaVacaciones,
                        statusCartolaVacaciones: statusCartolaVacaciones,
                        loadingCartolaVacaciones: loadingCartolaVacaciones
                    }
                },
                {
                    name: "cartola/Cartola",
                    props: {
                        cartolaVacaciones: cartolaVacaciones,
                        statusCartolaVacaciones: statusCartolaVacaciones,
                        loadingCartolaVacaciones: loadingCartolaVacaciones
                    }
                }
            ]
        }
    }
    const countryData = paises[region];


    const DinamicComponent = (component, index) => {

        const { name, props } = component;
        const Component = require(`./${name}`).default;
        return <Component key={index} {...props} />;

    }

    const DinamicArrayComponent = (component, index,) => {

        if (Array.isArray(component)) {
            return component?.map((subComponent, index) => {
                return DinamicComponent(subComponent, index)
            })
        } else {
            return DinamicComponent(component, index)
        }


    }

    return (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="../">
                            <i style={{ pointerEvents: "none" }} aria-hidden="true"></i> Mi equipo
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        <Link to="#" onClick={() => (isOnline ? (colaborador === "error" ? window.location.reload() : null) : null)}>
                            <i style={{ pointerEvents: "none" }} aria-hidden="true"></i> Ficha Resumen
                        </Link>
                    </li>
                </ol>
            </nav>
            <section className="mi-equipo">
                <h1>Ficha Resumen</h1>
            </section>
            <div id="carousel-ficha-resumen" className="d-none d-md-block">
                <div className="row">
                    {countryData.puesto && <div className="col mb-4">
                        <Link
                            onClick={() => { setCarouselSelected(0) }}
                            className={`btn btn-xl text-uppercase ${carouselSelected === 0 ? "btn-degradado-2" : ""}`}
                            to="#"
                        >
                            <span className="align-self-center">Datos de puesto / Compensación</span>
                            <i className="fa fa-chevron-right" aria-hidden="true"></i>
                        </Link>
                    </div>}
                    {countryData.remuneraciones && <div className="col mb-4">
                        <Link
                            onClick={() => { setCarouselSelected(1); }}
                            className={`btn btn-xl text-uppercase ${carouselSelected === 1 ? "btn-degradado-2" : ""}`}
                            to="#"
                        >
                            <span className="align-self-center">Datos de Remuneraciones</span>
                            <i className="fa fa-chevron-right" aria-hidden="true"></i>
                        </Link>
                    </div>}
                    {countryData.vacaciones && <div className="col mb-4">
                        <Link
                            onClick={() => {
                                setCarouselSelected(2);
                            }}
                            className={`btn btn-xl text-uppercase ${carouselSelected === 2 ? "btn-degradado-2" : ""}`}
                            to="#"
                        >
                            <span className="align-self-center">Datos de Vacaciones</span>
                            <i className="fa fa-chevron-right" aria-hidden="true"></i>
                        </Link>
                    </div>}
                </div>
            </div>

            {
                carouselSelected === 0 ?
                    loadingPosition ? <div><div data-testid="loading" className="full-spinner">
                        <div className="spinner-border text-primary"></div>
                    </div></div> :
                        (<div id="fichaVacaciones">
                            {countryData.puesto.map((component, index) => {
                                return (
                                    <div className="row row-cols-1 g-4 mb-4">
                                        {DinamicArrayComponent(component, index)}
                                    </div>
                                )
                            })
                            }
                        </div>
                        )
                    : null
            }

            {
                (carouselSelected === 1) ?
                    (loadingPosition) ? <div><div data-testid="loading" className="full-spinner">
                        <div className="spinner-border text-primary"></div>
                    </div></div> : (
                        <div id="fichaRemuneracion">
                            <div className="form-check form-switch d-inline-block ms-4">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    defaultChecked={!hiddenTd}
                                    checked={!hiddenTd}
                                    id="datos-ocultos"
                                    onChange={() => {
                                        setHiddenTd(!hiddenTd);
                                    }}
                                />
                                <label className="form-check-label text-uppercase" htmlFor="datos-ocultos">
                                    Datos visibles
                                </label>
                            </div>
                            {countryData.remuneraciones.map((component, index) => {
                                return (
                                    <div className="row row-cols-1 g-4 mb-4">
                                        {DinamicArrayComponent(component, index)}
                                    </div>
                                )
                            })}
                        </div>) : null
            }

            {
                carouselSelected === 2 ?
                    (loadingDiasTomados && loadingCartolaVacaciones) ? <div><div data-testid="loading" className="full-spinner">
                        <div className="spinner-border text-primary"></div>
                    </div></div> : (
                        <div id="fichaVacaciones">
                            {countryData.vacaciones?.map((component, index) => {
                                return (
                                    <div className="row row-cols-1 g-4 mb-4">
                                        {DinamicArrayComponent(component, index)}
                                    </div>
                                )

                            })}
                        </div>) : null
            }
        </>
    )
};

export default DetailMiEquipo;