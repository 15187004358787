import Auth from "@aws-amplify/auth";
import { urls, urlsAws } from "../resources/foo/api-endpoints";

const GeoVictoriaService = {
    libroAsistencia: (emplid, params, setResponse, setError, setLoading) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: JSON.stringify({ StartDate: params.StartDate, EndDate: params.EndDate }),
            };
            setLoading(true);
            fetch(urlsAws.getLibroAsistencia , requestOptions)
                .then((response) => response.json())
                .then((e) => {
                    if (e != undefined && e.data) {
                        caches.open("geovictoria-cache").then((cache) => {
                            cache.put(urlsAws.getLibroAsistencia + "-" + params.StartDate + "-" + emplid, new Response(JSON.stringify(e.Users)));
                            setResponse(e.data);
                            setLoading(false);
                        });
                    } else if(e.message){
                        setResponse(2);
                        setError("No se ha podido recuperar información.");
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    caches.match(urlsAws.getLibroAsistencia + "-" + params.StartDate + "-" + emplid).then((response) => {
                        if (response) {
                            response.json().then((params) => {
                                setResponse(params);
                                setLoading(false);
                            });
                        } else {
                            setResponse(2);
                            setError("Sin datos para mostrar");
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },
};
export default GeoVictoriaService;
