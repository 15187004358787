import React, { FC, ReactElement } from "react";
import {
    FaXTwitter,
    FaWhatsapp,
    FaFacebookF,
    FaPaperPlane,
    FaLinkedinIn,
} from "react-icons/fa6";

type SocialName = "Facebook" | "Whatsapp" | "Telegram" | "X" | "Linkedin";
type SocialMedia = Record<
    SocialName,
    { className: string; urlBase: string; icon: ReactElement }
>;

interface Props {
    url: string;
    socialName: SocialName;
}

const SOCIAL_MEDIA: SocialMedia = {
    Facebook: {
        className: "share-facebook",
        urlBase: "https://www.facebook.com/sharer/sharer.php?u=",
        icon: <FaFacebookF />,
    },
    Whatsapp: {
        className: "share-whatsapp",
        urlBase: "https://wa.me/?text=",
        icon: <FaWhatsapp />,
    },
    Telegram: {
        className: "share-telegram",
        urlBase: "https://t.me/share/url?url=",
        icon: <FaPaperPlane />,
    },
    X: {
        className: "share-x",
        urlBase: "https://twitter.com/intent/tweet?url=",
        icon: <FaXTwitter />,
    },
    Linkedin: {
        className: "share-linkedin",
        urlBase: "https://www.linkedin.com/shareArticle?mini=true&url=",
        icon: <FaLinkedinIn />,
    },
};

export const ShareLink: FC<Props> = ({ url, socialName }) => {
    return (
        <a
            className={SOCIAL_MEDIA[socialName].className}
            href={SOCIAL_MEDIA[socialName].urlBase + url}
            target="_blank"
            rel="noopener noreferrer"
        >
            {SOCIAL_MEDIA[socialName].icon}
        </a>
    );
};
