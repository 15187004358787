import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { Context } from "../../context";
import styles from "../../resources/css/style.module.css";

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
};
const GlobalNavbar = () => {
    const { step, listStep, active, setActive, setShrink, menuItems } = useContext(Context);
    const [stateSideBar, setStateSideBar] = useState("");
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        if (windowDimensions.width > 991) {
            setActive(false);
        } else {
            setShrink(false);
        }
    }, [windowDimensions.width, setActive, setShrink]);
    useEffect(() => {
        if (active) {
            setStateSideBar(" active");
        } else {
            setStateSideBar("");
        }
    }, [active]);
    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const itemSelected = (item) => {
        const classList = document.getElementsByClassName("nav-item-regional");
        for (let i = 0; i < classList.length; i++) {
            classList[i].className = "nav-item-regional active";
        }
        if (windowDimensions.width < 990) {
            if (item !== 69 && item !== 420) {
                document.getElementById("sidebarCollapse").click();
            }
        }
    };

    const Styles = {
        gray: { color: "gray" },
        blue: { color: "#0169b5" },
    };

    return (
        <nav id="sidebar" className={`mainmenu mCustomScrollbar _mCS_1 mCS-autoHide mCS_no_scrollbar ${stateSideBar}`}>
            <ul className={styles['list-unstyled']}>
                {menuItems && menuItems.map((item, index) => {
                    // Adds Hidden attribute from content reference to avoid li entry in Menu.
                    // This attribute allows devs to add dynamic routing with react router (ie.: /foopath/:fooid).
                    if (!item.publico && !item.hidden) {
                        if (!item.haschildrens) {
                            return (
                               item.label && (
                                        <li key={index} onClick={() => itemSelected()} className="nav-item-regional">
                                            {item.component !== "" ? (
                                                <Link className="nav-link-regional" to={item.url} data-bs-toggle="tooltip" title={item.label} >
                                                    <i className={item.class} style={Styles.blue} aria-hidden="true"> </i>
                                                    <span className="nav-link__hide"  > {item.label}</span>
                                                </Link>
                                            ) :
                                                (<a className="nav-link-regional" href={item.url} data-bs-toggle="tooltip" title={item.label} target="_blank" rel="noopener noreferrer">
                                                    <i className={item.class} style={Styles.blue} aria-hidden="true"> </i>
                                                    <span className="nav-link__hide"  > {item.label}</span>
                                                </a>
                                                )}
                                        </li>
                                    )
                            )
                        } else if (item.haschildrens) {
                            return <li key={index} name="elHermano" onClick={() => itemSelected(420)} className="nav-item-regional parent">
                                <a className="nav-link-regional" data-bs-toggle="collapse" href={"/#" + item.title} role="button" aria-expanded="false" title={item.label}>
                                    <i className={item.class} aria-hidden="true"></i>
                                    <span className="nav-link__hide">{item.label}</span>
                                    <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                </a>
                                <div className="collapse" id={item.title}>
                                    <ul className={`${styles['list-unstyled']} nav-child`}>
                                        {item.subitems.map((subitem, index) => {
                                            return <li key={index} onClick={windowDimensions.width < 990 ? () => document.getElementById("sidebarCollapse").click() : null} className="nav-item-regional">
                                                {subitem.component !== "" ? <Link className="nav-link-regional" to={subitem.url}>{subitem.label}</Link> : <a className="nav-link-regional" href={subitem.url} target="_blank" rel="noopener noreferrer">{subitem.label}</a>} </li>
                                        })}
                                    </ul>
                                </div>
                            </li>
                        }
                    }
                })}
            </ul>
        </nav>
    );
};

export default GlobalNavbar;
