import Auth from "@aws-amplify/auth";
import { readerTaskEither as RTE } from "fp-ts";
import * as t from "io-ts";
import { urlsAws } from "../../../../resources/foo/api-endpoints";
import { HttpRequestError, HttpContentTypeError, HttpResponseStatusError, HttpClientEnv, voucherCodec, Voucher } from "../interfaces";
import { getJson } from "./httpClient";

export const getVoucher = async (params: string) => {
    const authorization = await Auth.currentSession().then((auth) => auth.getIdToken().getJwtToken());
    const accessToken = await Auth.currentSession().then((auth) => auth.getAccessToken().getJwtToken());
    const requestOptions: RequestInit = {
        method: "GET",
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: authorization, AccessToken: accessToken },
    };
    const response: RTE.ReaderTaskEither<
            HttpClientEnv,
            HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
            Voucher
        > = getJson(urlsAws.getPersonalVoucher, params, requestOptions, voucherCodec.decode)
    
    return response;
};