import React from "react";
import { Link } from "react-router-dom";
import argentinianFlag from "../assets/flags/argentina.png";
import brazilianFlag from "../assets/flags/brazil.png";
import chileanFlag from "../assets/flags/chile.png";
import chineseFlag from "../assets/flags/china.png";
import colombianFlag from "../assets/flags/colombia.png";
import peruvianFlag from "../assets/flags/peru.png";
import uruguayanFlag from "../assets/flags/uruguay.png";

const ReportingChannel = () => {
    return (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/inicio"><i className="fa fa-home" aria-hidden="true" /> Inicio</Link></li>
                    <li className="breadcrumb-item"><a href="#" onClick={(e) => { e.preventDefault() }}>Código de Ética</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Canales de Denuncia</li>
                </ol>
            </nav>              
            <section className="canales-de-denuncia">
                <h1>Canales de Denuncia</h1>
                <div className="box mb-4">
                    <h2 className="h4 tit-section">Procedimiento de Consultas y Canalización de Denuncias</h2>
                    <p>Como apoyo a nuestro Código de Ética, implementamos múltiples canales formales, administrados por un tercero independiente, con experiencia internacional en la canalizaciòn de denuncias, donde nuestros colaboradores pueden acudir en caso de dudas o de detectar incumplimiento a situaciones detectadas en este documento, garantizando su absoluta confidencialidad.</p>
                    <div className="d-flex align-items-center p-3 bg-light-2 rounded-4">
                        <i className="fa fa-info-circle fa-lg color-primary me-2" aria-hidden="true" />
                        <p className="m-0">Los canales de denuncia fueron creados para presentar situaciones que puedan "ser" o "parecer" un conflicto de interés al Código de Ética o a las Políticas de Cencosud.</p>
                    </div>
                </div>
                <div className="box mb-4">
                    <h2 className="h4 tit-section">Canales de Consulta y Denuncia</h2>
                    <div className="p-4 mb-4 border border-primary rounded-4">
                        <h3 className="h5">Línea Telefónica Gratuita</h3>
                        <p>Colabradores de Cencosud. El mismo estará presente en horario de oficina vigente en cada país.</p>
                        <div className="row mt-4">
                            <div className="col-md-6 col-xl-4 d-flex align-items-center mb-3">
                                <img className="me-3" width="32" height="32" src={argentinianFlag} alt="" />
                                <p className="fw-bold m-0">{"Argentina: "}<a className="text-decoration-none" href="tel:+08003481003">0800 348 1003</a></p>
                            </div>
                            <div className="col-md-6 col-xl-4 d-flex align-items-center mb-3">
                                <img className="me-3" width="32" height="32" src={chileanFlag} alt="" />
                                <p className="fw-bold m-0">{"Chile: "}<a className="text-decoration-none" href="tel:+800914601">800 914 601</a></p>
                            </div>
                            <div className="col-md-6 col-xl-4 d-flex align-items-center mb-3">
                                <img className="me-3" width="32" height="32" src={colombianFlag} alt="" />
                                <p className="fw-bold m-0">{"Colombia: "}<a className="text-decoration-none" href="tel:+018005185244">018005 185 244</a></p>
                            </div>
                            <div className="col-md-6 col-xl-4 d-flex align-items-center mb-3">
                                <img className="me-3" width="32" height="32" src={brazilianFlag} alt="" />
                                <p className="fw-bold m-0">{"Brasil: "}<a className="text-decoration-none" href="tel:+008005802895">00800 580 2895</a></p>
                            </div>
                            <div className="col-md-6 col-xl-4 d-flex align-items-center mb-3">
                                <img className="me-3" width="32" height="32" src={chineseFlag} alt="" />
                                <p className="fw-bold m-0">{"China: "}<a className="text-decoration-none" href="tel:+862160314569">86 21 60314569</a></p>
                            </div>
                            <div className="col-md-6 col-xl-4 d-flex align-items-center mb-3">
                                <img className="me-3" width="32" height="32" src={peruvianFlag} alt="" />
                                <p className="fw-bold m-0">{"Perú: "}<a className="text-decoration-none" href="tel:+080070272">0800 70272</a></p>
                            </div>
                            <div className="col-md-6 col-xl-4 d-flex align-items-center mb-3">
                                <img className="me-3" width="32" height="32" src={uruguayanFlag} alt="" />
                                <p className="fw-bold m-0">{"Uruguay: "}<a className="text-decoration-none" href="tel:+000405296728">000 4052 96728</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="p-4 mb-4 border border-primary rounded-4">
                        <h3 className="h5">Página Web</h3>
                        <p>Sitio de internet, provisto por un tercero independiente: <a href="http://www.eticacencosud.com">www.eticacencosud.com</a>.</p>
                        <h3 className="h5">Correo electrónico</h3>
                        <p>Casilla de correo corporativo, al cual los Colaboradores podrán escribir y que será revisado exclusivamente por un tercero independiente: <a href="mailto:lineaetica@cencosud.com">lineaetica@cencosud.com</a>.</p>
                        <h3 className="h5">Entrevistas personales o Virtuales</h3>
                        <p>Se solicitan al email: <a href="mailto:lineaetica@cencosud.com">lineaetica@cencosud.com</a>.</p>
                        <h3 className="h5">Cartas Físicas</h3>
                        <p>Se envían a las siguientes direcciones:</p>
                        <ol>
                            <li>Resguarda 25 de Mayo 432, Piso 3. Ciudad autónoma de Buenos Aires, Argentina.</li>
                            <li>Resguarda Av. Eng. Luis Carlos Berrini, 550, 4º andar, - Broklin, Sao Paulo, Brasil.</li>
                        </ol>
                    </div>
                    <div className="p-4 mb-4 border border-primary rounded-4">
                        <h3 className="h5">Whatsapp</h3>
                        <div className="row mt-4">
                            <div className="col-md-6 col-xl-4 mb-4">
                                <div className="d-flex mb-2">
                                    <img className="me-3" width="32" height="32" src={argentinianFlag} alt="" />
                                    <img className="me-3" width="32" height="32" src={chileanFlag} alt="" />
                                    <img className="me-3" width="32" height="32" src={colombianFlag} alt="" />
                                    <img className="me-3" width="32" height="32" src={peruvianFlag} alt="" />
                                    <img className="me-3" width="32" height="32" src={uruguayanFlag} alt="" />
                                </div>
                                <p className="fw-bold m-0">{"Español: "}<a className="text-decoration-none" href="tel:+56232150270">+56 232 150 270</a></p>
                            </div>
                            <div className="col-md-6 col-xl-4 mb-3">
                                <div className="d-flex align-items-center mb-3">
                                    <img className="me-3" width="32" height="32" src={brazilianFlag} alt="" />
                                    <p className="fw-bold m-0">{"Portugués: "}<a className="text-decoration-none" href="tel:+56232150271">+56 232 150 271</a></p>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-4 mb-3">
                                <div className="d-flex align-items-center mb-3">
                                    <img className="me-3" width="32" height="32" src={chineseFlag} alt="" />
                                    <p className="fw-bold m-0">{"Inglés: "}<a className="text-decoration-none" href="tel:+56232150272">+56 232 150 272</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="color-primary mb-0">La investigación y resolución de los casos que se denuncien serán de responsabilidad de las áreas de Auditoría Corporativa y Equipo Corporativo de Personas y aquellas denuncias que impliquen una mayor complejidad, serán responsabilidad del Comité de Ética. Nos aseguraremos que cada denuncia tenga un código de seguimiento para informar, si corresponde, el estado de cada caso.</p>
                </div>
            </section>
        </>
    )
}

export default ReportingChannel;