import axios from 'axios';
import Auth from '@aws-amplify/auth';
import { FEATURE_FLAGS } from '../../utils/enum/featureFlags.enum';

const url = process.env.REACT_APP_BACKEND_URL_GLOBAL;
// const url = 'http://localhost:3001'; // for local testing only

const ReplacementsService = {

    getReplacementsFeatureFlag: async () => {
        try {
            const authHeader = await getAuthHeader();
            const response = await axios.get(`${url}/api/featureflag/${FEATURE_FLAGS.REPLACEMENT_VACATIONS}`, { headers: authHeader });
            return response
        } catch (error) {
            console.error('Error getting feature flag:', error.response?.data);
            throw error;
        }
    },
    
    getReplacementPrompt: async (setLoading, setReplacementsPrompt, setDataNotFetched) => {
        try {
            const authHeader = await getAuthHeader();
            const response = await axios.get(`${url}/api/replacements/getreplacementprompt`, { headers: authHeader });
            setReplacementsPrompt(response.data);
        } catch (error) {
            setDataNotFetched(true);
            console.error('Error getting replacement prompt:', error.response?.data);
            throw error;
        } finally {
            setLoading(false);
        }
    },

    saveReplacement: async (setLoadingPost, replacementData, setResponse, setSuccess) => {
        try {
            setLoadingPost(true);
            const authHeader = await getAuthHeader();
            const response = await axios.post(
                `${url}/api/replacements/saveReplacement`,
                replacementData,
                { headers: authHeader }
            ).then((response) => {
                setResponse(response.data);
            });
        } catch (error) {
            console.error('Error saving replacement:', error.response?.data);
            throw error;
        } finally {
            setLoadingPost(false);
            setSuccess(true)
        }
    },
    getCurrentReplacement: async (setLoadingTable, setCurrentReplacement, setDataNotFetched) => {
        try {
            setLoadingTable(true);
            const authHeader = await getAuthHeader();
            const response = await axios.get(`${url}/api/replacements/getreplacementsaved`, { headers: authHeader });
            console.log('response', response)
            setCurrentReplacement(response.data);
        } catch (error) {
            setLoadingTable(false);
            console.error('Error getting current replacement:', error.response?.data);
            setDataNotFetched(true)
            throw error;
        } finally {
            setLoadingTable(false);

        }
    },
    getUserReplacements: async (setLoadingTable, setUserReplacements, setDataNotFetched) => {
        try {
            setLoadingTable(true);
            const authHeader = await getAuthHeader();
            const response = await axios.get(`${url}/api/replacements/getUserReplacements`, { headers: authHeader });
            console.log('response', response)
            setUserReplacements(response.data);
        } catch (error) {
            setLoadingTable(false);
            console.error('Error getting current replacement:', error.response?.data);
            setDataNotFetched(true)
            throw error;
        } finally {
            setLoadingTable(false);

        }
    },
    hasSubordinates: async (setIsApprover, setLoadingApproverStatus) => {
        try {
            setLoadingApproverStatus(true);
            const authHeader = await getAuthHeader();
            const response = await axios.get(`${url}/api/replacements/hassubordinates`, { headers: authHeader }).then((res) => {
                setIsApprover(res.data);
            });
        } catch (error) {
            setLoadingApproverStatus(false);
            console.error('Error getting current replacement:', error.response?.data);
            setDataNotFetched(true)
            throw error;
        } finally {
            setLoadingApproverStatus(false);
        }
    },
    invalidateReplacement: async (id, setCurrentLoadingState, setInvalidationMessage) => {
        const authHeader = await getAuthHeader();
        const response = await axios.post(`${url}/api/replacements/invalidatereplacement?id=${id}`, {}, { headers: authHeader }).then((res) => {
            setInvalidationMessage(res?.data);
        }).catch((error) => {
            setInvalidationMessage(error?.data?.message || error?.response?.data?.message);
            console.error('Error invalidating replacement:', error.response?.data);
            throw error;
        }).finally(() => {
            setCurrentLoadingState(false);

        })
    }
};

const getAuthHeader = async () => {
    const auth = await Auth.currentSession();
    return {
        'Content-Type': 'application/json',
        'AccessToken': auth.getAccessToken().getJwtToken(),
    };
};

export default ReplacementsService;
