import React, { useContext, useRef, useState } from "react";
import AdminCategory from "./adminCategory/adminCategory";
import NewBenefit from "./newBenefit/newBenefit";
import { BreadCrumbsDinamic } from "../../../commonComponents/BreadCrumbsDinamic";
import IndexBenefit from "./indexBenefit/indexBenefit";
import { Context } from "../../../../context";

const BenefitManager = () => {
    const [newBenefit, setNewBenefit ]= useState(false);
    const [showTable, setShowTable]= useState(true);
    const [isEditBenefit, setIsEditBenefit] = useState(false);
    const [adminCategory, setAdminCategory] = useState(false);
    const [idBenefit, setIdBenefit] = useState(0);
    const [reload, setReload] = useState(false);
    const refLoad = useRef<HTMLButtonElement>(null);
    const refLoadClose = useRef<HTMLButtonElement>(null);
    const refStatus = useRef<HTMLButtonElement>(null);
    const refConfirmAction = useRef<HTMLButtonElement>(null);
    const {pais} = useContext(Context);
    
    return(
        <>
            <main className="wrapper d-flex">
                <div className="w-100 p-0" id="content">
                <BreadCrumbsDinamic/>
                    {showTable &&
                        <IndexBenefit
                            setAdminCategory={setAdminCategory}
                            setNewBenefit={setNewBenefit}
                            setShowTable={setShowTable}
                            setIsEditBenefit={setIsEditBenefit}
                            setIdBenefit={setIdBenefit}
                            idBenefit={idBenefit}
                            reload={reload}
                            setReload={setReload}
                            refConfirmAction={refConfirmAction}
                            refStatus={refStatus}
                            pais={pais}
                            refLoad={refLoad}
                            refLoadClose={refLoadClose}
                        />
                    }

                    {(newBenefit || isEditBenefit) && (
                        <NewBenefit
                            isEditBenefit={isEditBenefit}
                            idBenefit={idBenefit}
                            refLoad={refLoad}
                            refLoadClose={refLoadClose}
                            refConfirmAction={refConfirmAction}
                            refStatus={refStatus}
                            setNewBenefit={setNewBenefit}
                            setShowTable={setShowTable}
                            setIsEditBenefit={setIsEditBenefit}
                            setIdBenefit={setIdBenefit}
                            pais={pais}
                        />
                    )}

                    {adminCategory && 
                        <AdminCategory
                            setAdminCategory={setAdminCategory}
                            setShowTable={setShowTable}
                            refLoad={refLoad}
                            refLoadClose={refLoadClose}
                            refConfirmAction={refConfirmAction}
                            refStatus={refStatus}
                            pais={pais}
                        /> 
                    }
                </div>
            </main>
        </>
    )    
}
export default BenefitManager;