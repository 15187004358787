import {
    ApplicationData,
    RequirementData,
} from "../../interfaces/regionalInfoInterface";
import { IInfoContext } from "./InfoProvider";

type RegionalActionsType =
    | { type: "REGIONAL - Set Application Info"; payload: ApplicationData[] }
    | { type: "REGIONAL - Set Requirement Info"; payload: RequirementData[] }
    | { type: "REGIONAL - Set Video URL"; payload: { title: string, video_url: string } }
    | { type: "REGIONAL - Set Has Error"; payload: boolean };

export const infoReducer = (
    state: IInfoContext,
    action: RegionalActionsType
): IInfoContext => {
    switch (action.type) {
        case "REGIONAL - Set Application Info":
            return {
                ...state,
                applicationInfo: action.payload,
            };

        case "REGIONAL - Set Requirement Info":
            return {
                ...state,
                requirementsInfo: action.payload,
            };

        case "REGIONAL - Set Video URL":
            return {
                ...state,
                talentVideo: action.payload,
            };
        
        case "REGIONAL - Set Has Error":
            return {
                ...state,
                hasErrorInfo: action.payload,
            };
            
        default:
            return state;
    }
};
