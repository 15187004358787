import type { ColumnHelper, ColumnsConfig } from "../../../../components/table";
import { useTableProvider } from "../../context/DashboardTableContext";
import { managmentPayRoll } from "../../context/carouselList.data";
import { Table } from "../../../../components/table";
import { DependenceCell } from "../table/DependenceCell";
import { MyTeamsResponse, TeamApi } from "../../hooks";
import { CountryCell } from "./CountryCell";
import { useEffect, useMemo } from "react";
import { Accordion, AccordionItem } from "../../../../components/accordion";
import { NameCell } from "../table";

export const ManagmentPayRoll = () => {
  const {
    tableFetchInfo,
    flagSelect,
    setDataTableToExport,
    idSubLeader,
    sortingDataTable,
    filterByFlagDataTable,
  } = useTableProvider();

  const dataInfo = tableFetchInfo.find(
    (fetch) => fetch.key === managmentPayRoll.name
  );

  const toRenderList = useMemo(() => {
    if (dataInfo) {
      const { subDatable, dataTable } = dataInfo.response;
      return idSubLeader
      ? (subDatable as MyTeamsResponse)
      : (dataTable as MyTeamsResponse);
    }
    
    return [];
  }, [dataInfo, idSubLeader]);
  
  const columnsConfig: ColumnsConfig[] = [
    { accessor: "name", header: "Nombre", className: "col-2" },
    {
      accessor: "country",
      header: "País",
      className: "text-nowrap",
    },
    {
      accessor: "position",
      header: "Posición",
      className: "text-nowrap col-1",
    },
    { accessor: "cl", header: "Clima Laboral", className: "text-nowrap" },
    {
      accessor: "tr",
      header: "TR",
      className: "text-nowrap",
    },
    {
      accessor: "assessment",
      header: "Evaluación",
      className: "text-nowrap",
    },
    {
      accessor: "cr",
      header: "CR",
      className: "text-nowrap",
    },
    {
      accessor: "salary",
      header: "Renta Bruta Anual",
    },
    {
      accessor: "lastDate",
      header: "Fecha Último Ajuste",
    },
    { accessor: "availableDays", header: "Vacaciones Disponibles" },
    {
      accessor: "dependence",
      header: "Dependencia",
      className: "text-center",
      enabledSort: false,
    },
  ];

  useEffect(() => {
    dataInfo?.response && buildExport();
  }, [dataInfo]);

  useEffect(() => {
    if (flagSelect) filterByFlagDataTable(managmentPayRoll.name);
  }, [flagSelect]);

  const buildDataTable = useMemo(() => {
    return toRenderList.map((item) => ({
      ...item,
      name: (
        <NameCell
          name={item.name}
          photo={item.photo}
          lastName={item.last_name}
          document={item.employee_record_id}
          countryCode={item.country}
        />
      ),
      country: <CountryCell country={item.country} />,
      dependence: (
        <DependenceCell
          document={item.nro_document}
          hasSubordinates={item.hasSubordinates}
        />
      ),
    }));
  }, [dataInfo?.response]);

  const buildExport = () => {
    if (dataInfo) {
      setDataTableToExport({
        header: columnsConfig.map((value) => ({
          label: value.header,
          value: value.accessor,
          hidden: false,
        })),
        rows: toRenderList,
        filename: managmentPayRoll.filenameExport,
      });
    }
  };

  const handleSort = (id: string, desc: boolean) => {
    const sorted = toRenderList.sort((a, b) => {
      const sortID = id as keyof typeof a;
      const aValue = a[sortID] || -1;
      const bValue = b[sortID] || -1;

      if (aValue < bValue) return desc ? 1 : -1;
      if (aValue > bValue) return desc ? -1 : 1;

      return 0;
    });

    sortingDataTable(
      managmentPayRoll.name,
      !!idSubLeader,
      (sorted as MyTeamsResponse) || []
    );
  };

  const buildaccordionList = () => {
    return toRenderList.map((item) => ({
      headerLabel: `${item.name} ${item.last_name}`,
      items: columnsConfig.map((config) => ({
        label: config.header,
        value:
          config.accessor === "name"
            ? `${item.name} ${item.last_name}`
            : item[config.accessor as keyof TeamApi],
      })),
    })) as AccordionItem[];
  };

  return (
    <>
      <Table
        data={buildDataTable as ColumnHelper[]}
        columnsConfig={columnsConfig}
        isLoading={dataInfo?.response.isLoading}
        handleSort={handleSort}
        responsiveClass="d-xs-none"
      />

      <Accordion itemList={buildaccordionList()} className="d-sm-none" />
    </>
  );
};
