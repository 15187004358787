import React from 'react';
import styles from './organigrama-ss.module.css';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { ReactComponent as IcoInternal } from '../../resources/images/ico_interno.svg';
import { ReactComponent as IcoExternal } from '../../resources/images/ico_externo.svg';
import { ReactComponent as IcoVacancy } from '../../resources/images/ico_vacante.svg';
import Tippy from '@tippyjs/react';
import { getCountry } from './get-country';

const PersonCard = ({ person, onInfoClick, onSelectPersonClick }: any) => {
    return (
        <div key={person.id} className={styles.nodecontent}>
            <div className={`col-12 ${styles['node-card-title']}`}>
                <div className={`col-11 text-truncate ${styles['title-name']}`}>{person.name} {person.last_name}</div>
                <Tippy content='Ver Info'>
                    <div className={`col-1`}>
                        <IoMdInformationCircleOutline onClick={() => onInfoClick(person)} className={styles['info-icon']} style={{ cursor: 'pointer' }} />
                    </div>
                </Tippy>
            </div>
            <div className={styles['organigrama-card']} onClick={() => onSelectPersonClick(person)} style={{ cursor: 'pointer' }}>
                <div className={`${styles['organigrama-card-container']}`}>
                    <div className={`col-3 ${styles['content-wrap']}`}>
                        <div className={styles['employee-image']}>
                            {!person?.photo ? <div className={styles['short-name']}>{person?.short_name}</div> : <img className={styles['employee-image']} src={person?.photo} alt='profile-pic' />}
                        </div>
                    </div>
                    <div className={`col-9 ${styles['content-wrap']}`}>
                        <div className={styles['card-text']}>
                            {person?.position ? person.position : "SIN CARGO ASOCIADO"}
                        </div>
                        <div className={styles.area}>
                            {person?.area ? person.area : "SIN AREA ASOCIADO"}
                        </div>
                    </div>
                </div>
                <div className={`${styles['organigrama-card-container-under']}`}>
                    <div className={`col-11`}>
                        <div className={styles['card-reportes']}>
                            Colaboradores
                        </div>
                        <div className={`${styles['report-elements']}`}>
                            <div className={styles['internal-data']}>
                                {person?.internal ? person?.internal : "0"}
                            </div>
                            <Tippy content='Internos'><div className={styles['ico-internal']}>
                                <IcoInternal />
                            </div></Tippy>
                        </div>
                    </div>
                    <div className={`col-1`}>
                        <Tippy content={person?.country}><div className={styles.pais}>{getCountry(person?.country)}</div></Tippy>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PersonCard;
