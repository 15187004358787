import Swal from "sweetalert2";

export const handleSuccess = (certificateSent,licenseSent,file,setLicenseSent,navigate) => {
    if (certificateSent || (licenseSent && !file.File)) {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: green; font-size: 16px">Exitoso</div>',
            html: '<div style="text-align: center; font-size: 15px">Su solicitud fue enviada correctamente</div>',
            confirmButtonColor: '#0169b5',
        }).then((result) => {
            if (result.isConfirmed) {
                setLicenseSent(false);
                navigate("historico");
            }
        })
    }
}