import { Auth } from "aws-amplify";
import axios from "axios";

const url = process.env.REACT_APP_BACKEND_URL_GLOBAL;

// Backend localhost
// const backend_localhost_url_base = "http://localhost:5000";
// const getPendingRequestBoss_URL =
//   backend_localhost_url_base + "/api/vacaciones/pendingrequestsboss";
// const getHistoricalRequestBoss_URL =
//   backend_localhost_url_base + "/api/vacaciones/historicalgrequestsboss";
// const approveOrRejectVacationRequest_URL =
//   backend_localhost_url_base + "/api/vacaciones/approveorrejectvacationrequest";
// const updateVacationRequest_URL =
//   backend_localhost_url_base + "/api/vacaciones/updatevacationrequest";

// Backend beanstalk
// const backend_beanstalk_url_base = "http://autoservicio-bff-col.us-east-1.elasticbeanstalk.com";
// const getPendingRequestBoss_URL = backend_beanstalk_url_base + "/api/vacaciones/pendingrequestsboss";
// const getHistoricalRequestBoss_URL = backend_beanstalk_url_base + "/api/vacaciones/historicalgrequestsboss";
// const approveOrRejectVacationRequest_URL = backend_beanstalk_url_base + "/api/vacaciones/approveorrejectvacationrequest";
// const updateVacationRequest_URL = backend_beanstalk_url_base + "/api/vacaciones/updatevacationrequest";

// Gateway
const backend_gateway_url_base = url;
const getPendingRequestBoss_URL =
  backend_gateway_url_base + "/api/vacaciones/pendingrequestsboss";
const getHistoricalRequestBoss_URL =
  backend_gateway_url_base + "/api/vacaciones/historicalgrequestsboss";
const approveOrRejectVacationRequest_URL =
  backend_gateway_url_base + "/api/vacaciones/approveorrejectvacationrequest";
const updateVacationRequest_URL =
  backend_gateway_url_base + "/api/vacaciones/updatevacationrequest";

const getHeader = () => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(async (auth) => {
        const header = {
          "Content-Type": "application/json",
          accesstoken: auth.getAccessToken().getJwtToken(),
        };
        resolve(header);
      })
      .catch(function (error) {
        return reject(error);
      });
  });
};

const VacationServices = {
  getPendingRequestBoss: async () => {
    try {
      const header = await getHeader();

      const data = await axios.get(getPendingRequestBoss_URL, {
        headers: header,
      });

      return data;
    } catch (error) {
      error?.response?.data;
    }
  },
  getHistoricalRequestBoss: async () => {
    try {
      const header = await getHeader();

      const data = await axios.get(getHistoricalRequestBoss_URL, {
        headers: header,
      });

      return data;
    } catch (error) {
      error?.response?.data;
    }
  },
  approveOrRejectVacationRequest: async (dto) => {
    try {
      const header = await getHeader();

      const data = await axios({
        method: "put",
        url: approveOrRejectVacationRequest_URL,
        data: dto,
        headers: {
          "Content-Type": "application/json",
          accesstoken: header.accesstoken,
        },
      });

      return data;
    } catch (error) {
      return error?.response?.data;
    }
  },
  updateVacationRequest: async (dto) => {
    try {
      const data = await axios({
        method: "post",
        url: updateVacationRequest_URL,
        data: dto,
        headers: {
          "Content-Type": "application/json",
        },
      });

      return data;
    } catch (error) {
      return error?.response?.data;
    }
  },
};

export default VacationServices;
