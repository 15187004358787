import React from "react";
import { useBarcode } from "react-barcodes";


export const Barcode = (props) => {

    const {inputRef}  = useBarcode({ value: props.data , options: { background: '#fff'}});
       
        return (
          <div>
            <canvas className='discount-content bc' ref={inputRef}></canvas>
          </div>
        )

}
