import React from 'react';
import { Link } from 'react-router-dom';
import InsuranceImg from '../../resources/images/imagen-seguros.png';

const Seguros = () => {
    return (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home color-orange" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                        Mis Beneficios
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Seguros
                    </li>
                </ol>
            </nav>
            <section className="noticias mb-3">
                <h1>Seguros</h1>
                <div className="col-12 position-relative mb-0">
                    {/* <a className="btn btn-degradado btn-absolute" href="#">Ver más <i className="fa fa-chevron-right" aria-hidden="true"></i></a> */}
                </div>
            </section>
            <div className="container">
                <div className='img-wrapper-main'>
                    <img className='insurance-img-main' alt='cencosud-insurance' src={InsuranceImg} onClick={() => {
                        window.open('https://tarjetacencosud.com.ar/?page_id=4742', '_blank')
                    }}></img>
                </div>
            </div>
        </>
    )
}

export default Seguros;