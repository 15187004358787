import React from 'react';
import CambioAsistenciaResumen from './cambio-asistencia-resumen';
import { Modal } from "react-bootstrap";
import { PropTypes } from 'prop-types';

const ModalResumen = (props) => {
ModalResumen.propTypes = {
        show: PropTypes.any,
        setShow:PropTypes.any
}
const show = props.show;
const setShow = props.setShow;

  return (
    <React.Fragment>
         {/*MODAL RESUMEN*/}
    <Modal show={show.active} data={show.data} centered dialogClassName="modal-width">
    {/* {console.log(show)} */}
    <div >
        <div className="d-flex text-left">
            <h2 className="title-modal m-3 text-center">Detalle</h2>
        </div>
        <div className="container-modal upload-content m-3">
           <CambioAsistenciaResumen tipo={show.data?.type} cambioTurno={show.data}></CambioAsistenciaResumen>
           </div>
    </div>
    <div className="d-flex justify-content-center mt-3 mb-2">
                <button onClick={() => setShow({ active: false, data: null })}
                    className="button history send-certify">
                    Cerrar
                </button>
            </div>
</Modal>
{/*MODAL RESUMEN */}
    </React.Fragment>
  )
}

export default ModalResumen;