import { JobRequisitionResponse } from "../interfaces/applyInterface";

export const cacheHelpers = () => {
    const saveApplyListInSessionStorage = (
        applyList: JobRequisitionResponse | undefined
    ): void => {
        sessionStorage.removeItem("applyList");
        if (!applyList) return;
        sessionStorage.setItem("applyList", JSON.stringify(applyList));
    };

    const getApplyListFromSessionStorage = (): JobRequisitionResponse => {
        const cacheApplyList = sessionStorage.getItem("applyList") ?? "{}";
        const applyList: JobRequisitionResponse = JSON.parse(cacheApplyList);

        return applyList;
    };

    return {
        saveApplyListInSessionStorage,
        getApplyListFromSessionStorage,
    };
};
