import React, { FunctionComponent, useState, useEffect } from "react";
import { GetSheet, Options, PostValues, Uniforms } from "../../utilities/interfaces";
import StepZero from "../step0";
import FirstStep from "../step1";
import SecondStep from "../step2";
import ThirdStep from "../step3";
import FourthStep from "../step4";
import FifthStep from "../step5";
import SixthStep from "../step6";
import SeventhStep from "../step7";
import EigthStep from "../step8";
import NinthStep from "../step9";
import TenthStep from "../step10";

interface Props {
    stepState: [number, React.Dispatch<React.SetStateAction<number>>],
    getSheet: GetSheet,
}

const MainPage: FunctionComponent<Props> = ({ stepState, getSheet }) => {
    const [step, setStep] = stepState;
    const retired = getSheet.cn_jubilado;
    const practice = getSheet.practice;
    // first step
    const [personalData, setPersonalData] = useState(getSheet.sheet.firstStep.personalData);
    const [contactData, setContactData] = useState(getSheet.sheet.firstStep.contactData);
    // second step
    const [addressData, setAddressData] = useState(getSheet.sheet.secondStep.addressData);
    // third step
    const [highschoolData, setHighschoolData] = useState(getSheet.sheet.thirdStep.highschoolData);
    const [collegeData, setCollegeData] = useState(getSheet.sheet.thirdStep.collegeData);
    // fourth step
    const [pensionData, setPensionData] = useState(getSheet.sheet.fourthStep.pensionData);
    const [documentData, setDocumentData] = useState(getSheet.sheet.fourthStep.documentData);
    // fifth step
    const [credentialImageData, setCredentialImageData] = useState(getSheet.sheet.fifthStep.credentialImageData);
    // sixth step
    const [accountData, setAccountData] = useState(getSheet.sheet.sixthStep.accountData);
    // seventh step
    const [requireUniform, setRequireUniform] = useState(getSheet.sheet.seventhStep.show);
    // eigth step
    const [contractData, setContractData] = useState(getSheet.sheet.eigthStep.contractData);
    // ninth step
    const [rulesData, setRulesData] = useState(getSheet.sheet.ninthStep.rulesData);
    const [ethicsData, setEthicsData] = useState(getSheet.sheet.ninthStep.ethicsData);
    const [conflictData, setConflictData] = useState(getSheet.sheet.ninthStep.conflictData);
    const [policyData, setPolicyData] = useState(getSheet.sheet.ninthStep.policyData);
    // tenth step
    const [token, setToken] = useState("");

    const [photoData, setPhotoData] = useState("");
    const [uniformValues, setuniformValues] = useState<Map<string, string> | null>(null);
    const [uniformData, setUniformData] = useState<{[K: string]: string} | null>(null);
    const contractDate = getSheet.effdt;

    const [postValues, setPostValues] = useState<PostValues>({
        ...personalData,
        phone: contactData.phone,
        ...addressData,
        ...highschoolData,
        ...collegeData,
        ...pensionData,
        ...documentData,
        ...contractData,
        ...rulesData,
        ...ethicsData,
        ...policyData,
        ...conflictData,
        estadoenvio: "0",
    } as PostValues);

    /* al back */
    const sexOptions = [
        {
            key: "F",
            value: "F",
            label: "Femenino",
        },
        {
            key: "M",
            value: "M",
            label: "Masculino",
        },
    ];
    const civilOptions = [
        {
            key: "M",
            value: "M",
            label: "Casado",
        },
        {
            key: "C",
            value: "C",
            label: "Conv./ Unión Libre",
        },
        {
            key: "D",
            value: "D",
            label: "Divorciado",
        },
        {
            key: "E",
            value: "E",
            label: "Separado",
        },
        {
            key: "S",
            value: "S",
            label: "Soltero",
        },
        {
            key: "W",
            value: "W",
            label: "Viudo",
        },
    ];
    const visaOptions = [
        {
            key: "03",
            value: "03",
            label: "Temporaria",
        },
        {
            key: "06",
            value: "06",
            label: "Permanencia Definitiva",
        },
    ];

    /* al back */
    const isGraduatedOptions = [
        {
            key: "N",
            value: "N",
            label: "No",
        },
        {
            key: "Y",
            value: "Y",
            label: "Si",
        },
    ];

    const accountTypeOptions = [
        {
            key: "A",
            value: "A",
            label: "Cuenta Vista",
        },
        {
            key: "B",
            value: "B",
            label: "Cuenta Rut",
        },
        {
            key: "C",
            value: "C",
            label: "Cuenta Corriente",
        },
    ];

    const uniformList = getSheet.uniformList;

    useEffect(() => {
        if (requireUniform && uniformList.length > 0) {
            const uniformsAsOption = uniformList.map((o) => {
                return [o[0].value, ""]
            });
            const values = new Map(uniformsAsOption as any);
            setuniformValues(values as Map<string, string>);
        }
    }, [requireUniform, uniformList]);

    useEffect(() => {
        if (uniformValues !== null) {
            setUniformData(Object.fromEntries(uniformValues));
        }
    }, [uniformValues]);

    const options: Map<string, Array<Options>> = new Map();
    options.set("sexOptions", sexOptions);
    options.set("civilOptions", civilOptions);
    options.set("countryOptions", getSheet.countries);
    options.set("visaOptions", visaOptions);
    options.set("regionsOptions", getSheet.regions);
    options.set("citiesOptions", getSheet.cities);
    options.set("isGraduatedOptions", isGraduatedOptions);
    options.set("afpOptions", getSheet.pensionFund);
    options.set("isapresOptions", getSheet.healthInsurance);
    options.set("bankOptions", getSheet.bankList);
    options.set("accountTypeOptions", accountTypeOptions);
    options.set("conflictsOptions", getSheet.conflicts);
    options.set("relationshipOptions", getSheet.relationships);

    const nextStep = () => {
        step > 0 && document.getElementById("closeSendingInfo")?.click();
        const tempStep = step + 1;
        if (tempStep === 7 && requireUniform) setStep(step + 1);
        else if (tempStep === 7 && !requireUniform) setStep(step + 2);
        else if (tempStep === 8 && requireUniform) setStep(step + 1);
        else if (tempStep === 8 && !requireUniform) setStep(step + 2);
        else setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    switch (step) {
        case 0:
            return <StepZero nextStep={nextStep} firstname={personalData.firstname} />;
        case 1:
            return <FirstStep
                        nextStep={nextStep}
                        options={options}
                        personalData={personalData}
                        setPersonalData={setPersonalData}
                        contactData={contactData}
                        setContactData={setContactData}
                        documentData={documentData}
                        setDocumentData={setDocumentData}
                        postValues={postValues}
                        setPostValues={setPostValues}
                        practice={practice}
                    />;
        case 2:
            return <SecondStep
                        nextStep={nextStep}
                        prevStep={prevStep}
                        addressData={addressData}
                        setAddressData={setAddressData}
                        options={options}
                        postValues={postValues}
                        setPostValues={setPostValues}
                    />
        case 3:
            return <ThirdStep
                        nextStep={nextStep}
                        prevStep={prevStep}
                        highschoolData={highschoolData}
                        setHighschoolData={setHighschoolData}
                        collegeData={collegeData}
                        setCollegeData={setCollegeData}
                        options={options}
                        postValues={postValues}
                        setPostValues={setPostValues}
                    />
        case 4:
            return <FourthStep
                        nextStep={nextStep}
                        prevStep={prevStep}
                        documentData={documentData}
                        setDocumentData={setDocumentData}
                        pensionData={pensionData}
                        setPensionData={setPensionData}
                        options={options}
                        postValues={postValues}
                        setPostValues={setPostValues}
                        contractDate={contractDate}
                        retired={retired}
                    />
        case 5:
            return <FifthStep
                        nextStep={nextStep}
                        prevStep={prevStep}
                        photoData={photoData}
                        setPhotoData={setPhotoData}
                        options={options}
                    />
        case 6:
            return <SixthStep
                        nextStep={nextStep}
                        prevStep={prevStep}
                        accountData={accountData}
                        setAccountData={setAccountData}
                        emplid={getSheet.sheet.firstStep.personalData.national_id}
                        options={options}
                    />
        case 7:
            return <SeventhStep
                        nextStep={nextStep}
                        prevStep={prevStep}
                        uniformList={uniformList}
                        uniformData={uniformData}
                        setUniformData={setUniformData}
                        options={options}
                    />
        case 8:
            return <EigthStep
                        nextStep={nextStep}
                        prevStep={prevStep}
                        contractData={contractData}
                        setContractData={setContractData}
                        options={options}
                        postValues={postValues}
                        setPostValues={setPostValues}
                        company={getSheet.company}
                    />
        case 9:
            return <NinthStep
                        nextStep={nextStep}
                        prevStep={prevStep}
                        company={getSheet.company}
                        ethicsData={ethicsData}
                        photoData={photoData}
                        rulesData={rulesData}
                        policyData={policyData}
                        accountData={accountData}
                        conflictData={conflictData}
                        uniformData={uniformData}
                        options={options}
                        postValues={postValues}
                        setPostValues={setPostValues}
                        setToken={setToken}
                        token={token}
            />
        case 10:
            return <TenthStep prevStep={prevStep} token={token} />;
        default:
            return <></>;
    }
};
export default MainPage;
