import moment from "moment";
import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import AprobacionLicenciasServices from "../../services/aprobacion licencias/aprobacion-licencias-service";
import Accordion from 'react-bootstrap/Accordion';



const TablaAprobacionLicencias = (props) => {
  // eslint-disable-next-line react/prop-types
  let info = props.info;
  const [openFile, setOpenFile] = useState({ active: false, data: null, type: null, title: null });
  const [licences, setlicences] = useState([])
  const [order, setOrder] = useState("DES")
  const [nameOrder, setNameOrder] = useState("DES")
  const [loadingIndices, setLoadingIndices] = useState(new Set());

  const isPendiente = (licencias) => {
    return licencias && licencias.status === 'P';
  }

  const getFile = (id, index) => {
    setLoadingIndices((prev) => new Set([...prev, index]));
    AprobacionLicenciasServices.getLicenceCertificate(id, setOpenFile, setLoadingIndices, index)
  }
  useEffect(() => {
    if (nameOrder === "ASC") {
      info = info.sort((a, b) => {
        if (a.userName.toLowerCase() > b.userName.toLowerCase()) return 1
        if (b.userName.toLowerCase() > a.userName.toLowerCase()) return -1
        return 0
      })
    } else {
      info = info.sort((a, b) => {
        if (b.userName.toLowerCase() > a.userName.toLowerCase()) return 1
        if (a.userName.toLowerCase() > b.userName.toLowerCase()) return -1
        return 0
      })
    }
  }, [nameOrder])

  const sortByDate = () => {
    if (order === "ASC") {
      info = props.info.sort((a, b) => moment(b.beginDate, "AAAA-MM-DD").unix() - moment(a.beginDate, "AAAA-MM-DD").unix())
      setOrder("DES")
    } else {
      info = props.info.sort((a, b) => moment(a.beginDate, "AAAA-MM-DD").unix() - moment(b.beginDate, "AAAA-MM-DD").unix())
      setOrder("ASC")
    }
  };


  useEffect(() => {
    props.allowAction(licences.length === 0)
  }, [licences])

  const onChangeInput = (e) => {
    if (e.target.checked) {
      setlicences([...licences, parseInt(e.target.value)]);
      props.action([...licences, parseInt(e.target.value)])
    } else {
      setlicences(licences.filter(lic => lic !== parseInt(e.target.value)))
      props.action(licences.filter(lic => lic !== parseInt(e.target.value)))
    }
  };

  const selectAll = (e) => {
    if (e.target.checked) {
      setlicences(info.filter(lic => isPendiente(lic)).map(lic => lic.id))
      props.action(licences);
    } else {
      setlicences([])
      props.action([])
    }
  }

  return (
    <section className="aprobacion-de-licencias">

      <div className="card-box mb-4">
        <h3>Detalle <div className='title-underlined'></div></h3>
        {/* Escritorio (Tabla) */}
        <div className="table-responsive license-approval-table">
          <table className="table table-even table-section table-borderless d-md-table">
            <thead>
              <tr>
                <th className="cap text-nowrap">
                  <input className="form-check-input me-2 h5" type="checkbox" value="" id="check-1" onChange={e => selectAll(e)}></input>
                </th>
                <th className="cap text-nowrap"> Nombre <Tippy content="Orden alfabético"><a onClick={() => nameOrder === "ASC" ? setNameOrder("DES") : setNameOrder("ASC")}><i className="fa fa-filter fa-fw color-orange" aria-hidden="true"></i></a></Tippy></th>
                <th className="cap text-nowrap">
                  Fecha Inicio
                  <Tippy content="Orden por periodo"><a onClick={sortByDate}>
                    {order === "DES" ? <i className="fa fa-long-arrow-down fa-fw color-orange" aria-hidden="true"></i>
                      : <i className="fa fa-long-arrow-up fa-fw color-orange" aria-hidden="true"></i>}
                  </a></Tippy>
                </th>
                <th className="cap text-nowrap">Fecha Final</th>
                <th className="cap">Tipo</th>
                <th className="cap">Detalle</th>
                <th className="cap">Estado</th>
                <th className="cap">Certificado</th>
              </tr>
            </thead>
            <tbody>
              {info.length > 0 ?
                info.map((employee, index) => {
                  return (
                    <tr key={employee.id}>
                      <td>
                        {/* <input className="form-check-input me-2 h5" type="checkbox" id={employee.id} onChange={(e) => onChangeInput(e)} checked={licences.includes(employee.id)} disabled={!isPendiente(employee)} value={employee.id}></input> */}
                        <input disabled={employee.status === "R" || employee.status === "A"} className="form-check-input me-2 h5" type="checkbox" id={employee.id} onChange={(e) => onChangeInput(e)} checked={licences.includes(employee.id)} value={employee.id}></input>
                      </td>
                      <td>{employee.userName}</td>
                      <td>{moment(employee.beginDate).format("DD-MM-YYYY")}</td>
                      <td>{moment(employee.returnDate).format("DD-MM-YYYY")}</td>
                      <td>{employee.licenceDescription}</td>
                      <td className="text-truncate">{employee.comments}</td>
                      <td className="text-uppercase fw-bold">
                        <div className={`${employee.status === 'A' ? "color-orange" : employee.status === 'P' ? "" : "color-primary"}`}>{employee.status === 'A' ? "Aprobada" : employee.status === 'P' ? "Pendiente" : "Rechazada"}</div>
                      </td>
                      <td>
                        {employee.hasCertificate ?
                          <div className="dropdown">
                            <Tippy content="Ver archivo"><div className="certificado-align" onClick={() => getFile(employee.id, index)}>{loadingIndices.has(index) ? <div className="spinner-border spinner-border-sm"></div> : <i className="color-orange fa fa-eye fa-fw fa-lg"></i>}</div></Tippy>
                          </div> : null}
                      </td>
                    </tr>
                  );
                }) : <tr><td colSpan={7}><div>No se encontraron ausencias</div></td></tr>}
            </tbody>
          </table>
        </div>
        {/*responsive-table */}
        {info.length > 0 ?
          <Accordion className="accordion accordion-flush accordion-light accordion-table d-md-none" defaultActiveKey="0">
            {info.map((employee, index) => {
              return (
                <Accordion.Item key={index} eventKey={employee.id}>
                  <Accordion.Header className="accordion-header" id="accordion">
                    <strong>{employee.userName}</strong></Accordion.Header>
                  <Accordion.Body className="accordion-body p-0">
                    <div className="data-list-box data-list-box-even">
                      <ul className="data-list-section--item list-unstyled">
                        <li>
                          <input disabled={employee.status === "R" || employee.status === "A"} className="form-check-input me-2 h5" type="checkbox" id={employee.id} onChange={(e) => onChangeInput(e)} checked={licences.includes(employee.id)} value={employee.id}></input>
                        </li>
                        <li>
                          <span className="data-list-box__title">Fecha Inicio</span>
                          <span className="data-list-box__text">{moment(employee.beginDate).format("DD-MM-YYYY")}</span>
                        </li>
                        <li>
                          <span className="data-list-box__title">Fecha Final</span>
                          <span className="data-list-box__text">{moment(employee.returnDate).format("DD-MM-YYYY")}</span>
                        </li>
                        <li>
                          <span className="data-list-box__title">Tipo</span>
                          <span className="data-list-box__text">{employee.licenceDescription}</span>
                        </li>
                        <li>
                          <span className="data-list-box__title">Detalle</span>
                          <span className="data-list-box__text text-truncate">{employee.comments}</span>
                        </li>
                        <li>
                          <span className="data-list-box__title">Estado</span>
                          <span className="data-list-box__text text-uppercase fw-bold">
                            <span className={`${employee.status === 'A' ? "color-orange" : employee.status === 'P' ? "" : "color-primary"}`}>{employee.status === 'A' ? "Aprobada" : employee.status === 'P' ? "Pendiente" : "Rechazada"}</span>
                          </span>
                        </li>
                        <li>
                          <span className="data-list-box__title">Certificado</span>
                          <span className="data-list-box__text">
                            {employee.hasCertificate ?
                              <div className="dropdown">
                                <div className="certificado-align" onClick={() => getFile(employee.id, index)}>{loadingIndices.has(index) ? <div className="spinner-border spinner-border-sm color-primary"></div> : <i className="color-orange fa fa-eye fa-fw fa-lg"></i>}</div>
                              </div> : <div><i>No se encontraron archivos</i></div>}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )
            })}
          </Accordion> : null}
        {/*responsive-table */}

        <Modal show={openFile.active} data={openFile.data}>
          <Modal.Header closeButton onClick={() => { setOpenFile({ active: false, data: null, type: null, title: null }) }}>
            <h2 className="title-modal m-3 text-center">Certificado</h2>
          </Modal.Header>
          <div className="file-container-if">
            {openFile.type === 'application/pdf' ? (
              <object data={openFile.data} type="application/pdf" style={{ width: "100%", height: "600px" }}>
                <p style={{ marginBottom: "0", textAlign: 'center', padding: '10px' }}>
                  El navegador no puede previsualizar el pdf. <br />
                  <a href={openFile.data} download={openFile.title}>
                    Descargar archivo
                  </a>
                </p>
              </object>) : <img className="img-fluid" src={openFile.data} style={{ width: "100%", height: "600px" }}></img>}

          </div>
        </Modal>
      </div>




    </section>
  );
}
export default TablaAprobacionLicencias;
