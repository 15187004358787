import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import React, { Children, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { urlsAws } from "../../resources/foo/api-endpoints";
import fotoHombre from "../../resources/images/default-colaborador-image.png";
import miEquipoService from "../../services/mi-equipo-service";
import { decrypt, formatDate } from "../../services/utiles";
import vacacionesService from "../../services/vacaciones-service";
import CarrouselVacaciones from "../elements/vacaciones-carrousel";

const AprobadasCel = ({ rows, setExistData, aceptarSolicitud, output }) => {
  if (rows.length === 0 || rows[0] === false) {
    setExistData(false);
    return <h5>No se ha encontrado información</h5>;
  } else {
    const tamanoArreglo =
      rows.filter((row) => row.estadoSolicitud === "1").length === 0;
    if (tamanoArreglo) {
      setExistData(false);
      return <h5>No se ha encontrado información</h5>;
    } else {
      setExistData(true);
      return Children.toArray(
        rows.map((row, index) => {
          if (row.estadoSolicitud === "1") {
            return (
              <div className="accordion-item">
                <h2 className="accordion-header" id="accordion">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={
                      "#accordion-item-solicitudes-" + index + 1
                    }
                    aria-expanded="true"
                    aria-controls={"accordion-item-solicitudes-" + index + 1}
                  >
                    <strong className="text-uppercase">Nombre</strong>{" "}
                    {row.name}
                  </button>
                </h2>
                <div
                  id={"accordion-item-solicitudes-" + index + 1}
                  className="accordion-collapse collapse show"
                  aria-labelledby="accordion"
                >
                  <div className="accordion-body p-0">
                    <div className="data-list-box data-list-box-even ">
                      <ul className="data-list-section--item list-unstyled">
                        <li>
                          <span className="data-list-box__title text-uppercase">
                            RUT
                          </span>
                          <span className="data-list-box__text">
                            {row.emplid}
                          </span>
                        </li>
                        <li>
                          <span className="data-list-box__title text-uppercase">
                            Cargo
                          </span>
                          <span className="data-list-box__text">
                            {row.jobCodeDescr}
                          </span>
                        </li>
                        <li>
                          <span className="data-list-box__title text-uppercase">
                            Fecha inicial
                          </span>
                          <span className="data-list-box__text">
                            {row.code}
                          </span>
                        </li>
                        <li>
                          <span className="data-list-box__title text-uppercase">
                            Fecha término
                          </span>
                          <span className="data-list-box__text">
                            {row.population}
                          </span>
                        </li>
                        <li>
                          <span className="data-list-box__title text-uppercase">
                            Duración
                          </span>
                          <span className="data-list-box__text">
                            {row.duracion_dias}
                          </span>
                        </li>
                        <li>
                          <span className="data-list-box__title text-uppercase">
                            Nuevo saldo
                          </span>
                          <span className="data-list-box__text">
                            {row.size}
                          </span>
                        </li>
                      </ul>
                      <div className="row my-4">
                        <div className="d-grid gap-3">
                          <div className="text-center mt-5">
                            {new Date(formatDate(row.code)) <= new Date(output) ? (
                              <></>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-degradado px-5"
                                onClick={(event) =>
                                  aceptarSolicitud(
                                    event,
                                    row.name,
                                    row.aprobar
                                  )
                                }
                                data-bs-toggle="modal"
                                data-bs-target="#anular"
                              >
                                Anular{" "}
                                <i
                                  className="fa fa-chevron-right fa-fw fa-xs"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })
      );
    }
  }
};

const PendienteCel = ({ rows, setExistData, aceptarSolicitud, rechazarSolicitud }) => {
  if (rows.length === 0 || rows[0] === false) {
    setExistData(false);
    return <h5>No se ha encontrado información</h5>;
  } else {
    const tamanoArreglo =
      rows.filter((row) => row.estadoSolicitud === "0").length === 0;
    if (tamanoArreglo) {
      setExistData(false);
      return <h5>No se ha encontrado información</h5>;
    } else {
      setExistData(true);
      return Children.toArray(
        rows.map((row, index) => {
          if (row.estadoSolicitud === "0") {
            return (
              <div className="accordion-item">
                <h2 className="accordion-header" id="accordion">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={
                      "#accordion-item-solicitudes-" + index + 1
                    }
                    aria-expanded="true"
                    aria-controls={"accordion-item-solicitudes-" + index + 1}
                  >
                    <strong className="text-uppercase">Nombre</strong>{" "}
                    {row.name}
                  </button>
                </h2>
                <div
                  id={"accordion-item-solicitudes-" + index + 1}
                  className="accordion-collapse collapse show"
                  aria-labelledby="accordion"
                >
                  <div className="accordion-body p-0">
                    <div className="data-list-box data-list-box-even ">
                      <ul className="data-list-section--item list-unstyled">
                        <li>
                          <span className="data-list-box__title text-uppercase">
                            RUT
                          </span>
                          <span className="data-list-box__text">
                            {row.emplid}
                          </span>
                        </li>
                        <li>
                          <span className="data-list-box__title text-uppercase">
                            Cargo
                          </span>
                          <span className="data-list-box__text">
                            {row.jobCodeDescr}
                          </span>
                        </li>
                        <li>
                          <span className="data-list-box__title text-uppercase">
                            Fecha inicial
                          </span>
                          <span className="data-list-box__text">
                            {row.code}
                          </span>
                        </li>
                        <li>
                          <span className="data-list-box__title text-uppercase">
                            Fecha término
                          </span>
                          <span className="data-list-box__text">
                            {row.population}
                          </span>
                        </li>
                        <li>
                          <span className="data-list-box__title text-uppercase">
                            Duración
                          </span>
                          <span className="data-list-box__text">
                            {row.duracion_dias}
                          </span>
                        </li>
                        <li>
                          <span className="data-list-box__title text-uppercase">
                            Nuevo saldo
                          </span>
                          <span className="data-list-box__text">
                            {row.size}
                          </span>
                        </li>
                      </ul>
                      <div className="row my-4">
                        <div className="d-grid gap-3">
                          <button
                            type="button"
                            onClick={(event) =>
                              aceptarSolicitud(event, row.name, row.aprobar)
                            }
                            data-bs-toggle="modal"
                            data-bs-target="#aprobar"
                            className="btn btn-degradado"
                          >
                            Aprobar{" "}
                            <i
                              className="fa fa-chevron-right fa-fw fa-xs"
                              aria-hidden="true"
                            ></i>
                          </button>

                          <button
                            type="button"
                            onClick={(event) =>
                              rechazarSolicitud(event, row.name, row.aprobar)
                            }
                            className="btn btn-primary"
                          >
                            Rechazar{" "}
                            <i
                              className="fa fa-chevron-right fa-fw fa-xs"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })
      );
    }
  }
};

const Aprobadas = ({ rows, setExistData, currentPhotosUsuarios, pixeles, consultarColaborador }) => {
  if (rows.length === 0 || rows[0] === false) {
    setExistData(false);
    return <h5>No se ha encontrado información</h5>;
  } else {
    const tamanoArreglo =
      rows.filter((row) => row.estadoSolicitud === "1").length === 0;

    if (tamanoArreglo) {
      setExistData(false);
      return <h5>No se ha encontrado información</h5>;
    } else {
      setExistData(true);
      return Children.toArray(
        rows.map((row, index) => {
          if (row.estadoSolicitud === "1") {
            let photoUsuario = "";
            if (currentPhotosUsuarios.length > 0) {
              let valorPhoto = currentPhotosUsuarios.filter(
                (value) => value.emplid === row.emplid
              );
              if(valorPhoto.length > 0){
                photoUsuario = valorPhoto[0].photo
              }
            }
            if (pixeles === "aprobadas-" + index) {
              return (
                <div
                  style={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "#0169B5",
                    borderRadius: "10px",
                    marginRight: "3%",
                  }}
                  id={"aprobadas-" + index}
                  onClick={(event) =>
                    consultarColaborador(event, row.aprobar, "aprobadas-" + index)
                  }
                >
                  <a className="text-decoration-none link-list d-block p-3 ">
                    <div className="row">
                      <div className="col-2">
                        {photoUsuario !== "" && photoUsuario !== null && photoUsuario !== undefined ? (
                          <img
                            src={`data:image/png;base64, ${photoUsuario}`}
                            alt=""
                            className="rounded-circle img-fluid"
                            width="160"
                            height="160"
                          />
                        ) : (
                          <img
                            src={fotoHombre}
                            alt=""
                            className="rounded-circle img-fluid"
                            width="160"
                            height="160"
                          />
                        )}
                      </div>
                      <div className="col-9">
                        <div className="data-list-box ">
                          <ul className="list-unstyled">
                            <li>
                              <span className="data-list-box__title col-6 text-uppercase">
                                Nombre
                              </span>
                              <span className="data-list-box__text">
                                {row.name}
                              </span>
                            </li>
                            <li>
                              <span className="data-list-box__title col-6 text-uppercase">
                                RUT
                              </span>
                              <span className="data-list-box__text">
                                {row.emplid}
                              </span>
                            </li>
                            <li>
                              <span className="data-list-box__title col-6 text-uppercase">
                                Cargo
                              </span>
                              <span className="data-list-box__text">
                                {row.jobCodeDescr}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-1 d-flex align-items-center">
                        <i
                          className="fa fa-chevron-right color-primary fa-lg"
                          id={"aprobadass-" + index}
                          aria-hidden="true"
                          onClick={(event) =>
                            consultarColaborador(event, row.aprobar, "aprobadass-" + index)
                          }
                        ></i>
                      </div>
                    </div>
                  </a>
                </div>
              );
            } else {
              return (
                <div
                  style={{
                    borderWidth: "0px",
                    borderStyle: "solid",
                    borderColor: "#0169B5",
                    borderRadius: "10px",
                    marginRight: "3%",
                  }}
                  id={"aprobadas-" + index}
                  onClick={(event) =>
                    consultarColaborador(event, row.aprobar, "aprobadas-" + index)
                  }
                >
                  <a className="text-decoration-none link-list d-block p-3 ">
                    <div className="row">
                      <div className="col-2">
                        {photoUsuario !== "" && photoUsuario !== null && photoUsuario !== undefined ? (
                          <img
                            src={`data:image/png;base64, ${photoUsuario}`}
                            alt=""
                            className="rounded-circle img-fluid"
                            width="160"
                            height="160"
                          />
                        ) : (
                          <img
                            src={fotoHombre}
                            alt=""
                            className="rounded-circle img-fluid"
                            width="160"
                            height="160"
                          />
                        )}
                      </div>
                      <div className="col-9">
                        <div className="data-list-box ">
                          <ul className="list-unstyled">
                            <li>
                              <span className="data-list-box__title col-6 text-uppercase">
                                Nombre
                              </span>
                              <span className="data-list-box__text">
                                {row.name}
                              </span>
                            </li>
                            <li>
                              <span className="data-list-box__title col-6 text-uppercase">
                                RUT
                              </span>
                              <span className="data-list-box__text">
                                {row.emplid}
                              </span>
                            </li>
                            <li>
                              <span className="data-list-box__title col-6 text-uppercase">
                                Cargo
                              </span>
                              <span className="data-list-box__text">
                                {row.jobCodeDescr}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-1 d-flex align-items-center">
                        <i
                          className="fa fa-chevron-right color-primary fa-lg"
                          id={"aprobadass-" + index}
                          aria-hidden="true"
                          onClick={(event) =>
                            consultarColaborador(event, row.aprobar, "aprobadass-" + index)
                          }
                        ></i>
                      </div>
                    </div>
                  </a>
                </div>
              );
            }
          }
        })
      );
    }
  }
};

const Solicitadas = ({ rows, setExistData, currentPhotosUsuarios, pixeles, consultarColaborador }) => {
  if (rows.length === 0 || rows[0] === false) {
    setExistData(false);
    return <h5>No se ha encontrado información</h5>;
  } else {
    const tamanoArreglo =
      rows.filter((row) => row.estadoSolicitud === "0").length === 0;

    if (tamanoArreglo) {
      setExistData(false);
      return <h5>No se ha encontrado información</h5>;
    } else {
      setExistData(true);
      return Children.toArray(
        rows.map((row, index) => {
          if (row.estadoSolicitud === "0") {
            let photoUsuario = "";
            if (currentPhotosUsuarios.length > 0) {
              let valorPhoto = currentPhotosUsuarios.filter(
                (value) => value.emplid === row.emplid
              );
              if(valorPhoto.length > 0){
                photoUsuario = valorPhoto[0].photo
              }
            }
            if (pixeles === "pendientes-" + index) {
              return (
                <div
                  style={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "#0169B5",
                    borderRadius: "10px",
                    marginRight: "3%",
                  }}
                  id={"pendientes-" + index}
                  onClick={(event) =>
                    consultarColaborador(event, row.aprobar, "pendientes-" + index)
                  }
                >
                  <a className="text-decoration-none link-list d-block p-3 ">
                    <div className="row">
                      <div className="col-2">
                        {photoUsuario !== "" && photoUsuario !== null && photoUsuario !== undefined ? (
                          <img
                            src={`data:image/png;base64, ${photoUsuario}`}
                            alt=""
                            className="rounded-circle img-fluid"
                            width="160"
                            height="160"
                          />
                        ) : (
                          <img
                            src={fotoHombre}
                            alt=""
                            className="rounded-circle img-fluid"
                            width="160"
                            height="160"
                          />
                        )}
                      </div>
                      <div className="col-9">
                        <div className="data-list-box ">
                          <ul className="list-unstyled">
                            <li>
                              <span className="data-list-box__title col-6 text-uppercase">
                                Nombre
                              </span>
                              <span className="data-list-box__text">
                                {row.name}
                              </span>
                            </li>
                            <li>
                              <span className="data-list-box__title col-6 text-uppercase">
                                RUT
                              </span>
                              <span className="data-list-box__text">
                                {row.emplid}
                              </span>
                            </li>
                            <li>
                              <span className="data-list-box__title col-6 text-uppercase">
                                Cargo
                              </span>
                              <span className="data-list-box__text">
                                {row.jobCodeDescr}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-1 d-flex align-items-center">
                        <i
                          className="fa fa-chevron-right color-primary fa-lg"
                          id={"pendientess-" + index}
                          aria-hidden="true"
                          onClick={(event) =>
                            consultarColaborador(event, row.aprobar, "pendientess-" + index)
                          }
                        ></i>
                      </div>
                    </div>
                  </a>
                </div>
              );
            } else {
              return (
                <div
                  style={{
                    borderWidth: "0px",
                    borderStyle: "solid",
                    borderColor: "#0169B5",
                    borderRadius: "10px",
                    marginRight: "3%",
                  }}
                  id={"pendientes-" + index}
                  onClick={(event) =>
                    consultarColaborador(event, row.aprobar, "pendientes-" + index)
                  }
                >
                  <a className="text-decoration-none link-list d-block p-3 ">
                    <div className="row">
                      <div className="col-2">
                        {photoUsuario !== "" && photoUsuario !== null && photoUsuario !== undefined ? (
                          <img
                            src={`data:image/png;base64, ${photoUsuario}`}
                            alt=""
                            className="rounded-circle img-fluid"
                            width="160"
                            height="160"
                          />
                        ) : (
                          <img
                            src={fotoHombre}
                            alt=""
                            className="rounded-circle img-fluid"
                            width="160"
                            height="160"
                          />
                        )}
                      </div>
                      <div className="col-9">
                        <div className="data-list-box ">
                          <ul className="list-unstyled">
                            <li>
                              <span className="data-list-box__title col-6 text-uppercase">
                                Nombre
                              </span>
                              <span className="data-list-box__text">
                                {row.name}
                              </span>
                            </li>
                            <li>
                              <span className="data-list-box__title col-6 text-uppercase">
                                RUT
                              </span>
                              <span className="data-list-box__text">
                                {row.emplid}
                              </span>
                            </li>
                            <li>
                              <span className="data-list-box__title col-6 text-uppercase">
                                Cargo
                              </span>
                              <span className="data-list-box__text">
                                {row.jobCodeDescr}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-1 d-flex align-items-center">
                        <i
                          className="fa fa-chevron-right color-primary fa-lg"
                          id={"pendientess-" + index}
                          aria-hidden="true"
                          onClick={(event) =>
                            consultarColaborador(event, row.aprobar, "pendientess-" + index)
                          }
                        ></i>
                      </div>
                    </div>
                  </a>
                </div>
              );
            }
          }
        })
      );
    }
  }
};

const AprobarVacaciones = () => {
  const [getVacacionesSubAlterno, setVacacionesSubAlterno] = useState(null);
  const [, setRespuestaSolicitudText] = useState("");
  const [, setRespuestaSolicitudValid] = useState(false);
  const [rows, setRows] = useState([false]);
  const [misDias, setMisDias] = useState(0);
  const [estadoChange, setEstadoChange] = useState("");
  const [codigoSeleccionado, setCodigoSeleccionado] = useState("");
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState("");
  const [codigoSeleccionado2, setCodigoSeleccionado2] = useState("");
  const [pixeles, setPixeles] = useState("0");
  const [stateAceptar, setStateAceptar] = useState(false);
  let navigate = useNavigate();
  const [hacerClic, setHacerClic] = useState(null);
  const [resultadoRechazo, setResultadoRechazo] = useState(false);
  const [emplid, setEmplid] = useState(localStorage.getItem("emplid"));
  const [existdata, setExistData] = useState(true);
  const [existDataModal, setExistDataModal] = useState(false);
  const [existDataModal2, setExistDataModal2] = useState(false);
  const [rechazoError, setRechazoError] = useState(false);
  const [aprobarError, setAprobarError] = useState(false);
  const [anularErrorx, setAnularErrorx] = useState(false);
  const [numeroSolicitado, setNumeroSolicitado] = useState(0);
  const textmodal = "Enviando solicitud..";
  const [cargando, setCargando] = useState(false);
  const [aprobarErrorModal, setAprobarErrorModal] = useState(false);
  const [anularErrorModal, setAnularErrorModal] = useState(false);
  const [rechazarExito, setRechazarExito] = useState(false);
  const [aprobarExito, setAprobarExito] = useState(false);
  const [anularExito, setAnularExito] = useState(false);
  const [, setRutUsuarios] = useState([]);
  const [photosUsuarios, setPhotosUsuarios] = useState([]);
  const [currentPhotosUsuarios, setCurrentPhotosUsuarios] = useState([]);
  const [tieneNet, setTieneNet] = useState(true);

  const createData = (
    name,
    code,
    population,
    duracion_dias,
    size,
    aprobar,
    emplid,
    estadoSolicitud,
    jobCodeDescr
  ) => {
    return {
      name,
      code,
      population,
      duracion_dias,
      size,
      aprobar,
      emplid,
      estadoSolicitud,
      jobCodeDescr
    };
  };

  const isObject = (obj) => {
    return Object.prototype.toString.call(obj) === "[object Object]";
  };
  const handleClose = () => {
    setRespuestaSolicitudValid(false);
    setExistDataModal(false);
    setRechazoError(false);
    setAprobarErrorModal(false);
    setAnularErrorModal(false);
    setRechazarExito(false);
    setAprobarExito(false);
    setAnularExito(false);
    setTieneNet(true);
    setExistDataModal2(false);
  };

  const [diasDisponibles, setDiasDisponibles] = useState(null);

  useEffect(() => {
    if (emplid !== null) {
      vacacionesService.getVacacionesSubAlterno(setVacacionesSubAlterno, emplid);
      vacacionesService.getDetalleDiasDisponibles(setDiasDisponibles, emplid);
    }
  }, [emplid, navigate]);

  useEffect(() => {
    if (diasDisponibles) {
      if (decrypt(diasDisponibles.balance) !== undefined) {
        setMisDias(decrypt(diasDisponibles.balance));
      }
    }
  }, [diasDisponibles]);

  useEffect(() => {
    let rowsCopy = [];
    let rutUsuariosCopy = [];
    if (getVacacionesSubAlterno) {
      if (!hacerClic) {
        try {
          //clic
          document.getElementById("pendientess-0").click();
          setHacerClic(true);
        } catch (error) {
          undefined;
        }
      }

      if (getVacacionesSubAlterno.subalternos !== undefined) {
        if (rows[0] === false) {
          rows.shift();
          if (isObject(getVacacionesSubAlterno.subalternos)) {
            if (getVacacionesSubAlterno.subalternos.estado_req !== " ") {
              rutUsuariosCopy.push(getVacacionesSubAlterno.subalternos.emplid);
              rowsCopy.push(
                createData(
                  getVacacionesSubAlterno.subalternos.nombre,
                  formato(getVacacionesSubAlterno.subalternos.fecha_inicio),
                  formato(getVacacionesSubAlterno.subalternos.fecha_retorno),
                  getVacacionesSubAlterno.subalternos.cantidad_dias,
                  getVacacionesSubAlterno.subalternos.saldo,
                  getVacacionesSubAlterno.subalternos.num_req,
                  getVacacionesSubAlterno.subalternos.emplid,
                  getVacacionesSubAlterno.subalternos.estado_req,
                  getVacacionesSubAlterno.subalternos.cargo
                )
              );
              setRutUsuarios(rutUsuariosCopy);
              setRows(rowsCopy);
            }
          } else {
            for (
              var i = 0;
              i < getVacacionesSubAlterno.subalternos.length;
              i++
            ) {
              if (getVacacionesSubAlterno.subalternos[i].estado_req !== " ") {
                rutUsuariosCopy.push(
                  getVacacionesSubAlterno.subalternos[i].emplid
                );
                rowsCopy.push(
                  createData(
                    getVacacionesSubAlterno.subalternos[i].nombre,
                    formato(
                      getVacacionesSubAlterno.subalternos[i].fecha_inicio
                    ),
                    formato(
                      getVacacionesSubAlterno.subalternos[i].fecha_retorno
                    ),
                    getVacacionesSubAlterno.subalternos[i].cantidad_dias,
                    getVacacionesSubAlterno.subalternos[i].saldo,
                    getVacacionesSubAlterno.subalternos[i].num_req,
                    getVacacionesSubAlterno.subalternos[i].emplid,
                    getVacacionesSubAlterno.subalternos[i].estado_req,
                    getVacacionesSubAlterno.subalternos[i].cargo
                  )
                );
                setRutUsuarios(rutUsuariosCopy);
                setRows(rowsCopy);
              }
            }
          }
        }

        if (codigoSeleccionado === "" || codigoSeleccionado === undefined) {
          var seleccion;
          if (estadoChange === "Aprobadas") {
            try {
              seleccion = document.getElementById("aprobadass-0");
              seleccion.click();
            } catch {
              undefined;
            }
          } else if (estadoChange === "Pendientes") {
            try {
              seleccion = document.getElementById("pendientess-0");
              seleccion.click();
            } catch {
              undefined;
            }
          } else {
            try {
              seleccion = document.getElementById("todass-0");
              seleccion.click();
            } catch {
              undefined;
            }
          }
        }

        if (getVacacionesSubAlterno === 2) {
          setExistDataModal2(true);
        }
      }
    }
  }, [getVacacionesSubAlterno, existdata, codigoSeleccionado]);

  function formato(texto) {
    return texto.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1");
  }
  const aceptarSolicitud = (event, name, numm_req) => {
    setUsuarioSeleccionado(name);
    setCodigoSeleccionado2(numm_req);
    setStateAceptar(!stateAceptar);
  };

  useEffect(() => {
    if (resultadoRechazo === 2) {
      setRechazoError(true);
      setCargando(false);
    } else if (resultadoRechazo !== undefined) {
      if (resultadoRechazo.status !== undefined) {
        setCargando(false);
        var newArray = rows.filter((item) => item.aprobar !== numeroSolicitado);
        rows.splice(0, rows.length);
        setRows(...[newArray]);
        setRechazarExito(true);
      } else {
        setCargando(false);
      }
    }
  }, [resultadoRechazo]);

  const rechazarSolicitud = (event, name, numm_req) => {
    let rutUsuarioSelect = "";
    let num_req = "";
    let cantidad_dias = 0;
    for (var i = 0; i < rows.length; i++) {
      if (rows[i].aprobar === numm_req) {
        rutUsuarioSelect = rows[i].emplid;
        num_req = rows[i].aprobar;
        cantidad_dias = rows[i].duracion_dias;
      }
    }
    if (rutUsuarioSelect !== "") {
      if (navigator.onLine) {
        setCodigoSeleccionado("");
        vacacionesService.rechazarVacaciones(
          rutUsuarioSelect,
          emplid,
          num_req,
          setResultadoRechazo
        );
        setCargando(true);
        setNumeroSolicitado(num_req);
        if (rutUsuarioSelect === emplid) {
          var totalGet = sessionStorage.getItem("diasDisponibles");
          var totalNuevoDia = parseFloat(totalGet) + parseFloat(cantidad_dias);
          sessionStorage.setItem("diasDisponibles", totalNuevoDia);
        }
      } else {
        setRespuestaSolicitudValid(true);
        setRespuestaSolicitudText(
          "La conexión no está disponible en estos momentos, favor de intentar más tarde."
        );
      }
    }
  };

  useEffect(() => {
    if (anularErrorx === 2) {
      setAnularErrorModal(true);
      setCargando(false);
    } else if (anularErrorx !== undefined) {
      if (anularErrorx.status !== undefined) {
        var newArray = rows.filter((item) => item.aprobar !== numeroSolicitado);
        rows.splice(0, rows.length);
        setRows(...[newArray]);
        setCargando(false);
        setAnularExito(true);
      } else {
        setCargando(false);
      }
    }
  }, [anularErrorx]);

  const anularSolicitud = (event, name, numm_req) => {
    let rutUsuarioSelect = "";
    let num_req = "";
    let cantidad_dias = 0;
    for (var i = 0; i < rows.length; i++) {
      if (rows[i].aprobar === numm_req) {
        rutUsuarioSelect = rows[i].emplid;
        num_req = rows[i].aprobar;
        cantidad_dias = rows[i].duracion_dias;
      }
    }
    if (rutUsuarioSelect !== "") {
      if (navigator.onLine) {
        setCodigoSeleccionado("");
        vacacionesService.anularVacaciones(
          rutUsuarioSelect,
          emplid,
          num_req,
          setAnularErrorx
        );
        setCargando(true);
        setNumeroSolicitado(num_req);
        if (emplid === rutUsuarioSelect) {
          var totalGet = sessionStorage.getItem("diasDisponibles");
          var totalNuevoDia = parseFloat(totalGet) + parseFloat(cantidad_dias);
          sessionStorage.setItem("diasDisponibles", totalNuevoDia);
        }
      } else {
        setRespuestaSolicitudValid(true);
        setRespuestaSolicitudText(
          "La conexión no está disponible en estos momentos, favor de intentar más tarde."
        );
      }
    }
  };

  const changeState = () => {
    var estado = document.getElementById("inputState").value;
    if (estado === "Aprobadas" || estado === "Pendientes") {
      setCodigoSeleccionado("");
      setEstadoChange(estado);
    }
  };

  const consultarColaborador = (event, valorSeleccionado, context) => {
    setCodigoSeleccionado(valorSeleccionado);
    setPixeles(context);
  };

  useEffect(() => {
    if (aprobarError === 2) {
      setAprobarErrorModal(true);
      setCargando(false);
    } else if (aprobarError !== undefined) {
      if (aprobarError.status !== undefined) {
        if (aprobarError.valid === "0" || aprobarError.return_cd === "1") {
          setAprobarErrorModal(true);
          setCargando(false);    
        } else {
          setCargando(false);
          let rows2 = rows.map((row) => {
            if (row.aprobar === codigoSeleccionado2) {
              row.estadoSolicitud = "1";
            }
            return row;
          });
          setRows(rows2);
          setCodigoSeleccionado("");
  
          setRespuestaSolicitudValid(true);
          setRespuestaSolicitudText("Se ha aprobado correctamente la solicitud.");
          setAprobarExito(true);
        }
      } else {
        setCargando(false);
      }
    }
  }, [aprobarError]);

  let date = new Date();
  let output =
    date.getFullYear() +
    "-" +
    String(date.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(date.getDate()).padStart(2, "0");

  const aprobarSolicitud = (event, name, numm_req) => {
    let rutUsuarioSelect = "";
    let num_req = "";
    for (var i = 0; i < rows.length; i++) {
      if (rows[i].aprobar === numm_req) {
        rutUsuarioSelect = rows[i].emplid;
        num_req = rows[i].aprobar;
      }
    }
    if (rutUsuarioSelect !== "") {
      if (navigator.onLine) {
        vacacionesService.aprobarVacaciones(
          rutUsuarioSelect,
          emplid,
          num_req,
          setAprobarError
        );
        setCargando(true);
      } else {
        setRespuestaSolicitudValid(true);
        setRespuestaSolicitudText(
          "La conexión no está disponible en estos momentos, favor de intentar más tarde."
        );
      }
    }
  };

  const getEmplidPhoto = async (arrayEmplids) => {
    let newArrayEmpl = arrayEmplids.map(async (emplid) => {
        const response = await caches.match(urlsAws.getPhotosEquipo + emplid);
        if (!response) {
            return emplid;
        }
        return null;
    });
    const results = await Promise.all(newArrayEmpl);
    return results.filter((ob) => ob !== null);
};

const setCurrentPhoto = async (temp, setCurrentPhotosUsuarios) => {
    const newCurrent = await Promise.all(
        temp.map(async (item) => {
            const response = await caches.match(urlsAws.getPhotosEquipo + item.emplid);
            if (response) {
                const data = await response.json();
                const updatedItem = { ...item, photo: data.photo };
                return updatedItem;
            } else {
                return item;
            }
        })
    );
    setCurrentPhotosUsuarios(newCurrent);
};


  useEffect(()=>{
    if(rows.length > 0 && getVacacionesSubAlterno !== null){
      let arrayEmplids = rows.map((obj) => {
        return obj.emplid;
    });

    getEmplidPhoto(arrayEmplids)
      .then((emplidPhoto) => {
          if (emplidPhoto.length > 0 && photosUsuarios.length === 0) {
            miEquipoService.getPhotos(setPhotosUsuarios, emplidPhoto);
          } else {
            setCurrentPhoto(rows, setCurrentPhotosUsuarios);
          }
      })
      .catch((error) => {
          console.log(error);
      });

    }
  }, [rows, getVacacionesSubAlterno, photosUsuarios])



  return (
    <>
      <nav className="breadcrumbs" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/inicio">
              <i className="fa fa-home" aria-hidden="true"></i> Inicio
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Aprobar vacaciones
          </li>
        </ol>
      </nav>

      <section className="aprobar-vacaciones">
        <div className="tit-group d-block d-xxl-flex justify-content-between">
          <h1>Vacaciones</h1>
          <div className="saldo-vacaciones rounded-3 bg-light p-3 color-primary mb-4">
            <div className="row align-items-center">
              <label
                htmlFor="inputPassword"
                className="col-6 col-sm-4 col-md-3 col-form-label"
              >
                Saldo Vacaciones:
              </label>
              <div
                className="col-3 col-md-2 col-xl-1"
                style={{ width: "100px" }}
              >
                <input
                  type="text"
                  className="form-control text-center bg-white"
                  value={misDias}
                  disabled
                />
              </div>
              <small className="col-12 col-md-6 col-lg-7 col-xl-15 mt-4 mt-md-0">
                * Su saldo de vacaciones actual incluye los días futuros
                aprobados
              </small>
            </div>
          </div>
        </div>
        <>
          <></>

          <CarrouselVacaciones tituloSelect="Aprobar"></CarrouselVacaciones>
        </>
        <div className="box mb-4">
          <h2 className="h4 tit-section">Filtrar por</h2>
          <div className="row">
            <div className="col-12 col-xl-6 mb-4">
              <select
                defaultValue={"Pendientes"}
                id="inputState"
                className="form-select"
                required="required"
              >
                <option disabled value="">
                  Seleccione
                </option>
                <option>Aprobadas</option>
                <option option={"Pendientes"}>Pendientes</option>
              </select>
            </div>
            <div className="col d-grid d-md-block">
              <button
                type="button"
                onClick={(event) => changeState(event)}
                className="btn btn-primary"
              >
                Filtrar{" "}
                <i
                  className="fa fa-chevron-right fa-fw fa-xs"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </div>
        </div>
        <div className="box mb-4">
          <h2 className="h4 tit-section">Solicitudes de Mi Equipo</h2>
          <div className="row d-none d-md-flex">
            <div className="col-12 col-xl-7 d-flex">
              <div className="border border-primary rounded-4 p-4 align-items-stretch w-100">
                <div className="box-scroll box-scroll-x2">
                  {estadoChange === "Pendientes" ? (
                    <Solicitadas consultarColaborador={consultarColaborador} currentPhotosUsuarios={currentPhotosUsuarios} pixeles={pixeles} rows={rows} setExistData={setExistData} />
                  ) : estadoChange === "Aprobadas" ? (
                    <Aprobadas consultarColaborador={consultarColaborador} currentPhotosUsuarios={currentPhotosUsuarios} pixeles={pixeles} rows={rows} setExistData={setExistData} />
                  ) : (
                    <Solicitadas consultarColaborador={consultarColaborador} currentPhotosUsuarios={currentPhotosUsuarios} pixeles={pixeles} rows={rows} setExistData={setExistData} />
                  )}
                </div>
              </div>
            </div>
            {existdata ? (
              <div className="col-12 col-xl-5 d-flex mt-4 mt-xl-0">
                <div className="border border-primary rounded-4 p-4 w-100">
                  {Children.toArray(
                    rows.map((row) => {
                      if (row.aprobar === codigoSeleccionado) {
                        return (
                          <>
                            <h3 className="h5">{row.name}</h3>
                            <div className="data-list-box text-uppercase">
                              <ul className="data-list-section--item list-unstyled">
                                <li>
                                  <span className="data-list-box__title col-6">
                                    Fecha inicial
                                  </span>
                                  <span className="data-list-box__text">
                                    {row.code}
                                  </span>
                                </li>
                                <li>
                                  <span className="data-list-box__title col-6">
                                    Fecha término
                                  </span>
                                  <span className="data-list-box__text">
                                    {row.population}
                                  </span>
                                </li>
                                <li>
                                  <span className="data-list-box__title col-6">
                                    Duración
                                  </span>
                                  <span className="data-list-box__text">
                                    {row.duracion_dias}
                                  </span>
                                </li>
                                <li>
                                  <span className="data-list-box__title col-6">
                                    Nuevo saldo
                                  </span>
                                  <span className="data-list-box__text">
                                    {row.size}
                                  </span>
                                </li>
                              </ul>
                            </div>
                            {row.estadoSolicitud === "0" ? (
                              <div className="row mt-5">
                                <div className="d-grid col-6">
                                  {navigator.onLine ? (
                                    <button
                                      type="button"
                                      onClick={(event) =>
                                        rechazarSolicitud(
                                          event,
                                          row.name,
                                          row.aprobar
                                        )
                                      }
                                      className="btn btn-primary"
                                    >
                                      Rechazar{" "}
                                      <i
                                        className="fa fa-chevron-right fa-fw fa-xs"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      onClick={() => setTieneNet(false)}
                                      className="btn btn-primary"
                                    >
                                      Rechazar{" "}
                                      <i
                                        className="fa fa-chevron-right fa-fw fa-xs"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  )}
                                </div>
                                <div className="d-grid col-6">
                                  {navigator.onLine ? (
                                    <button
                                      type="button"
                                      onClick={(event) =>
                                        aceptarSolicitud(
                                          event,
                                          row.name,
                                          row.aprobar
                                        )
                                      }
                                      data-bs-toggle="modal"
                                      data-bs-target="#aprobar"
                                      className="btn btn-degradado"
                                    >
                                      Aprobar{" "}
                                      <i
                                        className="fa fa-chevron-right fa-fw fa-xs"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      onClick={() => setTieneNet(false)}
                                      data-bs-toggle="modal"
                                      data-bs-target="#aprobar"
                                      className="btn btn-degradado"
                                    >
                                      Aprobar{" "}
                                      <i
                                        className="fa fa-chevron-right fa-fw fa-xs"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="text-center mt-5">
                                {formatDate(row.code) === output ? (
                                  <></>
                                ) : navigator.onLine ? (
                                  <button
                                    type="button"
                                    className="btn btn-degradado px-5"
                                    onClick={(event) =>
                                      aceptarSolicitud(
                                        event,
                                        row.name,
                                        row.aprobar
                                      )
                                    }
                                    data-bs-toggle="modal"
                                    data-bs-target="#anular"
                                  >
                                    Anular{" "}
                                    <i
                                      className="fa fa-chevron-right fa-fw fa-xs"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-degradado px-5"
                                    onClick={() => setTieneNet(false)}
                                    data-bs-toggle="modal"
                                    data-bs-target="#anular"
                                  >
                                    Anular{" "}
                                    <i
                                      className="fa fa-chevron-right fa-fw fa-xs"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                )}
                              </div>
                            )}
                          </>
                        );
                      }
                    })
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
            {estadoChange === "Pendientes" ? (
              <PendienteCel aceptarSolicitud={aceptarSolicitud} rechazarSolicitud={rechazarSolicitud} rows={rows} setExistData={setExistData} />
            ) : estadoChange === "Aprobadas" ? (
              <AprobadasCel aceptarSolicitud={aceptarSolicitud} output={output} rows={rows} setExistData={setExistData} />
            ) : (
              <PendienteCel aceptarSolicitud={aceptarSolicitud} rechazarSolicitud={rechazarSolicitud} rows={rows} setExistData={setExistData} />
            )}
          </div>

          <div className="color-red mt-4">
            <p>
              <i
                className="fa fa-exclamation-triangle fa-fw fa-lg me-1"
                aria-hidden="true"
              ></i>{" "}
              Si la información en esta página no es correcta, por favor
              solicitar el cambio al responsable de RR.HH. de su departamento.
            </p>
          </div>
        </div>
        <div
          className="modal fade"
          id="aprobar"
          tabIndex="-1"
          aria-labelledby="aprobar"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0 pb-0">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body text-center py-4">
                <h2 className="h5">
                  ¿Estás seguro que deseas aprobar <br /> la solicitud?
                </h2>
              </div>
              <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary px-5"
                  data-bs-dismiss="modal"
                >
                  No{" "}
                  <i
                    className="fa fa-chevron-right fa-fw fa-xs"
                    aria-hidden="true"
                  ></i>
                </button>
                {navigator.onLine ? (
                  <button
                    type="button"
                    className="btn btn-degradado px-5"
                    data-bs-dismiss="modal"
                    onClick={(event) =>
                      aprobarSolicitud(
                        event,
                        usuarioSeleccionado,
                        codigoSeleccionado2
                      )
                    }
                  >
                    Sí{" "}
                    <i
                      className="fa fa-chevron-right fa-fw fa-xs"
                      aria-hidden="true"
                    ></i>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-degradado px-5"
                    data-bs-dismiss="modal"
                    onClick={() => setTieneNet(false)}
                  >
                    Sí{" "}
                    <i
                      className="fa fa-chevron-right fa-fw fa-xs"
                      aria-hidden="true"
                    ></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="anular"
          tabIndex="-1"
          aria-labelledby="anular"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0 pb-0">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body text-center py-4">
                <h2 className="h5">
                  ¿Estás seguro que deseas anular <br /> la solicitud?
                </h2>
              </div>
              <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary px-5"
                  data-bs-dismiss="modal"
                >
                  No{" "}
                  <i
                    className="fa fa-chevron-right fa-fw fa-xs"
                    aria-hidden="true"
                  ></i>
                </button>
                {navigator.onLine ? (
                  <button
                    type="button"
                    className="btn btn-degradado px-5"
                    onClick={(event) =>
                      anularSolicitud(
                        event,
                        usuarioSeleccionado,
                        codigoSeleccionado2
                      )
                    }
                    data-bs-dismiss="modal"
                  >
                    Sí{" "}
                    <i
                      className="fa fa-chevron-right fa-fw fa-xs"
                      aria-hidden="true"
                    ></i>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-degradado px-5"
                    onClick={() => setTieneNet(false)}
                    data-bs-dismiss="modal"
                  >
                    Sí{" "}
                    <i
                      className="fa fa-chevron-right fa-fw fa-xs"
                      aria-hidden="true"
                    ></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="success"
          tabIndex="-1"
          aria-labelledby="success"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0 pb-0">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body text-center py-4 pt-0">
                <i
                  className="fa fa-check-circle-o color-orange fa-3x mb-2"
                  aria-hidden="true"
                ></i>
                <h2 className="h5">
                  La solicitud a sido aprobada <br /> con éxito.
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="success2"
          tabIndex="-1"
          aria-labelledby="success2"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0 pb-0">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body text-center py-4 pt-0">
                <i
                  className="fa fa-check-circle-o color-orange fa-3x mb-2"
                  aria-hidden="true"
                ></i>
                <h2 className="h5">
                  La solicitud a sido anulada <br /> con éxito.
                </h2>
              </div>
            </div>
          </div>
        </div>
        {aprobarExito ? (
          <>
            <div className="col">
              <div
                className="modal fade show"
                id="foto-credencial"
                tabIndex={"-1"}
                aria-labelledby="enviar-por-correo"
                aria-modal="true"
                role="dialog"
                style={{ display: "block", textAlign: "left" }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                      <button
                        type="submit"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        id="enviar"
                        onClick={(e) => handleClose(e)}
                      ></button>
                    </div>
                    <div className="modal-body text-center py-4">
                      <i
                        className="fa fa-check-circle-o color-orange fa-3x mb-2"
                        aria-hidden="true"
                      ></i>
                      <h2 className="h5">Aprobar vacaciones</h2>
                      <p>Solicitud aprobada exitosamente.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {rechazarExito ? (
          <>
            <div className="col">
              <div
                className="modal fade show"
                id="foto-credencial"
                tabIndex={"-1"}
                aria-labelledby="enviar-por-correo"
                aria-modal="true"
                role="dialog"
                style={{ display: "block", textAlign: "left" }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                      <button
                        type="submit"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        id="enviar"
                        onClick={(e) => handleClose(e)}
                      ></button>
                    </div>
                    <div className="modal-body text-center py-4">
                      <i
                        className="fa fa-check-circle-o color-orange fa-3x mb-2"
                        aria-hidden="true"
                      ></i>
                      <h2 className="h5">Aprobar vacaciones</h2>
                      <p>Solicitud rechazada exitosamente.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {anularExito ? (
          <>
            <div className="col">
              <div
                className="modal fade show"
                id="foto-credencial"
                tabIndex={"-1"}
                aria-labelledby="enviar-por-correo"
                aria-modal="true"
                role="dialog"
                style={{ display: "block", textAlign: "left" }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                      <button
                        type="submit"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        id="enviar"
                        onClick={(e) => handleClose(e)}
                      ></button>
                    </div>
                    <div className="modal-body text-center py-4">
                      <i
                        className="fa fa-check-circle-o color-orange fa-3x mb-2"
                        aria-hidden="true"
                      ></i>
                      <h2 className="h5">Aprobar vacaciones</h2>
                      <p>Solicitud anulada exitosamente.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}

        {aprobarErrorModal ? (
          <>
            <div className="col">
              <div
                className="modal fade show"
                id="foto-credencial"
                tabIndex={"-1"}
                aria-labelledby="enviar-por-correo"
                aria-modal="true"
                role="dialog"
                style={{ display: "block", textAlign: "left" }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                      <button
                        type="submit"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        id="enviar"
                        onClick={(e) => handleClose(e)}
                      ></button>
                    </div>
                    <div className="modal-body text-center py-4">
                      <i
                        className="fa fa-download color-orange fa-3x mb-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        aria-hidden="true"
                      ></i>
                      <h2 className="h5">Aprobar vacaciones</h2>
                      <p>Se ha producido un error al aprobar la solicitud.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {anularErrorModal ? (
          <>
            <div className="col">
              <div
                className="modal fade show"
                id="foto-credencial"
                tabIndex={"-1"}
                aria-labelledby="enviar-por-correo"
                aria-modal="true"
                role="dialog"
                style={{ display: "block", textAlign: "left" }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                      <button
                        type="submit"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        id="enviar"
                        onClick={(e) => handleClose(e)}
                      ></button>
                    </div>
                    <div className="modal-body text-center py-4">
                      <i
                        className="fa fa-download color-orange fa-3x mb-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        aria-hidden="true"
                      ></i>
                      <h2 className="h5">Aprobar vacaciones</h2>
                      <p>Se ha producido un error al anular la solicitud.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {rechazoError ? (
          <>
            <div className="col">
              <div
                className="modal fade show"
                id="foto-credencial"
                tabIndex={"-1"}
                aria-labelledby="enviar-por-correo"
                aria-modal="true"
                role="dialog"
                style={{ display: "block", textAlign: "left" }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                      <button
                        type="submit"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        id="enviar"
                        onClick={(e) => handleClose(e)}
                      ></button>
                    </div>
                    <div className="modal-body text-center py-4">
                      <i
                        className="fa fa-download color-orange fa-3x mb-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        aria-hidden="true"
                      ></i>
                      <h2 className="h5">Aprobar vacaciones</h2>
                      <p>Se ha producido un error al rechazar la solicitud.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {existDataModal ? (
          <>
            <div className="col">
              <div
                className="modal fade show"
                id="foto-credencial"
                tabIndex={"-1"}
                aria-labelledby="enviar-por-correo"
                aria-modal="true"
                role="dialog"
                style={{ display: "block", textAlign: "left" }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                      <button
                        type="submit"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        id="enviar"
                        onClick={(e) => handleClose(e)}
                      ></button>
                    </div>
                    <div className="modal-body text-center py-4">
                      <i
                        className="fa fa-download color-orange fa-3x mb-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        aria-hidden="true"
                      ></i>
                      <h2 className="h5">Aprobar vacaciones</h2>
                      <p>No se ha encontrado información.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {existDataModal2 ? (
          <>
            <div className="col">
              <div
                className="modal fade show"
                id="foto-credencial"
                tabIndex={"-1"}
                aria-labelledby="enviar-por-correo"
                aria-modal="true"
                role="dialog"
                style={{ display: "block", textAlign: "left" }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                      <button
                        type="submit"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        id="enviar"
                        onClick={(e) => handleClose(e)}
                      ></button>
                    </div>
                    <div className="modal-body text-center py-4">
                      <i
                        className="fa fa-download color-orange fa-3x mb-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        aria-hidden="true"
                      ></i>
                      <h2 className="h5">Aprobar vacaciones</h2>
                      <p>
                        Por un error de comunicación no se ha obtenido la
                        información, por favor inténtelo más tarde.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {cargando ? (
          <>
            <div className="col">
              <div
                className="modal fade show"
                id="foto-credencial"
                tabIndex={"-1"}
                aria-labelledby="enviar-por-correo"
                aria-modal="true"
                role="dialog"
                style={{ display: "block", textAlign: "left" }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header border-0 pb-0"></div>
                    <div className="modal-body text-center py-4">
                      <i
                        className="fa fa-download color-orange fa-3x mb-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        aria-hidden="true"
                      ></i>
                      <h2 className="h5">{textmodal}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {!tieneNet ? (
          <>
            <div className="col">
              <div
                className="modal fade show"
                id="foto-credencial"
                tabIndex={"-1"}
                aria-labelledby="enviar-por-correo"
                aria-modal="true"
                role="dialog"
                style={{ display: "block", textAlign: "left" }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                      <button
                        type="submit"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        id="enviar"
                        onClick={(e) => handleClose(e)}
                      ></button>
                    </div>
                    <div className="modal-body text-center py-4">
                      <i
                        className="fa fa-exclamation-triangle color-orange fa-3x"
                        aria-hidden="true"
                      ></i>
                      <h2 className="h5">No tienes conexión</h2>
                      <p>
                        No puedes ejecutar esta acción si no tienes conexión
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </section>
    </>
  );
};

export default AprobarVacaciones;
