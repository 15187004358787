import React from "react";

export const CheckBoxSample = ({onChange, defaultValue = false, label}) => {
  
  return (
    <div className="form-check form-switch mb-4">
      <input
    
        className="form-check-input h3"
        type="checkbox"
        //role="switch"
        id="datos-visibles"
        defaultChecked={defaultValue}
        onChange={() => {
          onChange();
        }}
      />
      <label
        className="form-check-label text-uppercase"
        htmlFor="datos-visibles"
      >
        {label}
      </label>
    </div>
  );
};
