import React, { useContext } from 'react'
import ErrorScreen from '../../commonComponents/error-screen/error-screen'
import { Context } from '../../../context'

export const MaintenanceCmp = () => {
  const {messageMaintenance} = useContext(Context)
  return (
    <ErrorScreen  etype="Maintenance" message={messageMaintenance}  />
  )
}
