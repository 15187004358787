import React, { FC, useEffect, useState, useCallback, memo } from "react";
import { WidgetProps, WidgetTypeProps, WidgetFactoryProps } from "./interfaces";
import { BannerComponentProps } from "../Helpers/interfaces/banner";
import { CarouselIconComponentProps } from "../Helpers/interfaces/carouselIcon";
import { SliderComponentProps } from "../Helpers/interfaces/slider";
import { CarouselComponentProps } from "../Helpers/interfaces/carousel";
import { TableComponentProps } from "../Helpers/interfaces/table";
import { BoxProps } from "../Helpers/interfaces/box";
import { TaskComponentProps } from "../Helpers/interfaces/task";
import Box from "../Box";
import Error from "../Helpers/Error";
import Loading from "../Helpers/Loading";
import BannerFC from "../Banner";
import CarouselIconFC from "../CarouselIcon";
import SliderFC from "../Slider";
import CarouselFC from "../Carousel";
import TableFC from "../Table";
import Task from "../Task";
import useWidgetProps from "./useWidgetProps";

const WidgetFactory : FC<WidgetFactoryProps>= ({ type, id, data, width }: WidgetFactoryProps) => {
    const extraProps = useWidgetProps({ type, id, data, width });
    const widgetTypes = {
        'banner': <BannerFC {...extraProps as BannerComponentProps} />,
        'carousel-icon': <CarouselIconFC {...extraProps as CarouselIconComponentProps} />,
        'slider': <SliderFC {...extraProps as SliderComponentProps} />,
        'carousel': <CarouselFC {...extraProps as CarouselComponentProps} />,
        'table': <TableFC {...extraProps as TableComponentProps} />,
        'task': <Task {...extraProps as TaskComponentProps} />
    };
  
    return widgetTypes[type];
};

const Widget: FC<WidgetProps & WidgetTypeProps & BoxProps> = memo(({ data, type, id, width, title, box }: WidgetProps & WidgetTypeProps & BoxProps) => {
    const [widgetData, setWidgetData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);
    const fetchData = useCallback(async () => {
        if (typeof data === 'function') {
            setLoading(true);
            setError(null);
            try {
                const result = await data();
                setWidgetData(result);
            } catch (error) {
                setError('Error al intentar obtener la información');
            } finally {
                setLoading(false);
            }
        }
        else {
            setWidgetData(data);
            setLoading(false);
        }
    }, [data]);

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) return <Loading width={width} />;
    if (error) return <Error width={width} />;
    if (!widgetData) return null;
    return (
        <Box width={width} box={box} title={title}>
            <WidgetFactory type={type} id={id} data={widgetData} width={width} />
        </Box>
    );
});

const WidgetBanner : FC<WidgetProps & BoxProps> = ({ data, id, width, title, box }: WidgetProps & BoxProps) => {	
    return <Widget 
        id={id} 
        type="banner"
        data={data}
        width={width}
        box={box}
        title={title}
    />
}

const WidgetCarouselIcon : FC<WidgetProps & BoxProps> = ({ data, id, width, title, box }: WidgetProps & BoxProps) => {	
    return <Widget 
        id={id} 
        type="carousel-icon"
        data={data}
        width={width}
        box={box}
        title={title}
    />
}

const WidgetSlider : FC<WidgetProps & BoxProps> = ({ data, id, width, title, box }: WidgetProps & BoxProps) => {	
    return <Widget 
        id={id} 
        type="slider"
        data={data}
        width={width}
        box={box}
        title={title}
    />
}

const WidgetCarousel : FC<WidgetProps & BoxProps> = ({ data, id, width, title, box }: WidgetProps & BoxProps) => {	
    return <Widget 
        id={id} 
        type="carousel"
        data={data}
        width={width}
        box={box}
        title={title}
    />
}

const WidgetTable : FC<WidgetProps & BoxProps> = ({ data, id, width, title, box }: WidgetProps & BoxProps) => {	
    return <Widget 
        id={id} 
        type="table"
        data={data}
        width={width}
        box={box}
        title={title}
    />
}

const WidgetTask : FC<WidgetProps & BoxProps> = ({ data, id, width, title, box }: WidgetProps & BoxProps) => {
    return <Widget 
        id={id} 
        type="task"
        data={data}
        width={width}
        box={box}
        title={title}
    />
}

export { WidgetBanner, WidgetCarouselIcon, WidgetSlider, WidgetCarousel, WidgetTable, WidgetTask };
