import React, { useState, useEffect } from 'react';
import MiEquipoService from '../../../services/mi-equipo/mi-equipo-service';

const useEvaluacionDesempenio = () => {
    const [evaluacionDesempenio, setEvaluacionDesempenio] = useState();

    const getEvaluacionDesempenio = async () => {
        const data = await MiEquipoService.getEvaluacionDesempenio();
        setEvaluacionDesempenio(data);
    }

    useEffect(() => {
        getEvaluacionDesempenio();
    }, []);

    return { evaluacionDesempenio };
};

export default useEvaluacionDesempenio;