import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as RD from "@devexperts/remote-data-ts";
import { RemoteData } from "@devexperts/remote-data-ts";
import { HttpError, Verification } from "../../utilities/interfaces";
import { getRemoteData } from "../../utilities/helpers/setData";
import ReCAPTCHA from "react-google-recaptcha";
import Header from "../../../elements/header";
import Footer from "../../../elements/footer";
import styles from "../../../../resources/css/style.module.css";

type FormValues = {
    codigo: string;
}

const verifyDocumentPage = () => {
    const { handleSubmit, register, formState: { errors }, setError, getValues, setValue } = useForm({ mode: "onSubmit", reValidateMode: "onChange", defaultValues: { codigo: "" } });
    const [remoteData, setRemoteData] = useState<RemoteData<HttpError, Verification>>(RD.initial);
    const [token, setToken] = useState<string>("");
    const { search } = useLocation();
    const paramCode = search.split("code=")[1];
    const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY || "";

    useEffect(() => {
        if (paramCode) {
            setValue("codigo", paramCode);
            getRemoteData({ code: paramCode, setRemoteData, token: "false" });
        }
    }, []);

    const verifyRecaptcha = useCallback((token: string | null) => {
        if (token) {
            setToken(token);
        }
    }, []);

    const onSubmit = async (data: FormValues) => {
        try {
            getRemoteData({ code: data.codigo, setRemoteData, token });
        } catch (error) {
            setError("codigo", { type: "manual", message: "*Ingresa el código del certificado" });
        }
    }
    return <div className={`d-flex flex-column space-between ${styles["min-vh-footer"]}` } >
        <Header />
        <main id="main" className="wrapper no-menu">
            <div id="content" className="container">
                <div className="box mb-4">
                    <h2 className="h4 tit-section" > Validar Certificado </h2>
                    <form className="d-flex flex-column" onSubmit={handleSubmit(onSubmit)}>
                        <label htmlFor="codigo" className="col-md-3 mb-2 col-form-label text-uppercase"><strong>Código:</strong></label>
                        <div className="mb-3">
                            <input type="text" className="form-control" id="codigo" placeholder="Código"
                                {...register("codigo", {
                                    required: {
                                        value: true,
                                        message: "*Ingresa el código del certificado"
                                    }
                                })}
                            />
                            {errors.codigo && <span className="invalid-feedback" style={{ display: "block" }}>{errors.codigo.message}</span>}
                        </div>
                        <div className="d-flex justify-content-center">
                            <ReCAPTCHA sitekey={recaptchaKey} onChange={verifyRecaptcha} hl="es" size="normal" theme="light" />
                        </div>
                        <div className="mt-2 d-flex justify-content-end">
                            <button disabled={remoteData._tag === "RemotePending" || token === ""} className="btn btn-degradado">Validar</button>
                        </div>
                    </form>
                </div>
                {remoteData._tag !== "RemoteInitial" &&
                    <div className="box px-5 py-4 text-center">
                        {remoteData._tag === "RemoteSuccess" && <>
                            <i className={`fa fa-${remoteData.value.valid ? "check" : "times"}-circle color-orange fa-3x mb-3`} aria-hidden="true" />
                            <h2 className="h5">{`${remoteData.value.valid ? "Este certificado es válido" : "Hubo un problema"}`}</h2>
                            {remoteData.value.rows && remoteData.value.rows.map(
                                (row: any, index: number) => <p className="mb-1" key={index}>{`${row.label} : ${row.value}`}</p>)
                            }
                        </>}
                        {
                            remoteData._tag === "RemotePending" ? <>
                                <i className="fa fa-spinner fa-spin fa-3x fa-fw mb-3" aria-hidden="true" />
                                <h2 className="h5 mb-3">Buscando...</h2>
                            </>
                                : remoteData._tag === "RemoteFailure" && <>
                                    <i className="fa fa-exclamation-triangle color-orange fa-3x mb-3" aria-hidden="true" />
                                    <p>No se pudo validar el certificado, intente nuevamente.</p>
                                </>
                        }

                    </div>
                }
            </div>
        </main>
        <Footer />
    </div>
}

export default verifyDocumentPage;
