import React from "react";
import logo from "../../resources/images/direccion-de-trabajo.svg";
import { urls } from "../../resources/foo/api-endpoints";

export const DTGobNorm = () => {
  return (
    <div className="d-flex align-items-center">
        <a href={ urls.gobDT } target="_blank" rel="noreferrer" className="text-decoration-none color-normal">
            <div className="d-flex align-items-center">
                <img className="me-3" width="70" src={logo} alt="" />
                <p className="m-0">
                    Aprobado por la Dirección del<br />
                    Trabajo en Dictamen ORD:<br />
                    1074/Marzo 2021
                </p>
            </div>
        </a>
    </div>
  );
};
