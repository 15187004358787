export const errorTypes = [
  'networkError',
  'notFound',
  'forbiden',
  'serverError',
  'timeout'
]

export const errorsMessages = {
  111: errorTypes[0],
  400: errorTypes[1],
  401: errorTypes[2],
  403: errorTypes[2],
  404: errorTypes[1],
  405: errorTypes[2],
  500: errorTypes[3],
  502: errorTypes[3],
  503: errorTypes[3],
  504: errorTypes[4],
}