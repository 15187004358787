import React, { useState, useEffect} from 'react';
import { Accordion } from 'react-bootstrap'
import AsistenciaService from '../../services/asistencia/asistencia-service';
import moment from 'moment';
import ModalResumen from './modal-resumen';
import SolicitudLicenciasService from '../../services/solicitud licencias/solicitud-licencias-service';
import Swal from 'sweetalert2';


const CalendarHistory = () => {
   
    const estadosOption = [
        { value: "", description: "Todas" },
        { value: "P", description: "Pendientes" },
        { value: "A", description: "Aprobadas" },
        { value: "R", description: "Rechazadas" },
        {value:"V", description: "Vencidas"}
    ];
    const [show, setShow] = useState({ active: false, data: null })
    const [estado, setEstado] = useState(estadosOption.value);
    const [loading,setLoading]=useState(false);
    const [history,setHistory]=useState([]);
    // eslint-disable-next-line no-unused-vars
    const [error,setError]=useState({});
    const [terms, setTerms] = useState(null);
    const [generalData, setGeneralData] = useState(null);

    useEffect(() => {
        getShifts();
      }, [])

      const modalTerms = () => {
        return Swal.fire({
            title: 'BP',
            html: `<div style="text-align: left; height: 300px;"> ${terms?.bpsConditions} </div>`,
            confirmButtonColor: '#0169b5',
        })
    }

    useEffect(async () => {
        if (loading) {
            await SolicitudLicenciasService.getTermsBP(setTerms);
            await SolicitudLicenciasService.getData(setGeneralData);
        }
    }, [loading])

useEffect(() => {
    if (estado === "A") {
        AsistenciaService.getShiftsHistoryFiltered(setHistory, setLoading,setError, estado);
      }
      if (estado === "R") {
        AsistenciaService.getShiftsHistoryFiltered(setHistory, setLoading,setError, estado);
      }
      if (estado === "P") {
        AsistenciaService.getShiftsHistoryFiltered(setHistory, setLoading,setError, estado);
      }
      if (estado === "V") {
        AsistenciaService.getShiftsHistoryFiltered(setHistory, setLoading,setError, estado);
      }
      if (estado === "") {
        AsistenciaService.getShiftsHistoryFiltered(setHistory, setLoading,setError, estado);
      }
}, [estado])
    const handleFilter = (e) => {
        setEstado(e.target.value)
    }
  
      const getShifts = async() => {
          AsistenciaService.getShiftsHistory(setHistory, setLoading,setError);
      }

      const filteredShift = (id) => {
          let filtered = history.filter(application => application.id == id);
          console.log(filtered)
          let data = filtered[0];

          const wdMomentLocale = moment(data.workingDate);
          wdMomentLocale.locale("es");
          const diaATrabajar = wdMomentLocale.format('dddd DD [de] MMMM'); 

          const doMomentLocale = moment(data.dayOffDate);
          doMomentLocale.locale("es");
          const diaFranco = doMomentLocale.format('dddd DD [de] MMMM');

           const request = {
            id: data.id,
            type: data.type,
            previous: {
                dateDescription: diaATrabajar,
                startTime: data.previousStartTime,
                endTime: data.previousEndTime
            },
            changed: {
                dateDescription: data.type == 'Franco' ? diaFranco : diaATrabajar,
                startTime: data.newStartTime,
                endTime: data.newEndTime
            }
        }
            setShow({active:true, data:request});
      }

    return (
        <>
            {!loading ?  
            <div>
               
            <section>
               <div className='container-header'>
               <h3>Filtrar  <div className='title-underlined'></div></h3>
                    <div className='filters-fields'>
                        <label className='label fw-bold'>Estado</label>
                        <select className="form-select filter-input" value={estado} onChange={handleFilter}>
                            {estadosOption.map((op, i) => {
                                return <option key={i} value={op.value}>{op.description}</option>
                            })}
                        </select>
                    </div>
                </div>
            </section>
            <section>
                <div className="container-header">
                <h3>Detalle <div className='title-underlined'></div></h3>
                <section>
                {!loading ? <div className="title-card-container mb-2">
                        <h3 className="h5 fw-bold">Aprobador de Solicitudes: {generalData?.CN_RSP_ABCEN_EMPL?.MANAGER_NAME}</h3>
                        <p>Si tu aprobador no es el correcto contactá a tu BP. <a className="terms-anchor" onClick={() => modalTerms()}>Consultá acá</a> en caso de duda sobre tu BP.</p>
                    </div> : null}
                </section>
                    <div className="table-responsive desktop-table-vacaciones-historico">
                        <table className="table table-even table-section table-borderless d-md-table">
                            <thead>
                                <tr>
                                    <th className="cap">Tipo</th>
                                    <th className="cap">Horario</th>
                                    <th className="cap">Día a trabajar</th>
                                    <th className="cap">Dia Franco</th>
                                    <th className="cap">Fecha Solicitud</th>
                                    <th className="cap">Estado</th>
                                    <th className="cap">Detalle</th>

                                </tr>
                            </thead>
                            <tbody>
                                {history.length === 0 ? <tr><td colSpan={7} className="text-center"><div>No se encontraron solicitudes</div></td></tr> :
                                history && history.map((application, i) => {
                                    return (
                                        <>
                                            <tr key={i}>
                                                <td>Cambio de {application?.type === "Turno" ? "Horario" : application?.type}</td>
                                                <td>{application?.previousStartTime}-{application?.previousEndTime}</td>
                                                <td>{moment(application?.workingDate).format("DD/MM/YYYY")}</td>
                                                <td>{!application?.dayOffDate ? "-" : moment(application?.dayOffDate).format("DD/MM/YYYY")}</td>
                                                <td>{moment(application?.createdAt).format("DD/MM/YYYY") }</td>
                                                <td className={`boton-estado ${application?.status === 'A' ? "Aprobada" : application?.status === 'P' ? "Pendiente" : application?.status === 'R' ? "Rechazada" : "Vencida"}`}>{application?.status == 'A' ? "Aprobada" : application?.status == 'R' ? "Rechazada" : application?.status == 'P' ? "Pendiente" : "Vencida"}</td>
                                                <td className="detail-btn"><i className="fa fa-eye fa-fw fa-lg color-orange" aria-hidden="true" onClick={() =>  filteredShift(application.id)}></i></td>
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="d-md-none">
                    {history.length > 0 ? <Accordion className="accordion accordion-flush accordion-light accordion-table" defaultActiveKey="0">
                        {history.map((application) => {
                            return (
                                <Accordion.Item key={application?.id} eventKey={application?.id}>
                                    <Accordion.Header className="accordion-header title-emp" id="accordion">
                                        <strong>Cambio de {application?.type}</strong></Accordion.Header>
                                    <Accordion.Body className="accordion-body p-0">
                                        <div className="data-list-box data-list-box-even">
                                            <ul className="data-list-section--item list-unstyled">
                                                <li>
                                                    <span className="data-list-box__title">Horario</span>
                                                    <span className="data-list-box__text">{application?.previousStartTime}-{application?.previousEndTime}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Día a Trabajar</span>
                                                    <span className="data-list-box__text">{moment(application?.workingDate).format("DD/MM/YYYY")}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Día de Franco</span>
                                                    <span className="data-list-box__text">{!application?.dayOffDate ? "-" : moment(application?.dayOffDate).format("MM/DD/YYYY")}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Fecha Solicitud</span>
                                                    <span className="data-list-box__text text-truncate">{moment(application?.createdAt).format("DD/MM/YYYY") }</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Estado</span>
                                                    <span className="data-list-box__text text-uppercase fw-bold">
                                                        <span className={`boton-estado ${application?.status === 'A' ? "Aprobada" : application?.status === 'P' ? "Pendiente" : application?.status === 'R' ? "Rechazada" : "Vencida"}`}>{application?.status == 'A' ? "Aprobada" : application?.status == 'R' ? "Rechazada" : application?.status == 'P' ? "Pendiente" : "Vencida"}</span>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Detalle</span>
                                                    <span className="data-list-box__text text-uppercase fw-bold">
                                                        <span className="detail-btn"><i className="fa fa-eye fa-fw  fa-lg color-orange" aria-hidden="true" onClick={() =>  filteredShift(application.id)}></i></span>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })}
                    </Accordion> :  <div className='text-center no-data-row'>No se encontraron solicitudes</div>}</div>
                </div>
               {show && <ModalResumen show={show} setShow={setShow}></ModalResumen>}
                {/*responsive-table */}
            </section>
            </div> :  <div data-testid="loading" className="full-spinner">
            <div className="spinner-border text-primary"></div>
        </div>}


        </>
    )
}

export default CalendarHistory

