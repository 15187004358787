import React, { FC } from "react";

interface ModalConfirmProps {
    refOpen: any,
    text: string,
    callBack: () => void,
    data_target ? : string,
}

const ModalConfirmAction: FC<ModalConfirmProps> = ({refOpen, text, callBack, data_target}) => {

    return (
        <>
            <a hidden ref={refOpen} data-bs-toggle="modal" data-bs-target={data_target ? `#${data_target}`: "#modalConfirmAction"}/>
            <div className="modal fade" id={data_target ? `${data_target}`: "modalConfirmAction"} aria-labelledby={data_target ? `${data_target}`: "modalConfirmAction"} aria-hidden="true" data-backdrop="static" data-keyboard="false" data-bs-target={data_target ? `#${data_target}`: "#modalConfirmAction"}>
                <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                    </div>
                    <div className="modal-body text-center py-4">
                        <h2 className="h5">
                        {text}
                        </h2>
                    </div>
                    <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                        <button
                            type="button"
                            className="btn btn-primary px-5"
                            data-bs-dismiss="modal">
                            No{" "}
                        </button>
                        <button
                            onClick={() => { callBack() }}
                            type="button"
                            className="btn btn-degradado px-5"
                            data-bs-dismiss="modal">
                            Sí{" "}
                            <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" ></i>
                        </button>
                    </div>
                </div>
                </div>
            </div>
        </>
    )

}

export default ModalConfirmAction;