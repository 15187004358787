const browsersList = [
  'chrome',
  'firefox',
  'safari',
  'opera',
  'edge'
]

const browserMatches = [
  /chrome|chromium|crios/i,
  /firefox|fxios/i,
  /safari/i,
  /opr\//i,
  /edg/i
]

export const checkBrowser = (): string => {
  let userAgent = navigator.userAgent;
  let browserName = ''
  const matchPatern = browserMatches.find((matchPatern: RegExp) => {
    if (userAgent.match(matchPatern)) {
      return matchPatern
    }
  })
  if (matchPatern) {
    const index = browserMatches.indexOf(matchPatern)
    browserName = browsersList[index]
  }
  return browserName
}