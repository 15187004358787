import Auth from "@aws-amplify/auth";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import * as t from "io-ts";
import { urlsAws } from "../../../../resources/foo/api-endpoints";
import { fetchGetBenefitListCodec, fetchGetCategoryListCodec, ResponseBenefitList, ResponseCategoryList, fetchPostBenefitCodec, PostBenefit, bodyPostCategoryBenefit, GetParamsBenefit, fetchGetParamsBenefitCodec, fetchGetBenefitByIDCodec, GetBenefitByIDProps, bodyPostBenefits, fetchGetBannersCodec, GetBanner, GetBenefitCard, fetchGetBenefitCardCodec, GetPhotoBenefit, fetchGetPhotoBenefitCodec, PhotoBenefitProps, GetBannerHome, fetchGetBannersCodecHome, GetFileBenefit, fetchGetFileBenefitCodec } from "../interfaces";
import { HttpRequestError, HttpContentTypeError, HttpResponseStatusError, HttpClientEnv, HttpError } from "../../../../services/apiServices/interfaces";
import { getJson, httpClientEnv, } from "../../../../services/apiServices/httpClient";
import * as RD from "@devexperts/remote-data-ts";
import { RemoteData } from "@devexperts/remote-data-ts";
import { Dispatch, SetStateAction } from "react";

const fetchData = async (url: string, method: string, body?: string) => {
    const auth = await Auth.currentSession();
    const requestOptions = {
        method,
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: auth.getIdToken().getJwtToken(),
            AccessToken: auth.getAccessToken().getJwtToken(),
        },
        body: body ? JSON.stringify(body) : null,
    };
    return await fetch(url, requestOptions).then((response) => response.json()).catch((error) => { console.log(error); return false; });
};

export const getItemsCache = async (url: string) => {
    const dataCache = await caches.match(url)
        .then((response) => {
            if (response) {
                return response.json();
            }
        })
        .then((data) => {
            if (data) {
                return data;
            }
            return false;
        })
        .catch(() => false);
    return dataCache;
}

export const handleCacheBenefits = async (key: string, data: RemoteData<HttpError, GetBenefitByIDProps> | RemoteData<HttpError, GetBanner> | RemoteData<HttpError, GetBannerHome> | RemoteData<HttpError, GetBenefitCard> | string | PhotoBenefitProps) => {
    const cache = await caches.open("api-cache");
    cache.put(key, new Response(JSON.stringify(data)));
};

export const getBenefitList = async () => {
    const params: string = ""
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: "GET",
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        ResponseBenefitList
    > = getJson(urlsAws.getBenefitList, params, requestOptions, fetchGetBenefitListCodec.decode)
    return response;
};

export const getCategoryList = async () => {
    const params: string = ""
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: "GET",
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        ResponseCategoryList
    > = getJson(urlsAws.getCategoryList, params, requestOptions, fetchGetCategoryListCodec.decode)
    return response;
};

export const crudCategoryBenefit = async (body: bodyPostCategoryBenefit, type: string) => {
    const params = "";
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: type,
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
        body: JSON.stringify(body)
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        PostBenefit
    > = getJson(urlsAws.crudCategoryBenefit, params, requestOptions, fetchPostBenefitCodec.decode)
    return response;
};

export const crudBenefit = async (body: bodyPostBenefits, type: string) => {
    const params = "";
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: type,
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
        body: JSON.stringify(body)
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        PostBenefit
    > = getJson(urlsAws.crudBenefit, params, requestOptions, fetchPostBenefitCodec.decode)
    return response;
};

export const getBenefitByID = async (idBenefit: number, view?: boolean) => {
    const paramView = view ? '&view=' + view : '';
    const params = `?id=${idBenefit}` + paramView;
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: "GET",
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        GetBenefitByIDProps
    > = getJson(urlsAws.crudBenefit, params, requestOptions, fetchGetBenefitByIDCodec.decode)
    return response;
};

export const getFileByID = async (idBenefit: number) => {
    const params = `?id=${idBenefit}`;
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: "GET",
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        GetFileBenefit
    > = getJson(urlsAws.getFileBenefit, params, requestOptions, fetchGetFileBenefitCodec.decode)
    return response;
};

export const getParamsBenefit = async () => {
    const params = "";
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        GetParamsBenefit
    > = getJson(urlsAws.getParamsBenefit, params, requestOptions, fetchGetParamsBenefitCodec.decode)
    return response;
};

export const getBanners = async () => {
    const params = "";
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        GetBanner
    > = getJson(urlsAws.getBannerBenefits, params, requestOptions, fetchGetBannersCodec.decode)
    return response;
};

export const getBannersHome = async () => {
    const params = "";
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        GetBannerHome
    > = getJson(urlsAws.getBannerBenefitsHome, params, requestOptions, fetchGetBannersCodecHome.decode)
    return response;
};

export const getBenefitCard = async (category?: number) => {
    const params = category ? `?category=${category}` : '';
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        GetBenefitCard
    > = getJson(urlsAws.getBenefitCard, params, requestOptions, fetchGetBenefitCardCodec.decode)
    return response;
};

export const likeBenefit = async (body: bodyPostBenefits, like: boolean) => {
    const likes = like ? '&like=' + like : '';
    const params = "?id=" + body.id + likes;
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
        body: JSON.stringify(body)
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        PostBenefit
    > = getJson(urlsAws.postLikeBenefit, params, requestOptions, fetchPostBenefitCodec.decode)
    return response;
};

export const getPhotoBenefit = async (ids: number[], banner?: boolean) => {
    const params = banner ? "?banner=" + banner : "";
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
        body: JSON.stringify({ ids }),
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        GetPhotoBenefit
    > = getJson(urlsAws.getPhotoBenefit, params, requestOptions, fetchGetPhotoBenefitCodec.decode)
    return response;
};

export const getExcelResumen = async (id: number, setStatusDownload: Dispatch<SetStateAction<number>>, refLoadClose: any) => {
    const data = await fetchData(`${urlsAws.getExcelBenefits}?id=${id}`, "GET");
    if (data && data.response && data.response.valid === 1) {
        const downloadLink = document.createElement("a");
        downloadLink.href = data.response.file;
        downloadLink.download = `Resumen_beneficio_${id}.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    } else {
        setStatusDownload(1)
    }
    setTimeout(() => {
        refLoadClose && refLoadClose.current && refLoadClose.current.click();
    }, 500)
};

export const getExcelAllResumen = async (setStatusDownload: Dispatch<SetStateAction<number>>, filterCountry: string, filterCategory: number, startDateFilter: string, endDateFilter: string, filterStatus: string, refLoadClose: any) => {
    const url = `${urlsAws.getExcelBenefits}?country=${filterCountry}&category=${filterCategory == 0 ? "" : filterCategory}&date_from=${startDateFilter}&date_to=${endDateFilter}&status=${filterStatus}`;
    const data = await fetchData(url, "GET");
    if (data && data.response && data.response.valid === 1) {
        const downloadLink = document.createElement("a");
        downloadLink.href = data.response.file;
        downloadLink.download = `Resumen_beneficios.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    } else {
        setStatusDownload(1)
    }

    setTimeout(() => {
        refLoadClose && refLoadClose.current && refLoadClose.current.click();
    }, 500)
};

export const existsPhotoIds = async (arrayIds: number[]) => {
    let newArrayEmpl = arrayIds.map(async (ph) => {
        const response = await caches.match(urlsAws.getPhotoBenefit + "?id=" + ph);
        if (!response) {
            return ph;
        }
        return null;
    });
    const results = await Promise.all(newArrayEmpl);
    return results.filter((ob) => ob !== null) as number[];
};

export const getRemotePhotosBenefits = async (arrayIds: number[], setRemoteData: Dispatch<SetStateAction<RemoteData<HttpError, GetPhotoBenefit>>>, setImgCard?: Dispatch<SetStateAction<string>>, banner?: boolean, setImgBanner?: Dispatch<SetStateAction<string>>) => {

    RTE.run(await getPhotoBenefit(arrayIds, banner), httpClientEnv)
        .then(E.fold(e => { return setRemoteData(RD.failure(e)) }, a => {
            let data = RD.success(a)
            if (data._tag === 'RemoteSuccess' && data.value.response.photo.length > 0) {
                data.value.response.photo.map((ph) => {
                    setImgCard && setImgCard(ph.img_card)
                    if (banner && setImgBanner && ph.img_banner) {
                        setImgBanner(ph.img_banner)
                    }
                    handleCacheBenefits(urlsAws.getPhotoBenefit + "?id=" + ph.id, ph);
                })
                return setRemoteData(RD.success(a));
            } else {
                return setRemoteData(RD.failure({ tag: 'httpRequestError', error: 'error GetBenefitCard' }));
            }
        }))
}

export const getRemoteAllBanners = async (setRemoteData: Dispatch<SetStateAction<RemoteData<HttpError, GetBanner>>>) => {
    setRemoteData(RD.pending)
    const bannerResponse = await getItemsCache(urlsAws.getBannerBenefits);
    if (bannerResponse) {
        setRemoteData(bannerResponse);
    } else {
        RTE.run(await getBanners(), httpClientEnv)
            .then(E.fold(e => { return setRemoteData(RD.failure(e)) }, a => {
                let data = RD.success(a)
                if (data._tag === 'RemoteSuccess' && data.value.response.banner.length > 0) {
                    handleCacheBenefits(urlsAws.getBannerBenefits, data)
                    return setRemoteData(RD.success(a));
                } else {
                    return setRemoteData(RD.failure({ tag: 'httpRequestError', error: 'error GetBanner' }));
                }
            }))
    }
};


export const getRemoteBannersHome = async (setRemoteData: Dispatch<SetStateAction<RemoteData<HttpError, GetBannerHome>>>) => {
    setRemoteData(RD.pending)
    const bannerResponse = await getItemsCache(urlsAws.getBannerBenefitsHome);
    if (bannerResponse) {
        setRemoteData(bannerResponse);
    } else {
        RTE.run(await getBannersHome(), httpClientEnv)
            .then(E.fold(e => { return setRemoteData(RD.failure(e)) }, a => {
                let data = RD.success(a)
                if (data._tag === 'RemoteSuccess' && data.value.response.banner.length > 0) {
                    handleCacheBenefits(urlsAws.getBannerBenefitsHome, data)
                    return setRemoteData(RD.success(a));
                } else {
                    return setRemoteData(RD.failure({ tag: 'httpRequestError', error: 'error GetBannerHome' }));
                }
            }))
    }
};

