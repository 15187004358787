import React, { FC } from 'react';
import { CarouselProps, DefaultCarouselProps, CarouselComponentProps } from './Helpers/interfaces/carousel';
import { Link } from 'react-router-dom';
import { base64ToArrayBuffer } from '../../services/utiles';

const Carousel: FC<CarouselComponentProps> = ({ id, itemsPerSlide, data: dataToDisplay }: CarouselComponentProps) => {

    // Agrupar los elementos para el carrusel
    const groupedItems = dataToDisplay.reduce((groups : CarouselProps[][], item : CarouselProps, index: number) => {
        const groupIndex = Math.floor(index / itemsPerSlide);
        if (!groups[groupIndex]) {
            groups[groupIndex] = [];
        }
        groups[groupIndex].push(item);
        return groups;
    }, []) as CarouselProps[][];

    return <>
        <div id={"carousel-"+id} className="carousel slide carousel-dark d-none d-md-block p-0" data-bs-ride="carousel">
            <div className="carousel-inner p-1">
                {groupedItems.map((group: CarouselProps[], groupIndex: number) => (
                    <div key={groupIndex} className={`carousel-item ${groupIndex === 0 ? 'active' : ''}`}>
                        <div className={`row row-cols-1 row-cols-md-${itemsPerSlide} g-4 ps-4`}>
                            {group.map((item: CarouselProps, itemIndex: number) => (
                                <div key={itemIndex} className="col d-flex p-2">
                                    {React.isValidElement(item) ? 
                                        item : 
                                        renderCarouselItem(item as DefaultCarouselProps[], "desktop")
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <button className="carousel-control-prev ms-3" type="button" data-bs-target={"#carousel-"+id} data-bs-slide="prev">
                <i className="fa fa-chevron-left color-dark" aria-hidden="true"></i>
                <span className="visually-hidden">Anterior</span>
            </button>
            <button className="carousel-control-next me-3" type="button" data-bs-target={"#carousel-"+id} data-bs-slide="next">
                <i className="fa fa-chevron-right color-dark" aria-hidden="true"></i>
                <span className="visually-hidden">Siguiente</span>
            </button>
        </div>
        <div id={"carousel-"+id+"-mobile"} className="carousel carousel-dark slide carousel-control-inner d-md-none" data-bs-ride="carousel">
            <div className="carousel-indicators m-0">
                {dataToDisplay.map((_: CarouselProps, indicatorIndex : number) => (
                    <button key={indicatorIndex}
                        type="button"
                        data-bs-target="#carousel-beneficios-mobile"
                        data-bs-slide-to={indicatorIndex}
                        className={indicatorIndex === 0 ? 'active' : ''}
                        aria-label={`Slide ${indicatorIndex + 1}`}>
                    </button>
                ))}
        
            </div>
            <div className="carousel-inner p-1">
                {
                    dataToDisplay.map((item: CarouselProps) => (
                        React.isValidElement(item) ? 
                            item : 
                            renderCarouselItem(item as DefaultCarouselProps[], "mobile")
                    ))
                }
            </div>
            {
                dataToDisplay.length !== itemsPerSlide &&
            <>
                <button className="carousel-control-prev ms-2 d-block" type="button" data-bs-target={"#carousel-"+id+"-mobile"} data-bs-slide="prev">
                    <i className="fa fa-chevron-left color-dark" aria-hidden="true"></i>
                    <span className="visually-hidden">Anterior</span>
                </button>
                <button className="carousel-control-next me-2 d-block" type="button" data-bs-target={"#carousel-"+id+"-mobile"} data-bs-slide="next">
                    <i className="fa fa-chevron-right color-dark" aria-hidden="true"></i>
                    <span className="visually-hidden">Siguiente</span>
                </button>
            </>
            }	
        </div>
    </>;
};


const renderCarouselItem = (elements: DefaultCarouselProps[], device : string) => {
    const truncateString = (str: string, maxLength: number) => str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
    return <div className="carousel-inner p-1">
        {
            device === "desktop" &&
                elements.map((item: DefaultCarouselProps) => (
                    <div className="col d-flex p-2">
                        <article className="d-flex flex-column box-card">
                            <div className="box-card__header pt-2 px-2">
                                <h2 className="h4 tit-section px-2">{item.subtitle}</h2>
                            </div>
                            <div className="box-card__img">
                                <img className="img-fluid carousel-img" src={URL.createObjectURL(new Blob([base64ToArrayBuffer(item.image)]))} alt={item.title}/>
                            </div>
                            <div className="box-card__body d-flex flex-column flex-grow-1 p-3">
                                {item.title && <h3><Link className="color-dark" to="">{item.title}</Link></h3>}
                                {item.description && <p>{truncateString(item.description, 100)}</p>}
                                <div className="text-end mt-auto">
                                    <Link className="btn btn-degradado" to={item.url}>Ver más <i className="fa fa-chevron-right" aria-hidden="true"></i></Link>
                                </div>
                            </div>
                        </article>
                    </div>
                ))
        }
        {
            device === "mobile" &&
                elements.map((item : DefaultCarouselProps, itemIndex : number) => (
                    <div className={`carousel-item ${ itemIndex === 0 ? 'active' : undefined}`} >
                        <div className="row row-cols-1 row-cols-md-2 row-cols-xl-2 row-cols-xxl-4 g-4 ps-4">
                            <div className="col p-2">
                                <article className="d-flex box-card flex-column w-100">
                                    <div className="box-card__header pt-2 px-2">
                                        <h2 className="h4 tit-section p-2">{item.title}</h2>
                                    </div>
                                    <div className="box-card__img">
                                        <img className="img-fluid w-100 carousel-img" src={URL.createObjectURL(new Blob([base64ToArrayBuffer(item.image)]))} alt={item.title}/>
                                    </div>
                                    <div className="box-card__body flex-grow-1 p-3">
                                        <h3><Link className="color-dark" to="">{item.subtitle}</Link></h3>
                                        <p>{item.description}</p>
                                        <div className="text-end mt-auto">
                                            <Link className="btn btn-degradado" to={item.url}>Ver más <i className="fa fa-chevron-right" aria-hidden="true"></i></Link>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                ))
        }
    </div>
}


export default Carousel;
