import { Children, FC } from "react";
import { SelectGroupProps } from "../../../../../../utilities/interfaces";

const SelectGroup: FC<SelectGroupProps> = ({ arrayGroup, setArrayGroup, group }) => {

    const handleAddGroup = () => {
        setArrayGroup([...arrayGroup, { id: 0, title: '' }]);
    };

    const AddGroups = (indice: number, value: string) => {
        let dataGroup = group.find((g) => g.id == Number(value));
        if (dataGroup) {
            const updatedArreglo = [...arrayGroup];
            updatedArreglo[indice] = { ...dataGroup };
            setArrayGroup(updatedArreglo);
        }
    };

    const DeleteGroup = (indice: number) => {
        const updatedArreglo = arrayGroup.filter((_, index) => index !== indice).map(obj => ({ ...obj }));
        setArrayGroup(updatedArreglo);
    };

    return (
        <>
            <div className="tit-group d-flex justify-content-between align-items-center mt-4" id="divGroups">
                <h3 className="h5 fw-bold m-0">Seleccione los grupos que podrán ver esta noticia</h3>
                <a className="mt-2 btnLink" onClick={() => { handleAddGroup() }}>
                    <i className="fa fa-plus-square-o fa-lg fa-fw" title="Añadir grupo" aria-hidden="true"></i></a>
            </div>

            <div className="mt-3 p-3 bg-light-2 rounded-4 mb-4">
                <table className="table table-section table-borderless align-middle d-none d-md-table text-uppercase">
                    <thead>
                        <tr>
                            <th className="col-6">Grupo *</th>
                            <th className="col-3 text-center">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>

                        {Children.toArray(arrayGroup.map((element, index) => {
                            if (element) {
                                return (
                                    <>
                                        <tr key={index}>
                                            <td>
                                                <div>
                                                    <select
                                                        id={"inputState" + element.id}
                                                        className="form-select"
                                                        defaultValue={element.id}
                                                        disabled={element.disabled}
                                                        onChange={(e) => {
                                                            AddGroups(index, e.target.value)
                                                        }}
                                                    >
                                                        <option value=""> {element.disabled ? element.title : 'Seleccione Grupo'} </option>

                                                        {element.id === 0 && group.length > 0 ? (
                                                            Children.toArray(group.map((name, ind) => (
                                                                <option
                                                                    key={"array1" + ind}
                                                                    value={name.id}
                                                                >
                                                                    {name.title}
                                                                </option>
                                                            ))
                                                            )) : !element.disabled && (
                                                                Children.toArray(group.map((name, ind) => (
                                                                    <>
                                                                        <option
                                                                            key={"array1" + ind}
                                                                            value={name.id}
                                                                            selected={name.id == element.id}>
                                                                            {name.title}
                                                                        </option>
                                                                    </>
                                                                ))
                                                                ))}
                                                    </select>
                                                    {element.disabled &&
                                                        <div style={{ display: "block", textTransform: 'none' }} className="invalid-feedback">{'Este grupo ha sido eliminado, al momento de guardar, se eliminará de la noticia.'}</div>
                                                    }
                                                </div>

                                            </td>
                                            <td className="text-center">
                                                <a
                                                    id={"trash" + index}
                                                    className="btnLink"
                                                    onClick={(e) => { DeleteGroup(index) }}>
                                                    <i className="fa fa-trash-o fa-fw me-2" title="Eliminar" aria-hidden="true"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </>
                                )
                            }
                        }))}
                    </tbody>
                </table>

                <div className="data-list-box data-list-box-align-middle data-list-box-form d-md-none">
                    {Children.toArray(
                        arrayGroup.map((element, index) => {
                            if (element) {
                                return (
                                    <ul className="list-column-1 list-unstyled" key={index}>
                                        <li>
                                            <span className="data-list-box__title">
                                                Grupo
                                            </span>
                                            <span className="data-list-box__text">
                                                <select
                                                    id={"inputState" + element.id}
                                                    className="form-select"
                                                    defaultValue={element.id}
                                                    disabled={element.disabled}
                                                    onChange={(e) => {
                                                        AddGroups(index, e.target.value)
                                                    }}>
                                                    <option value=""> {element.disabled ? element.title : 'Seleccione Grupo'} </option>
                                                    {element.id === 0 && group.length > 0 ? (
                                                        Children.toArray(group.map((name, ind) => (
                                                            <option
                                                                key={"array1" + ind}
                                                                value={String(name.id)}>
                                                                {name.title}
                                                            </option>
                                                        ))
                                                        )) : (
                                                        Children.toArray(group.map((name, ind) => (
                                                            <option
                                                                key={"array1" + ind}
                                                                value={String(name.id)}
                                                                selected={name.id == element.id}>
                                                                {name.title}
                                                            </option>
                                                        ))

                                                        ))}
                                                </select>
                                                {element.disabled &&
                                                    <div style={{ display: "block", textTransform: 'none' }} className="invalid-feedback">{'Este grupo ha sido eliminado, al momento de guardar, se eliminará de la noticia.'}</div>
                                                }
                                            </span>
                                        </li>
                                        <li>
                                            <span className="data-list-box__title">
                                                Acciones
                                            </span>
                                            <span className="data-list-box__text">
                                                <a
                                                    id={"trash" + index}
                                                    onClick={(e) => { DeleteGroup(index) }}>
                                                    <i className="fa fa-trash-o fa-fw me-2" aria-hidden="true"></i>
                                                </a>
                                            </span>
                                        </li>
                                    </ul>
                                )
                            }
                        })
                    )}
                </div>
            </div>
        </>
    )
}

export default SelectGroup