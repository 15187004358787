import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate';
import { PaginatorProps } from './interfaces';


const Paginator = (props: PaginatorProps) => {
    const { itemsPerPage, totalPages, updateListToShow } = props
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPosition, setLastPosition] = useState(itemsPerPage)

    useEffect(() => {
        const fragment = evaluateSectionToShow()
        updateListToShow(fragment)
    }, [currentPage])

    const evaluateSectionToShow = () => {
        let fragment = { start : 0, end: itemsPerPage }
        if (currentPage !== 1) {
            const endIndex = itemsPerPage * currentPage
            fragment = { start : getStartPosition(endIndex), end: endIndex }
            setLastPosition(endIndex)
        } else {
            setLastPosition(itemsPerPage)
        }
        return fragment
    }

    const getStartPosition = (endIndex: number): number => {
        const isPreviousPage = lastPosition > endIndex
        if(isPreviousPage) {
            return lastPosition - endIndex
        } else {
            return lastPosition
        }
    }

    const handlePageClick = (event: { selected: number; }) => {
        setCurrentPage(event.selected + 1)
    };

  return (
        <ReactPaginate
            className="pagination justify-content-center align-items-center"
            breakLabel=" ... "
            nextLabel=" > "
            onPageChange={(event) => handlePageClick(event)}
            pageRangeDisplayed={0}
            marginPagesDisplayed={1}
            pageCount={totalPages}
            previousLabel=" < "
            renderOnZeroPageCount={() => null}
            previousLinkClassName={"page-link"}
            nextLinkClassName={"page-link"}
            breakLinkClassName={"page-link"}
            pageLinkClassName={"page-link"}
            pageClassName={"page-item"}
            breakClassName={"page-item"}
            previousClassName={"page-item"}
            nextClassName={"page-item"}
            activeClassName={"page-item active"}
            activeLinkClassName={"page-link active"}
            />
  );
}

export default Paginator;
