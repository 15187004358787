import React, { useState, useEffect } from "react";
import axios from "axios";
import Auth from "@aws-amplify/auth";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import styles from "../../resources/css/style-COL.module.css";

const ExtractoCredito = () => {
    const { register, handleSubmit, control, reset, setValue, formState: { errors } } = useForm();
    const [loader, setLoader] = useState(false);
    
    const resetValues = () => {
      setValue("fechaInicial", "")
          setValue("fechaFinal", "")
    }
    const onSubmit = async (data) => {
      setLoader(true);
        if (data.fechaInicial > data.fechaFinal)
        {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
                html: `<div style="text-align: center; font-size: 15px"> La fecha final no puede ser menor a la inicial.</div>`,
                confirmButtonColor: '#0169b5',
              })
              setLoader(false);
              return
        }

        if (new Date(data.fechaFinal) > new Date())
        {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
                html: `<div style="text-align: center; font-size: 15px"> La fecha final no puede ser mayor al dia actual.</div>`,
                confirmButtonColor: '#11427C',
              })
              setLoader(false);
              return
        }

        if (new Date(data.fechaFinal) > new Date())
        {
            Swal.fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
                html: `<div style="text-align: center; font-size: 15px"> La fecha final no puede ser mayor al dia actual.</div>`,
                confirmButtonColor: '#11427C',
              })
              setLoader(false);
              return
        }

        const auth = await Auth.currentSession();
        const head = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
        axios.get(`${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/extractocredito`, {
          headers: head,
          params: data
        }, )
        .then( response => {
          let pdf = {
            file: `data:application/pdf;base64,${response.data}`,
            file_name: "ExtractoPago"
          }
              const pdfLink = `${pdf.file}`;
              const anchorElement = document.createElement('a');
              const fileName = `${pdf.file_name}.pdf`;
              anchorElement.href = pdfLink;
              anchorElement.download = fileName;
              anchorElement.click();
              resetValues()
              setLoader(false);
        })
        .catch( response => {
          Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
            html: `<div style="text-align: center; font-size: 15px"> ERROR!.</div>`,
            confirmButtonColor: '#11427C',
          }).then(() => {
            resetValues()
          })
          setLoader(false);
          return
        });    
      };
    

  return (
    <>
      <div  className={styles["content-box"]}>
        <nav className={`${styles["breadcrumbs"]} ${styles["d-none"]} ${styles["d-lg-block"]}`} aria-label="breadcrumb">
          <ol className={styles["breadcrumb"]}>
            <li className={styles["breadcrumb-item"]}>
              <Link to="/inicio">
                <i className="fa fa-home" aria-hidden="true"></i> Inicio
              </Link>
            </li>
            <li className={styles["breadcrumb-item"]}>
              Opciones de Crédito
            </li>
            <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">
              Extracto de Crédito de Empleados
            </li>
          </ol>
        </nav>

        <section className={styles["extracto-de-credito-de-empleados"]}>
        <h1>Extracto de Crédito de Empleados</h1>
     {
      loader &&
      <div data-testid="loading" className={styles["full-spinner"]}>
            <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
        </div>
    }
    {
      loader == false &&

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${styles["box"]} ${styles["mb-4"]}`}>
              <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Detalle</h2>

              <div className={`${styles["data-list-box"]} ${styles["data-list-box-align-middle"]} ${styles["data-list-box-md-odd"]}`}>
                <ul className={styles["list-unstyled"]}>
                  <li>
                    <span className={`${styles["data-list-box__title"]} ${styles["col-md-4"]}`}>
                      Fecha Inicial
                    </span>
                    <div>
                       <span className={styles["data-list-box__text"]}>
                      <div className={styles["input-group"]}>
                        <input
                         {...register("fechaInicial", {
                            required: "Ingrese fecha",
                            
                          })}

                          className={styles["form-control"]}
                          type="date"
                          placeholder="dd/mm/aaaa"
                        />
                        
                      </div>
                    </span>
                      <small className={styles["form-error-message"]}>
                          {errors.fechaInicial?.message}
                        </small>
                    </div>
                   
                  </li>
                  <li>
                    <span className={`${styles["data-list-box__title"]} ${styles["col-md-4"]}`}>
                      Fecha Final
                    </span>
                    <div>
                    <span className={styles["data-list-box__text"]}>
                      <div className={styles["input-group"]}>
                        <input
                         {...register("fechaFinal", {
                            required: "Ingrese fecha",
                            
                          })}

                          className={styles["form-control"]}
                          type="date"
                          placeholder="dd/mm/aaaa"
                        />
                        
                     
                      </div>
                    </span> 
                    <small className={styles["form-error-message"]}>
                          {errors.fechaFinal?.message}
                        </small>
                        </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mt-4"]}`}>
              <div className={`${styles["col-12"]} ${styles["col-md-auto"]}`}>
                <button type="submit" className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["w-100"]}`}>
                  Generar{" "}
                  <i
                     className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
            </div>
          </form>
      }
        </section>
      </div>
    </>
  );
};
export default ExtractoCredito;
