import React, { FC } from "react";

const ModalAnexo: FC<any> = ({ getConflicto }) => {
    return <>
        {/*Modal anexo */}
        <div className="modal fade" id="anexo" tabIndex={-1} aria-labelledby="anexo" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                    </div>
                    <div className="modal-body text-center py-4">
                        <h2 className="h5">Recordar que esto generará un anexo y quedará guardado en su carpeta digital.</h2>
                    </div>
                    <div className="modal-footer border-0 pt-0 pb-5 justify-content-center">
                        <button type="button" className="btn btn-degradado col-12 col-md-auto" data-bs-dismiss="modal" onClick={() => { getConflicto() }}>Aceptar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <a href="#" id="modal-anexo" hidden data-bs-toggle="modal" data-bs-target="#anexo" data-bs-backdrop="static" data-bs-keyboard="false"/>
    </>
}   

export default ModalAnexo;