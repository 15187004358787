import { FC } from "react";
import DynamicSelect from "../../../../../commons/DynamicSelect";
import { SelectIndexProps } from "../../../../../utilities/interfaces";

const SelectIndex: FC<SelectIndexProps> = ({
    filters,
    setFilterCountry,
    setStartDateFilter,
    setEndDateFilter,
    setFilterStatus,
    setFilterCategory,
    pais,
    filterCountry
}) => {

    return (
        <>
            <div className="row mb-1 w-auto">
                <div className="col-md">
                    <label className="form-label">País</label>
                    <div className="input-group mb-3">
                        {filters.countryFilters.length > 0 ?
                            <>
                                <DynamicSelect arrayData={filters.countryFilters} setStatus={setFilterCountry} isLabel={false} pais={pais} />
                            </>
                            :
                            <>
                                <select className="form-select" disabled>
                                    <option selected disabled>Seleccione</option>
                                </select>
                            </>
                        }
                    </div>
                </div>
                <div className="col-md">
                    <label className="form-label">Categoría</label>
                    <div className="input-group mb-3">
                        {filters.categoryFilters.length > 0 ?
                            <>
                                <DynamicSelect arrayData={filters.categoryFilters.filter((category) => category.country_id === filterCountry)} setStatus={setFilterCategory} isLabel={false} isRequired={false} />
                            </>
                            :
                            <>
                                <select className="form-select" disabled>
                                    <option selected disabled>Seleccione</option>
                                </select>
                            </>
                        }
                    </div>
                </div>
                <div className="col-md">
                    <label className="form-label">Vigencia Desde</label>
                    <div className="d-flex align-items-center">
                        <input type="date" placeholder=""
                            className="form-control"
                            defaultValue={""}
                            onChange={(e) => { setStartDateFilter(e.target.value); }} />
                    </div>
                </div>
                <div className="col-md">
                    <label className="form-label">Vigencia Hasta</label>
                    <div className="d-flex align-items-center">
                        <input type="date" placeholder=""
                            className="form-control"
                            defaultValue={""}
                            onChange={(e) => { setEndDateFilter(e.target.value); }}
                        />
                    </div>
                </div>
                <div className="col-md">
                    <label className="form-label">Estado de Publicación</label>
                    <div className="input-group mb-3">
                        {filters.statusFilter.length > 0 ?
                            <>
                                <DynamicSelect arrayData={filters.statusFilter} setStatus={setFilterStatus} isLabel={true} isRequired={false} />
                            </>
                            :
                            <>
                                <select className="form-select" disabled>
                                    <option selected disabled>Seleccione</option>
                                </select>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default SelectIndex;