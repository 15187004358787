import Auth from "@aws-amplify/auth";
import { urlsAws } from "../resources/foo/api-endpoints";
import { decrypt, formatDate } from "./utiles";

const emplEvalService = {
    getemplEvalTable: (emplid, setData, setStatus) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.getEvaluacionEmpleado, requestOptions)
                .then((response) => response.json())
                .then((data) => data.response)
                .then((data) => {
                    if (data != undefined) {
                        if (data.cs_evaluaciones_rp != undefined) {
                            if (!Array.isArray(data.cs_evaluaciones_rp)) {
                                data.cs_evaluaciones_rp = [data.cs_evaluaciones_rp];
                            }
                            caches.open("api-cache").then((cache) => {
                                let dataEva = data.cs_evaluaciones_rp
                                    .map((evaluacion) => {
                                        evaluacion.c_review_dt = formatDate(decrypt(evaluacion.c_review_dt));
                                        return evaluacion;
                                    })
                                    .sort(function (a, b) {
                                        return b.c_review_dt - a.c_review_dt;
                                    })
                                    .reverse();
                                data.cs_evaluaciones_rp = dataEva;
                                cache.put(urlsAws.getEvaluacionEmpleado + "?emplid=" + emplid, new Response(JSON.stringify(data.cs_evaluaciones_rp)));
                                setData(data.cs_evaluaciones_rp);
                                setStatus(true);
                            });
                        } else {
                            throw new Error("error 429");
                        }
                    } else {
                        throw new Error("error 429");
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    caches.match(urlsAws.getEvaluacionEmpleado + "?emplid=" + emplid).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                setData(data);
                            });
                        } else {
                            setData(1);
                            setStatus("No hay registro para mostrar");
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },
};
export default emplEvalService;
