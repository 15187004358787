import Auth from "@aws-amplify/auth";
import { urlsAws } from "../resources/foo/api-endpoints";

const documentoService = {
    getDocumentType: (setDocument, company, docType) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken(),
                },
            };
            fetch(urlsAws.getDocumentList  + '?company=' + company + '&doctype=' + docType, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.message !== undefined) setDocument("error")
                    else setDocument(data.response.CN_RESULT_RP);
                })
                .catch((error) =>{
                    console.log("error", error);
                });
        }).catch((err) => console.log(err));
    },
};

export default documentoService;