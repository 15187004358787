import React, { useEffect, useState } from "react";
import List from "./list";
import Form from "./form";
import ListFields from "./list-fields";
import FormFields from "./form-fields";
import CertificadoIngresosRetencionesServices from "../../services/certificado-ingresos-retenciones/service";
import styles from "../../resources/css/style-COL.module.css";
import { Link } from 'react-router-dom'

const CertificadoIngresosRetenciones = () => {
  const [idCertificate, setIdCertificate] = useState(null);

  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isConfiguring, setIsConfiguring] = useState(false);
  const [isAddingField, setIsAddingField] = useState(false);
  const [isEditingField, setIsEditingField] = useState(false);

  const [certificates, setCertificates] = useState([]);
  const [isLoadingCertificates, setIsLoadingCertificates] = useState(false);

  const [certificate, setCertificate] = useState({});
  const [certificateName, setCertificateName] = useState("");
  const [isLoadingCertificate, setIsLoadingCertificate] = useState(false);

  const [idField, setIdField] = useState(0);
  const [fields, setFields] = useState([]);
  const [isLoadingFields, setIsLoadingFields] = useState(false);
  const [field, setField] = useState([]);
  const [isLoadingField, setIsLoadingField] = useState(false);

  const [colors, setColors] = useState([]);
  const [fonts, setFonts] = useState([]);
  const [aligns, setAligns] = useState([]);

  const getCertificates = async () => {
    try {
      setIsLoadingCertificates(true);

      const response =
        await CertificadoIngresosRetencionesServices.getCertificates();

      if (Array.isArray(response)) {
        setCertificates(response);
      }

      const responseFonts =
        await CertificadoIngresosRetencionesServices.getFonts();

      if (Array.isArray(responseFonts)) {
        setFonts(responseFonts);
      }

      const responseColors =
        await CertificadoIngresosRetencionesServices.getColors();

      if (Array.isArray(responseColors)) {
        setColors(responseColors);
      }

      const responseAligns =
        await CertificadoIngresosRetencionesServices.getAligns();

      if (Array.isArray(responseAligns)) {
        setAligns(responseAligns);
      }

      setIsLoadingCertificates(false);
    } catch (error) {
      setIsLoadingCertificates(false);
    }
  };

  const getCertificate = async (id) => {
    try {
      setIsLoadingCertificate(true);

      const response =
        await CertificadoIngresosRetencionesServices.getCertificate(id);

      if (response !== null) {
        setCertificate(response);
      }

      setIsLoadingCertificate(false);
    } catch (error) {
      setIsLoadingCertificate(false);
    }
  };

  const getFields = async (id) => {
    try {
      setIsLoadingFields(true);

      const response = await CertificadoIngresosRetencionesServices.getFields(
        id
      );

      if (Array.isArray(response)) {
        setFields(response);
      }

      setIsLoadingFields(false);
    } catch (error) {
      setIsLoadingFields(false);
    }
  };

  const getField = async (id) => {
    try {
      setIsLoadingField(true);

      const response = await CertificadoIngresosRetencionesServices.getField(
        id
      );

      if (response !== null) {
        setField(response);
      }

      setIsLoadingField(false);
    } catch (error) {
      setIsLoadingField(false);
    }
  };

  useEffect(() => {
    getCertificates();
  }, []);

  useEffect(() => {
    if (
      idCertificate !== null &&
      idCertificate !== undefined &&
      idCertificate !== 0
    ) {
      getFields(idCertificate);
    }
  }, [idCertificate]);

  return (
    <div className={styles["cencosud-col"]}>
      {/* <!-- inicio breadcrumbs -->   */}
      <nav
        className={
          styles["breadcrumbs"] +
          " " +
          styles["d-none"] +
          " " +
          styles["d-lg-block"]
        }
        aria-label="breadcrumb"
      >
        <ol className={styles.breadcrumb}>
          <li className={styles["breadcrumb-item"]}>
            <Link to="/inicio">
              <i className="fa fa-home" aria-hidden="true"></i> Inicio
            </Link>
          </li>
          <li className={styles["breadcrumb-item"]}>
          Transacciones
          </li>
          {(isAdding ||
            isEditing ||
            isConfiguring ||
            isAddingField ||
            isEditingField) && (
            <li className={styles["breadcrumb-item"]}>
              Certificado de Ingresos y Retenciones (conf.)</li>
          )}
          {!isAdding &&
            !isEditing &&
            !isConfiguring &&
            !isAddingField &&
            !isEditingField && (
              <li className={styles["breadcrumb-item"] + " " + styles["active"]}>
                <a>Certificado de Ingresos y Retenciones (conf.)</a>
              </li>
            )}
          {isAdding && (
            <li
              className={styles["breadcrumb-item"] + " " + styles["active"]}
              aria-current="page"
            >
              Agregar Certificado
            </li>
          )}
          {isEditing && (
            <li
              className={styles["breadcrumb-item"] + " " + styles["active"]}
              aria-current="page"
            >
              Editar Certificado
            </li>
          )}
          {isConfiguring && (
            <li className={styles["breadcrumb-item"] + " " + styles["active"]}>
              <a>Configurar Campos</a>
            </li>
          )}
          {isAddingField && (
            <li
              className={styles["breadcrumb-item"] + " " + styles["active"]}
              aria-current="page"
            >
              Agregar Campo
            </li>
          )}
          {isEditingField && (
            <li
              className={styles["breadcrumb-item"] + " " + styles["active"]}
              aria-current="page"
            >
              Editar Campo
            </li>
          )}
        </ol>
      </nav>
      {/* <!-- fin breadcrumbs --> */}

      {/* <!-- inicio Certificado de Ingresos y Retenciones --> */}
      <section className={styles["certificados-ingresos-retenciones"]}>
        <h1>Certificado de Ingresos y Retenciones</h1>

        {isLoadingCertificates ? (
          <div data-testid="loading" className={styles["full-spinner"]}>
            <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
          </div>
        ) : null}

        {!isConfiguring && !isLoadingCertificates ? (
          <List
            certificates={certificates}
            setIsAdding={setIsAdding}
            setIsEditing={setIsEditing}
            setIsConfiguring={setIsConfiguring}
            setIdField={setIdField}
            setIdCertificate={setIdCertificate}
            getCertificate={getCertificate}
            setCertificate={setCertificate}
            setCertificateName={setCertificateName}
          />
        ) : null}

        {isAdding || isEditing ? (
          <Form
            isAdding={isAdding}
            isEditing={isEditing}
            setIsAdding={setIsAdding}
            setIsEditing={setIsEditing}
            colors={colors}
            fonts={fonts}
            getCertificates={getCertificates}
            idCertificate={idCertificate}
            isLoadingCertificate={isLoadingCertificate}
            certificate={certificate}
          />
        ) : null}

        {isLoadingFields ? (
          <div data-testid="loading" className={styles["full-spinner"]}>
            <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
          </div>
        ) : null}

        {isConfiguring && !isLoadingFields && !isLoadingCertificates ? (
          <ListFields
            setIsAddingField={setIsAddingField}
            setIsEditingField={setIsEditingField}
            fields={fields}
            isAddingField={isAddingField}
            isEditingField={isEditingField}
            setIsConfiguring={setIsConfiguring}
            setIdField={setIdField}
            setIdCertificate={setIdCertificate}
            getField={getField}
            setField={setField}
            certificateName={certificateName}
            colors={colors}
            fonts={fonts}
          />
        ) : null}

        {isAddingField || isEditingField ? (
          <FormFields
            setIsConfiguring={setIsConfiguring}
            setIsEditingField={setIsEditingField}
            isAddingField={isAddingField}
            isEditingField={isEditingField}
            setIsAddingField={setIsAddingField}
            setIsEditing={setIsEditing}
            colors={colors}
            fonts={fonts}
            aligns={aligns}
            getFields={getFields}
            idField={idField}
            isLoadingField={isLoadingField}
            field={field}
            idCertificate={idCertificate}
            certificate={certificate}
          />
        ) : null}
      </section>
    </div>
  );
};

export default CertificadoIngresosRetenciones;
