import Auth from "@aws-amplify/auth";
import { urlsAws } from "../resources/foo/api-endpoints";
import { decrypt } from "./utiles";

const tiempoService = {
    getBeneficios: (setData) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.getBeneficiosTiempo , requestOptions)
                .then((response) => response.text())
                .then((result) => result.replaceAll(",}", "}"))
                .then((result) => JSON.parse(result))
                .then((data) => {
                    if (data) {
                        if (data.response) {
                            caches.open("api-cache").then((cache) => {
                                cache.put(urlsAws.getBeneficiosTiempo, new Response(JSON.stringify(data)));
                                setData(data);
                            });
                        } else {
                            setData(2);
                        }
                    } else {
                        setData(2);
                    }
                })
                .catch((error) => {
                    setData(2);
                });
        }).catch((err) => console.log(err));
    },
    getResumen: (setData, emplid) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.getResumenTiempo , requestOptions)
                .then((response) => response.text())
                .then((result) => result.replaceAll(",}", "}"))
                .then((result) => JSON.parse(result))
                .then((data) => {
                    if (data) {
                        if (data.response) {
                            setData(data.response);
                        } else {
                            setData(2);
                        }
                    } else {
                        setData(2);
                    }
                })
                .catch((error) => {
                    setData(2);
                });
        }).catch((err) => console.log(err));
    },
    solicitarBeneficio: (emplid, idBeneficio, fechaPermiso, jornadaBen, setData) => {
        Auth.currentSession().then((auth) => {
            const body = JSON.stringify({ response: { rut: emplid, id_beneficio: idBeneficio, fecha_permiso: fechaPermiso, jornada: jornadaBen } });
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: body,
            };
            fetch(urlsAws.solicitarBeneficio , requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data) {
                        if (data.response) {
                            setData(data.response);
                        } else {
                            setData(2);
                        }
                    } else {
                        setData(2);
                    }
                })
                .catch((error) => {
                    setData(2);
                });
        }).catch((err) => console.log(err));
    },
    getHistorial: (setData) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.getHistorial , requestOptions)
                .then((response) => response.text())
                .then((result) => result.replaceAll(",}", "}"))
                .then((result) => JSON.parse(result))
                .then((data) => {
                    if (data) {
                        if (data.response) {
                            if (data.response.historial !== undefined && !Array.isArray(data.response.historial)) {
                                data.response.historial = [data.response.historial];
                            }
                           
                            setData(data.response);
                        } else {
                            setData(2);
                        }
                    } else {
                        setData(2);
                    }
                })
                .catch((error) => {
                    setData(2);
                });
        }).catch((err) => console.log(err));
    },
    getEquipo: (setData) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.getEquipo , requestOptions)
                .then((response) => response.text())
                .then((result) => result.replaceAll(",}", "}"))
                .then((result) => JSON.parse(result))
                .then((data) => {
                    if (data) {
                        if (data.response) {
                            if (data.response.subalternos !== undefined && !Array.isArray(data.response.subalternos)) {
                                data.response.subalternos = [data.response.subalternos];
                            } 
                            setData(data.response);
                        } else {
                            setData(2);
                        }
                    } else {
                        setData(2);
                    }
                })
                .catch((error) => {
                    setData(2);
                });
        }).catch((err) => console.log(err));
    },
    anularTiempo: (emplid, numeroSoli, fechaPermiso, setData) => {
        Auth.currentSession().then((auth) => {
            const body = JSON.stringify({ request: { rut: emplid, nro_solicitud: numeroSoli, fecha_permiso: fechaPermiso } });
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: body,
            };
            fetch(urlsAws.anularTiempo , requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data) {
                        if (data.response) {
                            setData(data.response);
                        } else {
                            setData(2);
                        }
                    } else {
                        setData(2);
                    }
                })
                .catch((error) => {
                    setData(2);
                });
        }).catch((err) => console.log(err));
    },
    aprobarTiempo: (emplid, numeroSoli, fechaPermiso, setData) => {
        Auth.currentSession().then((auth) => {
            const body = JSON.stringify({ request: { rut: emplid, nro_solicitud: numeroSoli, fecha_permiso: fechaPermiso } });
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: body,
            };
            fetch(urlsAws.aprobarTiempo , requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data) {
                        if (data.response) {
                            setData(data.response);
                        } else {
                            setData(2);
                        }
                    } else {
                        setData(2);
                    }
                })
                .catch((error) => {
                    setData(2);
                });
        }).catch((err) => console.log(err));
    },
};

export default tiempoService;
