import React from "react";
import PropTypes from 'prop-types';

const Modales = {
    ModalBasico: (props) => {
        return (
            <>
                <button hidden id={props.idBtn} data-bs-toggle="modal" data-bs-target={"#" + props.target} />
                <div className="modal fade" id={props.idModal} tabIndex="-1" aria-labelledby="permiso-rut" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header border-0 pb-0">
                                <button type="button" id={props.modalCierre} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-center py-4">
                                <i className={props.icon} aria-hidden="true"></i>
                                <h2 className="h5">{props.msj}</h2>
                                <p>{props.p}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    },

    ModalNoPermiso: (props) => {
        return (
            <>
                <button hidden id={props.idBtnOpen} data-bs-toggle="modal" data-bs-target="#permiso-rut"  />
                <div className="modal fade" id="permiso-rut" tabindex="-1" aria-labelledby="permiso-rut" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header border-0 pb-0">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-center py-4">
                                <h2 className="h5">No tiene permisos para consultar ese RUT</h2>
                            </div>
                            <div className="modal-footer border-0 pt-0 justify-content-center pb-5">
                                <button type="button" className="btn btn-degradado" data-bs-dismiss="modal">Aceptar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

Modales.ModalBasico.propTypes = {
    idBtn: PropTypes.any,
    target: PropTypes.any,
    icon: PropTypes.any,
    modalCierre: PropTypes.any,
    msj: PropTypes.any,
    p: PropTypes.any,
    idModal: PropTypes.any,
}

Modales.ModalNoPermiso.propTypes = {
    idBtnOpen: PropTypes.any,
}


export default Modales;