import React, { Children, Dispatch, SetStateAction, useEffect, useState } from "react";
import { getRemoteAllBanners, getNewsCard, getItemsCache, getRemotePhotosNewsList, existsPhotoIds, handleCacheNewsList } from "../../utilities/apiServices/apiRequest";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import * as RD from "@devexperts/remote-data-ts";
import { RemoteData } from "@devexperts/remote-data-ts";
import { httpClientEnv } from "../../../../services/apiServices/httpClient";
import { HttpError } from "../../../../services/apiServices/interfaces";
import { SpinerFullScreen } from "../../../commonComponents/SpinerFullScreen";
import { base64ToArrayBuffer, scrollToTop, useCarousel } from "../../../../services/utiles";
import { newsListCards, categories, GetBanner, GetNewsCard, GetPhotoNews } from "../../utilities/interfaces";
import { Link, useNavigate } from "react-router-dom";
import { BreadCrumbsDinamic } from "../../../commonComponents/BreadCrumbsDinamic";
import { urlsAws } from "../../../../resources/foo/api-endpoints";
import Slider from "../../../commonComponents/Slider";

const transformDataForSlider = (data: categories[], filterCategory: number, setFilterCategory: Dispatch<SetStateAction<number>>) => {
    return data.map((category, i) => (
        <div key={i} className={`col mb-4 mb-lg-0 p-0 ${data.length < 4 ? '' : data.length === 4 ? 'news-category-4' : data.length === 5 ? 'news-category-5' : 'news-category-6'}`}>
            <div className="icons-big icons-big-ad text-center btnLink text-decoration-none mb-2">
                <a onClick={(e) => {
                    if (filterCategory === category.id) {
                        setFilterCategory(0)
                    } else {
                        setFilterCategory(category.id)
                    }
                }} className={`text-decoration-none d-block ${filterCategory === category.id ? 'active' : ''}`}>
                    <span className="material-symbols-outlined fs-1">
                        <i className={`fa ${category.icon} fa-fw fa-sm`} aria-hidden="true"></i>
                    </span>
                    <span className="d-block mt-3 h4 color-dark fw-bold">{category.title}</span>
                </a>
            </div>
        </div>
    ));
};

const MyNewsList = () => {
    const [remoteDataBanners, setRemoteDataBanners] = useState<RemoteData<HttpError, GetBanner>>(RD.initial);
    const [remoteDataNewsCard, setRemoteDataNewsCard] = useState<RemoteData<HttpError, GetNewsCard>>(RD.initial);
    const [remoteDataPhoto, setRemoteDataPhoto] = useState<RemoteData<HttpError, GetPhotoNews>>(RD.initial);
    const [filterCategory, setFilterCategory] = useState(0);
    const [tempNews, setTempNews] = useState<newsListCards[]>([])
    const [allNewsList, setAllNewsList] = useState<newsListCards[]>([])
    const [allCategories, setAllCategories] = useState<categories[]>([]);
    const [cantInitialBenef, setCantInitialBenef] = useState<number>(0);
    const [sigPag, setSigPag] = useState<number>(7);
    const [wordEntered, setWordEntered] = useState('');
    const [searchResults, setSearchResults] = useState<newsListCards[]>([])
    const [idArrays, setIdsArray] = useState<{ id: number, photo: string | undefined }[]>([])

    const navigate = useNavigate();

    const setCurrentPhoto = async (temp: RemoteData<HttpError, GetNewsCard>, setTempNews: any) => {
        const newNewsList = await Promise.all(
            temp._tag === 'RemoteSuccess' ? temp.value.response.newsList.map(async (news) => {
                const response = await caches.match(urlsAws.getPhotoNews + "?id=" + news.id);
                if (response) {
                    const data = await response.json();
                    if (data.id === news.id) {
                        return { ...news, img_card: data.img_card };
                    } else {
                        return { ...news }
                    }
                } else {
                    return { ...news }
                }
            }) : []
        );
        temp._tag === 'RemoteSuccess' ? temp.value.response.newsList = newNewsList : temp

        setTempNews(temp._tag === 'RemoteSuccess' ? temp.value.response.newsList : [])
        setRemoteDataNewsCard(temp);
    };

    const GetNewsCard = async () => {
        setFilterCategory(0);
        setRemoteDataNewsCard(RD.pending)
        const newsCardCache = await getItemsCache(urlsAws.getNewsCard);
        if (newsCardCache == "asd") {
            setCurrentPhoto(newsCardCache, setTempNews);
            setAllCategories(prev => newsCardCache._tag === 'RemoteSuccess' ? [...prev, ...newsCardCache.value.response.categories] : [...prev])
        } else {
            RTE.run(await getNewsCard(), httpClientEnv)
                .then(E.fold(e => {
                    return setRemoteDataNewsCard(RD.failure(e))
                }, async a => {
                    let data = RD.success(a)
                    if (data._tag === 'RemoteSuccess') {
                        data.value.response.newsList.length > 0 && handleCacheNewsList(urlsAws.getNewsCard, data)
                        setAllCategories(prev => data._tag === 'RemoteSuccess' ? [...prev, ...data.value.response.categories] : [...prev])

                        setTempNews(data.value.response.newsList)
                        return setRemoteDataNewsCard(RD.success(a));
                    } else {
                        return setRemoteDataNewsCard(RD.failure({ tag: 'httpRequestError', error: 'error GetNewsCard' }));
                    }
                }))
        }
    };

    const handleSearch = (text: string) => {
        const filteredResults: newsListCards[] = allNewsList.filter((item) => {
            return item.title.toLowerCase().includes(text.toLowerCase()) || item.description.toLowerCase().includes(text.toLowerCase()) || item.category.title.toLowerCase().includes(text.toLowerCase())
        }
        );

        setTempNews(filteredResults);
    };

    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchWord = event.target.value;
        setWordEntered(searchWord);
        const filteredResults: newsListCards[] = allNewsList.filter((item) => {
            return item.title.toLowerCase().includes(searchWord.toLowerCase()) || item.description.toLowerCase().includes(searchWord.toLowerCase()) || item.category.title.toLowerCase().includes(searchWord.toLowerCase())
        }
        );

        if (searchWord === "") {
            setFilterCategory(0);
            setSearchResults([]);
        } else {
            setSearchResults(filteredResults);
        }
    };

    useEffect(() => {
        scrollToTop();
        getRemoteAllBanners(setRemoteDataBanners);
        GetNewsCard();
    }, [])

    useEffect(() => {
        if (remoteDataNewsCard._tag === 'RemoteSuccess') {
            const filteredNewsList = filterCategory > 0
                ? allNewsList.filter(news => news.category.id === filterCategory)
                : allNewsList;

            if (filterCategory) {
                const ids = filteredNewsList.map((nw) => {
                    return nw.id
                })
                existsPhotoIds(ids)
                    .then((photoBenef) => {
                        if (photoBenef.length > 0) {
                            getRemotePhotosNewsList(photoBenef, setRemoteDataPhoto)
                        } else {
                            setIdsArray(allNewsList.map((nw) => ({
                                id: nw.id,
                                photo: nw.img_card,
                            })));
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }

        }
    }, [remoteDataNewsCard, filterCategory, allNewsList]);

    useEffect(() => {
        if (remoteDataNewsCard._tag === 'RemoteSuccess') {
            let temp = remoteDataNewsCard.value.response.newsList.slice(cantInitialBenef, 1 + sigPag);
            let arrayEmplids = temp.map((obj) => {
                return obj.id;
            });
            setAllNewsList(remoteDataNewsCard.value.response.newsList)
            existsPhotoIds(arrayEmplids)
                .then((photoBenef) => {
                    if (photoBenef.length > 0) {
                        getRemotePhotosNewsList(photoBenef, setRemoteDataPhoto)
                    } else {
                        setCurrentPhoto(remoteDataNewsCard, setTempNews);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [remoteDataNewsCard, sigPag, cantInitialBenef])

    useEffect(() => {
        if (remoteDataPhoto._tag === 'RemoteSuccess' && remoteDataPhoto.value.response.photo.length > 0) {
            remoteDataPhoto.value.response.photo.map(async (ph, i) => {
                setIdsArray((prevIdsArray) => [...prevIdsArray, ...[{
                    id: ph.id,
                    photo: ph.img_card
                }]]);
                handleCacheNewsList(urlsAws.getPhotoNews + "?id=" + ph.id, ph)
            });
        }
    }, [remoteDataPhoto])

    useEffect(() => {
        const fetchData = async () => {
            if (idArrays.length > 0 && remoteDataNewsCard._tag === 'RemoteSuccess') {
                const newData = await Promise.all(remoteDataNewsCard.value.response.newsList.map(async (nw) => {
                    const matchingPh = idArrays.find(
                        ph => ph.id === nw.id && ph.photo,
                    );
                    const response = await getItemsCache(urlsAws.getPhotoNews + '?id=' + nw.id);
                    if (matchingPh) {
                        return {
                            ...nw,
                            img_card: matchingPh.photo,
                        };
                    } else if (response) {
                        return {
                            ...nw,
                            img_card: response.img_card,
                        };
                    } else {
                        return nw;
                    }
                }));

                const filteredNewsList = filterCategory > 0
                    ? newData.filter(news => news.category.id === filterCategory)
                    : newData;

                setTempNews(filteredNewsList);
            }
        };

        fetchData();
    }, [idArrays, filterCategory, remoteDataNewsCard]);

    useCarousel(10000, 'carousel-noticias-dest', remoteDataBanners._tag === 'RemoteSuccess');
    useCarousel(10000, 'carousel-categorias-mobile', remoteDataNewsCard._tag === 'RemoteSuccess');

    return (
        <>
            <section className="noticias">
                <BreadCrumbsDinamic />
                <h1>Mis Noticias</h1>
                {remoteDataBanners._tag === 'RemoteSuccess' && remoteDataBanners.value.response.banner.length > 0 && (
                    <>
                        <div id="carousel-noticias-dest" className="carousel carousel-dark slide pointer-event btnLink" data-bs-ride="carousel">
                            <div className="carousel-indicators m-0" >
                                {remoteDataBanners.value.response.banner.map((banner, index) => {
                                    if (banner && banner.img_banner) {
                                        return <button key={index} type="button" data-bs-target="#carousel-noticias-dest" data-bs-slide-to={index} aria-label={"Slide " + index} className={index === 0 ? "active" : ""} aria-current={index === 0 ? "true" : "false"}></button>
                                    }
                                })
                                }
                            </div>
                            <div className="carousel-inner">
                                {Children.toArray(remoteDataBanners.value.response.banner.map((banner, i) => {
                                    return (
                                        <>
                                            <div className={i === 0 ? "carousel-item active" : "carousel-item"} data-bs-interval="10000" key={i}>
                                                <a onClick={(e) => { navigate(`/mis-noticias/${banner.id}`) }}>
                                                    <picture>
                                                        <source media="(max-width: 767px)" src={banner.img_banner} />
                                                        <source media="(min-width: 768px)" src={banner.img_banner} />
                                                        <img src={banner.img_banner} className="d-block w-100 rounded-4 img-banner-newsList mb-2" alt="" />
                                                    </picture>
                                                    {
                                                        banner.is_visible_title &&
                                                        <div className="carousel-caption" style={{ bottom: 0, transform: 'translateY(30%)' }}>
                                                            <h2 className="text-title-banner" style={{ color: 'white', textShadow: '2px 2px 2px black' }}>{banner.title}</h2>
                                                        </div>
                                                    }
                                                </a>
                                            </div>
                                        </>
                                    )
                                }))}
                            </div>
                            <button className="carousel-control-prev my-auto" type="button" data-bs-target="#carousel-noticias-dest" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Anterior</span>
                            </button>
                            <button className="carousel-control-next my-auto" type="button" data-bs-target="#carousel-noticias-dest" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Siguiente</span>
                            </button>
                        </div>
                    </>
                )}

                {remoteDataNewsCard._tag === 'RemoteSuccess' && remoteDataNewsCard.value.response.newsList.length > 0 ?
                    <>
                        <div className="tit-group d-block d-md-flex justify-content-between mt-4">
                            <h2 className="h4 tit-section mb-2">Categorías</h2>
                        </div>
                        <div className="row justify-content-end w-auto">
                            <div className="col-12 col-md-6 col-xl-5">
                                <div className="input-group input-group-white">
                                    <input type="text" className="form-control" onChange={(e) => { handleFilter(e) }} placeholder="Buscar" />
                                    <button className="input-group-text border border-1 border-solid border-color-#ced4da m-0"
                                        onClick={() => { handleSearch(wordEntered) }}>
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                                {searchResults.length > 0 && (
                                    <div className="position-relative">
                                        <div className="search-results">
                                            <ul>
                                                {searchResults.map((result, index) => (
                                                    <a key={index} onClick={() => {
                                                        navigate(`/mis-noticias/${result.id}`);
                                                    }} className="text-decoration-none m-0">
                                                        <li className="d-flex align-items-center">
                                                            <i className="fa fa-search mx-2"></i>
                                                            {result.title + ' - ' + result.category.title}
                                                        </li>
                                                    </a>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div id="carousel-categories" className="carousel carousel-dark carousel-border slide mb-5 mt-2 d-none d-lg-block pointer-event" data-bs-ride="carousel" >
                            <div className="row w-auto mb-2">
                                <Slider
                                    id="carousel-category"
                                    data={transformDataForSlider(allCategories, filterCategory, setFilterCategory)}
                                />
                            </div>
                        </div>

                        <div id="carousel-categorias-mobile" className="carousel carousel-dark carousel-border mb-4 slide d-lg-none pointer-event" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                {allCategories.reduce((chunks: categories[][], category, i) => {
                                    const index = Math.floor(i / 2);
                                    if (!chunks[index]) {
                                        chunks[index] = [];
                                    }
                                    chunks[index].push(category);
                                    return chunks;
                                }, []).map((banner, index) => {
                                    return <button key={index} type="button" data-bs-target="#carousel-categorias-mobile" data-bs-slide-to={index} aria-label={"Slide " + index} className={index === 0 ? "active" : ""} aria-current={index === 0 ? "true" : "false"}></button>
                                })
                                }
                            </div>
                            <div className="carousel-inner">
                                {allCategories.reduce((chunks: categories[][], category, i) => {
                                    const index = Math.floor(i / 2);
                                    if (!chunks[index]) {
                                        chunks[index] = [];
                                    }
                                    chunks[index].push(category);
                                    return chunks;
                                }, []).map((chunk, index) => (
                                    <div key={index} className={`carousel-item p-2 mb-4 ${index === 0 ? 'active' : ''}`} data-bs-interval="10000">
                                        <div className="row gap-0 w-auto">
                                            {chunk.map((category, i) => {
                                                if (chunk.length > 1) {
                                                    return (
                                                        <div className="col-6 m-0 p-0">
                                                            <div className="icons-big icons-big-ad text-center">
                                                                <a onClick={(e) => {
                                                                    if (filterCategory === category.id) {
                                                                        setFilterCategory(0)
                                                                    } else {
                                                                        setFilterCategory(category.id)
                                                                    }
                                                                }} className={`text-decoration-none d-block ${filterCategory === category.id ? 'active' : ''}`}>
                                                                    <span className="material-symbols-outlined fs-1">
                                                                        <i className={`fa ${category.icon} fa-fw fa-sm`} aria-hidden="true"></i>
                                                                    </span>
                                                                    <span className="d-block mt-3 h4 color-dark fw-bold">{category.title}</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div className="text-center">
                                                            <div className="icons-big icons-big-ad">
                                                                <a onClick={(e) => {
                                                                    if (filterCategory === category.id) {
                                                                        setFilterCategory(0)
                                                                    } else {
                                                                        setFilterCategory(category.id)
                                                                    }
                                                                }} className={`text-decoration-none d-block ${filterCategory === category.id ? 'active' : ''}`}>
                                                                    <span className="material-symbols-outlined fs-1">
                                                                        <i className={`fa ${category.icon} fa-fw fa-sm`} aria-hidden="true"></i>
                                                                    </span>
                                                                    <span className="d-block mt-3 h4 color-dark fw-bold">{category.title}</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                    : remoteDataNewsCard._tag === 'RemoteSuccess' && remoteDataNewsCard.value.response.newsList.length === 0 ?
                        <div className="text-center mt-4">
                            <p> No se han encontrado noticias.</p>
                        </div>
                        : remoteDataNewsCard._tag === 'RemotePending' ? (
                            <>
                                <div className="text-center mt-4">
                                    <p>Cargando noticias...</p>
                                    <SpinerFullScreen />
                                </div>
                            </>
                        ) : remoteDataNewsCard._tag === 'RemoteFailure' &&
                        <>
                            <div className="text-center mt-4">
                                <p>Ha ocurrido un error al obtener noticias, por favor, intente más tarde.</p>
                            </div>
                        </>
                }

                <div className="row row-cols-1 row-cols-md-2 row-cols-xl-2 row-cols-xxl-2 g-4 mb-4 ms-0">
                    {tempNews.map((news, i) => {
                        if (i <= sigPag) {
                            return (
                                <>
                                    <div
                                        key={i}
                                        className="col-12 col-md-6 mb-4 position-relative"
                                    >
                                        <Link
                                            className="btn btn-degradado btn-absolute"
                                            to={"/mis-noticias/" + news.id}
                                        >
                                            Ver más
                                            <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                        </Link>

                                        <img
                                            style={{
                                                width: "500px",
                                                objectFit: "cover",
                                                maxHeight: "350px"
                                            }}
                                            src={!news.img_banner ?
                                                require('../../../../resources/images/default_img.png')
                                                : URL.createObjectURL(new Blob([base64ToArrayBuffer(news.img_banner.split(";base64")[1])]))}
                                            className="d-block w-100 rounded-4"
                                            alt=""
                                        />
                                    </div>
                                </>
                            )
                        }
                    })}
                </div>

                {remoteDataNewsCard._tag === 'RemoteSuccess' && (sigPag + 1 < remoteDataNewsCard.value.response.newsList.length) && filterCategory === 0 &&
                    <div className="text-center mt-5">
                        <button className="btn btn-primary" onClick={() => {
                            setCantInitialBenef(sigPag)
                            setSigPag(sigPag + 8)
                        }}> Cargar más </button>
                    </div>
                }
            </section>
        </>
    )
}

export default MyNewsList;