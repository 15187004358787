import React, { useState } from "react";
import EsquemaHibridoService from "../../services/esquemaHibrido/esquema-hibrido-service";
import errorMessage from "../SharedComponents/error-message";
import { useForm } from "react-hook-form";
import styles from "../../resources/css/style-COL.module.css";
import Swal from "sweetalert2";
import successMessage from "../SharedComponents/success-message";
import { Link } from "react-router-dom";

export default function SolicitudForm({ info, loadingInfo, errorInfo, resetValues, registros }) {
    const [loadingPostSolicitud, setLoadingPostSolicitud] = useState(false);

    const {
        setValue,
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        setLoadingPostSolicitud(true)
        if (info?.datosJefe.emplid == "" && data.type != "P") {
            Swal.fire({
                allowOutsideClick: false,
                position: "center",
                title:
                    '<div style="text-align: center; color: red; font-size: 16px"> Advertencia! </div>',
                html: '<div style="text-align: center; font-size: 15px"> Para continuar debe tener asignado a su jefe aprobador. Comuníquese con Recursos humanos. </div>',
                confirmButtonColor: "#0169b5",
            });
            setLoadingPostSolicitud(false)
            return;
        }

        if (registros?.filter(r => r.estado == 'A' && r.esquema != 'P').length > 0){
            Swal.fire({
                allowOutsideClick: false,
                position: "center",
                title:
                    '<div style="text-align: center; color: red; font-size: 16px"> Advertencia! </div>',
                html: '<div style="text-align: center; font-size: 15px"> Ya te encuentras adherido a un esquema de trabajo. Si quieres modificarlo deberás desadherirte primero.</div>',
                confirmButtonColor: "#0169b5",
            });
            setLoadingPostSolicitud(false);
            return;
        }

        if (registros?.filter(r => r.estado == 'P').length > 0){
            Swal.fire({
                allowOutsideClick: false,
                position: "center",
                title:
                    '<div style="text-align: center; color: red; font-size: 16px"> Advertencia! </div>',
                html: '<div style="text-align: center; font-size: 15px">Ya cuentas con una solicitud en curso. Si tienes dudas contáctate con Recursos Humanos.</div>',
                confirmButtonColor: "#0169b5",
            });
            setLoadingPostSolicitud(false);
            return;
        }

        const submit = {
            tipoEsquema: data.type,
            idJefe: info?.datosJefe.emplid,
            nombreJefe: info?.datosJefe.nombre,
            direccion: info?.datosPersonales.street + " " + info?.datosPersonales.number + ", " + info?.datosPersonales.state,
            aceptacionterminos: data.type == "P" ? true : data.terms,
            fullname: info?.datosPersonales.fullname
        }
        const response = await EsquemaHibridoService.postSolicitud(submit)

        if (response === null) {
            errorMessage(response?.message, resetValues);
            setLoadingPostSolicitud(false);
        } else {
            const status = response?.statusCode || response?.status;
            switch (status) {
                case 200:
                    setLoadingPostSolicitud(false);
                    successMessage(resetValues);
                    break;

                case 400:
                    errorMessage(resetValues, response?.message)
                    setLoadingPostSolicitud(false);
                    break;

                case 404:
                    errorMessage(resetValues, response?.message)
                    setLoadingPostSolicitud(false);
                    break;

                case 500:
                    errorMessage(resetValues, response?.message)
                    setLoadingPostSolicitud(false);
                    break;

                default:
                    errorMessage(resetValues, response?.message)
                    setLoadingPostSolicitud(false);
                    break;
            }
        }
    }

    const cancelar = () => {
        setValue("type", null)
        setValue("terms", false)
    }
        return (
            <>

                <h1>Definición de Esquema</h1>
                {!loadingInfo ? (!errorInfo ?
                    <>
                        <div className={`${styles["row"]} ${styles["mb-4"]}`}>
                            <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Solicitud Adhesión Esquema de Trabajo</h2>
                            {info?.datosJefe.emplid !== "" ? (
                                <><h3 className={`${styles["h5"]} ${styles["fw-bold"]}`}>Aprobador:  {info?.datosJefe.nombre}</h3>
                                    <p>Si tu aprobador no es el correcto y eres de la <strong>Operación</strong> contacta a tu Jefe de RR.HH. 
                                    Si eres de <strong>Administración</strong> contacta al Área de Compensación y Beneficios</p>
                                </>
                            ) : (
                                <h3 className={`${styles["h5"]} ${styles["fw-bold"]}`}>
                                    No cuenta con aprobador. Contacta a tu BP o Jefe de Recursos
                                    Humanos.
                                </h3>
                            )}
                            <div className={`${styles["row"]} ${styles["mb-3"]} ${styles["mt-4"]}`}>
                                <div className={styles["col-md-6"]}>
                                    <label className={styles["form-label"]}>Selecciona el esquema al cual quieres adherirte</label>
                                    <div className={styles["input-group"]}>
                                        <select className={styles["form-select"]} {...register("type", { required: "Para finalizar seleccione un esquema" })}>
                                            <option value="" disabled selected>Seleccione</option>
                                            {info?.tipoEsquema.map(t => {
                                                return <option key={t.key} value={t.key}>{t.value}</option>
                                            })}
                                        </select>
                                    </div>
                                    <small className="form-error-message">
                                        {errors.type?.message}
                                    </small>
                                </div>
                            </div>
                            {watch("type") != "P" ? <>
                                <div className={`${styles["row"]} ${styles["mb-3"]}`}>
                                    <div className={styles["col-md-6"]}>
                                        <label className={styles["form-label"]}>Confirma que la dirección desde la cual realizarás tus tareas es la siguiente:</label>
                                        <input type="text" className={styles["form-control"]} value={info?.datosPersonales.street + " " + info?.datosPersonales.number + ", " + info?.datosPersonales.state} disabled />
                                    </div>
                                    <p className={styles["mt-2"]}>Si la dirección no es correcta, por favor modifícala desde la sección <strong>Información de contacto</strong>.</p>
                                </div>
                                <div className={`${styles["p-3"]} ${styles["bg-light-2"]} ${styles["mb-4"]} ${styles["rounded-4"]}`}>
                                    <div className={`${styles["row"]} ${styles["align-items-center"]}`}>
                                        <div className={`${styles["col-12"]} ${styles["col-xl-9"]} ${styles["col-xxl-10"]}`}>
                                            <h3 className={styles["h5"]}>Recuerda que para adherirte al esquema debes haber realizado el siguiente curso requerido</h3>
                                            <p>Si aún no lo realizas, accede desde aquí y complétalo antes de enviar tu solicitud.</p>
                                        </div>
                                        <div className={`${styles["col-auto"]} ${styles["col-xl-3"]} ${styles["col-xxl-2"]}`}>
                                            <Link to={info?.info?.cursos} target="_blank" type="button" className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`}>Ir al curso <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles["mb-4"]}>
                                    <p><strong>Lee atentamente cuáles serán tus responsabilidades:</strong></p>
                                    <div className={`${styles["border"]} ${styles["border-primary"]} ${styles["p-3"]} ${styles["rounded-4"]} ${styles["mb-3"]}`}>
                                        <div className={styles["box-scroll"]} style={{ "max-height": 100 + "px" }}>
                                            <ul>
                                                <li>Retomar a las oficinas cuando sea necesario y/o sea requerido por la empresa.</li>
                                                <li>Retomar a las oficinas cuando haya imposibilidad de realizar mis trabajos de forma remota debido a imprevistos relacionados a la falta de energía eléctrica, conexión a internet, entre otros, debiendo coordinarlo con mi jefe inmediato.</li>
                                                <li>Cuidar que el lugar destinado para trabajar desde casa posea mínimo de privacidad en lo que refiere a confidencialidad y seguridad de la información de la compañía, así como bajo ruido ambiental.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles["p-3"]} ${styles["bg-light-2"]} ${styles["mb-4"]} ${styles["rounded-4"]}`}>
                                    <div className={`${styles["row"]} ${styles["align-items-center"]}`}>
                                        <div className={`${styles["col-12"]} ${styles["col-xl-9"]} ${styles["col-xxl-10"]}`}>
                                            <h3 className={styles["h5"]}>Antes de finalizar lee atentamente la política de esquema de trabajo.</h3>
                                        </div>
                                        <div className={`${styles["col-auto"]} ${styles["col-xl-3"]} ${styles["col-xxl-2"]}`}>
                                            <Link to={info?.info?.politicas} target="_blank" type="button" className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`}>Ir a la Política <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles["form-check"]}>
                                    <input className={`${styles["form-check-input"]} ${styles["h5"]}`} type="checkbox" {...register("terms", {
                                        required: watch("type") == "Presencial" ? false :
                                            "Acepte la política y sus responsabilidades antes de guardar",
                                    })} id="acepto" />
                                    <label className={`${styles["form-check-label"]} ${styles["fw-normal"]}`} htmlFor="acepto">Declaro que he leído la política y conozco mis responsabilidades.</label>

                                </div><small className="form-error-message">
                                    {errors.terms?.message}
                                </small></> : null}
                        </div>

                        <div className={`${styles["d-grid"]} ${styles["d-md-flex"]} ${styles["justify-content-md-end"]} ${styles["gap-3"]} ${styles["flex-nowrap"]} ${styles["my-5"]}`}>
                            <div className={`${styles["order-md-last"]} ${styles["d-grid"]} ${styles["d-md-flex"]} ${styles["gap-2"]}`}>
                                <button type="button" className={`${styles["btn"]} ${styles["btn-degradado"]}`} onClick={handleSubmit(onSubmit)} disabled={loadingPostSolicitud}>Enviar {loadingPostSolicitud ? (
                              <div className="spinner-border spinner-border-sm"></div>
                            ) : (<i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i>)}</button>
                            </div>
                            <button type="button" className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["order-md-first"]}`} onClick={cancelar}>Cancelar <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i></button>
                        </div>
                    </> :
                    <div
                        className="alert alert-info alert-dismissible fade show text-center"
                        role="alert"
                    >
                        No se encontraron datos asociados
                    </div>
                ) : (
                    <div data-testid="loading" className="full-spinner">
                        <div className="spinner-border text-primary"></div>
                    </div>
                )}
            </>
        )
    }