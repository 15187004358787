import Auth from "@aws-amplify/auth";
import axios from "axios";
import { urlsAws } from "../../resources/foo/api-endpoints";

// eslint-disable-next-line no-undef
const url = process.env.REACT_APP_BACKEND_URL_GLOBAL;

const menuService = {
    getSearcherOptions: async () => {
        const auth = await Auth.currentSession();
        const headers = { 'Content-Type': 'application/json', 'accessToken': auth.getAccessToken().getJwtToken() };
        const response = await axios.get(urlsAws.getMenu, { headers });
        return response;
    },
    getMenu: async () => {
        try {
            const auth = await Auth.currentSession();
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'accessToken': auth.getAccessToken().getJwtToken() }
            };
            return await fetch(urlsAws.getMenu, requestOptions)
                .then(response => response.json())
                .then(response => {
                    return response;
                }).catch(function (error) {
                    console.log(error);
                    throw error;
                });
        } catch (error) {
            return await fetch(urlsAws.getMenu, { method: 'GET' })
                .then(response => response.json())
                .then(response => {
                    return response;
                }).catch(function (error) {
                    console.log(error);
                    return null
                });
        }
    },
    getPagesByRoles: async () => {
        const auth = await Auth.currentSession();
        const headers = { 'Content-Type': 'application/json', 'accessToken': auth.getAccessToken().getJwtToken() };
        const response = axios.get(`${url}/api/acl/rol`, { headers });
        return response;
    },
    getRolesByCountry: async ({ country }) => {
        const auth = await Auth.currentSession();
        const headers = { 'Content-Type': 'application/json', 'accessToken': auth.getAccessToken().getJwtToken() };
        const response = axios.get(`${url}/api/acl/rol/country/${country}`, { headers });
        return response;
    },
    assingRolToPage: async (payload) => {
        const auth = await Auth.currentSession();
        const headers = { 'Content-Type': 'application/json', 'accessToken': auth.getAccessToken().getJwtToken() };
        const response = axios.post(`${url}/api/acl/rol/assign_rol`, payload, { headers });
        return response;
    },
    desassignRolToPage: async (payload) => {
        const auth = await Auth.currentSession();
        const headers = { 'Content-Type': 'application/json', 'accessToken': auth.getAccessToken().getJwtToken() };
        const response = axios.post(`${url}/api/acl/rol/desassign_rol`, payload, { headers });
        return response
    },
    getArbolModulos: async () => {
        const auth = await Auth.currentSession();
        const headers = { 'Content-Type': 'application/json', 'accessToken': auth.getAccessToken().getJwtToken() };
        const response = await axios.get(`${url}/api/common/feature/securityTree`, { headers });
        return response.data;
    },
    saveArbolModulos: async (nodes) => {
        const auth = await Auth.currentSession();
        const headers = { 'Content-Type': 'application/json', 'accessToken': auth.getAccessToken().getJwtToken() };
        const body = nodes;
        await axios.post(`${url}/api/common/feature/securityTree`, body, { headers });
    }
}
export default menuService;


