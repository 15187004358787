
import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { HttpClient } from '../../utilities/apiServices/httpClient'
import { ApiRequest } from '../../utilities/apiServices/apiRequest'
import { APIArticles, APICategories, Article, Category } from '../../utilities/interfaces';
import { SearchContent, LoadingContent } from '../../utilities/constantsTexts'
import Headers from './components/headers'
import Loading from './components/loading'
import SuccessResults from './components/successResults'
import FailResults from './components/failResults'
import { SearchResultsProps } from './interfaces';
import { errorsMessages } from '../../utilities/apiServices/apiErrors';
import resultsStyles from './searchResultsStyle.module.css'
import { UpdateLocalStorage } from '../../utilities/helpers/updateStorage';

const httpClient = new HttpClient()
const apiService = new ApiRequest(httpClient)

const SearchResults = ({ searchText, emmitCardClicked }: SearchResultsProps) => {
  const [subtitle, setSubtitle] = useState(SearchContent.foundMsg)
  const [resultText, setResultText] = useState(SearchContent.foundSubtitle)
  const [articlesList, setArticlesList] = useState<Article[]>([])
  const [statusMessage, setStatusMessage] = useState(LoadingContent.loading)
  const [validData, setValidData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [categoriesList, setCategories] = useState<Category[]>([])
  const update = new UpdateLocalStorage()
  const headerProps = {
    resultText,
    searchText,
    subtitle,
    validData
  }
  const successProps = {
    searchText,
    articles: articlesList
  }

  useEffect(() => {
    setLoading(true)
    fetchResults()
      .then(articles => {
        if (!isValidData(articles)) return
        setArticlesList(articles.data)
      })
      .catch((err) => {
        emitError(err)
      })
  }, [searchText]);

  const emitError = (err: number) => {
    const newError = {
      errorStatus: err,
      errorFire: 'getSearchResults',
      errorComponent: 'SearchResults'
    }
    update.updateSlot(update.keyConstants[7], JSON.stringify(newError))
  }

  const isValidData = (articles: APIArticles): boolean => {
    if (!articles.data || articles.data.length === 0) {
      setValidData(false)
      setStatusMessage(LoadingContent.notValid)
      setLoading(false)
      return false
    } else {
      setValidData(true)
      setLoading(false)
      return true
    }
  }

  useEffect(() => {
    if(validData) {
      setResultText(SearchContent.foundMsg)
      setSubtitle(SearchContent.foundSubtitle)
    } else {
      setResultText(SearchContent.notFoundMsg)
      setSubtitle(SearchContent.notFoundSubtitle)
      getCategories()
    }
  }, [validData])

  const getCategories = () => {
    fetchCategoriesList().then(results => {
        setCategories(results.data.slice(0, 4))
    }).catch((err) => {
      emitError(err)
    })
  }

  const fetchResults = async () => {
    const data = await apiService.getSearchResults(searchText)
    return data
  }

  const fetchCategoriesList = async (): Promise<APICategories> => {
    const data = await apiService.getCategories()
    return data
  }

  const ResultsContent = () => {
    return(
      <React.Fragment>
        <Headers {...headerProps}/>
        {validData ? (<SuccessResults {...successProps}/>):(<FailResults categoriesList={categoriesList} onCategoryClicked={() => emmitCardClicked()}/>)}
      </React.Fragment>
    )
  }

  return (
    <Grid container columnSpacing={12} mt={1} className={resultsStyles.results_page_container}>
    { loading ? (<Loading statusMessage={statusMessage}/>) : (<ResultsContent/>)}
    </Grid>
  );
}

export default SearchResults;