import React, { Children, useEffect, useState } from "react";
import { DefineKpiProps, asignation, indicador, kpi, KPIpost } from "../../../utilities/interfaces";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/lib/Option";
import * as S from "fp-ts/string";
import * as E from "fp-ts/Either";
import { contramap, Eq } from "fp-ts/Eq";
import { pipe } from "fp-ts/lib/function";
import { range } from "fp-ts/lib/ReadonlyNonEmptyArray";
import KpiModal from "./kpiModal";
import PreviewModal from "./previewModal";

interface SetIfCheckedProps {
    hasKpiList: asignation[];
    directReportsList: asignation[];
    setDirectReportsList: React.Dispatch<React.SetStateAction<asignation[]>>;
    setSuccess: React.Dispatch<React.SetStateAction<{ valid: number, message: string }>>;
}

interface CheckKpiListsProps extends SetIfCheckedProps {
    kpiLists: kpi[][];
    selectedList: boolean[];
    setSelectedList: React.Dispatch<React.SetStateAction<boolean[]>>;
}

type Emplid = string;

const eqEmplid: Eq<Emplid> = S.Eq;
const eqAsignationByEmplid: Eq<asignation> = pipe(
    eqEmplid,
    contramap((kpi) => kpi.emplid)
);

const getKpiIndicator = (hasKpiList: asignation[]): Array<indicador | undefined> => hasKpiList.map((r) => r.asignationIndicators.find((ind) => ind.indicatorType === "KPI"));

const setIfChecked = ({ hasKpiList, directReportsList, setDirectReportsList, setSuccess }: SetIfCheckedProps) => {
    pipe(
        hasKpiList,
        A.union(eqAsignationByEmplid)(directReportsList),
        setDirectReportsList,
    );
    document.getElementById("openPreviewKpisModal")?.click();
};

const eitherSelected = (b: boolean[]) => (i: number): E.Either<boolean[], boolean[]> => i === -1 ? E.left(b) : E.right(b.map((_, j) => i === j));

const checkKpiLists = ({ kpiLists, selectedList, setSelectedList, directReportsList, hasKpiList, setDirectReportsList, setSuccess }: CheckKpiListsProps) => {
    pipe(
        kpiLists.map((kpi) => kpi.filter((k) => parseInt(`${k.weight}`) > 0)).findIndex((kpiList) => kpiList.reduce((a, b) => a + b.weight, 0) !== 100),
        eitherSelected(selectedList),
        E.match(
            () => setIfChecked({ directReportsList, hasKpiList, setDirectReportsList, setSuccess }),
            (newSelectedList) => setSelectedList(newSelectedList),
        ),
    )
};

const DefineKpi: React.FC<DefineKpiProps> = ({ data, setDirectReportsList, setShowTable, showTable, directReportsList, hasKpiByReport, setSuccess }) => {
    const [hasKpiList, setHasKpiList] = useState<asignation[]>(pipe(
        hasKpiByReport,
        A.zip(directReportsList),
        A.filterMap((x) => x[0] ? O.some(x[1]) : O.none),
    ));

    const reportsToDisplay = pipe(
        hasKpiByReport,
        A.zip(data.directReports),
        A.filterMap((x) => x[0] ? O.some(x[1]) : O.none),
    );

    const [kpiLists, setKpiLists] = useState<kpi[][]>(pipe(
        reportsToDisplay,
        A.map((x) => range(1, 5).map((k) => {
            return { id: `${k}|${x.emplid}`, name: "", weight: 0, applySame: false }
        }))
    ));
    
    const [kpiList, setKpiList] = useState<kpi[]>([]);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [selectedList, setSelectedList] = useState<boolean[]>(pipe(
        reportsToDisplay,
        A.mapWithIndex((i) => i === 0),
    ));
    const [selectedReport, setSelectedReport] = useState<asignation | undefined>(undefined);
    const [weightByReport, setWeightByReport] = useState<number[]>(A.makeBy(reportsToDisplay.length, () => 0));

    useEffect(() => {
        selectedList.forEach((s, i) => s && (setSelectedReport(hasKpiList[i]), setSelectedIndex(i), setKpiList(kpiLists[i])));
    }, [selectedList]);

    useEffect(() => {
        kpiList.length > 0 && setKpiLists((oldLists) => oldLists.map((kl, i) => i === selectedIndex ? kpiList : kl));
    }, [kpiList]);

    useEffect(() => {
        setSelectedReport((oldReport) => oldReport !== undefined ? { ...oldReport, asignationIndicators: oldReport.asignationIndicators.map((ind) => {
            return ind.indicatorType === "KPI" ?
                { ...ind, kpi: kpiLists[selectedIndex].map((k) => {
                    return { kpiId: k.id, kpiName: k.name, kpiWeight: k.weight, applySameKpi: k.applySame }
                }) }
                : { ...ind }
       }) } : undefined);
    }, [kpiLists]);

    useEffect(() => {
        setHasKpiList((oldList) => oldList.map((report, index) => {
            return index === selectedIndex && selectedReport !== undefined ? { ...selectedReport } : { ...report }
        }));
    }, [selectedReport]);

    useEffect(() => {
        pipe(
            hasKpiList,
            getKpiIndicator,
            A.map((x) => x !== undefined && x.kpi !== undefined ? x.kpi : [{ kpiId: "", kpiName: "", kpiWeight: 0 }]),
            A.map((x) => A.reduce(0, (a: number, b: KPIpost) => a + b.kpiWeight)(x)),
            setWeightByReport,
        );
    }, [hasKpiList]);

    return (
        <>
            {hasKpiList.length > 0 &&
                <div className="box mb-4">
                    <div className="d-md-flex justify-content-between align-items-center mb-4">
                        <h2 className="h4 tit-section mb-md-0">Definir KPI</h2>
                        <div className="py-2 px-3 bg-light rounded-3">
                            Estado de Avance<span className={`color-${weightByReport.filter((w) => w === 100).length === weightByReport.length ? "primary" : "orange"} ms-3`}>{` ${weightByReport.filter((w) => w === 100).length}/${weightByReport.length}`}</span>
                        </div>
                    </div>
                    <div className="row d-none d-md-flex">
                        <div className="col-12 col-xl-5 d-flex">
                            <div className="border border-primary rounded-4 py-4 px-2 align-items-stretch w-100">
                                <div className="box-scroll box-scroll" style={{ maxHeight: "470px" }}>
                                    {Children.toArray(reportsToDisplay.map((r, i) => {
                                        return (
                                            <a
                                                className={`text-decoration-none link-list d-block py-3 border-bottom border-primary ${selectedList[i] ? "selected" : ""}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    pipe(selectedList, A.mapWithIndex((idx) => idx === i), setSelectedList)
                                                }}
                                                href="#"
                                            >
                                                <div className="row">
                                                    <div className="col-10">
                                                        <div className="data-list-box">
                                                            <ul className="list-unstyled">
                                                                <li>
                                                                    <span className="data-list-box__title col-6 px-2">Nombre</span>
                                                                    <span className="data-list-box__text">{r.name}</span>
                                                                </li>
                                                                <li>
                                                                    <span className="data-list-box__title col-6 px-2">Cargo</span>
                                                                    <span className="data-list-box__text">{r.jobCodeDescr}</span>
                                                                </li>
                                                                <li>
                                                                    <span className="data-list-box__title col-6 px-2">Estado</span>
                                                                    <span className="data-list-box__text color-orange">
                                                                        <strong className={`${weightByReport[i] !== 100 ? "color-red": "color-primary"}`}>{`${weightByReport[i]}% / 100%`}</strong>
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-2 d-flex align-items-center justify-content-end pe-3">
                                                        <i className="fa fa-chevron-right color-primary fa-lg" aria-hidden="true" />
                                                    </div>
                                                </div>
                                            </a>
                                        )
                                    }))}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-7 d-flex mt-4 mt-xl-0">
                            <div className="border border-primary rounded-4 p-4 w-100">
                                {Children.toArray(selectedList.map((r, i) => {
                                    return (
                                        r &&
                                            <>
                                                <div className="d-flex justify-content-between align-items-center mb-2">
                                                    <h3 className="h4 mb-0">Detalle</h3>
                                                    <div>
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#kpiModal" onClick={(e) => e.preventDefault()}>
                                                            <p className="btn btn-link order-md-first mt-3 text-decoration-none disabled fw-bolder">{(weightByReport[i]) === 0 ? "Crear " : "Editar "}</p>
                                                            <i className={`fa fa-${(weightByReport[i]) === 0 ? "plus-square-o fa-lg" : "pencil fa-fw me-2"}`} aria-hidden="true" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="box-scroll box-scroll">
                                                    <div className="table-responsive">
                                                        <table className="table table-section table-borderless table-th-border align-middle table-even d-none d-md-table">
                                                            <thead>
                                                                <tr>
                                                                    <th className="col-9">Nombre KPI</th>
                                                                    <th>Peso</th>
                                                                    <th className="text-center">Replicar KPI</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {selectedReport !== undefined && Children.toArray(selectedReport.asignationIndicators.find((ind) => ind.indicatorType === "KPI")?.kpi?.map((k) => {
                                                                    return (k.kpiName !== "" && k.kpiWeight > 0) && (
                                                                        <tr>
                                                                            <td className="col-9">{k.kpiName}</td>
                                                                            <td>{`${k.kpiWeight}%`}</td>
                                                                            <td className="text-center">{k?.applySameKpi && <input type="checkbox" className="form-check-input me-2 h5" checked readOnly/>}</td>
                                                                        </tr>
                                                                    )
                                                                }))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <small className="mt-5 pt-3 d-block border-top">Puedes ingresar hasta 5 KPI's. Si necesitas incorporar otro de manera excepcional, favor comunícate con {Children.toArray(data.contactEmail.split(", ").map((mail, index, arr) => {
                                                    return (
                                                        <><a href={`mailto:${mail}`}>{mail}</a>{index < arr.length - 1 ? "," : ""}</>
                                                    )
                                                }))}.</small>
                                            </>
                                    )
                                }))}
                            </div>
                        </div>
                    </div>
                    <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
                        {Children.toArray(reportsToDisplay.map((r, i) => {
                            return (
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="accordion">
                                        <button
                                            className={`accordion-button ${i !== selectedIndex ? "collapsed" : ""}`}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#accordion-item-solicitudes-${i}`}
                                            aria-expanded={`${i === selectedIndex}`}
                                            aria-controls={`accordion-item-solicitudes-${i}`}
                                            onClick={() => {
                                                pipe(selectedList, A.mapWithIndex((idx) => idx === i), setSelectedList)
                                            }}
                                        >
                                            <strong>Nombre</strong>{` ${r.name}`}
                                        </button>
                                    </h2>
                                    <div id={`accordion-item-solicitudes-${i}`} className={`accordion-collapse collapse ${i === selectedIndex && "show"}`} aria-labelledby="accordion">
                                        <div className="accordion-body p-0">
                                            <div className="data-list-box data-list-box-even">
                                                <ul className="data-list-section--item list-unstyled">
                                                    <li>
                                                        <span className="data-list-box__title">Cargo</span>
                                                        <span className="data-list-box__text">{r.jobCodeDescr}</span>
                                                    </li>
                                                    <li>
                                                        <span className="data-list-box__title">Estado</span>
                                                        <span className="data-list-box__text color-orange">
                                                            <strong className={`${weightByReport[i] !== 100 ? "color-red": "color-primary"}`}>{`${weightByReport[i]}% / 100%`}</strong>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-2 mt-4">
                                                <h3 className="h4 mb-0">Detalle</h3>
                                                <div>
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#kpiModal" onClick={(e) => e.preventDefault()}>
                                                        <i className={`fa fa-${(weightByReport[i]) === 0 ? "plus-square-o fa-lg" : "pencil fa-fw me-2"}`} aria-hidden="true" />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="data-list-box data-list-box-even">
                                                {selectedReport !== undefined && Children.toArray(selectedReport.asignationIndicators.find((ind) => ind.indicatorType === "KPI")?.kpi?.map((k) => {
                                                    return (k.kpiName !== "" || k.kpiWeight !== 0) && (
                                                        <ul className="data-list-section--item list-unstyled">
                                                            <li>
                                                                <span className="data-list-box__title">Nombre KPI</span>
                                                                <span className="data-list-box__text">{k.kpiName}</span>
                                                            </li>
                                                            <li>
                                                                <span className="data-list-box__title">Peso</span>
                                                                <span className="data-list-box__text color-orange">{`${k.kpiWeight}%`}</span>
                                                            </li>
                                                            <li>
                                                                <span className="data-list-box__title">Replicar KPI</span>
                                                                <span className="data-list-box__text">{k?.applySameKpi && <input type="checkbox" className="form-check-input me-2 h5" checked readOnly/>}</span>
                                                            </li>
                                                        </ul>
                                                    )
                                                }))}
                                            </div>
                                            <small className="mt-5 pt-3 d-block border-top">Puedes ingresar hasta 5 KPI's. Si necesitas incorporar otro de manera excepcional, favor comunícate con {Children.toArray(data.contactEmail.split(", ").map((mail, index, arr) => {
                                                return (
                                                    <><a href={`mailto:${mail}`}>{mail}</a>{index < arr.length - 1 ? "," : ""}</>
                                                )
                                            }))}.</small>
                                        </div>
                                    </div>
                                </div>
                            )
                        }))}
                    </div>
                    <PreviewModal kpiLists={kpiLists.map((kpi) => kpi.filter((k) => k.weight !== 0))} reportsToDisplay={reportsToDisplay} setSuccess={setSuccess} />
                    {selectedReport !== undefined  && <KpiModal kpiList={kpiList} setKpiList={setKpiList} contactEmail={data.contactEmail} reportEmplid={selectedReport.emplid} reportName={reportsToDisplay[selectedIndex].name} setKpiLists={setKpiLists} />}
                </div>}
            {showTable &&
                <div className="d-grid d-md-flex justify-content-md-between gap-2 flex-nowrap">
                    <button
                        id="cuck"
                        className={`btn btn-degradado order-md-last`}
                        onClick={() => {
                            checkKpiLists({ directReportsList, hasKpiList, kpiLists, selectedList, setDirectReportsList, setSelectedList, setSuccess });
                        }}
                        disabled={weightByReport.some((w) => w !== 100) && kpiLists.some((kl) => kl.length > 5)}
                    >
                        {"Continuar "}
                        <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                    </button>
                    <button hidden id="openPreviewKpisModal" data-bs-toggle="modal" data-bs-target="#previewKpisModal" />
                    {showTable &&
                        <button className="btn btn-link order-md-first" onClick={() => {
                            showTable ? setShowTable(false) : setSuccess({ valid: 2, message: "" }) 
                        }}>
                            Volver
                        </button>}
                </div>}
        </>
    )
};

export default DefineKpi;