import Auth from "@aws-amplify/auth";
import { urlsAws } from "../resources/foo/api-endpoints";

const CarpetaDigitalService = {
    getCarpetaDigital: (emplid, setData, setStatus, userDt = undefined) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(`${urlsAws.getCarpetaDigital}?emplid=${emplid}&userDt=${userDt}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.message == undefined) {
                        return data.response;
                    } else {
                        if (data.message == "API rate limit exceeded") {
                            throw new Error("error 429");
                        }
                    }
                })
                .then((data) => {
                    if (data != undefined) {
                        if (!Array.isArray(data.CN_DOCDIG_PKG)) {
                            if (data.CN_DOCDIG_PKG.codigo == "000") {
                                data.CN_DOCDIG_PKG = undefined;
                            } else {
                                data.CN_DOCDIG_PKG = [data.CN_DOCDIG_PKG];
                            }
                        }
                        if (data.CN_DOCDIG_PKG != undefined) {
                            caches.open("carpeta-digital").then((cache) => {
                                cache.put("carpeta-digital" + emplid, new Response(JSON.stringify(data.CN_DOCDIG_PKG)));
                                setData(data.CN_DOCDIG_PKG);
                                setStatus(false);
                            });
                        } else {
                            setStatus("No hay registro para mostrar");
                        }
                    } else {
                        setStatus("No hay registro para mostrar");
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    caches.match("carpeta-digital" + emplid).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                setData(data);
                                setStatus(false);
                            });
                        } else {
                            setData(1);
                            setStatus("No hay registro para mostrar");
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },

    descargarDocumento: (emplid, doc, setData, setStatus, email, userDt) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(`${urlsAws.getArchivoCarpetaDigital}?doc=${doc}&email=${email}&userDt=${userDt}&emplid=${emplid}`, requestOptions)
                .then((response) => response.json())
                .then((data) => data.response)
                .then((data) => {
                    if (data != undefined) {
                        if (data.respuesta == "ok") {
                            if (email == "") {
                                caches.open("carpeta-digital").then((cache) => {
                                    cache.put("carpeta-digital-doc" + emplid + "/" + doc, new Response(JSON.stringify(data.archivo)));
                                    setData(data.archivo);
                                });
                            } else {
                                setStatus(true);
                            }
                        } else {
                            setStatus(1);
                        }
                    } else {
                        setStatus(1);
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    caches.match("carpeta-digital-doc" + emplid + "/" + doc).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                setData(data);
                            });
                        } else {
                            setData(1);
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },
};
export default CarpetaDigitalService;
