import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import coursesData from '../data/coursesData';

export interface Course {
  image: any;
  title: string;
  url: string;
  category: string;
}

interface CoursesContextType {
  courses: Course[];
  setCourses: React.Dispatch<React.SetStateAction<Course[]>>;
}

const CoursesContext = createContext<CoursesContextType | undefined>(undefined);

export const CoursesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [courses, setCourses] = useState<Course[]>([]);

  useEffect(() => {
    const allCourses: Course[] = [
      ...coursesData.todos.courses.map(course => ({ ...course, category: 'Todos' })),
      ...coursesData.supermercados.courses.map(course => ({ ...course, category: 'Supermercados' })),
      ...coursesData.mejoramientoDelHogar.courses.map(course => ({ ...course, category: 'Mejoramiento del Hogar' })),
      ...coursesData.retailFinanciero.courses.map(course => ({ ...course, category: 'Retail Financiero' })),
    ];
    setCourses(allCourses);
  }, []);

  return (
    <CoursesContext.Provider value={{ courses, setCourses }}>
      {children}
    </CoursesContext.Provider>
  );
};

export const useCourses = (): CoursesContextType => {
  const context = useContext(CoursesContext);
  if (context === undefined) {
    throw new Error('useCourses debe ser usado dentro de un CoursesProvider');
  }
  return context;
};
