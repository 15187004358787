import Auth from "@aws-amplify/auth";
import { urlsAws } from "../../resources/foo/api-endpoints";
import { decrypt } from "../../services/utiles";


const createRequestOptions = (auth: any, method = "GET") => ({
    method,
    headers: {
        "Content-Type": "application/json; charset=utf-8",
        AccessToken: auth.getAccessToken().getJwtToken()
    }
});

const codigoService = {
    getConflicto: async () => {
        try {
            const auth = await Auth.currentSession();
            const response = await fetch(urlsAws.ethics, createRequestOptions(auth));
            const data = await response.json();
            return data;
        } catch (error) {
            return false;
        }
    },

    setConflicto: async (request: any) => {
        try {
            const auth = await Auth.currentSession();
            const requestOptions: RequestInit = {
                ...createRequestOptions(auth),
                method: 'POST',
                body: JSON.stringify({ request : request  })
            };
            const data = await fetch(urlsAws.ethics, requestOptions).then(res => res.json());
            return data;
        } catch (error) {
            console.log("error", error);
        }
    },

    getVariableList: async (setConflictos: any) => {
        try {
            const auth = await Auth.currentSession();
            const data = await fetch(urlsAws.getConflictosLista, createRequestOptions(auth)).then(res => res.json());
            if (data.message) {
                setConflictos([[]]);
            } else {
                
                data.response.conflictos.splice(4, 1);
                setConflictos(data.response);
            }
        } catch (error) {
            console.log("error", error);
        }
    },
    getFile: async (company: string, docType: string) => {
        try {
            const auth = await Auth.currentSession();
            const data = await fetch(`${urlsAws.getDocumentList}?company=${company}&doctype=${docType}`, createRequestOptions(auth)).then(res => res.json());
            return(data.message ? "error" : data.response.CN_RESULT_RP.archivo);
        } catch (error) {
            console.log("error", error);
            return "error";
        }
    },
    revalidate: async () => {
        try {
            const auth = await Auth.currentSession();
            const data = await fetch(urlsAws.revalidateEthics, createRequestOptions(auth, "POST")).then(res => res.json());
            return data;
        } catch (error) {
            console.log("error", error);
            return false;
        }
    }
};

export default codigoService;
