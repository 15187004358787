import { useEffect, FC, useRef } from "react";
import { urlsAws } from "../../../resources/foo/api-endpoints";
import Status from "../../commonComponents/ModalnfoDinamic";
import { BreadCrumbsDinamic } from "../../commonComponents";
import Loading from "../../commonComponents/ModalLoad";
import { scrollToTop } from "../../../services/utiles";
import useApiForm from "./hooks/useApiForm";
import TaskTable from "./components/TaskTable";
import { useNavigate } from "react-router-dom";
import { InitialData } from "./interfaces";

const VacacionesTareas: FC = () => {
  const navigate = useNavigate();
  const openLoadingModal = useRef<HTMLButtonElement | null>(null);
  const closeLoadingModal = useRef<HTMLButtonElement | null>(null);
  const openStatusModal = useRef<HTMLButtonElement | null>(null);
  const closeStatusModal = useRef<HTMLButtonElement | null>(null);
  const STATUS_PENDING = '0';

  const {
    initialData,
    initialError,
    submitData,
    submitError,
    loading,
    handleApprove,
    handleReject,
  } = useApiForm<InitialData>({
    listUrl: urlsAws.getVacacionesSubAlterno,
    approveUrl: urlsAws.aprobarVacaciones,
    rejectUrl: urlsAws.rechazarVacaciones,
  });

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (loading) {
      openLoadingModal.current?.click();
    }
    else {
      closeLoadingModal.current?.click();
    }
  }, [loading]);

  useEffect(() => {
    if (initialError || submitData || submitError) {
      openStatusModal.current?.click();
    }
  }, [initialError, submitError, submitData]);

  useEffect(() => {
    if (initialData && !loading &&
      (!initialData?.response?.subalternos ||
        initialData.response.subalternos.length === 0 ||
        initialData.response.subalternos[0].nombre === "" ||
        !initialData.response.subalternos.some((subalterno) => subalterno.estado_req === STATUS_PENDING))
    ) {
      navigate("/inicio");
    }
  }, [initialData, loading]);

  return (
    <>
      <div className="m-3">
        <BreadCrumbsDinamic 
          paths={[
            { label: "Vacaciones", url: "/admin/aprobar-vacaciones" },
            { label: "Vacaciones Tareas", url: "/vacaciones-tareas" }
          ]} 
        />
      </div>
      {
        initialData && 
        <section className="solicitud-mi-tiempo">
          <h1>Solicitud de Vacaciones</h1>
          <div className="box mb-4">
              <h2 className="h4 tit-section">Detalle</h2>
              <TaskTable
                data={initialData.response.subalternos.filter((subalterno) => subalterno.estado_req === STATUS_PENDING)}
                onApprove={handleApprove}
                onReject={handleReject}
              />
          </div>
        </section>
      }
      <Loading refOpen={openLoadingModal} refClose={closeLoadingModal} />
      <Status
        refOpen={openStatusModal}
        refClose={closeStatusModal}
        text={initialError || submitError ? "Error" : "Éxito"}
        icon={initialError || submitError ? "fa-exclamation-circle" : "fa-check-circle-o"}
        subtext={
          initialError ||
          submitError ||
          "Formulario enviado exitosamente"
        }
        callBack={() => {
          closeStatusModal.current?.click();
          if (initialError || submitData) {
            const modalBackdrop = document.querySelector('.modal-backdrop.fade.show');
            if (modalBackdrop) {
                modalBackdrop.remove();
            }
          }
        }}
      />
    </>
  );
};

export default VacacionesTareas;
