import React, { FC, useState, useEffect, SyntheticEvent, Children } from "react";
import ModalSuccess from "../../commons/modals/modalSuccess";
import ModalWarning from "../../commons/modals/modalWarning";
import AddKpiTable from "../../commons/addKpiTable";
import DownloadDocument from "../../commons/downloadDocument";
import { asignation, kpi, AssignModelProps } from "../../utilities/interfaces";
import KpiModal from "./kpiModal";
import { formatDate } from "../../../../services/utiles";
import SelectModelModal from "./selectModelModal";
import DefineKpi from "./defineKpi";
import { makeBy } from "fp-ts/Array";
import moment from "moment";
import AssignByJobcode from "./assignByJobcode";

const AssignModel: FC<AssignModelProps> = ({ data, setCarouselSelected }) => {
    const isFirtsPeriod = moment(data.periodEndDate, "YYYY-MM-DD").diff(moment(), "days") >= 0;
    const [allManagement, setAllManagement] = useState<boolean | null>(null);
    const [modelSelected, setModelSelected] = useState("");
    const [hasKpi, setHasKpi] = useState<boolean | null>(null);
    const [kpiList, setKpiList] = useState<kpi[]>([]);
    const [success, setSuccess] = useState({ valid: 2, message: "" });
    const [showTable, setShowTable] = useState<boolean>(false);
    const [hasKpiByReport, setHasKpiByReport] = useState<boolean[]>(makeBy(data.directReports.length, () => false));
    const [directReportsList, setDirectReportsList] = useState<asignation[]>(data.directReports.map((d) => {
        return {
            emplid: d.emplid,
            allDependencies: false,
            modelId: "",
            asignationIndicators: [{ indicatorCode: "", indicatorWeight: "", indicatorType: "" }]
        }
    }));

    useEffect(() => {
        modelSelected !== "" && setDirectReportsList(data.directReports.map((d) => {
            return {
                emplid: d.emplid,
                allDependencies: false,
                modelId: "",
                asignationIndicators: [{ indicatorCode: "", indicatorWeight: "", indicatorType: "" }]
            }
        }));
    }, [modelSelected]);
    
    useEffect(() => {
        allManagement !== null && !allManagement && (
            setHasKpi(null),
            setModelSelected(""),
            setDirectReportsList(data.directReports.map((d) => {
                return {
                    emplid: d.emplid,
                    allDependencies: false,
                    modelId: "",
                    asignationIndicators: [{ indicatorCode: "", indicatorWeight: "", indicatorType: "" }]
                }
            }))
        );
        allManagement !== null && allManagement && setHasKpi(false)
    }, [allManagement]);

    return (
        <div style={{ display: "block" }}>
            {data.valid === "1" && data.managePeriod ?
                <>
                    {success.valid === 2 &&
                        <>
                            <div className="box mb-4">
                                <h2 className="h4 tit-section">Plataforma de Gestión Bono Anual</h2>
                                <p>
                                    {`¡Hola ${data.nameDisplay}!`}<br/>
                                    {`Bienvenido a la Plataforma de Gestión de Bono Anual Ejercicio Año ${parseInt(data.period)} - Pago Año ${parseInt(data.period) + 1}.`}<br/>
                                    A continuación, podrás escoger el modelo que aplicará para tus reportes, áreas o incluso a toda tu gerencia.
                                </p>
                                {isFirtsPeriod &&
                                    <>
                                        <div className="rounded-3 bg-light p-3 color-primary mb-2">
                                            <div className="form-check d-inline-block me-5">
                                                <input className="form-check-input" type="radio" name="radio_all" id="radio_all" onChange={()=>{setAllManagement(true)}} checked={allManagement !== null && allManagement} />
                                                <label className="form-check-label text-uppercase fw-bold" htmlFor="radio_all">
                                                    Aplicar a toda la Gerencia
                                                </label>
                                            </div>
                                            <div className="form-check d-inline-block">
                                                <input className="form-check-input" type="radio" name="radio_all" id="radio_notall" onChange={()=>{setAllManagement(false)}} checked={allManagement !== null && !allManagement} />
                                                <label className="form-check-label text-uppercase fw-bold" htmlFor="radio_notall">
                                                    Aplicar según reportes / área
                                                </label>
                                            </div>
                                        </div>
                                        <small className="mt-4 d-block">* Si tienes duda respecto del alcance del FRN, favor comunícate con {Children.toArray(data.contactEmail.split(", ").map((mail, index, arr) => {
                                            return (
                                                <><a href={`mailto:${mail}`}>{mail}</a>{index < arr.length - 1 ? "," : ""}</>
                                            )
                                        }))}.</small>
                                    </>
                                }
                                {!isFirtsPeriod &&
                                    <AssignByJobcode directReportsData={data.directReports} referenceKpi={data.referenceKpi} models={data.models} contactEmail={data.contactEmail} period={data.period} />
                                }
                            </div>
                            {isFirtsPeriod && allManagement !== null && allManagement &&
                                <>
                                    <div className="box mb-4">
                                        <h2 className="h4 tit-section">Seleccione Modelo</h2>
                                        {Children.toArray(data.models.map((model) => {
                                            return (
                                                <label className={`btn btn-lg-2 fs-6 hover-white text-uppercase d-flex justify-content-between mb-4${modelSelected === model.modelId ? " btn-degradado-2" : ""}`}>
                                                    <div>
                                                        <input
                                                            className="hidden-checkbox"
                                                            type="checkbox"
                                                            name={model.modelId}
                                                            value={model.modelId}
                                                            onClick={(e: SyntheticEvent) => {
                                                                e.preventDefault();
                                                                setModelSelected(model.modelId);
                                                                setHasKpi(model.modelContainsKpi);
                                                            }} 
                                                        />
                                                        <span className="align-self-center me-5 color-primary d-block mb-2 d-md-inline-block mb-md-0">{model.modelDescr}</span>
                                                        <span className="fw-normal">
                                                            {Children.toArray(model.indicators.sort((a,b)=> parseInt(b.indicatorWeight) - parseInt(a.indicatorWeight)).map((indicator, idx, indArr) => {
                                                                return (
                                                                    <>
                                                                        <span className="color-primary">{indicator.indicatorCode}</span>
                                                                        <span className="color-orange">{" = "}</span>
                                                                        <span className="color-normal">{`${indicator.indicatorWeight}%`}</span>
                                                                        {idx < indArr.length - 1 && <span className="color-orange">{" + "}</span>}
                                                                    </>
                                                                )
                                                            }))}
                                                        </span>
                                                    </div>
                                                    <i className="fa fa-chevron-right fa-2x" aria-hidden="true" />
                                                </label>
                                            )
                                        }))}
                                        {hasKpi &&
                                            <>
                                                <AddKpiTable kpiList={kpiList} setKpiList={setKpiList} /> 
                                                <KpiModal kpiList={kpiList} setKpiList={setKpiList} contactEmail={data.contactEmail} />
                                            </>
                                        }
                                    </div>
                                </>
                            }
                            {isFirtsPeriod && allManagement !== null && !allManagement &&
                                <>
                                    {!showTable ?
                                        <label className="btn btn-lg-2 fs-6 hover-white d-flex justify-content-between mb-4 mt-5" data-bs-toggle="modal" data-bs-target="#select-model">
                                            <div>
                                                <span className="align-self-center me-5 color-primary d-block mb-2 d-md-inline-block mb-md-0 text-uppercase">Seleccione Modelo</span>
                                                <span className="fw-normal">
                                                    <span className="color-primary">Según Reportes</span>
                                                </span>
                                            </div>
                                            <i className="fa fa-chevron-right fa-2x" aria-hidden="true" />
                                        </label>
                                    :
                                        <DefineKpi data={data} setDirectReportsList={setDirectReportsList} setShowTable={setShowTable} showTable={showTable} directReportsList={directReportsList} hasKpiByReport={hasKpiByReport} setHasKpiByReport={setHasKpiByReport} setSuccess={setSuccess} lastAssignation={data.lastAssignation} />
                                    }
                                    <SelectModelModal data={data} setDirectReportsList={setDirectReportsList} setShowTable={setShowTable} hasKpiByReport={hasKpiByReport} setHasKpiByReport={setHasKpiByReport} setSuccess={setSuccess} lastAssignation={data.lastAssignation} />
                                </>
                            }
                        </>
                    }

                    {isFirtsPeriod && allManagement && success.valid !== 1 &&
                        <div className="p-3 d-flex">
                            <i className="fa fa-exclamation-triangle fa-lg me-3 mt-1 color-red" aria-hidden="true" />
                            <p className="m-0">{`Recuerda que si no cargas el modelo antes del ${formatDate(data.periodEndDate)}, quedará por defecto el`} <strong>{data.models.find((model) => !model.modelContainsKpi)?.modelDescr}</strong> para toda tu Gerencia.</p>
                        </div>
                    }

                    {isFirtsPeriod && success.valid === 1 && <DownloadDocument nameDisplay={data.nameDisplay} period={data.period} assignations={directReportsList} selectedModel={data.models.find((m) => m.modelId === modelSelected)?.modelDescr} setSuccess={setSuccess} allManagement={allManagement} />}

                    {isFirtsPeriod && allManagement && success.valid !== 1 &&
                        <div className="row justify-content-end mt-4">
                            <div className="col-12 col-md-auto">
                                <button
                                    type="button"
                                    className="btn btn-degradado w-100"
                                    disabled={hasKpi === null || (hasKpi && kpiList.length === 0 && kpiList.reduce((a, b) => a + b.weight, 0) < 100) || modelSelected === ""}
                                    onClick={() => {
                                        setDirectReportsList((oldDIrectReportList) => oldDIrectReportList.map((d, i) => {
                                            return { ...d, modelId: modelSelected, allDependencies: true, asignationIndicators: data.models.filter((m) => m.modelId === modelSelected)[0].indicators.map((ind) => {
                                                return {
                                                    indicatorCode: ind.indicatorCode,
                                                    indicatorWeight: ind.indicatorWeight,
                                                    indicatorType: ind.indicatorType,
                                                    kpi: ind.indicatorType === "KPI" && hasKpi ? kpiList.map((k) => {
                                                        return {
                                                            kpiId: k.id,
                                                            kpiName: k.name,
                                                            kpiWeight: k.weight
                                                        }
                                                    }) : undefined
                                                }
                                            }) }
                                        }));
                                        setSuccess({ valid: 1, message: "Éxito" });
                                    }}
                                >
                                    {"Continuar "}
                                    <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    }
                    
                    {isFirtsPeriod && success.valid !== 2 && <ModalSuccess success={success} />}
                    {isFirtsPeriod && <ModalWarning asignations={directReportsList} setCarouselSelected={setCarouselSelected} period={data.period} />}
                </>
            :
                <div className="box mb-4">
                    {data.status}
                </div>
            }
        </div>
    );
};
    
export default AssignModel;