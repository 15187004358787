
import CalendarHistory from './calendario-historico';
import React from 'react';
import { Link, Routes, Route, useLocation } from 'react-router-dom';
import MisHorarios from './mis-horarios';

const CalendarioGestionAsistencia = () => {
    const location = useLocation();
    return (
        <React.Fragment>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home color-orange" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    {location.pathname && location.pathname.split('/')[location.pathname.split('/').length-1] == 'historico' ? 'Histórico Horarios' : 'Horarios'}
                    </li>
                </ol>
            </nav>
            <section className="noticias mb-3">
                <h1>{location.pathname && location.pathname.split('/')[location.pathname.split('/').length-1] == 'historico' ? 'Histórico Horarios' : 'Horarios'}</h1>
                <div className="col-12 position-relative mb-0">
                    {/* <a className="btn btn-degradado btn-absolute" href="#">Ver más <i className="fa fa-chevron-right" aria-hidden="true"></i></a> */}
                </div>
            </section>

            <div>
                <Routes>
                    <Route path="" element={<MisHorarios></MisHorarios>}></Route>
                </Routes>
                <Routes>
                    <Route path="historico" element={<CalendarHistory></CalendarHistory>}></Route>
                </Routes>
            </div>
        </React.Fragment>

    )
}

export default CalendarioGestionAsistencia