import { createContext, useContext } from "react";
import {
  ProfileSummaryContextType,
  ProfileSummaryProviderProps,
} from "./teamdashboard.types";
import { useProfileSummaryFetch } from "../hooks";
import { useTableProvider } from "./DashboardTableContext";

export const ProfileSummaryContext = createContext<
  ProfileSummaryContextType | undefined
>(undefined);

export const ProfileSummaryProvider = (props: ProfileSummaryProviderProps) => {
  const { children } = props;
  const { profileSummarySelected } = useTableProvider();

  const { summaryEmployee, error } = useProfileSummaryFetch(profileSummarySelected);

  // Data exported context
  const contextValue = {
    summaryEmployee,
    error,
    countryCode: profileSummarySelected.countryCode,
  };
  return (
    <ProfileSummaryContext.Provider value={contextValue}>
      {children}
    </ProfileSummaryContext.Provider>
  );
};

export const useProfileSummaryProvider = (): ProfileSummaryContextType => {
  const profileSummaryContext = useContext(ProfileSummaryContext);

  if (!profileSummaryContext) return {} as ProfileSummaryContextType;

  return profileSummaryContext;
};
