import { Auth } from "aws-amplify";
import axios from "axios";

export const getMaintanceServices = async (setSomeError, setmessageMaintenance) => {
  let response;
  const API_URL = process.env.REACT_APP_BACKEND_URL_GLOBAL;
  //   const API_URL="http://localhost:3001"
  try {
    Auth.currentSession().then(async (auth) => {
      const headers = {
        "Content-Type": "application/json",
        accesstoken: auth.getAccessToken().getJwtToken(),
      };
      const apiUrl = `${API_URL}/api/maintenance`;
      response = await axios.get(apiUrl, { headers: headers });

      response.data.mantenimiento.Active === 'N' && setSomeError({ etype: "Maintenance", message: response.data.mantenimiento.Message, title: 'Mantenimiento' })
      setmessageMaintenance(response.data.mantenimiento.Message)
    });
  } catch (error) {
    console.log(error);

  }



}

export const getMaintanceServicesAll = async (setloadingData, setdata) => {
  const API_URL = process.env.REACT_APP_BACKEND_URL_GLOBAL;
  //const API_URL="http://localhost:3001"
  setloadingData(true);
  try {
    Auth.currentSession().then(async (auth) => {
      const headers = {
        "Content-Type": "application/json",
        accesstoken: auth.getAccessToken().getJwtToken(),
      };
      const apiUrl = `${API_URL}/api/maintenance/country`;

      const response = await axios.get(apiUrl, { headers: headers });
      setloadingData(false);

      setdata(response.data.mantenimiento);
    });
  } catch (error) {
    setloadingData(false);
    throw error;

  }
}

export const getMaintenceFeatures = async (setloadingData, setdata) => {
  const API_URL = process.env.REACT_APP_BACKEND_URL_GLOBAL;
  // const API_URL="http://localhost:3001"
  setloadingData(true);
  try {
    Auth.currentSession().then(async (auth) => {
      const headers = {
        "Content-Type": "application/json",
        accesstoken: auth.getAccessToken().getJwtToken(),
      };
      const apiUrl = `${API_URL}/api/maintenance/feature`;

      const response = await axios.get(apiUrl, { headers: headers });
      setloadingData(false);

      setdata(response.data);
    });
  } catch (error) {
    setloadingData(false);
    throw error;

  }
}

export const putMaintanceServices = async (data, setStatusRequest) => {
  setStatusRequest({
    sending: true,
    error: false,
    success: false,

  });
  try {
    const API_URL = process.env.REACT_APP_BACKEND_URL_GLOBAL;
    //   const API_URL="http://localhost:3001"
    Auth.currentSession().then(async (auth) => {
      const headers = {
        "Content-Type": "application/json",
        accesstoken: auth.getAccessToken().getJwtToken(),
      };
      const apiUrl = `${API_URL}/api/maintenance/country`;

      const response = await axios.put(apiUrl, data, { headers: headers });
      setStatusRequest({
        sending: false,
        error: false,
        success: true
      });

    });
  } catch (error) {
    setStatusRequest({
      sending: false,
      error: true,
      success: false
    });



  }
}

export const putMaintanceServicesFeature = async (data, setStatusRequestFeature) => {
  const API_URL = process.env.REACT_APP_BACKEND_URL_GLOBAL;
  //const API_URL="http://localhost:3001"
  setStatusRequestFeature(
    {
      sending: true,
      error: false,
      success: false,

    }
  );
  try {
    Auth.currentSession().then(async (auth) => {
      const headers = {
        "Content-Type": "application/json",
        accesstoken: auth.getAccessToken().getJwtToken(),
      };
      const apiUrl = `${API_URL}/api/maintenance/feature`;

      const response = await axios.put(apiUrl, data, { headers: headers });
      setStatusRequestFeature(
        {
          sending: false,
          error: false,
          success: true
        }
      );

    });
  } catch (error) {
    setStatusRequestFeature(
      {
        sending: false,
        error: true,
        success: false
      }
    );


  }
}

export const addItem = async (body, setNoDataAdd, setDataAdd) => {
  const API_URL=process.env.REACT_APP_BACKEND_URL_GLOBAL;
  // const API_URL = "http://localhost:5000"

  try {
    Auth.currentSession().then(async auth => {
      const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
      console.log("head", head)
      await axios.post(`${API_URL}/api/contentreferenceitem/create`, body, { headers: head }).then(() => {
        setDataAdd(true)
      })
    }).catch(function (error) {
      console.log(error);
      setNoDataAdd(true)
    })
  } catch (error) {
    console.error(error);
  }
}

export const updateItem = async (body, setNoDataAdd, setDataAdd, id, onSave) => {
  const API_URL=process.env.REACT_APP_BACKEND_URL_GLOBAL;
  // const API_URL = "http://localhost:5000"

  try {
    Auth.currentSession().then(async auth => {
      const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
      await axios.put(`${API_URL}/api/contentreferenceitem/updateitem/${id}`, body, { headers: head }).then(() => {
        setDataAdd(true)
        onSave()
      })
    }).catch(function (error) {
      console.log(error);
      setNoDataAdd(true)
    })
  } catch (error) {
    console.error(error);
  }
}