import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Grid } from '@mui/material'
import { HttpClient } from '../../utilities/apiServices/httpClient'
import { ApiRequest } from '../../utilities/apiServices/apiRequest'
import AllCategoryCard from './components/card/index'
import { APICategories, Category } from '../../utilities/interfaces';
import { setCategoryIcon } from '../../utilities/helpers/imgUrlSeter';
import { UpdateLocalStorage } from '../../utilities/helpers/updateStorage';
import { paths } from '../../utilities/paths';
import { CategoryContent, LoadingContent } from '../../utilities/constantsTexts';
import { errorsMessages } from '../../utilities/apiServices/apiErrors';
import styles from '../../commons/styles/rootStyle.module.css'
import textstyles from '../../commons/styles/textStyles.module.css'
import allCategoryStyles from './styles/allCategories.module.css'
import categoryStyle from '../category/styles/categoryStyles.module.css'
import Highlighter from '../../commons/highlighter';
import Loading from '../../commons/dataStatus/loading/loading'

const httpClient = new HttpClient()
const apiService = new ApiRequest(httpClient)

const HCAllCategories = () => {
  const subtitle = CategoryContent.allCategoriesTitle
  const backButtonTitle = CategoryContent.backButton
  const [categoriesList, setCategoriesList] = useState<Category[]>([])
  const [statusMessage, setStatusMessage] = useState(LoadingContent.loading)
  const [validData, setValidData] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const update = new UpdateLocalStorage()

  useEffect(() => {
    update.updateSlot(update.keyConstants[0], paths[4].breadCrumb)
    setIsLoading(true)
    fetchDataCategory()
      .then(categories => {
        if (!isValidData(categories)) return
        setCategoriesList(categories.data)
      })
      .catch((err) => {
        emitError(err)
      }).finally(() => {
        setIsLoading(false)
      })
  }, []);

  const emitError = (err: number) => {
    const newError = {
      errorStatus: err,
      errorFire: 'fetchDataCategory',
      errorComponent: 'HCAllCategories'
    }
    update.updateSlot(update.keyConstants[7], JSON.stringify(newError))
  }

  const isValidData = (categories: APICategories): boolean => {
    if (!categories.data ||
      categories.data.length === 0 ||
      categories.data[0]?.attributes?.title === null) {
      setStatusMessage(LoadingContent.notValid)
      emitError(500)
      return false
    } else {
      setValidData(true)
      return true
    }
  }

  const fetchDataCategory = async () => {
    const data = await apiService.getCategories()
    return data
  }

  const backToPrevUrl = () => {
    navigate(paths[1].path)
  }

  return (
    <Grid container columnSpacing={12} mt={1} className={allCategoryStyles.all_categories_container}>
      <Grid item xs={12}>
        <h1 className={`${textstyles.section_title} ${allCategoryStyles.all_categories_title}`}>
          {subtitle}
        </h1>
        <Highlighter width={35} />
      </Grid>
      <Grid item xs={12} className={allCategoryStyles.all_categories_card_wrapper}>
        {isLoading && <Loading normalMessage={true} />}
        {validData && !isLoading &&
          categoriesList.map((category: Category) => {
            const icon = setCategoryIcon(category)
            const infoMap = {
              id: category.id,
              cardIcon: icon,
              cardTitle: category.attributes.title,
              cardSubtitle: category.attributes.subtitle,
            }
            return (
              <AllCategoryCard key={category.id} CardInfo={infoMap} />
            )
          })
        }
        {!validData && !isLoading && <p>{statusMessage}</p>}
      </Grid>
      <Grid item xs={12} className={categoryStyle.back_button_wrapper}>
        <Button className={styles.terciary_button} onClick={() => backToPrevUrl()}>
          {backButtonTitle}
        </Button>
      </Grid>
    </Grid>
  );
}

export default HCAllCategories;
