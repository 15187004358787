import { Children, FC, useEffect, useRef, useState, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { DataModalDinamicProps, DonationPost, GetDonate, PostDonate } from "../../utilities/interfaces";
import { RemoteData } from "@devexperts/remote-data-ts";
import * as RD from "@devexperts/remote-data-ts";
import { HttpError } from "../../../../services/apiServices/interfaces";
import { httpClientEnv } from "../../../../services/apiServices/httpClient";
import { GetDonations, PostDonations } from "../../utilities/apiServices";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import ModalConfirmAction from "../../../commonComponents/ModalConfirmAction";
import ModalLoad from "../../../commonComponents/ModalLoad";
import ModalInfoDinamic from "../../../commonComponents/ModalnfoDinamic";
import { Context } from "../../../../context";
import { dotNumber } from "../../../../services/utiles";

const Donate: FC = () => {
    const { register, handleSubmit, control,  getValues, reset, setValue, trigger, watch,  formState: { errors , isDirty, isValid}} = useForm({ mode: "onSubmit" });
    const { setShowDonations, showDonationButton } = useContext(Context);
    const [remoteDonate, setRemoteDonate] = useState<RemoteData<HttpError, GetDonate>>(RD.initial);
    const [remotePostDonate, setRemotePostDonate] = useState<RemoteData<HttpError, PostDonate>>(RD.initial);
    const [disabledBtn, setDisabledBtn] = useState(true);
    const refLoad = useRef<HTMLButtonElement>(null);
    const refLoadClose = useRef<HTMLButtonElement>(null);
    const refStatus = useRef<HTMLButtonElement>(null);
    const refStatusClose = useRef<HTMLButtonElement>(null);
    const refConfirmActionSubmit = useRef<HTMLButtonElement>(null);
    const [selectAmount, setSelectAmount] = useState("");
    const amountSelect = watch('amount');
    const amountInput = watch('amount_2');

    const DONATION_TYPE = "1";
    const AMOUNT_OPTIONS = [
        {
            value: 1000,
            label : '$1.000'
        },
        {
            value: 5000,
            label : '$5.000'
        },
        {
            value: 10000,
            label : '$10.000'
        },
        {
            value: "0",
            label: "Otro monto"
        }
    ]

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const module = queryParams.get('module');
    const navigate = useNavigate();
  
   useEffect(() => {
      if(module !== 'donations' || !showDonationButton){
        setShowDonations(false)
      }
    }, []);
    
    const [dataModal, setDataModal] = useState<DataModalDinamicProps>({
        callBack : ()=> null,
        icon: '',
        msg: ''
    })
    
    const getDonates = async () => {
        refLoad && refLoad.current && refLoad.current.click();
        setRemoteDonate(RD.pending)
        RTE.run(await GetDonations(DONATION_TYPE), httpClientEnv)
            .then(E.fold(e => { return setRemoteDonate(RD.failure(e)) }, a => {
            let data = RD.success(a)
            if (data._tag === 'RemoteSuccess' && data.value.response.valid) {
                refLoadClose && refLoadClose.current && refLoadClose.current.click();
                return setRemoteDonate(RD.success(a))
            } else {
                return setRemoteDonate(RD.failure({ tag: 'httpRequestError', error: 'getDonates' }))
            } 
        }))
    }

    useEffect(()=> {
        trigger('amount_2')
    },[getValues('amount_2')])

    const postDonate = async (body: DonationPost) => {
        refLoad && refLoad.current && refLoad.current.click();
        setRemotePostDonate(RD.pending)

        RTE.run(await PostDonations(body), httpClientEnv)
            .then(E.fold(e => { return setRemotePostDonate(RD.failure(e)) }, a => {
            let data = RD.success(a)
            if (data._tag === 'RemoteSuccess' && data.value.response.valid) {
                refLoadClose && refLoadClose.current && refLoadClose.current.click();
                getDonates();
                window.scrollTo(0, 0);
                return setRemotePostDonate(RD.success(a))
            } else {
                return setRemotePostDonate(RD.failure({ tag: 'httpRequestError', error: 'postDonate' }))
            } 
        }))
    }

    const onSubmit = () => {
        let data = getValues()
        let body = {
            "amount": data.amount_2 ? Number(data.amount_2) : Number(data.amount),
            "type" : DONATION_TYPE
        }
        postDonate(body)
    }

    useEffect(()=> {
        getDonates();
    },[])

    useEffect(()=>{
        if (!isDirty || !isValid) {
            setDisabledBtn(true)
        } else {
            setDisabledBtn(false)
        }
    },[isDirty, isValid])

    useEffect(()=> {
        if(remotePostDonate._tag === 'RemoteFailure'){
            refLoadClose && refLoadClose.current && refLoadClose.current.click();
            refStatus && refStatus.current && refStatus.current.click();
            setDataModal({
                msg: 'No hemos podido procesar su petición. Por favor, inténtalo nuevamente.',
                icon: 'fa-exclamation-triangle',
                callBack: () => {
                   reset();
                   setSelectAmount("");
                   refStatusClose && refStatusClose.current && refStatusClose.current.click();
                }
            })
        }
    },[remotePostDonate])

    useEffect(()=> {
        if(remoteDonate._tag === 'RemoteFailure'){
            refLoadClose && refLoadClose.current && refLoadClose.current.click();
            refStatus && refStatus.current && refStatus.current.click();
            setDataModal({
                msg:'Ha ocurrido un error al conseguir información. Por favor, intente más tarde.',
                icon: 'fa-exclamation-triangle',
                callBack: () => {
                   setShowDonations(false);
                   refStatusClose && refStatusClose.current && refStatusClose.current.click();
                   navigate('/noticias');
                }
            })
        }
    },[remoteDonate])

    return(
        <>
            <form onSubmit={handleSubmit(onSubmit)} >
            <div className="box mb-4">
                <h2 className="h4 tit-section" > Juntos en esta emergencia, ¡Tu aporte, Cenco lo iguala!  </h2>
                {remoteDonate._tag === 'RemoteSuccess' && remoteDonate.value.response.canDonate ? 
                    <>
                        <div className="border-bottom border-2">
                            <p>
                            Dona el monto que quieras y nuestra compañía lo igualará. Todo lo recaudado irá en ayuda de nuestros colaboradores y las comunidades damnificadas en la
                            <span className="color-primary-strong"> <strong>Región de Valparaíso</strong> </span>
                            a través de
                            <span className="color-primary-strong"> <strong>Desafío Levantemos Chile</strong> </span>.
                            </p>
                            <div className="p-3 bg-light-2 rounded-4">
                                <div className="col-12 col-md-4">
                                    <label className="">Aporte</label>
                                    <div className="input-group">
                                    <Controller
                                        name="amount"
                                        control={control}
                                        rules={{ required: 'Este campo es requerido' }}
                                        render={({ field }) => (
                                            <select
                                                {...field}
                                                className="form-select"
                                                onChange={(e) => {
                                                    if (!e.target.value) {
                                                        setSelectAmount("");
                                                        setValue('amount_2', 0);
                                                    } else {
                                                        setSelectAmount(e.target.value);
                                                        setValue('amount_2', e.target.value === "0" ? 1000 : e.target.value);
                                                    }
                                                    field.onChange(e);
                                                }}
                                            >
                                                <option value="">Seleccione</option>
                                                {AMOUNT_OPTIONS.map(opt => (
                                                    <option key={opt.value} value={opt.value}>
                                                        {opt.label}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    />
                                    </div>
                                </div>
                                {selectAmount === '0' && 
                                    <>
                                        <label className=""> Monto </label>
                                        <div className="col-12 col-md-4">
                                            <div className="input-group">
                                            <span className="input-group-text">$</span>
                                            <input
                                                className="form-control mt-1" 
                                                type="number"
                                                step="1"
                                                min="1"
                                                placeholder="$1.000"
                                                {...register('amount_2', {
                                                    required: 'Ingrese monto',
                                                    min: { value: 1000, message: 'El monto mínimo por colaborador es $1.000'},
                                                    max: { value: 500001, message: 'El monto máximo por colaborador es $500.000'},
                                                })}
                                                style={{height:'40px'}}
                                            />
                                            </div>
                                        </div>
                                        {errors["amount_2"] !== undefined && <div style={{ display: "block" }} className="invalid-feedback">{String(errors["amount_2"].message)}</div>}
                                    </>
                                }
                            </div>
                            
                            <div className="mt-2">
                                <h5 className="color-primary-strong">Consideraciones:</h5>
                                <ul>
                                    <li>
                                        <strong>Donación 100% voluntaria que se descontará por planilla en tu próxima liquidación de sueldo.</strong>
                                    </li>
                                    <li>
                                        Podrás donar solo una vez.
                                    </li>
                                    <li>
                                        Cencosud aportará el doble de los montos donados por nuestros colaboradores hasta el domingo 11 de febrero 2024 <strong>e igualará los montos donados desde el 12 al 29 de febrero 2024.</strong>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="form-check mt-4">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="checkboxtitulo1" 
                                    {...register('checkConset', {
                                        required: 'Seleccione',
                                    })}
                                />
                            <label className="form-check-label fw-normal color-normal ms-2" htmlFor="consentimiento">
                                Acepto consideraciones
                            </label>
                        </div>
                    </>
                : remoteDonate._tag === 'RemoteSuccess' && !remoteDonate.value.response.canDonate &&
                    <>
                        <p>¡Muchas gracias! Te recordamos que ya hiciste tu donación. Si quieres seguir aportando a las familias damnificadas en la Región de Valparaíso, te invitamos a aportar directamente en la cuenta oficial de Desafío Levantemos Chile. </p>
                    </>
                }
            </div>
            <div className="d-grid d-md-flex justify-content-md-between gap-2 flex-nowrap mt-4">
                {remoteDonate._tag === 'RemoteSuccess' && remoteDonate.value.response.canDonate && <div className="d-grid d-md-flex gap-2">
                    <button type="button" onClick={()=>{
                        refConfirmActionSubmit && refConfirmActionSubmit.current && refConfirmActionSubmit.current.click();
                    }} disabled={Object.keys(errors).length > 0 || disabledBtn} className="btn btn-degradado ms-md-2" data-bs-toggle="modal" data-bs-target="#seguro-publicar"> Donar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                </div>}
                <button type="button" className="btn btn-link order-md-first" data-bs-toggle="modal" data-bs-target="#back" onClick={() =>{
                        setShowDonations(false);
                        navigate("/noticias");}}>Volver</button>
            </div>
            </form>
            <ModalConfirmAction refOpen={refConfirmActionSubmit} data_target={'modalConfirmAction'} text={`¿Estás seguro que deseas donar $${amountInput ? dotNumber(amountInput) : dotNumber(amountSelect)} a la causa?`} 
                callBack={()=>{ onSubmit() }}/>
            <ModalLoad text="Cargando información..." refOpen={refLoad} refClose={refLoadClose} data_target='donation'/>
            <ModalInfoDinamic refOpen={refStatus} icon={dataModal.icon} text={dataModal.msg} subtext={dataModal.submsg} callBack={dataModal.callBack} refClose={refStatusClose}/>
            </>
    )

}
export default Donate;