import React, { useState, useEffect, FunctionComponent } from "react";
import UnorderedList from "../../commons/forms";
import { FormList, Props, PersonalData, ContactData, DocumentData, PostValues, GetSheet } from "../../utilities/interfaces";
import { stepOnePersonal, stepOneContact } from "../../utilities/formValues";
import { useForm, SubmitHandler } from "react-hook-form";
import OfflineModal from "../../commons/plain/modals/offline";
import fichaContratacionService from "../../../../services/ficha-contratacion-service";
import { capitalize, scrollToTop } from "../../../../services/utiles";

interface Props1 extends Props {
    personalData: PersonalData;
    setPersonalData: React.Dispatch<React.SetStateAction<PersonalData>>;
    contactData: ContactData;
    setContactData: React.Dispatch<React.SetStateAction<ContactData>>;
    documentData: DocumentData;
    setDocumentData: React.Dispatch<React.SetStateAction<DocumentData>>;
    postValues: PostValues;
    setPostValues: React.Dispatch<React.SetStateAction<PostValues>>;
    practice: boolean;
}

interface CountryCode {
    countryCode?: string;
}

const FirstStep: FunctionComponent<Props1> = ({ options, nextStep, personalData, setPersonalData, contactData, setContactData, documentData, setDocumentData, postValues, setPostValues, practice }) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        setError,
    } = useForm<PersonalData | ContactData | CountryCode>({
        defaultValues: {
            ...personalData,
            ...contactData,
            countryCode: "+569",
            phone: contactData.phone !== undefined ? contactData.phone.replace("+569", "") : "", 
        }
    });

    const birthcountry = watch("birthcountry");
    const birthDate = watch("birthdate");
    const [personal, setPersonal] = useState(stepOnePersonal);
    const [postStatus, setPostStatus] = useState<any>(false);
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        if (birthcountry === "" || birthcountry === "CHL") {
            setPersonal(stepOnePersonal.slice(0, stepOnePersonal.length - 2));
            setValue("expiratn_dt", "");
            setValue("visa_permit_type", "");
        } else {
            setPersonal(stepOnePersonal);
        }
    }, [birthcountry]);

    useEffect(() => {
        (0 > (new Date().valueOf() - new Date(birthDate).valueOf())/(1000 * 3600 * 24 * 365.25) - 18 - 23.999999999999996/(365.25 * 60)) && !practice ?
            setDocumentData((prevDocumentData) => {
                return { ...prevDocumentData, aut_menor: "" };
            })
        :
            setDocumentData((prevDocumentData) => {
                "aut_menor" in prevDocumentData &&
                    delete prevDocumentData["aut_menor"];
                return { ...prevDocumentData };
            })
    }, [birthDate]);

    const formSubmitHandler: SubmitHandler<PersonalData | ContactData | CountryCode> = (data: PersonalData | ContactData | CountryCode) => {
        if (navigator.onLine) {
            if ("national_id" in data && data.national_id === personalData.national_id) {
                setDisableButton(true);
                let newData = { ...data as PersonalData | ContactData | CountryCode };
                let newContactData;
                if ("countryCode" in newData && "emailaddr" in newData && "phone" in newData) {
                    newContactData = { phone: newData["phone"], emailaddr: newData["emailaddr"] };
                    delete newData["countryCode"];
                    delete newData["emailaddr"];
                }
                let newPersonalData:PersonalData = { ...newData as PersonalData };
                const fullname = newPersonalData.firstname + " " + newPersonalData.middlename + " " + newPersonalData.lastname + " " + newPersonalData.secondlastname;
                
                sessionStorage.setItem("name", capitalize(fullname));
                sessionStorage.setItem("position", capitalize(newPersonalData.descr_jobcode));
                sessionStorage.setItem("department", capitalize(newPersonalData.descr_deptid));
                if ("phone" in newPersonalData) {
                    delete newPersonalData["phone"];
                }
                let stringSheet = sessionStorage.getItem("ficha");
                if (stringSheet !== null) {
                    setPersonalData(newPersonalData as PersonalData);
                    setContactData(newContactData as ContactData);
                    let savedSheet = JSON.parse(stringSheet) as GetSheet;
                    sessionStorage.setItem("ficha", JSON.stringify({
                        ...savedSheet,
                        sheet: {
                            ...savedSheet.sheet,
                            firstStep: {
                                personalData: newPersonalData,
                                contactData: newContactData,
                            }
                        }
                    }));
                }
                fichaContratacionService.postMethods.setFicha(postValues, { ...newData, phone: newContactData !== undefined && "phone" in newContactData ? "+569" +  newContactData.phone as string : "" }, setPostValues, false, setPostStatus, setDisableButton);
            } else {
                setError("national_id", { type: "pattern", message: "Deshabilitar campos tipo 'readOnly' no va con los principios Cencosud" });
            }
            document.getElementById("sendingInfo")?.click();
        } else {
            document.getElementById("offline")?.click();
        }
    };

    useEffect(() => {
        if (postStatus) {
            document.getElementById("closeSendingInfo")?.click();
        }
        const timer = setTimeout(() => {
            setDisableButton(false);
            if (postStatus.status === "0") {
                nextStep();
            }
        }, 519);
        return () => clearTimeout(timer);
    }, [postStatus]);

    return (
        <>
            <form
                className="needs-validation"
                onSubmit={handleSubmit(formSubmitHandler)}
            >
                <div id={"datosPersonales"} className="box mb-4">
                    <h2 className="h4 tit-section" children={"Datos Personales"} />
                    <UnorderedList formList={personal as Array<FormList>} options={options} register={register} errors={errors} />
                </div>
                <div className="box mb-4">
                    <h2 className="h4 tit-section" children={"Contacto"} />
                    <UnorderedList formList={stepOneContact as Array<FormList>} options={options} register={register} errors={errors} />
                </div>
                <div className="text-end d-grid d-md-block">
                    <button type="submit" className="btn btn-degradado" disabled={disableButton}>
                        {"Siguiente"}
                        <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                    </button>
                </div>
            </form>
            <OfflineModal />
        </>
    );
};

export default FirstStep;