import React from "react";
import { dotNumber } from "../../../../services/utiles";

interface Props {
    balance: string;
}

const BalanceCard: React.FC<Props> = ({ balance }) => {
    return (
        <div className="tit-group d-block d-md-flex justify-content-between mb-4">
            <h1 className="mb-md-0">{"Vale Personal"}</h1>
            <div className="saldo rounded-3 bg-light p-3 fs-6">
                <div className="d-flex align-items-center">
                    <span className="color-primary">{"Saldo Actual"}</span>
                    <span className="color-orange ms-3">{balance !== "" ? `$${dotNumber(balance)}` : ""}</span>
                </div>
            </div>
        </div>
    )
}

export default BalanceCard;