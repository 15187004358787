import React from "react";
import styles from '../../resources/css/style-COL.module.css';
import { Link } from "react-router-dom";

const HistoricoJefe = ()=>{

    return(
        <>
        

             
            <nav className={`${styles["breadcrumbs"]} ${styles["d-none"]} ${styles["d-lg-block"]}`} aria-label="breadcrumb">
                <ol className={styles["breadcrumb"]}>
                    <li className={styles["breadcrumb-item"]}><Link href="/inicio"><i className={`${styles["fa"]} ${styles["fa-home"]}`} aria-hidden="true"></i> Inicio</Link></li>
                    <li className={styles["breadcrumb-item"]}>Vacaciones</li>
                    <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">Pendientes e Histórico de Vacaciones</li>
                </ol>
            </nav>
            
            <section className={styles["vacaciones"]}>

                <h1>Vacaciones</h1>

                <div className={`${styles["row"]} ${styles["mb-4"]}`}>
                    
                    <div className={`${styles["col-12"]} ${styles["col-xl-4"]} ${styles["d-flex"]} ${styles["mb-4"]} ${styles["mb-xl-0"]}`}>
                        <div className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}>
                            <img src="assets/images/foto-usuario-h.png" alt="" className={`${styles["rounded-circle"]} ${styles["mb-3"]}`} width="120" height="120" />                            <h2 className="h3 mb-2">Joel Méndez</h2>
                            <p className={`${styles["h5"]} ${styles["fw-bold"]}`}>Nombre de Cargo</p>

                            <div className={`${styles["data-list-box"]} ${styles["data-list-box-align-middle"]} ${styles["mb-3"]}`}>
                                <ul className={`${styles["list-unstyled"]} ${styles["m-0"]}`}>
                                    <li>
                                        <span className={`${styles["ic-circle"]} ${styles["ic-circle-primary"]}`}>2</span>
                                        Solicitudes Pendientes
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    
                    <div className={`${styles["col-12"]} ${styles["col-xl-8"]} ${styles["d-flex"]}`}>
                        
                        
                        <div className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}>

                            <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Pendiente e Histórico</h2>
        
                            
                            <div className={styles["table-responsive"]}>
                                <table className={`${styles["table"]} ${styles["table-even"]} ${styles["table-section"]} ${styles["table-borderless"]} ${styles["d-none"]} ${styles["d-md-table"]}`}>
                                    <thead>
                                        <tr>
                                            <th className={styles["text-nowrap"]}>ID Solicitud <Link href="#"><i className={`${styles["fa"]} ${styles["fa-long-arrow-down"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></th>
                                            <th className={styles["text-nowrap"]}>Nombre Solicitante <Link href="#"><i className={`${styles["fa"]} ${styles["fa-long-arrow-down"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></th>
                                            <th className={styles["text-nowrap"]}>Fecha Inicio <Link href="#"><i className={`${styles["fa"]} ${styles["fa-long-arrow-down"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></th>
                                            <th className={styles["text-nowrap"]}>Fecha Término <Link href="#"><i className={`${styles["fa"]} ${styles["fa-long-arrow-down"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></th>
                                            <th className={`${styles["text-nowrap"]} ${styles["text-center"]}`}>Días <Link href="#"><i className={`${styles["fa"]} ${styles["fa-long-arrow-down"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></th>
                                            <th className={`${styles["text-nowrap"]} ${styles["text-center"]}`}>Beneficio <Link href="#"><i className={`${styles["fa"]} ${styles["fa-long-arrow-down"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></th>
                                            <th className={`${styles["text-nowrap"]} ${styles["text-center"]}`}>Comentario <Link href="#"><i className={`${styles["fa"]} ${styles["fa-long-arrow-down"]} ${styles["fa-fw"]}`} aria-hidden="true"></i></Link></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>00001293</td>
                                            <td>María Julia Rodriguez</td>
                                            <td>30/11/2022</td>
                                            <td>09/12/2022</td>
                                            <td>7</td>
                                            <td>3</td>
                                            <td className={`${styles["text-uppercase"]} ${styles["fw-bold"]} ${styles["color-primary"]}`}>Aprobada</td>
                                        </tr>
                                        <tr>
                                            <td>00007654</td>
                                            <td>Manuel Gutierrez</td>
                                            <td>01/12/2022</td>
                                            <td>20/12/2022</td>
                                            <td>5</td>
                                            <td>-</td>
                                            <td className={`${styles["text-uppercase"]} ${styles["fw-bold"]}`}>Pendiente</td>
                                        </tr>
                                        <tr>
                                            <td>00007528</td>
                                            <td>Juán Muñoz</td>
                                            <td>06/12/2022</td>
                                            <td>18/12/2022</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td className={`${styles["text-uppercase"]} ${styles["fw-bold"]} ${styles["color-orange"]}`}>Rechazada</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                           
                        </div>
                    </div>
                 
                </div>

                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-4"]}`}></div>
                    <div className={`${styles["col-12"]} ${styles["col-xl-8"]}`}>
                        <div className={`${styles["row"]} ${styles["justify-content-between"]}`}>
                            <div className={`${styles["col-12"]} ${styles["col-lg-auto"]} ${styles["mb-3"]} ${styles["mb-lg-0"]}`}>
                                <button type="button" className={`${styles["btn"]} ${styles["btn-link"]} ${styles["w-100"]}`}>Volver a Solicitudes Pendientes</button>
                            </div>
                        </div>
                    </div>  
                </div>

            </section>
        
        </>
    )
}

export default HistoricoJefe;