import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { decrypt, encrypt } from "../../services/utiles";
import newsService from '../../services/news/news-services';

export default function NewsManagerForm() {
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(true);
  const [, setModalShow] = useState(true);
  const [, setFechaTerminoError] = useState(false);
  const [respuesta, setRespuesta] = useState(null);
  const [respuestaSolicitudValid, setRespuestaSolicitudValid] = useState(false);
  const [respuestaSolicitudFallida, setRespuestaSolicitudFallida] = useState(false);
  const [titutoloNoticia, setTituloNoticia] = useState("");
  const [descripNoticia, setDescripNoticia] = useState("")
  const [itemGroup, setItemGroup] = useState(0);
  const [arregloGroup, setArregloGroup] = useState([]);
  const [cargando, setCargando] = useState(false);
  const [validacionFecha, setValidacionFecha] = useState(true);
  const [valorTexto, setValorTexto] = useState("");
  const [valorNoticia, setValorNoticia] = useState(null);

  const navigate = useNavigate();
  const params = useParams();

  const nameUser = decrypt(localStorage.getItem("name"));

  const modulos = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formatos = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image"
  ];

  const handleClose = () => {
    setRespuestaSolicitudValid(false);
    setRespuestaSolicitudFallida(false);
  };

  useEffect(() => {
    setDescripNoticia(valorTexto);
  }, [valorTexto]);

  const [, setPicture3] = useState(null);
  const [, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [, setPicture2] = useState(null);
  const [imgData2, setImgData2] = useState(null);

  const deletePicture3 = () => {
    setPdf(null);
    document.getElementById("selectImage3").value = "";
  };
  const onChangePicture2 = (e) => {
    if (e.target.files[0]) {
      setPicture2(e.target.files[0]);
      const reader2 = new FileReader();
      reader2.addEventListener("load", () => {
        setImgData2(reader2.result);
      });
      reader2.readAsDataURL(e.target.files[0]);
    }
  };
  const onChangePicture3 = (e) => {
    if (e.target.files[0]) {
      setPicture3(e.target.files[0]);
      const reader3 = new FileReader();
      reader3.addEventListener("load", () => {
        setPdf(reader3.result);
      });
      reader3.readAsDataURL(e.target.files[0]);
    }
  };

  const deletePicture = () => {
    setImgData(null);
    document.getElementById("selectImage1").value = "";
  };
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const deletePicture2 = () => {
    document.getElementById("selectImage2").value = "";
    setImgData2(null);
  };

  useEffect(() => {
    if (params.id) {
      newsService.getNew(setValorNoticia, params.id);
    }
  }, []);

  useEffect(() => {
    if (valorNoticia) {
      setImgData(valorNoticia.feature_img_url);
      setImgData2(valorNoticia.banner_img_url);
      setTituloNoticia(valorNoticia.title);
      if (
        valorNoticia.file !== "null" &&
        valorNoticia.file
      ) {
        setPdf(valorNoticia.file);

      }

      setValorTexto(valorNoticia.content);
    }
  }, [valorNoticia]);

  const handleSubmit = (e) => {
    e.preventDefault();

    var titulo = document.getElementById("titulo").value;
    var fechaInicio = document.getElementById("fechaInicio").value;
    var fechaTermino = document.getElementById("fechaTermino").value;


    var nombreUsuario = "";
    if (nameUser) {
      if (nameUser !== "") {
        nombreUsuario = nameUser;
      }
    }

    if (
      titulo !== "" &&
      fechaInicio !== "" &&
      fechaTermino !== "" &&
      imgData !== "" &&
      imgData2 != "" &&
      validacionFecha
    ) {

      var radio1 = document.getElementById("flexRadioDefault1").checked;

      var estadoNoticia = 'draft';
      if (radio1) {
        estadoNoticia = 'available';
      }

      setStatus(true);
      setMessage("");
      setTituloNoticia(titulo);
      setDescripNoticia(valorTexto);
      setCargando(true);

      const payload = {
        title: titulo,
        date_from: fechaInicio,
        date_to: fechaTermino,
        banner_img_url: imgData2,
        feature_img_url: imgData,
        is_featured: false,
        file: pdf,
        status: estadoNoticia,
        author: nombreUsuario,
        content: valorTexto
      }

      if (params.id) {
        newsService.updateNew(setRespuesta, payload, params.id, setRespuestaSolicitudFallida);
      } else {
        newsService.createNew(setRespuesta, payload, setRespuestaSolicitudFallida);
      }
    }
  };

  useEffect(() => {
    if (respuesta) {
      setCargando(false);
      setRespuestaSolicitudValid(true);
    }
  }, [respuesta]);

  function setFechaActual() {
    function join(t, a, s) {
      function format(m) {
        let f = new Intl.DateTimeFormat("en", m);
        return f.format(t);
      }
      return a.map(format).join(s);
    }

    let a = [{ year: "numeric" }, { month: "numeric" }, { day: "numeric" }];
    let s = join(new Date(), a, "-");
    let castFecha = s.split("-");
    if (castFecha[1] < 10) {
      castFecha[1] = "0" + castFecha[1];
    }
    if (castFecha[2] < 10) {
      castFecha[2] = "0" + castFecha[2];
    }
    var fechaNew = castFecha[0] + "-" + castFecha[1] + "-" + castFecha[2];
    return fechaNew;
  }
  const fechaInicioSelect = () => {
    var fechaInicio = document.getElementById("fechaInicio");
    var fechaTermino = document.getElementById("fechaTermino");

    if (!params.id) fechaInicio.min = setFechaActual();

    fechaTermino.min = setFechaActual();
    if (Date.parse(fechaTermino.value) < Date.parse(fechaInicio.value)) {
      setStatus(false);
      setMessage("La fecha de término no debe ser menor a la fecha de inicio.");
      setModalShow(true);
      setFechaTerminoError(true);
      setValidacionFecha(false);
    } else {
      setStatus(true);
      setMessage("");
      setModalShow(false);
      setFechaTerminoError(false);
      setValidacionFecha(true);
    }
  };

  const fechaTerminoSelect = () => {
    var fechaTermino = document.getElementById("fechaTermino");
    var fechaInicio = document.getElementById("fechaInicio");

    if (!params.id) fechaInicio.min = setFechaActual();

    fechaTermino.min = setFechaActual();

    if (Date.parse(fechaTermino.value) < Date.parse(fechaInicio.value)) {
      setStatus(false);
      setMessage("La fecha de término no debe ser menor a la fecha de inicio.");
      setModalShow(true);
      setFechaTerminoError(true);
      setValidacionFecha(false);
    } else {
      setStatus(true);
      setMessage("");
      setModalShow(false);
      setFechaTerminoError(false);
      setValidacionFecha(true);
    }
  };
  const renderHTML = (rawHTML, string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  const agregarGrupo = () => {
    setItemGroup(itemGroup + 1);
    setArregloGroup([...arregloGroup, itemGroup]);
  };

  const eliminarGrupo = (e, indice) => {
    e.preventDefault();
    let arreglo = arregloGroup;

    let arreglo2 = arreglo.map((element, index) => {
      if (index === indice) {
        element = null;
      }
      return element;
    });

    setArregloGroup(arreglo2);
  };

  return (
    <>
      <nav class="breadcrumbs" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <Link to={"/inicio"}>
              <i class="fa fa-home" aria-hidden="true"></i> Inicio
            </Link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Crear-noticias
          </li>
        </ol>
      </nav>
      <form className="needs-validation" onSubmit={(e) => handleSubmit(e)}>
        <section class="crear-nueva-noticia">
          <h1>Crear Nueva Noticia</h1>

          <div class="row">
            <div class="col-12 col-xxl-8 d-flex">
              {/*<!--Datos de Noticia */}
              <div class="box mb-4 align-items-stretch w-100">
                <h2 class="h4 tit-section">Datos de Noticia</h2>
                <div class="data-list-box data-list-box-align-middle data-list-box-form">
                  <ul class="list-column-1 list-unstyled">
                    <li>
                      <span class="data-list-box__title">Título</span>
                      <span class="data-list-box__text">
                        <input
                          id="titulo"
                          onChange={(e) => setTituloNoticia(e.target.value)}
                          required
                          defaultValue={valorNoticia && valorNoticia.title}
                          class="form-control"
                          type="text"
                          placeholder="Ingrese título"
                        />
                      </span>
                    </li>
                   
                    <li class="border-0">
                      <span class="data-list-box__title">Vigencia</span>
                      <span class="data-list-box__text d-flex align-items-center">
                        Desde{" "}
                        <input
                          id="fechaInicio"
                          label="Vigente desde"
                          required
                          onClick={(event) => fechaInicioSelect(event)}
                          onChange={(event) => fechaInicioSelect(event)}
                          type="date"
                          class="form-control ms-3"
                          placeholder="dd/mm/aaaa"
                          defaultValue={valorNoticia && valorNoticia.date_from}
                        />
                        <i
                          class="fa fa-calendar fa-fw fa-lg color-primary ms-3"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </li>
                    <li>
                      <span class="data-list-box__title"></span>
                      <span class="data-list-box__text d-flex align-items-center">
                        Hasta{" "}
                        <input
                          id="fechaTermino"
                          label="Vigente Hasta"
                          required
                          onClick={(event) => fechaTerminoSelect(event)}
                          onChange={(event) => fechaTerminoSelect(event)}
                          type="date"
                          style={{ width: "100%" }}
                          class="form-control ms-3"
                          placeholder="dd/mm/aaaa"
                          defaultValue={valorNoticia && valorNoticia.date_to}
                        />
                        <i
                          class="fa fa-calendar fa-fw fa-lg color-primary ms-3"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/*<!--Fin Datos de Noticia */}
            </div>

            <div class="col-12 col-xxl-4 d-flex">
              {/*<!--Adjuntar Archivos */}
              <div class="box mb-4 align-items-stretch w-100">
                <h2 class="h4 tit-section">Adjuntar Archivos</h2>
                <ul class="upload-files list-unstyled">
                  <li>
                    <span
                      style={{ width: "100%" }}
                      className="data-list-box__text"
                    >
                      <label for="selectImage1">
                        Seleccione una imagen tamaño banner
                      </label>

                      <input
                        accept="image/*"
                        // required
                        style={{ width: "100%" }}
                        files={imgData}
                        id="selectImage1"
                        className="form-control"
                        type="file"
                        onChange={(e) => onChangePicture(e)}
                      ></input>

                      {imgData !== null ? (
                        <>
                          <br></br>
                          <Button
                            variant="outlined"
                            onClick={deletePicture}
                            startIcon={<DeleteIcon />}
                          >
                            Borrar imagen tamaño banner
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                      <p>Se recomienda una dimensión de 1000 x 200 píxeles. </p>

                      {imgData !== null ? (
                        <div className="d-flex justify-content-center align-items-end">
                          <picture>
                            <source
                              media="(max-width: 767px)"
                              srcSet={imgData != undefined ? imgData : null}
                            />
                            <source
                              media="(min-width: 768px)"
                              srcSet={imgData != undefined ? imgData : null}
                            />
                            <img
                              style={{
                                width: "1000px",
                                height: "200px",
                                objectFit: "cover",
                              }}
                              src={
                                imgData != undefined && imgData != undefined
                                  ? imgData
                                  : null
                              }
                              className="d-block w-100 rounded-4"
                              alt=""
                            />
                          </picture>
                        </div>
                      ) : (
                        <></>
                      )}
                    </span>
                  </li>
                  <li>
                    <span
                      style={{ width: "100%" }}
                      className="data-list-box__text"
                    >
                      <label for="selectImage1">
                        Seleccione una imagen tamaño responsive
                      </label>

                      <input
                        accept="image/*"
                        style={{ width: "100%" }}
                        files={imgData2}
                        id="selectImage2"
                        // required
                        className="form-control"
                        type="file"
                        onChange={(e) => onChangePicture2(e)}
                      ></input>

                      {imgData2 !== null ? (
                        <>
                          <br></br>
                          <Button
                            variant="outlined"
                            onClick={deletePicture2}
                            startIcon={<DeleteIcon />}
                          >
                            Borrar imagen tamaño responsive
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                      <p>Se recomienda una dimensión de 500 x 200 píxeles. </p>

                      {imgData2 !== null ? (
                        <div className="d-flex justify-content-center align-items-end">
                          <picture>
                            <source
                              media="(max-width: 767px)"
                              srcSet={imgData2 != undefined ? imgData2 : null}
                            />
                            <source
                              media="(min-width: 768px)"
                              srcSet={imgData2 != undefined ? imgData2 : null}
                            />
                            <img
                              style={{
                                width: "1000px",
                                height: "200px",
                                objectFit: "cover",
                              }}
                              src={
                                imgData2 != undefined && imgData2 != undefined
                                  ? imgData2
                                  : null
                              }
                              className="d-block w-100 rounded-4"
                              alt=""
                            />
                          </picture>
                        </div>
                      ) : (
                        <></>
                      )}
                    </span>
                  </li>
                  <li>
                    <span
                      style={{ width: "100%" }}
                      className="data-list-box__text"
                    >
                      <label for="selectImage3">Seleccione un archivo</label>

                      <input
                        accept=".pdf"
                        style={{ width: "100%" }}
                        files={pdf}
                        id="selectImage3"
                        className="form-control"
                        type="file"
                        onChange={(e) => onChangePicture3(e)}
                      ></input>
                      {pdf !== null ? (
                        <>
                          <br></br>
                          <Button
                            variant="outlined"
                            onClick={deletePicture3}
                            startIcon={<DeleteIcon />}
                          >
                            Borrar archivo
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                    </span>
                  </li>
                </ul>
              </div>
              {/*<!--Fin Adjuntar Archivos */}
            </div>
          </div>

          {/*<!--Contenidos */}
          <div class="box mb-4">
            <h2 class="h4 tit-section">Contenido</h2>
            <div class="row">
              <div class="col-12 col-md-12">
                {/*<!--Editor de texto */}
                <span style={{ width: "100%" }} className="data-list-box__text">
                  <ReactQuill
                    theme="snow"
                    modules={modulos}
                    value={valorTexto}
                    onChange={setValorTexto}
                    formats={formatos}
                  ></ReactQuill>
                </span>

                <div class="row justify-content-end mt-3">
                  <div class="col-auto">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        defaultChecked
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        Publicado
                      </label>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        Borrador
                      </label>
                    </div>
                  </div>
                </div>
                {/*<!--Fin Editor de texto */}
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between">
            <a
              class="btn btn-link text-uppercase"
              data-bs-toggle="modal"
              data-bs-target="#back"
            >
              Volver
            </a>
            <div>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#previsualizar"
              >
                Previsualizar{" "}
                <i
                  class="fa fa-chevron-right fa-fw fa-xs"
                  aria-hidden="true"
                ></i>
              </button>
              <button
                type="submit"
                disabled={valorTexto !== "" && imgData && imgData2 ? false : true}
                class="btn btn-degradado ms-3"
              >
                Guardar{" "}
                <i
                  class="fa fa-chevron-right fa-fw fa-xs"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </div>

          <div
            class="modal fade"
            id="previsualizar"
            tabindex="-1"
            aria-labelledby="previsualizar"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-xl modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header border-0 pb-0">
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <h2 class="h5 text-uppercase">Vista previa</h2>
                  <img
                    class="rounded rounded-4 mb-3 img-fluid"
                    src="assets/images/noticia-home.jpg"
                    alt=""
                  />
                  <div className="d-flex justify-content-left align-items-end">
                    <img
                      style={{
                        width: "1000px",
                        height: "200px",
                        objectFit: "cover",
                      }}
                      className="playerProfilePic_home_tile"
                      src={imgData}
                    />
                  </div>
                  <h3 className="d-flex justify-content-left align-items-end">
                    {titutoloNoticia}
                  </h3>
                  {renderHTML(valorTexto)}
                </div>
                <div class="modal-footer border-0 pt-0">
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Aceptar{" "}
                    <i
                      class="fa fa-chevron-right fa-fw fa-xs"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal fade"
            id="publicar-noticia"
            tabindex="-1"
            aria-labelledby="publicar-noticia"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header border-0 pb-0">
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body text-center py-4">
                  <h2 class="h5">
                    ¿Estás seguro que deseas <br /> publicar la noticia?
                  </h2>
                </div>
                <div class="modal-footer border-0 pt-0 pb-5 justify-content-center">
                  <button
                    type="button"
                    class="btn btn-primary px-5"
                    data-bs-dismiss="modal"
                  >
                    No{" "}
                    <i
                      class="fa fa-chevron-right fa-fw fa-xs"
                      aria-hidden="true"
                    ></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-degradado px-5"
                    data-bs-dismiss="modal"
                    data-bs-toggle="modal"
                    data-bs-target="#success"
                  >
                    Sí{" "}
                    <i
                      class="fa fa-chevron-right fa-fw fa-xs"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal fade"
            id="back"
            tabindex="-1"
            aria-labelledby="back"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header border-0 pb-0">
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body text-center py-4">
                  <h2 class="h5">
                    ¿Estás seguro que deseas volver al listado? <br /> Se
                    perderán los datos ingresados.
                  </h2>
                </div>
                <div class="modal-footer border-0 pt-0 pb-5 justify-content-center">
                  <button
                    type="button"
                    class="btn btn-primary px-5"
                    data-bs-dismiss="modal"
                  >
                    No{" "}
                    <i
                      class="fa fa-chevron-right fa-fw fa-xs"
                      aria-hidden="true"
                    ></i>
                  </button>
                  <button
                    onClick={() => navigate("/canal-de-comunicacion")}
                    type="button"
                    class="btn btn-degradado px-5"
                    data-bs-dismiss="modal"
                  >
                    Sí{" "}
                    <i
                      class="fa fa-chevron-right fa-fw fa-xs"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal fade"
            id="success"
            tabindex="-1"
            aria-labelledby="success"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header border-0 pb-0">
                  <button
                    onClick={() => navigate("/canal-de-comunicacion")}
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body text-center py-4 pt-0 pb-1">
                  <i
                    class="fa fa-check-circle-o color-orange fa-3x mb-2"
                    aria-hidden="true"
                  ></i>
                  <h2 class="h5">
                    Su noticia ha sido guardada
                    <br /> correctamente.
                  </h2>
                </div>
                <div class="modal-footer border-0 pt-0 pb-5 justify-content-center">
                  <button
                    onClick={() => navigate("/canal-de-comunicacion")}
                    type="button"
                    class="btn btn-degradado px-5"
                    data-bs-dismiss="modal"
                  >
                    Aceptar{" "}
                    <i
                      class="fa fa-chevron-right fa-fw fa-xs"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/*<!--fin Contenidos */}
        </section>
      </form>
      {cargando ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0"></div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-download color-orange fa-3x mb-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">Enviando solicitud..</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {respuestaSolicitudValid ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div class="modal-header border-0 pb-0">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => handleClose(e)}
                    ></button>
                  </div>
                  <div class="modal-body text-center py-4 pt-0 pb-1">
                    <i
                      class="fa fa-check-circle-o color-orange fa-3x mb-2"
                      aria-hidden="true"
                    ></i>

                    <h2 class="h5">
                      Su noticia ha sido creada
                      <br /> correctamente.
                    </h2>
                  </div>
                  <div class="modal-footer border-0 pt-0 pb-5 justify-content-center">
                    <button
                      onClick={() => navigate("/canal-de-comunicacion")}
                      type="button"
                      class="btn btn-degradado px-5"
                      data-bs-dismiss="modal"
                    >
                      Aceptar{" "}
                      <i
                        class="fa fa-chevron-right fa-fw fa-xs"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {respuestaSolicitudFallida ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div class="modal-header border-0 pb-0">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => handleClose(e)}
                    ></button>
                  </div>
                  <div class="modal-body text-center py-4 pt-0 pb-1">
                    <i
                      class="fa fa-times-circle-o color-orange fa-3x mb-2"
                      aria-hidden="true"
                    ></i>

                    <h2 class="h5">
                      Ha ocurrido un error.
                    </h2>
                  </div>
                  <div class="modal-footer border-0 pt-0 pb-5 justify-content-center">
                    <button
                      onClick={(e) => handleClose(e)}
                      type="button"
                      class="btn btn-degradado px-5"
                      data-bs-dismiss="modal"
                    >
                      Aceptar{" "}
                      <i
                        class="fa fa-chevron-right fa-fw fa-xs"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {!status ? (
        <div>
          <div
            visible="false"
            style={{
              width: "100%",
              textAlign: "center",
              padding: "5px",
              background: "pink",
              border: "0",
              borderRadius: "5px",
              marginBottom: "10px",
            }}
          >
            <p
              style={{ color: "red", textAlign: "center", width: "100%" }}
              className="forgot-password"
            >
              {message}
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
}
