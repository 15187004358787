import React, { useEffect, useState } from 'react';
import MiEquipoService from '../../../services/mi-equipo/mi-equipo-service';

const useCartolaVacaciones = (emplid, country) => {
    const [cartolaVacaciones, setCartolaVacaciones] = useState();
    const [statusCartolaVacaciones, setStatusCartolaVacaciones] = useState();
    const [loadingCartolaVacaciones, setLoadingCartolaVacaciones] = useState(true);

    const getCartolaVacaciones = async () => {
        const data = await MiEquipoService.getCartolaVacaciones(emplid, country);
        setCartolaVacaciones(data.data);
        setStatusCartolaVacaciones(data.status);
        setLoadingCartolaVacaciones(false);
    };

    useEffect(() => {
        getCartolaVacaciones();
    }, []);

    return { cartolaVacaciones, statusCartolaVacaciones, loadingCartolaVacaciones };
};

export default useCartolaVacaciones;