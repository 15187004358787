/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react'
import RequestPlateTable from './requestPlateTable';
import RequestPlateService from '../../services/request-plate/request-plate-service'
import Swal from 'sweetalert2';
import moment from 'moment'
import { Link } from 'react-router-dom';
import styles from "../../resources/css/style.module.css"

const RequestPlate = () => {
  const [toSubmit, setToSubmit] = useState([])
  const [info, setInfo] = useState([])
  const [loadingAbsences, setLoadingAbsences] = useState(false)
  const [noData, setNoData] = useState(false)

  //const [filteredData, setFilteredInfo] = useState([])


  // estados de rechazo/aprobacion vacaciones
  const [actionData, setActionData] = useState(null)

  const [noDataAction, setNoDataAction] = useState(false)
  const [loadingApproveAction, setLoadingApproveAction] = useState(false)
  const [loadingRejectAction, setLoadingRejectAction] = useState(false)


  const [selectedRows, setSelectedRows] = useState([])
  const [stateOptions, setStateOptions] = useState([]);
  const [typesOptions, setTypesOptions] = useState([{ value: 'all', description: 'Todos' }]);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [statusSelected, setStatusSelected] = useState('P');
  const [typeSelected, setTypeSelected] = useState('all');
  const [countrySelected, setCountrySelected] = useState('all');

  const filteredData = useMemo(() => info.filter(d =>
    (d.region === countrySelected || countrySelected === 'all') &&
    (d.type === typeSelected || typeSelected === 'all') &&
    (d.status === statusSelected || statusSelected === 'F')
  ), [statusSelected, typeSelected, countrySelected, info])

  const castType = (type) => {
    const types = {
      licenses: 'Licencia',
      vacations: 'Vacaciones',
      freeTime: 'Mi Tiempo'
    }

    return types[type];
  }

  const handleSetData = (data) => {
    setInfo(data);
    const uniqueTypes = [...new Set(data.map(d => d.type))];
    const types = uniqueTypes.map(t => ({
      value: t,
      description: castType(t)
    }));
    const allOption = { value: 'all', description: 'Todos' };
    setTypesOptions([allOption, ...types]);
  }

  const getFilters = async () => {
    const data = await RequestPlateService.getFilters();
    const { status, countries } = data;
    const allOption = { value: 'all', description: 'Todos' };
    setStateOptions(status);
    setCountriesOptions([allOption, ...countries]);
  }

  useEffect(() => {
    getFilters();
  }, []);

  // Funcion que trae objeto para enviar al post
  const action = (ausencias) => {
    const aus = ausencias.map(aus => JSON.parse(aus))
    setToSubmit(aus)
  }

  const onSelectRow = e => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedRows([...selectedRows, value]);
      return;
    }

    setSelectedRows(selectedRows.filter(sr => sr !== value));

  }

  const onSelectAll = e => {
    const { checked } = e.target;
    if (checked) {
      setSelectedRows(filteredData.filter(fd => fd.status === 'P').map(i => i.id.toString()));
      return;
    }

    setSelectedRows([]);
  }


  // Fetching de datos 
  useEffect(() => {
    getRequestList();
  }, [statusSelected])

  const getRequestList = async () => {
    try {
      setLoadingAbsences(true);
      const { data } = await RequestPlateService.getRequestPlateList(statusSelected);
      const sortedData = data.sort((a, b) => moment(b.beginDate, "AAAA-MM-DD").unix() - moment(a.beginDate, "AAAA-MM-DD").unix());
      handleSetData(sortedData);
    } catch (err) {
      console.log(err);
      setNoData(true);
    } finally {
      setLoadingAbsences(false);
    }
  }

  // Mensaje de error al enviar un post
  const handleStatusError = () => {
    setNoDataAction(false)
    setLoadingRejectAction(false)
    setLoadingApproveAction(false)
    Swal.fire({
      allowOutsideClick: false,
      position: 'center',
      title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
      html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo.</div>`,
      confirmButtonColor: '#0169b5',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("error")
      }
    })
  }
  // Mensaje de éxito al enviar un post
  const handleStatusSuccess = (message) => {
    setLoadingRejectAction(false)
    setLoadingApproveAction(false)
    setActionData(false)
    setLoadingAbsences(true)
    Swal.fire({
      allowOutsideClick: false,
      position: 'center',
      title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
      html: `<div style="text-align: center; font-size: 15px"> ${message} </div>`,
      confirmButtonColor: '#0169b5',
    }).then((result) => {
      if (result.isConfirmed) {
        getRequestList();
      }
    })
  }

  // Rechazar peticion de vacaciones
  const onRejectSubmit = async () => {
    setLoadingRejectAction(true)
    const rows = info
      .filter(x => selectedRows.includes(x.id.toString()))
      .map(r => ({
        ...r,
        id: r.id.toString(),
      }));

    try {

      const response = await RequestPlateService.rejectRequest(rows);
      setSelectedRows([]);
      handleStatusSuccess(response.data.message);
    } catch (err) {
      handleStatusError();
    } finally {
      setLoadingRejectAction(false);
    }
  }

  // Aprobar peticion de vacaciones
  const onApproveSubmit = async () => {
    setLoadingApproveAction(true)
    const rows = info
      .filter(x => selectedRows.includes(x.id.toString()))
      .map(r => ({
        ...r,
        id: r.id.toString(),
      }));
    try {
      const response = await RequestPlateService.approveRequest(rows);
      setSelectedRows([]);
      handleStatusSuccess(response.data.message);
    } catch (err) {
      handleStatusError();
    } finally {
      setLoadingApproveAction(false);
    }
  }

  return (
    <>
      <nav className="breadcrumbs" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/inicio">
              <i style={{ pointerEvents: "none" }} className="fa fa-home color-orange" aria-hidden="true"></i> Inicio
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Centro de Aprobaciones
          </li>
        </ol>
      </nav>
      <section className="noticias mb-3 p-0">
        <div className="header-page-container">
          <h1 className="mb-3"> Centro de Aprobaciones</h1>
          <div className="buttons">
            <button className={`button reject ${styles['font-16']}`} onClick={onRejectSubmit} disabled={selectedRows.length === 0}>
              Rechazar {loadingRejectAction ? <div className="spinner-border spinner-border-sm"></div> : <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>}
            </button>
            <button className={`button save ${styles['font-16']}`} onClick={onApproveSubmit} disabled={selectedRows.length === 0}>
              Aprobar {loadingApproveAction ? <div className="spinner-border spinner-border-sm"></div> : <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>}
            </button>
          </div>
        </div>
        <div className="col-12 position-relative mb-0">
          {/* <a className="btn btn-degradado btn-absolute" href="#">Ver más <i className="fa fa-chevron-right" aria-hidden="true"></i></a> */}
        </div>
      </section>

      <section className="container p-0">
        <div className="card-box mb-3">
          <div><h4 className="form-title tit aproove-tit">Filtrar</h4></div>
          <div className="row offset-sm-0">
            <div className="mb-2 col-lg-4">
              <label className={`col-sm-2 col-form-label fw-bold ${styles['font-16']}`}>Pais</label>
              <div className="col">
                <select className="form-select aproove-select" value={countrySelected} onChange={e => setCountrySelected(e.target.value)}>
                  {countriesOptions && countriesOptions.map(t => {
                    return <option key={t.value} value={t.value}>{t.description}</option>
                  })}
                </select>
              </div>
            </div>
            <div className="mb-2 col-lg-4">
              <label className={`col-sm-2 col-form-label fw-bold ${styles['font-16']}`}>Tipo</label>
              <div className="col">
                <select className="form-select aproove-select" value={typeSelected} onChange={e => setTypeSelected(e.target.value)}>
                  {typesOptions && typesOptions.map(t => {
                    return <option key={t.value} value={t.value}>{t.description}</option>
                  })}
                </select>
              </div>
            </div>
            <div className="mb-2 mb-sm-4 col-lg-4">
              <label className={`col-sm-2 col-form-label fw-bold ${styles['font-16']}`}>Estado</label>
              <select className="form-select aproove-select" value={statusSelected} onChange={e => setStatusSelected(e.target.value)}>
                {stateOptions && stateOptions.map((state, i) => {
                  return <option key={i} value={state.value} defaultValue={state.value === 'F'}>{state.description}</option>
                })}
              </select>
            </div>
          </div>
        </div>

        {
          !loadingAbsences ? <RequestPlateTable action={action} info={filteredData} onSelectAll={onSelectAll} onSelectRow={onSelectRow} selectedRows={selectedRows} getRequestList={getRequestList}  statusSelected={statusSelected} typeSelected={typeSelected} countrySelected={countrySelected} /> : <div data-testid="loading" className="full-spinner">
            <div className="spinner-border text-primary"></div>
          </div>
        }


        {noDataAction ? handleStatusError() : null}
        {actionData ? handleStatusSuccess() : null}
      </section >
    </>
  )

}

export default RequestPlate;
