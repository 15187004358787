import React from 'react'
import CardLicencias from './card-licencias-arg';
import LicenciasHistorico from './licencias-historico';
import { Link, Routes, Route, useLocation } from 'react-router-dom';

const Licencias = () => {

    const location = useLocation();

    return (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home color-orange" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        {location.pathname && location.pathname.split('/')[location.pathname.split('/').length-1] == 'historico' ? 'Histórico Licencias' : 'Licencias'}
                    </li>
                </ol>
            </nav>
            <section className="noticias mb-3">
                <h1>{location.pathname && location.pathname.split('/')[location.pathname.split('/').length-1] == 'historico' ? 'Histórico Licencias' : 'Licencias'}</h1>
                <div className="col-12 position-relative mb-0">
                    {/* <a className="btn btn-degradado btn-absolute" href="#">Ver más <i className="fa fa-chevron-right" aria-hidden="true"></i></a> */}
                </div>
            </section>
            <div className='container p-0'>
                <div className="row">
                    <Routes>
                        <Route path="" element={<CardLicencias></CardLicencias>}></Route>
                    </Routes>
                    <Routes>
                        <Route path="historico" element={<LicenciasHistorico></LicenciasHistorico>}></Route>
                    </Routes>

                </div>
            </div>

        </>
    )
}

export default Licencias