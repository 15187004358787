import React, { useState, useEffect } from 'react';
import MiEquipoService from '../../../services/mi-equipo/mi-equipo-service';

const useHistorialUbicaciones = () => {
    const [historialPosUbi, setHistorialPosUbi] = useState();

    const getHistorialPosicionesUbicaciones = async () => {
        const data = await MiEquipoService.getHistorialPosicionesUbicaciones();
        setHistorialPosUbi(data);
    }

    useEffect(() => {
        getHistorialPosicionesUbicaciones();
    }, []);

    return { historialPosUbi };
};

export default useHistorialUbicaciones;