import Auth from "@aws-amplify/auth";
import { urls } from "../resources/foo/api-endpoints";
const PanelAdministracionService = {
    setState: (request, setStatus) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json; charset=utf-8', Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: JSON.stringify(request)
            };
            fetch(urls.setState , requestOptions)
                .then(response => response.json())
                .then(data => {
                    setStatus(data);
                    document.getElementById("status-btn").click();
                })
                .catch(error => console.log('error', error));
        }).catch((err) => console.log(err));
    },
    getState: (setData, unidad, pais, modulo, bandera) => {
        Auth.currentSession().then((auth) => {
            let body = {
                request: {}
            }
            if (modulo === "all") {
                body.request.modulo = "all";
            }
            else {
                if (pais) {
                    if (unidad) {
                        if (bandera) {
                            body.request.unidad = unidad;
                            body.request.pais = pais;
                            body.request.modulo = modulo;
                            body.request.bandera = bandera;
                        }
                        else {
                            body.request.unidad = unidad;
                            body.request.pais = pais;
                            body.request.modulo = modulo;
                        }
                    }
                    else {
                        body.request.pais = pais;
                        body.request.modulo = modulo;
                    }
                }
                else {
                    body.request.modulo = modulo;
                }
            }
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: JSON.stringify(body),
                "Access-Control-Allow-Origin": "*"
            };
            fetch(urls.getState , requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.response.status === 0) setData(false);
                    setData(data.response.estados);
                })
                .catch(error => console.log('error', error));
        }).catch((err) => console.log(err));
    },
    getAppState: async () => {
        try {
            const auth = await Auth.currentSession()
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json; charset=utf-8', Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            return await fetch(urls.getAppState, auth && requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.status === 0) return false
                    return data.components
                })
                .catch(error => {
                    console.log('error', error); 
                    throw error;
                });
            
        } catch (error) {
            console.log(error)
            return false
        }
    },
    getUBxPais: (setStatus, setData, syncBUN) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json; charset=utf-8', Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urls.getUBxPais + "CHL" , requestOptions)
                .then(result => JSON.parse(result))
                .then(data => {
                    if (data.message !== undefined) data = false
                    let temp = [], resp = [];
                    data.response.unidades.map(unito => {
                        if (unito.banderas) {
                            if (!Array.isArray(unito.banderas)) {
                                unito.banderas = [unito.banderas];
                            }
                            unito.banderas.map(bandera => {
                                temp.push({
                                    pais: data.response.country,
                                    unidad: unito.valor,
                                    bandera: bandera.valor,
                                })
                                resp.push({
                                    pais: data.response.country,
                                    unidad: {
                                        nombre: unito.descr,
                                        codigo: unito.valor
                                    },
                                    bandera: {
                                        nombre: bandera.descr,
                                        codigo: bandera.valor
                                    }
                                })
                            })
                        }
                        else {
                            temp.push({
                                pais: data.response.country,
                                unidad: unito.valor,
                                bandera: "",
                            })
                            resp.push({
                                pais: data.response.country,
                                unidad: {
                                    nombre: unito.descr,
                                    codigo: unito.valor
                                },
                                bandera: ""
                            })
                        }
                    })
                    setData(resp);
                    syncBUN(temp, setStatus);
                })
                .catch(error => console.log('error', error));
        }).catch((err) => console.log(err));
    },
    syncPUB: (valores, setStatus) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json; charset=utf-8', Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: JSON.stringify({
                    request: {
                        "valores": valores
                    }
                })
            };
            fetch(urls.syncPUB , requestOptions)
                .then(response => response.json())
                .then(data => {
                    setStatus(data.response.status)
                })
                .catch(error => console.log('error', error));
        }).catch((err) => console.log(err));
    },
    getRol: (setData) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json; charset=utf-8', Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urls.getRol , requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.message !== undefined) data = false
    
                    setData(false);
                    setData(data.response.roles);
                }).catch(error => console.log('error', error));
        }).catch((err) => console.log(err));
    },
    setRol: (request, setStatus) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json; charset=utf-8', Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: JSON.stringify(request)
            };
            fetch(urls.setRol , requestOptions)
                .then(response => response.json())
                .then(data => {
                    setStatus(data);
                    document.getElementById("btn-success").click();
                }).catch(error => console.log('error', error));
        }).catch((err) => console.log(err));
    },
    getPaises: (setData) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json; charset=utf-8', Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urls.getPaises , requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.message !== undefined) data = false
                    setData(data.response.paises);
                }).catch(error => console.log('error', error));
        }).catch((err) => console.log(err));
    },
    getUserIdentity: (setData) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json; charset=utf-8', Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urls.getUserIdentity , requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.message !== undefined) {
                        setData(false);
                    }
                    setData(data.users[0]);
                }).catch(error => console.log('error', error));
        }).catch((err) => console.log(err));
    }
}

export default PanelAdministracionService;