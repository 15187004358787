import { useSubscriptionContext } from "./context";

export const SubscriptionActions = () => {
  const { checkSubsTerms, disabledDelete, handleModalConfirm } =
    useSubscriptionContext();

  return (
    <div className="pt-4 mt-4 border-top">
      <div className="form-check mb-2 ms-1 mb-3">
        <input
          className="form-check-input h5"
          type="checkbox"
          onChange={checkSubsTerms}
        />
        <label
          className="form-check-label fw-normal color-primary ms-2"
          htmlFor="consentimiento"
        >
          <strong>
            Doy mi consentimiento para que Cencosud envíe información de mi
            interés como Noticias, Comunicados, Novedades sobre Beneficios,
            Servicios u otros, a mi correo personal, por mensaje de Texto o
            WhatsApp a mi número de teléfono y correos personal registrados en
            los sistemas de RR.HH.
          </strong>
        </label>
      </div>
      <div className="d-flex justify-content-end mt-4">
        <button
          className="btn btn-primary"
          onClick={handleModalConfirm}
          disabled={disabledDelete}
        >
          Eliminar todas mis suscripciones
        </button>
      </div>
    </div>
  );
};
