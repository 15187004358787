import Auth from "@aws-amplify/auth";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import * as t from "io-ts";
import { urlsAws } from "../../../../resources/foo/api-endpoints";
import { fetchGetNewsListCodec, fetchGetCategoryListCodec, ResponseNewsList, ResponseCategoryList, fetchPostNewsCodec, PostNews, bodyPostCategoryNews, GetParamsNews, fetchGetParamsNewsCodec, fetchGetNewsByIDCodec, GetNewsByIDProps, bodyPostNewsList, fetchGetBannersCodec, GetBanner, GetNewsCard, fetchGetNewsCardCodec, GetPhotoNews, fetchGetPhotoNewsCodec, PhotoNewsProps, GetBannerHome, fetchGetBannersCodecHome, GetFileNews, fetchGetFileNewsCodec, bodyPostCommentNews, GetCommentNews, fetchGetCommentNews } from "../interfaces";
import { HttpRequestError, HttpContentTypeError, HttpResponseStatusError, HttpClientEnv, HttpError } from "../../../../services/apiServices/interfaces";
import { getJson, httpClientEnv, } from "../../../../services/apiServices/httpClient";
import * as RD from "@devexperts/remote-data-ts";
import { RemoteData } from "@devexperts/remote-data-ts";
import { Dispatch, SetStateAction } from "react";

const fetchData = async (url: string, method: string, body?: string) => {
    const auth = await Auth.currentSession();
    const requestOptions = {
        method,
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: auth.getIdToken().getJwtToken(),
            AccessToken: auth.getAccessToken().getJwtToken(),
        },
        body: body ? JSON.stringify(body) : null,
    };
    return await fetch(url, requestOptions).then((response) => response.json()).catch((error) => { console.log(error); return false; });
};

export const getItemsCache = async (url: string) => {
    const dataCache = await caches.match(url)
        .then((response) => {
            if (response) {
                return response.json();
            }
        })
        .then((data) => {
            if (data) {
                return data;
            }
            return false;
        })
        .catch(() => false);
    return dataCache;
}

export const handleCacheNewsList = async (key: string, data: RemoteData<HttpError, GetNewsByIDProps> | RemoteData<HttpError, GetBanner> | RemoteData<HttpError, GetBannerHome> | RemoteData<HttpError, GetNewsCard> | string | PhotoNewsProps) => {
    const cache = await caches.open("api-cache");
    cache.put(key, new Response(JSON.stringify(data)));
};

export const getNewsList = async () => {
    const params: string = ""
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: "GET",
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        ResponseNewsList
    > = getJson(urlsAws.getNewsList, params, requestOptions, fetchGetNewsListCodec.decode)
    return response;
};

export const getCategoryNewsList = async () => {
    const params: string = ""
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: "GET",
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        ResponseCategoryList
    > = getJson(urlsAws.getCategoryNewsList, params, requestOptions, fetchGetCategoryListCodec.decode)
    return response;
};

export const crudCategoryNews = async (body: bodyPostCategoryNews, type: string) => {
    const params = "";
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: type,
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
        body: JSON.stringify(body)
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        PostNews
    > = getJson(urlsAws.crudCategoryNews, params, requestOptions, fetchPostNewsCodec.decode)
    return response;
};

export const crudNews = async (body: bodyPostNewsList, type: string) => {
    const params = "";
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: type,
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
        body: JSON.stringify(body)
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        PostNews
    > = getJson(urlsAws.crudNews, params, requestOptions, fetchPostNewsCodec.decode)
    return response;
};

export const crudCommentNews = async (body: bodyPostCommentNews, type: string) => {
    const params = "";
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: type,
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
        body: JSON.stringify(body)
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        GetCommentNews
    > = getJson(urlsAws.crudCommentNews, params, requestOptions, fetchGetCommentNews.decode)
    return response;
};

export const getNewsByID = async (idNews: number, view?: boolean) => {
    const paramView = view ? '&view=' + view : '';
    const params = `?id=${idNews}` + paramView;
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: "GET",
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        GetNewsByIDProps
    > = getJson(urlsAws.crudNews, params, requestOptions, fetchGetNewsByIDCodec.decode)
    return response;
};

export const getFileByID = async (idNews: number) => {
    const params = `?id=${idNews}`;
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: "GET",
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        GetFileNews
    > = getJson(urlsAws.getFileNews, params, requestOptions, fetchGetFileNewsCodec.decode)
    return response;
};

export const getParamsNews = async () => {
    const params = "";
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        GetParamsNews
    > = getJson(urlsAws.getParamsNews, params, requestOptions, fetchGetParamsNewsCodec.decode)
    return response;
};

export const getBanners = async () => {
    const params = "";
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        GetBanner
    > = getJson(urlsAws.getBannerNewsList, params, requestOptions, fetchGetBannersCodec.decode)
    return response;
};

export const getBannersHome = async () => {
    const params = "";
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        GetBannerHome
    > = getJson(urlsAws.getBannerNewsHome, params, requestOptions, fetchGetBannersCodecHome.decode)
    return response;
};

export const getNewsCard = async (category?: number) => {
    const params = category ? `?category=${category}` : '';
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        GetNewsCard
    > = getJson(urlsAws.getNewsCard, params, requestOptions, fetchGetNewsCardCodec.decode)
    return response;
};

export const likeNews = async (body: bodyPostNewsList, like: boolean) => {
    const likes = like ? '&like=' + like : '';
    const params = "?id=" + body.id + likes;
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
        body: JSON.stringify(body)
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        PostNews
    > = getJson(urlsAws.postLikeNews, params, requestOptions, fetchPostNewsCodec.decode)
    return response;
};

export const getPhotoNews = async (ids: number[], banner?: boolean) => {
    const params = banner ? "?banner=" + banner : "";
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
        body: JSON.stringify({ ids }),
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        GetPhotoNews
    > = getJson(urlsAws.getPhotoNews, params, requestOptions, fetchGetPhotoNewsCodec.decode)
    return response;
};

export const getExcelResumen = async (id: number, setStatusDownload: Dispatch<SetStateAction<number>>, refLoadClose: any) => {
    const data = await fetchData(`${urlsAws.getExcelNewsList}?id=${id}`, "GET");
    if (data && data.response && data.response.valid === 1) {
        const downloadLink = document.createElement("a");
        downloadLink.href = data.response.file;
        downloadLink.download = `Resumen_noticia_${id}.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    } else {
        setStatusDownload(1)
    }
    setTimeout(() => {
        refLoadClose && refLoadClose.current && refLoadClose.current.click();
    }, 500)
};

export const getExcelAllResumen = async (setStatusDownload: Dispatch<SetStateAction<number>>, filterCountry: string, filterCategory: number, startDateFilter: string, endDateFilter: string, filterStatus: string, refLoadClose: any) => {
    const url = `${urlsAws.getExcelNewsList}?country=${filterCountry}&category=${filterCategory == 0 ? "" : filterCategory}&date_from=${startDateFilter}&date_to=${endDateFilter}&status=${filterStatus}`;
    const data = await fetchData(url, "GET");
    if (data && data.response && data.response.valid === 1) {
        const downloadLink = document.createElement("a");
        downloadLink.href = data.response.file;
        downloadLink.download = `Resumen_noticias.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    } else {
        setStatusDownload(1)
    }

    setTimeout(() => {
        refLoadClose && refLoadClose.current && refLoadClose.current.click();
    }, 500)
};

export const existsPhotoIds = async (arrayIds: number[]) => {
    let newArrayEmpl = arrayIds.map(async (ph) => {
        const response = await caches.match(urlsAws.getPhotoNews + "?id=" + ph);
        if (!response) {
            return ph;
        }
        return null;
    });
    const results = await Promise.all(newArrayEmpl);
    return results.filter((ob) => ob !== null) as number[];
};

export const getRemotePhotosNewsList = async (arrayIds: number[], setRemoteData: Dispatch<SetStateAction<RemoteData<HttpError, GetPhotoNews>>>, setImgCard?: Dispatch<SetStateAction<string>>, banner?: boolean, setImgBanner?: Dispatch<SetStateAction<string>>) => {

    RTE.run(await getPhotoNews(arrayIds, banner), httpClientEnv)
        .then(E.fold(e => { return setRemoteData(RD.failure(e)) }, a => {
            let data = RD.success(a)
            if (data._tag === 'RemoteSuccess' && data.value.response.photo.length > 0) {
                data.value.response.photo.map((ph) => {
                    setImgCard && setImgCard(ph.img_card)
                    if (banner && setImgBanner && ph.img_banner) {
                        setImgBanner(ph.img_banner)
                    }
                    handleCacheNewsList(urlsAws.getPhotoNews + "?id=" + ph.id, ph);
                })
                return setRemoteData(RD.success(a));
            } else {
                return setRemoteData(RD.failure({ tag: 'httpRequestError', error: 'error GetNewsCard' }));
            }
        }))
}

export const getRemoteAllBanners = async (setRemoteData: Dispatch<SetStateAction<RemoteData<HttpError, GetBanner>>>) => {
    setRemoteData(RD.pending)
    const bannerResponse = await getItemsCache(urlsAws.getBannerNewsList);
    if (bannerResponse) {
        setRemoteData(bannerResponse);
    } else {
        RTE.run(await getBanners(), httpClientEnv)
            .then(E.fold(e => { return setRemoteData(RD.failure(e)) }, a => {
                let data = RD.success(a)
                if (data._tag === 'RemoteSuccess' && data.value.response.banner.length > 0) {
                    handleCacheNewsList(urlsAws.getBannerNewsList, data)
                    return setRemoteData(RD.success(a));
                } else {
                    return setRemoteData(RD.failure({ tag: 'httpRequestError', error: 'error GetBanner' }));
                }
            }))
    }
};


export const getRemoteBannersHome = async (setRemoteData: Dispatch<SetStateAction<RemoteData<HttpError, GetBannerHome>>>) => {
    setRemoteData(RD.pending)
    const bannerResponse = await getItemsCache(urlsAws.getBannerNewsHome);
    if (bannerResponse) {
        setRemoteData(bannerResponse);
    } else {
        RTE.run(await getBannersHome(), httpClientEnv)
            .then(E.fold(e => { return setRemoteData(RD.failure(e)) }, a => {
                let data = RD.success(a)
                if (data._tag === 'RemoteSuccess' && data.value.response.banner.length > 0) {
                    handleCacheNewsList(urlsAws.getBannerNewsHome, data)
                    return setRemoteData(RD.success(a));
                } else {
                    return setRemoteData(RD.failure({ tag: 'httpRequestError', error: 'error GetBannerHome' }));
                }
            }))
    }
};

