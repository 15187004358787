import { useState, useEffect, Children, useContext } from "react";
import { Context } from "../context";
import noticiasService from "../services/noticias-service";
import PropTypes from "prop-types";
import { Base64 } from "js-base64";
import home from "../resources/images/noticia-home.jpg";
import { urls } from "../resources/foo/api-endpoints";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useOnlineState from "./elements/online-hook";
import NewsLetter from "./newsletter/newsletter";
import Donate from "./Donations/pages/Donate/donate";

const Noticias = () => {
  // noticias states
  const [dataListadoNoticias, setDataListadoNoticias] = useState(null);
  const { showNewsletter, setShowNewsletter, showNewsletterButton, setShowDonations, showDonations, showDonationButton } = useContext(Context);
  const [id, setId] = useState(-1);
  const [loading, setLoading] = useState(false);
  const isOnline = useOnlineState();
  const [siguientePagina, setSiguientePagina] = useState(1);
  const [cargandoInfo, setCargandoInfo] = useState(false);
  const [end, setEnd] = useState(false);
  const cantidadNoticias = 9999;
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const module = queryParams.get('module');
  const navigate = useNavigate();

  useEffect(() => {
    if(module === 'donations' && showDonationButton){
      setShowDonations(true)
    }
    if(module === 'newsletter'){
      setShowNewsletter(true)
    }
  }, [search]);

  // noticias useEffect
  useEffect(() => {
    if (!dataListadoNoticias || !dataListadoNoticias.message) {
      setLoading(true);
      caches.open("api-cache").then((cache) => {
        cache.match(urls.getInfoNoticias)
          .then((response) => response.json())
          .then((data) => {
            if (data && data.length > 0 && data[0].id) {
                return data;
            }
            throw new Error();
          })
          .then((data) => {
            if (data && data.length > 0) {
              setSiguientePagina(data[data.length - 1].pagina);
              setDataListadoNoticias(data);
            }
          })
          .catch((err) => {
            noticiasService.getInfoNoticias(
              setDataListadoNoticias,
              setSiguientePagina,
              siguientePagina,
              dataListadoNoticias,
              setEnd
            );
          });
      });
    }
  }, []);
  useEffect(() => {
    if (dataListadoNoticias && loading) {
      setLoading(false);
      setCargandoInfo(false);
    }
    if(dataListadoNoticias && !loading) {
      setCargandoInfo(true);
    }
  }, [dataListadoNoticias]);

  

  // noticias functions
  const base64ToArrayBuffer = (base64) => {
    let binaryString = Base64.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  const handleCantidadNoticias = (e) => {
    e.preventDefault();
    if (dataListadoNoticias === null || !dataListadoNoticias.message) {
      noticiasService.getInfoNoticias(
        setDataListadoNoticias,
        setSiguientePagina,
        siguientePagina,
        dataListadoNoticias,
        setEnd
      );
      setCargandoInfo(true);
      setLoading(true);
    }
  };

  return <>
    <nav className="breadcrumbs" aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/inicio">
            <i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true" />{" "}Inicio
          </Link>
        </li>
        <li className={`breadcrumb-item ${showNewsletter ? "" : showDonations ? "" : "active"}`} aria-current="page">
          {" "}{ showNewsletter || showDonations  ? <Link to="/noticias" onClick={() => {setShowNewsletter(false), setShowDonations(false)}}>Noticias</Link> : "Noticias" }
        </li>
        {
          showNewsletter && <li className="breadcrumb-item active" aria-current="page">
            Suscripciones
          </li>
        }

        {
          showDonations && <li className="breadcrumb-item active" aria-current="page">
          Donaciones
        </li>
        }
      </ol>
    </nav>
    {!showNewsletter && !showDonations ?
      <section className="noticias">
        <div className="d-flex justify-content-between align-items-center pe-4 mb-4">
          <h1 className="m-0">{showNewsletter ? "Suscripciones" : "Noticias"}</h1>
          <div className="align-content-end">
            <div className="row w-auto">
              <div className="col d-grid d-md-block">
              {
                !showNewsletter && showNewsletterButton &&
                <button
                  className="btn btn-degradado me-md-2 mb-2"
                  onClick={() => {
                    setShowNewsletter(true)
                    navigate('/noticias?module=newsletter');
                  }}
                  disabled={cargandoInfo}
                >
                  Suscripciones
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                </button>
              }
              {
                !showDonations && showDonationButton &&
                <button
                  className="btn btn-primary mb-2"
                  onClick={() => {
                    setShowDonations(true)
                    navigate('/noticias?module=donations');
                  }}
                  disabled={cargandoInfo}
                >
                  Haz tu aporte aquí
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                </button>
              }
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 position-relative mb-5">
          <h2 className="h4 tit-section">#NoticiasCencosud</h2>
          <div className="row">
            {
              dataListadoNoticias && Children.toArray(dataListadoNoticias.map((value, index) => {
                if (value.id === "") {
                  return <div className="text-center">
                    <h3>No hay noticias para mostrar</h3>
                  </div>;
                } else if (index <= cantidadNoticias) {
                  return <div className="col-12 col-md-6 mb-4 position-relative">
                    <Link
                      className="btn btn-degradado btn-absolute"
                      to={isOnline ? "/noticias/" + value.id : "#"}
                      onClick={(e) => {
                        if (!isOnline) {
                          e.preventDefault();
                          setId(value.id);
                          caches.open("api-cache").then((cache) => {
                            cache.match(urls.getInfoNoticias)
                              .then((noticias) => {
                                if (noticias) {
                                  document.getElementById("offlineCache").click();
                                } else {
                                  document.getElementById("sinConexion").click();
                                }
                              });
                          });
                        }
                      }}
                    >
                      Ver más
                      <i
                        className="fa fa-chevron-right"
                        aria-hidden="true"
                      ></i>
                    </Link>
                    <Link id="offlineCache" to={"/noticias/" + id} hidden />
                    <img style={{ width: "500px", objectFit: "cover" }} src={
                      dataListadoNoticias && !dataListadoNoticias.message ?
                        URL.createObjectURL(new Blob([base64ToArrayBuffer(value.imagen),]))
                        : home
                    }
                      className="d-block w-100 rounded-4"
                      alt=""
                    />
                  </div>;
                }
                return <></>;
              }))
            }
            {
              siguientePagina > 0 && dataListadoNoticias && dataListadoNoticias.length > 0 && !end &&
              <div className="text-center mt-4 d-grid d-md-inline-block">
                <a className="btn btn-degradado" href="/"
                  onClick={(e) => handleCantidadNoticias(e)}
                >Cargar más</a>
              </div>
            }
            {
              (loading || cargandoInfo) && !end &&
              <div className="text-center mt-3">
                <h3>Cargando...</h3>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </div>
            }
          </div>
        </div>
        <a id={"sinConexion"} data-bs-toggle="modal" data-bs-target={"#sinConexionModal"} />
        <div className="modal fade" id={"sinConexionModal"} tabIndex="-1" aria-labelledby={"sinConexionModal"} aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0 pb-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body text-center py-4">
                <i className="fa fa-exclamation-triangle color-orange fa-3x" aria-hidden="true" />
                <h2 className="h5">No tienes conexión</h2>
                <p>No puedes ejecutar esta acción si no estás conectado.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      : showDonations ?
        <Donate/>
      :
        <NewsLetter/>
    }
  </>;
};

Noticias.propTypes = {
  success: PropTypes.bool,
  successNoticias: PropTypes.bool,
  idNoticias: PropTypes.number,
};

export default Noticias;
