import Swal from "sweetalert2";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-danger",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
  allowOutsideClick: false,
});

const errorMessage = (callBack, message) => {
  swalWithBootstrapButtons
    .fire(
      "Se ha producido un error",
      // `Intentelo nuevamente en unos minutos. \u000A${message}`,
      `${message}`,
      "error"
    )
    .then((result) => {
      if (result.isConfirmed) {
        if (typeof callBack === "function") {
          callBack();
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
};

export default errorMessage;
