import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { getPaginationPagesNumber } from '../../../utilities/helpers/paginationNumbers'
import { Article } from '../../../utilities/interfaces'
import Results from './resultsItems'
import Paginator from '../../../commons/paginator'
import { extractCategoryPath, extractResultContent } from '../utils/parseContent'
import { ResultsProps } from '../interfaces'
import resultsStyles from '../searchResultsStyle.module.css'

const SuccessResults = ({searchText, articles}: ResultsProps) => {
  const [listToShow, setListToShow] = useState(Array<Article>)
  const itemsPerPage = 5
  const pages = getPaginationPagesNumber(articles, itemsPerPage)

  useEffect(() => {
    if (!articles) return
    extractCategoryPath(articles)
    extractResultContent(articles, searchText)
    setListToShow(articles.slice(0, itemsPerPage))
  }, [articles])
  
  const handlePaginatorClick = (fragmentToShow: { start: number, end: number }) => {
    const itemsCopy = [...articles]
    setListToShow(itemsCopy.slice(fragmentToShow.start, fragmentToShow.end))
  }
  return (
    <Grid item xs={12}>
      <Results articles={listToShow} searchText={searchText} />
      <Paginator
        itemsPerPage={itemsPerPage}
        totalPages={pages}
        updateListToShow={(fragment) => handlePaginatorClick(fragment)}
      />
    </Grid>
  )
}

export default SuccessResults