import axios from "axios";

const url = process.env.REACT_APP_BACKEND_URL_GLOBAL;
//const url = "http://localhost:3002";

const AprobacionRegionalService = {
  aprobacionPublico: async (body, setIsSaving, setShowError, setSendRequest, setShowSuccess, setErrorMessage,setTitle) => {
    try {
      setIsSaving(true);
      const response = await axios({
        method: "post",
        url: `${url}/api/approval-by-hash`,
        data: body,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response === null || response === undefined) {
        setShowError(true);
        setIsSaving(false);
        setErrorMessage(response?.value);
      } else {
        const action = response?.actionCode || response?.status;
        if (action === 200 && response?.data.label !== "Error") {
          setIsSaving(false);
          setShowSuccess(true);
          setSendRequest(false);
          response?.data.title && setTitle(response?.data.title);
         
        } else {
          setIsSaving(false);
          setShowError(true);
          setErrorMessage(response.value || response.data.value);
          setSendRequest(false);
          response?.data.title &&setTitle(response?.data.title);
        }
      }
    } catch (error) {
      setIsSaving(false);
      setShowError(true);
      setErrorMessage(error?.response?.data);
    }
  },
}

export default AprobacionRegionalService;