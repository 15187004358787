import React, { useState } from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import Accordion from 'react-bootstrap/Accordion';
import VacacionesService from '../../services/solicitud-vacaciones-uy/vacaciones-uy-service';
import errorMessage from '../SharedComponents/error-message';
import successMessage from '../SharedComponents/success-message';
import Swal from 'sweetalert2';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-degradado ms-md-2",
    cancelButton: "btn btn-primary",
  },
  buttonsStyling: false,
});

const TablaVacaciones = (props) => {
  const [loadingCancelar, setLoadingCancelar] = useState(false);
  let tableLayout = props.list;
  const [sortList, setSortList] = useState("ASC");


  const toggleOrder = () => {
    if (sortList === "DESC") {
      tableLayout = tableLayout.sort((a, b) => a.INICIO < b.INICIO ? 1 : b.INICIO < a.INICIO ? -1 : 0)
      setSortList("ASC")
    } else {
      tableLayout = tableLayout.sort((a, b) => a.INICIO > b.INICIO ? 1 : b.INICIO > a.INICIO ? -1 : 0)
      setSortList("DESC")
    }
  }

  const status = (status) => {
    switch (status) {
      case "A":
        return "Aprobado";
      case "P":
        return "Pendiente";
      case "R":
        return "Rechazado";
      case "C":
        return "Cancelado";
      default:
        return "-";
    }
  }

  const cancelarSolicitud = async (id) => {
    swalWithBootstrapButtons
      .fire({
        title: "Confirmación",
        text: "¿Desea continuar la operación?",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
        showCloseButton: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          setLoadingCancelar(true)
          try {
            const response = await VacacionesService.cancelarSolicitud(id);
            if (
              response !== null &&
              response !== undefined &&
              (response?.statusCode === 200 || response?.status === 200)
            ) {
              successMessage(null);
              setLoadingCancelar(false)
              props.loadAusencias()
            } else {
              errorMessage(null, "Intentelo de nuevo más tarde.")
              setLoadingCancelar(false)
              props.loadAusencias()
            }

            setLoadingCancelar(false);
          } catch (error) {
            errorMessage(null, error?.response?.data);
            setLoadingCancelar(false);
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      })
  }

  return (

     <>
      <div className="table-responsive desktop-table-vacaciones-historico">
        <table className="table table-even table-section table-borderless d-md-table">
          <thead>
            <tr>
              <th className="cap">Fecha Inicio {sortList === "ASC" ? <BsArrowDown className="color-orange fw-bold" onClick={toggleOrder} /> : sortList === "DESC" ? <BsArrowUp className="color-orange" onClick={toggleOrder} /> : null} </th>
              <th className="cap">Fecha Final</th>
              <th className="cap">Duracion</th>
              <th className="cap">Aprobador</th>
              <th className="cap">Estado</th>
              <th className="cap">Cancelar</th>

            </tr>
          </thead>
          <tbody>
            {tableLayout.length === 0 ? <td colSpan={7} className="no-absence-text alert alert-info alert-dismissible fade show text-center" role="alert">
              <AiOutlineWarning></AiOutlineWarning> No se encontraron datos asociados
            </td> : tableLayout.map((absence, i) => {
              return (
                <tr key={i}>
                  <td key={i}>{absence.INICIO}</td>
                  <td key={i}>{absence.FIN}</td>
                  <td key={i}>{absence.DURACION} {absence.DURACION == 1 ? "Día" : "Días"}</td>
                  <td key={i}>{absence.NAME_DISPLAY}</td>
                  <td>
                    <div className={`boton-estado ${status(absence.ESTADO)}`}> {status(absence.ESTADO)}</div>
                  </td>
                  <td>
                    <button className="btn-dropdown" onClick={() => cancelarSolicitud(absence.CN_ID_SOL_VAC)} disabled={absence.ESTADO != "P" || loadingCancelar}>
                      {!loadingCancelar ? <i className={`fa fa-trash fa-fw fa-lg ${absence.ESTADO != "P"? "color-gray" : "color-orange"}`} aria-hidden="true"></i> : <div className="spinner-border spinner-border-sm"></div>}</button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      {tableLayout.length > 0 ?
        <Accordion className="accordion accordion-flush accordion-light accordion-table d-md-none" defaultActiveKey="0">
          {tableLayout.map((absence, i) => {
            return (
              <Accordion.Item eventKey={i} key={i}>
                <Accordion.Header className="accordion-header" id="accordion">
                  <strong>Fecha Inicio</strong> {absence.INICIO}</Accordion.Header>
                <Accordion.Body className="accordion-body p-0">
                  <div className="data-list-box data-list-box-even">
                    <ul className="data-list-section--item list-unstyled">
                      <li>
                        <span className="data-list-box__title">Fecha Final</span>
                        <span className="data-list-box__text">{absence.FIN}</span>
                      </li>
                      <li>
                        <span className="data-list-box__title">Duración</span>
                        <span className="data-list-box__text">{absence.DURACION}</span>
                      </li>
                      <li>
                        <span className="data-list-box__title">Aprobador</span>
                        <span className="data-list-box__text">{absence.NAME_DISPLAY}</span>
                      </li>
                      <li>
                        <span className="data-list-box__title">Estado</span>
                        <span className="data-list-box__text">
                          <span className={`boton-estado ${status(absence.ESTADO)}`}> {status(absence.ESTADO)}</span>
                        </span>
                      </li>
                      <li>
                        <span className="data-list-box__title">Cancelar</span>
                        <span className="data-list-box__text">
                          <button className="btn-dropdown" onClick={() => cancelarSolicitud(absence.CN_ID_SOL_VAC)} disabled={absence.ESTADO != "P" || loadingCancelar}>
                          {!loadingCancelar ? <i className={`fa fa-trash fa-fw fa-lg ${absence.ESTADO != "P"? "color-gray" : "color-orange"}`} aria-hidden="true"></i> : <div className="spinner-border spinner-border-sm"></div>}</button>
                        </span>
                      </li>
                    </ul>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )
          })}
        </Accordion> : null}

    </>
  )
}

export default TablaVacaciones
