import React, { FC } from "react";

interface Props {
    status: string;
    valid: string;
}
const SuccessRsp: FC<Props> = ({ status, valid }) => {
    return (
        <>
            <button hidden id="successInfo" data-bs-target="#successInfoModal" data-bs-toggle="modal" />
            <div className="modal fade" id="successInfoModal" tabIndex={-1} aria-labelledby="success-info" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button hidden id="closeSuccessInfo" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center py-4">
                            <div className="modal-body text-center py-4">
                                { valid === "1" && <i className="fa fa-check-circle color-orange fa-3x" aria-hidden="true"></i> }
                                { valid !== "1" && <i className="fa fa-exclamation-triangle color-orange fa-3x" aria-hidden="true"></i> }
                                <h2 className="h5">{valid === "1" ? "Éxito" : "Ha ocurrido un problema"}</h2>
                                <p>{status}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SuccessRsp;