

import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { paths } from '../../utilities/paths'
import breadCrumStyle from './styles/breadcrumbs.module.css'
import SubRoutesBlock from './components/subRoutes'
import { UpdateLocalStorage } from '../../utilities/helpers/updateStorage'
import { trimTitle } from './utilities/formaters'

const storageService = new UpdateLocalStorage()

const BreadCrumbs = () => {
  const [currentPathName, setCurrentPathName] = useState('')
  const [isNestedRoute, setNestedRoute] = useState(false)
  const [hasSubLevelRoute, setHasSubLevelRoute] = useState(false)
  const [subLevels, setSubLevels] = useState('empty')
  const location = useLocation()

  const getFormatedPath = (): string => {
    const defaultPath = paths[1].breadCrumb
    const separatePaths = location.pathname.split('/').slice(2)
    if (separatePaths.length <= 1) {
      setNestedRoute(false)
      return defaultPath
    }
    setNestedRoute(true)
    const foundPath = localStorage.getItem(storageService.keyConstants[0])
    if (foundPath) return trimTitle(foundPath, location.pathname)
    return ''
  }

  useEffect(() => {
    window.addEventListener('storage', readTitleUpdate)
    return (() => {
      window.removeEventListener('storage', readTitleUpdate)
    })
  }, [])

  useEffect(() => {
    updatePath()
  }, [location]);

  useEffect(() => {
    if (subLevels !== 'empty' && subLevels !== paths[1].breadCrumb) {
      setHasSubLevelRoute(true)
    } else {
      setHasSubLevelRoute(false)
    }
  }, [subLevels]);

  const updatePath = () => {
    const path = getFormatedPath()
    setCurrentPathName(path)
  }

  const readTitleUpdate = (event: StorageEvent): void => {
    if (event.key === storageService.keyConstants[0]) {
      event.newValue &&  setCurrentPathName(trimTitle(event.newValue, location.pathname)) 
    } else if (event.key === storageService.keyConstants[1]) {
      event.newValue && setSubLevels(event.newValue)
      event.newValue === null && setSubLevels('empty')
    } else {
      return
    }
  }

  return (
    <Breadcrumbs aria-label="breadcrumb" className={breadCrumStyle.breadcrumb_root}>
      <Link className={breadCrumStyle.breadcrumb_item} href={paths[0].path}>
        <i className="fa fa-home" aria-hidden="true"></i>
        {paths[0].breadCrumb}
      </Link>
      {isNestedRoute && !hasSubLevelRoute &&
        <Link className={breadCrumStyle.breadcrumb_item} href={paths[1].path}>
          {paths[1].breadCrumb}
        </Link>
      }
      {hasSubLevelRoute && <SubRoutesBlock subLevels={subLevels} />}
      <a className={`${breadCrumStyle.breadcrumb_item} ${breadCrumStyle.active}`}>
        {currentPathName}
      </a>
    </Breadcrumbs>
  );
}

export default BreadCrumbs;



