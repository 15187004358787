import React, { useState, useEffect } from "react";


const Public = (props) => {





    return (
        <div>
            <p>COMPONENTE PUBLICO TESTEO</p>
        </div>
    )

}


export default Public;
