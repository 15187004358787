import React from 'react'
import { Grid } from '@mui/material'
import Highlighter from '../../../commons/highlighter'
import { HeadersProps } from '../interfaces'
import  textstyles from '../../../commons/styles/textStyles.module.css'
import resultsStyles from '../searchResultsStyle.module.css'

const Headers = (props: HeadersProps) => {
  const { resultText, searchText, subtitle, validData } = props

  return (
    <Grid item xs={12}>
      <p>{resultText}<strong> {searchText}</strong></p>
      <h2 
      className={validData ? 
      `${textstyles.page_subtitle} ${resultsStyles.subtitle}` : 
      `${textstyles.section_title} ${resultsStyles.subtitle}`}>
        {subtitle}
      </h2>
      <Highlighter width={35} />
    </Grid>
  )
}

export default Headers