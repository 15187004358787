import { useEffect, FC, useRef } from "react";
import { urlsAws } from "../../../resources/foo/api-endpoints";
import StatusModal from "../../commonComponents/ModalnfoDinamic";
import { BreadCrumbsDinamic } from "../../commonComponents";
import Loading from "../../commonComponents/ModalLoad";
import { scrollToTop } from "../../../services/utiles";
import useApiForm from "./hooks/useApiForm";
import TaskTable from "./components/TaskTable";
import { useNavigate } from "react-router-dom";
import { InitialData } from "./interfaces";

const TiempoTareas: FC = () => {
  const navigate = useNavigate();
  const openLoadingModal = useRef<HTMLButtonElement | null>(null);
  const closeLoadingModal = useRef<HTMLButtonElement | null>(null);
  const openStatusModal = useRef<HTMLButtonElement | null>(null);
  const closeStatusModal = useRef<HTMLButtonElement | null>(null);
  const STATUS_PENDING = "P";

  const {
    initialData,
    initialError,
    submitData,
    submitError,
    loading,
    handleApprove,
    handleReject,
  } = useApiForm<InitialData>({
    listUrl: urlsAws.getEquipo,
    approveUrl: urlsAws.aprobarTiempo,
    rejectUrl: urlsAws.anularTiempo,
  });

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (loading) {
      openLoadingModal.current?.click();
    }
    else {
      closeLoadingModal.current?.click();
    }
  }, [loading]);

  useEffect(() => {
    if (initialError || submitData || submitError) {
      openStatusModal.current?.click();
    }
  }, [initialError, submitError, submitData]);

  useEffect(() => {
    if (initialData && !loading && (
        !initialData.response ||
        !initialData.response.subalternos ||
        initialData.response.subalternos.length === 0 || 
        initialData.response.subalternos[0].nombre === "" ||
        !initialData.response.subalternos.some((subalterno) => subalterno.estado === STATUS_PENDING)
      )) {
        navigate("/inicio");
      }     
  }, [initialData, loading]);
  
  return (
    <>
      <div className="m-3">
        <BreadCrumbsDinamic 
          paths={[
            { label: "Mi Tiempo", url: "/tiempo-equipo" }, 
            { label: "Tareas", url: "/tiempo-tareas" }
          ]} 
        />
      </div>
      {
        initialData && 
        <section className="solicitud-mi-tiempo">
          <h1>Solicitud Mi Tiempo</h1>
          <div className="box mb-4">
              <h2 className="h4 tit-section">Detalle</h2>
              <TaskTable
                data={initialData.response.subalternos.filter((subalterno) => subalterno.estado === STATUS_PENDING)}
                onApprove={handleApprove}
                onReject={handleReject}
              />
          </div>
        </section>
      }
      <Loading refOpen={openLoadingModal} refClose={closeLoadingModal} />
      <StatusModal
        refOpen={openStatusModal}
        refClose={closeStatusModal}
        text={initialError || submitError ? "Error" : "Éxito"}
        icon={initialError || submitError ? "fa-exclamation-circle" : "fa-check-circle-o"}
        subtext={
          initialError ||
          submitError ||
          "Formulario enviado exitosamente"
        }
        callBack={() => {
          closeStatusModal.current?.click();
          if (initialError || submitData) {
            const modalBackdrop = document.querySelector('.modal-backdrop.fade.show');
            if (modalBackdrop) {
                modalBackdrop.remove();
            }
          }
        }}
      />
    </>
  );
};

export default TiempoTareas;
