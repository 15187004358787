import React, { FC, useRef } from "react";

interface props {
    activeState: boolean;
    height?: string;
    videoTitle: string;
    videoUrl: string;
    width?: string;

    toggleState: (state: boolean) => void;
}

export const ModalVideo: FC<props> = ({
    activeState,
    height = "360",
    videoTitle,
    videoUrl = "",
    width = "640",
    toggleState,
}) => {
    const divPopup = useRef<HTMLDivElement>(null);
    const isActive = activeState ? "modal-video-show" : "";

    return (
        <div
            ref={divPopup}
            className={`modal-video ${isActive}`}
            onClick={() => toggleState(false)}
        >
            {isActive && (
                <div className="modal-video-content">
                    <iframe
                        src={videoUrl}
                        style={{ width: `${width}px`, height: `${height}px` }}
                        frameBorder="0"
                        scrolling="no"
                        allowFullScreen
                        title={videoTitle}
                    ></iframe>
                </div>
            )}
        </div>
    );
};
