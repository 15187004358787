
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Button, Grid } from '@mui/material'
import { HttpClient } from '../../utilities/apiServices/httpClient'
import { ApiRequest } from '../../utilities/apiServices/apiRequest'
import { UpdateLocalStorage } from '../../utilities/helpers/updateStorage'
import { LoadingContent, BreadCrumbs, BackButton } from '../../utilities/constantsTexts'
import { paths } from '../../utilities/paths';
import { APICategory, Category, SubCategory as SubCategoryType } from '../../utilities/interfaces';
import SubCategory from './components/subcategory';
import { errorsMessages } from '../../utilities/apiServices/apiErrors';
import styles from '../../commons/styles/rootStyle.module.css'
import textstyles from '../../commons/styles/textStyles.module.css'
import categoryStyle from './styles/categoryStyles.module.css'
import Loading from '../../commons/dataStatus/loading/loading'

const httpClient = new HttpClient()
const apiService = new ApiRequest(httpClient)

const HCCategory = () => {
  const { id } = useParams()
  const backButtonTitle = BackButton.simple
  const [category, setCategory] = useState<Category>()
  const [subCategories, setSubCategories] = useState<SubCategoryType[]>([])
  const [statusMessage, setStatusMessage] = useState(LoadingContent.loading)
  const [validData, setValidData] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation();
  const update = new UpdateLocalStorage()

  useEffect(() => {
    let from = location.state?.from;
    from && update.updateSlot(update.keyConstants[4], from)
    setIsLoading(true)
    fetchDataCategory()
    return (() => {
      restoreBreadcrumb()
    })
  }, []);

  const fetchDataCategory = async () => {
    apiService.getCategoryByID(Number(id)).then(categoryInfo => {
      if (!isValidData(categoryInfo)) return
      setCategory(categoryInfo.data)
      setSubCategories(categoryInfo.data.attributes.sub_categories.data)
      const pageTitle = categoryInfo.data.attributes.title
      update.updateSlot(update.keyConstants[0], pageTitle)
      update.updateSlot(update.keyConstants[1], BreadCrumbs.allCategories)
    })
      .catch((err) => {
        emitError(err)
      }).finally(() => {
        setIsLoading(false)
      })
  }

  const emitError = (err: number) => {
    const newError = {
      errorStatus: err,
      errorFire: 'getCategoryByID',
      errorComponent: 'HCCategory'
    }
    update.updateSlot(update.keyConstants[7], JSON.stringify(newError))
  }

  const isValidData = (apiResponse: APICategory): boolean => {
    if (!apiResponse.data || !apiResponse.data.attributes) {
      setStatusMessage(LoadingContent.notValid)
      update.updateSlot(update.keyConstants[0], BreadCrumbs.category)
      emitError(500)
      return false
    } else {
      setValidData(true)
      return true
    }
  }

  const backToPrevUrl = () => {
    let from = localStorage.getItem(update.keyConstants[4])
    if (from && from !== null) {
      navigate(from)
    } else {
      navigate(paths[1].path)
    }
  }

  const restoreBreadcrumb = () => {
    update.clearSlot(update.keyConstants[0])
    update.clearSlot(update.keyConstants[1])
  }

  const categoryName = (): string => {
    const title = category?.attributes.title
    if (title) {
      return title
    } else {
      return ''
    }
  }

  return (
    <Grid container columnSpacing={12} mt={1} className={categoryStyle.category_container}>
      {isLoading && <Grid item><Loading normalMessage={true} /></Grid>}
      {validData && !isLoading &&
        <React.Fragment>
          <Grid item xs={12}>
            <h1 className={`${textstyles.page_title_2} ${categoryStyle.page_title_subcategory}`}>
              {category?.attributes.title}
            </h1>
            <p className={textstyles.content_paragraph}>{category?.attributes.subtitle}</p>
          </Grid>
          {subCategories.map(subcategory => {
            return (
              <SubCategory subCategory={subcategory} key={subcategory.id} categoryName={categoryName()} />
            )
          })}
          <Grid item xs={12} className={categoryStyle.back_button_wrapper}>
            <Button className={styles.terciary_button} onClick={() => backToPrevUrl()}>
              {backButtonTitle}
            </Button>
          </Grid>
        </React.Fragment>
      }
      {!validData && !isLoading && <p>{statusMessage}</p>}
    </Grid>
  );
}

export default HCCategory;