import { FC, useState } from "react";
import { Subalterno } from "../interfaces";
import { formatDate } from "../../../../services/utiles";
type Props = {
  data: Subalterno[];
  onApprove: (id: any) => void;
  onReject: (id: any) => void;
};

const TaskTable : FC<Props>= ({ data, onApprove, onReject }) => {
  const [modalData, setModalData] = useState({ num_req: '', emplid: '', action: '' });

  const handleModalOpen = (item: Subalterno, action: 'approve' | 'reject') => {
    const { num_req, emplid } = item;
    setModalData({ num_req, emplid, action });
    document.getElementById("openConfirmationModal")?.click();
  };

  const handleConfirmAction = () => {
    const { num_req, emplid, action } = modalData;
    const requestBody = { request: { num_req, emplid } };
    if (action === "approve") {
      onApprove(requestBody);
    } else if (action === "reject") {
      onReject(requestBody);
    }
    setModalData({ num_req: '', emplid: '', action: '' });
  };

  return <>
    <div className="table-responsive d-none d-md-block">
      <table className="table table-even table-section table-borderless align-middle">
        <thead>
          <tr>
            <th>Nombre Solicitante</th>
            <th>Fecha de Inicio</th>
            <th>Fecha Final</th>
            <th>Saldo Inicial</th>
            <th>Saldo Final</th>
            <th className="text-center">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index: number) => (
            <tr key={index}>
              <td>{item.nombre}</td>
              <td>{formatDate(item.fecha_inicio)}</td>
              <td>{formatDate(item.fecha_retorno)}</td>
              <td>{item.saldo}</td>
              <td>{item.saldo - item.cantidad_dias}</td>
              <td className="text-center text-nowrap">
                <button
                  type="button"
                  className="btn btn-primary btn-sm mx-2 px-3"
                  onClick={() => handleModalOpen(item, "reject")}
                >
                  Rechazar
                </button>
                <button
                  type="button"
                  className="btn btn-degradado btn-sm mx-2 px-3"
                  onClick={() => handleModalOpen(item, "approve")}
                >
                  Aprobar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
      {data.map((item, index: number) => (
        <div className="accordion-item" key={index}>
          <h2 className="accordion-header" id={`acordeon-${index}`}>
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#accordion-item-${index}`}
              aria-expanded="true"
              aria-controls={`accordion-item-${index}`}
            >
              <strong>Nombre Solicitante</strong> {item.nombre}
            </button>
          </h2>
          <div
            id={`accordion-item-${index}`}
            className={`accordion-collapse collapse ${index === 0 ? "show" : ""}`}
            aria-labelledby={`acordeon-${index}`}
          >
            <div className="accordion-body p-0">
              <div className="data-list-box">
                <ul className="data-list-section--item list-unstyled">
                  <li>
                    <span className="data-list-box__title">Fecha de Inicio</span>
                    <span className="data-list-box__text">{formatDate(item.fecha_inicio)}</span>
                  </li>
                 <li>
                    <span className="data-list-box__title">Fecha Final</span>
                    <span className="data-list-box__text">{formatDate(item.fecha_retorno)}</span>
                  </li>
                  <li>
                    <span className="data-list-box__title">Saldo Inicial</span>
                    <span className="data-list-box__text">{item.saldo + item.cantidad_dias}</span>
                  </li>
                  <li>
                    <span className="data-list-box__title">Saldo Final</span>
                    <span className="data-list-box__text">{item.saldo}</span>
                  </li>
                  <li>
                    <span className="data-list-box__title">Acciones</span>
                    <span className="data-list-box__text">
                      <div className="d-grid gap-3">
                        <button
                          type="button"
                          className="btn btn-degradado btn-sm w-100"
                          onClick={() => handleModalOpen(item, "approve")}
                        >
                          Aprobar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm w-100"
                          onClick={() => handleModalOpen(item, "reject")}
                        >
                          Rechazar
                        </button>
                      </div>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
    <div className="modal fade" id="confirmationModal" tabIndex={-1} aria-labelledby="confirmationModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0 pb-0">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body text-center py-4">
            <h2 className="h5">
              {modalData.action === "approve"
                ? "¿Estás seguro que deseas aprobar la solicitud?"
                : "¿Estás seguro que deseas rechazar la solicitud?"}
            </h2>
          </div>
          <div className="modal-footer border-0 pt-0 pb-5 justify-content-center gap-2">
            <button
              type="button"
              className="btn btn-degradado order-md-last col-12 col-md-auto my-auto px-5"
              data-bs-dismiss="modal"
              onClick={handleConfirmAction}
            >
              Sí <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
            </button>
            <button
              type="button"
              className="btn btn-primary col-12 col-md-auto my-auto px-5"
              data-bs-dismiss="modal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
    <button hidden id="openConfirmationModal" data-bs-toggle="modal" data-bs-target="#confirmationModal" />
  </>;
};

export default TaskTable;
