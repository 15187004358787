import { useEffect, useState } from "react";
import { services } from "./subscriptionSetting.services";
import type {
  Newsletter,
  RegionalNewsLetterApi,
  Subscription,
  SubscriptionSetting,
  Category,
} from "./subscriptionSetting.types";

export const useSubscriptionSetting = (): SubscriptionSetting => {
  const [data, setData] = useState<Newsletter>({} as Newsletter);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    getNewsLetter();
  }, []);

  const getNewsLetter = async () => {
    try {
      setIsLoading(true);
      const newsLetter = await services.getRegionalNewsLetter();
      newsLetter && subscriptionMapper(newsLetter);
    } catch (err) {
      setIsError(true);
    } finally {
      setIsError(false);
      setIsLoading(false);
    }
  };

  const subscriptionMapper = (subscriptionApi: RegionalNewsLetterApi) => {
    const subscriptions = subscriptionApi.response.subscriptions;
    const categoriesMap: Map<string, Subscription[]> = new Map();

    subscriptions.forEach((sub) => {
      const category = sub.category;
      if (!categoriesMap.has(category)) {
        categoriesMap.set(category, []);
      }

      categoriesMap.get(category)?.push({
        value: sub.value,
        title: sub.title,
        description: sub.description,
        selected: sub.selected,
      });
    });

    const categories: Category[] = [];
    categoriesMap.forEach((subscription, category: string) => {
      categories.push({
        category,
        subscription,
      });
    });

    setData({
      subscribed: subscriptionApi.response.subscribed,
      lastUpdate: subscriptionApi.response.lastUpdate,
      newsletter: categories,
    });
  };

  return {
    subscriptions: data,
    isLoading,
    isError,
  };
};
