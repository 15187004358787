import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { urlsAws } from "../../resources/foo/api-endpoints";
import { decrypt, dotNumber } from "../../services/utiles";
import vacacionesService from "../../services/vacaciones-service";
import CarrouselVacaciones from "../elements/vacaciones-carrousel";

const SolicitarVacaciones = () => {
  const [, setModalShow] = useState(true);
  const [data, setData] = useState(false);
  const [loginData, setLoginData] = useState({});
  const [status, setStatus] = useState(true);
  const [message, setMessage] = useState("");
  const [diasDisponibles, setDiasDisponibles] = useState(null);
  const [existErrorCont, setExistErrorCont] = useState(false);
  const [fechaInicioError, setFechaInicioError] = useState(false);
  const [fechaTerminoError, setFechaTerminoError] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [respuestaSolicitud, setRespuestaSolicitud] = useState(false);
  const [respuestaSolicitudText, setRespuestaSolicitudText] = useState("");
  const [respuestaSolicitudValid, setRespuestaSolicitudValid] = useState(false);
  const [respuestaSolicitudText2, setRespuestaSolicitudText2] = useState("");
  const [respuestaSolicitudValid2, setRespuestaSolicitudValid2] =
    useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [getdiasDisponibles, setGetDiasDisponibles] = useState("");
  const [misDias, setMisDias] = useState(0);
  const [cargando, setCargando] = useState(false);
  const [terminos, setTerminos] = useState(true);
  const [clicTermino, setClicTermino] = useState(false);
  let navigate = useNavigate();
  const [emplid, setEmplid] = useState(localStorage.getItem("emplid"));
  const [tieneNet, setTieneNet] = useState(true);
  const [feriados, setFeriados] = useState(null);

  useEffect(() => {
    if (emplid !== null) {
      vacacionesService.getDetalleDiasDisponibles(setDiasDisponibles, emplid);
      caches.open("api-cache").then((cache) => {
        cache.delete(
          urlsAws.getDetalleVacaciones + "/verDetalle?rut=" + emplid + "&rcd=0"
        );
      });

      caches.match(urlsAws.firstLogin).then((response) => {
        if (response && response.body !== null) {
          response.json().then((data) => {
            setLoginData(data);
          });
        }
      });

      caches.match(urlsAws.datosLaborales).then((response) => {
        if (response) {
          response.json().then((data) => {
            setData(data);
          });
        } 
      });
    }
  }, [emplid]);

  useEffect(() => {
    if (emplid !=='') {
      vacacionesService.getFeriados(setFeriados, emplid);
    }
  }, [emplid]);

  useEffect(() => {
    if (diasDisponibles) {
      if (decrypt(diasDisponibles.balance) !== undefined) {
        setMisDias(decrypt(diasDisponibles.balance));
      }
    }
  }, [diasDisponibles]);

  useEffect(() => {
    if (getdiasDisponibles === "") {
      setGetDiasDisponibles(misDias);
    }
  }, [loginData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (clicTermino) {
      let fechaTermino = document.getElementById("fechaTermino").value;
      let fechaInicio = document.getElementById("fechaInicio").value;
      let enviar = document.getElementById("enviarSoli");
      let saldo = document.getElementById("saldoDias").value;
      if (!fechaTerminoError && !existErrorCont && !fechaInicioError) {
        if (navigator.onLine) {
          vacacionesService.solicitarVacaciones(
            emplid,
            fechaInicio,
            fechaTermino,
            setRespuestaSolicitud,
            dotNumber(saldo)
          );
          setStatusLoading(true);
          setCargando(true);
          enviar.disabled = true;
          setEmplid(null)
        } else {
          setRespuestaSolicitudValid(true);
          setRespuestaSolicitudText(
            "La conexión no está disponible en estos momentos, favor de intentar más tarde."
          );
        }
      }
    } else {
      setTerminos(false);
    }
  };

  useEffect(() => {
    if (
      respuestaSolicitud !== "" &&
      respuestaSolicitud !== undefined &&
      respuestaSolicitud !== false &&
      respuestaSolicitud !== 2
    ) {
      let enviar = document.getElementById("enviarSoli");

      if (!respuestaSolicitud.message) {
        if (respuestaSolicitud.response.valid !== "1") {
          setRespuestaSolicitudValid(true);
          let mensaje = respuestaSolicitud.response.status.split(".");
          setRespuestaSolicitudText(mensaje[0]);
        } else {
          setRespuestaSolicitudValid2(true);
          let mensaje = respuestaSolicitud.response.status.split(".");
          setRespuestaSolicitudText2(mensaje[0]);
          sessionStorage.setItem(
            "diasDisponibles",
            document.getElementById("saldoDias").value
          );
        }
      } else {
        setRespuestaSolicitudValid(true);
        let mensaje =
          "Se han realizado muchas solicitudes simultaneas, favor de esperar y realizar nuevamente.";
        setRespuestaSolicitudText(mensaje[0]);
      }
      setRespuestaSolicitud("");
      setStatusLoading(false);
      setCargando(false);
      enviar.disabled = false;
    }
  }, [respuestaSolicitud]);

  const fechaInicioSelect = () => {
    let fechaTermino = document.getElementById("fechaTermino");
    let fechaInicio = document.getElementById("fechaInicio");
    let diasSolicitados = document.getElementById("diasSolicitados");
    if (Date.parse(fechaTermino.value) < Date.parse(fechaInicio.value)) {
      setStatus(false);
      setMessage("La fecha de término no debe ser menor a la fecha de inicio.");
      setModalShow(true);
      setFechaTerminoError(true);
    } else {
      setStatus(true);
      setMessage("");
      setModalShow(false);
      setFechaTerminoError(false);

      let fechaInicioCalc = new Date(fechaInicio.value).getTime();
      let fechaFinCalc = new Date(fechaTermino.value).getTime();

      //restar findes
      let totalEliminados = 0;
      let fechaInicioCalc2 = new Date(fechaInicio.value);
      let fechaFinCalc2 = new Date(fechaTermino.value);
      let fechaValidada = false;
      while (fechaFinCalc2.getTime() >= fechaInicioCalc2.getTime()) {
        fechaValidada = false;
        fechaInicioCalc2.setDate(fechaInicioCalc2.getDate() + 1);
        let fechas = new Date(
          fechaInicioCalc2.getFullYear() +
            "/" +
            (fechaInicioCalc2.getMonth() + 1) +
            "/" +
            fechaInicioCalc2.getDate()
        );

        let dia = fechas.getDate();
        if (dia < 10) {
          dia = "0" + fechas.getDate();
        }
        let mes = fechas.getMonth() + 1;
        if (mes < 10) {
          mes = "0" + (fechas.getMonth() + 1);
        }

        //Funcion feriados
        if (feriados && !fechaValidada) {
          if (feriados.feriado) {
            for (let i = 0; i < feriados.feriado.length; i++) {
              if (
                feriados.feriado[i].holiday ===
                fechas.getFullYear() + "-" + mes + "-" + dia
              ) {
                totalEliminados++;
                fechaValidada = true;
              }
            }
          }
        }

        if (
          fechas.getDay() === 6 ||
          (fechas.getDay() === 0 && !fechaValidada)
        ) {
          totalEliminados++;
          fechaValidada = true;
        }
      }

      let diff = fechaFinCalc - fechaInicioCalc;

      diasSolicitados.value =
        diff / (1000 * 60 * 60 * 24) + 1 - totalEliminados;

      let total = misDias - diasSolicitados.value;
      document.getElementById("saldoDias").value = Number(total).toFixed(2);
      setStatus(true);
      setMessage("");
      setModalShow(false);
      setExistErrorCont(false);
      if (isNaN(total)) {
        document.getElementById("saldoDias").value = 0;
        diasSolicitados.value = 0;
      } else {
        document.getElementById("saldoDias").value = Number(total).toFixed(2);
      }

      setFechaInicioError(false);
    }
  };

  const fechaTerminoSelect = () => {
    let fechaTermino = document.getElementById("fechaTermino");
    let fechaInicio = document.getElementById("fechaInicio");
    let diasSolicitados = document.getElementById("diasSolicitados");
    if (Date.parse(fechaTermino.value) < Date.parse(fechaInicio.value)) {
      setStatus(false);
      setMessage("La fecha de término no debe ser menor a la fecha de inicio.");
      setModalShow(true);
      setFechaTerminoError(true);
    } else {
      setStatus(true);
      setMessage("");
      setModalShow(false);
      setFechaTerminoError(false);

      let fechaInicioCalc = new Date(fechaInicio.value).getTime();
      let fechaFinCalc = new Date(fechaTermino.value).getTime();

      //restar findes
      let totalEliminados = 0;
      let fechaInicioCalc2 = new Date(fechaInicio.value);
      let fechaFinCalc2 = new Date(fechaTermino.value);
      let fechaValidada = false;
      while (fechaFinCalc2.getTime() >= fechaInicioCalc2.getTime()) {
        fechaValidada = false;
        fechaInicioCalc2.setDate(fechaInicioCalc2.getDate() + 1);
        let fechas = new Date(
          fechaInicioCalc2.getFullYear() +
            "/" +
            (fechaInicioCalc2.getMonth() + 1) +
            "/" +
            fechaInicioCalc2.getDate()
        );
        let dia = fechas.getDate();
        if (dia < 10) {
          dia = "0" + fechas.getDate();
        }
        let mes = fechas.getMonth() + 1;
        if (mes < 10) {
          mes = "0" + (fechas.getMonth() + 1);
        }

        //Funcion feriados
        if (feriados && !fechaValidada) {
          if (feriados.feriado) {
            for (let i = 0; i < feriados.feriado.length; i++) {
              if (
                feriados.feriado[i].holiday ===
                fechas.getFullYear() + "-" + mes + "-" + dia
              ) {
                totalEliminados++;
                fechaValidada = true;
              }
            }
          }
        }

        if (
          (fechas.getDay() === 6 || fechas.getDay() === 0) &&
          !fechaValidada
        ) {
          totalEliminados++;
          fechaValidada = true;
        }
      }

      let diff = fechaFinCalc - fechaInicioCalc;

      diasSolicitados.value =
        diff / (1000 * 60 * 60 * 24) + 1 - totalEliminados;

      let total = misDias - diasSolicitados.value;
      document.getElementById("saldoDias").value = Number(total).toFixed(2);
      setStatus(true);
      setMessage("");
      setModalShow(false);
      setExistErrorCont(false);
      if (isNaN(total)) {
        document.getElementById("saldoDias").value = 0;
        diasSolicitados.value = 0;
      } else {
        document.getElementById("saldoDias").value = Number(total).toFixed(2);
      }
    }
  };

  const ejecutarModal = () => {
    setModalState(true);
    setTerminos(true);
    setClicTermino(true);
  };

  const handleClose2 = () => {
    setRespuestaSolicitudValid(false);
    navigate("/ver-detalle-vacaciones");
    caches.open("api-cache").then((cache) => {
      cache.delete(
        urlsAws.getDetalleVacaciones + "/verDetalle?rut=" + emplid + "&rcd=0"
      );
    });
  };

  const handleClose = () => {
    setModalState(false);
    setRespuestaSolicitudValid(false);
    setTieneNet(true);
  };

  const detalleVacaciones = () => {
    if (navigator.onLine) {
      window.location.replace("/ver-detalle-vacaciones");
    } else {
      setTieneNet(false);
    }
  };

  const solicitarVacaciones = () => {
    if (navigator.onLine) {
      window.location.replace("/solicitar-vacaciones");
    } else {
      caches
        .match(
          urlsAws.getDetalleDiasDisponibles +
            "/getResumen?rut=" +
            emplid +
            "&rcd=0"
        )
        .then((response) => {
          if (response) {
            response.json().then(() => {
              window.location.replace("/solicitar-vacaciones");
            });
          } else {
            setTieneNet(false);
          }
        });
    }
  };

  const cartolaVacaciones = () => {
    if (navigator.onLine) {
      window.location.replace("/ver-cartola");
    } else {
      setTieneNet(false);
    }
  };

  const aprobarVacaciones = () => {
    if (navigator.onLine) {
      window.location.replace("/admin/aprobar-vacaciones");
    } else {
      caches
        .match(urlsAws.getVacacionesSubAlterno + "?rut=" + emplid)
        .then((response) => {
          if (response) {
            response.json().then(() => {
              window.location.replace("/admin/aprobar-vacaciones");
            });
          } else {
            setTieneNet(false);
          }
        });
    }
  };

  const reemplazoVacaciones = () => {
    if (navigator.onLine) {
      window.location.replace("/reemplazo-vacaciones");
    } else {
      caches
        .match(urlsAws.buscarReasignacion + "?rut=" + emplid)
        .then((response) => {
          if (response) {
            response.json().then(() => {
              window.location.replace("/reemplazo-vacaciones");
            });
          } else {
            setTieneNet(false);
          }
        });
    }
  };

  return (
    <>
      <nav className="breadcrumbs" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/inicio">
              <i className="fa fa-home" aria-hidden="true"></i> Inicio
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Solicitud vacaciones
          </li>
        </ol>
      </nav>{" "}
      <section className="vacaciones">
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="tit-group d-block d-xxl-flex justify-content-between">
            <h1>Vacaciones</h1>
            <div className="saldo-vacaciones rounded-3 bg-light p-3 color-primary mb-4">
              <div className="row align-items-center">
                <label
                  htmlFor="inputPassword"
                  className="col-6 col-sm-4 col-md-3 col-form-label"
                >
                  Saldo Vacaciones:
                </label>
                <div
                  className="col-3 col-md-2 col-xl-1"
                  style={{ width: "100px" }}
                >
                  <input
                    type="text"
                    className="form-control text-center bg-white"
                    value={misDias}
                    disabled
                  />
                </div>
                <small className="col-12 col-md-6 col-lg-7 col-xl-15 mt-4 mt-md-0">
                  * Su saldo de vacaciones actual, incluye los días futuros
                  aprobados
                </small>
              </div>
            </div>
          </div>

          <CarrouselVacaciones tituloSelect="Solicitar"></CarrouselVacaciones>

          <div className="box mb-4">
            <h2 className="h4 tit-section">Datos Solicitante</h2>
            <div className="data-list-box data-list-box-even data-list-box-align-middle data-list-box-form">
              <ul className="list-column-1 list-unstyled">
                <li>
                  <span className="data-list-box__title text-uppercase">
                    RUT
                  </span>
                  <span className="data-list-box__text">
                    <input
                      className="form-control"
                      type="text"
                      disabled
                      value={loginData.EMPLID}
                      />
                  </span>
                </li>
                <li>
                  <span className="data-list-box__title text-uppercase">
                    Nombre
                  </span>
                  <span className="data-list-box__text">
                    <input
                      className="form-control"
                      type="text"
                      disabled
                      value={loginData.NAME}
                      />
                  </span>
                </li>
                <li>
                  <span className="data-list-box__title text-uppercase">
                    Posición
                  </span>
                  <span className="data-list-box__text">
                    <input
                      className="form-control"
                      type="text"
                      disabled
                      value={loginData && loginData.POSITION_DESCR}
                      />
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className="box mb-4">
            <h2 className="h4 tit-section">Datos Jefe</h2>
            <div className="data-list-box data-list-box-even data-list-box-align-middle data-list-box-form">
              <ul className="list-column-1 list-unstyled">
                <li>
                  <span className="data-list-box__title text-uppercase">
                    Nombre
                  </span>
                  <span className="data-list-box__text">
                    <input
                      className="form-control"
                      type="text"
                      disabled
                      value={loginData && loginData.BOSSNAME_DESCR}
                      />
                  </span>
                </li>
                <li>
                  <span className="data-list-box__title text-uppercase">
                    Posición
                  </span>
                  <span className="data-list-box__text">
                    <input
                      className="form-control"
                      type="text"
                      disabled
                      value={loginData && loginData.BOSSPOSITION_DESCR}
                      />
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className="box mb-4">
            <h2 className="h4 tit-section">Datos Solicitud</h2>
            <div className="data-list-box data-list-box-even data-list-box-align-middle data-list-box-form">
              <ul className="list-column-1 list-unstyled">
                <li>
                  <span className="data-list-box__title text-uppercase">
                    Días disponibles
                  </span>
                  <span className="data-list-box__text">
                    <input
                      className="form-control"
                      type="text"
                      placeholder={misDias}
                      disabled
                      value={misDias !== undefined ? misDias : "Cargando días"}
                    />
                  </span>
                </li>
                <li>
                  <span className="data-list-box__title text-uppercase">
                    Fecha inicio
                  </span>
                  <span className="data-list-box__text">
                    <div className="row">
                      <div className="col-10">
                        <input
                          className="form-control"
                          onClick={(event) => fechaInicioSelect(event)}
                          onChange={(event) => fechaInicioSelect(event)}
                          id="fechaInicio"
                          type="date"
                          placeholder="dd/mm/aaaa"
                          required
                        />
                        <div className="invalid-feedback">
                          Debe ingresar una fecha
                        </div>
                      </div>
                      <div className="col-auto">
                        <i
                          className="fa fa-calendar fa-fw mt-2 fa-lg color-primary"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <span className="data-list-box__title text-uppercase">
                    Fecha término
                  </span>
                  <span className="data-list-box__text">
                    <div className="row">
                      <div className="col-10">
                        <input
                          className="form-control"
                          onChange={(event) => fechaTerminoSelect(event)}
                          onClick={(event) => fechaTerminoSelect(event)}
                          id="fechaTermino"
                          type="date"
                          placeholder="dd/mm/aaaa"
                          required
                        />
                        <div className="invalid-feedback">
                          Debe ingresar una fecha
                        </div>
                      </div>
                      <div className="col-auto">
                        <i
                          className="fa fa-calendar fa-fw mt-2 fa-lg color-primary"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <span className="data-list-box__title text-uppercase">
                    Días solicitados
                  </span>
                  <span className="data-list-box__text">
                    <input
                      id="diasSolicitados"
                      className="form-control"
                      type="text"
                      disabled
                    />
                  </span>
                </li>
                <li>
                  <span className="data-list-box__title text-uppercase">
                    Saldo días
                  </span>
                  <span className="data-list-box__text">
                    <input
                      type="number"
                      id="saldoDias"
                      step="0,01"
                      className="form-control"
                      disabled
                    />
                  </span>
                </li>
              </ul>
            </div>

            <div className="mb-3">
              <input
                type="checkbox"
                className="btn-check"
                id="btn-check"
                autoComplete="off"
              />
              <label
                className="color-primary"
                htmlFor="btn-check"
                data-bs-toggle="modal"
                data-bs-target="#terminos-y-condiciones"
              >
                <a
                  style={{ color: "blue", cursor: "pointer" }}
                  name="verTerminosBanco"
                  data-bs-toggle="modal"
                  data-bs-target="#seleccionar-foto"
                  onClick={(event) => ejecutarModal(event)}
                >
                  <i
                    className="fa fa-file-text-o color-primary me-2 fa-lg"
                    aria-hidden="true"
                  ></i>{" "}
                  Ver Términos y Condiciones{" "}
                </a>
              </label>
              {!terminos ? (
                <>
                  <div className="invalid-feedback d-block mt-2 ">
                    Recuerda leer los términos y condiciones
                  </div>
                </>
              ) : null}
            </div>

            <div>
              <input
                className="form-check-input border-primary me-2 h5"
                type="checkbox"
                value=""
                required
              />
              <label
                className="form-check-label color-primary-strong mt-1"
                htmlFor="invalidCheck"
              >
                Acepto los términos legales y autorización de permisos
              </label>
              <div className="invalid-feedback">
                Debe aceptar los términos legales
              </div>
            </div>
          </div>

          <div className="text-end">
            <button
              type="submit"
              className="btn btn-degradado"
              id="enviarSoli"
              data-bs-dismiss="modal"
            >
              Enviar{" "}
              <i
                className="fa fa-chevron-right fa-fw fa-xs"
                aria-hidden="true"
              ></i>
            </button>
          </div>

          <div
            className="modal fade"
            id="success"
            tabIndex="-1"
            aria-labelledby="success"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header border-0 pb-0">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body text-center py-4 pb-5">
                  <i
                    className="fa fa-check-circle-o color-orange fa-3x mb-2"
                    aria-hidden="true"
                  ></i>
                  <h2 className="h5">Solicitud enviada exitosamente</h2>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="error"
            tabIndex="-1"
            aria-labelledby="error"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header border-0 pb-0">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body text-center py-4 pb-5">
                  <i
                    className="fa fa-times-circle-o color-orange fa-3x mb-2"
                    aria-hidden="true"
                  ></i>
                  <h2 className="h5">Error al crear solicitud</h2>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="saldo-negativo"
            tabIndex="-1"
            aria-labelledby="error"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header border-0 pb-0">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body text-center py-4 pb-5">
                  <i
                    className="fa fa-exclamation-triangle color-red fa-3x mb-2"
                    aria-hidden="true"
                  ></i>
                  <h2 className="h5">
                    La cantidad de los días solicitados es mayor <br /> al saldo
                    disponible, por lo tanto se generará <br />
                    un saldo negativo.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
      {modalState ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => handleClose(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-check-circle-o color-orange fa-3x"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">Términos y Condiciones</h2>

                    <p>
                      <b>Términos legales</b>
                      <br></br>
                      Estoy en conocimiento del saldo de días hábiles
                      disponibles posterior a la solicitud de vacaciones,
                      declarando mi conformidad con la información proporcionada
                      por el Sistema por medio del formulario.
                    </p>
                    <br></br>
                    <p>
                      <b>Autorización Permisos</b>
                      <br></br>Aprovecho la ocasión para señalar que durante mi
                      feriado, hemos revisado conjuntamente con mi jefatura,
                      estableciendo y definiendo la asignación de las
                      principales funciones de autorización o validación que
                      quedan pendientes durante mi ausencia, de manera tal de
                      asegurar la continuidad operacional de los procesos que
                      regularmente debo atender.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {!status ? (
        <div>
          <div
            visible="false"
            style={{
              width: "100%",
              textAlign: "center",
              padding: "5px",
              background: "pink",
              border: "0",
              borderRadius: "5px",
              marginBottom: "10px",
            }}
          >
            <p
              style={{ color: "red", textAlign: "center", width: "100%" }}
              className="forgot-password"
            >
              {message}
            </p>
          </div>
        </div>
      ) : null}
      {respuestaSolicitudValid ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => handleClose(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <h2 className="h5">Solicitud de vacaciones.</h2>
                    <p>{respuestaSolicitudText}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {cargando ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0"></div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-download color-orange fa-3x mb-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">Enviando solicitud..</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {respuestaSolicitudValid2 ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => handleClose2(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <h2 className="h5">Solicitud de vacaciones.</h2>
                    <p>{respuestaSolicitudText2}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {!tieneNet ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => handleClose(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-exclamation-triangle color-orange fa-3x"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">No tienes conexión</h2>
                    <p>No puedes ejecutar esta acción si no tienes conexión</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
export default SolicitarVacaciones;
