import { Children, FC, useContext } from "react";
import { Context } from "../../../context";
import { DynamicSelectProps } from "../utilities/interfaces";



const DynamicSelect: FC<DynamicSelectProps> = ({setStatus, arrayData, isLabel, status, pais, isRequired = true}) => {
    const country = pais ? pais : "";
    return(
        <>
            <select className="form-select"
                onChange={(e) => {
                    if (e.target.value === undefined) {
                        setStatus("");
                    } else {
                        setStatus(e.target.value);
                    }
                }}>
                <option value="" disabled={isRequired}>Seleccione</option>
                {Children.toArray(arrayData.map((data) => (
                    <option key={data.id} value={isLabel ? data.label : data.id} selected={isLabel ? (data.label == status || data.id === country) : (data.id == status || data.id === country)}>
                    {data.label === 'published' ? 'Publicado' : data.label === 'unpublished' ? 'Despublicado' : data.label === 'draft' ? 'Borrador' : data.label}
                    </option>
                )))}
            </select>
        </>
    )
}

export default DynamicSelect