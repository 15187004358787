import React, { Children, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { urlsAws } from "../resources/foo/api-endpoints";
import datosPersonalesService from "../services/datos-personales-service";
import fichaContratacionService from "../services/ficha-contratacion-service";
import listService from "../services/list-service";
import useOnlineState from "./elements/online-hook";
import Section from "./elements/section";
import { useCarousel } from "../services/utiles";

const DatosPersonalesCHL = () => {
    const [carouselList, setCarouselList] = useState([]);
    const [data, setData] = useState(false);
    const [DireccionCHL, setDireccionCHL] = useState(
        () => {
            const direccion = localStorage.getItem("DireccionCHL");
            if (direccion) return JSON.parse(direccion);
            else return null;

        }
    );
    const [loading, setLoading] = useState(false);
    const [bancos, setBancos] = useState(
        () => {
            const banco = localStorage.getItem("bankList");
            if (banco) return JSON.parse(banco).CN_LISTABANK_RP
            else return null;
        }
    );
    const [titles, setTitles] = useState([]);
    const isOnline = useOnlineState();
    const delay = (ms = 1500) => new Promise((r) => setTimeout(r, ms));
    let navigate = useNavigate();
    const [emplid, setEmplid] = useState(localStorage.getItem("emplid"));
    useCarousel(0,'carousel-datos-personales-mobile', carouselList);
    const [selectedCarousel, setSelectedCarousel] = useState(() => {
        const carousel = window.sessionStorage.getItem("carrusel");
        let temp = []
        caches.match(urlsAws.firstLogin).then((response) => {
            if (response) {
                response.json().then((data) => {
                    setEmplid(data.EMPLID);
                    //separar data.PERSONALDATA en en sub listas de 4 elementos
                    for (let i = 0; i < data.PERSONALDATA.length; i++) {
                        if (i % 4 == 0) temp.push([data.PERSONALDATA[i]])
                        else temp[temp.length - 1].push(data.PERSONALDATA[i])
                    }
                    setCarouselList(temp);
                    if (carousel !== null) setSelectedCarousel(carousel);
                    else setSelectedCarousel(temp[0][0].replace(/_/g, " "));
                });
            }
        })
    });
    const newCall = async () => {
        listService.getFullDirecciones(setDireccionCHL);
        await delay();
        setLoading(false);
    };

    const [hiddenTd, setHiddenTd] = useState(false);

    useEffect(() => {
        if (DireccionCHL === null || DireccionCHL.message) {
            setLoading(true);
            newCall();
        }
    }, [DireccionCHL]);

    useEffect(() => {
        if (emplid !== null && titles.length > 0) if (selectedCarousel) {
            datosPersonalesService.datosPersonales(setData, selectedCarousel.toLowerCase(), emplid)
        }
        else { navigate("/") }
    }, [emplid, navigate, selectedCarousel, titles]);
    useEffect(() => {
        if (bancos === null || bancos.message) {
            fichaContratacionService.getMethods.getBankList(setBancos);
        }
    }, [bancos]);
    useEffect(() => {
        if (DireccionCHL === null || DireccionCHL.message) {
            listService.getFullDirecciones(setDireccionCHL);
        }
    }, [DireccionCHL]);
    useEffect(() => {
        caches.open('api-cache').then(cache => cache.match(urlsAws.firstLogin).then(response => {
            if (response) response.json().then(data => {
                if (emplid !== null && titles.length === 0 && isOnline) datosPersonalesService.getTitles(emplid, data.PERSONALDATA, setTitles);
                if (emplid !== null && titles.length === 0 && !isOnline && window.sessionStorage.getItem("titles") !== null) datosPersonalesService.getTitles(emplid, JSON.parse(window.sessionStorage.getItem('titles')), setTitles);
            })
        }));
    }, [emplid, titles]);
    const selectedCarouselBtn = (item) => {
        setSelectedCarousel(null);
        for (let i = 0; i < carouselList.length; i++) {
            for (let j = 0; j < carouselList[i].length; j++) {
                if (carouselList[i][j].replace(/_/g, " ") === item) {
                    document.getElementById(item).className = "btn btn-xl text-uppercase btn-degradado-2"
                }
                else {
                    document.getElementById(carouselList[i][j].replace(/_/g, " ")).className = "btn btn-xl text-uppercase"
                }
            }
        }
        window.sessionStorage.setItem("carrusel", item);
        setSelectedCarousel(item);
    }
    return (
        <>
            {/* <!-- inicio breadcrumbs --> */}
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Datos Personales</li>
                </ol>
            </nav>
            <section className="datos-personales">
                <h1>Datos Personales</h1>
                {!loading ?
                    <>
                        <div className="form-check form-switch mb-4">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="datos-visibles"
                                defaultChecked={hiddenTd}
                                onChange={() => {
                                    setHiddenTd(!hiddenTd);
                                }}
                            />
                            <label className="form-check-label text-uppercase" htmlFor="datos-visibles">
                                Datos visibles
                            </label>
                        </div>
                        <div id="carousel-datos-personales" className="carousel carousel-dark slide carousel-control-inner mb-3 d-none d-lg-block" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                {
                                    Children.toArray(carouselList.map((items, index) => {
                                        let temp = false
                                        items.find((item) => {
                                            if (item.replace(/_/g, " ") === selectedCarousel) {
                                                temp = true
                                            }
                                        })
                                        return (
                                            <button
                                                data-bs-target="#carousel-datos-personales"
                                                data-bs-slide-to={index}
                                                className={temp ? "active" : ""}
                                                aria-label={`Slide ${index + 1}`}
                                            >
                                            </button>
                                        );
                                    }))
                                }
                            </div>
                            <div className="carousel-inner">
                                {
                                    Children.toArray(carouselList.map((items) => {
                                        let temp = false
                                        items.find((item) => {
                                            if (item.replace(/_/g, " ") === selectedCarousel) {
                                                temp = true
                                            }
                                        })
                                        return (
                                            <div className={`carousel-item p-2 ${temp ? "active" : ""}`} data-bs-interval="10000" >
                                                <div className="row">
                                                    {Children.toArray(items.map((item, index) => {
                                                        //reemplazar _ por espacio
                                                        item = item.replace(/_/g, " ");
                                                        return (
                                                            <div className="col mb-4">
                                                                {/* <!-- clase btn-degradado-2 pinta el botón --> */}
                                                                <Link
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        selectedCarouselBtn(item);
                                                                    }}
                                                                    className={`btn btn-xl text-uppercase ${selectedCarousel === item ? "btn-degradado-2" : ""}`}
                                                                    to=""
                                                                    id={item}
                                                                >
                                                                    <span style={{ pointerEvents: "none" }} className="align-self-center">
                                                                        {titles[index]}
                                                                    </span>
                                                                    <i style={{ pointerEvents: "none" }} className="fa fa-chevron-right" aria-hidden="true"></i>
                                                                </Link>
                                                            </div>
                                                        )
                                                    }))}
                                                </div>
                                            </div>
                                        )
                                    }))
                                }
                            </div>
                        </div>
                        <div id="carousel-datos-personales-mobile" className="carousel carousel-dark slide carousel-control-inner mb-3 d-lg-none" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                {
                                    Children.toArray(carouselList.flat().map((item, index) => {
                                        let temp = false;
                                        if (item.replace(/_/g, " ") === selectedCarousel) {
                                            temp = true
                                        }
                                        return (
                                            <button
                                                data-bs-target="#carousel-datos-personales-mobile"
                                                data-bs-slide-to={index}
                                                className={temp ? "active" : ""}
                                                aria-current={temp ? "true" : "false"}
                                                aria-label={`Slide ${index + 1}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    selectedCarouselBtn(item.replace(/_/g, " "));
                                                }}
                                            >
                                            </button>
                                        )
                                    }))}
                            </div>
                            <div className="carousel-inner">
                                {
                                    Children.toArray(carouselList.flat().map((items, index) => {
                                        let temp = false
                                        if (items.replace(/_/g, " ") === selectedCarousel) {
                                            temp = true
                                        }
                                        return (
                                            <div className={`carousel-item p-2 ${temp ? "active" : ""}`} data-bs-interval="10000">
                                                <Link
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        selectedCarouselBtn(items.replace(/_/g, " "));
                                                    }}
                                                    className={`btn btn-xl text-uppercase ${selectedCarousel === items.replace(/_/g, " ") ? "btn-degradado-2" : ""}`}
                                                    to="#"
                                                    id={items.replace(/_/g, " ")}
                                                >
                                                    <span style={{ pointerEvents: "none" }} className="align-self-center">
                                                        {titles[index]}
                                                    </span>
                                                    <i style={{ pointerEvents: "none" }} className="fa fa-chevron-right" aria-hidden="true"></i>
                                                </Link>
                                            </div>
                                        )
                                    }))
                                }
                            </div>
                        </div>
                        {(data && data !== "error" && data.sections !== undefined && bancos && bancos !== "error" && DireccionCHL && DireccionCHL !== "error") && (
                            Children.toArray(data.sections.map((section) => {
                                if (section !== undefined) return <Section bancos={bancos} direccion={DireccionCHL} section={section} hiddenTd={!hiddenTd} setData={setData} />;
                            })
                            ))}
                    </>
                    :
                    <div className="text-center">
                        <h3>Cargando...</h3>
                    </div>}
            </section>
        </>
    );
};

DatosPersonalesCHL.propTypes = {
    data: PropTypes.object,
    bancos: PropTypes.object,
    DireccionCHL: PropTypes.object,
    setData: PropTypes.func,
    hiddenTd: PropTypes.bool,
    setHiddenTd: PropTypes.func,
    selectedCarousel: PropTypes.string,
    setSelectedCarousel: PropTypes.func,
    selectedCarouselBtn: PropTypes.func,
    titles: PropTypes.array,
    carouselList: PropTypes.array,
};

export default DatosPersonalesCHL;
