import React from 'react'
import { Grid } from '@mui/material'
import { Category } from '../../../utilities/interfaces'
import { setCategoryIcon } from '../../../utilities/helpers/imgUrlSeter'
import AllCategoryCard from '../../allCategories/components/card'
import { SearchContent } from '../../../utilities/constantsTexts'
import { FailResultsProps } from '../interfaces'
import  textstyles from '../../../commons/styles/textStyles.module.css'
import resultsStyles from '../searchResultsStyle.module.css'

const FailResults = ({ categoriesList, onCategoryClicked } : FailResultsProps) => {
  const notFoundSuggestions = SearchContent.notFoundSuggestions
  const notFoundCategories = SearchContent.notFoundCategories

  return (
    <Grid item xs={12} className={resultsStyles.not_found_page}>
      <ul className={textstyles.custom_list}>
      {notFoundSuggestions.map((value: string, index) => {
        return(
          <li className={textstyles.custom_li} key={index}>{value}</li>
        )
      })}
      </ul>
      <h2 className={`${textstyles.page_subtitle} ${resultsStyles.not_found_category}`}>{notFoundCategories}</h2>
      <Grid item xs={12} className={resultsStyles.not_found_category_wrapper} onClick={() => onCategoryClicked()}>
        {
          categoriesList.map((category: Category) => {
            const icon = setCategoryIcon(category)
            const infoMap = {
              id: category.id,
              cardIcon: icon,
              cardTitle: category.attributes.title,
              cardSubtitle: category.attributes.subtitle,
            }
            return (
                <AllCategoryCard key={category.id} CardInfo={infoMap}/>
            )
          })
        }
      </Grid>
    </Grid>
  )
}

export default FailResults