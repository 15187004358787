import React, { useEffect, useState } from 'react'
import { Breadcrumbs, Link } from '@mui/material'
import breadCrumStyle from '../styles/breadcrumbs.module.css'
import { paths } from '../../../utilities/paths'
import { BreadcrumbLevels, LevelsProps } from '../../../utilities/interfaces'

const LevelsExpanded = ({levelsToShow}: LevelsProps) => {
  const [levels, setLevels] = useState<Array<BreadcrumbLevels>>([])

  useEffect(() => {
    levelsToShow.length > 0 && setLevels(levelsToShow)
  }, [levelsToShow])

  return (
    <Breadcrumbs className={breadCrumStyle.breadcrumb_root}>
      <Link className={breadCrumStyle.breadcrumb_item} href={paths[1].path}>
        {paths[1].breadCrumb}
      </Link>
      {levels?.map((level, index) => {
        return (
          <Link
            key={index}
            className={breadCrumStyle.breadcrumb_item}
            href={level.path}
          >
            {level.label}
          </Link>
        )
      })}
    </Breadcrumbs>
  )
}

export default LevelsExpanded