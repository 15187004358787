import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import arLang from "./locales/ar.json";
import uyLang from "./locales/uy.json";
import clLang from "./locales/cl.json";
import peLang from "./locales/pe.json";
import coLang from "./locales/co.json";

const resources = {
  AR: {
    translation: arLang,
  },
  UY: {
    translation: uyLang,
  },
  CL: {
    translation: clLang,
  },
  PE: {
    translation: peLang,
  },
  CO: {
    translation: coLang,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "AR",
  fallbackLng: "AR",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
