import { useTeamDashboardProvider } from "./context/TeamDashboardContext";
import { CarouselSubMenuList } from "./_components/carouselSubMenu";
import { ManagmentPayRoll } from "./_components/managmentPayRoll";
import { WorkEnvironment } from "./_components/workEnvironment";
import { Breadcrumb } from "./_components/breadcrumb";
import { TypeWithKey } from "../../utils";
import { DashboardTableLayout } from "./DashboardTableLayout";
import { Compensations } from "./_components/compensations";
import { PerformanceEvaluation } from "./_components/performanceEvaluation";
import { TalentReview } from "./_components/talentReview";
import { EmployeeProfileSummary } from "./_components/employeeProfileSummary";
import { VacationData } from "./_components/vacationData";

export const TeamDashboardContent = () => {
  const { getSelectedMenu, resetMenus } = useTeamDashboardProvider();

  const content: TypeWithKey<JSX.Element> = {
    performanceEvaluation: <PerformanceEvaluation />,
    jobDetails: <EmployeeProfileSummary />,
    workEnvironment: <WorkEnvironment />,
    compensations: <Compensations />,
    talentReview: <TalentReview />,
    vacationData: <VacationData/>
  };

  return (
    <>
      <Breadcrumb />

      <h1>Dashboard de mi Equipo</h1>

      {getSelectedMenu && (
        <div className="row mt-3">
          <div className="d-flex justify-content-end">
            <button
              onClick={resetMenus}
              className="btn-degradado btn btn-sm text-capitalize fw-bold"
            >
              Volver a Nómina de Gestión
            </button>
          </div>
        </div>
      )}

      <CarouselSubMenuList />

      <DashboardTableLayout>
        <>
          {!getSelectedMenu ? (
            <ManagmentPayRoll />
          ) : (
            <>{content[getSelectedMenu.name]}</>
          )}
        </>
      </DashboardTableLayout>
    </>
  );
};
