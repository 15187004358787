import Auth from "@aws-amplify/auth";
import { urlsAws } from "../resources/foo/api-endpoints";

const listService = {
    regiones: (codigopais, setData) => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
        };
        fetch(urlsAws.getRegiones + codigopais, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                localStorage.setItem("Regiones" + codigopais, JSON.stringify(data));
                setData(localStorage.getItem("Regiones" + codigopais));
            })
            .catch((error) => {
                console.log("error", error);
                setData("error");
            });
    },
    comunas: (codigopais, setData) => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
        };
        fetch(urlsAws.getComunas + codigopais, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                localStorage.setItem("Comunas" + codigopais, JSON.stringify(data));
                setData(localStorage.getItem("Comunas" + codigopais));
            })
            .catch((error) => {
                console.log("error", error);
                setData("error");
            });
    },
    getFullDirecciones: (setData) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.getComunasProvinciasRegiones , requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    let regiones = data.response.regiones.map((value) => {
                        return { country: "CHL", state: value.state, descr: value.name.replaceAll("&n", "ñ").replaceAll("&N", "Ñ") };
                    });
                    let comunas = data.response.regiones.map((value) => {
                        if (Array.isArray(value.provincias)) {
                            return value.provincias.map((provincia) => {
                                if (Array.isArray(provincia.comunas)) {
                                    return provincia.comunas.map((comuna) => {
                                        return {
                                            country: "CHL",
                                            state: value.state,
                                            city: comuna.city,
                                            descr50: comuna.nombre.replaceAll("&n", "ñ").replaceAll("&N", "Ñ"),
                                            county: provincia.county,
                                        };
                                    });
                                } else {
                                    return {
                                        country: "CHL",
                                        state: value.state,
                                        city: provincia.comunas.city,
                                        descr50: provincia.comunas.nombre.replaceAll("&n", "ñ").replaceAll("&N", "Ñ"),
                                        county: provincia.county,
                                    };
                                }
                            });
                        } else {
                            return value.provincias.comunas.map((comuna) => {
                                return {
                                    country: "CHL",
                                    state: value.state,
                                    city: comuna.city,
                                    descr50: comuna.nombre.replaceAll("&n", "ñ").replaceAll("&N", "Ñ"),
                                    county: value.provincias.county.replaceAll("&n", "ñ").replaceAll("&N", "Ñ"),
                                };
                            });
                        }
                    });
                    data.response.regiones = data.response.regiones ? Array.isArray(data.response.regiones) ? data.response.regiones : [data.response.regiones] : [];
                    data.response.regiones.forEach(region => {
                        region.name = region.name.replaceAll("&n", "ñ").replaceAll("&N", "Ñ");
                        region.provincias = region.provincias ? Array.isArray(region.provincias) ? region.provincias : [region.provincias] : [];
                        region.provincias.forEach(
                            provincia => {
                                provincia.county = provincia.county.replaceAll("&n", "ñ").replaceAll("&N", "Ñ");
                                provincia.comunas = provincia.comunas ? Array.isArray(provincia.comunas) ? provincia.comunas : [provincia.comunas] : [];
                                provincia.comunas.forEach(
                                    comuna => { comuna.nombre = comuna.nombre.replaceAll("&n", "ñ").replaceAll("&N", "Ñ"); }
                                );
                            }
                        )
                    })
                    data.response.regiones = data.response.regiones.sort((a, b) => { if (a.name < b.name) return -1; if (a.name > b.name) return 1; return 0; });
                    localStorage.setItem("RegionesCHL", JSON.stringify({ response: { cs_regiones_rp: regiones } }));
                    localStorage.setItem("ComunasCHL", JSON.stringify({ response: { cs_comunas_rp: comunas.flat(3) } }));
                    localStorage.setItem("DireccionCHL", JSON.stringify(data));
                    setData(localStorage.getItem("DireccionCHL"));
                })
                .catch((error) => {
                    console.log("error", error);
                    setData("error");
                });
        }).catch((err) => console.log(err));
    },
    setAnexoContrato: (email, setData, setLoginData) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: JSON.stringify({ request: { email: email, anxaut: "Y" } }),
            };
            fetch(urlsAws.setAnexoContrato , requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data && data.response !== undefined && data.response.return_cd !== undefined) {
                        caches.open("api-cache").then((cache) => {
                            cache.match(urlsAws.firstLogin).then((response) => response.json().then((response) => {
                                if (response !== null && response !== undefined) {
                                    cache.put(urlsAws.firstLogin, new Response(JSON.stringify({ ...response, FIRSTLOGIN: "Y" })));
                                } else {
                                    throw new Error("malo :(");
                                }
                            }));
                            setData(data);
                        });
                    } else {
                        throw new Error("malito :(");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }).catch((err) => console.log(err));
    },
};
export default listService;
