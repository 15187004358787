import React, { useState, useEffect, FormEvent, FunctionComponent } from "react";
import UnorderedList from "../../commons/forms";
import { FormList, Props, AccountData } from "../../utilities/interfaces";
import { stepSixAccount } from "../../utilities/formValues";
import { useForm, SubmitHandler } from "react-hook-form";
import OfflineModal from "../../commons/plain/modals/offline";
import { scrollToTop } from "../../../../services/utiles";

interface Terms {
    readed?: boolean;
    accepted?: boolean;
}

interface Props1 extends Props {
    prevStep(): void;
    accountData: AccountData;
    setAccountData: React.Dispatch<React.SetStateAction<AccountData>>;
    emplid: string;
}

const SixthStep: FunctionComponent<Props1> = ({ options, nextStep, prevStep, accountData, setAccountData, emplid }) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
    } = useForm<AccountData | Terms>({
        defaultValues: {
            ...accountData,
            readed: accountData.bank !== "" && accountData.numcta !== "" && accountData.tipocta !== "",
            accepted: accountData.bank !== "" && accountData.numcta !== "" && accountData.tipocta !== "",
        }
    });

    const accountType = watch("tipocta");
    const bank = watch("bank");
    const [account, setAccount] = useState([...stepSixAccount]);
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        setAccount((prevAccount) => {
            return prevAccount.map((acc) => {
                if (acc.id === "numcta") {
                    return { ...acc, readOnly: accountType === "B" && bank === "012" };
                } else {
                    return { ...acc };
                }
            })
        });
        if (bank !== "012") {
            setValue("numcta", "");
            setValue("tipocta", "");
        }
    }, [bank]);

    useEffect(() => {
        scrollToTop();
    }, []);

    useEffect(() => {
        setAccount((prevAccount) => {
            return prevAccount.map((acc) => {
                if (acc.id === "numcta") {
                    return { ...acc, readOnly: accountType === "B" && bank === "012" };
                } else {
                    return { ...acc };
                }
            })
        });
        if (accountType === "B") {
            setValue("bank", "012");
            setValue("numcta", emplid.split("-")[0]);
        } else {
            setValue("numcta", "");
        }
    }, [accountType]);

    useEffect(() => {
        accountData.numcta !== "" && setValue("numcta", accountData.numcta);
    }, [accountData])

    const formSubmitHandler: SubmitHandler<AccountData | Terms> = (data: AccountData | Terms) => {
        if (navigator.onLine) {
            setDisableButton(true);
            let newData = { ...data };
            if ("tipocta" in newData && "bank" in newData && "numcta" in newData) {
                setAccountData({ tipocta: newData.tipocta, bank: newData.bank, numcta: newData.numcta });
            }
            nextStep();
        } else {
            document.getElementById("offline")?.click();
        }
    };

    useEffect(() => {
        return () => setDisableButton(false);
    }, []);

    return (
        <>
            <form
                className="needs-validation"
                onSubmit={handleSubmit(formSubmitHandler)}
            >
                <div id={"pension"} className="box mb-4">
                    <div>
                        <p>
                            {"Advertencia: Las cuenta como MACH, FPAY, TENPO, etc. no está autorizada para depósito de remuneraciones por la institución bancaria."}
                        </p>
                    </div>
                    <h2 className="h4 tit-section" children={"Forma de Pago"} />
                    <UnorderedList formList={account as Array<FormList>} options={options} register={register} errors={errors} accountTerms={true} />
                </div>
                <div className="d-grid d-md-flex justify-content-md-between gap-2">
                    <button type="button" className="btn btn-primary" onClick={(e) => { e.preventDefault(); prevStep() }}>
                        <i className="fa fa-chevron-left fa-fw fa-xs" aria-hidden="true" />
                        {"Anterior"}
                    </button>
                    <button type="submit" className="btn btn-degradado" disabled={disableButton}>
                        {"Siguiente"}
                        <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                    </button>
                </div>
            </form>
            <OfflineModal />
        </>
    );
};

export default SixthStep;