import { Dispatch, SetStateAction } from "react";
import { taskEither as TE } from "fp-ts";
import * as t from "io-ts";

type id = string;
interface kpi {
    id: string,
    name: string,
    weight: number,
    applySame: boolean,
}
interface BreadCrumbsProps {
    crumbs: Array<{
        name: string;
        url: string;
    }>;
    actual: string;
}
interface CarouselProps {
    carouselSelected: number;
    setCarouselSelected: Dispatch<SetStateAction<number>>;
    views: Array<string>;
}

export const Indicator = t.type({
    indicatorCode: t.string,
    indicatorDescr: t.string,
    indicatorWeight: t.string,
    indicatorType: t.string,
});

export type Indicator = t.TypeOf<typeof Indicator>;

export const Indicators = t.union([t.array(Indicator), t.UnknownArray]);

export type Indicators = t.TypeOf<typeof Indicators>;

export const Model = t.type({
    modelId: t.string,
    modelDescr: t.string,
    modelContainsKpi: t.boolean,
    indicators: t.array(Indicator)
});

export type Model = t.TypeOf<typeof Model>

export const Models = t.union([t.array(Model), t.UnknownArray]);

export type Models = t.TypeOf<typeof Models>;

export const directReport = t.type({
    emplid: t.string,
    name: t.string,
    jobCode: t.string,
    jobCodeDescr: t.string,
    newHired: t.boolean,
    models: Model,
});

export type directReport = t.TypeOf<typeof directReport>;

export const directReports = t.array(directReport);

export type directReports = t.TypeOf<typeof directReports>;

export const KPI = t.type({
    kpiName: t.string,
    kpiWeight: t.string,
});

export type KPI = t.TypeOf<typeof KPI>;

export const KPIs = t.union([t.array(KPI), t.UnknownArray]);

export type KPIs = t.TypeOf<typeof KPIs>;

export const referenceKPI = t.type({
    emplid: t.string,
    name: t.string,
    kpi: t.array(KPI)
});
export type referenceKPI = t.TypeOf<typeof referenceKPI>;

export const referenceKPIs = t.union([t.array(referenceKPI), t.UnknownArray]);

export type referenceKPIs = t.TypeOf<typeof referenceKPIs>;

export const AnnualBondData = t.type({
    valid: t.string,
    status: t.string,
    period: t.string,
    managePeriod: t.boolean,
    defaultModelDesc: t.string,
    contactEmail: t.string,
    periodBeginDate: t.string,
    periodEndDate: t.string,
    periodFinalDate: t.string,
    nameDisplay: t.string,
    models: t.array(Model),
    directReports: t.array(directReport),
    referenceKpi: t.array(referenceKPI),
    lastAssignation: t.boolean
});

export type AnnualBondData = t.TypeOf <typeof AnnualBondData>;

interface AssignModelProps {
    data: AnnualBondData;
    setCarouselSelected: Dispatch<SetStateAction<number>>;
}

interface SelectModelProps {
    data: AnnualBondData;
    setDirectReportsList: Dispatch<SetStateAction<asignation[]>>;
    setShowTable: Dispatch<SetStateAction<boolean>>;
    hasKpiByReport: boolean[];
    setHasKpiByReport: Dispatch<SetStateAction<boolean[]>>;
    setSuccess: Dispatch<SetStateAction<{ valid: number, message: string }>>;
    lastAssignation: boolean;
}

interface DefineKpiProps extends SelectModelProps {
    showTable: boolean;
    directReportsList: asignation[];
}

interface AssignByJobCodeProps {
    directReportsData: directReports;
    referenceKpi: referenceKPI[];
    models: Model[];
    contactEmail: string;
    period: string;
}

interface ModalSuccessProps {
    success: {
        valid: number;
        message: string;
    };
}

interface ModalWarningProps { 
    asignations: asignation[];
    setCarouselSelected?: Dispatch<SetStateAction<number>>;
    period: string;
    setValidPostResponse?: Dispatch<SetStateAction<boolean>>;
}

interface KpiModalProps {
    kpiList: kpi[];
    setKpiList: Dispatch<SetStateAction<kpi[]>>;
}

interface AddKpiTableProps extends KpiModalProps {
    contactEmail?: string;
    reportEmplid?: string;
    reportName?: string;
    setKpiLists?: Dispatch<SetStateAction<kpi[][]>>;
    nameToDiplay?: string;
};

interface PreviewModalProps {
    reportsToDisplay: directReports;
    kpiLists: kpi[][];
    setSuccess: Dispatch<SetStateAction<{ valid: number, message: string }>>;
}

export type HttpRequestError = {
    tag: 'httpRequestError';
    error: unknown;
}
  
export type HttpContentTypeError = {
    tag: 'httpContentTypeError';
    error: unknown;
}

export type HttpResponseStatusError = {
    tag: 'httpResponseStatusError';
    status: number;
}

export interface HttpClient {
    request(
        input: RequestInfo,
        init?: RequestInit,
        ): TE.TaskEither<HttpRequestError, Response>
}
    
export interface HttpClientEnv {
    httpClient: HttpClient;
}

export const AnnualBondCodec = t.type({
    response: AnnualBondData,
});

export type AnnualBond = t.TypeOf<typeof AnnualBondCodec>;

export const KPIpost = t.intersection([
    t.type({
        kpiId: t.string,
        kpiName: t.string,
        kpiWeight: t.number,
    }),
    t.partial({
        applySameKpi: t.boolean
    })
]);


export type KPIpost = t.TypeOf<typeof KPIpost>;

export const KPIsPost = t.union([t.array(KPIpost), t.UnknownArray]);

export type KPIsPost = t.TypeOf<typeof KPIsPost>;

export const indicadorC = t.intersection([
    t.type({
        indicatorCode: t.string,
        indicatorWeight: t.string,
    }),
    t.partial({
        indicatorType: t.string,
        kpi: t.array(KPIpost)
    })
]);

export type indicador = t.TypeOf<typeof indicadorC>

export const indicadoresC = t.array(indicadorC);

export type indicadores = t.TypeOf<typeof indicadoresC>

export const asignation = t.intersection([
    t.type({
        emplid: t.string,
        modelId: t.string,
        allDependencies: t.boolean,
        asignationIndicators: indicadoresC
    }),
    t.partial({
        keepSameModel: t.boolean
    })
])

export type asignation = t.TypeOf<typeof asignation>

export const asignatorEmplid = t.type({
    asignatorEmplid: t.string,
});

export type asignatorEmplid = t.TypeOf<typeof asignatorEmplid>

const postAnnualBondData = t.type({
    emplid: t.string,
    asignations: t.array(asignation),
});

export const postAnnualBondCodec = t.type({
    request: postAnnualBondData,
});

export type PostAnnualBond = t.TypeOf<typeof postAnnualBondCodec>

export const responsePostContentC = t.type({
    valid: t.string,
    status: t.string,
})

export type ResponsePostContent = t.TypeOf<typeof responsePostContentC>

export const PostDocumentContentC = t.type({
    valid: t.string,
    status: t.string,
    file: t.string,
})

export type PostDocumentContent = t.TypeOf<typeof PostDocumentContentC>

export const responsePostAnnualBondCodec = t.type({
    response: responsePostContentC,
})

export type ResponsePostAnnualBond = t.TypeOf<typeof responsePostAnnualBondCodec>

export const responsePostDocumentCodec = t.type({
    response: PostDocumentContentC,
})

export type ResponsePostDocument = t.TypeOf<typeof responsePostDocumentCodec>

const allManagement = t.union([t.boolean, t.null]);
const selectedModel = t.union([t.string, t.undefined]);
interface DownloadDocumentProps {
    assignations: asignation[];
    selectedModel: t.TypeOf<typeof selectedModel>;
    setSuccess: Dispatch<SetStateAction<{ valid: number, message: string }>>;
    period: string;
    nameDisplay: string;
    allManagement: t.TypeOf<typeof allManagement>
}   

export const myModelC = t.type({
    asignatorId: t.string,
    asignatorName: t.string,
    asignationDate: t.string,
    asignationYear: t.string,
    modelId: t.string,
    modelDescr: t.string,
    allDependencies: t.boolean,
    bondSent: t.boolean,
    asignationIndicators: t.array(t.type({
        indicatorCode: t.string,
        indicatorWeight: t.string,
        kpi: t.array(t.type({
            kpiName: t.string,
            kpiWeight: t.string,
        }))
    })),
})

export type MyModel = t.TypeOf<typeof myModelC>

export const teamAsignationC = t.type({
    emplid: t.string,
    name: t.string,
    jobcode: t.string,
    jobCodeDescr: t.string,
    modelId: t.string,
    allDependencies: t.boolean,
    bondSent: t.boolean,
    modelDescr: t.string,
    asignationIndicators: t.array(t.type({
        indicatorCode: t.string,
        indicatorWeight: t.string,
        kpi: t.array(t.type({
            kpiName: t.string,
            kpiWeight: t.string,
        }))
    })),
})

export type TeamAsignation = t.TypeOf<typeof teamAsignationC>

export const getMyModelContentC = t.type({
    myModel: t.array(myModelC),
    valid: t.string,
    status: t.string,
})

export type GetMyModelContent = t.TypeOf<typeof getMyModelContentC>

export const getTeamModelContentC = t.type({
    teamAsignation: t.array(teamAsignationC),
    valid: t.string,
    status: t.string,
})

export type GetTeamModelContent = t.TypeOf<typeof getTeamModelContentC>

export const getMyModelResponseCodec = t.type({
    response: getMyModelContentC,
})

export type GetMyModelResponse = t.TypeOf<typeof getMyModelResponseCodec>

export const getTeamAsignationResponseCodec = t.type({
    response: getTeamModelContentC,
})

export type GetTeamAsignationResponse = t.TypeOf<typeof getTeamAsignationResponseCodec>
interface MyModelProps {
    data: GetMyModelContent;
}

interface TeamModelProps {
    data: GetTeamModelContent;
}

export type HttpError =
  | HttpRequestError
  | HttpContentTypeError
  | HttpResponseStatusError
  | t.Errors

interface SelectModelTableProps {
    directReports: directReports;
    models: Model[];
    hasKpiByReport: boolean[];
    setHasKpiByReport: Dispatch<SetStateAction<boolean[]>>;
    everyoneUnder: boolean[];
    setEveryoneUnder: Dispatch<SetStateAction<boolean[]>>;
    modelCheckedPerReport: string[];
    setModelCheckedPerReport: Dispatch<SetStateAction<string[]>>;
    preview: boolean;
    setPreview: Dispatch<SetStateAction<boolean>>;
    lastAssignation: boolean;
}
  
export type {
    BreadCrumbsProps,
    CarouselProps,
    AssignModelProps,
    ModalSuccessProps,
    ModalWarningProps,
    AddKpiTableProps,
    KpiModalProps,
    id,
    kpi,
    SelectModelProps,
    SelectModelTableProps,
    DefineKpiProps,
    DownloadDocumentProps,
    MyModelProps,
    TeamModelProps,
    PreviewModalProps,
    AssignByJobCodeProps,
};
