import ico_uy from "../resources/images/flag_uruguay.svg";
import ico_usa from "../resources/images/flag_usa.svg";
import ico_ar from "../resources/images/ico_ar.svg";
import ico_br from "../resources/images/ico_br.svg";
import ico_cl from "../resources/images/ico_cl.svg";
import ico_col from "../resources/images/ico_col.svg";
import ico_pe from "../resources/images/ico_pe.svg";
import { TypeWithKey } from "./global.types";

export const flagByCountry: TypeWithKey<string> = {
  AR: ico_ar,
  CL: ico_cl,
  CO: ico_col,
  UY: ico_uy,
  PE: ico_pe,
  BR: ico_br,
  US: ico_usa,
} as const;

export const countryDictionary = (country: string) => {
  const countryCode: TypeWithKey<string> = {
    Argentina: "AR",
    Chile: "CL",
    Colombia: "CO",
    Uruguay: "UY",
    Perú: "PE",
    Brasil: "BR",
    "Estados Unidos": "US",
  };

  return countryCode[country];
};

export const reverseCountryDictionary = (code: string) => {
  const countryName: TypeWithKey<string> = {
    AR: "Argentina",
    CL: "Chile",
    CO: "Colombia",
    UY: "Uruguay",
    PE: "Perú",
    BR: "Brasil",
    US: "Estados Unidos",
  };

  return countryName[code]
};