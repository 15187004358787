import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

interface Props {
    photo: string;
    setPhoto: React.Dispatch<React.SetStateAction<string | null>>;
}

const Preview: FunctionComponent<Props> = ({ photo, setPhoto }) => {
  return (
    <div className="d-flex justify-content-start align-items-end">
        <div className="col-sm-6 w-25 h-50">
            <img
                className="img-thumbnail img-fluid mt-2"
                src={photo}
                alt=""
            />
        </div>
        <Link
            to=""
            id={"uploadedPhoto"}
            onClick={(e) => {e.preventDefault(); setPhoto("")}}
            style={{
                pointerEvents: window.navigator.onLine ? "auto" : "none",
            }}
        >
            <i className="fa fa-trash-o ms-3 fa-lg color-primary" aria-hidden="true" />
        </Link>
    </div>
  )
}

export default Preview;