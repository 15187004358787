import React, { useEffect, useState } from "react";
import defaultPhoto from "../../assets/defaultCollaborator/defaultCollaborator.png";
import { PhotoProps } from "../../pages/warning";
import { NameCollaborator } from "../../utilities/interfaces";

interface CardColabProps {
    collaborator: NameCollaborator[];
    photos: PhotoProps[];
    emplidColab: string;
}

const ColaboradorCard: React.FC<CardColabProps> = ({collaborator, photos, emplidColab }) => {
    const [dataColab,setDataColab] = useState<NameCollaborator[]>([]);

    useEffect(() => {
        if (collaborator && emplidColab) {
            let datosColab = collaborator.filter((empl)=>empl.EMPLID === emplidColab.toLocaleUpperCase())
            setDataColab(datosColab)
        } else {
            setDataColab([])
        }
    }, [collaborator, emplidColab])
    
    return (
        <>
            <div className="col-12 col-xl-4 d-flex mb-4 mb-xl-0">
                <div className="box align-items-stretch w-100">
                    <img 
                        src={ photos.length>0 ?
                                photos[0].photo !== "" && photos[0].photo!==undefined ?
                                    "data:image/png;base64," + photos[0].photo
                                :
                                    defaultPhoto
                            :
                                defaultPhoto
                        }
                        alt=""
                        className="rounded-circle mb-3"
                        width="120"
                        height="120"
                    />
                    <div className="data-list-box data-list-box-align-middle mt-3">
                        <ul className="list-unstyled list-unstyled">
                            <li>
                                <span className="data-list-box__title col-6 color-primary d-inline-block">Nombre</span>
                                <span className="data-list-box__text">{dataColab[0]?.NOMBRE ?? "-"}</span>
                            </li>
                            <li>
                                <span className="data-list-box__title col-6 color-primary d-inline-block">Posición</span>
                                <span className="data-list-box__text">{dataColab[0]?.POSICION ?? "-"}</span>
                            </li>
                            <li>
                                <span className="data-list-box__title col-6 color-primary d-inline-block">Ubicación</span>
                                <span className="data-list-box__text">{dataColab[0]?.UBICACION ?? "-"}</span>
                            </li>
                            <li>
                                <span className="data-list-box__title col-6 color-primary d-inline-block">Empresa</span>
                                <span className="data-list-box__text">{dataColab[0]?.EMPRESA ?? "-"}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>  
        </>
    )
}

export default ColaboradorCard;
