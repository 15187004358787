import { useState, useEffect } from 'react';
import styles from './replacement-assignment.module.css';
import ReplacementComponent from './replacement-dc';
import Swal from 'sweetalert2';
import moment from 'moment';
import { useForm } from "react-hook-form";
import { FaChevronRight } from 'react-icons/fa';
import { BsExclamationCircle } from 'react-icons/bs';
import ReplacementsService from '../../services/asignacion-reemplazo/replacements-service';
import ReactDomServer from 'react-dom/server'
import { useNavigate } from "react-router-dom";

const ReplacementExportableComponent = (props) => {
    const { initDate, endDate, current, replacements, hideSendButton, isSubmitForm } = props;
    const [replacementsPrompt, setReplacementsPrompt] = useState([]);
    const [loadingPost, setLoadingPost] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [replacement, setReplacement] = useState({
        person: '',
        init: initDate,
        end: endDate
    });
    const [response, setResponse] = useState({});
    const [success, setSuccess] = useState(false);
    const [noData, setNoData] = useState(false);
    const {
        register,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (initDate || endDate) {
            setReplacement({
                ...replacement,
                init: initDate,
                end: endDate
            })
        }
    }, [initDate, endDate])

    useEffect(() => {
        setReplacementsSelector();
    }, [current])

    const successSwal = (data) => Swal.fire({
        allowOutsideClick: false,
        position: 'center',
        title: ReactDomServer.renderToString(
            <>
                <i className="fa fa-check-circle-o color-orange fa-3x" aria-hidden="true"></i>
                <h2 className="h5">El reemplazo se ha asignado correctamente con la siguiente información:</h2>
            </>),
        html: ReactDomServer.renderToString(
            <>
                <ul className="h5" style={{ listStyleType: 'none', textAlign: 'center' }}>
                    <li><strong>Responsable:</strong> {data.fullname}</li>
                    <li><strong>Fecha inicio reemplazo:</strong> {moment.utc(data.init_date).format('DD-MM-YYYY')}</li>
                    <li><strong>Fecha fin:</strong> {moment.utc(data.return_date).format('DD-MM-YYYY')}</li>
                </ul>
            </>),
        confirmButtonText: ReactDomServer.renderToString(<button
            className={styles['save-button']}>
            Cerrar
        </button>),
        confirmButtonColor: '#fff'
    }).then((result) => {
        if (result.isConfirmed) {
            setReplacement({});
            setResponse({});
            setSuccess(false);
            setReplacementsSelector();
        }
    });

    const existingReplacementSwal = () => Swal.fire({
        allowOutsideClick: false,
        position: 'center',
        title: '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
        html: '<div style="text-align: center; font-size: 15px">Ya posee un reemplazo asignado.</div>',
        confirmButtonColor: '#0169b5',
    });


    const setReplacementsSelector = async () => {
        await ReplacementsService.getReplacementPrompt(setLoading, setReplacementsPrompt, setNoData);
    }

    const succesSave = (data) => {
        setResponse(data)
        successSwal(data)
    }

    const handleSave = async () => {
        try {
            const areDatesValid = validateDatesWithVigentsReplacements(replacement);
            if (areDatesValid) {
                const body = updateReplacement(replacement);
                await ReplacementsService.saveReplacement(setLoadingPost, body, succesSave, setSuccess);
            } else existingReplacementSwal()
        } catch (error) {
            existingReplacementSwal()
            console.error('Error while saving replacement:', error);
        } finally {
            setLoadingPost(false);
        }
    };

    const validateDatesWithVigentsReplacements = (replacement) => {
        let isValid = true;

        replacements.filter(r => r.status === 1).forEach(rep => {
            let currentInitDate = moment(rep.init_date);
            let currentEndDate = moment(rep.return_date);

            let selectedInitDate = moment(replacement.init);
            let selectedEndDate = moment(replacement.end);

            if (
                (currentInitDate.isBetween(selectedInitDate, selectedEndDate, undefined, '[]')) ||
                (currentEndDate.isBetween(selectedInitDate, selectedEndDate, undefined, '[]')) ||
                (selectedInitDate.isBetween(currentInitDate, currentEndDate, undefined, '[]')) ||
                (selectedEndDate.isBetween(currentInitDate, currentEndDate, undefined, '[]'))
            ) isValid = false
        });

        return isValid
    }

    useEffect(() => {
        if(isSubmitForm && replacement.person) {
            handleSave()
        };
    }, [isSubmitForm, replacement])

    const updateReplacement = (updatedValues) => {
        const selectedTeamMember = replacementsPrompt.find(member => member.name === updatedValues.person);

        if (selectedTeamMember) {
            const { ad, legajo, nro_document, email } = selectedTeamMember;
            const postValues = {
                replacementUsername: ad,
                fileNumber: legajo,
                nroDocument: nro_document,
                init: updatedValues.init,
                end: updatedValues.end,
                name: updatedValues.person,
                email: email
            };
            return postValues;
        }
    };
    return (
        <>
            {!loading ?
                !noData ?
                    <>
                        <div className={'col-12'}>
                            <ReplacementComponent
                                errors={errors}
                                register={register}
                                team={replacementsPrompt}
                                replacement={replacement}
                                setReplacement={setReplacement}
                                replacements={replacements}
                                hideSendButton={hideSendButton}
                            />
                        </div>
                        {
                        !hideSendButton && 
                        <div className={'col-12'}>
                            <div className={`${styles['button-container']} `}>
                                <button onClick={handleSave}
                                    disabled={loadingPost || !replacement.person || !replacement.init || !replacement.end}
                                    className={`${styles['save-button']} ${loading ? styles['save-button-load'] : ''}`}>
                                    Asignar {loadingPost && <div className="spinner-border spinner-border-sm"></div>}
                                    {!loadingPost && <FaChevronRight />}
                                </button>
                            </div>
                        </div>
                        }
                    </>

                    : <div className="alert alert-info alert-dismissible fade show text-center" role="alert">
                        <BsExclamationCircle />  No se encontraron datos asociados
                    </div> : <div className="spinner-border full-spinner text-primary"></div>}
        </>

    )


}
export default ReplacementExportableComponent;