import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import UserImage from "../../resources/images/default_profile_foto.png";
import Auth from '@aws-amplify/auth';
import DatosLaboralesService from '../../services/datos laborales/datos-laborales-service';


const Inicio = () => {
    const [loadingHome, setLoadingHome] = useState(true);
    const [name, setName] = useState('');
    const [avatar, setAvatar] = useState(null);


    useEffect(() => {
        Auth.currentUserInfo().then(user => {
            setName(user.attributes.given_name);
            setLoadingHome(false);
        }).catch(err => console.log(err));

    }, [name]);

    useEffect(() => {
        loadAvatar();
    }, []);

    const loadAvatar = async () => {
        try {
            const response = await DatosLaboralesService.getAvatarRegional();
            setAvatar(response.data);
        } catch (e) {
            setAvatar(null);
        }
    }


    return (
        <div>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home color-orange" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                </ol>
            </nav>
            {loadingHome ? <div data-testid="loading" className="full-spinner"><div className="spinner-border text-primary"></div></div>
                : <section>
                    <h2 className=" h1 text-center text-md-start">¡Hola {name}!</h2>
                    <div className="card-box d-md-flex align-items-center mb-5 text-center text-md-start">
                        {/* <img src={UserImage} alt="" className="rounded-circle mb-3 mb-md-0 me-0 me-md-5" width="120" height="120" /> */}
                        {avatar ? <img src={avatar} alt="" className="rounded-circle mb-3 mb-md-0 me-0 me-md-5" width="120" height="120" />
                            : (<div className="main-image me-0 me-md-5">
                                <svg style={{ opacity: "0.75", fill: `url(#SVGID_1_)` }} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 170.4 169.8" xmlSpace="preserve">

                                    <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="2.8226" y1="83.7369" x2="166.4679" y2="83.7369">
                                        <stop offset="0.1534" style={{ "stop-color": "#0069B4" }} />
                                        <stop offset="0.2558" style={{ "stop-color": "#0069B4" }} />
                                        <stop offset="0.3273" style={{ "stop-color": "#0B73BB" }} />
                                        <stop offset="0.6238" style={{ "stop-color": "#3498D4" }} />
                                        <stop offset="0.8583" style={{ "stop-color": "#4DAFE3" }} />
                                        <stop offset="1" style={{ "stop-color": "#56B8E9" }} />
                                    </linearGradient>
                                    <path className="st0" d="M84.8,1.9c11.1,0,21.6,2.3,31.7,6.5s18.9,10.1,26.1,17.3s13.1,16.1,17.3,26.1s6.5,20.6,6.5,31.7
        s-2.3,21.6-6.5,31.7s-10.1,18.9-17.3,26.1s-16.1,13.1-26.1,17.6s-20.6,6.5-31.9,6.5s-21.9-2.3-31.9-6.5
        c-10.1-4.3-18.9-10.1-26.1-17.6c-6.8-7.3-12.8-16.1-17.1-26.1S2.8,94.7,2.8,83.6S5.1,62,9.4,51.9s10.1-18.9,17.3-26.1
        S42.8,12.7,53.1,8.5S73.7,1.9,84.8,1.9z M141.3,125.1c9-12.6,13.6-26.4,13.6-41.5c0-9.6-1.8-18.6-5.5-27.1s-8.8-16.1-15.1-22.4
        S120.5,22.8,111.9,19s-17.6-5.5-27.1-5.5S66.2,15.2,57.6,19s-16.1,8.8-22.4,15.1S23.9,47.9,20.2,56.5s-5.5,17.6-5.5,27.1
        c0,15.3,4.5,29.2,13.6,41.5c4-19.9,13.3-29.9,27.9-29.9c8,7.8,17.6,11.6,28.7,11.6s20.6-3.8,28.7-11.6
        C128,95.4,137.3,105.2,141.3,125.1z M119.7,66c0-9.6-3.5-17.8-10.3-24.9c-6.8-7-15.1-10.3-24.9-10.3s-17.8,3.5-24.9,10.3
        S49.3,56.2,49.3,66s3.5,17.8,10.3,24.9s15.1,10.3,24.9,10.3s17.8-3.5,24.9-10.3S119.7,75.8,119.7,66z"/>
                                </svg>
                            </div>)
                        }


                        <p className="h3 mb-4 mb-md-0">Te presentamos el nuevo Portal de Recursos Humanos, diseñado para facilitar tus solicitudes y consultas a través de un simple clik.</p>
                    </div>
                    <h2 className=" h2 text-center text-md-start">Accesos Directos</h2>


                    {/* <!-- inicio carrusel desktop  --> */}
                    <div id="carousel-accesos-directos" className="carousel carousel-dark slide my-5 d-none d-md-block" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carousel-accesos-directos" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carousel-accesos-directos" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner">
                            {/* <!-- dos columnas --> */}
                            <div className="carousel-item active" data-bs-interval="10000">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-12 mb-4 mb-lg-0 ">
                                        <div className="icons-big text-center px-4">
                                            <Link to="/arg/datospersonales" className="text-decoration-none d-block">
                                                <i className="fa fa-user-o d-block fa-fw fa-4x mb-4" aria-hidden="true"></i>
                                                <h3 className="h4 fw-bold text-uppercase">Datos Personales</h3>
                                                <p className="color-normal">Actualizá tus datos personales, familiares y de emergencia.</p>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12 mb-4 mb-lg-0">
                                        <div className="icons-big text-center border-primary border-start px-4">
                                            <Link to="/arg/descuento-caja" className="text-decoration-none d-block">
                                                <i className="fa fa-handshake-o d-block fa-fw fa-4x mb-4" aria-hidden="true"></i>
                                                <h3 className="h4 fw-bold text-uppercase">Mis beneficios</h3>
                                                <p className="color-normal">Descubrí los beneficios que tenemos preparados para vos.</p>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 mb-4 mb-lg-0">
                                        <div className="icons-big text-center border-primary border-start px-4">
                                            <Link to="/arg/recibos" className="text-decoration-none d-block">
                                                <i className="fa fa-file-text-o color-orange d-block fa-fw fa-4x mb-4" aria-hidden="true"></i>
                                                <h3 className="h4 fw-bold text-uppercase">Recibos</h3>
                                                <p className="color-normal">Descargá tus recibos de sueldo.</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- dos columnas - pag 2 --> */}
                            <div className="carousel-item" data-bs-interval="10000">
                                <div className="row">
                                    <div className="col-4 mb-4 mb-lg-0">
                                        <div className="icons-big text-center px-4">
                                            <Link to="https://cencosud.csod.com/phnx/driver.aspx?routename=Social/UniversalProfile/Transcript" target="_blank"className="text-decoration-none d-block">
                                                <i className="fa fa-graduation-cap color-orange d-block fa-fw fa-4x mb-4" aria-hidden="true"></i>
                                                <h3 className="h4 fw-bold text-uppercase">Cursos</h3>
                                                <p className="color-normal">Conocé y accedé a los cursos.</p>
                                            </Link> 
                                        </div>
                                    </div>
                                    <div className="col-4 mb-4 mb-lg-0">
                                        <div className="icons-big text-center border-primary border-start px-4">
                                            <Link to="https://cencosud.csod.com/phnx/driver.aspx?routename=Social/UniversalProfile/Resume" target="_blank" className="text-decoration-none d-block">
                                                <i className="fa fa-address-card-o d-block fa-fw fa-4x mb-4" aria-hidden="true"></i>
                                                <h3 className="h4 fw-bold text-uppercase">Mi Curriculum</h3>
                                                <p className="color-normal">Accedé a tu Curriculum y actualizalo.</p>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-4 mb-4 mb-lg-0">
                                        <div className="icons-big text-center border-primary border-start px-4">
                                            <i className="fa fa-envelope-o color-orange d-block fa-fw fa-4x mb-4" aria-hidden="true"></i>
                                            <h3 className="h4 fw-bold text-uppercase">¡Contactanos!</h3>
                                            <p className="color-normal">Por cualquier consulta escribinos a: <a href="mailto:csarrhh@cencosud.com.ar">csarrhh@cencosud.com.ar</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*mobile */}
                    <div id="carousel-accesos-directos-mobile" className="carousel carousel-dark slide my-5 d-md-none" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carousel-accesos-directos-mobile" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carousel-accesos-directos-mobile" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carousel-accesos-directos-mobile" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#carousel-accesos-directos-mobile" data-bs-slide-to="3" aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#carousel-accesos-directos-mobile" data-bs-slide-to="4" aria-label="Slide 5"></button>
                            <button type="button" data-bs-target="#carousel-accesos-directos-mobile" data-bs-slide-to="5" aria-label="Slide 6"></button>
                        </div>
                        <div className="carousel-inner">

                            <div className="carousel-item active" data-bs-interval="10000">
                                <div className="icons-big text-center">
                                    <Link to="/arg/datospersonales" className="text-decoration-none d-block">
                                        <i className="fa fa-user-o d-block fa-fw fa-4x mb-4" aria-hidden="true"></i>
                                        <h3 className="h4 fw-bold text-uppercase">Datos Personales</h3>
                                        <p className="color-normal">Actualizá tus datos personales, familiares y de emergencia.</p>
                                    </Link>
                                </div>
                            </div>

                            <div className="carousel-item" data-bs-interval="10000">
                                <div className="icons-big text-center">
                                    <Link to="/arg/descuento-caja" className="text-decoration-none d-block">
                                        <i className="fa fa-handshake-o d-block fa-fw fa-4x mb-4" aria-hidden="true"></i>
                                        <h3 className="h4 fw-bold text-uppercase">Mis beneficios</h3>
                                        <p className="color-normal">Descubrí los beneficios que tenemos preparados para vos.</p>
                                    </Link>
                                </div>
                            </div>

                            <div className="carousel-item" data-bs-interval="10000">
                                <div className="icons-big text-center">
                                    <Link to="/arg/recibos" className="text-decoration-none d-block">
                                        <i className="fa fa-file-text-o color-orange d-block fa-fw fa-4x mb-4" aria-hidden="true"></i>
                                        <h3 className="h4 fw-bold text-uppercase">Recibos</h3>
                                        <p className="color-normal">Descargá tus recibos de sueldo.</p>
                                    </Link>
                                </div>
                            </div>

                            <div className="carousel-item" data-bs-interval="10000">
                                <div className="icons-big text-center">
                                <Link to="https://cencosud.csod.com/phnx/driver.aspx?routename=Social/UniversalProfile/Transcript" target="_blank" className="text-decoration-none d-block">
                                                <i className="fa fa-graduation-cap color-orange d-block fa-fw fa-4x mb-4" aria-hidden="true"></i>
                                                <h3 className="h4 fw-bold text-uppercase">Cursos</h3>
                                                <p className="color-normal">Conocé y accedé a los cursos.</p>
                                            </Link>
                                </div>
                            </div>

                            <div className="carousel-item" data-bs-interval="10000">
                                <div className="icons-big text-center">
                                <Link to="https://cencosud.csod.com/phnx/driver.aspx?routename=Social/UniversalProfile/Resume" target="_blank" className="text-decoration-none d-block">
                                                <i className="fa fa-address-card-o d-block fa-fw fa-4x mb-4" aria-hidden="true"></i>
                                                <h3 className="h4 fw-bold text-uppercase">Mi Curriculum</h3>
                                                <p className="color-normal">Accedé a tu Curriculum y actualizalo.</p>
                                            </Link>
                                </div>
                            </div>

                            <div className="carousel-item" data-bs-interval="10000">
                                <div className="icons-big text-center">
                                    <i className="fa fa-envelope-o color-orange d-block fa-fw fa-4x mb-4" aria-hidden="true"></i>
                                    <h3 className="h4 fw-bold text-uppercase">¡Contactanos!</h3>
                                    <p className="color-normal">Por cualquier consulta escribinos a: <a href="mailto:csarrhh@cencosud.com.ar">csarrhh@cencosud.com.ar</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </div>
    )
}

export default Inicio
