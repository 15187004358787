import { Auth } from "aws-amplify";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BACKEND_URL_GLOBAL;
const DatosLaboralesService = {
    getEmployee: async (setInfo, setLoadingInfo, setNoData) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accessToken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/emp`, { headers: head })
                setInfo(data)
                setLoadingInfo(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingInfo(false);
                setNoData(true);
            })
        } catch (error) {
            console.error(error);
        }
    },
    getAvatar: async (setAvatar, setLoadingAvatar, setNoAvatar) => {
        try {
            Auth.currentSession().then(async auth => {
                const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/emp/avatar`
                   , { headers: head })
                setAvatar(data.avatar)
                setLoadingAvatar(false);
            }).catch(function (error) {
                console.log(error);
                setLoadingAvatar(false);
                setNoAvatar(true);
            })
        } catch (error) {
            console.error(error);
        }
    },
    postAvatar: (file, setLoadingPostAvatar, setErrorPostAvatar, setPostAvatar) => {
        const body = {
            "avatar": file
        }
        Auth.currentSession().then(auth => {
            const head = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() }
            axios.post(`${process.env.REACT_APP_BACKEND_URL_GLOBAL}/api/emp/avatar`, body, { headers: head }).then((response) => {
                setPostAvatar(true)
                if (response.status === 200) {
                    setLoadingPostAvatar(false);
                } else {
                    setLoadingPostAvatar(false);
                    setErrorPostAvatar(true);
                }

            }).catch((error) => {
                console.log(error.response.data)
                setLoadingPostAvatar(false);
                setErrorPostAvatar(true);
            })

            // setLoadingFile(false)
        }).catch(function (error) {
            console.log(error);
            setLoadingPostAvatar(false);
        })
    },
    getAvatarRegional: async () => {
        const url = `${baseUrl}/api/common/avatar`;
        const auth = await Auth.currentSession();
        const headers = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() };
        const response = await axios.get(url, { headers });
        return response;
    },
    saveAvatarRegional: async (avatar) => {
        const url = `${baseUrl}/api/common/avatar`;
        const auth = await Auth.currentSession();
        const body = JSON.stringify({ avatar });
        const headers = { 'Content-Type': 'application/json', 'accesstoken': auth.getAccessToken().getJwtToken() };
        await axios.post(url, body, { headers });
    }
}

export default DatosLaboralesService