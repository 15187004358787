import React, { useEffect, useState } from "react";
import styles from "../../resources/css/style-COL.module.css";
"../../resources/css/style-COL.css";

const List = ({
  certificates,
  setIsAdding,
  setIsEditing,
  setIsConfiguring,
  setIdField,
  setIdCertificate,
  getCertificate,
  setCertificate,
  setCertificateName,
}) => {
  const addCertificate = () => {
    setIsAdding(true);
    setIsEditing(false);
    setIdCertificate(null);
    setCertificate({
      id: null,
      name: "",
      description: "",
      year: "",
      enable: true,
      default_text_size: "",
      default_text_color: null,
      default_text_font: null,
      box_background_color: "",
      show_box_border: false,
    });
  };
  const editCertificate = (e, id) => {
    e.preventDefault();
    setIsEditing(true);
    setIsAdding(false);
    setIdCertificate(id);
    getCertificate(id);
  };

  const setUpFields = (e, id, name, certificate) => {
    e.preventDefault();
    setIsConfiguring(true);
    setIdCertificate(id);
    setCertificateName(`${certificate?.year} - ${name}`);
    setCertificate(certificate);

    setIsAdding(false);
    setIsEditing(false);
  };

  return (
    <>
      <div className={styles["box"] + " " + styles["mb-4"]}>
        <div
          className={
            styles["tit-group"] +
            " " +
            styles["d-flex"] +
            " " +
            styles["justify-content-between"]
          }
        >
          <h2 className={styles["h4"] + " " + styles["tit-section"]}>
            Certificados
          </h2>

          <a
            onClick={addCertificate}
            className={
              styles["h5"] + " " + styles["mt-2"] + " " + styles["fl-normal"]
            }
          >
            <span
              className={styles["d-none"] + " " + styles["d-sm-inline-block"]}
            >
              Agregar
            </span>
            <i
              className={
                styles["fa"] +
                " " +
                styles["fa-plus-square-o"] +
                " " +
                styles["fa-lg"] +
                " " +
                styles["ms-2"]
              }
              aria-hidden="true"
              style={{ cursor: "pointer" }}
            ></i>
          </a>
        </div>

        {/* <!-- Escritorio (Tabla) --> */}
        <div className={styles["table-responsive"]}>
          <table
            className={
              styles.table +
              " " +
              styles["table-even"] +
              " " +
              styles["table-section"] +
              " " +
              styles["table-borderless td-none"] +
              " " +
              styles["d-none"] +
              " " +
              styles["d-md-table"]
            }
          >
            {!Array.isArray(certificates) ||
            certificates?.length === 0 ||
            certificates === null ||
            certificates === undefined ? (
              <caption>No se encontraron datos asociados.</caption>
            ) : null}

            <thead>
              <tr>
                <th>Nombre</th>
                <th>Año</th>
                <th>Habilitado</th>
                <th className={styles["text-center"]}>Editar</th>
                <th className={styles["text-center"]}>Conf. Campos</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(certificates)
                ? certificates.map((i) => {
                    return (
                      <tr key={i.id}>
                        <td>{i.name}</td>
                        <td>{i.year}</td>
                        <td>{i.enable ? "Sí" : "No"}</td>
                        <td className={styles["text-center"]}>
                          <a onClick={(e) => editCertificate(e, i.id)}>
                            <i
                              className={
                                styles.fa +
                                " " +
                                styles["fa-pencil"] +
                                " " +
                                styles["fa-fw"] +
                                " " +
                                styles["fa-lg"]
                              }
                              aria-hidden="true"
                              style={{ cursor: "pointer" }}
                            ></i>
                          </a>
                        </td>
                        <td className={styles["text-center"]}>
                          <a onClick={(e) => setUpFields(e, i.id, i.name, i)}>
                            <i
                              className={
                                styles["fa"] +
                                " " +
                                styles["fa-gear"] +
                                " " +
                                styles["fa-fw"] +
                                " " +
                                styles["fa-lg"]
                              }
                              aria-hidden="true"
                              style={{ cursor: "pointer" }}
                            ></i>
                          </a>
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>

        {/* <!-- Móvil (Acordeón) --> */}
        <div className={`accordion accordion-flush accordion-light accordion-table ${styles["d-md-none"]}`}>
          {/* <!-- Acordeón - ítems --> */}
          {Array.isArray(certificates)
            ? certificates.map((i, index) => {
                return (
                  <div key={index} className="accordion-item">
                    <h2 className="accordion-header" id="accordion">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#accordion-item-${index}`}
                          aria-expanded="false"
                          aria-controls={`accordion-item-${index}`}
                        >
                          <strong>Nombre</strong> {" " + i?.name}
                        </button>
                    </h2>
                    <div
                      id={`accordion-item-${index}`}
                      className="accordion-collapse collapse"
                      aria-labelledby="accordion"
                    >
                      <div className={`accordion-body ${styles["p-0"]}`}>
                        {/* <!-- cuerpo item --> */}
                        <div className={`${styles["data-list-box"]} ${styles["data-list-box-even"]}`}>
                          <ul className={`${styles["data-list-section--item"]} ${styles["list-unstyled"]}`}>
                            <li>
                              <span className={styles["data-list-box__title"]}>Año</span>
                              <span className={styles["data-list-box__text"]}>{i?.year}</span>
                            </li>
                            <li>
                              <span className={styles["data-list-box__title"]}>
                                Habilitado
                              </span>
                              <span className={styles["data-list-box__text"]}>
                                {i.enable ? "Sí" : "No"}
                              </span>
                            </li>
                            <li>
                              <span className={styles["data-list-box__title"]}>Editar</span>
                              <span className={styles["data-list-box__text"]}>
                                <a onClick={(e) => editCertificate(e, i.id)}>
                                  <i
                                    className={
                                      styles["fa"] +
                                      " " +
                                      styles["fa-pencil"] +
                                      " " +
                                      styles["fa-fw"] +
                                      " " +
                                      styles["fa-lg"]
                                    }
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span>
                            </li>
                            <li>
                              <span className={styles["data-list-box__title"]}>
                                Configurar Campos
                              </span>
                              <span className={styles["data-list-box__text"]}>
                                <a
                                  onClick={(e) =>
                                    setUpFields(e, i.id, i.name, i)
                                  }
                                >
                                  <i
                                    className={
                                      styles["fa"] +
                                      " " +
                                      styles["fa-gear"] +
                                      " " +
                                      styles["fa-fw"] +
                                      " " +
                                      styles["fa-lg"]
                                    }
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span>
                            </li>
                          </ul>
                        </div>
                        {/* <!-- fin cuerpo item --> */}
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
          {/* <!-- fin items acordeón --> */}
        </div>
      </div>
    </>
  );
};

export default List;
