import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import tiempoService from "../../services/tiempo-service";
import { dotNumber } from "../../services/utiles";
import PaginatorTable from "../elements/paginator-table";
import CarrouselTiempo from "../elements/tiempo-carrousel";
import SaldoTiempo from "../elements/tiempo-saldo";

const TiempoHistorial = () => {
  const [headers, setHeaders] = useState(null);
  const [resetOffset, setResetOffset] = useState(false);
  const [selectFechas, setSelectFechas] = useState([]);
  const [dataHistorial, setDataHistorial] = useState(null);
  const [itemPaginador, setItemPaginador] = useState(null);
  const [errorBeneficios, setErrorBeneficios] = useState(false);
  const [errorBeneficios2, setErrorBeneficios2] = useState(false);
  const [fechaSeleccionada, setFechaSeleccionada] = useState("all");
  //const [textError, setTextError] = useState("");
  const emplid = localStorage.getItem("emplid");
  const rowJefe = [];
  const rowPuntos = [];
  const rowEstado = [];
  const rowBeneficio = [];
  const rowFechaPermiso = [];
  const rowEstadoDescrip = [];
  const rowFechaSolicitud = [];

  const handleClose = () => { setErrorBeneficios(false);setErrorBeneficios2(false);};
  const formato = (texto) => texto.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1");

  useEffect(() => {
    if (emplid !== null && dataHistorial === null) tiempoService.getHistorial(setDataHistorial);
  }, [dataHistorial]);

  useEffect(() => {
    if (dataHistorial?.historial) {
      let historial = Array.isArray(dataHistorial.historial) ? dataHistorial.historial : [dataHistorial.historial];
      let temp =[];
      historial.map((e) => {
        let fecha = e.fecha_solicitud.split("-")[0];
        if (fecha && fecha !== "" && fecha !== " " && !temp.includes(fecha)) temp.push(fecha);
      });
      setSelectFechas(temp);
      if (temp.length > 0) {
        temp.sort((a, b) => b - a);
        setFechaSeleccionada(temp[0]);
      }
    }
  }, [dataHistorial]);


  useEffect(() => {
    if (dataHistorial?.historial) {
      let historial = Array.isArray(dataHistorial.historial) ? dataHistorial.historial : [dataHistorial.historial];
      historial.map((e) => {
        let fecha = e.fecha_solicitud.split("-")[0];
        if (fecha === fechaSeleccionada) {
          rowFechaSolicitud.push(formato(e.fecha_solicitud));
          rowBeneficio.push(e.beneficio);
          rowPuntos.push(dotNumber(e.puntos));
          rowFechaPermiso.push(formato(e.fecha_permiso));
          rowEstado.push(e.estado);
          rowEstadoDescrip.push(e.estado_descr);
          rowJefe.push(e.jefe);
        }
      });
    }
    if (
      rowFechaSolicitud.length > 0 ||
      rowBeneficio.length > 0 ||
      rowPuntos.length > 0 ||
      rowFechaPermiso.length > 0 ||
      rowEstado.length > 0 ||
      rowEstadoDescrip.length > 0 ||
      rowJefe.length > 0
    ) setHeaders(rowFechaSolicitud);
    setItemPaginador([
      {
        title: "Beneficio",
        rows: rowBeneficio,
      },
      {
        title: "Puntos",
        rows: rowPuntos,
      },
      {
        title: "Fecha Permiso",
        rows: rowFechaPermiso,
      },
      {
        title: "Estado",
        rows: rowEstadoDescrip,
      },
      {
        title: "Jefe",
        rows: rowJefe,
      },
    ]);
  }, [dataHistorial, fechaSeleccionada]);


  return (
    <>
      <nav className="breadcrumbs" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/inicio">
              <i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true"></i> Inicio
            </Link>
          </li>
          <li className="breadcrumb-item">Mi Tiempo</li>
          <li className="breadcrumb-item active" aria-current="page">
            Historial de Canjes
          </li>
        </ol>
      </nav>
      <section className="mi-tiempo" >
        <div className="tit-group d-block d-lg-flex justify-content-between align-items-center mb-4">
          <h1 className="mb-3 mb-lg-0" >Mi Tiempo</h1>
          <SaldoTiempo/>
        </div>
        <CarrouselTiempo tituloSelect="Historial de Canjes" />
        <div className="box mb-4">
          <div className="tit-group d-flex justify-content-between">
            <h2 className="h4 tit-section">Mis Canjes</h2>
            <div>
              <select
                id="inputState"
                className="form-select"
                required="required"
                defaultValue={fechaSeleccionada}
                onChange={(e) => {
                  setFechaSeleccionada(e.target.value);
                }}
              >
              {selectFechas && selectFechas.map((e, index) => <option key={"array1" + index} value={e}> {e} </option>)}
              </select>
            </div>
          </div>
          {itemPaginador && headers && (
            <PaginatorTable
              itemsPerPage={5}
              items={itemPaginador}
              header={headers}
              constHeader={"Fecha Solicitud"}
              setResetOffset={setResetOffset}
              resetOffset={resetOffset}
            />
          )}
        </div>

        {errorBeneficios && (
          <>
            <div className="col">
              <div
                className="modal fade show"
                id="foto-credencial"
                tabIndex={"-1"}
                aria-labelledby="enviar-por-correo"
                aria-modal="true"
                role="dialog"
                style={{ display: "block", textAlign: "left" }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                      <button
                        type="submit"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        id="enviar"
                        onClick={() => handleClose()}
                      ></button>
                    </div>
                    <div className="modal-body text-center py-4">
                      <i
                        className="fa fa-download color-orange fa-3x mb-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        aria-hidden="true"
                      ></i>
                      <h2 className="h5">Historial tiempo</h2>
                      <p>
                        Se ha producido un error de comunicación, vuelva a
                        intentarlo más tarde.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {errorBeneficios2 && (
          <>
            <div className="col">
              <div
                className="modal fade show"
                id="foto-credencial"
                tabIndex={"-1"}
                aria-labelledby="enviar-por-correo"
                aria-modal="true"
                role="dialog"
                style={{ display: "block", textAlign: "left" }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                      <button
                        type="submit"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        id="enviar"
                        onClick={() => handleClose()}
                      ></button>
                    </div>
                    <div className="modal-body text-center py-4">
                      <i
                        className="fa fa-download color-orange fa-3x mb-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        aria-hidden="true"
                      ></i>
                      <h2 className="h5">Historial tiempo</h2>
                      {/* <p>{textError}</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
};
export default TiempoHistorial;
