import React, { useState, useEffect } from 'react';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import DetailMiEquipo from './detail-equipo';
import ListaMiEquipo from './lista-equipo';

const MiEquipo = () => {
    const [viewSelected, setViewSelected] = useState('list');
    const [employeeDetailed, setEmployeeDetailed] = useState('');
    const seeDetail = (employee) => {
        setEmployeeDetailed(employee);
    };


    return (
        <>

            <div className='container p-0'>
                <div className='row'>
                    <Routes>
                        <Route path="" element={<ListaMiEquipo seeDetail={seeDetail} />} />
                    </Routes>
                    <Routes>
                        <Route path="detalleEmpleado" element={<DetailMiEquipo employeeDetailed={employeeDetailed} />} />
                    </Routes>
                </div>

            </div>

        </>
    );
};

export default MiEquipo;