import type { SortingState, TableOptions } from "@tanstack/react-table";
import type { ColumnHelper, TableProps } from "./table.types";
import { useEffect, useMemo, useState } from "react";
import clsx from "../../utils/clsx";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  HiOutlineArrowNarrowDown,
  HiOutlineArrowNarrowUp,
  HiSelector,
} from "react-icons/hi";
import { TypeWithKey } from "../../utils";

export const Table = (props: TableProps) => {
  const {
    data = [],
    columnsConfig,
    isLoading,
    handleSort,
    responsiveClass,
    style,
  } = props;
  const columnHelper = createColumnHelper<ColumnHelper>();
  const [sorting, setSorting] = useState<SortingState>([]);

  const columns = columnsConfig
    .filter((config) => config.showColumn !== false)
    .map((config) =>
      columnHelper.accessor(config.accessor, {
        cell: (info) => info.getValue(),
        header: () => config.header,
        enableSorting: config.enabledSort,
      })
    );

  const memoizedData = useMemo(() => data, [data]);
  const memoizedColumns = useMemo(() => columns, [columns]);

  useEffect(() => {
    handleSort && sorting.map((s) => handleSort(s.id, s.desc));
  }, [sorting]);

  const tableOptions: TableOptions<any> = {
    data: memoizedData,
    columns: memoizedColumns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
    state: { sorting },
    onSortingChange: setSorting,
    manualSorting: true,
  };

  const table = useReactTable(tableOptions);

  const isNoDataFound =
    !isLoading && (!memoizedData || memoizedData.length === 0);

  const iconSort = (header: any) => {
    if (header.column.columnDef.enableSorting === false) {
      return null;
    }

    if (!header.column.getIsSorted()) {
      return <HiSelector />;
    }

    const arrowSort: TypeWithKey<JSX.Element> = {
      asc: <HiOutlineArrowNarrowUp className="color-normal fw-bold" />,
      desc: <HiOutlineArrowNarrowDown className="color-normal fw-bold" />,
    };

    return arrowSort[header.column.getIsSorted() as string];
  };

  if (isLoading)
    return (
      <div>
        <div data-testid="loading" className="full-spinner">
          <div className="spinner-border text-primary"></div>
        </div>
      </div>
    );

  return (
    <div className="table-responsive" style={style}>
      <table
        className={`table table-sm table-even table-section table-borderless align-middle ${responsiveClass}`}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => (
                <th
                  key={header.id}
                  className={clsx(
                    "align-middle",
                    columnsConfig[index].className
                  )}
                  {...{
                    onClick: header.column.getToggleSortingHandler(),
                  }}
                >
                  {!header.isPlaceholder &&
                    flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}

                  {handleSort && iconSort(header)}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="text-center">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}

          {isNoDataFound && (
            <tr>
              <td colSpan={12} className="text-center">
                Sin resultados
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
