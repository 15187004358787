import { Auth } from "aws-amplify";
import axios from "axios";

const url = process.env.REACT_APP_BACKEND_URL_GLOBAL;
// const url = "http://localhost:9000";

const getHeader = () => {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(async (auth) => {
          const header = {
            "Content-Type": "application/json",
            accesstoken: auth.getAccessToken().getJwtToken(),
          };
          resolve(header);
        })
        .catch(function (error) {
          return reject(error);
        });
    });
  };

  const EsquemaHibridoService = {
    getRegistros: async () => {
      try {
        const header = await getHeader();
  
        const data = await axios.get(`${url}/api/esquema/verhistorico`, {
          headers: header,
        });
  
        return data;
      } catch (error) {
        error?.response?.data;
      }
    },
    getPendientes: async () => {
      try {
        const header = await getHeader();
  
        const data = await axios.get(`${url}/api/esquema/verhistorico/subordinados`, {
          headers: header,
        });
  
        return data;
      } catch (error) {
        error?.response?.data;
      }
    },
    getInfoEmpleado: async () => {
      try {
        const header = await getHeader();
  
        const data = await axios.get(`${url}/api/esquema/infosolicitud`, {
          headers: header,
        });
        
        return data;
      } catch (error) {
        error?.response?.data;
      }
    },
    postSolicitud: async (body) => {
      try {
        const header = await getHeader();

        const data = await axios({
          method: "post",
          url: `${url}/api/esquema/solicitaresquema`,
          data: body,
          headers: header,
        });
        return data;
      } catch (error) {
        return error?.response?.data;
      }
    },
    desadherirse: async (id) => {
      try {
        const header = await getHeader();

        const data = await axios({
          method: "put",
          url: `${url}/api/esquema/desadherirse/${id}`,
          headers: header,
        });
        return data;
      } catch (error) {
        return error?.response?.data;
      }
    },
    aprobacion: async (body) => {
      try {
        const header = await getHeader();

        const data = await axios({
          method: "post",
          url: `${url}/api/esquema/aprobacion`,
          data: body,
          headers: header,
        });
        return data;
      } catch (error) {
        return error?.response?.data;
      }
    }
}

export default EsquemaHibridoService;