
import React, { useEffect } from 'react'
import { Grid, Button } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';
import { paths } from '../../utilities/paths'
import { HomeContent } from '../../utilities/constantsTexts'
import TopArticles from './components/topArticles/topArticles'
import TopCategories from './components/topCategories/topCategories'
import styles from '../../commons/styles/rootStyle.module.css'
import textstyles from '../../commons/styles/textStyles.module.css'
import { UpdateLocalStorage } from '../../utilities/helpers/updateStorage';

const HCHome = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const localStorageService = new UpdateLocalStorage()

  const goToCategories = () => {
    navigate(paths[4].path, { state: { from: location.pathname } })
  }

  useEffect(() => {
    clearStorage()
    localStorageService.updateSlot(localStorageService.keyConstants[0], paths[1].breadCrumb)
  }, [])

  const clearStorage = () => {
    localStorageService.keyConstants.forEach((key: string) => {
      localStorageService.clearSlot(key)
    })
  }

  return (
    <Grid container columnSpacing={12} mt={1}>
      <React.Fragment>
        <Grid item>
          <h3 className={textstyles.page_subtitle}>{HomeContent.subtitle}</h3>
        </Grid>
        <TopCategories />
        <Grid item xs={12} className={styles.categories_btn_grid}>
          <Button className={styles.terciary_button} onClick={() => goToCategories()}>
            {HomeContent.goToCategoriesBtn}
          </Button>
        </Grid>
        <TopArticles />
      </React.Fragment>
    </Grid>
  );
}

export default HCHome;