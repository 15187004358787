import { Dispatch, SetStateAction } from "react";
import { taskEither as TE } from "fp-ts";
import * as t from "io-ts";
import { RemoteData } from "@devexperts/remote-data-ts";

export const directReport = t.type({
    emplid: t.string,
    fullName: t.string,
    disabled: t.union([t.boolean, t.undefined]),
});

export type DirectReport = t.TypeOf<typeof directReport>;

interface DependencyArray extends DirectReport {
    request: string;
    disabled: boolean;
}

export const nominas = t.type({
    emplid: t.string,
    nombre: t.string,
    cargo: t.string,
    clima: t.string,
    evaluacion: t.string,
    renta_bruta_anual: t.string,
    fecha_ultimo_ajuste: t.string,
});

export type Nominas = t.TypeOf<typeof nominas>;

export const clima = t.type({
    emplid: t.string,
    nombre: t.string,
    gerente: t.string,
    rango: t.string,
});

export type Clima = t.TypeOf<typeof clima>;

export const talentos = t.type({
    emplid: t.string,
    nombre: t.string,
    cargo: t.string,
    detalle: t.string,
    fecha_posicion_actual: t.string,
});

export type Talentos = t.TypeOf<typeof talentos>;

export const dataSets = t.type({
    yValues: t.array(t.number),
    legend: t.string,
})

export type DataSets = t.TypeOf<typeof dataSets>

export const graph = t.type({
    id: t.string,
    order: t.number,
    type: t.string,
    title: t.string,
    columns: t.string,
    xAxis: t.string,
    yAxis: t.string,
    xLabels: t.array(t.string),
    dataSets: t.array(dataSets)
})

export type Graph = t.TypeOf<typeof graph>

export const header = t.type({
    label: t.string,
    value: t.string,
    hidden: t.boolean,
})

export type Header = t.TypeOf<typeof header>

export const row = t.record(t.string, t.union([t.string, t.undefined, t.null, t.boolean]));

export type Row = t.TypeOf<typeof row>

export const table = t.type({
    title: t.string,
    info: t.string,
    orderBy: t.string,
    headers: t.array(header),
    rows: t.array(row),
})

export const axisLabel = t.type({
    label: t.string,
    info: t.string
})

export const labelsNineBox = t.type({
    position: t.number,
    label: t.string
})

export const positionNineBox = t.type({
    x: t.number,
    y: t.number
})

export const infoNineBox = t.type({
    title: t.string,
    icon: t.string,
    text: t.string
})

export const dataNineBox = t.type({
    id: t.string,
    value: t.string
})

export const cellsNineBox = t.type({
    value: t.number,
    label: t.string,
    position: positionNineBox,
    info: infoNineBox,
    data: t.array(dataNineBox),
})

export const ninebox = t.type({
    show: t.boolean,
    title: t.string,
    yAxisLabel: axisLabel,
    xAxisLabel: axisLabel,
    yLabels: t.array(labelsNineBox),
    xLabels: t.array(labelsNineBox),
    cells: t.array(cellsNineBox)
})

export type DataNineBox = t.TypeOf<typeof dataNineBox>

export type CellsNineBox = t.TypeOf<typeof cellsNineBox>

export type Ninebox = t.TypeOf<typeof ninebox>

export type Table = t.TypeOf<typeof table>

export const home = t.type({
    title: t.string,
    headers: t.array(header),
    rows: t.array(row),
});

export type Home = t.TypeOf<typeof home>

export const section = t.type({
    id: t.string,
    total: t.string,
    graph: t.array(graph),
    table: table,
    ninebox: t.union([ninebox, t.undefined])

})

export const dashboardSection = t.type({
    id: t.string,
    title: t.string,
    icon: t.string,
    iconMobile: t.string,
    total: t.string,
    order: t.string,
    showYear: t.boolean
})

export type Section = t.TypeOf<typeof section>

export type DashboardSection = t.TypeOf<typeof dashboardSection>

export const dashboard = t.type({
    valid: t.boolean,
    status: t.string,
    directReports: t.array(directReport),
    sections: t.array(dashboardSection),
});

export const homeDashboard = t.type({
    valid: t.boolean,
    status: t.string,
    title: t.string,
    info: t.string,
    orderBy: t.string,
    headers: t.array(header),
    rows: t.array(row),
});

export const sectionDashboard = t.type({
    valid: t.boolean,
    status: t.string,
    sections: t.array(section)
});

export type Dashboard = t.TypeOf<typeof dashboard>

export const getDashboardCodec = t.type({
    response: dashboard,
});

export const getHomeDashboardCodec = t.type({
    response: homeDashboard,
});

export const getSectionDashboardCodec = t.type({
    response: sectionDashboard,
});

export type GetDashboard = t.TypeOf<typeof getDashboardCodec>

export type GetHomeDashboard = t.TypeOf<typeof getHomeDashboardCodec>

export type GetSectionDashboard = t.TypeOf<typeof getSectionDashboardCodec>

interface BreadCrumbsProps {
    crumbs: {
        name: string;
        url: string;
    }[];
    actual: string;
    setCarouselSelected: Dispatch<SetStateAction<number>>;
    setDependencyArray: Dispatch<SetStateAction<DependencyArray[]>>;
    setRut: Dispatch<SetStateAction<string>>;
    setselectedOptionEquipo: Dispatch<SetStateAction<string>>;
    setTitle: Dispatch<SetStateAction<string>>;
    setShrink: Dispatch<SetStateAction<boolean>>;
}

export const card = t.type({
    id: t.string,
    title: t.string,
    icon: t.string,
    total: t.string,
    showYear: t.boolean
});
export type Card = t.TypeOf<typeof card>

interface CarouselProps {
    carouselSelected: number;
    cards: Card[];
    setCarouselSelected: Dispatch<SetStateAction<number>>;
    setTitle: Dispatch<SetStateAction<string>>;
    setShowYear: Dispatch<SetStateAction<boolean>>;
    setIsGraphLoaded: Dispatch<SetStateAction<boolean>>;
}

const label = t.type({
    label: t.string,
    value: t.string,
});
export type Label = t.TypeOf<typeof label>
interface TableProps {
    header: Header[];
    rows: Row[];
    selectedOptionYear: string;
    setRequest: Dispatch<SetStateAction<string>>;
    emplid: string;
    dependencyArray: DependencyArray[];
    setDependencyArray: Dispatch<SetStateAction<DependencyArray[]>>;
    isLoadData: boolean
    setSelectedOption: Dispatch<SetStateAction<string>>;
    directReport?: DirectReport[];
    setselectedOptionEquipo: Dispatch<SetStateAction<string>>;
    setDirectReport: Dispatch<SetStateAction<DirectReport[]>>;
};
interface OptionProps {
    setSelectedOption: Dispatch<SetStateAction<string>>;
    setselectedOptionEquipo: Dispatch<SetStateAction<string>>;
    setselectedOptionYear: Dispatch<SetStateAction<string>>;
    setselectedOptionRut?: Dispatch<SetStateAction<string>>;
    selectedOption: string;
    selectedOptionEquipo: string;
    selectedOptionYear: string;
    selectedOptionRut?: string;
    directReport?: DirectReport[];
    setRut: Dispatch<SetStateAction<string>>;
    rut: string;
    setRequest: Dispatch<SetStateAction<string>>;
    rows: Row[];
    emplid: string;
    header: Header[];
    title: string;
    showYear: boolean;
    setDirectReport: Dispatch<SetStateAction<DirectReport[]>>;
    rutValid: boolean;
    info: string;
    setRutValid: Dispatch<SetStateAction<boolean>>;
    setDependencyArray: Dispatch<SetStateAction<DependencyArray[]>>;

};
interface GraphProps {
    datos: Graph;
    dataSets?: DataSets;
    arrayColors: Array<string>;
    columns?: string;
    title: string;
    idGraph: string;
    sizeGraph: number;
    isGraphLoaded: boolean,
    setIsGraphLoaded: Dispatch<SetStateAction<boolean>>;
};

interface NineBoxProps {
    dataNineBox: Ninebox;
}

export type HttpRequestError = {
    tag: 'httpRequestError';
    error: unknown;
}

export type HttpContentTypeError = {
    tag: 'httpContentTypeError';
    error: unknown;
}

export type HttpResponseStatusError = {
    tag: 'httpResponseStatusError';
    status: number;
}

export interface HttpClient {
    request(
        input: RequestInfo,
        init?: RequestInit,
    ): TE.TaskEither<HttpRequestError, Response>
}

export interface HttpClientEnv {
    httpClient: HttpClient;
}

export type HttpError =
    | HttpRequestError
    | HttpContentTypeError
    | HttpResponseStatusError
    | t.Errors

export type {
    BreadCrumbsProps,
    CarouselProps,
    TableProps,
    OptionProps,
    DependencyArray,
    GraphProps,
    NineBoxProps
};
