import React, { ChangeEvent, FunctionComponent, useEffect } from "react";
import OfflineModal from "../../commons/plain/modals/offline";
import { scrollToTop } from "../../../../services/utiles";

type Props = {
    nextStep: Function;
    firstname: string;
};

const StepZero: FunctionComponent<Props> = ({ nextStep, firstname }) => {
    useEffect(() => {
        scrollToTop();
    }, []);

    const nextPage = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (navigator.onLine) {
            nextStep();
        } else {
            document.getElementById("offline")?.click();
        }
    };

    return (
        <>
            <div className="welcome">
                <h1 className="tit-section tit-section-center h2">{"Estimado " + firstname.substring(0, 1) + firstname.substring(1, firstname.length).toLocaleLowerCase()}</h1>
                <p className="lh-base">{"Junto con darte la bienvenida a nuestra compañía, te indicamos que para \
          tu proceso de inscripción, debes tener a mano los siguientes \
          documentos."}</p>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-3">
                    <div className="box-welcome">
                        <div className="box-welcome__icon">
                            <i className="fa fa-address-card-o fa-fw" aria-hidden="true" />
                        </div>
                        <span className="text-uppercase color-primary">{"Cédula de identidad por ambos lados"}</span>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                    <div className="box-welcome">
                        <div className="box-welcome__icon">
                            <i className="fa fa-user-o fa-fw" aria-hidden="true" />
                        </div>
                        <span className="text-uppercase color-primary">{"Foto tipo carné para credencial"}</span>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                    <div className="box-welcome">
                        <div className="box-welcome__icon">
                            <i className="fa fa-plus-square-o fa-fw" aria-hidden="true" />
                        </div>
                        <span className="text-uppercase color-primary">{"Certificado de afiliación Isapre"}</span>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                    <div className="box-welcome">
                        <div className="box-welcome__icon">
                            <i className="fa fa-file-text-o fa-fw" aria-hidden="true" />
                        </div>
                        <span className="text-uppercase color-primary">{"Certificación de afiliación AFP"}</span>
                        <br />
                        <span className="text-uppercase color-primary">{"(si estás afiliado)"}</span>
                    </div>
                </div>
            </div>
            <div className="start mb-5 pb-5 text-center d-grid d-md-block">
                <button type="submit" className="btn btn-degradado px-5" data-bs-toggle="modal" onClick={(e: any) => {nextPage(e); }}>
                    Iniciar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                </button>
            </div>
            <OfflineModal />
        </>
    );
};

export default StepZero