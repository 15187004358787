import { useEffect, useState } from 'react'
import TablaVacaciones from './tabla-vacaciones';
import AprobacionVacacionesServices from '../../services/aprobacion vacaciones/aprobacion-vacaciones-service'
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

const AprobacionVacaciones = () => {
  const [disabled, setDisabled] = useState(true)
  const [toSubmit, setToSubmit] = useState([])
  const [info, setInfo] = useState([])
  const [loadingAbsences, setLoadingAbsences] = useState(true)
  const [noData, setNoData] = useState(false)
  const [stateToFetch, setStateToFetch] = useState('F')
  const [filteredData, setFilteredInfo] = useState([])

  // estados de rechazo/aprobacion vacaciones
  const [actionData, setActionData] = useState(null)
  const [loadingAction, setLoadingAction] = useState(false)
  const [noDataAction, setNoDataAction] = useState(false)
  const [loadingApproveAction, setLoadingApproveAction] = useState(false)
  const [loadingRejectAction, setLoadingRejectAction] = useState(false)
  const [serverMessage, setServerMessage] = useState(null)


  const stateOptions = [{ value: "F", description: "Todas" }, { value: "P", description: "Pendientes" }, { value: "A", description: "Aprobadas" }, { value: "R", description: "Rechazadas" }]

  // Funcion para activar botones
  const isDisabled = (ausencias) => {
    setDisabled(ausencias)
  }

  // Funcion que trae objeto para enviar al post
  const action = (ausencias) => {
    const aus = ausencias.map(aus => JSON.parse(aus))
    console.log("actions: ", aus)
    setToSubmit(aus)
  }

  // Filtro por empleados
  const handleSelectEmpleado = (e) => {
    e.preventDefault()
    if (e.target.value === "todos") {
      setFilteredInfo(info)
    } else {
      let infoFiltered = info.filter(aus => aus.employeeId === e.target.value)
      setFilteredInfo(infoFiltered)
    }
  }

  // Filtro por estado
  const handleSelectOption = (e) => {
    setStateToFetch(e.target.value)
    setLoadingAbsences(true)
  }

  // Fetching de datos 
  useEffect(() => {
    AprobacionVacacionesServices.getSubordinatesAbsenceList(stateToFetch, setInfo, setFilteredInfo, setLoadingAbsences, setNoData)
  }, [stateToFetch])

  const getAbsenceList = async () => {
   await  AprobacionVacacionesServices.getSubordinatesAbsenceList(stateToFetch, setInfo, setFilteredInfo, setLoadingAbsences, setNoData)
  }

  const filterOptions =
    Array.from(new Set(info?.map(item => item.employeeId)))?.map(employeeId => {
      return {
        employeeId: employeeId,
        employeeName: info.find(i => i.employeeId === employeeId)?.employeeName
      }
    })

  // Mensaje de error al enviar un post
  const handleStatusError = () => {
    setNoDataAction(false)
    setLoadingRejectAction(false)
    setLoadingApproveAction(false)
    Swal.fire({
      allowOutsideClick: false,
      position: 'center',
      title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
      html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo.</div>`,
      confirmButtonColor: '#0169b5',
    }).then((result) => {
      if (result.isConfirmed){ 
        console.log("error")
      }})
  }
  // Mensaje de éxito al enviar un post
  const handleStatusSuccess = () => {
    setLoadingRejectAction(false)
    setLoadingApproveAction(false)
    setActionData(false)
    setLoadingAbsences(true)
    Swal.fire({
      allowOutsideClick: false,
      position: 'center',
      title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
      html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
      confirmButtonColor: '#0169b5',
    }).then((result) => {
      if (result.isConfirmed){ 
       getAbsenceList();
      }})
  }

  // Rechazar peticion de vacaciones
  const onRejectSubmit = async () => {
    setLoadingRejectAction(true)
    const submit = toSubmit.map(aus => {
      return {
        applicationId: aus.applicationId,
        applicationState: "R",
        applicationComments: " "
      }
    })

    AprobacionVacacionesServices.AbsenceAction(submit, setActionData, setLoadingAction, setNoDataAction, setServerMessage)
  }

  // Aprobar peticion de vacaciones
  const onApproveSubmit = () => {
    setLoadingApproveAction(true)
    const submit = toSubmit.map(aus => {
      return {
        applicationId: aus.applicationId,
        applicationState: "A",
        applicationComments: " "
      }
    })
    AprobacionVacacionesServices.AbsenceAction(submit, setActionData, setLoadingAction, setNoDataAction, setServerMessage)
  }

  return (
    <>
      <nav className="breadcrumbs" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/inicio">
              <i style={{ pointerEvents: "none" }} className="fa fa-home color-orange" aria-hidden="true"></i> Inicio
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Aprobación Vacaciones
          </li>
        </ol>
      </nav>
      <section className="noticias mb-3 p-0">
      <div className="header-page-container">
        <h1 className="mb-3"> Aprobación Vacaciones</h1>
          <div className="buttons">
            <button className="button reject" onClick={onRejectSubmit} disabled={disabled}>
              Rechazar {loadingRejectAction ? <div className="spinner-border spinner-border-sm"></div> : <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>}
            </button>
            <button className="button save" onClick={onApproveSubmit} disabled={disabled}>
              Aprobar {loadingApproveAction ? <div className="spinner-border spinner-border-sm"></div> : <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>}
            </button>
          </div>
        </div>
        <div className="col-12 position-relative mb-0">
          {/* <a className="btn btn-degradado btn-absolute" href="#">Ver más <i className="fa fa-chevron-right" aria-hidden="true"></i></a> */}
        </div>
      </section>

      <section className="container p-0">
        <div className="alert alert-warning d-flex align-items-center">
          <i className="fa fa-exclamation-triangle fa-lg me-3 mt-1" aria-hidden="true"></i>
          <span className="text-start ps-2">
            <b>Info! </b>Las fracciones menores a 7 días corridos, deberán ser
            autorizadas y controladas por Uds. Asegurando la correcta
            contabilización de días hábiles y no hábiles.
          </span>
        </div>
        <div className="card-box mb-3">
        <h3>Filtrar <div className='title-underlined'></div></h3>
          <div className="row">
            <div className="mb-2 mb-sm-4 col-lg-4">
              <label className='col-sm-2 col-form-label fw-bold'>Estado</label>
              <select className="form-select aproove-select" onChange={e => handleSelectOption(e)}>
                {stateOptions && stateOptions.map((state, i) => {
                  return <option key={i} value={state.value} defaultValue={state.value === 'F'}>{state.description}</option>
                })}
              </select>
            </div>
            <div className="mb-2 col-lg-4">
              <label className="col-sm-2 col-form-label fw-bold">Empleado</label>
              <div className="col">
                <select className="form-select aproove-select" onChange={e => handleSelectEmpleado(e)}>
                  <option value="todos">TODOS</option>
                  {filterOptions && filterOptions.map(emp => {
                    return <option key={emp.employeeId} value={emp.employeeId}>{emp.employeeName}</option>
                  })}
                </select>
              </div>
            </div>
          </div>

        </div>

        {
          !loadingAbsences ? <TablaVacaciones isDisabled={isDisabled} action={action} info={filteredData} /> : <div data-testid="loading" className="full-spinner">
            <div className="spinner-border text-primary"></div>
          </div>
        }


        {noDataAction ? handleStatusError() : null}
        {actionData ? handleStatusSuccess() : null}
      </section >
    </>
  )

}

export default AprobacionVacaciones
