import React, { useState } from "react";
import FirstLoginService from "../../services/firstlogin-service";
import LogoMiPortal from "../../resources/images/mi-portal.svg";
import logoCencosud from "../../resources/images/cencosud-logo.svg";

const ResendMail = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const sendRegister = async () => {
        if (email) {
            setLoading(true);
            await FirstLoginService.resendMail(email);
            setLoading(false);
        }
    };

    return (
        <div className="new-login">
            <div className="container d-flex justify-content-center">
                <div className="box col-12 col-md-6 position-relative">
                    <form>
                        <img className="logo-login" src={LogoMiPortal} alt="" width="130" />
                        <div className="text-center">
                            <h1>¡Bienvenid@!</h1>
                            <p className="h6 border-orange border-bottom pb-3 mb-3">Ahora solo falta que confirmes el mail que te enviamos a tu casilla de correo.</p>
                            <p className="h6">¿No te llegó el correo? Reenviarlo nuevamente.</p>
                        </div>
                        <div className="form-email my-4">
                            <label className="form-label text-uppercase fw-bold">Email</label>
                            <input type="text" className="form-control" id="titulo" onChange={(e) => setEmail(e.target.value)}
                                required value={email} />
                        </div>
                        <div className="text-center">
                            <button type="button" className="btn btn-degradado px-5 col-12 col-md-auto new-login-button" onClick={sendRegister}>
                                Reenviar Email {loading ? <div className="spinner-border spinner-border-sm ms-1"></div> : <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <footer>
                <div class="container">
                    <div class="d-flex align-items-center">
                        <img class="me-3" src={logoCencosud} width="60" alt="Ir al inicio"></img>
                        <p class="m-0">Copyright © 2022 Cencosud</p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default ResendMail;