import React, { useEffect, useState } from "react";
import List from "./list";
import Form from "./form";
import EthicalCodeServices from "../../services/codigo-etica/service";
import styles from "../../resources/css/style-COL.module.css";
import { Link } from 'react-router-dom'

const CodigoEticaAdmin = () => {
  const [idPdf, setIdPdf] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [pdfs, setPdfs] = useState([]);
  const [isLoadingPdfs, setLoadingPdfs] = useState(false);
  const [pdf, setPdf] = useState({});
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);

  const getPdfs = async () => {
    try {
      setLoadingPdfs(true);

      const response = await EthicalCodeServices.getPdfs();

      if (Array.isArray(response)) {
        setPdfs(response);
      }

      setLoadingPdfs(false);
    } catch (error) {
      setLoadingPdfs(false);
    }
  };

  useEffect(() => {
    getPdfs();
  }, []);

  return (
    <div className={styles["cencosud-col"]}>
      {/* <!-- inicio breadcrumbs -->   */}
      <nav
        className={
          styles["breadcrumbs"] +
          " " +
          styles["d-none"] +
          " " +
          styles["d-lg-block"]
        }
        aria-label="breadcrumb"
      >
        <ol className={styles.breadcrumb}>
          <li className={styles["breadcrumb-item"]}>
            <Link to="/inicio">
              <i className={`${styles["color-orange"]} ${styles["fa"]} ${styles["fa-home"]}`} aria-hidden="true"></i>
              Inicio
            </Link>
          </li>
          <li className={styles["breadcrumb-item"]}>
            Actualización Código de Ética
          </li>
          {isAdding && (
            <li className={styles["breadcrumb-item"]}>
              Código de Ética (conf.)
            </li>
          )}
          {!isAdding && (
            <li className={styles["breadcrumb-item"] + " " + styles["active"]}>
              Código de Ética (conf.)
            </li>
          )}
          {isAdding && (
            <li
              className={styles["breadcrumb-item"] + " " + styles["active"]}
              aria-current="page"
            >
              Agregar
            </li>
          )}
        </ol>
      </nav>
      {/* <!-- fin breadcrumbs --> */}

      {/* <!-- inicio Código de Ética --> */}
      <section className={styles["certificados-ingresos-retenciones"]}>
        <h1>Código de Ética</h1>

        {isLoadingPdfs ? (
          <div data-testid="loading" className={styles["full-spinner"]}>
            <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
          </div>
        ) : null}

        {!isLoadingPdfs ? (
          <List
            pdfs={pdfs}
            setIsAdding={setIsAdding}
            setIdPdf={setIdPdf}
            setPdf={setPdf}
          />
        ) : null}

        {isAdding ? (
          <Form
            isAdding={isAdding}
            setIsAdding={setIsAdding}
            getPdfs={getPdfs}
            idPdf={idPdf}
            isLoadingPdf={isLoadingPdf}
            pdf={pdf}
          />
        ) : null}
      </section>
    </div>
  );
};

export default CodigoEticaAdmin;
