import React, { CSSProperties, FC } from "react";
import { PaginationButton } from "./PaginationButton";

interface Props {
    page: number;
    setPage: (page: number) => void;
    totalItems: number;
    elementsPerPage: number;
    className?: string;
    style?: CSSProperties;
}

export const Pagination: FC<Props> = ({
    page,
    setPage,
    totalItems,
    elementsPerPage,
    className,
    style,
}) => {
    const totalPages = (totalItems === 0) ? 1 : Math.ceil(totalItems / elementsPerPage);
    const isFirstPage = page === 1;
    const isLastPage = page === totalPages;

    const invisibleButtonStyle: CSSProperties = {
        visibility: "hidden",
        pointerEvents: "none",
    };

    return (
        <nav className={className} style={style} aria-label="Paginación">
            <ul className="pagination justify-content-center align-items-center">
                {/* Botón de primera página */}
                <PaginationButton
                    styleType="first"
                    style={isFirstPage ? invisibleButtonStyle : {}}
                    onClick={() => setPage(1)}
                />

                {/* Botón de página anterior */}
                <PaginationButton
                    styleType="prev"
                    onClick={() => setPage(Math.max(1, page - 1))}
                />

                {/* Página actual */}
                <PaginationButton
                    label={page}
                    styleType="number"
                    className="active"
                />

                {/* Total de páginas */}
                <li className="page-item mx-2">de</li>

                <PaginationButton label={totalPages} styleType="number" />

                {/* Botón de página siguiente */}
                <PaginationButton
                    styleType="next"
                    onClick={() => setPage(Math.min(totalPages, page + 1))}
                />

                {/* Botón de última página */}
                <PaginationButton
                    styleType="last"
                    style={isLastPage ? invisibleButtonStyle : {}}
                    onClick={() => setPage(totalPages)}
                />
            </ul>
        </nav>
    );
};
