import React, { useEffect, useState } from "react";
import styles from "../../resources/css/style-COL.module.css";
import VacacionesJefePanel from "./jefe-panel";
import VacacionesJefePendientesList from "./jefe-pendientes-list";
import VacacionesJefeHistoricalList from "./jefe-historico-list";
import errorMessage from "../SharedComponents/error-message";
import successMessage from "../SharedComponents/success-message";
import VacationServices from "../../services/vacaciones-COL/service";
import Swal from "sweetalert2";
import warningMessage from "../SharedComponents/warning-message";
import { Link } from "react-router-dom";
"../../resources/css/style-COL.css";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-degradado ms-md-2",
    cancelButton: "btn btn-primary",
  },
  buttonsStyling: false,
});

const VacacionesJefeIndex = () => {
  const [isLoadingPending, setIsLoadingPending] = useState(false);
  const [isLoadingPendingError, setIsLoadingPendingError] = useState(false);
  const [pendingRequest, setPendingRequest] = useState([]);
  const [viewPendings, setViewPendings] = useState(true);

  const [isLoadingHistorical, setIsLoadingHistorical] = useState(false);
  const [isLoadingHistoricalError, setIsLoadingHistoricalError] =
    useState(false);
  const [historicalRequests, setHistoricalRequests] = useState([]);
  const [viewHistoricals, setViewHistoricals] = useState(false);

  const [selectedList, setSelectedList] = useState([]); //structure: { id: number, checked: boolean}
  const [commentList, setCommentList] = useState([]); //structure: { id: number, comment: string }

  const [isSaving, setIsSaving] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);

  const getPendingRequestBoss = async () => {
    try {
      setIsLoadingPending(true);

      const response = await VacationServices.getPendingRequestBoss();

      if (
        response !== null &&
        response !== undefined &&
        (response?.statusCode === 200 || response?.status === 200)
      ) {
        setPendingRequest(response?.data.sort((a,b) => b.cn_id_solic_vac - a.cn_id_solic_vac));
      } else {
        errorMessage(
          null,
          response?.message === null || response?.message === undefined
            ? ""
            : response?.message
        );
        setIsLoadingPendingError(true);
      }

      setIsLoadingPending(false);
    } catch (error) {
      setIsLoadingPending(false);
    }
  };

  const viewHistoricalRequest = async () => {
    setViewPendings(false);
    setViewHistoricals(true);

    try {
      setIsLoadingHistorical(true);

      const response = await VacationServices.getHistoricalRequestBoss();

      if (
        response !== null &&
        response !== undefined &&
        (response?.statusCode === 200 || response?.status === 200)
      ) {
        setHistoricalRequests(response?.data.sort((a,b) => b.cn_id_solic_vac - a.cn_id_solic_vac));
      } else {
        errorMessage(
          null,
          response?.message === null || response?.message === undefined
            ? ""
            : response?.message
        );
        setIsLoadingHistoricalError(true);
      }

      setIsLoadingHistorical(false);
    } catch (error) {
      setIsLoadingHistorical(false);
    }
  };

  const viewPendingRequest = async () => {
    setViewPendings(true);
    setViewHistoricals(false);
  };

  const approve = () => {
    if (selectedList?.length === 0) {
      warningMessage(null, "No hay items seleccionados.");
      return;
    }

    swalWithBootstrapButtons
      .fire({
        title: "Confirmación",
        text: "¿Desea continuar la operación?",
        // icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
        showCloseButton: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          for (let index = 0; index < selectedList.length; index++) {
            const element = selectedList[index];

            const id = element?.id;
            const comment = commentList.find((c) => c.id === id)?.comment;

            const dto = {
              cn_id_solic_vac: id,
              cn_c_estado_vac: "PRH",
              comentario:
                comment === null || comment === undefined ? "" : comment,
            };

            setIsSaving(true);
            setIsApproving(true);

            // Call to service
            let response =
              await VacationServices.approveOrRejectVacationRequest(dto);

            // let response = null;

            if (response === null) {
              errorMessage(null, "");
              setIsSaving(false);
              setIsApproving(false);
              setSelectedList([]);
            } else {
              const status = response?.statusCode || response?.status;
              switch (status) {
                case 200:
                  setIsSaving(false);
                  setIsApproving(false);
                  successMessage(null);
                  break;

                case 400:
                  errorMessage(null, response?.message);
                  setIsSaving(false);
                  setIsApproving(false);
                  setSelectedList([]);
                  break;

                case 500:
                  errorMessage(null, response?.message);
                  setIsSaving(false);
                  setIsApproving(false);
                  setSelectedList([]);
                  break;

                default:
                  break;
              }
            }
          }

          // reload
          if (selectedList.length > 0) {
            getPendingRequestBoss();
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
  };

  const reject = () => {
    if (selectedList?.length === 0) {
      warningMessage(null, "No hay items seleccionados.");
      return;
    }

    swalWithBootstrapButtons
      .fire({
        title: "Confirmación",
        text: "¿Desea continuar la operación?",
        // icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
        showCloseButton: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          for (let index = 0; index < selectedList.length; index++) {
            const element = selectedList[index];

            const id = element?.id;
            const comment = commentList.find((c) => c.id === id)?.comment;

            const dto = {
              cn_id_solic_vac: id,
              cn_c_estado_vac: "NAJ",
              comentario:
                comment === null || comment === undefined ? "" : comment,
            };

            setIsSaving(true);
            setIsRejecting(true);

            // Call to service
            let response =
              await VacationServices.approveOrRejectVacationRequest(dto);

            // let response = null;

            if (response === null) {
              errorMessage(null, "");
              setIsSaving(false);
              setIsRejecting(false);
              setSelectedList([]);
            } else {
              const status = response?.statusCode || response?.status;
              switch (status) {
                case 200:
                  setIsSaving(false);
                  successMessage(null);
                  setIsRejecting(false);
                  break;

                case 400:
                  errorMessage(null, response?.message);
                  setIsSaving(false);
                  setIsRejecting(false);
                  setSelectedList([]);
                  break;

                case 500:
                  errorMessage(null, response?.message);
                  setIsSaving(false);
                  setIsRejecting(false);
                  setSelectedList([]);
                  break;

                default:
                  break;
              }
            }
          }

          // reload
          if (selectedList.length > 0) {
            getPendingRequestBoss();
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
  };

  useEffect(() => {
    getPendingRequestBoss();
  }, []);

  return (
    <>
      {/* <!-- inicio breadcrumbs -->   */}
      <nav class="breadcrumbs d-none d-lg-block" aria-label="breadcrumb">
        <ol class="breadcrumb">
        <li className={styles["breadcrumb-item"]}><Link to="/inicio"><i className={`${styles["fa"]} ${styles["fa-home"]}`} aria-hidden="true"></i> Inicio</Link></li>
        <li className={styles["breadcrumb-item"]}>Vacaciones</li>  
          {/* <li class="breadcrumb-item">
            <i
              className={`${styles["color-orange"]} ${styles["fa"]} ${styles["fa-home"]}`}
              aria-hidden="true"
            ></i>
            Vacaciones
          </li> */}
          {viewPendings ? (
            <li class="breadcrumb-item active" aria-current="page">
              Aprobaciones
            </li>
          ) : null}
          {viewHistoricals ? (
            <li class="breadcrumb-item active" aria-current="page">
              Solicitudes Historicas
            </li>
          ) : null}
        </ol>
      </nav>
      {/* <!-- fin breadcrumbs --> */}

      {/* <!-- inicio Vacaciones --> */}
      <section class={styles["vacaciones"] + " " + "cencosud-col"}>
        <h1>Vacaciones</h1>

        {isLoadingPending || isLoadingHistorical ? (
          <div data-testid="loading" className="full-spinner">
            <div className="spinner-border text-primary"></div>
          </div>
        ) : null}

        {viewPendings && !isLoadingPending && !isLoadingPendingError ? (
          <div class={styles["box"] + " " + styles["mb-4"]}>
            <VacacionesJefePendientesList
              pendingRequest={pendingRequest}
              selectedList={selectedList}
              setSelectedList={setSelectedList}
              commentList={commentList}
              setCommentList={setCommentList}
            />
          </div>
        ) : null}

        {viewHistoricals &&
        !isLoadingHistorical &&
        !isLoadingHistoricalError ? (
          <div class={styles["box"] + " " + styles["mb-4"]}>
            <VacacionesJefeHistoricalList
              historicalRequests={historicalRequests}
            />
          </div>
        ) : null}
      </section>

      {viewPendings && !isLoadingPending && !isLoadingPendingError ? (
        <div
          class={
            styles["d-grid"] +
            " " +
            styles["d-md-flex"] +
            " " +
            styles["justify-content-md-between"] +
            " " +
            styles["gap-2"] +
            " " +
            styles["flex-nowrap"] +
            " " +
            styles["mt-4"]
          }
        >
          <div class="d-grid d-md-flex gap-2">
            <button
              type="button"
              class="btn btn-degradado order-md-last ms-md-2"
              onClick={approve}
              disabled={isSaving}
            >
              Aprobar{" "}
              {isSaving && isApproving ? (
                <div className="spinner-border spinner-border-sm"></div>
              ) : (
                <i
                  className="fa fa-chevron-right fa-fw fa-xs"
                  aria-hidden="true"
                ></i>
              )}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={reject}
              disabled={isSaving}
            >
              No Aprobar{" "}
              {isSaving && isRejecting ? (
                <div className="spinner-border spinner-border-sm"></div>
              ) : (
                <i
                  className="fa fa-chevron-right fa-fw fa-xs"
                  aria-hidden="true"
                ></i>
              )}
            </button>
          </div>

          <button
            type="button"
            class="btn btn-secondary order-md-first"
            onClick={viewHistoricalRequest}
            disabled={isSaving}
          >
            Ver Históricos{" "}
          </button>
        </div>
      ) : null}

      {viewHistoricals && !isLoadingHistorical && !isLoadingHistoricalError ? (
        <>
          <div
            class={
              styles["d-grid"] +
              " " +
              styles["d-md-flex"] +
              " " +
              styles["justify-content-md-between"] +
              " " +
              styles["gap-2"] +
              " " +
              styles["flex-nowrap"] +
              " " +
              styles["mt-4"]
            }
          >
            <div class="d-grid d-md-flex gap-2">
              {/* <button
                type="button"
                class="btn btn-degradado order-md-last ms-md-2"
                onClick={approve}
                disabled={isSaving}
              >
                Aprobar{" "}
                {isSaving ? (
                  <div className="spinner-border spinner-border-sm"></div>
                ) : (
                  <i
                    className="fa fa-chevron-right fa-fw fa-xs"
                    aria-hidden="true"
                  ></i>
                )}
              </button> */}
              <button
                type="button"
                class="btn btn-link w-100 order-md-first"
                onClick={viewPendingRequest}
              >
                Volver a Solicitudes Pendientes
              </button>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default VacacionesJefeIndex;
