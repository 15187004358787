// UserModal.tsx
import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from './organigrama-ss.module.css';

interface UserModalProps {
    person: any;
    onHide: () => void;
}

const UserModal: React.FC<UserModalProps> = ({ person, onHide }) => {
    console.log(person)
    return (
        <Modal centered backdrop="static"
            keyboard={false} show onHide={onHide}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                {/* Render the details of the person inside the modal */}
                <div className={`${styles['title-input']} mt-3`}>Nombres</div>
                <div className={styles['input-content']}>
                    {person?.name}
                </div>
                <div className={`${styles['title-input']} mt-3`}>Apellidos</div>
                <div className={styles['input-content']}>
                    {person?.last_name}
                </div>
                <div className={`${styles['title-input']} mt-3`}>Fecha Nacimiento</div>
                <div className={styles['input-content']}>
                    {person?.birthdate ? person?.birthdate : 'SIN FECHA'}
                </div>
                <div className={`${styles['title-input']} mt-3`}>Cargo</div>
                <div className={styles['input-content']}>
                    {person?.position ? person?.position : "SIN CARGO"}
                </div>
                <div className={`${styles['title-input']} mt-3`}>Mail</div>
                <div className={styles['input-content']}>
                    {person?.mail ? person?.mail : "SIN MAIL"}
                </div>
                <div className={`${styles['title-input']} mt-3`}>Legajo / Rut</div>
                <div className={styles['input-content']}>
                    {person?.rut ? person?.rut : "SIN RUT"}
                </div>
                {/* <div className={`${styles['title-input']} mt-3`}>Teléfono ad</div>
                <div className={styles['input-content']}>
                    {person?.phone ? person?.phone : 'SIN TELÉFONO'}
                </div> */}
                <div className={`${styles['title-input']} mt-3`}>País</div>
                <div className={styles['input-content']}>
                    {person?.country ? person?.country : 'SIN PAÍS'}
                </div>
                {/* <div className={`${styles['title-input']} mt-3`}>Tipo de Empleado</div>
                <div className={styles['input-content']}>
                    {person?.type_employee ? person?.type_employee : 'SIN TIPO DE USUARIO'}
                </div> */}
                <div className={`${styles['title-input']} mt-3`}>Ceco</div>
                <div className={styles['input-content']}>
                    {person?.ceco ? person?.ceco : 'SIN CECO'}
                </div>
                {/* <div className={`${styles['title-input']} mt-3`}>Gerencia</div>
                <div className={styles['input-content']}>
                    {person?.management ? person?.management : 'SIN GERENCIA'}
                </div> */}
                <div className={`${styles['title-input']} mt-3`}>Área</div>
                <div className={styles['input-content']}>
                    {person?.area ? person?.area : 'SIN ÁREA'}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default UserModal;
