import Auth from "@aws-amplify/auth";
import { urlsAws } from "../resources/foo/api-endpoints";
import { dateCheck } from "../services/utiles";

const liquidacionService = {
    getEmail_att: (emplid, year, month, email, setDocumento, userDt = undefined) => {
        Auth.currentSession().then((auth) => {
            let requestOptions = {};
            if (email === "" || email === undefined) {
                requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                    body: JSON.stringify({ request: { emplid: emplid, year: String(year), month: month, email: "", userDt: userDt } }),
                };
            } else {
                requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                    body: JSON.stringify({ request: { emplid: emplid, year: String(year), month: month, email: email, userDt: userDt } }),
                };
            }
            fetch(urlsAws.getEmail_att , requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data !== undefined) {
                        if (data.message == undefined) {
                            return data.response;
                        } else {
                            if (data.message == "API rate limit exceeded") {
                                throw new Error("error 429");
                            }
                        }
                    }
                })
                .then((data) => {
                    if (data !== undefined) {
                        if (!email) {
                            if (data.email_att != 0) {
                                caches.open("Liquidaciones_De_Sueldo").then((cache) => {
                                    cache.put("Doc_Liqui_" + year + "_" + month + "_" + emplid, new Response(JSON.stringify([data.email_att, year, month])));
                                    setDocumento([data.email_att, year, month]);
                                });
                            } else {
                                setDocumento(1);
                            }
                        } else {
                            if (data.email_att == 0) {
                                setDocumento(true);
                            } else {
                                setDocumento(1);
                            }
                        }
                    }
                })
                .catch((error) => {
                    if (!email) {
                        caches.match("Doc_Liqui_" + year + "_" + month + "_" + emplid).then((response) => {
                            if (response) {
                                response.json().then((data) => {
                                    setDocumento([data[0], data[1], data[2]]);
                                });
                            } else {
                                if (navigator.onLine) {
                                    setDocumento(1);
                                } else {
                                    setDocumento(2);
                                }
                            }
                        });
                    }
                });
        }).catch((err) => console.log(err));
    },

    getLiquidaciones: (emplid, desde, hasta, setData, setStatus, cantidad, userDt = undefined) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(`${urlsAws.getLiquidaciones}?desde=${desde}&hasta=${hasta}&emplid=${emplid}&userDt=${userDt}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.message == undefined) {
                        return data.response;
                    } else {
                        if (data.message == "API rate limit exceeded") {
                            throw new Error("error 429");
                        }
                    }
                })
                .then((data) => {
                    if (data != undefined) {
                        if (!Array.isArray(data.periodos)) {
                            if (data.periodos.year == "" && data.periodos.month == "") {
                                data.periodos = undefined;
                            } else {
                                data.periodos = [data.periodos];
                            }
                        }
                        if (data.periodos != undefined) {
                            caches.open("Liquidaciones_De_Sueldo").then((cache) => {
                                data.periodos.map((liquidacion) => {
                                    cache.put("Liquidacion_De_Sueld_" + liquidacion.year + "_" + liquidacion.month + "_" + emplid, new Response(JSON.stringify(liquidacion)));
                                });
                                setData(data.periodos);
                            });
                        } else {
                            setData(1);
                            setStatus("No hay registro para mostrar");
                        }
                    } else {
                        setData(1);
                        setStatus("No hay registro para mostrar");
                    }
                })
                .catch(async (error) => {
                    console.log("error", error);
                    let liquidaciones = [];
                    const today = new Date(Date.now());
                    let month = today.getMonth();
                    let year = today.getFullYear();
                    let fecha = hasta.split("-");
                    if (cantidad == 1) {
                        caches.match("Liquidacion_De_Sueld_" + fecha[0] + "_" + fecha[1] + "_" + emplid).then((response) => {
                            if (response) {
                                response.json().then((data) => {
                                    liquidaciones.push(data);
                                });
                            }
                        });
                    } else {
                        dateCheck(cantidad, month, year).map((liquidacion) => {
                            caches.match("Liquidacion_De_Sueld_" + liquidacion[1] + "_" + liquidacion[0] + "_" + emplid).then((response) => {
                                if (response) {
                                    response.json().then((data) => {
                                        liquidaciones.push(data);
                                    });
                                }
                            });
                        });
                    }
                    setTimeout(() => {
                        if (liquidaciones.length > 0) {
                            setData(liquidaciones);
                        } else {
                            setData(1);
                            setStatus("No hay registro para mostrar");
                        }
                    }, 1000);
                });
        }).catch((err) => console.log(err));
    },
};
export default liquidacionService;
