import React, { FC } from 'react';
import { WidgetBanner, WidgetCarouselIcon, WidgetSlider, WidgetTable } from '../../commonComponents/Widget';
import { getNextBirthdays, getShortcuts, getVacationsAndLicenses, getBannerNews } from '../services/homeServices';

const HomeUY: FC = () => {
    return (
        <>
            <WidgetCarouselIcon
                id="accesos-directos"
                width="3/3"
                box={false}
                data={() => getShortcuts()}
            />

            <WidgetBanner
                id="noticias-destacadas"
                width="2/3"
                box={false}
                data={() => getBannerNews()}
            />

            <WidgetTable
                id="vacations-and-licenses"
                title="Mi Equipo"
                data={() => getVacationsAndLicenses()}
                width="2/3"
                box={true}
            />

            <WidgetTable
                id="proximos-cumpleanos"
                title="Próximos Cumpleaños"
                data={() => getNextBirthdays()}
                width="1/3"
                box={true}
            />
        </>
    );
}

export default HomeUY;