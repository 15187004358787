import React, { useEffect, useState } from "react";
import ContactosEmergenciaFamiliaresServices from "../../services/contactos emergencia y familiares/contactos-emergencia-y-familiares-service";
import { useForm } from "react-hook-form";
import styles from "../../resources/css/style-COL.module.css";
import Swal from "sweetalert2";

const ContactoEmergencia = ({
  nameContact,
  parentesco,
  close,
  setNameContact,
  newData,
  reloadInfo,
  setEditG,
  listContact,
}) => {
  const [info, setInfo] = useState({});
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      RELATIONSHIP: "",
      CONTACT_NAME: "",
      TEL_PHONE: "",
      CEL_PHONE: "",
    },
  });
  

  const onSubmit = async (data) => {

    if ( newData == true && (listContact.filter((e) => e.CONTACT_NAME == data.CONTACT_NAME).length > 0)) {
      handleStatusError("No es posible registrar dos contactos con el mismo nombre. Por favor modifique el nombre.");
      return;
    }
    setLoading(true);
    setNameContact("");
    const body = {
      CN_COL_CNT_EMERG: {
        RELATIONSHIP: data.RELATIONSHIP,
        CONTACT_NAME: data.CONTACT_NAME,
        TEL_PHONE: data.TEL_PHONE,
        CEL_PHONE: data.CEL_PHONE ? data.CEL_PHONE : "",
      },
    };
    console.log(newData)
    if (newData == true)
      await ContactosEmergenciaFamiliaresServices.Addcontactemergency(body)
        .then((res) => handleStatusSuccess())
        .catch((e) => handleStatusError("Error en la conexion"))
        .finally(() => setLoading(false));
    else
      await ContactosEmergenciaFamiliaresServices.Editcontactemergency(
        nameContact,
        body
      )
        .then((res) => handleStatusSuccess())
        .catch((e) => handleStatusError("Error en la conexion"))
        .finally(() => setLoading(false));
  };

  useEffect(async () => {
    if (nameContact != "" && nameContact != null) {
      setEdit(true);
      await ContactosEmergenciaFamiliaresServices.Getcontactemergency(
        nameContact,
        setInfo
      );
    } else {
      reset({
        RELATIONSHIP: "",
        CONTACT_NAME: "",
        TEL_PHONE: "",
        CEL_PHONE: "",
      });
      setEdit(false);
      setLoading(false);
    }
  }, [nameContact]);

  useEffect(async () => {
    console.log(info)
    reset(info);
    setLoading(false);
  }, [info]);

  
  const deleteContact = () => {
    Swal.fire({
      allowOutsideClick: false,
      position: "center",
      title:
        '<div style="text-align: center; color: red; font-size: 16px"> Eliminar </div>',
      html: '<div style="text-align: center; font-size: 15px"> ¿Desea continuar la operación? </div>',
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "red",
      cancelButtonColor: "#0169b5",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await ContactosEmergenciaFamiliaresServices.Deletecontactemergency(
          nameContact
        )
          .then((res) => handleStatusSuccess())
          .catch((err) => handleStatusError());
      }
    });
  };

  // Mensaje de error al enviar un post
  const handleStatusError = (message) => {
    Swal.fire({
      allowOutsideClick: false,
      position: "center",
      title:
        '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
      html: `<div style="text-align: center; font-size: 15px"> ${message}</div>`,
      confirmButtonColor: "#11427C",
    }).then(() => {
      
    });
  };

  // Mensaje de éxito al enviar un post
  const handleStatusSuccess = () => {
    Swal.fire({
      allowOutsideClick: false,
      position: "center",
      title:
        '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
      html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
      confirmButtonColor: "#0169b5",
    }).then(() => {
      reloadInfo();
    });
  };
  return (
    <>
      {loading ? (
        <div data-testid="loading" className={styles["full-spinner"]}>
          <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
        </div>
      ) : (
        <div className={styles["cencosud-col"]}>
          {/* <!-- Inicio Agregar Contacto --> */}
          <div className={`${styles["box"]} ${styles["mb-4"]}`}>
            <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>
              Agregar Contacto
            </h2>
            <form>
              {/* <!-- Fila 1 --> */}
              <div className={styles["row"]}>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    Tipo de Contacto{" "}
                    <span className={styles["color-red"]}>*</span>
                  </label>
                  <div className={styles["input-group"]}>
                    <select
                      className={styles["form-select"]}
                      {...register("RELATIONSHIP", {
                        required: "* Campo obligatorio",
                        maxLength: 3,
                      })}
                      disabled={newData==true?false:true}
                    >
                      <option disabled value="" selected>
                        Seleccione
                      </option>
                      {parentesco.map((p) => {
                        return (
                          <option key={p.FIELDVALUE} value={p.FIELDVALUE}>
                            {p.XLATLONGNAME}
                          </option>
                        );
                      })}
                    </select>
                    <small className={styles["form-error-message"]}>
                      {errors.RELATIONSHIP?.message}
                    </small>
                  </div>
                </div>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    Nombre <span className={styles["color-red"]}>*</span>
                  </label>
                  <input
                    type="text"
                    className={styles["form-control"]}
                    {...register("CONTACT_NAME", {
                      required: "* Campo obligatorio",
                    })}
                    disabled={newData==true?false:true}
                  />
                  <small className={styles["form-error-message"]}>
                    {errors.CONTACT_NAME?.message}
                  </small>
                </div>
              </div>
              {/* <!-- Fila 2 --> */}
              <div className={styles["row"]}>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    Teléfono <span className={styles["color-red"]}>*</span>
                  </label>
                  <input
                    type="text"
                    className={styles["form-control"]}
                    {...register("TEL_PHONE", {
                      required: "* Campo obligatorio",
                      maxLength: 24,
                    })}
                    disabled={edit}
                  />
                  <small className={styles["form-error-message"]}>
                    {errors.TEL_PHONE?.message}
                  </small>
                </div>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>Celular</label>
                  <input
                    type="text"
                    className={styles["form-control"]}
                    {...register("CEL_PHONE", { maxLength: 24 })}
                    disabled={edit}
                  />
                  <small className={styles["form-error-message"]}>
                    {errors.CEL_PHONE?.message}
                  </small>
                </div>
              </div>
              {/* <!-- fin filas --> */}
            </form>

            <div
              className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mt-4"]}`}
            >
              {newData == false && (
                <>
                  <div
                    className={`${styles["col-6"]} ${styles["col-md-auto"]}`}
                  >
                    <button
                      type="submit"
                      className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`}
                      onClick={deleteContact}
                    >
                      Eliminar{" "}
                    </button>
                  </div>
                  <div
                    className={`${styles["col-6"]} ${styles["col-md-auto"]}`}
                  >
                    {
                      edit == false?
                      (<></>):
                      (
                        <button
                      type="submit"
                      className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`}
                      onClick={() => {
                        setEdit(false);
                        setEditG(false);
                        // changeDisplayContact(true, idContact, infoContactEmergency)
                      }}
                    >
                      Modificar{" "}
                      <i
                        className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                        aria-hidden="true"
                      ></i>
                    </button>
                      )
                    }
                    
                  </div>
                </>
              )}

              <div className={`${styles["col-6"]} ${styles["col-md-auto"]}`}>
                <button
                  type="button"
                  onClick={() => {
                    setNameContact("");
                    close(edit ? false : true);
                  }}
                  className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`}
                >
                  Cancelar{" "}
                  <i
                    className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
              <div
                className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mt-4"]}`}
              >
                <div className={`${styles["col-12"]} ${styles["col-md-auto"]}`}>
                  {/* <button type="button" className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["w-100"]}`} onClick={handleSubmit(onSubmit)} disabled={loadingAddContactEmergency}>Guardar {loadingAddContactEmergency ? <div className="spinner-border spinner-border-sm"></div> : <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i>}</button> */}
                  <button
                    type="button"
                    className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["w-100"]}`}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Guardar
                  </button>
                </div>
              </div>
            </div>

            {/* {noDataAddContactEmergency ? handleStatusError() : null} */}
          </div>
          {/* <!-- Fin Agregar --> */}
        </div>
      )}
    </>
  );
};

export default ContactoEmergencia;
