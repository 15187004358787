import React, { useEffect, useState } from 'react';
import styles from "../../resources/css/style-COL.module.css";
import { useLocation, useNavigate } from 'react-router';
import RenunciaVoluntariaService from '../../services/renuncia-voluntaria-col/service';
import errorMessage from '../SharedComponents/error-message';
import Swal from "sweetalert2";

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-degradado ms-md-2",
        cancelButton: "btn btn-primary",
    },
    buttonsStyling: false,
});

const VerArchivo = () => {
    const [pdf, setPdf] = useState(null)
    const [loading, setLoading] = useState(true)
    const [loadingRatificar, setLoadingRatificar] = useState(false)

    const { state } = useLocation();

    const getPdf = async () => {
        const response = await RenunciaVoluntariaService.getPdf();
        if (response?.status == 200) {
            setPdf(response.data);
            setLoading(false)
        } else {
            errorMessage(null, "")
            setLoading(false)
            }
        }
        useEffect(() => {
            getPdf()
        }, [])

        const siguiente = async () => {
            setLoadingRatificar(true)
            swalWithBootstrapButtons
            .fire({
                title: "Confirmación",
                text: `Al presionar RATIFICAR estás solicitando gestionar tu renuncia voluntaria, por lo que a partir de esta acción no hay opción de deshacer dicha solicitud. Es de tu exclusiva responsabilidad haber ingresado correctamente un correo personal para recibir la documentación que genera este proceso, ya que esta acción generará el bloqueo de tu correo corporativo CENCOSUD. De acuerdo con lo informado, el correo al que se enviarán los antecedentes es: ${state}`,
                showCancelButton: true,
                confirmButtonText: "Ratificar",
                cancelButtonText: "Cancelar",
                reverseButtons: true,
                showCloseButton: true,
            })
            .then(async (result) => {
                if (result.isConfirmed) {  
            const data = await RenunciaVoluntariaService.ratificarPdf()
            if(data?.status == 200){
                navigate("../descargar-archivo")
            }else{
                errorMessage(null, "Se ha producido un error al ratificar.")
                setLoadingRatificar(false)
            }}else if (result.dismiss === Swal.DismissReason.cancel) {
                setLoadingRatificar(false)
            }})
        }

        const navigate = useNavigate()

        return (<>
            {!loading ? <div className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}>
                <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Renuncia Voluntaria Digital</h2>
                <ul className={`${styles["list-unstyled"]} ${styles["d-flex"]} ${styles["justify-content-center"]} ${styles["flex-column"]} ${styles["gap-3"]} ${styles["flex-md-row"]} ${styles["gap-md-5"]} ${styles["fw-bold"]} ${styles["border-md-bottom"]} ${styles["pb-4"]} ${styles["mb-0"]} ${styles["mb-md-5"]}`}>
                    <li className={`${styles["d-flex"]} ${styles["align-items-center"]}`}><span className={`${styles["ic-circle"]} ${styles["bg-orange"]}`}>1</span><span className={styles["color-primary"]}>Completa el Formulario</span></li>
                    <li className={`${styles["d-flex"]} ${styles["align-items-center"]}`}><span className={`${styles["ic-circle"]} ${styles["bg-orange"]}`}>2</span><span className={styles["color-primary"]}>Ratifica la Carta</span></li>
                    <li className={`${styles["d-flex"]} ${styles["align-items-center"]}`}><span className={`${styles["ic-circle"]} ${styles["ic-circle-primary"]}`}>3</span><span className={styles["color-primary"]}>Descarga la Carta</span></li>
                </ul>
                <div className={`${styles["embed-responsive"]} ${styles["embed-responsive-16by9"]}`}>
                    <iframe width="100%" height="500" className={styles["embed-responsive-item"]} src={`data:application/pdf;base64,${pdf}`} allowFullScreen><p>El navegador no soporta PDF.</p></iframe>
                </div>
                <div className={`${styles["d-grid"]} ${styles["d-md-flex"]} ${styles["justify-content-md-end"]} ${styles["gap-3"]} ${styles["flex-nowrap"]} ${styles["my-5"]}`}>
                    <div className={`${styles["order-md-last"]} ${styles["d-grid"]} ${styles["d-md-flex"]} ${styles["gap-2"]}`}>
                        <button type="button" className={`${styles["btn"]} ${styles["btn-degradado"]}`} onClick={siguiente} disabled={loadingRatificar}>Siguiente {loadingRatificar ? <div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div> : <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i>}</button>
                    </div>
                    <button type="button" className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["order-md-first"]}`} onClick={() => navigate(-1)}>Editar <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i></button>
                </div>
            </div> : <div data-testid="loading" className={styles["full-spinner"]}>
                <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
            </div>}</>
        )
    }

    export default VerArchivo;