import { useState, useEffect } from 'react';
import AbsenceActionService from '../../services/absence-action/absence-action-service';
import { useLocation } from 'react-router-dom';
import Header from '../elements/header';
import Footer from "../elements/footer";

const LicenseAction = () => {
    // eslint-disable-next-line react/prop-types
    const { search } = useLocation();
    const index = search.indexOf("&")
    const init = search.indexOf('?') + 7;
    const token = search.substring(init, index);
    // eslint-disable-next-line react/prop-types
    const state = search.split("state=")[1];
    const [loading, setLoading] = useState(true);
    const data = { token: token, status: state };
    const [responseMsg, setResponseMsg] = useState({});
    const [error, setError] = useState(false);
    const [sendRequest, setSendRequest] = useState(false);

    useEffect(() => {
        setSendRequest(true);
    }, []);

    useEffect(() => {
        if (sendRequest) {
            postRequest();
        }
    }, [sendRequest]);


    const postRequest = () => {
        AbsenceActionService.licenseActionMail(data, setLoading, setResponseMsg, setError);
    }

    return (
        <div className="d-flex flex-column space-between" style={{ height: 'calc(100vh - 32px)' }}>
            <Header />
            <main id="main" className="wrapper no-menu">
                <div id="content" className="container">
                    <div className="box mb-4">
                        <h2 className="h4 tit-section" >Aprobación Licencias</h2>
                        <div className="content-mail">
                            {loading ? <div className="h5 color-primary">Procesando la solicitud.. <div className="spinner-border spinner-border-sm color-primary"></div></div> :
                                <div className="response-content h5 color-primary ">{responseMsg?.descripcion ? responseMsg?.descripcion : error ? 'Ha ocurrido un error al procesar la solicitud.' : ''} </div>}
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default LicenseAction
