import React, { FC, useEffect, useState, Dispatch, SetStateAction } from "react";
import BreadCrumbs from "../commons/BreadCrumbs";
import Section from "../commons/Section";
import Form from "../commons/Form";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import * as RD from "@devexperts/remote-data-ts";
import { RemoteData } from "@devexperts/remote-data-ts";
import { HttpError, FetchGetProps, FetchPostProps, SectionsProps } from "../utilities/interfaces";
import { httpClientEnv } from "../utilities/apiServices/httpClient";
import { get, post } from "../utilities/apiServices/apiRequest";
import Searching from "../commons/modals/Searching";
import Success from "../commons/modals/Success";
import SearchBar from "../commons/searchBar";
import { NameCollaborator } from "../../warningLetter/utilities/interfaces";
import { urlsAws } from "../../../../resources/foo/api-endpoints";
import { PhotoProps } from "../../warningLetter/pages/warning";
import miEquipoService from "../../../../services/mi-equipo-service";
import { formatDate, scrollToTop } from "../../../../services/utiles";


interface Props {
    setFichaSIC: Dispatch<SetStateAction<boolean>>;
    emplidColab: string;
    collaborators: NameCollaborator[];
    setCollaboratorEmplid: React.Dispatch<React.SetStateAction<string>>;
    fromEquipo: boolean;
    setFromEquipo: React.Dispatch<React.SetStateAction<boolean>>;
}

const Ficha: FC<Props> = ({setFichaSIC, emplidColab, collaborators, setCollaboratorEmplid, fromEquipo, setFromEquipo,}) => {
    const [getFicha, setGetFicha] = useState<RemoteData<HttpError, FetchGetProps>>(RD.initial);
    const [postFicha, setPostFicha] = useState<RemoteData<HttpError, FetchPostProps>>(RD.initial);
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const [date,setDate]= useState<string>('');
    const [errorMsjDate,setMsjErrorDate] =  useState('');
    const [photos, setPhotos] = useState<PhotoProps[]>([]);
    const [optionSelect, setOptionSelect] = useState('01'); /* Valor temporal por defecto */

    useEffect(()=>{
        if(emplidColab!==''){
            onSubmitGet(emplidColab.toLocaleUpperCase());
        }
    },[emplidColab])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (getFicha._tag === "RemoteSuccess" && emplidColab!=='' ) {
                document.getElementById("closeSendingInfo")?.click();
                dataCachePhotos(emplidColab.toLocaleUpperCase());
            }
        }, 700);
        if (getFicha._tag === "RemotePending") {
            document.getElementById("sendingInfo")?.click();
        }
        if (getFicha._tag === "RemoteFailure") {
            document.getElementById("closeSendingInfo")?.click();
            document.getElementById("errorInfo")?.click();
            setPhotos([])
        }
        return () => clearTimeout(timer);
    }, [getFicha]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (postFicha._tag === "RemoteSuccess") {
                document.getElementById("closeSendingInfo")?.click();
                document.getElementById("successInfo")?.click();
                if(postFicha.value.response.valid === '1'){
                    setFichaSIC(false);
                    setCollaboratorEmplid('');
                    setGetFicha(RD.initial);
                }else{
                    const inputElement = document.getElementById('dateFormSic') as HTMLInputElement;
                    if(inputElement){
                        inputElement.value = '';
                    }
                }
                setShowDetail(false);
                setDate('');
            }
        }, 700);

        if (postFicha._tag === "RemotePending") {
            document.getElementById("sendingInfo")?.click();
        }
        if (postFicha._tag === "RemoteFailure") {
            document.getElementById("closeSendingInfo")?.click();
            document.getElementById("errorInfo")?.click();
        }
        return () => clearTimeout(timer);
    }, [postFicha]);

    const onSubmitGet = (emplidColab:string) => {
        setGetFicha(RD.pending);
        const transfer = async () => RTE.run(await get(emplidColab), httpClientEnv)
            .then(E.fold(e => { return setGetFicha(RD.failure(e)) }, a => { return setGetFicha(RD.success(a))}));
        transfer();
    }

    const onSubmitPost = (emplidColab:string, date:string, optionSelect:string) => {
        setPostFicha(RD.pending);
        const body = {
            emplid: emplidColab,
            vacancyType: optionSelect,
            estimatedStartDate: date
        }
        const transfer = async () => RTE.run(await post(body), httpClientEnv)
            .then(E.fold(e => { return setPostFicha(RD.failure(e)) }, a => { return setPostFicha(RD.success(a)) }));
        transfer();
    }

    const dataCachePhotos = async (request:string) => {
        const getFoto = async () => {
            await caches.match(urlsAws.getPhotosEquipo + request).then((response) => {
                if (response) {
                    response.json().then((data) => {
                        if(data){
                            setPhotos([data]);
                        }
                    });
                }else{
                    miEquipoService.getPhotos(setPhotos, [request]);
                }
            })
        };
        getFoto();
    };

    return <>
        <BreadCrumbs setFichaSIC={setFichaSIC} setCollaboratorEmplid={setCollaboratorEmplid}/>
        <h1>Mi Equipo</h1>
           {!fromEquipo && !showDetail && <div className="box mb-4">
                <h2 className="h4 tit-section">Buscar Colaborador</h2>
                <div className="row">
                    <div className="col-md-8">
                    <SearchBar 
                        data={collaborators}
                        placeholder={"Por nombre de reporte directo o rut colaborador..."} 
                        setCollaboratorEmplid={setCollaboratorEmplid}/>
                    </div>
                </div>
            </div>}

         
            {!showDetail && <Form date={date} optionSelect={optionSelect} setOptionSelect={setOptionSelect} emplidColab={emplidColab} setDate={setDate} errorMsjDate={errorMsjDate} setMsjErrorDate={setMsjErrorDate} collaborator={collaborators} photos={photos} />}
           
            {getFicha._tag === "RemoteSuccess" && !showDetail &&
             getFicha.value.response.firstPage?.map((section: SectionsProps) =>{
                 return(
                    section.sections.map((sect,i)=>{
                        return(
                            <Section {...sect}/>
                        )
                    })
                 )
             })}

            {getFicha._tag === "RemoteSuccess" && showDetail &&
                <>
                    <div className="tit-group d-flex justify-content-end mb-2">
                        <h6><strong>Fecha de solicitud:</strong> {formatDate(date)}</h6>
                    </div>
                    <div className="alert alert-primary d-flex align-items-start" role="alert">
                        <i className="fa fa-info-circle fa-fw fa-lg mt-1 me-2 ms-2" aria-hidden="true"></i>
                        <span>
                            <p className="h5 color-normal mb-0">
                                "El cálculo del CR y la renta asociada es preliminar, 
                                en función a las reglas definidas para la banda salarial en la política
                                de compensaciones y puede modificarse al momento de la revisión del área de Compensaciones".
                            </p>
                        </span>
                    </div>
                    {
                        getFicha.value.response.secondPage?.map((section: SectionsProps) =>{
                            return(
                            section.sections.map((sect,i)=>{
                                return(
                                    <Section {...sect}/>
                                )
                            })
                            )
                        })
                    }
                </>
            }
            <div className="d-grid d-md-flex justify-content-md-between gap-2 flex-nowrap mt-5">
                <div className="order-md-last d-grid d-md-flex gap-2">
                        {getFicha._tag === "RemoteSuccess" && !showDetail &&
                            <button
                                type="button"
                                disabled={!(date !== '' && errorMsjDate === '' && optionSelect !== '')}
                                className="btn btn-degradado order-md-last ms-md-2"
                                onClick={() => { 
                                    setShowDetail(true);
                                    scrollToTop();
                                }}> Siguiente <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                            </button>
                        }
                        { showDetail && getFicha._tag === "RemoteSuccess" &&
                            <button
                                type="button"
                                disabled={!(date !== '' && errorMsjDate === '')}
                                className="btn btn-degradado order-md-last ms-md-2"
                                onClick={()=>{emplidColab !=='' && errorMsjDate === '' && optionSelect!=='' && onSubmitPost(emplidColab,date,optionSelect)}}>
                                    Enviar Solicitud 
                                <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                        </button> }
                </div>
                { getFicha._tag === "RemoteSuccess"  &&
                    <button
                        type="button"
                        className="btn btn-link order-md-first"
                        onClick={() => {
                            if (showDetail) {
                                setShowDetail(false)
                            } else {
                                setMsjErrorDate('')
                                setFichaSIC(false)
                                setFromEquipo(false)
                                setCollaboratorEmplid('');
                            }
                            if (!showDetail && fromEquipo) {
                                setCollaboratorEmplid('');
                            }
                            scrollToTop();
                         }}>
                        Volver
                    </button>
                }
            </div>
        
        <Searching />
        { getFicha._tag === "RemoteSuccess" && postFicha._tag === "RemoteInitial" && <Success valid={getFicha.value.response.valid} status={getFicha.value.response.status} /> }
        { postFicha._tag === "RemoteSuccess" && <Success valid={postFicha.value.response.valid} status={postFicha.value.response.status} /> }
        { (getFicha._tag === "RemoteFailure" || postFicha._tag === "RemoteFailure") && 
            <>
                <button hidden id="errorInfo" data-bs-target="#errorInfoModal" data-bs-toggle="modal" />
                <div className="modal fade" id="errorInfoModal" tabIndex={-1} aria-labelledby="success-info" aria-hidden="true" >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header border-0 pb-0">
                                <button id="closeErrorInfo" type="button" className="btn-close" data-bs-dismiss="modal" onClick={()=>{setCollaboratorEmplid('')}} aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-center py-4">
                                <div className="modal-body text-center py-4">
                                    <i className="fa fa-exclamation-triangle color-orange fa-3x" aria-hidden="true"></i>
                                    <h2 className="h5">
                                        { getFicha._tag === "RemoteFailure" ? "Colaborador no encontrado o fuera de las dependencias directas." : "Ha ocurrido un problema, por favor intente más tarde." }
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </> 
        }
    </>
};

export default Ficha;