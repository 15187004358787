import React from 'react'
import { Link } from 'react-router-dom';
import ManagementComponent from '../reemplazos-manager/management-component';

const ReplacementsManagement = () => {
    return (
        <React.Fragment>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home color-orange" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Gestion de Reemplazos
                    </li>
                </ol>
            </nav>
            <section className="noticias mb-3">
                <h1>Gestión de Reemplazos</h1>
                <div className="col-12 position-relative mb-0">
                    {/* <a className="btn btn-degradado btn-absolute" href="#">Ver más <i className="fa fa-chevron-right" aria-hidden="true"></i></a> */}
                </div>
            </section>
            <section>
                <ManagementComponent />
            </section>
        </React.Fragment>
    )
}

export default ReplacementsManagement