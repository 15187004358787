import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';

export interface InterestSites {
    id: number;
    title: string;
    url: string;
    class: string;
    country: string;
    vpn: number;
    password: number;
}

interface InterestSitesContextType {
    interestSites: InterestSites[];
    setInterestSites: React.Dispatch<React.SetStateAction<InterestSites[]>>;
}

const InterestSitesContext = createContext<InterestSitesContextType | undefined>(undefined);

export const InterestSitesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [interestSites, setInterestSites] = useState<InterestSites[]>([]);


  return (
    <InterestSitesContext.Provider value={{ interestSites, setInterestSites }}>
      {children}
    </InterestSitesContext.Provider>
  );
};

export const useInterestSites = (): InterestSitesContextType => {
  const context = useContext(InterestSitesContext);
  if (context === undefined) {
    throw new Error('useCourses debe ser usado dentro de un InterestSitesProvider');
  }
  return context;
};
