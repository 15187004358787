import React from "react";

const AccountModal = () => {
    return (
        <div className="modal fade" id="termsModal" tabIndex={-1} aria-labelledby="termsModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <h2 className="h5 text-uppercase mb-4">{"Términos y condiciones"}</h2>
                        <p>
                            {"Autorizo a la Empresa, para que deposite en la cuenta que individualizo a continuación, todas las remuneraciones y asignaciones que me corresponda percibir en mi calidad de colaborador de ella. Además, confirmo que la cuenta que estoy ingresando corresponde a mi propiedad o rut y no un tercero."}
                        </p>
                    </div>
                    <div className="modal-footer border-0 pt-0 pb-4 justify-content-center">
                        <button type="button" className="btn btn-degradado" data-bs-dismiss="modal">{"Aceptar "}<i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AccountModal;