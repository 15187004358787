import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import successMessage from "../SharedComponents/success-message";
import errorMessage from "../SharedComponents/error-message";
import Swal from "sweetalert2";
import styles from "../../resources/css/style-COL.module.css";
import EthicalCodeServices from "../../services/codigo-etica/service";
import { Auth } from "aws-amplify";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-degradado ms-md-2",
    cancelButton: "btn btn-primary",
  },
  buttonsStyling: false,
});

const Form = ({ isAdding, setIsAdding, getPdfs, idPdf, isLoadingPdf, pdf }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [file, setFile] = useState(null);
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [maxSizeExceeded, setMaxSizeExceeded] = useState(false);
  const [pais, setPais] = useState(null);

  const getCountry = async () => {
    const country = await Auth.currentUserInfo()
      .then((user) => {
        if (
          user.attributes["custom:cd_pais"] &&
          user.attributes["custom:cd_pais"] !== undefined &&
          user.attributes["custom:cd_pais"] !== null &&
          user.attributes["custom:cd_pais"] !== ""
        ) {
          return user.attributes["custom:cd_pais"];
        }
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
    setPais(country);
  };

  useEffect(() => {
    getCountry();
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const cancel = () => {
    setIsAdding(false);
  };

  const save = async (data) => {
    setIsFirstTime(false);

    if (file === null) {
      return;
    }

    if (file.size / 1024 > 10240) {
      setMaxSizeExceeded(true);
      return;
    } else {
      setMaxSizeExceeded(false);
    }

    swalWithBootstrapButtons
      .fire({
        title: "Confirmación",
        text: "¿Desea continuar la operación?",
        // icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
        showCloseButton: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          setIsSaving(true);

          const dto = {
            description: data.name,
            id: idPdf,
          };

          // Call to service
          let response = null;
          response = await EthicalCodeServices.uploadPdf(dto, file, pais ?? "");

          if (response === null) {
            errorMessage(null, "");
          } else {
            const status = response?.statusCode || response?.status;
            switch (status) {
              case 200:
                setIsAdding(false);
                setIsSaving(false);
                successMessage(getPdfs);
                break;

              case 400:
                errorMessage(null, response?.message);
                setIsSaving(false);
                break;

              case 500:
                errorMessage(null, response?.message);
                setIsSaving(false);
                break;

              default:
                break;
            }
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      if (e.target.files.length < 1) {
        return;
      }
      const file = e.target.files[0];
      switch (file.type) {
        case "application/pdf":
          break;
        default:
          e.target.value = null;
          break;
      }

      setFile(e.target.files[0]);
      setIsFileLoaded(true);
    } else {
      setIsFileLoaded(false);
    }
  };

  useEffect(() => {
    reset();
  }, [pdf]);

  return (
    <>
      {isLoadingPdf ? (
        <div data-testid="loading" className={styles["full-spinner"]}>
          <div
            className={`${styles["spinner-border"]} ${styles["text-primary"]}`}
          ></div>
        </div>
      ) : null}

      {!isLoadingPdf ? (
        <div className={styles["box"] + " " + styles["mb-4"]}>
          <h2 className={styles["h4"] + " " + styles["tit-section"]}>
            {isAdding ? "Agregar Pdf" : null}
          </h2>

          <form>
            {/* <!-- Fila 1 --> */}
            <div className={styles["row"]}>
              <div
                className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
              >
                <label className={styles["form-label"]}>
                  Nombre <span className={styles["color-red"]}>*</span>
                </label>
                <input
                  type="text"
                  className={styles["form-control"]}
                  {...register("name", {
                    required: "* Campo obligatorio",
                    maxLength: 255,
                  })}
                  defaultValue={pdf?.name}
                />
                <small className={styles["form-error-message"]}>
                  {errors?.name?.message}
                </small>
              </div>
              <div
                className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
              >
                <label className={styles["form-label"]}>
                  PDF (Archivos menores a 8Mb){" "}
                  <span className={styles["color-red"]}>*</span>
                </label>
                <div className={styles["input-group"]}>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    // hidden
                    id="file"
                    formcontrolname="file"
                    name="file"
                    accept=".pdf"
                    className={styles["form-control"]}
                    aria-label="Adjuntar pdf"
                  />
                </div>
                <small className={styles["form-error-message"]}>
                  {!isFileLoaded && !isFirstTime
                    ? "* Campo obligatorio.  "
                    : null}
                  {maxSizeExceeded
                    ? "* El archivo no puede superar los 10MB"
                    : null}
                </small>
              </div>
            </div>
          </form>

          <div
            className={
              styles["d-grid"] +
              " " +
              styles["d-md-flex"] +
              " " +
              styles["justify-content-md-end"] +
              " " +
              styles["gap-2"] +
              " " +
              styles["flex-nowrap"] +
              " " +
              styles["mt-4"]
            }
          >
            <div
              className={`${styles["d-grid"]} ${styles["d-md-flex"]} ${styles["gap-2"]}`}
            >
              <button
                type="button"
                className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["order-md-last"]} ${styles["ms-md-2"]}`}
                onClick={handleSubmit(save)}
                disabled={isSaving}
              >
                Guardar{" "}
                {isSaving ? (
                  <div
                    className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}
                  ></div>
                ) : (
                  <i
                    className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                    aria-hidden="true"
                  ></i>
                )}
              </button>
              <button
                type="button"
                className={`${styles["btn"]} ${styles["btn-primary"]}`}
                onClick={cancel}
                disabled={isSaving}
              >
                Cancelar{" "}
                <i
                  className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Form;
