import { getVoucher } from "../apiServices/apiRequest";
import { httpClientEnv } from "../apiServices/httpClient";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import * as RD from "@devexperts/remote-data-ts";
import { HttpError, Voucher } from "../interfaces";
import { RemoteData } from "@devexperts/remote-data-ts";

interface Props {
    voucher: string;
    year: string;
    month: string;
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    setRemoteData: React.Dispatch<React.SetStateAction<RemoteData<HttpError, Voucher>>>;
}

export const getRemoteData = async ({ voucher, year, month, setDisabled, setRemoteData }: Props) => {
    const transfer = async() => {
        setRemoteData(RD.pending);
        RTE.run(await getVoucher(`?type=${voucher}&year=${year}&month=${month}`), httpClientEnv)
            .then(E.fold(e => { return setRemoteData(RD.failure(e)) }, a => { return setRemoteData(RD.success(a)) }));
    }
    if (voucher !== "" && year !== "" && month !== "") {
        await transfer();
        setDisabled(true);
    }
};