import Auth from "@aws-amplify/auth";
import { decrypt } from "./utiles";
import { urls, urlsAws } from "../resources/foo/api-endpoints";

const certificadosService = {
    getCertificadoCovid: (emplid, setSeguroCovid) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urls.getCertificadoCovid , requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data !== undefined) {
                        if (data.message) throw new Error(data.message);
                        caches.open("Certificados_Y_Seguros").then((cache) => {
                            cache.put("Certificado_Seguro_Covid" + emplid, new Response(JSON.stringify(data)));
                            setSeguroCovid(data);
                        });
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    caches.match("Certificado_Seguro_Covid" + emplid).then((response) => {
                        if (response !== undefined) {
                            response.json().then((data) => {
                                setSeguroCovid(data);
                            });
                        } else {
                            setSeguroCovid(2);
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },

    certificadosAntiguedad: (emplid, email, type, setCertificado, setSuccess, setButtonDisabled) => {
        Auth.currentSession().then((auth) => {
            let requestOptions = {};
            requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken(),
                },
                body: JSON.stringify({
                    request: {
                        email: email,
                        type: type,
                    }
                })
            };
            fetch(urlsAws.getCertificado , requestOptions)
                .then((response) => response.json())
                .then((data) => data.response)
                .then((data) => {
                    if (setButtonDisabled !== undefined) setButtonDisabled(false);
                    if (decrypt(data?.valid)) {
                        caches.open("Certificados_Y_Seguros").then((cache) => {
                            cache.put("cert" + type + emplid, new Response(JSON.stringify(data)));
                            setCertificado(data.document);
                            setSuccess(true)
                        });
                    } else setCertificado(2);
                })
                .catch((error) => {
                    console.log("error", error);
                    caches.match("cert" + type + emplid).then((data) => {
                        if (data != undefined) {
                            data.json().then((response) => {
                                setCertificado(decrypt(response.document));
                            });
                        } else {
                            setCertificado(2);
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },
};
export default certificadosService;
