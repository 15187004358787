import Auth from "@aws-amplify/auth";
import { urlsAws } from "../resources/foo/api-endpoints";
import { serialize } from "./utiles";

const fichaContratacionService = {
    getMethods: {
        getBankList: (setBankOptions) => {
            Auth.currentSession().then((auth) => {
                const requestOptions = {
                    method: "GET",
                    headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                };
                if (setBankOptions !== null) {
                    fetch(urlsAws.getBankList , requestOptions)
                        .then((response) => response.json())
                        .then((data) => data.response)
                        .then((data) => {
                            if (data !== undefined) {
                                localStorage.setItem("bankList", JSON.stringify(data));
                                caches.open("api-cache").then((cache) => {
                                    cache.put(urlsAws.getBankList, new Response(JSON.stringify(data)));
                                    setBankOptions([{ Code: "", descr_bank: "Seleccione un Banco" }, ...data.CN_LISTABANK_RP]);
                                });
                            }
                        })
                        .catch((error) => {
                            caches.match(urlsAws.getBankList).then((response) => {
                                if (response) {
                                    response.json().then((data) => {
                                        setBankOptions([{ Code: "", descr_bank: "Seleccione un Banco" }, ...data.CN_LISTABANK_RP]);
                                    });
                                }
                            });
                            console.log("error", error);
                        });
                } else {
                    fetch(urlsAws.getBankList , requestOptions)
                        .then((response) => response.json())
                        .then((data) => data.response)
                        .then((data) => {
                            if (data !== undefined) {
                                localStorage.setItem("bankList", JSON.stringify(data));
                            }
                        })
                        .catch((error) => {
                            console.log("error", error);
                        });
                }
            }).catch((err) => console.log(err));
        },
        getDocumentList: (setDocumentsList, company, docType) => {
            Auth.currentSession().then((auth) => {
                const requestOptions = {
                    method: "GET",
                    headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                };
                fetch(urlsAws.getDocumentList + "?company=" + company + "&doctype=" + docType , requestOptions)
                    .then((response) => response.json())
                    .then((data) => data.response)
                    .then((data) => {
                        if (data !== undefined) {
                            caches.open("api-cache").then((cache) => {
                                cache.put(urlsAws.getDocumentList, new Response(JSON.stringify(data)));
                                setDocumentsList(data.CN_RESULT_RP);
                            });
                        }
                    })
                    .catch((error) => {
                        caches.match(urlsAws.getDocumentList).then((response) => {
                            if (response) {
                                response.json().then((data) => {
                                    setDocumentsList(data.CN_RESULT_RP);
                                });
                            }
                        });
                        console.log("error", error);
                    });
            }).catch((err) => console.log(err));
        },
        getVariableList: (setCountryOptions, setIsapreOptions, setAfpOptions, setConflictoOptions) => {
            Auth.currentSession().then((auth) => {
                const requestOptions = {
                    method: "GET",
                    headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                };
                fetch(urlsAws.getVariableList , requestOptions)
                    .then((response) => response.json())
                    .then((data) => data.response)
                    .then((data) => {
                        let afps = [{ tipo: "AFP", codigo: "", descripcion: "Seleccione AFP" }];
                        let conflictos = [{ tipo: "CIN", codigo: "", descripcion: "Seleccione Conflicto" }];
                        let paises = [{ tipo: "CNT", codigo: "", descripcion: "Seleccione País" }];
                        let isapres = [{ tipo: "ISP", codigo: "", descripcion: "Seleccione Isapre" }];
                        for (let i = 0; i < data.CN_LISTFINRESULT_RQ.length; i++) {
                            if (data.CN_LISTFINRESULT_RQ[i].tipo === "AFP") {
                                afps.push(data.CN_LISTFINRESULT_RQ[i]);
                            } else if (data.CN_LISTFINRESULT_RQ[i].tipo === "CIN") {
                                conflictos.push(data.CN_LISTFINRESULT_RQ[i]);
                            } else if (data.CN_LISTFINRESULT_RQ[i].tipo === "CNT") {
                                paises.push(data.CN_LISTFINRESULT_RQ[i]);
                            } else if (data.CN_LISTFINRESULT_RQ[i].tipo === "ISP") {
                                isapres.push(data.CN_LISTFINRESULT_RQ[i]);
                            }
                        }
                        setAfpOptions(afps);
                        setConflictoOptions(conflictos);
                        setCountryOptions(paises);
                        setIsapreOptions(isapres);
                        caches.open("api-cache").then((cache) => {
                            cache.put(urlsAws.getVariableList, new Response(JSON.stringify(data)));
                        });
                    })
                    .catch((error) => {
                        console.log("error", error);
                        caches.match(urlsAws.getVariableList).then((response) => {
                            if (response && response.body !== null) {
                                response.json().then((data) => {
                                    window.location.reload();
                                });
                            }
                        });
                    });
            }).catch((err) => console.log(err));
        },
        getUniformes: (emplid, setUniformOptions) => {
            Auth.currentSession().then((auth) => {
                const url = urlsAws.getUniformes  + "?emplid=" + emplid;
                const requestOptions = {
                    method: "GET",
                    headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                };
                fetch(url, requestOptions)
                    .then((response) => response.json())
                    .then((data) => data.response)
                    .then((data) => {
                        if (data !== undefined) {
                            caches.open("api-cache").then((cache) => {
                                cache.put(url, new Response(JSON.stringify(data)));
                                if (data.posee_uniformes === "S") {
                                    let prendasList = data.CN_LISTPRENDAS;
                                    let uniques = {};
                                    for (let i = 0; i < prendasList.length; i++) {
                                        let prenda = prendasList[i].prenda;
                                        if (uniques.prenda === undefined || uniques.prenda === "") {
                                            uniques[prenda] = [prendasList[i].descr_prenda, [{ talla: "", descr_talla: "Seleccione talla" }]];
                                        }
                                    }
                                    for (let i = 0; i < prendasList.length; i++) {
                                        if (uniques[prendasList[i].prenda] !== undefined) {
                                            uniques[prendasList[i].prenda][1].push({
                                                talla: prendasList[i].talla,
                                                descr_talla: prendasList[i].descr_talla,
                                            });
                                        }
                                    }
                                    setUniformOptions(uniques);
                                }
                            });
                        }
                    })
                    .catch((error) => {
                        caches.match(url).then((response) => {
                            if (response) {
                                response.json().then((data) => {
                                    setUniformOptions(data);
                                });
                            }
                        });
                        console.log("error", error);
                    });
            }).catch((err) => console.log(err));
        },
        getFicha: async (setGetSheet, setListStep, setFichaErr) => {
            await Auth.currentSession().then(async (auth) => {
                const url = urlsAws.getFicha;
                const requestOptions = {
                    method: "GET",
                    headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                };
                await fetch(url, requestOptions)
                    .then(async (response) => {
                        const thowErr = async (err) => { throw new Error(await err) };
                        const returnVal = response.status !== 200 ? thowErr(response.text()) : response.json();
                        return await returnVal;
                    })
                    .then((data) => data.response)
                    .then((data) => {
                        if (data !== undefined) {
                            setGetSheet(data);
                            setListStep([...Array(data.numberOfPages).keys()].map(n => n + 1));
                            sessionStorage.setItem("ficha", JSON.stringify(data));
                        } else {
                            setGetSheet(null);
                        }
                        setFichaErr(null)
                    })
                    .catch(async (error) => {
                        const err = await JSON.parse(error.message).message;
                        if (err !== undefined && (err === "WAIT" || err === "COOM")) {
                            setFichaErr(err);
                        } else {
                            console.log(error);
                        }
                        setGetSheet(null);
                    });
            }).catch((err) => console.log(err));
        },
    },
    postMethods: {
        setFicha: async (postValues, valuesToPost, setPostValues, full, setPostStatus, setDisabledButton, photoData, accountData, uniformData, setToken) => {
            Auth.currentSession().then(async (auth) => {
                const values = { ...postValues, ...valuesToPost };
                const url = urlsAws.setFicha;
                const requestOptions = {
                    method: "POST",
                    headers: { Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                    body: photoData !== undefined && accountData !== undefined && uniformData !== undefined ?
                            serialize({ request: values, full: full, photo: photoData, account: accountData, uniform: uniformData })
                        :   serialize({ request: values, full: full }),
                };
                try {
                    await fetch(url, requestOptions)
                        .then(async (response) => {
                            const thowErr = async (err) => { throw new Error(await err) };
                            const returnVal = response.status !== 200 ? thowErr(response.text()) : response.json();
                            return await returnVal;
                        })
                        .then((data) => {
                            data.response ? (
                                setPostValues({ ...values }),
                                setPostStatus({ status: data.response.return_cd }),
                                (data.response.return_cd === undefined || data.response.return_cd !== "0") ? setDisabledButton(false) : null,
                                setToken && data.response.token !== "" ? setToken(data.response.token) : null
                            )
                            :
                                console.log(data);
                        })
                        .catch(async (error) => {
                            const err = await JSON.parse(error.message);
                            const delay = (ms = 519) => new Promise((r) => setTimeout(r, ms));
                            const newCall = async () => {
                                await delay(260);
                                document.getElementById("closeSendingInfo")?.click();
                                await delay(258)
                                document.getElementById("fichaSomeError")?.click();
                                console.log(err);
                            };
                            await newCall();
                        });
                } catch (err) {
                    const delay = (ms = 519) => new Promise((r) => setTimeout(r, ms));
                    const newCall = async () => {
                        await delay(260);
                        document.getElementById("closeSendingInfo")?.click();
                        await delay(258)
                        document.getElementById("fichaSomeError")?.click();
                        console.log(err);
                        setDisabledButton(false);
                    };
                    newCall();
                }
            }).catch((err) => console.log(err));
        },
        setFichaResumen: async (body) => {
            await Auth.currentSession().then((auth) => {
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                    body: JSON.stringify(body),
                };
                fetch(urlsAws.setFichaResumen, requestOptions).catch((error) => { console.log("error", error) });
            }).catch((err) => console.log(err));
        },
        welcomeNotification: async (body, jwt = '') => {
            const params = jwt ? '?token=' + jwt : '';
            const auth = await Auth.currentSession();
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    Authorization: auth.getIdToken().getJwtToken(),
                    AccessToken: auth.getAccessToken().getJwtToken(),
                },
                body: JSON.stringify(body),
            };
            const data = await fetch(urlsAws.welcomeNotification + params, requestOptions)
                .then((response) => response.json())
                .catch((error) => { console.log(error); return false; });
            if (data && data.response) return data.response;
            else return data;
        }
    },
};
export default fichaContratacionService;
