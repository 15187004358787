import { useAxiosFetch } from "../../../hooks";
import type {
    TalentReviewApi,
    TalentReviewResponse,
    UseFetchTableDataReturnType,
    UseTalentReviewFetchProps,
} from "./hooksTeamDashboard.types";
import defaultProfilImage from "../../../resources/images/default-colaborador-image.png";
import { useTeamDashboardProvider } from "../context/TeamDashboardContext";
import { useEffect } from "react";
import { subMenuListData } from "../context/carouselList.data";

export const useTalentReviewFetch = (
    props: UseTalentReviewFetchProps
    ): UseFetchTableDataReturnType<TalentReviewResponse> => {
    const { selectedYear } = props;
    const { getSelectedMenu } = useTeamDashboardProvider();
    const [getTalentReview, dataTalentReview] = useAxiosFetch<
        TalentReviewApi[]
    >({
        method: "GET",
        url: "/api/my-teams/talent-review",
        params: {
            year: selectedYear,
        },
    });

    useEffect(() => {
        if (getSelectedMenu?.name === subMenuListData[1].name) {
            getTalentReview();
        }
    }, [getSelectedMenu, selectedYear]);

    const talentReviewMapper = (
        response: typeof dataTalentReview
    ): TalentReviewResponse => {
        const { data } = response;
        return (
            data?.map((employee) => ({
                nro_document: employee.nro_document || "",
                last_name: employee.last_name || "",
                name: employee.name || "",
                photo: employee.photo || defaultProfilImage,
                position: employee.position || "",
                tr: employee.tr || "-",
                detail: employee.detail || "-",
            })) || []
        );
    };

    
  return {
    key: subMenuListData[1].name,
    response: {
      dataTable: talentReviewMapper(dataTalentReview),
      subDatable: [],
      isLoading: dataTalentReview.isLoading || dataTalentReview.isLoading,
      isError: dataTalentReview.isError || dataTalentReview.isError,
      getAllFlags: [],
    },
  };
};