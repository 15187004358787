import { useAxiosFetch } from "../../../hooks";
import { useEffect, useState } from "react";
import { InterestSites } from '../context/interestSiteContext';
import { Auth } from "aws-amplify";

const useFetchInterestSite = () => {
    const [country, setCountry] = useState<string>('');

    const [fetchInterestSite, { data, isError, isLoading }, resetInterestSite] = useAxiosFetch<InterestSites[]>({
        url: `/api/interest_sites/country/${country}`,
        method: 'GET',
    });

    useEffect(() => {
        Auth.currentUserInfo().then(user => {
            if (user.attributes['custom:cd_pais']) {
                const countryCode = user.attributes['custom:cd_pais'];
                setCountry(countryCode);
            }
        }).catch(err => console.log(err));
    }, []);

    useEffect(() => {
        country && fetchInterestSite()
    }, [country]);

    return { data, isError, isLoading, resetInterestSite, country, setCountry };
};

 export default useFetchInterestSite;