// import { Link } from "react-router-dom";
import React from "react";
// import Swal from 'sweetalert2';
import "../../resources/css/style-ARG.css";
// import styles from './organigrama.module.css';
// import OrganigramaIcon from '../../resources/images/organigrama/ico_info.svg';
import OrgChartTree from "./org-chart-tree";


const Organigrama = () => {
    return <>
        <OrgChartTree></OrgChartTree>
        {/* <div className="content-chart">
            <ul className={styles["orgchart"]}>
                <li className={styles["root"]}>
                    {tree?.BaseLevel?.report_to ?
                        <div className={styles["nodecontent"]}>

                            <div className={styles["card-title"]}>
                                <h3 className={styles["title-name"]}>
                                    {tree?.BaseLevel.report_to.name} {tree.BaseLevel.report_to.last_name}
                                </h3>
                                <img className={styles["ico-info"]} src={OrganigramaIcon} alt="" />
                            </div>
                            <div className={styles["card"]} style={{ width: "18rem" }}>
                                <div className={styles["card-body"]}>
                                    <p className={styles["card-text"]}>
                                        {tree?.BaseLevel.report_to.position}
                                    </p>
                                    <p className={styles["card-area"]}>
                                        {tree?.BaseLevel.report_to.area}
                                    </p>

                                </div>
                                <hr></hr>
                            </div>
                        </div>
                        : null}
                    <ul>
                        <li className={styles["fade-in"]}>
                            {tree?.BaseLevel?.person ?
                                <div className={styles["nodecontent"]}>

                                    <div className={styles["card-title"]}>
                                        <h3 className={styles["title-name"]}>
                                            {tree?.BaseLevel.person.name} {tree?.BaseLevel.person.last_name}
                                        </h3>
                                        <img className={styles["ico-info"]} src="../../resources/images/organigrama/ico_info.svg" alt="" />
                                    </div>
                                    <div className={styles["card"]} style={{ width: "18rem" }}>
                                        <div className={styles["card-body"]}>
                                            <p className={styles["card-text"]}>
                                                {tree?.BaseLevel.person.position ? tree?.BaseLevel.person.position : 'SIN CARGO'}
                                            </p>
                                            <p className={styles["card-area"]}>
                                                {tree?.BaseLevel.person.area ? tree?.BaseLevel.person.area : 'SIN ÁREA ASOCIADA'}
                                            </p>
                                        </div>
                                        <hr></hr>
                                    </div>
                                </div>
                                : null}
                        </li>
                    </ul>
                </li>
            </ul>
        </div> */}
    </>


}

export default Organigrama;


