import React, { FC, useEffect, useRef, useState } from "react";
import { crudBenefit, getBenefitList, getExcelAllResumen } from "../../../utilities/apiServices/apiRequest";
import { DataModalDinamicProps, IndexProps, PostBenefit, ResponseBenefitList } from "../../../utilities/interfaces";
import TableIndex from "./components/tableIndex/tableBenefit";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import * as RD from "@devexperts/remote-data-ts";
import { RemoteData } from "@devexperts/remote-data-ts";
import { httpClientEnv } from "../../../../../services/apiServices/httpClient";
import { HttpError } from "../../../../../services/apiServices/interfaces";
import { SpinerFullScreen } from "../../../../commonComponents/SpinerFullScreen";
import SelectIndex from "./components/selectsTable/selectIndex";
import ModalConfirmAction from "../../../../commonComponents/ModalConfirmAction";
import ModalInfoDinamic from "../../../../commonComponents/ModalnfoDinamic";
import { scrollToTop } from "../../../../../services/utiles";

const IndexBenefit: FC<IndexProps> = ({
    idBenefit, 
    reload, 
    refConfirmAction, 
    refStatus,
    setAdminCategory, 
    setReload, 
    setIdBenefit, 
    setIsEditBenefit, 
    setNewBenefit, 
    setShowTable,
    pais,
    refLoad,
    refLoadClose
}) => {
    const [filterCountry, setFilterCountry] = useState(pais || '');
    const [startDateFilter, setStartDateFilter] = useState('');
    const [endDateFilter, setEndDateFilter] = useState('');
    const [filterCategory, setFilterCategory] = useState(0);
    const [filterStatus, setFilterStatus] = useState('');
    const [benefitList, setBenefitList] = useState<RemoteData<HttpError, ResponseBenefitList>>(RD.initial);
    const [excelList, setExcelList] = useState(-1);
    const refCloseStatus = useRef<HTMLButtonElement>(null);
    const [remoteDataDeleteBenefit, setRemoteDataDeleteBenefit] = useState<RemoteData<HttpError, PostBenefit>>(RD.initial);
    const [dataModal, setDataModal] = useState<DataModalDinamicProps>({
        callBack : () => {},
        icon: '',
        msg: ''
    })

    const GetBenefitList = async () => {
        setBenefitList(RD.pending)
        RTE.run(await getBenefitList(), httpClientEnv)
        .then(E.fold(e => { return setBenefitList(RD.failure(e)) }, a => {
            let data = RD.success(a)
            if (data._tag === 'RemoteSuccess') {
                return setBenefitList(RD.success(a));
            } else {
                return setBenefitList(RD.failure({ tag: 'httpRequestError', error: 'error GetBenefitList' }));
            } 
        }))
    };

    const DeleteBenefit = async () => {
        setRemoteDataDeleteBenefit(RD.pending)

        let body = {
            id : idBenefit
        }

        RTE.run(await crudBenefit(body, 'DELETE'), httpClientEnv)
            .then(E.fold(e => { return setRemoteDataDeleteBenefit(RD.failure(e)) }, a => {
                let data = RD.success(a)
                if (data._tag === 'RemoteSuccess' && data.value.valid) {
                    refStatus && refStatus.current && refStatus.current.click();
                    setDataModal({
                        msg:'Beneficio despublicado correctamente.',
                        icon: 'fa-check-circle-o',
                        callBack: () => {
                            setReload(true)
                            refCloseStatus && refCloseStatus.current && refCloseStatus.current.click();
                        }
                    })
                    return setRemoteDataDeleteBenefit(RD.success(a))
                } else {
                    return setRemoteDataDeleteBenefit(RD.failure({ tag: 'httpRequestError', error: 'Valid deleteBenefit' }))
                }
        }))
    };

    const HandleDownloadExcel = (date_from: string, date_to: string) => {
        
        if(!date_from || !date_to){
            refStatus && refStatus.current && refStatus.current.click();
            setDataModal({
                msg:'Para descargar la información, por favor seleccione una fecha de Vigencia Desde y Hasta.',
                icon: 'fa-exclamation-triangle',
                callBack: () => {
                    refCloseStatus && refCloseStatus.current && refCloseStatus.current.click();
                }
            })
        } else {
            refLoad && refLoad.current && refLoad.current.click();
            getExcelAllResumen(setExcelList, filterCountry, filterCategory, startDateFilter, endDateFilter, filterStatus, refLoadClose)
        }
    }

    useEffect(()=>{
        GetBenefitList();
        scrollToTop();
    }, [])

    useEffect(()=>{
        if (reload) {
            GetBenefitList();
            setReload(false);
        }
    }, [reload])

    useEffect(()=>{
        if (remoteDataDeleteBenefit._tag === 'RemoteFailure') {
            setDataModal({
                msg:'Ha ocurrido un error al eliminar beneficio, por favor, intente más tarde.',
                icon: 'fa-exclamation-triangle',
                callBack: () => {
                    refCloseStatus && refCloseStatus.current && refCloseStatus.current.click();
                }
            })

            setTimeout(()=> {
                refStatus && refStatus.current && refStatus.current.click();
            },500)
        }
    },[remoteDataDeleteBenefit])

    useEffect(()=>{
        if(excelList === 1){
            setDataModal({
                msg:'Ha ocurrido un error al obtener el reporte. Por favor, intente más tarde.',
                icon: 'fa-exclamation-triangle',
                callBack: () => {
                    refCloseStatus && refCloseStatus.current && refCloseStatus.current.click();
                }
            })

            setTimeout(()=> {
                refStatus && refStatus.current && refStatus.current.click();
                setExcelList(-1);
            },500)
        }
    },[excelList])

    useEffect(()=> {
        if(filterCountry){
            setFilterCategory(0);
        }
    },[filterCountry])

    return(
        <>
            <section className="gestion-de-beneficios">
                <h1>Gestión de Beneficios</h1>
                <div className="box">
                    <div className="tit-group d-md-flex justify-content-between">
                        <h2 className="h4 tit-section">Beneficios</h2>
                        <div className="mb-3 mb-md-0">
                            <div className="d-grid d-md-flex gap-2">
                                <button type="button" className="btn btn-primary"
                                    onClick={()=> {
                                        setShowTable(false);
                                        setAdminCategory(true);
                                    }}> Administrar Categoría 
                                </button>
                                <button type="button" className="btn btn-degradado ms-md-2" 
                                    onClick={()=> {
                                        setShowTable(false);
                                        setNewBenefit(true);
                                    }}> Nuevo Beneficio <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                                </button>
                               {benefitList._tag === 'RemoteSuccess' && 
                                    <a onClick={()=> {
                                        HandleDownloadExcel(startDateFilter, endDateFilter);
                                    }}>
                                        <i className="fa fa-file-excel-o fa-2x mt-1 btnLink" aria-hidden="true" title="Descargar reporte de beneficios"></i>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>

                    {benefitList._tag === 'RemoteSuccess' ? 
                        <>
                            <SelectIndex 
                                filters={benefitList.value.response.filters} 
                                setEndDateFilter={setEndDateFilter} 
                                setFilterCountry={setFilterCountry} 
                                setStartDateFilter={setStartDateFilter}
                                setFilterCategory={setFilterCategory}
                                setFilterStatus={setFilterStatus}
                                pais={pais}
                                filterCountry={filterCountry}
                            />
                            <TableIndex 
                                benefitList={benefitList.value.response} 
                                endDateFilter={endDateFilter} 
                                filterCountry={filterCountry}
                                startDateFilter={startDateFilter}
                                filterCategory={filterCategory}
                                filterStatus={filterStatus}
                                setIdBenefit={setIdBenefit}
                                setIsEditBenefit={setIsEditBenefit}
                                setShowTable={setShowTable}
                                refConfirmAction={refConfirmAction}
                                refStatus={refStatus}
                                setDataModal={setDataModal}
                                refLoad={refLoad}
                                refLoadClose={refLoadClose}
                                refCloseStatus={refCloseStatus}
                            />
                        </>
                    : benefitList._tag === 'RemoteInitial' || benefitList._tag === 'RemotePending' ?
                        <div className="text-center mt-5">
                            <SpinerFullScreen/>
                        </div>
                    : benefitList._tag === 'RemoteFailure' && 
                        <div className="text-center mt-5">
                            <p>Ha ocurrido un error al obtener la información, por favor, intente más tarde.</p>
                        </div>
                    }
                </div>

            </section>

            <ModalConfirmAction refOpen={refConfirmAction} text={`¿Estás seguro que deseas despublicar?`} callBack={()=>{ DeleteBenefit(); } } />

            <ModalInfoDinamic refOpen={refStatus} text={dataModal.msg} icon={dataModal.icon} callBack={dataModal.callBack} refClose={refCloseStatus}/>
        </>
    )    

}
export default IndexBenefit;