import { useState, useCallback, useEffect } from "react";
import Auth from "@aws-amplify/auth";

type IRequestOptions = {
  method: 'GET' | 'POST';
  headers: { [key: string]: string };
  body?: string;
};

type IValidationFunc = (jsonData: any) => Promise<{ error: string } | undefined>;

type IFetchOptions = {
  listUrl: string;
  approveUrl: string;
  rejectUrl: string;
};

type IApiForm<T> = {
  initialData: T | null;
  initialError: null | string;
  submitData: any;
  submitError: null | string;
  loading: boolean;
  handleApprove: (body: any) => void;
  handleReject: (body: any) => void;
};

type IMakeApiRequest = {
  url: string;
  method: 'GET' | 'POST';
  body?: any;
  handleErrors?: IValidationFunc;
  callback?: (jsonData: any) => any;
};

const useApiForm = <T,>({ listUrl, approveUrl, rejectUrl }: IFetchOptions): IApiForm<T> => {
  const [initialData, setInitialData] = useState<T | null>(null);
  const [initialError, setInitialError] = useState<null | string>(null);
  const [submitData, setSubmitData] = useState<any>(null);
  const [submitError, setSubmitError] = useState<null | string>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const makeApiRequest = async ({ url, method, body }: IMakeApiRequest) => {
    try {
      const session = await Auth.currentSession();
      const requestOptions: IRequestOptions = {
        method,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: session.getIdToken().getJwtToken(),
          AccessToken: session.getAccessToken().getJwtToken(),
        },
        body: body ? JSON.stringify(body) : undefined,
      };

      const response = await fetch(url, requestOptions);
      const jsonData = await response.json();
      if (!response.ok) {
        throw new Error();
      }
      if (jsonData.response && jsonData.response.valid !== "1"){
        throw new Error();
      }

      return { data: jsonData };
    } catch (error: any) {
      let errorMessage = "Hemos encontrado un error. Por favor intente más tarde.";
      return { error: errorMessage };
    }
  };

  const fetchData = useCallback(async () => {
    setInitialError(null);
    const { data, error } = await makeApiRequest({
      url: listUrl,
      method: "GET",
    });
    if (error) {
      setLoading(false);
      setInitialError(error);
    } else {
      setLoading(false);
      setInitialData(data);
    }
  }, [listUrl]);

  const handleApprove = useCallback(async (body: any) => {
    setSubmitError(null);
    setLoading(true);
    const { data, error } = await makeApiRequest({
      url: approveUrl,
      method: "POST",
      body,
    });
    if (error) {
      setLoading(false);
      setSubmitError(error);
    } else {
      await fetchData();
      setLoading(false);
      setSubmitData(data);
    }
  }, [approveUrl]);

  const handleReject = useCallback(async (body: any) => {
    setSubmitError(null);
    setLoading(true);
    const { data, error } = await makeApiRequest({
      url: rejectUrl,
      method: "POST",
      body,
    });
    if (error) {
      setLoading(false);
      setSubmitError(error);
    } else {
      await fetchData();
      setLoading(false);
      setSubmitData(data);
    }
  }, [rejectUrl]);

  useEffect(() => {
    const apiRequest = async () => {
      setLoading(true);
      await fetchData();
    };
    if (listUrl) {
      apiRequest();
    }
  }, [fetchData, listUrl]);

  return {
    initialData,
    initialError,
    submitData,
    submitError,
    loading,
    handleApprove,
    handleReject,
  };
};

export default useApiForm;