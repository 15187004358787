import React, { useState, useEffect } from 'react'
import { BreadcrumbLevels, SubLevelsProps } from '../../../utilities/interfaces'
import { formatSubLevels } from '../utilities/formaters'
import LevelsCollapsed from './levelsCollapsed'
import LevelsExpanded from './levelsExpanded'

const SubRoutesBlock = ({ subLevels }: SubLevelsProps) => {
  const [levelsToShow, setLevelsToShow] = useState<Array<BreadcrumbLevels>>([])

  useEffect(() => {
    subLevels && setLevelsToShow(formatSubLevels(subLevels))
  }, [subLevels])

  const SubLevelsRender = () => {
    if (levelsToShow.length > 1) {
      return (<LevelsCollapsed levelsToShow={levelsToShow} />)
    } else {
      return (<LevelsExpanded levelsToShow={levelsToShow} />)
    }
  }

  return (<>{levelsToShow.length > 0 && <SubLevelsRender/>}</>)
}

export default SubRoutesBlock