import remuneracionService from "../services/remuneracion-service";
import { useState, useEffect, useMemo, Children, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Base64 } from "js-base64";
import { decrypt } from "../services/utiles";
import React from "react";
import { Link } from "react-router-dom";
import { Context } from "../context";

const Remuneracion = (props) => {
    let navigate = useNavigate();
    const { firstLoginData } = useContext(Context);
    const [data, setData] = useState(null);
    const [dataEmail, setDataEmail] = useState(null);
    const emplid = window.localStorage.getItem("emplid");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [validarEmail, setValidarEmail] = useState(true);
    const [email, setEmail] = useState("");
    const today = new Date(Date.now());
    const [selectedYear, setSelectedYear] = useState("");
    let year = today.getFullYear();
    const [data2, setData2] = useState(null);
    const [data2Xls, setData2Xls] = useState(null);
    const [descripcion, setDescripcion] = useState("");
    const [concepto, setConcepto] = useState("");
    const [bloquear, setBloquear] = useState(true);
    const [cargando, setCargando] = useState(false);
    const [modalOff, setModalOff] = useState(false);
    const [clicBuscar, setClicBuscar] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
        if (emplid === null) navigate("/");
        else remuneracionService.getremuneracion(emplid, selectedMonth, selectedYear, setData);
    }, [emplid, clicBuscar]);

    useEffect(() => {
        if (typeof data2 === "object" && data2 !== null) {
            handleSubmit(descripcion, selectedMonth, selectedYear, data2.email_att);
        }
    }, [data2]);

    useEffect(() => {
        if (typeof data2Xls === "object" && data2Xls !== null) {
            handleSubmitExcel(descripcion, selectedMonth, selectedYear, data2Xls.email_att);
        }
    }, [data2Xls]);

    useEffect(() => {
        selectedMonth !== "" && selectedYear !== "" ? setBloquear(false) : setBloquear(true);
    }, [selectedMonth, selectedYear]);

    const monthOption = [
        {
            value: "ene",
            int: "01",
            label: "Enero",
        },
        {
            value: "feb",
            int: 2,
            label: "Febrero",
        },
        {
            value: "mar",
            int: 3,
            label: "Marzo",
        },
        {
            value: "abr",
            int: 4,
            label: "Abril",
        },
        {
            value: "may",
            int: 5,
            label: "Mayo",
        },
        {
            value: "jun",
            int: 6,
            label: "Junio",
        },
        {
            value: "jul",
            int: 7,
            label: "Julio",
        },
        {
            value: "ago",
            int: 8,
            label: "Agosto",
        },
        {
            value: "sep",
            int: 9,
            label: "Septiembre",
        },
        {
            value: "oct",
            int: 10,
            label: "Octubre",
        },
        {
            value: "nov",
            int: 11,
            label: "Noviembre",
        },
        {
            value: "dic",
            int: 12,
            label: "Diciembre",
        },
    ];
    monthOption.unshift({ value: "", int: 0, label: "Seleccione mes" });

    const yearOption = useMemo(() => [], [year]);

    const yearCharge = () => {
        if (yearOption.length === 0) {
            yearOption.push({ value: "", label: "Selecione año", int: 0 });
            for (let y = year; y >= 2020; y--) {
                yearOption.push({
                    value: String(y),
                    label: String(y),
                    int: y,
                });
            }
        }
    };

    yearCharge();

    const openModal = (concepto) => {
        setButtonDisabled(false);
        setEmail(firstLoginData.EMAIL);
        setConcepto(concepto);
        if (navigator.onLine) {
            document.getElementById("abrirModalLiquidaciones").click();
        } else {
            document.getElementById("sinConexion").click();
        }
    };

    const base64ToArrayBuffer = (base64) => {
        base64 = decrypt(base64);
        if (base64.charAt(0) === "0") {
            base64 = base64.substring(1).replaceAll("\n", "").replaceAll(" ", "");
        }
        let binaryString = Base64.atob(base64);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    };

    const saveByteArrayPDF = (reportName, byte) => {
        let blob = new Blob([byte], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        let fileName = reportName;
        link.download = fileName;
        link.dispatchEvent(new MouseEvent("click"));
        setCargando(false);
        document.body.removeChild(document.getElementById("modal-overlay"));
        document.body.classList.remove("modal-open");
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "0";
    };

    const saveByteArrayExcel = (reportName, byte) => {
        let blob = new Blob([byte], { type: "application/vnd.ms-excel" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        let fileName = reportName;
        link.download = fileName;
        link.dispatchEvent(new MouseEvent("click"));
        setCargando(false);
        document.body.removeChild(document.getElementById("modal-overlay"));
        document.body.classList.remove("modal-open");
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "0";
    };

    const handleSubmitExcel = (descripcion, month, year, base64) => {
        var ascii = base64ToArrayBuffer(base64);
        saveByteArrayExcel(descripcion.replaceAll(" ", "_") + "-" + month + "-" + year, ascii);
    };

    const handleSubmit = (descripcion, month, year, base64) => {
        var ascii = base64ToArrayBuffer(base64);
        saveByteArrayPDF(descripcion.replaceAll(" ", "_") + "-" + month + "-" + year, ascii);
    };

    const handlePdf = (e, descripcion, concepto) => {
        e.preventDefault();
        if (data !== undefined && data.length !== 0) {
            setDescripcion(descripcion);
            setCargando(true);
            document.body.classList.add("modal-open");
            document.body.style.overflow = "hidden";
            document.body.style.paddingRight = "15px";
            let newDiv = document.createElement("div");
            newDiv.id = "modal-overlay";
            newDiv.className = "modal-backdrop fade show";
            document.body.appendChild(newDiv);
            remuneracionService.getremuneracion2(emplid, selectedMonth, selectedYear, setData2, email, "PDF", data, concepto, setValidarEmail, setModalOff, setCargando);
            //document.getElementById("fail-certificados").click();
        }
    };

    const handleXls = (e, descripcion, concepto) => {
        e.preventDefault();
        if (data !== undefined && data.length !== 0) {
            setDescripcion(descripcion);
            setCargando(true);
            document.body.classList.add("modal-open");
            document.body.style.overflow = "hidden";
            document.body.style.paddingRight = "15px";
            let newDiv = document.createElement("div");
            newDiv.id = "modal-overlay";
            newDiv.className = "modal-backdrop fade show";
            document.body.appendChild(newDiv);
            remuneracionService.getremuneracion2(emplid, selectedMonth, selectedYear, setData2Xls, email, "XLS", data, concepto, setValidarEmail, setModalOff, setCargando);
        }
    };

    useEffect(() => {
        if (validarEmail) {
            setValidarEmail(false);
        }
    }, [validarEmail]);

    const handleSubmitEmail = (e) => {
        e.preventDefault();
        const validarEmail = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;
        if (!email.match(validarEmail)) {
            setValidarEmail(false);
        } else {
            setButtonDisabled(true);
            setValidarEmail(true);
            remuneracionService.getremuneracion2(emplid, selectedMonth, selectedYear, setDataEmail, email, "PDF", data, concepto, setValidarEmail);
            setEmail("");
        }
        document.getElementById("cerrar-modal-rem").click();
        document.getElementById("openModalEmaliSend").click();
    };

    const handleSubmitBuscar = async (e) => {
        e.preventDefault();
        setClicBuscar((state) => !state);
        if (selectedYear === "" || selectedMonth === "") {
            document.getElementById("abrirModalFail").click();
        } else {
            setBloquear(true);
        }
    };

    const limpiarModal = () => {
        setEmail("");
    };

    return (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item">Liquidaciones y Anexos</li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Anexo de Remuneraciones
                    </li>
                </ol>
            </nav>

            {/*<!-- inicio anexo de remuneraciones // Seleccione año-->*/}
            <section className="anexo-de-remuneraciones">
                <h1>Anexo de Remuneraciones</h1>
                <div className="box pb-5 mb-4">
                    <div className="row">
                        <div className="col-12 col-md-6 col-xl-6 mb-4">
                            <select
                                className="form-select"
                                id="year"
                                value={selectedYear}
                                onChange={(e) => setSelectedYear(e.target.value)}
                            >
                                {yearOption.map((o) => (
                                    <option key={o.value} value={o.value}>
                                        {o.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6 mb-4">
                            <select
                                className="form-select"
                                id="month"
                                value={selectedMonth}
                                onChange={(e) => setSelectedMonth(e.target.value)}
                            >
                                {monthOption.map((o) => (
                                    <option key={o.value} value={o.int}>
                                        {o.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="text-end d-grid d-md-block">
                        {/*<!-- Agregar disabled a button para desactivar botón -->*/}
                        <button
                            type="button"
                            className="btn btn-degradado"
                            disabled={bloquear}
                            onClick={(e) => {
                                handleSubmitBuscar(e);
                            }}
                        >
                            Buscar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                {/*<!-- Modal fail -->*/}
                <a hidden id="abrirModalFail" data-bs-toggle="modal" data-bs-target="#fail"></a>
                <div className="modal fade" id="fail" tabIndex="-1" aria-labelledby="fail" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body text-center py-4">
                                <i className="fa fa-times-circle-o color-orange fa-3x mb-2" data-bs-dismiss="modal" aria-label="Close" aria-hidden="true"></i>
                                <h2 className="h5">Seleccione año y mes</h2>
                            </div>
                        </div>
                    </div>
                </div>
                {/* modal cargando */}
                {cargando ? (
                    <div className="col">
                        <div className="modal fade show" id="descargando" key={"descargando"} tabIndex="-1" aria-labelledby="fail" aria-modal="true" role="dialog" style={{ display: "block" }}>
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-body text-center py-4">
                                        <i className="fa fa-download color-orange fa-3x mb-2" data-bs-dismiss="modal" aria-label="Close" aria-hidden="true"></i>
                                        <h2 className="h5">Descargando...</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {/*<!-- Detalle liquidaciones -->*/}
                <div className="box mb-4">
                    <h2 className="h4 tit-section">Detalle Remuneraciones</h2>
                    {/*<!-- Escritorio -->*/}
                    <table className="table table-even table-section table-borderless align-middle text-uppercase d-none d-md-table">
                        <thead>
                            <tr>
                                <th className="col-3">Concepto</th>
                                <th className="col-7">Descripción</th>
                                <th className="col-2 text-center">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* condicional */}
                            {data !== null && data !== undefined && data.length > 0 && data !== "" ? (
                                decrypt(data[0].concepto) !== "" && decrypt(data[0].concepto) !== undefined ? (
                                    Children.toArray(
                                        data.map((value) => {
                                            return (
                                                <tr key={value.concepto}>
                                                    <td>{decrypt(value.concepto)}</td>
                                                    <td>{decrypt(value.descr)}</td>
                                                    {decrypt(value.publisher) !== "0" ? (
                                                        <td className="text-center">
                                                            <a href="#">
                                                                <i onClick={(e) => handleXls(e, decrypt(value.descr), decrypt(value.concepto))} className="fa fa-file-excel-o fa-fw me-2" aria-hidden="true"></i>
                                                            </a>
                                                            <a href="#">
                                                                <i onClick={(e) => handlePdf(e, decrypt(value.descr), decrypt(value.concepto))} className="fa fa-file-pdf-o fa-fw me-2" aria-hidden="true"></i>
                                                            </a>
                                                            {/*<!-- desplegar modal email -->*/}
                                                            <a
                                                                href="#"
                                                                data-bs-toggle="modal"
                                                                onClick={() => {
                                                                    openModal(decrypt(value.concepto));
                                                                }}
                                                            >
                                                                <i className="fa fa-envelope-o fa-fw" aria-hidden="true"></i>
                                                            </a>
                                                        </td>
                                                    ) : (
                                                        <td className="text-center">
                                                            <a href="#">
                                                                <i onClick={(e) => handlePdf(e, decrypt(value.descr))} className="fa fa-file-pdf-o fa-fw me-2" aria-hidden="true"></i>
                                                            </a>
                                                            {/*<!-- desplegar modal email -->*/}
                                                            <a
                                                                href="#"
                                                                data-bs-toggle="modal"
                                                                onClick={() => {
                                                                    openModal(decrypt(value.concepto));
                                                                }}
                                                            >
                                                                <i className="fa fa-envelope-o fa-fw" aria-hidden="true"></i>
                                                            </a>
                                                        </td>
                                                    )}
                                                </tr>
                                            );
                                        })
                                    )
                                ) : (
                                    <tr>
                                        <td>Sin datos para mostrar</td>
                                    </tr>
                                )
                            ) : (
                                <tr>
                                    <td>Sin datos para mostrar</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {/*<!-- Móviles -->*/}
                    <div className="data-list-section d-block d-md-none">
                        <ul className="data-list-section--item list-unstyled mb-0">
                            {/* condicional */}
                            {data !== null && data !== undefined && data.length > 0 && data !== ""
                                ? decrypt(data[0].concepto) !== "" && decrypt(data[0].concepto) !== undefined
                                    ? Children.toArray(data.map((value) => {
                                          return (
                                              <>
                                                  <li>
                                                      <span className="text-uppercase d-block mb-1">
                                                          <strong>Concepto</strong>
                                                      </span>
                                                      <span>{decrypt(value.concepto)}</span>
                                                  </li>
                                                  <li>
                                                      <span className="text-uppercase d-block mb-1">
                                                          <strong>Descripción</strong>
                                                      </span>
                                                      <span>{decrypt(value.descr)}</span>
                                                  </li>
                                                  {decrypt(value.publisher) !== "0" ? (
                                                      <li>
                                                          <span className="text-uppercase d-block mb-1">
                                                              <strong>Acciones</strong>
                                                          </span>
                                                          <a href="#">
                                                              <i onClick={(e) => handleXls(e, decrypt(value.descr), decrypt(value.concepto))} className="fa fa-file-excel-o fa-fw me-2" aria-hidden="true"></i>
                                                          </a>
                                                          <a href="#">
                                                              <i onClick={(e) => handlePdf(e, decrypt(value.descr), decrypt(value.concepto))} className="fa fa-file-pdf-o fa-fw me-2" aria-hidden="true"></i>
                                                          </a>
                                                          {/*<!-- desplegar modal email -->*/}
                                                          <a
                                                              href="#"
                                                              data-bs-toggle="modal"
                                                              onClick={() => {
                                                                openModal(decrypt(value.concepto));
                                                              }}
                                                          >
                                                              <i className="fa fa-envelope-o fa-fw" aria-hidden="true"></i>
                                                          </a>
                                                      </li>
                                                  ) : (
                                                      <li>
                                                          <a href="#">
                                                              <i onClick={(e) => handlePdf(e, decrypt(value.descr), decrypt(value.concepto))} className="fa fa-file-pdf-o fa-fw me-2" aria-hidden="true"></i>
                                                          </a>
                                                          {/*<!-- desplegar modal email -->*/}
                                                          <a
                                                              href="#"
                                                              data-bs-toggle="modal"
                                                              onClick={() => {
                                                                  openModal(decrypt(value.concepto));
                                                              }}
                                                          >
                                                              <i className="fa fa-envelope-o fa-fw" aria-hidden="true"></i>
                                                          </a>
                                                      </li>
                                                  )}
                                              </>
                                          );
                                      }))
                                    : (
                                        <li>
                                            <p>Sin datos para mostrar</p>
                                        </li>
                                    )
                                : (
                                    <li>
                                        <p>Sin datos para mostrar</p>
                                    </li>
                                )}
                        </ul>
                    </div>
                    <>
                        <form
                            onSubmit={async (e) => {
                                handleSubmitEmail(e);
                            }}
                        >
                            <>
                                {/* modal correo */}
                                <a hidden id="abrirModalLiquidaciones" data-bs-toggle="modal" data-bs-target="#enviar-por-correo"></a>
                                <div className="modal fade " id="enviar-por-correo" tabIndex={"-1"} aria-labelledby="enviar-por-correo" aria-hidden="true">
                                    (
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header border-0 pb-0">
                                                {/* <!-- cierra la modal 1 --> */}
                                                <button type="submit" id="cerrar-modal-rem" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => limpiarModal()}></button>
                                            </div>
                                            <div className="modal-body">
                                                <h2 className="h5 mb-2">Enviar a:</h2>
                                                <div className="mb-3">
                                                    <label className="form-label mb-2"><strong>Dirección de correo electrónico</strong></label>
                                                    <input type="email" required pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$' id="emailLiquidacion" className="form-control" placeholder="mailcontacto@cencosud.com"  onInput={(e) => e.target.setCustomValidity("")} onInvalid={(e) => e.target.setCustomValidity("Ingrese email válido")} onChange={(e) => { setEmail(e.target.value); e.target.value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? document.getElementById("btnEmail").disabled = false : document.getElementById("btnEmail").disabled = true }} defaultValue={firstLoginData.EMAIL}></input>
                                                </div>
                                            </div>
                                            <div className="modal-footer border-0 pt-0">
                                                <button type="button" className="btn btn-primary col-12 col-md-auto" data-bs-dismiss="modal" onClick={() => limpiarModal()}>
                                                    Cancelar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                                                </button>
                                                <button id="btnEmail" type="submit" disabled={buttonDisabled} className="btn btn-degradado col-12 col-md-auto" data-bs-target="#success">
                                                    Confirmar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                </div>
                                {/* Modal mensaje no conexion */}
                                <a id="sinConexion" data-bs-toggle="modal" data-bs-target="#sinConexionModal"></a>
                                <div className="modal fade" id="sinConexionModal" tabIndex="-1" aria-labelledby="sinConexionModal" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header border-0 pb-0">
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body text-center py-4">
                                                <i className="fa fa-exclamation-triangle color-orange fa-3x" aria-hidden="true"></i>
                                                <h2 className="h5">No tienes conexión</h2>
                                                <p>No puedes ejecutar esta acción si no estás conectado.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* fin modal */}
                                {/* Modal fail certificado*/}
                                <a id="abrirModalcertificados" data-bs-toggle="modal" data-bs-target="#fail-certificados"></a>
                                <div className="modal fade" id="fail-certificados" tabIndex="-1" aria-labelledby="sinConexionModal" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header border-0 pb-0">
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body text-center py-4">
                                                <i className="fa fa-exclamation-triangle color-orange fa-3x" aria-hidden="true"></i>
                                                <h2 className="h5">No tienes conexión </h2>
                                                <p>No puedes ejecutar esta acción.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* fin modal fail certificado*/}
                                {/* modal mensaje exitoso */}
                                <a hidden id="openModalEmaliSend" data-bs-toggle="modal" data-bs-target="#success">
                                    {" "}
                                </a>
                                <div className="modal fade" id="success" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-body text-center py-4">
                                                <i className="fa fa-check-circle-o color-orange fa-3x" aria-hidden="true"></i>
                                                <h2 className="h5">Correo enviado exitosamente</h2>
                                                <p>Su detalle de liquidaciones ha sido enviado a la dirección de correo electrónico proporcionada.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        </form>
                    </>
                </div>
                {/*<!-- fin modal -->*/}
                <div className="ps-4">
                    <p>
                        <i className="fa fa-exclamation-triangle fa-fw fa-lg me-1 color-red" aria-hidden="true"></i> Si necesita la impresión de su liquidación de sueldo, favor mande mail al responsable de RR.HH. de su Local/Tienda o CD.
                    </p>
                </div>
            </section>
        </>
    );
};

export default Remuneracion;
