const HomeContent = {
  title: 'Centro de Ayuda',
  subtitle: 'Puedes conocer nuestras categorías más buscadas a continuación:',
  sectionTitle: 'Preguntas más frecuentes',
  goToCategoriesBtn: 'Ver todas las categorías'
}

const BackButton = {
  simple: 'Volver'
}

const CategoryContent = {
  allCategoriesTitle: 'Todas las categorías',
  backButton: 'Volver al inicio'
}

const ArticleContent = {
  collapseButtonClosed: 'Ver todas las preguntas ',
  collapseButtonOpen: 'Ver menos preguntas',
  relatedArticlesTitle: 'Artículos relacionados',
  views: 'vistas'
}

const SearchContent = {
  placeholder: '¿En qué podemos ayudarte hoy?',
  foundMsg: 'Se muestran resultados para:',
  foundSubtitle: 'Artículos relacionados',
  notFoundMsg: 'No se encuentran resultados para:',
  notFoundSubtitle: 'Ideas para mejorar tu búsqueda',
  notFoundSuggestions: [
    'Prueba usando sinónimos (Ejemplo: “Sueldo”, “Remuneración”, “Liquidación”)',
    'Sé breve. Mientras más palabras, menos resultados',
    'No uses caracteres especiales como @, $, % u otros.'
  ],
  notFoundCategories: 'Puede resultarte útil',
  failedSearch: {
    mainText: 'No encontramos resultados para tu búsqueda',
    suggestions: 'Intenta usar palabras similares, sin caracteres especiales ni faltas ortográficas.',
    recomendations: 'También te puede interesar: '
  }
}

const LoadingContent = {
  simpleLoading: 'Cargando...',
  loading: 'Cargando información...',
  notValid: 'No hay información disponible'
}

const BreadCrumbs = {
  home: 'Inicio',
  helpCenter: 'Centro de Ayuda',
  categoryTitle: 'Categoría',
  article: 'Artículo',
  allCategories: 'Todas las categorías',
  category: 'Detalle de categoría'
}

const Paths = {
  home: '/inicio',
  helpCenter: '/centro-de-ayuda',
  category: '/centro-de-ayuda/categoria/',
  article: '/centro-de-ayuda/articulo/',
  allCategories: '/centro-de-ayuda/todas-las-categorias/',
  searchResults: '/centro-de-ayuda/resultados-de-la-busqueda/'
}

const PathDetails = {
  categoryPath: 'categoria',
  articlePath: 'articulo',
  categoriesPath: 'todas-las-categorias',
  searchPath: 'resultados-de-la-busqueda',
  helpCenterPath: 'Inicio / Centro de Ayuda',
}

const FeedBackContent = {
  firstMessage: '¿Te sirvió la información?',
  positiveOption: 'Si',
  negativeOption: 'No',
  positiveCommenTitle: 'Gracias por tu respuesta',
  positiveCommenSubtitle: '¿Qué te gustó del contenido?',
  positiveCommenPlaceholder: 'Este campo es opcional',
  sendFeedbackButton: 'Enviar respuesta',
  sendFeedbackButtonLoading: 'Enviando respuesta...',
  negativeCommenSubtitle: '¿En qué podemos mejorar?',
  negativeCommenPlaceholder: 'Recuerda escribir un comentario para continuar',
  successToast: 'Gracias por tus comentarios.',
  failToast: 'No pudimos enviar tu respuesta.',
  failToastButton: 'Reintentar',
}

const ErrorContent = {
  title404: 'Lo sentimos. No pudimos encontrar esta página.',
  subtitle404: 'Intenta revisando la dirección web',
  title403: 'Lo sentimos, esta página requiere otros permisos',
  subtitle403: 'Contacta a tu referente de RRHH de tu tienda o local.',
  title504: 'Lo sentimos. El sistema tardó mucho en responder.',
  subtitle504: 'Vuelve a intentarlo en unos minutos',
  title500_line1: 'Lo sentimos el servicio consultado no se',
  title500_line2: 'encuentra disponible en este momento.',
  subtitle500: 'Intenta en unos minutos',
  reloadBtn: 'Recargar la página',
  backButton: 'Volver a la página de inicio'
}

export {
  HomeContent,
  CategoryContent,
  ArticleContent,
  SearchContent,
  LoadingContent,
  BreadCrumbs,
  Paths,
  PathDetails,
  FeedBackContent,
  BackButton,
  ErrorContent
}