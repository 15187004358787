import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import React, { useEffect, useState } from "react";
import tiempoService from "../../services/tiempo-service";
import CarrouselTiempo from "../elements/tiempo-carrousel";
import SaldoTiempo from "../elements/tiempo-saldo";
import { Link } from 'react-router-dom';

const TiempoHistorial = () => {
  const [dataEquipo, setDataEquipo] = useState(null);
  const [errorBeneficios, setErrorBeneficios] = useState(false);
  const [equipo, setEquipo] = useState(null);
  const [estadoSeleccionado, setEstadoSeleccionado] = useState("P");
  const [primerRegistro, setPrimerRegistro] = useState(null);
  const [estadoSelect, setEstadoSelect] = useState("");
  const [casilla, setCasilla] = useState(0);
  const [selectFechas, setSelectFechas] = useState(null);
  const [fechaSeleccionada, setFechaSeleccionada] = useState(null);
  const [cargando, setCargando] = useState(false);
  const [respuestaSolicitud, setRespuestaSolicitud] = useState(null);
  const [respuestaSolicitudModal, setRespuestaSolicitudModal] = useState(false);
  const [respuestaSolicitudValid, setRespuestaSolicitudValid] = useState(false);
  const [estado, setEstado] = useState(null);
  const [estado_descr, setEstado_descr] = useState(null);
  const [filterValue, setFilterValue] = useState("P");

  const delay = (ms = 6000) => new Promise((r) => setTimeout(r, ms));
  const newCall = async (valid, msj) => {
    await delay();
    setRespuestaSolicitudModal(true);
    setCargando(false);
    document.getElementById('mensajeStatus').innerHTML = msj;
    let newDataEquipo = dataEquipo;
    let idSeleccionado = primerRegistro.nro_solicitud;
    if (newDataEquipo && valid === "1") {
      newDataEquipo.subalternos.forEach((element) => {
        if (element.nro_solicitud === idSeleccionado) {
          element.estado = estado;
          element.estado_descr = estado_descr;
        }
      });
      setEstado(null);
      setEstado_descr(null);
      let listadoEquipo =
        newDataEquipo.subalternos !== undefined
          ? newDataEquipo.subalternos.filter(
              (e) => e.estado === estadoSeleccionado
            )
          : [];
      if (fechaSeleccionada) {
        let listaFiltradaFecha = listadoEquipo.filter(
          (e) => obtener_anio(e.fecha_permiso) === fechaSeleccionada
        );
        if (listaFiltradaFecha.length > 0) {
          setPrimerRegistro(listaFiltradaFecha[casilla]);
        } else {
          setPrimerRegistro(null);
        }
        setEquipo(listaFiltradaFecha);
      } else {
        if (listadoEquipo.length > 0) {
          setPrimerRegistro(listadoEquipo[casilla]);
        } else {
          setPrimerRegistro(null);
        }
        setEquipo(listadoEquipo);
      }
      setDataEquipo(newDataEquipo);
    }
  };
  const handleClose = () => {
    setErrorBeneficios(false);
    setRespuestaSolicitudModal(false);
    // setFilterValue("P");
  };

  const anularRegistro = () => {
    if (primerRegistro) {
      setCargando(true);
      tiempoService.anularTiempo(
        primerRegistro.rut,
        primerRegistro.nro_solicitud,
        primerRegistro.fecha_permiso,
        setRespuestaSolicitud
      );
      setEstado("R");
      setEstado_descr("Anulado");
    }
  };
  const aprobarRegistro = () => {
    if (primerRegistro) {
      setCargando(true);
      tiempoService.aprobarTiempo(
        primerRegistro.rut,
        primerRegistro.nro_solicitud,
        primerRegistro.fecha_permiso,
        setRespuestaSolicitud
      );
      setEstado("A");
      setEstado_descr("Aprobado");
    }
  };

  useEffect(() => {
    if (respuestaSolicitud) {
      setRespuestaSolicitudValid(respuestaSolicitud.valid)
      let msj = respuestaSolicitud.status.replace(/\./g, '.<br>');
      newCall(respuestaSolicitud.valid, msj);
    }
  }, [respuestaSolicitud]);

  useEffect(() => {
    tiempoService.getEquipo(setDataEquipo);
    return () => setDataEquipo(null);
  }, []);

  //useeffect
  useEffect(() => {
    setEstadoSelect("selected");
  }, [casilla]);

  useEffect(() => {
    if (equipo && Array.isArray(equipo)) {
      let selectedDates = [];
      equipo.forEach((e) => {
        let fecha = obtener_anio(e.fecha_permiso);
        if (fecha && fecha !== "" && fecha !== " ") {
          if (!selectedDates.includes(fecha)) {
            selectedDates.push(fecha);
          }
        }
      });
      setSelectFechas(selectedDates);
    }
  }, [equipo]);

  useEffect(() => {
    if (dataEquipo) {
      if (dataEquipo === 2) {
        setErrorBeneficios(true);
      } else {
        if (dataEquipo.valid === "1") {
          let listadoEquipo =
            dataEquipo.subalternos !== undefined
              ? dataEquipo.subalternos.filter(
                  (e) => e.estado === estadoSeleccionado
                )
              : [];
          if (fechaSeleccionada) {
            let listaFiltradaFecha = listadoEquipo.filter(
              (e) => obtener_anio(e.fecha_permiso) === fechaSeleccionada
            );
            if (listaFiltradaFecha.length > 0) {
              setPrimerRegistro(listaFiltradaFecha[casilla]);
            } else {
              setPrimerRegistro(null);
            }
            setEquipo(listaFiltradaFecha);
          } else {
            if (listadoEquipo.length > 0) {
              setPrimerRegistro(listadoEquipo[casilla]);
            } else {
              setPrimerRegistro(null);
            }
            setEquipo(listadoEquipo);
          }
        } else {
          setErrorBeneficios(true);
        }
      }
    }
  }, [dataEquipo, estadoSeleccionado, casilla, fechaSeleccionada]);

  const cambiarFiltro = () => {
    let idFiltro = document.getElementById("filtro").value;
    setCasilla(0);
    setEstadoSeleccionado(idFiltro);
  };

  function obtener_anio(fechaOb) {
    let fecha = fechaOb.split("-");
    return fecha[0];
  }

  function formato(texto) {
    return texto.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1");
  }

  return (
    <>
      {/* <!-- inicio breadcrumbs -->*/}
      <nav className="breadcrumbs" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/inicio">
              <i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true"></i> Inicio
            </Link>
          </li>
          <li className="breadcrumb-item">Mi Tiempo</li>
          <li className="breadcrumb-item active" aria-current="page">
            Canjes de mi equipo
          </li>
        </ol>
      </nav>
      {/* <!-- fin breadcrumbs -->*/}

      {/* <!-- inicio Mi Tiempo -->*/}
      <section className="mi-tiempo">
        <div className="tit-group d-block d-lg-flex justify-content-between align-items-center mb-4">
          <h1 className="mb-3 mb-lg-0">Mi Tiempo</h1>

          <SaldoTiempo></SaldoTiempo>
        </div>

        <CarrouselTiempo tituloSelect="Canjes de mi equipo"></CarrouselTiempo>
        <div className="box mb-4">
          <h2 className="h4 tit-section">Filtrar por</h2>
          <div className="row">
            <div className="col-12 col-xl-6 mb-4">
              <select id="filtro" className="form-select" value={filterValue} onChange={(e) => {setFilterValue(e.target.value)}}>
                <option value={"A"}>Aprobadas</option>
                <option selected value={"P"}>
                  Pendientes
                </option>
              </select>
            </div>
            <div className="col d-grid d-md-block">
              <button
                type="button"
                onClick={(e) => {
                  cambiarFiltro(e);
                }}
                className="btn btn-degradado"
              >
                Filtrar{" "}
                <i
                  className="fa fa-chevron-right fa-fw fa-xs"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </div>
        </div>

        <div className="box mb-4">
          <div className="tit-group d-flex justify-content-between">
            <h2 className="h4 tit-section">Canjes de mi equipo</h2>
            <div>

              {selectFechas && selectFechas.length > 0 ? (
                <>
                  <select
                  onChange={(e) => {
                    setFechaSeleccionada(e.target.value);
                  }}
                  id="inputState"
                  className="form-select"
                  required="required">
                  {selectFechas.map((e, index) => {
                      return (
                        <option key={"Array1" + index} value={e}>
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </>
              ): <></>}
            </div>
          </div>

          <div className="row d-none d-md-flex">
            <div className="col-12 col-xl-7 d-flex">
              <div className="border border-primary rounded-4 p-4 align-items-stretch w-100">
                <div className="box-scroll box-scroll">
                  {dataEquipo === null ? (
                    <>
                     <div className="modal-body text-center py-4">
                        <span className="spinner-border spinner-border-sm" />
                        <p> Cargando información... </p>
                    </div>
                    </>
                  )
                  :  equipo ? (
                    equipo.length === 0 ? (
                      <p>No se ha encontrado información.</p>
                    ) : (
                      equipo.map((e, index) => {
                        if (index === casilla) {
                          return (
                            <a
                              className={
                                "text-decoration-none link-list d-block p-3 border-top " +
                                estadoSelect
                              }
                              onClick={() => setCasilla(index)}
                            >
                              <div className="row">
                                <div className="col-11">
                                  <div className="data-list-box">
                                    <ul className="list-unstyled">
                                      <li>
                                        <span className="data-list-box__title col-6 text-uppercase">
                                          SOLICITUD
                                        </span>
                                        <span className="data-list-box__text">
                                          {formato(e.fecha_solicitud)}
                                        </span>
                                      </li>
                                      <li>
                                        <span className="data-list-box__title col-6 text-uppercase">
                                          Solicitante
                                        </span>
                                        <span className="data-list-box__text">
                                          {e.nombre}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-1 d-flex align-items-center">
                                  <i
                                    className="fa fa-chevron-right color-primary fa-lg"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </a>
                          );
                        } else {
                          return (
                            <a
                              className={
                                "text-decoration-none link-list d-block p-3 border-top "
                              }
                              onClick={() => setCasilla(index)}
                            >
                              <div className="row">
                                <div className="col-11">
                                  <div className="data-list-box">
                                    <ul className="list-unstyled">
                                      <li>
                                        <span className="data-list-box__title col-6 text-uppercase">
                                          SOLICITUD
                                        </span>
                                        <span className="data-list-box__text">
                                          {formato(e.fecha_solicitud)}
                                        </span>
                                      </li>
                                      <li>
                                        <span className="data-list-box__title col-6 text-uppercase">
                                          Solicitante
                                        </span>
                                        <span className="data-list-box__text">
                                          {e.nombre}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-1 d-flex align-items-center">
                                  <i
                                    className="fa fa-chevron-right color-primary fa-lg"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </a>
                          );
                        }
                      })
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            <div className="col-12 col-xl-5 d-flex mt-4 mt-xl-0">
              <div className="border border-primary rounded-4 p-4 w-100">
                {primerRegistro ? (
                  <>
                    <h3 className="h5">{primerRegistro.nombre}</h3>
                    <div className="data-list-box">
                      <ul className="data-list-section--item list-unstyled">
                        <li>
                          <span className="data-list-box__title col-6 text-uppercase">
                            Fecha Permiso
                          </span>
                          <span className="data-list-box__text">
                            {formato(primerRegistro.fecha_permiso)}
                          </span>
                        </li>
                        <li>
                          <span className="data-list-box__title col-6 text-uppercase">
                            Jornada
                          </span>
                          <span className="data-list-box__text">
                            {primerRegistro.jornada}
                          </span>
                        </li>
                        <li>
                          <span className="data-list-box__title col-6 text-uppercase">
                            Beneficio
                          </span>
                          <span className="data-list-box__text">
                            {primerRegistro.beneficio}
                          </span>
                        </li>
                        <li>
                          <span className="data-list-box__title col-6 text-uppercase">
                            Estado
                          </span>
                          {primerRegistro.estado === "A" ? (
                            <span className="data-list-box__text">
                              <i
                                className="fa fa-check-circle-o fa-fw me-2 color-primary"
                                aria-hidden="true"
                              ></i>
                              {primerRegistro.estado_descr}
                            </span>
                          ) : (
                            <span className="data-list-box__text">
                              {primerRegistro.estado_descr}
                            </span>
                          )}
                        </li>
                      </ul>
                    </div>
                    {primerRegistro.estado === "P" ? (
                      <div className="row mt-5">
                        <div className="d-grid col-6">
                          <button
                            type="button"
                            onClick={() => {
                              anularRegistro();
                            }}
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#rechazar"
                          >
                            Rechazar{" "}
                            <i
                              className="fa fa-chevron-right fa-fw fa-xs"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </div>
                        <div className="d-grid col-6">
                          <button
                            type="button"
                            onClick={() => {
                              aprobarRegistro();
                            }}
                            className="btn btn-degradado"
                            data-bs-toggle="modal"
                            data-bs-target="#aprobar"
                          >
                            Aprobar{" "}
                            <i
                              className="fa fa-chevron-right fa-fw fa-xs"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="row mt-5 justify-content-center">
                        <div className="d-grid col-6">
                          <button
                            type="button"
                            onClick={() => {
                              anularRegistro();
                            }}
                            className="btn btn-degradado"
                            data-bs-toggle="modal"
                            data-bs-target="#anular"
                          >
                            Anular{" "}
                            <i
                              className="fa fa-chevron-right fa-fw fa-xs"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                ) : dataEquipo === null ? (
                  <>
                   <div className="modal-body text-center py-4">
                      <span className="spinner-border spinner-border-sm" />
                      <p> Cargando información... </p>
                  </div>
                  </>
                ) : equipo && equipo.length === 0 ? (
                  <>
                   <p>No se ha encontrado información.</p>
                  </>
                ): <></>}
              </div>
            </div>
          </div>

          <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
            {dataEquipo === null ? (
                <>
                  <div className="modal-body text-center py-4">
                    <span className="spinner-border spinner-border-sm" />
                    <p> Cargando información... </p>
                </div>
                </>
              ): equipo ? (
              equipo.length === 0 ? (
                <h4>No se ha encontrado información.</h4>
              ) : (
                equipo.map((e, index) => {
                  if (index === 0) {
                    return (
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="accordion">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#acordeon-" + index}
                            aria-expanded="true"
                            aria-controls={"acordeon-" + index}
                          >
                            <strong className="text-uppercase">Fecha Permiso</strong>{" "}
                            {formato(e.fecha_permiso)}
                          </button>
                        </h2>
                        <div
                          id={"acordeon-" + index}
                          className="accordion-collapse collapse show"
                          aria-labelledby="accordion"
                        >
                          <div className="accordion-body p-0">
                            <div className="data-list-box data-list-box-even text-uppercase">
                              <ul className="data-list-section--item list-unstyled">
                                <li>
                                  <span className="data-list-box__title">
                                    Solicitante
                                  </span>
                                  <span className="data-list-box__text">
                                    {e.nombre}
                                  </span>
                                </li>
                                <li>
                                  <span className="data-list-box__title">
                                    Fecha Solicitud
                                  </span>
                                  <span className="data-list-box__text">
                                    {formato(e.fecha_solicitud)}
                                  </span>
                                </li>
                                <li>
                                  <span className="data-list-box__title">
                                    Beneficio
                                  </span>
                                  <span className="data-list-box__text">
                                    {e.beneficio}
                                  </span>
                                </li>
                                <li>
                                  <span className="data-list-box__title">
                                    Estado
                                  </span>
                                  {e.estado === "A" ? (
                                    <span className="data-list-box__text">
                                      <i
                                        className="fa fa-check-circle-o fa-fw me-2 color-primary"
                                        aria-hidden="true"
                                      ></i>
                                      {e.estado_descr}
                                    </span>
                                  ) : (
                                    <span className="data-list-box__text">
                                      {e.estado_descr}
                                    </span>
                                  )}
                                </li>
                              </ul>
                              <div className="row my-4">
                                {e.estado === "P" ? (
                                  <div className="d-grid gap-3">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        aprobarRegistro();
                                      }}
                                      className="btn btn-degradado"
                                      data-bs-toggle="modal"
                                      data-bs-target="#apropbar"
                                    >
                                      Aprobar{" "}
                                      <i
                                        className="fa fa-chevron-right fa-fw fa-xs"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        anularRegistro();
                                      }}
                                      className="btn btn-primary"
                                    >
                                      Rechazar{" "}
                                      <i
                                        className="fa fa-chevron-right fa-fw fa-xs"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                ) : (
                                  <div className="d-grid gap-3">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        anularRegistro();
                                      }}
                                      className="btn btn-degradado"
                                      data-bs-toggle="modal"
                                      data-bs-target="#anular"
                                    >
                                      Anular{" "}
                                      <i
                                        className="fa fa-chevron-right fa-fw fa-xs"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="accordion">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#acordeon-" + index}
                            aria-expanded="false"
                            aria-controls={"acordeon-" + index}
                          >
                            <strong className="text-uppercase">Fecha</strong>{" "}
                            {formato(e.fecha_permiso)}
                          </button>
                        </h2>
                        <div
                          id={"acordeon-" + index}
                          className="accordion-collapse collapse "
                          aria-labelledby="accordion"
                        >
                          <div className="accordion-body p-0">
                            <div className="data-list-box data-list-box-even text-uppercase">
                              <ul className="data-list-section--item list-unstyled">
                                <li>
                                  <span className="data-list-box__title">
                                    Solicitante
                                  </span>
                                  <span className="data-list-box__text">
                                    {e.nombre}
                                  </span>
                                </li>
                                <li>
                                  <span className="data-list-box__title">
                                    Solicitud
                                  </span>
                                  <span className="data-list-box__text">
                                    {formato(e.fecha_solicitud)}
                                  </span>
                                </li>
                                <li>
                                  <span className="data-list-box__title">
                                    Beneficio
                                  </span>
                                  <span className="data-list-box__text">
                                    {e.beneficio}
                                  </span>
                                </li>
                                <li>
                                  <span className="data-list-box__title">
                                    Estado
                                  </span>
                                  {primerRegistro.estado === "A" ? (
                                    <span className="data-list-box__text">
                                      <i
                                        className="fa fa-check-circle-o fa-fw me-2 color-primary"
                                        aria-hidden="true"
                                      ></i>
                                      {e.estado_descr}
                                    </span>
                                  ) : (
                                    <span className="data-list-box__text">
                                      {e.estado_descr}
                                    </span>
                                  )}
                                </li>
                              </ul>
                              <div className="row my-4">
                                {primerRegistro.estado === "P" ? (
                                  <div className="d-grid gap-3">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        aprobarRegistro();
                                      }}
                                      className="btn btn-degradado"
                                      data-bs-toggle="modal"
                                      data-bs-target="#apropbar"
                                    >
                                      Aprobar{" "}
                                      <i
                                        className="fa fa-chevron-right fa-fw fa-xs"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        anularRegistro();
                                      }}
                                      className="btn btn-primary"
                                    >
                                      Rechazar{" "}
                                      <i
                                        className="fa fa-chevron-right fa-fw fa-xs"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                ) : (
                                  <div className="d-grid gap-3">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        anularRegistro();
                                      }}
                                      className="btn btn-degradado"
                                      data-bs-toggle="modal"
                                      data-bs-target="#anular"
                                    >
                                      Anular{" "}
                                      <i
                                        className="fa fa-chevron-right fa-fw fa-xs"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })
              )
            ) : (
              <></>
            )}
          </div>
        </div>
        {cargando ? (
          <>
            <div className="col">
              <div
                className="modal fade show"
                id="foto-credencial"
                tabIndex={"-1"}
                aria-labelledby="enviar-por-correo"
                aria-modal="true"
                role="dialog"
                style={{ display: "block", textAlign: "left" }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header border-0 pb-0"></div>
                    <div className="modal-body text-center py-4">
                      <i
                        className="fa fa-download color-orange fa-3x mb-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        aria-hidden="true"
                      ></i>
                      <h2 className="h5">Enviando solicitud...</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {respuestaSolicitudModal ? (
          <>
            <div className="col">
              <div
                className="modal fade show"
                id="foto-credencial"
                tabIndex={"-1"}
                aria-labelledby="enviar-por-correo"
                aria-modal="true"
                role="dialog"
                style={{ display: "block", textAlign: "left" }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                      <button
                        type="submit"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        id="enviar"
                        onClick={(e) => handleClose(e)}
                      ></button>
                    </div>
                    <div className="modal-body text-center py-4">
                      <i
                        className={`fa ${respuestaSolicitudValid === '1' ? 'fa-check-circle-o': 'fa-exclamation-triangle'} color-orange fa-2x`}
                        aria-hidden="true"
                      ></i>
                      <h2 className="h5">Solicitudes equipo</h2>
                      <p id="mensajeStatus"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {errorBeneficios ? (
          <>
            <div className="col">
              <div
                className="modal fade show"
                id="foto-credencial"
                tabIndex={"-1"}
                aria-labelledby="enviar-por-correo"
                aria-modal="true"
                role="dialog"
                style={{ display: "block", textAlign: "left" }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                      <button
                        type="submit"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        id="enviar"
                        onClick={(e) => handleClose(e)}
                      ></button>
                    </div>
                    <div className="modal-body text-center py-4">
                      <i
                        className="fa fa-download color-orange fa-3x mb-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        aria-hidden="true"
                      ></i>
                      <h2 className="h5">Solicitudes equipo</h2>
                      <p>
                        Se ha producido un error de comunicación, vuelva a
                        intentarlo más tarde.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </section>
    </>
  );
};
export default TiempoHistorial;
