import Auth from "@aws-amplify/auth";
import { readerTaskEither as RTE } from "fp-ts";
import { urlsAws } from "../../../../../resources/foo/api-endpoints";
import { HttpClientEnv, HttpError } from "../../../../../services/apiServices/interfaces";
import { getJson } from "../../../../../services/apiServices/httpClient";
import { GetPhotosResponse, getPhotosResponseC, GetPhotosRequest, PostWarningLetterResponse, getWarningLetterResponseCodec, GetWarningLetterResponse, GetWarningLetterFileRequest, getWarningLetterFileResponseCodec, GetWarningLetterFileResponse, postWarningLetterResponseCodec, PostWarningLetterRequest } from "../interfaces";
import { serialize } from "../../../../../services/utiles";

export const getWarningLetter = async (params: string) => {
    const authorization = await Auth.currentSession().then((auth) => auth.getIdToken().getJwtToken());
    const accessToken = await Auth.currentSession().then((auth) => auth.getAccessToken().getJwtToken());
    const requestOptions: RequestInit = {
        method: "GET",
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: authorization, AccessToken: accessToken },
    };

    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpError,
        GetWarningLetterResponse
    > = getJson(urlsAws.getWarningLetter, params, requestOptions, getWarningLetterResponseCodec.decode)

    return response;
};

export const postWarningLetter = async (body: PostWarningLetterRequest) => {
    const authorization = await Auth.currentSession().then((auth) => auth.getIdToken().getJwtToken());
    const accessToken = await Auth.currentSession().then((auth) => auth.getAccessToken().getJwtToken());
    let serialized = serialize(body.request, { indices: true });
    const requestOptions: RequestInit = {
        method: "POST",
        headers: { Authorization: authorization, AccessToken: accessToken },
        body: serialized,
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpError,
        PostWarningLetterResponse
    > = getJson(urlsAws.postWarningLetter, "", requestOptions, postWarningLetterResponseCodec.decode)

    return response;
};

export const getWarningLetterFile = async (body: GetWarningLetterFileRequest) => {
    const authorization = await Auth.currentSession().then((auth) => auth.getIdToken().getJwtToken());
    const accessToken = await Auth.currentSession().then((auth) => auth.getAccessToken().getJwtToken());
    const requestOptions: RequestInit = {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: authorization, AccessToken: accessToken },
        body: JSON.stringify(body),
    };

    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpError,
        GetWarningLetterFileResponse
    > = getJson(urlsAws.getWarningLetterFile, "", requestOptions, getWarningLetterFileResponseCodec.decode)

    return response;
};