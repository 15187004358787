import React, { Children, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Conflicts, Options } from "../../../utilities/interfaces";
import ModalBody from "./modalBody";
import { conflictsForm } from '../constants/constants';
import { validaRut } from "../../../../../services/utiles";

interface Props {
    conflictModal: Conflicts | null;
    setConflictModal: React.Dispatch<React.SetStateAction<Conflicts | null>>;
    setConflictsList: React.Dispatch<React.SetStateAction<Array<Conflicts> | null>>;
    options: Map<string, Array<Options>>;
}

const ConflictModal: React.FC<Props> = ({ conflictModal, setConflictModal, setConflictsList, options }) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        reset,
        setError,
    } = useForm<Conflicts>({
        defaultValues: {
            ...conflictModal as Conflicts,
        },
        shouldUnregister: false,
    });
    
    const confVal = watch("conflictValue");
    const [fields, setFields] = useState([...conflictsForm]);
    
    useEffect(() => {
        if (confVal !== "") {
            setValue("relationshipValue", conflictModal?.relationshipValue as string);
            setValue("rut", conflictModal?.rut as string);
            setValue("name", conflictModal?.name as string);
            setValue("obs", conflictModal?.obs as string);
            setValue("index", conflictModal?.index as string);
            if (confVal === "006") {
                setFields((oldFields) => {
                    return oldFields.filter((f) => f.target !== "rut" && f.target !== "relationshipValue" && f.target !== "name");
                });
            } else {
                setFields([...conflictsForm]);
            }
        }
    }, [confVal]);

    const formSubmitHandler: SubmitHandler<Conflicts> = (data: Conflicts) => {
        if (navigator.onLine) {
            if (data?.rut === "" || (data.rut !== undefined && data.rut !== "" && validaRut(data.rut))) {
                setConflictsList((prevConflictsList) => {
                    if (prevConflictsList === null) {
                        return [{ ...data }];
                    } else {
                        let isUpdated = false;
                        let updated = prevConflictsList.map((c) => {
                            if (c.index === data.index) {
                                isUpdated = true;
                                return { ...data };
                            } else {
                                return { ...c };
                            }
                        });
                        if (isUpdated) {
                            return updated;
                        } else {
                            return [...updated, { ...data }];
                        }
                    }
                });
                reset();
                document.getElementById("closeConflictModal")?.click();
                setConflictModal(null);
            } else {
                setError("rut", { type: "pattern", message: "EL rut ingresado no es válido" });
            }
        } else {
            document.getElementById("offline")?.click();
        }
    };

    return (
        <>
            <button hidden id={"openConflictModal"} data-bs-toggle="modal" data-bs-target={"#conflictModal"} data-bs-backdrop="static" />
            <div className="modal fade" id={"conflictModal"} tabIndex={-1} aria-labelledby="conflictModal" data-bs-backdrop="static" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        {conflictModal !== null ?
                            <form className="needs-validation" onSubmit={handleSubmit(formSubmitHandler)}>
                                <div className="modal-header border-0 pb-0">
                                    <button id="closeConflictModal" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setConflictModal(null)}/>
                                </div>
                                <ModalBody confVal={confVal} conflictsForm={fields} register={register} errors={errors} options={options} />
                                <div className="modal-footer border-0 pt-0">
                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => setConflictModal(null)}>Cancelar</button>
                                    <button type="submit" className="btn btn-degradado" data-bs-toggle="modal">Crear <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"/></button>
                                </div>
                            </form>
                        : <></>}
                    </div>
                </div>
            </div>
        </>
    )
};

export default ConflictModal;