import { useEffect, useState } from "react";
import DatosPersonalesServices from "../../services/datos personales col/datos-personales-services";
import DetalleDatosPersonales from "./detalle-datos-personales";
import InformacionContacto from "./informacion-contacto";
import ModificarDatosPersonales from "./modificar-datos-personales";
import styles from "../../resources/css/style-COL.module.css";
import "./datos-personales.css";
import { Link } from "react-router-dom";

export default function DatosPersonalesPE() {
  const [displayDetail, setDisplayDetail] = useState(true);
  const [displayContact, setDisplayContact] = useState(false);
  const [displayEdit, setDisplayEdit] = useState(false);
  const [info, setInfo] = useState({});
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [noData, setNoData] = useState(false);
  const [bloodType, setBloodType] = useState([]);
  const [education, setEducation] = useState([]);
  const [genre, setGenre] = useState([]);
  const [idioma, setIdioma] = useState([]);
  const [marStatus, setMarStatus] = useState([]);
  const [nacionality, setNacionality] = useState([]);
  const [countries, setCountries] = useState([]);

  const changeDisplay = (display) => {
    setDisplayEdit(display);
    setDisplayDetail(!display);
  };

  const reloadPersonalData = () => {
    setDisplayDetail(true);
    setDisplayContact(false);
    setDisplayEdit(false);
    DatosPersonalesServices.getPersonalData(setInfo, setLoadingInfo, setNoData);
  };

  useEffect(() => {
    DatosPersonalesServices.getPersonalData(setInfo, setLoadingInfo, setNoData);
    DatosPersonalesServices.getBloodType(setBloodType);
    DatosPersonalesServices.getEducation(setEducation);
    DatosPersonalesServices.getGenre(setGenre);
    DatosPersonalesServices.getIdioma(setIdioma);
    DatosPersonalesServices.getMarStatus(setMarStatus);
    DatosPersonalesServices.getNacionality(setNacionality);
    DatosPersonalesServices.GetCountries(setCountries);
  }, []);
  return (
    <div>
      {/* <!-- inicio breadcrumbs -->   */}
      <nav
        className={`${styles["breadcrumbs"]} ${styles["d-none"]} ${styles["d-lg-block"]}`}
        aria-label="breadcrumb"
      >
        <ol className={styles["breadcrumb"]}>
          <li className={styles["breadcrumb-item"]}>
            <Link to="/inicio">
              <i className="fa fa-home" aria-hidden="true"></i> Inicio
            </Link>
          </li>
          <li className={styles["breadcrumb-item"]}>
            Actualización de Información
          </li>
          <li className={styles["breadcrumb-item"]}>Información Personal</li>
          {displayDetail && (
            <li
              className={`${styles["breadcrumb-item"]} ${styles["active"]}`}
              aria-current="page"
            >
              Datos Personales
            </li>
          )}
          {displayEdit && (
            <>
              <li className={styles["breadcrumb-item"]}>
                <i className={styles["pointer"]}>Datos Personales</i>
              </li>
              <li
                className={`${styles["breadcrumb-item"]} ${styles["active"]}`}
                aria-current="page"
              >
                Corrección de Datos Personales
              </li>
            </>
          )}
          {displayContact && (
            <li
              className={`${styles["breadcrumb-item"]} ${styles["active"]}`}
              aria-current="page"
            >
              Información de Contacto
            </li>
          )}
        </ol>
      </nav>
      {/* <!-- fin breadcrumbs --> */}

      {/* <!-- inicio Información Personal --> */}
      <section className={styles["informacion-personal"]}>
        <h1>Información Personal</h1>
        {noData ? (
          <div
            className={`${styles["alert"]} ${styles["alert-info"]} ${styles["alert-dismissible"]} ${styles["fade"]} ${styles["show"]} ${styles["text-center"]}`}
            role="alert"
          >
            No se encontraron datos asociados
          </div>
        ) : (
          <>
            {/* <!-- Botones Escritorio --> */}
            <div
              className={`${styles["d-none"]} ${styles["d-md-block"]} ${styles["my-4"]}`}
            >
              <div className={styles["row"]}>
                <div className={`${styles["col"]} ${styles["mb-4"]}`}>
                  <button
                    onClick={() => {
                      setDisplayDetail(true);
                      setDisplayContact(false);
                      setDisplayEdit(false);
                    }}
                    className={` ${styles["btn"]} ${styles["btn-xl"]} ${
                      displayDetail || displayEdit
                        ? styles["btn-degradado-2"]
                        : null
                    } ${styles["text-uppercase"]}`}
                  >
                    <span className={styles["align-self-center"]}>
                      Datos Personales
                    </span>
                    <i
                      className={`${styles["fa"]} ${styles["fa-chevron-right"]}`}
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
                <div className={`${styles["col"]} ${styles["mb-4"]}`}>
                  <button
                    onClick={() => {
                      setDisplayDetail(false);
                      setDisplayContact(true);
                      setDisplayEdit(false);
                    }}
                    className={`${styles["btn"]} ${styles["btn-xl"]} ${
                      displayContact ? styles["btn-degradado-2"] : null
                    } ${styles["text-uppercase"]}`}
                  >
                    <span className={styles["align-self-center"]}>
                      Información de Contacto
                    </span>
                    <i
                      className={`${styles["fa"]} ${styles["fa-chevron-right"]}`}
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
            {/* <!-- fin Botones Escritorio --> */}

            {/* <!-- Botones Móvil (Carrusel) --> */}
            <div
              id="carousel-actualizacion-informacion"
              className="carousel carousel-dark slide carousel-control-inner d-md-none"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carousel-actualizacion-informacion"
                  data-bs-slide-to="0"
                  className={displayDetail ? "active" : null}
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carousel-actualizacion-informacion"
                  data-bs-slide-to="1"
                  className={displayContact ? "active" : null}
                  aria-label="Slide 2"
                ></button>
              </div>
              <div className="carousel-inner">
                {/* <!-- Botón 1 --> */}
                <div
                  className={`carousel-item p-2 ${
                    displayDetail || displayEdit ? "active" : null
                  }`}
                  data-bs-interval="10000"
                >
                  <div className="row">
                    <div className="col mb-4">
                      {/* <!-- clase btn-degradado-2 pinta el botón --> */}
                      <button
                        onClick={() => {
                          setDisplayDetail(true);
                          setDisplayContact(false);
                          setDisplayEdit(false);
                        }}
                        className={`btn btn-xl ${
                          displayDetail || displayEdit
                            ? "btn-degradado-2"
                            : null
                        } text-uppercase`}
                      >
                        <span className="align-self-center">
                          Datos Personales
                        </span>
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
                {/* <!-- Botón 2 --> */}
                <div
                  className={`carousel-item p-2 ${
                    displayContact ? "active" : null
                  }`}
                  data-bs-interval="10000"
                >
                  <div className="row">
                    <div className="col mb-4">
                      <button
                        onClick={() => {
                          setDisplayDetail(false);
                          setDisplayContact(true);
                          setDisplayEdit(false);
                        }}
                        className={`btn btn-xl ${
                          displayContact ? "btn-degradado-2" : null
                        } text-uppercase`}
                      >
                        <span className="align-self-center">
                          Información de Contacto
                        </span>
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
                {/* <!-- Botón 3 --> */}
                <div className={`carousel-item p-2`} data-bs-interval="10000">
                  <div className="row">
                    <div className="col mb-4">
                      <button
                        onClick={() => {
                          setDisplayDetail(false);
                          setDisplayContact(false);
                          setDisplayEdit(false);
                        }}
                        className={`btn btn-xl text-uppercase`}
                      >
                        <span className="align-self-center">Otros</span>
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- fin Botones Móvil (Carrusel) --> */}

            {displayDetail && (
              <DetalleDatosPersonales
                info={info}
                loadInfo={loadingInfo}
                countries={countries}
                bloodType={bloodType}
                education={education}
                genre={genre}
                idioma={idioma}
                marStatus={marStatus}
                nacionality={nacionality}
                changeDisplay={changeDisplay}
              />
            )}
            {displayEdit && (
              <ModificarDatosPersonales
                info={info}
                countries={countries}
                bloodType={bloodType}
                education={education}
                genre={genre}
                idioma={idioma}
                marStatus={marStatus}
                nacionality={nacionality}
                changeDisplay={changeDisplay}
                reloadPersonalData={reloadPersonalData}
              />
            )}
            {displayContact && (
              <InformacionContacto reloadPersonalData={reloadPersonalData} />
            )}
          </>
        )}
      </section>
    </div>
  );
}
