import { useEffect, useState } from "react";
import DatosPersonalesServices from "../../services/datos personales col/datos-personales-services";
import DetalleCargo from "./detalle-cargo";
import styles from "../../resources/css/style-COL.module.css";

export default function DetalleDatosPersonalesCOL({
  changeDisplay,
  countries,
  bloodType,
  education,
  genre,
  idioma,
  marStatus,
  info,
  loadInfo,
}) {
  const [states, setStates] = useState([]);
  const [loadingStates, setLoadingStates] = useState(false);
  const [counties, setCounties] = useState([]);
  const [loadingCounties, setLoadingCounties] = useState(false);

  useEffect(() => {
    if (info.BIRTHCOUNTRY === "PE") {
      setLoadingStates(true);
      DatosPersonalesServices.GetStates(
        info.BIRTHCOUNTRY,
        setStates,
        setLoadingStates
      );
    }
  }, [info.BIRTHCOUNTRY]);

  useEffect(() => {
    if (info.BIRTHCOUNTRY === "PE") {
      if (info.BIRTHSTATE !== "") {
        setLoadingCounties(true);
        DatosPersonalesServices.GetCounties(
          info.BIRTHCOUNTRY,
          info.BIRTHSTATE,
          setCounties,
          setLoadingCounties
        );
      }
    }
  }, [info.BIRTHSTATE]);

  useEffect(() => {}, [info]);

  return (
    <>
      <div className={`${styles["row"]} ${styles["mb-4"]}`}>
        <DetalleCargo />

        {loadInfo ? (
          <div
            className={`${styles["col-12"]} ${styles["col-xl-8"]} ${styles["d-flex"]}`}
          >
            <div
              className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}
            >
              <div className={styles["alert"]} role="alert">
                <i
                  className={`${styles["fa"]} ${styles["fa-spinner"]} ${styles["fa-spin"]} ${styles["color-orange"]} ${styles["fa-fw"]}`}
                  aria-hidden="true"
                ></i>
                <span className={styles["ms-2"]}>Cargando...</span>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={`${styles["col-12"]} ${styles["col-xl-8"]} ${styles["d-flex"]}`}
          >
            <div
              className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}
            >
              <div
                className={`${styles["tit-group"]} ${styles["d-flex"]} ${styles["justify-content-between"]}`}
              >
                <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>
                  Detalle
                </h2>
                <h4>
                  {" "}
                  {info.CN_APPROVAL_STATUS == "P"
                    ? "Pendiente de aprobacion"
                    : ""}
                </h4>
              </div>

              <p>
                Datos Obligatorios{" "}
                <span className={styles["color-red"]}>*</span>
              </p>
              <form>
                {/* <!-- Fila 1 --> */}
                <div className={styles["row"]}>
                  <div
                    className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                  >
                    <label className={styles["form-label"]}>
                      Número de Documento
                    </label>
                    <input
                      type="text"
                      className={styles["form-control"]}
                      defaultValue={info.EMPLID}
                      disabled
                    />
                  </div>
                  <div
                    className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                  >
                    <label className={styles["form-label"]}>Nombre</label>
                    <input
                      type="text"
                      className={styles["form-control"]}
                      defaultValue={info.NAME_DISPLAY}
                      disabled
                    />
                  </div>
                </div>
                {/* <!-- Fila 2 --> */}
                <div className={styles["row"]}>
                  <div
                    className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                  >
                    <label className={styles["form-label"]}>Estado Civil</label>
                    <div className={styles["input-group"]}>
                      <select className={styles["select-col"]} disabled>
                        {marStatus.map((m) => {
                          return (
                            <option
                              key={m.FIELDVALUE}
                              value={m.FIELDVALUE}
                              selected={m.FIELDVALUE === info.MAR_STATUS}
                            >
                              {m.XLATLONGNAME}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div
                    className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                  >
                    <label className={styles["form-label"]}>Género</label>
                    <div className={styles["input-group"]}>
                      <select className={styles["select-col"]} disabled>
                        {genre.map((g) => {
                          return (
                            <option
                              key={g.FIELDVALUE}
                              value={g.FIELDVALUE}
                              selected={g.FIELDVALUE === info.SEX}
                            >
                              {g.XLATLONGNAME}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                {/* <!-- Fila 3 --> */}
                <div className={styles["row"]}>
                  <div
                    className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                  >
                    <label className={styles["form-label"]}>
                      Tipo de Sangre
                    </label>
                    <div className={styles["input-group"]}>
                      <select className={styles["select-col"]} disabled>
                        {bloodType.map((b) => {
                          if (info.BLOOD_TYPE === "") {
                            return (
                              <option
                                key={b.FIELDVALUE}
                                value={b.FIELDVALUE}
                                selected={b.FIELDVALUE === "U"}
                              >
                                {b.XLATLONGNAME}
                              </option>
                            );
                          }
                          return (
                            <option
                              key={b.FIELDVALUE}
                              value={b.FIELDVALUE}
                              selected={b.FIELDVALUE === info.BLOOD_TYPE}
                            >
                              {b.XLATLONGNAME}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div
                    className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                  >
                    <label className={styles["form-label"]}>
                      Lenguaje Materno
                    </label>
                    <div className={styles["input-group"]}>
                      <select className={styles["select-col"]} disabled>
                        {idioma.map((l) => {
                          return (
                            <option
                              key={l.FIELDVALUE}
                              value={l.FIELDVALUE}
                              selected={l.FIELDVALUE === info.LANG_CD}
                            >
                              {l.XLATLONGNAME}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                {/* <!-- Fila 4 --> */}
                <div className={styles["row"]}>
                  <div
                    className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                  >
                    <label className={styles["form-label"]}>
                      País de Nacimiento
                    </label>
                    <div className={styles["input-group"]}>
                      <select className={styles["select-col"]} disabled>
                        {countries.map((c) => {
                          return (
                            <option
                              key={c.country}
                              value={c.country}
                              selected={info.BIRTHCOUNTRY === c.country}
                            >
                              {c.descr}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div
                    className={`${styles["col-12"]} ${styles["col-md-6"]} ${
                      styles["mb-3"]
                    } ${info.BIRTHCOUNTRY !== "PE" ? "d-none" : null}`}
                  >
                    <label className={styles["form-label"]}>
                      Departamento de Nacimiento
                    </label>
                    <div className={styles["input-group"]}>
                      {!loadingStates ? (
                        <select className={styles["select-col"]} disabled>
                          {states.map((s) => {
                            return (
                              <option
                                key={s.state}
                                value={s.state}
                                selected={info.BIRTHSTATE === s.state}
                              >
                                {s.descrshort}
                              </option>
                            );
                          })}
                        </select>
                      ) : (
                        <div>
                          <div
                            className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}
                          ></div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`${styles["col-12"]} ${styles["col-md-6"]} ${
                      styles["mb-3"]
                    } ${info.BIRTHCOUNTRY === "PE" ? "d-none" : null}`}
                  >
                    <label className={styles["form-label"]}>
                      Ciudad de Nacimiento
                    </label>
                    <input
                      type="text"
                      className={styles["form-control"]}
                      value={info.BIRTHPLACE}
                      disabled
                    />
                  </div>
                </div>
                {/* <!-- Fila 5 --> */}
                <div className={styles["row"]}>
                  <div
                    className={`${styles["col-12"]} ${styles["col-md-6"]} ${
                      styles["mb-3"]
                    } ${info.BIRTHCOUNTRY !== "PE" ? "d-none" : null}`}
                  >
                    <label className={styles["form-label"]}>
                      Ciudad de Nacimiento
                    </label>
                    {!loadingCounties ? (
                      <select className={styles["select-col"]} disabled>
                        {counties.map((c) => {
                          return (
                            <option
                              key={c.county}
                              value={c.county}
                              selected={info.COUNTY_CD === c.county}
                            >
                              {c.descr}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <div>
                        <div
                          className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}
                        ></div>
                      </div>
                    )}
                  </div>
                </div>
                {/* <!-- Fila 6 --> */}
                <div className={styles["row"]}>
                  {/* <div className={`col-12 col-md-6 mb-3 ${info.BIRTHCOUNTRY === "COL" ? "d-none" : null}`}>
                                <label className="form-label">Ciudad de Nacimiento</label>
                                <input type="text" className="form-control" value={info.COUNTY_CD} disabled />
                            </div> */}
                  <div
                    className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                  >
                    <label className={styles["form-label"]}>
                      Fecha de Nacimiento
                    </label>
                    <div className={styles["input-group"]}>
                      <input
                        className={styles["form-control"]}
                        type="date"
                        value={info.BIRTHDATE}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                {/* <!-- Fila 7 --> */}
                <div className={styles["row"]}>
                  <div
                    className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                  >
                    <label className={styles["form-label"]}>
                      Nivel Educativo Titulado
                    </label>
                    <div className={styles["input-group"]}>
                      <select className={styles["select-col"]} disabled>
                        {education.map((b) => {
                          return (
                            <option
                              key={b.id}
                              value={b.FIELDVALUE}
                              selected={b.FIELDVALUE === info.HIGHEST_ED_LVL}
                            >
                              {b.XLATLONGNAME}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                {/* <!-- fin filas --> */}
              </form>
            </div>
          </div>
        )}
      </div>
      <div
        className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mb-4"]}`}
      >
        <div className={`${styles["col-6"]} ${styles["col-lg-auto"]}`}>
          <button
            type="submit"
            disabled={info.CN_APPROVAL_STATUS == "P" ? true : false}
            className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["w-100"]}`}
            onClick={() => changeDisplay(true)}
          >
            Solicitar corrección de datos{" "}
            <i
              className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
              aria-hidden="true"
            ></i>
          </button>
        </div>
      </div>
    </>
  );
}
