import React from 'react'
import { Grid } from '@mui/material'
import { LoadingProps } from '../interfaces'

const Loading = ({statusMessage}: LoadingProps) => {
  return (
    <Grid item xs={12}>
      <p>{statusMessage}</p>
    </Grid>
  )
}

export default Loading