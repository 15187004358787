import React, { useEffect, useState } from "react";
import EthicalCodeServices from "../../services/codigo-etica/service";
import Form from "./form";
import errorMessage from "../SharedComponents/error-message";
import styles from "../../resources/css/style-COL.module.css";
import { Link } from 'react-router-dom'
import { Auth } from "aws-amplify";

const CodigoEticaEmpleado = () => {
  const [ethicalcode, setEthicalCode] = useState({});
  const [isLoadingEthicalCode, setIsLoadingEthicalCode] = useState(false);
  const [isLoadingEthicalCodeError, setIsLoadingEthicalCodeError] =
    useState(false);

  const [pdf, setPdf] = useState(null);
  const [isLoadingPdf, setIsLoadigPdf] = useState(false);
  const [isLoadingPdfError, setIsLoadigPdfError] = useState(false);

  const [isLoadingPdfSet, setIsLoadigPdfSet] = useState(false);

  const [pais, setPais] = useState(null)
 
  const getCountry = async () => {
    const country = await Auth.currentUserInfo()
                .then(user => {
                    if (user.attributes['custom:cd_pais'] && user.attributes['custom:cd_pais'] !== undefined && user.attributes['custom:cd_pais'] !== null && user.attributes['custom:cd_pais'] !== "") {
                        return user.attributes['custom:cd_pais'];
                    }
                }).catch(err => {
                    console.log(err);
                    return null;
                });
    setPais(country)
  }

  useEffect(() => {
    getCountry();
  }, []);

  const getEhicalCode = async () => {
    try {
      setIsLoadingEthicalCode(true);

      const response = await EthicalCodeServices.getEhicalCode();

      if (
        response !== null &&
        response !== undefined &&
        (response?.statusCode === 200 || response?.status === 200)
      ) {
        setEthicalCode(response?.data);
      } else {
        errorMessage(
          null,
          response?.message === null || response?.message === undefined
            ? ""
            : response?.message
        );
        setIsLoadingEthicalCodeError(true);
      }

      setIsLoadingEthicalCode(false);
    } catch (error) {
      setIsLoadingEthicalCode(false);
    }
  };

  const getPdf = async () => {
    try {
      setIsLoadigPdf(true);

      // const existingPdfBytes =
      //   await CertificadoIngresosRetencionesServices.generateFilledPdf(1);

      let existingPdfBytes = null
      // Call to service
      if (pais != null){
        switch (pais) {
        case "UY":
          existingPdfBytes = await EthicalCodeServices.downloadPdf(pais);
          break
        default:
          existingPdfBytes = await EthicalCodeServices.downloadPdf("");
          break
      }}

      var blob1 = new Blob([existingPdfBytes], { type: "application/pdf" });
      const url = URL.createObjectURL(blob1);
      setPdf(url);

      setIsLoadigPdf(false);
    } catch (error) {
      errorMessage(null, "");
      setIsLoadigPdfError(true);
      setIsLoadigPdf(false);
    }
  };

  const reLoad = () => {
    getEhicalCode();
    getPdf();
  };

  useEffect(() => {
    getEhicalCode();
    getPdf();
  }, [pais]);

  useEffect(() => {
    if (pdf !== null) setIsLoadigPdfSet(true);
  }, [pdf]);

  return (
    <>
      {/* <!-- inicio breadcrumbs -->   */}
      <nav
        className={
          styles["breadcrumbs"] +
          " " +
          styles["d-none"] +
          " " +
          styles["d-lg-block"]
        }
        aria-label="breadcrumb"
      >
        <ol className={styles.breadcrumb}>
          <li className={styles["breadcrumb-item"]}>
            <Link to="/inicio">
              <i className={`${styles["color-orange"]} ${styles["fa"]} ${styles["fa-home"]}`} aria-hidden="true"></i>
              Inicio
            </Link>
          </li>
          <li className={styles["breadcrumb-item"]}>
            Actualización Código de Ética
          </li>
          <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">
            Código de Ética
          </li>
        </ol>
      </nav>
      {/* <!-- fin breadcrumbs --> */}

      {/* <!-- inicio código de ética --> */}
      <section className={styles["codigo-de-etica"]}>
        <h1>Código de Ética</h1>

        {isLoadingEthicalCode || isLoadingPdf ? (
          <div data-testid="loading" className={styles["full-spinner"]}>
            <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
          </div>
        ) : null}

        {!isLoadingEthicalCode &&
          !isLoadingEthicalCodeError &&
          !isLoadingPdf &&
          !isLoadingPdfError &&
          isLoadingPdfSet ? (
          <Form ethicalcode={ethicalcode} pdf={pdf} reLoad={reLoad} />
        ) : null}
      </section>
      {/* <!-- fin código de ética --> */}
    </>
  );
};

export default CodigoEticaEmpleado;
