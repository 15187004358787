import React,{ useState, useEffect } from "react";
import PropTypes from "prop-types";
import PanelAdministracionService from "../services/panel-administracion-service";
import { Link } from "react-router-dom";


const EstadoModuloBANDERA = ({ pais, unidad, setUnidad, modulo, setStatus }) => {
    const [banderaState, setBanderaState] = useState([]);
    const [botonGuardar, setBotonGuardar] = useState(true);
    useEffect(() => {
        if (unidad && banderaState.length === 0) {
            PanelAdministracionService.getState(setBanderaState, unidad.codigo, pais.codigo, modulo.codigo)
        }
    }, [unidad])
    const handleChange = (e) => {
        if (botonGuardar) {
            setBotonGuardar(false);
        }
        let temp = [...banderaState];
        temp.forEach(item => {
            if (item.codigo === e.target.name) {
                item.estado = e.target.checked ? 1 : 0;
            }
        });
        setBanderaState(temp);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const bandera = banderaState.map(item => item.codigo)
        const estados = banderaState.map(item => item.estado)
        const request = {
            request: {
                pais: pais.codigo,
                estado: estados,
                modulo: modulo.codigo,
                unidad: unidad.codigo,
                bandera: bandera
            }
        }
        PanelAdministracionService.setState(request, setStatus);
    }
    return (
        <form id="formban" className="needs-validation" noValidate="novalidate" onSubmit={(e) => handleSubmit(e)}>
            {/* <!-- inicio Formulario --> */}
            <div className="box mb-4">
                <h2 className="h4 tit-section">Administrar Unidades Organizativas</h2>

                <div className="data-list-box data-list-box-form data-list-box-align-middle mb-4">
                    <ul className="list-column-1 list-unstyled">
                        <li>
                            <span className="data-list-box__title text-uppercase">País</span>
                            <span className="data-list-box__text">
                                <input className="form-control" type="text" placeholder={pais.nombre} disabled />
                            </span>
                        </li>
                        <li>
                            <span className="data-list-box__title text-uppercase">Unidad de negocio</span>
                            <span className="data-list-box__text">
                                <input className="form-control" type="text" placeholder={unidad.nombre} disabled />
                            </span>
                        </li>
                    </ul>
                </div>

                {/* <!-- Inicio Tabla // Desktop y Mobile --> */}
                <table className="table table-even table-section table-borderless table-th-border align-middle table-color-reset d-none d-md-table">
                    <thead className="text-uppercase">
                        <tr>
                            <th className="col-5">Bandera</th>
                            <th className="col-4">Estatus</th>
                            <th className="col-3">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            banderaState.length > 0 &&
                            banderaState.map((item) => {
                                return (
                                    <tr>
                                        <td>
                                            {item.nombre}
                                        </td>
                                        <td>{item.estado === 1 ? "Activo" : item.estado === 0 ? "Inactivo" : ""}</td>
                                        <td>
                                            <input className="form-check-input border-primary" type="checkbox" name={item.codigo} onChange={(e) => handleChange(e)} defaultChecked={item.estado === 1} />
                                        </td>
                                    </tr>
                                )
                            })

                        }

                    </tbody>
                </table>

                {/* <!-- Inicio Lista // Mobile --> */}
                <div className="data-list-box data-list-box-border-primary d-block d-md-none">
                    {
                        banderaState.length > 0 &&
                        banderaState.map((item) => {
                            return (
                                <ul className="list-unstyled">
                                    <li>
                                        <span className="data-list-box__title">Bandera</span>
                                        <span className="data-list-box__text">{item.nombre}</span>
                                    </li>
                                    <li>
                                        <span className="data-list-box__title">Estatus</span>
                                        <span className="data-list-box__text">{item.estado === 1 ? "Activo" : item.estado === 0 ? "Inactivo" : ""}</span>
                                    </li>
                                    <li>
                                        <span className="data-list-box__title">Acciones</span>
                                        <span className="data-list-box__text"><input className="form-check-input border-primary" type="checkbox"
                                            name={item.codigo} onChange={(e) => handleChange(e)} defaultChecked={item.estado === 1} /></span>
                                    </li>
                                </ul>
                            )
                        })
                    }
                </div>
                {/* <!-- Fin Lista // Mobile --> */}
            </div>

            <div className="d-grid d-md-flex justify-content-md-between gap-2 flex-nowrap">
                {/* <!-- Botón guardar. Agregar clase disabled para desactivar botón --> */}
                <button type="submit" form="formban" className="btn btn-degradado order-md-last" data-bs-toggle="modal" data-bs-target="#success" disabled={botonGuardar}>Guardar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                <button type="button" onClick={() => setUnidad(false)} className="btn btn-link order-md-first">Volver</button>
            </div>

        </form>
    )
}

const EstadoModuloUNIDAD = ({ setUnidad, pais, setPais, modulo, setStatus }) => {
    const [unidadState, setUnidadState] = useState([]);
    const [botonGuardar, setBotonGuardar] = useState(true);
    useEffect(() => {
        if (pais && unidadState.length === 0) {
            PanelAdministracionService.getState(setUnidadState, "", pais.codigo, modulo.codigo)
        }
    }, [pais])
    const handleChange = (e) => {
        if (botonGuardar) {
            setBotonGuardar(false);
        }
        let temp = [...unidadState];
        temp.forEach(item => {
            if (item.codigo === e.target.name) {
                item.estado = e.target.checked ? 1 : 0;
            }
        });
        setUnidadState(temp);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const unidad = unidadState.map(item => item.codigo)
        const estados = unidadState.map(item => item.estado)
        const request = {
            request: {
                pais: pais.codigo,
                estado: estados,
                modulo: modulo.codigo,
                unidad: unidad
            }
        }
        PanelAdministracionService.setState(request, setStatus);
    }
    return (
        <form id="formun" className="needs-validation" noValidate="novalidate" onSubmit={(e) => handleSubmit(e)}>
            {/* <!-- inicio Formulario --> */}
            <div className="box mb-4">
                <h2 className="h4 tit-section">Administrar Unidades Organizativas</h2>

                <div className="data-list-box data-list-box-form data-list-box-align-middle mb-4">
                    <ul className="list-column-1 list-unstyled">
                        <li>
                            <span className="data-list-box__title text-uppercase">País</span>
                            <span className="data-list-box__text">
                                <input className="form-control" type="text" placeholder={pais.nombre} disabled />
                            </span>
                        </li>
                    </ul>
                </div>

                {/* <!-- Inicio Tabla // Desktop y Mobile --> */}
                <table className="table table-even table-section table-borderless table-th-border align-middle table-color-reset d-none d-md-table">
                    <thead className="text-uppercase">
                        <tr>
                            <th className="col-5">Unidad de negocio</th>
                            <th className="col-4">Estatus</th>
                            <th className="col-3">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            unidadState.length > 0 &&
                            unidadState.map((unidad, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <Link to="#" onClick={() => setUnidad(unidad)}><i className="fa fa-plus-square-o fa-fw fa-lg me-2" aria-hidden="true"></i></Link>
                                            {unidad.nombre}
                                        </td>
                                        <td>{unidad.estado === 1 ? "Activo" : unidad.estado === 0 ? "Inactivo" : "Activo para algunas banderas"}</td>
                                        <td>
                                            <input className="form-check-input border-primary" type="checkbox"
                                                defaultChecked={unidad.estado === 1}
                                                name={unidad.codigo}
                                                onChange={(e) => handleChange(e)} />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

                {/* <!-- Inicio Lista // Mobile --> */}
                <div className="data-list-box data-list-box-border-primary d-block d-md-none">
                    {
                        unidadState.length > 0 &&
                        unidadState.map((unidad, index) => {
                            return (
                                <ul className="list-unstyled" key={index}>
                                    <li>
                                        <span className="data-list-box__title">Unidad de negocio</span>
                                        <span className="data-list-box__text"><Link to="#" onClick={() => setUnidad(unidad)}><i className="fa fa-plus-square-o fa-fw fa-lg me-2" aria-hidden="true"></i></Link>{unidad.nombre}</span>
                                    </li>
                                    <li>
                                        <span className="data-list-box__title">Estatus</span>
                                        <span className="data-list-box__text">{unidad.estado === 1 ? "Activo" : unidad.estado === 0 ? "Inactivo" : "Activo para algunas banderas"}</span>
                                    </li>
                                    <li>
                                        <span className="data-list-box__title">Acciones</span>
                                        <span className="data-list-box__text"><input className="form-check-input border-primary" type="checkbox"
                                            defaultChecked={unidad.estado === 1}
                                            name={unidad.codigo}
                                            onChange={(e) => handleChange(e)} /></span>
                                    </li>
                                </ul>
                            )

                        })
                    }
                </div>
                {/* <!-- Fin Lista // Mobile --> */}
            </div>

            <div className="d-grid d-md-flex justify-content-md-between gap-2 flex-nowrap">
                {/* <!-- Botón guardar. Agregar clase disabled para desactivar botón --> */}
                <button type="submit" form="formun" className="btn btn-degradado order-md-last">Guardar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" disabled={botonGuardar}></i></button>
                <button type="button" onClick={() => setPais(false)} className="btn btn-link order-md-first">Volver</button>
            </div>

        </form>
    )
}

const EstadoModuloPAIS = ({ modulo, setModulo, setPais, setStatus }) => {

    const [paisState, setPaisState] = useState([]);
    const [showPAISList, setShowPAISList] = useState(modulo.estado === 2);
    const [botonGuardar, setBotonGuardar] = useState(true);
    useEffect(() => {
        if (modulo && paisState.length === 0) {
            PanelAdministracionService.getState(setPaisState, "", "", modulo.codigo);
        }
    }, [modulo])

    const handleChange = (e) => {
        if (botonGuardar) {
            setBotonGuardar(false);
        }
        if (showPAISList) {
            let temp = [...paisState];
            temp.forEach(item => {
                if (item.codigo === e.target.name) {
                    item.estado = e.target.checked ? 1 : 0;
                }
            });
            setPaisState(temp);
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        if (showPAISList) {
            const paises = paisState.map(item => item.codigo)
            const estados = paisState.map(item => item.estado)
            const request = {
                request: {
                    pais: paises,
                    estado: estados,
                    modulo: modulo.codigo
                }
            }
            PanelAdministracionService.setState(request, setStatus);
        }
        else {
            const value = document.querySelector('input[name="radiostate"]:checked').value;
            const request = {
                request: {
                    estado: [{
                        estado: value,
                        codigo: modulo.codigo
                    }]
                }
            }
            PanelAdministracionService.setState(request, setStatus);
        }

    }
    return (
        <>
            <div className="box mb-4">
                <h2 className="h4 tit-section">Estado del Servicio</h2>
                {/* <!-- Inicio Tabla // Desktop y Mobile --> */}
                <form id="formgen" onSubmit={(e) => handleSubmit(e)}>
                    <table className="table table-section table-borderless table-th-border align-middle table-color-reset">
                        <thead className="text-uppercase">
                            <tr>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <input className="form-check-input me-2 h5" type="radio" name="radiostate" value={1} onClick={() => setShowPAISList(false)}
                                        onChange={(e) => handleChange(e)}
                                        defaultChecked={modulo.estado === 1} />
                                    <label className="form-check-label mt-1" >Encendido para todos.</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input className="form-check-input me-2 h5" type="radio" name="radiostate" value={0} onClick={() => setShowPAISList(false)}
                                        onChange={(e) => handleChange(e)}
                                        defaultChecked={modulo.estado === 0} />
                                    <label className="form-check-label mt-1" >Apagado para todos.</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input className="form-check-input me-2 h5" type="radio" name="radiostate" value="nifunifa" onClick={() => setShowPAISList(true)}
                                        onChange={(e) => handleChange(e)}
                                        defaultChecked={modulo.estado === 2} />
                                    <label className="form-check-label mt-1" >Activado para algunas unidades organizativas.</label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
                {showPAISList &&
                    <>
                        {/* <!-- Administrar Unidades Organizativas --> */}
                        <h2 className="h4 tit-section mt-5">Administrar Unidades Organizativas</h2>
                        {/* <!-- Inicio Tabla // Desktop y Mobile --> */}
                        <form id="formdesk" onSubmit={(e) => handleSubmit(e)}>
                            <table className="table table-section table-borderless table-th-border align-middle table-color-reset d-none d-md-table">
                                <thead className="text-uppercase">
                                    <tr>
                                        <th className="col-3">País</th>
                                        <th className="col-2">Estatus</th>
                                        <th className="col-3 text-center">Acciones</th>
                                        <th className="col-4">Configuración actual</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        paisState.length > 0 &&
                                        paisState.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <Link to="#" onClick={(e) => {e.preventDefault();setPais(item)}}><i className="fa fa-plus-square-o fa-fw fa-lg me-2" aria-hidden="true"></i></Link>
                                                        {item.nombre}
                                                    </td>
                                                    <td>{item.estado === 1 ? "Activo" : item.estado === 0 ? "Inactivo" : ""}</td>
                                                    <td className="text-center">
                                                        <input className="form-check-input border-primary" type="checkbox"
                                                            name={item.codigo}
                                                            onChange={(e) => handleChange(e)}
                                                            defaultChecked={item.estado === 1} />
                                                    </td>
                                                    <td>{item.estado === 1 ? `Habilitado para todo ${item.nombre}` : item.estado === 0 ? `Deshabilitado para todo ${item.nombre}` : `Habilitado para algunas unidades`}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </form>
                        <form id="formmob" onSubmit={(e) => handleSubmit(e)}>
                            {/* <!-- Inicio Lista // Mobile --> */}
                            <div className="data-list-box data-list-box-border-primary d-block d-md-none">
                                {
                                    paisState.length > 0 &&
                                    paisState.map((item, index) => {
                                        return (
                                            <ul className="list-unstyled" key={index}>
                                                <li>
                                                    <span className="data-list-box__title">País</span>
                                                    <span className="data-list-box__text"><Link to="#" onClick={() => setPais(item)}><i className="fa fa-plus-square-o fa-fw fa-lg me-2" aria-hidden="true"></i></Link>{item.nombre}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Estatus</span>
                                                    <span className="data-list-box__text">{item.estado === 1 ? "Activo" : item.estado === 0 ? "Inactivo" : ""}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Acciones</span>
                                                    <span className="data-list-box__text">
                                                        <input className="form-check-input border-primary" type="checkbox"
                                                            name={item.codigo}
                                                            onChange={(e) => handleChange(e)}
                                                            defaultChecked={item.estado === 1} />
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Configuración actual</span>
                                                    <span className="data-list-box__text">{item.estado === 1 ? `Habilitado para todo ${item.nombre}` : item.estado === 0 ? `Deshabilitado para todo ${item.nombre}` : `Habilitado para algunas unidades`}</span>
                                                </li>
                                            </ul>
                                        )
                                    })
                                }
                            </div>
                        </form>
                    </>}
            </div>
            {
                showPAISList ?
                    <>
                        <div className="d-grid d-md-flex justify-content-md-between gap-2 flex-nowrap d-md-none d-md-table">
                            <button type="submit" className="btn btn-degradado order-md-last" form="formmob" disabled={botonGuardar}>Guardar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"  ></i></button>
                            <button type="button" className="btn btn-link order-md-first" onClick={() => setModulo(false)}>Volver</button>
                        </div>
                        <div className="d-grid d-md-flex justify-content-md-between gap-2 flex-nowrap d-none">
                            <button type="submit" className="btn btn-degradado order-md-last" form="formdesk" disabled={botonGuardar}>Guardar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                            <button type="button" className="btn btn-link order-md-first" onClick={() => setModulo(false)}>Volver</button>
                        </div>
                    </>
                    :
                    <div className="d-grid d-md-flex justify-content-md-between gap-2 flex-nowrap">
                        <button type="submit" form="formgen" disabled={botonGuardar} className="btn btn-degradado order-md-last">Guardar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
                        <button type="button" className="btn btn-link order-md-first" onClick={() => setModulo(false)}>Volver</button>
                    </div>
            }
        </>
    )
}

const Modulos = ({ setModulo, setStatus }) => {
    const [allModulesState, setAllModulesState] = useState([{
        estado: 0,
        codigo: "EMPTY",
    }]);
    const [botonGuardar, setBotonGuardar] = useState(true);
    useEffect(() => {
        if (allModulesState[0].codigo === "EMPTY") {
            const modulo = "all";
            PanelAdministracionService.getState(setAllModulesState, "", "", modulo);
        }
    }, [allModulesState]);
    const handleSubmit = (e) => {
        e.preventDefault();
        const estados = allModulesState.map(modulo => {
            return {
                codigo: modulo.codigo,
                estado: modulo.estado
            }
        });
        const request = {
            request: {
                estado: estados
            }
        }
        PanelAdministracionService.setState(request, setStatus);
    }
    return (
        <>
            {/* <!-- inicio Formulario --> */}
            <div className="box mb-4">
                <h2 className="h4 tit-section">Autoservicio Suit</h2>
                <p className="h5 fw-bold color-primary d-none d-md-block">Estado de las aplicaciones en todas las unidades de organización.</p>
                <p className="h5 fw-bold text-uppercase mb-3 d-block d-md-none">Servicios</p>
                <form id="formdesk" className="needs-validation" noValidate="novalidate" onSubmit={(e) => handleSubmit(e)}>
                    {/* <!-- Inicio Tabla // Desktop --> */}
                    <table className="table table-section table-borderless table-th-border align-middle table-color-reset d-none d-md-table">
                        <thead className="text-uppercase">
                            <tr>
                                <th className="col-6">Servicios</th>
                                <th className="col-6">Estado del servicio</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                allModulesState[0].codigo !== "EMPTY" && allModulesState.map((modulo, index) => {
                                    if(index !== 0){
                                    return (
                                        <tr>
                                            <td>
                                                <input
                                                    className="form-check-input border-primary me-2 h5"
                                                    type="checkbox"
                                                    defaultChecked={modulo.estado === 1}
                                                    name={modulo.codigo}
                                                    onChange={(e) => {
                                                        let temp = [...allModulesState];
                                                        temp.forEach(item => {
                                                            if (item.codigo === modulo.codigo) {
                                                                item.estado = e.target.checked ? 1 : 0;
                                                            }
                                                        });
                                                        setAllModulesState(temp);
                                                        if (botonGuardar) {
                                                            setBotonGuardar(false);
                                                        }
                                                    }}
                                                />
                                                <Link to="" className="form-check-label mt-1" htmlFor="check-1" onClick={() => setModulo(modulo)} style={{ cursor: "pointer" }}>
                                                    <i className="fa fa-newspaper-o fa-fw color-primary me-1" aria-hidden="true"></i> {modulo.nombre}
                                                </Link>
                                            </td>
                                            <td>{modulo.estado === 0 ? "Apagado para todos" : modulo.estado === 1 ? "Encendido para todos" : "Encendido para algunas unidades"}</td>
                                        </tr>
                                    )
                                    }
                                })
                            }
                        </tbody>
                    </table>
                </form>
                <form id="formmob" className="needs-validation" noValidate="novalidate" onSubmit={(e) => handleSubmit(e)}>
                    {/* <!-- Inicio lista // Mobile --> */}
                    <div className="data-list-box data-list-box-border-primary d-block d-md-none">
                        {
                            allModulesState[0].codigo !== "EMPTY" && allModulesState.map((modulo, index) => {
                                if(index !== 0){
                                return (
                                    <ul className="list-unstyled">
                                        <li>
                                            <input
                                                className="form-check-input border-primary me-2 h5"
                                                type="checkbox"
                                                defaultChecked={modulo.estado === 1}
                                                name={modulo.codigo}
                                                onChange={(e) => {
                                                    let temp = [...allModulesState];
                                                    temp.forEach(item => {
                                                        if (item.codigo === modulo.codigo) {
                                                            item.estado = e.target.checked ? 1 : 0;
                                                        }
                                                    });
                                                    setAllModulesState(temp);
                                                    if (botonGuardar) {
                                                        setBotonGuardar(false);
                                                    }
                                                }}
                                            />
                                            <Link to="" className="form-check-label mt-1 fw-bold" htmlFor="check-1" onClick={() => setModulo(modulo)} style={{ cursor: "pointer" }}>
                                                <i className="fa fa-newspaper-o fa-fw color-primary me-1" aria-hidden="true"></i> {modulo.nombre}
                                            </Link>
                                        </li>
                                        <li className="ps-4">
                                            <span className="data-list-box__title">Estado del servicio</span>
                                            <span className="data-list-box__text">{modulo.estado === 0 ? "Apagado para todos" : modulo.estado === 1 ? "Encendido para todos" : "Encendido para algunas unidades"}</span>
                                        </li>
                                    </ul>
                                )
                                }
                            })
                        }
                    </div>
                </form>
            </div>
            <div className="text-end d-grid d-md-block d-md-none d-md-table">
                {/* <!-- Botón guardar --> */}
                <button type="submit" form="formmob" className="btn btn-degradado" disabled={botonGuardar}>Guardar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
            </div>
            <div className="text-end d-grid d-md-block d-none">
                {/* <!-- Botón guardar --> */}
                <button type="submit" form="formdesk" className="btn btn-degradado" disabled={botonGuardar} >Guardar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></button>
            </div>
        </>
    )
}

const PanelAdministracion = () => {
    const [modulo, setModulo] = useState(false);
    const [pais, setPais] = useState(false);
    const [unidad, setUnidad] = useState(false);
    const [status, setStatus] = useState(false);
    return (
        <>
            {/* <!-- breadcrumbs --> */}
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active" aria-current="page">Administrador Autoservicio</li>
                </ol>
            </nav>
            {/* <!-- inicio Administrador autoservicio --> */}
            <section className="administrador-autoservicio">
                <h1>Administrador Autoservicio</h1>
                {
                    modulo ?
                        pais ?
                            unidad ?
                                <EstadoModuloBANDERA
                                    pais={pais}
                                    unidad={unidad}
                                    setUnidad={setUnidad}
                                    modulo={modulo}
                                    setStatus={setStatus}
                                />
                                :
                                <EstadoModuloUNIDAD
                                    setUnidad={setUnidad}
                                    pais={pais}
                                    setPais={setPais}
                                    modulo={modulo}
                                    setStatus={setStatus}
                                />
                            :
                            <EstadoModuloPAIS
                                setPais={setPais}
                                modulo={modulo}
                                setModulo={setModulo}
                                setStatus={setStatus}
                            />
                        :
                        <Modulos
                            setModulo={setModulo}
                            setStatus={setStatus}
                        />
                }
                <div className="modal fade show" id="status" tabIndex="-1" aria-labelledby="status" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header border-0 pb-0">
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body text-center py-4">
                                {
                                    status && status.status === 1 ?
                                        <>
                                            <i className="fa fa-check-circle-o color-orange fa-3x" aria-hidden="true"></i>
                                            <h2 className="h5">¡Éxito!</h2>
                                            <h2 className="h5">{status && status.message}</h2>
                                        </>
                                        :
                                        <>
                                            <i className="fa fa-times-circle-o color-orange fa-3x mb-2" aria-hidden="true"></i>
                                            <h2 className="h5">Error </h2>
                                            <h2 className="h5">{status && status.message}</h2>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <a hidden id={"status-btn"} data-bs-toggle={"modal"} data-bs-target={"#status"} />
            </section>
        </>
    )
};

EstadoModuloBANDERA.propTypes = {
    pais: PropTypes.object.isRequired,
    unidad: PropTypes.object.isRequired,
    setUnidad: PropTypes.func.isRequired,
    modulo: PropTypes.object.isRequired,
    setStatus: PropTypes.func.isRequired
}

EstadoModuloUNIDAD.propTypes = {
    setUnidad: PropTypes.func.isRequired,
    pais: PropTypes.object.isRequired,
    setPais: PropTypes.func.isRequired,
    modulo: PropTypes.object.isRequired,
    setStatus: PropTypes.func.isRequired
}

EstadoModuloPAIS.propTypes = {
    modulo: PropTypes.object.isRequired,
    setModulo: PropTypes.func.isRequired,
    setPais: PropTypes.func.isRequired,
    setStatus: PropTypes.func.isRequired
}

Modulos.propTypes = {
    setModulo: PropTypes.func.isRequired,
    setStatus: PropTypes.func.isRequired
}

export default PanelAdministracion;