import React, { useState } from "react";
import TitleCmp from "../commonComponents/TitleCmp";
import { CheckBoxSample } from "../commonComponents/CheckBoxSample";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import "../../resources/css/style-ARG.css";
import color from "../../resources/colors/colors"
import { countrys } from "./CountryDefn";
import { Link } from "react-router-dom";


export const MaintanceFunctionPais = ({
  item,
  index,
  features,
  dataFeature,
  setdataFeature,
  updateContent,
}) => {
  const [isTableVisible, setIsTableVisible] = useState(false);



  return (
    <div
      key={index}
      className={`card-box  align-items-center mb-3 justify-content-between`}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <TitleCmp title={item.descripcion} key={index} />


        <FontAwesomeIcon
          icon={
            faChevronDown}

          className={`arrow-icon ${isTableVisible ? 'rotate-icon' : ''}`}
          onClick={() => setIsTableVisible(!isTableVisible)}
          style={{ color: isTableVisible ? color.primary : color.secondary }}
        />
      </div>
      {isTableVisible && (
        <div className="table-responsive">
          <table className="table table-even table-section table-borderless d-md-table">
            <thead>
              <tr>
                <th className="cap">Funcionalidad</th>
                <th className="cap">Activo</th>
                <th className="cap text-center">Modificar</th>
              </tr>
            </thead>
            <tbody>
              {

                features
                  .filter((feature) => feature.region === item.valor)
                  .map((feature, index) => {
                    return (
                      <tr key={index}>
                        <td>{feature.label}</td>
                        <td>
                          <CheckBoxSample
                            onChange={() =>
                              setdataFeature(

                                dataFeature.some(
                                  (item) =>
                                    item.id === feature.id
                                )
                                  ? dataFeature.map((item) => {

                                    return item.id === feature.id
                                      ? {
                                        ...item,
                                        Maintenance: !item.Maintenance,
                                      }
                                      : item
                                  }
                                  )
                                  : [
                                    ...dataFeature,
                                    {
                                      id: feature.id,

                                      Maintenance:
                                        !feature.Maintenance,
                                    },
                                  ]
                              )
                            }
                            defaultValue={feature.Maintenance}
                          />
                        </td>
                        <td className="text-center">
                          <Link to="#" onClick={() => updateContent(feature.id)}>
                            <i className="fa fa-pencil color-orange" aria-hidden="true"></i>
                          </Link>
                        </td>
                      </tr>
                    );
                  })
              }
            </tbody>
          </table>
        </div>)}
    </div>
  );
};
