import { useState } from "react";
import { services } from "./subscriptionSetting.services";

export const useSubscriptionSave = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [openModalSave, setOpenModalSave] = useState(false);

  const saveSubscription = async (stringSelected: string[]) => {
    try {
      setOpenModalSave(true);
      setIsLoading(true);
      await services.updateRegionalNewsLetter(stringSelected);
      setIsSuccess(true);
    } catch (e) {
      setIsError(true);
    } finally {
      setIsError(false);
      setIsLoading(false);
    }
  };
  return {
    saveSubscription,
    isLoading,
    isError,
    isSuccess,
    openModalSave,
    setOpenModalSave,
  };
};
