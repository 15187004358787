import { NameCellProps } from "./table.types";
import { useTeamDashboardProvider } from "../../context/TeamDashboardContext";
import { useTableProvider } from "../../context/DashboardTableContext";

export const NameCell = (props: NameCellProps) => {
  const { name, lastName, photo, document, countryCode } = props;
  const { handleChangeSubMenu } = useTeamDashboardProvider();
  const { setProfileSummarySelected } = useTableProvider();

  const handleChange = () => {
    if (document) {
      setProfileSummarySelected({
        countryCode,
        idEmpoyee: document,
        photo,
      });
      handleChangeSubMenu(5);
    }
  };

  return (
    <div className="d-flex">
      <img
        src={photo}
        alt="foto colaborador"
        className="rounded-circle me-2"
        width="35"
        height="35"
      />
      <div
        onClick={handleChange}
        role="button"
        className="text-decoration-none text-secondary text-capitalize d-flex text-start cursor-pointer"
      >
        {`${name} ${lastName}`}
      </div>
    </div>
  );
};
