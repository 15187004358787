import React, { useEffect, useState } from 'react'
import rootstyles from '../../../styles/rootStyle.module.css'
import styles from '../../styles/errorStyle.module.css'
import { Button, Grid } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { ErrorContent } from '../../../../utilities/constantsTexts'

interface Props {
  singleButton?: boolean
}

const ErrorButtons = ({singleButton}: Props) => {
  const [showSingleButton, setShowSingleButton] = useState(false)
  const singleButtonsClass = `${styles.error_action_buttons} ${styles.single}`


  useEffect(() => {
    singleButton && setShowSingleButton(singleButton)
  }, [singleButton])

  const reload = () => {
    window.location.reload()
  }

  const goToIndex = () => {
    const indexURL = process.env.REACT_APP_IDP_SIGNIN_URL
    indexURL && window.location.replace(indexURL)
  }

  return (
    <Grid item className={styles.error_action_container}>
      <Grid className={showSingleButton ?  singleButtonsClass : styles.error_action_buttons}>
        <Button className={rootstyles.terciary_button} onClick={() => goToIndex()}>
          {ErrorContent.backButton}
        </Button>
        {!showSingleButton && 
          <Button className={rootstyles.main_button} onClick={() => reload()}>
            {ErrorContent.reloadBtn}
            <ArrowForwardIosIcon />
          </Button>
        }
      </Grid>
    </Grid>
  );
}

export default ErrorButtons;