import React from "react";
import { Card } from "../../../commonComponents";
import { useCencoInfo } from "../../hooks/useCencoInfo";

export const RequisitosCencoTalento = () => {
    const { requirementsInfo } = useCencoInfo();

    return (
        <>
            <Card className="mb-4">
                <h2 className="h4 tit-section">Requisitos</h2>
                <div className="row mt-4 mb-4">
                    <div className="col-12 col-xl-5 order-xl-2 d-flex align-items-center">
                        <div className="color-impulsa fs--2 fw-bold lh-1 mb-4">
                            <div>Empodérate</div>
                            <div>Atrévete</div>
                            <div>Postula</div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-7 order-xl-1">
                        {requirementsInfo.map(
                            ({ step, title, description, isList }) => (
                                <Card
                                    key={title}
                                    type="secondary"
                                    className="mb-3"
                                >
                                    <div className="d-flex">
                                        <div className="border border-start border-primary border-5"></div>
                                        <div className="mx-3">
                                            <span className="circle-number circle-number-outline fs-5 border border-primary color-primary">
                                                0{step}
                                            </span>
                                        </div>
                                        <div>
                                            <h3 className="h4 fw-bold color-normal">
                                                {title}
                                            </h3>
                                            {isList ? (
                                                <ul>
                                                    {description
                                                        .split(";")
                                                        .map((item) => (
                                                            <li key={item}>
                                                                {item}
                                                            </li>
                                                        ))}
                                                </ul>
                                            ) : (
                                                <p>{description}</p>
                                            )}
                                        </div>
                                    </div>
                                </Card>
                            )
                        )}
                    </div>
                </div>

                <p>
                    <strong>¡Recuerda avisar a tu líder sobre tu postulación!</strong>
                </p>
            </Card>
        </>
    );
};
