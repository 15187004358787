import React, { ReactElement, FC } from "react";

interface props {
  text: string;
  icon: ReactElement;
  className?: string;
}

export const JobCardIconInfo: FC<props> = ({ text, icon, className = "" }) => {
  return (
    <>
      {text !== "" && (
        <li className={`${className} d-flex align-items-center py-1`}>
          <i className="fa-lg color-primary mt-1">{icon}</i>
          <span className="ms-2" style={{ whiteSpace: "pre-wrap" }}>{text}</span>
        </li>
      )}
    </>
  );
};
