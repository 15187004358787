import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import AprobacionVacacionesServices from '../../services/aprobacion vacaciones/aprobacion-vacaciones-service';
import Swal from 'sweetalert2'

const DetalleVacaciones = () => {
  const [inputComment, setInputComment] = useState(" ")
  // estados de rechazo/aprobacion vacaciones
  const [actionData, setActionData] = useState(null)
  const [loadingAction, setLoadingAction] = useState(false)
  const [serverMessage,setServerMessage]=useState("");
  const [noDataAction, setNoDataAction] = useState(false)
  const [loadingApproveAction, setLoadingApproveAction] = useState(false)
  const [loadingRejectAction, setLoadingRejectAction] = useState(false)


  const { state } = useLocation()
  const navigate = useNavigate()
  const onChange = (e) => {
    e.preventDefault()
    setInputComment(e.target.value)
  }

  // Mensaje de error al enviar un post
  const handleStatusError = () => {
    setLoadingRejectAction(false)
    setLoadingApproveAction(false)
    setActionData(false)
    Swal.fire({
      allowOutsideClick: false,
      position: 'center',
      title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
      html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo.</div>`,
      confirmButtonColor: '#0169b5',
    }).then(() => {
    
    })
  }

  // Mensaje de éxito al enviar un post
  const handleStatusSuccess = () => {
    setLoadingRejectAction(false)
    setLoadingApproveAction(false)
    setActionData(false)
    Swal.fire({
      allowOutsideClick: false,
      position: 'center',
      title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
      html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
      showConfirmButton: true,
      confirmButtonColor: "#0169b5"
    }).then(() => {
      navigate("../")
    })
  }

  const onRejectSubmit = async () => {
    setLoadingRejectAction(true)
    const submit = [{
      applicationId: state.applicationId,
      applicationState: "R",
      applicationComments: inputComment
    }]

    AprobacionVacacionesServices.AbsenceAction(submit, setActionData, setLoadingAction, setNoDataAction,setServerMessage)
  }

  const onApproveSubmit = () => {
    setLoadingApproveAction(true)
    const submit = [{
      applicationId: state.applicationId,
      applicationState: "A",
      applicationComments: inputComment
    }]
    AprobacionVacacionesServices.AbsenceAction(submit, setActionData,setLoadingAction,setNoDataAction,setServerMessage)
  }
  return (
    <>
      <div className="container edit-card">
      <div className="detail-btn-container">
        <div className='button-history-container'>
          <button className="button cancel"><Link to='../'>Volver <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i></Link></button>
        </div>
        <div className="actions-btn-container">
          <button disabled={state.state === "A" || state.state === "R" || loadingRejectAction} className="button history" onClick={onRejectSubmit}>
            Rechazar {loadingRejectAction ? <div className="spinner-border spinner-border-sm"></div> : <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>}
          </button>
          <button disabled={state.state === "A" || state.state === "R" || loadingApproveAction}className="button save" onClick={onApproveSubmit}>
            Aprobar {loadingApproveAction ? <div className="spinner-border spinner-border-sm"></div> : <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>}
          </button>
        </div>
      </div>
        <div className="approver">
          <div className="title">
            {/* <div className="row"> */}
            {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6"> */}
            EMPLEADO:  <span className='approver-name'>{state.employeeName}</span>
          </div>
          {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6"> */}
          <div className="title">
            FECHA SOLICITUD:  <span className='approver-name'>{state.applicationRequestDate}</span>
          </div>
          {/* </div> */}
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
            <div className="fields">
              <label className="label">Fecha inicio</label>
              <input className="form-control" value={state.beginDate} readOnly />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
            <div className="fields">
              <label className="label">Fecha final</label>
              <input className="form-control" value={state.returnDate} readOnly />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
            <div className="fields">
              <label className="label">Fecha anticipo</label>
              <input className="form-control" value={state.advanceDate} readOnly />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="fields">
              <label className="label">Comentario colaborador</label>
              <textarea className="form-control" value={state.applicantComments} readOnly />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="fields">
              <label className="label">Comentario aprobador</label>
              <textarea className="form-control" onChange={onChange} value={state.state !== 'P' ? state.aprovedComments : null} />
            </div>
          </div>
        </div>

        {noDataAction ? handleStatusError() : null}
        {actionData ? handleStatusSuccess() : null}
      </div>
    </>
  )
}

export default DetalleVacaciones

