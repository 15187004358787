import React, { useState, useEffect } from 'react';
import MenuService from '../../services/permissions/access-service';
import styles from "../../resources/css/style.module.css"

const useSecurityTree = () => {
    const [tree, setTree] = useState([]);
    const [enabledNodes, setEnabledNodes] = useState([]);

    const getLevelFourNodes = (data, levelthreeid) => {
        const myLevelFour = data.filter((f) => f.levelthree_id === levelthreeid && !!f.levelfour_id);
        const uniqueLevelFour = [...new Set(myLevelFour.map((l) => ({ value: `lf_${l.levelfour_id}`, label: l.levelfour, className: styles['rct-custom-label']})))];
        return uniqueLevelFour;
    }

    const getLevelThreeNodes = (data, leveltwoid) => {
        const myLevelThree = data.filter((f) => f.leveltwo_id === leveltwoid && !!f.levelthree_id);
      //  const uniqueLevelThree = [...new Set(myLevelThree.map((l) => ({ value: `lth_${l.levelthree_id}`, label: l.levelthree, className: styles['rct-custom-label'], children: getLevelFourNodes(data, l.levelthree_id)})))];
      const uniqueLevelThree = [];


      myLevelThree.forEach((item) => {
        const existInArray = uniqueLevelThree.some(x => x.value === `lth_${item.levelthree_id}`);
        if(existInArray) return;
        
        uniqueLevelThree.push({ value: `lth_${item.levelthree_id}`, label: item.levelthree, className: styles['rct-custom-label'], children: getLevelFourNodes(data, item.levelthree_id)});
        
    });


      return uniqueLevelThree;
    }

    const getLevelTwoNodes = (data, leveloneid) => {
        const myLevelTwo = data.filter((f) => f.levelone_id === leveloneid && !!f.leveltwo_id);
        // const uniqueLevelTwo = [...new Set(myLevelTwo.map((l) => ({ value: `lt_${l.leveltwo_id}`, label: l.leveltwo, className: styles['rct-custom-label'], children: getLevelThreeNodes(data, l.leveltwo_id)})))];
        const uniqueLevelTwo = [];

        myLevelTwo.forEach((item) => {
            const existInArray = uniqueLevelTwo.some(x => x.value === `lt_${item.leveltwo_id}`);
            if(existInArray) return;
            
            uniqueLevelTwo.push({ value: `lt_${item.leveltwo_id}`, label: item.leveltwo, className: styles['rct-custom-label'], children: getLevelThreeNodes(data, item.leveltwo_id)});
            
        });

        return uniqueLevelTwo;
    }

    const getLevelOneNodes = (data, featureCountryId) => {
        const myLevelOne = data.filter((f) => f.fcid === featureCountryId && !!f.levelone_id);
        const uniqueLevelOne = [];
        myLevelOne.forEach((item) => {
            const existInArray = uniqueLevelOne.some(x => x.value === `l_${item.levelone_id}`);
            if(existInArray) return;
            
            uniqueLevelOne.push({ value: `l_${item.levelone_id}`, label: item.levelone, className: styles['rct-custom-label'], children: getLevelTwoNodes(data, item.levelone_id)});
            
        });
        return uniqueLevelOne;
    }

    const getCountryNodes = (data, featureid) => {
        let featureCountries = [];
        const uniqueFeatureCountries = [...new Set(data.filter((f) => f.featureid === featureid)
            .map(item => item.fcid))];

        uniqueFeatureCountries.forEach((fc) => {
            const featureCountry = data.find(x => x.fcid === fc);
            const auxFC = { value: `fc_${fc}`, label: featureCountry.region, children: getLevelOneNodes(data, fc), className: styles['rct-custom-label']};
            featureCountries.push(auxFC);
        })
        return featureCountries;
    }

    const transformData = (data) => {
        let nodes = [];
        // const uniqueFeatures = [...new Set(data.map(item => ({ value: `f_${item.featureid}`, label: item.featurename })))];
        const uniqueFeatures = [...new Set(data.map(item => item.featureid))];
        

        uniqueFeatures.forEach((f) => {
            const feature = data.find(x => x.featureid === f);
            const auxFeature = { value: `f_${f}`, label: feature.featurename, className: styles['rct-custom-label'], children: getCountryNodes(data, f) };
            nodes.push(auxFeature);
        });

        const feature_checkedNodes = data.filter((d) => d.featureStatus === 'Enabled').map(l => `f_${l.featureid}`);
        const feature_country_checkedNodes = data.filter((d) => d.fcstatus === 'Enabled').map(l => `fc_${l.fcid}`);
        const levelone_checkedNodes = data.filter((d) => d.levelone_status === 'Enabled').map(l => `l_${l.levelone_id}`);
        const leveltwo_checkedNodes = data.filter((d) => d.leveltwo_status === 'Enabled').map(l => `lt_${l.leveltwo_id}`);
        const levelthree_checkedNodes = data.filter((d) => d.levelthree_status === 'Enabled').map(l => `lth_${l.levelthree_id}`);
        const levelfour_checkedNodes = data.filter((d) => d.levelfour_status === 'Enabled').map(l => `lf_${l.levelfour_id}`);

        const checkedNodes = [...feature_checkedNodes, ...feature_country_checkedNodes, ...levelone_checkedNodes, ...leveltwo_checkedNodes, ...levelthree_checkedNodes, ...levelfour_checkedNodes];

        return { nodes, checkedNodes };
    }

    const getTree = async () => {
        const data = await MenuService.getArbolModulos();
        const { nodes, checkedNodes } = transformData(data);
        setTree(nodes);
        setEnabledNodes(checkedNodes);
    }

    useEffect(() => {
        getTree();
    }, []);

    return { tree, enabledNodes };
};

export default useSecurityTree;