import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import moment from 'moment';
import 'moment/locale/es';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import AsistenciaService from './../../services/asistencia/asistencia-service';
import CambioAsistenciaResumen from './cambio-asistencia-resumen';
import { useNavigate } from 'react-router';
import StepContent from '@mui/material/StepContent';
import { BsQuestionCircleFill } from 'react-icons/bs';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FaCalendarAlt } from 'react-icons/fa';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
registerLocale("es", es);




const SolicitudCambioTurno = (props) => {

    SolicitudCambioTurno.propTypes = {
        cambioTurno: PropTypes.any,
        setCambioTurno: PropTypes.any,
        schedules: PropTypes.any
    }

    const [cambioTurno, setCambioTurno] = useState(props.cambioTurno);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingShifts, setLoadingShifts] = useState(false);
    const [workSchedules, setWorkSchedules] = useState([]);
    const [loadingSchedules, setLoadingSchedules] = useState(false);
    const [openPicker, setOpenPicker] = useState(false)
    const navigation = useNavigate();



    const setCalendarCambioTurno = props.setCambioTurno;

    const tipo = props.cambioTurno.tipo; // Turno o Franco

    useEffect(() => {
        setCambioTurno(props.cambioTurno);
        setActiveStep(0);
        console.log(props.cambioTurno)
    }, [props.cambioTurno])

    const [activeStep, setActiveStep] = useState(0);

    const pasosCambioFranco = ['Dia seleccionado', 'Cambio de Franco', 'Resumen'];
    const pasosCambioHorario = ['Dia seleccionado', 'Cambio de horario', 'Resumen'];

    const pasos = tipo === 'Franco' ? pasosCambioFranco : pasosCambioHorario;

    const solicitudValida = () => {
        if (activeStep === 1) {
            if (tipo === 'Franco') {
                return cambioTurno.changed.startDate && cambioTurno.changed.horarioId
            } else {
                return cambioTurno.changed.horarioId
            }
        }
        return true;
    }

    useEffect(() => {
        if (tipo === 'Turno') {
            setLoadingSchedules(true);
            AsistenciaService.getWorkSchedules(cambioTurno.previous?.duration, setWorkSchedules, setError, setLoadingSchedules);
        }
    }, [cambioTurno.previous.startDate]);

    useEffect(() => {
        if (activeStep == 1) {
            setOpenPicker(true)
        }
    }, [activeStep])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleRequest = () => {

        let previousDate = moment(cambioTurno.previous.startDate).format('YYYY-MM-DD');
        let newDate = moment(cambioTurno.changed.startDate).format('YYYY-MM-DD');

        const request = {
            type: tipo,
            workingDate: previousDate,
            dayOffDate: tipo === 'Turno' ? null : newDate,
            previousStartTime: tipo === 'Turno' ? cambioTurno.previous.startTime : null,
            previousEndTime: tipo === 'Turno' ? cambioTurno.previous.endTime : null,
            newStartTime: cambioTurno.changed.startTime,
            newEndTime: cambioTurno.changed.endTime
        }

        setLoading(true);
        AsistenciaService.createShiftChangeRequest(request, setLoading, setSuccess, setError);
    }

    const handleSuccess = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: green; font-size: 16px">Exitoso</div>',
            html: `<div style="text-align: center; font-size: 15px">${success}</div>`,
            confirmButtonColor: '#0169b5'
        }).then(result => {
            if (result.isConfirmed) {
                setSuccess(false);
                setCalendarCambioTurno(null);
                navigation("historico");
            }
        })
    }

    const handleError = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px">Error</div>',
            html: `<div style="text-align: center; font-size: 15px">${error || 'Ha ocurrido un error en la solicitud, intentelo nuevamente'}</div>`,
            confirmButtonColor: '#0169b5',
        }).then((result) => {
            if (result.isConfirmed) {
                setError();
            }
        })
    }

    const getScheduleById = (scheduleId) => {
        return workSchedules.find(schedule => schedule.id == scheduleId)
    }

    const handleSetNuevaFecha = async (fecha) => {
        setOpenPicker(false)
        const momentLocale = moment(fecha);
        momentLocale.locale("es");
        const dateDescription = momentLocale.format('dddd DD [de] MMMM');
        setCambioTurno({ ...cambioTurno, changed: { ...cambioTurno.changed, startDate: fecha, dateDescription: dateDescription, horarioId: null } });

        if (tipo === 'Franco') {
            setWorkSchedules([]);
            setLoadingShifts(true);
            try {
                let response = await AsistenciaService.getShiftForDate(moment(fecha).format("YYYY-MM-DD"));

                if (response && response.length > 0) {
                    const shift = response[0];
                    if (shift.type !== 'Turno') {
                        setError("No tiene un turno el dia seleccionado para cambiar el franco.");
                        setWorkSchedules([]);
                    } else {
                        setLoadingSchedules(true);
                        AsistenciaService.getWorkSchedules(shift.duration, setWorkSchedules, setError, setLoadingSchedules);
                    }
                } else {
                    setError("No tiene un turno el dia seleccionado para cambiar el franco.");
                    setWorkSchedules([]);
                }
                setLoadingShifts(false);

            } catch (e) {
                setError(e);
            }

        }
    }

    const handleSetHorario = (horarioId) => {
        const schedule = getScheduleById(horarioId);

        setCambioTurno({
            ...cambioTurno,
            changed: {
                ...cambioTurno.changed,
                horarioId: horarioId,
                startTime: schedule.startTime,
                endTime: schedule.endTime,
                timeDescription: schedule.description
            }
        });
    }


    const cambioTurnoContent = () => {
        return (
            <div>
                <section className="cambio-turno">

                    {activeStep == 0 ? <React.Fragment>
                        <div className="row align-items-center custom-border p-3">
                            <div className='label fw-bold col-md-2 mb-0 d-flex align-items-center'>Detalle <Tippy content="La solicitud debe ser aprobada como mínimo 6 hrs. antes del horario de ingreso solicitado, de lo contrario se deberá solicitar por otra vía"><div className="m-2"><BsQuestionCircleFill /></div></Tippy></div>
                            <input className='text-capitalize form-control col-md-10' name='selectedDate' type='text' value={cambioTurno.previous.dateDescription} disabled></input>
                        </div>
                    </React.Fragment> : null}

                    {activeStep == 1 ? <React.Fragment>
                        <div className="row">
                            {tipo === 'Franco' ? <p className="text-capitalize">Día Franco seleccionado el {cambioTurno.previous.dateDescription}</p>
                                : <p className="text-capitalize">Día {cambioTurno.previous.detail}</p>}
                        </div>

                        <div className="row align-items-center">
                            {tipo === 'Franco' ?
                                <div className="row align-items-center custom-border p-3">
                                    <label className='label fw-bold col-md-4 mb-0'>Seleccione el día a descansar</label>
                                    <div className="row mt-1">
                                    <span className="col-auto m-0 input-group-text">
                                            <FaCalendarAlt />
                                            </span>
                                            <div className="col-9">
                                        <DatePicker
                                            locale="es"
                                            open={openPicker}
                                            placeholderText="Dd/Mm/Aaaa"
                                            selected={!cambioTurno.changed?.startDate ? new Date(cambioTurno.previous.startDate) : new Date(cambioTurno.changed.startDate) }
                                            className='text-capitalize dayoff-calendar form-control col-md-8'
                                            required
                                            name='nuevaFecha'
                                            dateFormat="dd/MM/yyyy"
                                            onFocus={() => setOpenPicker(true)}
                                            onChange={e => handleSetNuevaFecha(e)}>
                                        </DatePicker>
                                    </div>
                                    </div>

                                </div>
                                : null}


                            {cambioTurno.changed.startDate || tipo === 'Turno' ?
                                <div className="row align-items-center mt-4  custom-border p-3">
                                    <label className='label fw-bold col-md-4 mb-0 d-flex align-items-center'>{tipo === 'Turno' ? 'Seleccione su nuevo horario' : 'Seleccione horario a trabajar'} <Tippy content="El Cambio de horario debe tener las mismas horas que el día solicitado"><div className="m-2"><BsQuestionCircleFill /></div></Tippy></label>
                                    <div className="col-md-8 p-0">
                                        <select className='text-capitalize form-select col-md-8' required name='nuevoHorarioId' value={cambioTurno.changed.horarioId} onChange={e => handleSetHorario(e.target.value)}>
                                            <option className='form-option' value=""></option>
                                            {workSchedules.map(schedule => {
                                                if (!schedule.startTime || !schedule.endTime) {
                                                    return null;
                                                }
                                                return <option className='form-option' value={schedule.id} key={schedule.id}>{schedule.description}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                : null}

                            {(loadingSchedules || loadingShifts) && <div className="full-spinner mb-4 mt-4"><div className="spinner-border text-primary"></div></div>}


                        </div>
                    </React.Fragment> : null}

                    {activeStep == 2 ?
                        <React.Fragment>
                            <CambioAsistenciaResumen tipo={tipo} cambioTurno={cambioTurno}></CambioAsistenciaResumen>
                        </React.Fragment>
                        : null}

                </section>

                <section>
                    <div className="row">
                        <div className={activeStep !== 0 ? "calendar-display-step-plusone" : "calendar-display-step"}>
                            <div>
                                {activeStep !== 0 && <button title="Volver" className="timegrid-back-btn" onClick={() => handleBack()}><i className=" color-orange fa fa-chevron-left fa-fw fa-xs"></i> Volver</button>}
                            </div>
                            <div>
                                {activeStep !== pasosCambioFranco.length - 1 ? <button title="Siguiente" className="button reject ms-auto" onClick={() => handleNext()} disabled={!solicitudValida()}>Siguiente</button>
                                    : <button title="Enviar" className="button save ms-auto" onClick={() => handleRequest()} disabled={loading}>
                                        Enviar {loading ? <div className="spinner-border spinner-border-sm"></div> : <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>}</button>}
                            </div>
                        </div>

                    </div>

                </section>
            </div>
        )
    }
    return (
        <>
            <section className="card-box">
                <div className="header-page-container">
                    <div className="mb-2 mb-sm-4">
                        <h3>Administración de asistencia <div className='title-underlined'></div></h3>
                    </div>
                </div>

                <Stepper activeStep={activeStep} className="desktop-stepper">
                    {pasos.map((label) => {
                        return (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <div className='desktop-stepper'>{cambioTurnoContent()}</div>

                <Stepper activeStep={activeStep} orientation={"vertical"} className="mobile-stepper">
                    {pasos.map((label) => {
                        return (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                                <StepContent>
                                    {cambioTurnoContent()}
                                </StepContent>
                            </Step>
                        );
                    })}
                </Stepper>


            </section>

            {success ? handleSuccess() : null}
            {error ? handleError() : null}

        </>
    )
}

export default SolicitudCambioTurno;
