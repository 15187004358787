import React, { Children, FC } from "react";
import { CarouselProps } from "../../utilities/interfaces";
import { dataNumber, spinnerDataLoad } from "./styles/carouselStyle.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import carouselStyle, { volver } from "./styles/carouselStyle.module.css";
import * as RD from "@devexperts/remote-data-ts";
import { useCarousel } from "../../../../services/utiles";

library.add(fas);

const Carousel: FC<CarouselProps> = ({
  cards,
  setCarouselSelected,
  carouselSelected,
  setTitle,
  setShowYear,
  setIsGraphLoaded,
}) => {
  
  useCarousel(0,'carousel-dashboard-mi-equipo-mobile', cards);
  
  return (
    <>
      {carouselSelected > -1 && (
        <div className={carouselStyle.volver}>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setCarouselSelected(-1);
            }}
          >
            {"< Volver a Nómina de gestión"}
          </a>
        </div>
      )}

      <div className="row mt-5 mb-4 d-none d-lg-flex">
        {Children.toArray(
          cards.map((card, index) => {
            return (
              <div className="col-12 col-lg-6 col-xl-3 mb-4">
                  <a
                    className={`btn btn-xl text-uppercase${
                      carouselSelected === index ? " btn-degradado-2" : ""
                    }`}
                    href="#"
                    onClick={(e) => {
                      
                      e.preventDefault();
                      setCarouselSelected((oldIndex) =>
                        oldIndex === index ? -1 : index
                      );

                      if (carouselSelected === index) {
                        setTitle("");
                        setShowYear(true)
                      } else {
                        setTitle(card.title);
                        setShowYear(card.showYear)
                      }
                      setIsGraphLoaded(false)

                    }}
                  >
                  <span>{card.title}</span>
                  <span className={dataNumber}>{}</span>
                  {/* <span className={dataNumber}>{card.total}</span> */}
                  
                  <FontAwesomeIcon
                    className={`fa ${card.icon.replace(
                      "chart-line-up",
                      "line-chart"
                    )}`}
                    icon={
                      `${card.icon.replace(
                        "chart-line-up",
                        "line-chart"
                      )}` as IconProp
                    }
                    aria-hidden="true"
                  />
                </a>
              </div>
            );
          })
        )}
      </div>

      <div
        id="carousel-dashboard-mi-equipo-mobile"
        className="carousel carousel-dark slide carousel-control-inner mt-4 mb-4 d-lg-none"
        data-bs-ride="carousel"
        data-bs-interval="false"
      >
        <div className="carousel-indicators">
          {Children.toArray(
            cards.map((p, index) => {
              return (
                <button
                  type="button"
                  data-bs-target="#carousel-dashboard-mi-equipo-mobile"
                  data-bs-slide-to={index}
                  className={`${index === 0 ? "active" : ""}`}
                  aria-current={index === 0}
                  aria-label={`Slide ${index + 1}`}
                />
              );
            })
          )}
        </div>
        <div className="carousel-inner">
          {Children.toArray(
            cards.map((card, index) => {
              return (
                <div
                  className={`carousel-item p-2${index === 0 ? " active" : ""}`}
                >
                  <a
                    className={`btn btn-xl text-uppercase${
                      carouselSelected === index ? " btn-degradado-2" : ""
                    }`}
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setCarouselSelected((oldIndex) =>
                        oldIndex === index ? -1 : index
                      );
                      
                      if (carouselSelected === index) {
                        setTitle("");
                        setShowYear(true)
                      } else {
                        setTitle(card.title);
                        setShowYear(card.showYear)
                      }
                      setIsGraphLoaded(false)
                    }}
                  >
                    <span>{card.title}</span>
                    <span className={dataNumber}>{''}</span>
                     {/* <span className={dataNumber}>{card.total}</span> */}
                    
                    <FontAwesomeIcon
                      className={`fa ${card.icon.replace(
                        "chart-line-up",
                        "line-chart"
                      )}`}
                      icon={
                        `${card.icon.replace(
                          "chart-line-up",
                          "line-chart"
                        )}` as IconProp
                      }
                      aria-hidden="true"
                    />
                  </a>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default Carousel;
