import React, { Children, useEffect, useState } from "react";
import { AddKpiTableProps, id, kpi } from "../../../utilities/interfaces";
import { makeBy, union } from "fp-ts/Array";
import { contramap, Eq } from "fp-ts/Eq";
import * as S from "fp-ts/string";
import { pipe } from "fp-ts/function";
import { range } from "fp-ts/lib/NonEmptyArray";

const eqKpi: Eq<id> = S.Eq;
const eqKpiById: Eq<kpi> = pipe(
    eqKpi,
    contramap((kpi) => kpi.id)
);

const KpiModal: React.FC<AddKpiTableProps> = ({ kpiList, setKpiList, contactEmail, nameToDiplay }) => {
    const [kpiListToDisplay, setKpiListToDisplay] = useState<kpi[]>(range(1, 5).map((k) => {
        return { id: `${k}`, name: "", weight: 0, applySame: false }
    }));
    const [accWeight, setAccWeight] = useState(0);

    useEffect(() => {
        kpiList.length && pipe(kpiList, union(eqKpiById)(kpiListToDisplay), setKpiListToDisplay);
    }, [kpiList]);

    useEffect(() => {
        setAccWeight(kpiListToDisplay.reduce((a, b) => a + b.weight, 0));
    }, [kpiListToDisplay]);

    return (
        <>
            <div className="modal fade" id="kpiModal" tabIndex={-1} aria-labelledby="kpiModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <form role="form">
                            <div className="modal-header border-0 pb-0">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <h2 className="h5 mb-4">{`FRG para ${nameToDiplay !== undefined ? nameToDiplay : "toda mi Gerencia"}`}</h2>
                                {Children.toArray(kpiListToDisplay.map((kpi, index) => {
                                    return (
                                        <div className="mb-3 border-bottom">
                                            <div className="row">
                                                <div className="col-12 col-md-9">
                                                    <div className="mb-3">
                                                        <label className="form-label mb-2"><strong>{`Escribir KPI ${kpi.id} (200 caracteres máx.)`}</strong></label>
                                                        <input
                                                            id={`kpiName${index}`}
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={200}
                                                            value={kpi.name}
                                                            readOnly={(index > 0 && kpiListToDisplay[index - 1].name === "" && kpiListToDisplay[index - 1].weight === 0) ||
                                                                (accWeight === 100 && index > 0 && kpiListToDisplay[index - 1].name === "" && kpiListToDisplay[index - 1].weight === 0)}
                                                            onChange={(e) => {
                                                                setKpiListToDisplay((oldKpiList) => {
                                                                    return oldKpiList.map((k, i) => i === index ? { ...k, name: e.target?.value } : { ...k })
                                                                });
                                                            }}
                                                            onBlur={(e) => {
                                                                e.target.value !== "" &&
                                                                setKpiListToDisplay((oldKpiList) => {
                                                                    return oldKpiList.map((k, i) => i === index ? { ...k, weight: 20 } : { ...k })
                                                                });
                                                                // imperative block :(
                                                                if (e.target.value === "") {
                                                                    setKpiListToDisplay((oldKpiList) => {
                                                                        return oldKpiList.map((k, i) => i === index ? { ...k, weight: 0 } : { ...k })
                                                                    });
                                                                    (document.getElementById(`kpiInput${index}`) as HTMLInputElement).value = "";
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-3">
                                                    <div className="mb-3">
                                                        <label className="form-label mb-2"><strong>{"Peso (%)*"}</strong></label>
                                                        <input
                                                            id={`kpiInput${index}`}
                                                            type="number"
                                                            className="form-control"
                                                            maxLength={3}
                                                            min={20}
                                                            max={100}
                                                            onBlur={(e) => {(document.getElementById(`kpiName${index}`) as HTMLInputElement)?.value !== "" &&
                                                                    (parseInt(e.target.value) > 0 && parseInt(e.target.value) < 20 || e.target.value === "" || parseInt(e.target.value) > 100) &&
                                                                    setKpiListToDisplay((oldKpiList) => {
                                                                        return oldKpiList.map((k, i) => i === index ? { ...k, weight: 20 } : { ...k })
                                                                    });
                                                                }
                                                            }
                                                            value={kpi.name !== "" || kpi.weight !== 0 ? kpi.weight.toString() : ""}
                                                            readOnly={(document.getElementById(`kpiName${index}`) as HTMLInputElement)?.value === "" ||
                                                                (index > 0 && kpiListToDisplay[index - 1].name === "" && kpiListToDisplay[index - 1].weight < 20) ||
                                                                (accWeight === 100 && index > 0 && kpiListToDisplay[index - 1].name === "" && kpiListToDisplay[index - 1].weight < 20)}
                                                            onChange={(e) => {
                                                                setKpiListToDisplay((oldKpiList) => {
                                                                    return oldKpiList.map((k, i) => i === index ? { ...k, weight: e.target?.value !== "" ? parseInt(e.target?.value) : 0 } : { ...k })
                                                                });
                                                            }}
                                                            onKeyDown={(e) => { (e.key === "." || e.key === ",") && e.preventDefault() }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }))}
                                <div className="d-grid d-md-flex justify-content-md-center gap-2 flex-nowrap mt-5">
                                    <button
                                        type="button"
                                        className="btn btn-degradado col-12 col-md-auto px-5 order-md-last ms-md-2"
                                        data-bs-dismiss="modal"
                                        disabled={accWeight !== 100}
                                        onClick={() => setKpiList(kpiListToDisplay.filter((k) => k.name !== ""))}
                                    >
                                        {"Agregar "}
                                        <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />
                                    </button>
                                    <button type="button" className="btn btn-primary col-12 col-md-auto px-5" data-bs-dismiss="modal">{"Cancelar "}<i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" /></button>
                                </div>
                            </div>
                            {accWeight > 100 && 
                                <div className="d-grid d-md-flex justify-content-md-center gap-2 flex-nowrap invalid-feedback d-block">
                                    {"La suma de los pesos no puede superar el 100%."}
                                </div>}
                            <div className="modal-footer border-0 pt-0 justify-content-center pb-5">
                                <div className="border-top mt-5 pt-3 text-center w-100">
                                    <p className="m-0">Puedes ingresar hasta 5 KPI's. Si necesitas incorporar otro de manera excepcional, favor comunícate con{Children.toArray(contactEmail?.split(", ").map((mail, index, arr) => {
                                            return (
                                                <><a href={`mailto:${mail}`}>{mail}</a>{index < arr.length - 1 ? "," : ""}</>
                                            )
                                        }))}.</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
};

export default KpiModal;