import React from "react";
import fotoEjemplo from "../../../../assets/fifthStep/foto-ejemplo.png";

const ImageModal = () => {
  return (
    <>
        <div className="modal fade" id="info-foto-credencial" tabIndex={-1} aria-labelledby="info-foto-credencial" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body px-4 pb-4">
                        <h2 className="h5 mb-4">{"Foto ejemplo"}</h2>
                        <img className="img-fluid my-auto" src={fotoEjemplo} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ImageModal;