import React, { useState, useRef, useEffect, useCallback } from 'react'
import { FeedBackContent } from '../../../../../utilities/constantsTexts'
import {
  Grid,
  Button,
  TextField,
} from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { CommentBoxProps } from '../../../utilities/interfaces'
import ToastAlert from '../../../../../commons/toast/toast'
import { toastOptions } from '../../../../../commons/toast/constants'
import styles from '../../../../../commons/styles/rootStyle.module.css'
import textstyles from '../../../../../commons/styles/textStyles.module.css'
import feedbackStyle from '../../../styles/feedback.module.css'

const CommentBox = (props: CommentBoxProps) => {
  const {
    saveComment,
    isNegative,
    showWarning,
    isSendingPost,
    onLinkClicked,
    onToastClosed
  } = props
  const commentBox = useRef<HTMLInputElement>(null)
  const [characteres, setCharacteres] = useState(0)
  const buttonStates = ['disabled', 'enabled']
  const [disabledButton, setDisabledButton] = useState(false)
  const [hideWarning, setHideWarning] = useState(false)

  useEffect(() => {
    isNegative && changeButtonStatus(buttonStates[0])
  }, [isNegative])

  const sendButton = useCallback((node: HTMLButtonElement) => {
    if (node === null) return
    if (disabledButton) {
      node.setAttribute(buttonStates[0], 'true')
      node.classList.add(styles.disabled)
    } else {
      node.removeAttribute(buttonStates[0])
      node.classList.remove(styles.disabled)
    }
  }, [disabledButton]);

  useEffect(() => {
    if (commentBox.current) {
      commentBox.current.addEventListener('click', handleActiveTextArea)
    }
    return (() => {
      commentBox.current && commentBox.current.removeEventListener('click', handleActiveTextArea)
    })
  }, [commentBox])

  const handleActiveTextArea = () => {
    if (!commentBox.current) return
    const parentBox = commentBox.current.parentElement
    if (parentBox?.classList.contains('Mui-focused')) {
      parentBox.classList.add(feedbackStyle.active)
    }
  }

  const changeButtonStatus = (action: string) => {
    if (action === buttonStates[0]) {
      setDisabledButton(true)
    } else if (action === buttonStates[1]) {
      setDisabledButton(false)
    }
  }

  const sendFeedback = () => {
    if (disabledButton) return
    const value = commentBox.current?.value
    if (isNegative) {
      value && saveComment(value)
    } else {
      const commment = value ? value : ''
      saveComment(commment)
    }
    setHideWarning(false)
  }

  const checkNegativeComment = (value: string) => {
    if (showWarning && value === '') {
      setHideWarning(true)
    }
  }

  const updateCounter = () => {
    const value = commentBox.current?.value
    const comment = value ? value : ''
    updateButton(comment)
    checkNegativeComment(comment)
    if (value && comment.length >= 500) {
      const cutComment = comment.slice(0, 500)
      commentBox.current.value = cutComment
      setCharacteres(cutComment.length)
    } else {
      setCharacteres(comment.length)
    }
  }

  const updateButton = (comment: string) => {
    if (!isNegative) {
      changeButtonStatus(buttonStates[1])
      return
    }
    if (comment.length > 0 && isNegative) {
      changeButtonStatus(buttonStates[1])
    } else {
      changeButtonStatus(buttonStates[0])
    }
  }

  const FeedbackHeader = () => {
    if (!isNegative) {
      return (
        <React.Fragment>
          <p className={`${textstyles.page_subtitle} ${feedbackStyle.feedback_title}`}>
            {FeedBackContent.positiveCommenTitle}
          </p>
          <p>
            {FeedBackContent.positiveCommenSubtitle}
          </p>
        </React.Fragment>
      )
    } else {
      return (
        <p>{FeedBackContent.negativeCommenSubtitle}</p>
      )
    }
  }

  const getPlaceHolder = (): string => {
    if (isNegative) {
      return FeedBackContent.negativeCommenPlaceholder
    } else {
      return FeedBackContent.positiveCommenPlaceholder
    }
  }

  const handleClick = () => {
    const newComment = commentBox.current?.value
    if (!onLinkClicked) return
    if (isNegative) {
      newComment && onLinkClicked(newComment)
    } else {
      const comment = newComment ? newComment : ''
      onLinkClicked(comment)
    }
  }

  const FeedbackAction = () => {
    if (showWarning && !hideWarning) {
      return (
        <ToastAlert
          message={FeedBackContent.failToast}
          variant={toastOptions.yellow}
          duration={10000}
          onToastClosed={() => onToastClosed()}
          link={
            <p className={feedbackStyle.toast_action} onClick={() => handleClick()}>
              {FeedBackContent.failToastButton}
            </p>}
        />
      )
    } else {
      return (
        <React.Fragment>
          {!isSendingPost ?
            (
              <Button
                className={styles.main_button}
                onClick={() => sendFeedback()}
                ref={sendButton}
              >
                {FeedBackContent.sendFeedbackButton}
                <ArrowForwardIosIcon />
              </Button>
            ) : (
              <Button className={`${styles.main_button} ${styles.disabled}`}>
                {FeedBackContent.sendFeedbackButtonLoading}
              </Button>
            )
          }
        </React.Fragment>
      )
    }
  }

  return (
    <Grid item
      className={isNegative ?
        `${feedbackStyle.comment_box_wrapper} ${feedbackStyle.negative} comment_box_wrapper negative` :
        feedbackStyle.comment_box_wrapper + ' comment_box_wrapper'
      }>
      <FeedbackHeader />
      <TextField
        fullWidth={true}
        placeholder={getPlaceHolder()}
        inputRef={commentBox}
        required={isNegative}
        multiline={true}
        className={feedbackStyle.comment_box}
        variant='standard'
        InputProps={{ disableUnderline: true }}
        onChange={() => updateCounter()}
      />
      <span
        className={isNegative ?
          `${feedbackStyle.counter} ${feedbackStyle.negative}` :
          feedbackStyle.counter}>
        {characteres}/500
      </span>
      <Grid item className={feedbackStyle.send_button_wrapper}>
        <FeedbackAction />
      </Grid>
    </Grid>
  )
}

export default CommentBox

