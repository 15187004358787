import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import ExperienciaLaboralServices from '../../services/experiencia laboral/experiencia-laboral-service';
import styles from "../../resources/css/style-COL.module.css";

function DetalleExperienciaLaboral({ changeDisplay, id, reloadWorkExperiences, countries }) {
    const [workExperience, setWorkExperience] = useState({})
    const [loadingWorkExperience, setLoadingWorkExperience] = useState(false)
    const [, setNoDataWorkExperience] = useState(false)
    const [deleteWorkExperience, setDeleteWorkExperience] = useState(false)
    const [loadingDeleteWorkExperience, setLoadingDeleteWorkExperience] = useState(false)
    const [noDataDelete, setNoDataDelete] = useState(false)
    const [states, setStates] = useState([])
    const [loadingStates, setLoadingStates] = useState(false)
    const [counties, setCounties] = useState([])
    const [loadingCounties, setLoadingCounties] = useState(false)
    const [, setInfoDataDeleteError] = useState(null)
    const [industries, setIndustries] = useState([])
    const [loadingIndustries, setLoadingIndustries] = useState(false)
    const [sectors, setSectors] = useState([])
    const [loadingSectors, setLoadingSectors] = useState(false)
    const [workAreas, setWorkAreas] = useState([])
    const [loadingWorkAreas, setLoadingWorkAreas] = useState(false)
    const [reasonsRetirement, setReasonsRetirement] = useState([])
    const [loadingReasonsRetirement, setLoadingReasonsRetirement] = useState(false)

    useEffect(() => {
        if (id !== null) {
            setLoadingWorkExperience(true)
            ExperienciaLaboralServices.GetWorkExperience(id, setWorkExperience, setLoadingWorkExperience, setNoDataWorkExperience)
        }
    }, [id])
    useEffect(() => {
        if (workExperience.country === "COL") {
            setLoadingStates(true)
            ExperienciaLaboralServices.GetStates(workExperience.country, setStates, setLoadingStates)
        }
    }, [workExperience.country])

    useEffect(() => {
        if (workExperience.country === "COL") {
            setLoadingCounties(true)
            ExperienciaLaboralServices.GetCounties(workExperience.country, workExperience.state, setCounties, setLoadingCounties)
        }
    }, [workExperience.state])
    useEffect(() => {
        ExperienciaLaboralServices.GetIndustries(setIndustries, setLoadingIndustries)
        ExperienciaLaboralServices.GetSectors(setSectors, setLoadingSectors)
        ExperienciaLaboralServices.GetWorkAreas(setWorkAreas, setLoadingWorkAreas)
        ExperienciaLaboralServices.GetReasonsRetirement(setReasonsRetirement, setLoadingReasonsRetirement)
    }, [id])

    const deleteOneWorkExperience = () => {
        Swal
            .fire({
                allowOutsideClick: false,
                position: 'center',
                title: '<div style="text-align: center; color: red; font-size: 16px"> Eliminar </div>',
                html: '<div style="text-align: center; font-size: 15px"> ¿Desea continuar la operación? </div>',
                showCancelButton: true,
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar",
                confirmButtonColor: "red",
                cancelButtonColor: "#0169b5"
            }).then((result) => {
                if (result.isConfirmed) {
                    setLoadingDeleteWorkExperience(true)
                    ExperienciaLaboralServices.DeleteWorkExperience(id, setDeleteWorkExperience, setLoadingDeleteWorkExperience, setNoDataDelete, setInfoDataDeleteError)
                }
            })
    }

    // Mensaje de error al enviar un post
    const handleStatusError = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
            html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo.</div>`,
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadWorkExperiences()
        })
        setLoadingDeleteWorkExperience(false)
        setNoDataDelete(false)
    }

    // Mensaje de éxito al enviar un post
    const handleStatusSuccess = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
            html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
            confirmButtonColor: '#0169b5',
        }).then(() => {
            reloadWorkExperiences()
        })
        setLoadingDeleteWorkExperience(false)
        setNoDataDelete(false)
    }

    useEffect(() => {
        // noDataDelete ? handleStatusError() : null
        deleteWorkExperience ? handleStatusSuccess() : null
    }, [loadingDeleteWorkExperience])

    return (<>
        {/* <!-- Inicio Ver Experiencia --> */}
        {!loadingWorkExperience ? <div className={`${styles["box"]} ${styles["mb-4"]}`}>
            <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Ver Experiencia</h2>
            <form>
                {/* <!-- Fila 1 --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Nombre de la Empresa <span className={styles["color-red"]}>*</span></label>
                        <input type="text" className={styles["form-control"]} defaultValue={workExperience.company} disabled />
                    </div>
                </div>
                {/* <!-- Fila 2 --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>País <span className={styles["color-red"]}>*</span></label>
                        <div className={styles["input-group"]}>
                            <select className={styles["select-col"]} disabled>
                                {
                                    countries.map((c) => {
                                        return (
                                            <option key={c.country} value={c.country} selected={workExperience.country === c.country}>{c.descr}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]} ${workExperience.country === "COL" ? "d-none" : null}`}>
                        <label className={styles["form-label"]}>Ciudad <span className={styles["color-red"]}>*</span></label>
                        <input type="text" className={styles["form-control"]} defaultValue={workExperience.city} disabled />
                    </div>
                </div>
                {/* <!-- Fila 3 --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]} ${workExperience.country !== "COL" ? "d-none" : null}`}>
                        <label className={styles["form-label"]}>Departamento <span className={styles["color-red"]}>*</span></label>
                        <div className={styles["input-group"]}>
                            {!loadingStates ? <select className={styles["select-col"]} disabled>
                                {
                                    states.map(s => {
                                        return (
                                            <option key={s.state} value={s.state} selected={workExperience.state === s.state}>{s.descrshort}</option>
                                        )
                                    })
                                }
                            </select> : <div><div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                        </div>
                    </div>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]} ${workExperience.country !== "COL" ? "d-none" : null}`}>
                        <label className={styles["form-label"]}>Ciudad <span className={styles["color-red"]}>*</span></label>
                        <div className={styles["input-group"]}>
                            {!loadingCounties ? <select className={styles["select-col"]} disabled>
                                {
                                    counties.map((c) => {
                                        return (
                                            <option key={c.county} value={c.county} selected={workExperience.county === c.county}>{c.descr}</option>
                                        )
                                    })
                                }
                            </select> : <div><div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                        </div>
                    </div>
                </div>
                {/* <!-- Fila 4 --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Industria <span className={styles["color-red"]}>*</span></label>
                        <div className={styles["input-group"]}>
                            {!loadingIndustries ? <select className={styles["select-col"]} disabled>
                                {
                                    industries.map(s => {
                                        return (
                                            <option key={s.id} value={s.id} selected={workExperience.industry === s.id}>{s.description}</option>
                                        )
                                    })
                                }
                            </select> : <div><div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                        </div>
                    </div>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Sector <span className={styles["color-red"]}>*</span></label>
                        <div className={styles["input-group"]}>
                            {!loadingSectors ? <select className={styles["select-col"]} disabled>
                                {
                                    sectors.map(s => {
                                        return (
                                            <option key={s.id} value={s.id} selected={workExperience.sector === s.id}>{s.description}</option>
                                        )
                                    })
                                }
                            </select> : <div><div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                        </div>
                    </div>
                </div>
                {/* <!-- Fila 5 --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Área de Trabajo <span className={styles["color-red"]}>*</span></label>
                        <div className={styles["input-group"]}>
                            {!loadingWorkAreas ? <select className={styles["select-col"]} disabled>
                                {
                                    workAreas.map(s => {
                                        return (
                                            <option key={s.id} value={s.id} selected={workExperience.workarea === s.id}>{s.description}</option>
                                        )
                                    })
                                }
                            </select> : <div><div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                        </div>
                    </div>
                </div>
                {/* <!-- Fila 6 --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Cargo Ocupado <span className={styles["color-red"]}>*</span></label>
                        <input type="text" className={styles["form-control"]} value={workExperience.position} disabled />
                    </div>
                </div>
                {/* <!-- Fila 7 --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Número de Personas a Cargo <span className={styles["color-red"]}>*</span></label>
                        <input type="text" className={styles["form-control"]} value={workExperience.subordinates} disabled />
                    </div>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Motivo Retiro <span className={styles["color-red"]}>*</span></label>
                        <div className={styles["input-group"]}>
                            {!loadingReasonsRetirement ? <select className={styles["select-col"]} disabled>
                                {
                                    reasonsRetirement.map(s => {
                                        return (
                                            <option key={s.id} value={s.id} selected={workExperience.reasons_retirement === s.id}>{s.description}</option>
                                        )
                                    })
                                }
                            </select> : <div><div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div></div>}
                        </div>
                    </div>
                </div>
                {/* <!-- Fila 8 --> */}
                <div className={styles["row"]}>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Fecha de Ingreso <span className={styles["color-red"]}>*</span></label>
                        <input type="date" className={styles["form-control"]} value={workExperience.start_date} disabled />
                    </div>
                    <div className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}>
                        <label className={styles["form-label"]}>Fecha de Retiro <span className={styles["color-red"]}>*</span></label>
                        <input type="date" className={styles["form-control"]} value={workExperience.end_date} disabled />
                    </div>
                </div>
                {/* <!-- fin filas --> */}
            </form>

            <div className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mt-4"]}`}>
                <div className={`${styles["col-6"]} ${styles["col-md-auto"]}`}>
                    <button type="submit" className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`} onClick={deleteOneWorkExperience} disabled={loadingDeleteWorkExperience}>Eliminar {loadingDeleteWorkExperience ? <div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div> : <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i>}</button>
                </div>
                <div className={`${styles["col-6"]} ${styles["col-md-auto"]}`}>
                    <button type="submit" className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`} onClick={() => changeDisplay(true, id, workExperience)} >Modificar <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i></button>
                </div>
            </div>
            {noDataDelete ? handleStatusError() : null}
            {/* <!-- Fin Ver Experiencia --> */}
        </div> : <div data-testid="loading" className={styles["full-spinner"]}>
            <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div> </div>}
    </>)
}

export default DetalleExperienciaLaboral;