import Auth from "@aws-amplify/auth";
import { readerTaskEither as RTE } from "fp-ts";
import { urlsAws } from "../../../../resources/foo/api-endpoints";
import { HttpClientEnv, AnnualBondCodec, AnnualBond, HttpError, PostAnnualBond, postAnnualBondCodec, ResponsePostAnnualBond, responsePostAnnualBondCodec, responsePostDocumentCodec, ResponsePostDocument, GetMyModelResponse, getMyModelResponseCodec, GetTeamAsignationResponse, getTeamAsignationResponseCodec} from "../interfaces";
import { getJson } from "./httpClient";

export const getAnnualBond = async (params: string = "") => {
    const authorization = await Auth.currentSession().then((auth) => auth.getIdToken().getJwtToken());
    const accessToken = await Auth.currentSession().then((auth) => auth.getAccessToken().getJwtToken());
    const requestOptions: RequestInit = {
        method: "GET",
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: authorization, AccessToken: accessToken},
    };
    const response: RTE.ReaderTaskEither<
            HttpClientEnv,
            HttpError,
            AnnualBond
        > = getJson(urlsAws.getAnnualBond, params, requestOptions, AnnualBondCodec.decode)
    
    return response;
};
export const postAnnualBond =async (body: PostAnnualBond) => {const authorization = await Auth.currentSession().then((auth) => auth.getIdToken().getJwtToken());
    const accessToken = await Auth.currentSession().then((auth) => auth.getAccessToken().getJwtToken());
    const requestOptions: RequestInit = {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: authorization, AccessToken: accessToken},
        body: JSON.stringify(body),
    }
    const response: RTE.ReaderTaskEither<
            HttpClientEnv,
            HttpError,
            ResponsePostAnnualBond
        > = getJson(urlsAws.postAnnualBond, "", requestOptions, responsePostAnnualBondCodec.decode)
    
    return response;
};
export const postDownloadDocumentAB =async (body: PostAnnualBond) => {const authorization = await Auth.currentSession().then((auth) => auth.getIdToken().getJwtToken());
    const accessToken = await Auth.currentSession().then((auth) => auth.getAccessToken().getJwtToken());
    const requestOptions: RequestInit = {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: authorization, AccessToken: accessToken},
        body: JSON.stringify(body),
    }
    const response: RTE.ReaderTaskEither<
            HttpClientEnv,
            HttpError,
            ResponsePostDocument
        > = getJson(urlsAws.postDownloadDocumentAB, "", requestOptions, responsePostDocumentCodec.decode)
    
    return response;
};
export const getMyModel = async (params: string = "") => {
    const authorization = await Auth.currentSession().then((auth) => auth.getIdToken().getJwtToken());
    const accessToken = await Auth.currentSession().then((auth) => auth.getAccessToken().getJwtToken());
    const requestOptions: RequestInit = {
        method: "GET",
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: authorization, AccessToken: accessToken},
    };
    const response: RTE.ReaderTaskEither<
            HttpClientEnv,
            HttpError,
            GetMyModelResponse
        > = getJson(urlsAws.getMyModel, params, requestOptions, getMyModelResponseCodec.decode)
    
    return response;
};
export const getTeamModel = async (params: string = "") => {
    const authorization = await Auth.currentSession().then((auth) => auth.getIdToken().getJwtToken());
    const accessToken = await Auth.currentSession().then((auth) => auth.getAccessToken().getJwtToken());
    const requestOptions: RequestInit = {
        method: "GET",
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: authorization, AccessToken: accessToken},
    };
    const response: RTE.ReaderTaskEither<
            HttpClientEnv,
            HttpError,
            GetTeamAsignationResponse
        > = getJson(urlsAws.getTeamModel, params, requestOptions, getTeamAsignationResponseCodec.decode)
    
    return response;
};