import React from 'react';
import { Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import logo from '../../resources/images/organigrama/cencosud-logo.png';

const options = { year: 'numeric', month: 'long', day: 'numeric' };
const formattedDate = new Date().toLocaleDateString('es-ES', options);

const styles = StyleSheet.create({
    page: {
        margin: 0,
        padding: 0,
    },
    container: {
        padding: 15,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#E2E4E9',
    },
    line: {
        width: 2,
        height: 20,
        backgroundColor: 'black',
        margin: '0 auto',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
        width: '100%',
        flexWrap: 'wrap',
        backgroundColor: '#CDE9F6',
        padding: 10,
    },
    horizontalLine: {
        borderBottom: '1pt solid black',
        flex: 1,
        marginHorizontal: 10,
    },
    personBox: {
        marginBottom: 10,
        width: 300,
        backgroundColor: 'white',
        borderRadius: 16,
    },
    image: {
        width: 50,
        height: 50,
        borderRadius: '50%',
        margin: '10px 0px 0px 10px',
    },
    collaborators: {
        margin: '10px 0px 0px 10px',
    },
    icon: {
        width: 20,
        height: 20,
    },
    flag: {
        width: 20,
        height: 20,
    },
    textHeader: {
        color: "white",
        padding: 10,
    },
    headerContainer: {
        backgroundColor: '#217DBE',
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        overflow: 'hidden',
    },
    headerText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
    header: {
        width: '100%',
        backgroundColor: '#217DBE',
        padding: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 0,
    },
    shortName: {
        width: 50,
        height: 50,
        borderRadius: '50%',
        backgroundColor: '#CCCCCC',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '10px 0px 0px 10px',
        fontWeight: 'bold',
    },
    positionText: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#3375CC',
    },
    areaText: {
        fontSize: 12,
        color: '#6B778C',
    },
    horizontalLine: {
        borderBottom: '1pt solid black',
        flex: 1,
        marginHorizontal: 10,
    },
    collaboratorsText: {
        color: '#344563',
        fontSize: 14,
    },
});

const PersonBox = ({ person }) => {
    if (!person) return null;

    const fullName = `${person.name} ${person.last_name}`;
    const truncatedFullName = fullName.length > 30 ? `${fullName.substring(0, 25)}...` : fullName;

    return (
        <View style={styles.personBox}>
            <View style={styles.headerContainer}>
                <Text style={styles.textHeader}>{truncatedFullName}</Text>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {person.photo ? (
                    <Image style={styles.image} src={person.photo} />
                ) : (
                    <View style={styles.shortName}>
                        <Text>{person.short_name}</Text>
                    </View>
                )}
                <View style={{ marginLeft: 15 }}>
                    <Text style={styles.positionText}>{person.position}</Text>
                    <Text style={styles.areaText}>{person.area}</Text>
                </View>
            </View>
            <View style={styles.collaborators}>
                <View style={[styles.horizontalLine, { borderColor: '#CACACA', margin: '10px' }]}></View>
                <Text style={styles.collaboratorsText}>Colaboradores</Text>
                <View style={{ flexDirection: 'row', alignItems: 'center', padding: 10 }}>
                    <Text style={{ fontSize: 12, marginRight: 'auto' }}>{person.internal}</Text>
                    <Text style={{ fontSize: 12, marginLeft: 'auto' }}>{person.country}</Text>
                </View>
            </View>
        </View>
    );
};

const Documento = ({ hierarchyTree }) => {
    const { BaseLevel, LastLevel } = hierarchyTree[0];
    return (
        <Document>
            <Page style={styles.page} size="A2">
                <View style={styles.header}>
                    <View style={styles.textContainer}>
                        <Text style={[styles.headerText, { fontSize: '25' }]}>{formattedDate}</Text>
                        <Text style={[styles.headerText, { fontWeight: 'heavy', fontSize: '25' }]}>{BaseLevel.report_to?.position}</Text>
                    </View>
                    <Image style={{ height: 100 }} src={logo} />
                </View>
                <View style={styles.container}>
                    {/* BaseLevel.report_to */}
                    <PersonBox person={BaseLevel.report_to} />
                    {/* Línea vertical */}
                    <View style={styles.line}></View>
                    {/* BaseLevel.person */}
                    <PersonBox person={BaseLevel.person} />
                    {/* Línea vertical */}
                    <View style={styles.line}></View>
                    {/* LastLevel */}
                    <View style={styles.row}>
                        {LastLevel.map((person, index) => (
                            <React.Fragment key={index}>
                                <View>
                                    <PersonBox person={person} />
                                </View>
                                {((index + 1) % 3 !== 0) && index !== LastLevel.length - 1 && <View style={styles.horizontalLine}></View>}
                            </React.Fragment>
                        ))}
                    </View>
                </View>
            </Page>
        </Document>
    );
};

const OrganigramaPDF = ({ hierarchyTree }) => (
    <Documento hierarchyTree={hierarchyTree} />
);

export default OrganigramaPDF;
