import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import GeoVictoriaService from "../services/geo-victoria-service";
import LibroAsistencia from "./libro-asistencia";
import PropTypes from 'prop-types';
import {Context } from "../context";

const GeoVictoria = () => {
    const { employeeId } = useContext(Context);
    const [parameters, setParameters] = useState(false);
    const [libroAsistencia, setLibroAsistencia] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (parameters)  GeoVictoriaService.libroAsistencia(employeeId, parameters, setLibroAsistencia, setError, setLoading);
    }, [parameters]);

    return <>
        <nav className="breadcrumbs" aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to="/inicio">
                        <i className="fa fa-home" aria-hidden="true"></i> Inicio
                    </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">Control de Asistencia</li>
            </ol>
        </nav>
        <section className="control-de-asistencia">
            <div className="tit-group d-block d-xxl-flex justify-content-between align-items-center mb-4">
                <h1 className="mb-3 mb-xxl-0">Control de Asistencia</h1>
                <div className="asistencia-geovictoria rounded-3 bg-light p-3 color-primary">
                    <span className="me-4">Más detalle de su asistencia aquí:</span>
                    <div className="text-end d-grid d-md-inline-block mt-2 mt-md-0">
                        <a href="https://cencosud.geovictoria.com/" target="_blank" rel="noreferrer" className="btn btn-degradado">Geovictoria</a>
                    </div>
                </div>
            </div>
            <div id='seccionCarpetaDigital'>
                <LibroAsistencia setParameters={setParameters} libro={libroAsistencia} emplid={employeeId} error={error} loading={loading}/>
            </div>
        </section>
        <a hidden id="btnModalInvalid" data-bs-toggle="modal" data-bs-target="#modalInvalid"></a>
        <div className="modal fade" id="modalInvalid" tabIndex="-1" style={{ zIndex: "1052" }} aria-labelledby="modalInvalid" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" style={{ zIndex: "2" }}>
                <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                        <button type="button" id="cerrarModalPer" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body text-center py-4">
                        <i className="fa fa-exclamation-triangle color-orange fa-3x" aria-hidden="true" />
                        <h2 className="h5 mt-3" id="msjModalInvalid"></h2>
                    </div>
                </div>
            </div>
        </div>
    </>
}

GeoVictoria.propTypes = {
    emplid: PropTypes.any,
};

export default GeoVictoria;