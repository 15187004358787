import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'reactstrap';
import Swal from 'sweetalert2'
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { toBase64 } from '../../services/utiles';
import styles from "../../resources/css/style.module.css"
import DatosLaboralesService from '../../services/datos laborales/datos-laborales-service';
import ProfilePicture from "@dsalvagni/react-profile-picture";
import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";

const validaciones = {
    DEFAULT: "Suelta tu foto aquí o toca para seleccionar.",
    DRAGOVER: "Suelta tu foto",
    INVALID_FILE_TYPE: "Solo se permiten imágenes."
};

const ModalAvatar = ({
    openModal,
    closeModal,
    updateAvatarHeader
}) => {
    const profilePictureRef = useRef(null);


    const handleStatusSuccess = () => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
            html: `<div style="text-align: center; font-size: 15px">Imagen actualizada correctamente</div>`,
            confirmButtonColor: '#11427C',
        }).then(() => window.location.reload());
    }

    const handleStatusError = (errorMessage) => {
        Swal.fire({
            allowOutsideClick: false,
            position: 'center',
            title: '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
            html: `<div style="text-align: center; font-size: 15px"> ${errorMessage}</div>`,
            confirmButtonColor: '#11427C',
        })
    }

    const updateAvatar = async () => {
        const pp = profilePictureRef.current;
        const base64File = pp.getImageAsDataUrl();
        try {
            if (pp.state.status == 'EMPTY') {

            } else {
                await DatosLaboralesService.saveAvatarRegional(base64File);
                updateAvatarHeader(base64File);
            }
            handleStatusSuccess();
        } catch (e) {
            console.log(e);
            handleStatusError("Ocurrió un error al actualizar la imagen de perfil");
        }
    }

    return (
        <Modal modalClassName='modal-properties' style={{ padding: 24 }} size="lg" centered isOpen={openModal}>
            <div className="modal-container">
                <div className="modal-data-title">
                    Imagen de Perfil
                    <AiOutlineCloseCircle title="Cerrar" type="button" className="dismiss-data-modal" onClick={() => { closeModal() }}></AiOutlineCloseCircle>
                </div>
                <div className="modal-data-body paddingModal">
                    <ProfilePicture
                        ref={profilePictureRef}
                        useHelper={true}
                        debug={false}
                        messages={validaciones}
                        minImageSize={1}
                    />
                </div>
                <div className="text-center mb-sm-4">
                    <button className="btn btn-primary me-1" onClick={() => updateAvatar()}>Actualizar</button>
                    <button className="btn btn-primary" onClick={() => closeModal()}>Cerrar</button>
                </div>
            </div>
        </Modal>
    );
};

export default ModalAvatar;