import { FC, ReactNode, useReducer } from "react";
import {
    JobInfo,
    SearchFilters,
    SearchFiltersV2,
} from "../../interfaces/applyInterface";
import { applyReducer } from "./applyReducer";
import { ApplyContext } from "./ApplyContext";

export interface IApplyContext {
    activeJob?: JobInfo;
    applyList?: JobInfo[];
    searchFilters?: SearchFilters;
    searchFiltersV2: SearchFiltersV2;
    filterSearchInput: string;
    filterArea: string;
    filterFlag: string;
    filterworkDay: string;
    filterUbication: string;
}

interface Props {
    children: ReactNode;
}

const INITIAL_STATE: IApplyContext = {
    applyList: [],
    searchFilters: {
        countries: [],
        areas: [],
        flags: [],
        ubications: [],
        workdays: [],
    },
    searchFiltersV2: {},
    filterSearchInput: "",
    filterArea: "",
    filterFlag: "",
    filterworkDay: "",
    filterUbication: "",
};

export const ApplyProvider: FC<Props> = ({ children }) => {
    const [state, dispatch] = useReducer(applyReducer, INITIAL_STATE);

    /**************** SEARCH FILTERS DISPATCHES ****************/
    const setFilterSearchInput = (filterSearchInput: string) => {
        dispatch({
            type: "SEARCH - Set Select SearchInput",
            payload: filterSearchInput,
        });
    };
    const setFilterArea = (filterArea: string) => {
        dispatch({ type: "SEARCH - Set Select Area", payload: filterArea });
    };
    const setFilterFlag = (filterFlag: string) => {
        dispatch({ type: "SEARCH - Set Select Flag", payload: filterFlag });
    };
    const setFilterworkDay = (filterworkDay: string) => {
        dispatch({
            type: "SEARCH - Set Select workDay",
            payload: filterworkDay,
        });
    };
    const setFilterUbication = (filterUbication: string) => {
        dispatch({
            type: "SEARCH - Set Select Ubication",
            payload: filterUbication,
        });
    };

    /**************** DETAILS JOB DISPATCHES ****************/
    const setSearchFilters = (filter: SearchFilters) => {
        dispatch({ type: "SEARCH - Set Filters Data", payload: filter });
    };

    const setSearchFiltersV2 = (filter: SearchFiltersV2) => {
        dispatch({ type: "SEARCH - Set Filters V2 Data", payload: filter });
    };

    const setApplyList = (applyList: JobInfo[]) => {
        dispatch({ type: "SEARCH - Set Apply List", payload: applyList });
    };

    const setToggleActiveApply = (applyId: string[]) => {
        dispatch({
            type: "SEARCH - Set Toggle Active Apply",
            payload: applyId,
        });
    };

    const setActiveAllAplys = () => {
        dispatch({ type: "SEARCH - Set All Applys Active" });
    };

    /**************** DETAILS JOB DISPATCHES ****************/
    const setActiveRequisition = (jobRequisition: JobInfo) => {
        dispatch({
            type: "JOB - Set Active Requisition",
            payload: jobRequisition,
        });
    };

    return (
        <ApplyContext.Provider
            value={{
                //Variables
                ...state,

                //Funciones
                setActiveAllAplys,
                setActiveRequisition,
                setApplyList,
                setSearchFilters,
                setSearchFiltersV2,
                setToggleActiveApply,
                setFilterSearchInput,
                setFilterArea,
                setFilterFlag,
                setFilterworkDay,
                setFilterUbication,
            }}
        >
            {children}
        </ApplyContext.Provider>
    );
};
