import React, { FC, useEffect, useRef, useState } from "react";
import codigoService from "../../../Compliance/services";
import { set } from "react-ga";

const ModalRevalidar : FC<any> = ({ refLoadClose, refLoadOpen, getConflicto }) => {
    const [revalidate, setRevalidate] = useState<boolean | null>(null);

    const onSubmit = async () => {
        document.getElementById('revalidatemodalbtn')?.click()
        refLoadOpen.current?.click();
        const data = await codigoService.revalidate();
        if (data && data.response.valid) {
            await getConflicto();
            setRevalidate(true);
        }
        else{
            setRevalidate(false);
        }
        refLoadClose.current?.click();
        document.getElementById('revalidatemodalbtn')?.click()
    }

    const closeModal = () => {
        setTimeout(() => {
            setRevalidate(null);
        }, 200);
    }

    return <>
        <div className="modal fade" id="revalidatemodal" tabIndex={-1} aria-labelledby="revalidatemodal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body text-center py-4">
                        <h2 className="h5">
                            { 
                                revalidate === null ?
                                    "¿Estás de acuerdo con confirmar que todos tus conflictos declarados se encuentran vigentes?"
                                    :
                                    revalidate ?
                                        "Todos los conflictos declarados han sido revalidados correctamente"
                                        :
                                        "Ha ocurrido un error al revalidar los conflictos"
                            }
                        </h2>
                    </div>
                    <div className="modal-footer border-0 pt-0">
                        {
                            revalidate === null ?
                            <>
                               <button type="button" className="btn btn-primary col-12 col-md-auto" data-bs-dismiss="modal" data-bs-toggle="modal" onClick={closeModal}>
                                    Cancelar
                                </button>
                                <button type="button" className="btn btn-degradado col-12 col-md-auto" onClick={onSubmit}>
                                    Continuar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                                </button> </>
                            :
                            <button type="button" className="btn btn-degradado col-12 col-md-auto" data-bs-dismiss="modal" data-bs-toggle="modal" onClick={closeModal}>
                                Continuar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
        <button hidden id="revalidatemodalbtn" data-bs-toggle="modal" data-bs-target="#revalidatemodal"></button>
    </>;
}

export default ModalRevalidar;