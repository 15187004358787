import React from "react";
import { BreadCrumbsDinamic } from "../../commonComponents/BreadCrumbsDinamic";
import { CencoTalentPage } from "../components/CencoTalent";
import { ApplyProvider, InfoProvider, UIProvider } from "../context";

const CencoTalent = () => {
    return (
        <>
            <BreadCrumbsDinamic />

            <UIProvider>
                <ApplyProvider>
                    <InfoProvider>
                        <CencoTalentPage />
                    </InfoProvider>
                </ApplyProvider>
            </UIProvider>
        </>
    );
};

export default CencoTalent;
