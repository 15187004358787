import React, { Children, useEffect, useMemo, useState } from "react";
import solicitudesService from "../services/solicitudes-service";
import moduloDtService from "../services/modulo-dt-service";
import { dotNumber, toBase64, validaRut, serialize } from "../services/utiles";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { RemoteJob } from "./RemoteJob/pages";

const OptionDef = (props) => {
    const [eliminarFamiliar, setEliminarFamiliar] = useState([]);
    const [tabla, setTabla] = useState(false);
    const data = props.data;
    const firstLogin = props.firstLogin;
    const familiarDef = props.familiar[0];

    const certificados = useMemo(() => {
        if (tabla != undefined && tabla != null && tabla != false) {
            return tabla[1];
        }
        if (Array.isArray(eliminarFamiliar)) {
            if (eliminarFamiliar[0] != undefined) {
                if (Array.isArray(eliminarFamiliar[0])) {
                    return eliminarFamiliar[0];
                }
            } else {
                return [];
            }
        }
        setEliminarFamiliar(false);
    }, [eliminarFamiliar, tabla]);

    let familiares = useMemo(() => {
        if (tabla != undefined && tabla != null && tabla != false) {
            return tabla[0];
        }
        if (Array.isArray(eliminarFamiliar)) {
            if (eliminarFamiliar[1] != undefined) {
                if (Array.isArray(eliminarFamiliar[1])) {
                    return eliminarFamiliar[1];
                }
            } else {
                return [];
            }
        }
        setTabla(false);
        setEliminarFamiliar(false);
    }, [eliminarFamiliar, tabla]);

    useEffect(() => {
        if (familiarDef != false && familiarDef != undefined && familiares != undefined) {
            let isfamiliarDef = false;
            isfamiliarDef = familiares.filter(fam => fam.fam_rut == familiarDef.fam_rut);
            if (isfamiliarDef != false && isfamiliarDef != undefined && isfamiliarDef.length > 0) {
                let filterFamiliares = [];
                filterFamiliares = familiares.filter(familia => familia.fam_rut != familiarDef.fam_rut);
                filterFamiliares.push(familiarDef);
                let filterCertificados = [];
                filterCertificados = certificados.filter(familia => familia.id != familiarDef.fam_rut);
                let filterCertificadosSoloIncluir = certificados.filter(familia => familia.id == familiarDef.fam_rut);
                props.familiar[1].forEach(element => {
                    if (element.doc.adjunto == '') {
                        filterCertificados.push(filterCertificadosSoloIncluir.filter(soloIncluye => soloIncluye.doc.descr == element.doc.descr)[0]);
                    } else {
                        filterCertificados.push(element);
                    }
                });
                setTabla([filterFamiliares, filterCertificados]);
            } else {
                familiares.push(props.familiar[0]);
                props.familiar[1].forEach(element => {
                    certificados.push(element);
                });
            }
            props.setFamiliar(false);
        }
    }, [familiarDef])

    if (data != false && data != undefined && firstLogin != false && firstLogin != undefined && data.bonos != undefined) {
        return <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true" /> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/mis-solicitudes">
                            <i className="breadcrumb-item" aria-hidden="true" /> Mis Solicitudes
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Bono por Defunción</li>
                </ol>
            </nav>
            <section className="bono-por-defuncion">
                <h1>Bono por Defunción</h1>
                <BotonSubmit emplid={props.emplid} reload={props.reload} optionSelected={props.optionSelected} dataOption="DEF" data={data} firstLogin={firstLogin} familiares={familiares} certificados={certificados}>
                    <MsjInicioBono tipoBono={"DEF"} data={data}>
                        <LeaveTable tipoBono={"DEF"} data={data} />
                        <TablaValoresBonos tipoBono={"DEF"} data={data} />
                    </MsjInicioBono>
                    <FormularioPrincipal optionSelected={props.optionSelected} data={data} firstLogin={firstLogin} />
                    <TablaFamiliares relationship={props.relationship} setRelationship={props.setRelationship} relationshipDescr={props.relationshipDescr} setRelationshipDescr={props.setRelationshipDescr} tipoBono="DEF" familiares={[familiares, certificados]} updateFam={setEliminarFamiliar} quitarEdit={setTabla} />
                </BotonSubmit>
            </section>
        </>
    } else {
        return null;
    }
}

const OptionMat = (props) => {
    const [statusPost, setStatusPost] = useState(false);
    const [msgPost, setMsjPost] = useState(false);
    const [btnEstado, setBtnEstado] = useState(true);
    const [btnEstado2, setBtnEstado2] = useState(true);
    const data = props.data;
    const firstLogin = props.firstLogin;
    let correoInput = "";
    let telefono = "";
    let fechaInicioPermiso = "";
    let correo = "";
    let estadoCivil = "";
    let primerNombreFam = "";
    let segundoNombreFam = "";
    let apellidoPaternoFam = "";
    let apellidoMaternoFam = "";
    let rutFam = "";
    let sexoFam = "";
    let fechaNacFam = "";
    let fechaMatrimonio = "";
    let relacion = "";
    let cedulaFrenteFamRaw = "";
    let cedulaReversaFamRaw  = "";
    let certificadoMatrimonioRaw  = "";
    let cedulaFrenteFam = "";
    let cedulaReversaFam = "";
    let certificadoMatrimonio = "";
    let cedulaFrenteFamExt = "";
    let cedulaReversaFamExt = "";
    let certificadoMatrimonioExt = "";

    const llenarCampos = async () => {
        correoInput = document.getElementById("correoInput").value.toLowerCase();
        telefono = document.getElementById("telefonoFP").value;
        correo = data.correo.toLowerCase();
        estadoCivil = document.getElementById("estadoCivil").value;
        primerNombreFam = document.getElementById("nombreFamiliarMat").value;
        segundoNombreFam = document.getElementById("segundoNombreFamiliarMat").value;
        apellidoPaternoFam = document.getElementById("apellidoPaternoMat").value;
        apellidoMaternoFam = document.getElementById("apellidoMaternoMat").value;
        rutFam = document.getElementById("rutMat").value;
        sexoFam = document.getElementById("sexoMat").value;
        fechaNacFam = document.getElementById("fechaNacimientoMat").value;
        fechaMatrimonio = document.getElementById("fechaMatrimonio").value;
        relacion = document.getElementById("relacionFamiliarMat").value;
        fechaInicioPermiso = document.getElementById("fechaInicioPermiso") !== null ? document.getElementById("fechaInicioPermiso").value : "";
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        llenarCampos();

        cedulaFrenteFamRaw = (await toBase64(document.getElementById("cedulaIdentidadFrenteMat").files[0]));
        cedulaReversaFamRaw  = (await toBase64(document.getElementById("cedulaIdentidadReverseMat").files[0]));
        certificadoMatrimonioRaw = (await toBase64(document.getElementById("certificadoMatrimonio").files[0]));

        cedulaFrenteFam = cedulaFrenteFamRaw.split("base64,")[1];
        cedulaReversaFam = cedulaReversaFamRaw.split("base64,")[1];
        certificadoMatrimonio = certificadoMatrimonioRaw.split("base64,")[1];

        cedulaFrenteFamExt = "." + cedulaFrenteFamRaw.split("/")[1].split(";")[0];
        cedulaReversaFamExt = "." + cedulaReversaFamRaw.split("/")[1].split(";")[0];
        certificadoMatrimonioExt = "." + certificadoMatrimonioRaw.split("/")[1].split(";")[0];

        const familiar = {
            "fam_rut": rutFam,
            "fam_nombre": primerNombreFam,
            "fam_nombre_seg": segundoNombreFam,
            "fam_apellido_pat": apellidoPaternoFam,
            "fam_apellido_mat": apellidoMaternoFam,
            "fam_relacion": relacion,
            "fam_sexo": sexoFam,
            "fam_pais": "CHL",
            "fam_estado_civil": "S",
            "fam_fecha_nac": fechaNacFam,
            "fam_fecha_act": fechaMatrimonio,
            "fam_tipo_beneficiario": "D"
        }
        const documentos = [
            {
                "descr": "Cedula frente familiar",
                "ext": cedulaFrenteFamExt,
                "adjunto": cedulaFrenteFam
            }, {
                "descr": "Cedula reverse familiar",
                "ext": cedulaReversaFamExt,
                "adjunto": cedulaReversaFam
            }, {
                "descr": "Certificado matrimonio",
                "ext": certificadoMatrimonioExt,
                "adjunto": certificadoMatrimonio
            }
        ];

        if (correoInput != correo) {
            document.getElementById("msjModalInvalid").innerHTML = "Error con el correo de confirmacion.";
            document.getElementById("btnModalInvalid").click();
            correoInput?.focus();
        } else if (!validaRut(rutFam)) {
            document.getElementById("msjModalInvalid").innerHTML = "Rut del familiar es invalido.";
            document.getElementById("btnModalInvalid").click();
        } else {
            let request = {
                "emplid": props.emplid,
                "correo": correo,
                "telefono": telefono,
                "estado_civil": estadoCivil,
                "tipo_bono": "MAT",
                "fecha_permiso": fechaInicioPermiso,
                "familiares": familiar,
                "documentos": documentos
            }
            let fd = serialize(request, { indices: true });
            document.getElementById("msjModalInvalid").innerHTML = "Enviando solicitud...";
            document.getElementById("btnModalInvalid")?.click();
            solicitudesService.postBono(fd, setStatusPost, setMsjPost);
        }
    }

    useEffect(() => {
        if (statusPost === 2 && msgPost != false) {
            setTimeout(() => {
                document.getElementById("cerrarModalPer").click();
            }, 500);
            setTimeout(() => {
                document.getElementById("msjModalInvalid").innerHTML = msgPost;
                document.getElementById("btnModalInvalid").click();
                setStatusPost(false);
                setMsjPost(false);
            }, 1000);
        } else if (statusPost == true && msgPost != false) {
            setTimeout(() => {
                document.getElementById("cerrarModalPer").click();
            }, 500);
            setTimeout(() => {
                document.getElementById("msjModalInvalid").innerHTML = msgPost;
                document.getElementById("btnModalInvalid").click();
                props.optionSelected("false");
                props.reload(true);
                setStatusPost(false);
                setMsjPost(false);
            }, 1000);
        }
    }, [statusPost, msgPost])

    const validarFormulario = ((e) => {
        e.preventDefault();
        llenarCampos();
        if (primerNombreFam == "" || apellidoPaternoFam == "" || apellidoMaternoFam == "" || rutFam == "" || fechaNacFam == "" || fechaMatrimonio == "" || btnEstado) {
            setBtnEstado2(true);
        } else {
            setBtnEstado2(false);
        }
    })

    if (data != false && data != undefined && firstLogin != false && firstLogin != undefined && data.bonos != undefined) {
        return <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true" /> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/mis-solicitudes">
                            <i className="breadcrumb-item" aria-hidden="true" /> Mis Solicitudes
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Bono por Matrimonio</li>
                </ol>
            </nav>
            <section className="bono-por-matrimonio">
                <h1>Bono por Matrimonio</h1>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="data-list-box">
                        <MsjInicioBono tipoBono={"MAT"} data={data}>
                            <LeaveTable tipoBono={"MAT"} data={data} />
                            <TablaValoresBonos tipoBono={"MAT"} data={data} />
                        </MsjInicioBono>
                        <FormularioPrincipal btn={setBtnEstado} optionSelected={"MAT"} data={data} firstLogin={firstLogin} />
                    </div>
                    <div className="box mb-4">
                        <h2 className="h4 tit-section">Familiares</h2>
                        <h3 className="h5">Debe ingresar los siguientes datos del Familiar</h3>
                        <div className="data-list-box data-list-box-even data-list-box-align-middle data-list-box-form">
                            <ul className="list-column-1 list-unstyled">
                                <li >
                                    <span className="data-list-box__title text-uppercase">Primer nombre *</span>
                                    <span className="data-list-box__text">
                                        <input type="text" onChange={e => validarFormulario(e)} id="nombreFamiliarMat" className="form-control" placeholder="Ingrese primer nombre" required="required"></input>
                                        <div className="invalid-feedback">
                                            Debe ingresar un nombre
                                        </div>
                                    </span>
                                </li>
                                <li>
                                    <span className="data-list-box__title text-uppercase">Segundo nombre</span>
                                    <span className="data-list-box__text">
                                        <input type="text" className="form-control" id="segundoNombreFamiliarMat" placeholder="Ingrese segundo nombre"></input>
                                        <div className="invalid-feedback">
                                            Debe ingresar un nombre
                                        </div>
                                    </span>
                                </li>
                                <li  >
                                    <span className="data-list-box__title text-uppercase">Apellido paterno *</span>
                                    <span className="data-list-box__text">
                                        <input type="text" onChange={e => validarFormulario(e)} className="form-control" id="apellidoPaternoMat" placeholder="Ingrese apellido paterno" required="required"></input>
                                        <div className="invalid-feedback">
                                            Debe ingresar un apellido
                                        </div>
                                    </span>
                                </li>
                                <li>
                                    <span className="data-list-box__title text-uppercase">Apellido materno *</span>
                                    <span className="data-list-box__text">
                                        <input type="text" onChange={e => validarFormulario(e)} className="form-control" id="apellidoMaternoMat" placeholder="Ingrese apellido materno" required="required"></input>
                                        <div className="invalid-feedback">
                                            Debe ingresar un apellido
                                        </div>
                                    </span>
                                </li>
                                <li>
                                    <span className="data-list-box__title text-uppercase">Rut *</span>
                                    <span className="data-list-box__text">
                                        <input type="text" onChange={e => validarFormulario(e)} className="form-control" id="rutMat" placeholder="111111-1" required="required"></input>
                                        <div className="invalid-feedback">
                                            Debe ingresar un rut válido
                                        </div>
                                    </span>
                                </li>
                                <li>
                                    <span className="data-list-box__title text-uppercase">Sexo *</span>
                                    <span className="data-list-box__text">
                                        <select className="form-select" id="sexoMat" required>
                                            {Children.toArray(data.generos.map(genero => {
                                                return <option value={genero.valor}>{genero.descr}</option>
                                            }))}
                                        </select>
                                        <div className="invalid-feedback">
                                            Debe seleccionar un campo
                                        </div>
                                    </span>
                                </li>
                                <li >
                                    <span className="data-list-box__title text-uppercase">Fecha de nacimiento *</span>
                                    <span className="data-list-box__text">
                                        <div className="row">
                                            <div className="col-10">
                                                <input onChange={e => validarFormulario(e)} className="form-control" type="date" max={new Date(`${new Date().getMonth() + 1}-${new Date().getDate()}-${new Date().getFullYear() - 16}`).toISOString().split("T")[0]} id="fechaNacimientoMat" placeholder="dd/mm/aaaa" required="required"></input>
                                                <div className="invalid-feedback">
                                                    Debe ingresar una fecha
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <i className="fa fa-calendar fa-fw mt-2 fa-lg color-primary" aria-hidden="true" />
                                            </div>
                                        </div>
                                    </span>
                                </li>
                                <li>
                                    <span className="data-list-box__title text-uppercase">Fecha de matrimonio *</span>
                                    <span className="data-list-box__text">
                                        <div className="row">
                                            <div className="col-10">
                                                <input onChange={e => validarFormulario(e)} className="form-control" id="fechaMatrimonio" type="date" placeholder="dd/mm/aaaa" required="required" max={new Date(`${new Date().getMonth() + 1}-${new Date().getDate()}-${new Date().getFullYear()}`).toISOString().split("T")[0]} ></input>
                                                <div className="invalid-feedback">
                                                    Debe ingresar una fecha
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <i className="fa fa-calendar fa-fw mt-2 fa-lg color-primary" aria-hidden="true" />
                                            </div>
                                        </div>
                                    </span>
                                </li>
                                <li >
                                    <span className="data-list-box__title text-uppercase">Relación *</span>
                                    <span className="data-list-box__text">
                                        <select id="relacionFamiliarMat" defaultValue="SP" className="form-select" disabled required>
                                            <option value="SP" >Conyuge</option>
                                        </select>
                                    </span>
                                </li>
                            </ul>
                            <small>(*) Campo obligatorio</small>
                        </div>
                    </div>
                    <div className="box mb-4">
                        <h2 className="h4 tit-section">Ingreso de Documentos</h2>
                        <h3 className="h5">Debe ingresar los siguientes documentos</h3>
                        <div className="data-list-box data-list-box-even data-list-box-align-middle data-list-box-form">
                            <ul className="list-column-1 list-unstyled">
                                <li>
                                    <span className="data-list-box__title text-uppercase">Cédula de identidad frontal *</span>
                                    <span className="data-list-box__text">
                                        <input type="file" accept=".jpg, .jpeg, .png, .pdf" id="cedulaIdentidadFrenteMat" className="form-control" aria-label="Cédula de identidad frontal" required="required"></input>
                                        <div className="invalid-feedback">
                                            Debe seleccionar un documento en formato PDF
                                        </div>
                                    </span>
                                </li>
                                <li>
                                    <span className="data-list-box__title text-uppercase">Cédula de identidad reverso *</span>
                                    <span className="data-list-box__text">
                                        <input type="file" className="form-control" aria-label="Cédula de identidad reverso" accept=".jpg, .jpeg, .png, .pdf" id="cedulaIdentidadReverseMat" required="required"></input>
                                        <div className="invalid-feedback">
                                            Debe seleccionar un documento en formato PDF
                                        </div>
                                    </span>
                                </li>
                                <li >
                                    <span className="data-list-box__title text-uppercase">Certificado de matrimonio *</span>
                                    <span className="data-list-box__text">
                                        <input type="file" className="form-control" accept=".jpg, .jpeg, .png, .pdf" aria-label="Certificado de matrimonio" id="certificadoMatrimonio" required="required"></input>
                                        <div className="invalid-feedback">
                                            Debe seleccionar un documento en formato PDF
                                        </div>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="text-end">
                        <button type="submit" id="btnSubmitMat" disabled={btnEstado || btnEstado2 ? true : false} className="btn btn-degradado me-4" >Enviar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" /></button>
                    </div>
                </form>
            </section>
        </>
    } else {
        return null;
    }
}

const OptionNac = (props) => {
    const data = props.data;
    const firstLogin = props.firstLogin;
    const [eliminarFamiliar, setEliminarFamiliar] = useState([]);
    const hijo = props.familiar[0];
    const [tabla, setTabla] = useState(false);

    let certificados = useMemo(() => {
        if (tabla != undefined && tabla != null && tabla != false) {
            return tabla[1];
        } else if (Array.isArray(eliminarFamiliar) && eliminarFamiliar.length > 0) {
            if (eliminarFamiliar[0] != undefined) {
                if (Array.isArray(eliminarFamiliar[0])) {
                    return eliminarFamiliar[0];
                }
            }
        } else {
            return [];
        }
    }, [eliminarFamiliar, tabla]);

    let familiares = useMemo(() => {
        if (tabla != undefined && tabla != null && tabla != false) {
            return tabla[0];
        } else if (Array.isArray(eliminarFamiliar) && eliminarFamiliar.length > 0) {
            if (eliminarFamiliar[1] != undefined) {
                if (Array.isArray(eliminarFamiliar[1])) {
                    return eliminarFamiliar[1];
                }
            }
        } else {
            return [];
        }
    }, [eliminarFamiliar, tabla]);

    useEffect(() => {
        if (hijo != false && hijo != undefined && familiares != undefined) {
            let isHijo = false;
            isHijo = familiares.filter(fam => fam.fam_rut == hijo.fam_rut);
            if (isHijo != false && isHijo != undefined && isHijo.length > 0) {
                let filterFamiliares = [];
                filterFamiliares = familiares.filter(familia => familia.fam_rut != hijo.fam_rut);
                filterFamiliares.push(hijo);
                let filterCertificados = [];

                filterCertificados = certificados.filter(familia => familia.id != hijo.fam_rut);
                let filterCertificadosSoloIncluir = certificados.filter(familia => familia.id == hijo.fam_rut);

                props.familiar[1].forEach(element => {
                    if (element.doc.adjunto == '') {
                        filterCertificados.push(filterCertificadosSoloIncluir.filter(soloIncluye => soloIncluye.doc.descr == element.doc.descr)[0]);
                    } else {
                        filterCertificados.push(element);
                    }
                });
                setTabla([filterFamiliares, filterCertificados]);
                setEliminarFamiliar([]);
            } else {
                familiares.push(props.familiar[0]);
                props.familiar[1].forEach(element => {
                    certificados.push(element);
                });
            }
            props.setFamiliar(false);
        }
    }, [hijo])

    if (data != false && data != undefined && firstLogin != false && firstLogin != undefined && data.bonos != undefined) {
        return <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true" /> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/mis-solicitudes">
                            <i className="breadcrumb-item" aria-hidden="true" /> Mis Solicitudes
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Bono por Nacimiento</li>
                </ol>
            </nav>
            <section className="bono-por-nacimiento">
                <h1>Bono por Nacimiento</h1>
                <BotonSubmit emplid={props.emplid} reload={props.reload} optionSelected={props.optionSelected} dataOption="NAC" data={data} firstLogin={firstLogin} familiares={familiares} certificados={certificados}>
                    <MsjInicioBono tipoBono={"NAC"} data={data}>
                        <LeaveTable tipoBono={"NAC"} data={data} />
                        <TablaValoresBonos tipoBono={"NAC"} data={data} />
                    </MsjInicioBono>
                    <FormularioPrincipal optionSelected={props.optionSelected} data={data} firstLogin={firstLogin} />
                    <TablaFamiliares relationship={props.relationship} setRelationship={props.setRelationship} relationshipDescr={props.relationshipDescr} setRelationshipDescr={props.setRelationshipDescr} familiares={[familiares, certificados]} updateFam={setEliminarFamiliar} quitarEdit={setTabla} />
                </BotonSubmit>
            </section>
        </>
    } else {
        return null;
    }
}

const BotonSubmit = (props) => {
    const [statusPost, setStatusPost] = useState(false);
    const [msgPost, setMsjPost] = useState(false);
    const data = props.data;
    const familiares = props.familiares;
    let cert = props.certificados;
    const dataOption = props.dataOption;

    const handleSubmit = (e) => {
        e.preventDefault();
        let correoInput = document.getElementById("correoInput").value.toLowerCase();
        let telefono = data.telefono;
        let fechaInicioPermiso = document.getElementById("fechaInicioPermiso");
        let correo = data.correo.toLowerCase();
        let estadoCivil = document.getElementById("estadoCivil").value;
        const newCert = cert.map(certificado => certificado.doc);

        if (!navigator.onLine) {
            document.getElementById("sinConexion").click();
        }
        else if (correoInput != correo) {
            document.getElementById("msjModalInvalid").innerHTML = "Error con el correo de confirmacion.";
            document.getElementById("btnModalInvalid").click();
        } else if (familiares.length == 0) {
            document.getElementById("msjModalInvalid").innerHTML = "Debe agrega un familiar a la lista.";
            document.getElementById("btnModalInvalid").click();
        } else {
            let request = {
                "emplid": props.emplid,
                "correo": correo,
                "telefono": telefono,
                "estado_civil": estadoCivil,
                "tipo_bono": dataOption,
                "fecha_permiso": fechaInicioPermiso !== null ? fechaInicioPermiso.value : "",
                "familiares": familiares,
                "documentos": newCert
            }

            let fd = serialize(request, { indices: true });
            document.getElementById("msjModalInvalid").innerHTML = "Enviando solicitud...";
            document.getElementById("btnModalInvalid").click();
            solicitudesService.postBono(fd, setStatusPost, setMsjPost);
        }
    }

    useEffect(() => {
        if (statusPost === 2 && msgPost != false) {
            setTimeout(() => {
                document.getElementById("cerrarModalPer").click();
            }, 500);
            setTimeout(() => {
                document.getElementById("msjModalInvalid").innerHTML = msgPost;
                document.getElementById("btnModalInvalid").click();
                setStatusPost(false);
                setMsjPost(false);
            }, 1000);
        } else if (statusPost == true && msgPost != false) {
            setTimeout(() => {
                document.getElementById("cerrarModalPer").click();
            }, 500);
            setTimeout(() => {
                document.getElementById("msjModalInvalid").innerHTML = msgPost;
                document.getElementById("btnModalInvalid").click();
                props.optionSelected("false");
                props.reload(true);
                setStatusPost(false);
                setMsjPost(false);
            }, 1000);
        }
    }, [statusPost, msgPost])

    return <>
        <form id="formPrincipal" onSubmit={(e) => handleSubmit(e)}>
            {props.children}
            <div className="text-end d-grid d-md-block">
                <button disabled id="btnSubmitP" type="submit" className="btn btn-degradado me-4">Enviar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" /></button>
            </div>
        </form>
    </>
}

const TablaFamiliares = (props) => {
    let fam = props.familiares[0];
    let cert = props.familiares[1];
    const [familiares, setFamiliares] = useState([]);
    const [certificados, setCertificados] = useState([]);

    const abrirModal = (e) => {
        e.preventDefault();
        props.setRelationship("D");
        props.setRelationshipDescr("Hijo/a");
        document.getElementById("formNac").reset();
        document.getElementById("btnSubmitModals").innerHTML = "Aceptar";
        document.getElementById("btnSubmitModals").disabled = true;

        let textCerDef = document.getElementById("parrafoCertDefEdit");
        let textNacCol = document.getElementById("parrafoCertificadoNacimientoColaborador");
        let textNacSon = document.getElementById(`parrafoCertificadoNacimientoHijo/a`);
        let textNacBro = document.getElementById(`parrafoCertificadoNacimientoHermano/a`);
        let textNacCon = document.getElementById(`parrafoCertificadoNacimientoCónyuge`);
        let textMatCol = document.getElementById(`parrafoCertificadoMatrimonioColaborador`);
        let texCertNac = document.getElementById("parrafoCertNac");

        if (textCerDef !== null) document.getElementById("parrafoCertDefEdit").style.display = "none";
        if (textNacCol !== null) document.getElementById("parrafoCertificadoNacimientoColaborador").style.display = "none";
        if (texCertNac !== null) document.getElementById("parrafoCertNac").style.display = "none";
        if (textNacSon !== null) document.getElementById(`parrafoCertificadoNacimientoHijo/a`).style.display = "none";
        if (textNacBro !== null) document.getElementById(`parrafoCertificadoNacimientoHermano/a`).style.display = "none";
        if (textNacCon !== null) document.getElementById(`parrafoCertificadoNacimientoCónyuge`).style.display = "none";
        if (textMatCol !== null) document.getElementById(`parrafoCertificadoMatrimonioColaborador`).style.display = "none";

        if (props.tipoBono == "DEF") {
            if (document.getElementById(`certificadoNacimientoHijo/a`) !== null && document.getElementById(`parrafoCertificadoNacimientoHijo/a`) !== null) {
                if (!document.getElementById(`certificadoNacimientoHijo/a`).hidden) {
                    document.getElementById(`certificadoNacimientoHijo/a`).setAttribute("required", true);
                    document.getElementById(`parrafoCertificadoNacimientoHijo/a`).style.display = "none";
                }
            }
            document.getElementById("certificadoDef").setAttribute("required", true);
            document.getElementById("parrafoCertDefEdit").style.display = "none";
        } else if (props.tipoBono == "NAC") {
            document.getElementById("certificadoNac").setAttribute("required", true);
            document.getElementById("parrafoCertDefEdit").style.display = "none";
        }
        document.getElementById("btnModalFormularioNac").click();
    }

    const editar = (familiar) => {
        document.getElementById("formNac").reset();
        document.getElementById("nombre").value = familiar.fam_nombre;
        document.getElementById("segNombre").value = familiar.fam_nombre_seg;
        document.getElementById("apellido").value = familiar.fam_apellido_pat;
        document.getElementById("segApellido").value = familiar.fam_apellido_mat;
        document.getElementById("rut").value = familiar.fam_rut;
        document.getElementById("sexo").value = familiar.fam_sexo;
        document.getElementById("fechaNac").value = familiar.fam_fecha_nac;
        document.getElementById("btnSubmitModals").disabled = false;

        if (props.tipoBono == "DEF") {
            document.getElementById("fechaDef").value = familiar.fam_fecha_act;
        }

        let cert = certificados.filter(certi => certi.id == familiar.fam_rut)

        cert.forEach(res => {
            if (res.doc.descr == "Certificado de Defuncion") {
                // document.getElementById("certificadoDef").files[0] = res.doc.adjunto;
                // document.getElementById("certificadoDef").removeAttribute("required");
                document.getElementById("certDefText").innerHTML = res.doc.descr;
                // document.getElementById("parrafoCertDefEdit").style.display = "block";
            } else if (res.doc.descr == "Certificado de Nacimiento Colaborador") {
                // document.getElementById("certificadoNacimientoColaborador").files[0] = res.doc.adjunto;
                // document.getElementById("certificadoNacimientoColaborador").removeAttribute("required");
                document.getElementById("nombreCertificadoNacimientoColaboradorTexto").innerHTML = res.doc.descr;
                // document.getElementById("parrafoCertificadoNacimientoColaborador").style.display = "block";
            } else if (res.doc.descr == "Certificado de Nacimiento") {
                // document.getElementById("certificadoNac").files[0] = res.doc.adjunto;
                // document.getElementById("certificadoNac").removeAttribute("required");
                document.getElementById("nombreCertNacTexto").innerHTML = res.doc.descr;
                // document.getElementById("parrafoCertNac").style.display = "block";
            } else if (res.doc.descr == `Certificado de Nacimiento Hijo/a`) {
                // document.getElementById(`certificadoNacimientoHijo/a`).files[0] = res.doc.adjunto;
                // document.getElementById(`certificadoNacimientoHijo/a`).removeAttribute("required");
                document.getElementById(`nombreCertificadoNacimientoHijo/aTexto`).innerHTML = res.doc.descr;
                // document.getElementById(`parrafoCertificadoNacimientoHijo/a`).style.display = "block";
            } else if (res.doc.descr == `Certificado de Nacimiento Cónyuge`) {
                // document.getElementById(`certificadoNacCónyuge`).files[0] = res.doc.adjunto;
                // document.getElementById(`certificadoNacCónyuge`).removeAttribute("required");
                document.getElementById(`nombreCertificadoNacimientoCónyugeTexto`).innerHTML = res.doc.descr;
                // document.getElementById(`parrafoCertificadoNacimientoCónyuge`).style.display = "block";
            } else if (res.doc.descr == `Certificado de Nacimiento Hermano/a`) {
                // document.getElementById(`certificadoNacimientoHermano/a`).files[0] = res.doc.adjunto;
                // document.getElementById(`certificadoNacimientoHermano/a`).removeAttribute("required");
                document.getElementById(`nombreCertificadoNacimientoHermano/aTexto`).innerHTML = res.doc.descr;
                // document.getElementById(`parrafoCertificadoNacimientoHermano/a`).style.display = "block";
            } else if (res.doc.descr == "Certificado de Matrimonio Colaborador") {
                // document.getElementById("certificadoMatrimonioColaborador").files[0] = res.doc.adjunto;
                // document.getElementById("certificadoMatrimonioColaborador").removeAttribute("required");
                document.getElementById("nombreCertificadoColaboradorTexto").innerHTML = res.doc.descr;
                // document.getElementById("parrafoCertificadoColaborador").style.display = "block";
            }
        })

        document.getElementById("btnSubmitModals").innerHTML = "Modificar";
        document.getElementById("btnModalFormularioNac").click();
    }

    const eliminarFamiliar = (rut) => {
        const newFamiliares = familiares.filter(familiar => familiar.fam_rut != rut);
        const newCertificados = certificados.filter(certificado => certificado.id != rut);
        props.updateFam([newCertificados, newFamiliares]);
        props.quitarEdit(false);
    }
    useEffect(() => {
    }, [familiares])

    useEffect(() => {
        if (fam != undefined) {
            setFamiliares(fam);
        }
    }, [fam]);

    useEffect(() => {
        if (cert != undefined) {
            setCertificados(cert);
        }
    }, [fam])

    return <>
        <div className="box mb-4">
            <h2 className="h4 tit-section">Familiares</h2>
            <div className="border-bottom border-primary border-4 p-2">
                <div className="tit-group d-flex justify-content-between align-items-center">
                    <h3 className="h5 m-0">Debe ingresar los siguientes datos del Familiar</h3>
                    <a href="#" onClick={(e) => abrirModal(e)} className="mt-2" ><i className="fa fa-plus-square-o fa-lg fa-fw" aria-hidden="true" /></a>
                </div>
            </div>
            {familiares != undefined && familiares.length > 0 && certificados.length > 0 ?
                <table className="table table-even table-section table-borderless text-uppercase d-none d-md-table align-middle">
                    <thead>
                        <tr>
                            <th>RUT</th>
                            <th>NOMBRE</th>
                            <th>APELLIDO</th>
                            <th className="text-center">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Children.toArray(familiares.map(familiar => {
                            return <>
                                <tr>
                                    <td>{familiar.fam_rut}</td>
                                    <td>{familiar.fam_nombre + " " + familiar.fam_nombre_seg}</td>
                                    <td>{familiar.fam_apellido_pat + " " + familiar.fam_apellido_mat}</td>
                                    <td className="text-center">
                                        <a type="button" onClick={() => editar(familiar)}><i className="fa fa-pencil fa-fw me-2" aria-hidden="true" /></a>
                                        <a type="button" onClick={() => eliminarFamiliar(familiar.fam_rut)}><i className="fa fa-trash-o fa-fw me-2" aria-hidden="true" /></a>
                                    </td>
                                </tr>
                            </>
                        }))}
                    </tbody>
                </table>
                : "No hay registro"
            }
            <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
                <div className="accordion-item">
                    {familiares.length > 0 && certificados.length > 0 ?
                        Children.toArray(familiares.map((familiar, i) => {
                            return <>
                                <h2 className="accordion-header" id="accordion">
                                    <button className={i == 0 ? "accordion-button " : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={"#accordion-item-familiares-" + i} aria-expanded="true" aria-controls="accordion-item-familiares-1">
                                        <strong className="text-uppercase">Rut</strong> {familiar.fam_rut}
                                    </button>
                                </h2>
                                <div id={"accordion-item-familiares-" + i} className={i == 0 ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="accordion">
                                    <div className="accordion-body p-0">

                                        <div className="data-list-box text-uppercase">
                                            <ul className="data-list-section--item list-unstyled">
                                                <li>
                                                    <span className="data-list-box__title">Nombre</span>
                                                    <span className="data-list-box__text">{familiar.fam_nombre + " " + familiar.fam_nombre_seg}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Apellido</span>
                                                    <span className="data-list-box__text">{familiar.fam_apellido_pat + " " + familiar.fam_apellido_mat}</span>
                                                </li>
                                                <li>
                                                    <span className="data-list-box__title">Acciones</span>
                                                    <span className="data-list-box__text">
                                                        <a onClick={() => editar(familiar)}><i className="fa fa-pencil fa-fw me-2" aria-hidden="true" /></a>
                                                        <a onClick={() => eliminarFamiliar(familiar.fam_rut)}><i className="fa fa-trash-o fa-fw" aria-hidden="true" /></a>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </>
                        }))
                        : null
                    }
                </div>
            </div>
        </div>
    </>
}

const TablaValoresBonos = (props) => {
    let data = props.data;
    return <>
        <p> {"*" + "Tabla de pagos:"}  </p>
        <table className="table table-even table-th-border table-section table-borderless text-uppercase align-middle">
            <thead>
                <tr>
                    <th>Descipcion</th>
                    <th>Valor</th>
                </tr>
            </thead>
            <tbody>
                {data != false && data.bonos != undefined && data.bonos.length > 0 ?
                    Children.toArray(data.bonos.map((element) => {
                        if (element.valor_bono == props.tipoBono && element.pagos != undefined) {
                            if (!Array.isArray(element.pagos)) {
                                element.pagos = [element.pagos];
                            }
                            let pagos = Children.toArray(element.pagos.map(pago => {
                                return <>
                                    <tr>
                                        <td>{pago.descr}</td>
                                        <td>${dotNumber(pago.valor)}</td>
                                    </tr>
                                </>
                            }));
                            return pagos;
                        }
                    }))
                    : "Cargando..."}
            </tbody>
        </table>
    </>
}

const LeaveTable = (props) => {
    let data = props.data;
    return <>
        <table className="table table-even table-th-border table-section table-borderless text-uppercase align-middle">
            <thead>
                <tr>
                    <th>Familiar</th>
                    <th>Días Permiso</th>
                </tr>
            </thead>
            <tbody>
                {data != false && data.bonos != undefined && data.bonos.length > 0 ?
                    Children.toArray(data.bonos.map((element) => {
                        if (element.valor_bono == props.tipoBono && element.permisos != undefined) {
                            if (!Array.isArray(element.permisos)) {
                                element.permisos = [element.permisos];
                            }
                            let permisos = Children.toArray(element.permisos.map(pago => {
                                return <>
                                    <tr>
                                        <td>{pago.descr}</td>
                                        <td>{pago.valor}</td>
                                    </tr>
                                </>
                            }));
                            return permisos;
                        }
                    }))
                    : "Cargando..."}
            </tbody>
        </table>
    </>
}

const MsjInicioBono = (props) => {
    let data = props.data;
    return <>
        <div className="box mb-4">
            <h2 className="h4 tit-section">Formulario</h2>
            <h3 className="h5">Detalle</h3>
            {data != false && data.bonos != undefined && data.bonos.length > 0 ?
                <>
                    {data?.temporal &&
                        <p className="color-orange">{"*Usted posee nuevo convenio, los valores reflejados están en proceso de actualización."}</p>
                    }
                    {Children.toArray(data.bonos.map((element) => {
                        return element.valor_bono == props.tipoBono &&
                            <>
                                <p> {"*" + element.descr_larga}  </p>
                                <p> {"*" + "Días de permiso:"}  </p>
                            </>
                        })
                    )}
                </>
                : "Cargando..."}
            {props.children}
        </div>
    </>
}

const FormularioPrincipal = (props) => {
    const data = props.data;
    const firstLogin = props.firstLogin;
    const [wantDays, setWantDays] = useState(true);
    const validarF = (e) => {
        e.preventDefault();
        const correoInput = document.getElementById("correoInput").value.toLowerCase();
        const btnSubmitP = document.getElementById("btnSubmitP");
        if (correoInput == "") {
            if (props.optionSelected == "MAT") {
                props.btn(true)
            } else {
                btnSubmitP.disabled = true;
            }
        } else {
            if (props.optionSelected == "MAT") {
                props.btn(false)

            } else {
                btnSubmitP.disabled = false;
            }
        }
    }

    return <>
        <div className="box mb-4">
            <h2 className="h4 tit-section">Mis datos</h2>
            <div className="data-list-box data-list-box-even data-list-box-align-middle data-list-box-form">
                <ul className="list-column-1 list-unstyled">
                    <li >
                        <span className="data-list-box__title text-uppercase">RUT</span>
                        <span className="data-list-box__text">
                            <input className="form-control" value={firstLogin != false ? firstLogin.emplid : "no se encontro dato"} type="text" placeholder="11111111-1" readOnly></input>
                        </span>
                    </li>
                    <li >
                        <span className="data-list-box__title text-uppercase">Nombre</span>
                        <span className="data-list-box__text">
                            <input className="form-control" type="text" value={firstLogin != false ? firstLogin.name : "no se encontro dato"} placeholder="Juan Pérez Pérez" disabled></input>
                        </span>
                    </li>
                    <li >
                        <span className="data-list-box__title text-uppercase">Teléfono celular *</span>
                        <span className="data-list-box__text">
                            <input className="form-control" onChange={e => validarF(e)} id="telefonoFP" defaultValue={data.telefono} type="text" placeholder="912345678"></input>
                        </span>
                    </li>
                    <li>
                        <span className="data-list-box__title text-uppercase">Correo personal *</span>
                        <span className="data-list-box__text">
                            <input className="form-control" value={data.correo} disabled type="email" placeholder="juan.perez@gmail.com" onInput={(e) => e.target.setCustomValidity("")} onInvalid={(e) => e.target.setCustomValidity("Correo Invalido")} ></input>
                        </span>
                    </li>
                    <li>
                        <span className="data-list-box__title text-uppercase">Confirme correo personal *</span>
                        <span className="data-list-box__text">
                            <input type="email" id="correoInput" onChange={e => validarF(e)} className="form-control" placeholder="juan.perez@gmail.com" onInput={(e) => e.target.setCustomValidity("")} onInvalid={(e) => e.target.setCustomValidity("Correo Invalido")} required></input>
                        </span>
                    </li>
                    <li>
                        <span className="data-list-box__title text-uppercase">Estado civil *</span>
                        <span className="data-list-box__text">
                            <select id="estadoCivil" defaultValue={props.optionSelected && props.optionSelected === "MAT" ? "M" : data.estado_civil} className="form-select" required="required" disabled={props.optionSelected && props.optionSelected === "MAT"}>
                                {Children.toArray(data.estados_civiles.map(estado => {
                                    return <>
                                        <option value={estado.valor} >{estado.descr}</option>
                                    </>
                                }))}
                            </select>
                            <div className="invalid-feedback">
                                Debe seleccionar un campo
                            </div>
                        </span>
                    </li>
                    <li>
                        <span className="data-list-box__title text-uppercase">¿Desea solicitar días de permiso? *</span>
                        <span className="data-list-box__text">
                            <select id="wannaDays" defaultValue={"Y"} value={wantDays ? "Y" : "N"} onChange={((e) => { setWantDays(e.target.value === "Y" ? true : false); validarF(e) })} className="form-select" required="required">
                                <option value={"Y"} >{"Si"}</option>
                                <option value={"N"} >{"No"}</option>
                            </select>
                            <div className="invalid-feedback">
                                Debe seleccionar un campo
                            </div>
                        </span>
                    </li>
                    {wantDays ?
                        <li>
                            <span className="data-list-box__title text-uppercase">Fecha inicio permiso*</span>
                            <span className="data-list-box__text">
                                <div className="row">
                                    <div className="col-9">
                                        <input className="form-control" onChange={e => validarF(e)} id="fechaInicioPermiso" type="date" required={wantDays} placeholder="dd/mm/aaaa" ></input>
                                        <div className="invalid-feedback">
                                            Debe ingresar una fecha
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-calendar fa-fw mt-2 fa-lg color-primary" aria-hidden="true" />
                                    </div>

                                </div>
                            </span>
                        </li>
                    : null}
                </ul>
                <small>(*) Campo obligatorio</small>
            </div>
        </div>
    </>
}

const Modals = (props) => {
    let data = props.data;
    let optionSelected = props.optionSelected;
    const relationship = props.relationship;
    const setRelationship = props.setRelationship;
    const setRelationshipDescr = props.setRelationshipDescr;
    const relationshipList = Array.isArray(data.relaciones) ? data.relaciones.filter((relacion) => {
        return data.empresa !== "281" ? relacion.valor !== "FI" : true;
    }) : [data.relaciones];

    useEffect(() => {
        relationshipList && setRelationshipDescr(relationshipList.filter((rel) => rel.valor === relationship)[0].descr);
    }, [relationship]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const nombre = document.getElementById("nombre").value;
        const segNombre = document.getElementById("segNombre").value;
        const apellido = document.getElementById("apellido").value;
        const segApellido = document.getElementById("segApellido").value;
        const rut = document.getElementById("rut").value;
        const sexo = document.getElementById("sexo").value;
        const relacion = document.getElementById("selectRelacion").value;
        const documento = [];
        let fechaNac = document.getElementById("fechaNac").value;
        let certificadoNac = "";
        let certificadoNacExt = "";
        let defCertList = [];
        let fechaDef = "";
        let certificadoDefuncion = "";
        let certificadoDefuncionExt = "";
        if (optionSelected == "NAC") {
            if (document.getElementById("certificadoNac").files.length > 0) {
                certificadoNac = (await toBase64(document.getElementById("certificadoNac").files[0])).split("base64,")[1];
                certificadoNacExt = (await toBase64(document.getElementById("certificadoNac").files[0])).split("/")[1].split(";")[0];
            }
        } else if (optionSelected == "DEF") {
            fechaDef = document.getElementById("fechaDef").value;
            if (document.getElementById("certificadoDef").files.length > 0) {
                certificadoDefuncion = (await toBase64(document.getElementById("certificadoDef").files[0])).split("base64,")[1]
                certificadoDefuncionExt = (await toBase64(document.getElementById("certificadoDef").files[0])).split("/")[1].split(";")[0]
            }
            if (document.getElementById("certificadoNacimientoColaborador") !== null && !document.getElementById("certificadoNacimientoColaborador").hidden && document.getElementById("certificadoNacimientoColaborador").files.length > 0) {
                defCertList.push(
                    {
                        name: "Certificado de Nacimiento Colaborador",
                        file: (await toBase64(document.getElementById("certificadoNacimientoColaborador").files[0])).split("base64,")[1],
                        ext: (await toBase64(document.getElementById("certificadoNacimientoColaborador").files[0])).split("/")[1].split(";")[0],
                    }
                );
            }
            if (document.getElementById(`certificadoNacimientoHijo/a`) !== null && !document.getElementById(`certificadoNacimientoHijo/a`).hidden && document.getElementById(`certificadoNacimientoHijo/a`).files.length > 0) {
                defCertList.push(
                    {
                        name: `Certificado de Nacimiento Hijo/a`,
                        file: (await toBase64(document.getElementById(`certificadoNacimientoHijo/a`).files[0])).split("base64,")[1],
                        ext: (await toBase64(document.getElementById(`certificadoNacimientoHijo/a`).files[0])).split("/")[1].split(";")[0],
                    }
                );
            }
            if (document.getElementById(`certificadoNacimientoHermano/a`) !== null && !document.getElementById(`certificadoNacimientoHermano/a`).hidden && document.getElementById(`certificadoNacimientoHermano/a`).files.length > 0) {
                defCertList.push(
                    {
                        name: `Certificado de Nacimiento Hermano/a`,
                        file: (await toBase64(document.getElementById(`certificadoNacimientoHermano/a`).files[0])).split("base64,")[1],
                        ext: (await toBase64(document.getElementById(`certificadoNacimientoHermano/a`).files[0])).split("/")[1].split(";")[0],
                    }
                );
            }
            if (document.getElementById(`certificadoNacimientoCónyuge`) !== null && !document.getElementById(`certificadoNacimientoCónyuge`).hidden && document.getElementById(`certificadoNacimientoCónyuge`).files.length > 0) {
                defCertList.push(
                    {
                        name: `Certificado de Nacimiento Cónyuge`,
                        file: (await toBase64(document.getElementById(`certificadoNacimientoCónyuge`).files[0])).split("base64,")[1],
                        ext: (await toBase64(document.getElementById(`certificadoNacimientoCónyuge`).files[0])).split("/")[1].split(";")[0],
                    }
                );
            }
            if (document.getElementById(`certificadoMatrimonioColaborador`) !== null && !document.getElementById(`certificadoMatrimonioColaborador`).hidden && document.getElementById(`certificadoMatrimonioColaborador`).files.length > 0) {
                defCertList.push(
                    {
                        name: "Certificado de Matrimonio Colaborador",
                        file: (await toBase64(document.getElementById(`certificadoMatrimonioColaborador`).files[0])).split("base64,")[1],
                        ext: (await toBase64(document.getElementById(`certificadoMatrimonioColaborador`).files[0])).split("/")[1].split(";")[0],
                    }
                );
            }

        }
        if (!validaRut(rut)) {
            document.getElementById("msjModalInvalid").innerHTML = 'Rut del familiar es invalido.';
            document.getElementById("btnModalInvalid").click();
        } else {
            const familiar = {
                "fam_rut": rut,
                "fam_nombre": nombre,
                "fam_nombre_seg": segNombre,
                "fam_apellido_pat": apellido,
                "fam_apellido_mat": segApellido,
                "fam_relacion": relacion,
                "fam_sexo": sexo,
                "fam_pais": "CHL",
                "fam_estado_civil": "S",
                "fam_fecha_nac": fechaNac,
                "fam_fecha_act": optionSelected == "NAC" ? fechaNac : optionSelected == "DEF" ? fechaDef : null,
                "fam_tipo_beneficiario": "D"
            }
            if (optionSelected == "NAC") {
                documento.push({
                    id: rut,
                    doc: {
                        "descr": "Certificado de Nacimiento",
                        "ext": "." + certificadoNacExt,
                        "adjunto": certificadoNac,
                    }
                });
            } else if (optionSelected === "DEF" && defCertList.length > 0) {
                defCertList.forEach((cert) => {
                    documento.push(
                        {
                            id: rut,
                            doc: {
                                "descr": cert.name,
                                "ext": "." + cert.ext,
                                "adjunto": cert.file,
                            }
                        }
                    );
                });
            }
            if (optionSelected == "DEF") {
                documento.push({
                    id: rut,
                    doc: {
                        "descr": "Certificado de Defuncion",
                        "ext": "." + certificadoDefuncionExt,
                        "adjunto": certificadoDefuncion
                    }
                });
            }
            props.familiar([familiar, documento])
            document.getElementById("cerrarModalNac").click();
            //document.getElementById("formNac").reset();
        }
    }

    const validInput = (e) => {
        e.preventDefault();
        const btnSubmit = document.getElementById("btnSubmitModals");
        const nombre = document.getElementById("nombre").value;
        const apellido = document.getElementById("apellido").value;
        const segApellido = document.getElementById("segApellido").value;
        const rut = document.getElementById("rut").value;
        const sexo = document.getElementById("sexo").value;
        const fechaNac = document.getElementById("fechaNac").value;
        let fechaDef = "";

        if (optionSelected == "DEF") {
            fechaDef = document.getElementById("fechaDef").value;
        }
        if (nombre == "" || apellido == "" || segApellido == "" || rut == "" || sexo == "" || fechaNac == "") {
            btnSubmit.disabled = true;
        } else {
            if (optionSelected == "NAC") {
                btnSubmit.disabled = false;
            }
            if (optionSelected == "DEF") {
                if (fechaDef == "") {
                    btnSubmit.disabled = true;
                } else {
                    btnSubmit.disabled = false;
                }
            }
        }
    }

    if (data != undefined && data != false && data.relaciones != undefined) {
        return <>
            <a hidden id="btnModalFormularioNac" data-backdrop="static" data-keyboard="false" data-bs-toggle="modal" data-bs-target="#modalFormularioNac"></a>
            <div className="modal fade" data-backdrop="static" style={{ zIndex: "1051" }} data-keyboard="false" id="modalFormularioNac" tabIndex="1" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <form id="formNac" onSubmit={(e) => handleSubmit(e)}>
                            <div className="modal-header border-0 pb-0">
                                <button type="button" id="cerrarModalNac" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <h2 className="h5 text-uppercase mb-4">Familiar</h2>
                                <div className="mb-3">
                                    <label className="form-label mb-2"><strong>Relación</strong></label>
                                    <select className="form-select" id="selectRelacion" required  defaultValue="D" disabled={optionSelected == "NAC" ? true : false} onChange={(e) => { setRelationship(e.target.value) }}>
                                        {Children.toArray(relationshipList.map((relacion) => <option value={relacion.valor} >{relacion.descr}</option>))}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label mb-2"><strong>Primer nombre</strong></label>
                                    <input onChange={(e) => validInput(e)} type="text" id={"nombre"} required className="form-control" placeholder="Ingrese primer nombre"></input>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label mb-2"><strong>Segundo nombre</strong></label>
                                    <input onChange={(e) => validInput(e)} type="text" id={"segNombre"} className="form-control" placeholder="Ingrese segundo nombre"></input>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label mb-2"><strong>Primer apellido</strong></label>
                                    <input onChange={(e) => validInput(e)} type="text" id={"apellido"} className="form-control" required placeholder="Ingrese primer apellido"></input>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label mb-2"><strong>Segundo apellido</strong></label>
                                    <input onChange={(e) => validInput(e)} type="text" id={"segApellido"} className="form-control" required placeholder="Ingrese segundo apellido"></input>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label mb-2"><strong>Rut</strong></label>
                                    <input onChange={(e) => validInput(e)} type="text" id={"rut"} className="form-control" placeholder="11111111-1" required></input>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label mb-2"><strong>Sexo</strong></label>
                                    <select onChange={(e) => validInput(e)} className="form-select" id={"sexo"}>
                                        {Children.toArray(data.generos.map(genero => {
                                            return <option value={genero.valor} >{genero.descr}</option>
                                        }))}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label mb-2"><strong>Fecha de Nacimiento</strong></label>
                                    <div className="row">
                                        <div className="col-10">
                                            <input onChange={(e) => validInput(e)} className="form-control" max={new Date().toISOString().split("T")[0]} type="date" id="fechaNac" placeholder="dd/mm/aaaa" required="required"></input>
                                            <div className="invalid-feedback">
                                                Debe ingresar una fecha
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <i className="fa fa-calendar fa-fw mt-2 fa-lg color-primary" aria-hidden="true" />
                                        </div>
                                    </div>
                                </div>
                                {optionSelected == "NAC" ?
                                    <>
                                        <div className="mb-3">
                                            <label className="form-label mb-2"><strong>Certificado de Nacimiento</strong></label>
                                            <input onChange={(e) => validInput(e)} accept=".jpg, .jpeg, .png, .pdf" type="file" id="certificadoNac" required className="form-control" aria-label="Certificado de Nacimiento"></input>
                                            <p style={{ display: "none" }} className="mt-2 fw-bold" id="parrafoCertNac"><i className="fa fa-check-square fa-fw color-primary" aria-hidden="true" /> <a href="#" id="nombreCertNacTexto">Certificado.pdf</a></p>
                                        </div>
                                    </>
                                    : optionSelected == "DEF" ?
                                        <>
                                            <div className="mb-3">
                                                <label className="form-label mb-2"><strong>Fecha de Defunción</strong></label>
                                                <div className="row">
                                                    <div className="col-10">
                                                        <input onChange={(e) => validInput(e)} className="form-control" max={new Date().toISOString().split("T")[0]} type="date" id="fechaDef" placeholder="dd/mm/aaaa" required="required"></input>
                                                        <div className="invalid-feedback">
                                                            Debe ingresar una fecha
                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        <i className="fa fa-calendar fa-fw mt-2 fa-lg color-primary" aria-hidden="true" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label mb-2"><strong>Certificado de Defunción</strong></label>
                                                <input onChange={(e) => validInput(e)} type="file" className="form-control" aria-label="Certificado de Defuncion" accept=".jpg, .jpeg, .png, .pdf" id="certificadoDef" required ></input>
                                                <p className="mt-2 fw-bold" id="parrafoCertDefEdit" style={{ display: "none" }}><i className="fa fa-check-square fa-fw color-primary" aria-hidden="true" /> <a id="certDefText" href="#" onClick={(e) => e.preventDefault()}>certificado.pdf</a></p>
                                            </div>
                                            <div hidden={relationship !== "SP" && relationship !== "FI"} className="mb-3">
                                                <label className="form-label mb-2"><strong>Certificado de Matrimonio Colaborador</strong></label>
                                                <input onChange={(e) => validInput(e)} accept=".jpg, .jpeg, .png, .pdf" type="file" id="certificadoMatrimonioColaborador" required={relationship === "SP" || relationship === "FI"} className="form-control" aria-label="Certificado de Matrimonio Colaborador"></input>
                                                <p style={{ display: "none" }} className="mt-2 fw-bold" id="parrafoCertificadoMatrimonioColaborador"><i className="fa fa-check-square fa-fw color-primary" aria-hidden="true" /> <a id="nombreCertificadoMatrimonioColaboradorTexto" href="#" onClick={(e) => e.preventDefault()}>certificado.pdf</a></p>
                                            </div>
                                            <div hidden={relationship !== "B"} className="mb-3">
                                                <label className="form-label mb-2"><strong>{`Certificado de Nacimiento Hermano/a`}</strong></label>
                                                <input onChange={(e) => validInput(e)} accept=".jpg, .jpeg, .png, .pdf" type="file" id={`certificadoNacimientoHermano/a`} required={relationship === "B"} className="form-control" aria-label={`Certificado de Nacimiento Hermano/a`}></input>
                                                <p style={{ display: "none" }} className="mt-2 fw-bold" id={`parrafoCertificadoNacimientoHermano/a`}><i className="fa fa-check-square fa-fw color-primary" aria-hidden="true" /> <a id={`nombreCertificadoNacimientoHermano/aTexto`} href="#" onClick={(e) => e.preventDefault()}>certificado.pdf</a></p>
                                            </div>
                                            <div hidden={relationship !== "D"} className="mb-3">
                                                <label className="form-label mb-2"><strong>{`Certificado de Nacimiento Hijo/a`}</strong></label>
                                                <input onChange={(e) => validInput(e)} accept=".jpg, .jpeg, .png, .pdf" type="file" id={`certificadoNacimientoHijo/a`} required={relationship === "D"} className="form-control" aria-label={`Certificado de Nacimiento Hijo/a`}></input>
                                                <p style={{ display: "none" }} className="mt-2 fw-bold" id={`parrafoCertificadoNacimientoHijo/a`}><i className="fa fa-check-square fa-fw color-primary" aria-hidden="true" /> <a id={`nombreCertificadoNacimientoHijo/aTexto`} href="#" onClick={(e) => e.preventDefault()}>certificado.pdf</a></p>
                                            </div>
                                            <div hidden={relationship !== "B" && relationship !== "M" && relationship !== "FA"}className="mb-3">
                                                <label className="form-label mb-2"><strong>{`Certificado de Nacimiento Colaborador`}</strong></label>
                                                <input onChange={(e) => validInput(e)} accept=".jpg, .jpeg, .png, .pdf" type="file" id={`certificadoNacimientoColaborador`} required={relationship === "B" || relationship === "M" || relationship === "FA"} className="form-control" aria-label="Certificado de Nacimiento"></input>
                                                <p style={{ display: "none" }} className="mt-2 fw-bold" id={`parrafoCertificadoNacimientoColaborador`}><i className="fa fa-check-square fa-fw color-primary" aria-hidden="true" /> <a id={`nombreCertificadoNacimientoColaboradorTexto`} href="#" onClick={(e) => e.preventDefault()}>certificado.pdf</a></p>
                                            </div>
                                            <div hidden={relationship !== "FI"} className="mb-3">
                                                <label className="form-label mb-2"><strong>{`Certificado de Nacimiento Cónyuge`}</strong></label>
                                                <input onChange={(e) => validInput(e)} accept=".jpg, .jpeg, .png, .pdf" type="file" id={`certificadoNacimientoCónyuge`} required={relationship === "FI"} className="form-control" aria-label={`Certificado de Nacimiento Cónyuge`}></input>
                                                <p style={{ display: "none" }} className="mt-2 fw-bold" id={`parrafoCertificadoNacimientoCónyuge`}><i className="fa fa-check-square fa-fw color-primary" aria-hidden="true" /> <a id={`nombreCertificadoNacimientoCónyugeTexto`} href="#" onClick={(e) => e.preventDefault()}>certificado.pdf</a></p>
                                            </div>
                                            </>
                                        : null
                                }
                            </div>
                            <div className="modal-footer border-0 pt-0 justify-content-center pb-5">
                                <button type="button" className="btn btn-primary col-12 col-md-auto" data-bs-dismiss="modal">Cancelar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" /></button>
                                <button type="submit" disabled id="btnSubmitModals" className="btn btn-degradado col-12 col-md-auto">Aceptar <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" /></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    } else {
        return null;
    }
}

const ModalPersonalizado = () => {
    return <>
        <a hidden id="btnModalInvalid" data-bs-toggle="modal" data-bs-target="#modalInvalid"></a>
        <div className="modal fade" id="modalInvalid" tabIndex="-1" style={{ zIndex: "1052" }} aria-labelledby="modalInvalid" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" style={{ zIndex: "2" }}>
                <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                        <button type="button" id="cerrarModalPer" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body text-center py-4">
                        <i className="fa fa-exclamation-triangle color-orange fa-3x" aria-hidden="true" />
                        <h2 className="h5 mt-3" id="msjModalInvalid"></h2>
                    </div>
                </div>
            </div>
        </div>
    </>
}

const ModalSinConexion = () => {
    return <>
        <a hidden id="sinConexion" data-bs-toggle="modal" data-bs-target="#sinConexionModal"></a>
        <div className="modal fade" id="sinConexionModal" tabIndex="-1" aria-labelledby="sinConexionModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body text-center py-4">
                        <i className="fa fa-exclamation-triangle color-orange fa-3x" aria-hidden="true" />
                        <h2 className="h5">No tienes conexión</h2>
                        <p>
                            No puedes ejecutar esta acción si no estás conectado.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </>
}

const EstadoSolicitud = (props) => {
    const data = props.data;
    const [bono, setBono] = useState(false);

    useEffect(() => {
        if (data != false) {
            setBono(data.bonos.filter(bon => bon.valor_bono == props.tipoBono)[0]);
        }
    }, [data]);

    return <>
        <div id="content">
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i className="breadcrumb-item" aria-hidden="true" /> Inicio
                        </Link>    
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/mis-solicitudes">
                            <i className="breadcrumb-item" aria-hidden="true" /> Mis Solicitudes
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Estado Solicitud</li>
                </ol>
            </nav>
            <section className="mis-solicitudes">
                <h1>Mis Solicitudes</h1>
                <div className="box mb-4">
                    <h2 className="h4 tit-section">Estado Solicitud</h2>
                    <h3 className="h5">Detalle</h3>
                    {bono &&
                        <>
                            <p>Usted tiene una solicitud en curso: Corresponde a la solicitud Nº {bono.id}.</p>
                            <p>Recuerde que la solicitud será respondida dentro de 10 días corridos a la fecha de envío.</p>
                            <p>&nbsp;</p>
                        </>
                    }
                    {!bono &&
                        <>
                            <p>Usted no tiene acceso a este bono.</p>
                            <p>&nbsp;</p>
                        </>
                    }
                </div>
            </section>
            <br /><br /><br /><br /><br /><br /><br /><br />
        </div>
    </>
}

const Bonos = () => {
    const emplid = localStorage.getItem("emplid");
    const employeeId = localStorage.getItem("employeeId");
    const [dataOption, setDataOption] = useState(false);
    const [reload, setReload] = useState(false);
    const [firstLogin, setFirsLogin] = useState(false);
    const [statusFirstLogin, setStatusFirstLogin] = useState(false);
    const [optionSelected, setOptionSelected] = useState(false);
    const [familiarNac, setFamiliarNac] = useState(false);
    const [relationship, setRelationship] = useState("D");
    const [relationshipDescr, setRelationshipDescr] = useState("Hijo/a");

    let navigate = useNavigate();
    useEffect(() => {
        if (emplid != null && employeeId != null && window.location.href.split("/mis-solicitudes/")[1] !== "ley-de-conciliacion") {
            solicitudesService.getBonos(emplid, setDataOption);
            moduloDtService.firstLogin(emplid, setFirsLogin, setStatusFirstLogin);
        }
    }, [employeeId, reload, emplid])

    useEffect(() => {
        if (dataOption !== 2 && dataOption != false && firstLogin !== 2 && firstLogin != false) {
            setOptionSelected(window.location.href.split("/mis-solicitudes/")[1]);
        }
    }, [dataOption, firstLogin])

    const habilitadoBono = (tipoBono) => {
        if (dataOption != undefined && dataOption.bonos != undefined && dataOption !== 2 && dataOption != false) {
            let bonos = dataOption.bonos;
            bonos = bonos.filter(bono => bono.valor_bono == tipoBono)[0];
            if (!bonos) {
                return null;
            }
            if (bonos.id != 0) {
                return false;
            } else {
                return true;
            }
        }
    }

    const abrirModalNoBono = () => {
        navigate("/mis-solicitudes")
        document.getElementById("msjModalInvalid").innerHTML = `Usted no tiene bonos disponibles`;
        document.getElementById("btnModalInvalid").click();
    }

return <>
        <ModalPersonalizado />
        <ModalSinConexion />
        {/*  {dataOption !== 2 && dataOption != false && firstLogin !== 2 && firstLogin != false ?
            <OptionTipoBono dataOption={dataOption} selected={optionSelected} optionSelected={setOptionSelected}> </OptionTipoBono>
            : null} */}
        {window.location.href.split("/mis-solicitudes/")[1] === "ley-de-conciliacion" ? (
            <>
                <RemoteJob/>
            </>
        ) : dataOption != undefined && dataOption.bonos != undefined && firstLogin !== 2 && firstLogin != false ?
            dataOption === 2 ? abrirModalNoBono() :
                <>
                    <Modals relationship={relationship} setRelationship={setRelationship} relationshipDescr={relationshipDescr} setRelationshipDescr={setRelationshipDescr} familiar={setFamiliarNac} optionSelected={optionSelected} data={dataOption}></Modals>
                    {optionSelected == "DEF" ?
                        habilitadoBono("DEF") ?
                            <div id='seccionCarpetaDigital'>
                                <OptionDef relationship={relationship} setRelationship={setRelationship} relationshipDescr={relationshipDescr} setRelationshipDescr={setRelationshipDescr} emplid={emplid} reload={setReload} optionSelected={setOptionSelected} familiar={familiarNac} setFamiliar={setFamiliarNac} data={dataOption} firstLogin={firstLogin != false && statusFirstLogin !== 2 ? firstLogin : false} />
                            </div>
                            : <EstadoSolicitud tipoBono={"DEF"} data={dataOption} />
                        : optionSelected == "NAC" ?
                            habilitadoBono("NAC") ?
                                <div id='seccionCarpetaDigital'>
                                    <OptionNac relationship={relationship} setRelationship={setRelationship} relationshipDescr={relationshipDescr} setRelationshipDescr={setRelationshipDescr} emplid={emplid} reload={setReload} optionSelected={setOptionSelected} familiar={familiarNac} setFamiliar={setFamiliarNac} data={dataOption} firstLogin={firstLogin != false && statusFirstLogin !== 2 ? firstLogin : false} />
                                </div>
                                : <EstadoSolicitud tipoBono={"NAC"} data={dataOption} />
                            : optionSelected == "MAT" ?
                                habilitadoBono("MAT") ?
                                    <div id='seccionCarpetaDigital'>
                                        <OptionMat setRelationship={setRelationship} setRelationshipDescr={setRelationshipDescr} emplid={emplid} reload={setReload} optionSelected={setOptionSelected} data={dataOption} firstLogin={firstLogin != false && statusFirstLogin !== 2 ? firstLogin : false} />
                                    </div>
                                    : <EstadoSolicitud tipoBono={"MAT"} data={dataOption} />
                                : null
                    }
                </>
            : dataOption === 2 ? abrirModalNoBono() : dataOption == false ? "Cargando informacion del usuario..." : firstLogin !== 2 && firstLogin == false ? "Cargando bono correspondente..." : "Error al cargar la informacion"
        }
    </>
}

EstadoSolicitud.propTypes = {
    data: PropTypes.any,
    tipoBono: PropTypes.any,
};

Modals.propTypes = {
    data: PropTypes.any,
    optionSelected: PropTypes.any,
    familiar: PropTypes.any,
    relationship: PropTypes.any,
    setRelationship: PropTypes.any,
    setRelationshipDescr: PropTypes.any,
};

FormularioPrincipal.propTypes = {
    data: PropTypes.any,
    firstLogin: PropTypes.any,
    btn: PropTypes.any,
    optionSelected: PropTypes.any
};

MsjInicioBono.propTypes = {
    data: PropTypes.any,
    tipoBono: PropTypes.any,
    children: PropTypes.any,
};

TablaValoresBonos.propTypes = {
    data: PropTypes.any,
    tipoBono: PropTypes.any,
};

LeaveTable.propTypes = {
    data: PropTypes.any,
    tipoBono: PropTypes.any,
};

TablaFamiliares.propTypes = {
    familiares: PropTypes.any,
    tipoBono: PropTypes.any,
    updateFam: PropTypes.any,
    quitarEdit: PropTypes.any,
    relationship: PropTypes.any,
    setRelationship: PropTypes.any,
    setRelationshipDescr: PropTypes.any
};

BotonSubmit.propTypes = {
    familiares: PropTypes.any,
    certificados: PropTypes.any,
    data: PropTypes.any,
    dataOption: PropTypes.any,
    emplid: PropTypes.any,
    reload: PropTypes.any,
    optionSelected: PropTypes.any,
    children: PropTypes.any,
};

OptionNac.propTypes = {
    data: PropTypes.any,
    familiar: PropTypes.any,
    firstLogin: PropTypes.any,
    setFamiliar: PropTypes.any,
    reload: PropTypes.any,
    optionSelected: PropTypes.any,
    emplid: PropTypes.any,
    relationship: PropTypes.any,
    setRelationship: PropTypes.any,
    relationshipDescr: PropTypes.any,
    setRelationshipDescr: PropTypes.any,
};

OptionMat.propTypes = {
    data: PropTypes.any,
    tipoBono: PropTypes.any,
    firstLogin: PropTypes.any,
    emplid: PropTypes.any,
    optionSelected: PropTypes.any,
    reload: PropTypes.any,
}

OptionDef.propTypes = {
    data: PropTypes.any,
    firstLogin: PropTypes.any,
    familiar: PropTypes.any,
    setFamiliar: PropTypes.any,
    emplid: PropTypes.any,
    reload: PropTypes.any,
    optionSelected: PropTypes.any,
    relationship: PropTypes.any,
    setRelationship: PropTypes.any,
    relationshipDescr: PropTypes.any,
    setRelationshipDescr: PropTypes.any,
}

export default Bonos;