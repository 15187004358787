import { taskEither as TE } from "fp-ts";
import * as t from "io-ts";

export interface FormValues {
    emplid: string;
    vacancyType: string;
    estimatedStartDate: string;
}

export const Cell = t.type({
    title: t.string,
    value: t.string,
    hidden: t.union([t.boolean, t.undefined]),
});
export type Cell = t.TypeOf<typeof Cell>;

export const SectionProps = t.type({
    title: t.string,
    type: t.string,
    content: t.union([t.array(Cell), t.undefined]),
    assets: t.union([t.array(Cell), t.undefined]),
    assets_total: t.union([Cell, t.undefined]),
    discounts: t.union([t.array(Cell), t.undefined]),
    discounts_total: t.union([Cell, t.undefined]),
    net_total: t.union([Cell, t.undefined])
});


export const SectionsProps = t.type({
    sections: t.array(SectionProps),
});

export type SectionProps = t.TypeOf<typeof SectionProps>;

export type SectionsProps = t.TypeOf<typeof SectionsProps>;

export const ResponseGetProps = t.type({
    valid: t.string,
    status: t.string,
    firstPage: t.array(SectionsProps),
    secondPage: t.array(SectionsProps),
});
export type ResponseGetProps = t.TypeOf<typeof ResponseGetProps>;

export const ResponsePostProps = t.type({
    valid: t.string,
    status: t.string,
});
export type ResponsePostProps = t.TypeOf<typeof ResponsePostProps>;

export type HttpRequestError = {
    tag: 'httpRequestError';
    error: unknown;
}
export type HttpContentTypeError = {
    tag: 'httpContentTypeError';
    error: unknown;
}
export type HttpResponseStatusError = {
    tag: 'httpResponseStatusError';
    status: number;
}
export interface HttpClient {
    request(
        input: RequestInfo,
        init?: RequestInit,
    ): TE.TaskEither<HttpRequestError, Response>
}
export interface HttpClientEnv {
    httpClient: HttpClient;
}

export const fetchGetCodec = t.type({
    response: ResponseGetProps
});
export type FetchGetProps = t.TypeOf<typeof fetchGetCodec>;

export const fetchPostCodec = t.type({
    response: ResponsePostProps
});
export type FetchPostProps = t.TypeOf<typeof fetchPostCodec>;




export type HttpError =
    | HttpRequestError
    | HttpContentTypeError
    | HttpResponseStatusError
    | t.Errors