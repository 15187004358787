import React from 'react'

const TitleCmp = ({title}) => {
  return (
    <section className="noticias mb-3">
    <h1>{title}</h1>
  </section>
  )
}

export default TitleCmp