import { WorkCompensationCard } from "./WorkCompensationCard";
import { EmployeeDetailCard } from "./EmployeeDetailCard";
import { HistoriesCard } from "./HistoriesCard";
import { useProfileSummaryProvider } from "../../context/EmployeeProfileSummaryContext";

export const EmployeeProfileContent = () => {
    const { summaryEmployee, error } = useProfileSummaryProvider();

    if (summaryEmployee.isLoading)
        return (
            <div className="col-12 col-xl-5">
                <div data-testid="loading" className="full-spinner">
                    <div className="spinner-border text-primary"></div>
                </div>
            </div>
        );

    if (error) {
        return (
            <div className="alert alert-danger" role="alert">
                {error}
            </div>
        );
    }

    return (
        <div className="row row-cols-1 g-4 mb-4">
            <EmployeeDetailCard />
            <WorkCompensationCard />
            <HistoriesCard />
        </div>
    );
};